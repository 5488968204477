import { Moment } from "moment";
import { unparse } from "papaparse";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import { saveDataAs } from "../../helpers/export";
import {
  selectBranchMap,
  selectJobPositionMap,
  selectUserDetailMap,
  selectUserMap,
} from "../../selectors/mapSelectors";
import { selectUsersFull } from "../../selectors/usersFullSelector";
import { RoleType } from "../../shared/entities/IUser";
import { SDateToDisplayDate, toMoment } from "../../shared/helpers/timeHelpers";
import { AccountStatus, UserInfo } from "../../shared/helpers/UserInfo";
import { GetState } from "../../types/AppState";

const userRoleTypeMap = {
  [RoleType.admin]: lg.admin,
  [RoleType.manager]: lg.manager,
  [RoleType.employee]: lg.mitarbeiter,
};

export const toDisplayDate = (simpleDate?: string): string => {
  return simpleDate ? toMoment(simpleDate!).format("L") : "";
};

export const userCsvExport = () => (disp: DispFn, getState: GetState) => {
  const rosterSetting = getState().data.rosterSettings[0];
  const jobPosMap = selectJobPositionMap(getState());
  const branchMap = selectBranchMap(getState());
  const hideDisabledUsers = getState().ui.general.usersPageSetting.hideDisabledUsers;
  const _users = selectUsersFull(getState());
  const users = _users
    .filter((user) => !user.isDeleted)
    .filter((u) => !hideDisabledUsers || new UserInfo(u).getAccountStatus() !== AccountStatus.deactivated);
  const userDetailMap = selectUserDetailMap(getState());
  const userRows: Array<{}> = users.map((user) => {
    const userDetail = userDetailMap[user.id] || {};
    const row = {
      [lg.name]: user.name,
      [lg.email]: user.email,
      [lg.berechtigung]: userRoleTypeMap[user.role],
      [lg.rolle]: user.jobPositionIds.map((id) => jobPosMap[id].name).join(", "),
      [lg.standort]: user.branchIds.map((id) => branchMap[id].name).join(", "),
      // Stammdaten
      [lg.adresse]: userDetail.adresse,
      [lg.plz]: userDetail.postalCode,
      [lg.stadt]: userDetail.city,
      [lg.geburtstag]: toDisplayDate(userDetail.birthDate),
      [lg.geburtsort]: userDetail.birthPlace,
      [lg.personalnummer]: userDetail.employNum,
      [lg.mobilnummer]: userDetail.mobileNum,
      [lg.arbeitsantritt]: toDisplayDate(userDetail.contractStartDate),
      [lg.vertragsende]: toDisplayDate(userDetail.contractEndDate),
      [lg.notiz]: userDetail.additional,
    };

    const customFieldsObj = rosterSetting.customMasterDataFields;
    const customFields = customFieldsObj ? Object.values(customFieldsObj) : [];
    customFields?.forEach(
      (field) =>
        (row[field.label] =
          field.type === "text"
            ? userDetail.customFields?.[field.id]
            : toDisplayDate(userDetail.customFields?.[field.id]))
    );
    return row;
  });

  const data = getRowsWithoutEmptyCols(userRows);
  saveDataAs(unparse(data), `title.csv`);
};

const getRowsWithoutEmptyCols = (rows: Array<{}>): Array<{}> => {
  const allCalls = Object.keys(rows[0]);
  const colsWithValues = {};
  allCalls.forEach((col) => rows.some((row) => row[col] && (colsWithValues[col] = true)));

  return rows.map((_row) => {
    const row = {};
    Object.keys(colsWithValues).forEach((col) => (row[col] = _row[col]));
    return row;
  });
};
