import { AppState } from "./../types/AppState";
import { DispFn } from "./../frontend-core/types/thunkTypes";
import { firebaseWrite, CrudOperation, Operation } from "./../shared/helpers/firebaseHelpers";
import { Raw } from "./../shared/types/general";
import { BaseRepository } from "../frontend-core/BaseRepository";
import { IContract } from "../shared/entities/IContract";
import { v4 as uuid } from "uuid";

class ContractRepository extends BaseRepository<IContract> {
  constructor() {
    super("contracts");
  }

  updateContracts =
    (newContracts: Raw<IContract>[], removeIds: string[]) => async (dispatch: DispFn, getState: () => AppState) => {
      const tenantId = getState().data.auth.session!.tenantId;
      const payload: CrudOperation<IContract>[] = [];

      removeIds.forEach((id) => {
        payload.push({ operation: Operation.remove, entity: { id } as any });
      });
      newContracts.forEach((contract) => {
        const entity = { ...contract, id: uuid() } as IContract;
        payload.push({ operation: Operation.create, entity });
      });

      await firebaseWrite(payload, this.getBaseRef(tenantId));
    };
}

export const contractRepository = new ContractRepository();
