import React, { useMemo } from "react";
import cn from "classnames";
import "./styles.scss";
import { ICreditSpan } from "../../../../../selectors/creditSpansSelector";
import { IUser } from "../../../../../shared/entities/IUser";
import { getValidContract } from "../../../../../shared/helpers/credit";
import { IContract, WorkInterval } from "../../../../../shared/entities/IContract";
import { getUserCreditsDisplayData } from "../../../../../helpers/creditsDisplayData";
import { RosterMode } from "../../../../../reducers/ui/shifts/roster/rosterMode";
import { getRosterModeSelection } from "../../../../../helpers/rosterHelpers";
import moment from "moment";
import { SDateFormat } from "../../../../../shared/helpers/SimpleTime";
import { toMoment } from "../../../../../shared/helpers/timeHelpers";
import { getMonthIntervalStartDate, getMonthIntervalEndDate } from "../../../../../shared/helpers/dateHelpers";
import { Features } from "../../../../../selectors/FeaturesSelector";
import { RosterDateRange } from "../../../../../selectors/rosterDateRangeSelector";
import { ICreditCorrection } from "../../../../../shared/entities/ICreditCorrection";
import { sitemap } from "../../../../../helpers/sitemap";
import { updateCreditsUISelection } from "../../../../../reducers/ui/shifts/credits";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";
import { push } from "connected-react-router";

export type UserCreditDetailsProps = {
  user: IUser;
  startDate: string;
  rosterMode: RosterMode;
  creditSpan?: ICreditSpan;
  contract: IContract;
  features: Features;
  initialUserCredit?: ICreditCorrection;
  rosterDateRange: RosterDateRange;
  isTemplateMode: boolean;
  customMonthStartDay: number;
  showOvertime?: boolean;
  isKanban?: boolean;
  doPresentCreditFunction?: boolean;
  dispatch?: DispFn;
  isV2?: boolean;
};

export const UserCreditDetails = React.memo((props: UserCreditDetailsProps) => {
  const {
    startDate,
    user,
    rosterMode,
    creditSpan,
    contract,
    features,
    initialUserCredit,
    rosterDateRange,
    isTemplateMode,
    customMonthStartDay,
    showOvertime,
    isKanban,
    doPresentCreditFunction,
    isV2,
  } = props;
  const { rangeStart, rangeEnd } = rosterDateRange;

  const hasMonthlyContract = contract?.interval === WorkInterval.monthly;
  const hasWeeklyContract = !hasMonthlyContract;
  const hasHourAccount = initialUserCredit && initialUserCredit.date <= startDate;
  const { isDayMode, isWeekMode, isMonthMode } = useMemo(() => getRosterModeSelection(rosterMode), [rosterMode]);

  const showMonthly = isMonthMode || (isWeekMode && hasMonthlyContract);

  const showQuota = (isDayMode && hasWeeklyContract) || (isMonthMode && hasMonthlyContract) || isWeekMode;

  const {
    isBalancePositive,
    isOvertimeNegative,
    balanceTime,
    creditTime,
    quotaTime,
    quotaTimeOnlyHours,
    balanceTimeOnlyHours,
    overTime,
    overTimeOnlyHours,
  } = useMemo(() => getUserCreditsDisplayData(creditSpan), [creditSpan]);

  const balanceInfo = lg.stundenkonto_zu_m(showMonthly ? lg.monatsbeginn : lg.wochenbeginn);

  const monthName = moment(rangeStart, SDateFormat).format("MMMM");
  const monthNameShort = monthName.substr(0, 3).toUpperCase();
  const showsCustomMonthInterval = customMonthStartDay !== 1;

  const monthRangeStart = getMonthIntervalStartDate(rangeStart, customMonthStartDay);
  const monthRangeEnd = getMonthIntervalEndDate(rangeStart, customMonthStartDay);

  const rangeDatesStr = useMemo(() => {
    const startDateFormatted = toMoment(monthRangeStart).format("DD.MMM");
    const endDateFormatted = toMoment(monthRangeEnd).format("DD.MMM");
    return `${startDateFormatted} - ${endDateFormatted}`;
  }, [rangeStart, rangeEnd]);

  const isoWeek = moment(rangeStart, SDateFormat).isoWeek();
  const creditQuotaTooltip = showQuota ? `${lg.istzeit} / ${lg.sollzeit} ` : lg.istzeit + " ";

  let creditQuotaInfo = "";

  const clickedAddBalance = useMemo(() => {
    return (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      if (props.dispatch) {
        props.dispatch(push(sitemap.hourAccounts.url));
        props.dispatch(
          updateCreditsUISelection({
            userId: user.id,
            showHourAccountInfo: true,
          })
        );
      }
    };
  }, []);

  if (isMonthMode) {
    creditQuotaInfo = showsCustomMonthInterval ? `[${rangeDatesStr}]` : `[${monthName}]`;
  }

  if (isWeekMode) {
    creditQuotaInfo = hasMonthlyContract
      ? showsCustomMonthInterval
        ? `[${rangeDatesStr}]`
        : `[${monthName}]`
      : `[ ${lg.kw} ${isoWeek} ]`;
  }

  const displayQuotaTime = isDayMode ? quotaTime : quotaTimeOnlyHours;

  const showMonthName = isWeekMode && hasMonthlyContract && !(isKanban && hasHourAccount);
  const showBalance = hasHourAccount && !isDayMode && !isTemplateMode && features.credits;
  const showBalanceActivate = doPresentCreditFunction && !showBalance && !isDayMode && !isTemplateMode;

  return (
    <div className="fb row userCreditDetailsMain">
      <div className="balanceDetails">
        <div
          data-rh={creditQuotaTooltip + creditQuotaInfo}
          data-rh-at="right"
          className={cn({
            creditAndQuota: true,
            hasMonthlyContract,
          })}
        >
          {showMonthName && <div className="monthName">{monthNameShort}</div>}
          {showQuota ? `${creditTime} / ${displayQuotaTime} h` : creditTime}
        </div>
        {showOvertime && !isV2 && (isWeekMode || (isMonthMode && hasMonthlyContract)) && (
          <div
            className={cn({ overtimeBox: true, isNegative: isOvertimeNegative, isZero: overTime === "0:00" })}
            data-rh={lg.überstunden + ": " + overTime}
          >
            {overTimeOnlyHours}
          </div>
        )}
        {showBalance && !isV2 && (
          <div
            className={cn({
              balanceValue: true,
              isPositive: isBalancePositive,
            })}
            data-rh={`${balanceTime} | ${balanceInfo}`}
            data-rh-at="right"
          >
            <div className="onlyHours">{balanceTimeOnlyHours}</div>
          </div>
        )}
        {showBalanceActivate && !isV2 && (
          <div
            className="balanceValue clickable"
            data-rh={lg.stundenkonto_hier_eintragen}
            data-rh-at="right"
            onClick={clickedAddBalance}
          >
            <div className="onlyHours">?</div>
          </div>
        )}
      </div>
    </div>
  );
});
