import React from "react";
import Pages from "./pages/Pages";
import { ConnectedRouter } from "connected-react-router";
import "antd/dist/antd.css";
import { ConfigProvider } from "antd";
import de_DE from "antd/lib/locale-provider/de_DE";
import en_GB from "antd/lib/locale-provider/en_GB";
import en_US from "antd/lib/locale-provider/en_US";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/en-gb";
// The moment-en-US has no package > its included by default
import { Route, Redirect, Switch } from "react-router";
import { sitemap } from "./helpers/sitemap";
import { AppState } from "./types/AppState";
import { connect } from "react-redux";
import ModalManager from "./components/ModalManager/ModalManager";
import { AccountActivate } from "./pages/account/accountActivate/AccountActivate";
import { DispatchBaseProps } from "./frontend-core/types/DispatchBaseProps";
import { History } from "history";
import { GlobalLoader } from "./components/GlobalLoader/GlobalLoader";
import NoMobile from "./pages/account/noMobile/NoMobile";
import Login from "./pages/account/login/Login";
import { Register } from "./pages/account/register/Register";
import SetupWizard from "./pages/setupWizard/SetupWizard";
import { environment } from "./env";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import { authRepository } from "./repositories/authRepository";
import { ISession } from "./shared/types/ISession";
import { DataStatus } from "./reducers/dataStatus";
import Preloader from "./pages/preloader/Preloader";
import { AdminDashboard } from "./pages/admin/Admin";
import { checkServerTime } from "./actions/checkServerTime";
import { WrongDeviceTimeWarning } from "./components/WrongDeviceTimeWarning";
import { isUnauthorized } from "./shared/helpers/authHelpers";
import { PrintModeLoader } from "./components/PrintModeLoader/PrintModeLoader";
// import "react-virtualized/styles.css";
import { MobileDownloadRedirector } from "./components/MobileDownloadRedirector/MobileDownloadRedirector";
import { NoMobileAdmin } from "./pages/account/noMobileAdmin/NoMobileAdmin";
import { IRosterSettings } from "./shared/entities/IRosterSettings";
import { getLocale } from "./helpers/dateFormatHelper";
import TestForm from "./surveyTest/TestForm";

moment.locale(getLocale());
const getAntLocale = getLocale().includes("de") ? de_DE : getLocale() === "en-US" ? en_US : en_GB;

if (firebase.apps.length === 0) {
  firebase.initializeApp(environment.getFirebaseConfig());
  firebase.database.enableLogging(false);
}

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

const mapStateToProps = (state: AppState) => {
  const maintenance = state.data.maintenance;
  const tenantId = state.data.auth.session?.tenantId;
  const env = environment.getEnv();
  const session = state.data.auth?.session;
  const currentUser = session && state.data.users.find((u) => u.id === session.userId);

  return {
    isLoggedIn: !!session,
    session,
    isLoading: state.dataStatus === DataStatus.isLoading,
    maintenanceMode: maintenance?.maintenanceMode,
    isDisconnected: tenantId && maintenance?.disconnectedTenants?.[tenantId],
    versionMismatch: maintenance && maintenance.webVersion > env.APP_VERSION,
    setupWizard: state.ui.setupWizard,
    wrongDeviceTime: state.ui.wrongDeviceTime,
    currentUser,
    rosterSetting: state.data.rosterSettings[0],
  };
};

type OwnProps = { history: History<any> };
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class App extends React.PureComponent<Props> {
  componentWillUpdate = (nextProps: Props) => {
    const currentUser = this.props.currentUser;
    const nextUser = nextProps.currentUser;
    const userGotUpdated = currentUser !== nextUser;

    if (currentUser && nextUser && userGotUpdated) {
      const nextUser = nextProps.currentUser;
      const isRoleChanging = currentUser.role !== nextUser!.role;
      const isUserUnauthorized = isUnauthorized(nextUser!.role, !!nextUser!.isDeleted, nextUser!.lastWorkDay);
      // to kick a user out as soon he got delted or roleChanged
      // otherwise need to 1 hour for the firebase token expire
      if (isUserUnauthorized || isRoleChanging) {
        this.props.dispatch(authRepository.logout());
      }
    }
  };

  componentDidMount() {
    firebase.auth().onIdTokenChanged(async (user) => {
      if (user) {
        const idToken = await firebase.auth().currentUser!.getIdTokenResult();
        const { claims } = idToken;
        console.log({ d: { p: { g: { g: { claims } } } } }); // dont remove this: useful for debugging auth-issues

        if (isUnauthorized(claims.role, claims.isDeleted, claims.lastWorkDay)) {
          this.props.dispatch(authRepository.logout());
          return;
        }

        const session = {
          accountId: user.uid,
          userId: claims.userId,
          role: claims.role,
          tenantId: claims.tenantId,
          servicebotHash: claims.servicebotHash,
          tenantCreatedAt: claims.tenantCreatedAt,
        } as ISession;
        this.props.dispatch(authRepository.setSession(session));
      }

      if (!user && this.props.session) {
        this.props.dispatch(authRepository.logout());
      }
    });
  }

  render() {
    const { isLoading, maintenanceMode, versionMismatch, setupWizard, isDisconnected } = this.props;

    if (this.props.wrongDeviceTime) {
      return <WrongDeviceTimeWarning />;
    }

    if (maintenanceMode || isDisconnected) {
      return (
        <div>
          {lg.die_anwendng_wird_derzeitig_gewartet_bitte_habe_etwas_geduld_die_Anwendung_ist_in_kürze_wieder_verfügbar}
        </div>
      );
    }

    if (versionMismatch) {
      return <div>{lg.version_ist_nicht_up_to_date}</div>;
    }

    if (setupWizard && this.props.isLoggedIn) {
      //if (setupWizard && this.props.isLoggedIn) {
      return <SetupWizard />;
    }

    return (
      <ConnectedRouter history={this.props.history}>
        <ConfigProvider locale={getAntLocale}>
          <>
            <GlobalLoader isLoading={isLoading} />
            <PrintModeLoader />
            {this.props.isLoggedIn ? (
              <Preloader>
                <Switch>
                  <Route path={sitemap.mobileDownload.url} component={MobileDownloadRedirector} />
                  <Route path={sitemap.noMobileAdmin.url} component={NoMobileAdmin} />
                  <Route path={sitemap.noMobile.url} component={NoMobile} />
                  <Route path={sitemap.app.url} component={Pages} />
                  <Route path={sitemap.passwordReset.url} component={AccountActivate} />
                  <Route path={sitemap.activation.url} component={AccountActivate} />
                  <Redirect from="/" exact to={sitemap.app.url} />
                  <Redirect from={sitemap.login.url} to={sitemap.app.url} />
                  <Redirect from={sitemap.register.url} to={sitemap.app.url} />
                  <Redirect to={"/"} />
                </Switch>
              </Preloader>
            ) : (
              <Switch>
                <Route path={sitemap.mobileDownload.url} component={MobileDownloadRedirector} />
                <Route path={sitemap.noMobile.url} component={NoMobile} />
                <Route path={sitemap.login.url} component={Login} />
                <Route path={sitemap.register.url} component={Register} />
                <Route path={sitemap.activation.url} component={AccountActivate} />
                <Route path={sitemap.passwordReset.url} component={AccountActivate} />
                <Route path={"/admin"} component={AdminDashboard} />
                <Redirect to={sitemap.login.url} />
              </Switch>
            )}
            <ModalManager />
            {/* {!isProd && !this.props.isLoggedIn && <DatabaseSeeder />} */}
            {/* {!this.props.isLoggedIn && <DatabaseSeeder />} */}
          </>
        </ConfigProvider>
      </ConnectedRouter>
    );
  }
}

const ConnectedApp = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(App);

export default ConnectedApp;
