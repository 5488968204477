import { getWeekDay } from "./weekDaysList";
import { getDuration } from "./timeHelpers";
import { IContract, WeekDays } from "../entities/IContract";
import { ITracking } from "../entities/ITracking";
import { IShift } from "../entities/IShift";
import { WorkInterval } from "../entities/IContract";
import { getWorkDaysInMonth } from "./dateHelpers";
import { ITimeClocking } from "../entities/ITimeClocking";

const getDummyCredit: IContract = {
  // this dummyCredit is just needed for the edcase when a user+credit gets created
  // for a milisecond there is no credit for the freshly created user
  // This occurs usually for others, that are simultaniously logged in the browser-application
  id: "dummyCredit",
  userId: "dummyCredit",
  dailyQuota: { mo: 60 } as any,
  validFrom: "2000-01-01",
  validTo: "3000-01-01",
  totalHours: 1,
  interval: WorkInterval.weekly,
};

export const isWorkDay = (quota: number | null | undefined): boolean => {
  return quota !== null && quota !== undefined;
};

export const getClockingCreditV2 = (clocking: ITimeClocking): number => {
  if (!clocking.isAccepted) {
    return 0;
  }
  const _clocking = { ...clocking, endTime: clocking.endTime || clocking.startTime };
  return getDuration(_clocking);
};

export const getShiftCredit = (shift: IShift, tracking?: ITracking): number => {
  if ((shift.isDynamicClocked || shift.isNoShow) && !tracking?.isAccepted) {
    return 0;
  }

  const duration = getDuration(tracking?.isAccepted ? tracking : shift);
  return duration + getSurcharge(shift, duration);
};

export const getSurcharge = (shift: IShift, duration: number): number => {
  if (shift.surcharge === undefined) {
    return 0;
  }

  return shift.surchargeInPercentage
    ? Math.round((duration * shift.surcharge) / 100)
    : Math.round(shift.surcharge * 60);
};

export const getQuotaOnDate = (contract: IContract, date: string): number => {
  const isWeekly = contract.interval === WorkInterval.weekly;
  const weekDay = WeekDays[getWeekDay(date)];

  if (!contract.dailyQuota[weekDay]) {
    return 0;
  }

  if (isWeekly) {
    return contract.dailyQuota[weekDay] || 0;
  } else {
    const workDaysInMonth = getWorkDaysInMonth(date, contract);
    const monthlyMinutes = contract.totalHours * 60;
    return Math.round((monthlyMinutes / workDaysInMonth) * 1000) / 1000;
  }
};

export const getValidContract = (userContracts: IContract[], date: string): IContract | undefined => {
  return userContracts?.find((c) => c.validFrom <= date && c.validTo >= date) || getDummyCredit;
};
