import { AppState } from "../types/AppState";
import { createSelector } from "reselect";
import moment from "moment";

export const selectGroupByJobPosition = createSelector(
  [(s: AppState) => s.data.tenantInfo, (s) => s.ui.shifts.roster.groupByJobPosition],
  (tenantInfo, groupByJobPosition) => {
    return tenantInfo.isV2 ? false : !!groupByJobPosition;
  }
);
