import React from "react";
import { useDispatch } from "react-redux";
import "./styles.scss";
import { hideEmptyShiftRows } from "../../../../../actions/hiddenShiftRows";
import { Icon } from "antd";

export const EmptyRowsAreHiddenRow = () => {
  const dispatch = useDispatch();
  return (
    <div className="emptyRowsAreHiddenRowMain">
      <div
        className="iconWrapper"
        data-rh={lg.wieder_einblenden}
        data-rh-at="right"
        onClick={() => dispatch(hideEmptyShiftRows(false))}
      >
        <Icon type="vertical-align-middle" />
      </div>
      <div className="label">{lg.leere_zeilen_werden_ausgeblendet}</div>
      {/* <div className="showAgain" onClick={() => dispatch(hideEmptyShiftRows(false))}>
        Wieder einblenden
      </div> */}
    </div>
  );
};
