import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { AppState } from "../../../../types/AppState";
import React from "react";
import Holidays from "date-holidays";
import { connect } from "react-redux";
import TZModal from "../../../../components/TZModal/TZModal";
import { Button } from "antd";
import { BasicSelect } from "../../../../components/BasicSelect/BasicSelect";
import { germanStates } from "../../../../constants/germanStates";
import "./styles.scss";

import { holidayRepository } from "../../../../repositories/holidaysRepository";
import { closeModal } from "../../../../actions/modal";
import { CountrySelector, ICountrySelectorFormValues } from "../../../../components/CountrySeclector/CountrySelector";
import moment from "moment";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";

const mapStateToProps = (state: AppState) => ({
  tenantInfo: state.data.tenantInfo,
  branches: state.data.branches,
  rosterSettings: state.data.rosterSettings[0],
});

type State = {
  formValues: ICountrySelectorFormValues;
  isLoading: boolean;
  isValid: boolean;
};

type OwnProps = {
  year: number;
  branchId?: string;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class HolidayImportModalComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      formValues: {
        country: this.props.tenantInfo.country || "DE",
        state: this.props.tenantInfo.state,
      },
      isLoading: false,
      isValid: false,
    };
  }

  importClicked = async () => {
    const holidaysOfYear = new Holidays(this.state.formValues.country, this.state.formValues.state!).getHolidays(
      this.props.year
    );

    const holidayEntities = holidaysOfYear
      .filter((h) => h.type === "public")
      .map((h) => ({
        date: moment(h.date).format(SDateFormat),
        name: h.name,
        isLegalHoliday: true,
        branchId: this.props.branchId,
      }));

    await this.props.dispatch(holidayRepository.createList(holidayEntities));
    this.props.dispatch(closeModal());
  };

  render() {
    const { canSetBranchSpecificHolidays } = this.props.rosterSettings;
    const branchTarget = canSetBranchSpecificHolidays
      ? this.props.branchId
        ? lg.für + " " + this.props.branches.find((b) => b.id === this.props.branchId)!.name
        : lg.für_alle_standorte
      : "";

    return (
      <TZModal>
        <TZModal.Head title={lg.feiertage_importieren}></TZModal.Head>
        <TZModal.Body>
          <div className="holidaysImportModalMain">
            <div className="title">
              {lg.wähle_das_bundesland_aus_um_die_gesetzlichen_feiertage_year_branch_zu_importieren(
                this.props.year,
                branchTarget
              )}{" "}
            </div>
            <div className="selectWrapper">
              <CountrySelector
                formValues={this.state.formValues}
                onChange={(formValues, isValid) => this.setState({ formValues, isValid })}
              />
            </div>
          </div>
        </TZModal.Body>
        <TZModal.Footer>
          <Button
            id="import-holidays-of-county"
            type="primary"
            loading={this.state.isLoading}
            disabled={!this.state.isValid}
            onClick={this.importClicked}
          >
            {lg.importieren}
          </Button>
        </TZModal.Footer>
      </TZModal>
    );
  }
}

export const HolidayImportModal = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(
  HolidayImportModalComponent
);
