import React, { useMemo } from "react";
import cn from "classnames";
import { IUser } from "../../../../../shared/entities/IUser";
import GridUserCell from "../../RosterWeekGrid/GridUserCell/GridUserCell";
import { AbsenceStatus } from "../../../../../shared/entities/IAbsence";
import "./styles.scss";
import { getMonthMomentDates } from "../../../../../helpers/general";
import { toSimpleDate } from "../../../../../shared/helpers/timeHelpers";
import { RosterMode } from "../../../../../reducers/ui/shifts/roster/rosterMode";
import { selectCreditSpanForUser } from "../../../../../selectors/creditSpansSelector";
import { RosterMonthGridSlot } from "./RosterMonthGridSlot";
import { selectRosterDateRange } from "../../../../../selectors/rosterDateRangeSelector";
import { selectContractsByUser } from "../../../../../selectors/contractsByUserSelector";
import { featuresSelector } from "../../../../../selectors/FeaturesSelector";
import { selectInitialCreditByUser } from "../../../../../selectors/initialCreditsByUserSelector";
import { useSelector } from "../../../../../helpers/redux";
import {
  selectAvailabilitiesByUserMap,
  selectBranchMap,
  selectHandOverRequestMap,
  selectJobPositionMap,
  selectTimeClockingMap,
  selectWorkSpaceMap,
  selectTrackingMap,
  selectUserMap,
  selectShiftAddressMap,
  selectUserDetailMap,
} from "../../../../../selectors/mapSelectors";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { otherShiftsOfRowSelector, shiftsOfRowSelector } from "../../../../../selectors/RelevantShiftsSelector";
import { selectShiftOverlappingsMap } from "../../../../../selectors/shiftOverlappingsSelector";
import { getValidContract } from "../../../../../shared/helpers/credit";
import { SDateFormat } from "../../../../../shared/helpers/SimpleTime";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { selectVisibleAbsencesByUser } from "../../../../../selectors/absencesByUserSelector";
import { selectAbsenceTypeMap } from "../../../../../selectors/absenceTypeMapSelector";
import { RosterRowCollapseButton } from "../../components/RosterRowCollapseButton/RosterRowCollapseButton";
import { selectLabourLawWarning } from "../../../../../selectors/laborLawWarningSelector";
import { monthlyMaxHoursWarningSelector } from "../../../../../selectors/monthlyMaxHoursWarningSelector";
import { assignedShiftsSelector } from "../../../../../selectors/assignedShiftsSelector";
import { OpenShiftsRowInfoIcon } from "../../RosterWeekGrid/OpenShiftsRowInfoIcon/AddUserRow";
import { canUserEditOwnShiftsOfDate } from "../../helpers";
import { selectRosterSettingsByUser } from "../../../../../selectors/rosterSettingsByUserSelector";

type Props = {
  userId?: string;
  jobPositionId?: string;
  isRosterLoading?: boolean;
  isRequirement?: boolean;
  style: Object;
  today: string;
};

export const RosterMonthGridRow = React.memo((props: Props) => {
  const dispatch = useDispatch();
  const usersMap = useSelector(selectUserMap);
  const user = props.userId ? usersMap[props.userId] : undefined;
  const { isRequirement, jobPositionId } = props;

  const shiftsOfRow = useSelector((s) => shiftsOfRowSelector(s, jobPositionId, user?.id, isRequirement), _.isEqual);
  const otherShifts = useSelector((s) => otherShiftsOfRowSelector(s, props.jobPositionId, user?.id), _.isEqual);
  const contractsMap = useSelector(selectContractsByUser);
  const features = useSelector(featuresSelector);
  const initialUserCredits = useSelector(selectInitialCreditByUser);
  const rosterSettings = useSelector((s) => s.data.rosterSettings[0]);
  const rosterSettingsByUser = useSelector(selectRosterSettingsByUser);
  const rosterDateRange = useSelector(selectRosterDateRange);
  const isTemplateMode = useSelector((s) => s.ui.shifts.roster.rosterTemplateMode.active);
  const absencesByUserMap = useSelector(selectVisibleAbsencesByUser);
  const absenceTypeMap = useSelector(selectAbsenceTypeMap);
  const availabilitiesByUser = useSelector(selectAvailabilitiesByUserMap);
  const branchMap = useSelector(selectBranchMap);
  const selectedBranchId = useSelector((s) => s.ui.selectedBranch);
  const userDetailMap = useSelector(selectUserDetailMap);
  const monthDates = useMemo(() => getMonthMomentDates(rosterDateRange.rangeStart), [rosterDateRange.rangeStart]);
  const holidays = useSelector((s) => s.data.holidays).filter(
    (h) =>
      // if the holiday is not explicitly assigned to a branch then it counts for all branches,
      // otherwise, holidays only count for the branch they are assigned to
      !h.branchId || selectedBranchId == h.branchId
  );
  const printMode = useSelector((s) => s.ui.printMode);
  const jobPosMap = useSelector(selectJobPositionMap);
  const workSpaceMap = useSelector(selectWorkSpaceMap);
  const addressMap = useSelector(selectShiftAddressMap);
  const sessionInfo = useSelector(selectSessionInfo);
  const overlappingsMap = useSelector(selectShiftOverlappingsMap);
  const recentModifiedShifts = useSelector((s) => s.ui.shifts.roster.recentModifiedShifts, _.isEqual);
  const trackingMap = useSelector(selectTrackingMap);
  const handOverRequestMap = useSelector(selectHandOverRequestMap);
  const timeClockingMap = useSelector(selectTimeClockingMap);
  const isOpenShiftsCollapsed = useSelector((s) => s.ui.shifts.roster.isOpenShiftsCollapsed);
  const isRequiredShiftsCollapsed = useSelector((s) => s.ui.shifts.roster.isRequiredShiftsCollapsed);
  const startDate = monthDates[0].format(SDateFormat);
  const labourLawWarningMap = useSelector(selectLabourLawWarning);
  const maxHoursExceedingMap = useSelector(monthlyMaxHoursWarningSelector);
  const assignedShiftsMap = useSelector(assignedShiftsSelector);
  const isV2 = useSelector((s) => s.data.tenantInfo.isV2);

  const userContracts = user && contractsMap[user.id];

  const canManage = sessionInfo.hasManagerPermissions();
  const showCredits = (user && canManage) || undefined;
  const validContract = showCredits && getValidContract(userContracts!, startDate);
  const userTypes = rosterSettings.userTypes || {};
  const userType = validContract?.userTypeId ? userTypes[validContract.userTypeId] : undefined;
  const creditSpan = useSelector((s) => showCredits && selectCreditSpanForUser(s, user!.id), _.isEqual);

  const { isRosterLoading, style } = props;

  return (
    <div style={style}>
      <div
        className={cn({
          gridRow: true,
          unassignedShiftsRow: !user && !props.isRequirement,
        })}
        key={user ? user.id : "openShifts"}
      >
        <div className="user cell firstColumn">
          {!user && (
            <div className="openShiftsCell">
              {props.isRequirement ? lg.personalbedarf : lg.offene_schichten}
              {props.isRequirement ? null : <OpenShiftsRowInfoIcon />}
              <RosterRowCollapseButton isRequirement={!!props.isRequirement} />
            </div>
          )}
          {!!user && !isRosterLoading && (
            <GridUserCell
              user={user!}
              key={user.id}
              startDate={rosterDateRange.rangeStart}
              rosterMode={RosterMode.Month}
              jobPosMap={jobPosMap}
              creditSpan={creditSpan}
              contract={getValidContract(contractsMap[user.id], rosterDateRange.rangeStart)!}
              features={features}
              initialUserCredit={initialUserCredits[user.id]}
              rosterDateRange={rosterDateRange}
              isTemplateMode={isTemplateMode}
              customMonthStartDay={rosterSettings.customMonthIntervalStart || 1}
              canManage={sessionInfo.hasManagerPermissions()}
              showOvertime={!rosterSettings.hideOvertimeInRoster}
              staffNumber={rosterSettings.showStaffNumberInRoster ? userDetailMap[user.id]?.employNum : undefined}
              userType={rosterSettings.showUserTypeInRoster ? userType : undefined}
              maxHoursExceeding={maxHoursExceedingMap[user.id]}
              isV2={isV2}
            />
          )}
        </div>
        {!isRosterLoading && (
          <div className="slots">
            {monthDates.map((date) => {
              const simpleDate = toSimpleDate(date);
              const absence = user?.id
                ? absencesByUserMap[user?.id]?.find(
                    (a) => a.startDate <= simpleDate && a.endDate >= simpleDate && a.status === AbsenceStatus.active
                  )
                : undefined;

              const shiftsOfDay = shiftsOfRow.filter((s) => s.date === simpleDate);
              const otherShiftsOfDay = otherShifts.filter((s) => s.date === simpleDate);
              const width = 100 / monthDates.length + "%";
              const isHoliday = !!holidays.find((h) => h.date === simpleDate);

              const canEditOwnShifts =
                !canManage &&
                user?.id === sessionInfo.user.id &&
                canUserEditOwnShiftsOfDate(rosterSettingsByUser[user.id], simpleDate);

              return (
                <RosterMonthGridSlot
                  shiftsOfDay={shiftsOfDay}
                  otherShiftsOfDay={otherShiftsOfDay}
                  key={simpleDate}
                  absence={absence}
                  absenceTypeMap={absenceTypeMap}
                  availabilitiesByUser={availabilitiesByUser}
                  width={width}
                  isHoliday={isHoliday}
                  date={date}
                  user={user}
                  simpleDate={simpleDate}
                  jobPositionId={jobPositionId}
                  branchMap={branchMap}
                  selectedBranchId={selectedBranchId}
                  overlapMap={overlappingsMap}
                  printMode={printMode}
                  dispatch={dispatch}
                  jobPosMap={jobPosMap}
                  recentModifiedShifts={recentModifiedShifts}
                  trackingMap={trackingMap}
                  handOverRequestMap={handOverRequestMap}
                  timeClockingMap={timeClockingMap}
                  contract={validContract}
                  showQuota={rosterSettings.showQuotaInMonthlyRosterSlots}
                  showLabelOfShift={rosterSettings.showLabelInMonthlyRosterShifts}
                  workSpaceMap={workSpaceMap}
                  addressMap={addressMap}
                  sessionInfo={sessionInfo}
                  showOpenShiftsCount={!user && !isRequirement && isOpenShiftsCollapsed}
                  showRequirementCount={props.isRequirement && isRequiredShiftsCollapsed}
                  labourLawWarningMap={labourLawWarningMap}
                  isRequirement={isRequirement}
                  assignedShiftsMap={assignedShiftsMap}
                  canEditOwnShifts={canEditOwnShifts}
                  isV2={isV2}
                  isInFuture={simpleDate > props.today}
                  isToday={simpleDate === props.today}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
});
