import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { Dispatch } from "redux";
import "./styles.scss";
import EntitlementRow from "./EntitlementRow/EntitlementRow";
import { selectActiveUsersFull } from "../../../../selectors/ActiveUserSelectors";
import moment from "moment";
import _ from "lodash";
import { selectUsersWithSharedBranch } from "../../../../selectors/UsersWithSharedBranchSelector";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import { IUserFull } from "../../../../shared/entities/IUser";
import { absenceDaysPrevYearSumSelector, absenceDaysSumSelector } from "../../../../selectors/absenceDaysSumSelector";
import { absenceRequestDaysSumSelector } from "../../../../selectors/absenceRequestDaysSumSelector";
import { selectContingentCorrections } from "../../../../selectors/contingentCorrectionsSelector";
import { selectAbsenceEntitlements } from "../../../../selectors/absenceEntitlementsSelector";

const mapStateToProps = (state: AppState) => {
  const year = state.ui.absences.selectedYear;
  const firstOfTheYeaer = moment().year(year).startOf("year").format(SDateFormat);

  return {
    users: selectActiveUsersFull(state, firstOfTheYeaer),
    absences: state.data.absences,
    absenceEntitlements: selectAbsenceEntitlements(state),
    contingentCorrections: selectContingentCorrections(state),
    absenceDaysByUser: absenceDaysSumSelector(state),
    absenceDaysPrevYearByUser: absenceDaysPrevYearSumSelector(state),
    requestedAbsenceDaysByUser: absenceRequestDaysSumSelector(state),
    year: state.ui.absences.selectedYear,
    usersWithSharedBranch: selectUsersWithSharedBranch(state),
    sessionInfo: selectSessionInfo(state),
    filters: state.ui.filters.absenceCalendar,
  };
};

type State = {};
type OwnProps = {
  selectedTypeId: string;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & { dispatch: Dispatch };

class AbsenceContingentBody extends PureComponent<Props, State> {
  getFilteredUsers = (): IUserFull[] => {
    const { filters, users, usersWithSharedBranch, sessionInfo } = this.props;
    const { searchInput, branchFilter, positionFilter } = filters;
    const inputLow = searchInput ? searchInput.toLowerCase() : "";

    const _users = users
      .filter((u) => !searchInput || u.name.toLowerCase().includes(inputLow))
      .filter((u) => !branchFilter || u.branchIds.includes(branchFilter))
      .filter((u) => !positionFilter || u.jobPositionIds.includes(positionFilter))
      .filter((u) => sessionInfo.isAdmin() || usersWithSharedBranch.includes(u.id));

    return _.sortBy(_users, (u) => u.name);
  };

  render() {
    const {
      absenceEntitlements,
      contingentCorrections,
      year,
      absenceDaysByUser,
      absenceDaysPrevYearByUser,
      requestedAbsenceDaysByUser,
      selectedTypeId,
    } = this.props;

    const entitlements = absenceEntitlements.filter((a) => a.absenceTypeId === selectedTypeId);
    const corrections = contingentCorrections.filter((a) => a.absenceTypeId === selectedTypeId);

    console.log(absenceDaysByUser);

    return (
      <div className="vacationCalendarBodyMain">
        {this.getFilteredUsers().map((user) => (
          <div className="vacationRowMain" key={user.id}>
            <EntitlementRow
              absenceTypeId={selectedTypeId}
              user={user}
              takenAbsenceDays={absenceDaysByUser[user.id]}
              requestedAbsenceDays={requestedAbsenceDaysByUser[user.id]}
              absenceEntitlements={entitlements.filter((c) => c.userId === user.id)}
              contingentCorrection={corrections.find((c) => c.userId === user.id && c.year === year && !c.isOverride)}
              contingentOverrideCorrection={corrections.find(
                (c) => c.userId === user.id && c.year === year && c.isOverride
              )}
              // for prev year
              contingentCorrectionPrevYear={corrections.find(
                (c) => c.userId === user.id && c.year === year - 1 && !c.isOverride
              )}
              contingentOverrideCorrectionPrevYear={corrections.find(
                (c) => c.userId === user.id && c.year === year - 1 && c.isOverride
              )}
              takenAbsenceDaysPrevYear={absenceDaysPrevYearByUser[user.id]}
            />
          </div>
        ))}
      </div>
    );
  }
}

export default connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(AbsenceContingentBody);
