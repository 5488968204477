import React, { PureComponent } from "react";
import { Input } from "antd";
import "./styles.scss";

type Props = {
  note?: string;
  changeNote: (text: string) => any;
  readOnly?: boolean;
};

export default class AbsenceNotesSection extends PureComponent<Props> {
  render() {
    const { note, changeNote, readOnly } = this.props;

    return (
      <>
        {readOnly ? (
          <div className="readOnlyNoteWrapper">{note}</div>
        ) : (
          <Input.TextArea
            placeholder={lg.kommentar}
            value={note}
            onChange={(e) => changeNote(e.target.value)}
            autoSize={{ minRows: 2, maxRows: 4 }}
          />
        )}
      </>
    );
  }
}
