import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, notification } from "antd";
import "./styles.scss";
import { busyInjector, BusyInjectorProps } from "../../../../components/BusyInjector/BusyInjector";
import _ from "lodash";
import BusyWrapper from "../../../../components/BusyWrapper/BusyWrapper";
import AvatarEditor from "react-avatar-edit";
import firebase from "firebase/compat/app";
import "@firebase/storage-compat";
import { useSelector } from "../../../../helpers/redux";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import Resizer from "react-image-file-resizer";
import { IUserFull } from "../../../../shared/entities/IUser";
import { userRepository } from "../../../../repositories/userRepository";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import { LoopProtector } from "../../../../frontend-core/LoopProtector";

const b64toBlob = (dataURI) => {
  var byteString = atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/jpeg" });
};

const resizeFile = (file, type: "big" | "small") =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      b64toBlob(file),
      type === "big" ? 500 : 100,
      type === "big" ? 500 : 100,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

type Props = BusyInjectorProps & {
  user: IUserFull;
};

export const ProfilePictureEditor = busyInjector((props: Props) => {
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [defaultLoaded, setDefaultLoaded] = useState(false);
  const [defaultSource, setDefaultSource] = useState<string | undefined>(undefined);
  const dispatch = useDispatch<DispFn>();
  const tenantId = useSelector((s) => s.data.auth.session!.tenantId);

  const getRefString = (type: "big" | "small") => {
    return `${tenantId}/profilePicture/${props.user.id}/${props.user.id}-profile${type === "big" ? "" : "-small"}.jpeg`;
  };

  const save = async () => {
    const bigImagestorageRef = firebase.storage().ref(getRefString("big"));
    const smallImagestorageRef = firebase.storage().ref(getRefString("small"));

    // Substring needed because the base64 contains some meta data in the beginning (copied from stackoverflow)
    await bigImagestorageRef.putString(((await resizeFile(croppedImage, "big")) as any).substring(23), "base64");
    await smallImagestorageRef.putString(((await resizeFile(croppedImage, "small")) as any).substring(23), "base64");
    const photoUrl = (await smallImagestorageRef.getDownloadURL()) as string;
    LoopProtector.check(dispatch);
    firebase.database().ref(`tenants/${tenantId}/users/${props.user.id}/photoUrl`).set(photoUrl);
    setDefaultSource(await bigImagestorageRef.getDownloadURL());
    setCroppedImage(null);
  };

  useEffect(() => {
    (async () => {
      try {
        const url = await firebase.storage().ref(getRefString("big")).getDownloadURL();
        setDefaultSource(url);
      } catch {
        // file does not exist
      } finally {
        setDefaultLoaded(true);
      }
    })();
  }, []);

  const deleteImage = async () => {
    await firebase.storage().ref(getRefString("big")).delete();
    await firebase.storage().ref(getRefString("small")).delete();
    const nextUser = { ...props.user, photoUrl: null };
    await dispatch(userRepository.update(nextUser));
    setDefaultSource(undefined);
  };

  // console.log(defaultSource);

  return (
    <div className="profilePictureEditor">
      <BusyWrapper isBusy={props.isLoading() || !defaultLoaded}>
        <div style={{ padding: 12, minHeight: 226 }} className="jCenter">
          <h2 style={{ marginBottom: 20 }}>{lg.profilbild}</h2>
          <div className="row fb aCenter">
            {defaultSource && (
              <div style={{ marginLeft: 0 }}>
                <img
                  src={defaultSource}
                  className="profilePicturePreview"
                  style={{ marginLeft: 0, marginTop: 5, width: 250, height: 250 }}
                />
                <img src={defaultSource} className="profilePicturePreview" />
                <img src={defaultSource} className="profilePicturePreviewSmall" />
              </div>
            )}
            {!defaultSource && defaultLoaded && (
              <>
                <div
                  style={{ overflow: "hidden", width: 250, height: 250, cursor: "pointer" }}
                  className="fb aCenter row"
                >
                  <AvatarEditor
                    width={250}
                    height={250}
                    imageWidth={250}
                    src={defaultSource}
                    labelStyle={{
                      fontSize: 15,
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                      cursor: "pointer",
                    }}
                    cropRadius={50}
                    exportAsSquare
                    onCrop={(f) => {
                      setCroppedImage(f);
                    }}
                    onClose={() => setCroppedImage(null)}
                    minCropRadius={50}
                    label={lg.hier_klicken_um_ein_profilbild_hochzuladen}
                    onBeforeFileLoad={(elem: any) => {
                      if (elem.target.files[0].size > 99994304) {
                        notification.error({
                          message: lg.die_datei_darf_nicht_größer_als,
                        });
                        elem.target.value = "";
                        return;
                      }
                    }}
                  />
                </div>
                {!!croppedImage && (
                  <>
                    <img src={croppedImage} className="profilePicturePreview" />
                    <img src={croppedImage} className="profilePicturePreviewSmall" />
                  </>
                )}
              </>
            )}
          </div>
          <div style={{ padding: 24, paddingLeft: 0, paddingBottom: 0 }}>
            {!defaultSource && (
              <Button
                disabled={!croppedImage}
                children={lg.profilbild_speichern}
                onClick={() => props.load(save())}
                type="primary"
              />
            )}

            {defaultSource && (
              <>
                <Button children={lg.profilbild_ändern} type="primary" onClick={() => setDefaultSource(undefined)} />
                <Button
                  style={{ marginLeft: 12 }}
                  children={lg.profilbild_löschen}
                  type="danger"
                  onClick={() => props.load(deleteImage())}
                />
              </>
            )}
          </div>
        </div>
      </BusyWrapper>
    </div>
  );
});
