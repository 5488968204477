import React from "react";
import "./styles.scss";
import cn from "classnames";
import { AppState } from "../../types/AppState";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import { connect } from "react-redux";
import { IAbsence } from "../../shared/entities/IAbsence";

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  return {
    absenceTypes: state.data.absenceTypes,
  };
};

type OwnProps = {
  absence?: IAbsence;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

export const _AbsenceLabel = (props: Props) => {
  if (!props.absence) {
    return null;
  }

  const absenceType = props.absenceTypes.find((t) => t.id === props.absence?.typeId);

  return (
    <div
      data-rh-at="bottom"
      data-rh={absenceType?.name}
      // {..._.omit(props, ['absence'])}
      className={cn({ absenceLabelMain: true })}
      // style={{ backgroundColor: absenceType.color }}
    >
      {lg.abwesend}
    </div>
  );
};

export const AbsenceLabel = connect(mapStateToProps)(_AbsenceLabel);
