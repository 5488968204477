import React, { ChangeEvent } from "react";

type Props = {
  onValueChange: (val: number | undefined) => void;
  value: number | undefined;
  getInputRef: (ref: any) => void;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export default class AvIntegerInput extends React.PureComponent<Props, {}> {
  onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value || "";

    if (val.includes(",") || val.includes(".")) {
      return;
    }

    if (val === "") {
      this.props.onValueChange(undefined);
      return;
    }

    if (val) {
      const lastChar = val.slice(-1);
      if ("0123456789".includes(lastChar)) {
        this.props.onValueChange(Number(val));
      }
    }
  };

  render() {
    const { value, onValueChange, getInputRef, ...otherProps } = this.props;

    return (
      <input
        type="text"
        value={this.props.value || ""}
        onChange={this.onChange}
        ref={(ref) => getInputRef && getInputRef(ref)}
        {...otherProps}
      />
    );
  }
}
