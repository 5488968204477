import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { InputNumber, Button, Icon } from "antd";
import TZModal from "../../TZModal/TZModal";
import { closeModal } from "../../../actions/modal";
import { Modal } from "antd";
import { Omit } from "lodash";
import { absenceEntitlementRepository } from "../../../repositories/absenceEntitlementRepository";
import "./styles.scss";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { busyInjector, BusyInjectorProps } from "../../BusyInjector/BusyInjector";
import { IAbsenceEntitlement } from "../../../shared/entities/IAbsenceEntitlement";
import { selectUsersFull } from "../../../selectors/usersFullSelector";
import { decimalSeparatorLocal } from "../../../helpers/dateFormatHelper";
import { BasicSelect } from "../../BasicSelect/BasicSelect";
import { selectActiveUsers, selectActiveUsersFull } from "../../../selectors/ActiveUserSelectors";
import { toSimpleDate } from "../../../shared/helpers/timeHelpers";
import moment from "moment";
import _ from "lodash";
import { selectAbsenceEntitlements } from "../../../selectors/absenceEntitlementsSelector";
import { v4 as uuid } from "uuid";

const mapStateToProps = (state: AppState) => ({
  users: selectUsersFull(state),
  jobPositions: state.data.jobPositions,
  branches: state.data.branches,
  activeUsersFull: selectActiveUsersFull(state, toSimpleDate(moment())),
  selectedYear: state.ui.absences.selectedYear as number,
  absenceTypes: state.data.absenceTypes,
  selectedTypeId: state.ui.absences.selectedTypeId as string,
  absenceEntitlements: selectAbsenceEntitlements(state),
});

type State = {
  jobPositionIds: string[];
  branchIds: string[];
  days?: number;
};
type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class AbsenceEntitlementBulkModalComp extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      jobPositionIds: [],
      branchIds: [],
      days: undefined,
    };
  }

  onChange = (_days?: number | string) => {
    // when the user clears the input-field, '' is the value
    const days = typeof _days === "string" ? undefined : _days;
    this.setState({ days });
  };

  async save() {
    const existintEntries = this.props.absenceEntitlements.filter((a) => a.year === this.props.selectedYear);
    const usersToSkipMap = {};
    existintEntries.forEach((e) => (usersToSkipMap[e.userId] = true));

    const selectedUsers = this.getSelectedUsers();
    const relevantUsers = selectedUsers.filter((u) => !usersToSkipMap[u.id]);

    if (this.state.days) {
      const newEntries = relevantUsers.map((user) => ({
        id: uuid(),
        absenceTypeId: this.props.selectedTypeId,
        year: this.props.selectedYear,
        userId: user.id,
        days: this.state.days as number,
      }));

      this.props.dispatch(absenceEntitlementRepository.createList(newEntries));
      console.log(newEntries);

      this.props.dispatch(closeModal());
    }
  }

  getSelectedUsers = () => {
    const { branchIds, jobPositionIds } = this.state;
    return this.props.activeUsersFull.filter(
      (u) =>
        (!branchIds.length || _.intersection(branchIds, u.branchIds).length) &&
        (!jobPositionIds.length || _.intersection(jobPositionIds, u.jobPositionIds).length)
    );
  };

  render() {
    const { selectedYear } = this.props;

    const selectedUsers = this.getSelectedUsers();
    const selectedUserNams = selectedUsers.map((u) => u.name).join(" / ");

    return (
      <TZModal>
        <TZModal.Head title={"Anspurch eintragen " + selectedYear}></TZModal.Head>
        <TZModal.Body>
          <div className="absenceEntitlementBulkModal">
            {/* <div className="label">{`${lg.urlaubsanspurch_ab_dem_jahr} ${year}:`}</div> */}
            <div className="selectWrapper jobPosSelectWrapper">
              <BasicSelect
                id="job-position-select"
                value={this.state.jobPositionIds}
                onChange={(value: string[]) => this.setState({ jobPositionIds: value })}
                style={{ width: "100%" }}
                mode="multiple"
                allowClear={true}
                placeholder="Alle Rollen"
                options={this.props.jobPositions
                  .filter((jp) => !jp.isInactive)
                  .map((o) => ({
                    value: o.id,
                    label: o.name,
                  }))}
              />
            </div>
            <div className="selectWrapper branchSelectWrapper">
              <BasicSelect
                id="branch-select"
                value={this.state.branchIds}
                onChange={(value: string[]) => this.setState({ branchIds: value })}
                style={{ width: "100%" }}
                mode="multiple"
                allowClear={true}
                placeholder="Alle Standorte"
                options={this.props.branches
                  .filter((jp) => !jp.isInactive)
                  .map((o) => ({
                    value: o.id,
                    label: o.name,
                  }))}
              />
            </div>
            <div className="selectedUser" data-rh={selectedUserNams}>
              {selectedUsers.length} {lg.mitarbeiter} {lg.selektiert}
            </div>
            <div className="inputWrapper">
              <InputNumber
                autoFocus={true}
                decimalSeparator={decimalSeparatorLocal}
                value={this.state.days}
                onChange={this.onChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.save();
                  }
                }}
              />
              <div className="days">{lg.Tage}</div>
            </div>
          </div>
        </TZModal.Body>
        <TZModal.Footer>
          <Button
            id="save-vacation-contingent"
            type="primary"
            onClick={() => this.props.load(this.save(), "save")}
            disabled={this.state.days === undefined}
            loading={this.props.isLoading("save")}
          >
            {lg.Speichern}
          </Button>
        </TZModal.Footer>
      </TZModal>
    );
  }
}

export const AbsenceEntitlementBulkModal = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  busyInjector(AbsenceEntitlementBulkModalComp)
);
