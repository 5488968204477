import { Button, Icon, Input, notification } from "antd";
import React, { useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { busyInjector, BusyInjectorProps } from "../../BusyInjector/BusyInjector";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { HintModal } from "../HintModal/HintModal";
import { useSelector } from "../../../helpers/redux";
import "./styles.scss";
import { Hinter, updateHinting } from "../../../actions/hinting";
import { closeModal, openModal } from "../../../actions/modal";
import absencePullingGifUrl from "./absencePulling.gif";
import firebase from "firebase/compat/app";
import TZModal from "../../TZModal/TZModal";
import { selectIsFreshTenant } from "../../../selectors/selectIsFreshTenant";
import { LiveDemoOfferModal } from "../LiveDemoOfferModal/LiveDemoOfferModal";
import { LoopProtector } from "../../../frontend-core/LoopProtector";

export const HowDidYouFindAplanoModal = () => {
  const dispatch = useDispatch();
  const tenantId = useSelector((s) => s.data.auth.session!.tenantId);
  const isFreshTennant = useSelector(selectIsFreshTenant);

  const submit = async (source: string) => {
    dispatch(updateHinting({ hasSeen_howDidYouFindAplano: true }));
    LoopProtector.check(dispatch);
    firebase.database().ref(`tenantMiscs/${tenantId}/marketingChannel`).set(source);
    dispatch(closeModal());

    if (isFreshTennant) {
      dispatch(openModal(LiveDemoOfferModal, {}, { closable: false }));
    }
  };

  const [showCustomInput, setShowCustomInput] = useState(false);
  const [customInput, updateCustomInput] = useState("");

  return (
    <TZModal>
      <TZModal.Body>
        <div className="howDidYouFindAplanoContent">
          <div className="question">{lg.wie_sind_sie_initial_auf_aplano_gestossen}</div>
          {/* <div className="desc">{lg.wie_haben_sie_von_aplano_erfahren}</div> */}
          {showCustomInput
            ? null
            : [
                <div className="option" onClick={() => submit("youtube")}>
                  <div className="circle"></div>
                  Youtube
                </div>,
                <div className="option" onClick={() => submit("google")}>
                  <div className="circle"></div>
                  Google
                </div>,
                <div className="option" onClick={() => submit("facebook")}>
                  <div className="circle"></div>
                  Facebook
                </div>,
                <div className="option" onClick={() => submit("instagram")}>
                  <div className="circle"></div>
                  Instagram
                </div>,
                <div className="option" onClick={() => submit("recommendation")}>
                  <div className="circle"></div>
                  Empfehlung
                </div>,
              ]}
          {!showCustomInput ? (
            <div className="option blank" onClick={() => setShowCustomInput(true)}>
              <div className="circle"></div>
              Sonstiges
            </div>
          ) : (
            <div className="customInputWrapper">
              <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 6 }}
                autoFocus
                style={{ marginBottom: 6 }}
                placeholder={lg.wie_haben_sie_von_aplano_erfahren}
                value={customInput}
                onChange={(e) => updateCustomInput(e.target.value)}
              />
              <Button
                children={lg.senden}
                // style={{ marginLeft: "8px" }}
                onClick={(e) => submit(customInput)}
                disabled={customInput.length < 3}
                type="primary"
              />
            </div>
          )}
        </div>
      </TZModal.Body>
      {/* <TZModal.Footer></TZModal.Footer> */}
    </TZModal>
  );
};
