import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import { IShift } from "../shared/entities/IShift";

export const selectShiftMap: (
  state: AppState
) => { [shiftId: string]: IShift } = createSelector(
  [(state: AppState) => state.data.shifts],
  (shifts) => {
    const shiftMap = {};
    shifts.forEach((s) => (shiftMap[s.id] = s));
    return shiftMap;
  }
);
