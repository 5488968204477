import React from "react";
import cn from "classnames";
import "./styles.scss";
type Props = {
  isIconActive: boolean;
};
export class TopBarDashboardIcon extends React.PureComponent<Props> {
  render() {
    return (
      <div className="topbarIconWrapper">
        <svg
          width="28px"
          height="28px"
          viewBox="0 0 512 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          className={cn({ topbarIcon: true })}
        >
          <g id="003-dashboard-1" fill="rgba(255, 255, 255, 0.5)" fillRule="nonzero">
            <path
              d="M235.539961,171 L0,171 L0.998050682,37.185919 C0.998050682,16.6805523 17.735149,1.80593041e-12 38.3100277,1.80593041e-12 L235.539961,0 L235.539961,171 Z M47.9220273,40 C43.5037493,40 39.9220273,43.581722 39.9220273,48 L39.9220273,131 L195.617934,131 L195.617934,40 L47.9220273,40 Z"
              id="Combined-Shape"
            ></path>
            <path
              d="M38.3100277,512 C17.735149,512 0.998050682,495.235289 0.998050682,474.626467 L0.998050682,213 L235.539961,213 L235.539961,512 L38.3100277,512 Z M195.617934,253 L39.9220273,253 L39.9220273,464 C39.9220273,468.418278 43.5037493,472 47.9220273,472 L195.617934,472 L195.617934,253 Z"
              id="Combined-Shape"
            ></path>
            <path
              d="M277.45809,511 L277.45809,341 L512,341 L512,473.814081 C512,494.319448 495.262902,511 474.688023,511 L277.45809,511 Z M316.382066,382 L316.382066,473 L464.077973,473 C468.496251,473 472.077973,469.418278 472.077973,465 L472.077973,382 L316.382066,382 Z"
              id="Combined-Shape"
            ></path>
            <path
              d="M276.460039,299 L276.460039,0 L474.688023,0 C495.262902,0 512,16.7647107 512,37.3735332 L512,299 L276.460039,299 Z M464.077973,40 L316.382066,40 L316.382066,259 L472.077973,259 L472.077973,48 C472.077973,43.581722 468.496251,40 464.077973,40 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </svg>
      </div>
    );
  }
}
