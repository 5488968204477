import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./styles.scss";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { RosterWeekGridRow } from "./RosterWeekGridRow";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { isMouseOverGridUserCells } from "../helpers";
import UsersGridHead from "./RosterWeekGridHead/RosterWeekGridHead";
import cn from "classnames";
import { AddUserRow } from "./AddUserRow/AddUserRow";
import { selectIsFreshTenant } from "../../../../selectors/selectIsFreshTenant";
import { handleScrolledDownClassIfNeeded } from "../../../../helpers/general";
import { RosterJobPositionLine } from "../components/RosterJobPositionLine/RosterJobPositionLine";
import { rosterElementClicked } from "../../../../actions/rosterUi";
import { mouseLeftUserCells, mouseEnteredUserCells } from "../../../../reducers/ui/shifts/roster/isMouseOverUserCells";
import { rosterRowDataSelector, rosterRowDataSelectorEqualityCheck } from "../../../../selectors/rosterRowDataSelector";
import { VariableSizeList as List, ListChildComponentProps, areEqual } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useSelector } from "../../../../helpers/redux";
import memoizeOne from "memoize-one";
import { SimpleList } from "../../../../components/SimpleList/SimpleList";
import { EmptyRowsAreHiddenRow } from "./EmptyRowsAreHiddenRow/EmptyRowsAreHiddenRow";
import { BatchDeletePopup } from "../../../../components/BatchDeletePopup/BatchDeletePopup";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import moment from "moment";
import { selectGroupByJobPosition } from "../../../../selectors/groupByJobPositionSelector";

type Props = {
  startDate: string;
  isRosterLoading?: boolean;
};

const createData = memoizeOne((startDate, isRosterLoading, rows) => ({
  startDate,
  isRosterLoading,
  rows,
}));

const generateRow = React.memo(({ index, style, data }: ListChildComponentProps) => {
  const { startDate, rows, isRosterLoading } = data;
  const today = moment().format(SDateFormat);
  // Index of row within collection // Style object to be applied to row (to position it)

  const { jobPositionId, user, isJobPositionHeader, isJobPositionHeaderOpen, isRequirement } = rows[index];
  if (isJobPositionHeader) {
    return <RosterJobPositionLine style={style} isOpen={isJobPositionHeaderOpen!} jobPositionId={jobPositionId!} />;
  }

  if (isRosterLoading) {
    return <div style={{ height: 47 }} className="fb row"></div>;
  }

  return (
    <RosterWeekGridRow
      isRequirement={isRequirement}
      startDate={startDate}
      userId={user?.id}
      style={style}
      jobPositionId={jobPositionId}
      today={today}
    />
  );
}, areEqual);

export const RosterWeekGrid = React.memo((props: Props) => {
  const RVListRef = useRef(null) as any;
  const dispatch = useDispatch();
  const draggingShift = useSelector((s) => s.ui.shifts.roster.draggingShift);
  const isFreshTenant = useSelector(selectIsFreshTenant);
  const isMouseOverUserCells = useSelector((s) => s.ui.shifts.roster.isMouseOverUserCells);
  const rows = useSelector(rosterRowDataSelector, rosterRowDataSelectorEqualityCheck);
  const groupByJobPosition = useSelector(selectGroupByJobPosition);
  const hideEmptyRows = useSelector((s) => s.ui.shifts.roster.emptyShiftRowsHidden);
  const sessionInfo = useSelector(selectSessionInfo);
  const printMode = useSelector((s) => s.ui.printMode);

  const clickDetected = useCallback((e) => dispatch(rosterElementClicked(e)), []);
  const scrollListener = useCallback(({ scrollOffset }) => {
    handleScrolledDownClassIfNeeded(scrollOffset !== 0, "userGridWrapper");
  }, []);

  useEffect(() => {
    document.addEventListener("click", clickDetected); // on mount
    return () => {
      document.removeEventListener("click", clickDetected); // on unmount
    };
  }, []);

  useEffect(() => {
    RVListRef?.current?.resetAfterIndex(0);
  }, [rows]);

  const maxHeight = useMemo(() => _.sumBy(rows, (r) => r.height), [rows]);

  // @See: https://react-window.now.sh/#/examples/list/memoized-list-items
  const data = createData(props.startDate, props.isRosterLoading, rows);

  // const needToVirtualize = rows.length > 30;
  const needToVirtualize = !printMode && true; // we decided to always virtualize

  return (
    <div
      className="userGridWrapper"
      onMouseMove={(e) => {
        const isInside = isMouseOverGridUserCells(e);
        isInside && !isMouseOverUserCells && dispatch(mouseEnteredUserCells());
        !isInside && isMouseOverUserCells && dispatch(mouseLeftUserCells());
      }}
      onMouseLeave={() => dispatch(mouseLeftUserCells())}
    >
      <div
        className={cn({
          isDraggingShift: !!draggingShift,
          isDraggingOpenShift:
            draggingShift && !draggingShift.userId && !draggingShift.isRequirement && !draggingShift.isTemplate,
          isDraggingShiftTemplate: draggingShift && draggingShift.isTemplate,
          isDraggingRequiredShift: !!draggingShift?.isRequirement,
          isDraggingUserShift: draggingShift && draggingShift.userId,
          weeklyRoster: true,
          usersGrid: true,
        })}
      >
        <UsersGridHead startDate={props.startDate as string} />
        <div
          className="fb column"
          style={{ flex: "1 1 auto", maxHeight: maxHeight, overflowY: !needToVirtualize ? "hidden" : undefined }}
        >
          {!needToVirtualize ? (
            <SimpleList
              className={cn({ userGridContent: true, isGrouped: groupByJobPosition })}
              onScroll={scrollListener}
              itemData={data}
              generateRow={generateRow}
              itemKey={(index, data) =>
                `${data.rows[index].jobPositionId}` + //
                `${data.rows[index].user?.id}` + //
                `${data.rows[index].isRequirement}`
              }
            />
          ) : (
            <AutoSizer>
              {({ height, width }) => (
                <List
                  className={cn({ userGridContent: true, isGrouped: groupByJobPosition })}
                  ref={RVListRef}
                  onScroll={scrollListener}
                  height={Math.min(maxHeight, height)}
                  itemData={data}
                  itemCount={rows.length}
                  itemSize={(index: number) => rows[index].height}
                  width={width}
                  children={generateRow}
                  itemKey={(index, data) =>
                    `${data.rows[index].jobPositionId}` +
                    `${data.rows[index].user?.id}` +
                    `${data.rows[index].isRequirement}`
                  }
                />
              )}
            </AutoSizer>
          )}
        </div>
        {isFreshTenant && sessionInfo.isAdmin() && <AddUserRow />}
        {hideEmptyRows && <EmptyRowsAreHiddenRow />}
      </div>
    </div>
  );
});
