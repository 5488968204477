import { Button, Icon, Input, message, Modal, Upload } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { busyInjector, BusyInjectorProps } from "../../../../components/BusyInjector/BusyInjector";
import BusyWrapper from "../../../../components/BusyWrapper/BusyWrapper";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import { useSelector } from "../../../../helpers/redux";
import { userDetailRepository } from "../../../../repositories/userDetailRepository";
import { IUpload } from "../../../../shared/entities/IUpload";
import moment from "moment";
import "./styles.scss";
import { selectUserFullMap } from "../../../../selectors/userFullMapSelector";
import cn from "classnames";
import _ from "lodash";
import { editUpload, removeUpload, uploadFile, uploadFileList } from "../../../../actions/uploads";
import { v4 as uuid } from "uuid";
import { uploadRepository } from "../../../../repositories/uploadRepository";
import { AvUploader } from "../../../../components/AvUploader/AvUploader";
import { openModal } from "../../../../actions/modal";
import { EditUploadModal } from "./EditUploadModal/EditUploadModal";
import { downloadFile } from "../../../../frontend-core/helpers/frontendHelpers";
import { RepoKey } from "../../../../shared/types/RepoKeys";
import { localizeFormat } from "../../../../helpers/dateFormatHelper";

type Props = BusyInjectorProps & {
  isCreationMode: boolean;
  userId: string;
  gotDirty: (isDirty?: boolean) => void;
};

export const UserDocumentsTab = busyInjector((props: Props) => {
  const userDetail = useSelector((s) => s.data.userDetails.find((d) => d.id === props.userId));
  const uploads = useSelector((s) => s.data.uploads);
  const userMap = useSelector(selectUserFullMap);
  const dispatch = useDispatch<DispFn>();

  useEffect(() => {
    const key = "userDetailUploads";
    props.load(dispatch(uploadRepository.addListener({ filter: ["relatedEntityId", "=", props.userId], key })));
    props.load(dispatch(userDetailRepository.fetchOne(props.userId)));
  }, []);

  const getDateTooltip = (upload: IUpload) => {
    const user = userMap[upload.userId!];
    const userName = user?.name;
    const size = Math.round((upload.size / 1024) * 100) / 100;
    const uploadTime = moment(upload.timestamp).format(localizeFormat("DD.MM.YY - hh:mm"));
    return lg.angelegt_von_user_am_time_size_kb(userName, uploadTime, size);
  };

  const createUserDetail = async () => {
    return dispatch(userDetailRepository.create({ id: props.userId }));
  };

  const delteClicked = (upload: IUpload) => {
    Modal.confirm({
      title: lg.dokument_löschen,
      content: lg.die_datei_file_wirklich_löschen(upload.name),
      onOk: () => dispatch(removeUpload(upload)),
      cancelText: lg.abbrechen,
      okText: lg.löschen,
      okType: "danger",
    });
  };

  const uploadFiles = async (files: File[]) => {
    if (props.isLoading("uploading")) {
      return; // to prevent simultanous upload-jobs
    }
    const userDetailId = userDetail?.id || (await createUserDetail()).id;
    const uploadJobs = files.map((file) => ({
      file,
      uploadBase: {
        id: uuid(),
        name: file.name,
        displayName: file.name,
        userId: props.userId,
        relatedRepoKey: "userDetails" as RepoKey,
        relatedEntityId: userDetailId,
      },
    }));
    try {
      props.load(dispatch(uploadFileList(uploadJobs)), "uploading");
    } catch (e) {
      console.error(e);
      props.setLoading("uploading", false);
    }
  };

  const editItemClicked = (upload: IUpload) => {
    dispatch(openModal(EditUploadModal, { upload, doSave: true, withComment: true }));
  };

  const renderUploadActions = (upload: IUpload) => {
    return (
      <div className={cn({ cell: true, actions: true })}>
        <div className="actionBtn download" onClick={() => downloadFile(upload)}>
          <Icon data-rh={lg.download} type="download" />
        </div>
        <div className="actionBtn" data-rh={lg.editieren} onClick={() => editItemClicked(upload)}>
          <Icon type="form" />
        </div>
        <div className="actionBtn remove" data-rh={lg.löschen} onClick={() => delteClicked(upload)}>
          <Icon type="delete" />
        </div>
      </div>
    );
  };

  const isUploading = props.isLoading("uploading");
  const uploadsSorted = _.orderBy(
    uploads.filter((u) => u.relatedEntityId === props.userId),
    (u) => u.timestamp
  );

  return (
    <div className="userDetailsTabDocuments">
      <BusyWrapper isBusy={props.isLoading("main")}>
        <div className="fancyUploader">
          <div className="body">
            {uploadsSorted.map((upload) => (
              <div className={cn({ row: true })} key={upload.id} id={upload.id}>
                <div className="cell date" data-rh={getDateTooltip(upload)}>
                  {moment(upload.timestamp).format(localizeFormat("DD.MM.YY"))}
                </div>
                <div className="cell file">
                  <a
                    href={upload.url}
                    data-rh={upload.displayName !== upload.name ? upload.name : null}
                    target="_blank"
                    onClick={(event) => event.stopPropagation()}
                    children={upload.displayName}
                  />
                  {upload.comment && <div className="comment">{upload.comment}</div>}
                </div>
                {renderUploadActions(upload)}
              </div>
            ))}
            {!uploadsSorted.length && <div className="noDocsBox">{lg.noch_keine_dokumente_hochgeladen}</div>}
          </div>
          <div className="uploadButtonWrapper">
            <AvUploader
              disabled={isUploading}
              isLoading={isUploading}
              onChange={async (files: File[]) => uploadFiles(files as File[])}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </BusyWrapper>
    </div>
  );
});
