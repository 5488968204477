import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { toSimpleDate } from "../../../shared/helpers/timeHelpers";
import moment from "moment";
import { shiftRepository } from "../../../repositories/shiftRepository";
import { timeClockingRepository } from "../../../repositories/timeClockingRepository";
import { trackingRepository } from "../../../repositories/TrackingRepository";
import _ from "lodash";
import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import { useSelector } from "../../../helpers/redux";
import {
  getActiveTimeClocking,
  getRelevantShiftsWithoutTrackingInBranch,
} from "../../../shared/helpers/timeClockingHelpers";
import { DispFn } from "../../../frontend-core/types/thunkTypes";
import { TimeClockUserContent } from "../TimeClockUserContent";
import { generateUserTimeClockSettings } from "../../../shared/helpers/settingsHelpers";
import { visibleShiftsSelector } from "../../../selectors/publishedShiftsSelector";
import { selectTimeClockingMap, selectTrackingMap, selectUserMap } from "../../../selectors/mapSelectors";
import { Button, Icon } from "antd";
import { getUserName } from "../../../shared/helpers/userHelpers";
import { alwaysClockableBranchIdsSelector } from "../../../selectors/AlwaysClockableBranchIdsSelector";
import { selectShiftMap } from "../../../selectors/shiftMapSelector";
import { PunchClockUserContent } from "../PunchClockUserContent/PunchClockUserContent";
import { addSimpleDays } from "../../../shared/helpers/dateHelpers";

type Props = BusyInjectorProps & {
  userId: string;
  deselectUserId?: () => void;
  isWebMode?: boolean;
};

export const PunchClockUser = busyInjector((props: Props) => {
  const dispatch = useDispatch<DispFn>();
  const yesterday = toSimpleDate(moment().add(-1, "days"));
  const today = toSimpleDate(moment());

  const usersMap = useSelector(selectUserMap);
  const timeClockStationMode = useSelector((state) => state.ui.timeClockMode);
  const branches = useSelector((s) => s.data.branches);
  const timeClockings = useSelector((state) => state.data.timeClockings);
  const alwaysClockableBranchIds = useSelector(alwaysClockableBranchIdsSelector);

  const currentUser = usersMap[props.userId];
  const clockableBranchIds: string[] = props.isWebMode
    ? branches.filter((b) => b.isClockingEnabled).map((b) => b.id)
    : timeClockStationMode.branchIds
    ? timeClockStationMode.branchIds
    : alwaysClockableBranchIds;

  const userClockings = timeClockings.filter((c) => c.userId === props.userId);

  const activeClocking = userClockings.find((c) => !c.endTime && !c.isAutoClockOut && c.date >= yesterday);

  const pastUserClockings = userClockings
    .filter((c) => !!c.endTime)
    .filter((c) => (c.startTime > c.endTime! ? addSimpleDays(c.date, 1) : c.date) === today);

  const prevClocking = _.orderBy(pastUserClockings, (c) => c.date + c.endTime!, "desc")[0];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    props.setLoading("full", true);
    const userId = currentUser.id;
    const userDateFilter = ["userId_date", "between", [`${userId}_${yesterday}`, `${userId}_${today}`]] as any;
    await dispatch(timeClockingRepository.addListener({ filter: userDateFilter, key: "punchingsOfToday" }));
    props.setLoading("full", false);
  };

  if (props.isLoading("full")) {
    return <Icon type="loading" style={{ fontSize: "70px", color: "white" }} spin />;
  }

  return (
    <>
      {props.deselectUserId && (
        <div className="fb row aCenter jCenter userBox">
          <Button id="deselect-user-of-timeclock" icon="arrow-left" size="default" onClick={props.deselectUserId} />
          {getUserName(currentUser)}
        </div>
      )}
      <PunchClockUserContent
        activeClocking={activeClocking}
        prevClocking={prevClocking}
        clockableBranchIds={clockableBranchIds}
        load={props.load}
        currentUser={currentUser}
        deselectUser={props.deselectUserId}
        isWebMode={props.isWebMode}
      />
    </>
  );
});
