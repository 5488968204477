import React, { useState } from "react";
import TZModal from "../../../TZModal/TZModal";
import { toSimpleDate, simpleDateToMoment } from "../../../../shared/helpers/timeHelpers";
import { DisplayDateFormat } from "../../../../shared/helpers/SimpleTime";
import { useSelector } from "../../../../helpers/redux";
import { selectActiveUsers, selectActiveUsersFull } from "../../../../selectors/ActiveUserSelectors";
import { Input, Button, Icon, Switch } from "antd";
import { selectActiveJobPositions } from "../../../../selectors/ActiveJobPositionsSelector";
import { selectCreditSpans } from "../../../../selectors/creditSpansSelector";
import { UserRow } from "./UserRow";
import { selectContractsByUser } from "../../../../selectors/contractsByUserSelector";
import { addSimpleDays } from "../../../../shared/helpers/dateHelpers";
import { useDispatch } from "react-redux";
import { openModal, closeModal } from "../../../../actions/modal";
import { ShiftPopup } from "../ShiftPopup";
import { getShiftOverlappingsMap } from "../../../../selectors/shiftOverlappingsSelector";
import { isWorkingTimeInvalid } from "../../../../helpers/workingTimeHelpers";
import { IShift } from "../../../../shared/entities/IShift";
import { IShiftOverlap } from "../../../../shared/entities/IShiftOverlap";
import "./styles.scss";
import cn from "classnames";
import { selectBranchMap, selectJobPositionMap } from "../../../../selectors/mapSelectors";
import { selectAvailabilites } from "../../../../selectors/availabilitiesSelector";
import { selectAbsencesByUser } from "../../../../selectors/absencesByUserSelector";
import { orderByName } from "../../../../frontend-core/helpers/frontendHelpers";

type Props = {
  date: string;
  jobPositionId?: string;
  selectedUserIds: string[];
  updateSelectedUserIds: (x: string[]) => void;
  turnIntoOpenShift: () => void;
  startTime?: string;
  endTime?: string;
};

export const UserSelectShiftRow = (props: Props) => {
  const dispatch = useDispatch();
  const users = useSelector((s) => selectActiveUsersFull(s, props.date));
  const absences = useSelector((s) => s.data.absences);
  const absencesByUser = useSelector(selectAbsencesByUser);
  const shifts = useSelector((s) => s.data.shifts);
  const branches = useSelector((s) => s.data.branches);
  const jobPositions = useSelector((s) => s.data.jobPositions);
  const jobPosMap = useSelector(selectJobPositionMap);
  const branchMap = useSelector(selectBranchMap);
  const selectedBranchId = useSelector((s) => s.ui.selectedBranch);
  const availabilities = useSelector(selectAvailabilites);
  const userCreditsMap = useSelector(selectCreditSpans);
  const creditCorrections = useSelector((s) => s.data.creditCorrections);
  const contractsMap = useSelector(selectContractsByUser);
  const isTemplateMode = useSelector((s) => s.ui.shifts.roster.rosterTemplateMode?.active);
  const [filterText, setFilterText] = useState("");

  const _users = users.filter(
    (u) =>
      (!props.jobPositionId || u.jobPositionIds.includes(props.jobPositionId)) && // TODO make JobPosFilter
      (!selectedBranchId || u.branchIds.includes(selectedBranchId))
  );

  const _usersFiltered = !filterText
    ? _users
    : _users.filter((u) => u.name.toLowerCase().includes(filterText.toLowerCase()));

  const usersFiltered = orderByName(_usersFiltered);

  const startOfWeekDate = toSimpleDate(simpleDateToMoment(props.date).startOf("week"));
  const endOfWeekDate = addSimpleDays(startOfWeekDate, 6);

  const toggleUser = (userId: string) => {
    const isDeselecting = props.selectedUserIds.includes(userId);
    const next = isDeselecting
      ? props.selectedUserIds.filter((uid) => uid !== userId)
      : [...props.selectedUserIds, userId];
    props.updateSelectedUserIds(next);
  };

  const isWorkTimeValid = !isWorkingTimeInvalid(props.startTime, props.endTime, 0);

  let overlapsByUserId: { [userId: string]: IShiftOverlap } = {};
  if (isWorkTimeValid) {
    const shiftsOfDay = shifts.filter((s) => s.date === props.date);
    const potentialShifts = usersFiltered.map(
      (user) =>
        ({
          id: "random-id",
          date: props.date,
          userId: user.id,
          branchId: selectedBranchId || "",
          startTime: props.startTime,
          endTime: props.endTime,
          breakMinutes: 0,
        } as any)
    );
    const overlapsMap = getShiftOverlappingsMap([...shiftsOfDay, ...potentialShifts], branchMap, jobPosMap);
    const overlapsList = Object.values(overlapsMap);
    usersFiltered.forEach((user) => {
      const overlap = overlapsList.find((o) => o.userId === user.id);
      overlap && (overlapsByUserId[user.id] = overlap);
    });
  }

  return (
    <div className="selectUsersOfShiftMain">
      <div className="avInputFieldMain">
        <div className="fb col" style={{ flex: "1 1 auto" }}>
          <div className="fb row">
            <span className="labelWrapper">
              <div className="label">{lg.mitarbeiter}</div>
            </span>
            <div className={cn({ usersList: true, isDisabled: !isWorkTimeValid })}>
              <div className={cn({ actionsRow: true, isDisabled: !isWorkTimeValid })}>
                <div className="searchIconWrapper">
                  <Icon type="search" />
                </div>
                <div className="searchInputWrapper">
                  <input
                    type="text"
                    onChange={(e) => setFilterText(e.target.value)}
                    value={filterText}
                    placeholder={`${lg.filtern}...`}
                    className="searchUserInput"
                    style={{ width: "100%" }}
                  />
                  <div
                    className={cn({
                      openShiftButton: true,
                      isDisabled: !!props.selectedUserIds.length,
                    })}
                    onClick={props.turnIntoOpenShift}
                  >
                    <div className="text">{lg.offene_schicht}</div>
                    <Icon type="arrow-right" />
                  </div>
                </div>
              </div>
              {isWorkTimeValid && (
                <div className="usersContent">
                  {usersFiltered.map((u) => {
                    return (
                      <UserRow
                        onClick={() => toggleUser(u.id)}
                        key={u.id}
                        user={u}
                        availabilities={availabilities}
                        absencesByUser={absencesByUser}
                        date={props.date}
                        hideHourAccount={isTemplateMode}
                        creditCorrections={creditCorrections}
                        userCredits={userCreditsMap[u.id]}
                        contracts={contractsMap[u.id]}
                        endOfWeekDate={endOfWeekDate}
                        startOfWeekDate={startOfWeekDate}
                        overlap={overlapsByUserId[u.id]}
                        branches={branches}
                        isSelected={!!props.selectedUserIds.includes(u.id)}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
