import { BalancesMap } from "./creditTypes";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import { generateDatesList } from "../../shared/helpers/dateHelpers";
import { AppState } from "../../types/AppState";
import { getCreditBases } from "./getCreditBases";
import _ from "lodash";
import { emptyCreditBase, getRoundenCreditBase } from "./creditHelpers";
import { selectInitialCreditByUser } from "../../selectors/initialCreditsByUserSelector";

export const getBalances = (
  _userIds: string[],
  fromDate: string,
  _untilDate?: string
) => (dispatch: DispFn, getState: () => AppState): BalancesMap => {
  const userIds = _.uniq(_userIds);
  const initialCreditsByUser = selectInitialCreditByUser(getState());

  const untilDate = _untilDate || fromDate; // by default untilDate is same as fromDate

  if (!userIds.length) {
    return {};
  }

  const ranges = userIds.map((userId) => ({
    userId,
    fromDate: _.min([initialCreditsByUser[userId]?.date || fromDate, fromDate])!,
    untilDate,
  }));

  const creditBasesMap = dispatch(getCreditBases(ranges));

  const balances: BalancesMap = {};

  userIds.forEach((userId) => {
    const startDate = _.min([initialCreditsByUser[userId]?.date || fromDate, fromDate])!;
    const dates = generateDatesList(startDate, untilDate);

    const initialCredit = initialCreditsByUser[userId];
    let prevBalance = 0;

    dates.forEach((date) => {
      const creditBase = creditBasesMap[`${userId}_${date}`] || emptyCreditBase;
      const isInitialCreditDay = initialCredit && initialCredit.date === date;
      const isBeforeInitialCredit = initialCredit && initialCredit.date > date;

      const balance = isBeforeInitialCredit
        ? 0
        : (isInitialCreditDay ? initialCredit.minutes : prevBalance) -
          creditBase.quota +
          creditBase.credit;

      prevBalance = balance;
      const roundenCreditBase = getRoundenCreditBase(creditBase);

      if (date >= fromDate) {
        balances[`${userId}_${date}`] = {
          ...roundenCreditBase,
          balance: Math.round(balance),
        };
      }
    });
  });
  return balances;
};
