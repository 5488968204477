import { toMoment } from "./../shared/helpers/timeHelpers";
// checks if the function fn is true for a node or a parent node.

import { IShiftOverlap } from "../shared/entities/IShiftOverlap";
import { IHoliday } from "../shared/entities/IHoliday";

import { IShift } from "../shared/entities/IShift";

import { IBranch } from "../shared/entities/IBranch";

import { AppState } from "../types/AppState";
import { orderBy, throttle } from "lodash";
import { toSimpleDate, simpleDateToMoment } from "../shared/helpers/timeHelpers";
import { IJobPosition } from "../shared/entities/IJobPosition";
import moment from "moment";
import { WeekDays } from "../shared/constants/WeekDays";
import { IUser, IUserFull } from "../shared/entities/IUser";
import _ from "lodash";
import { Map } from "../shared/types/general";
import { IPublishedWeek } from "../shared/entities/IPublishedWeek";

// And returnes the matching node.
export const closest = (el: any, fn: (el: any) => boolean): HTMLElement | undefined => {
  while (el) {
    if (fn(el)) return el;
    el = el.parentNode;
  }
  return undefined;
};

// finds the closest html-element that matches attributeName and attributeValue
// searching ( looping ) through the parents of the given html-element
export const closestWithAttribute = (el: HTMLElement, attrName: string, attrValue: string): HTMLElement | undefined => {
  const elementHasAttribute = (el: HTMLElement): boolean =>
    !!(el.getAttribute && el.getAttribute(attrName) === attrValue);
  return closest(el, (element) => elementHasAttribute(element));
};

export const closestAttributeValue = (el: HTMLElement, attrName: string): string | undefined => {
  const elementHasAttribute = (el: HTMLElement): boolean => !!(el.getAttribute && el.getAttribute(attrName));
  const matchingNode = closest(el, (element) => elementHasAttribute(element));
  return matchingNode?.getAttribute(attrName)!;
};

export const asyncSleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

/**
 * Returns the initial letter of the first name and the initial letter
 * of the last word from the last name.
 */
export const getNameInitials = (name: string) => {
  const splitted = name.split(" ");
  if (splitted.length === 1) {
    return `${name.charAt(0) + name.charAt(1)}`.toUpperCase();
  }
  return `${name.charAt(0)}${splitted[splitted.length - 1].charAt(0)}`.toUpperCase();
};

export const generateOverlappingTooltip = (overlapping: IShiftOverlap) => {
  return `${lg.überschneidung_mit_schicht} ${overlapping.overlapsWith[0].jobPositionName} - ${overlapping.overlapsWith[0].branchName}`;
};

export const isDayAHoliday = (day: string, holidays: IHoliday[], branchId?: string) => {
  return !!holidays.find((h) => h.date === day && (!branchId || !h.branchId || branchId === h.branchId));
};

export const getotherShiftsOnSameDayTooltip = (
  shifts: IShift[],
  branchId: string | undefined | null,
  branchMap: Map<IBranch>,
  jobPosMap: Map<IJobPosition>
) => {
  const shift = shifts[0];
  if (!shift) {
    return "";
  }
  const showBranchName = shift.branchId !== branchId || !branchId;
  const branchName = branchMap[shift.branchId]?.name;
  const jobPositionName = jobPosMap[shift.jobPositionId]?.name;
  const timeRange = `${shift.startTime} - ${shift.endTime}`;

  return `Schicht am selben Tag: ${showBranchName ? branchName : jobPositionName} | ${timeRange}`;
};

export const isShiftInPublishedWeek = (publishedWeeks: IPublishedWeek[], shift: IShift) => {
  return !!publishedWeeks.find(
    (w) => w.startDate <= shift.date && w.endDate >= shift.date && w.branchId === shift.branchId && w.published
  );
};

export const isWeekPublished = (publishedWeeks: IPublishedWeek[], date: string, selectedBranchId?: string) => {
  return publishedWeeks.find((w) => w.startDate <= date && w.endDate >= date && selectedBranchId === w.branchId)
    ?.published;
};

export const handleScrolledDownClassIfNeeded = throttle((add: boolean, className: string) => {
  const classList = document.getElementsByClassName(className).item(0)?.classList!;
  if (!classList) {
    return; // in some edge-cases the classList seems to be undefined
  }
  if (add) {
    if (!classList.contains("scrolledDown")) {
      classList.add("scrolledDown");
    }
  } else {
    classList.remove("scrolledDown");
  }
}, 30);

export const getMonthMomentDates = (startDate: string) => {
  const daysInMonth = toMoment(startDate).daysInMonth();
  return [...Array(daysInMonth).keys()].map((i) => {
    return toMoment(startDate).add(i, "days");
  });
};

export const reorder = <T>(list: Array<T>, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const orderUsers = (users: IUserFull[], _branchId: string | null, alphabetically?: boolean) => {
  if (alphabetically) {
    return orderBy(users, (u) => u.name.toLocaleLowerCase());
  }

  return orderBy(users, (u) => {
    const branchId = _branchId || "none";
    return u.sortIndexByBranch && typeof u.sortIndexByBranch[branchId] === "number"
      ? u.sortIndexByBranch[branchId]
      : u.createdAt;
  });
};

export const getDouplicates = (arr: any[]) => {
  return _.uniq(_.filter(arr, (val, i, iteratee) => _.includes(iteratee, val, i + 1)));
};

export const waitAsync = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(() => resolve(true), milliseconds));
};
