import { IThreadInfo } from "../../shared/entities/IThreadInfo";
import { IUser, IUserFull } from "../../shared/entities/IUser";
import { IUserThreadInfo } from "../../shared/entities/IUserThreadInfo";
import { Map } from "../../shared/types/general";
import moment, { Moment } from "moment";
import { simpleDateToMoment, toDoubleDigit } from "../../shared/helpers/timeHelpers";
import { SDateFormat } from "../../shared/helpers/SimpleTime";
import { decryptUser, getUserName } from "../../shared/helpers/userHelpers";
import AvatarDefaultImageSource from "./avatar-default-image.png";
import GroupAvatarDefaultImageSource from "./group-avatar-default-image.png";

export const getChatTitle = (threadInfo: IUserThreadInfo | IThreadInfo, userMap: Map<IUser>, currentUserId) =>
  threadInfo.userIds.length === 2
    ? getUserName(userMap[threadInfo.userIds.find((uId) => uId !== currentUserId)!])
    : threadInfo.title;

export const getChatDateText = (timestamp: number, now: Moment) => {
  const m = moment(timestamp);
  const base = m.clone().set("hours", 0).set("minutes", 0);
  const daysDiff = now.diff(base, "days");
  if (daysDiff === 0) {
    return m.format("HH:mm");
  } else if (daysDiff === 1) {
    return lg.gestern;
  } else if (daysDiff < 6) {
    return m.format("dddd");
  } else {
    return m.format("L");
  }
};

const daysMilliseconds = 1000 * 60 * 60 * 24;

export const timestampToSimpleTime = (timestamp: number | string) => {
  const d = new Date(typeof timestamp === "number" ? timestamp : parseInt(timestamp));
  const hours = toDoubleDigit(d.getHours());
  const minutes = toDoubleDigit(d.getMinutes());
  const timeString = hours + ":" + minutes;
  return timeString;
};

export const simpleDateToChatText = (simpleDate: string, now: Moment) => {
  const m = simpleDateToMoment(simpleDate);
  if (m.isSame(now, "day")) {
    return lg.heute;
  } else if (m.clone().add(-1, "day").isSame(now, "day")) {
    return lg.gestern;
  } else {
    return m.format("dddd DD. MMM YYYY");
  }
};

export const timestampToSimpleDate = (timestamp: number | string) => {
  const date = new Date(typeof timestamp === "number" ? timestamp : parseInt(timestamp));
  return `${date.getFullYear()}-${date.getMonth() + 1}-${toDoubleDigit(date.getDate())}`;
};

export const getImageOfThread = (userThreadInfo: IUserThreadInfo, userMap: Map<IUser>, currentUserId: string) => {
  const isGroupChat = !!userThreadInfo?.title?.length;
  let photoUrl: any = AvatarDefaultImageSource;
  if (isGroupChat) {
    photoUrl = userThreadInfo.photoUrl || GroupAvatarDefaultImageSource;
  } else {
    const otherUser = userMap[userThreadInfo.userIds.find((uId) => uId !== currentUserId)!];
    if (otherUser) {
      const otherUserPhoto = decryptUser(otherUser)?.photoUrl;
      if (otherUserPhoto) {
        photoUrl = otherUserPhoto;
      }
    }
  }

  return photoUrl;
};
