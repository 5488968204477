import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import AvFormField from "../../../../components/AvFormField/AvFormField";
import { Input, Checkbox, Button, notification, InputNumber, Icon } from "antd";
import { branchRepository } from "../../../../repositories/branchRepository";
import { userRepository } from "../../../../repositories/userRepository";
import TZModal from "../../../../components/TZModal/TZModal";
import * as Sentry from "@sentry/browser";
import { closeModal, openModal } from "../../../../actions/modal";
import { busyInjector, BusyInjectorProps } from "../../../../components/BusyInjector/BusyInjector";
import { LocationSearchInput } from "./LocationSearchInput";
import { selectActiveUsers, selectActiveUsersFull } from "../../../../selectors/ActiveUserSelectors";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import { IWorkSpace } from "../../../../shared/entities/IWorkSpace";
import { IBranch } from "../../../../shared/entities/IBranch";
import { IUser } from "../../../../shared/entities/IUser";
import moment from "moment";
import "./styles.scss";
import _, { lowerFirst } from "lodash";
import { GeofencingSpecificationModal } from "./GeofencingSpecificationModal/GeofencingSpecificationModal";
import { IRuleSpecification } from "../../../../shared/entities/IRuleSpecification";
import { isRuleApplyingToUser } from "../../../../shared/helpers/settingsHelpers";
import { shiftRepository } from "../../../../repositories/shiftRepository";
import { FarFuture } from "../../../Users/UserDetailsModal/UserGeneralTab/UserGeneralTab";
import { selectUsersFull } from "../../../../selectors/usersFullSelector";
import { selectUserFullMap } from "../../../../selectors/userFullMapSelector";
import UserPickerList from "../UserPickerList/UserPickerList";
import AvColorPicker from "../../../../components/AvColorPicker/AvColorPicker";
import { pastelColors, strongColors } from "../../../../frontend-core/helpers/colors";
import { getBranchColor } from "../../../../frontend-core/helpers/frontendHelpers";
import { decimalSeparatorLocal } from "../../../../helpers/dateFormatHelper";
import { dummyColors } from "../../../../constants/styling";
import { selectWorkSpaces } from "../../../../selectors/_workSpacesSelector";

const DEFAULT_CLOCKIN_RADIUS = 30; // in meters

const mapStateToProps = (state: AppState, props: OwnProps) => {
  return {
    branches: state.data.branches,
    isV2: state.data.tenantInfo.isV2,
    users: selectUsersFull(state),
    userMap: selectUserFullMap(state),
    workSpaces: selectWorkSpaces(state),
    activeUsers: selectActiveUsersFull(state, moment().format(SDateFormat)),
  };
};

export type BasicWorkSpace = {
  name: string;
  color: string;
  id: string;
  isInactive?: boolean;
};

type State = {
  id: string;
  name: string;
  color?: string;
  userNameFilter: string;
  addedUserIds: string[];
  removedUserIds: string[];
  isLoading: boolean;
  address?: string;
  googlePlaceId?: string;
  addressIsBeingEdited?: boolean;
  isClockingEnabled?: boolean;
  allowMobileClocking?: boolean;
  isGeofencingDisabled?: boolean;
  geofencingUserSpecification?: IRuleSpecification;
  locationLatitude?: string;
  locationlongitude?: string;
  geofencingMeterRadius?: number;
  showGeoRadiusWarning: boolean;
  locationSearchIsMounted: boolean;
};

type OwnProps = {
  branch?: IBranch;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class BranchPopup extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const isV2 = props.isV2;
    const isCreationMode = !props.branch;
    const nextBranchIndex = props.branches.length + 1;
    const nextBranchColor = [...strongColors, ...pastelColors][nextBranchIndex]; // generating color by branchId ( this was just for backward compatibility, because color was added later on )

    this.state = {
      id: props.branch?.id || `b${nextBranchIndex}`,
      name: props.branch?.name || "",
      color: props.branch?.color || nextBranchColor,
      userNameFilter: "",
      addedUserIds: [],
      removedUserIds: [],
      isLoading: false,
      address: props.branch?.address,
      googlePlaceId: props.branch?.googlePlaceId,
      locationLatitude: props.branch?.locationLatitude,
      locationlongitude: props.branch?.locationlongitude,
      geofencingMeterRadius: props.branch?.geofencingMeterRadius,
      isGeofencingDisabled: isCreationMode ? true : props.branch?.isGeofencingDisabled,
      addressIsBeingEdited: false,
      isClockingEnabled: isCreationMode ? isV2 : props.branch?.isClockingEnabled,
      allowMobileClocking: isCreationMode ? isV2 : props.branch?.allowMobileClocking,
      geofencingUserSpecification: props.branch?.geofencingUserSpecification,
      showGeoRadiusWarning: false,
      locationSearchIsMounted: true, // for a hacky way to re-mounte the component > so it reads the initial-state
    };
  }

  submitClicked = async () => {
    const freshBranch: IBranch = {
      ...this.props.branch,
      id: this.state.id,
      name: this.state.name,
      color: this.state.color,
      googlePlaceId: this.state.googlePlaceId,
      address: this.state.address,
      locationLatitude: this.state.locationLatitude,
      locationlongitude: this.state.locationlongitude,
      geofencingMeterRadius: this.state.geofencingMeterRadius || DEFAULT_CLOCKIN_RADIUS,
      isClockingEnabled: this.state.isClockingEnabled,
      allowMobileClocking: this.state.allowMobileClocking,
      isGeofencingDisabled: this.state.isGeofencingDisabled,
      geofencingUserSpecification: this.state.geofencingUserSpecification,
    };

    Sentry.addBreadcrumb({
      message: "saved branch",
      data: { branch: freshBranch },
    });

    this.props.branch
      ? await this.props.dispatch(branchRepository.update(freshBranch as IBranch))
      : await this.props.dispatch(branchRepository.create(freshBranch));

    if (this.state.addedUserIds.length || this.state.removedUserIds.length) {
      await this.saveUserBranchIds();
    }

    this.props.dispatch(closeModal());
  };

  saveUserBranchIds = async () => {
    const { addedUserIds, removedUserIds, id } = this.state;
    const { userMap } = this.props;
    const userBranchesMapper = {};
    addedUserIds.forEach((uid) => (userBranchesMapper[uid] = _.uniq([...userMap[uid].branchIds, id])));
    removedUserIds.forEach((uid) => (userBranchesMapper[uid] = userMap[uid].branchIds.filter((bid) => bid !== id)));
    await this.props.dispatch(userRepository.batchUpdatBranches(userBranchesMapper));
  };

  isUserInBranch = (userId: string) =>
    (this.state.addedUserIds.includes(userId) || this.props.userMap[userId].branchIds.includes(this.state.id)) &&
    !this.state.removedUserIds.includes(userId);

  toggledUser = (userId: string) => {
    const user = this.props.userMap[userId];
    const isAdding = !this.isUserInBranch(userId);
    if (!isAdding && user.branchIds.includes(this.props.branch?.id!) && user.branchIds.length === 1) {
      notification.info({ message: lg.mitarbeiter_brauchen_mindestens_einen_standort });
      return;
    }

    const removedUserIds = this.state.removedUserIds.filter((uid) => user.id !== uid);
    const addedUserIds = this.state.addedUserIds.filter((uid) => user.id !== uid);
    isAdding ? addedUserIds.push(user.id) : removedUserIds.push(user.id);

    this.setState({ removedUserIds, addedUserIds });
  };

  setBranchInactive = async () => {
    const { branch, activeUsers, load, dispatch, setLoading } = this.props;
    const usersOfBranch = activeUsers.filter((u) => branch && u.branchIds.includes(branch.id));
    const today = moment().format(SDateFormat);
    const branchId = branch!.id;

    if (usersOfBranch.length) {
      notification.error({
        message: lg.um_einen_standort_zu_löschen_müssen_vorher_alle_zugewiesenen_mitarbeiter_entfernt_werden,
      });
      return;
    }

    setLoading("setInactive", true);
    // delteting all future shifts of the branch
    const futureShiftsOfBranch = await dispatch(
      shiftRepository.fetchMany({
        filter: ["branchId_date", "between", [`${branchId}_${today}`, `${branchId}_${FarFuture}`]],
      })
    );
    await dispatch(shiftRepository.removeList(futureShiftsOfBranch.map((s) => s.id)));
    await dispatch(branchRepository.update({ ...branch!, isInactive: true }));

    setLoading("setInactive", false);
    this.props.dispatch(closeModal());
  };

  setBranchDisabled = async (disable: boolean) => {
    await this.props.dispatch(branchRepository.update({ ...this.props.branch!, isDisabled: disable }));
    disable ? notification.info({ message: lg.deaktiviert }) : notification.success({ message: lg.reaktiviert });
    this.props.dispatch(closeModal());
  };

  geofencingUserSpecificationClicked = () => {
    this.props.dispatch(
      openModal(GeofencingSpecificationModal, {
        ruleSpecification: this.state.geofencingUserSpecification,
        onComplete: (spec) => {
          this.setState({ geofencingUserSpecification: spec });
        },
      })
    );
  };

  onLocationBlur = () => {
    const { address, googlePlaceId, locationLatitude, locationlongitude } = this.state;
    this.setState({
      address,
      googlePlaceId,
      locationLatitude,
      locationlongitude,
      addressIsBeingEdited: false,
      locationSearchIsMounted: false,
    });
    setTimeout(() => {
      this.setState({
        locationSearchIsMounted: true,
      });
    });
  };

  render() {
    const { branch, activeUsers, isV2 } = this.props;
    const low = (str: string) => (str ? str.toLowerCase().trim() : "");

    const activeUsersLength = this.props.activeUsers.length;
    const activeUsersOfBranch = activeUsers.filter((user) => this.isUserInBranch(user.id));

    const geofencedUsersLength = this.props.activeUsers.filter((u) =>
      isRuleApplyingToUser(this.state.geofencingUserSpecification, u)
    ).length;

    return (
      <TZModal>
        <TZModal.Head title={branch ? branch.name : lg.neuer_standort}></TZModal.Head>
        <TZModal.Body>
          <div className="BranchPopupMain">
            <div className="fb column leftCol">
              {branch?.isDisabled && (
                <div
                  className="isDisabledInfo"
                  data-rh={lg.deaktivierte_standorte_werden_im_dienstplan_nicht_mehr_angezeit}
                >
                  {lg.deaktiviert}
                </div>
              )}
              <div className="fb row">
                <div className="cell branchNameCell">
                  <AvFormField label={lg.name}>
                    <Input
                      type="text"
                      onChange={(e) => {
                        this.setState({ name: e.target.value });
                      }}
                      value={this.state.name}
                    />
                  </AvFormField>
                </div>
                <div className="cell branchColorCell">
                  <AvFormField label={lg.farbe}>
                    <AvColorPicker
                      miniVersion
                      colors={_.uniq([...dummyColors, this.state.color || "#f5222d"])}
                      value={this.state.color}
                      onChange={(color: string) => this.setState({ color })}
                    />
                  </AvFormField>
                </div>
              </div>
              <div className="locationSearchWrapper" onBlur={this.onLocationBlur}>
                {this.state.locationSearchIsMounted && (
                  <LocationSearchInput
                    setAddressBeingEdited={(v) => this.setState({ addressIsBeingEdited: v })}
                    initialAddress={this.state.address}
                    initialPlaceId={this.state.googlePlaceId}
                    onClear={() => {
                      this.setState({
                        address: undefined,
                        googlePlaceId: undefined,
                        locationLatitude: undefined,
                        locationlongitude: undefined,
                      });
                    }}
                    onSelect={(a, p, latLng) =>
                      this.setState({
                        address: a,
                        googlePlaceId: p,
                        locationLatitude: latLng.lat,
                        locationlongitude: latLng.lng,
                      })
                    }
                  />
                )}
              </div>
              {!isV2 && (
                <div className="row checkboxRow">
                  <Checkbox
                    checked={this.state.isClockingEnabled}
                    onChange={(val) => {
                      this.setState({
                        isClockingEnabled: !this.state.isClockingEnabled,
                        allowMobileClocking: false,
                        isGeofencingDisabled: true,
                      });
                    }}
                  >
                    {lg.in_diesem_standort_stempeluhr_zeiterfassung_aktivieren()}
                  </Checkbox>
                </div>
              )}
              {this.state.isClockingEnabled && (
                <div className="row checkboxRow" style={{ marginTop: 12 }}>
                  <Checkbox
                    checked={this.state.allowMobileClocking}
                    onChange={(val) => {
                      const allowMobileClocking = !this.state.allowMobileClocking;
                      this.setState({ allowMobileClocking });
                      // if disabeling mobileClocking -> disable also geofencing
                      !allowMobileClocking && this.setState({ isGeofencingDisabled: true });
                    }}
                  >
                    {lg.arbeitszeit_kann_per_app_gestempelt_werden()}
                  </Checkbox>
                </div>
              )}

              {this.state.allowMobileClocking && (
                <div className="geofencintSettings">
                  <div className="fb row aCenter enableRow">
                    <Checkbox
                      checked={!this.state.isGeofencingDisabled}
                      onChange={(val) =>
                        this.setState({
                          isGeofencingDisabled: !this.state.isGeofencingDisabled,
                        })
                      }
                    >
                      {lg.geofencing_der_mobilen_stempeluhr}
                    </Checkbox>
                    <div
                      className="infoIconWrapper"
                      data-rh={
                        lg.geofencing_aktivieren_um_das_ein_ausstempeln_per_app_nur_in_der_nähe_des_arbeitsplatzes_zu_erlauben
                      }
                    >
                      <Icon type="info-circle" />
                    </div>
                  </div>
                  {!this.state.isGeofencingDisabled && (
                    <div className="fb row aCenter radiusSetting">
                      {lg.entfernung_in_metern_zum_einstempeln}
                      <InputNumber
                        size="small"
                        style={{ marginLeft: "10px", width: 65 }}
                        decimalSeparator={decimalSeparatorLocal}
                        onChange={(v) => {
                          this.setState({
                            geofencingMeterRadius: v,
                            showGeoRadiusWarning: false,
                          });
                          setTimeout(() => {
                            // to delay the warning, so it doesnt confuse during typing
                            if (
                              this.state.geofencingMeterRadius &&
                              this.state.geofencingMeterRadius < DEFAULT_CLOCKIN_RADIUS
                            ) {
                              this.setState({ showGeoRadiusWarning: true });
                            }
                          }, 2000);
                        }}
                        value={this.state.geofencingMeterRadius}
                        min={0}
                        max={99000}
                        placeholder={DEFAULT_CLOCKIN_RADIUS + ""}
                      />
                    </div>
                  )}
                  {!this.state.isGeofencingDisabled && (
                    <div
                      className="fb row aCenter validForUsers"
                      data-rh={lg.mitarbeiter_vom_geofencing_ausschließen}
                      onClick={this.geofencingUserSpecificationClicked}
                    >
                      {geofencedUsersLength === activeUsersLength
                        ? lg.geofencing_gilt_für_alle_mitarbeiter
                        : lg.gilt_für_geofenced_users_active_users_mitarbeiter(geofencedUsersLength, activeUsersLength)}
                    </div>
                  )}
                </div>
              )}
              {this.state.allowMobileClocking && !this.state.isGeofencingDisabled && !this.state.address && (
                <div className="row warningBox" style={{ marginTop: 12 }}>
                  {
                    lg.um_das_einstempeln_per_app_zu_aktivieren_ist_die_adresse_des_standortes_erforderlich_sodass_mitarbeiter_sich_nur_einstempeln_können_wenn_ihr_handy_am_arbeitsplatz_geortet_werden_kann
                  }
                </div>
              )}
              {this.state.showGeoRadiusWarning && (
                <div className="row warningBox" style={{ marginTop: 12 }}>
                  {lg.es_wird_empfohlen_einen_radius_von_mindestens_radius_metern_anzugeben_um_zu_verhindern_dass_sich_mitarbeiter_mit_ungenauer_smartphone_lokalisierung_nicht_einstempeln_können(
                    DEFAULT_CLOCKIN_RADIUS
                  )}
                </div>
              )}
            </div>
            <div className="fb column">
              <div className="actionsRow">
                <div className="fb usersOfBranchList">
                  <div className="fb title">{lg.mitarbeiter_des_standortes}</div>
                  <UserPickerList
                    selectedUserIds={activeUsersOfBranch.map((u) => u.id)}
                    toggleUser={this.toggledUser}
                    selectList={(addedUserIds) => {
                      const removeAll = !addedUserIds.length;
                      // we cant remove users that have this branch as only branch ( every user needs to have at least one branch )
                      const usersWith2Branches = activeUsersOfBranch.filter((u) => u.branchIds.length >= 2);
                      this.setState({
                        addedUserIds,
                        removedUserIds: removeAll ? usersWith2Branches.map((u) => u.id) : [],
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </TZModal.Body>
        <TZModal.Footer isLoading={this.state.isLoading}>
          <div className="branchPopupFooter">
            {/* {!isOwnUserEdit && !this.isCreationMode && (
              <div
                className="deactivateBtn linkBtn"
                id="activate-deactivate-user"
                children={lastWorkDay ? lg.aktivieren : lg.deaktivieren}
                onClick={() =>
                  lastWorkDay
                    ? this.props.dispatch(this.reactivateUser)
                    : this.props.dispatch(openModal(UserDeactivateModal, { user: user as IUser }))
                }
              /> */}

            {!!this.props.branch && (
              <div
                id="delete-branch"
                className="deleteBtn"
                style={{ marginRight: 8 }}
                onClick={this.setBranchInactive}
                data-rh={lg.löschen}
                children={<Icon type={this.props.isLoading("setInactive") ? "loading" : "delete"} />}
              />
            )}
            {!!this.props.branch && (
              <div
                id="disable-branch"
                className="deleteBtn"
                style={{ marginRight: "auto" }}
                onClick={() => this.setBranchDisabled(!branch?.isDisabled)}
                data-rh={branch?.isDisabled ? null : lg.deaktivierte_standorte_werden_im_dienstplan_nicht_mehr_angezeit}
                data-rh-at="right"
                children={branch?.isDisabled ? lg.reaktivieren : lg.deaktivieren}
              />
            )}
            <Button
              id="save-branch"
              type="primary"
              disabled={
                !this.state.name ||
                this.state.addressIsBeingEdited ||
                (!this.state.isGeofencingDisabled && !this.state.address)
              }
              onClick={async () => this.props.load(this.props.dispatch(this.submitClicked), "submitting")}
              loading={this.props.isLoading("submitting")}
            >
              {lg.Speichern}
            </Button>
          </div>
        </TZModal.Footer>
      </TZModal>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(busyInjector(BranchPopup));
