import { AbsenceTypeCode, AbsenceEarning } from "./IAbsenceType";
import { Raw } from "./IResource";

export enum AbsenceType {
  vacation = "vacation",
  illness = "illness",
  custom = "custom",
}

export enum AbsenceStatus {
  requested = "requested",
  active = "active",
}

export type EffectiveAbsenceDays = {
  [year: string]: number;
};

export type IAbsence = {
  id: string;
  userId: string;
  startDate: string;
  endDate: string;
  startsHalfDay?: boolean;
  endsHalfDay?: boolean;
  status: AbsenceStatus;
  typeId: string;
  note?: string;
  effectiveDaysOverride?: EffectiveAbsenceDays;
  typeCode: AbsenceTypeCode;
  requestedAt?: string;
  earning: AbsenceEarning;
  acceptedAt?: number;
  acceptedBy?: string;
  createdLog?: string;
  editedLog?: string;
};

export type IAbsenceRaw = Raw<IAbsence>;

export type IAbsenceExtended = IAbsence & {
  effectiveDays: EffectiveAbsenceDays;
};
