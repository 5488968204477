import React from "react";
import cn from "classnames";
import _, { Dictionary } from "lodash";
import { useDrop } from "react-dnd";
import { AvIcon } from "../../../../../components/AvIcon/AvIcon";
import {
  filterAvailabilitiesForDate,
  generateAvailabilityText,
  getUnavailableDuringShift,
} from "../../../../../helpers/workingTimeHelpers";
import { notification } from "antd";
import "./styles.scss";
import { moveShift, cloneShift } from "../../../../../actions/shift";
import { closestWithAttribute, getotherShiftsOnSameDayTooltip } from "../../../../../helpers/general";
import { shfitDragActionTypes } from "../../../../../reducers/ui/shifts/roster/draggingShift";
import { IShift } from "../../../../../shared/entities/IShift";
import { IBranch } from "../../../../../shared/entities/IBranch";
import { AbsenceStatus, IAbsence } from "../../../../../shared/entities/IAbsence";
import { AbsenceTypeCode, IAbsenceType } from "../../../../../shared/entities/IAbsenceType";
import { tTimetoMinutes, minutesToDuration } from "../../../../../shared/helpers/timeHelpers";
import { IJobPosition } from "../../../../../shared/entities/IJobPosition";
import DraggableShift from "./DraggableShift";
import { ShiftDropArea } from "../../../../../components/ShiftDropArea/ShiftDropArea";
import { ITracking } from "../../../../../shared/entities/ITracking";
import { IRosterSettings } from "../../../../../shared/entities/IRosterSettings";
import { ITimeClocking } from "../../../../../shared/entities/ITimeClocking";
import { IChangeRequest } from "../../../../../shared/entities/IChangeRequest";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";
import { IShiftHandOverRequest } from "../../../../../shared/entities/IShiftHandOverRequest";
import { IAvailability } from "../../../../../shared/entities/IAvailability";
import { UserInfo } from "../../../../../shared/helpers/UserInfo";
import { boolean } from "firebase-bolt/lib/ast";
import { IShiftOverlap } from "../../../../../shared/entities/IShiftOverlap";
import { IUser } from "../../../../../shared/entities/IUser";
import { IWorkSpace } from "../../../../../shared/entities/IWorkSpace";
import { Map } from "../../../../../shared/types/general";
import { IContract, WeekDay, WorkInterval } from "../../../../../shared/entities/IContract";
import { IShiftAddress } from "../../../../../shared/entities/IShiftAddress";
import { AbsenceIcon } from "../../../../../components/AbsenceIcon/AbsenceIcon";
import { setOpenShiftsCollapsed } from "../../../../../reducers/ui/shifts/roster/isOpenShiftsCollapsed";
import { setRequiredShiftsCollapsed } from "../../../../../reducers/ui/shifts/roster/isRequiredShiftsCollapsed";

const getSlotKey = (date: string, userId?: string, jobPositionId?: string) => {
  return [date, userId, jobPositionId].filter((s) => !!s).join("_");
};

type Props = {
  date: string;
  weekDay: WeekDay;
  userId?: string;
  jobPositionId?: string;
  shifts: IShift[];
  otherShiftsOnSameDay: IShift[];
  disableDnD?: boolean;
  recentModifiedShifts: string[];
  contract?: IContract;
  dispatch: DispFn;
  absencesByUser: Map<IAbsence[]>;
  absenceTypeMap: Map<IAbsenceType>;
  sessionInfo: UserInfo;
  selectedBranchId: string;
  handOverRequestMap: Map<IShiftHandOverRequest>;
  changeRequestMap: Map<IChangeRequest>;
  branchMap: Map<IBranch>;
  addressMap: Map<IShiftAddress>;
  trackingMap: Map<ITracking>;
  timeClockingMap: Map<ITimeClocking>;
  availabilitiesByUser: Map<IAvailability[]>;
  groupByJobPosition: boolean;
  rosterSettings: IRosterSettings;
  jobPosMap: Map<IJobPosition>;
  workSpaceMap: Map<IWorkSpace>;
  overlapMap?: { [shiftId: string]: IShiftOverlap };
  usersMap: Map<IUser>;
  showQuota?: boolean;
  showOpenShiftsCount?: boolean;
  showRequirementCount?: boolean;
  labourLawWarningMap?: Map<string>;
  isRequirement?: boolean;
  assignedShiftsMap: Map<number>;
  canEditOwnShifts?: boolean;
  isV2?: boolean;
  isInFuture?: boolean;
};

export const Slot = React.memo((props: Props) => {
  // // Create DnD Drop Hook
  const isV2 = props.isV2;
  const shifts = props.shifts;
  const [{ isOver: isOverMoveToDropArea }, moveToDropRef] = useDrop({
    accept: "SHIFT",
    canDrop: (item) => {
      const hasClocking = !!props.timeClockingMap[item.id];
      const hasTracking = !!props.trackingMap[item.id];
      if (hasClocking || hasTracking) {
        notification.error({
          message: lg.eine_schicht_mit_einer_zeiterfassung_kann_nicht_verschoben_werden,
        });
      }
      return !hasClocking && !hasTracking;
    },

    drop: (item: IShift) => {
      props.dispatch({ type: shfitDragActionTypes.END });
      props.dispatch(moveShift(item.id, props.date, props.userId, props.jobPositionId, props.isRequirement));
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const [{ isOver: isOverCloneDropArea }, cloneDropRef] = useDrop({
    accept: "SHIFT",
    drop: (item: IShift) => {
      return props.dispatch(cloneShift(item.id, props.date, props.userId, props.jobPositionId, props.isRequirement));
    },
    collect: (monitor) => {
      return {
        isOver: !!monitor.isOver(),
      };
    },
  });

  const [{ isOverSlot }, slotRef] = useDrop({
    accept: "SHIFT",
    collect: (monitor) => ({
      isOverSlot: !!monitor.isOver(),
    }),
  });

  const { rosterSettings, sessionInfo } = props;
  const canManage = sessionInfo.hasManagerPermissions();
  const sessionUserId = sessionInfo.user.id;
  const isSessionUserSlot = sessionUserId === props.userId;
  const showAvailableTimes = props.rosterSettings.expandAvailabilitesInWeeklyRoster;
  const isOpenShiftSlot = !props.userId;
  const showComment =
    canManage || isSessionUserSlot || isOpenShiftSlot || rosterSettings.employeesCanSeeShiftCommentsOfCoworker;

  let absence: IAbsence | undefined;
  if (props.userId && props.absencesByUser[props.userId]?.length) {
    const userAbsences = props.absencesByUser[props.userId];
    absence = userAbsences.find(
      (a) => a.status === AbsenceStatus.active && a.startDate <= props.date && a.endDate >= props.date
    );
  }

  const userAvailabilities =
    props.userId && (canManage || isSessionUserSlot) && props.availabilitiesByUser[props.userId]
      ? filterAvailabilitiesForDate(props.availabilitiesByUser[props.userId], props.date)
      : [];

  const unavailabilities = userAvailabilities.filter((a) => !a.isAvailable);
  const availabilities = userAvailabilities.filter((a) => a.isAvailable);

  const dailyQuota = props.contract?.dailyQuota[props.weekDay];
  const hasWeeklyContract = props.contract?.interval === WorkInterval.weekly;

  const shiftsSorted = _.orderBy(shifts, [
    (s) => tTimetoMinutes(s.startTime),
    "duration",
    "breakMinutes",
    (s) => props.jobPosMap[s.jobPositionId]?.sortIndex,
    (s) => s.workSpaceId || "",
    (s) => s.id,
  ]);

  const openShiftsCount =
    props.showOpenShiftsCount && shifts.length && _.sumBy(shifts, (s) => s.requiredUsersAmount || 0);

  const requiredShiftsCount =
    props.showRequirementCount && shifts.length && _.sumBy(shifts, (s) => s.requiredUsersAmount || 0);

  const assignedShiftsCount =
    props.showRequirementCount && shifts.length && _.sumBy(shifts, (s) => props.assignedShiftsMap?.[s.id] || 0);

  const clickable = isV2
    ? !props.isInFuture && (canManage || props.canEditOwnShifts)
    : canManage || props.canEditOwnShifts;

  return (
    <div
      id={`grid-slot-${props.date}-${props.userId}-${props.jobPositionId}-${props.isRequirement}`}
      data-type="grid-slot"
      data-slot-id={getSlotKey(props.date, props.userId, props.jobPositionId)}
      data-is-requirement-slot={props.isRequirement || null}
      data-is-disabled={!clickable}
      className={cn({
        slotMain: true,
        // hasAbsence: absence,
        cell: true,
        isOpenShiftSlot: !props.userId,
        isRequirementSlot: props.isRequirement,
        isUserShiftSlot: props.userId,
        isOverSlot: isOverSlot,
        hasShift: !!shifts.length,
        clickable,
        isInFutureGrey: isV2 && props.isInFuture,
        showOpenShiftsCount: (props.showOpenShiftsCount || props.showRequirementCount) && !!shifts.length,
      })}
      ref={slotRef}
    >
      {!isV2 && (
        <ShiftDropArea
          isOverMoveToDropArea={isOverMoveToDropArea}
          isOverCloneDropArea={isOverCloneDropArea}
          cloneDropRef={cloneDropRef}
          moveToDropRef={moveToDropRef}
        />
      )}
      {props.showQuota && props.contract && !isV2 && (
        <div className={cn({ quota: true, freeDay: !dailyQuota })}>
          {dailyQuota ? (hasWeeklyContract ? minutesToDuration(dailyQuota) : lg.arbeit) : lg.frei}
        </div>
      )}
      {!shifts.length && (!!userAvailabilities.length || !!props.otherShiftsOnSameDay.length) && !isV2 && (
        <div className="indicatorWrapper availabilityIndicators">
          {!!props.otherShiftsOnSameDay.length && !absence && (canManage || isSessionUserSlot) && (
            <div
              className="indicator"
              style={{ backgroundColor: "#0083ff" }}
              data-rh={getotherShiftsOnSameDayTooltip(
                props.otherShiftsOnSameDay,
                props.selectedBranchId,
                props.branchMap,
                props.jobPosMap
              )}
            />
          )}
          {!!availabilities.length && (
            <div
              className={showAvailableTimes ? "indicatorExpanded" : "indicator"}
              style={{ backgroundColor: showAvailableTimes ? "#ffffff00" : "#18c756", color: "#3cc38c" }}
              data-rh={generateAvailabilityText(availabilities)}
              children={showAvailableTimes ? generateAvailabilityText(availabilities, { minimal: true }) : undefined}
            />
          )}
          {!!unavailabilities.length && (
            <div
              className={showAvailableTimes ? "indicatorExpanded" : "indicator"}
              style={{ backgroundColor: showAvailableTimes ? "#ffffff00" : "#f5222d", color: "#f53e3e" }}
              data-rh={generateAvailabilityText(unavailabilities)}
              children={showAvailableTimes ? generateAvailabilityText(unavailabilities, { minimal: true }) : undefined}
            />
          )}
        </div>
      )}
      {!!shifts.length && !props.showOpenShiftsCount && !props.showRequirementCount && (
        <div className="shiftsWrapper">
          {shiftsSorted.map((shift) => {
            return (
              <DraggableShift
                userUnavailable={!!unavailabilities.length && !!getUnavailableDuringShift(shift, unavailabilities)}
                data={shift}
                key={shift.id}
                disableDnD={!canManage || props.disableDnD || isV2}
                absence={absence}
                userId={props.userId}
                wasRecentlyModified={props.recentModifiedShifts.includes(shift.id)}
                handOverRequest={props.handOverRequestMap[shift.id]}
                changeRequest={props.changeRequestMap[shift.id]}
                tracking={props.trackingMap[shift.id]}
                clocking={props.timeClockingMap[shift.id]}
                showJobPosition={!isV2 && (!shift.userId || !props.groupByJobPosition)}
                isGroupedByJobPos={props.groupByJobPosition}
                showBreakes={props.rosterSettings.showBreaksInWeekPlan && !shift.isRequirement}
                showDurations={props.rosterSettings.showDurationsInWeekPlan && !shift.isRequirement}
                dispatch={props.dispatch}
                jobPosMap={props.jobPosMap}
                workSpaceMap={props.workSpaceMap}
                usersMap={props.usersMap}
                branchMap={props.branchMap}
                addressMap={props.addressMap}
                shiftOverlap={props.overlapMap && props.overlapMap[shift.id]}
                canManage={canManage}
                isOwnShift={sessionUserId === shift.userId}
                showBranch={!props.selectedBranchId}
                labourLawWarning={props.labourLawWarningMap?.[shift.id]}
                assignedUsersAmount={(shift.isRequirement && props.assignedShiftsMap[shift.id]) || 0}
                isV2={isV2}
                showComment={showComment}
              />
            );
          })}
        </div>
      )}
      {props.showOpenShiftsCount && !!openShiftsCount && (
        <div
          className="shiftCountDisplay"
          data-rh={openShiftsCount + " " + lg.offene + " " + (openShiftsCount === 1 ? lg.schicht : lg.schichten)}
          onClick={() => props.dispatch(setOpenShiftsCollapsed(false))}
        >
          {openShiftsCount}
        </div>
      )}
      {props.showRequirementCount && !!requiredShiftsCount && (
        <div
          className={cn({
            shiftCountDisplay: true,
            isRequirement: true,
            isRequirementComplete: (assignedShiftsCount || 0) >= (requiredShiftsCount || 0),
          })}
          onClick={() => props.dispatch(setRequiredShiftsCollapsed(false))}
        >
          {assignedShiftsCount + " / " + requiredShiftsCount}
        </div>
      )}
      {absence && (
        <div className="absence fb aCenter jCenter">
          <AbsenceIcon
            typeCode={absence.typeCode}
            color={props.absenceTypeMap[absence.typeId].color}
            style={{ fontSize: "28px" }}
          />
        </div>
      )}
      {(canManage || props.canEditOwnShifts) && !!props.shifts.length && (
        <div
          className="addShiftButton"
          data-type="grid-slot"
          data-slot-id={getSlotKey(props.date, props.userId, props.jobPositionId)}
          data-is-requirement-slot={props.isRequirement || null}
        >
          +
        </div>
      )}
    </div>
  );
});
