import React, { PureComponent } from "react";
import { VAC_USER_COLUMN_WIDTH } from "../../../EntitlementHead/EntitlementHead";
import { IUserFull } from "../../../../../../shared/entities/IUser";
import cn from "classnames";
import "./styles.scss";

type Props = {
  user: IUserFull;
  remainingDaysPrevYear?: number;
  year: number;
  overtakeRemainingDays: () => void;
  isOvertakePossible: boolean;
};

export default class EntitlementUserCell extends PureComponent<Props, {}> {
  render() {
    const { user, remainingDaysPrevYear, year, isOvertakePossible, overtakeRemainingDays } = this.props;

    return (
      <div className="vacAccountUserCellMain" style={{ width: VAC_USER_COLUMN_WIDTH }}>
        <div className="fb userName">{user.name}</div>
        {remainingDaysPrevYear && (
          <div
            className={cn({ remainingDays: true, isClickable: !!isOvertakePossible })}
            data-rh={`${lg.resturlaub} ${year - 1} ${isOvertakePossible ? ` / ${lg.per_klick_uebernehmen}` : ""}`}
            data-rh-at="right"
            onClick={() => isOvertakePossible && overtakeRemainingDays()}
          >
            {remainingDaysPrevYear}
          </div>
        )}
      </div>
    );
  }
}
