import React from "react";
import { connect } from "react-redux";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";
import { AppState } from "../../types/AppState";

import { Button, Input } from "antd";

import { dummyColors } from "../../constants/styling";
import _ from "lodash";
import { Raw } from "../../shared/entities/IResource";
import { IJobPosition } from "../../shared/entities/IJobPosition";
import { busyInjector, BusyInjectorProps } from "../../components/BusyInjector/BusyInjector";
import { v4 as uuid } from "uuid";
import { SetupWizardHead } from "./SetupWizardHead";

const mapStateToProps = (state: AppState) => ({});

type OwnProps = {
  setJobPositions: (jobPositions: IJobPosition[]) => void;
  jobPositions: IJobPosition[];
  changeStep: (n: number) => void;
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class jobPositionsStep extends React.PureComponent<Props> {
  componentDidUpdate() {
    this.fillFirstEntry();
  }

  fillFirstEntry = () => {
    if (this.props.jobPositions.length === 0) {
      this.props.setJobPositions([this.getEmptyJobPosition()]);
    }
  };

  componentDidMount() {
    this.fillFirstEntry();
  }

  getEmptyJobPosition = () => {
    const alreadyUsedColors = this.props.jobPositions.map((j) => j.color);
    const potentialColors =
      this.props.jobPositions.length >= dummyColors.length ? dummyColors : _.difference(dummyColors, alreadyUsedColors);

    return {
      name: "",
      color: _.sample(potentialColors) as string,
      sortIndex: this.props.jobPositions.length,
      id: "p" + (this.props.jobPositions.length + 1),
    };
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = +(e?.target?.dataset?.index as string);
    const next = [...this.props.jobPositions];
    next[index] = {
      ...next[index],
      [e.target.name]: e.target.value,
    };
    this.props.setJobPositions(next);
  };

  removeEntry = (id: string) => {
    const { jobPositions } = this.props;
    const jobPositionsClean = jobPositions.filter((j) => j.id !== id);
    const next = jobPositionsClean.map((jp, i) => ({ ...jp, id: "p" + (i + 1) }));
    this.props.setJobPositions(next);
  };

  render() {
    const { jobPositions } = this.props;
    return (
      <>
        <div className="content jobPositionStep">
          <SetupWizardHead />
          <div className="stepTitle">{lg.rollen_anlegen}</div>
          <div className="wizMiniDesc">{lg.zum_beispiel_leitung_koch_lieferant}</div>
          <div className="fb column">
            {jobPositions.map((jobPos, i) => {
              return (
                <div key={i}>
                  <div className="fb row" style={{ marginBottom: 10 }}>
                    <Input
                      type="text"
                      onChange={this.handleChange}
                      value={jobPos.name}
                      // placeholder="zum Beispiel Koch"
                      name={"name"}
                      data-index={i}
                      autoFocus
                    />
                    {
                      <Button
                        id="registration-job-position-remove"
                        icon="delete"
                        style={{
                          marginLeft: 10,
                          flexShrink: 0,
                          visibility: i === 0 ? "hidden" : "visible",
                        }}
                        onClick={() => this.removeEntry(jobPos.id)}
                        type="danger"
                      />
                    }
                  </div>
                </div>
              );
            })}
            <div className="fb row">
              <Button
                id="registration-job-position-add"
                children={lg.hinzufügen}
                icon="plus"
                disabled={!jobPositions.length || !jobPositions[jobPositions.length - 1].name}
                onClick={() => this.props.setJobPositions([...jobPositions, this.getEmptyJobPosition()])}
              />
            </div>
          </div>
        </div>
        <div className="footer fb jBetween row">
          <Button
            onClick={() => {
              this.props.setJobPositions(jobPositions.filter((jp) => jp.name));
              setTimeout(() => this.props.changeStep(3));
            }}
            disabled={jobPositions.some((jp, i) => !jp.name && (i !== jobPositions.length - 1 || i === 0))}
            type="primary"
            id="setup_wizard_job_positions_next_setp"
            children={lg.weiter}
            size="large"
            icon="arrow-right"
          />
        </div>
      </>
    );
  }
}

export default connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(busyInjector(jobPositionsStep));
