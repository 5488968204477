import { pushNoteToDb } from "./../shared/helpers/dbTransforms";
import { IPushNote, IPushNoteDB } from "../shared/entities/IPushNote";
import { BaseRepository } from "../frontend-core/BaseRepository";
import _ from "lodash";
import { generatePushNoteId } from "../shared/helpers/pushNotes";

class PushNoteRepository extends BaseRepository<IPushNote, IPushNoteDB> {
  constructor() {
    super("pushNotes");
  }

  toDbEntity(s: IPushNote): IPushNoteDB {
    return pushNoteToDb(s);
  }

  toLocalEntity(s: IPushNoteDB): IPushNote {
    return _.omit(s, ["userId_hasSeen"]);
  }

  generateId = (userId: string) => generatePushNoteId(userId);
}

export const pushNoteRepository = new PushNoteRepository();
