import { IUserDetail } from "../shared/entities/IUserDetail";
import { BaseRepository } from "../frontend-core/BaseRepository";

class UserDetailRepository extends BaseRepository<IUserDetail> {
  constructor() {
    super("userDetails");
  }
}

export const userDetailRepository = new UserDetailRepository();
