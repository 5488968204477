import { BaseRepository } from "../frontend-core/BaseRepository";
import { IChangeRequest } from "../shared/entities/IChangeRequest";
import { DispFn } from "../frontend-core/types/thunkTypes";
import { AppState } from "../types/AppState";
import { selectShiftMap } from "../selectors/shiftMapSelector";
import { shiftRepository } from "./shiftRepository";

class ChangeRequestRepository extends BaseRepository<IChangeRequest, IChangeRequest> {
  constructor() {
    super("changeRequests");
  }

  onListenerEvent(changeRequests: IChangeRequest[]) {
    return (dispatch: DispFn, getState: () => AppState): void => {
      const shiftMap = selectShiftMap(getState());
      const missingShiftIds = changeRequests.map((t) => t.id).filter((shiftId) => !shiftMap[shiftId]);

      if (missingShiftIds.length) {
        dispatch(shiftRepository.fetchByIds(missingShiftIds));
      }
    };
  }
}

export const changeRequestRepository = new ChangeRequestRepository();
