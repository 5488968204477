import React from "react";
import { connect } from "react-redux";
import { openModal } from "../../../../../actions/modal";
import { ITracking } from "../../../../../shared/entities/ITracking";
import { AppState } from "../../../../../types/AppState";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { CreditEntryBar } from "../CreditEntryBar/CreditEntryBar";
import "./styles.scss";
import { IShift } from "../../../../../shared/entities/IShift";
import { getShiftCredit } from "../../../../../shared/helpers/credit";
import { ShiftPopup } from "../../../../../components/ShiftPopup/ShiftPopup/ShiftPopup";
import { ITimeClocking } from "../../../../../shared/entities/ITimeClocking";

const iconStyle = {
  marginLeft: "4px",
  padding: "4px",
  borderRadius: "2px",
  color: "white",
  fontSize: "16px",
};

const mapStateToProps = (state: AppState) => ({
  branches: state.data.branches,
  isV2: state.data.tenantInfo.isV2,
});

type OwnProps = {
  shift: IShift;
  tracking?: ITracking;
  clocking?: ITimeClocking;
  reloadCredits: () => void;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

export class CreditShiftEntryComp extends React.PureComponent<Props> {
  onClick = () => {
    this.props.dispatch(
      openModal(ShiftPopup, {
        shift: this.props.shift,
        onUpdateComplete: this.props.reloadCredits,
      })
    );
  };

  render() {
    const { shift, branches, tracking, clocking, isV2 } = this.props;
    const branchName = branches.find((b) => b.id === shift.branchId)!.name;
    const creditMinutes = getShiftCredit(shift, tracking);
    const planedTime = `${shift.startTime} - ${shift.endTime} | ${shift.breakMinutes} ${lg.min_pause}`;
    const { startTime, endTime, breakMinutes } = tracking || {};
    const trackingTime = tracking && `${lg.zeiterfassung}: ${startTime} - ${endTime} | ${breakMinutes} ${lg.min_pause}`;

    const isMultiBranch = this.props.branches.filter((b) => !b.isInactive).length > 1;

    let durationTooltip = tracking ? trackingTime : planedTime;
    isV2 && clocking && !clocking.endTime && (durationTooltip = `${lg.eingestempelt}: ${clocking.startTime}`);

    const icons: any[] = [];
    if (tracking && !isV2) {
      icons.push({
        type: "history",
        style: { ...iconStyle, background: "#1990ff" },
        tooltip: lg.schicht_mit_zeiterfassung,
      });
    }

    if (shift.isNoShow && !tracking && !isV2) {
      icons.push({
        type: "exclamation",
        style: { ...iconStyle, background: "red" },
        tooltip: lg.schicht_nicht_gestempelt,
      });
    }

    if (shift.surcharge && !isV2) {
      icons.push({
        type: "pie-chart",
        style: { ...iconStyle, background: "grey" },
        tooltip: lg.inclusive_aufschlag_von + " " + shift.surcharge + " " + (shift.surchargeInPercentage ? " %" : " h"),
      });
    }

    if (isV2 && clocking && clocking.endTime && !clocking.isAccepted) {
      icons.push({
        type: "hourglass",
        style: { ...iconStyle, background: "orange" },
        tooltip: lg.unbestätigte_zeiterfassung,
      });
    }

    if (isV2 && clocking && clocking.isAutoClockOut) {
      icons.push({
        type: "exclamation",
        style: { ...iconStyle, background: "red" },
        tooltip: lg.auto_ausgestempelt,
      });
    }

    return (
      <CreditEntryBar
        minutes={creditMinutes}
        label={isMultiBranch ? branchName : undefined}
        durationTooltip={durationTooltip}
        icons={icons}
        onClick={this.onClick}
      />
    );
  }
}

export const CreditShiftEntry = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  CreditShiftEntryComp
);
