import React from "react";
import cn from "classnames";
import "./styles.scss";
type Props = {
  isIconActive: boolean;
};
export class TopBarAbsenceIcon extends React.PureComponent<Props> {
  render() {
    return (
      <div className="topbarIconWrapper">
        <svg
          width="28px"
          height="28px"
          viewBox="0 0 845 845"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          className={cn({ topbarIcon: true })}
        >
          <g id="005-plane" fill="rgba(255, 255, 255, 0.5)">
            <path
              d="M753.775866,519.083266 L735.099408,537.749141 L728.02184,473.179408 L783.955435,417.275856 L737.269246,370.617771 L718.802507,389.074046 L709.084474,300.407015 L780.555362,228.811586 C816.98849,192.399102 877.270017,74.5400297 823.843129,21.1434153 C770.416242,-32.2531991 652.490351,27.9941709 615.99117,64.4710199 L544.919902,135.903061 L455.718765,126.137723 L474.185504,107.679797 L427.499315,61.0217115 L371.56572,116.923613 L306.95938,109.850055 L325.637489,91.1825302 L278.952951,44.5260955 L222.809638,100.637596 L133.923904,90.9069156 L0,214.047226 L345.638306,336.203905 L189.312562,493.32898 L73.3385336,493.177144 L0.194856052,569.938285 L177.517166,661.536402 L282.131747,845 L354.859291,768.681163 L354.859291,655.25668 L509.178679,500.66825 L630.848118,844.537892 L754.05659,710.689872 L744.320393,621.856152 L800.465357,565.743001 L753.775866,519.083266 Z M125.422896,188.388662 L156.551976,159.764353 L485.36826,195.764241 L397.156265,284.42467 L125.422896,188.388662 Z M288.834465,627.970836 L288.834465,723.493573 L225.195799,611.889467 L123.248104,559.227274 L216.73112,559.349403 L662.74176,111.06639 C673.799016,100.0154 699.942753,83.6188075 728.515586,73.662024 C760.666834,62.457548 775.299203,66.2880957 777.128868,67.7701431 C778.677808,69.6680879 782.510528,84.2954661 771.2997,116.426847 C761.337271,144.981839 744.931382,171.107462 733.849357,182.184858 L288.834465,627.970836 Z M656.521229,719.186063 L560.876631,448.874161 L649.240548,360.357316 L685.161766,688.074622 L656.521229,719.186063 Z"
              id="Shape"
            ></path>
          </g>
        </svg>
      </div>
    );
  }
}
