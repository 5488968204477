import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import "./styles.scss";
import { absenceTypeRepository } from "../../../repositories/absenceTypeRepository";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { openModal } from "../../../actions/modal";
import { AbsenceTypeModal } from "../../../components/modals/AbsenceTypeModal/AbsenceTypeModal";
import { Button, Checkbox, Icon } from "antd";

import sortBy from "lodash/sortBy";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { IRosterSettings } from "../../../shared/entities/IRosterSettings";
import { rosterSettingsRepository } from "../../../repositories/rosterSettingsRepository";

const mapStateToProps = (state: AppState) => ({
  absenceTypes: state.data.absenceTypes,
  isV2: state.data.tenantInfo.isV2,
  rosterSettings: state.data.rosterSettings[0],
});

type State = {};
type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StoreProps & DispatchBaseProps;

class AbsenceTypesList extends React.PureComponent<Props, State> {
  toggleRosterSetting = (key: keyof IRosterSettings) => {
    this.props.dispatch(
      rosterSettingsRepository.update({
        ...this.props.rosterSettings,
        [key]: !this.props.rosterSettings[key],
      })
    );
  };

  renderCheckbox = (fieldName: keyof IRosterSettings, label: string, invert = false, infoText?: string) => {
    const { rosterSettings } = this.props;
    return (
      <div className="ruleRow" key={fieldName}>
        <Checkbox
          children={label}
          checked={invert ? !rosterSettings[fieldName] : !!rosterSettings[fieldName]}
          onChange={(e) => this.toggleRosterSetting(fieldName)}
        ></Checkbox>
        {infoText && (
          <div className="infoIconWrapper" data-rh={infoText}>
            <Icon type="info-circle" />
          </div>
        )}
      </div>
    );
  };

  render() {
    const { absenceTypes, dispatch, isV2 } = this.props;

    return (
      <div className="absenceTypesListMain">
        <div className="fb jBetween aCenter row">
          <h2 className="grow">{lg.abwesenheiten}</h2>
        </div>
        <div className="content">
          <div className="absenceTypes">
            {/* <div className="title">Abwesenheitstypen</div> */}
            <div className="listWrapper">
              {sortBy(absenceTypes, ["name"]).map((absenceType) => (
                <div
                  className="element"
                  key={absenceType.id}
                  onClick={() => dispatch(openModal(AbsenceTypeModal, { absenceType }))}
                >
                  {absenceType.name}
                </div>
              ))}
            </div>

            <Button
              id="absence-settings-save"
              type="primary"
              onClick={() => dispatch(openModal(AbsenceTypeModal, {}))}
              children={lg.hinzufügen}
              style={{ width: 120 }}
            />
          </div>
          <div className="settingsCol">
            {this.renderCheckbox(
              "managersCanManageAbsences",
              lg.manager_können_abwesenheiten_verwalten,
              false,
              lg.managern_erlauben_abwesenheiten_zu_erstellen_und_editieren_sowie_urlaubsanträgen_zu_bearbeiten
            )}
            {!isV2 &&
              this.renderCheckbox(
                "openUpShiftsInAbsence",
                lg.schichten_von_abwesenden_mitarbeitern_freigeben,
                false,
                lg.beim_anlegen_der_abwesenheit_werden_die_schichten_des_mitarbeirs_zu_offene_schichten_umgewandelt
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default withErrorBoundary(
  connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(AbsenceTypesList)
);
