import React from "react";
import { connect } from "react-redux";
import "../styles.scss";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { shiftRepository } from "../../../../repositories/shiftRepository";
import { trackingRepository } from "../../../../repositories/TrackingRepository";
import Page from "../../../../components/Page/Page";
import ReportActionBar from "../ReportActionBar/ReportActionBar";
import BusyWrapper from "../../../../components/BusyWrapper/BusyWrapper";
import { busyInjector, BusyInjectorProps } from "../../../../components/BusyInjector/BusyInjector";
import _, { StringNullableChain } from "lodash";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { setReportFilterData, ReportFilterData } from "../../../../actions/reporting";
import { withErrorBoundary } from "../../../../components/ErrorBoundary/ErrorBoundary";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { closestWithAttribute, waitAsync } from "../../../../helpers/general";
import { openModal } from "../../../../actions/modal";
import { IShift } from "../../../../shared/entities/IShift";
import { ITracking } from "../../../../shared/entities/ITracking";
import { minutesToDuration, minutesToHoursStr } from "../../../../shared/helpers/timeHelpers";
import { IAbsence } from "../../../../shared/entities/IAbsence";
import { ShiftPopup } from "../../../../components/ShiftPopup/ShiftPopup/ShiftPopup";
import { selectAbsencesByUser } from "../../../../selectors/absencesByUserSelector";
import { acceptTracking } from "../../../../actions/tracking";
import {
  selectBranchMap,
  selectUserMap,
  selectJobPositionMap,
  selectWorkSpaceMap,
  selectTrackingMap,
  selectAbsenceMap,
  selectUserDetailMap,
} from "../../../../selectors/mapSelectors";
import {
  exportShiftReportAsCsv,
  exportShiftReportAsPdf,
  getFilteredShiftEntries,
  getFilteredExtraEntries,
  fetchShiftReportData,
  exportMultiShiftReportAsPdf,
  exportShiftReportAsExcel,
} from "./shiftReportHelpers";
import { selectAbsenceTypeMap } from "../../../../selectors/absenceTypeMapSelector";
import { ICreditCorrection } from "../../../../shared/entities/ICreditCorrection";
import { selectCreditCorrectionMap } from "../../../../selectors/creditCorrectionMapSelector";
import AbsenceModal from "../../../../components/modals/AbsenceModal/AbsenceModal";
import { CreditCorrectionModal } from "../../../../components/modals/CreditCorrectionModal/CreditCorrectionModal";
import { ReportRow } from "./ReportRow";
import { ReportEntryType } from "../reportHelpers";
import { TimeFormat } from "../../../../reducers/ui/reportSettings/reportTimeFormat";
import { waitFor } from "@testing-library/react";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import moment from "moment";
import { selectWorkSpaces } from "../../../../selectors/_workSpacesSelector";
import { acceptPunchingV2 } from "../../../../actions/timeClocking";
import { ITimeClocking } from "../../../../shared/entities/ITimeClocking";
import { reportColsToV2 } from "../../../../frontend-core/helpers/frontendHelpers";

export type ReportEntry = {
  date: string;
  userId: string;
  userName: string;
  type: ReportEntryType;
  duration: number; // minutes
  /// The rest are optional props
  staffNumber?: string;
  jobPositionName?: string;
  branchName?: string;
  addressName?: string;
  shiftTime?: string;
  trackedTime?: string;
  isTrackingAccepted?: boolean;
  workSpaceName?: string;
  hashtags?: string;
  comment?: string;
  absenceTypeId?: string;
  shift?: IShift;
  absence?: IAbsence;
  creditCorrection?: ICreditCorrection;
  tracking?: ITracking;
  breakIntervalls?: string;
  wage?: number;
  clocking?: ITimeClocking; // used in V2
  isEarning: boolean;
  // surcharges::
  nightSurcharge?: number;
  holidaySurcharge?: number;
  sundaySurcharge?: number;
  customSurcharge?: number;
};

type ReportCol = { key: keyof ReportEntry; title: string; visible: boolean; tooltip?: string };

export const REPORT_COL_STYLE = {
  shiftId: { width: 100, flex: "0 0 auto" },
  date: { width: 100, flex: "0 0 auto", paddingLeft: 8 },
  userName: { minWidth: 80 },
  staffNumber: { minWidth: 100 },
  jobPosition: { minWidth: 100 },
  branchName: { minWidth: 100 },
  addressName: { minWidth: 100 },
  workSpace: { minWidth: 80 },
  hashtags: { minWidth: 80 },
  comment: { minWidth: 80 },
  times: { minWidth: 120 },
  tracking: { minWidth: 150 },
  breakIntervalls: { minWidth: 150 },
  wage: { minWidth: 60, flex: "0 0 auto" },
  sundaySurcharge: { minWidth: 55, flex: "0 0 auto" },
  holidaySurcharge: { minWidth: 55, flex: "0 0 auto" },
  nightSurcharge: { minWidth: 55, flex: "0 0 auto" },
  duration: {
    width: 74,
    flex: "0 0 auto",
    justifyContent: "flex-end",
    paddingRight: 8,
  },
};

const mapStateToProps = (state: AppState) => {
  const isV2 = state.data.tenantInfo.isV2;
  return {
    users: state.data.users, // show all users including inactive ones for retrospective data
    shifts: state.data.shifts,
    workSpaces: selectWorkSpaces(state),
    trackingMap: selectTrackingMap(state),
    userMap: selectUserMap(state),
    branchMap: selectBranchMap(state),
    jobPosMap: selectJobPositionMap(state),
    workSpaceMap: selectWorkSpaceMap(state),
    jobPositions: state.data.jobPositions,
    branches: state.data.branches,
    absencesByUser: selectAbsencesByUser(state),
    absenceMap: selectAbsenceMap(state),
    correctionMap: selectCreditCorrectionMap(state),
    reportFilters: state.ui.shifts.reporting.filters,
    userInfo: selectSessionInfo(state),
    reportColumns: reportColsToV2(state.ui.reportSettings.reportColumns, isV2),
    reportDataTypes: state.ui.reportSettings.reportDataTypes,
    reportTimeFormat: state.ui.reportSettings.reportTimeFormat,
    absenceTypeMap: selectAbsenceTypeMap(state),
    userDetailMap: selectUserDetailMap(state),
    sessionInfo: selectSessionInfo(state),
    surcharges: state.data.surcharges,
    isV2,
  };
};

type State = {
  loadingTrackingId?: string; // to show loading-spinner when clicking on tracking-accept
  sortBy: string;
  sortDirection: "asc" | "desc";
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & DispatchBaseProps & BusyInjectorProps;

class _ShiftReport extends React.PureComponent<Props, State> {
  isMultiBranch: boolean;
  extraEntries: ReportEntry[] = []; // to hold Entries of Absences/Holidays/CreditCorrections if activated in the report-settings
  shiftEntries: ReportEntry[] = [];

  constructor(props: Props) {
    super(props);

    this.state = {
      loadingTrackingId: undefined,
      sortBy: "date",
      sortDirection: "asc",
    };

    this.isMultiBranch = this.props.branches.filter((b) => !b.isInactive).length > 1;
  }

  async componentDidMount() {
    if (!this.props.sessionInfo.hasManagerPermissions()) {
      // for employees we preset some filters:
      // they shouldnt be a able to change the selected user and select dates that lie in the future
      this.props.dispatch(
        setReportFilterData({
          ...this.props.reportFilters,
          filterUserId: this.props.sessionInfo.user.id,
          filterStartDate: moment().add(-1, "month").startOf("month").format(SDateFormat),
          filterEndDate: moment().add(-1, "month").endOf("month").format(SDateFormat),
        })
      );
      await waitAsync(100);
    }

    this.props.load(this.init());
    window.addEventListener("click", this.clickDetected);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.clickDetected);
  }

  clickDetected = async (e: any) => {
    const hasManagerPermissions = this.props.sessionInfo.hasManagerPermissions();
    const trackingBtn = closestWithAttribute(e.target, "data-tracking-button", "true");
    if (trackingBtn && hasManagerPermissions) {
      const trackingId = trackingBtn.getAttribute("data-tracking-id")!;
      this.setState({ loadingTrackingId: trackingId });
      this.props.isV2
        ? await this.props.dispatch(acceptPunchingV2(trackingId))
        : await this.props.dispatch(acceptTracking(trackingId));
      this.setState({ loadingTrackingId: undefined });
      this.setEntries();
      return;
    }

    const shiftNode = closestWithAttribute(e.target, "data-report-row", "shift");
    if (shiftNode) {
      const shiftId = shiftNode!.getAttribute("data-shift-id");
      const shift = this.props.shifts.find((s) => s.id === shiftId)!;
      this.props.dispatch(openModal(ShiftPopup, { shift, onUpdateComplete: this.setEntries }));
    }

    const absenceNode = closestWithAttribute(e.target, "data-report-row", "absence");
    if (absenceNode) {
      const absenceId = absenceNode!.getAttribute("data-absence-id")!;
      const absence = this.props.absenceMap[absenceId];
      this.props.dispatch(
        openModal(AbsenceModal, { absence, userId: absence.userId, onUpdateComplete: this.setExtraEntries })
      );
    }

    const correctionNode = closestWithAttribute(e.target, "data-report-row", "correction");
    if (correctionNode && hasManagerPermissions) {
      const correctionId = correctionNode!.getAttribute("data-correction-id")!;
      const creditCorrection = this.props.correctionMap[correctionId];
      const { date, userId, type } = creditCorrection;
      this.props.dispatch(
        openModal(CreditCorrectionModal, {
          date,
          userId,
          type,
          creditCorrection,
          onUpdateComplete: this.setExtraEntries,
        })
      );
    }
  };

  init = async () => {
    const { reportFilters, reportDataTypes } = this.props;
    await this.props.dispatch(fetchShiftReportData());
    this.setEntries();
    if (
      this.extraEntries.length ||
      reportDataTypes.creditCorrections ||
      reportDataTypes.holidays ||
      reportDataTypes.absenceTypeIds.length
    ) {
      this.setExtraEntries();
    }
  };

  getRowRenderer =
    (entries: ReportEntry[]) =>
    ({ index, style }) => {
      const { reportColumns, absenceTypeMap, reportTimeFormat, sessionInfo } = this.props;
      const hasManagerPermissions = sessionInfo.hasManagerPermissions();
      const reportShowDecimals = reportTimeFormat === TimeFormat.DecimalHours;
      const entry = entries[index];

      return (
        <ReportRow
          isLoadingTracking={this.state.loadingTrackingId === entry.shift?.id}
          reportColumns={reportColumns}
          absenceTypeMap={absenceTypeMap}
          type={entry.type}
          shift={entry.shift}
          breakIntervalls={entry.breakIntervalls}
          wage={entry.wage}
          clocking={entry.clocking}
          tracking={entry.tracking}
          userName={entry.userName}
          staffNumber={entry.staffNumber}
          absence={entry.absence}
          workSpaceName={entry.workSpaceName}
          hashtags={entry.hashtags}
          comment={entry.comment}
          trackedTime={entry.trackedTime}
          isTrackingAccepted={entry.isTrackingAccepted}
          creditCorrection={entry.creditCorrection}
          branchName={entry.branchName}
          addressName={entry.addressName}
          duration={entry.duration}
          date={entry.date}
          absenceTypeId={entry.absenceTypeId}
          jobPositionName={entry.jobPositionName}
          isMultiBranch={this.isMultiBranch}
          style={style}
          reportShowDecimals={reportShowDecimals}
          hasManagerPermissions={hasManagerPermissions}
          nightSurcharge={entry.nightSurcharge}
          holidaySurcharge={entry.holidaySurcharge}
          sundaySurcharge={entry.sundaySurcharge}
          customSurcharge={entry.customSurcharge}
          isV2={!!this.props.isV2}
        />
      );
    };

  getCols = (): ReportCol[] => {
    const visCols = this.props.reportColumns;
    const getSurchargeCol = (key: string, type: string, name: string): ReportCol => {
      const surcharge = this.props.surcharges.find((s) => s.type === type);
      return {
        key: key as any,
        title: surcharge?.shorthand + " %",
        visible: visCols[key],
        tooltip: `${name} ${surcharge?.percentage} %`,
      };
    };

    return [
      { key: "shiftId", title: "Id", visible: visCols.shiftId },
      { key: "date", title: lg.datum, visible: visCols.date },
      { key: "userName", title: lg.mitarbeiter, visible: visCols.userName },
      { key: "staffNumber", title: lg.personalnr_punkt, visible: visCols.staffNumber },
      { key: "jobPositionName", title: lg.rolle, visible: visCols.jobPositionName },
      {
        key: "branchName",
        title: lg.standort,
        visible: this.isMultiBranch && visCols.branchName,
      },
      { key: "addressName", title: lg.adresse, visible: visCols.addressName },
      { key: "workSpaceName", title: lg.label, visible: visCols.workSpaceName },
      { key: "hashtags", title: lg.hashtags, visible: visCols.hashtags },
      { key: "comment", title: lg.kommentar, visible: visCols.comment },
      { key: "shiftTime", title: lg.planzeit, visible: visCols.shiftTime },
      { key: "tracking", title: lg.zeiterfassung, visible: visCols.trackedTime },
      { key: "breakIntervalls", title: lg.pausenintervall, visible: visCols.breakIntervalls },
      // Surcharges START
      getSurchargeCol("nightSurcharge", "night", lg.Nachtzuschlag),
      getSurchargeCol("holidaySurcharge", "holiday", lg.Feiertagszuschalg),
      getSurchargeCol("sundaySurcharge", "sunday", lg.Sonntagszuschlag),
      getSurchargeCol("customSurcharge", "custom", lg.Extrazuschlag),
      // Surchages END
      { key: "wage", title: lg.lohn, visible: visCols.wage },
      { key: "duration", title: lg.dauer, visible: visCols.duration },
    ].filter((c) => c.visible) as ReportCol[];
  };

  exportReport = async (type: "pdf" | "csv" | "excel" | "multi-pdf", options?: { userIds: string[] }) => {
    const { dispatch } = this.props;
    const entries = this.orderEntries([...this.shiftEntries, ...this.extraEntries]);

    type === "csv" && (await dispatch(exportShiftReportAsCsv(entries)));
    type === "excel" && (await dispatch(exportShiftReportAsExcel(entries)));
    type === "pdf" && (await dispatch(exportShiftReportAsPdf(entries)));
    type === "multi-pdf" && (await this.doMultiUserPdfExport(options!.userIds));
  };

  doMultiUserPdfExport = async (userIds: string[]) => {
    const { dispatch } = this.props;
    let entriesByUser: { userId: ReportEntry[] } = {} as any;
    this.props.setLoading("main", true);
    for (let userId of userIds) {
      dispatch(setReportFilterData({ ...this.props.reportFilters, filterUserId: userId }));
      await waitAsync(50);
      await this.init();
      const entries = this.orderEntries([...this.shiftEntries, ...this.extraEntries]);
      entriesByUser[userId] = entries;
    }
    this.props.setLoading("main", false);
    dispatch(exportMultiShiftReportAsPdf(entriesByUser));
  };

  setEntries = () => {
    this.shiftEntries = this.props.dispatch(getFilteredShiftEntries());
    this.forceUpdate();
  };

  setExtraEntries = () => {
    this.extraEntries = this.props.dispatch(getFilteredExtraEntries());
    this.forceUpdate();
  };

  headCellClicked = (key: string) => {
    const { sortDirection, sortBy } = this.state;
    if (key === sortBy) {
      this.setState({ sortDirection: sortDirection === "asc" ? "desc" : "asc" });
    } else {
      this.setState({
        sortBy: key,
        sortDirection: "asc",
      });
    }
  };

  orderEntries = (entries: ReportEntry[]): ReportEntry[] => {
    return _.orderBy(
      entries,
      [
        (s) => s[this.state.sortBy],
        (s) => s[this.state.sortBy === "shiftTime" ? "date" : "shiftTime"], // always use shifTime as second orderKey, unless the user is sorting by it
      ],
      [this.state.sortDirection, "asc"]
    );
  };

  render() {
    const { isLoading, reportTimeFormat } = this.props;
    const reportEntries = this.orderEntries([...this.shiftEntries, ...this.extraEntries]);
    const sum = _.sum(reportEntries.map((e) => e.duration));
    const reportShowDecimals = reportTimeFormat === TimeFormat.DecimalHours;
    const sumString = reportShowDecimals ? minutesToHoursStr(sum) : minutesToDuration(sum);

    console.log("reportEntries");
    console.log(reportEntries);

    return (
      <Page>
        <div className={`shiftsReportMain`}>
          <ReportActionBar
            filters={this.props.reportFilters}
            createExport={this.exportReport}
            updateFilters={(filters: ReportFilterData) => {
              this.props.setLoading("main", true);
              this.props.dispatch(setReportFilterData(filters));
              setTimeout(async () => {
                await this.init();
                this.props.setLoading("main", false);
              });
            }}
          />
          <div className="mainReportTable">
            <div className="head">
              {this.getCols().map((col) => (
                <div
                  className={"cell headCell " + col.key}
                  key={col.key}
                  style={REPORT_COL_STYLE[col.key]}
                  data-rh={col.tooltip || null}
                  data-rh-at="top"
                  onClick={() => this.headCellClicked(col.key)}
                >
                  {col.title}
                </div>
              ))}
            </div>
            <BusyWrapper isBusy={isLoading()} style={{ height: "100%" }}>
              <div className="body">
                {!reportEntries.length && !this.props.isLoading() && (
                  <div className="noData">{lg.keine_daten_im_gewählten_zeitraum_vorhanden}</div>
                )}
                {!this.props.isLoading() && (
                  <>
                    <AutoSizer>
                      {({ height, width }) => (
                        <List
                          height={height}
                          itemCount={reportEntries.length}
                          itemSize={38}
                          width={width}
                          style={{ overflowY: "scroll" }}
                        >
                          {this.getRowRenderer(reportEntries)}
                        </List>
                      )}
                    </AutoSizer>
                  </>
                )}
              </div>
            </BusyWrapper>
            <div className="footer">
              {lg.summe}: {sumString}
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export const ShiftReport = withErrorBoundary(
  connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(busyInjector(_ShiftReport))
);
