import React from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { Icon } from "antd";

import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";

import { busyInjector, BusyInjectorProps } from "../../../BusyInjector/BusyInjector";
import cn from "classnames";
import { IShift } from "../../../../shared/entities/IShift";
import { ITimeClocking } from "../../../../shared/entities/ITimeClocking";
import { generateUserTimeClockSettings } from "../../../../shared/helpers/settingsHelpers";

const mapStateToProps = (state: AppState) => {
  return {
    timeClockSettings: state.data.timeClockSettings[0],
    users: state.data.users,
  };
};

type OwnProps = {
  shift: IShift;
  timeClocking: ITimeClocking;
};

type State = {
  isLoading: boolean;
  timeClocking: ITimeClocking;
  isEditMode: boolean;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class _TimeClockingRow extends React.PureComponent<Props, State> {
  render() {
    const { timeClocking, timeClockSettings, users } = this.props;

    const user = users.find((u) => u.id === timeClocking.userId)!;
    const userTimeClockSettings = generateUserTimeClockSettings(timeClockSettings, user);

    return (
      <>
        <div
          className={cn({
            timeClockingRow: true,
          })}
        >
          <div
            className="shiftPopupLabelMain"
            style={{
              height: "100%",
              borderRight: "1px solid #ffffff47",
            }}
          >
            {lg.gestempelt}
          </div>
          <div className="fb row aEnd grow content">
            <div className="time">{timeClocking.startTime}</div>
            <div className="line">
              <Icon type="right" />
            </div>
            <div className="time">{timeClocking.endTime || "-"}</div>
            {userTimeClockSettings.breaksNeedToBeClocked && (
              <div className="breake">
                <Icon type="coffee" className="coffeIcon" />
                {timeClocking.breakMinutes}
              </div>
            )}
          </div>
          {/* {(timeClocking.isMobileClockIn || timeClocking.isMobileClockOut) && (
            <div
              className="mobileSignalIcon"
              data-rh={
                timeClocking.isMobileClockIn && timeClocking.isMobileClockOut
                  ? "Mobil ein- und ausgestempelt"
                  : timeClocking.isMobileClockIn
                  ? "Mobil nur eingestempelt "
                  : "Mobil nur ausgestempelt"
              }
            >
              <Icon type="wifi" />
            </div>
          )} */}
          {timeClocking.isAutoClockOut && (
            <div
              className="autoClockoutWarning"
              data-rh="Automatisch ausgestempelt, da der Mitarbeiter es vergessen hat"
            >
              <Icon type="exclamation-circle" className="autoIcon" />
              {lg.auto_ausgestempelt}
            </div>
          )}
        </div>
      </>
    );
  }
}

export const TimeClockingRow = connect(mapStateToProps)(busyInjector(_TimeClockingRow));
