import { IShift } from "./../shared/entities/IShift";
import { selectShiftMap } from "./../selectors/shiftMapSelector";
import { AppState } from "./../types/AppState";
import { DispFn } from "../frontend-core/types/thunkTypes";
import { trackingRepository } from "../repositories/TrackingRepository";
import { ITracking } from "../shared/entities/ITracking";
import { selectTrackingMap } from "../selectors/mapSelectors";
import { shiftRepository } from "../repositories/shiftRepository";
import { shfitDragActionTypes } from "../reducers/ui/shifts/roster/draggingShift";

const doRemoveNoShowFlag = (nextTracking: ITracking, shift: IShift) => {
  return shift && shift.isNoShow && nextTracking.isAccepted;
};

export const removeTracking = (tracking: ITracking) => {
  return async (dispatch: DispFn) => {
    return dispatch(trackingRepository.remove(tracking.id));
  };
};

export const updateTracking = (tracking: ITracking) => {
  return async (dispatch: DispFn, getState: () => AppState) => {
    const shift = selectShiftMap(getState())[tracking.id];
    doRemoveNoShowFlag(tracking, shift) && (await dispatch(shiftRepository.update({ ...shift, isNoShow: undefined })));
    return dispatch(trackingRepository.update(tracking));
  };
};

export const createTracking = (tracking: ITracking) => {
  return async (dispatch: DispFn, getState: () => AppState) => {
    const shift = selectShiftMap(getState())[tracking.id];
    doRemoveNoShowFlag(tracking, shift) && (await dispatch(shiftRepository.update({ ...shift, isNoShow: undefined })));
    return dispatch(trackingRepository.create(tracking));
  };
};

export const acceptTracking = (trackingId: string) => {
  return async (dispatch: DispFn, getState: () => AppState) => {
    const tracking = selectTrackingMap(getState())[trackingId];
    const trackingAccepted = { ...tracking, isAccepted: true };
    return dispatch(updateTracking(trackingAccepted));
  };
};
