import React from "react";
import "./styles.scss";
import { Icon, DatePicker } from "antd";
import { IContractCore, IContract } from "../../../../shared/entities/IContract";
import { simpleDateToMoment, toSimpleDate } from "../../../../shared/helpers/timeHelpers";
import { Moment } from "moment";
import cn from "classnames";

type State = {};
type Props = {
  contract: IContractCore;
  prevContract?: IContractCore;
  nextContract?: IContractCore;
  isInitial: boolean;
  isCollapsed: boolean;
  isDeletable: boolean;
  onDelete: () => void;
  onToggle: () => void;
  onDateChange: (m: Moment | null) => void;
};

export class ContractEntryHead extends React.PureComponent<Props, State> {
  isDateDisabled = (mom: Moment | null): boolean => {
    const { prevContract, nextContract } = this.props;
    const prevDate = prevContract?.validFrom;
    const nextDate = nextContract?.validFrom;
    const date = mom && toSimpleDate(mom);

    return !date || !!(prevDate && date <= prevDate) || !!(nextDate && date >= nextDate);
  };

  onClick = () => {
    if (this.props.isCollapsed) {
      this.props.onToggle();
    }
  };

  render() {
    const { contract, isCollapsed, isInitial } = this.props;

    return (
      <div className={cn({ contractEntryHeadMain: true, isCollapsed })} onClick={this.onClick}>
        <div className="fb validFromTo">
          {isInitial && <div className="initialLabel">{lg.initiale_arbeitszeit}</div>}
          {!isInitial && (
            <div className="fb row jCenter aCenter grow">
              <div className="label" style={{ marginRight: 11 }}>
                {lg.gültig_ab}
              </div>
              <div className="datePickerWrapper">
                <DatePicker
                  format="DD. MMM YYYY"
                  value={simpleDateToMoment(contract.validFrom)}
                  onChange={this.props.onDateChange}
                  disabledDate={this.isDateDisabled}
                  disabled={isCollapsed}
                  allowClear={false}
                />
              </div>
              {this.props.isDeletable && (
                <div className="deleteButton" onClick={this.props.onDelete} data-rh={lg.eintrag_löschen}>
                  <Icon type="delete" />
                </div>
              )}
            </div>
          )}
          <div className="collapseButton" onClick={this.props.onToggle}>
            <Icon type={isCollapsed ? "caret-down" : "caret-up"} style={{ fontSize: 18 }} />
          </div>
        </div>
      </div>
    );
  }
}
