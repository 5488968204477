import { FarFuture } from "../UserGeneralTab/UserGeneralTab";
import _ from "lodash";
import { Raw } from "../../../../shared/entities/IResource";
import { IContract } from "../../../../shared/entities/IContract";
import { simpleDateToMoment, toSimpleDate } from "../../../../shared/helpers/timeHelpers";

// sets the `validTo` of a contract to the validFrom of the following contract minus one day.
export const updateValidToDates = (_contracts: Raw<IContract>[]): Raw<IContract>[] => {
  const contracts = _.sortBy(_contracts, "validFrom");
  return contracts.map((wp, i) => {
    if (i === contracts.length - 1) {
      // if its the last contract
      return { ...wp, validTo: FarFuture };
    } else {
      const nextValidFrom = contracts[i + 1].validFrom;
      const nextValidFromMom = simpleDateToMoment(nextValidFrom);
      const validToMom = nextValidFromMom.subtract(1, "day");
      const validTo = toSimpleDate(validToMom);
      return { ...wp, validTo };
    }
  });
};

// turns daily quotas of 0 to `null`
// export const clearDailyQuotas = (_contracts: Raw<IContract>[]): Raw<IContract>[] => {
//   return _contracts.map((wp) => {
//     const dailyQuota = _.mapValues(wp.dailyQuota, (quota) => quota || null);
//     return { ...wp, dailyQuota };
//   });
// };
