import React from "react";
import "./styles.scss";
import AvatarDfaultSrc from "./avatar-default-image.png";

type Props = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
  src?: string;
  size?: number;
};

export const AvatarImage = ({ size, src, ...props }: Props) => {
  const sizeWithDefault = size || 50;
  return (
    <img
      className="avatarImage"
      width={sizeWithDefault}
      height={sizeWithDefault}
      {...props}
      src={src || AvatarDfaultSrc}
    />
  );
};
