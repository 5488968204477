import React, { useEffect } from "react";
import Holidays from "date-holidays";
import { Select } from "antd";
import _ from "lodash";
import { antDesignSelectFilterSearch } from "../../frontend-core/helpers/frontendHelpers";

const countryCodesOfInterest = {
  AT: 1,
  AU: 1,
  CA: 1,
  CH: 1,
  DE: 1,
  DK: 1,
  FR: 1,
  GB: 1,
  IT: 1,
  NL: 1,
  NZ: 1,
  SG: 1,
  US: 1,
  ZA: 1,
  // AM: 1,
  // PL: 1,
  // SE: 1,
  // UA: 1,
  // BY: 1,
  // RU: 1,
  // EE: 1,
  // GE: 1,
  // LV: 1,
  // LT: 1,
};

export interface ICountrySelectorFormValues {
  country: string;
  state?: string;
}

// e.g. US, DE, FR...
// see https://developer.mozilla.org/en-US/docs/Web/API/NavigatorLanguage/language

type Props = {
  formValues?: ICountrySelectorFormValues;
  onChange: (result: ICountrySelectorFormValues, isComplete: boolean) => void;
};

export const CountrySelector = (props: Props) => {
  const hd = new Holidays();
  const stateWordings = { CH: "Kanton", DE: "Bundesland", AT: "Bundesland" };

  useEffect(() => {
    const lang = navigator.language || navigator.languages[0];

    let countryCode = "";
    (lang === "de-DE" || lang === "de-de" || lang === "de") && (countryCode = "DE");
    (lang === "de-AT" || lang === "de-at") && (countryCode = "AT");
    (lang === "de-CH" || lang === "de-ch") && (countryCode = "CH");

    if (countryCode) {
      props.onChange({ country: countryCode }, false);
    }
  }, []);

  const countryStates = props.formValues?.country && hd.getStates(props.formValues?.country);

  const countries = Object.entries(hd.getCountries())
    .map(([code, label]) => ({ code, label }))
    .filter(({ code, label }) => countryCodesOfInterest[code]);

  console.log(countries);

  return (
    <>
      <div className="fb column" style={{ maxWidth: 420 }}>
        <div className="fb row" style={{ marginBottom: 10 }}>
          <Select
            {...props}
            value={props.formValues?.country}
            onChange={(country: string) => props.onChange({ country, state: undefined }, !hd.getStates(country))}
            style={{ width: 300 }}
            filterOption={antDesignSelectFilterSearch}
            showSearch
          >
            {countries.map(({ code, label }) => (
              <Select.Option value={code} key={code} label={label} title={label}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      {countryStates && (
        <>
          <div className="fb column" style={{ maxWidth: 420 }}>
            <div className="fb row" style={{ marginBottom: 10 }}>
              <Select
                {...props}
                placeholder={stateWordings[props.formValues!.country] || "State"}
                value={props.formValues?.state}
                onChange={(value: string) => props.onChange({ ...props.formValues!, state: value }, true)}
                style={{ width: 300 }}
              >
                {Object.entries(countryStates).map(([code, label]) => (
                  <Select.Option value={code} key={code}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </>
      )}
    </>
  );
};
