import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "../../helpers/redux";
import { IShift } from "../../shared/entities/IShift";
import "./styles.scss";
import cn from "classnames";
import { Button, Drawer, Icon, Input, Popover } from "antd";
import { openModal } from "../../actions/modal";
import { ShiftPopup } from "../ShiftPopup/ShiftPopup/ShiftPopup";
import { selectShiftTemplates, SHIFT_TEMPLATE_DATE } from "../../selectors/shiftTemplatesSelector";
import { usePrevious } from "../../frontend-core/helpers/usePrevious";
import { shiftRepository } from "../../repositories/shiftRepository";
import DraggableShift from "../../pages/shiftsPage/RosterPage/RosterWeekGrid/Slot/DraggableShift";
import {
  selectBranchMap,
  selectJobPositionMap,
  selectShiftAddressMap,
  selectWorkSpaceMap,
} from "../../selectors/mapSelectors";
import { toggleShiftTemplatesDrawer } from "../../reducers/ui/shifts/roster/isShiftTemplatesOpen";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { reorder } from "../../helpers/general";
import { useDispatch } from "react-redux";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import { toTripleDigit } from "../../shared/helpers/timeHelpers";

type Props = {};

export const ShiftTemplateDrawer = React.memo(({}: Props) => {
  const dispatch = useDispatch<DispFn>();
  const isOpen = useSelector((s) => s.ui.shifts.roster.isShiftTemplatesOpen);
  const selectedBranch = useSelector((s) => s.ui.selectedBranch);
  const shiftTemplates = useSelector(selectShiftTemplates);
  const prevSelectedBranch = usePrevious(selectedBranch);

  const selectedBranchRemoved = !!prevSelectedBranch && !selectedBranch;
  const draggingShift = useSelector((s) => s.ui.shifts.roster.draggingShift);

  const branchMap = useSelector(selectBranchMap);
  const jobPosMap = useSelector(selectJobPositionMap);
  const workSpaceMap = useSelector(selectWorkSpaceMap);
  const addressMap = useSelector(selectShiftAddressMap);
  const [isOrderingMode, setOrderingMode] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [filterString, setFilterString] = useState("");

  const isOpenRef = useRef(false);
  const inputRef = useRef(null);

  const displayFilterField = shiftTemplates.length > 15;
  const drawerJutOpened = isOpenRef.current === false && isOpen;

  if (drawerJutOpened) {
    // we focus the filterInput with a delay, otherwise the fade-in animation is breaking for some reaseon..
    if (displayFilterField) {
      setTimeout(() => (inputRef.current as any).focus(), 300);
    }
  }

  isOpenRef.current = isOpen;

  useEffect(() => {
    dispatch(shiftRepository.fetchMany({ filter: ["date", "=", SHIFT_TEMPLATE_DATE] }));
  }, []);

  if (selectedBranchRemoved && isOpen) {
    console.log("selectedBranchRemoved");
    dispatch(toggleShiftTemplatesDrawer());
  }

  const openShfitTemplateModal = () => {
    const shift = {
      date: SHIFT_TEMPLATE_DATE,
      branchId: selectedBranch || "b1",
      userId: undefined,
      isTemplate: true,
    };
    dispatch(openModal(ShiftPopup, { shift }));
  };

  const onDragEnd = async (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reOrderedJobPositions = reorder(shiftTemplates, result.source.index, result.destination.index).map(
      (j, i) => ({ ...j, isTemplate: `z${toTripleDigit(i)}` })
    ); // we use the isTemplate prop to also encode the order

    setLoading(true);
    await dispatch(shiftRepository.updateList(reOrderedJobPositions));
    setLoading(false);
  };

  const renderFilterInput = () => (
    <div className="filterInputWrapper">
      <Input
        value={filterString}
        onChange={(e) => setFilterString(e.target.value)}
        size="small"
        ref={inputRef}
        placeholder="filter"
      />
    </div>
  );

  const toggleDrawer = () => {
    setOrderingMode(false);
    dispatch(toggleShiftTemplatesDrawer());
  };

  const renderShift = (s: IShift, wrapperStyle: React.CSSProperties = {}) => (
    <div className="shiftWrapper" key={s.id} style={wrapperStyle}>
      <DraggableShift
        data={s}
        style={{ borderRadius: 3, cursor: "grab" }}
        showBreakes={true}
        showDurations={true}
        dispatch={dispatch}
        branchMap={branchMap}
        jobPosMap={jobPosMap}
        workSpaceMap={workSpaceMap}
        addressMap={addressMap}
        usersMap={{}}
        canManage={true}
        isOwnShift={false}
        isGroupedByJobPos={false}
        showJobPosition={true}
      />
    </div>
  );

  const shiftTemplatesFiltered = !filterString
    ? shiftTemplates
    : shiftTemplates.filter(
        (shift) =>
          shift.workSpaceId &&
          workSpaceMap[shift.workSpaceId!].name.toLocaleLowerCase().includes(filterString.toLocaleLowerCase())
      );

  return (
    <div
      className={cn({
        shiftTemplateDrawerMain: true,
        moveRight: !!draggingShift,
        hideRight: !isOpen,
      })}
    >
      <div className="head">
        <div className="title">Vorlagen</div>
        <div className="closeIconWrapper" onClick={toggleDrawer}>
          <Icon type="close" />
        </div>
      </div>
      <div className="body">
        <div className="fb row">
          <div className={cn({ createTempBtn: true, disabled: isOrderingMode })} onClick={openShfitTemplateModal}>
            + Neue Vorlage
          </div>
          {shiftTemplates.length >= 4 && (
            <div
              className={cn({ sortIconWrapper: true, active: isOrderingMode })}
              data-rh={isOrderingMode ? null : lg.reihenfolge_ändern}
              data-rh-at="left"
            >
              <Icon type="sort-ascending" onClick={() => setOrderingMode(!isOrderingMode)} />
            </div>
          )}
        </div>
        {!isOrderingMode && displayFilterField && renderFilterInput()}
        {isOrderingMode && <div className="sortInfoText">Reihenfolge per Drag & Drop anpassen.</div>}
        <div className="listWrapper">
          <div className="listContent">
            {isOrderingMode ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {shiftTemplates.map((s, index) => (
                        <Draggable key={s.id} draggableId={s.id} index={index}>
                          {(provided) => (
                            <div>
                              <div
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                style={{ ...provided.draggableProps.style, marginBottom: 2 }}
                              >
                                {!isLoading && (
                                  <div className="fb row sortableShiftWrapper">
                                    <Icon type="more" className="moveIcon" style={{ marginRight: 4, cursor: "move" }} />
                                    {renderShift(s, { pointerEvents: "none" })}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              shiftTemplatesFiltered.map((s) => renderShift(s))
            )}
          </div>
        </div>
        {shiftTemplates.length >= 1 && shiftTemplates.length <= 3 && (
          <div className="infoText">{lg.vorlagen_per_drag_and_drop_anwenden()}</div>
        )}
      </div>
    </div>
  );
});
