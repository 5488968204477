import React, { useEffect, useState } from "react";
import "./styles.scss";

import { BasicSelect } from "../../../BasicSelect/BasicSelect";
import { IAbsenceType } from "../../../../shared/entities/IAbsenceType";
import { Button, Icon, Input, Modal, Select } from "antd";
import { AbsenceIcon } from "../../../AbsenceIcon/AbsenceIcon";
import { IUpload, IUploadBase } from "../../../../shared/entities/IUpload";
import { useDispatch } from "react-redux";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import { removeUpload } from "../../../../actions/uploads";
import { closeModal, openModal } from "../../../../actions/modal";
import TZModal from "../../../TZModal/TZModal";
import { isUploaded } from "./../localHelpers";
import { EditUploadModal } from "../../../../pages/Users/UserDetailsModal/DocumentsTab/EditUploadModal/EditUploadModal";
import { downloadFile } from "../../../../frontend-core/helpers/frontendHelpers";
import { useSelector } from "../../../../helpers/redux";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import moment from "moment";
import { selectUserFullMap } from "../../../../selectors/userFullMapSelector";

type Props = {
  uploads: Array<IUpload | IUploadBase>;
  onChange: (upload: IUploadBase) => any;
  onDelete: (uploadId: string) => any;
};

export const AbsenceUploadsSection = (props: Props) => {
  const { uploads, onChange, onDelete } = props;

  const dispatch = useDispatch<DispFn>();
  const sessionInfo = useSelector(selectSessionInfo);
  const userFullMap = useSelector(selectUserFullMap);

  const deleteFile = (upload: IUpload) => {
    Modal.confirm({
      title: lg.dokument_löschen,
      content: lg.die_datei_file_wirklich_löschen(upload.name),
      onOk: async () => {
        await dispatch(removeUpload(upload));
        props.onDelete(upload.id);
      },
      cancelText: lg.abbrechen,
      okText: lg.löschen,
      okType: "danger",
    });
  };

  const deleteClicked = (upload: IUpload | IUploadBase) => {
    const isAlreadyUploaded = (upload as any).url;
    isAlreadyUploaded ? deleteFile(upload as IUpload) : props.onDelete(upload.id);
  };

  const editClicked = (upload: IUploadBase | IUpload) => {
    dispatch(
      openModal(EditUploadModal, {
        upload,
        onComplete: (upload: IUploadBase) => onChange({ ...upload }),
        doSave: isUploaded(upload), // if its an IUpload its already on DB > update the name in DB
      })
    );
  };

  const renderFileName = (upload: IUpload | IUploadBase) =>
    isUploaded(upload) ? (
      <a
        href={(upload as IUpload).url}
        target="_blank"
        onClick={(event) => event.stopPropagation()}
        children={upload.displayName}
      />
    ) : (
      <div className="fileName">{upload.displayName}</div>
    );

  return (
    <div className="absenceUploadsSectionMain">
      <div className="title">{lg.anhänge}</div>
      {uploads.map((upload) => {
        const isCreator = (upload as IUpload)?.creatorId === sessionInfo.user.id;
        const isCreatedRecently = (upload as IUpload)?.timestamp > moment().add(-2, "hours").toISOString();
        // only admin or manager can edit, or an employee if he has created it recently -> so a raging emplyee cant delte his documents
        const canEdit = sessionInfo.hasManagerPermissions() || (isCreator && isCreatedRecently) || !isUploaded(upload);
        const timestamp = (upload as IUpload)?.timestamp;
        const creatorId = (upload as IUpload)?.creatorId;
        const dateDisplay = moment(timestamp).format("LLL");
        const creatorName = creatorId && userFullMap[creatorId].name;

        return (
          <div className="uploadEntry" key={upload.id}>
            <div className="attachment">
              <Icon type="file-text" />
            </div>
            <div
              className="nameWrapper"
              data-rh={
                timestamp && creatorName ? `${lg.hochgeladen_am} ${dateDisplay} ${lg.vonBy} ${creatorName}` : null
              }
              data-rh-at="top"
            >
              {renderFileName(upload)}
            </div>
            {isUploaded(upload) && (
              <div className="download actionBtn" data-rh={lg.download} onClick={() => downloadFile(upload as IUpload)}>
                <Icon type="download" />
              </div>
            )}
            {canEdit && (
              <div className="fb row">
                <div className="edit actionBtn" data-rh={lg.editieren} onClick={() => editClicked(upload)}>
                  <Icon type="edit" />
                </div>
                <div className="delete actionBtn" data-rh={lg.löschen} onClick={() => deleteClicked(upload)}>
                  <Icon type="delete" />
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
