export const SET_MOUSE_OVER_USER_CELLS = "SET_MOUSE_OVER_USER_CELLS";

export const mouseEnteredUserCells = () => {
  return setMouseOverUserCells(true);
};

export const mouseLeftUserCells = () => {
  return setMouseOverUserCells(false);
};

export const setMouseOverUserCells = (isMouseOver: boolean) => ({
  type: SET_MOUSE_OVER_USER_CELLS,
  payload: isMouseOver,
});

export const isMouseOverUserCellsReducer = (state = false, a: any): string =>
  a.type === SET_MOUSE_OVER_USER_CELLS ? a.payload : state;
