import { dataStatusRedcuer } from "./dataStatus";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import { dataReducer } from "./data/index";
import { uiReducer } from "./ui";
import { adminDataReducer } from "./data/adminDataReducer";

const rootReducer = (history: History) => {
  const reducer = combineReducers({
    router: connectRouter(history),
    data: dataReducer,
    dataStatus: dataStatusRedcuer,
    ui: uiReducer,
    adminData: adminDataReducer,
  });

  return (state, action) => {
    if (action.type === "@@AV/LOGOUT") {
      // Clear the complete redux store after a logout
      state = undefined;
    }
    return reducer(state, action);
  };
};

export default rootReducer;
