import React from "react";
import { envi } from "../../../env";

type Props = {
  handleSubmit: (login: any) => {};
};

export const QuickLoginBar = (props: Props) => {
  const options = envi.isV2()
    ? [
        { name: "Felix", email: "kueppers@zeitguru.de", password: "allgood" },
        { name: "Tade", email: "tadeus.gregorian@zeitguru.de", password: "allgood" },
        { name: "Hayk", email: "hayk.gregorian@zeitguru.de", password: "allgood" },
        { name: "David", email: "david.gregorian@zeitguru.de", password: "allgood" },
      ]
    : [
        { name: "Felix", email: "kueppers@avetiq.de", password: "test1234" },
        { name: "Tade", email: "tadeus.gregorian@avetiq.de", password: "test1234" },
        { name: "Hayk", email: "hayk.gregorian@avetiq.de", password: "test1234" },
        { name: "David", email: "david.gregorian@avetiq.de", password: "test1234" },
      ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        zIndex: 999,
        marginTop: 20,
      }}
    >
      {options.map((o) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "solid 3px white",
            color: "white",
            padding: 20,
            margin: 10,
            borderRadius: 5,
            cursor: "pointer",
          }}
          key={o.email}
          onClick={() => {
            props.handleSubmit({
              email: o.email,
              password: o.password,
            });
          }}
        >
          {o.name}
        </div>
      ))}
    </div>
  );
};
