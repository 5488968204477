import { IAbsenceType } from "./../shared/entities/IAbsenceType";

import { BaseRepository } from "../frontend-core/BaseRepository";

class AbsenceTypeRepository extends BaseRepository<IAbsenceType> {
  constructor() {
    super("absenceTypes");
  }
}

export const absenceTypeRepository = new AbsenceTypeRepository();
