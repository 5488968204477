import React from "react";
import { Button, notification } from "antd";
import { openModal } from "./modal";
import { DispFn } from "../frontend-core/types/thunkTypes";
import { AppState } from "../types/AppState";
import { selectSessionInfo } from "../selectors/SessionInfoSelector";
import { tierInfoSelector } from "../selectors/TierInfoSelector";

// we are doing this, so stripe gets only injected when the user is going to the payment modal
const TierPicker = React.lazy(() => import("../components/TierPicker/TierPicker") as any) as any;

export const paidFeatureWarning = (featureName?: string) => {
  return async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const sessionInfo = selectSessionInfo(state);
    const tierInfo = tierInfoSelector(state);
    let message = !featureName
      ? lg.für_diese_funktion_wird_ein_upgrade_benötigt
      : lg.um_die_funktion_feature_zu_nutzen_brauchst_du_ein_upgrade(featureName);

    if (tierInfo.unpaidSinceTwoWeeks) {
      message = lg.auf_grund_einer_überfälligen_zahlung_vom_administrator_wurde_der_account_eingeschränkt;
    }

    const btn = !tierInfo.unpaidSinceTwoWeeks ? (
      <Button
        type="primary"
        size="small"
        style={{ marginTop: "-20px!important" }}
        onClick={() => dispatch(openModal(TierPicker, {}))}
        disabled={!sessionInfo.isAdmin()}
        data-rh={sessionInfo.isAdmin() ? null : lg.nur_ein_admin_kann_ein_upgrade_durchführen}
        data-rh-at="bottom"
        id="upgrade-now-button"
      >
        {lg.jetzt_upgraden}
      </Button>
    ) : null;

    notification.info({
      message,
      btn,
    });
  };
};
