import React from "react";

import { useDispatch } from "react-redux";
import { useState } from "react";
import TZModal from "../TZModal/TZModal";

import { Input, Button } from "antd";
import { closeModal } from "../../actions/modal";
import { useSelector } from "../../helpers/redux";
import { deleteAllShiftsOfWeek } from "../../actions/shift";
import { simpleDateToMoment } from "../../shared/helpers/timeHelpers";
import { sendPost } from "../../frontend-core/actions/send";
import { DispFn } from "../../frontend-core/types/thunkTypes";

type Props = {
  callback: () => any;
};

export const CancelSubscriptionModal = (props: Props) => {
  const dispatch = useDispatch<DispFn>();
  const [cancelReason, setCancelReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSure, setIsSure] = useState(false);

  return (
    <TZModal>
      <TZModal.Head>{lg.abo_kuendigen}</TZModal.Head>
      <TZModal.Body>
        <TZModal.Row className="noPadding">
          <div>
            <div style={{ marginBottom: 24, color: "red", maxWidth: 470 }}>
              {
                lg.hiermit_wird_ihr_abo_zum_ende_des_abrechnungszyklus_gekündigt_und_der_zugang_für_ihr_unternehmen_aufgehoben_kein_mitarbeiter_wird_sich_mehr_einloggen_können
              }
            </div>
            <div style={{ margin: "10px 0 0 0", fontWeight: 900 }}>
              {lg.bitte_erzählen_sie_uns_den_grund_ihrer_kündigung}{" "}
            </div>
            <Input.TextArea
              style={{ margin: "16px 0", height: 100 }}
              value={cancelReason}
              placeholder={lg.was_hat_ihnen_gefehlt}
              onChange={(e) => setCancelReason(e.target.value)}
            />
          </div>
        </TZModal.Row>
      </TZModal.Body>
      <TZModal.Footer>
        <Button
          id="cancel-cancel-subscription"
          onClick={async () => {
            dispatch(closeModal());
          }}
          children={lg.abbrechen}
        />
        {!isSure && (
          <Button
            id="cancel-subscription"
            type="danger"
            onClick={async () => {
              setIsSure(true);
            }}
            style={{ marginLeft: "auto" }}
            disabled={cancelReason.length <= 5}
            children={lg.abo_kündigen}
          />
        )}
        {isSure && (
          <Button
            id="cancel-subscription"
            type="danger"
            onClick={async () => {
              setIsLoading(true);
              await dispatch(sendPost("/api/cancel-subscription", { reason: cancelReason }));
              dispatch(closeModal());
              props.callback();
            }}
            loading={isLoading}
            style={{ marginLeft: "auto" }}
            disabled={cancelReason.length <= 5}
            children={lg.wirklich_kündigen}
          />
        )}
      </TZModal.Footer>
    </TZModal>
  );
};
