import { Button } from "antd";
import moment from "moment";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { closeModal } from "../../../actions/modal";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { selectActiveUsers } from "../../../selectors/ActiveUserSelectors";
import { IRuleSpecification } from "../../../shared/entities/IRuleSpecification";
import { nullifyProps } from "../../../shared/helpers/firebaseHelpers";
import { toSimpleDate } from "../../../shared/helpers/timeHelpers";
import { AppState } from "../../../types/AppState";
import { RuleSpecificationForm } from "../../RuleSpecificationForm/RuleSpecificationForm";
import TZModal from "../../TZModal/TZModal";
import "./styles.scss";

const mapStateToProps = (state: AppState) => ({
  activeUsers: selectActiveUsers(state, toSimpleDate(moment())),
  jobPositions: state.data.jobPositions,
  branches: state.data.branches,
});

type State = IRuleSpecification;

type OwnProps = {
  ruleSpecification?: IRuleSpecification;
  onComplete: (x: IRuleSpecification) => void;
  title?: string;
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _UserSpecificationModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.props.ruleSpecification || {};
  }

  saveClicked = async () => {
    this.props.onComplete(nullifyProps(this.state));
    this.props.dispatch(closeModal());
  };

  isSaveDisabled = () => {
    const { jobPositionIds, userIds, exceptUserIds, branchIds } = this.state;
    // if an option is checked, there needs to be selected IDs. Otherwise disable saving
    return (
      (userIds && !userIds.length) ||
      (exceptUserIds && !exceptUserIds.length) ||
      (jobPositionIds && !jobPositionIds.length) ||
      (branchIds && !branchIds.length)
    );
  };

  render() {
    return (
      <TZModal>
        <TZModal.Head title={this.props.title || lg.diese_regel_gilt_für}></TZModal.Head>
        <TZModal.Body>
          <RuleSpecificationForm
            ruleSpecification={this.state}
            onChange={(rule: IRuleSpecification) => this.setState({ ...rule })}
          />
        </TZModal.Body>
        <TZModal.Footer>
          <Button
            id="rule-specification-modal-save-button"
            type="primary"
            onClick={this.saveClicked}
            children={lg.Speichern}
            disabled={this.isSaveDisabled()}
          />
        </TZModal.Footer>
      </TZModal>
    );
  }
}

export const UserSpecificationModal = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  _UserSpecificationModal
);
