import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Icon } from "antd";
import cn from "classnames";
import WhiteZplanoLogo from "../Logos/WhiteZplanoLogo";

type Props = {
  isLoading;
};

export const GlobalLoader = (props: Props) => {
  const [isVisible, setVisible] = useState(false);
  const [isVisibleAnimate, setVisibleAnimate] = useState(false);

  useEffect(() => {
    const htmlNode = document.getElementsByTagName("html")[0];
    if (props.isLoading) {
      htmlNode.classList.add("disableScroll");
      setVisible(true);
      setVisibleAnimate(true);
    } else {
      htmlNode.classList.remove("disableScroll");
      setVisibleAnimate(false);
      setTimeout(() => {
        setVisible(false);
      }, 1000);
    }
  }, [props.isLoading]);

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={cn({
        globalLoadingOverlay: true,
        isVisibleAnimate,
      })}
    >
      <Icon
        type="loading"
        style={{
          fontSize: "130px",
          color: "white",
          marginBottom: 80,
        }}
        spin
      />
      <h1 style={{ fontWeight: 100 }}>
        {lg.willkommen_bei}{" "}
        <WhiteZplanoLogo
          style={{
            height: 43,
            marginBottom: -10,
            marginLeft: 1,
          }}
        />
      </h1>
      <span style={{ opacity: 0.8 }}>{lg.einen_augenblick_bitte_ihre_daten_werden_vorgeladen}</span>
    </div>
  );
};
