import { selectRosterDateRange } from "./rosterDateRangeSelector";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import { visibleShiftsSelector } from "./publishedShiftsSelector";
import { addSimpleDays, getPrevSimpleDate, rangesOverlap } from "../shared/helpers/dateHelpers";
import { createCachedSelector } from "re-reselect";
import { getOtherShifts, getShiftsOfRow } from "../pages/shiftsPage/RosterPage/helpers";
import { IShift } from "../shared/entities/IShift";
import { RosterFilter } from "../reducers/ui/shifts/roster/rosterFilter";
import { RosterMode } from "../reducers/ui/shifts/roster/rosterMode";
import { selectSessionInfo } from "./SessionInfoSelector";
import _ from "lodash";

export const relevantShiftsSelector = createSelector(
  [
    visibleShiftsSelector,
    selectRosterDateRange,
    (state: AppState) => state.ui.shifts.roster.rosterTemplateMode,
    (state: AppState) => state.ui.shifts.roster.rosterMode,
  ],
  (shifts, rosterDateRange, rosterTemplateMode, rosterMode) => {
    const isTemplateMode = rosterTemplateMode?.active;
    const selectedTemplate = rosterTemplateMode.rosterTemplate;
    let { rangeStart, rangeEnd } = rosterDateRange;

    if (rosterMode === RosterMode.Day) {
      rangeStart = getPrevSimpleDate(rangeStart); // to include nightshifts of previous day
    }

    if (isTemplateMode && selectedTemplate) {
      const from = selectedTemplate!.shiftsStoredAtDate;
      const to = addSimpleDays(from, 6);
      return shifts.filter((s) => s.date >= from && s.date <= to);
    }

    return shifts.filter((s) => s.date >= rangeStart && s.date <= rangeEnd);
  }
);

export const getRosterFilteredShifts = (shifts: IShift[], rosterFilter: RosterFilter) => {
  const { jobPositions, workSpaces, timeSpan, addresses, hashtags } = rosterFilter;
  const isRosterFilterActive =
    jobPositions.length || workSpaces.length || addresses.length || hashtags.length || timeSpan.length;

  if (!isRosterFilterActive) {
    return shifts;
  }

  return shifts.filter(
    (s) =>
      (!jobPositions.length || jobPositions.includes(s.jobPositionId)) &&
      (!workSpaces.length || (s.workSpaceId && workSpaces.includes(s.workSpaceId))) &&
      (!addresses.length || (s.addressId && addresses.includes(s.addressId))) &&
      (!hashtags.length || (s.hashtagIds && !!_.intersection(hashtags, s.hashtagIds).length)) &&
      (!timeSpan.length || rangesOverlap(s.startTime, s.endTime, timeSpan[0], timeSpan[1], { equalIsOverlap: false }))
  );
};

// if in the roster-action-bar there is an active filter > this selector applies that filter ot the roster
export const relevantShiftsFilteredSelector = createSelector(
  [
    (state: AppState) => relevantShiftsSelector(state),
    (state) => state.ui.shifts.roster.rosterFilter,
    (state) => state.ui.shifts.roster.rosterTemplateMode.active,
  ],
  (shifts, rosterFilter, isTemplateMode) => {
    // in Template-Mode ignore the shiftFilters
    return isTemplateMode ? shifts : getRosterFilteredShifts(shifts, rosterFilter);
  }
);

export const relevantShiftsOfBranchSelector = createSelector(
  [(state: AppState) => relevantShiftsFilteredSelector(state), (state: AppState) => state.ui.selectedBranch],
  (shifts, selectedBranch) => {
    return shifts.filter((s) => !selectedBranch || s.branchId === selectedBranch);
  }
);

export const shiftsOfRowSelector = createCachedSelector(
  (s: AppState) => s.ui.selectedBranch,
  (s: AppState) => relevantShiftsFilteredSelector(s),
  (s: AppState, _jpId: string | undefined) => _jpId,
  (s: AppState, _jpId: string | undefined, uid: string | undefined) => uid,
  (s: AppState, _jpId: string | undefined, uid: string | undefined, isRequirement?: boolean) => isRequirement,
  (s: AppState) => selectSessionInfo(s),
  (selectedBranchId, shifts, jpId, userId, isRequirement, sessionInfo) =>
    getShiftsOfRow(shifts, selectedBranchId, userId, jpId, isRequirement, sessionInfo.user)
)((_s: AppState, jobPositionId, userId, isRequirement) => userId + "" + jobPositionId + "" + isRequirement);

export const otherShiftsOfRowSelector = createCachedSelector(
  (s: AppState) => s.ui.selectedBranch,
  (s: AppState) => relevantShiftsSelector(s),
  (_s: AppState, jpId: string | undefined) => jpId,
  (_s: AppState, _jpId: string | undefined, userId: string | undefined) => userId,
  (selectedBranchId, shifts, jpId, userId) => getOtherShifts(shifts, selectedBranchId, userId, jpId)
)((_s: AppState, jobPositionId, userId) => userId + "" + jobPositionId);
