import { IAbsence } from "./../../shared/entities/IAbsence";
import { CreditInterval, CreditBalance, CreditBase } from "./creditTypes";
import { WeekDays } from "./../../shared/constants/WeekDays";
import { getWeekDay } from "./../../shared/helpers/weekDaysList";
import { IContract } from "../../shared/entities/IContract";
import { getMeanDailyQuota } from "../../shared/helpers/absences";
import { WorkInterval } from "../../shared/entities/IContract";
import { AbsenceEarning, AbsenceTypeCode } from "../../shared/entities/IAbsenceType";
import _ from "lodash";
import { isWorkDay } from "../../shared/helpers/credit";

export const getAbsenceEarningOfDate = ({ date, quotaOfDate, absence, contract, shiftEarnings }: any): number => {
  const isMonthly = contract.interval === WorkInterval.monthly;
  const isWeekly = contract.interval === WorkInterval.weekly;

  if (
    !quotaOfDate &&
    absence.earning !== AbsenceEarning.asPlaned &&
    !(isMonthly && absence.typeCode === AbsenceTypeCode.vacation) // in this case contract.vacationDayCredit is being credited
  ) {
    return 0;
  }

  const isHalfDay =
    (absence.startsHalfDay && date === absence.startDate) || (absence.endsHalfDay && date === absence.endDate);

  let absenceDayCredit = 0;

  switch (absence.earning) {
    case AbsenceEarning.none:
      absenceDayCredit = 0;
      break;
    case AbsenceEarning.dailyQuota:
      absenceDayCredit = quotaOfDate;
      break;
    case AbsenceEarning.meanQuota:
      absenceDayCredit = isWeekly ? getMeanDailyQuota(contract.dailyQuota) : quotaOfDate; // getMeanDailyQuota does ot work for monthly
      break;
    case AbsenceEarning.asPlaned:
      absenceDayCredit = shiftEarnings;
      break;
    default:
      return 0;
  }

  if (absence.typeCode === AbsenceTypeCode.vacation && isMonthly) {
    const weekDay = WeekDays[getWeekDay(date)];
    const quota = contract.dailyQuota[weekDay];
    absenceDayCredit = isWorkDay(quota) ? contract.vacationDayCredit || 0 : 0;
  }

  // if no illnessDayCredit is entred > use default like other absenceTypes > due to backward compatibility
  if (absence.typeCode === AbsenceTypeCode.illness && isMonthly && contract.illnessDayCredit) {
    const weekDay = WeekDays[getWeekDay(date)];
    const quota = contract.dailyQuota[weekDay];
    absenceDayCredit = isWorkDay(quota) ? contract.illnessDayCredit || 0 : 0;
  }

  return isHalfDay ? Math.floor(absenceDayCredit / 2) + shiftEarnings : absenceDayCredit;
};

export const emptyCreditBase = {
  credit: 0,
  creditInAbsence: 0,
  quota: 0,
};

export const getUsersWithMonthlyContract = (date: string, contracts: IContract[]): string[] =>
  _.uniq(
    contracts
      .filter((c) => c.validFrom <= date && c.validTo >= date && c.interval === WorkInterval.monthly)
      .map((c) => c.userId)
  );

export const getUsersWithMinMaxHours = (date: string, contracts: IContract[]): string[] =>
  _.uniq(contracts.filter((c) => c.validFrom <= date && c.validTo >= date && c.monthlyMaxHours).map((c) => c.userId));

export const getRoundenCreditInterval = (interval: CreditInterval): CreditInterval => ({
  quota: Math.round(interval.quota),
  credit: Math.round(interval.credit),
  creditInAbsence: Math.round(interval.creditInAbsence),
});

export const getRoundenCreditBase = (interval: CreditBase): CreditBase => ({
  quota: interval.quota, // we cant round the quota, as it causes rounding errors for the monthly-users in the reportings
  credit: Math.round(interval.credit),
  creditInAbsence: Math.round(interval.creditInAbsence),
});

export const getHolidayCredit = (contract: IContract, quotaOfDate: number, absence?: IAbsence): number =>
  contract.applyQuotaOnHolidays && (!absence || absence.earning === AbsenceEarning.asPlaned) ? quotaOfDate : 0;
