import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../types/AppState";
import "./styles.scss";
import { Button, Icon } from "antd";
import { trackingRepository } from "../../repositories/TrackingRepository";

import PrettyDate from "../PrettyDate/PrettyDate";

import PlanedAndTrackedTime from "../PlanedAndTrackedTime/PlanedAndTrackedTime";
import { busyInjector, BusyInjectorProps } from "../BusyInjector/BusyInjector";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { AvIcon } from "../AvIcon/AvIcon";
import { ITracking } from "../../shared/entities/ITracking";
import { ITimeClocking } from "../../shared/entities/ITimeClocking";
import { IShift } from "../../shared/entities/IShift";
import { isClockingEqualToTracking } from "../../shared/helpers/timeClockingHelpers";
import { getUserName } from "../../shared/helpers/userHelpers";
import { updateTracking } from "../../actions/tracking";

const mapStateToProps = (state: AppState) => {
  return {
    branches: state.data.branches,
    users: state.data.users,
    sessionInfo: selectSessionInfo(state),
  };
};

type OwnProps = {
  tracking: ITracking;
  shift: IShift;
  openModal: () => void;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & BusyInjectorProps & DispatchBaseProps;

class _TrackingRequestItem extends React.PureComponent<Props> {
  approveTracking = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { load, dispatch, tracking } = this.props;
    await load(dispatch(updateTracking({ ...tracking, isAccepted: true })));
  };

  getClockedIcon = () =>
    this.props.tracking.isAutoClockOut ? (
      <div
        className="timerIconWrapper autoClockedOut"
        data-rh={lg.automatisch_ausgestempelt_da_der_mitarbeiter_es_vergessen_hat}
      >
        <Icon type="export" />
      </div>
    ) : (
      <div className="timerIconWrapper clockeIconWrapper" data-rh={lg.per_stempeluhr_erfasst}>
        <AvIcon type="icon-timer-f" />
      </div>
    );

  getManualIcon = () => (
    <div className="timerIconWrapper manuallyEditedIconWrapper" data-rh={lg.manuell_eingetragene_zeit}>
      <Icon type="form" />
    </div>
  );

  render() {
    const { tracking, shift, isLoading, sessionInfo } = this.props;
    const canManage = sessionInfo.hasManagerPermissions();

    const canQuickAccept = !tracking.isAutoClockOut;
    const clockingEnapled = !!this.props.branches.find((b) => b.isClockingEnabled);
    const user = this.props.users.find((u) => u.id === tracking.userId)!;

    return (
      <div className="trackingRequestItemMain" key={tracking.id} onClick={this.props.openModal}>
        <div className="rowItem">
          <div className="listItem dateCell">
            <PrettyDate date={shift.date} />
          </div>
          <div className="listItem dateCell">
            <PlanedAndTrackedTime planedTime={shift.isDynamicClocked ? undefined : shift} trackedTime={tracking} />
            {clockingEnapled && (tracking.isManaully ? this.getManualIcon() : this.getClockedIcon())}
          </div>
          <div className="fb nameItem">
            <div className="user">{getUserName(user)}</div>
            {this.props.branches.length > 1 && (
              <div className="trackingBranch">
                {this.props.branches.find((branch) => branch.id === shift.branchId)!.name}
              </div>
            )}
          </div>
          <div className="buttonsWrapper" style={{ visibility: canManage && canQuickAccept ? "visible" : "hidden" }}>
            <Button
              id="tracking-request-entry-accept"
              size="default"
              icon="check"
              onClick={(e) => this.approveTracking(e)}
              loading={isLoading()}
              data-rh={lg.akzeptieren}
            />
          </div>
        </div>
      </div>
    );
  }
}

export const TrackingRequestItem = connect(mapStateToProps)(busyInjector(_TrackingRequestItem));
