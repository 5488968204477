import React from "react";
import "./styles.scss";
import { environment } from "../../env";

export const BackgroundSquareAnimation = () => {
  return (
    <div className={`backgroundAnimations ${environment.isV2() ? "zeitguru" : ""}`}>
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
};
