import React from "react";

export const WrongDeviceTimeWarning = () => {
  return (
    <div
      className="fb center aCenter jCenter"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        textAlign: "center",
        backgroundColor: "#1a90ff",
        fontSize: 18,
        color: "white",
        padding: 50,
      }}
    >
      {
        lg.leider_ist_die_eingestellte_zeit_ihres_betriebssystems_nicht_korrekt_bitte_korrigieren_sie_die_lokale_zeit_ihres_rechners_und_starten_die_anwendung_neu_vielen_dank
      }
    </div>
  );
};
