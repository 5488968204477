import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { InputNumber, Button, Icon } from "antd";
import TZModal from "../../TZModal/TZModal";
import { closeModal, openModal } from "../../../actions/modal";
import { Modal } from "antd";
import { Omit } from "lodash";
import { absenceEntitlementRepository } from "../../../repositories/absenceEntitlementRepository";
import "./styles.scss";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { busyInjector, BusyInjectorProps } from "../../BusyInjector/BusyInjector";
import { IAbsenceEntitlement } from "../../../shared/entities/IAbsenceEntitlement";
import { selectUsersFull } from "../../../selectors/usersFullSelector";
import { decimalSeparatorLocal } from "../../../helpers/dateFormatHelper";
import { BasicSelect } from "../../BasicSelect/BasicSelect";
import { selectActiveUsers, selectActiveUsersFull } from "../../../selectors/ActiveUserSelectors";
import { toSimpleDate } from "../../../shared/helpers/timeHelpers";
import moment, { Moment } from "moment";
import _ from "lodash";
import { selectAbsenceEntitlements } from "../../../selectors/absenceEntitlementsSelector";
import { v4 as uuid } from "uuid";
import { AbsenceDateSelect } from "../AbsenceModal/AbsenceDateSelect/AbsenceDateSelect";
import AbsenceTypeSelect from "../AbsenceModal/AbsenceTypeSelect";
import { AbsenceTypeCode } from "../../../shared/entities/IAbsenceType";
import { RuleSpecificationBtn } from "../../RuleSpecificationBtn/RuleSpecificationBtn";
import { rosterSettingsRepository } from "../../../repositories/rosterSettingsRepository";
import { IRosterSettings, IVacationBan } from "../../../shared/entities/IRosterSettings";
import { isRuleApplyingToUser } from "../../../shared/helpers/settingsHelpers";
import { IRuleSpecification } from "../../../shared/entities/IRuleSpecification";
import { UserSpecificationModal } from "../UserSpecificationModal/UserSpecificationModal";
import { nullifyProps } from "../../../shared/helpers/firebaseHelpers";

const mapStateToProps = (state: AppState) => ({
  users: selectUsersFull(state),
  jobPositions: state.data.jobPositions,
  branches: state.data.branches,
  activeUsersFull: selectActiveUsersFull(state, toSimpleDate(moment())),
  selectedYear: state.ui.absences.selectedYear as number,
  selectedMonth: state.ui.absences.selectedMonth as number,
  isYearView: state.ui.absences.isYearView,
  absenceTypes: state.data.absenceTypes,
  selectedTypeId: state.ui.absences.selectedTypeId as string,
  absenceEntitlements: selectAbsenceEntitlements(state),
  rosterSetting: state.data.rosterSettings[0],
});

type State = {
  from?: string;
  until?: string;
  typeId?: string;
  appliesTo?: IRuleSpecification;
  confirmDelete?: boolean;
};

type OwnProps = {
  entity?: IVacationBan;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class VacationBansModalComp extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const vacTypeId = props.absenceTypes.find((t) => t.code === AbsenceTypeCode.vacation)?.id;

    this.state = {
      from: props.entity?.from || undefined,
      until: props.entity?.until || undefined,
      typeId: props.entity ? props.entity.typeId : vacTypeId,
      appliesTo: props.entity?.appliesTo || undefined,
    };
  }

  async save() {
    const { entity, rosterSetting, dispatch } = this.props;
    const freshBan = nullifyProps({
      from: this.state.from!,
      until: this.state.until!,
      typeId: this.state.typeId,
      appliesTo: this.state.appliesTo,
    });

    const prevBans = this.props.rosterSetting.vacationBans || [];
    const newBans = entity ? prevBans.map((b) => (_.isEqual(entity, b) ? freshBan : b)) : [...prevBans, freshBan];
    const freshRosterSetting: IRosterSettings = { ...rosterSetting, vacationBans: newBans };

    await dispatch(rosterSettingsRepository.update(freshRosterSetting));
    dispatch(closeModal());
  }

  datesChanged = (fromMom: Moment | undefined, endDateMom?: Moment) => {
    const from = fromMom ? toSimpleDate(fromMom.startOf("day")) : undefined;
    let until = endDateMom ? toSimpleDate(endDateMom.startOf("day")) : undefined;

    const isEndDateBeforeStartDate = from && until && until < from;
    isEndDateBeforeStartDate && (until = from);

    this.setState({ from, until });
  };

  openUserSpecificationModal = () => {
    const { dispatch } = this.props;
    dispatch(
      openModal(UserSpecificationModal, {
        ruleSpecification: this.state.appliesTo,
        title: lg.sperre_gilt_fuer,
        onComplete: (userSpecification: IRuleSpecification) => {
          this.setState({ appliesTo: userSpecification });
        },
      })
    );
  };

  deleteClicked = () => {
    this.state.confirmDelete ? this.delete() : this.setState({ confirmDelete: true });
  };

  delete = () => {
    const { entity, rosterSetting, dispatch } = this.props;
    const prevBans = this.props.rosterSetting.vacationBans || [];
    const newBans = prevBans.filter((b) => !_.isEqual(entity, b));
    const freshRosterSetting: IRosterSettings = { ...rosterSetting, vacationBans: newBans };
    this.props.load(dispatch(rosterSettingsRepository.update(freshRosterSetting)), "delete");
    this.props.dispatch(closeModal());
  };

  render() {
    const { selectedYear, selectedMonth, activeUsersFull } = this.props;
    const isCreationMode = !this.props.entity;

    const includingUsers = activeUsersFull.filter((u) => isRuleApplyingToUser(this.state.appliesTo, u));
    const includesAll = includingUsers.length === activeUsersFull.length;

    return (
      <TZModal>
        <TZModal.Head title={lg.Neue + " " + lg.urlaubssperre}></TZModal.Head>
        <TZModal.Body style={{ minWidth: 400, width: 400 }}>
          <div className="vacationBanModal">
            <div className="infoText">{lg.Mitarbeiter_koennen_keine_abwesenheiten_beantragen}</div>

            <div className="selectWrapper dateSelectWrapper">
              <AbsenceDateSelect
                startDate={this.state.from}
                endDate={this.state.until}
                selectedYear={selectedYear}
                selectedMonth={selectedMonth}
                onDatesChange={(a, b) => this.datesChanged(a, b)}
                readOnly={false}
                isYearView={this.props.isYearView}
              />
            </div>

            <div className="selectWrapper">
              <AbsenceTypeSelect
                selectedTypeId={this.state.typeId}
                selectType={(typeId?: string) => this.setState({ typeId })}
                absenceTypes={this.props.absenceTypes}
                canMangaAbsences={true}
                withOptionAllTypes={true}
                placeholder={lg.alle_typen}
              />
            </div>

            <div className="validForUsers" data-rh={lg.editieren} onClick={this.openUserSpecificationModal}>
              {lg.gilt_für_x_mitarbeiter(includesAll ? lg.alle : includingUsers.length)}
            </div>
          </div>
        </TZModal.Body>
        <TZModal.Footer>
          {!isCreationMode && (
            <Button
              id="delete-vacation-ban-button"
              style={{ marginRight: "auto" }}
              type={this.state.confirmDelete ? "danger" : "default"}
              onClick={() => this.deleteClicked()}
              loading={this.props.isLoading("delete")}
              data-rh={this.state.confirmDelete ? lg.wirklich_löschen : null}
            >
              {this.state.confirmDelete ? "?" : <Icon type="delete" />}
            </Button>
          )}

          <Button
            id="save-vacation-ban"
            type="primary"
            onClick={() => this.props.load(this.save(), "save")}
            disabled={!this.state.from || !this.state.until}
            loading={this.props.isLoading("save")}
          >
            {lg.Speichern}
          </Button>
        </TZModal.Footer>
      </TZModal>
    );
  }
}

export const VacationBansModal = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  busyInjector(VacationBansModalComp)
);
