import { AppState } from "./../types/AppState";
import { Dispatch } from "redux";

export const collapsedJobPositionsActionTypes = {
  SET: "@@AV/EXPANDED_JOB_POSITIONS",
};

export const toggleJobPositionExpanded = (jpId: string) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const collapsedJobPositions = getState().ui.shifts.roster
      .collapsedJobPositions as string[];
    const next = collapsedJobPositions.find((id) => id === jpId)
      ? collapsedJobPositions.filter((id) => id !== jpId)
      : [...collapsedJobPositions, jpId];

    dispatch(setExpandedJobPositions(next));
  };
};

export const setExpandedJobPositions = (payload: string[]) => ({
  type: collapsedJobPositionsActionTypes.SET,
  payload,
});
