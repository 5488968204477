import React from "react";
import { Tiers } from "../shared/constants/Tiers";
import * as Sentry from "@sentry/browser";
import { environment } from "../env";

type Props = {
  name?: string;
  email?: string;
  tenantId?: string;
  role?: string;
  accountId?: string;
  billableUsers?: number;
  tier?: Tiers;
  daysUntilTrialEnd?: number;
};

class CrispInjector extends React.Component<Props> {
  componentDidMount() {
    // Include the Crisp code here, without the <script></script> tags
    (window as any).$crisp = [];
    (window as any).CRISP_WEBSITE_ID = environment.isV2()
      ? "6463a4b8-b334-4bc4-9a85-99edd50f19e9"
      : "dbdba2bc-24f2-41a8-addc-ead0901b027c";

    (window as any).CRISP_TOKEN_ID = this.props.accountId;

    //(window as any).$crisp.push(["do", "chat:hide"]);

    (function () {
      console.log("Mountitt");
      var d = document;
      var s = d.createElement("script") as any;

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);

      (window as any)?.$crisp?.push([
        "on",
        "chat:closed",
        () => {
          console.log("Closing");
          (window as any).$crisp?.push(["do", "chat:hide"]);
        },
      ]);
    })();

    (window as any).$crisp.push([
      "set",
      "session:data",
      [
        [
          ["billableUsers", this.props.billableUsers],
          ["tier", this.props.tier],
          ["daysUntilTrialEnd", this.props.daysUntilTrialEnd],
          ["accountId", this.props.accountId],
          ["tenantId", this.props.tenantId],
          ["name", this.props.name],
          ["email", this.props.email],
          ["role", this.props.role],
        ],
      ],
    ]);
    (window as any).$crisp.push(["set", "user:email", this.props.email]);
    (window as any).$crisp.push(["set", "user:nickname", [this.props.name]]);
    (window as any).$crisp.push([
      "on",
      "message:received",
      (m) => {
        if (m !== lg.haben_sie_fragen) {
          // We don't want to open crisp always, when the welcome message comes in
          openCrisp(false);
        }
      },
    ]);
  }

  render() {
    return <div />;
  }
}

const openCrisp = (openFully = true) => {
  console.log("HH");

  if (!(window as any)?.$crisp) {
    console.log("No $crips on window");
    Sentry.captureException(new Error("No $crips on window"));
  }

  (window as any)?.$crisp?.push(["do", "chat:show"]);
  if (openFully) {
    console.log("SHOW");
    (window as any)?.$crisp?.push(["do", "chat:open"]);
  }
};

export const openCrispSupportMessage = () => {
  const text = lg.haben_sie_fragen;

  setTimeout(() => {
    openCrisp(true);
    (window as any)?.$crisp.push(["do", "message:show", ["text", text]]);
  }, 2000);
};

export { CrispInjector, openCrisp };
