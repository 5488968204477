import { CreditIntervalsMap } from "./creditTypes";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import { getCreditBases } from "./getCreditBases";
import { AppState } from "../../types/AppState";
import { generateDatesList } from "../../shared/helpers/dateHelpers";
import _ from "lodash";
import { emptyCreditBase, getRoundenCreditInterval } from "./creditHelpers";

export const getCreditIntervals = (
  _userIds: string[],
  fromDate: string,
  untilDate: string
) => (dispatch: DispFn, getState: () => AppState): CreditIntervalsMap => {
  if (!_userIds.length) {
    return {};
  }

  const userIds = _.uniq(_userIds);

  const ranges = userIds.map((userId) => ({ userId, fromDate, untilDate }));
  const creditBasesMap = dispatch(getCreditBases(ranges));

  const intervals = {} as CreditIntervalsMap;
  const dates = generateDatesList(fromDate, untilDate);

  userIds.forEach((userId) => {
    const key = `${userId}_${fromDate}_${untilDate}`;
    intervals[key] = { quota: 0, credit: 0, creditInAbsence: 0 };
    dates.forEach((date) => {
      const creditBase = creditBasesMap[`${userId}_${date}`] || emptyCreditBase;

      intervals[key].quota += creditBase.quota;
      intervals[key].credit += creditBase.credit;
      intervals[key].creditInAbsence += creditBase.creditInAbsence;
    });
    intervals[key] = getRoundenCreditInterval(intervals[key]);
  });

  return intervals;
};
