import { selectShiftMap } from "./../selectors/shiftMapSelector";
import { ITimeClocking, ITimeClockingDB } from "./../shared/entities/ITimeClocking";
import { AppState } from "./../types/AppState";
import { DispFn } from "./../frontend-core/types/thunkTypes";
import { BaseRepository, CrudActionTypes, getActionTypes } from "../frontend-core/BaseRepository";
import { shiftRepository } from "./shiftRepository";
import { timeClockingToDb } from "../shared/helpers/dbTransforms";
import _ from "lodash";
import { IFilterExpression } from "../shared/types/queryParams";
import { IShift } from "../shared/entities/IShift";
import { StandardFilterExpression } from "../shared/helpers/queryParams";
import { CrudOperation } from "../shared/helpers/firebaseHelpers";
import { getClockingMiniUpdates, getShiftMiniUpdates } from "../shared/helpers/creditSyncHelpers";
import { selectTimeClockingMap } from "../selectors/mapSelectors";

class TimeClockingRepository extends BaseRepository<ITimeClocking, ITimeClockingDB> {
  constructor() {
    super("timeClockings");
  }

  editComment(clockingId: string, comment: string | undefined) {
    return async (dispatch: DispFn, getState: () => AppState) => {
      const tenantId = getState().data.auth.session!.tenantId;
      const ref = this.getBaseRef(tenantId);
      await ref.child(clockingId).child("comment").set(comment);
      this.fetchOne(clockingId); // to update store
    };
  }

  toDbEntity(s: ITimeClocking): ITimeClockingDB {
    return timeClockingToDb(s);
  }

  toLocalEntity(s: ITimeClockingDB): ITimeClocking {
    return _.omit(s, ["branchId_date", "userId_date"]);
  }

  onListenerEvent(trackings: ITimeClocking[]) {
    return (dispatch: DispFn, getState: () => AppState): void => {
      if (!getState().data.tenantInfo.isV2) {
        const shiftMap = selectShiftMap(getState());
        const missingShiftIds = trackings.map((t) => t.id).filter((shiftId) => !shiftMap[shiftId]);

        if (missingShiftIds.length) {
          dispatch(shiftRepository.fetchByIds(missingShiftIds));
        }
      }
    };
  }

  getExtraUpdates = (actions: CrudOperation<ITimeClocking>[]) => {
    return (dispatch: DispFn, getState: () => AppState) => {
      const isV2 = getState().data.tenantInfo.isV2;
      const clockingsMap = selectTimeClockingMap(getState());

      return isV2 ? getClockingMiniUpdates(actions, clockingsMap) : {};
    };
  };

  storeActionFollowUp(
    writingEntities: ITimeClocking[],
    removingIds?: string[],
    clearSelection?: IFilterExpression<ITimeClocking> | "all"
  ) {
    return (dispatch: DispFn, getState: () => AppState) => {
      const isV2 = getState().data.tenantInfo.isV2;
      if (isV2) {
        const freshShifts: IShift[] = writingEntities.map((c) => ({
          id: c.id,
          date: c.date,
          userId: c.userId,
          branchId: c.branchId,
          jobPositionId: c.jobPositionId,
          startTime: c.startTime,
          endTime: c.endTime || c.startTime,
          breakMinutes: c.breakMinutes,
          comment: c.comment,
          addressId: c.addressId,
          workSpaceId: c.workSpaceId,
          hashtagIds: c.hashtagIds,
          //isNoShow: !c.isAccepted,
          //surcharge: c.surcharge,
          //surchargeInPercentage: c.surchargeInPercentage,
          isActiveClocking: !c.endTime && !c.isAutoClockOut,
        }));

        const getShiftAction = (type: keyof CrudActionTypes, payload?: any) => ({
          type: getActionTypes("shifts")[type],
          payload,
        });

        if (clearSelection) {
          clearSelection === "all"
            ? dispatch(getShiftAction("clearSelection", "all"))
            : dispatch(getShiftAction("clearSelection", new StandardFilterExpression(clearSelection)));
        }

        removingIds?.length && dispatch(getShiftAction("removeList", removingIds));
        freshShifts.length && dispatch(getShiftAction("addList", freshShifts));
      }
    };
  }
}

export const timeClockingRepository = new TimeClockingRepository();
