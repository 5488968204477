import { decryptUser } from "./../shared/helpers/userHelpers";
import { UserInfo } from "../shared/helpers/UserInfo";
import { AppState } from "../types/AppState";
import { createSelector } from "reselect";
import { IUser, IUserFull, RoleType } from "../shared/entities/IUser";
import * as Sentry from "@sentry/browser";

export const selectUsersFull = createSelector(
  [(state: AppState) => state.data.auth.session, (state: AppState) => state.data.users],
  (session, users): IUserFull[] => {
    if (!session) {
      return [];
    }

    if (session.role === RoleType.employee) {
      console.error("Employee is referencing UsersFull");
      Sentry.captureException(new Error("Employee is referencing UsersFull"));
      return [];
    }

    return users.map((user) => decryptUser(user));
  }
);
