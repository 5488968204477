import React from "react";
import "./styles.scss";

type Props = {
  startTime?: string;
  endTime?: string;
};

export class ShiftMoonIcon extends React.PureComponent<Props> {
  render() {
    return (
      <div className="shiftIcon shiftMoonIconMain" data-rh-at="bottom" data-rh={lg.schicht_endet_am_folgetag}>
        {/* <AvIcon type="icon-moon" /> */}

        <svg width="12px" height="12px" viewBox="0 0 203 203" version="1.1">
          <g id="Page-1" stroke="none" strokeWidth="1" fill="#235e8c" fillRule="evenodd">
            <path
              d="M199.876,119.867 C197.471,117.981 194.161,117.743 191.51,119.267 C178.94,126.492 165.654,130.156 152.021,130.156 C108.217,130.156 72.581,94.518 72.581,50.713 C72.581,37.02 76.25,23.752 83.487,11.279 C85.024,8.631 84.796,5.314 82.911,2.9 C81.027,0.486 77.864,-0.541 74.922,0.309 C53.761,6.409 34.715,19.351 21.293,36.75 C7.363,54.809 0,76.437 0,99.297 C0,156.332 46.4,202.735 103.434,202.735 C126.294,202.735 147.927,195.375 165.995,181.449 C183.406,168.03 196.354,148.995 202.454,127.849 C203.302,124.911 202.282,121.754 199.876,119.867 Z"
              id="Shape"
            ></path>
          </g>
        </svg>
      </div>
    );
  }
}
