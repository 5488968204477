import React from "react";
import { Select, Icon, Divider } from "antd";
import { SelectProps } from "antd/lib/select";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { pastelColors } from "../../../../frontend-core/helpers/colors";
import { IWorkSpace } from "../../../../shared/entities/IWorkSpace";
import { openModal } from "../../../../actions/modal";
import { AddWorkSpaceModal } from "../../../AddWorkSpaceModal/AddWorkSpaceModal";
import { antDesignSelectFilterSearch } from "../../../../frontend-core/helpers/frontendHelpers";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import "./styles.scss";
import _ from "lodash";
import { selectWorkSpaces } from "../../../../selectors/_workSpacesSelector";

const mapStateToProps = (state: AppState) => {
  return {
    workSpaces: selectWorkSpaces(state),
    canManage: selectSessionInfo(state).hasManagerPermissions(),
  };
};

type State = {};
type OwnProps = {
  onChange: (value: string) => void;
  value: string | undefined;
  closeField: () => void;
  isDisplayOnly?: boolean;
  noAutoFocus?: boolean;
} & SelectProps<any>;

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _WorkSpaceSelect extends React.PureComponent<Props, {}> {
  renderDisplayOnly = (workSpace: IWorkSpace) => (
    <div className="row fb aCenter rectBox">
      <div
        className="colorRectWorkSpaceSelect"
        style={{
          background: workSpace.color,
          color: pastelColors.includes(workSpace.color) ? "inherit" : "white",
        }}
      ></div>
      <div>{workSpace.name}</div>
    </div>
  );

  addNewWorkspaceClicked = () => {
    this.props.dispatch(openModal(AddWorkSpaceModal, { onComplete: (v) => this.props.onChange(v.id) }));
  };

  render() {
    const { value, onChange, workSpaces, isDisplayOnly, canManage, noAutoFocus, ...otherProps } = this.props;
    const selectedWorkSpace = value ? workSpaces.find((ws) => ws.id === value) : undefined;

    let selectableWorkSpaces = workSpaces.filter((ws) => !ws.isInactive || ws.id === value);
    selectableWorkSpaces = _.orderBy(selectableWorkSpaces, (w) => w.name.toLowerCase());

    return (
      <div className="avInputFieldMain">
        <span className="labelWrapper">
          <label>{lg.label}</label>
        </span>
        <div
          className="workSpaceSelectWrapper"
          data-rh={selectedWorkSpace && selectedWorkSpace.name.length > 50 ? selectedWorkSpace.name : undefined}
        >
          {isDisplayOnly ? (
            selectedWorkSpace && this.renderDisplayOnly(selectedWorkSpace)
          ) : (
            <Select
              id="work-space-select"
              value={value}
              onChange={(value: string) => this.props.onChange(value)}
              style={{ width: "100%" }}
              showSearch={workSpaces.length > 6}
              autoFocus={!noAutoFocus && canManage && !value} // autofocus only when expanding
              showAction={noAutoFocus ? undefined : ["focus", "click"]} // needed for autofocus to type in for search
              filterOption={antDesignSelectFilterSearch}
              allowClear={false}
              {...otherProps}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider style={{ margin: "4px 0" }} />
                  <div className="addNewWorkSpaceButton" onMouseDown={this.addNewWorkspaceClicked}>
                    <Icon type="plus" /> {lg.neues_label_erstellen}
                  </div>
                </div>
              )}
            >
              {selectableWorkSpaces.map((jP) => (
                <Select.Option value={jP.id} key={jP.id} title={jP.name}>
                  <div className="row fb aCenter">
                    <div className="colorRectWorkSpaceSelect" style={{ background: jP.color }} />
                    {jP.name}
                  </div>
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
        {!isDisplayOnly && (
          <div
            className="closeExpandedFieldButton"
            onClick={() => {
              this.props.onChange("");
              this.props.closeField();
            }}
          >
            <Icon type="close" />
          </div>
        )}
      </div>
    );
  }
}

export const WorkSpaceSelect = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(_WorkSpaceSelect);
