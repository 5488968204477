import React, { PureComponent } from "react";
import "./styles.scss";
import TZModal from "../../TZModal/TZModal";

import { Input, Button, Checkbox, Modal, Radio, Popover, Icon, notification } from "antd";
import AvFormField from "../../AvFormField/AvFormField";
import AvColorPicker from "../../AvColorPicker/AvColorPicker";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { connect } from "react-redux";
import { closeModal } from "../../../actions/modal";
import { absenceTypeRepository } from "../../../repositories/absenceTypeRepository";
import { absenceRepository } from "../../../repositories/absenceRepository";
import { RadioChangeEvent } from "antd/lib/radio";
import { busyInjector, BusyInjectorProps } from "../../BusyInjector/BusyInjector";
import { IAbsenceType, AbsenceTypeCode, AbsenceEarning } from "../../../shared/entities/IAbsenceType";

const mapStateToProps = (state: AppState) => ({
  absences: state.data.absences,
  isV2: state.data.tenantInfo.isV2,
});

type State = {
  absenceType: Partial<IAbsenceType>;
  earningUpdateNoteWasShown: boolean; // informing the user that 'earning' changes doesnt effect existing absences
};

type OwnProps = { absenceType?: IAbsenceType };
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

class AbsenceTypeModalComponent extends PureComponent<Props, State> {
  creationMode: boolean;

  constructor(props: Props) {
    super(props);

    this.creationMode = !this.props.absenceType;

    this.state = {
      absenceType: {
        name: props.absenceType?.name,
        color: props.absenceType?.color,
        code: props.absenceType?.code,
        earning: props.absenceType?.earning || AbsenceEarning.dailyQuota,
        allowHalfDay: props.absenceType?.allowHalfDay,
        canBeRequested: props.absenceType?.canBeRequested,
        isVisibleToColleagues: props.absenceType?.isVisibleToColleagues,
        hasEntitlement: props.absenceType?.hasEntitlement,
      },
      earningUpdateNoteWasShown: false,
    };
  }

  updateAbsenceType = (newProps: Partial<IAbsenceType>) => {
    this.setState({ absenceType: { ...this.state.absenceType, ...newProps } });
  };

  load = async <T extends any>(asyncAction: Promise<T>): Promise<T> => {
    const result = await asyncAction;
    return result;
  };

  saveClicked = async () => {
    const freshAbsenceType = {
      ...(this.props.absenceType || {}),
      ...this.state.absenceType,
      code: this.creationMode ? AbsenceTypeCode.custom : this.props.absenceType!.code,
    } as IAbsenceType;

    console.log(freshAbsenceType);

    await this.load(
      this.props.dispatch(
        this.creationMode
          ? absenceTypeRepository.create(freshAbsenceType)
          : absenceTypeRepository.update(freshAbsenceType)
      )
    );

    this.props.dispatch(closeModal());
  };

  deleteClicked = async () => {
    const absencesOfType = this.props.absences.filter((a) => a.typeId === this.props.absenceType!.id);
    const absencesExistWithType = !!absencesOfType.length;

    if (absencesExistWithType) {
      Modal.warning({
        title: lg.löschen_nicht_möglich,
        content: lg.das_löschen_von_bereits_verwendeten_abwesenheitstypen_ist_nicht_möglich,
        onOk: () => {
          this.props.dispatch(closeModal());
        },
        okText: lg.ok,
      });
      return;
    }

    await this.load(this.props.dispatch(absenceTypeRepository.remove(this.props.absenceType!.id)));

    this.props.dispatch(closeModal());
  };

  changedIsPaid = (isPaid: boolean) => {
    this.updateAbsenceType({
      earning: isPaid ? AbsenceEarning.dailyQuota : AbsenceEarning.none,
    });
  };

  changedEarning = (earning: AbsenceEarning) => {
    this.updateAbsenceType({ earning });

    if (!this.state.earningUpdateNoteWasShown && !this.creationMode && this.props.absences.length) {
      const message = lg.bereits_erstellte_abwesenheiten_bleiben_von_dieser_änderung_unbetroffen;
      notification.warning({ message });
      this.setState({ earningUpdateNoteWasShown: true });
    }
  };

  getPopoverContnet_1 = () => {
    return (
      <div className="absenceTypeInfoPopoverContnet">
        <div className="title">{lg.durchschnittliche_sollstunden}</div>
        <div className="content">{lg.durchschnittliche_sollstunden_explenation()}</div>
      </div>
    );
  };

  getPopoverContnet_2 = () => {
    return (
      <div className="absenceTypeInfoPopoverContnet">
        <div className="title">{lg.tägliche_sollstunden}</div>
        <div className="content">{lg.tägliche_sollstunden_explenation()}</div>
      </div>
    );
  };

  getPopoverContnet_3 = () => {
    return (
      <div className="absenceTypeInfoPopoverContnet">
        <div className="title">{lg.planstunden}</div>
        <div className="content">
          {lg.dem_mitarbeiter_wird_die_zeit_gutgeschrieben_wie_dieser_im_dienstplan_eingeplant_ist}
        </div>
      </div>
    );
  };

  render() {
    const { name, color, earning, allowHalfDay } = this.state.absenceType;
    const { absenceType, isV2 } = this.props;
    const isCustomAbsenceType = this.creationMode || absenceType!.code === AbsenceTypeCode.custom;

    return (
      <TZModal>
        <TZModal.Head title={lg.abwesenheitstyp}></TZModal.Head>
        <TZModal.Body>
          <div className="absenceTypeModal">
            <div className="formFieldWrapper marginBottom">
              <AvFormField label={lg.bezeichnung}>
                <Input
                  value={name}
                  onChange={(e) => this.updateAbsenceType({ name: e.target.value })}
                  disabled={!isCustomAbsenceType}
                />
              </AvFormField>
            </div>

            <div className="formFieldWrapper marginBottom">
              <AvFormField label={lg.farbe}>
                <AvColorPicker value={color} onChange={(color) => this.updateAbsenceType({ color })} />
              </AvFormField>
            </div>

            {this.props.absenceType?.code === AbsenceTypeCode.vacation && (
              <div className="formFieldWrapper">
                <Checkbox
                  checked={allowHalfDay}
                  onChange={(e) => this.updateAbsenceType({ allowHalfDay: e.target.checked })}
                >
                  {lg.halbe_urlaubstage_erlauben}
                </Checkbox>
              </div>
            )}

            {this.state.absenceType.code !== AbsenceTypeCode.illness && (
              <div className="formFieldWrapper">
                <Checkbox
                  checked={absenceType?.code === AbsenceTypeCode.vacation || !!this.state.absenceType.hasEntitlement}
                  onChange={(e) => this.updateAbsenceType({ hasEntitlement: e.target.checked })}
                  disabled={absenceType?.code === AbsenceTypeCode.vacation}
                >
                  {lg.mit_kontingent}
                </Checkbox>
              </div>
            )}

            <div className="formFieldWrapper">
              <Checkbox
                checked={earning !== AbsenceEarning.none}
                onChange={(e) => this.changedIsPaid(e.target.checked)}
                disabled={!isCustomAbsenceType}
              >
                {lg.bezahlte_abwesenheit}
              </Checkbox>
            </div>

            {earning !== AbsenceEarning.none && !isV2 && (
              <div className="formFieldWrapper applyMeanQuotaField marginBottom">
                <div className="fb row aCenter">
                  <Radio
                    onChange={(e: RadioChangeEvent) => this.changedEarning(e.target.value)}
                    style={radioStyle}
                    value={AbsenceEarning.meanQuota}
                    checked={earning === AbsenceEarning.meanQuota}
                  >
                    {lg.durchnittliche_sollstunden_gutschreiben}
                  </Radio>
                  <Popover placement="topLeft" title={null} content={this.getPopoverContnet_1()} trigger="click">
                    <div className="infoIconWrapper" data-rh={lg.klicken_für_weitere_infos}>
                      <Icon type="info-circle" />
                    </div>
                  </Popover>
                </div>

                <div className="fb row aCenter">
                  <Radio
                    onChange={(e: RadioChangeEvent) => this.changedEarning(e.target.value)}
                    style={radioStyle}
                    value={AbsenceEarning.dailyQuota}
                    checked={earning === AbsenceEarning.dailyQuota}
                  >
                    {" "}
                    {lg.tägliche_sollstunden_gutschreiben}{" "}
                  </Radio>
                  <Popover placement="topLeft" title={null} content={this.getPopoverContnet_2()} trigger="click">
                    <div className="infoIconWrapper" data-rh={lg.klicken_für_weitere_infos}>
                      <Icon type="info-circle" />
                    </div>
                  </Popover>
                </div>

                <div className="fb row aCenter">
                  <Radio
                    onChange={(e: RadioChangeEvent) => this.changedEarning(e.target.value)}
                    style={radioStyle}
                    value={AbsenceEarning.asPlaned}
                    checked={earning === AbsenceEarning.asPlaned}
                  >
                    {" "}
                    {lg.planstunden_gutschreiben}{" "}
                  </Radio>
                  <Popover placement="topLeft" title={null} content={this.getPopoverContnet_3()} trigger="click">
                    <div className="infoIconWrapper" data-rh={lg.klicken_für_weitere_infos}>
                      <Icon type="info-circle" />
                    </div>
                  </Popover>
                </div>
              </div>
            )}
            <div className="formFieldWrapper" style={{ marginTop: 0 }}>
              <Checkbox
                checked={this.state.absenceType.canBeRequested}
                onChange={(e) => this.updateAbsenceType({ canBeRequested: e.target.checked })}
              >
                {lg.kann_von_mitarbeitern_beantragt_werden}
              </Checkbox>
            </div>
            <div className="formFieldWrapper" style={{ marginTop: 0 }}>
              <Checkbox
                checked={this.state.absenceType.isVisibleToColleagues}
                onChange={(e) => this.updateAbsenceType({ isVisibleToColleagues: e.target.checked })}
              >
                {lg.mitarbeiter_können_die_abwesenheiten_der_kollegen_sehen}
              </Checkbox>
            </div>
          </div>
        </TZModal.Body>
        <TZModal.Footer>
          {!this.creationMode && isCustomAbsenceType && (
            <Button
              id="delete-absence-type"
              type="dashed"
              onClick={async () => this.props.load(this.props.dispatch(this.deleteClicked), "deleting")}
              loading={this.props.isLoading("deleting")}
              icon="delete"
            />
          )}
          <Button
            id="save-absence-type"
            type="primary"
            onClick={async () => this.props.load(this.props.dispatch(this.saveClicked), "saving")}
            loading={this.props.isLoading("saving")}
            children={lg.Speichern}
            disabled={!name || !color}
          />
        </TZModal.Footer>
      </TZModal>
    );
  }
}

export const AbsenceTypeModal = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  busyInjector(AbsenceTypeModalComponent)
);
