import { selectSessionInfo } from "./../selectors/SessionInfoSelector";
import { selectShiftMap } from "./../selectors/shiftMapSelector";
import { AppState } from "../types/AppState";
import { DispFn } from "../frontend-core/types/thunkTypes";
import {
  canUserEditOwnShiftsOfDate,
  getClickedShiftKey,
  getClickedSlotID,
  getShiftCoreBySlotKey,
} from "../pages/shiftsPage/RosterPage/helpers";
import { openModal } from "./modal";
import { ShiftPopup } from "../components/ShiftPopup/ShiftPopup/ShiftPopup";
import { featuresSelector } from "../selectors/FeaturesSelector";
import { paidFeatureWarning } from "./paidFeatureWarning";
import { RoleType } from "../shared/entities/IUser";
import { selectRosterSettingsByUser } from "../selectors/rosterSettingsByUserSelector";
import { startTimeClocking } from "./timeClocking";
import { selectTimeClockSettingsByUser } from "../selectors/timeClockSettingsByUserSelector";
import { canEmployeAddEditPunchingOnDate } from "../frontend-core/helpers/frontendHelpers";
import { SDateFormat } from "../shared/helpers/SimpleTime";
import moment from "moment";
import { selectTimeClockSettingsOfSesionUser } from "../selectors/timeClockSettingsOfSessionUser";

export const rosterElementClicked = (e: any) => {
  return async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const isV2 = state.data.tenantInfo.isV2;
    const userTimeClockSettings = selectTimeClockSettingsOfSesionUser(state);
    const canManage = state.data.auth.session?.role !== RoleType.employee;
    const rosterSettingsByUser = selectRosterSettingsByUser(state);
    const sessionUser = selectSessionInfo(state).user;
    const shiftId = getClickedShiftKey(e);
    const slotKey = getClickedSlotID(e);
    const today = moment().format(SDateFormat);

    const canAddOwnShifts =
      slotKey &&
      slotKey.userId === sessionUser.id &&
      (isV2
        ? canEmployeAddEditPunchingOnDate(userTimeClockSettings, slotKey!.date, today)
        : canUserEditOwnShiftsOfDate(rosterSettingsByUser[slotKey?.userId], slotKey.date));

    if (shiftId) {
      const shiftsMap = selectShiftMap(state);
      const shift = shiftsMap[shiftId]!;
      if (
        canManage ||
        shift.userId === sessionUser.id ||
        (!shift.userId && sessionUser.jobPositionIds.includes(shift.jobPositionId))
      ) {
        dispatch(openModal(ShiftPopup, { shift, isRequirement: shift.isRequirement }));
      }
    } else if (slotKey && !slotKey.isDisabled && (canManage || canAddOwnShifts)) {
      const shift = getShiftCoreBySlotKey(slotKey);
      if (!shift.userId) {
        const features = featuresSelector(state);
        if (!features.openShifts) {
          dispatch(paidFeatureWarning());
          return;
        }
      }
      dispatch(openModal(ShiftPopup, { shift, isRequirement: !!slotKey.isRequirement }));
    }
  };
};
