import React, { useEffect } from "react";
import { connect } from "react-redux";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";
import { AppState } from "../../types/AppState";

import { Button, Input, Select } from "antd";

import { dummyColors } from "../../constants/styling";
import _ from "lodash";
import { Raw } from "../../shared/entities/IResource";
import { IJobPosition } from "../../shared/entities/IJobPosition";
import { busyInjector, BusyInjectorProps } from "../../components/BusyInjector/BusyInjector";
import JobPositions from "../settings/job-positions/JobPositions";
import { SetupWizardHead } from "./SetupWizardHead";
import { IUserSetup } from "../../shared/entities/IUser";
import { setUser } from "@sentry/browser";
import { setUsersPageSetting } from "../../reducers/ui/general/usersPageSetting";
import { useSelector } from "../../helpers/redux";

type Props = BusyInjectorProps & {
  setUsers: (users: IUserSetup[]) => void;
  jobPositions: IJobPosition[];
  users: IUserSetup[];
  changeStep: (n: number) => void;
  finalize: () => Promise<void>;
  isV2: boolean;
};

export const UsersStep = busyInjector((props: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = +(e?.target?.dataset?.index as string);
    const next = [...props.users];
    next[index] = {
      ...next[index],
      [e.target.name]: e.target.value,
    };
    props.setUsers(next);
  };

  useEffect(() => {
    if (!props.users.length) {
      props.setUsers([
        {
          name: "",
          jobPositionIds: props.isV2 ? ["p1"] : [], // in V2 we hide the jobPos Step completely
        },
      ]);
    }
  }, []);

  const handleJobPositionChange = (jobPositionIds: string[], userIndex: number) => {
    const next = [...props.users];
    next[userIndex] = {
      ...next[userIndex],
      jobPositionIds,
    };
    props.setUsers(next);
  };

  const getEmptyUser = (): IUserSetup => {
    return {
      name: "",
      jobPositionIds: props.isV2 ? ["p1"] : [],
    };
  };

  const tryToFinalize = async () => {
    props.load(props.finalize());
  };

  const goToPrevStep = () => {
    const skipJobPosStep = props.isV2;
    props.setUsers(props.users.map((u) => ({ ...u, jobPositionIds: [] }))); // clear jobPositions, because they can get edited potentially in next step
    setTimeout(() => props.changeStep(skipJobPosStep ? 1 : 2));
  };

  return (
    <>
      <div className="usersStep content">
        <SetupWizardHead />
        <div className="stepTitle" style={{}}>
          {lg.weitere_mitarbeiter_anlegen}
        </div>
        <div className="wizMiniDesc">{lg.sie_können_die_mitarbeiter_auch_nachträglich_bearbeiten}</div>
        <div className="fb column">
          {props.users.map((user, i) => {
            return (
              <div key={i} className="userRow">
                <div className="fb row selectWrapper">
                  <Input
                    type="text"
                    onChange={handleChange}
                    value={user.name}
                    placeholder={lg.max_mustermann}
                    name={"name"}
                    data-index={i}
                    autoFocus={i === props.users.length - 1}
                  />
                </div>

                {/* {props.users.length !== 1 && (
                    <Button
                      id="registration-user-remove"
                      icon="delete"
                      style={{
                        marginLeft: 10,
                        flexShrink: 0,
                      }}
                      onClick={() => props.setUsers(props.users.filter((user, index) => index !== i))}
                      type="dashed"
                    />
                  )} */}

                {!props.isV2 && (
                  <div className="fb row selectWrapper">
                    <Select
                      style={{ width: "100%", flex: "0 1 auto" }}
                      value={user.jobPositionIds?.[0]}
                      placeholder={lg.rolle}
                      showSearch={false}
                      // maxTagCount={}
                      notFoundContent={lg.keine_weiteren_rollen_gefunden}
                      maxTagTextLength={10}
                      onChange={(value: string) => {
                        handleJobPositionChange([value], i);
                      }}
                    >
                      {props.jobPositions.map((j) => (
                        <Select.Option value={j.id} key={j.id}>
                          {j.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                )}
              </div>
            );
          })}
          <div className="fb row" style={{ marginTop: 4 }}>
            <Button
              id="registration-user-add"
              children={" " + lg.hinzufügen}
              icon="plus"
              disabled={
                !props.users[props.users.length - 1]?.name ||
                !props.users[props.users.length - 1]?.jobPositionIds.length
              }
              onClick={() => props.setUsers([...props.users, getEmptyUser()])}
            />
            {props.users.length !== 1 && (
              <Button
                style={{ marginLeft: "auto" }}
                id="registration-user-remove"
                data-rh={" " + lg.entfernen}
                icon="delete"
                onClick={() => props.setUsers(props.users.filter((user, i) => i !== props.users.length - 1))}
              />
            )}
          </div>
        </div>
      </div>
      <div className="footer fb jStart row">
        <Button
          onClick={() => {
            goToPrevStep();
          }}
          type="ghost"
          id="setup_wizard_back_button"
          children={lg.zurück}
          icon="arrow-left"
          size="large"
          style={{ marginRight: 14 }}
        />
        {!!props.users[0]?.name && (
          <Button
            onClick={() => tryToFinalize()}
            disabled={!props.users[0]?.name || !!props.isLoading()}
            type="primary"
            id="setup_wizard_done_button"
            children={lg.fertig}
            icon="check"
            size="large"
            loading={props.isLoading()}
          />
        )}
        {!props.users[0]?.name && (
          <Button
            onClick={() => tryToFinalize()}
            //type="default"
            type="ghost"
            id="setup_wizard_done_button"
            children={lg.überspringen}
            icon="arrow-right"
            size="large"
            loading={props.isLoading() || !!props.isLoading()}
            // icon="arrow-right"
          />
        )}
      </div>
    </>
  );
});
