import React from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import AvFormField from "../../../../components/AvFormField/AvFormField";
import { AutoComplete } from "antd";
import GoogleLogoSrc from "./powered_by_google_on_white_hdpi.png";

type OwnProps = {
  initialPlaceId?: string;
  initialAddress?: string;
  onSelect: (address: string, placeId: string, latLng: { lat: string; lng: string }) => void;
  setAddressBeingEdited: (v: boolean) => void;
  onClear?: () => void;
};

const AutoCompleteAny = AutoComplete as any;

type Props = OwnProps;

type State = {
  address: string;
  selectedPlaceId?: string;
  sessionToken: google.maps.places.AutocompleteSessionToken;
  browserSuggestionsDisabled: boolean;
};

class _LocationSearchInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      browserSuggestionsDisabled: false,
      address: props.initialAddress || "",
      sessionToken: new google.maps.places.AutocompleteSessionToken(),
      selectedPlaceId: props.initialPlaceId,
    };
  }

  handleChange = (address: string) => {
    this.setState({ address });
    if (address !== this.props.initialAddress && this.props.initialPlaceId === this.state.selectedPlaceId) {
      // Don't allow the user to save the popup,
      // while the location field is still in an "open with unsaved changes" status
      this.props.setAddressBeingEdited(true);
    } else {
      this.props.setAddressBeingEdited(false);
    }
  };

  handleSelect = (placeId: string, option) => {
    const address = option.props["data-address"];
    this.setState({ address });
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        // casting to strings because long decimal numbers make issues with rounding and get
        // castet to string from browser in some cases...
        const lat = String(latLng.lat);
        const lng = String(latLng.lng);
        this.props.onSelect(address, placeId, { lat, lng });
        this.setState({ selectedPlaceId: placeId });
        this.setState({
          sessionToken: new google.maps.places.AutocompleteSessionToken(),
        });
        this.props.setAddressBeingEdited(false);
      })
      .catch((error) => console.error("Error", error));
  };

  handleError = (status: string, clearSuggestion: () => void) => {
    if (status === "ZERO_RESULTS") {
      return;
    }
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onError={this.handleError}
        searchOptions={{ sessionToken: this.state.sessionToken }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          const inputProps = getInputProps();
          return (
            <>
              <AvFormField label={lg.adresse}>
                <AutoCompleteAny // I had to cast the Ant Component to any, so we could render the google logo at the bottom. (Which is mandatory)
                  value={this.state.address}
                  onChange={(v) => {
                    if (!v) {
                      this.props.onClear && this.props.onClear();
                    }
                    inputProps.onChange({ target: { value: v.toString() } });
                  }}
                  placeholder={lg.adresse}
                  onSelect={this.handleSelect}
                  onFocus={() => {
                    /**
                     * This is a very hacky fix, to avoid browser input suggestions.
                     * Apparently there is no better way to disable the browser suggestions
                     */
                    if (!this.state.browserSuggestionsDisabled) {
                      let i;
                      const el = document.getElementsByClassName("ant-select-search__field");
                      for (i = 0; i < el.length; i++) {
                        el[i].setAttribute("autocomplete", "registration-select");
                      }
                      this.setState({ browserSuggestionsDisabled: true });
                    }
                  }}
                >
                  {suggestions.map((s) => {
                    return (
                      <AutoComplete.Option value={s.placeId} data-address={s.description}>
                        {s.description}
                      </AutoComplete.Option>
                    );
                  })}
                  <AutoComplete.Option disabled value={"s.placeId"} data-address={"s.description"}>
                    <div className="fb aEnd jCenter" style={{ paddingTop: 12 }}>
                      <img src={GoogleLogoSrc} width="144" alt="google-logo" />
                    </div>
                  </AutoComplete.Option>
                </AutoCompleteAny>
              </AvFormField>
            </>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}

export const LocationSearchInput = _LocationSearchInput;
