import { DispFn } from "../../frontend-core/types/thunkTypes";
import { shiftRepository } from "../../repositories/shiftRepository";
import { IShift } from "../../shared/entities/IShift";
import { BatchDeleteFilter } from "./BatchDeletePopup";

export const fetchBatchDelteShifts = (filter: BatchDeleteFilter) => async (disp: DispFn): Promise<IShift[]> => {
  const { startDate, endDate, userId, branchId, shiftAddressId, jobPositionId, labelId } = filter;

  if (!startDate || !endDate) {
    return [];
  }

  let filterQuery: any;
  if (userId) {
    filterQuery = ["userId_date", "between", [`${userId}_${startDate}`, `${userId}_${endDate}`]];
  } else if (branchId) {
    filterQuery = ["branchId_date", "between", [`${branchId}_${startDate}`, `${branchId}_${endDate}`]];
  } else {
    filterQuery = ["date", "between", [startDate, endDate]];
  }

  return disp(shiftRepository.fetchMany({ filter: filterQuery }));
};
