import { environment } from "./../env";
import { firebaseListenerRepo } from "./firebaseListenerRepo";
import { AppState } from "../types/AppState";
import { DispFn } from "../frontend-core/types/thunkTypes";
import firebase from "firebase/compat/app";
import { Reducer } from "redux";
import { getSimpleReducer } from "../reducers/helpers/reducerHelpers";
import { ITenantInfo } from "../shared/entities/ITenantInfo";
import { LoopProtector } from "../frontend-core/LoopProtector";

class TenantInfoRepository {
  getAction = (tenantInfo: ITenantInfo) => ({
    type: "SET_TENANT_INFO",
    payload: tenantInfo,
  });

  addListener = () => async (dispatch: DispFn, getState: () => AppState) => {
    LoopProtector.check(dispatch);
    const ref = firebase.database().ref("tenantInfos");
    const tenatnId = getState().data.auth.session?.tenantId || "";
    ref.child(tenatnId).on("value", (snap) => {
      dispatch(this.getAction(snap.val()));
    });
  };

  fetch =
    () =>
    async (dispatch: DispFn, getState: () => AppState): Promise<ITenantInfo> => {
      LoopProtector.check(dispatch);
      const ref = firebase.database().ref("tenantInfos");
      const tenatnId = getState().data.auth.session?.tenantId || "";
      const tenantInfo = (await ref.child(tenatnId).once("value")).val() as ITenantInfo;
      return tenantInfo;
    };

  getReducer(): Reducer<ITenantInfo> {
    const initialState = {};
    return getSimpleReducer("SET_TENANT_INFO", initialState) as any;
  }
}

export const tenantInfoRepository = new TenantInfoRepository();
