import React, { PureComponent } from "react";

import { getTotalDaysCount } from "../localHelpers";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { Tooltip, Icon, Popover } from "antd";
import { openModal } from "../../../../actions/modal";
import EffectiveAbsenceDaysModal from "../../EffectiveAbsenceDaysModal/EffectiveAbsenceDaysModal";
import _ from "lodash";

import cn from "classnames";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import "./styles.scss";
import { AbsenceEarning, AbsenceTypeCode } from "../../../../shared/entities/IAbsenceType";
import { IAbsence, EffectiveAbsenceDays } from "../../../../shared/entities/IAbsence";
import { IContract, WorkInterval } from "../../../../shared/entities/IContract";
import { getMeanDailyQuota, getEffectiveDaysByYear } from "../../../../shared/helpers/absences";
import { getValidContract } from "../../../../shared/helpers/credit";
import { minutesToDuration } from "../../../../shared/helpers/timeHelpers";
import { IHoliday } from "../../../../shared/entities/IHoliday";
import { selectCan } from "../../../../selectors/canSelector";
import { selectHolidayFinder } from "../../../../selectors/holidayMapSelector";

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
  sessionInfo: selectSessionInfo(state),
  holidayFinder: selectHolidayFinder(state),
  can: selectCan(state),
  isV2: state.data.tenantInfo.isV2,
});

const earningOptions = {
  [AbsenceEarning.dailyQuota]: lg.sollstunden,
  [AbsenceEarning.meanQuota]: lg.sollstd_durchschnitt,
  [AbsenceEarning.asPlaned]: lg.planstunden,
  [AbsenceEarning.none]: lg.unbezahlt,
};

type OwnProps = {
  absence: IAbsence;
  contracts: IContract[];
  holidays: IHoliday[];
  earning: AbsenceEarning;
  updateAbsence: (absence: Partial<IAbsence>) => void;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

type State = {
  isEarningPopoverOpen: boolean;
};

class TotalAbsenceDaysSection extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isEarningPopoverOpen: false,
    };
  }

  getCalculatedEffectiveDays = () => {
    const { absence, contracts } = this.props;
    return getEffectiveDaysByYear(absence as IAbsence, this.props.holidayFinder, contracts);
  };

  openOverrideModal = () => {
    const { absence, updateAbsence } = this.props;
    const effectiveDays = absence.effectiveDaysOverride || this.getCalculatedEffectiveDays();

    this.props.dispatch(
      openModal(EffectiveAbsenceDaysModal, {
        effectiveDays,
        onChange: (effectiveDaysOverride: EffectiveAbsenceDays) => {
          updateAbsence({ effectiveDaysOverride });
        },
      })
    );
  };

  getValidContract = () => {
    const { absence, contracts } = this.props;
    return getValidContract(contracts, absence.startDate);
  };

  getSelectedEarning = () => {
    const { earning } = this.props.absence;
    const contract = this.getValidContract();
    // for users with monthly contract 'meanQuota' makes no sense, so it gets turned into 'dailyQuota
    const isMonthly = contract!.interval === WorkInterval.monthly;
    const _earning = isMonthly && earning === AbsenceEarning.meanQuota ? AbsenceEarning.dailyQuota : earning;
    const label = earningOptions[_earning];
    const quota = minutesToDuration(getMeanDailyQuota(contract!.dailyQuota));
    return _earning === AbsenceEarning.meanQuota ? `${label} (${quota})` : label;
  };

  render() {
    const { absence, earning, isV2 } = this.props;
    const canManage = this.props.can.manageAbsences;

    const totalDays = getTotalDaysCount(absence);
    const _effectiveDays = absence.effectiveDaysOverride || this.getCalculatedEffectiveDays();
    const effectiveDaysVal = _.sum(Object.values(_effectiveDays));
    const contract = this.getValidContract()!;
    const isWeekly = contract!.interval === WorkInterval.weekly;
    const isMonthly = contract!.interval === WorkInterval.monthly;
    const isVacation = absence.typeCode === AbsenceTypeCode.vacation;
    const isIllness = absence.typeCode === AbsenceTypeCode.illness;
    const isOverride = !!absence.effectiveDaysOverride;
    const creditSepecifiedValue = (isMonthly && isVacation) || (isMonthly && isIllness && contract.illnessDayCredit);

    const earningPopover = (
      <div className="absenceEarningPopover" onClick={(e) => this.setState({ isEarningPopoverOpen: false })}>
        <div
          className={cn({ selected: earning === AbsenceEarning.dailyQuota }) + " option"}
          onClick={() => this.props.updateAbsence({ earning: AbsenceEarning.dailyQuota })}
        >
          {lg.sollstunden}{" "}
        </div>
        {isWeekly && (
          <div
            className={cn({ selected: earning === AbsenceEarning.meanQuota }) + " option"}
            onClick={() => this.props.updateAbsence({ earning: AbsenceEarning.meanQuota })}
          >
            {lg.sollstunden_durchschnitt}
          </div>
        )}
        <div
          className={cn({ selected: earning === AbsenceEarning.asPlaned }) + " option"}
          onClick={() => this.props.updateAbsence({ earning: AbsenceEarning.asPlaned })}
        >
          {lg.planstunden}
        </div>
        <div
          className={cn({ selected: earning === AbsenceEarning.none }) + " option"}
          onClick={() => this.props.updateAbsence({ earning: AbsenceEarning.none })}
        >
          {lg.unbezahlt}
        </div>
      </div>
    );

    return (
      <div className="totalAbsenceDaysSectionMain">
        <div className="col edgeRight">
          <div className="label totalDays">{lg.tage_gesamt}</div>
          <div className="count totalDays">{totalDays}</div>
        </div>

        <div className="col edgeRight effectiveDays">
          <div className="label blue">{lg.tage_effektiv}</div>

          <div
            data-rh={canManage ? (isOverride ? lg.manuell_überschrieben : lg.überschreiben) : null}
            className={cn({ canManage, isOverride }) + " count effectiveDaysCount"}
            onClick={canManage ? this.openOverrideModal : undefined}
          >
            {effectiveDaysVal}
          </div>
        </div>

        {canManage && (
          <div className={cn({ earningBox: true, notClickable: isV2 })}>
            <div className="label">{lg.gutschrift_pro_arbeitstag}:</div>
            {!creditSepecifiedValue && (
              <Popover
                content={earningPopover}
                title={null}
                trigger={isV2 ? "contextMenu" : "click"}
                visible={this.state.isEarningPopoverOpen}
                onVisibleChange={(isOpen) => this.setState({ isEarningPopoverOpen: isOpen })}
              >
                <div className="earning">
                  <div className="type">{this.getSelectedEarning()}</div>
                </div>
              </Popover>
            )}
            {!!creditSepecifiedValue && (
              <div data-rh="Hinterlegter Wert unter Mitarbeiter > Arbeitszeit" className="monthlyUserEarning">
                {minutesToDuration((isVacation ? contract.vacationDayCredit : contract.illnessDayCredit) || 0)}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(TotalAbsenceDaysSection);
