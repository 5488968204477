import React from "react";
import { Radio } from "antd";
import "./styles.scss";
import { AbsenceStatus } from "../../../../shared/entities/IAbsence";

type Props = {
  selectedStatus?: string;
  selectStatus: (absenceStatus: AbsenceStatus) => void;
  disabled?: boolean;
};
export const AbsenceStatusSelect = (props: Props) => {
  const { selectedStatus, selectStatus, disabled } = props;

  const absenceStatuses = [
    { value: AbsenceStatus.active, label: lg.bestätigt },
    { value: AbsenceStatus.requested, label: lg.beantragt },
  ];

  return (
    <div className="absenceStatusSelectMain">
      <Radio.Group onChange={(e: any) => selectStatus(e.target.value)} disabled={disabled}>
        {absenceStatuses.map((status) => (
          <Radio key={status.value} value={status.value} checked={selectedStatus === status.value}>
            {status.label}
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
};
