import React from "react";
import { Button, Popover } from "antd";
import "./styles.scss";
import { SelectProps } from "antd/lib/select";
import { AppState } from "../../types/AppState";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import { connect } from "react-redux";
import { BasicSelect } from "../../components/BasicSelect/BasicSelect";
import Search from "antd/lib/input/Search";
import cn from "classnames";
import { selectVisibleBranches } from "../../selectors/VisibleBranchesSelector";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";

const mapStateToProps = (state: AppState) => {
  return {
    jobPositions: state.data.jobPositions,
    visibleBranches: selectVisibleBranches(state),
    currentUser: selectSessionInfo(state).user,
  };
};

type State = {
  // searchOpened: boolean;
  // filtersOpened: boolean;
  isPopoverOpen: boolean;
};

type OwnProps = {
  onSearchChange: (searchValue?: string) => void;
  onBranchChange: (branchValue?: string) => void;
  onPositionChange: (positionValue?: string) => void;
  branchFilter?: string;
  positionFilter?: string;
  searchInput?: string;
  titleStyle?: React.CSSProperties;
  noBranchFilter?: boolean;
} & SelectProps<any>;

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _UserFilter extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      // searchOpened: false,
      //filtersOpened: !!(props.branchFilter || props.positionFilter),
      isPopoverOpen: false,
    };
  }

  // toggleSearchInput = () => {
  //   this.setState({ searchOpened: !this.state.searchOpened });
  //   this.props.onSearchChange('');
  // };

  toggleFilter = () => {
    //const openFilert = !this.state.filtersOpened;
    // if (!openFilert && (this.props.branchFilter || this.props.positionFilter)) {
    //   return; // dont allow to close filter if there is an active filter
    // }
    //this.setState({ filtersOpened: openFilert });
  };

  toggleBranchFilter = () => {
    this.setState({ isPopoverOpen: false });
    const next = this.props.branchFilter ? undefined : this.props.currentUser.branchIds[0];
    this.props.onBranchChange(next);
  };

  togglePositionFilter = () => {
    this.setState({ isPopoverOpen: false });
    const next = this.props.positionFilter
      ? undefined
      : this.props.currentUser.jobPositionIds.find((jpId) =>
          this.props.jobPositions.find((jp) => jp.id === jpId && !jp.isInactive)
        );
    this.props.onPositionChange(next);
  };

  getPopoverContent = () => {
    const { jobPositions, visibleBranches } = this.props;
    return (
      <div className="filterPopoverContentMain">
        {this.props.visibleBranches.length > 1 && !this.props.noBranchFilter && (
          <div
            className={cn({
              option: true,
              selected: this.props.branchFilter,
              withMarginBottom: visibleBranches.length > 1 && jobPositions.length > 1,
            })}
            onClick={this.toggleBranchFilter}
          >
            {lg.standort_filter}
          </div>
        )}
        <div
          className={cn({
            option: true,
            selected: this.props.positionFilter,
          })}
          onClick={this.togglePositionFilter}
        >
          {lg.rollen_filter}
        </div>
      </div>
    );
  };

  render() {
    const { jobPositions, visibleBranches, branchFilter, positionFilter, searchInput } = this.props;
    const { isPopoverOpen } = this.state;

    const activeJobPositions = jobPositions.filter((jp) => !jp.isInactive);
    const showFilterBtn = visibleBranches.length > 1 || jobPositions.length > 1;

    return (
      <div className="userFilterMain">
        <div className="userSearchBar">
          <div className="searchWrapper">
            {
              <Search
                value={searchInput}
                onChange={(e) => this.props.onSearchChange(e.target.value)}
                style={{ width: "100%" }}
                placeholder={lg.mitarbeiter}
                autoFocus
              />
            }
          </div>
          {showFilterBtn && (
            <div className="buttonWrapper">
              <Popover
                content={this.getPopoverContent()}
                trigger="click"
                visible={this.state.isPopoverOpen}
                onVisibleChange={(isPopoverOpen) => this.setState({ isPopoverOpen })}
                placement="rightTop"
              >
                <Button id="user-filter-options" type={isPopoverOpen ? "primary" : "default"} icon="filter" />
              </Popover>
            </div>
          )}
        </div>
        <div className={"userFiltersWrapper"}>
          {branchFilter && (
            <div className="branchFilterWrapper">
              <BasicSelect
                id="user-filter-branch-select"
                value={branchFilter}
                onChange={(id: string) => {
                  this.props.onBranchChange(id);
                }}
                options={visibleBranches.map((o) => ({ label: o.name, value: o.id }))}
                placeholder={lg.alle_standorte}
                allowClear
              />
            </div>
          )}
          {positionFilter && (
            <div className="jobPosFilterwrapper">
              <BasicSelect
                id="user-filter-jobposition-select"
                value={positionFilter}
                onChange={(id: string) => {
                  this.props.onPositionChange(id);
                }}
                options={activeJobPositions.map((o) => ({ label: o.name, value: o.id }))}
                placeholder={lg.alle_rollen}
                allowClear
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export const UserFilter = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(_UserFilter);
