import { AppState } from "../types/AppState";
import { DispFn } from "../frontend-core/types/thunkTypes";

import { Reducer } from "redux";
import { IThreadInfo } from "../shared/entities/IThreadInfo";
import { selectSessionInfo } from "../selectors/SessionInfoSelector";
import { Raw } from "../shared/entities/IResource";
import moment from "moment";
import { v4 as uuid } from "uuid";
import "firebase/database";
import firebase from "firebase/compat/app";
import { Map } from "../shared/types/general";
import _, { update } from "lodash";
import { IMessage } from "../shared/entities/IMessage";
import { userThreadInfoRepository } from "./userThreadInfoRepository";
import { nullifyProps } from "../shared/helpers/firebaseHelpers";
import { Pusher } from "../actions/pushNote";
import { LoopProtector } from "../frontend-core/LoopProtector";
import { selectUserThreadInfos } from "../selectors/userThreadInfoSelector";

export enum MessageAT {
  addList = "@AV_MESSAGE_ADD_LIST",
  add = "@AV_MESSAGE_CREATE",
  remove = "@AV_MESSAGE_REMOVE",
  update = "@AV_MESSAGE_UPDATE",
  clear = "@AV_MESSAGE_CLEAR",
}

type Action =
  | { type: MessageAT.addList; payload: IMessage[] }
  | { type: MessageAT.add; payload: IMessage }
  | { type: MessageAT.remove; payload: IMessage }
  | { type: MessageAT.update; payload: IMessage }
  | { type: MessageAT.clear; payload: undefined };

class MessageRepository {
  /**
   * There can only be listeners for only 1 Thread always!
   * We don't want to listen to multiple threads at the same time.
   */
  listeners: {
    child_added?: any;
    child_changed?: any;
    child_removed?: any;
  } = {};

  LOADING_CHUNK_SIZE = 30;

  isLoadingMore = false;

  getRefPath = (tenantId: string, threadId: string) => {
    return `tenants/${tenantId}/messagesByThreadId/${threadId}`;
  };

  getRef = (tenantId: string, threadId: string) => {
    const db = firebase.database();
    return db.ref(this.getRefPath(tenantId, threadId));
  };

  clear = () => async (dispatch: DispFn, getState: () => AppState) => {
    return dispatch({
      type: MessageAT.clear,
    });
  };

  loadMore = (threadId: string) => async (dispatch: DispFn, getState: () => AppState) => {
    if (this.isLoadingMore) {
      return;
    }

    this.isLoadingMore = true;
    const state = getState();
    const messages = state.data.messages[threadId];

    if (!messages) {
      return;
    }

    const tenantId = state.data.auth.session!.tenantId;
    const ref = this.getRef(tenantId, threadId)
      .orderByKey()
      .endAt(messages[messages.length - 1].id)
      .limitToLast(this.LOADING_CHUNK_SIZE);

    const valuesSnap = (await ref.once("value")).val() as Map<IMessage>;
    const values = Object.values(valuesSnap || {});

    dispatch({
      type: MessageAT.addList,
      payload: values,
    });

    this.isLoadingMore = false;
    return values;
  };

  addListener = (threadId: string) => async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const tenantId = state.data.auth.session!.tenantId;
    const ref = this.getRef(tenantId, threadId).orderByKey().limitToLast(this.LOADING_CHUNK_SIZE);

    const initialValueSnap = (await ref.once("value")).val();
    const initialValue = Object.values(initialValueSnap || {}) as IMessage[];

    dispatch({
      type: MessageAT.addList,
      payload: initialValue,
    });

    let currentCount = 0;
    this.listeners.child_added = ref.on("child_added", (snap: any) => {
      currentCount++;
      if (currentCount <= initialValue.length) {
        return;
      }
      dispatch({
        type: MessageAT.addList,
        payload: [snap.val()],
      });
    });

    this.listeners.child_changed = ref.on("child_changed", (snap: any) => {
      dispatch({
        type: MessageAT.update,
        payload: snap.val(),
      });
    });

    this.listeners.child_removed = ref.on("child_removed", (snap: any) => {
      dispatch({
        type: MessageAT.remove,
        payload: snap.key,
      });
    });

    return initialValue;
  };

  generateId = () => `${Date.now()}_${Math.floor(Math.random() * 10)}`;

  removeListener = (threadId: string) => async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const tenantId = state.data.auth.session!.tenantId;

    const ref = this.getRef(tenantId, threadId);

    if (this.listeners) {
      ref.off("child_added", this.listeners.child_added);
      ref.off("child_removed", this.listeners.child_removed);
      ref.off("child_changed", this.listeners.child_changed);
    }
  };

  create = (opt: { threadId: string; text: string }) => async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const tenantId = state.data.auth.session!.tenantId;
    const sessionInfo = selectSessionInfo(state);
    const allUserThreadInfos = selectUserThreadInfos(state);
    const userThreadInfo = allUserThreadInfos.find((t) => t.id === opt.threadId);
    const timestamp = Date.now();
    const newMessage: IMessage = {
      userId: sessionInfo.user.id,
      text: opt.text.trim(),
      threadId: opt.threadId,
      id: this.generateId(),
    };

    const updates: any = {};
    const messageRefPath = `${this.getRefPath(tenantId, opt.threadId)}/${newMessage.id}`;
    const userThreadInfoUpdateRefs = userThreadInfoRepository.getUserThreadRefPaths(
      tenantId,
      newMessage.threadId,
      userThreadInfo!.userIds
    );

    updates[messageRefPath] = nullifyProps(newMessage);
    userThreadInfoUpdateRefs.forEach((userThreadRefPath, index) => {
      updates[`${userThreadRefPath.path}/lastMessageTimestamp`] = timestamp;
      updates[`${userThreadRefPath.path}/lastMessageText`] = opt.text;
      if (userThreadRefPath.userId !== sessionInfo.user.id) {
        updates[`${userThreadRefPath.path}/hasUnseenMessages`] = true;
      }
    });

    LoopProtector.check(dispatch);
    await firebase.database().ref().update(updates);
    dispatch(Pusher.chatMessage(newMessage, opt.threadId));

    return dispatch({
      type: MessageAT.add,
      payload: newMessage,
    });
  };

  delete =
    (opt: { threadId: string; messageId: string; previousMessage?: IMessage; isLatestMessage: boolean }) =>
    async (dispatch: DispFn, getState: () => AppState) => {
      const state = getState();
      const tenantId = state.data.auth.session!.tenantId;
      const userThreadInfos = selectUserThreadInfos(state);
      const userThreadInfo = userThreadInfos.find((t) => t.id === opt.threadId);

      const messageRefPath = `${this.getRefPath(tenantId, opt.threadId)}/${opt.messageId}`;
      const userThreadInfoUpdateRefs = userThreadInfoRepository.getUserThreadRefPaths(
        tenantId,
        opt.threadId,
        userThreadInfo!.userIds
      );

      const updates: any = {};

      updates[messageRefPath] = null;

      if (opt.isLatestMessage) {
        userThreadInfoUpdateRefs.forEach((userThreadRefPath, index) => {
          if (opt.previousMessage) {
            updates[`${userThreadRefPath.path}/lastMessageTimestamp`] = parseInt(opt.previousMessage!.id.split("_")[0]);
          }
          updates[`${userThreadRefPath.path}/lastMessageText`] = opt.previousMessage ? opt.previousMessage!.text : null;
        });
      }

      await firebase.database().ref().update(updates);

      dispatch({
        type: MessageAT.remove,
        payload: { id: opt.messageId, threadId: opt.threadId },
      });
    };

  sortbyTimestamp = (data: IMessage[]) => {
    return _.orderBy(data, ["id"], ["desc"]);
  };

  getReducer(): Reducer<{ [threadId: string]: IMessage[] }, Action> {
    return (state: { [threadId: string]: IMessage[] } = {}, action: Action): { [threadId: string]: IMessage[] } => {
      switch (action.type) {
        case MessageAT.add:
        case MessageAT.update: {
          const messages = state[action.payload.threadId] || [];
          return {
            ...state,
            [action.payload.threadId]: this.sortbyTimestamp([
              ...messages.filter((e) => e.id !== action.payload.id),
              action.payload,
            ]),
          };
        }
        case MessageAT.remove: {
          const messages = state[action.payload.threadId] || [];

          return {
            ...state,
            [action.payload.threadId]: this.sortbyTimestamp(messages.filter((e) => e.id !== action.payload.id)),
          };
        }
        case MessageAT.addList: {
          if (!action.payload.length) {
            return state;
          }
          const messages = state[action.payload[0].threadId] || [];
          return {
            ...state,
            [action.payload[0].threadId]: this.sortbyTimestamp(_.unionBy(messages, action.payload, "id")),
          };
        }
        case MessageAT.clear:
          return {};
        default:
          return state;
      }
    };
  }
}

export const messageRepository = new MessageRepository();
