import { combineReducers } from "redux";
import { getAbsenceCalendarFilterReducer } from "./absenceCalendarFilter";
import { getHourAccountFilterReducer } from "./hourAccountFilter";

const filtersReducer = combineReducers({
  absenceCalendar: getAbsenceCalendarFilterReducer,
  hourAccount: getHourAccountFilterReducer,
});

export default filtersReducer;
