import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { UserFilter } from "../../../../components/UserFilter/UserFilter";
import { Tooltip } from "antd";
import BusyWrapper from "../../../../components/BusyWrapper/BusyWrapper";
import { IUser, IUserFull } from "../../../../shared/entities/IUser";
import { CreditCorrectionType } from "../../../../shared/entities/ICreditCorrection";
import { minutesToDuration } from "../../../../shared/helpers/timeHelpers";
import cn from "classnames";
import _ from "lodash";
import "./styles.scss";
import { HourAccountUiFilter, setHourAccountFilter } from "../../../../reducers/ui/filters/hourAccountFilter";
import { featuresSelector } from "../../../../selectors/FeaturesSelector";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { selectUserFullMap } from "../../../../selectors/userFullMapSelector";

const mapStateToProps = (state: AppState) => {
  return {
    branches: state.data.branches,
    jobPositions: state.data.jobPositions,
    creditBalances: state.data.creditBalances,
    creditCorrections: state.data.creditCorrections,
    filter: state.ui.filters.hourAccount,
    isV2: state.data.tenantInfo.isV2,
    features: featuresSelector(state),
    sessionInfo: selectSessionInfo(state),
    userFullMap: selectUserFullMap(state),
  };
};

type OwnProps = {
  userIds: string[];
  selectUser: (userId: string) => void;
  userId: string;
  endDate: string;
  startDate: string;
  isDataLoading: boolean;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class CreditsUserBarComponent extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchInput: "",
      branchFilter: undefined,
      positionFilter: undefined,
    };
  }

  onSearchChange = (searchInput: string) => {
    this.setState({ searchInput });
  };

  onBranchChange = (branchFilter: string) => {
    this.setState({ branchFilter });
  };

  onPositionChange = (positionFilter: string) => {
    this.setState({ positionFilter });
  };

  getFilteredUsers = (): IUserFull[] => {
    const { sessionInfo } = this.props;
    const { positionFilter, branchFilter, searchInput } = this.props.filter;
    const inputLow = searchInput ? searchInput.toLowerCase() : "";

    const _users = this.props.userIds
      .map((userId) => this.props.userFullMap[userId])
      .filter((u) => sessionInfo.isAdmin() || _.intersection(u.branchIds, sessionInfo.user.branchIds).length)
      .filter((u) => !searchInput || u.name.toLowerCase().includes(inputLow))
      .filter((u) => !branchFilter || u.branchIds.includes(branchFilter))
      .filter((u) => !positionFilter || u.jobPositionIds.includes(positionFilter));

    return _.sortBy(_users, (u) => u.name);
  };

  getCreditOfUser = (userId: string) => {
    const { endDate, startDate, creditBalances, isV2 } = this.props;
    const date = isV2 ? startDate : endDate;
    return creditBalances[`${userId}_${date}`]?.balance || 0;
  };

  updateFilter = (filter: Partial<HourAccountUiFilter>) => {
    this.props.dispatch(setHourAccountFilter(filter));
  };

  render() {
    const { positionFilter, branchFilter, searchInput } = this.props.filter;
    const usersWithInitialCredit = this.props.creditCorrections
      .filter((c) => c.type === CreditCorrectionType.initial)
      .map((c) => c.userId);

    const showhourAccount = this.props.features.credits;

    return (
      <BusyWrapper isBusy={this.props.isDataLoading} style={{ minHeight: "auto", width: "100%" }}>
        <div className="creditsUserBarMain">
          <div className="head">
            <UserFilter
              onSearchChange={(searchInput?: string) => this.updateFilter({ searchInput })}
              onBranchChange={(branchFilter?: string) => this.updateFilter({ branchFilter })}
              onPositionChange={(positionFilter?: string) => this.updateFilter({ positionFilter })}
              searchInput={searchInput}
              branchFilter={branchFilter}
              positionFilter={positionFilter}
              titleStyle={{ color: "white" }}
            />
          </div>
          <div className="userList">
            {this.getFilteredUsers().map((user) => (
              <div
                key={user.id}
                className={cn({ selected: this.props.userId === user.id }) + " userItem"}
                onClick={() => {
                  this.props.selectUser(user.id);
                }}
              >
                <div className="userName">{user.name}</div>
                {showhourAccount && usersWithInitialCredit.includes(user.id) && (
                  <div
                    data-rh={lg.stundenkonto}
                    className={cn({
                      credit: true,
                      redFont: this.getCreditOfUser(user.id)! > 0,
                    })}
                  >
                    {minutesToDuration(this.getCreditOfUser(user.id), {
                      withSign: true,
                      onlyHours: true,
                    })}{" "}
                    h
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </BusyWrapper>
    );
  }
}

export const CreditsUserBar = connect<StoreProps, DispatchBaseProps, {}, AppState>(mapStateToProps)(
  CreditsUserBarComponent
);
