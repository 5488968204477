import { createSelector } from "reselect";
import { IShift } from "../shared/entities/IShift";
import { AppState } from "../types/AppState";
import _ from "lodash";

export const SHIFT_TEMPLATE_DATE = "2000-01-01";

export const selectShiftTemplates = createSelector(
  [(state: AppState) => state.data.shifts, (state: AppState) => state.ui.selectedBranch],
  (shifts: IShift[], selectedBranchId: string) => {
    const visibleTemplateShifts = shifts.filter(
      (s) => s.date === SHIFT_TEMPLATE_DATE && s.branchId === selectedBranchId
    );
    return _.orderBy(visibleTemplateShifts, (s) => s.isTemplate);
  }
);
