import { generateUserRosterSettings } from "./../shared/helpers/settingsHelpers";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import { IUserRosterSettings } from "../shared/entities/IRosterSettings";
import { selectSessionInfo } from "./SessionInfoSelector";

export const selectRosterSettingsByUser = createSelector(
  [(state: AppState) => state.data.users, (state: AppState) => state.data.rosterSettings[0]],
  (users, rosterSettings): { [userId: string]: IUserRosterSettings } => {
    const rosterSettingsByUser = {};
    users.forEach((user) => {
      rosterSettingsByUser[user.id] = generateUserRosterSettings(rosterSettings, user);
    });
    return rosterSettingsByUser;
  }
);

export const selectRosterSettingsForCurrentUser = createSelector(
  [selectSessionInfo, (state: AppState) => state.data.rosterSettings[0]],
  (sessionInfo, rosterSettings) => {
    return generateUserRosterSettings(rosterSettings, sessionInfo.user);
  }
);
