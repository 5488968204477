import { notification } from "antd";
import _ from "lodash";
import { DispFn } from "../frontend-core/types/thunkTypes";
import { AnnouncementFormValues } from "../pages/dashboard/AnnouncementsPaper/AnnouncementAddEditModal/AnnouncementFormValues";
import { announcementRepository } from "../repositories/announcementRepository";
import { selectSessionInfo } from "../selectors/SessionInfoSelector";
import { IAnnouncement } from "../shared/entities/IAnnouncement";
import { AppState } from "../types/AppState";
import { Pusher } from "./pushNote";
import { NewUpload, uploadFileList } from "./uploads";

/**
 * EVERY CHANGE NEEDS TO BE APPLIED TO _MOBILE_ AS WELL!
 */
export const saveAnnouncement = (
  values: AnnouncementFormValues & { userIds: string[]; jobPositionIds: string[]; branchIds: string[] },
  newUploads: Array<NewUpload>,
  existingAnnouncement?: IAnnouncement
) => async (dispatch: DispFn, getState: () => AppState) => {
  const sessionInfo = selectSessionInfo(getState());

  const nextReadByUserId = values.userIds.reduce(
    (prev, userId) => ({ ...prev, [userId]: !!existingAnnouncement?.readByUserId?.[userId] }),
    {}
  );

  const next: IAnnouncement = {
    id: values.id,
    title: values.title,
    message: values.message,
    branchIds: values.branchIds,
    jobPositionIds: values.jobPositionIds,
    readByUserId: nextReadByUserId,
    by: existingAnnouncement?.by || sessionInfo.user.id,
    at: existingAnnouncement?.at || Date.now(),
  };

  const usersToNotify = existingAnnouncement
    ? values.userIds.filter((uid) => !existingAnnouncement.readByUserId?.hasOwnProperty(uid))
    : values.userIds;

  const res = existingAnnouncement
    ? await dispatch(announcementRepository.update(next as IAnnouncement))
    : await dispatch(announcementRepository.create(next));

  if (usersToNotify.length && !existingAnnouncement) {
    dispatch(Pusher.announcementCreated(res, usersToNotify));
    notification.success({ message: `${usersToNotify.length} ` + lg.mitarbeiter_wurden_benachrichtigt });
  }

  if (newUploads.length) {
    const uploadJobs = newUploads.map((u) => ({ uploadBase: _.omit(u, "file"), file: u.file }));
    await dispatch(uploadFileList(uploadJobs));
  }

  return res;
};
