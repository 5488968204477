import { AppState } from "../types/AppState";
import { DispFn } from "../frontend-core/types/thunkTypes";
import firebase from "firebase/compat/app";
import { Reducer } from "redux";

export enum FbListenerAction {
  ADD = "@AV_FIREBASE_LISTENER_ADD",
  REMOVE = "@AV_FIREBASE_LISTENER_REMOVE",
  REMOVE_ALL = "@AV_FIREBASE_LISTENER_REMOVE_ALL",
}

export type FbListenerCallbacks = {
  child_added: () => void;
  child_changed: () => void;
  child_removed: () => void;
};

type FbListener = {
  key: string;
  ref: firebase.database.Reference;
  callbacks: FbListenerCallbacks;
};

type Action =
  | { type: FbListenerAction.ADD; payload: FbListener }
  | { type: FbListenerAction.REMOVE; payload: string }
  | { type: FbListenerAction.REMOVE_ALL; payload: undefined };

class FirebaseListenerRepo {
  add = (key: string, ref: firebase.database.Reference, callbacks: FbListenerCallbacks) => (dispatch: DispFn) => {
    return dispatch({
      type: FbListenerAction.ADD,
      payload: {
        key,
        status: "active",
        ref,
        callbacks,
      },
    });
  };

  remove = (key: string) => (dispatch: DispFn, getState: () => AppState) => {
    const listeners = getState().data.firebaseListeners;
    const listener = listeners.find((l) => l.key === key);
    if (listener) {
      listener.ref.off("child_added", listener.callbacks.child_added);
      listener.ref.off("child_removed", listener.callbacks.child_removed);
      listener.ref.off("child_changed", listener.callbacks.child_changed);
    }

    return dispatch({
      type: FbListenerAction.REMOVE,
      payload: key,
    });
  };

  removeAll = () => (dispatch: DispFn, getState: () => AppState): Promise<void> => {
    const listeners = getState().data.firebaseListeners;
    listeners.forEach((listener) => {
      listener.ref.off("child_added", listener.callbacks.child_added);
      listener.ref.off("child_removed", listener.callbacks.child_removed);
      listener.ref.off("child_changed", listener.callbacks.child_changed);
    });

    dispatch({ type: FbListenerAction.REMOVE_ALL });
    // give firebase some time to detach listeners
    return new Promise((resolve) => setTimeout(resolve, 100));
  };

  // There is no 'addAll' method to activate the listeners after the Maintainence is Over.
  // Because when the Maintainence Mode is over, the App mounts again, The <Preloader> adds the Fb-Listeners on Mount

  getReducer(): Reducer<FbListener[], Action> {
    return (state: FbListener[] = [], action: Action): FbListener[] => {
      switch (action.type) {
        case FbListenerAction.ADD:
          return [...state, action.payload];
        case FbListenerAction.REMOVE:
          return state.filter((l) => l.key !== action.payload);
        case FbListenerAction.REMOVE_ALL:
          return [];
        default:
          return state;
      }
    };
  }
}

export const firebaseListenerRepo = new FirebaseListenerRepo();
