import { IShift } from "./../shared/entities/IShift";
import { IPublishedWeek } from "./../shared/entities/IPublishedWeek";
import { RoleType } from "./../shared/entities/IUser";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";

import { selectSessionInfo } from "./SessionInfoSelector";

const isShiftPublished = (s: IShift, publishedWeeks: IPublishedWeek[]) =>
  publishedWeeks.find((w) => w.branchId === s.branchId && w.startDate <= s.date && w.endDate >= s.date && w.published);

export const publishedShiftsSelector = createSelector(
  [
    (state: AppState) => state.data.shifts,
    (state: AppState) => state.data.publishedWeeks,
    (state: AppState) => state.data.tenantInfo.isV2,
  ],
  (shifts, publishedWeeks, isV2) => {
    const publishedDaysMap: any = {};
    return shifts.filter((s) => {
      // we memoize a branch-date combination that already got calculated to reduce the loops in loops
      if (publishedDaysMap[s.branchId + s.date] === undefined) {
        publishedDaysMap[s.branchId + s.date] = isV2 || isShiftPublished(s, publishedWeeks);
      }
      return publishedDaysMap[s.branchId + s.date];
    });
  }
);

export const visibleShiftsSelector = createSelector(
  [
    (state: AppState) => state.data.shifts,
    publishedShiftsSelector,
    selectSessionInfo,
    (state: AppState) => state.data.rosterSettings[0],
    (state: AppState) => state.data.tenantInfo.isV2,
  ],
  (shifts, publishedShfts, sessionInfo, rosterSettings, isV2) => {
    switch (sessionInfo.user.role) {
      case RoleType.admin:
      case RoleType.manager:
        return shifts;
      case RoleType.employee:
        return publishedShfts.filter(
          (s) =>
            isV2 ||
            // can only see published shifts of his branches.
            (sessionInfo.user.branchIds.includes(s.branchId) &&
              // for open shifts > only the ones that match the users jobPosition
              (s.userId || sessionInfo.user.jobPositionIds.includes(s.jobPositionId)) &&
              (!rosterSettings.usersOfOtherRolesAreHidden || sessionInfo.user.jobPositionIds.includes(s.jobPositionId)))
        );
      default:
        return [];
    }
  }
);
