import { IAdminData } from "../shared/entities/IAdminData";

export const adminDataActionTypes = {
  SET: "@@AV/ADMIN_DATA_SET",
};

export const setAdminData = (payload: IAdminData | null) => ({
  type: adminDataActionTypes.SET,
  payload,
});
