import React from "react";
import "./styles.scss";
import moment, { Moment } from "moment";
import { DatePicker, Icon } from "antd";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import { simpleDateToMoment } from "../../../../shared/helpers/timeHelpers";
import { IAbsence } from "../../../../shared/entities/IAbsence";

type OwnProps = {
  startDate?: string;
  endDate?: string;
  selectedYear?: number;
  selectedMonth?: number;
  onDatesChange: (startDateMom: Moment | undefined, endDateMom?: Moment) => void;
  readOnly?: boolean;
  isYearView?: boolean;
  arrowIconStyle?: React.CSSProperties;
  allowClear?: string | boolean;
};

type State = {
  isEndDatePickerOpen: boolean;
};
type Props = OwnProps;

export class AbsenceDateSelect extends React.PureComponent<Props, State> {
  state: State;

  constructor(props: Props) {
    super(props);
    this.state = {
      isEndDatePickerOpen: false,
    };
  }

  onDatesChange = (startDateMom: Moment, endDateMom?: Moment) => {
    if (!!startDateMom && !endDateMom) {
      this.setState({ isEndDatePickerOpen: true });
    }
    this.props.onDatesChange(startDateMom, endDateMom);
  };

  render() {
    const { selectedYear, selectedMonth, readOnly, isYearView, endDate, startDate, allowClear } = this.props;
    const startDateMom = startDate ? simpleDateToMoment(startDate) : undefined;
    const endDateMom = endDate ? simpleDateToMoment(endDate) : undefined;
    return (
      <>
        {readOnly ? (
          <div className="readOnlyDateWrapper">
            <div className="readOnlyDate">
              {startDateMom!.format("DD. MMM YY")}
              <div className="weekDay">{startDateMom!.format("dd")}</div>
            </div>
            <Icon type="arrow-right" style={{ margin: "0px 6px" }} />
            <div className="readOnlyDate">
              {endDateMom!.format("DD. MMM YY")}
              <div className="weekDay">{endDateMom!.format("dd")}</div>
            </div>
          </div>
        ) : (
          <div className="datePickerWrapper">
            <DatePicker
              value={startDateMom}
              defaultPickerValue={moment()
                .year(selectedYear || moment().year())
                .month(isYearView ? 0 : selectedMonth || moment().month())
                .date(1)}
              onChange={(_startDate) =>
                this.onDatesChange(_startDate!, endDate ? moment(endDate, SDateFormat) : undefined)
              }
              format="DD. MMM YYYY"
              placeholder={lg.startdatum}
              allowClear={!!allowClear}
            />
            <div className="devider" style={this.props.arrowIconStyle || {}}>
              <Icon type="arrow-right" />
            </div>
            <DatePicker
              open={this.state.isEndDatePickerOpen}
              value={endDateMom}
              defaultPickerValue={startDateMom}
              onChange={(_endDate) => this.props.onDatesChange(startDateMom, _endDate!)}
              format="DD. MMM YYYY"
              disabled={!startDateMom}
              disabledDate={(mom) => !!(startDateMom && mom?.isBefore(startDateMom))}
              onOpenChange={(isOpen) => this.setState({ isEndDatePickerOpen: isOpen })}
              placeholder={lg.enddatum}
              allowClear={!!allowClear}
            />
          </div>
        )}
      </>
    );
  }
}
