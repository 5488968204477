import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Input, AutoComplete, Icon, Dropdown, Menu, Modal, Checkbox } from "antd";
import Table from "../../components/Table/Table";
import { Column, CellInfo, RowInfo } from "react-table-v6";
import "./styles.scss";
import { showAccounts } from "./helpers";
import AvFormField from "../../components/AvFormField/AvFormField";
import { useSelector } from "../../helpers/redux";
import { IAdminTenantData } from "../../shared/entities/IAdminData";
import { selectAdminTenantsData } from "../../selectors/selectAdminTenantsData";
import { sendPost } from "../../frontend-core/actions/send";
import { Tiers } from "../../shared/constants/Tiers";
import moment from "moment";
import { toSimpleDate } from "../../shared/helpers/timeHelpers";
import { useDispatch } from "react-redux";
import { DispFn } from "../../frontend-core/types/thunkTypes";

interface IFilter {
  user?: string;
}

type Props = {};

export const AdminTablePage = (p: Props) => {
  const adminData = useSelector((s) => s.adminData);
  const dispatch = useDispatch<DispFn>();
  const [filter, setFilter] = useState<IFilter>({});
  const [onlyPaid, setOnlyPaid] = useState<boolean>(false);
  const adminTenantsData = useSelector(selectAdminTenantsData);

  const tableData = React.useMemo(() => {
    const data = adminTenantsData
      .filter(
        (rowData) =>
          !filter?.user?.length ||
          !filter.user ||
          rowData.tenantAccounts.some((a) => a.name?.includes(filter.user!) || a.email?.includes(filter.user!))
      )
      .filter((rowData) => !onlyPaid || rowData.tier !== Tiers.Core);

    return data;
  }, [filter, adminTenantsData, onlyPaid])!;

  const accounts = React.useMemo(() => _.flatten(adminTenantsData.map((t) => t.tenantAccounts)), [adminData])!;

  // console.log(Tiers.Core);
  // console.log(Tiers.Pro);
  // console.log(adminTenantsData.map((a) => a.tier));
  // console.log(adminTenantsData.filter((a) => a.tier === Tiers.Pro).length);

  const tableColumns: Column<IAdminTenantData>[] = React.useMemo(
    () => [
      {
        Header: "Created",
        accessor: (r) => r.createdAt,
        Cell: (props) => moment(props.original.createdAt).format("L"),
        id: "createdAt",
        width: 100,
      },
      {
        Header: "Company",
        accessor: "name",
      },
      {
        Header: "Tier",
        accessor: "tier",
        width: 60,
        Cell: (props: CellInfo) => (
          <span
            style={{
              color: props.original.tier !== Tiers.Core ? "green" : undefined,
              fontWeight: props.original.tier !== Tiers.Core ? "bolder" : "normal",
            }}
          >
            {props.original.tier}
          </span>
        ),
      },
      {
        Header: "Trial Days",
        accessor: "daysUntilTrialEnd",
        width: 60,
      },
      {
        Header: "UTM",
        accessor: "utm",
        width: 60,
        Cell: (props: CellInfo) => (
          <span
            onClick={() => navigator?.clipboard?.writeText(props.original.utm)}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {props.original.utm}
          </span>
        ),
      },
      {
        Header: "Survey",
        accessor: "marketingChannel",
        width: 60,
        Cell: (props: CellInfo) => (
          <span
            data-rh={props.original.marketingChannel}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {props.original.marketingChannel}
          </span>
        ),
      },
      {
        Header: "REF",
        accessor: "referrer",
        width: 60,
        Cell: (props: CellInfo) => (
          <span
            onClick={() => navigator?.clipboard?.writeText(props.original.utm)}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {props.original.referrer}
          </span>
        ),
      },
      {
        Header: "Phone",
        accessor: "phoneNumber",
        width: 60,
      },
      {
        Header: "Setup Done",
        width: 50,
        accessor: "finishedSetupWizard",
        Cell: (props: CellInfo) => (
          <span
            style={{
              color: props.original.finishedSetupWizard ? undefined : "white",
              background: props.original.finishedSetupWizard ? undefined : "red",
              fontWeight: !props.original.finishedSetupWizard ? "bolder" : "normal",
            }}
          >
            {props.original.finishedSetupWizard ? "Yes" : "NO"}
          </span>
        ),
      },
      {
        Header: "Sneak",
        width: 50,
        accessor: "sneakedOneToFiveCompanySize",
        Cell: (props: CellInfo) => (
          <span
            style={{
              color: !props.original.sneakedOneToFiveCompanySize ? undefined : "white",
              background: !props.original.sneakedOneToFiveCompanySize ? undefined : "red",
              fontWeight: props.original.sneakedOneToFiveCompanySize ? "bolder" : "normal",
            }}
          >
            {props.original.sneakedOneToFiveCompanySize ? "Yes" : "NO"}
          </span>
        ),
      },
      {
        id: "initialAccountLastLogin",
        width: 120,
        Header: "Last Login (ADMIN)",
        accessor: (r) => r.initialAccountLastLogin,
        Cell: (props) =>
          props.original.initialAccountLastLogin ? moment(props.original.initialAccountLastLogin).format("L") : "-",
      },
      {
        Header: "Active Accounts",
        accessor: "activeAccountsCount",
        width: 70,
      },
      {
        Header: "Accounts",
        accessor: "numberOfAccounts",
        style: { cursor: "pointer" },
        Cell: (props: CellInfo) => {
          const shouldBeHighlighted =
            props.original.tenantAccounts.length > 1 &&
            moment(props.original.initialAccountLastLogin).isAfter(moment().add(-3, "days"));
          return (
            <div
              className={shouldBeHighlighted ? "highlighted" : ""}
              onClick={() => {
                showAccounts({ accounts: props.original.tenantAccounts });
              }}
            >
              {props.value}
            </div>
          );
        },
      },
      {
        Header: "BillableUsers",
        accessor: "billableUsers",
        width: 70,
        style: { cursor: "pointer" },
      },
      {
        Header: "Name",
        accessor: "initialAccountName",
        width: 150,
      },
      {
        Header: "Email",
        accessor: "initialAccountEmail",
        width: 200,
      },
      {
        Header: "Deleted",
        accessor: (r) => r.isDeleted,
        id: "isDeleted",
        Cell: (props: CellInfo) => {
          return <div>{props.original.isDeleted ? "true" : ""}</div>;
        },
      },
      {
        Header: "Options",
        width: 150,
        accessor: "tenantId",
        Cell: (props) => {
          return (
            <Dropdown.Button
              trigger={["click"]}
              overlay={
                <Menu>
                  <Menu.Item
                    key="1"
                    style={{ color: "red" }}
                    onClick={() => {
                      Modal.confirm({
                        title: "Delete: " + props.original.name,
                        content:
                          "Click on OK and wait 15 Seconds. If you want to abort it, you can just close the window.",
                        onOk: async () => {
                          return await new Promise((resolve) =>
                            setTimeout(async () => {
                              await dispatch(
                                sendPost(
                                  `/api/delete-tenant?token=${sessionStorage.getItem("admin-token")}&tenantId=${
                                    props.original.id
                                  }`,
                                  {}
                                )
                              );
                              resolve("");
                            }, 15000)
                          );
                        },
                      });
                    }}
                  >
                    Delete Tenant
                  </Menu.Item>
                </Menu>
              }
            ></Dropdown.Button>
          );
        },
      },
    ],
    []
  );

  const onSearch = (val) => {
    setFilter({ ...filter, user: val });
  };

  return (
    <div className="tablePageWrapper">
      <div id="searchbar">
        <AvFormField label={"Only show tenants with user"}>
          <AutoComplete onSearch={_.debounce(onSearch, 400)} placeholder="enter email or username">
            {accounts
              .filter((a) => a.name?.includes(filter.user!) || a.email?.includes(filter.user!))
              .map((a) => (
                <AutoComplete.Option key={a.id} value={a.email}>
                  {`${a.name} (${a.email})`}
                </AutoComplete.Option>
              ))}
          </AutoComplete>
        </AvFormField>
        <div className="onlyPaidFilter">
          <Checkbox children="Only Paid" checked={!!onlyPaid} onChange={() => setOnlyPaid(!onlyPaid)} />
        </div>
      </div>
      <Table data={tableData} columns={tableColumns} pageSize={100} showPagination={true} />
      <div className="adminTableFooter">Length: {tableData.length}</div>
    </div>
  );
};
