import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import { ReportType } from "../../../actions/reporting";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { ShiftReport } from "./shiftReport/ShiftReport";
import { UserReport } from "./userReport/UserReport";
import { DispFn } from "../../../frontend-core/types/thunkTypes";
import { shiftRepository } from "../../../repositories/shiftRepository";
import { trackingRepository } from "../../../repositories/TrackingRepository";
import "./styles.scss";
import _ from "lodash";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { RoleType } from "../../../shared/entities/IUser";
import moment from "moment";
import { SDateFormat } from "../../../shared/helpers/SimpleTime";
import { surchargeRepository } from "../../../repositories/surchargeRepository";
import { userDetailRepository } from "../../../repositories/userDetailRepository";

const mapStateToProps = (state: AppState) => {
  return {
    reportFilters: state.ui.shifts.reporting.filters,
    reportType: state.ui.shifts.reporting.reportType,
    sessionInfo: selectSessionInfo(state),
    shifts: state.data.shifts,
  };
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & DispatchBaseProps & BusyInjectorProps;

const ReportPage = (props: Props) => {
  useEffect(() => {
    const { dispatch, sessionInfo } = props;
    sessionInfo.isEmployee() && props.dispatch(surchargeRepository.fetchMany()); // so that also employees can see the surcharges in reports
    sessionInfo.isEmployee() && props.dispatch(userDetailRepository.fetchOne(sessionInfo.user.id)); // admins and mangers have a listener set for this data in the preloader

    return () => {
      if (props.shifts.length > 10000) {
        // removing data from redux, as big amount of fetched data can make the roster laggy
        const past31Days = moment().add(-31, "days").format(SDateFormat);
        const in60Days = moment().add(60, "days").format(SDateFormat);
        dispatch(shiftRepository.clearSelection(["date", "<=", past31Days]));
        dispatch(shiftRepository.clearSelection(["date", ">=", in60Days]));
        dispatch(trackingRepository.clearSelection(["date", "<=", past31Days]));
        console.log("removing far past and far future shifts from store");
      }
    };
  }, []);

  // make sure that employees only enter ShiftReport. UserReport is Manager / Admin only
  const showShiftReport = props.reportType === ReportType.shift || props.sessionInfo.user.role === RoleType.employee;
  const showUserReport = props.reportType === ReportType.user && props.sessionInfo.user.role !== RoleType.employee;

  return (
    <>
      {showShiftReport && <ShiftReport></ShiftReport>}
      {showUserReport && <UserReport></UserReport>}
    </>
  );
};

export const Report = withErrorBoundary(
  connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(busyInjector(ReportPage))
);
