import React, { useEffect, useState } from "react";
import { useSelector } from "../../helpers/redux";
import { Thread } from "./Thread/Thread";
import { ThreadsList } from "./Sidebar/ThreadsList/ThreadsList";
import { CreateNewThread } from "./Sidebar/CreateNewThread/CreateNewThread";
import { GroupThreadInfo } from "./Sidebar/GroupThreadInfo/GroupThreadInfo";
import BusyWrapper from "../../components/BusyWrapper/BusyWrapper";
import { pushNoteRepository } from "../../repositories/pushNoteRepository";
import { selectUnseenPushNotes } from "../../selectors/selectUnseenPushNotes";
import { PushNoteTopic } from "../../shared/entities/IPushNote";
import { useDispatch } from "react-redux";
import "./styles.scss";
import { ChatSidebarMode } from "./ChatSidebarMode";
import { ChatNotEnabledMessage } from "./ChatNotEnabledMessage";
import { userThreadInfoRepository } from "../../repositories/userThreadInfoRepository";
import { useLoading } from "../../helpers/useLoading";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import _ from "lodash";
import { selectUserThreadInfos } from "../../selectors/userThreadInfoSelector";
import { ThreadInfo } from "./Sidebar/ThreadInfo/ThreadInfo";

type Props = {};

export const Chat = React.memo((props: Props) => {
  const [selectedThreadId, setSelectedThreadId] = useState<string | undefined>();
  const [isLoading, load] = useLoading();
  const [mode, setMode] = useState<ChatSidebarMode>(ChatSidebarMode.ThreadsList);
  const userThreadInfos = useSelector(selectUserThreadInfos);
  const rosterSettings = useSelector((s) => s.data.rosterSettings[0]);

  useEffect(() => {
    // pick the first thread if one exists
    const lastUserThreadInfo = _.orderBy(userThreadInfos || [], (ti) => ti.lastMessageTimestamp).reverse()[0];
    lastUserThreadInfo && setSelectedThreadId(lastUserThreadInfo.id);
  }, []);

  return (
    <div className="fb grow shrink aCenter chat" style={{ background: "white", overflow: "hidden" }}>
      <div
        className="fb row"
        style={{
          flexGrow: 1,
          flexShrink: 1,
          overflow: "hidden",
          alignSelf: "center",
          maxWidth: 1400,
          minWidth: 820,
          margin: 40,
          borderRadius: 5,
          boxShadow: "0 2px 32px #00000038, 0 1px 4px #00000012",
        }}
      >
        {!rosterSettings.chatModuleEnabled && <ChatNotEnabledMessage />}
        {rosterSettings.chatModuleEnabled && (
          <>
            <div className="fb column">
              {mode === ChatSidebarMode.CreateNewThread && (
                <CreateNewThread setMode={setMode} setSelectedThreadId={setSelectedThreadId} />
              )}
              {mode === ChatSidebarMode.ThreadsList && (
                <ThreadsList
                  setSelectedThreadId={setSelectedThreadId}
                  selectedThreadId={selectedThreadId}
                  setMode={setMode}
                />
              )}
              {mode === ChatSidebarMode.GroupThreadInfo && selectedThreadId && (
                <GroupThreadInfo
                  setMode={setMode}
                  selectedThreadId={selectedThreadId}
                  setSelectedThreadId={setSelectedThreadId}
                />
              )}
              {mode === ChatSidebarMode.ThreadInfo && selectedThreadId && (
                <ThreadInfo
                  setMode={setMode}
                  selectedThreadId={selectedThreadId}
                  setSelectedThreadId={setSelectedThreadId}
                />
              )}
            </div>
            {selectedThreadId && <Thread selectedThreadId={selectedThreadId} setMode={setMode} />}
            {!selectedThreadId && !userThreadInfos.length ? (
              <BusyWrapper isBusy={isLoading()}>
                <div style={{ minWidth: 530 }} className="fb column grow shrink"></div>
              </BusyWrapper>
            ) : (
              <div />
            )}
          </>
        )}
      </div>
    </div>
  );
});
