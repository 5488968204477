import _ from "lodash";
import moment, { Moment } from "moment";
import { createSelector } from "reselect";
import { environment } from "../env";
import { Tiers } from "../shared/constants/Tiers";
import { IAdminData, IAdminTenantData } from "../shared/entities/IAdminData";
import { ITenantInfo } from "../shared/entities/ITenantInfo";
import { ITenantMisc } from "../shared/entities/ITenantMisc";
import { simpleDateToMoment } from "../shared/helpers/timeHelpers";
import { Map } from "../shared/types/general";
import { AppState } from "../types/AppState";

const getTierByActiveStripeProductIds = (productIds: string[] = []) => {
  if (productIds.includes(environment.getPremiumTierProductId())) {
    return Tiers.Pro;
  } else if (productIds.includes(environment.getLightTierProductId())) {
    return Tiers.Basic;
  } else {
    return Tiers.Core;
  }
};

export const getAdminTenantsDataForDate = (adminData: IAdminData, forDate = moment()) => {
  const oneWeekAgo = moment().add(-1, "weeks").toISOString();
  const tenantMiscsMap: Map<ITenantMisc> = {};
  adminData.tenantMiscs.forEach((e) => (tenantMiscsMap[e.id] = e));
  const now = moment();
  return _(
    adminData?.tenantInfos
      .filter((t) => moment(t.createdAt).isBefore(forDate))
      .map((tenantInfo: ITenantInfo) => {
        const t: ITenantInfo & ITenantMisc = { ...tenantInfo, ...tenantMiscsMap[tenantInfo.id] };

        // Need to filter out all accs
        const tenantAccounts = adminData!.accounts.filter(
          (a) => a.tenantId === t.id && moment(a.createdAt).isBefore(forDate)
        );
        const tier = getTierByActiveStripeProductIds(t.activeStripeProductIds);
        let trialPeriodEnd: Moment;
        if (moment(tenantInfo.createdAt).isAfter(simpleDateToMoment("2021-02-02"))) {
          trialPeriodEnd = moment(tenantInfo.createdAt).add(15, "days");
        } else {
          trialPeriodEnd = moment(tenantInfo.createdAt).add(1, "month");
        }

        const isTrialMode = trialPeriodEnd.isAfter(moment()) && tier === Tiers.Core;
        const daysUntilTrialEnd = isTrialMode ? trialPeriodEnd.diff(moment(), "days") : 0;
        return {
          ...t,
          tier,
          daysUntilTrialEnd,
          tenantAccounts,
          activeAccountsCount: tenantAccounts.filter((a) => a.lastLogin && a.lastLogin > oneWeekAgo).length,
          numberOfAccounts: tenantAccounts.length,
          initialAccountLastLogin: tenantAccounts.find((a) => a.userId === "u1")?.lastLogin,
          initialAccountEmail: t.initialAccountEmail || tenantAccounts.find((a) => a.userId === "u1")?.email,
          initialAccountName: t.initialAccountName || tenantAccounts.find((a) => a.userId === "u1")?.name,
          marketingChannel: t.marketingChannel || "",
        } as IAdminTenantData;
      })
  )
    .filter((t) => !!t.initialAccountEmail && !t.initialAccountEmail.includes("avetiq"))
    .orderBy("createdAt", "desc")
    .value();
};

export const selectAdminTenantsData = createSelector(
  [(s: AppState) => s.adminData],
  (adminData): IAdminTenantData[] => {
    if (!adminData?.tenantInfos) {
      return [];
    }

    return getAdminTenantsDataForDate(adminData);
  }
);
