import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../types/AppState";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { selectShiftApplications } from "../../../../../selectors/ShiftApplicationsSelector";
import _ from "lodash";
import { RoleType } from "../../../../../shared/entities/IUser";
import moment from "moment";
import { selectCurrentTier } from "../../../../../selectors/TierInfoSelector";
import { SDateFormat } from "../../../../../shared/helpers/SimpleTime";
import { Tiers } from "../../../../../shared/constants/Tiers";
import "./styles.scss";
import { Popover, Button, Icon } from "antd";
import { UsersListInviterModal } from "../../../../../components/UsersListInviterModal/UsersListInviterModal";
import { openModal } from "../../../../../actions/modal";

const mapStateToProps = (state: AppState) => {
  return {
    currentUser: selectSessionInfo(state).user,
    tier: selectCurrentTier(state),
    users: state.data.users,
  };
};

type OwnProps = {};
type State = { isOpen: boolean };
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _InviteUsersReminder extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  openModal = () => {};

  getPopoverContent = () => {
    if (!this.state.isOpen) {
      return null; // to unmounte the popover-content when it closes
    }

    return (
      <div className="inviteUsersContentMain">
        <div className="desc">
          {lg.um_den_dienstplan_einsehen_zu_können_benötigen_die_mitarbeiter_eine_einladung}
          <br />
        </div>
        <div className="advantagesWrapper">
          <div className="title">{lg.mitarbeier_mit_einem_zugang_können}:</div>
          <ul className="list">
            <li className="point">{lg.aktuelle_dienstplän_von_überall_per_app_einsehen}</li>
            <li className="point">{lg.benachrichtigung_bei_planänderungen_erhalten}</li>
            <li className="point">{lg.schichttausch_beantragen}</li>
            <li className="point">{lg.sich_auf_offene_schichten_bewerben}</li>
            <li className="point">{lg.urlaubsanträge_einreichen}</li>
            <li className="point">{lg.arbeitszeiten_per_app_stempeln}</li>
          </ul>
        </div>
        <div className="footer">
          <Button
            type="primary"
            icon="arrow-right"
            children={lg.jetzt_mitarbeiter_einladen}
            onClick={() => {
              this.setState({ isOpen: false });
              this.props.dispatch(openModal(UsersListInviterModal, {}, { maskClosable: false }));
            }}
          />
        </div>
      </div>
    );
  };

  render() {
    const { currentUser, tier, users } = this.props;
    const fiveMinAfterRegistration = moment(currentUser.createdAt).add(5, "minutes").unix();
    const now = moment().unix();
    const invitedUsers = users.filter((u) => u.isInvited || u.accountId);
    const notInvitedUsers = users.filter((u) => !u.isInvited && !u.accountId && !u.isDeleted);

    const showReminder =
      currentUser.role === RoleType.admin &&
      tier === Tiers.Core &&
      now >= fiveMinAfterRegistration &&
      users.length >= 5 &&
      invitedUsers.length <= 4;

    if (!showReminder) {
      return null;
    }

    return (
      <Popover
        content={this.getPopoverContent()}
        placement="bottom"
        trigger="click"
        visible={this.state.isOpen}
        onVisibleChange={(isOpen) => this.setState({ isOpen })}
      >
        <div
          className="inviteUsersReminderMain"
          data-rh={lg.jetzt_mitarbeiter_einladen}
          onClick={() => {
            this.setState({ isOpen: true });
          }}
        >
          {/* <div className="number">{notInvitedUsers.length}</div> */}
          <div className="iconWrapper">
            <Icon type="warning" />
          </div>
          <div className="text">
            {notInvitedUsers.length} {lg.mitarbeiter_ohne_zugang}
          </div>
        </div>
      </Popover>
    );
  }
}

export const InviteUsersReminder = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(_InviteUsersReminder);
