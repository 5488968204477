import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../types/AppState";
import { Column, RowInfo } from "react-table-v6";
import Table from "../../components/Table/Table";
import cn from "classnames";
import "./styles.scss";
import sortBy from "lodash/sortBy";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import { FiltersRow } from "./FiltersRow/FiltersRow";

import { Tooltip, Button, message, notification, Icon, Popover } from "antd";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { openModal } from "../../actions/modal";
import UserDetailsModal from "./UserDetailsModal/UserDetailsModal";

import { busyInjector, BusyInjectorProps } from "../../components/BusyInjector/BusyInjector";
import { withErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";

import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { userRepository } from "../../repositories/userRepository";
import { simpleDateToFormat } from "../../shared/helpers/timeHelpers";
import { IUser, IUserFull } from "../../shared/entities/IUser";
import { AccountStatus, UserInfo } from "../../shared/helpers/UserInfo";
import { ImportUsersModal } from "./ImportModal/ImportModal";
import Page from "../../components/Page/Page";
import _, { orderBy } from "lodash";
import { reorder } from "../../helpers/general";
import BusyWrapper from "../../components/BusyWrapper/BusyWrapper";
import { UserHeadCell } from "./UserHeadCell/UserHeadCell";
import { Tiers } from "../../shared/constants/Tiers";
import { selectCurrentTier } from "../../selectors/TierInfoSelector";
import { UsersListInviterModal } from "../../components/UsersListInviterModal/UsersListInviterModal";
import { selectUsersFull } from "../../selectors/usersFullSelector";
import { setUsersPageSetting } from "../../reducers/ui/general/usersPageSetting";
import * as Sentry from "@sentry/browser";
import { AvatarImage } from "../../components/AvatarImage/AvatarImage";
import { MasterDataFormFieldManager } from "../../components/MasterDataFormFieldManager/MasterDataFormFieldManager";
import { userCsvExport } from "./userPageHelpers";
import { selectIsFreshTenant } from "../../selectors/selectIsFreshTenant";
import { AvIcon } from "../../components/AvIcon/AvIcon";
import { usersHaveIntersectingBranch } from "../../selectors/UsersWithSharedBranchSelector";

export const getDeactivationLabel = (lastWorkDay: string): string => {
  const date = simpleDateToFormat(lastWorkDay!, "L");
  return `Letzter Arbeitstag ${date}`;
};

const mapStateToProps = (state: AppState) => {
  return {
    jobPositions: state.data.jobPositions,
    branches: state.data.branches,
    sessionInfo: selectSessionInfo(state),
    users: selectUsersFull(state),
    tier: selectCurrentTier(state),
    usersPageSetting: state.ui.general.usersPageSetting,
    isFreshTenant: selectIsFreshTenant(state),
  };
};

type FilterState = {
  searchInput: string;
  positionFilter?: string;
  branchFilter?: string;
  isNameFilterVisible: boolean;
  isJobPosFilterVisible: boolean;
  isBranchFilterVisible: boolean;
  isOptionsPopoverOpen: boolean;
};

type State = FilterState;
type OwnProps = RouteComponentProps;
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class UsersPage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchInput: "",
      positionFilter: undefined,
      branchFilter: undefined,
      isNameFilterVisible: true,
      isJobPosFilterVisible: false,
      isBranchFilterVisible: false,
      isOptionsPopoverOpen: false,
    };
  }

  getStatusTranslation = (user: IUserFull) => {
    const translations = {
      [AccountStatus.passive]: {
        color: "blue",
        title: lg.passiv,
        tooltip: lg.der_mitarbeiter_hat_kein_zugang_und_kann_die_App_nicht_benutzen,
      },
      [AccountStatus.active]: {
        color: "green",
        title: lg.aktiv,
        tooltip: lg.der_mitarbeiter_ist_aktiviert_und_kann_sich_einloggen,
      },
      [AccountStatus.deactivated]: {
        color: "grey",
        title: lg.deaktiviert,
        tooltip: lg.dieser_account_ist_deaktiviert_der_mitarbeiter_kann_sich_nicht_einloggen,
      },
      invited: {
        color: "orange",
        title: lg.eingeladen,
        tooltip: lg.es_wurde_eine_einladung_per_mail_an_den_mitarbeiter_versendet,
      },
    };

    const accontStatus = new UserInfo(user).getAccountStatus();
    const isPassiveAndInvited = accontStatus === AccountStatus.passive && user.isInvited;
    return translations[isPassiveAndInvited ? "invited" : accontStatus];
  };

  tableColumns: Column[] = [
    {
      Header: () => (
        <UserHeadCell
          label={lg.name}
          isFilterVisible={this.state.isNameFilterVisible}
          toggleFilter={(isVisible) => {
            this.setState({
              isNameFilterVisible: isVisible,
              searchInput: "",
            });
          }}
        />
      ),
      accessor: "name",
      Cell: (props) => {
        const user = props.original.user as IUserFull;
        const renderBadge = () => {
          if (user.role === "admin") {
            return <div className="badge admin">{lg.admin}</div>;
          }
          if (user.role === "manager") {
            return <div className="badge manager">{lg.manager}</div>;
          } else {
            return null;
          }
        };
        return (
          <div className="userCell">
            {/* <Icon type="more" style={{ marginRight: 12, cursor: "move", userSelect: "none" }} /> */}
            <AvatarImage src={user.photoUrl} size={30} style={{ marginRight: 10 }} />
            <span className="userName">{user.name}</span>
            {renderBadge()}
          </div>
        );
      },
      id: "name",
    },
    {
      Header: () => (
        <UserHeadCell
          label={lg.standort}
          isFilterVisible={this.state.isBranchFilterVisible}
          toggleFilter={(isVisible) => {
            this.setState({
              isBranchFilterVisible: isVisible,
              branchFilter: undefined,
            });
          }}
        />
      ),
      accessor: "branchNames",
      Cell: (props) => {
        const text = props.value.join(" / ");
        return (
          <div className="fb jStart">
            <div data-rh={text} className="cell-text-wrapper">
              {text}
            </div>
          </div>
        );
      },
      show: !!(this.props.branches.length > 1),
      id: "branch",
    },
    {
      Header: () => (
        <UserHeadCell
          label={lg.rolle}
          isFilterVisible={this.state.isJobPosFilterVisible}
          toggleFilter={(isVisible) => {
            this.setState({
              isJobPosFilterVisible: isVisible,
              positionFilter: undefined,
            });
          }}
        />
      ),
      accessor: "jobPositionNames",
      Cell: (props) => {
        const text = props.value.join(" / ");
        return (
          <div className="fb jStart">
            <div data-rh={text} className="cell-text-wrapper">
              {text}
            </div>
          </div>
        );
      },
    },
    {
      Header: lg.Status,
      accessor: "status",
      Cell: (props) => {
        const user = props.original.user;

        const canInvite = new UserInfo(user).getAccountStatus() === AccountStatus.passive && user.email;
        return (
          <div className="row fb aCenter grow">
            <div
              className={cn({
                statusBadge: true,
                [this.getStatusTranslation(user).color]: true,
              })}
            ></div>
            <Tooltip placement="topLeft" title={this.getStatusTranslation(user).tooltip}>
              <div>{this.getStatusTranslation(user).title}</div>
            </Tooltip>
            {user.lastWorkDay && (
              <div
                className="inactivationTag"
                data-rh={lg.letzter_arbeitstag + " " + simpleDateToFormat(user.lastWorkDay!, "L")}
              >
                <Icon type="contacts" />
                {/* {simpleDateToFormat(user.lastWorkDay!, "L")} */}
              </div>
            )}
            {canInvite && (
              <Button
                id="send-user-invite"
                icon="mail"
                loading={this.props.isLoading(`inviting_${user.id}`)}
                style={{ marginLeft: "auto" }}
                children={lg.einladen}
                onClick={(e) => {
                  e.stopPropagation();
                  this.sendUserInvite(user);
                }}
              />
            )}
          </div>
        );
      },
      id: "status",
    },
  ];

  getTrProps = (_: any, rowInfo: RowInfo) => {
    return {
      onClick: () =>
        this.props.dispatch(openModal(UserDetailsModal, { userId: rowInfo.original.id }, { maskClosable: false })),
      style: { cursor: "pointer" },
      rowInfo,
    };
  };

  getFilteredUsers = (): IUserFull[] => {
    const { positionFilter, branchFilter, searchInput } = this.state;
    const { usersPageSetting, sessionInfo } = this.props;
    const { hideDisabledUsers } = usersPageSetting;
    const inputLow = searchInput ? searchInput.toLowerCase() : "";
    const isManager = sessionInfo.isManager();

    return this.props.users
      .filter((u) => !u.isDeleted)
      .filter((u) => !isManager || usersHaveIntersectingBranch(u, sessionInfo.user))
      .filter((u) => !hideDisabledUsers || new UserInfo(u).getAccountStatus() !== AccountStatus.deactivated)
      .filter((u) => !searchInput || u.name.toLowerCase().includes(inputLow))
      .filter((u) => !branchFilter || u.branchIds.includes(branchFilter))
      .filter((u) => !positionFilter || u.jobPositionIds.includes(positionFilter));
  };

  routeTo = (route: string) => {
    this.props.history.push(route);
  };

  sendUserInvite = async (user: IUserFull) => {
    Sentry.addBreadcrumb({ message: "sendUserInvite clicked", data: user });
    await this.props.load(this.props.dispatch(userRepository.sendInvitationMail(user)), `inviting_${user.id}`);

    notification.success({
      message: lg.es_wurde_eine_einladung_per_email_gesendet,
    });
  };

  generateUserList = () => {
    const newList = this.getFilteredUsers().map((user) => {
      const userBranches = user.branchIds.map((bId) => this.props.branches.find((br) => bId === br.id)?.name);
      const userJobPositions = this.props.jobPositions
        .filter((jP) => user.jobPositionIds.includes(jP.id))
        .map((jp) => jp.name);

      const generatedUser = {
        id: user.id,
        name: user.name,
        branchNames: userBranches,
        jobPositionNames: userJobPositions,
        status: this.getStatusTranslation(user).title,
        user: user,
        role: user.role,
      };
      return generatedUser;
    });
    return newList;
  };

  toggleHideDisabledOnes = () => {
    const { dispatch, usersPageSetting } = this.props;
    const hideDisabledUsers = !usersPageSetting.hideDisabledUsers;
    dispatch(setUsersPageSetting({ ...usersPageSetting, hideDisabledUsers }));
    notification.info({
      message: hideDisabledUsers
        ? lg.deaktivierte_mitarbeiter_werden_ausgeblendet
        : lg.deaktivierte_mitarbeiter_werden_angezeigt,
    });
  };

  getPopoverContent = () => (
    <div className="userOptionsPopover" onClick={() => this.setState({ isOptionsPopoverOpen: false })}>
      <div
        className="option"
        id="open-ImportUsersModal-button"
        onClick={() => this.props.dispatch(openModal(ImportUsersModal, {}))}
      >
        <Icon type="cloud-upload" />
        {lg.aus_csv_importieren}
      </div>
      <div
        className="option last"
        id="open-ImportUsersModal-button"
        onClick={() => this.props.dispatch(userCsvExport())}
      >
        <Icon type="file-excel" />
        {lg.csv_export}
      </div>
    </div>
  );

  renderHowToInvitePopover = () => (
    <div className="howToInviteUsersPopover">
      <div className="content">
        <div className="mainText">
          {lg.tragen_sie_die_email_adresse_des_mitarbeiers_ein_und_klicken_sie_auf_speichern_einladen}
        </div>
        <div>{lg.der_mitarbeiter_erhält_so_eine_einladungs_email_um_ein_passwort_zu_wählen}</div>
        <div>
          {lg.mit_der_email_adresse_und_dem_passwort_kann_der_mitarbeiter_sich_anschließend_in_der_app_einloggen}
        </div>
      </div>
    </div>
  );

  render() {
    const usersSorted = orderBy(this.generateUserList(), ["sortIndex", "name"]);
    const disabledUsersExist = this.props.users.some((u) => u.lastWorkDay);
    const hideDisabledUsers = this.props.usersPageSetting.hideDisabledUsers;
    const hasInvited3Users = this.props.users.filter((u) => u.isInvited).length >= 3;

    const usersToInvite = this.props.users.filter(
      (u) => !u.isDeleted && !u.lastWorkDay && !u.isInvited && !u.accountId
    );

    const showUserInviteButton = usersToInvite.length >= 5;
    const userInviteIsUrgent = this.props.tier === Tiers.Core;

    return (
      <Page>
        <div className="userListPageMain pagePadding">
          <h2 className="avTitle">{lg.mitarbeiter_plural}</h2>
          <div className="fb row actionRowBar">
            <div className="actionButtons">
              <Button
                id="add-user-button"
                children={lg.mitarbeiter_hinzufügen}
                icon="plus"
                type="primary"
                onClick={() =>
                  this.props.dispatch(openModal(UserDetailsModal, { userId: undefined }, { maskClosable: false }))
                }
              />
              {showUserInviteButton && (
                <div
                  className={cn({ inviteUsersReminerBtn: true, userInviteIsUrgent })}
                  data-rh={lg.jetzt_mitarbeiter_einladen}
                  onClick={() => {
                    this.props.dispatch(openModal(UsersListInviterModal, {}, { maskClosable: false }));
                  }}
                >
                  <div className="iconWrap">
                    <Icon type="warning" />
                  </div>
                  <div className="number">{usersToInvite.length}</div>
                  <div>{lg.mitarbeiter_ohne_einladung}</div>
                </div>
              )}
            </div>
            <FiltersRow
              onSearchChange={(searchInput: string) => this.setState({ searchInput })}
              onBranchChange={(branchFilter: string) => this.setState({ branchFilter })}
              onPositionChange={(positionFilter: string) => this.setState({ positionFilter })}
              searchInput={this.state.searchInput}
              positionFilter={this.state.positionFilter}
              branchFilter={this.state.branchFilter}
              isNameFilterVisible={this.state.isNameFilterVisible}
              isJobPosFilterVisible={this.state.isJobPosFilterVisible}
              isBranchFilterVisible={this.state.isBranchFilterVisible}
            />
            <div className="actionButtonsRight">
              {disabledUsersExist && (
                <div
                  data-rh={
                    hideDisabledUsers ? lg.deaktivierte_mitarbeiter_anzeigen : lg.deaktivierte_mitarbeiter_ausblenden
                  }
                  className={cn({ hideDisabledUsersButton: true, isActive: hideDisabledUsers })}
                  onClick={this.toggleHideDisabledOnes}
                >
                  <Icon type="vertical-align-middle" />
                </div>
              )}
              <Popover
                visible={this.state.isOptionsPopoverOpen}
                onVisibleChange={(isOpen) => this.setState({ isOptionsPopoverOpen: isOpen })}
                content={this.getPopoverContent()}
                title={null}
                trigger="click"
                placement="bottomRight"
              >
                <Button
                  style={{ marginLeft: 8 }}
                  id="open-ImportUsersModal-button"
                  children="CSV"
                  type="default"
                  icon="cloud-upload"
                />
              </Popover>
            </div>
          </div>
          {!hasInvited3Users && (
            <Popover content={this.renderHowToInvitePopover()} placement="bottomLeft">
              <div className="fb row howToInviteInfo">
                <AvIcon type="icon-Idea" />
                <div className="text">{lg.wie_werden_mitarbeiter_eingeladen}</div>
              </div>
            </Popover>
          )}
          <BusyWrapper>
            <Table
              data={usersSorted}
              columns={this.tableColumns}
              showPagination={false}
              pageSize={usersSorted.length}
              getTrProps={this.getTrProps as any}
              NoDataComponent={() => null}
            />
          </BusyWrapper>
        </div>
      </Page>
    );
  }
}

export default withErrorBoundary(
  withRouter(connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(busyInjector(UsersPage)))
);
