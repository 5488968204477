import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../types/AppState";
import "./styles.scss";
import { Dispatch } from "redux";
import { Icon, Tooltip } from "antd";
import cn from "classnames";
import { hideEmptyAbsenceRows } from "../../../../../actions/absence";

const mapStateToProps = (state: AppState) => ({
  emptyAbsenceRowsHidden: state.ui.absences.emptyAbsenceRowsHidden,
});

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & { dispatch: Dispatch };

class HideAbsenceRowsButton extends PureComponent<Props, {}> {
  render() {
    const { dispatch, emptyAbsenceRowsHidden } = this.props;

    return (
      <Tooltip title={lg.leere_zeilen_ausblenden}>
        <div
          className={cn({ selected: emptyAbsenceRowsHidden }) + " hideEmptyRowsBtn"}
          onClick={() => {
            dispatch(hideEmptyAbsenceRows(!emptyAbsenceRowsHidden));
          }}
        >
          <Icon type="vertical-align-middle" />
        </div>
      </Tooltip>
    );
  }
}

export default connect<StoreProps, { dispatch: Dispatch }, OwnProps, AppState>(mapStateToProps)(HideAbsenceRowsButton);
