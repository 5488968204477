import React from "react";
import Page from "../../components/Page/Page";
import { connect } from "react-redux";
import { HandOverRequestPaper } from "./HandOverRequestsPaper/HandOverRequestsPaper";
import { TrackingRequestsPaper } from "./TrackingRequestsPaper/TrackingRequestsPaper";
import { AppState } from "../../types/AppState";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import { withErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";
import { ShiftApplicationsPaper } from "./ShiftApplicationsPaper/ShiftApplicationsPaper";
import "./styles.scss";
import { ChangeRequestsPaper } from "./ChangeRequestsPaper/ChangeRequestsPaper";
import { OpenShiftsPaper } from "./OpenShiftsPaper/OpenShiftsPaper";
import { LiveStatusPaper } from "./LiveStatusPaper/LiveStatusPaper";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { RouteComponentProps } from "react-router-dom";
import { MyUpcomingShiftsPaper } from "./MyUpcomingShiftsPaper/MyUpcomingShiftsPaper";
import { selectRosterSettingsByUser } from "../../selectors/rosterSettingsByUserSelector";
import { AnnouncementsPaper } from "./AnnouncementsPaper/AnnouncementsPaper";

const mapStateToProps = (state: AppState) => {
  return {
    sessionInfo: selectSessionInfo(state),
    generalRosterSettings: state.data.rosterSettings[0],
    rosterSettingsByUser: selectRosterSettingsByUser(state),
    branches: state.data.branches,
  };
};

type OwnProps = {} & RouteComponentProps;
type State = {};
type Props = OwnProps & StoreProps & DispatchBaseProps;
type StoreProps = ReturnType<typeof mapStateToProps>;

class Dashboard extends React.PureComponent<Props, State> {
  render() {
    const { generalRosterSettings, rosterSettingsByUser, sessionInfo } = this.props;
    const rosterSettings = sessionInfo.hasManagerPermissions()
      ? generalRosterSettings
      : rosterSettingsByUser[sessionInfo.user.id];

    const canManage = sessionInfo.hasManagerPermissions();

    const displayLiveStatusPaper =
      canManage && this.props.branches.find((b) => sessionInfo.user.branchIds.includes(b.id) && b.isClockingEnabled);

    return (
      <Page noWrapper>
        <div className="dashboardMain">
          <div className="col" style={{ paddingRight: "20px" }}>
            {displayLiveStatusPaper && <LiveStatusPaper />}
            <TrackingRequestsPaper />
            {!canManage && <MyUpcomingShiftsPaper />}
          </div>

          <div className="col" style={{ paddingLeft: "20px" }}>
            {rosterSettings.usersCanSwapShifts && <HandOverRequestPaper />}
            {rosterSettings.usersCanMakeChangeRequests && <ChangeRequestsPaper />}
            {canManage && rosterSettings.usersCanApplyToOpenShifts && <ShiftApplicationsPaper />}
            {rosterSettings.usersCanApplyToOpenShifts && <OpenShiftsPaper />}
            {<AnnouncementsPaper />}
          </div>
        </div>
      </Page>
    );
  }
}

// export default Dashboard
export default withErrorBoundary(connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(Dashboard));
