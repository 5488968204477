import React from 'react';
import { FormikProps } from 'formik';
import { Input, Form } from 'antd';
import './styles.scss';
import { InputProps } from 'antd/lib/input';
import AvFormField from '../AvFormField/AvFormField';

type Props = {
  formikProps: FormikProps<any>;
  fieldName: string;
  noErrorMessage?: boolean; // to hide the error-message-div
  label?: string;
  wrapperClassName?: string;
  inputStyle?: {};
  onChangeCallback?: (v: any) => void;
  isRequired?: boolean;
} & InputProps;

export default class AvFormikInput extends React.PureComponent<Props, {}> {
  render() {
    const {
      fieldName,
      formikProps,
      label,
      noErrorMessage,
      wrapperClassName,
      inputStyle,
      onChangeCallback,
      isRequired,
      ...otherProps
    } = this.props; // fieldName is causing issues when passing along to <input>
    const isTouched = formikProps.touched[fieldName];
    const error: string | undefined | false =
      isTouched && (formikProps.errors[fieldName] as any);

    return (
      <AvFormField
        label={label}
        errorMessage={!noErrorMessage && error}
        wrapperClassName={wrapperClassName}
        isRequired={isRequired}
      >
        <Form.Item
          style={{ marginBottom: 0 }}
          validateStatus={error ? 'error' : undefined}
          hasFeedback={true}
        >
          <Input
            type="text"
            onChange={(e) => {
              formikProps.handleChange(e);
              if (this.props.onChangeCallback) {
                this.props.onChangeCallback(e.target.value);
              }
            }}
            onBlur={formikProps.handleBlur}
            value={formikProps.values[fieldName]}
            name={fieldName}
            style={inputStyle || {}}
            {...otherProps}
          />
        </Form.Item>
      </AvFormField>
    );
  }
}
