import React, { MouseEvent } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { Checkbox, Button, message, Icon } from "antd";
import "./styles.scss";
import { rosterSettingsRepository } from "../../../repositories/rosterSettingsRepository";
import _ from "lodash";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { IRosterSettings } from "../../../shared/entities/IRosterSettings";
import WorkSpaceEditor from "../branches/branchPopup/workspaceEditor/WorkSpaceEditor";
import { openModal } from "../../../actions/modal";
import { CustomMonthStartModal } from "./CustomMonthStartModal/CustomMonthStartModal";
import { RuleSpecificationBtn } from "../../../components/RuleSpecificationBtn/RuleSpecificationBtn";
import { AddressesSection } from "./AddressesSection/AddressesSection";
import { BreakRulesModal, defaultBreakRules } from "./BreakRulesModal/BreakRulesModal";
import { MinRestHoursModal, standardRestHours } from "./MinRestHoursModal/MinRestHoursModal";
import { surchargeRepository } from "../../../repositories/surchargeRepository";
import { SurchargesSection } from "./SurchargesSection/SurchargesSection";
import { EditShiftsIntervalModal } from "./EditShiftsIntervalModal/EditShiftsIntervalModal";
import { HashtagSection } from "./HashtagsSection/HashtagsSection";
import { selectWorkSpaces } from "../../../selectors/_workSpacesSelector";
import cn from "classnames";

const mapStateToProps = (state: AppState) => ({
  rosterSettings: state.data.rosterSettings[0], // setting-entities are always tables with only one row -> so we grab the index 0
  isV2: state.data.tenantInfo.isV2,
  workSpaces: selectWorkSpaces(state),
  columns: state.ui.reportSettings.reportColumns,
});

type State = {
  settings: IRosterSettings;
  isLoading: boolean;
  isModalOpen?: boolean; // TODO remove
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _RosterSettingsComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      settings: this.props.rosterSettings,
      isLoading: false,
      isModalOpen: true,
    };
  }

  openBreakRules = (e: MouseEvent<any, any>, key: "breakRules" | "minimumBreakRules") => {
    e.stopPropagation();
    e.preventDefault();

    this.props.dispatch(
      openModal(BreakRulesModal, {
        rules: this.state.settings[key],
        onComplete: (rules) => this.updateSettings({ [key]: rules }),
      })
    );
  };

  openMinRestHoursModal = (e: MouseEvent<any, any>) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.dispatch(
      openModal(MinRestHoursModal, {
        hours: this.state.settings.minRestHoursBetweenShifts,
        onComplete: (hours) => this.updateSettings({ minRestHoursBetweenShifts: hours }),
      })
    );
  };

  renderCheckbox = (
    fieldName: keyof IRosterSettings,
    label: React.ReactNode,
    options?: {
      invert?: boolean;
      withSpecification?: boolean;
      onChange?: Function; // to override the on-change function
      hide?: boolean;
      infoText?: string;
      onUpdate?: (checked: boolean) => void;
    }
  ) => {
    return options?.hide ? null : (
      <div className="checkboxWrapper" key={fieldName}>
        <Checkbox
          children={label}
          checked={options?.invert ? !this.state.settings[fieldName] : !!this.state.settings[fieldName]}
          onChange={(e) => {
            options?.onChange
              ? options?.onChange(e)
              : this.updateSettings({ [fieldName]: options?.invert ? !e.target.checked : e.target.checked });
            options?.onUpdate && options.onUpdate(e.target.checked); // update in next render cycle to not override setState
          }}
        ></Checkbox>
        {options?.infoText && (
          <div className="infoIconWrapper" data-rh={options.infoText}>
            <Icon type="info-circle" />
          </div>
        )}
        {options?.withSpecification && this.state.settings[fieldName] && (
          <RuleSpecificationBtn ruleKey={fieldName} type="RosterSettings" style={{ marginLeft: "auto" }} />
        )}
      </div>
    );
  };

  saveSettings = async () => {
    await this.props.dispatch(
      rosterSettingsRepository.update({
        ...this.props.rosterSettings,
        ...this.state.settings,
      })
    );
  };

  updateSettings = (props: Partial<IRosterSettings>) => {
    console.log("props");
    console.log(props);
    this.setState({ settings: { ...this.props.rosterSettings, ...props } }, () => {
      this.saveSettings();
    });
  };

  renderBreakRulesRow = () => (
    <span>
      {lg.schicht_pausen_automatisch_gemäß_der_pausenregelung_eintragen((e) => this.openBreakRules(e, "breakRules"))}
    </span>
  );

  renderAddEditShiftsRow = () => (
    <span>
      {lg.mitarbeiter_koennen_eigene_schichten_erstellen_und_editieren}
      {this.state.settings.usersCanAddEditOwnShifts && (
        <span
          className="openBreakRulesBtn"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.props.dispatch(openModal(EditShiftsIntervalModal, {}));
          }}
          data-rh="Zeitraum einschränken"
        >{`${lg.Tage}`}</span>
      )}
    </span>
  );

  renderMaxWorkTimeRow = () => (
    <span>
      {lg.warnung_bei_unterschreitung_der_gesetzlichen_ruhezeit_ruhepausen(this.openMinRestHoursModal, (e) =>
        this.openBreakRules(e, "minimumBreakRules")
      )}
    </span>
  );

  onChangeUsersCanAddEditOwnShifts = () => {
    const defaultMinMax = { min: -7, max: 60 };
    this.updateSettings({
      usersCanAddEditOwnShifts: this.state.settings.usersCanAddEditOwnShifts ? undefined : defaultMinMax,
    });
  };

  toggleReportingWageColumn = (checked: boolean) => {
    console.log("checked", checked);
    //dispatch(setReportColumns({ ...columns, [key]: !columns[key] }));
  };

  render() {
    const { rosterSettings, workSpaces, isV2 } = this.props;
    const { settings } = this.state;
    const customMonthStart = this.props.rosterSettings.customMonthIntervalStart || 1;

    return (
      <div className={cn({ rosterSettingsMain: true, isV2 })}>
        {/* <h2 className="avTitle">Dienstplanung</h2> */}
        <h2 className="">{isV2 ? lg.allgemein : lg.dienstplanung}</h2>
        <div className="content">
          <div className={cn({ generalRosterSettings: true, isV2 })}>
            <div className="basicCheckBoxesList">
              {[
                this.renderCheckbox(
                  "usersCanApplyToOpenShifts",
                  <span>{lg.mitarbeiter_können_sich_auf_offene_schichten_bewerben()}</span>,
                  { withSpecification: true, hide: isV2 }
                ),
                this.renderCheckbox(
                  "usersCanMakeChangeRequests",
                  <span>{lg.mitarbeiter_können_schichtänderung_beantragen()}</span>,
                  { withSpecification: true, hide: isV2 }
                ),
                this.renderCheckbox(
                  "usersCanSwapShifts",
                  <span>{lg.mitarbeiter_können_schichttausch_beantragen()}</span>,
                  {
                    withSpecification: true,
                    hide: isV2,
                  }
                ),
                this.renderCheckbox(
                  "usersCanSetAvailabilities",
                  <span>{lg.mitarbeiter_können_verfügbare_zeiten_angeben()}</span>,
                  {
                    withSpecification: true,
                    hide: isV2,
                  }
                ),
                this.renderCheckbox(
                  "usersCanSetUnavailabilities",
                  <span>{lg.mitarbeiter_können_nicht_verfügbare_zeiten_angeben()}</span>,
                  { withSpecification: true, hide: isV2 }
                ),
                this.renderCheckbox(
                  "employeesCanNotSeeShiftsOfCoWorkers",
                  <span>{lg.mitarbeiter_können_die_schichten_der_kollegen_sehen()}</span>,
                  { invert: true, hide: isV2 }
                ),
                this.renderCheckbox(
                  "employeesCanSeeShiftCommentsOfCoworker",
                  <span>{lg.mitarbeiter_können_die_schichten_kommentare_der_kollegen_sehen()}</span>,
                  { withSpecification: true, hide: isV2 }
                ),
                this.renderCheckbox(
                  "usersOfOtherRolesAreHidden",
                  <span>{lg.mitarbeiter_sehen_nur_die_kollegen_mit_selber_rolle()}</span>,
                  { hide: isV2 }
                ),
                this.renderCheckbox(
                  "showRequirementRowInRoster",
                  <span>{lg.personalbedarf_kann_im_dienstplan_eingetragen_werden()}</span>,
                  { hide: isV2 }
                ),
                // this.renderCheckbox(
                //   "shiftSurchargesDisabled",
                //   <span>{lg.aufschläge_können_pro_schicht_festgelegt_werden()}</span>,
                //   { invert: true }
                // ),
                // this.renderCheckbox(
                //   "canSetBranchSpecificHolidays",
                //   <span>
                //     <b>Feiertage für Standorte</b> definieren.
                //   </span>
                // ),
                this.renderCheckbox(
                  "markFreeDaysAsUnavailable",
                  <span>{lg.mitarbeiter_an_freien_tagen_als_nicht_verfügbar_anzeigen()}</span>,
                  { withSpecification: true, hide: isV2 }
                ),
                this.renderCheckbox(
                  "canEnterBreakStartTime",
                  <span>{lg.die_uhrzeit_der_pause_ist_beim_erstellen_einer_schicht_anzugeben()}</span>,
                  { hide: isV2 }
                ),
                this.renderCheckbox(
                  "applyBreakRulesOnShifts",
                  this.renderBreakRulesRow(),
                  {
                    hide: isV2,
                    onUpdate: () => {
                      !settings.breakRules && this.updateSettings({ breakRules: defaultBreakRules });
                    },
                  }
                  // if there are not break-rules set > add them here
                ),
                this.renderCheckbox("warningOnExceedingMaxWorkTime", this.renderMaxWorkTimeRow(), {
                  onUpdate: () => {
                    !settings.breakRules && this.updateSettings({ breakRules: defaultBreakRules }); // if there are not break-rules set > add them here
                    !settings.minRestHoursBetweenShifts && // if there are no minRestHoursBetweenShifts set > add them here
                      this.updateSettings({ minRestHoursBetweenShifts: standardRestHours });
                  },
                }),
                this.renderCheckbox("usersCanAddEditOwnShifts", this.renderAddEditShiftsRow(), {
                  onChange: this.onChangeUsersCanAddEditOwnShifts,
                  withSpecification: true,
                  hide: isV2,
                }),
                this.renderCheckbox("wagesCanBeEntered", <span>{lg.stundenlohn_kann_hinterlegt_werden()}</span>, {
                  onUpdate: (checked) => this.toggleReportingWageColumn(checked),
                  infoText:
                    "Unter `Mitarbeiter > Arbeitszeit` kann der Stundenlohn eingetragen werden. \n Unter `Auswertungen` wird der Lohn berechent und angezeigt. ",
                }),
                this.renderCheckbox(
                  "shiftAddressesActive",
                  <span>{lg.schicht_adressen_können_definiert_werden()}</span>,
                  { hide: isV2 }
                ),
              ]}
            </div>
          </div>
        </div>
        {rosterSettings.allowCustomMonthInterval && !isV2 && (
          <div
            className="customMonthStartWrapper"
            onClick={() => this.props.dispatch(openModal(CustomMonthStartModal, {}))}
          >
            {lg.monatsintervall_beginnt_am_month_start_des_monats(customMonthStart)}
          </div>
        )}
        <div className="section">
          <div className="workSpacesWrapper">
            <WorkSpaceEditor />
            <div style={{ height: 22 }} />
            <SurchargesSection />
          </div>
          <div className="shifAddressSection">
            <AddressesSection />
            <div style={{ height: 22 }} />
            <HashtagSection />
          </div>
        </div>
      </div>
    );
  }
}

export const RosterSettings = withErrorBoundary(
  connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(_RosterSettingsComponent)
);
