type UsersPageSetting = {
  hideDisabledUsers: boolean;
};

export const SET_USERS_PAGE_SETTING = "SET_USERS_PAGE_SETTING";

export const setUsersPageSetting = (userPageSetting: UsersPageSetting) => ({
  type: SET_USERS_PAGE_SETTING,
  payload: userPageSetting,
});

const initialState: UsersPageSetting = {
  hideDisabledUsers: false,
};

export const usersPageSettingRedcuer = (state = initialState, a: any): UsersPageSetting =>
  a.type === SET_USERS_PAGE_SETTING ? a.payload : state;
