import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { openModal } from "../../../actions/modal";
import AvailabilityModal from "../../../components/modals/AvailabilityModal/AvailabilityModal";

import { AvailabilityEntry } from "./availabilityEntry/AvailabilityEntry";
import "./styles.scss";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";

import moment from "moment";
import { selectActiveUsers } from "../../../selectors/ActiveUserSelectors";
import _ from "lodash";
import { SDateFormat } from "../../../shared/helpers/SimpleTime";
import { IAvailability } from "../../../shared/entities/IAvailability";
import { simpleDateToMoment } from "../../../shared/helpers/timeHelpers";
import { selectUserFullMap } from "../../../selectors/userFullMapSelector";
import { selectUserMap } from "../../../selectors/mapSelectors";
import { decryptUser } from "../../../shared/helpers/userHelpers";
import { Button } from "antd";
import { IUserFull } from "../../../shared/entities/IUser";

const mapStateToProps = (state: AppState) => {
  return {
    sessionInfo: selectSessionInfo(state),
  };
};

type OwnProps = {
  user: IUserFull;
  availabilities: IAvailability[];
  availabilityClicked: (a: IAvailability) => void;
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class AvailabilitiesList extends React.PureComponent<Props, {}> {
  addAvailabilityClicked = (isAvailable: boolean) => {
    this.props.dispatch(
      openModal(AvailabilityModal, {
        userId: this.props.user.id,
      })
    );
  };

  render() {
    const { availabilities, user } = this.props;

    return (
      <div className="availabilitiesListMain">
        {/* {!visibleAvailabilities.length && <div className="emptyAvailabilities">{lg.keine_einträge_vorhanden}</div>} */}
        <div className="userName">{user.name}</div>

        <div className="content">
          <div className="availabilitisCol">
            {/* {!availabilities.length && (
              <div className="emptyEntry">Hier können Verfügbarkeiten eingetragen werden.</div>
            )} */}
            {availabilities.map((av) => (
              <AvailabilityEntry key={av.id} availability={av} onClick={(_av) => this.props.availabilityClicked(_av)} />
            ))}
            <Button
              size="small"
              icon="plus"
              type={availabilities.length ? "ghost" : "default"}
              style={{ width: 116 }}
              onClick={() => this.addAvailabilityClicked(true)}
              data-rh={lg.verfügbarkeit_hinzufügen}
              data-rh-at="right"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(AvailabilitiesList);
