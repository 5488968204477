import React from "react";
import { Select, Icon, Divider } from "antd";
import { SelectProps } from "antd/lib/select";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";
import { pastelColors } from "../../../../frontend-core/helpers/colors";
import { IShiftAddress } from "../../../../shared/entities/IShiftAddress";
import { closeModal, openModal } from "../../../../actions/modal";
import { sitemap } from "../../../../helpers/sitemap";
import { push } from "connected-react-router";
import { ShiftAddressModal } from "../../../../pages/settings/rosterSettings/AddressesSection/ShiftAddressModal/ShiftAddressModal";
import _ from "lodash";
import { antDesignSelectFilterSearch, orderAddresses } from "../../../../frontend-core/helpers/frontendHelpers";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";

const mapStateToProps = (state: AppState) => {
  return {
    shiftAddress: state.data.shiftAddresses,
    canManage: selectSessionInfo(state).hasManagerPermissions(),
  };
};

type State = {};
type OwnProps = {
  onChange: (value: string) => void;
  value: string | undefined;
  closeField: () => void;
  isDisplayOnly?: boolean;
} & SelectProps<any>;

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _AddressSelect extends React.PureComponent<Props, {}> {
  renderDisplayOnly = (shiftAddress: IShiftAddress) => (
    <div className="row fb aCenter rectBox">
      <div
        className="colorRectAddressSelect"
        style={{
          background: shiftAddress.color,
          color: pastelColors.includes(shiftAddress.color) ? "inherit" : "white",
        }}
      ></div>
      <div>{shiftAddress.name || shiftAddress.address}</div>
    </div>
  );

  addAddressClicked = () => {
    this.props.dispatch(openModal(ShiftAddressModal, { onComplete: (v) => this.props.onChange(v.id) }));
  };

  render() {
    const { value, onChange, shiftAddress, isDisplayOnly, canManage, ...otherProps } = this.props;

    const selectableAddresses = orderAddresses(shiftAddress).filter((ws) => !ws.isInactive);
    const selectedAddress = value && shiftAddress.find((ws) => ws.id === value);

    if (selectedAddress?.isInactive) {
      selectableAddresses.push(selectedAddress); // need to make sure inactive WS is among options, if its selected
    }

    return (
      <div className="avInputFieldMain">
        <span className="labelWrapper">
          <label>{lg.adresse}</label>
        </span>
        <div className="addressSelectWrapperMain">
          {isDisplayOnly ? (
            selectedAddress && this.renderDisplayOnly(selectedAddress)
          ) : (
            <Select
              id="shift-address-select"
              value={value}
              onChange={(value: string) => this.props.onChange(value)}
              style={{ width: "100%" }}
              showSearch={selectableAddresses.length > 6}
              autoFocus={canManage && !value} // autofocus only when expanding
              showAction={["focus", "click"]} // needed for autofocus to type in for search
              filterOption={antDesignSelectFilterSearch}
              {...otherProps}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider style={{ margin: "4px 0" }} />
                  <div className="addNewAddressOption" onMouseDown={this.addAddressClicked}>
                    <Icon type="plus" /> {lg.neues_adresse_erstellen}
                  </div>
                </div>
              )}
            >
              {selectableAddresses.map((ad) => (
                <Select.Option value={ad.id} key={ad.id} title={ad.name || ad.address}>
                  <div className="row fb aCenter shiftAddressSelectOption">
                    <div className="colorRectAddressSelect" style={{ background: ad.color }}>
                      <Icon
                        type="environment"
                        style={{ color: pastelColors.includes(ad.color) ? "#373737" : "white" }}
                      />
                    </div>
                    <div className="optionLabel">{ad.name || ad.address}</div>
                  </div>
                </Select.Option>
              ))}
              {/* {
                <Select.Option value={undefined} key="openModal">
                  <div className="addNewAddressOption" onMouseDown={this.addAddressClicked}>
                    <Icon type="plus" /> {lg.neues_adresse_erstellen}
                  </div>
                </Select.Option>
              } */}
            </Select>
          )}
        </div>
        {!isDisplayOnly && (
          <div
            className="closeExpandedFieldButton"
            onClick={() => {
              this.props.onChange("");
              this.props.closeField();
            }}
          >
            <Icon type="close" />
          </div>
        )}
      </div>
    );
  }
}

export const AddressSelect = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(_AddressSelect);
