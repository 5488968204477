import { Button, Input, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { closeModal } from "../../../../../actions/modal";
import "./styles.scss";
import { busyInjector, BusyInjectorProps } from "../../../../../components/BusyInjector/BusyInjector";
import TZModal from "../../../../../components/TZModal/TZModal";
import _ from "lodash";
import { IDailyNote } from "../../../../../shared/entities/IDailyNote";
import { useSelector } from "../../../../../helpers/redux";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { AppState } from "../../../../../types/AppState";
import AvColorPicker from "../../../../../components/AvColorPicker/AvColorPicker";
import { dailyNoteRepository } from "../../../../../repositories/dailyNoteRepository";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { selectVisibleBranches } from "../../../../../selectors/VisibleBranchesSelector";
import { featuresSelector } from "../../../../../selectors/FeaturesSelector";
import { paidFeatureWarning } from "../../../../../actions/paidFeatureWarning";

const mapStateToProps = (state: AppState) => {
  return {
    selectedBranch: state.ui.selectedBranch,
    sessionInfo: selectSessionInfo(state),
    visibleBranches: selectVisibleBranches(state),
    features: featuresSelector(state),
  };
};

type OwnProps = {
  dailyNote?: IDailyNote;
  date: string;
};

type State = {
  text: string;
  pickedColor: string;
  branchId: string | undefined;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class _DailyNoteModal extends React.PureComponent<Props, State> {
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      text: this.props.dailyNote ? this.props.dailyNote.text : "",
      pickedColor: this.props.dailyNote ? this.props.dailyNote.color : "#f5222d",
      branchId: this.props.dailyNote?.branchId || this.props.selectedBranch || undefined,
    };
  }

  delete = async () => {
    await this.props.load(this.props.dispatch(dailyNoteRepository.remove(this.props.dailyNote!.id)), "delete");
    this.close();
  };

  create = async () => {
    const isCreate = !this.props.dailyNote;

    if (isCreate && !this.props.features.dailyNotes) {
      this.props.dispatch(paidFeatureWarning());
      return;
    }

    await this.props.load(
      this.props.dispatch(
        !isCreate
          ? dailyNoteRepository.update({
              ...this.props.dailyNote!,
              text: this.state.text,
              date: this.props.date,
              color: this.state.pickedColor,
            })
          : dailyNoteRepository.create({
              text: this.state.text,
              date: this.props.date,
              color: this.state.pickedColor,
              branchId: this.state.branchId!,
            })
      ),
      "save"
    );

    this.close();
  };

  close = () => {
    this.props.dispatch(closeModal());
  };

  inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      text: e.target.value,
    });
  };

  render() {
    const canManage = this.props.sessionInfo.hasManagerPermissions();

    return (
      <TZModal className="sortOrderModal">
        {/* <TZModal.Head>Tagesnotiz</TZModal.Head> */}
        <TZModal.Body>
          <div className="addDailyNoteModalBody">
            <div className="title">{lg.tagesnotiz}</div>
            <div className="formField">
              <div className="colorAndInputWrapper">
                <Input
                  style={{ borderRadius: 4, marginBottom: 6 }}
                  type="text"
                  onChange={canManage ? this.inputChangeHandler : undefined}
                  value={this.state.text}
                  placeholder={lg.text}
                  name="dailyNote"
                  autoFocus
                />
                {canManage && (
                  <AvColorPicker
                    value={this.state.pickedColor}
                    onChange={(value: string) => this.setState({ pickedColor: value })}
                  />
                )}
                {!this.props.selectedBranch && (
                  <Select
                    id="daily-note-branch-select"
                    value={this.state.branchId}
                    placeholder={lg.standort_auswählen}
                    onChange={(value: string) => {
                      this.setState({ branchId: value });
                    }}
                    style={{ width: "100%", marginTop: 6 }}
                    allowClear={false}
                  >
                    {this.props.visibleBranches.map((branch) => (
                      <Select.Option value={branch.id} key={branch.id}>
                        <div className="row fb aCenter">{branch.name}</div>
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </div>
            </div>
          </div>
        </TZModal.Body>
        {canManage && (
          <TZModal.Footer>
            {this.props.dailyNote && (
              <Button
                id="daily-note-delte"
                icon="delete"
                type="danger"
                disabled={!this.props.dailyNote}
                onClick={this.delete}
                loading={this.props.isLoading("delete")}
              />
            )}
            <Button
              id="daily-note-save"
              type="primary"
              children={lg.Speichern}
              onClick={this.create}
              style={{ marginLeft: "6px" }}
              disabled={!this.state.text.length || !this.state.branchId}
              loading={this.props.isLoading("save")}
            />
          </TZModal.Footer>
        )}
      </TZModal>
    );
  }
}

export const DailyNoteModal = connect(mapStateToProps)(busyInjector(_DailyNoteModal));
