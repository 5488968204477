import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../types/AppState";
import { busyInjector, BusyInjectorProps } from "../BusyInjector/BusyInjector";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";
import { Icon, Button, notification } from "antd";
import cn from "classnames";
import { getExistingEmails } from "../../actions/user";
import { userRepository } from "../../repositories/userRepository";
import BusyWrapper from "../BusyWrapper/BusyWrapper";
import { closeModal } from "../../actions/modal";
import TZModal from "../TZModal/TZModal";
import { IUser, IUserFull } from "../../shared/entities/IUser";
import { selectUserMap } from "../../selectors/mapSelectors";
import { selectUsersFull } from "../../selectors/usersFullSelector";
import { selectUserFullMap } from "../../selectors/userFullMapSelector";

const mapStateToProps = (state: AppState) => {
  return {
    users: selectUsersFull(state),
    userMap: selectUserFullMap(state),
  };
};

type OwnProps = {};
type State = { emailById: { [userId: string]: string | undefined } };
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & BusyInjectorProps & DispatchBaseProps;

class _UsersListInviterModal extends React.PureComponent<Props, State> {
  firstInputField: any;
  userToInvite: IUserFull[];
  constructor(props: Props) {
    super(props);

    this.userToInvite = props.users.filter((u) => !u.isInvited && !u.accountId && !u.isDeleted && !u.lastWorkDay);

    this.state = { emailById: {} };

    this.userToInvite.forEach((u) => {
      if (u.email && this.isEmailValid(u.email)) {
        this.state.emailById[u.id] = u.email;
      }
    });
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.firstInputField?.focus();
    });
  };

  isEmailValid = (email?: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email && re.test(String(email).toLowerCase());
  };

  updateEmail = (userId: string, email?: string) => {
    this.setState({
      emailById: {
        ...this.state.emailById,
        [userId]: email,
      },
    });
  };

  inviceClicked = async () => {
    const { users, dispatch, userMap } = this.props;
    const { emailById } = this.state;

    if (this.props.isLoading()) {
      return;
    }

    const newEmails = Object.entries(this.state.emailById)
      .filter(([userId, email]) => email && userMap[userId].email !== email)
      .map(([userId, email]) => email) as string[];

    const douplicates = await dispatch(getExistingEmails(newEmails));

    if (douplicates.length) {
      const _emails = douplicates.join(", ");
      const message = lg.folgende_email_adressen_sind_bereits_angelegt + " \n" + _emails;
      return notification.warning({ message });
    }

    const usersWithEmail = users.filter((u) => this.isEmailValid(emailById[u.id]));
    const nextUsers = usersWithEmail.map((u) => ({ ...u, email: emailById[u.id] }));
    await dispatch(userRepository.updateList(nextUsers));

    for (let user of nextUsers) {
      await dispatch(userRepository.sendInvitationMail(user));
    }
    this.props.dispatch(closeModal());
  };

  render() {
    const validEmailsCount = this.userToInvite.filter((u) => this.isEmailValid(this.state.emailById[u.id])).length;

    return (
      <BusyWrapper isBusy={this.props.isLoading()}>
        <TZModal>
          <div className="usersListInviterMain">
            <div className="head">{lg.email_adressen_eingeben_und_mitarbeiter_einladen}</div>
            <div className="desc">{lg.es_wird_ein_aktivierungs_link_an_die_mitarbeiter_geschickt}</div>
            <div className="content">
              <div className="userList">
                <div className="headrow">
                  <div className="cell">{lg.name}</div>
                  <div className="cell">{lg.email}</div>
                  <div className="" style={{ width: 40 }}></div>
                </div>
                {this.userToInvite.map((u, i) => {
                  const isValid = this.isEmailValid(this.state.emailById[u.id]);
                  return (
                    <div className="userRow" key={u.id}>
                      <div className="cell nameCell">{u.name}</div>
                      <div className="cell inputCell">
                        <input
                          ref={(input) => {
                            i === 0 && (this.firstInputField = input);
                          }}
                          className="emailInput"
                          type="text"
                          value={this.state.emailById[u.id] || ""}
                          onChange={(e) => this.updateEmail(u.id, e.target.value)}
                          autoFocus={i === 0}
                        />
                      </div>
                      <div className={cn({ iconWrapper: true, isValid })}>
                        {isValid ? <Icon type="check" /> : <Icon type="question" />}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="footer">
              <Button
                id="invice-users-list-button"
                type="primary"
                children={lg.einladungen_versenden + " " + (!!validEmailsCount ? `(${validEmailsCount})` : "")}
                icon={this.props.isLoading() ? "loading" : "cloud-upload"}
                disabled={validEmailsCount === 0}
                onClick={() => {
                  this.props.load(this.inviceClicked());
                }}
              />
            </div>
          </div>
        </TZModal>
      </BusyWrapper>
    );
  }
}

export const UsersListInviterModal = connect(mapStateToProps)(busyInjector(_UsersListInviterModal));
