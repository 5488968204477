import { BaseRepository } from "../frontend-core/BaseRepository";
import { sendPost } from "../frontend-core/actions/send";
import { getCalenderNeedsSyncUpdate, getSimpleDateToday } from "../frontend-core/helpers/frontendHelpers";
import { DispFn } from "../frontend-core/types/thunkTypes";
import { selectPublishedWeeksMap } from "../selectors/mapSelectors";
import { selectShiftMap } from "../selectors/shiftMapSelector";
import firebase from "firebase/compat/app";
import "firebase/database";
import { IPublishedWeek } from "../shared/entities/IPublishedWeek";
import { CrudOperation, Operation } from "../shared/helpers/firebaseHelpers";
import { AppState } from "../types/AppState";
import _ from "lodash";

class PublishedWeekRepository extends BaseRepository<IPublishedWeek> {
  constructor() {
    super("publishedWeeks");
  }

  preUpdateHook(writes: CrudOperation<IPublishedWeek>[]) {
    return async (dispatch: DispFn, getState: () => AppState) => {
      const iCalUsers = getState().data.users.filter((u) => u.hasICalSyncing);
      if (!iCalUsers.length) {
        return;
      }

      const publishedWeeksMap = selectPublishedWeeksMap(getState());
      const publishedWeeks = writes.map(
        (write) => (write.operation === Operation.remove ? publishedWeeksMap[write.entity.id] : write.entity) // neccesarry because in case fo remove the entitys are hollow  like this: ( { id: '1234' })
      );
      const affectedBranchesMap = {};
      publishedWeeks.forEach((pw) => (affectedBranchesMap[pw.branchId] = true));

      const today = getSimpleDateToday();
      const affectedUserIds = iCalUsers
        .filter((user) => !user.lastWorkDay || user.lastWorkDay >= today)
        .filter((user) => !!user.branchIds.find((bid) => affectedBranchesMap[bid]))
        .map((user) => user.id);

      if (affectedUserIds.length) {
        const tenantId = getState().data.auth.session!.tenantId;
        const calendarUpdates = getCalenderNeedsSyncUpdate(tenantId, affectedUserIds);
        firebase.database().ref().update(calendarUpdates);
      }
    };
  }
}

export const publishedWeekRepository = new PublishedWeekRepository();
