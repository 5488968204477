import { Button, Checkbox, Icon, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../actions/modal";
import "./styles.scss";
import { busyInjector, BusyInjectorProps } from "../../../../../components/BusyInjector/BusyInjector";
import TZModal from "../../../../../components/TZModal/TZModal";
import { orderUsers, reorder } from "../../../../../helpers/general";
import { useSelector } from "../../../../../helpers/redux";
import _ from "lodash";
import { IUser } from "../../../../../shared/entities/IUser";
import { userRepository } from "../../../../../repositories/userRepository";
import { decryptUser, getUserName } from "../../../../../shared/helpers/userHelpers";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";
import { rosterSettingsRepository } from "../../../../../repositories/rosterSettingsRepository";
import { RosterSettings } from "../../../../settings/rosterSettings/RosterSettings";

const DraggableUser = (props: { user: IUser; index: number; disabled?: boolean }) => {
  return (
    <Draggable draggableId={props.user.id} index={props.index} isDragDisabled={props.disabled}>
      {(provided) => (
        <div
          className="draggableUser"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {!props.disabled && <Icon type="more" />}
          <div className="index">{props.index + 1}</div>
          {getUserName(props.user)}
        </div>
      )}
    </Draggable>
  );
};

export const SortOrderModal = busyInjector((props: {} & BusyInjectorProps) => {
  const dispatch = useDispatch<DispFn>();
  const selectedBranch = useSelector((s) => s.ui.selectedBranch);
  const users = useSelector((s) => s.data.users);
  const rosterSetting = useSelector((s) => s.data.rosterSettings[0]);
  const [stateUsers, setStateUsers] = useState<IUser[]>([]);
  const [sortByName, setSortByName] = useState<boolean | undefined>(rosterSetting.sortRosterEmployeesByName);

  useEffect(() => {
    orderStateUsers(sortByName);
  }, [users, rosterSetting]);

  const orderStateUsers = (_sortByName: boolean | undefined) => {
    const usersOfBranch = users
      .filter((u) => !u.isDeleted && (!selectedBranch || u.branchIds.includes(selectedBranch)))
      .map((u) => decryptUser(u));

    const sortedUsers = orderUsers(usersOfBranch, selectedBranch, _sortByName);

    setStateUsers(sortedUsers);
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reOrderedUsers: IUser[] = reorder(stateUsers, result.source.index, result.destination.index).map((u, i) => {
      return {
        ...u,
        sortIndex: undefined,
        sortIndexByBranch: {
          ...(u.sortIndexByBranch || {}),
          [selectedBranch || "none"]: i,
        } as any,
      };
    });

    setStateUsers(reOrderedUsers);
  };

  const save = async () => {
    props.setLoading("all", true); // no idea, but props.load throws type error
    const sortRosterEmployeesByName = sortByName ? true : undefined;
    await dispatch(userRepository.updateList(stateUsers));
    await dispatch(rosterSettingsRepository.update({ ...rosterSetting, sortRosterEmployeesByName }));
    props.setLoading("all", false);
    dispatch(closeModal());
  };

  return (
    <TZModal className="sortOrderModal">
      <TZModal.Head>{lg.reihenfolge_ändern}</TZModal.Head>
      <TZModal.Body>
        <div className="sortOptions">
          <Radio.Group
            style={{ color: "white" }}
            options={[
              { value: false, label: lg.editierbar },
              { value: true, label: lg.alphabetisch },
            ]}
            value={!!sortByName}
            onChange={(e) => {
              setSortByName(e.target.value);
              setTimeout(() => orderStateUsers(e.target.value), 50);
            }}
          />
        </div>
        {!sortByName && <div className="infoText">{lg.reihenfolge_per_drag_drop_ändern}</div>}
        <TZModal.Row className="noPadding">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps} className="list">
                  {stateUsers.map((u, i) => {
                    return <DraggableUser user={u} index={i} disabled={sortByName} key={u.id} />;
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </TZModal.Row>
      </TZModal.Body>
      <TZModal.Footer>
        <Button
          id="cancel-sortorder-modal"
          onClick={async () => {
            dispatch(closeModal());
          }}
          type="danger"
          children={lg.abbrechen}
        />
        <Button
          id="save-sortorder-modal"
          type="primary"
          onClick={save}
          loading={props.isLoading("all")}
          style={{ marginLeft: "12" }}
          children={lg.Speichern}
        />
      </TZModal.Footer>
    </TZModal>
  );
});
