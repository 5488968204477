import { IUserTimeClockSettings } from "../shared/entities/ITimeClockSettings";
import { generateUserTimeClockSettings, isRuleApplyingToUser } from "../shared/helpers/settingsHelpers";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import { selectSessionInfo } from "./SessionInfoSelector";

// this is a workaround for the rule `manualTrackingIsDisabled`, because this rule is inverted, so when its False > its enabled.
// Thats why we cant use UserTimeClockSettings for this. So `isRuleApplyingToUser` has to be inverted too.

export const selectIsManualTrackingEnaledForSessionUser = createSelector(
  [(state: AppState) => state.data.timeClockSettings[0], selectSessionInfo],
  (timeClockSettings, sessionInfo): boolean => {
    return (
      !timeClockSettings.manualTrackingIsDisabled &&
      (!timeClockSettings.specifications ||
        isRuleApplyingToUser(timeClockSettings.specifications?.manualTrackingIsDisabled, sessionInfo.user))
    );
  }
);
