import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import "./styles.scss";
import { Icon } from "antd";
import { EmptyContent } from "../../../components/EmptyContent/EmptyContent";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { selectTrackingRequests } from "../../../selectors/TrackingRequestSelector";
import { TrackingRequestItem } from "../../../components/TrackingRequestItem/TrackingRequestItem";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { openModal } from "../../../actions/modal";
import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import BusyWrapper from "../../../components/BusyWrapper/BusyWrapper";
import _ from "lodash";
import { IShift } from "../../../shared/entities/IShift";
import { ShiftPopup } from "../../../components/ShiftPopup/ShiftPopup/ShiftPopup";
import { HintPopover } from "../../../components/HintPopover/HintPopover";
import { selectShiftMap } from "../../../selectors/shiftMapSelector";
import cn from "classnames";
import { CountToggler } from "../CountToggler/CountToggler";

const mapStateToProps = (state: AppState) => {
  return {
    trackingRequests: selectTrackingRequests(state),
    sessionInfo: selectSessionInfo(state),
    shiftMap: selectShiftMap(state),
  };
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;
type State = { isOpen: boolean };

class _TrackingRequestsPaper extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isOpen: false };
  }

  openModal = (shift: IShift) => {
    this.props.dispatch(openModal(ShiftPopup, { shift }));
  };

  render() {
    const { shiftMap, trackingRequests, sessionInfo } = this.props;
    const { isOpen } = this.state;
    const sortedTrackingRequests = _.orderBy(trackingRequests, ["date", "startTime"], ["desc", "desc"]);

    return (
      <div className="cell">
        <div className="content">
          <HintPopover
            hideHint={!sessionInfo.hasManagerPermissions()}
            placement="right"
            hint={
              lg.die_erfassten_arbeitszeiten_der_mitarbeiter_werden_aufgelistet_und_können_hier_bestätigt_oder_abgelehnt_werden
            }
          >
            <div className="dashboardPaper">
              <div className={cn({ isOpen }) + " title expandable"} onClick={() => this.setState({ isOpen: !isOpen })}>
                <Icon type="history" style={{ marginRight: "12px", color: "#1a90ff" }} />
                {lg.zeiterfassung}
                <CountToggler
                  count={trackingRequests.length}
                  isOpen={this.state.isOpen}
                  style={{ marginLeft: "auto" }}
                />
              </div>
              {isOpen && (
                <BusyWrapper isBusy={this.props.isLoading()} style={{ width: "100%" }}>
                  <div className="body">
                    <EmptyContent
                      show={trackingRequests.length ? false : true}
                      text={lg.keine_unbestätigte_zeiterfassung}
                    />

                    {sortedTrackingRequests.map((tracking) => {
                      const shift = shiftMap[tracking.id];
                      return (
                        <TrackingRequestItem
                          key={tracking.id}
                          tracking={tracking}
                          shift={shift}
                          openModal={() => this.openModal(shift)}
                        />
                      );
                    })}
                  </div>
                </BusyWrapper>
              )}
            </div>
          </HintPopover>
        </div>
      </div>
    );
  }
}

export const TrackingRequestsPaper = withErrorBoundary(connect(mapStateToProps)(busyInjector(_TrackingRequestsPaper)));
