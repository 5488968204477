import React from "react";
import "./styles.scss";
import { Icon } from "antd";
import cn from "classnames";
import _ from "lodash";

type Props = {
  isCollapsed?: boolean;
  onCollapsedChange: (isCol: boolean) => void;
  count: number;
  label: string;
};

const MAX_LENGTH_COUNT = 2;

export class SettingsListCollapser extends React.PureComponent<Props> {
  componentDidMount = () => {
    if (this.props.count > MAX_LENGTH_COUNT) {
      this.props.onCollapsedChange(true);
    }
  };

  render() {
    const { isCollapsed, onCollapsedChange, count, label } = this.props;

    return (
      <>
        {count > MAX_LENGTH_COUNT && (
          <div
            className={cn({ settingsCollapsedBarMain: true, isCollapsed })}
            onClick={() => onCollapsedChange(!isCollapsed)}
          >
            <Icon className="icon" type={!isCollapsed ? "caret-down" : "caret-right"} />
            <div className="label">{!isCollapsed ? `${count} ${label}` : `${count} ${label}`}</div>
          </div>
        )}
      </>
    );
  }
}
