import { Button, Icon, Input, message, Modal, Select } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import { useSelector } from "../../../../helpers/redux";
import { threadInfoRepository } from "../../../../repositories/threadInfoRepository";
import { userThreadInfoRepository } from "../../../../repositories/userThreadInfoRepository";
import { selectActiveUsers } from "../../../../selectors/ActiveUserSelectors";
import { selectUserMap } from "../../../../selectors/mapSelectors";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import { decryptUser, getUserName } from "../../../../shared/helpers/userHelpers";
import { busyInjector, BusyInjectorProps } from "../../../../components/BusyInjector/BusyInjector";
import BusyWrapper from "../../../../components/BusyWrapper/BusyWrapper";
import { ThreadsListStyles } from "../ThreadsList/styles";
import { ChatSidebarMode } from "../../ChatSidebarMode";
import { selectUserThreadInfos } from "../../../../selectors/userThreadInfoSelector";
import AvatarDefaultImageSource from "./../../avatar-default-image.png";

type Props = {
  setMode: (m: ChatSidebarMode) => void;
  setSelectedThreadId: (id: string | undefined) => void;
  selectedThreadId: string;
};

const Comp: React.FC<Props & BusyInjectorProps> = (props) => {
  const dispatch = useDispatch<DispFn>();
  const [displayAddUserField, setDisplayAddUserField] = useState(false);
  const [selectedNewUserId, setSelectedNewUserId] = useState<string | undefined>();
  const [displayTitleInput, setDisplayTitleInput] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const userMap = useSelector(selectUserMap);
  const activeUsers = useSelector((s) => selectActiveUsers(s, moment().format(SDateFormat)));
  const sessionInfo = useSelector(selectSessionInfo);
  const userThreadInfos = useSelector(selectUserThreadInfos);
  const threadInfos = useSelector((s) => s.data.threadInfos);
  const userThreadInfo = userThreadInfos.find((t) => t.id === props.selectedThreadId)!;
  const threadInfo = threadInfos.find((t) => t.id === props.selectedThreadId);
  const currentUserIsGroupAdmin = threadInfo?.adminUserIds?.includes(sessionInfo.user.id);

  const chatPartner = decryptUser(userMap[userThreadInfo.userIds.find((uId) => uId !== sessionInfo.user.id)!]);
  const photoUrl = chatPartner.photoUrl || AvatarDefaultImageSource;

  return (
    <BusyWrapper isBusy={props.isLoading()} style={{ flex: 1 }}>
      <div className="fb column shrink" style={{ ...ThreadsListStyles.wrapper, background: "#ededed" }}>
        {/* Head */}
        <div className="fb row" style={ThreadsListStyles.head}>
          <div
            className="fb row jCenter aCenter"
            style={{
              height: 60,
              background: "#ededed",
              padding: 6,
            }}
          >
            <Button
              onClick={() => props.setMode(ChatSidebarMode.ThreadsList)}
              icon="close"
              style={{ position: "absolute", left: 12, top: 12 }}
            />
            <span style={{ fontSize: 16, opacity: 0.8, textAlign: "center" }}>{lg.einstellungen}</span>
          </div>
          {/* <div className="fb row" style={ThreadsListStyles.headSecondRow}>
        </div> */}
        </div>

        {/* Content */}

        <div className="fb column" style={ThreadsListStyles.content}>
          <img
            src={photoUrl}
            style={{
              width: 100,
              height: 100,
              borderRadius: "100%",
              background: "gray",
              alignSelf: "center",
              marginBottom: 24,
              marginTop: 20,
              flexShrink: 0,
            }}
          />

          <div style={{ textAlign: "center", fontSize: 18, opacity: 1 }}>{chatPartner?.name}</div>

          <div className="fb column aCenter jCenter" style={{ margin: "12px 12px 0 12px" }}>
            <Button
              type="default"
              style={{ alignSelf: "stretch" }}
              children={userThreadInfo?.muted ? lg.stummschaltung_aufheben : lg.stumm_schalten}
              onClick={async () => {
                await props.load(dispatch(userThreadInfoRepository.mute(!userThreadInfo.muted, threadInfo?.id!)));
              }}
            />
          </div>

          {sessionInfo.isAdmin() && (
            <div className="fb column aCenter jCenter" style={{ margin: "12px 12px 0 12px" }}>
              <Button
                children={lg.konversation_endgültig_löschen}
                type="danger"
                style={{ alignSelf: "stretch" }}
                onClick={() =>
                  Modal.confirm({
                    title: lg.konversation_wirklich_löschen,
                    content: lg.alle_nachrichten_dieser_konversation_werden_gelöscht,
                    cancelText: lg.abbrechen,
                    okType: "danger",
                    okText: lg.löschen,
                    onOk: async () => {
                      props.setSelectedThreadId(undefined);
                      await props.load(dispatch(threadInfoRepository.delete(threadInfo!.id)));
                      props.setMode(ChatSidebarMode.ThreadsList);
                    },
                  })
                }
              />
            </div>
          )}
        </div>
      </div>
    </BusyWrapper>
  );
};

export const ThreadInfo = React.memo(busyInjector(Comp));
