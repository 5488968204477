import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import * as Yup from "yup";
import { Formik, FormikProps, Form, FormikHelpers } from "formik";
import AvFormikInput from "../../../../components/AvFormikInput/AvFormikInput";

import AvFormikSelect from "../../../../components/AvFormikSelect/AvFormikSelect";
import { Button, message, Checkbox, Icon, notification } from "antd";

import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";
import * as Sentry from "@sentry/browser";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import TZModal from "../../../../components/TZModal/TZModal";
import ContractEntry from "../ContractEntry/ContractEntry";
import cn from "classnames";
import { closeModal, openModal } from "../../../../actions/modal";
import { busyInjector, BusyInjectorProps } from "../../../../components/BusyInjector/BusyInjector";
import { UserDeactivateModal } from "../../UserDeactivateModal/UserDeactivateModal";
import { UserDeleteModal } from "../../UserDeleteModal/UserDeleteModal";
import { createUser, updateUser, doesEmailExist } from "../../../../actions/user";
import { selectActiveJobPositions } from "../../../../selectors/ActiveJobPositionsSelector";
import { selectActiveBranches } from "../../../../selectors/ActiveBranchesSelector";
import { userRepository } from "../../../../repositories/userRepository";
import _ from "lodash";
import { IContractCore, WorkInterval, IContract } from "../../../../shared/entities/IContract";
import { IUser, RoleType, IUserFull } from "../../../../shared/entities/IUser";
import { updateCreditsOfRoster } from "../../../../actions/creditActions/updateCreditsOfRoster";
import { UserInfo, AccountStatus } from "../../../../shared/helpers/UserInfo";
import { SetEmployeePasswordModal } from "../../SetEmployeePasswordModal/SetEmployeePasswordModal";
import moment from "moment";
import JobPositionsModal from "../../../settings/job-positions/JobPositionsModal/JobPositionsModal";
import { UserTabBar } from "../UserTabBar/UserTabBar";
import { contractMap } from "../../../../selectors/absencesExtendedSelector";
import { RolesExplenationField } from "./RolesExplenationBox/UserRoleField";
import AvFormikCheckbox from "../../../../components/AvFormikCheckbox/AvFormikCheckbox";
import { PickAllBranchesBox } from "./PickAllBranchesBox/PickAllBranchesBox";

const difference = (arrA: string[], arrB: string[]) =>
  arrA.filter((x) => !arrB.includes(x)).concat(arrB.filter((x) => !arrA.includes(x)));

export const FarFuture: string = "2200-12-31";
export const FarPast: string = "2000-01-01";

export const to4Digits = (_num: number | string): string => {
  const num = _num.toString();
  const digitsToAdd = 4 - num.length;
  const zeros = `0000`;
  const zerosToAdd = zeros.substr(0, digitsToAdd);
  return zerosToAdd + num;
};

export const roleOptions = [
  { value: RoleType.admin, label: lg.admin },
  { value: RoleType.manager, label: lg.manager },
  { value: RoleType.employee, label: lg.mitarbeiter },
];

export const defaultContract: IContractCore = {
  dailyQuota: {
    mo: 480,
    tu: 480,
    we: 480,
    th: 480,
    fr: 480,
    sa: null,
    su: null,
  },
  validFrom: FarPast,
  validTo: FarFuture,
  totalHours: 40,
  applyQuotaOnHolidays: false,
  interval: WorkInterval.weekly,
};

const mapStateToProps = (state: AppState) => {
  return {
    jobPositions: state.data.jobPositions,
    isV2: state.data.tenantInfo.isV2,
    activeJobPositions: selectActiveJobPositions(state),
    branches: selectActiveBranches(state),
    sessionInfo: selectSessionInfo(state),
    users: state.data.users,
    contracts: state.data.contracts,
    rosterSettings: state.data.rosterSettings[0],
  };
};

type State = {
  contract?: IContractCore;
};

type OwnProps = {
  user?: IUserFull;
  isCreationMode: boolean;
  branchId?: string;
  gotDirty: (dirty?: boolean) => void;
  closeOnSave: boolean;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class UserGeneralTab extends React.PureComponent<Props, State> {
  isCreationMode = false;
  sendInviteAfterSave = false;
  initialValues: Partial<IUserFull> = {};
  isFormPrepopulated = false;

  constructor(props: Props) {
    super(props);
    const { user, branches, activeJobPositions, jobPositions } = this.props;
    this.isCreationMode = !user;
    this.state = {
      contract: this.isCreationMode ? defaultContract : undefined,
    };

    this.initialValues = {
      name: user?.name || "",
      jobPositionIds: activeJobPositions.length > 1 ? user?.jobPositionIds : [activeJobPositions[0].id],
      branchIds: props.branchId ? [props.branchId] : branches.length > 1 ? user?.branchIds : [branches[0].id],
      email: user?.email,
      role: user?.role || RoleType.employee,
      timeClockId: user?.timeClockId || (this.isCreationMode ? to4Digits(_.random(1, 9999)) : ""),
      isHiddenInRoster: user?.isHiddenInRoster,
      canManageEmployees: user?.canManageEmployees,
    };
  }

  UNSAFE_componentWillMount = () => {
    const prevCreatedUser = _.orderBy(this.props.users, (u) => u.createdAt).reverse()[0];
    const fiveMinAgo = moment().add(-5, "minutes").toISOString();
    const isCreatingFirstUser = this.props.users.length === 1;
    // If creating new users within 5 minutes, we prepopulate parts of the form:
    if (this.props.isCreationMode && prevCreatedUser.createdAt > fiveMinAgo && !isCreatingFirstUser) {
      this.isFormPrepopulated = true;
      this.initialValues.branchIds = prevCreatedUser.branchIds;
      this.initialValues.jobPositionIds = prevCreatedUser.jobPositionIds;

      const contract = this.props.contracts.find((c) => c.userId === prevCreatedUser.id);
      const contractRaw = {
        ...contract,
        validFrom: FarPast,
        validTo: FarFuture,
        userId: undefined,
      } as any as IContractCore;
      this.setState({ contract: contractRaw });
    }
  };

  componentDidMount = () => {
    Sentry.addBreadcrumb({
      message: "opened user modal",
      data: {
        user: this.props.user,
      },
    });

    // This is done to cold-start the cloud-function. The first 2 executions take very long.
    this.props.dispatch(doesEmailExist("random-mail@aplano.de"));
    setTimeout(() => this.props.dispatch(doesEmailExist("random-mail@aplano.de")), 100);
    setTimeout(() => this.props.dispatch(doesEmailExist("random-mail@aplano.de")), 400);
  };

  showEmailSentNotification = (userName: string) => {
    message.success(lg.an_user_wurde_eine_email_zur_registrierung_gesendet(userName));
  };

  showUserSavedNotification = (userName: string) => {
    message.success(lg.mitarbeiterdaten_gespeichert);
  };

  setDuplicateEmailFieldError = (formikHelpers: FormikHelpers<Partial<IUser>>) => {
    formikHelpers.setFieldError("email", lg.diese_e_mail_ist_bereits_im_system_vergeben);
  };

  validationSchema = Yup.object().shape({
    name: Yup.string().min(3, lg.mindestens_3_zeichen).max(50, lg.maximal_50_zeichen).required(lg.eingabe_erforderlich),
    jobPositionIds: Yup.array().min(1, lg.mindestens_eine_rolle_wählen).required(lg.eingabe_erforderlich),
    branchIds: Yup.array().min(1, lg.mindestens_einen_standort_wählen).required(lg.eingabe_erforderlich),
    email: Yup.string().email(lg.ungültiges_email_format),
    timeClockId: Yup.string().matches(/^\d{4}$/, lg.vier_ziffern_eingeben),
  });

  saveData = async (values: Partial<IUserFull>, formikHelpers: FormikHelpers<Partial<IUserFull>>) => {
    this.props.setLoading("save", true);
    const { dispatch, closeOnSave } = this.props;
    const _user = this.props.user;
    const emailChanged = this.props.user?.email !== values.email;

    Sentry.addBreadcrumb({
      message: "saved user",
      data: { _user, values, contract: this.state.contract },
    });

    if (values.email && (emailChanged || this.isCreationMode) && (await dispatch(doesEmailExist(values.email)))) {
      this.setDuplicateEmailFieldError(formikHelpers);
      this.props.setLoading("save", false);
      return;
    }

    let user = undefined as any;
    try {
      user = this.isCreationMode
        ? await dispatch(createUser(values, this.state.contract!))
        : await dispatch(updateUser({ ...(_user || {}), ...values } as IUserFull));
    } catch (e: any) {
      console.error(e);
      notification.error({ message: e.message });
      dispatch(closeModal());
      Sentry.addBreadcrumb({ data: user, message: "createUser/updateUser issue" });
      Sentry.captureException(e);
      return;
    }

    this.props.setLoading("save", false);
    this.props.gotDirty(false);
    // need to update credits because users can be created from roster-page
    this.props.dispatch(updateCreditsOfRoster([user.id]));
    closeOnSave && dispatch(closeModal());

    if (this.sendInviteAfterSave) {
      this.props.dispatch(userRepository.sendInvitationMail(user));
      this.showEmailSentNotification(user.name);
    } else {
      this.showUserSavedNotification(user.name);
    }
  };

  reactivateUser = async () => {
    const { user } = this.props;
    this.props.dispatch(
      userRepository.update({
        ...user!,
        lastWorkDay: undefined,
      })
    );
  };

  branchChanged = (branchIds: string[] = [], formikProps: FormikProps<Partial<IUserFull>>) => {
    const { sessionInfo } = this.props;
    const { canSetBranchSpecificHolidays } = this.props.rosterSettings;

    const changedBranchId = difference(branchIds, formikProps.values.branchIds || [])[0];

    if (sessionInfo.isManager() && changedBranchId && !sessionInfo.user.branchIds.includes(changedBranchId)) {
      // dont allow a manager to edit the branches that he does not belong to. > thes resets the value:
      formikProps.setFieldValue("branchIds", formikProps.values.branchIds);
    }

    const mainBranchId = this.state.contract?.mainBranchId;
    // keeping mainBranchId in sync > so user doesnt have to set and sync maually
    if (canSetBranchSpecificHolidays && branchIds.length && (!mainBranchId || !branchIds.includes(mainBranchId))) {
      this.setState({ contract: { ...this.state.contract, mainBranchId: branchIds[0] } as any });
    }
  };

  selectAllBranches = (formikProps: FormikProps<Partial<IUserFull>>) => {
    const { branches, sessionInfo } = this.props;
    const isAdmin = sessionInfo.isAdmin();
    const selectableBranches = branches.filter((branch) => isAdmin || sessionInfo.user.branchIds.includes(branch.id));
    const selectableBranchIds = selectableBranches.map((b) => b.id);
    formikProps.setFieldValue("branchIds", selectableBranchIds);
  };

  renderAddJobPosOption = (formikProps: FormikProps<Partial<IUserFull>>) => {
    return (
      <div
        className="addNewRoleButton"
        onMouseDown={() => {
          this.props.dispatch(
            openModal(JobPositionsModal, {
              hideUserPicker: true,
              onComplete: (v) =>
                !this.props.isV2 &&
                formikProps.setFieldValue("jobPositionIds", [...(formikProps.values.jobPositionIds || []), v.id]),
            })
          );
        }}
      >
        <Icon type="plus" /> {lg.neue_rolle_erstellen}
      </div>
    );
  };

  preProcesJobPositions = (prev: string[], next: string[]) => {
    // inV2 jobPos should be just single value not multiSelect > so we just save the new selected jobPosId
    return next?.length === 2 ? [next[1]] : next;
  };

  renderForm = (formikProps: FormikProps<Partial<IUserFull>>) => {
    const { branches, activeJobPositions, jobPositions, user, rosterSettings, sessionInfo, isV2 } = this.props;

    const { email } = formikProps.values;
    const lastWorkDay = user?.lastWorkDay;

    const updatedEmailOfUser = !this.isCreationMode && !user?.accountId && user?.email !== email;

    const displaySaveAndInviteBtn = (this.isCreationMode && email) || updatedEmailOfUser;

    const isOwnUserEdit = sessionInfo.user.id === user?.id;
    const isManagerOwnUserEdit = sessionInfo.user.id === user?.id && sessionInfo.isManager();
    const isManagerEditingAdmin = sessionInfo.isManager() && user?.role === RoleType.admin;

    const hasBranchWithEnabledClocking = branches.some(
      (b) => formikProps.values.branchIds?.includes(b.id) && b.isClockingEnabled
    );

    const userJobPositions = jobPositions.filter((jp) => user?.jobPositionIds.includes(jp.id));

    const jobPositionOptions = _.uniqBy(
      // including the userJobPositions so if the user has an inactive JP it still displays it correctly
      [...userJobPositions, ...activeJobPositions],
      (jp) => jp.id
    ).map((j) => ({
      label: j.name,
      value: j.id,
    }));

    return (
      <Form className="userFormMain">
        <TZModal.Body>
          {user?.isInvited && !user.accountId && user.email && (
            <div className="invitedUserBar">
              <Icon type="mail" />
              <div className="text">{lg.miterbeiter_wurde_eingeladen}</div>
              <div className="action">
                {lg.alternativ_können_sie_selber_ein_passwort_vergeben(
                  <span
                    className="clickableText"
                    onClick={() => this.props.dispatch(openModal(SetEmployeePasswordModal, { user: this.props.user! }))}
                  >
                    {lg.passwort_vergeben}
                  </span>
                )}{" "}
                .
              </div>
            </div>
          )}
          {this.isFormPrepopulated && (
            <div className="formIsPrepopulatedNote">
              {lg.einige_felder_wurden_auf_basis_vorheriger_eingaben_vorbelegt}
            </div>
          )}
          <div className="content">
            <div className="row">
              <div className="topCell">
                <AvFormikInput
                  fieldName="name"
                  placeholder={lg.vor_nachname}
                  label={lg.name}
                  formikProps={formikProps}
                  isRequired={true}
                  onChangeCallback={this.props.gotDirty}
                />
              </div>
              <div className="topCell">
                <AvFormikInput
                  fieldName="email"
                  label={lg.email}
                  formikProps={formikProps}
                  onChangeCallback={this.props.gotDirty}
                />
              </div>
            </div>
            <div className="row">
              <div className="bottomCell">
                <AvFormikSelect
                  mode="default"
                  label={lg.berechtigung}
                  fieldName="role"
                  formikProps={formikProps}
                  options={roleOptions}
                  isRequired={true}
                  disabled={isOwnUserEdit || sessionInfo.isManager()}
                  onChangeCallback={() => {
                    (formikProps as FormikProps<any>).setFieldValue("canManageEmployees", undefined);
                    this.props.gotDirty();
                  }}
                  extraBox={<RolesExplenationField />}
                />
                {formikProps.values.role === RoleType.manager && (
                  <div className="row canManageUsersOption">
                    <AvFormikCheckbox
                      fieldName="canManageEmployees"
                      formikProps={formikProps}
                      label={lg.kann_mitarbeiter_verwalten}
                    />
                  </div>
                )}
              </div>
              <div className="bottomCell">
                <AvFormikSelect
                  mode="multiple"
                  label={lg.rolle}
                  fieldName="jobPositionIds"
                  formikProps={formikProps}
                  options={jobPositionOptions}
                  disabled={isManagerOwnUserEdit}
                  isRequired={true}
                  preProcess={isV2 ? this.preProcesJobPositions : undefined}
                  onChangeCallback={(x) => {
                    console.log(x);
                    this.props.gotDirty();
                  }}
                  additionalDropDownComponent={this.renderAddJobPosOption(formikProps)}
                />
              </div>
            </div>

            <div className="row">
              {branches.length > 1 && (
                <div className="bottomCell">
                  <AvFormikSelect
                    mode="multiple"
                    label={lg.standort}
                    fieldName="branchIds"
                    formikProps={formikProps}
                    options={branches.map((l) => ({ label: l.name, value: l.id }))}
                    extraBox={
                      branches.length >= 12 && this.isCreationMode ? (
                        <PickAllBranchesBox
                          selectAllBranches={() => this.selectAllBranches(formikProps)}
                          deseletAllBranches={() => formikProps.setFieldValue("branchIds", [])}
                          selectedBranchIds={formikProps.values.branchIds || []}
                        />
                      ) : null
                    }
                    isRequired={true}
                    onChangeCallback={(branchIds) => {
                      this.branchChanged(branchIds, formikProps);
                      this.props.gotDirty();
                    }}
                  />
                </div>
              )}
              {hasBranchWithEnabledClocking && (
                <div className="bottomCell">
                  <AvFormikInput
                    fieldName="timeClockId"
                    label={lg.stempeluhr_pin}
                    placeholder={lg.view_stellige_pin}
                    formikProps={formikProps}
                    onChangeCallback={this.props.gotDirty}
                  />
                </div>
              )}
            </div>
          </div>
          {this.isCreationMode && (
            <div className="contractWrapper">
              <ContractEntry
                contract={this.state.contract!}
                previousContract={undefined}
                displayValidFromDate={false}
                isEditable={true}
                onContractChange={(wp: IContractCore) => {
                  this.setState({ contract: wp });
                  this.props.gotDirty();
                }}
                onDelete={() => {}}
                isCollapsed={false}
                isDeletable={false}
                extraClassName={"isUserCreation"}
                rosterSettings={rosterSettings}
              />
            </div>
          )}
        </TZModal.Body>
        <TZModal.Footer>
          <div className="deactivateDelteUserBtnWrapper">
            {!isOwnUserEdit && !this.isCreationMode && !isManagerEditingAdmin && (
              <div
                data-rh={lg.mitarbeiter_löschen}
                className="deleteBtn linkBtn"
                id="delete-user-btn"
                style={{ marginRight: 4 }}
                children={<Icon type="delete" />}
                onClick={async () => this.props.dispatch(openModal(UserDeleteModal, { user: user as IUser }))}
              />
            )}
            {!isOwnUserEdit && !this.isCreationMode && (
              <div
                className="deactivateBtn linkBtn"
                id="activate-deactivate-user"
                children={lastWorkDay ? lg.aktivieren : lg.deaktivieren}
                onClick={() =>
                  lastWorkDay
                    ? this.props.dispatch(this.reactivateUser)
                    : this.props.dispatch(openModal(UserDeactivateModal, { user: user as IUser }))
                }
              />
            )}
            <div
              className={cn({ hideInRosterBtn: true, isActive: formikProps.values.isHiddenInRoster })}
              onClick={() => formikProps.setFieldValue("isHiddenInRoster", !formikProps.values.isHiddenInRoster)}
              data-rh={
                formikProps.values?.isHiddenInRoster
                  ? lg.dieser_mitarbeiter_wird_nicht_im_dienstplan_angezeigt
                  : lg.mitarbeiter_im_dienstplan_verbergen
              }
            >
              <Icon type="eye-invisible" />
            </div>
          </div>
          <div className="saveButtonsWrapper">
            <Button
              id="save-user-general-tab"
              style={{ marginRight: "auto" }}
              type={displaySaveAndInviteBtn ? "default" : "primary"}
              onClick={formikProps.submitForm}
              loading={!this.sendInviteAfterSave && this.props.isLoading("save")}
            >
              {lg.Speichern}
            </Button>
            {displaySaveAndInviteBtn && (
              <div className="saveAndInviteBtnWrapper">
                <Button
                  id="save-and-invite-user"
                  type="primary"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    // because of formik we cant pass props to submit, need to use a
                    // flag to sendInvite after saving.
                    this.sendInviteAfterSave = true;
                    formikProps.submitForm();
                  }}
                  loading={this.sendInviteAfterSave && this.props.isLoading("save")}
                >
                  {lg.speichern_einladen}{" "}
                </Button>
              </div>
            )}
          </div>
        </TZModal.Footer>
      </Form>
    );
  };

  render() {
    return (
      <div className="userGeneralTabMain">
        <Formik
          initialValues={this.initialValues}
          onSubmit={this.saveData}
          enableReinitialize
          validationSchema={this.validationSchema}
          children={this.renderForm}
        />
      </div>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  busyInjector(UserGeneralTab)
);
