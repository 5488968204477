import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../types/AppState";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";

import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import TZModal from "../TZModal/TZModal";
import "./styles.scss";

import { shiftRepository } from "../../repositories/shiftRepository";
import { closeModal } from "../../actions/modal";
import { Button, message } from "antd";
import _ from "lodash";
import moment from "moment";
import { selectRosterTemplates } from "../../selectors/rosterTemplatesSelector";
import * as Sentry from "@sentry/browser";
import cn from "classnames";
import { BusyInjectorProps, busyInjector } from "../BusyInjector/BusyInjector";
import { addSimpleDays } from "../../shared/helpers/dateHelpers";
import { toSimpleDate, toMomentUnsafe } from "../../shared/helpers/timeHelpers";
import { dailyNoteRepository } from "../../repositories/dailyNoteRepository";

const mapStateToProps = (state: AppState) => {
  return {
    sessionInfo: selectSessionInfo(state),
    rosterTemplates: selectRosterTemplates(state),
    selectedWeek: state.ui.shifts.roster.selectedWeek,
  };
};

type State = { selected?: string };
type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class _RosterTemplateListPopup extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: undefined,
    };
  }

  applyTemplate = async () => {
    const { dispatch } = this.props;
    const template = this.props.rosterTemplates.find((t) => t.id === this.state.selected)!;

    const startDate = template.shiftsStoredAtDate;
    const endDate = addSimpleDays(startDate, 6);
    const shifts = await dispatch(
      shiftRepository.fetchMany({
        filter: ["date", "between", [startDate, endDate]],
      })
    );

    const dailyNotes = await dispatch(
      dailyNoteRepository.fetchMany({
        filter: ["date", "between", [startDate, endDate]],
      })
    );

    Sentry.addBreadcrumb({
      message: "applied template",
      data: { selectedTemplate: this.state.selected },
    });

    const processedShifts = shifts.map((s) => ({
      ..._.omit(s, ["id"]),
      date: toSimpleDate(
        moment(this.props.selectedWeek as string).add("days", toMomentUnsafe(s.date as string)!.isoWeekday() - 1)
      ),
    }));

    const processedDailyNotes = dailyNotes.map((d) => ({
      ..._.omit(d, ["id"]),
      date: toSimpleDate(
        moment(this.props.selectedWeek as string).add("days", toMomentUnsafe(d.date as string)!.isoWeekday() - 1)
      ),
    }));

    await dispatch(shiftRepository.createList(processedShifts));
    await dispatch(dailyNoteRepository.createList(processedDailyNotes));

    message.success(lg.vorlage_wurde_übernommen);
    dispatch(closeModal());
  };

  // removeTemplate = (template: IRosterTemplate, e: React.MouseEvent) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   Modal.confirm({
  //     title: 'Vorlage löschen',
  //     content: `Die Vorlage "${template.name}" wirklich löschen?`,
  //     onOk: () => this.props.dispatch(rosterTemplateRepository.remove(template.id)),
  //   });
  //   return false;
  // };

  render() {
    return (
      <TZModal>
        <TZModal.Head>{lg.vorlage_importieren}</TZModal.Head>
        <TZModal.Body>
          <div className="rosterTemplates">
            {this.props.rosterTemplates.map((template) => {
              const selected = template.id === this.state.selected;
              return (
                <div
                  className={cn({ option: true, selected })}
                  //type={isSelected ? 'primary' : 'default'}
                  onClick={() => {
                    this.setState({ selected: template.id });
                  }}
                >
                  {template.name}
                </div>
              );
            })}
            {!this.props.rosterTemplates.length && <div>{lg.keine_vorlagen_vorhanden}</div>}
          </div>
        </TZModal.Body>
        <TZModal.Footer>
          <Button
            id="import-roster-to-week"
            children={lg.importieren}
            type="primary"
            disabled={!this.state.selected}
            onClick={() => {
              this.props.load(this.applyTemplate());
            }}
            loading={this.props.isLoading()}
          />
        </TZModal.Footer>
      </TZModal>
    );
  }
}

export const RosterTemplateListPopup = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(
  busyInjector(_RosterTemplateListPopup)
);
