import { setLg, importHack } from "./lg"; // DON'T REMOVE THIS

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { configureStore } from "./store";
import "./styles/index.scss";
import "react-table-v6/react-table.css";
import ReactHintFactory from "react-hint";
import "react-hint/css/index.css";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { createBrowserHistory } from "history";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { Helmet } from "react-helmet";
import { environment } from "./env";
import * as Sentry from "@sentry/browser";
import { notification } from "antd";
import { configureSentry } from "./frontend-core/helpers/sentryFrontend";
import { GoogleTagManagerInjector } from "./components/GoogleTagManagerInjector/GoogleTagManajerInjector";
console.log(importHack); // DON'T REMOVE THIS

const handleErrorEvent = (e: any) => {
  const isPromiseRejection = e instanceof PromiseRejectionEvent;
  //const isErrorEvent = e instanceof ErrorEvent;
  const _message = isPromiseRejection ? e.reason?.message : e.error?.message;
  const _error = isPromiseRejection ? e.reason : e.error;
  if (!environment.isProd()) {
    notification.warning({ message: _message || e });
  }
  Sentry.captureException(_error);
};

configureSentry(Sentry, environment.getAppEnv());

window.addEventListener("error", handleErrorEvent);
window.addEventListener("unhandledrejection", handleErrorEvent);
window.sentryLog = [];

declare global {
  interface Window {
    sentryLog: any[];
  }
}

const googleApiSrc =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyBzBPhgf1x-wEBvH3CmepYGk7WxqjvVzEU&libraries=places";

(async () => {
  const ReactHint = ReactHintFactory(React);
  const history = createBrowserHistory();

  const store = configureStore(history);
  const persistor = persistStore(store);

  const rootElement = document.getElementById("root");

  if (environment.isV2()) {
    rootElement?.classList.add("zeitguru");
  }

  const render = (Component: any) => {
    return ReactDOM.render(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ReactHint autoPosition events />
          <Helmet>
            <script src={googleApiSrc}></script>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
          </Helmet>
          <GoogleTagManagerInjector />
          <DndProvider backend={HTML5Backend}>
            <Component history={history} />
          </DndProvider>
        </PersistGate>
      </Provider>,
      rootElement
    );
  };
  render(App);
})();
