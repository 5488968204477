import React from "react";
import { InputNumber, Input } from "antd";
import "./styles.scss";

type Props = {
  disabled?: boolean;
  value?: number; // minutes
  onChange: (minutes: number) => void;
  size?: "small" | "default" | "large";
  inputWidth?: number;
  hoursPlaceholder?: string;
  minutesPlaceholder?: string;
  autoFocus?: string | boolean;
  showZero?: boolean;
  showTooltip?: boolean;
  showTooltipAt?: string;
  style?: React.CSSProperties;
};

export default class DurationInput extends React.PureComponent<Props, {}> {
  render() {
    const {
      value,
      onChange,
      disabled,
      size,
      inputWidth,
      hoursPlaceholder,
      minutesPlaceholder,
      autoFocus,
      showZero,
      showTooltip,
      showTooltipAt,
      style,
    } = this.props;

    const hours = Math.floor((value as any) / 60);
    const minutes = (value as any) % 60;

    return (
      <div className="durationInputMain">
        <Input.Group compact style={style || {}}>
          <InputNumber
            size={size || "small"}
            style={{
              width: inputWidth || 36,
              textAlign: "center",
              zIndex: 1,
            }}
            disabled={disabled}
            placeholder={hoursPlaceholder || "0"}
            value={hours === 0 && showZero ? 0 : hours || undefined}
            onChange={(h?: number) => onChange((h || 0) * 60 + (minutes || 0))}
            autoFocus={!!autoFocus}
            data-rh={showTooltip ? lg.stunden : null}
            data-rh-at={showTooltipAt || "bottom"}
          />
          <InputNumber
            size={size || "small"}
            style={{
              width: inputWidth || 36,
              textAlign: "center",
            }}
            disabled={disabled}
            placeholder={minutesPlaceholder || "0"}
            value={minutes === 0 && showZero ? 0 : minutes || undefined}
            onChange={(m?: number) => onChange((hours || 0) * 60 + (m || 0))}
            data-rh={showTooltip ? lg.minuten : null}
            data-rh-at={showTooltipAt || "bottom"}
          />
        </Input.Group>
      </div>
    );
  }
}
