import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import TZModal from "../../TZModal/TZModal";
import { Button } from "antd";
import { closeModal, openModal } from "../../../actions/modal";
import { assignOpenShiftToUser, cloneShift } from "../../../actions/shift";
import "./styles.scss";
import StartEndTimeInput from "../ShiftPopup/StartEndTimeInput/StartEndTimeInput";
import { OpenShiftInfoRow } from "../components/OpenShiftInfoRow/OpenShiftInfoRow";
import _ from "lodash";
import { Input } from "antd";
import { ShiftPopupHead } from "../components/ShiftPopupHead/ShiftPopupHead";
import { selectActiveUsersFull } from "../../../selectors/ActiveUserSelectors";
import {
  filterAvailabilitiesForUser,
  generateAvailabilityText,
  getUnavailableDuringShift,
} from "../../../helpers/workingTimeHelpers";
import { AbsenceLabel } from "../../AbsenceLabel/Absencelabel";
import { IShift } from "../../../shared/entities/IShift";
import { IUserFull } from "../../../shared/entities/IUser";
import { ShiftPopup } from "../ShiftPopup/ShiftPopup";
import { selectShiftMap } from "../../../selectors/shiftMapSelector";
import { selectAvailabilites } from "../../../selectors/availabilitiesSelector";
import { selectShiftsByUserMap } from "../../../selectors/mapSelectors";
import { doShiftsOverlap } from "../../../selectors/shiftOverlappingsSelector";
import { selectAbsencesByUser } from "../../../selectors/absencesByUserSelector";
import { UserCreditDetails } from "../../../pages/shiftsPage/RosterPage/components/UserCreditDetails/UserCreditDetails";
import { RosterMode } from "../../../reducers/ui/shifts/roster/rosterMode";
import { selectRosterDateRange } from "../../../selectors/rosterDateRangeSelector";
import { selectContractsByUser } from "../../../selectors/contractsByUserSelector";
import { selectCreditSpanForUser } from "../../../selectors/creditSpansSelector";
import { featuresSelector } from "../../../selectors/FeaturesSelector";
import { selectInitialCreditByUser } from "../../../selectors/initialCreditsByUserSelector";
import { getValidContract } from "../../../shared/helpers/credit";

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const shift = selectShiftMap(state)[ownProps.shiftId];
  return {
    shift: selectShiftMap(state)[ownProps.shiftId],
    activeUsers: shift ? selectActiveUsersFull(state, shift.date) : [],
    shifts: state.data.shifts,
    shiftsByUser: selectShiftsByUserMap(state),
    availabilities: selectAvailabilites(state),
    absences: state.data.absences,
    absencesByUser: selectAbsencesByUser(state),
    rosterMode: state.ui.shifts.roster.rosterMode,
    rosterDateRange: selectRosterDateRange(state),
    contractsMap: selectContractsByUser(state),
    state: state, // yes, super dirty > workaround is a lot of work
    features: featuresSelector(state),
    initialUserCreditsByUser: selectInitialCreditByUser(state),
    isTemplateMode: state.ui.shifts.roster.rosterTemplateMode.active,
    rosterSettings: state.data.rosterSettings[0],
  };
};

type OwnProps = {
  shiftId: string;
};

type State = {
  loadingUserId?: string;
  userFilter: string;
  requiredUsersAmount?: number;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _OpenShiftAssignmentPopup extends React.PureComponent<Props, State> {
  shift: IShift;
  constructor(props: Props) {
    super(props);
    this.shift = props.shift;
    this.state = {
      loadingUserId: undefined,
      userFilter: "",
    };
  }

  assignUserClicked = async (userId: string) => {
    const { shift } = this.props;
    this.setState({ loadingUserId: userId });
    setTimeout(async () => {
      await this.props.dispatch(
        shift.isRequirement
          ? cloneShift(shift.id, shift.date, userId, shift.jobPositionId, false)
          : assignOpenShiftToUser(shift.id, userId, shift.jobPositionId)
      );
      !shift && this.props.dispatch(closeModal());
      this.setState({ loadingUserId: undefined });
    }, 20);
  };

  isUserVisible = (user: IUserFull) =>
    user.branchIds.includes(this.props.shift.branchId) &&
    (!this.props.shift.jobPositionId || user.jobPositionIds.includes(this.props.shift.jobPositionId)) &&
    (!this.state.userFilter || user.name.toLowerCase().includes(this.state.userFilter.toLowerCase()));

  getAlreadyAssignedUsers = () => {
    const { shift } = this.props;
    return this.props.activeUsers
      .filter((u) => {
        return !!this.props.shifts.find(
          (s) =>
            u.id === s.userId &&
            shift.date === s.date &&
            shift.startTime === s.startTime &&
            shift.endTime === s.endTime &&
            shift.branchId === s.branchId
        );
      })
      .map((user) => user.id);
  };

  render() {
    const { shiftsByUser, contractsMap, rosterDateRange } = this.props;
    const { startTime, endTime, breakMinutes, breakStartTime } = this.shift;
    if (!this.props.shift) {
      return (
        <TZModal style={{ minWidth: 600 }} className="shiftAssignmentPopupMain">
          <ShiftPopupHead shift={this.shift} title={this.shift.isRequirement ? lg.bedarf : lg.offene_schicht} />
          <StartEndTimeInput
            onChange={() => {}}
            startTime={startTime as string}
            endTime={endTime as string}
            breakMinutes={breakMinutes}
            withBorderBottom
            withBreakStartTime={!!breakStartTime}
            breakStartTime={breakStartTime}
            disabled={true}
          />
          <div className="fb aCenter jCenter noApplicationsMsg" style={{ padding: 32 }}>
            {lg.alle_offenen_schichten_wurden_vergeben}
          </div>
        </TZModal>
      );
    }

    const alreadyAssignedUsers = this.getAlreadyAssignedUsers();

    return (
      <>
        <TZModal style={{ minWidth: 600 }} className="shiftAssignmentPopupMain">
          <ShiftPopupHead
            shift={this.props.shift}
            title={this.props.shift.isRequirement ? lg.bedarf : lg.offene_schicht}
          />
          <StartEndTimeInput
            onChange={() => {}}
            startTime={startTime as string}
            endTime={endTime as string}
            breakMinutes={breakMinutes}
            withBorderBottom
            withBreakStartTime={!!breakStartTime}
            breakStartTime={breakStartTime}
            disabled={true}
          />
          <div className="infoRowWrapper" key="infoRow">
            <OpenShiftInfoRow
              shift={this.props.shift}
              setUserFilter={(e) => this.setState({ userFilter: e.target.value })}
            />
          </div>
          <div className="content">
            {!!this.props.shift.requiredUsersAmount && [
              <div className="userList" key="userList">
                {_.orderBy(this.props.activeUsers, (u) => u.name)
                  .filter((user) => this.isUserVisible(user))
                  .map((user, i) => {
                    const absence = (this.props.absencesByUser[user.id] || []).find(
                      (a) => a.startDate <= this.props.shift.date && a.endDate >= this.props.shift.date
                    );

                    const allAvailabilities = filterAvailabilitiesForUser(
                      user.id,
                      this.props.availabilities,
                      this.props.shift.date
                    );

                    const unavailabilities = allAvailabilities.filter((a) => !a.isAvailable);
                    const availabilities = allAvailabilities.filter((a) => a.isAvailable);
                    const overlapShift = shiftsByUser[user.id]?.find((s) => doShiftsOverlap(this.shift, s));
                    const isUnavailableDuringShift = !!getUnavailableDuringShift(this.shift, unavailabilities);

                    const shouldNotPick = isUnavailableDuringShift || overlapShift || absence;

                    return (
                      <div className="fb row aCenter userItem" key={user.id}>
                        <span style={{ marginRight: "auto" }}>{user.name}</span>

                        {!absence && (
                          <div className="userCreditDetailsWrapper">
                            <UserCreditDetails
                              user={user}
                              startDate={this.props.rosterDateRange.rangeStart}
                              rosterMode={this.props.rosterMode}
                              creditSpan={selectCreditSpanForUser(this.props.state, user.id)} // yes, super dirty > workaround is a lot of work
                              contract={getValidContract(contractsMap[user.id], rosterDateRange.rangeStart)!}
                              features={this.props.features}
                              initialUserCredit={this.props.initialUserCreditsByUser[user.id]}
                              rosterDateRange={this.props.rosterDateRange}
                              isTemplateMode={this.props.isTemplateMode}
                              customMonthStartDay={this.props.rosterSettings.customMonthIntervalStart || 1}
                              showOvertime={true}
                            />
                          </div>
                        )}

                        <div className="availabilitiesIndicatorWrapper">
                          {!!availabilities.length && (
                            <div
                              className="availabilitiesIndicator"
                              style={{ backgroundColor: "#78e29e" }}
                              data-rh={generateAvailabilityText(availabilities)}
                            />
                          )}
                          {!!unavailabilities.length && (
                            <div
                              className="availabilitiesIndicator"
                              style={{ backgroundColor: "#ff847e" }}
                              data-rh={generateAvailabilityText(unavailabilities)}
                            />
                          )}
                          {overlapShift && !isUnavailableDuringShift && (
                            <div
                              className="availabilitiesIndicator"
                              style={{ backgroundColor: "#ff847e" }}
                              data-rh={`${lg.bereits_eingeplant}: ${overlapShift.startTime} - ${overlapShift.endTime}`}
                            />
                          )}
                        </div>
                        {absence && (
                          <div style={{ marginRight: 8 }}>
                            <AbsenceLabel absence={absence} />
                          </div>
                        )}
                        <Button
                          id="open-shift-assigne"
                          type={shouldNotPick ? "ghost" : "primary"}
                          disabled={!this.props.shift.requiredUsersAmount || alreadyAssignedUsers.includes(user.id)}
                          loading={this.state.loadingUserId === user.id}
                          onClick={() => this.assignUserClicked(user.id)}
                          size="small"
                        >
                          {this.state.loadingUserId === user.id ? "" : lg.zuweisen}
                        </Button>
                      </div>
                    );
                  })}
              </div>,
            ]}
          </div>
          {!!this.props.shift.requiredUsersAmount && (
            <TZModal.Footer style={{ justifyContent: "flex-start" }}>
              <Button
                id="open-shift-assignment-goback"
                type="default"
                icon="arrow-left"
                children={lg.zurück}
                onClick={() => {
                  this.props.dispatch(closeModal());
                  this.props.dispatch(openModal(ShiftPopup, { shift: this.props.shift as IShift }));
                }}
              />
            </TZModal.Footer>
          )}
        </TZModal>
      </>
    );
  }
}

export const OpenShiftAssignmentPopup = connect(mapStateToProps)(_OpenShiftAssignmentPopup);
