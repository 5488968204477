import React, { useCallback, useEffect } from "react";
import { useSelector } from "../../../../helpers/redux";
import "./styles.scss";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import moment from "moment";
import { selectUsersWithSharedBranch } from "../../../../selectors/UsersWithSharedBranchSelector";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { RoleType } from "../../../../shared/entities/IUser";
import { selectUserFullMap } from "../../../../selectors/userFullMapSelector";
import {
  selectBranchMap,
  selectJobPositionMap,
  selectShiftAddressMap,
  selectWorkSpaceMap,
} from "../../../../selectors/mapSelectors";
import { Icon } from "antd";
import cn from "classnames";
import { getDuration, toMoment, toSimpleDate } from "../../../../shared/helpers/timeHelpers";
import { DeviceType, ITimeClocking } from "../../../../shared/entities/ITimeClocking";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../actions/modal";
import { ShiftPopup } from "../../../../components/ShiftPopup/ShiftPopup/ShiftPopup";
import { selectShiftMap } from "../../../../selectors/shiftMapSelector";
import _ from "lodash";
import { AvIcon } from "../../../../components/AvIcon/AvIcon";
import clockedInGifSrc from "./../../../../frontend-core/assets/clockedinsignal.gif";
import { localizeFormat } from "../../../../helpers/dateFormatHelper";
import { IShiftAddress } from "../../../../shared/entities/IShiftAddress";
import { selectIsMultiJobPos } from "../../../../selectors/isMultiJobPos";
import { v4 as uuid } from "uuid";
import { EnterClockingButtonV2 } from "./EnterClockingButtonV2/EnterClockingButtonV2";

type Props = {
  selectedDay: string;
};

export const RosterListV2 = (props: Props) => {
  const sessionInfo = useSelector(selectSessionInfo);
  const sessionUser = sessionInfo.user;
  const branchId = useSelector((s) => s.ui.selectedBranch);
  const branches = useSelector((s) => s.data.branches);
  const usersWithSharedBranch = useSelector(selectUsersWithSharedBranch);
  const selectedBranchId = useSelector((s) => s.ui.selectedBranch);
  const isAdmin = sessionUser.role === RoleType.admin;
  const isManager = sessionUser.role === RoleType.manager;
  const isEmployee = sessionUser.role === RoleType.employee;
  const userFullMap = useSelector(selectUserFullMap);
  const jobPosMap = useSelector(selectJobPositionMap);
  const branchMap = useSelector(selectBranchMap);
  const workSpaceMap = useSelector(selectWorkSpaceMap);
  const addressMap = useSelector(selectShiftAddressMap);
  const dispatch = useDispatch();
  const shiftMap = useSelector(selectShiftMap);
  const isMultiJobPos = useSelector(selectIsMultiJobPos);
  const onlyShowActiveClockings = useSelector((s) => s.ui.shifts.roster.onlyShowActiveClockings);

  const selectedDay = useSelector((state) => state.ui.shifts.roster.selectedDay);
  const selectedMonthStart = useSelector((state) => state.ui.shifts.roster.selectedMonth);
  const daysInMonth = toMoment(selectedMonthStart).daysInMonth();
  const selectedMonthEnd = toSimpleDate(toMoment(selectedMonthStart).add(daysInMonth - 1, "days"));

  const clockings = useSelector((s) => s.data.timeClockings);
  const isEmployeeMode = isEmployee; // to employees we show the own clockins of a month > to admins&manager we show clockins of one day of the team

  const visibleClockings = clockings.filter(
    (c) =>
      (!onlyShowActiveClockings || (!c.endTime && !c.isAutoClockOut)) &&
      (isEmployee ? c.date >= selectedMonthStart && c.date <= selectedMonthEnd : c.date === selectedDay) &&
      (isEmployee || !selectedBranchId || c.branchId === selectedBranchId) &&
      (isEmployee
        ? c.userId === sessionUser.id
        : isAdmin || (isManager && usersWithSharedBranch.includes(c.userId)) || c.userId === sessionUser.id)
  );

  const visibleClockingsSorted = _.orderBy(visibleClockings, ["date", "startTime", "userId", "id"], ["desc", "desc"]);

  const isMultiBanch = branches.filter((b) => !b.isInactive).length > 1;

  const getDurationDisplay = (c: ITimeClocking) => {
    const _minutes = c.endTime ? getDuration(c as any) : 0;
    const hours = Math.floor(_minutes / 60);
    const minutes = _minutes % 60;
    return !c.endTime ? (
      ""
    ) : (
      <div className="duration">
        <b>{hours}</b> {lg.std} <b>{minutes}</b> {lg.min}
      </div>
    );
  };

  const getClockedIcon = () => (
    <div className="timerIconWrapper clockeIconWrapper" data-rh={lg.per_stempeluhr_erfasst}>
      <AvIcon type="icon-timer-f" />
    </div>
  );

  const getManualIcon = () => (
    <div className="timerIconWrapper manuallyEditedIconWrapper" data-rh={lg.manuell_eingetragene_zeit}>
      <Icon type="form" />
    </div>
  );

  const getAutoClockoutIcon = () => (
    <div
      className="timerIconWrapper autoClockedOut"
      data-rh={lg.automatisch_ausgestempelt_da_der_mitarbeiter_es_vergessen_hat}
    >
      <Icon type="export" />
    </div>
  );

  const renderClockedInIcon = useCallback(() => {
    return <img src={clockedInGifSrc} style={{ width: 20, height: 20 }} data-rh={lg.ist_eingestempelt} />;
  }, []);

  const getAddressName = (addressId: string) => {
    return addressMap[addressId].name || addressMap[addressId].address;
  };

  // for (let i = 0; i < 50; i++) {
  //   visibleClockingsSorted.push({
  //     branchId: "b1",
  //     breakMinutes: 0,
  //     date: "2023-09-18",
  //     id: uuid(),
  //     jobPositionId: "p2",
  //     startTime: "19:00",
  //     isAccepted: false,
  //     userId: "u155",
  //     logS: "M_u1_1695042716",
  //   });
  // }

  return (
    <div className="RosterListV2Main">
      {/*  */}

      {visibleClockingsSorted.map((c) => {
        const manualKey = DeviceType.Manual;
        const isAutoClockOut = !!c.isAutoClockOut;
        const isManually = c.logS?.includes(manualKey) || c.logE?.includes(manualKey) || c.logB?.includes(manualKey);
        const isActiveClocking = !c.endTime && !c.isAutoClockOut;

        return (
          <div className="item" onClick={() => dispatch(openModal(ShiftPopup, { shift: shiftMap[c.id] }))}>
            <div className="leftPart">
              {<div className={"clockedInBadge " + cn({ isClockedIn: isActiveClocking })}></div>}
              {!isEmployeeMode && (
                <div className={"cell name " + cn({ isGreen: isActiveClocking })}>{userFullMap[c.userId].name}</div>
              )}
              {isEmployeeMode && (
                <div className="cell date">{moment(c.date, SDateFormat).format(localizeFormat("dd DD.MM.YY"))}</div>
              )}
              <div className="cell time">
                {c.startTime + (c.endTime ? ` - ${c.endTime}` : `${c.isAutoClockOut ? " - ?" : " ->"}`)}
              </div>
              <div className={"cell breake " + cn({ grey: !c.breakMinutes })}>
                <Icon type="coffee" />
                <div className="label">{c.breakMinutes + " min"}</div>
              </div>
              <div
                className={cn({ cell: true, iconWrapper: true, isAccepted: c.isAccepted, isActiveClocking })}
                data-rh={c.isAccepted ? lg.bestätigt : lg.unbestätigt}
              >
                {isActiveClocking ? renderClockedInIcon() : <Icon type={c.isAccepted ? "check" : "hourglass"} />}
              </div>
              <div className={"cell duration " + cn({ noBorder: false })} data-rh={lg.dauer}>
                {getDurationDisplay(c)}
              </div>

              {isMultiBanch && <div className="cell branch">{branchMap[c.branchId].name}</div>}
              {isMultiJobPos && <div className="cell jobPos">{jobPosMap[c.jobPositionId].name}</div>}
              {c.workSpaceId && <div className="cell label">{workSpaceMap[c.workSpaceId].name}</div>}
              {c.addressId && <div className="cell address">{getAddressName(c.addressId)}</div>}
              {c.comment && <div className="cell comment">{c.comment}</div>}
            </div>
            <div className="rightPart">
              {isAutoClockOut ? getAutoClockoutIcon() : isManually ? getManualIcon() : getClockedIcon()}
            </div>
          </div>
        );
      })}

      {!visibleClockingsSorted.length && <div className="noTrackingsBox">Es sind keine Zeiterfassungen vorhanden.</div>}
      <EnterClockingButtonV2 />
    </div>
  );
};
