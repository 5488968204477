import React from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { Button, notification } from "antd";

import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { isWorkingTimeInvalid } from "../../../../helpers/workingTimeHelpers";
import moment from "moment";
import { BusyInjectorProps, busyInjector } from "../../../BusyInjector/BusyInjector";
import StartEndTimeInput from "../../ShiftPopup/StartEndTimeInput/StartEndTimeInput";

import { changeRequestRepository } from "../../../../repositories/ChangeRequestRepository";
import "./styles.scss";

import { createChangeRequest } from "../../../../actions/changeRequest";
import { IShift } from "../../../../shared/entities/IShift";
import { IChangeRequest } from "../../../../shared/entities/IChangeRequest";

export const getInitialChangeRequest = (shift: IShift): IChangeRequest => ({
  id: shift.id,
  startTime: shift.startTime,
  endTime: shift.endTime,
  breakMinutes: shift.breakMinutes,
  createdAt: moment().toISOString(),
  userId: shift.userId!,
  branchId: shift.branchId,
  date: shift.date,
  isFreeRequest: true,
});

const mapStateToProps = (state: AppState) => ({
  users: state.data.users,
});

type OwnProps = {
  shift: IShift;
  // changeRequest?: IChangeRequest;
  cancelEditing: () => void;
};

type State = {
  startTime?: string;
  endTime?: string;
  breakMinutes?: number;
  isFreeRequest: boolean;
  step: "pickType" | "enterTime";
};

type StoreProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class _ChangeRequestCreateRow extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      startTime: props.shift.startTime, //changeRequest && (changeRequest.startTime as string),
      endTime: props.shift.endTime, //changeRequest && (changeRequest.endTime as string),
      breakMinutes: props.shift.breakMinutes,
      isFreeRequest: true,
      step: "pickType",
    };
  }

  // deleteClicked = async () => {
  //   this.props.load(this.props.dispatch(changeRequestRepository.remove(this.props.changeRequest!.id)), "delete");
  // };

  requestClicked = async () => {
    this.setState({ step: "pickType" });
  };

  cancelClicked = () => {
    this.props.cancelEditing();
  };

  onInputChange = (value: string | number, name: "startTime" | "endTime" | "breakMinutes" | "breakStartTime") => {
    this.setState({
      [name]: value,
    } as any);
  };

  saveChangeRequest = async (asFree: boolean) => {
    await this.props.load(
      this.props.dispatch(
        createChangeRequest(
          this.props.shift,
          asFree,
          this.state.startTime!,
          this.state.endTime!,
          this.state.breakMinutes || 0
        )
      ),
      asFree ? "freeRequest" : "timeChange"
    );

    this.props.cancelEditing();
    notification.success({
      message: asFree ? lg.dienstfrei_beantragt : lg.änderung_beantragt,
    });
  };

  requestTimeChangeClicked = () => {
    const { startTime, endTime, breakMinutes } = this.props.shift;
    this.setState({
      step: "enterTime",
      startTime: startTime as string,
      endTime: endTime as string,
      breakMinutes,
    });
  };

  render() {
    const changeRequest = this.state;
    const timeInputInvalid =
      changeRequest && isWorkingTimeInvalid(changeRequest.startTime, changeRequest.endTime, changeRequest.breakMinutes);

    return (
      <div className="changeRequestCreateRowMain">
        <label className="shiftPopupLabelMain chageRequestLabel">{lg.änderung}</label>
        <div className="content fb row aEnd grow">
          {this.state.step === "pickType" && (
            <div className="optionButtonsWrapper">
              <Button
                id="change-request-free-option"
                children={lg.dienstfrei_beantragen}
                type="primary"
                onClick={() => this.saveChangeRequest(true)}
                loading={this.props.isLoading("freeRequest")}
              />
              <Button
                id="change-request-shfittime-option"
                children={lg.schichtzeit_änderung}
                type="primary"
                onClick={this.requestTimeChangeClicked}
              />
            </div>
          )}
          {this.state.step === "enterTime" && (
            <div className="row fb aCenter grow">
              <StartEndTimeInput
                onChange={this.onInputChange}
                startTime={this.state.startTime as string}
                endTime={this.state.endTime as string}
                breakMinutes={this.state.breakMinutes}
                noLabel
              />
              <div className="actionButtonsWrapper">
                <Button
                  id="change-request-request"
                  type="primary"
                  style={{ marginLeft: 10 }}
                  disabled={timeInputInvalid}
                  children={this.props.isLoading("timeChange") ? "" : lg.beantragen}
                  loading={this.props.isLoading("timeChange")}
                  onClick={() => this.saveChangeRequest(false)}
                />
                <Button
                  id="change-request-close"
                  icon="close"
                  style={{ marginLeft: 10 }}
                  onClick={this.props.cancelEditing}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export const ChangeRequestCreateRow = connect(mapStateToProps)(busyInjector(_ChangeRequestCreateRow));
