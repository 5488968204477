import React from "react";
import "./styles.scss";
import { Select } from "antd";
import { dummyColors } from "../../constants/styling";

type Props = {
  value: string | undefined;
  onChange: (color: string) => void;
  colors?: string[];
  miniVersion?: string | boolean;
};

export default class AvColorPicker extends React.PureComponent<Props, {}> {
  render() {
    const colors = this.props.colors || dummyColors;
    const { miniVersion } = this.props;
    return (
      <div className="colorPickerMain" style={{ width: miniVersion ? 70 : "auto" }}>
        <Select
          id="color-picker"
          value={this.props.value}
          onChange={this.props.onChange}
          style={{ width: "100%" }}
          placeholder={lg.farbe}
        >
          {colors.map((dC) => (
            <Select.Option value={dC} key={dC} label={dC}>
              <div className="row fb aCenter colorOptionMain">
                <div className="colorBall" style={{ background: dC }} />
                {miniVersion ? "" : dC}
              </div>
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  }
}
