import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../types/AppState";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { Popover, Button, Badge } from "antd";
import { openModal } from "../../../../../actions/modal";

import "./styles.scss";
import { TrackingRequestItem } from "../../../../../components/TrackingRequestItem/TrackingRequestItem";
import { withErrorBoundary } from "../../../../../components/ErrorBoundary/ErrorBoundary";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { busyInjector, BusyInjectorProps } from "../../../../../components/BusyInjector/BusyInjector";
import _ from "lodash";
import { selectTrackingRequests } from "../../../../../selectors/TrackingRequestSelector";
import { ITracking } from "../../../../../shared/entities/ITracking";
import { ShiftPopup } from "../../../../../components/ShiftPopup/ShiftPopup/ShiftPopup";
import { selectShiftMap } from "../../../../../selectors/shiftMapSelector";
import { selectClockingRequestsV2 } from "../../../../../selectors/clockingRequestSelectorV2";
import { ClockingRequestItemV2 } from "../../../../../components/ClockingRequestItemV2/ClockingRequestItemV2";
import { ITimeClocking } from "../../../../../shared/entities/ITimeClocking";
import { UnapprovedClockingsModal } from "../../components/UnapprovedClockingsModal/UnapprovedClockingsModal";

const mapStateToProps = (state: AppState) => {
  return {
    clockingRequests: selectClockingRequestsV2(state),
  };
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class UnapprovedClockingsButtonV2_ extends React.PureComponent<Props> {
  render() {
    const { clockingRequests, dispatch } = this.props;

    return (
      <Badge count={clockingRequests.length}>
        <Button
          id="unapproved-trackings-button"
          icon="history"
          style={{ marginLeft: 12 }}
          disabled={!clockingRequests.length}
          data-rh={lg.unbestätigte_zeiterfassungen}
          onClick={() => {
            clockingRequests.length && dispatch(openModal(UnapprovedClockingsModal, {}));
          }}
        />
      </Badge>
    );
  }
}

export const UnapprovedClockingsButtonV2 = withErrorBoundary(
  connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(busyInjector(UnapprovedClockingsButtonV2_))
);
