import jsPDF from "jspdf";
import autoTable, { CellDef } from "jspdf-autotable";
import { DispFn } from "../frontend-core/types/thunkTypes";
import { AbsenceRow } from "../pages/absences/helprs";
import { simpleDateToMoment } from "../shared/helpers/timeHelpers";
import { AppState } from "../types/AppState";
import { Column } from "react-table-v6";

export const exportAbsenceTablePdf = (
  absenceListData: Array<any>,
  _columns: Array<Column>,
  startDate: string,
  endDate: string
) => {
  return (dispatch: DispFn, getState: () => AppState) => {
    const isV2 = getState().data.tenantInfo.isV2;

    const columns = _columns.map((c) => ({
      label: c.Header,
      render: (r: AbsenceRow) => r[c.accessor],
    }));

    const headCells: CellDef[] = columns.map((c) => {
      return {
        content: c.label,
        styles: {
          fillColor: "#555555",
          overflow: "ellipsize",
          fontSize: 9,
        },
      };
    });
    const mainTableRows: CellDef[][] = absenceListData.map((row) => {
      return columns.map((c) => ({
        content: c.render(row as any),
        styles: {
          // fillColor: "#000000",
          overflow: "ellipsize",
          fontSize: 9,
        },
        rowData: row,
      }));
    });

    const doc = new jsPDF("l");
    doc.setFontSize(14);
    const prettyStartDate = simpleDateToMoment(startDate).format("L");
    const prettyEndDateDate = simpleDateToMoment(endDate).format("L");

    const title = isV2
      ? lg.zeitguru_abwesenheiten_export(prettyStartDate, prettyEndDateDate)
      : lg.aplano_abwesenheiten_export(prettyStartDate, prettyEndDateDate);

    doc.text(title, 10, 12);
    const totalPagesExp = "{total_pages_count_string}";
    autoTable(doc, {
      // theme: "",
      head: [headCells],
      body: mainTableRows,
      startY: 18,
      margin: 8,
      // startX: 15,
      didDrawPage: (data) => {
        // Footer
        let str = lg.seite_count((doc.internal as any).getNumberOfPages());
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === "function") {
          str = str + " " + lg.von_count + " " + totalPagesExp;
        }
        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 5);
      },
    });
    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }

    const pdfName = isV2 ? lg.Zeitguru_Abwesenheiten_Export_PDF : lg.Aplano_Abwesenheiten_Export_PDF;
    doc.save(pdfName);
  };
};
