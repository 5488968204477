export const dummyColors = [
  "#f5222d",
  "#fa541c",
  "#fa8c16",
  "#faad14",
  "#fabe14",
  "#a0d911",
  "#52c41a",
  "#13c2c2",
  "#2ddcab",
  "#1cb6f1",
  "#1890ff",
  "#2f54eb",
  "#06649a",
  "#2a3f8c",
  "#722ed1",
  "#8667ff",
  "#eb2f96",
  "#f616fa",
  "#c34c13",
];
