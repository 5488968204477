import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../types/AppState";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { Popover, Button, Badge, notification } from "antd";
import { openModal } from "../../../../../actions/modal";
import "./styles.scss";
import { TrackingRequestItem } from "../../../../../components/TrackingRequestItem/TrackingRequestItem";
import { withErrorBoundary } from "../../../../../components/ErrorBoundary/ErrorBoundary";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { busyInjector, BusyInjectorProps } from "../../../../../components/BusyInjector/BusyInjector";
import _ from "lodash";
import { selectTrackingRequests } from "../../../../../selectors/TrackingRequestSelector";
import { ITracking } from "../../../../../shared/entities/ITracking";
import { ShiftPopup } from "../../../../../components/ShiftPopup/ShiftPopup/ShiftPopup";
import { selectShiftMap } from "../../../../../selectors/shiftMapSelector";
import { ITimeClocking } from "../../../../../shared/entities/ITimeClocking";
import { sendPost } from "../../../../../frontend-core/actions/send";
import { SDateFormat, STimeFormat } from "../../../../../shared/helpers/SimpleTime";
import moment from "moment";
import { startDynamicTimeClocking, stopDynamicTimeClocking } from "../../../../../actions/timeClocking";
import cn from "classnames";

const mapStateToProps = (state: AppState) => {
  return {
    user: selectSessionInfo(state).user,
  };
};

type State = {
  clocking?: ITimeClocking;
  isLoading?: boolean;
};
type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class _DevClockingBox extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { dispatch, user } = this.props;

    return (
      <div className="devClockingBoxMain">
        <div
          className="testBtn"
          onClick={async () => {
            const res = await dispatch(sendPost("/api/handle-unclocked-shifts", {}));
            notification.success({ message: "handled unclocked shifts" });
          }}
        >
          CRON
        </div>

        <div
          className={cn({ testBtn: true, isDisabled: !!this.state.clocking })}
          onClick={async () => {
            const clocking = await dispatch(
              startDynamicTimeClocking({
                userId: user.id,
                branchId: user.branchIds[0],
                jobPositionId: user.jobPositionIds[0],
                startTime: moment().add(-2, "h").format(STimeFormat), // ClockIN time is two hours ago
              })
            );
            this.setState({ clocking });
            notification.success({ message: "clocked IN" });
          }}
        >
          IN
        </div>
        <div
          className={cn({ testBtn: true, isDisabled: !this.state.clocking })}
          onClick={async () => {
            await dispatch(stopDynamicTimeClocking(this.state.clocking!));
            this.setState({ clocking: undefined });
            notification.success({ message: "clocked OUT" });
          }}
        >
          OUT
        </div>
        <div
          className={cn({ testBtn: true })}
          onClick={async () => {
            const res = await dispatch(sendPost("/api/auto-clockout", {}));
            notification.success({ message: JSON.stringify(res as any) });
          }}
        >
          AUTO CLOCKOUT
        </div>
      </div>
    );
  }
}

export const DevClockingBox = withErrorBoundary(
  connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(busyInjector(_DevClockingBox))
);
