import { createSelector } from "reselect";
import { IUserFull } from "../../../../../shared/entities/IUser";
import { getUserName } from "../../../../../shared/helpers/userHelpers";
import { AppState } from "../../../../../types/AppState";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { selectUsersWithSharedBranch } from "../../../../../selectors/UsersWithSharedBranchSelector";
import { selectVisibleAbsencesInMonth } from "./visibleAbsencesInMonthSelector";
import { getActiveUsersAtDate } from "../../../../../selectors/ActiveUserSelectors";
import moment from "moment";
import _ from "lodash";
import { SDateFormat } from "../../../../../shared/helpers/SimpleTime";
import { selectVisibleAbsencesInYear } from "./visibleAbsencesInYearSelector";
import { selectAbsenceTypeMap } from "../../../../../selectors/absenceTypeMapSelector";
import { isAbsenceVisible } from "../../../../../selectors/absencesByUserSelector";

export const selectAbsenceCalendarVisibleUsers = createSelector(
  [
    (s: AppState) => s.data.users,
    (s: AppState) => s.ui.filters.absenceCalendar,
    selectSessionInfo,
    (s) => s.ui.absences,
    selectUsersWithSharedBranch,
    selectVisibleAbsencesInMonth,
    selectVisibleAbsencesInYear,
    selectAbsenceTypeMap,
  ],
  (
    users,
    filters,
    sessionInfo,
    absencesUi,
    usersWithSharedBranch,
    visibleAbsencesInMonth,
    visibleAbsencesInYear,
    absenceTypeMap
  ) => {
    const getFilteredUsers = (): IUserFull[] => {
      const { positionFilter, branchFilter, searchInput } = filters;
      const { selectedMonth, selectedYear } = absencesUi;
      const selectedMonthStartDate = moment().year(selectedYear).month(selectedMonth).date(1).format(SDateFormat);
      const activeUsers = getActiveUsersAtDate(users, selectedMonthStartDate);
      const inputLow = searchInput ? searchInput.toLowerCase() : "";

      const _users = activeUsers
        .map((u) => ({ ...u, name: getUserName(u), email: "---" } as IUserFull))
        .filter((u) => !searchInput || u.name.toLowerCase().includes(inputLow))
        .filter((u) => !branchFilter || u.branchIds.includes(branchFilter))
        .filter((u) => !positionFilter || u.jobPositionIds.includes(positionFilter));

      return _.sortBy(_users, (u) => u.name.toLocaleLowerCase());
    };

    const _absences = absencesUi.isYearView ? visibleAbsencesInYear : visibleAbsencesInMonth;
    const visibleAbsences = _absences.filter((a) => isAbsenceVisible(a, absenceTypeMap[a.typeId], sessionInfo.user));

    // if there exists an absenceType that is visible to colleagues
    const absencesOfColleaguesAreVisible = Object.values(absenceTypeMap).some((at) => at.isVisibleToColleagues);

    const usersWithAbsencesInMonth = users
      .filter((u) => !!visibleAbsences.find((a) => a.userId === u.id))
      .map((u) => u.id);

    const sessionUser = sessionInfo.user;
    const visibleUsers = getFilteredUsers().filter(
      (u) =>
        (sessionInfo.hasManagerPermissions() || sessionUser.id === u.id || absencesOfColleaguesAreVisible) &&
        (!absencesUi.emptyAbsenceRowsHidden || !!usersWithAbsencesInMonth.includes(u.id)) &&
        (sessionInfo.isAdmin() || usersWithSharedBranch.includes(u.id)) // managers + employees see only users with intersecting branchIds
    );

    return visibleUsers;
  }
);
