export const saveDataAs = (data: any, filename: string, options?: { mime: string }) => {
  const mime = options?.mime || "text/csv; charset=utf-18";
  const blob = data.constructor !== Blob ? new Blob(["\uFEFF" + data], { type: mime }) : data;

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
    return;
  }

  var a = document.createElement("a"),
    url = window.URL,
    objectURL;

  if (mime) {
    a.type = mime;
  }

  a.download = filename;
  a.href = objectURL = url.createObjectURL(blob);
  a.dispatchEvent(new MouseEvent("click"));
  setTimeout(url.revokeObjectURL.bind(url, objectURL));
};
