import React from "react";
import { IAbsence } from "../../../../shared/entities/IAbsence";
import { IAvailability } from "../../../../shared/entities/IAvailability";
import { IUser, IUserFull } from "../../../../shared/entities/IUser";
import { filterAvailabilitiesForUser, generateAvailabilityText } from "../../../../helpers/workingTimeHelpers";
import { AbsenceLabel } from "../../../AbsenceLabel/Absencelabel";
import { ICreditCorrection, CreditCorrectionType } from "../../../../shared/entities/ICreditCorrection";
import { ICreditSpan } from "../../../../selectors/creditSpansSelector";
import { getValidContract } from "../../../../shared/helpers/credit";
import { IContract, WorkInterval } from "../../../../shared/entities/IContract";
import { minutesToDuration } from "../../../../shared/helpers/timeHelpers";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import moment from "moment";
import cn from "classnames";
import { getUserCreditsDisplayData } from "../../../../helpers/creditsDisplayData";
import { IShiftOverlap } from "../../../../shared/entities/IShiftOverlap";
import { generateOverlappingTooltip } from "../../../../helpers/general";
import { IBranch } from "../../../../shared/entities/IBranch";
import { Icon } from "antd";

type Props = {
  date: string;
  availabilities: IAvailability[];
  user: IUserFull;
  hideHourAccount: boolean;
  creditCorrections: ICreditCorrection[];
  userCredits: ICreditSpan;
  contracts: IContract[];
  startOfWeekDate: string;
  endOfWeekDate: string;
  onClick: () => any;
  overlap?: IShiftOverlap;
  branches: IBranch[];
  absencesByUser: { [userId: string]: IAbsence[] };
  isSelected: boolean;
};

export const UserRow = (props: Props) => {
  const {
    isSelected,
    startOfWeekDate,
    contracts,
    user,
    date,
    absencesByUser,
    availabilities,
    overlap,
    userCredits,
    onClick,
  } = props;

  const hasMonthlyContract = getValidContract(contracts, startOfWeekDate)?.interval === WorkInterval.monthly;

  const absence = (absencesByUser[user.id] || []).find((a) => a.startDate <= date && a.endDate >= date);

  const allAvailabilities = filterAvailabilitiesForUser(user.id, availabilities, date);

  const userUnavailabilities = allAvailabilities.filter((a) => !a.isAvailable);
  const userAvailabilities = allAvailabilities.filter((a) => a.isAvailable);

  const isUnavailable = !!userUnavailabilities.length;

  const cdd = getUserCreditsDisplayData(userCredits);
  const monthNameFull = moment(startOfWeekDate, SDateFormat).format("MMMM");
  const monthName = monthNameFull.substr(0, 3).toUpperCase();
  const isoWeek = moment(startOfWeekDate, SDateFormat).isoWeek();

  const isUnusable = isUnavailable || absence || overlap;

  return (
    <div className={cn({ userItem: true, isUnusable, isSelected })} onClick={onClick}>
      <div className="selectedBox">
        <Icon type="check" />
      </div>
      <div className="content">
        <span className="name" style={{ marginRight: "auto" }}>
          {user.name}
        </span>
        {!!allAvailabilities.length && !absence && (
          <div className="availabilitiesIndicatorWrapper">
            {!!userAvailabilities.length && (
              <div
                className="availabilitiesIndicator"
                style={{ backgroundColor: "#78e29e" }}
                data-rh-at="bottom"
                data-rh={generateAvailabilityText(userAvailabilities)}
              />
            )}
            {!!userUnavailabilities.length && (
              <div
                className="availabilitiesIndicator"
                style={{ backgroundColor: "#ff847e" }}
                data-rh-at="bottom"
                data-rh={generateAvailabilityText(userUnavailabilities)}
              />
            )}
          </div>
        )}
        {!absence && overlap && (
          <div
            className="availabilitiesIndicator"
            style={{ backgroundColor: "#18c756" }}
            data-rh={generateOverlappingTooltip(overlap!)}
            data-rh-at="bottom"
          >
            {/* <Icon type="stop" /> */}
          </div>
        )}
        {absence && (
          <div style={{ marginRight: 8 }}>
            <AbsenceLabel absence={absence} />
          </div>
        )}
        <div className="fb row balances">
          <div className="balanceDetails">
            <div
              className={cn({
                creditAndQuota: true,
                hasMonthlyContract,
              })}
              data-rh-at="bottom"
              data-rh={`${lg.istzeit} / ${lg.sollzeit} ` + (hasMonthlyContract ? `[${monthName}]` : `[KW ${isoWeek}]`)}
            >
              {hasMonthlyContract && <div className="monthName">{monthName}</div>}
              {`${cdd.creditTime} / ${cdd.quotaTimeOnlyHours} h`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
