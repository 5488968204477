import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import _ from "lodash";
import { BasicSelect } from "../../../components/BasicSelect/BasicSelect";
import { Button, Tooltip, message, Checkbox, Modal } from "antd";
import { holidayRepository } from "../../../repositories/holidaysRepository";
import { closeModal, openModal } from "../../../actions/modal";
import { HolidayPopup } from "../../../components/HolidayPopup/HolidayPopup";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { HolidayImportModal } from "./holidayImportModal/HolidayImportModal";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { IHoliday } from "../../../shared/entities/IHoliday";
import { SDateToDisplayDate, getSimpleDateYear, simpleDateToMoment } from "../../../shared/helpers/timeHelpers";
import { rosterSettingsRepository } from "../../../repositories/rosterSettingsRepository";
import { selectActiveBranches } from "../../../selectors/ActiveBranchesSelector";
import { openCrisp } from "../../../helpers/crisp";
import "./styles.scss";

const mapStateToProps = (state: AppState) => {
  return {
    holidays: state.data.holidays,
    sessionInfo: selectSessionInfo(state),
    branches: selectActiveBranches(state),
    rosterSettings: state.data.rosterSettings[0],
  };
};

type State = {
  selectedYear: number;
  selectedBranchId?: string;
};
type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _Holidays extends React.PureComponent<Props, State> {
  currentYear = new Date().getFullYear();
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedYear: this.currentYear,
      selectedBranchId: undefined,
    };
  }

  renderHolidayRow = (holiday: IHoliday) => (
    <div className="element" key={holiday.id}>
      <div className="name">{holiday.name}</div>
      <div className="fb row">
        <div className="date">{SDateToDisplayDate(holiday.date)}</div>
        <Tooltip placement="right" title={lg.feiertag_löschen}>
          <Button
            id="remove-holiday"
            icon="minus"
            type="danger"
            size="small"
            shape="circle"
            onClick={() => this.props.dispatch(holidayRepository.remove(holiday.id!))}
          />
        </Tooltip>
      </div>
    </div>
  );

  deleteLegalHolidaysClicked = async () => {
    const { selectedBranchId } = this.state;
    const holidaysIdsToDelete = this.props.holidays
      .filter(
        (h) =>
          h.isLegalHoliday &&
          getSimpleDateYear(h.date) === this.state.selectedYear &&
          (!selectedBranchId || h.branchId === selectedBranchId)
      )
      .map((h) => h.id);

    await this.props.dispatch(holidayRepository.removeList(holidaysIdsToDelete));
    message.warning(lg.gesetzliche_feiertage_aus_dem_jahr_year_gelöscht(this.state.selectedYear));
  };

  importHolidaysClicked = () => {
    this.props.dispatch(
      openModal(HolidayImportModal, {
        year: this.state.selectedYear,
        branchId: this.state.selectedBranchId,
      })
    );
  };

  toggleBranchSpecificHolidays = () => {
    Modal.confirm({
      title: lg.standort_spezifische_feiertage,
      content: (
        <div className="branchSpecificHolidaysConfirm">
          <div className="text">
            {lg.um_diese_funktion_zu_aktivieren_kontaktieren_sie_bitte_unseren_online_support_per_chat}
          </div>
        </div>
      ),
      // onOk: () => this.props.load(this.saveEditsRepeatingly(), "editRepeate"),
      okText: lg.support_chat_öffnen,
      onOk: () => openCrisp(),
      cancelText: lg.abbrechen,
    });
  };

  getBranchOptions = () => [
    // we explicitly add a null value here to let the user consciously select "Standortübergreifend"
    {
      value: null,
      label: lg.standortübergreifend,
    },
    ...this.props.branches.map((b) => ({
      value: b.id,
      label: b.name,
    })),
  ];

  render() {
    const { rosterSettings, dispatch } = this.props;
    const { selectedBranchId } = this.state;
    const holidaysOfSelectedYear = _.sortBy(this.props.holidays, (h) => h.date).filter(
      (h) =>
        simpleDateToMoment(h.date).year() === this.state.selectedYear &&
        ((!h.branchId && !selectedBranchId) || h.branchId === selectedBranchId)
    );

    const legalHolidays = holidaysOfSelectedYear.filter((h) => h.isLegalHoliday);
    const customHolidays = holidaysOfSelectedYear.filter((h) => !h.isLegalHoliday);

    return (
      <div className="fb column grow shrink holidaySettingsMain">
        <div className="fb jBetween  col">
          <h2 className="grow">{lg.feiertage}</h2>
          <div className="fb noGrow settingsRow">
            <BasicSelect
              id="holiday-year-select"
              style={{ marginRight: 6, width: 200 }}
              value={this.state.selectedYear}
              options={_.range(this.currentYear - 3, this.currentYear + 10).map((n) => ({
                value: n,
                label: lg.jahr + " " + String(n),
              }))}
              onChange={(year: number) => this.setState({ selectedYear: year })}
            />
            {!!this.props.branches.length && this.props.rosterSettings.canSetBranchSpecificHolidays && (
              <BasicSelect
                placeholder={lg.standortübergreifend}
                style={{ marginRight: 12, width: 200 }}
                options={this.getBranchOptions()}
                defaultValue={null}
                value={this.state.selectedBranchId}
                mode="default"
                onChange={(branchId: string | null) => this.setState({ selectedBranchId: branchId || undefined })}
              />
            )}
            {this.props.branches.length > 1 && (
              <div className="checkBoxWrapper">
                <div className="label">{lg.feiertage_gelten_für_alle_standorte}</div>
                <Checkbox
                  checked={!rosterSettings.canSetBranchSpecificHolidays}
                  onClick={this.toggleBranchSpecificHolidays}
                />
              </div>
            )}
          </div>
          {rosterSettings.canSetBranchSpecificHolidays && (
            <div className="branchSpecificWarning">
              {
                lg.feiertage_gelten_nicht_für_alle_standorte_fügen_sie_jedem_standort_die_gewünschten_feiertage_hinzu_es_ist_darauf_zu_achten_dass_für_jeden_mitarbeiter_der_hauptstandort_unter_den_arbeitszeiten_anzugeben_ist
              }
            </div>
          )}
        </div>
        <div className="fb column" style={{ flex: "1 1 0", minHeight: 0 }}>
          <div className="holidaysListMain">
            <div className="holidaysCol listWrapper legalDays">
              <div className="fb row headerWithButtonWrapper">
                <h3 style={{ margin: "0" }}>{lg.gesetzliche_feiertage}</h3>
                {legalHolidays.length ? (
                  <Button id="remove-holiday-list" onClick={this.deleteLegalHolidaysClicked}>
                    {lg.liste_löschen}
                  </Button>
                ) : (
                  <Button id="import-holidays" onClick={this.importHolidaysClicked}>
                    {lg.feiertage_importieren}
                  </Button>
                )}
              </div>
              <div className="legalHolidaysList">
                {!legalHolidays.length && <div className="noEntries">{lg.keine_einträge}</div>}
                {legalHolidays.map(this.renderHolidayRow)}
              </div>
            </div>

            <div className="devider"></div>

            <div className="holidaysCol extraHolidays">
              <div className="fb row headerWithButtonWrapper">
                <h3 style={{ margin: "0" }}>{lg.extra_feiertage}</h3>
                <Button
                  id="add-holiday"
                  style={{ marginLeft: "20px" }}
                  children={lg.hinzufügen}
                  onClick={() =>
                    this.props.dispatch(
                      openModal(HolidayPopup, {
                        year: this.state.selectedYear,
                        selectedBranchId: this.state.selectedBranchId,
                      })
                    )
                  }
                />
              </div>
              <div className="listWrapper">
                {customHolidays.map(this.renderHolidayRow)}
                {!customHolidays.length && <div className="noEntries">{lg.keine_einträge}</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const Holidays = withErrorBoundary(connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(_Holidays));
