import React from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { Icon, Alert } from "antd";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import BlueAplanoLogo from "../../../components/Logos/BlueAplanoLogo";
import googleBadge from "./google-badge.svg";
import appleBadge from "./apple-badge.svg";
import tagaUrl from "../noMobileAdmin/tada.gif";
import { envi, environment } from "../../../env";
import BlackZeitguruLogo from "../../../components/Logos/BlackZeitguruLogo";

const mapStateToProps = (state: AppState) => {
  return {};
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class NoMobile extends React.PureComponent<Props> {
  render() {
    const isV2 = envi.isV2();
    const domain = isV2 ? "https://zeitguru.de" : "https://aplano.de";

    return (
      <div className="mobileDetectionPage">
        <div className="fb aCenter" style={{ marginBottom: 14 }}>
          {isV2 && <BlackZeitguruLogo style={{ width: 300, height: 80, marginBottom: 20 }} />}
          {!isV2 && <BlueAplanoLogo style={{ width: 300, height: 80, marginBottom: 20 }} />}
        </div>
        <div className="alertMsg" style={{ marginBottom: 20 }}>
          <Alert
            message={lg.aplano_app_verwenden}
            description={lg.zur_mobilen_nutzung_von_aplano_laden_sie_bitte_die_aplano_app_herunter}
            type="info"
          />
        </div>
        <div className="fb row aCenter jCenter" style={{ marginBottom: 40, marginTop: 0 }}>
          <img src={tagaUrl} width="80" />
        </div>
        <div className="buttonsWrapper">
          <a
            href={
              environment.isV2()
                ? "https://play.google.com/store/apps/details?id=com.zeitguru"
                : "https://play.google.com/store/apps/details?id=com.aplano"
            }
          >
            <img src={googleBadge} alt="googleBadge" className="googleBadge" />
          </a>
          <a
            href={
              environment.isV2()
                ? "https://apps.apple.com/de/app/zeitguru/id6467637471"
                : "https://apps.apple.com/de/app/aplano/id1497688145"
            }
          >
            <img src={appleBadge} alt="appleBadge" className="appleBadge" />
          </a>
        </div>
        <a href={domain} target="_blank" rel="noopener noreferrer" className="avetiqLink">
          Made with <Icon type="heart" theme="filled" style={{ color: "#ff4d4f", fontSize: 11 }} /> in Germany
        </a>
      </div>
    );
  }
}

export default connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(NoMobile);
