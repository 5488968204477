import _ from "lodash";
import { getLocaleLang } from "./helpers/dateFormatHelper";
import { lgDictionary } from "./lgDictionary";

export type languageKeys = "en" | "de";

const setLg = (lgKey: languageKeys = "de") => {
  const fullLg = lgDictionary;
  const nextLg = _.mapValues(fullLg, (k) => k[lgKey]);
  (window as any).lg = nextLg;
  (window as any).setLg = setLg;
};

export type lgDictionaryType = typeof lgDictionary;

export type lgType = {
  [Property in keyof lgDictionaryType]: lgDictionaryType[Property]["en"];
};

setLg(getLocaleLang()); // DON'T REMOVE THIS

const importHack = 1;
export { setLg, importHack };
