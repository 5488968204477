import { BaseRepository } from "../frontend-core/BaseRepository";
import { IShiftHandOverRequest } from "../shared/entities/IShiftHandOverRequest";
import { DispFn } from "../frontend-core/types/thunkTypes";
import { AppState } from "../types/AppState";
import { selectShiftMap } from "../selectors/shiftMapSelector";
import { shiftRepository } from "./shiftRepository";

class ShiftHandOverRequestRepository extends BaseRepository<IShiftHandOverRequest> {
  constructor() {
    super("shiftHandOverRequests");
  }

  onListenerEvent(handOvers: IShiftHandOverRequest[]) {
    return (dispatch: DispFn, getState: () => AppState): void => {
      const shiftMap = selectShiftMap(getState());
      const missingShiftIds = handOvers
        .map((t) => t.id)
        .filter((shiftId) => !shiftMap[shiftId]);

      if (missingShiftIds.length) {
        dispatch(shiftRepository.fetchByIds(missingShiftIds));
      }
    };
  }
}

export const shiftHandOverRequestRepository = new ShiftHandOverRequestRepository();
