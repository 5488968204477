import React from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { AppState } from "../../types/AppState";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import Page from "../../components/Page/Page";

import { withErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";
import _ from "lodash";
import { Switch, Button, notification } from "antd";
import { notificationSettingsRepository } from "../../repositories/notificationSettingsRepository";
import { busyInjector, BusyInjectorProps } from "../../components/BusyInjector/BusyInjector";
import { INotificationSettings } from "../../shared/entities/INotificationSettings";
import { Raw } from "../../shared/entities/IResource";

const mapStateToProps = (state: AppState) => {
  return {
    sessionInfo: selectSessionInfo(state),
  };
};

const settingOptions: {
  key: keyof INotificationSettings;
  label: string;
  forManager: boolean;
}[] = [
  //   //for employees
  {
    key: "openShiftAvailable",
    label: lg.verfügbare_offene_schichten,
    forManager: false,
  },
  {
    key: "handOverAvailable",
    label: lg.anfragen_von_schichtabgaben_an_gruppen,
    forManager: false,
  },
  {
    key: "shiftApplicationResolved",
    label: lg.annahmen_und_ablehnungen_von_schichtbewerbungen,
    forManager: false,
  },
  {
    key: "weekPlanPublished",
    label: lg.veröffentlichungen_von_wochenplänen,
    forManager: false,
  },
  // {
  //   key: "lateToShiftRemind",
  //   label: "Erinnerung über Späterscheinungen zur Schicht",
  //   forManager: false,
  // },
  {
    //for admin/manager:
    key: "changeRequested",
    label: lg.schichtänderung_beantragt,
    forManager: true,
  },
  {
    key: "pendingHandOverRequest",
    label: lg.schichtabgabe_beantragt,
    forManager: true,
  },
  {
    key: "absenceRequestCreated",
    label: lg.abwesenheit_beantragt,
    forManager: true,
  },
  // {
  //   key: "lateToShiftInform",
  //   label: "Verspätetes Einstempelnt zu Schichtbeginn",
  //   forManager: true,
  // },
];

type State = { canceledNotifications: Array<keyof INotificationSettings> };

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class _NotificationSettings extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      canceledNotifications: [],
    };
  }

  componentDidMount = async () => {
    const { dispatch, sessionInfo } = this.props;
    const userId = sessionInfo.user.id;
    const settings = await dispatch(notificationSettingsRepository.fetchOne(userId));
    if (settings) {
      this.setState({
        canceledNotifications: Object.keys(settings) as (keyof INotificationSettings)[],
      });
    }
  };

  updateSettings = (key: keyof INotificationSettings, checked: boolean) => {
    const { canceledNotifications } = this.state;
    this.setState({
      canceledNotifications: checked ? [...canceledNotifications, key] : _.without(canceledNotifications, key),
    });
  };

  saveNotificationSettings = async () => {
    const newSettings: INotificationSettings = {
      id: this.props.sessionInfo.user.id, // we use the userId as a unique ID
    };
    settingOptions.forEach((option) => {
      const isCanceled = this.state.canceledNotifications.includes(option.key);
      const optionKey = option.key as any;
      newSettings[optionKey] = isCanceled ? true : null;
    });

    this.props.dispatch(notificationSettingsRepository.create(newSettings as INotificationSettings));
    notification.success({ message: lg.einstellungen_gespeichert });
  };

  render() {
    const canManage = this.props.sessionInfo.hasManagerPermissions();

    return (
      <Page>
        <div className="notificationSettingsMain pagePadding">
          <h2 className="avTitle">{lg.benachrichtigungen}</h2>
          <div className="infoText">
            {lg.in_folgenden_fällen_möchte_ich_durch_handy_push_notes_benachrichtigt_werden}
          </div>
          {settingOptions
            .filter((o) => (canManage ? o.forManager : !o.forManager))
            .map((option) => {
              const isCanceled = !!this.state.canceledNotifications.find((key) => key === option.key);
              return (
                <div className="switchWrapper" key={option.key}>
                  <Switch checked={!isCanceled} onChange={() => this.updateSettings(option.key, !isCanceled)} />
                  <div className="switchLabel">{option.label}</div>
                </div>
              );
            })}
          <div className="buttonWrapper">
            <Button
              id="notification-settings-save"
              children={lg.Speichern}
              type="primary"
              onClick={async () =>
                this.props.load(
                  this.props.dispatch(() => this.saveNotificationSettings()),
                  "saving"
                )
              }
              loading={this.props.isLoading("saving")}
            />
          </div>
        </div>
      </Page>
    );
  }
}

export const NotificationSettings = withErrorBoundary(
  connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(busyInjector(_NotificationSettings))
);
