import React from "react";
import { Moment } from "moment";
import cn from "classnames";
import "./styles.scss";

type Props = {
  mom: Moment;
  width: number;
  isHoliday: boolean;
  daysInMonth: number;
  holiday: string | null;
  isHighlighted?: boolean;
};

export const AbsenceDayCell = (props: Props) => {
  const weekDays = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];
  const dayOfMonth = props.mom.date();
  const dayOfWeek = props.mom.isoWeekday();
  const isSunnday = dayOfWeek === 7;
  const isSaturday = dayOfWeek === 6;
  const isWeekend = isSaturday || isSunnday;
  const calendarWeek = props.mom.isoWeek();
  const isHoliday = props.isHoliday;
  const { holiday, isHighlighted } = props;

  const toTight = dayOfMonth === props.daysInMonth || (dayOfMonth === 1 && dayOfWeek === 7);

  const width = 100 / props.daysInMonth + "%";

  return (
    <div
      className={cn({
        fb: true,
        absenceDayCellMain: true,
        su: isSunnday,
        sa: isSaturday,
        holiday,
        isHighlighted,
      })}
      style={{ width, flexBasis: width }}
      id={"absence-day-cell-" + dayOfMonth}
    >
      {(isWeekend || isHoliday) && (
        <div
          className={cn({
            isColored: true,
            isHoliday,
            isWeekend,
          })}
          style={{ width: `calc(${width} - 8px)` }}
        />
      )}
      <div className="dayLine"></div>
      {(dayOfMonth === 1 || dayOfWeek === 1) && (
        <div className="fb weekDisplay"> {!toTight && lg.kw + " " + calendarWeek}</div>
      )}
      <div data-rh={holiday}>
        <div className={cn({ fb: true, weekDay: true, holiday: isHoliday })}>{weekDays[dayOfWeek - 1]}</div>
        <div className={cn({ fb: true, monthDay: true, holiday: isHoliday })}>{dayOfMonth}</div>
      </div>
    </div>
  );
};
