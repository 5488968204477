import { Button, Icon, Input, message, Modal, Select } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import { useSelector } from "../../../../helpers/redux";
import { threadInfoRepository } from "../../../../repositories/threadInfoRepository";
import { userThreadInfoRepository } from "../../../../repositories/userThreadInfoRepository";
import { selectActiveUsers } from "../../../../selectors/ActiveUserSelectors";
import { selectUserMap } from "../../../../selectors/mapSelectors";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import { getUserName } from "../../../../shared/helpers/userHelpers";
import { busyInjector, BusyInjectorProps } from "../../../../components/BusyInjector/BusyInjector";
import BusyWrapper from "../../../../components/BusyWrapper/BusyWrapper";
import { ThreadsListStyles } from "../ThreadsList/styles";
import { ChatSidebarMode } from "../../ChatSidebarMode";
import { selectUserThreadInfos } from "../../../../selectors/userThreadInfoSelector";

type Props = {
  setMode: (m: ChatSidebarMode) => void;
  setSelectedThreadId: (id: string | undefined) => void;
  selectedThreadId: string;
};

const Comp: React.FC<Props & BusyInjectorProps> = (props) => {
  const dispatch = useDispatch<DispFn>();
  const [displayAddUserField, setDisplayAddUserField] = useState(false);
  const [selectedNewUserId, setSelectedNewUserId] = useState<string | undefined>();
  const [displayTitleInput, setDisplayTitleInput] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const userMap = useSelector(selectUserMap);
  const activeUsers = useSelector((s) => selectActiveUsers(s, moment().format(SDateFormat)));
  const sessionInfo = useSelector(selectSessionInfo);
  const userThreadInfos = useSelector(selectUserThreadInfos);
  const threadInfos = useSelector((s) => s.data.threadInfos);
  const userThreadInfo = userThreadInfos.find((t) => t.id === props.selectedThreadId)!;
  const threadInfo = threadInfos.find((t) => t.id === props.selectedThreadId);
  const currentUserIsGroupAdmin = threadInfo?.adminUserIds?.includes(sessionInfo.user.id);

  return (
    <BusyWrapper isBusy={props.isLoading()} style={{ flex: 1 }}>
      <div className="fb column shrink" style={{ ...ThreadsListStyles.wrapper, background: "#ededed" }}>
        {/* Head */}
        <div className="fb row" style={ThreadsListStyles.head}>
          <div
            className="fb row jCenter aCenter"
            style={{
              height: 60,
              background: "#ededed",
              padding: 6,
            }}
          >
            <Button
              onClick={() => props.setMode(ChatSidebarMode.ThreadsList)}
              icon="close"
              style={{ position: "absolute", left: 12, top: 12 }}
            />
            <span style={{ fontSize: 16, opacity: 0.8, textAlign: "center" }}>{lg.gruppen_einstellung}</span>
          </div>
          {/* <div className="fb row" style={ThreadsListStyles.headSecondRow}>
        </div> */}
        </div>

        {/* Content */}

        <div className="fb column" style={ThreadsListStyles.content}>
          {userThreadInfo.photoUrl && (
            <img
              src={userThreadInfo.photoUrl}
              style={{
                width: 100,
                height: 100,
                borderRadius: "100%",
                background: "gray",
                alignSelf: "center",
                marginBottom: 24,
                marginTop: 20,
                flexShrink: 0,
              }}
            />
          )}

          <div style={{ textAlign: "center", fontSize: 18, opacity: 0.8 }}>
            {!displayTitleInput && threadInfo?.title}
            {currentUserIsGroupAdmin && displayTitleInput && (
              <div className="fb row" style={{ margin: 12 }}>
                <Input style={{ borderRadius: 50 }} value={newTitle} onChange={(e) => setNewTitle(e.target.value)} />
                <Button
                  disabled={newTitle.length === 0}
                  type="primary"
                  children={lg.Speichern}
                  style={{ marginLeft: 6 }}
                  onClick={async () => {
                    await props.load(
                      dispatch(threadInfoRepository.updateSharedKeyValue(threadInfo!.id, "title", newTitle))
                    );
                    setDisplayTitleInput(false);
                  }}
                />
              </div>
            )}
            {currentUserIsGroupAdmin && !displayTitleInput && (
              <Icon
                type="edit"
                style={{ marginLeft: 6 }}
                onClick={() => {
                  setNewTitle(threadInfo?.title!);
                  setDisplayTitleInput(true);
                }}
              />
            )}
          </div>

          <div style={{ fontSize: 12, opacity: 0.65, marginLeft: 12, marginBottom: 6, marginTop: 20 }}>
            {threadInfo?.userIds.length} {lg.mitglieder}:{" "}
          </div>
          <div className="fb column" style={{ borderTop: "solid 1px #f2f2f2" }}>
            {threadInfo?.userIds.map((uId) => {
              const user = userMap[uId]!;
              return (
                <div
                  key={uId}
                  className="fb row"
                  style={{ padding: "6px 12px", background: "white", borderBottom: "solid 1px #f2f2f2" }}
                >
                  <div className="grow shrink" style={{ overflow: "hidden" }}>
                    {getUserName(user)}
                  </div>
                  {!threadInfo.adminUserIds?.includes(user.id) && currentUserIsGroupAdmin && (
                    <Button
                      type="danger"
                      shape="circle"
                      icon="minus"
                      size={"small"}
                      onClick={() =>
                        Modal.confirm({
                          title: lg.user_aus_der_gruppe_entfernen(getUserName(user)),
                          cancelText: lg.abbrechen,
                          okType: "danger",
                          okText: lg.aus_gruppe_entfernen,
                          onOk: async () => {
                            await props.load(dispatch(threadInfoRepository.removeUser(user.id, threadInfo!.id)));
                          },
                        })
                      }
                    />
                  )}
                  {threadInfo.adminUserIds?.includes(user.id) && (
                    <div
                      style={{
                        padding: "2px 6px",
                        borderRadius: 5,
                        border: "solid 1px green",
                        color: "green",
                        fontSize: 12,
                      }}
                    >
                      {lg.admin}
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {currentUserIsGroupAdmin && displayAddUserField && (
            <div className="fb row" style={{ margin: 12 }}>
              <Select
                showSearch
                onChange={(id) => setSelectedNewUserId(id as string)}
                className="grow shrink"
                placeholder={lg.mitarbeiter_auswählen}
              >
                {activeUsers
                  .filter((u) => !threadInfo?.userIds.includes(u.id))
                  .map((u) => (
                    <Select.Option label={getUserName(u)} value={u.id} children={getUserName(u)} key={u.id} />
                  ))}
              </Select>
              <Button
                type="primary"
                style={{ marginLeft: 6 }}
                icon="plus"
                disabled={!selectedNewUserId}
                onClick={async () => {
                  await props.load(dispatch(threadInfoRepository.addUser(selectedNewUserId!, threadInfo?.id!)));
                  setSelectedNewUserId(undefined);
                  setDisplayAddUserField(false);
                }}
              />
            </div>
          )}

          {currentUserIsGroupAdmin && !displayAddUserField && (
            <div className="fb column aCenter jCenter" style={{ margin: "12px 12px 0 12px" }}>
              <Button
                type="primary"
                children={lg.mitarbeiter_hinzufügen}
                style={{ alignSelf: "stretch" }}
                icon="plus"
                onClick={() => setDisplayAddUserField(true)}
              />
            </div>
          )}

          <div className="fb column aCenter jCenter" style={{ margin: "12px 12px 0 12px" }}>
            <Button
              type="default"
              style={{ alignSelf: "stretch" }}
              children={userThreadInfo?.muted ? lg.stummschaltung_aufheben : lg.stumm_schalten}
              onClick={async () => {
                await props.load(dispatch(userThreadInfoRepository.mute(!userThreadInfo.muted, threadInfo?.id!)));
              }}
            />
          </div>

          {currentUserIsGroupAdmin && (
            <div className="fb column aCenter jCenter" style={{ margin: "12px 12px 0 12px" }}>
              <Button
                children={lg.gruppe_löschen}
                type="danger"
                style={{ alignSelf: "stretch" }}
                onClick={() =>
                  Modal.confirm({
                    title: lg.title_wirklich_löschen(threadInfo?.title),
                    content: lg.alle_nachrichten_in_der_gruppe_werden_endgültig_gelöscht,
                    cancelText: lg.abbrechen,
                    okType: "danger",
                    okText: lg.gruppe_löschen,
                    onOk: async () => {
                      props.setSelectedThreadId(undefined);
                      await props.load(dispatch(threadInfoRepository.delete(threadInfo!.id)));
                      props.setMode(ChatSidebarMode.ThreadsList);
                    },
                  })
                }
              />
            </div>
          )}
        </div>
      </div>
    </BusyWrapper>
  );
};

export const GroupThreadInfo = React.memo(busyInjector(Comp));
