import React from "react";
import { connect } from "react-redux";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";
import { notification, Icon } from "antd";
import { AppState } from "../../../types/AppState";
import FirstRegisterForm from "./FirstRegisterForm";
import SecondRegisterForm from "./SecondRegisterForm";
import { RouteComponentProps } from "react-router";
import { authRepository } from "../../../repositories/authRepository";
import { sitemap } from "../../../helpers/sitemap";
import { FormikErrors } from "formik";
import BlueAplanoLogo from "../../../components/Logos/BlueAplanoLogo";
import { MadeWithLoveLogo } from "../../../components/Logos/MadeWithLoveLogo";
import { BackgroundSquareAnimation } from "../../../components/BackgroundSquareAnimation/BackgroundSquareAnimation";
import { IRegisterTenant } from "../../../shared/types/IRegisterTenant";
import Login from "../login/Login";
import { startSetupWizard } from "../../../reducers/ui/setupWizard";
import { envi, environment } from "../../../env";
import _ from "lodash";
import { asyncSleep } from "../../../helpers/general";
import { getUrlParam } from "../../../frontend-core/helpers/frontendHelpers";
import { sendPost } from "../../../frontend-core/actions/send";
import { getLocaleLang } from "../../../helpers/dateFormatHelper";
import BlackZeitguruLogo from "../../../components/Logos/BlackZeitguruLogo";

const mapStateToProps = () => {
  return {};
};

type State = {
  // currentStepIndex: number;
  values: Partial<IRegisterTenant>;
  errors: FormikErrors<IRegisterTenant>;
};
type OwnProps = {} & RouteComponentProps;
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class RegisterOld extends React.PureComponent<Props, State> {
  isLoading = false;
  constructor(props: Props) {
    super(props);

    const emailUrlParam = getUrlParam("email");

    this.state = {
      // currentStepIndex: 1,
      values: {
        email: emailUrlParam || "",
      },
      errors: {},
    };
  }

  startLoadingHints = async () => {
    this.isLoading = true;
    await asyncSleep(1600);
    this.isLoading && notification.success({ message: "Datenbank initialisiert" });
    await asyncSleep(1400);
    this.isLoading && notification.success({ message: "Account erstellt" });
  };

  stopLoadingHints = () => {
    this.isLoading = false;
  };

  goNext = async (_values: Partial<IRegisterTenant>) => {
    const values = _values as IRegisterTenant;
    try {
      // this.props.dispatch(startSetupWizard());
      this.startLoadingHints();
      await this.props.dispatch(
        sendPost("/api/register-tenant", {
          registration: {
            ..._values,
            isV2: envi.isV2(),
          },
        })
      );
      // await this.props.dispatch(
      //   authRepository.login({
      //     email: values.email,
      //     password: values.password,
      //   })
      // );
      this.stopLoadingHints();
      this.openSuccessNotification();
      this.props.dispatch(startSetupWizard());
      return;
    } catch (e: any) {
      const status = e.response?.nativeResponse.status;
      if (status === 409 || status === 403) {
        const message = {
          409: "E-Mail bereits registriert. Bitte nutzen Sie den LOGIN",
          403: "Bitte nutzen Sie eine gültige private oder geschäfliche E-Mail-Adresse.",
        };
        this.setState({
          // currentStepIndex: 1,
          values: {
            ...this.state.values,
            ...values,
          },
          errors: {
            email: message[status],
          },
        });
      } else {
        throw e;
      }
    }
    // } else {
    //   this.setState({
    //     currentStepIndex: this.state.currentStepIndex + 1,
    //     values: { ...this.state.values, ..._values },
    //     errors: {},
    //   });
    // }
  };

  openSuccessNotification = () => {
    notification.open({
      message: "Registrierung erfolgreich",
      icon: <Icon type="check-circle" style={{ color: "#ffc107" }} />,
    });
  };

  // goPrevious = (values: Partial<IRegisterTenant> = {}) => {
  //   if (this.state.currentStepIndex === 1) {
  //     this.props.history.push(sitemap.login.url);
  //   }
  //   this.setState({
  //     currentStepIndex: this.state.currentStepIndex - 1,
  //     values: { ...values, ...this.state.values },
  //   });
  // };

  render() {
    // const { currentStepIndex } = this.state;
    return (
      <div className="registerPage">
        <BackgroundSquareAnimation />
        <div className="registerForm content">
          <div className="fb aCenter">
            {envi.isV2() ? (
              <BlackZeitguruLogo style={{ width: 300, height: 80, marginBottom: 20 }} />
            ) : (
              <BlueAplanoLogo style={{ width: 300, height: 80, marginBottom: 20 }} />
            )}
          </div>
          <FirstRegisterForm
            // goPrevious={this.goPrevious}
            onNext={this.goNext}
            values={this.state.values}
            errors={this.state.errors}
          />
          {/* {currentStepIndex === 1 && (
          )}
          {currentStepIndex === 2 && (
            <SecondRegisterForm
              goPrevious={this.goPrevious}
              onNext={this.goNext}
              errors={this.state.errors}
              values={this.state.values}
              submitButtonName="Registrierung abschließen"
            />
          )} */}
        </div>
        <MadeWithLoveLogo />
      </div>
    );
  }
}

export default connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(RegisterOld);
