import React, { useEffect, useState } from "react";
import Stripe from "stripe";
import { sendPost } from "../../../frontend-core/actions/send";
import { useSelector } from "../../../helpers/redux";
import { tierInfoSelector } from "../../../selectors/TierInfoSelector";
import moment from "moment";

import "./styles.scss";
import { Button, Checkbox, Icon, message, Modal, notification } from "antd";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../../actions/modal";
import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import BusyWrapper from "../../../components/BusyWrapper/BusyWrapper";
import { IBillingDetails } from "../../../shared/entities/IBillingDetails";
import { openUpdatePaymentDetailsModal } from "../../../actions/billingActions";
import { RuleSpecificationBtn } from "../../../components/RuleSpecificationBtn/RuleSpecificationBtn";
import { IRosterSettings } from "../../../shared/entities/IRosterSettings";
import { rosterSettingsRepository } from "../../../repositories/rosterSettingsRepository";
import { DispFn } from "../../../frontend-core/types/thunkTypes";
import { runMobielBugMigration, runRepeatingShiftsMigration } from "../../../helpers/bugMigrations";

type Props = {};

export const AnnouncementSettings = busyInjector((props: Props & BusyInjectorProps) => {
  const dispatch = useDispatch<DispFn>();
  const rosterSettings = useSelector((s) => s.data.rosterSettings[0]);
  const [settingsState, setSettingsState] = useState<IRosterSettings>({ ...rosterSettings });

  const optionClicked = () => {
    setSettingsState({ ...settingsState, canMakeAnnouncements: !settingsState.canMakeAnnouncements });
  };

  const saveSettings = async () => {
    await dispatch(
      rosterSettingsRepository.update({
        ...rosterSettings,
        ...settingsState,
        specifications: rosterSettings.specifications, // this needs to be done, so that 'specifications' that have been edited in a modal, dont get removed by clicking save
      })
    );
    message.success(lg.einstellungen_gespeichert);
  };

  return (
    <div className="announcementSettingsMain">
      {/* <h2 className="avTitle">Chat Einstellungen</h2> */}
      <h2>{lg.ansagen}</h2>
      <div className="infoText">
        {
          lg.ansagen_sind_nachrichten_an_alle_oder_einzelne_mitarbeiter_die_per_klick_als_gelesen_markiert_werden_müssen_mit_der_lesebestätigung_gehen_wichtige_informationen_im_betrieb_nicht_mehr_unter
        }
        <div onDoubleClick={() => dispatch(runMobielBugMigration())}>+</div>
        <div onDoubleClick={() => dispatch(runRepeatingShiftsMigration())} style={{ marginLeft: 14 }}>
          x
        </div>
      </div>
      <div className="content">
        <div className="basicCheckBoxesList">
          <div className={"checkboxWrapper borderTop ruleBlock"}>
            <Checkbox checked={settingsState.canMakeAnnouncements} onChange={optionClicked} />
            <div className="label" onClick={optionClicked}>
              {lg.ansagen_können_gemacht_werden_von}
            </div>
            {/* {options.infoText && (
              <div className="infoIconWrapper" data-rh={options.infoText}>
                <Icon type="info-circle" />
              </div>
            )} */}
            {settingsState.canMakeAnnouncements ? (
              <RuleSpecificationBtn
                type="RosterSettings"
                ruleKey={"canMakeAnnouncements"}
                style={{ background: "#e9e9e9e0" }}
              />
            ) : (
              <div className="noOne">{lg.keinem}</div>
            )}
          </div>
        </div>
        <div className="saveButtonWrapper">
          <Button
            id="save-chat-settings"
            type="primary"
            children={lg.Speichern}
            onClick={() => props.load(saveSettings())}
            loading={props.isLoading()}
          ></Button>
        </div>
      </div>
    </div>
  );
});
