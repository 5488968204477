import React, { useState } from "react";
import "./styles.scss";
import { DatePicker } from "antd";
import { Moment } from "moment";
import cn from "classnames";
import { simpleDateToMoment, toSimpleDate } from "../../../../../shared/helpers/timeHelpers";

type Props = {
  shift: any;
  changeShiftDate?: (date: string) => void;
  disabled?: boolean;
  justWeekDay?: boolean;
  isEmpty?: boolean;
  today: string;
  disabledDate?: (m: moment.Moment | null) => boolean;
};

export const ShiftPopupDatePicker = (props: Props) => {
  const { shift } = props;
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);

  return (
    <label
      className={cn({
        shiftPopupDatePicker: true,
        disabled: props.disabled,
      })}
    >
      <span
        className="datePickerLabel"
        // onClick={() => {
        //   props.changeShiftDate && setDatePickerIsOpen(!datePickerIsOpen);
        // }}
      >
        {props.isEmpty
          ? null
          : simpleDateToMoment(shift.date as string).format(props.justWeekDay ? "dddd" : "dd DD. MMM")}
      </span>

      <DatePicker
        disabled={props.disabled}
        open={datePickerIsOpen}
        value={simpleDateToMoment(shift.date)}
        onChange={(value: Moment | null) => {
          props.changeShiftDate && props.changeShiftDate(toSimpleDate(value as Moment));
        }}
        format="dd DD. MMM"
        placeholder={lg.tag_wählen}
        allowClear={false}
        onOpenChange={(v) => setDatePickerIsOpen(v)}
        disabledDate={props.disabledDate ? props.disabledDate : (m) => !!(m && m.year() > 2999)}
        id="rosterDayPicker"
      />
    </label>
  );
};
