import React from "react";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "../../../../helpers/redux";
import { BasicSelect } from "../../../../components/BasicSelect/BasicSelect";
import { DatePicker } from "antd";
import "./styles.scss";
import { Operation } from "../../../../shared/helpers/firebaseHelpers";
import { LogFilters } from "../ShiftLogPage";
import { selectUsersFull } from "../../../../selectors/usersFullSelector";
import { localizeFormat } from "../../../../helpers/dateFormatHelper";

export const operationWordings = {
  [Operation.create]: "erstellt",
  [Operation.update]: "geändert",
  [Operation.remove]: "gelöscht",
};

type Props = {
  fetchLogs: () => void;
  filters: LogFilters;
  updateFilters: (filters: LogFilters) => any;
};

export const ShiftLogActionBar = (props: Props) => {
  const branches = useSelector((state) => state.data.branches);
  const users = useSelector(selectUsersFull);

  return (
    <div className="shiftLogActionRowMain">
      {branches.length > 1 && (
        <div className="fieldWrapper" style={{ width: 180 }}>
          <BasicSelect
            id="shiftlog-select-branch"
            options={branches.map((u) => ({
              value: u.id,
              label: u.name,
            }))}
            onChange={(branchId: string) => {
              props.updateFilters({ ...props.filters, branchId });
              props.fetchLogs();
            }}
            value={props.filters.branchId}
            placeholder={lg.standort}
          />
        </div>
      )}
      <div className="fieldWrapper" style={{ width: 280 }}>
        <DatePicker.RangePicker
          allowClear={false}
          format={"L"}
          placeholder={[lg.von_start, lg.bis]}
          onChange={([startMoment, endMoment]) => {
            props.updateFilters({
              ...props.filters,
              startTimestamp: startMoment!.startOf("day").unix(),
              endTimestamp: endMoment!.endOf("day").unix(),
            });
            props.fetchLogs();
          }}
          value={[moment.unix(props.filters.startTimestamp), moment.unix(props.filters.endTimestamp)]}
        />
      </div>
      <div className="fieldWrapper" style={{ width: 180 }}>
        <BasicSelect
          id="shiftlog-select-operation"
          options={Object.entries(operationWordings).map(([value, label]) => ({
            value,
            label,
          }))}
          onChange={(operation: Operation) => props.updateFilters({ ...props.filters, operation })}
          value={props.filters.operation}
          placeholder={lg.operation}
          allowClear
        />
      </div>
      <div className="fieldWrapper" style={{ width: 220 }}>
        <BasicSelect
          id="shiftlog-select-user"
          options={users.map((u) => ({
            label: u.name,
            value: u.id,
          }))}
          onChange={(involvedUserIds: string[]) => props.updateFilters({ ...props.filters, involvedUserIds })}
          value={props.filters.involvedUserIds}
          placeholder={lg.mitarbeiter}
          mode="multiple"
          allowClear
          maxTagTextLength={11}
          maxTagCount={1}
        />
      </div>
    </div>
  );
};
