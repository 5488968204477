import { BaseRepository } from '../frontend-core/BaseRepository';
import { IDailyNote } from '../shared/entities/IDailyNote';

class DailyNoteRepository extends BaseRepository<IDailyNote> {
  constructor() {
    super('dailyNotes');
  }
}

export const dailyNoteRepository = new DailyNoteRepository();
