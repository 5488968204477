import { RoleType } from "./../shared/entities/IUser";
import { toSimpleDate } from "./../shared/helpers/timeHelpers";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import moment from "moment";
import { selectSessionInfo } from "./SessionInfoSelector";
import { selectShiftMap } from "./shiftMapSelector";

// used for the grid and shiftModals
export const selectActiveHandOverRequests = createSelector(
  [(state: AppState) => state.data.shiftHandOverRequests, selectShiftMap],
  (handOverRequests, shiftMap) => {
    const today = toSimpleDate(moment());
    return (
      handOverRequests
        .filter((handOverReq) => handOverReq.date >= today)
        // filter out requests where the shift is not yet loaded to avoid null-pointers
        .filter((r) => shiftMap[r.id])
    );
  }
);

export const selectVisibleHandoverRequestItems = createSelector(
  [selectActiveHandOverRequests, selectSessionInfo, selectShiftMap],
  (_handOverRequests, sessionInfo, shiftMap) => {
    const { user } = sessionInfo;
    const handOverRequests = _handOverRequests.filter((r) => shiftMap[r.id]);

    switch (sessionInfo.user.role) {
      case RoleType.admin:
      case RoleType.manager:
        return handOverRequests
          .filter((r) => sessionInfo.isAdmin() || user.branchIds.includes(r.branchId))
          .filter(
            (r) =>
              r.toUserId === user.id ||
              r.approvedByUserId ||
              (!r.toUserId && user.jobPositionIds.includes(r.jobPositionId))
          );
      case RoleType.employee:
      default:
        return handOverRequests
          .filter((r) => user.branchIds.includes(r.branchId))
          .filter(
            (r) =>
              r.fromUserId === user.id ||
              r.toUserId === user.id ||
              (!r.toUserId &&
                user.jobPositionIds.includes(r.jobPositionId) &&
                (!r.approvedByUserId || r.approvedByUserId === user.id))
          );
    }
  }
);
