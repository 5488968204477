import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { Button, Checkbox, DatePicker, Icon, Input, InputNumber } from "antd";
import _ from "lodash";
import { ISurcharge } from "../../../../../shared/entities/ISurcharge";
import { InputProps } from "antd/lib/input";
import { InputNumberProps } from "antd/lib/input-number";
import WeekDayPicker from "../../../../../components/modals/AvailabilityModal/weekDayPicker/WeekDayPicker";
import { WeekDay } from "../../../../../shared/constants/WeekDays";
import { BasicSelect } from "../../../../../components/BasicSelect/BasicSelect";
import AvTimeInput from "../../../../../components/AvTimeInput/AvTimeInput";
import cn from "classnames";
import moment from "moment";
import { arrayToMap, mapToArray } from "./SurchargeModal";
import { isUserActive } from "../../../../../selectors/ActiveUserSelectors";
import { selectUsersFull } from "../../../../../selectors/usersFullSelector";
import { dummyUserTypes } from "../../../../Users/UserDetailsModal/ContractEntry/UserTypeEditor/UserTypeEditor";
import { useSelector } from "../../../../../helpers/redux";
import { toMoment, toSimpleDate } from "../../../../../shared/helpers/timeHelpers";
import UserPickerList from "../../../branches/UserPickerList/UserPickerList";
import DurationInput from "../../../../Users/UserDetailsModal/ContractEntry/DurationInput/DurationInput";
import { selectWorkSpaces } from "../../../../../selectors/_workSpacesSelector";

type Props = {
  surcharge: ISurcharge;
  onChange: (s: Partial<ISurcharge>) => void;
};

const toOptions = (arr: { name: string; id: string }[]) =>
  arr.map((el) => ({
    label: el.name,
    value: el.id,
  }));

export const SurchargeModalMiscTab = (props: Props) => {
  const { surcharge, onChange } = props;

  const _jobPositions = useSelector((s) => s.data.jobPositions);
  const _branches = useSelector((s) => s.data.branches);
  const _labels = useSelector(selectWorkSpaces);
  const _addresses = useSelector((s) => s.data.shiftAddresses);

  const jobPositions = _jobPositions.filter((b) => surcharge.jobPositions?.[b.id] || !b.isInactive);
  const branches = _branches.filter((b) => surcharge.branches?.[b.id] || !b.isInactive);
  const labels = _labels.filter((b) => surcharge.labels?.[b.id] || !b.isInactive);
  const addresses = _addresses
    .filter((b) => surcharge.addresses?.[b.id] || !b.isInactive)
    .map((a) => ({ ...a, name: a.name || a.address }));

  return (
    <div className="surchargeModalUserTabMain">
      <div className="formRow" style={{ padding: 0 }}>
        <div className="formWrapper" style={{ width: 500, marginRight: 12 }}>
          <div className="datePickerWrapper">
            <DatePicker
              value={surcharge.validFrom ? toMoment(surcharge.validFrom) : undefined}
              onChange={(validFrom) => onChange({ validFrom: validFrom ? toSimpleDate(validFrom) : undefined })}
              format="DD. MMM YYYY"
              placeholder={lg.gueltig_ab}
              allowClear
            />
            <div className="devider">
              <Icon type="arrow-right" />
            </div>
            <DatePicker
              value={surcharge.validTo ? toMoment(surcharge.validTo) : undefined}
              onChange={(validTo) => onChange({ validTo: validTo ? toSimpleDate(validTo) : undefined })}
              format="DD. MMM YYYY"
              placeholder={lg.gueltig_bis}
              allowClear
            />
          </div>
        </div>
      </div>
      <div className="labelWrapper" style={{ marginLeft: 4, marginBottom: 12, color: "#6e6d6d" }}>
        Gilf für folgende Schichten:
      </div>
      <div className="formRow" style={{ padding: 0 }}>
        <div className="formWrapper" style={{ width: 162, marginRight: 12 }}>
          <BasicSelect
            placeholder={lg.alle_standorte}
            options={toOptions(branches)}
            value={mapToArray(surcharge.branches || {})}
            mode="multiple"
            onChange={(ids) => onChange({ branches: arrayToMap(ids) })}
          />
        </div>
        <div className="formWrapper" style={{ width: 162, marginRight: 12 }}>
          <BasicSelect
            placeholder={lg.alle_rollen}
            options={toOptions(jobPositions)}
            value={mapToArray(surcharge.jobPositions || {})}
            mode="multiple"
            onChange={(ids) => onChange({ jobPositions: arrayToMap(ids) })}
          />
        </div>
        <div className="formWrapper" style={{ width: 162, marginRight: 12 }}>
          <BasicSelect
            placeholder={lg.alle_labels}
            options={toOptions(labels)}
            value={mapToArray(surcharge.labels || {})}
            mode="multiple"
            onChange={(ids) => onChange({ labels: arrayToMap(ids) })}
          />
        </div>
        {addresses.length && (
          <div className="formWrapper" style={{ width: 162 }}>
            <BasicSelect
              placeholder={lg.alle_adressen}
              options={toOptions(addresses)}
              value={mapToArray(surcharge.addresses || {})}
              mode="multiple"
              onChange={(ids) => onChange({ addresses: arrayToMap(ids) })}
            />
          </div>
        )}
      </div>
      <div className="formRow">
        <div className="infoText minDurationText">Nur anwenden, wenn Zuschlagszeitruam mindestens</div>
        <DurationInput
          value={surcharge.minDuration}
          onChange={(minDuration) => onChange({ minDuration })}
          inputWidth={50}
          hoursPlaceholder={lg.Std}
          minutesPlaceholder={lg.Min}
        />
        <div className="infoText minDurationText" style={{ marginLeft: 8 }}>
          beträgt.
        </div>
      </div>
    </div>
  );
};
