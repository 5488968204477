import { ReportColumns, reportColumnActionTypes } from "../../../actions/reporting";
//
const initialState: ReportColumns = {
  shiftId: false,
  date: true,
  userName: true,
  staffNumber: false,
  jobPositionName: true,
  branchName: true,
  addressName: false,
  shiftTime: true,
  trackedTime: true,
  breakIntervalls: true,
  duration: true,
  comment: false,
  workSpaceName: false,
  hashtags: false,
  wage: false,
  /// surcharges
  nightSurcharge: false,
  holidaySurcharge: false,
  sundaySurcharge: false,
  customSurcharge: false,
};

export const reportColumnsReducer = (state = initialState, a: any): ReportColumns =>
  a.type === reportColumnActionTypes.SET ? a.payload : state;
