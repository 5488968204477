import React from "react";
import { connect, batch } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { Button, Dropdown, Menu, Icon } from "antd";
import { openModal } from "../../../../actions/modal";
import { RosterTemplateListPopup } from "../../../../components/RosterTemplateListPopup/RosterTemplateListPopup";
import { RosterTemplatePopup } from "../../../../components/RosterTemplatePopup/RosterTemplatePopup";
import { setRosterTemplateMode } from "../../../../actions/rosterTemplate";
import { selectRosterTemplates } from "../../../../selectors/rosterTemplatesSelector";
import { DeleteWeekPopup } from "../../../../components/DeleteWeekPopup/DeleteWeekPopup";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { push } from "connected-react-router";
import { sitemap } from "../../../../helpers/sitemap";
import { RosterMode } from "../../../../reducers/ui/shifts/roster/rosterMode";
import { RosterType, setRosterType } from "../../../../reducers/ui/shifts/roster/rosterType";
import { featuresSelector } from "../../../../selectors/FeaturesSelector";
import { paidFeatureWarning } from "../../../../actions/paidFeatureWarning";
import { WeekPlanSettingsModal } from "../components/WeekPlanSettingsModal/WeekPlanSettingsModal";
import { startPrintMode } from "../../../../actions/printMode";
import { sendPost } from "../../../../frontend-core/actions/send";
import { DayPlanSettingsModal } from "../components/DayPlanSettingsModal/DayPlanSettingsModal";
import { MonthPlanSettingsModal } from "../components/MonthPlanSettingsModal/MonthPlanSettingsModal";
import { BatchDeletePopup } from "../../../../components/BatchDeletePopup/BatchDeletePopup";

const mapStateToProps = (state: AppState) => {
  return {
    selectedWeek: state.ui.shifts.roster.selectedWeek,
    selectedBranch: state.ui.selectedBranch,
    rosterTemplateModeIsActive: state.ui.shifts.roster.rosterTemplateMode.active,
    rosterTemplates: selectRosterTemplates(state),
    sessionInfo: selectSessionInfo(state),
    rosterMode: state.ui.shifts.roster.rosterMode,
    rosterType: state.ui.shifts.roster.rosterType,
    features: featuresSelector(state),
  };
};

type State = { isOpen: boolean };
type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class MoreDropdown_ extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isOpen: false };
  }

  render() {
    const {
      rosterTemplateModeIsActive,
      rosterTemplates,
      dispatch,
      selectedWeek,
      sessionInfo,
      rosterMode,
      rosterType,
      selectedBranch,
    } = this.props;

    const canDeleteRoster =
      (sessionInfo.isAdmin() || sessionInfo.isManagingBranch(this.props.selectedBranch as string)) &&
      !rosterTemplateModeIsActive;

    const showTemplateOptions = rosterMode === RosterMode.Week;

    const items = [
      {
        isVisible: !rosterTemplateModeIsActive && showTemplateOptions,
        isDisabled: !selectedBranch,
        tooltip: !selectedBranch && lg.für_diese_funktion_müssen_sie_zunächst_den_dienstplan_standort_festlegen,
        text: lg.wochenVorlagen,
        icon: "file-text",
        onClick: () => {
          batch(() => {
            this.props.dispatch(setRosterType(RosterType.Grid));
            dispatch(setRosterTemplateMode(true, rosterTemplates.length ? rosterTemplates[0] : undefined));
          });
        },
      },
      {
        isVisible: !rosterTemplateModeIsActive && !!rosterTemplates.length && showTemplateOptions,
        isDisabled: !selectedBranch,
        tooltip: !selectedBranch && lg.für_diese_funktion_müssen_sie_zunächst_den_dienstplan_standort_festlegen,
        text: lg.vorlage_importieren,
        icon: "file-search",
        onClick: () => {
          if (!this.props.features.rosterTemplates) {
            this.props.dispatch(paidFeatureWarning());
            return;
          }
          dispatch(openModal(RosterTemplateListPopup, {}));
        },
      },
      {
        isVisible: !rosterTemplateModeIsActive && showTemplateOptions,
        isDisabled: !selectedBranch,
        tooltip: !selectedBranch && lg.für_diese_funktion_müssen_sie_zunächst_den_dienstplan_standort_festlegen,
        text: lg.als_vorlage_speichern,
        icon: "file-add",
        onClick: () => {
          dispatch(openModal(RosterTemplatePopup, { basedOnDate: selectedWeek }));
        },
        style: { borderBottom: "1px solid #eaecef" },
      },
      {
        isVisible: true,
        text: lg.änderungs_log,
        icon: "interaction",
        onClick: () => {
          if (!this.props.features.activityProtocol) {
            dispatch(paidFeatureWarning());
            return;
          }
          dispatch(push(sitemap.shiftLogs.url));
        },
      },
      {
        isVisible: rosterMode === RosterMode.Day,
        text: lg.ansicht,
        icon: "pic-left",
        onClick: () => dispatch(openModal(DayPlanSettingsModal, {})),
      },
      {
        isVisible: rosterMode === RosterMode.Week,
        text: lg.ansicht,
        icon: "pic-left",
        onClick: () => dispatch(openModal(WeekPlanSettingsModal, {})),
      },
      {
        isVisible: rosterMode === RosterMode.Month,
        text: lg.ansicht,
        icon: "pic-left",
        onClick: () => dispatch(openModal(MonthPlanSettingsModal, {})),
      },
      {
        isVisible:
          (rosterMode === RosterMode.Week || rosterMode === RosterMode.Month) && rosterType === RosterType.Grid,
        text: lg.drucken,
        icon: "printer",
        onClick: () => {
          this.props.features.absences
            ? this.props.dispatch(startPrintMode())
            : this.props.dispatch(paidFeatureWarning());
        },
      },
      {
        isVisible: canDeleteRoster,
        text: lg.bulk_löschen,
        icon: "delete",
        iconColor: "#F5232D",
        onClick: () => dispatch(openModal(BatchDeletePopup, {})),
      },
      {
        isVisible: canDeleteRoster && rosterMode === RosterMode.Week,
        text: lg.wochenplan_löschen,
        icon: "delete",
        iconColor: "#F5232D",
        onClick: () => dispatch(openModal(DeleteWeekPopup as any, {})),
      },
    ];

    return (
      <>
        <Dropdown
          overlayClassName="moreDropdown"
          trigger={["click"]}
          visible={this.state.isOpen}
          onVisibleChange={(isOpen) => this.setState({ isOpen })}
          placement="bottomRight"
          overlay={
            <>
              <Menu>
                {items
                  .filter((item) => item.isVisible)
                  .map((item, i) => (
                    <Menu.Item
                      disabled={item.isDisabled}
                      data-rh={item.tooltip || null}
                      style={{
                        ...(item.style || {}),
                        marginBottom: 0,
                        marginTop: 0,
                      }}
                      key={i}
                      onClick={() => {
                        this.setState({ isOpen: false });
                        item.onClick();
                      }}
                      children={
                        <>
                          <Icon type={item.icon} style={{ color: item.iconColor || "#1a90ff" }} />
                          {item.text}
                        </>
                      }
                    />
                  ))}
              </Menu>
            </>
          }
        >
          <Button icon="more" type="primary" id="roster-more-dropdown" style={{ marginLeft: 12 }} />
        </Dropdown>
      </>
    );
  }
}

export const MoreDropdown = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(MoreDropdown_);
