import React from "react";
import { Select, Icon, Input, notification } from "antd";
import { SelectProps } from "antd/lib/select";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";
import cn from "classnames";

const mapStateToProps = (state: AppState) => {
  return {};
};

type State = {
  isLoading: boolean;
};

type OwnProps = {
  onChange: (value: string) => void;
  value: string | undefined;
  closeField: () => void;
  canManage: boolean;
  hastFeatureShiftComment?: boolean;
  isDisplayOnly?: boolean;
  saveShiftComment: () => Promise<any>;
} & SelectProps<any>;

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _ShiftCommentField extends React.PureComponent<Props, State> {
  initialCommentValue: string;

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.initialCommentValue = this.props.value || "";
  }

  saveClicked = async () => {
    this.setState({ isLoading: true });
    await this.props.saveShiftComment();
    this.setState({ isLoading: false });
    this.initialCommentValue = this.props.value; // so the save-button gets greyed out again
    this.forceUpdate(); // to rerender after the 'initialCommentValue' got updated
    notification.success({ message: lg.kommentar_gespeichert });
  };

  render() {
    const { value, onChange, canManage, disabled, hastFeatureShiftComment, isDisplayOnly, ...otherProps } = this.props;

    return (
      <div className="avInputFieldMain shiftCommentField">
        <span className="labelWrapper">
          <label>{lg.kommentar}</label>
        </span>
        <Input
          onKeyDown={(e) => e.stopPropagation()} // to prevent Enter-Save of the shift popup.
          type="text"
          onChange={(e) => onChange(e.target.value)}
          value={value}
          name="Kommentar"
          autoFocus={canManage && !value} // autofocus only when expanding
          disabled={disabled || isDisplayOnly || !hastFeatureShiftComment}
          data-rh-at="bottom"
          data-rh={hastFeatureShiftComment ? null : lg.für_diese_funktion_wird_ein_upgrade_benötigt}
        />
        {canManage ? (
          <div
            className="closeExpandedFieldButton"
            onClick={() => {
              this.props.onChange("");
              this.props.closeField();
            }}
          >
            <Icon type="close" />
          </div>
        ) : (
          <div
            className={cn({
              saveCommentButton: true,
              isClickable: this.initialCommentValue !== (value || ""),
            })}
            onClick={this.saveClicked}
            data-rh={lg.kommentar_speichern}
          >
            {this.state.isLoading ? <Icon type="loading" /> : <Icon type="edit" />}
          </div>
        )}
      </div>
    );
  }
}

export const ShiftCommentField = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(_ShiftCommentField);
