// dd22
import { selectRosterDateRange } from "./../selectors/rosterDateRangeSelector";
import { getDaysInMonth, getPrevSimpleDate } from "./../shared/helpers/dateHelpers";
import { toMoment } from "./../shared/helpers/timeHelpers";
import { AppState } from "./../types/AppState";
import { DispFn } from "../frontend-core/types/thunkTypes";
import { trackingRepository } from "../repositories/TrackingRepository";
import { shiftRepository } from "../repositories/shiftRepository";
import { addSimpleDays } from "../shared/helpers/dateHelpers";
import { timeClockingRepository } from "../repositories/timeClockingRepository";
import { toSimpleDate } from "../shared/helpers/timeHelpers";
import moment from "moment";
import { RosterMode } from "../reducers/ui/shifts/roster/rosterMode";
import { FilterValue } from "../shared/types/queryParams";
import { SDateFormat } from "../shared/helpers/SimpleTime";
import { selectSessionInfo } from "../selectors/SessionInfoSelector";
import { RoleType } from "../shared/entities/IUser";

export const addShiftsOfRosterListener = () => {
  return async (dispatch: DispFn, getState: () => AppState) => {
    const templateMode = getState().ui.shifts.roster.rosterTemplateMode;
    const rosterMode = getState().ui.shifts.roster.rosterMode;
    const { rangeStart, rangeEnd } = selectRosterDateRange(getState());
    let from = rangeStart;
    let to = rangeEnd;

    if (templateMode.active) {
      if (templateMode.rosterTemplate) {
        from = templateMode.rosterTemplate!.shiftsStoredAtDate;
        to = addSimpleDays(from, 6);
      }
    }

    if (rosterMode === RosterMode.Day) {
      from = getPrevSimpleDate(from); // fetch prev day aswell, to include nightshifts of previous day
    }

    return dispatch(
      shiftRepository.addListener({
        filter: ["date", "between", [from, to]],
        key: "shiftsOfRoster",
      })
    );
  };
};

export const addTrackingsOfRosterListener = () => {
  return async (dispatch: DispFn, getState: () => AppState) => {
    const branchId = getState().ui.selectedBranch;
    const rosterMode = getState().ui.shifts.roster.rosterMode;
    let from = "";
    let to = "";

    if (rosterMode === RosterMode.Month) {
      return; // we dont display trackings in monthView
    }

    if (rosterMode === RosterMode.Week) {
      from = getState().ui.shifts.roster.selectedWeek;
      to = addSimpleDays(from, 6);
    }

    if (rosterMode === RosterMode.Day) {
      from = getState().ui.shifts.roster.selectedDay;
      from = getPrevSimpleDate(from); // fetch prev day aswell, to include nightshifts of previous day
      to = getState().ui.shifts.roster.selectedDay;
    }

    const filterProp = branchId ? "branchId_date" : "date";
    const filterFromTo: [FilterValue, FilterValue] = branchId
      ? [`${branchId}_${from}`, `${branchId}_${to}`]
      : [`${from}`, `${to}`];

    await dispatch(
      trackingRepository.addListener({
        filter: [filterProp, "between", filterFromTo],
        key: "trackingsOfWeek",
      })
    );
  };
};

export const addClockingsOfRosterListener = () => {
  return async (dispatch: DispFn, getState: () => AppState) => {
    const branchId = getState().ui.selectedBranch;
    const sessionUser = selectSessionInfo(getState()).user;
    const sessionUserId = sessionUser.id;
    const rosterMode = getState().ui.shifts.roster.rosterMode;
    const isV2 = getState().data.tenantInfo.isV2;
    const isEmplyee = sessionUser.role === RoleType.employee;
    //const { rangeStart, rangeEnd } = selectRosterDateRange(getState());
    let from = "";
    let to = "";

    if (rosterMode === RosterMode.Month) {
      if (isV2) {
        from = getState().ui.shifts.roster.selectedMonth;
        to = moment(from, SDateFormat).endOf("month").format(SDateFormat);
      } else {
        return; // we dont display clockings in monthView, no need to fetch
      }
    }

    if (rosterMode === RosterMode.Week) {
      from = getState().ui.shifts.roster.selectedWeek;
      to = addSimpleDays(from, 6);
    }

    if (rosterMode === RosterMode.Day) {
      from = getState().ui.shifts.roster.selectedDay;
      from = getPrevSimpleDate(from); // fetch prev day aswell, to include nightshifts of previous day
      to = getState().ui.shifts.roster.selectedDay;
    }

    const filterProp = branchId ? "branchId_date" : "date";
    const filterFromTo: [FilterValue, FilterValue] = branchId
      ? [`${branchId}_${from}`, `${branchId}_${to}`]
      : [`${from}`, `${to}`];

    const filterPropV2 = "userId_date";
    const filterFromToV2: [FilterValue, FilterValue] = [`${sessionUserId}_${from}`, `${sessionUserId}_${to}`];

    const fetchOnlyUserClockigs = isV2 && isEmplyee;

    await dispatch(
      timeClockingRepository.addListener({
        filter: fetchOnlyUserClockigs
          ? [filterPropV2, "between", filterFromToV2]
          : [filterProp, "between", filterFromTo],
        key: "clockingsOfWeek",
      })
    );
  };
};
