import React, { useEffect } from "react";
import { useSelector } from "../../../../helpers/redux";
import _ from "lodash";
import { addSimpleDays } from "../../../../shared/helpers/dateHelpers";
import { generateWeekdays, simpleDateToMoment } from "../../../../shared/helpers/timeHelpers";

import "./styles.scss";
import { KanbanWeekdaysHeaderRow } from "./KanbanWeekdaysHeaderRow";
import { canUserEditOwnShiftsOfDate, getClickedShiftKey } from "../helpers";
import { openModal } from "../../../../actions/modal";
import { useDispatch } from "react-redux";
import { ShiftPopup } from "../../../../components/ShiftPopup/ShiftPopup/ShiftPopup";
import { KanbanUserList } from "./KanbanUserList";
import { KanbanColumn } from "./KanbanColumn/KanbanColumn";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import { KanbanRow } from "./KanbanRow/KanbanRow";
import { getKanbanCellID } from "./kanbanHelpers";
import { IShift } from "../../../../shared/entities/IShift";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { RoleType } from "../../../../shared/entities/IUser";
import { selectRosterSettingsByUser } from "../../../../selectors/rosterSettingsByUserSelector";
import { selectGroupByJobPosition } from "../../../../selectors/groupByJobPositionSelector";

type Props = {
  startDate: string;
};

export const RosterKanban = ({ startDate }: Props) => {
  const branchId = useSelector((s) => s.ui.selectedBranch);
  const jobPositions = useSelector((s) => s.data.jobPositions);
  const sessionInfo = useSelector(selectSessionInfo);
  const sessionUser = sessionInfo.user;
  const canManage = sessionInfo.hasManagerPermissions();
  const dispatch = useDispatch();
  const shifts = useSelector((s) => s.data.shifts);
  const groupByJobPosition = useSelector(selectGroupByJobPosition);
  const rosterSettings = useSelector((s) => s.data.rosterSettings[0]);
  const rosterSettingsByUser = useSelector(selectRosterSettingsByUser);

  const detectShitClick = (e: any) => {
    const shiftId = getClickedShiftKey(e);
    const shift = shiftId ? shifts.find((s) => s.id === shiftId)! : undefined;
    const canOpenShift =
      canManage ||
      (shift && shift.userId === sessionUser.id) ||
      (shift && !shift.userId && sessionUser.jobPositionIds.includes(shift.jobPositionId)); // employees can open openShifts with theyr jobPos

    if (shift) {
      canOpenShift && dispatch(openModal(ShiftPopup, { shift }));
      e.stopPropagation();
      e.preventDefault();
      return;
    }

    const kanbanCellId = getKanbanCellID(e);

    const canEditOwnShifts =
      kanbanCellId &&
      !canManage &&
      (!kanbanCellId.jobPositionId || sessionUser.jobPositionIds.includes(kanbanCellId.jobPositionId!)) &&
      canUserEditOwnShiftsOfDate(rosterSettingsByUser[sessionUser.id], kanbanCellId.date);

    if (kanbanCellId && (canManage || canEditOwnShifts)) {
      const date = kanbanCellId.date as string;
      const jobPositionId = kanbanCellId.jobPositionId !== "undefined" ? kanbanCellId.jobPositionId : undefined;
      const shiftCore = { date, jobPositionId, userId: canManage ? undefined : sessionUser.id };
      dispatch(openModal(ShiftPopup, { shift: shiftCore, isUserPickerMode: canManage }));
    }
  };

  useEffect(() => {
    document.addEventListener("click", detectShitClick);
    return () => {
      document.removeEventListener("click", detectShitClick);
    };
  }, [shifts]);

  const weekdaysMom = generateWeekdays(startDate, {
    noSaturday: rosterSettings.hideSaturdaysInWeekPlan,
    noSunday: rosterSettings.hideSundaysInWeekPlan,
  });

  return (
    <div className="kanbanWrapper">
      {canManage && <KanbanUserList startDate={startDate} />}
      <div className="kanban weeklyRoster">
        <KanbanWeekdaysHeaderRow startDate={startDate} weekdays={weekdaysMom} />
        <div className="kanbanContent">
          {groupByJobPosition ? (
            _.orderBy(jobPositions, (j) => (j.sortIndex === undefined ? j.name.toLowerCase() : j.sortIndex))
              .filter((j) => !j.isInactive)
              .map((j) => <KanbanRow jobPositionId={j.id} startDate={startDate} key={j.id} />)
          ) : (
            <KanbanRow startDate={startDate} />
          )}
        </div>
      </div>
    </div>
  );
};
