interface StyleSheet {
  [key: string]: React.CSSProperties;
}

export const TimeClockDetailStyles: StyleSheet = {
  box: {
    backgroundColor: "#fcf1d1;",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    // padding: 16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 0,
    paddingBottom: 12,
    overflow: "auto",
    maxHeight: 240,
    flexGrow: 0,
    flexShrink: 1,
  },
  row: {
    marginTop: 12,
    flexShrink: 0,
    flexGrow: 0,
    // height: 30,
  },
  text: {
    marginLeft: 8,
    color: "black",
  },
};
