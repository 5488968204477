import { toSimpleDate, toSimpleDateTime, getEndDateTime } from "./../shared/helpers/timeHelpers";
import { createSelector } from "reselect";
import { selectActiveUsers } from "./ActiveUserSelectors";
import moment from "moment";
import { selectUsersWithSharedBranch } from "./UsersWithSharedBranchSelector";
import _ from "lodash";
import { SDateFormat } from "../shared/helpers/SimpleTime";
import { selectUserMap, selectTrackingMap } from "./mapSelectors";
import { IUser, RoleType } from "../shared/entities/IUser";
import { ITimeClocking } from "../shared/entities/ITimeClocking";
import { Map } from "../shared/types/general";
import { selectTimeClockSettingsByUser } from "./timeClockSettingsByUserSelector";
import { selectAbsencesByUser } from "./absencesByUserSelector";
import { selectSessionInfo } from "./SessionInfoSelector";

export const SelectClockingStatus = createSelector(
  (s) => selectActiveUsers(s, toSimpleDate(moment())),
  selectUsersWithSharedBranch,
  (s) => s.data.shifts,
  (s) => s.data.timeClockings,
  selectTrackingMap,
  (s) => s.data.branches,
  selectAbsencesByUser,
  selectTimeClockSettingsByUser,
  selectSessionInfo,
  (
    activeUsers,
    usersWithSameBranchIds,
    shifts,
    timeClockings,
    trackingsMap,
    branches,
    absencesByUser,
    timeClockSettingsByUser,
    sessionInfo
  ) => {
    const today = moment().format(SDateFormat);
    const now = toSimpleDateTime(moment());

    const branchIdsToClock = {};
    branches.forEach((b) => {
      if (b.isClockingEnabled) {
        branchIdsToClock[b.id] = true;
      }
    });

    const relevantUsersMap: Map<IUser> = {};
    activeUsers
      .filter((u) => sessionInfo.isAdmin() || usersWithSameBranchIds.includes(u.id))
      .forEach((u) => (relevantUsersMap[u.id] = u));

    const activeRelevantClockings = timeClockings.filter(
      (c) => !c.endTime && !c.isAutoClockOut && !trackingsMap[c.id] && !!relevantUsersMap[c.userId]
    );

    const isUserAbsent = (userId: string) => {
      const userAbsences = absencesByUser[userId] || [];
      return !!userAbsences.find((a) => a.startDate <= today && a.endDate >= today);
    };

    const currentShiftsToClock = shifts.filter((s) => {
      const startDateTime = `${s.date} ${s.startTime}`;
      const endDateTime = getEndDateTime(s);
      return now >= startDateTime && now <= endDateTime && !!s.userId && branchIdsToClock[s.branchId];
    });

    const relevantUnclockedShifts = currentShiftsToClock.filter((s) => {
      return (
        s.userId &&
        !activeRelevantClockings.find((aC) => aC.id === s.id) &&
        relevantUsersMap[s.userId] &&
        !trackingsMap[s.id] &&
        !isUserAbsent(s.userId!) &&
        timeClockSettingsByUser[s.userId].shiftsNeedToBeClocked
      );
    });

    const isCurrentlyInBreak = (activeClocking: ITimeClocking) => {
      return (
        activeClocking?.breakActivities?.length &&
        activeClocking?.breakActivities[activeClocking.breakActivities.length - 1].type === "start"
      );
    };

    const activeRelevantClockingsInBreak = activeRelevantClockings.filter(isCurrentlyInBreak);
    const activeRelevantClockingsNotInBreak = activeRelevantClockings.filter((a) => !isCurrentlyInBreak(a));

    return {
      activeClockingsInBreak: activeRelevantClockingsInBreak,
      activeClockings: activeRelevantClockingsNotInBreak,
      unclockedShifts: relevantUnclockedShifts,
    };
  }
);
