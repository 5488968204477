import { Button, Icon, Input, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { busyInjector, BusyInjectorProps } from "../../BusyInjector/BusyInjector";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { HintModal } from "../HintModal/HintModal";
import { useSelector } from "../../../helpers/redux";
import "./styles.scss";
import { Hinter, updateHinting } from "../../../actions/hinting";
import { closeModal, openModal } from "../../../actions/modal";
import absencePullingGifUrl from "./absencePulling.gif";
import firebase from "firebase/compat/app";
import TZModal from "../../TZModal/TZModal";
import { selectIsFreshTenant } from "../../../selectors/selectIsFreshTenant";
import { envi } from "../../../env";

export const LiveDemoOfferModal = () => {
  const dispatch = useDispatch();

  // const [showCustomInput, setShowCustomInput] = useState(false);
  const [showCalendly, setShowCalendly] = useState(false);

  useEffect(() => {
    if (showCalendly) {
      const head = document.querySelector("head");
      const script = document.createElement("script");
      script.setAttribute("src", "https://assets.calendly.com/assets/external/widget.js");
      head!.appendChild(script);
    }

    return () => {
      if (showCalendly) {
        const head = document.querySelector("head");
        const script = document.querySelector("script");
        head!.removeChild(script!);
      }
    };
  }, [showCalendly]);

  if (showCalendly) {
    return (
      <div className="calendlyWrapperPaper">
        <div className="cloeCalendlyModalButton" onClick={() => dispatch(closeModal())}>
          {lg.schließen}
        </div>
        <div id="schedule_form" style={{ width: 1000, height: 660, marginBottom: 24 }}>
          <div className="loadingCalendlyText">Wird geladen...</div>
          <div
            className="calendly-inline-widget"
            data-url={
              envi.isV2() ? "https://calendly.com/zeitguru/demo" : "https://calendly.com/aplano-team/kurze-einfuehrung"
            }
            style={{
              minWidth: "800px",
              height: "100%",
              zIndex: 1,
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <TZModal>
      <TZModal.Body style={{ maxWidth: 500 }}>
        <div className="liveDemoOfferModalMain">
          <div className="question">{lg.lust_auf_eine_live_demo_von_aplano}</div>
          <div className="desc">{lg.dabei_zeigen_wir_ihnen_per_screen_sharing_wie}</div>
          {/* <div>
            <Icon type="customer-service" theme="filled" />
          </div> */}
        </div>
        <TZModal.Footer>
          {/* <div className="slotsInfoExtraModalMain">{26 + " " + lg.termine_verfügbar}</div> */}
          <Button type="primary" children={lg.ja_gerne} onClick={() => setShowCalendly(true)} />
          <Button
            children={lg.nein_danke}
            onClick={() => {
              dispatch(closeModal());
            }}
          />
        </TZModal.Footer>
      </TZModal.Body>
    </TZModal>
  );
};
