import { decryptUser } from "./userHelpers";
import { IUser, IUserFull } from "./../entities/IUser";
import { RoleType } from "../entities/IUser";
import { SDateFormat } from "./SimpleTime";
import moment from "moment";
import { Moment } from "moment";

/**
 * lifecycle of an account:
 */
export enum AccountStatus {
  /**
   * account has been created by the admin but has not yet been
   * activated (via registration email) by the account of the accountBase.
   */
  passive = "passive",
  /**
   * the account has been activated and can be used to login
   */
  active = "active",
  /**
   * the 'lastWorkDay' has been exceeded
   */
  deactivated = "deactivated",
}

export class UserInfo {
  public user: IUserFull;

  constructor(private _user: IUser) {
    this.user = decryptUser(this._user);
  }

  hasManagerPermissions(): boolean {
    return this.user.role === RoleType.manager || this.user.role === RoleType.admin;
  }
  isAdmin(): boolean {
    return this.user.role === RoleType.admin;
  }
  isManager(): boolean {
    return this.user.role === RoleType.manager;
  }
  isEmployee(): boolean {
    return this.user.role === RoleType.employee;
  }
  isDeactivated(_date: string | Moment): boolean {
    const date = typeof _date === "string" ? _date : (_date as Moment).format(SDateFormat);

    return !!(this.user.isDeleted || (this.user.lastWorkDay && date > this.user.lastWorkDay));
  }
  isActive(date: string | Moment): boolean {
    return this.getAccountStatus(date) === AccountStatus.active;
  }

  getAccountStatus(date: string | Moment = moment()): AccountStatus {
    if (this.isDeactivated(date)) {
      return AccountStatus.deactivated;
    }

    return this.user.accountId ? AccountStatus.active : AccountStatus.passive;
  }

  isManagingBranch(branchId: string): boolean {
    return (
      (this.user.role === RoleType.admin || this.user.role === RoleType.manager) &&
      this.user.branchIds.includes(branchId)
    );
  }
  canManageBranch(branchId: string): boolean {
    return (
      this.user.role === RoleType.admin ||
      (this.user.role === RoleType.manager && this.user.branchIds.includes(branchId))
    );
  }
}
