import React, { useState, useEffect, useRef } from "react";
import Holidays from "date-holidays";
import { useDispatch } from "react-redux";
import "./styles.scss";
import { notification } from "antd";
import JobPositionsStep from "./JobPositionsStep";
import RegionStep from "./RegionStep";
import moment from "moment";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import { IJobPosition } from "../../shared/entities/IJobPosition";
import { endSetupWizard } from "../../reducers/ui/setupWizard";
import { authRepository } from "../../repositories/authRepository";
import { IHoliday } from "../../shared/entities/IHoliday";
import { branchRepository } from "../../repositories/branchRepository";
import { asyncSleep } from "../../helpers/general";
import { useSelector } from "../../helpers/redux";
import { Analytics } from "../../helpers/analytics";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import firebase from "firebase/compat/app";
import { UsersStep } from "./UsersStep";
import { IUserSetup } from "../../shared/entities/IUser";
import { SDateFormat } from "../../shared/helpers/SimpleTime";
import { environment } from "../../env";
import { getLocaleLang } from "../../helpers/dateFormatHelper";
import { ICountrySelectorFormValues } from "../../components/CountrySeclector/CountrySelector";
import * as Sentry from "@sentry/browser";
import { LoopProtector } from "../../frontend-core/LoopProtector";
import { tenantInfoRepository } from "../../repositories/tenantInfoRepository";

type State = {
  currentStep: number;
  noDemoSeeding: boolean;
  jobPositions: IJobPosition[];
  users: IUserSetup[];
};

export const SetupWizard = () => {
  const [region, setRegion] = useState<ICountrySelectorFormValues | undefined>(undefined);
  const [state, setState] = useState<State>({
    noDemoSeeding: false,
    currentStep: 1,
    users: [],
    jobPositions: [],
  });

  const [isV2, setIsV2] = useState<Boolean | undefined>(undefined);

  // const branches = useSelector((s) => s.data.branches);
  // const users = useSelector((s) => s.data.users);
  const session = useSelector((s) => s.data.auth.session);
  const dispatch = useDispatch<DispFn>();

  const currency = useRef("EUR");

  useEffect(() => {
    onMount();
  }, []);

  const onMount = async () => {
    if (session) {
      try {
        const tenantInfo = await dispatch(tenantInfoRepository.fetch());
        setIsV2(!!tenantInfo.isV2);

        const branches = await dispatch(branchRepository.fetchMany());

        if (branches.length) {
          dispatch(endSetupWizard());
        } else {
          if (!!(window as any).fbq) {
            (window as any).fbq("track", "StartTrial", { currency: "EUR", value: 5, external_id: session.tenantId });
          }
          if (Analytics.hasBeenInitialized) {
            Analytics.tagManager.dataLayer({ dataLayer: { event: "registrationFirstStep" } });
          }
        }

        if (getLocaleLang() === "en") {
          // we only determine the currency if it is
          currency.current = "EUR";
        }

        if (tenantInfo.isV2) {
          setJobPositions([{ name: "Mitarbeiter", color: "#52c41a", sortIndex: 0, id: "p1" }]);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      dispatch(endSetupWizard());
    }
  };

  const changeStep = (next: number) => {
    console.log("set step ", next);
    setState({ ...state, currentStep: next });
  };

  const setJobPositions = (jobPositions: IJobPosition[]) => {
    setState({ ...state, jobPositions });
  };

  const setUsers = (users: IUserSetup[]) => {
    setState({ ...state, users });
  };

  const getHolidays = (): IHoliday[] => {
    const nextYears = [moment().year(), moment().year() + 1];
    return _.flatten(nextYears.map((year) => new Holidays(region!.country, region!.state!).getHolidays(year)))
      .filter((h) => h.type === "public")
      .map((h) => ({
        id: uuid(),
        date: moment(h.date).format(SDateFormat),
        name: h.name,
        isLegalHoliday: true,
      }));
  };

  const startLoadingHints = async () => {
    await asyncSleep(1500);
    notification.success({ message: lg.benutzerkonto_aktiviert });
    await asyncSleep(1200);
    notification.success({ message: lg.demodaten_generiert });
  };

  const finalize = async () => {
    startLoadingHints();

    await dispatch(
      authRepository.seedTenant({
        branch: { id: "b1", name: lg.hauptstandort },
        jobPositions: state.jobPositions.filter((j) => !!j.name),
        users: state.users.filter((u) => !!u.name),
        holidays: getHolidays(),
        country: region!.country,
        state: region!.state!,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        language: getLocaleLang(),
        currency: currency.current as any,
      })
    );

    try {
      if (!!(window as any).fbq) {
        const external_id = session?.tenantId;
        (window as any).fbq("track", "CompleteRegistration", { currency: "EUR", value: 10, external_id });
      }
      if (Analytics.hasBeenInitialized) {
        Analytics.tagManager.dataLayer({ dataLayer: { event: "finishSetupWizard" } });
      }
    } catch (e) {
      Sentry.addBreadcrumb({ data: { whatHapened: "Facebook or Analytics tracking failed" } });
      Sentry.captureException(e);
    }

    // Just for marketing purposes
    LoopProtector.check(dispatch);

    dispatch(endSetupWizard());
  };

  const { currentStep } = state;

  if (isV2 === undefined) {
    return null;
  }

  return (
    <>
      <div className="setupWizardPage">
        {/* linkedin conversion tracking: Started Setup Wizard */}
        {/* {environment.isProd() && (
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            alt=""
            src="https://px.ads.linkedin.com/collect/?pid=3229657&conversionId=4279673&fmt=gif"
          />
        )} */}
        <div className="leftItemWizardPage">
          <div className="imageWrapper"></div>
        </div>
        <div className="rowItemWizardPage">
          {currentStep === 1 && (
            <RegionStep changeStep={changeStep} formValues={region} setRegionStepResult={setRegion} isV2={!!isV2} />
          )}
          {currentStep === 2 && (
            <JobPositionsStep
              setJobPositions={setJobPositions}
              jobPositions={state.jobPositions}
              changeStep={changeStep}
            />
          )}
          {currentStep === 3 && (
            <UsersStep
              setUsers={setUsers}
              jobPositions={state.jobPositions}
              users={state.users}
              changeStep={changeStep}
              finalize={finalize}
              isV2={!!isV2}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SetupWizard;
