import { shiftLogRepository } from "./../../repositories/ShiftLogRepository";
import { firebaseListenerRepo } from "./../../repositories/firebaseListenerRepo";
import { maintenanceRepo } from "../../repositories/maintenanceRepo";
import { creditBalanceRepository } from "../../repositories/creditBalanceRepository";
import { AuthRepository } from "../../repositories/authRepository";
import { creditIntervalRepository } from "./../../repositories/creditIntervalRepository";
import { rosterSettingsRepository } from "./../../repositories/rosterSettingsRepository";
import { branchRepository } from "./../../repositories/branchRepository";
import { jobPositionRepository } from "./../../repositories/jobPositionRepository";
import { combineReducers } from "redux";
import { userRepository } from "../../repositories/userRepository";
import { shiftRepository } from "../../repositories/shiftRepository";
import { absenceRepository } from "../../repositories/absenceRepository";
import { absenceEntitlementRepository } from "../../repositories/absenceEntitlementRepository";
import { contingentCorrectionRepository } from "../../repositories/contingentCorrectionRepository";
import { shiftHandOverRequestRepository } from "../../repositories/shiftHandOverRequestRepository";
import { creditCorrectionRepository } from "../../repositories/creditCorrectionRepository";
import { dailyNoteRepository } from "../../repositories/dailyNoteRepository";
import { contractRepository } from "../../repositories/contractRepository";
import { availabilityRepository } from "../../repositories/availabilityRepository";
import { rosterTemplateRepository } from "../../repositories/rosterTemplateRepository";
import { holidayRepository } from "../../repositories/holidaysRepository";
import { creditRepository } from "../../repositories/creditRepository";
import { absenceTypeRepository } from "../../repositories/absenceTypeRepository";
import { trackingRepository } from "../../repositories/TrackingRepository";
import { publishedWeekRepository } from "../../repositories/publishedWeekRepository";
import { workSpaceRepository } from "../../repositories/workSpaceRepository";
import { timeClockingRepository } from "../../repositories/timeClockingRepository";
import { timeClockSettingsRepository } from "../../repositories/timeClockSettingsRepository";
import { changeRequestRepository } from "../../repositories/ChangeRequestRepository";
import { notificationSettingsRepository } from "../../repositories/notificationSettingsRepository";
import { shiftRepeatRepository } from "../../repositories/shiftRepeatRepository";
import { tenantInfoRepository } from "../../repositories/tenantInfoRepository";
import { userDetailRepository } from "../../repositories/userDetailRepository";
import _ from "lodash";
import { shiftAddressRepository } from "../../repositories/shiftAddressRepository";
import { messageRepository } from "../../repositories/messageRepository";
import { threadInfoRepository } from "../../repositories/threadInfoRepository";
import { userThreadInfoRepository } from "../../repositories/userThreadInfoRepository";
import { pushNoteRepository } from "../../repositories/pushNoteRepository";
import { uploadRepository } from "../../repositories/uploadRepository";
import { RepoKey } from "../../shared/types/RepoKeys";
import { announcementRepository } from "../../repositories/announcementRepository";
import { surchargeRepository } from "../../repositories/surchargeRepository";

export const crudRepos = {
  absences: absenceRepository,
  contracts: contractRepository,
  _contingentCorrections: contingentCorrectionRepository, // lodash-prefixed repos should not be referenced directly from store > use the selector
  users: userRepository,
  shifts: shiftRepository,
  shiftLogs: shiftLogRepository,
  jobPositions: jobPositionRepository,
  _absenceEntitlements: absenceEntitlementRepository, // lodash-prefixed repos should not be referenced directly from store > use the selector
  branches: branchRepository,
  shiftHandOverRequests: shiftHandOverRequestRepository,
  creditCorrections: creditCorrectionRepository,
  dailyNotes: dailyNoteRepository,
  _availabilities: availabilityRepository, // lodash-prefixed repos should not be referenced directly from store > use the selector
  rosterTemplates: rosterTemplateRepository,
  holidays: holidayRepository,
  absenceTypes: absenceTypeRepository,
  notificationSettings: notificationSettingsRepository,
  trackings: trackingRepository,
  changeRequests: changeRequestRepository,
  publishedWeeks: publishedWeekRepository,
  rosterSettings: rosterSettingsRepository,
  _workSpaces: workSpaceRepository, // lodash-prefixed repos should not be referenced directly from store > use the selector
  timeClockings: timeClockingRepository,
  timeClockSettings: timeClockSettingsRepository,
  shiftRepeats: shiftRepeatRepository,
  userDetails: userDetailRepository,
  shiftAddresses: shiftAddressRepository,
  pushNotes: pushNoteRepository,
  uploads: uploadRepository,
  surcharges: surchargeRepository,
  announcements: announcementRepository,
};

const nonCrudRepos = {
  auth: new AuthRepository(),
  credits: creditRepository,
  creditBalances: creditBalanceRepository,
  creditIntervals: creditIntervalRepository,
  firebaseListeners: firebaseListenerRepo,
  maintenance: maintenanceRepo,
  tenantInfo: tenantInfoRepository,
  messages: messageRepository,
  threadInfos: threadInfoRepository,
  _userThreadInfos: userThreadInfoRepository, // lodash-prefixed repos should not be referenced directly from store > use the selector
};

export const repositoryMap = {
  ...crudRepos,
  ...nonCrudRepos,
};

export const getRepoByRepoKey = (repoKey: RepoKey) => {
  const crudRepoList = Object.values(crudRepos);
  const repo = crudRepoList.find((repo) => repo.repoKey === repoKey);
  if (!repo) {
    throw new Error("@AV: No repo found by repoKey in Function: getRepoByRepoKey");
    // if the repo should not be part of the redux-data-reducer, manually add it to the crudRepoList
  }
  return repo;
};

type Repository = typeof repositoryMap;
type RepoReducers = {
  [K in keyof Repository]: ReturnType<Repository[K]["getReducer"]>;
};
const reducers = _.mapValues(repositoryMap, (repo) => repo.getReducer()) as RepoReducers;
export const dataReducer = combineReducers(reducers);
