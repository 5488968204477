import { v4 as uuid } from "uuid";
import { Dispatch } from "redux";
import { ModalProps } from "antd/lib/modal";

export type IModal = {
  id: string;
  props: any;
  component: React.ComponentType<any>;
  antModalProps?: ModalProps;
};

export const modalActionTypes = {
  OPEN: "@@AV_MODAL_OPEN",
  CLOSE: "@@AV_MODAL_CLOSE",
  REMOVE: "@@AV_MODAL_REMOVE",
  UPDATE: "@@AV_MODAL_UPDATE",
};

export const openModal =
  <C extends React.ComponentType<React.ComponentProps<C>>>(
    component: C,
    props: React.ComponentProps<C>,
    antModalProps?: ModalProps
  ) =>
  (dispatch: Dispatch) => {
    const modal: IModal = {
      id: uuid(),
      component,
      props,
      antModalProps,
    };

    return dispatch({
      type: modalActionTypes.OPEN,
      payload: modal,
    });
  };

// closes the Modal at the top of the stack
export const closeModal = (id?: string) => {
  return { type: modalActionTypes.CLOSE, payload: { id } };
};
