import React from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../../actions/modal";
import { useSelector } from "../../../helpers/redux";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { tierInfoSelector } from "../../../selectors/TierInfoSelector";
import "./styles.scss";

type Props = {};

// we are doing this, so stripe gets only injected when the user is going to the payment modal
const TierPicker = React.lazy(() => import("../../TierPicker/TierPicker") as any) as any;

export const TrialRemainingDaysInfoBox = React.memo((props: Props) => {
  const tierInfo = useSelector(tierInfoSelector);
  const sessionInfo = useSelector(selectSessionInfo);
  const dispatch = useDispatch();
  if (tierInfo.isAlwaysFreePremiumTenant) {
    return null;
  }
  if (!tierInfo.isTrialMode) {
    return null;
  }

  if (!tierInfo.isCore) {
    return null;
  }

  // After 7 days trial, we show this info box
  if (tierInfo.daysUntilTrialEnd > 7) {
    return null;
  }

  if (!sessionInfo.isAdmin()) {
    return null;
  }

  return (
    <div className="trialInfoBoxWrapper" onClick={() => dispatch(openModal(TierPicker, {}))}>
      <div className="trialRemainingDaysInfoBox">{lg.pro_noch_days_tage(tierInfo.daysUntilTrialEnd)}</div>
    </div>
  );
});
