import React from "react";
import { useSelector } from "../../helpers/redux";
import _ from "lodash";
import { IShift } from "../../shared/entities/IShift";
import { simpleDateToMoment } from "../../shared/helpers/timeHelpers";
import {
  getActiveTimeClocking,
  getRelevantShifts,
  getRelevantShiftsWithoutTrackingInBranch,
} from "../../shared/helpers/timeClockingHelpers";
import { IUser, IUserFull } from "../../shared/entities/IUser";
import { ITimeClocking } from "../../shared/entities/ITimeClocking";
import { useDispatch } from "react-redux";
import { startDynamicTimeClocking } from "../../actions/timeClocking";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import { selectJobPositionMap, selectTrackingMap } from "../../selectors/mapSelectors";
import { visibleShiftsSelector } from "../../selectors/publishedShiftsSelector";
import { paidFeatureWarning } from "../../actions/paidFeatureWarning";
import { featuresSelector } from "../../selectors/FeaturesSelector";
import { busyInjector, BusyInjectorProps } from "../../components/BusyInjector/BusyInjector";
import loaderGifUrl from "./loader.gif";
import moment from "moment";
import { SDateFormat } from "../../shared/helpers/SimpleTime";
type Props = {
  setSelectedShiftOrClockingId: (shiftId: string) => void;
  currentUser: IUser;
  clockableBranchIds: string[];
  isFreeClockingAllowed: boolean | undefined;
};
export const TimeClockShiftPicker = busyInjector(
  ({
    setSelectedShiftOrClockingId,
    clockableBranchIds,
    currentUser,
    isFreeClockingAllowed,
    ...props
  }: Props & BusyInjectorProps) => {
    const dispatch = useDispatch<DispFn>();
    const features = useSelector(featuresSelector);
    const branches = useSelector((s) => s.data.branches);
    const jobPositions = useSelector((s) => s.data.jobPositions);
    const jobPosMap = useSelector(selectJobPositionMap);
    const shifts = useSelector(visibleShiftsSelector);
    const trackings = useSelector((state) => state.data.trackings);
    const timeClockings = useSelector((state) => state.data.timeClockings);
    const timeClockStationMode = useSelector((state) => state.ui.timeClockMode);
    const trackingMap = useSelector(selectTrackingMap);
    const relevantShifts = getRelevantShifts(currentUser.id, shifts).filter((s) =>
      clockableBranchIds.includes(s.branchId)
    );
    const clockableShifts = getRelevantShiftsWithoutTrackingInBranch(
      currentUser.id,
      shifts,
      trackingMap,
      clockableBranchIds
    );

    const activeTimeClocking = getActiveTimeClocking(timeClockings, currentUser.id, trackingMap);

    const startDynamicClocking = async () => {
      if (props.isLoading("all")) {
        return;
      }

      if (!features.timeClock) {
        dispatch(paidFeatureWarning());
        return;
      }
      if (activeTimeClocking) {
        alert(lg.sie_müssen_sich_erst_von_ihrer_derzeitigen_schicht_ausstempeln);
        return;
      }
      props.setLoading("all", true);
      const newClocking = await dispatch(
        startDynamicTimeClocking({
          userId: currentUser.id,
          branchId: timeClockStationMode?.branchIds
            ? timeClockStationMode.branchIds.find((bId) => currentUser.branchIds.includes(bId))!
            : clockableBranchIds[0],
          jobPositionId: currentUser.jobPositionIds[0],
        })
      );
      setSelectedShiftOrClockingId(newClocking.id);
      props.setLoading("all", false);
    };

    return (
      <div
        style={{
          padding: 12,
          flexGrow: 0,
          flexShrink: 1,
          width: "100%",
        }}
      >
        {_.values(_.groupBy(relevantShifts, (s) => s.branchId)).map((shifts) => {
          const branch = branches.find((b) => shifts[0].branchId === b.id);
          return (
            <div key={branch?.id} style={{ flexGrow: 0, flexShrink: 1 }}>
              <div className="fb branchNameWrapper">
                <div className="fb branchNameText">{branch?.name}</div>
              </div>
              {shifts.map((shift) => {
                const jobPosition = jobPositions.find((jP) => jP.id === shift.jobPositionId);
                const tracking = trackings.find((t) => t.id === shift.id);
                const clocking = timeClockings.find((c) => c.id === shift.id) as ITimeClocking | undefined;
                const activeClocking = !tracking && !clocking?.endTime ? clocking : undefined;
                const clockingHasFinished = !!clocking?.endTime;

                return (
                  <div
                    key={shift.id}
                    onClick={() => {
                      if (!!tracking || clockingHasFinished) {
                        return;
                      }
                      setSelectedShiftOrClockingId(shift.id);
                    }}
                  >
                    <div className="fb shiftItemWrapper row">
                      <div className="fb shiftItem">
                        <div className="fb row grow" style={{ alignItems: "center", alignSelf: "stretch" }}>
                          {activeClocking && (
                            <div className="fb runningClockInShiftItem">
                              <img src={loaderGifUrl} style={{ width: 30, height: 30 }} />
                            </div>
                          )}
                          <div className="fb shiftItemDateWrapper">
                            <div className="fb shiftItemDateText">{simpleDateToMoment(shift.date).format("dddd")}</div>
                          </div>
                          <div className="fb shiftItemTimeText">
                            {shift.startTime}
                            {" - "}
                            {shift.endTime}
                          </div>

                          <div className="fb shiftItemRightTextWrapper">
                            <div
                              className="fb shiftItemJobPositionWrapper"
                              style={{
                                backgroundColor: jobPosition?.color,
                              }}
                            >
                              <div className="fb shiftItemJobPositionText">{jobPosition?.name}</div>
                            </div>
                          </div>
                        </div>

                        {(clockingHasFinished || tracking) && (
                          <div className="fb shiftItemTrackingRow">
                            {tracking && !clockingHasFinished ? (
                              <div className="fb shiftItemTrackingRowText">
                                {lg.zeiterfassung}: {tracking.startTime}
                                {" - "}
                                {tracking.endTime}
                                {" | "}
                                {tracking.breakMinutes} {lg.min_pause}
                              </div>
                            ) : (
                              <div className="fb shiftItemTrackingRowText">
                                {lg.gestempelt}: {clocking!.startTime}
                                {" - "}
                                {clocking!.endTime}
                                {" | "}
                                {tracking ? tracking.breakMinutes : clocking!.breakMinutes} {lg.min_pause}
                                {/*  We show here the Tracking BreakMinutes > So that users dont get upset, that there is no breakes deduced */}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
        {!!activeTimeClocking?.isDynamicClocking && (
          <div onClick={() => setSelectedShiftOrClockingId(activeTimeClocking.id)}>
            <div className="fb shiftItemWrapper row">
              <div className="fb shiftItem">
                <div className="fb row grow" style={{ alignItems: "center", alignSelf: "stretch" }}>
                  <div className="fb runningClockInShiftItem">
                    <img src={loaderGifUrl} style={{ width: 30, height: 30 }} />
                  </div>
                  <div className="fb shiftItemDateWrapper">
                    <div className="fb shiftItemDateText">
                      {simpleDateToMoment(activeTimeClocking.date).format("dddd")}
                    </div>
                  </div>
                  <div className="fb shiftItemTimeText">{activeTimeClocking.startTime}</div>

                  <div className="fb shiftItemRightTextWrapper">
                    <div
                      className="fb shiftItemJobPositionWrapper"
                      style={{
                        backgroundColor: jobPosMap[activeTimeClocking.jobPositionId]?.color,
                      }}
                    >
                      <div className="fb shiftItemJobPositionText">
                        {jobPosMap[activeTimeClocking.jobPositionId]?.name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!activeTimeClocking?.isDynamicClocking && isFreeClockingAllowed && !clockableShifts.length && (
          <div className="fb freeClockingButtonWrapper">
            <div className="fb freeClockingButton" onClick={startDynamicClocking}>
              <div className="fb freeClockingButtonText">{lg.stempeluhr_starten}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
);
