import React from "react";
import "./styles.scss";
import { Button, InputNumber, Popover } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import { value } from "firebase-bolt/lib/ast";

type Props = {
  min?: number;
  max?: number;
  value: number; //integer
  onChange: (value: number) => void;
  disabled?: boolean;
  size?: "small" | "large" | "default";
  stepSize?: number;
};

type State = {
  isInputMode: boolean;
  // inputNumber?: number;
};
export class AvStepper extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isInputMode: false,
    };
  }

  render() {
    const { min, max, value, onChange, size, stepSize } = this.props;
    const _stepSize = stepSize || 1;

    if (this.state.isInputMode) {
      return (
        <div className="popoverMain fb row">
          <div className="avStepperInputWrapper">
            <InputNumber
              autoFocus
              value={this.props.value || undefined}
              onChange={(newValue) => {
                Number.isInteger(parseInt(newValue + "")) ? this.props.onChange(newValue || 0) : this.props.onChange(0);
              }}
              style={{ borderBottomRightRadius: 0, borderTopRightRadius: 0, width: "100%" }}
            />
          </div>
          <Button
            onClick={() => this.setState({ isInputMode: false })}
            icon="check"
            type="primary"
            style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
          />
        </div>
      );
    }

    return (
      <div className="avStepperMain">
        <ButtonGroup style={{ display: "flex" }} size={size || "default"}>
          <Button
            id="stepper-minus"
            type="default"
            icon="minus"
            style={{ paddingTop: "1px", backgroundColor: "#f8f8f8" }}
            onClick={() => onChange(value - _stepSize)}
            disabled={min !== undefined && value <= min}
          ></Button>

          <div className="requiredUserAmount" onClick={() => this.setState({ isInputMode: true })}>
            {this.props.value}
          </div>

          <Button
            id="stepper-plus"
            type="default"
            icon="plus"
            style={{ paddingTop: "1px", backgroundColor: "#f8f8f8" }}
            onClick={() => onChange(value + _stepSize)}
            disabled={max !== undefined && value >= max}
          ></Button>
        </ButtonGroup>
      </div>
    );
  }
}
