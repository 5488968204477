import { createSelector } from "reselect";
import { selectSessionInfo } from "./SessionInfoSelector";

import moment from "moment";
import { publishedShiftsSelector } from "./publishedShiftsSelector";
import { toSimpleDate } from "../shared/helpers/timeHelpers";

export const selectShiftApplications = createSelector(
  [publishedShiftsSelector, selectSessionInfo],
  (shifts, sessionInfo) => {
    const today = toSimpleDate(moment());
    const canManage = sessionInfo.hasManagerPermissions();
    const isAdmin = sessionInfo.isAdmin();
    const { user } = sessionInfo;

    return shifts.filter((s) => {
      return (
        s.date >= today &&
        !s.userId &&
        s.appliedUserIds?.length &&
        (canManage ? isAdmin || user.branchIds.includes(s.branchId) : s.appliedUserIds.includes(user.id))
      );
    });
  }
);
