import { Button, Input, InputNumber, Popover } from "antd";
import React, { useEffect, useState } from "react";
import cn from "classnames";
import "./styles.scss";

type Props = {
  monthlyMaxHours?: number;
  updateMonthlyMaxHours: (h?: number) => void;
};

export const MinMaxHoursEditor = React.memo((props: Props) => {
  const [openPopover, setOpenPopover] = useState<"Min" | "Max" | null>(null);
  const [hoursInput, setHoursInput] = useState<number | undefined>();

  const save = () => {
    // openPopover === "Min" ? props.updateMonthlyMinHours(hoursInput) : props.updateMonthlyMaxHours(hoursInput);
    props.updateMonthlyMaxHours(hoursInput);
    setOpenPopover(null);
  };

  const renderPopover = () => {
    return (
      <div className="minMaxPopoverMain col fb">
        <div className="fb row"></div>
        <div className="fb row contentRow ">
          {openPopover && (
            <InputNumber
              autoFocus
              value={hoursInput}
              onChange={(hours) => setHoursInput(hours)}
              placeholder="0"
              onKeyDown={(e) => e.key === "Enter" && save()}
            />
          )}
          <div className="unit">{lg.stunden}</div>
          <Button type="primary" children={lg.fertig} onClick={save} />
        </div>
      </div>
    );
  };

  const generateElement = (key: "Min" | "Max", title: string) => {
    // const value = key === "Min" ? props.monthlyMinHours : props.monthlyMaxHours;
    const value = props.monthlyMaxHours;
    return (
      <Popover
        content={renderPopover()}
        title={<div style={{ color: "#1a90ff" }}>{title}</div>}
        trigger="click"
        visible={openPopover === key}
        onVisibleChange={(isOpen: boolean) => {
          setHoursInput(value);
          setOpenPopover(isOpen ? key : null);
        }}
        children={
          <div
            className={"minWrapper element " + cn({ editing: openPopover === key })}
            data-rh={openPopover === key ? null : title}
          >
            {key}
            {value && <div className="value">{`${value} h`}</div>}
          </div>
        }
      />
    );
  };
  return (
    <div className="minMaxHoursEditorMain">
      {/* {generateElement("Min", lg.monatliche_mindestarbeitszeit_eingeben)} */}
      {generateElement("Max", lg.monatliche_maximalarbeitszeit_eingeben)}
    </div>
  );
});
