import React from "react";

type Props = {
  className: string;
  onScroll: ({ scrollOffset: number }) => void;
  itemData: any;
  generateRow: any;
  itemKey: (index: number, data: any) => string;
};

// This List is NOT virtualized and should only be used in case there are not many list items
export const SimpleList = (props: Props) => {
  return (
    <div
      className={props.className}
      style={{ willChange: "transform" }}
      onScroll={(e: React.UIEvent<HTMLDivElement>) => props.onScroll({ scrollOffset: (e.target as any).scrollTop })}
    >
      {props.itemData.rows.map((row, index) => {
        return <props.generateRow index={index} data={props.itemData} key={props.itemKey(index, props.itemData)} />;
      })}
    </div>
  );
};
