import { rosterSettingsRepository } from "./../repositories/rosterSettingsRepository";
import { DispFn } from "./../frontend-core/types/thunkTypes";
import { DragAndDropHintModal } from "./../components/HintModals/DragAndDropHintModals/DragAndDropHintModal";
import { openModal } from "./modal";
import { Dispatch } from "redux";
import { AppState } from "../types/AppState";
import { IHinting } from "../shared/entities/IRosterSettings";
import { AbsenceCreateHintModal } from "../components/HintModals/AbsenceCreateHintModal.tsx/AbsenceCreateHintModal";
import { HowDidYouFindAplanoModal } from "../components/HintModals/HowDidYouFindAplanoModal/HowDidYouFindAplanoModal";
import { isMobile } from "mobile-device-detect";

/*
 * The Hinter is taking care of the user behaviour and displaying Hints in form of Modals and Notifications to improve the user experience
 */

export const updateHinting = (partialHinting: Partial<IHinting>) => (dispatch: DispFn, getState: () => AppState) => {
  const rosterSettings = getState().data.rosterSettings[0];
  const prevHinting = rosterSettings.hinting || {};
  const nextHinting = { ...prevHinting, ...partialHinting };
  const nextRosterSettings = { ...rosterSettings, hinting: nextHinting };
  dispatch(rosterSettingsRepository.update(nextRosterSettings));
};

export class Hinter {
  static closedShiftModalCount = 0;

  static closedShiftModal = () => (dispatch: DispFn, getState: () => AppState) => {
    Hinter.closedShiftModalCount++;

    const hinting = getState().data.rosterSettings[0].hinting || {};
    if (!hinting.hasDragAndDropped && Hinter.closedShiftModalCount >= 6 && Hinter.closedShiftModalCount <= 7) {
      dispatch(openModal(DragAndDropHintModal, {}, { closable: false }));
    }
  };

  static createdAbsence =
    (startDate: string, endDate?: string) => async (dispatch: DispFn, getState: () => AppState) => {
      const hinting = getState().data.rosterSettings[0].hinting || {};
      const showHint = !hinting.hasCreatedAbsenceByPulling;
      await new Promise((resolve) => setTimeout(() => resolve(true), 600));
      showHint && dispatch(openModal(AbsenceCreateHintModal, {}, { maskClosable: false }));
      !hinting.hasCreatedAbsenceByPulling && dispatch(updateHinting({ hasCreatedAbsenceByPulling: true }));
    };

  static dragAndDropped = () => (dispatch: DispFn, getState: () => AppState) => {};

  static loadedBaseData = () => async (dispatch: DispFn, getState: () => AppState) => {
    const getHints = () => getState().data.rosterSettings[0].hinting || {}; // its a func to create a new ref in the set timeout
    const userId = getState().data.auth.session?.userId;
    const fiveMinutesMs = 300000;

    if (userId === "u1" && !getHints().hasSeen_howDidYouFindAplano && !isMobile) {
      setTimeout(() => {
        !getHints().hasSeen_howDidYouFindAplano &&
          dispatch(openModal(HowDidYouFindAplanoModal, {}, { closable: false, maskClosable: false }));
      }, fiveMinutesMs);
    }
  };
}
