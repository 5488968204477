import { AppState } from "./../types/AppState";
import { Dispatch } from "redux";

export const groupByJobPositionsActionTypes = {
  SET: "@@AV/GROUP_BY_JOB_POSITIONS_SET",
};

export const setGroupByJobPositions = (payload: boolean) => ({
  type: groupByJobPositionsActionTypes.SET,
  payload,
});
