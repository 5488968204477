import { DispFn } from "../frontend-core/types/thunkTypes";
import { shiftRepository } from "../repositories/shiftRepository";
import { chunk } from "lodash";
import { AppState } from "../types/AppState";
import { IShiftDB } from "../shared/entities/IShift";

export const runMobielBugMigration = () => async (dispatch: DispFn, getState: () => AppState) => {
  const shifts = await dispatch(
    shiftRepository.fetchMany({ filter: ["date", "between", ["2022-08-01", "2022-10-01"]] })
  );

  console.log(shifts.length);
  console.log(shifts);

  const shiftChunks = chunk(shifts, 300);
  for (let _shiftChunk of shiftChunks) {
    await dispatch(shiftRepository.updateList(_shiftChunk));
    console.log("chunk updated");
  }

  console.log("DONE..");
};

export const runRepeatingShiftsMigration = () => async (dispatch: DispFn, getState: () => AppState) => {
  const shifts = (await dispatch(
    shiftRepository.fetchMany({ filter: ["date", "between", ["2022-09-01", "2023-07-31"]] }, true)
  )) as IShiftDB[];

  console.log(shifts.length);

  const wrongShifts = shifts.filter((shift) => shift.userId_date !== `${shift.userId}_${shift.date}`);
  console.log("---");
  console.log(wrongShifts.length);
  console.log(wrongShifts);

  const shiftChunks = chunk(wrongShifts, 300);
  for (let _shiftChunk of shiftChunks) {
    await dispatch(shiftRepository.updateList(_shiftChunk));
    console.log("chunk updated");
  }

  console.log("DONE..");
};
