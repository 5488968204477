import { BaseRepository } from "../frontend-core/BaseRepository";
import { ISurcharge } from "../shared/entities/ISurcharge";

class SurchargeRepository extends BaseRepository<ISurcharge> {
  constructor() {
    super("surcharges");
  }
}

export const surchargeRepository = new SurchargeRepository();
