import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../types/AppState";
import { Popover, Badge, Icon, Button } from "antd";
import "./styles.scss";

import { selectMonth, setSelectedYear } from "../../../../../actions/absence";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";

import moment from "moment";
import _ from "lodash";
import cn from "classnames";
import { openModal } from "../../../../../actions/modal";
import AbsenceModal from "../../../../../components/modals/AbsenceModal/AbsenceModal";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { selectUsersWithSharedBranch } from "../../../../../selectors/UsersWithSharedBranchSelector";
import { AbsenceStatus } from "../../../../../shared/entities/IAbsence";
import { SDateFormat } from "../../../../../shared/helpers/SimpleTime";
import { selectAbsencesExtended } from "../../../../../selectors/absencesExtendedSelector";
import { AbsenceTypeCode } from "../../../../../shared/entities/IAbsenceType";
import { getUserName } from "../../../../../shared/helpers/userHelpers";
import { VacationBansModal } from "../../../../../components/modals/VacationBansModal/VacationBansModal";
import { simpleDateToMoment } from "../../../../../shared/helpers/timeHelpers";
import { localizeFormat } from "../../../../../helpers/dateFormatHelper";
import { isRuleApplyingToUser } from "../../../../../shared/helpers/settingsHelpers";

const mapStateToProps = (state: AppState) => ({
  sessionInfo: selectSessionInfo(state),
  selectedYear: state.ui.absences.selectedYear,
  selectedMonth: state.ui.absences.selectedMonth,
  absenceTypes: state.data.absenceTypes,
  rosterSettings: state.data.rosterSettings[0],
});

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;
type State = {
  visible: boolean;
};

class VacationBansTrackerComp extends PureComponent<Props, State> {
  today: string;
  constructor(props: Props) {
    super(props);

    this.state = {
      visible: false,
    };
    this.today = moment().format(SDateFormat);
  }

  onVisibleChange = (visible: boolean) => {
    this.setState({ visible });
  };

  render() {
    const { absenceTypes, rosterSettings, sessionInfo } = this.props;
    const { managersCanManageAbsences } = rosterSettings;
    const canManageAbsences = sessionInfo.isAdmin() || (sessionInfo.isManager() && managersCanManageAbsences);

    let bans = _.orderBy(rosterSettings.vacationBans || [], (ban) => ban.from);
    bans = bans.filter((ban) => ban.until >= this.today);
    bans = canManageAbsences ? bans : bans.filter((b) => isRuleApplyingToUser(b.appliesTo, sessionInfo.user));

    if (!canManageAbsences && !bans.length) {
      return null;
    }

    const content = (
      <div
        className="vacationBansTrackerPopover"
        onClick={() => {
          this.onVisibleChange(false);
        }}
      >
        <div className="popoverContent">
          {canManageAbsences && (
            <div
              className="requestAbsenceButton"
              style={{ marginBottom: bans.length ? 4 : 0 }}
              onClick={() => {
                this.props.dispatch(openModal(VacationBansModal, {}));
              }}
            >
              {lg.neue_urlaubssperre}
            </div>
          )}
          {bans.map((ban, i) => {
            const startMoment = simpleDateToMoment(ban.from);
            const endMoment = simpleDateToMoment(ban.until);
            let totalDays = moment(endMoment).diff(startMoment, "days") + 1;

            const startDate = startMoment.format(localizeFormat("DD.MM.YY"));
            const endDate = endMoment.format(localizeFormat("DD.MM.YY"));
            const absenceType = ban.typeId ? absenceTypes.find((a) => a.id === ban.typeId) : undefined;

            return (
              <div key={i} className="row">
                <div
                  className={cn({ mainContent: true, clickable: canManageAbsences })}
                  onClick={() => {
                    canManageAbsences && this.props.dispatch(openModal(VacationBansModal, { entity: ban }));
                  }}
                >
                  <div className="mainRow">
                    <div className="userName">{absenceType?.name || lg.alle_typen}</div>
                  </div>
                  <div className="dates">
                    {/* <Icon type="caret-right" /> */}
                    {startDate + " - " + endDate}
                    <div className="daysCount">
                      {totalDays} {lg.Tage}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );

    return (
      <div className="vacationBanTrackerMain">
        <Popover
          content={content}
          placement="bottom"
          trigger="click"
          visible={this.state.visible}
          onVisibleChange={this.onVisibleChange}
        >
          <div id="vacation-requests" className="vacationBanButton">
            {lg.urlaubssperren}
            {!!bans.length && <div className="count">{bans.length}</div>}
          </div>
        </Popover>
      </div>
    );
  }
}

export const VacationBansTracker = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  VacationBansTrackerComp
);
