import { notification } from "antd";
import React from "react";
import { DispFn } from "../frontend-core/types/thunkTypes";
import { closeModal, openModal } from "./modal";

// we are doing this, so stripe gets only injected when the user is going to the payment modal
const PaymentDetailsModal = React.lazy(
  () => import("../components/PaymentDetailsModal/PaymentDetailsModal") as any
) as any;

export const openUpdatePaymentDetailsModal =
  (onComplete?: () => any) =>
  async (dispatch: DispFn): Promise<any> => {
    return dispatch(
      openModal(PaymentDetailsModal, {
        onUpdateComplete: async () => {
          if (onComplete) {
            onComplete();
          }
          notification.success({
            message: lg.zahlungsinformationen_aktualisiert,
          });
          dispatch(closeModal());
        },
        submitButtonText: lg.Speichern,
      })
    );
  };
