import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import _ from "lodash";
import { openModal } from "../../../actions/modal";
import { ShiftApplicationsPopup } from "../../../components/ShiftPopup/ShiftApplicationsPopup/ShiftApplicationsPopup";
import { EmptyContent } from "../../../components/EmptyContent/EmptyContent";
import { OpenShiftItem } from "../../../components/OpenShiftItem/OpenShiftItem";
import { Icon } from "antd";
import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import BusyWrapper from "../../../components/BusyWrapper/BusyWrapper";
import { selectShiftApplications } from "../../../selectors/ShiftApplicationsSelector";
import { IShift } from "../../../shared/entities/IShift";
import { ShiftPopup } from "../../../components/ShiftPopup/ShiftPopup/ShiftPopup";
import { HintPopover } from "../../../components/HintPopover/HintPopover";
import moment from "moment";
import { SDateFormat } from "../../../shared/helpers/SimpleTime";
import { CountToggler } from "../CountToggler/CountToggler";
import cn from "classnames";

const mapStateToProps = (state: AppState) => {
  return {
    sessionInfo: selectSessionInfo(state),
    currentUser: selectSessionInfo(state).user,
    shiftsWithApplications: selectShiftApplications(state),
  };
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;
type State = { isOpen: boolean };

class _ShiftApplicationsPaper extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isOpen: false };
  }

  openAdminModal = (shift: IShift) => {
    this.props.dispatch(openModal(ShiftApplicationsPopup, { shiftId: shift.id }));
  };

  openEmployModal = (shift: IShift) => {
    this.props.dispatch(openModal(ShiftPopup, { shift }));
  };

  render() {
    const { sessionInfo, shiftsWithApplications } = this.props;
    const in60Days = moment().add(60, "days").format(SDateFormat);
    const canManage = sessionInfo.hasManagerPermissions();
    const recentShifts = shiftsWithApplications.filter((s) => s.date < in60Days);
    const shiftsWithApplicationsSorted = _.sortBy(recentShifts, "date");
    const { isOpen } = this.state;

    if (!canManage && !shiftsWithApplications.length) {
      return null;
    }

    return (
      <div className="cell">
        <div className="content">
          <HintPopover
            hideHint={!sessionInfo.hasManagerPermissions()}
            placement="left"
            hint={lg.hier_kannst_du_die_bewerbungen_deiner_mitarbeiter_auf_offene_schichten_akzeptieren_oder_ablehnen}
          >
            <div className="dashboardPaper">
              <div className={cn({ isOpen }) + " title expandable"} onClick={() => this.setState({ isOpen: !isOpen })}>
                <Icon type="solution" style={{ marginRight: "12px", color: "#1a90ff" }} />
                {lg.schichtbewerbung}
                <CountToggler
                  count={shiftsWithApplicationsSorted.length}
                  isOpen={this.state.isOpen}
                  style={{ marginLeft: "auto" }}
                />
              </div>
              {isOpen && (
                <BusyWrapper isBusy={this.props.isLoading()} style={{ width: "100%" }}>
                  <div className="body">
                    <EmptyContent show={!shiftsWithApplicationsSorted.length} text={lg.keine_bewerbungen} />
                    {shiftsWithApplicationsSorted.map((application) => (
                      <OpenShiftItem
                        key={application.id}
                        appliedShift={application}
                        openModal={() =>
                          canManage ? this.openAdminModal(application) : this.openEmployModal(application)
                        }
                      />
                    ))}
                  </div>
                </BusyWrapper>
              )}
            </div>
          </HintPopover>
        </div>
      </div>
    );
  }
}

export const ShiftApplicationsPaper = connect(mapStateToProps)(busyInjector(_ShiftApplicationsPaper));
