import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../types/AppState";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { Popover, Button, Badge } from "antd";
import "./styles.scss";

import { openModal } from "../../../../../actions/modal";
import { ShiftApplicationsPopup } from "../../../../../components/ShiftPopup/ShiftApplicationsPopup/ShiftApplicationsPopup";
import { OpenShiftItem } from "../../../../../components/OpenShiftItem/OpenShiftItem";
import _ from "lodash";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { selectShiftApplications } from "../../../../../selectors/ShiftApplicationsSelector";
import { IShift } from "../../../../../shared/entities/IShift";

const mapStateToProps = (state: AppState) => {
  return {
    sessionInfo: selectSessionInfo(state),
    shiftsWithApplications: selectShiftApplications(state),
    selectedBranchId: state.ui.selectedBranch,
  };
};

type State = {
  popoverIsVisible: boolean;
};
type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

// This is a managers-only component
class ShiftApplicationsButton_ extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      popoverIsVisible: false,
    };
  }

  openModal = (shift: IShift) => {
    this.setState({ popoverIsVisible: false });
    this.props.dispatch(openModal(ShiftApplicationsPopup, { shiftId: shift.id }));
  };

  render() {
    const { shiftsWithApplications, selectedBranchId } = this.props;
    const shiftsInBranch = shiftsWithApplications.filter((s) => !selectedBranchId || s.branchId === selectedBranchId);

    const shiftInBranchSorted = _.sortBy(shiftsInBranch, "date");
    if (!shiftsInBranch.length) {
      return null;
    }
    return (
      <>
        <Popover
          trigger={"click"}
          placement="bottomRight"
          visible={this.state.popoverIsVisible}
          onVisibleChange={(v) => this.setState({ popoverIsVisible: v })}
          content={
            <div className="shiftApplicationsPopover fb column">
              <div className="head">{lg.schichtbewerbungen}</div>
              <div className="body">
                {shiftInBranchSorted.map((appliedShift) => (
                  <OpenShiftItem
                    key={appliedShift.id}
                    appliedShift={appliedShift}
                    border="1px solid #ededed"
                    openModal={() => this.openModal(appliedShift)}
                  />
                ))}
              </div>
              <div className="footer"></div>
            </div>
          }
        >
          <Badge count={shiftsWithApplications.length}>
            <Button
              id="shift-applications-button"
              icon="solution"
              style={{ marginLeft: 12 }}
              data-rh={lg.schichtbewerbungen}
              data-rh-at="bottom"
            />
          </Badge>
        </Popover>
      </>
    );
  }
}

export const ShiftApplicationsButton =
  connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(ShiftApplicationsButton_);
