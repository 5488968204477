import { printModeActionTypes } from "../../actions/printMode";

export const printModeReducer = (state = false, action: any): boolean => {
  switch (action.type) {
    case printModeActionTypes.SET:
      return action.payload;
    default:
      return state;
  }
};
