import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";
import { Icon } from "antd";
import { paidFeatureWarning } from "../../../../actions/paidFeatureWarning";
import { IShift } from "../../../../shared/entities/IShift";
import { closeModal, openModal } from "../../../../actions/modal";
import AbsenceModal from "../../../modals/AbsenceModal/AbsenceModal";
import { date } from "yup";
import { featuresSelector } from "../../../../selectors/FeaturesSelector";
import { AvIcon } from "../../../AvIcon/AvIcon";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";

const mapStateToProps = (state: AppState) => ({
  isV2: state.data.tenantInfo.isV2,
  features: featuresSelector(state),
  sessionInfo: selectSessionInfo(state),
  managersCanManageAbsences: state.data.rosterSettings[0].managersCanManageAbsences,
});

export type ExpandableField = { key: ExpandableFieldKey; label: string; tooltip?: string; noPlus?: boolean };
export type ExpandableFieldKey = "workSpace" | "surcharge" | "comment" | "repeatOptions" | "address" | "hashtags";
export const expandableFields: ExpandableField[] = [
  {
    key: "comment",
    label: lg.kommentar,
  },
  {
    key: "workSpace",
    label: lg.label,
  },
  {
    key: "repeatOptions",
    label: lg.wiederholend,
  },
  {
    key: "address",
    label: lg.adresse,
  },
  {
    key: "hashtags",
    label: "#",
    tooltip: "Hashtags",
    noPlus: true,
  },
  {
    key: "surcharge",
    label: "%",
    tooltip: lg.aufschlag,
    noPlus: true,
  },
];

type OwnProps = {
  shift: Partial<IShift>;
  fields: ExpandableField[];
  expandField: (x: ExpandableFieldKey) => void;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _ExpandableShiftFields extends React.PureComponent<Props> {
  absenceClicked = () => {
    if (!this.props.features.absences) {
      return this.props.dispatch(paidFeatureWarning());
    }
    const { shift, dispatch } = this.props;
    const userId = shift.userId!;
    const startDate = shift.date;
    dispatch(closeModal());
    setTimeout(() => dispatch(openModal(AbsenceModal, { userId, startDate })), 100);
  };

  render() {
    const { sessionInfo, managersCanManageAbsences, isV2 } = this.props;
    const canManageAbsences = sessionInfo.isAdmin() || (sessionInfo.isManager() && managersCanManageAbsences);

    return (
      <div className="extraShiftFieldsRowMain avInputFieldMain" style={{ paddingTop: 0 }}>
        <div className="labelWrapper"></div>
        <div className="content">
          {this.props.shift.userId && canManageAbsences && !isV2 && (
            <div
              data-rh={lg.abwesenheit_eintragen}
              id={"expandableField_OpenAbsence"}
              className="fieldTag absenceTag"
              key="absence"
              onClick={this.absenceClicked}
            >
              <AvIcon type="icon-beach_access" />
            </div>
          )}
          {this.props.fields.map((field) => (
            <div
              id={"expandableField_" + field.key}
              data-rh={field.tooltip || null}
              className="fieldTag"
              key={field.key}
              onClick={() => this.props.expandField(field.key)}
            >
              {!field.noPlus && <Icon type="plus" />}
              <div className="text">{field.label}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export const ExpandableShiftFields = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(
  _ExpandableShiftFields
);
