import moment from "moment";

export const getYearSelectOptions = (start?: number, end?: number) => {
  const startYear = start || 2017;
  const maxYear = end || moment().year() + 2;
  const length = maxYear - startYear;
  return [...Array(length)].map((u, i) => i + startYear).map((year) => ({ value: year, label: year }));
};

export const getWeekSelectOptions = (year: number) => {
  const weeksInYear = moment().year(year).isoWeeksInYear();
  return [...Array(weeksInYear)].map((u, i) => i + 1).map((week) => ({ value: week, label: lg.kw + " " + week }));
};

export const getAllMonthsAsOptions = () => moment.months().map((month, index) => ({ value: index, label: month }));
