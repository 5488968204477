import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "../../helpers/redux";
import { Statistic, Row, Col, Button, Card, Switch } from "antd";
import { environment } from "../../env";
import { ITenantInfo } from "../../shared/entities/ITenantInfo";
import { simpleDateToMoment } from "../../shared/helpers/timeHelpers";
import { selectAdminTenantsData } from "../../selectors/selectAdminTenantsData";
import { AdminRegistrationsGraph } from "./graphs/AdminRegistrationsGraph";
import { AdminActiveTenantsGraph } from "./graphs/AdminActiveTenantsGraph";
import { isActiveTenant } from "./helpers";
import { IAdminTenantData } from "../../shared/entities/IAdminData";
import { Tiers } from "../../shared/constants/Tiers";

type Props = {};

export const AdminStatisticsPage = (props: Props) => {
  const adminData = useSelector((s) => s.adminData);
  const [excludeOldTenants, setExcludeOldTenants] = useState(true);
  const adminTenantsData = useSelector(selectAdminTenantsData);
  const tenants = useMemo(() => {
    const oneYearAgo = moment().add(-1, "year");
    if (!adminData) {
      return [];
    }
    if (excludeOldTenants) {
      return adminTenantsData.filter((t) => moment(t.createdAt).isAfter(oneYearAgo));
    } else {
      return adminTenantsData;
    }
  }, [adminData, excludeOldTenants]);
  if (!adminData) {
    return null;
  }

  const premiumAccounts = tenants.filter((t) =>
    t.activeStripeProductIds?.includes(environment.getPremiumTierProductId())
  );
  const lightAccounts = tenants.filter((t) => t.activeStripeProductIds?.includes(environment.getLightTierProductId()));

  let trackedTenantsFromMeta: IAdminTenantData[] = [];
  let trackedTenantsFromOther: IAdminTenantData[] = [];

  const metaUTMMatcher: string[] = ["fbclid"];
  const metaMarketingChannelMatcher: string[] = ["insta", "face"];
  for (let tenant of tenants) {
    const containtsMetaInUTM =
      !!tenant.utm && !!metaUTMMatcher.find((matcher) => tenant.utm?.toLowerCase().includes(matcher));
    const containtsMetaInMarketingChannel =
      !!tenant.marketingChannel &&
      !!metaMarketingChannelMatcher.find((matcher) => tenant.marketingChannel?.toLowerCase().includes(matcher));

    if (containtsMetaInUTM || containtsMetaInMarketingChannel) {
      trackedTenantsFromMeta.push(tenant);
    } else {
      if (tenant.utm || tenant.marketingChannel) {
        // not including all tenants!
        trackedTenantsFromOther.push(tenant);
      }
    }
  }

  return (
    <>
      <div style={{ paddingTop: "30px", overflowY: "auto", overflowX: "hidden" }}>
        <Row gutter={16}>
          <Col span={12}>
            <label style={{ marginRight: 12 }}>Nur Tenants der letzten 12 Monate</label>
            <Switch onChange={(c) => setExcludeOldTenants(c)} defaultChecked={excludeOldTenants} />
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 20 }}>
          <Col span={6}>
            <Card>
              <Statistic
                title="Tenants From Meta"
                value={trackedTenantsFromMeta.length}
                valueStyle={{ color: "#1a90ff" }}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Tenants From Meta Paid"
                value={trackedTenantsFromMeta.filter((t) => t.tier !== Tiers.Core).length}
                valueStyle={{ color: "#1a90ff" }}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Tenants From Other Than Meta"
                value={trackedTenantsFromOther.length}
                valueStyle={{ color: "#1a90ff" }}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Tenants From Other Than Meta Paid"
                value={trackedTenantsFromOther.filter((t) => t.tier !== Tiers.Core).length}
                valueStyle={{ color: "#1a90ff" }}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic title="Light Tenats" value={lightAccounts.length} valueStyle={{ color: "violet" }} />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Core Tenants (active ones only)"
                value={
                  tenants.filter((t) => t.activeStripeProductIds?.includes(environment.getCoreTierProductId())).length
                }
                valueStyle={{ color: "gray" }}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 20 }}>
          <Col span={6}>
            <Card>
              <Statistic
                title="Finished SetupWizard"
                value={tenants.filter((t) => t.finishedSetupWizard).length}
                valueStyle={{ color: "green" }}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Incomplete SetupWizard"
                value={tenants.filter((t) => !t.finishedSetupWizard).length}
                valueStyle={{ color: "red" }}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Finished SetupWizard Rate %"
                value={((tenants.filter((t) => t.finishedSetupWizard).length / tenants.length) * 100).toFixed(2)}
                valueStyle={{ color: "#1a90ff" }}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 20 }}>
          <Col span={24}>
            <Card>
              <div>
                <AdminRegistrationsGraph tenants={tenants} />
              </div>
            </Card>
          </Col>
        </Row>
        {/* <Row gutter={16} style={{ marginTop: 20 }}>
          <Col span={24}>
            <Card>
              <div>
                <AdminActiveTenantsGraph
                  tenants={tenants}
                  excludeOldTenants={excludeOldTenants}
                />
              </div>
            </Card>
          </Col>
        </Row> */}
      </div>
    </>
  );
};
