import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import "./styles.scss";
import { Button, Checkbox, Icon, Input, InputNumber, notification } from "antd";
import TZModal from "../../../../../components/TZModal/TZModal";
import { LocationSearchInput } from "../../../branches/branchPopup/LocationSearchInput";
import { IShiftAddress } from "../../../../../shared/entities/IShiftAddress";
import AvColorPicker from "../../../../../components/AvColorPicker/AvColorPicker";
import { pastelColors, strongColors } from "../../../../../frontend-core/helpers/colors";
import _ from "lodash";
import AvFormField from "../../../../../components/AvFormField/AvFormField";
import { closeModal } from "../../../../../actions/modal";
import { busyInjector, BusyInjectorProps } from "../../../../../components/BusyInjector/BusyInjector";
import { shiftAddressRepository } from "../../../../../repositories/shiftAddressRepository";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";
import { decimalSeparatorLocal } from "../../../../../helpers/dateFormatHelper";
import { ISurcharge } from "../../../../../shared/entities/ISurcharge";
import { InputProps } from "antd/lib/input";
import { v4 as uuid } from "uuid";
import { InputNumberProps } from "antd/lib/input-number";
import WeekDayPicker from "../../../../../components/modals/AvailabilityModal/weekDayPicker/WeekDayPicker";
import { WeekDay } from "../../../../../shared/constants/WeekDays";
import { BasicSelect } from "../../../../../components/BasicSelect/BasicSelect";
import AvTimeInput from "../../../../../components/AvTimeInput/AvTimeInput";
import { RosterSettings } from "../../RosterSettings";
import { dummyUserTypes } from "../../../../Users/UserDetailsModal/ContractEntry/UserTypeEditor/UserTypeEditor";
import { selectUsersFull } from "../../../../../selectors/usersFullSelector";
import { isUserActive } from "../../../../../selectors/ActiveUserSelectors";
import { toDoubleDigit, toSimpleDate, toTripleDigit } from "../../../../../shared/helpers/timeHelpers";
import cn from "classnames";
import moment from "moment";
import { SurchargeModalBasicTab } from "./SurchargeModalBasicTab";
import { SurchargeModalUsersTab } from "./SurchargeModalUsersTab";
import { SurchargeModalMiscTab } from "./SurchargeModalMiscTab";
import { surchargeRepository } from "../../../../../repositories/surchargeRepository";
import { useSelector } from "../../../../../helpers/redux";

type Props = {
  surcharge?: ISurcharge;
};

const getEmptySurcharge = (surcharges: ISurcharge[]): ISurcharge =>
  ({
    id: getNextSurchargeId(surcharges),
    name: "",
    shorthand: "",
    startTime: "00:00",
    endTime: "24:00",
    appliesTo: "all-users",
  } as ISurcharge);

export const getNextSurchargeId = (surcharges: ISurcharge[]): string => {
  const surchargeNrs = surcharges.map((u) => parseInt(u.id.split("sc")[1]) || 1); //  does this: 'sc32' -> 32
  const nextIndex = surchargeNrs.length ? _.max(surchargeNrs)! + 1 : 1;
  return `sc${toDoubleDigit(nextIndex)}`; // ids are incremental
};

// turnes {[key: string]: boolean} to ['key', 'key', 'key']
export const mapToArray = (map: { [key: string]: boolean }) =>
  Object.entries(map)
    .filter(([key, val]) => !!val)
    .map(([key, val]) => key);

// turnes ['key', 'key', 'key'] to {[key: string]: boolean}
export const arrayToMap = (arr: string[]) => arr.reduce((acc, ut) => ({ ...acc, [ut]: true }), {});

export const SurchargeModal = busyInjector((props: Props & BusyInjectorProps) => {
  const dispatch = useDispatch<DispFn>();
  const surcharges = useSelector((s) => s.data.surcharges);
  const [surcharge, setSurcharge] = useState<ISurcharge>({} as ISurcharge);
  const [cofirmDelete, setCofirmDelete] = useState(false);
  const [tab, setTab] = useState<"general" | "users" | "extended">("general");

  useEffect(() => {
    console.log(props.surcharge); // leave this for production debugging
    props.surcharge ? setSurcharge(props.surcharge) : setSurcharge(getEmptySurcharge(surcharges));
  }, []);

  const saveClicked = async () => {
    surcharge.startTime === surcharge.endTime && notification.warning({ message: "Von / Bis Uhrzeit ungültig" });

    const _surcharge = {
      ...surcharge,
      startTime: surcharge.startTime || "00:00",
      endTime: surcharge.endTime || "24:00",
      shorthand: surcharge.shorthand || surcharge.name.substr(0, 4),
      percentage: surcharge.percentage || 0,
    };

    const isCreating = !props.surcharge;

    isCreating ? dispatch(surchargeRepository.create(_surcharge)) : dispatch(surchargeRepository.update(_surcharge));

    dispatch(closeModal());
  };

  const delteClicked = () => {
    dispatch(surchargeRepository.remove(props.surcharge!.id));
    dispatch(closeModal());
  };

  const onChange = (next: Partial<ISurcharge>) => {
    setSurcharge({ ...surcharge, ...next });
  };

  const tabs = [
    { label: lg.general, key: "general" },
    { label: lg.mitarbeiter, key: "users" },
    { label: lg.erweitert, key: "extended" },
  ];

  return (
    <TZModal>
      <TZModal.Head>{lg.zuschlag}</TZModal.Head>
      <TZModal.Body>
        <div className="surchargeModalMainTabRow">
          {tabs.map((t) => (
            <div key={t.key} className={cn({ tab: 1, active: tab === t.key })} onClick={() => setTab(t.key as any)}>
              {t.label}
            </div>
          ))}
        </div>
        <div className="surchargeModalMain fb col">
          {tab === "general" && <SurchargeModalBasicTab surcharge={surcharge} onChange={onChange} />}
          {tab === "users" && <SurchargeModalUsersTab surcharge={surcharge} onChange={onChange} />}
          {tab === "extended" && <SurchargeModalMiscTab surcharge={surcharge} onChange={onChange} />}
        </div>
      </TZModal.Body>
      <TZModal.Footer>
        {props.surcharge && (
          <Button
            id="delete-surcharge"
            type={cofirmDelete ? "danger" : "dashed"}
            onClick={() => (cofirmDelete ? delteClicked() : setCofirmDelete(true))}
            data-rh={cofirmDelete ? lg.löschen : lg.wirklich_löschen}
            children={cofirmDelete ? lg.wirklich_löschen : <Icon type="delete" />}
            style={{ marginRight: "auto" }}
          />
        )}
        <Button
          id="save-surcharge"
          type="primary"
          onClick={saveClicked}
          loading={props.isLoading("submitting")}
          children={lg.Speichern}
          disabled={!surcharge.shorthand || !surcharge.name}
        />
      </TZModal.Footer>
    </TZModal>
  );
});
