import { IEnvCommon } from "./shared/types/IEnvCommon";

type AppEnv = "production" | "testing" | "development";

class Environment {
  getAppName = () => (process.env.REACT_APP_NAME as "Aplano" | "Zeitguru") || "Aplano";
  getAppEnv = () => process.env.REACT_APP_ENV as AppEnv;
  getNodeEnv = () => process.env.NODE_ENV;

  getEnv = () =>
    ({
      APP_VERSION: "v1",
      APP_ENVIRONMENT: this.getAppEnv(),
      NODE_ENV: this.getNodeEnv(),
    } as IEnvCommon);

  isDev = () => this.getAppEnv() !== "production" && this.getAppEnv() !== "testing";

  isProd = () => this.getAppEnv() === "production";
  isTesting = () => this.getAppEnv() === "testing";
  isBuild = () => this.getNodeEnv() === "production";

  isV2 = () => process.env.REACT_APP_NAME === "Zeitguru";

  isRunningLocally = () => window.location.host.includes("localhost");

  getCloudFunctionsHost = () => (this.isRunningLocally() ? "http://localhost:5002" : this.getHost());

  getHost = () => {
    if (this.isRunningLocally()) {
      return window.location.origin;
    }
    if (this.isDev()) {
      //return this.isV2() ? "https://zeitguru-dev.web.app" : "https://aplano-dev.web.app";
      return "https://aplano-dev.web.app";
    }
    if (this.isTesting()) {
      //return this.isV2() ? "https://testing.zeitguru.de" : "https://testing.aplano.de";
      return "https://testing.aplano.de";
    }
    if (this.isProd()) {
      //return this.isV2() ? "https://web.zeitguru.de" : "https://web.aplano.de";
      return "https://web.aplano.de";
    }
  };

  getFirebaseConfig = () => {
    // return {
    //   apiKey: "AIzaSyDQsVwOMwgeePjbwhztmh1c-th99E2pEOY",
    //   authDomain: "aplano-production.firebaseapp.com",
    //   databaseURL: "https://aplano-production.firebaseio.com",
    //   projectId: "aplano-production",
    //   storageBucket: "aplano-production.appspot.com",
    //   messagingSenderId: "502691546834",
    //   appId: "1:502691546834:web:78f6419aff0b203515cf0b",
    //   measurementId: "G-J6MJ0CCYMT",
    // };

    //TODO: Add Prod and Testing configs
    if (this.isProd()) {
      return {
        apiKey: "AIzaSyDQsVwOMwgeePjbwhztmh1c-th99E2pEOY",
        authDomain: "aplano-production.firebaseapp.com",
        databaseURL: "https://aplano-production.firebaseio.com",
        projectId: "aplano-production",
        storageBucket: "aplano-production.appspot.com",
        messagingSenderId: "502691546834",
        appId: "1:502691546834:web:78f6419aff0b203515cf0b",
        measurementId: "G-J6MJ0CCYMT",
      };
    }

    if (this.isTesting()) {
      return {
        apiKey: "AIzaSyDyGmnP8Wmw8zUggY-sHznzFQTm7dm-NMo",
        authDomain: "aplano-testing.firebaseapp.com",
        databaseURL: "https://aplano-testing.firebaseio.com",
        projectId: "aplano-testing",
        storageBucket: "aplano-testing.appspot.com",
        messagingSenderId: "971368211685",
        appId: "1:971368211685:web:d50195c889d1a7450c200c",
        measurementId: "G-RFWQ0QKTR6",
      };
    }

    // dev
    return {
      apiKey: "AIzaSyDXknr4MK5jmuh-5Nv9C-AkPGkA9Pf07-M",
      authDomain: "aplano-dev.firebaseapp.com",
      databaseURL: "https://aplano-dev.firebaseio.com",
      projectId: "aplano-dev",
      storageBucket: "aplano-dev.appspot.com",
      messagingSenderId: "588621123252",
      appId: "1:588621123252:web:cecce9b66e5e0112d8d1e1",
      measurementId: "G-P8E3255ZNG",
    };
  };

  getFirebaseEnv = () => {
    if (this.isProd()) {
      return "aplano-production";
    }
    if (this.isDev()) {
      return "aplano-dev";
    }
    if (this.isTesting()) {
      return "aplano-testing";
    }
  };

  // this price id is used to create a core subscription for all new tenants
  getStripeCorePriceId = (isV2?: boolean) =>
    isV2
      ? this.isProd()
        ? "price_1NzLZEA61DSaoFlSTY7UGMHs"
        : "price_1NqA3nA61DSaoFlSuS24Wg6B"
      : this.isProd()
      ? "price_1I43wmA61DSaoFlSDFeNT0wM"
      : "price_1I40GHA61DSaoFlST2neOJsi";

  getStripePublicApiKey = () =>
    this.isProd()
      ? "pk_live_51HJfNAA61DSaoFlSWF56iWvChfujtiFN5An8eo5qWSVAIjx0egqZ0M9WpnPU8OztM3DRUxrIvvtnoHLNEMKKejIc00okLErqYP"
      : "pk_test_51HJfNAA61DSaoFlSkR8ylrZDkw10xwRCnblw2n9FzXPGqg8YTiFDu13bq5h3CuVxc9gyM7vfCJFQ83VesGAG0tKe00IBoqJpqQ";

  getLightTierProductId = () => (this.isProd() ? "prod_IERgDg8VRtHdiM" : "prod_I82p3bF9JsBguF");
  getPremiumTierProductId = (isV2?: boolean) =>
    isV2
      ? this.isProd()
        ? "prod_OmvPzR6Vr1tt2J"
        : "prod_OdQv8ZE78OQTyB"
      : this.isProd()
      ? "prod_IERfFbAKEWv3OA"
      : "prod_I85OYS9LnGark8";
  getCoreTierProductId = (isV2?: boolean) =>
    isV2
      ? this.isProd()
        ? "prod_OmvQNKCGQUM4XN" // TODO: Add Production Price ID
        : "prod_OdQv38PWbMzku1"
      : this.isProd()
      ? "prod_IERfkYPBBEFT0X"
      : "prod_I9BwvGYdME2qML";

  getCurrentTaxRate = () => {
    // @see: https://www.bundesregierung.de/breg-de/aktuelles/faq-mehrwertsteuersenkung-1764364#:~:text=Die%20Mehrwertsteuer%20wird%20befristet%20vom,7%20Prozent%20auf%205%20Prozent.
    if (new Date().getFullYear() >= 2021) {
      return this.isProd() // Normal 19%
        ? "txr_1HdyoWA61DSaoFlS406BubdV"
        : "txr_1HdeBLA61DSaoFlShDfnT1zl";
    } else {
      return this.isProd() // Reduced 16 % (Corona)
        ? "txr_1HdyocA61DSaoFlSp6IBphkd"
        : "txr_1Hde7RA61DSaoFlSmhrih3JT";
    }
  };
}

export const environment = new Environment();
export const envi = new Environment();
