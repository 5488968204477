import { environment } from "./env";
import { createStore, compose, applyMiddleware, AnyAction } from "redux";
import thunk, { ThunkDispatch } from "redux-thunk";
import { connectRouter, routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers/rootReducer";
import { AppState } from "./types/AppState";

export const configureStore = (history) => {
  const connectRouterHistory = connectRouter(history);

  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    // Add other middleware on this line...
    thunk,
    reactRouterMiddleware,
  ];

  function configureStoreProd() {
    return createStore(
      createRootReducer(history),
      compose(
        applyMiddleware<ThunkDispatch<AppState, null, AnyAction>, AppState>(
          ...middlewares
        )
      )
    );
  }

  function configureStoreDev() {
    // add support for Redux dev tools
    const composeEnhancers = ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ||
      compose) as typeof compose;
    const store = createStore(
      createRootReducer(history),
      composeEnhancers(
        applyMiddleware<ThunkDispatch<AppState, null, any>, AppState>(...middlewares)
      )
    );

    if ((module as any).hot) {
      // Enable Webpack hot module replacement for reducers
      (module as any).hot.accept("./reducers/rootReducer", () => {
        const nextRootReducer = require("./reducers/rootReducer").default; // eslint-disable-line global-require
        store.replaceReducer((connectRouterHistory as any)(nextRootReducer(history)));
      });
    }

    return store;
  }

  return environment.isDev() ? configureStoreDev() : configureStoreProd();
};
