import { getShiftStartDateTime } from "./../shared/helpers/shiftHelpers";
import { toSimpleDateTime } from "./../shared/helpers/timeHelpers";
import { getShiftMiniUpdates } from "./../shared/helpers/creditSyncHelpers";
import firebase from "firebase/compat/app";
import "firebase/database";
import { selectShiftMap } from "./../selectors/shiftMapSelector";
import { shiftToDb } from "./../shared/helpers/dbTransforms";
import { CrudOperation } from "./../shared/helpers/firebaseHelpers";
import { DispFn } from "../frontend-core/types/thunkTypes";
import { BaseRepository } from "../frontend-core/BaseRepository";
import { IShift, IShiftDB } from "../shared/entities/IShift";
import { getShiftLogUpdates } from "./ShiftLogRepository";
import { AppState } from "./../types/AppState";
import { Dispatch } from "redux";
import moment from "moment";
import _ from "lodash";
import { isShiftInPublishedWeek } from "../helpers/general";
import { Operation } from "./../shared/helpers/firebaseHelpers";
import {
  getCalenderNeedsSyncUpdate,
  getSimpleDateToday,
  isUserActiveOnDate,
} from "../frontend-core/helpers/frontendHelpers";
import { selectSessionInfo } from "../selectors/SessionInfoSelector";
import { sendPost } from "../frontend-core/actions/send";
import { userRepository } from "./userRepository";

class ShiftRepository extends BaseRepository<IShift, IShiftDB> {
  constructor() {
    super("shifts");
  }

  addApplication(shift: IShift, userId: string) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      const tenantId = getState().data.auth.session!.tenantId;
      const ref = this.getBaseRef(tenantId);
      const appliedUserIds = _.uniq([...(shift.appliedUserIds || []), userId]);
      await ref.child(shift.id).child("appliedUserIds").set(appliedUserIds);
      this.fetchOne(shift.id); // to update store
    };
  }

  removeApplication(shift: IShift, userId: string) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      const tenantId = getState().data.auth.session!.tenantId;
      const ref = this.getBaseRef(tenantId);
      const appliedUserIds = (shift.appliedUserIds || []).filter((uid) => uid !== userId);
      await ref.child(shift.id).child("appliedUserIds").set(appliedUserIds);
      this.fetchOne(shift.id); // to update store
    };
  }

  editComment(shiftId: string, comment: string | undefined) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      const tenantId = getState().data.auth.session!.tenantId;
      const ref = this.getBaseRef(tenantId);
      await ref.child(shiftId).child("comment").set(comment);
      this.fetchOne(shiftId); // to update store
    };
  }

  preProcess = (shifts: IShift[]): IShift[] => {
    // just a safety to cleare out the noShow flag if a shift gets drag and dropped from the past to the future
    const nowDateTime = toSimpleDateTime(moment());
    // to make shure when moving a shift > to not have a noShow in a future shift
    return shifts
      .map((shift) => {
        const removeNoShow = shift.isNoShow && (getShiftStartDateTime(shift) >= nowDateTime || !shift.userId);
        return removeNoShow ? { ...shift, isNoShow: undefined } : shift;
      })
      .map((shift) => {
        return {
          ...shift,
          isRequirement: shift.userId ? undefined : shift.isRequirement,
          deassignedUserId: shift.userId ? undefined : shift.deassignedUserId,
        };
      });
  };

  toDbEntity = (s: IShift): IShiftDB => {
    return shiftToDb(s);
  };

  toLocalEntity = (s: IShiftDB): IShift => {
    return _.omit(s, ["branchId_date", "userId_date"]);
  };

  getExtraUpdates = (operations: CrudOperation<IShift>[]) => {
    return (dispatch: DispFn, getState: () => AppState) => {
      const shiftsById = selectShiftMap(getState());

      return {
        ...getShiftMiniUpdates(operations, shiftsById),
        ...getShiftLogUpdates(operations, getState()),
      };
    };
  };

  preUpdateHook(writes: CrudOperation<IShift>[]) {
    return async (dispatch: DispFn, getState: () => AppState) => {
      const iCalUsers = getState().data.users.filter((u) => u.hasICalSyncing);
      const iCalUsersMap = _.keyBy(iCalUsers, "id");
      if (!iCalUsers.length) {
        return;
      }

      const shiftMap = selectShiftMap(getState());
      const publishedWeeks = getState().data.publishedWeeks;
      const shifts = writes.map(
        (write) => (write.operation === Operation.remove ? shiftMap[write.entity.id] : write.entity) // neccesarry because in case fo remove the entitys are hollow  like this: ( { id: '1234' })
      );
      const today = getSimpleDateToday();
      const shiftsOfICalUsers = shifts.filter(
        (s) => s && s.userId && iCalUsersMap[s.userId] && isUserActiveOnDate(iCalUsersMap[s.userId], today)
      );
      const publishedShiftsOfICalUsers = shiftsOfICalUsers.filter((s) => isShiftInPublishedWeek(publishedWeeks, s));
      const affectedUserIds = _.uniq(publishedShiftsOfICalUsers.map((s) => s.userId));

      if (affectedUserIds.length) {
        const tenantId = getState().data.auth.session!.tenantId;
        const calendarUpdates = getCalenderNeedsSyncUpdate(tenantId, affectedUserIds);
        firebase.database().ref().update(calendarUpdates);
        // dispatch(sendPost("/api/sync-i-cal", {})); for testing only
      }
    };
  }
}

export const shiftRepository = new ShiftRepository();
