import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import moment, { Moment } from "moment";
import "./styles.scss";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { UserFilter } from "../../../../components/UserFilter/UserFilter";

import { isDayAHoliday } from "../../../../helpers/general";
import { toSimpleDate } from "../../../../shared/helpers/timeHelpers";
import { AbsenceUiFilter, setAbsenceCalenarFilter } from "../../../../reducers/ui/filters/absenceCalendarFilter";
import { AbsenceDayCell } from "./AbsenceDayCell/AbsenceDayCell";
import { AbsenceMonthCell } from "./AbsenceMonthCell/AbsenceMonthCell";
import { AbsenceUserCellMiniWidth, AbsenceUserCellWidth } from "../CalendarBody/CalendarBody";
import { AbsenceTypeCode } from "../../../../shared/entities/IAbsenceType";
import { Dropdown, Menu } from "antd";
import { setSelectedAbsenceType } from "../../../../actions/absence";

export const DAY_WIDTH = 32;

const mapStateToProps = (state: AppState) => ({
  month: state.ui.absences.selectedMonth,
  year: state.ui.absences.selectedYear,
  holidays: state.data.holidays,
  filters: state.ui.filters.absenceCalendar,
  isYearView: state.ui.absences.isYearView,
  absenceTypes: state.data.absenceTypes,
  selectedTypeId: state.ui.absences.selectedTypeId,
});

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class CalendarHead extends PureComponent<Props, {}> {
  getDaysArray = () => {
    const { year, month } = this.props;
    const daysInMonth = moment().year(year).month(month).daysInMonth();

    const monthArr: number[] = [];
    for (let i = 1; i <= daysInMonth; i++) {
      monthArr.push(i);
    }
    return monthArr;
  };

  updateFilter = (filter: Partial<AbsenceUiFilter>) => {
    this.props.dispatch(setAbsenceCalenarFilter(filter));
  };

  getMonthName = (m: Moment) => {
    return m.format("MMMM").substr(0, 3).toUpperCase();
  };

  getMonths = () => {
    const { year } = this.props;
    return [
      { month: this.getMonthName(moment().month(0)), daysInMonth: moment(`${year}-${1}`, "YYYY-MM").daysInMonth() },
      { month: this.getMonthName(moment().month(1)), daysInMonth: moment(`${year}-${2}`, "YYYY-MM").daysInMonth() },
      { month: this.getMonthName(moment().month(2)), daysInMonth: moment(`${year}-${3}`, "YYYY-MM").daysInMonth() },
      { month: this.getMonthName(moment().month(3)), daysInMonth: moment(`${year}-${4}`, "YYYY-MM").daysInMonth() },
      { month: this.getMonthName(moment().month(4)), daysInMonth: moment(`${year}-${5}`, "YYYY-MM").daysInMonth() },
      { month: this.getMonthName(moment().month(5)), daysInMonth: moment(`${year}-${6}`, "YYYY-MM").daysInMonth() },
      { month: this.getMonthName(moment().month(6)), daysInMonth: moment(`${year}-${7}`, "YYYY-MM").daysInMonth() },
      { month: this.getMonthName(moment().month(7)), daysInMonth: moment(`${year}-${8}`, "YYYY-MM").daysInMonth() },
      { month: this.getMonthName(moment().month(8)), daysInMonth: moment(`${year}-${9}`, "YYYY-MM").daysInMonth() },
      { month: this.getMonthName(moment().month(9)), daysInMonth: moment(`${year}-${10}`, "YYYY-MM").daysInMonth() },
      { month: this.getMonthName(moment().month(10)), daysInMonth: moment(`${year}-${11}`, "YYYY-MM").daysInMonth() },
      { month: this.getMonthName(moment().month(11)), daysInMonth: moment(`${year}-${12}`, "YYYY-MM").daysInMonth() },
    ];
  };

  render() {
    const { year, month, holidays, isYearView, absenceTypes, selectedTypeId } = this.props;
    const daysInMonth = moment().year(year).month(month).daysInMonth();
    // const showAbsenceTypeSelect = absenceTypes.some((a) => a.hasEntitlement && a.code !== AbsenceTypeCode.vacation);
    const vacationAbsenceType = absenceTypes.find((a) => a.code === AbsenceTypeCode.vacation);
    const selectedAbsenceType = absenceTypes.find((a) => a.id === selectedTypeId) || vacationAbsenceType;

    const menu = (
      <Menu>
        {absenceTypes
          // .filter((a) => a.hasEntitlement || a.code === AbsenceTypeCode.vacation)
          .map((a) => (
            <Menu.Item key={a.id} onClick={() => this.props.dispatch(setSelectedAbsenceType(a.id))}>
              {a.name}
            </Menu.Item>
          ))}
      </Menu>
    );

    return (
      <div className="absenceCalendarHeadMain">
        <div className="fb usersHead" style={{ width: isYearView ? AbsenceUserCellMiniWidth : AbsenceUserCellWidth }}>
          <UserFilter
            onSearchChange={(searchInput?: string) => this.updateFilter({ searchInput })}
            onBranchChange={(branchFilter?: string) => this.updateFilter({ branchFilter })}
            onPositionChange={(positionFilter?: string) => this.updateFilter({ positionFilter })}
            searchInput={this.props.filters.searchInput}
            branchFilter={this.props.filters.branchFilter}
            positionFilter={this.props.filters.positionFilter}
          />
          <Dropdown overlay={menu} trigger={["click"]}>
            <div className="absenceTypeSelectWrapper" data-rh={lg.summe_der_tage} data-rh-at="right">
              {selectedAbsenceType?.name}
            </div>
          </Dropdown>
        </div>
        {this.props.isYearView ? (
          <div className="yearHead">
            {this.getMonths().map((el) => {
              return <AbsenceMonthCell key={el.month} monthHead={el.month} width={el.daysInMonth} />;
            })}
          </div>
        ) : (
          <div className="monthHead _unselectable">
            {this.getDaysArray().map((d) => {
              const mom = moment().year(year).month(month).date(d);
              const holiday = holidays.find((h) => h.date === toSimpleDate(mom));
              return (
                <AbsenceDayCell
                  key={d}
                  width={DAY_WIDTH}
                  isHoliday={isDayAHoliday(toSimpleDate(mom), holidays)}
                  mom={mom}
                  daysInMonth={daysInMonth}
                  holiday={holiday ? holiday.name : null}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, {}, AppState>(mapStateToProps)(CalendarHead);
