import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../../types/AppState";

import moment from "moment";
import { Icon } from "antd";
import "./styles.scss";
import classNames from "classnames";

import { selectSessionInfo } from "../../../../../../selectors/SessionInfoSelector";
import { DispatchBaseProps } from "../../../../../../frontend-core/types/DispatchBaseProps";
import { simpleDateToMoment, toSimpleDate } from "../../../../../../shared/helpers/timeHelpers";
import { batch } from "react-redux";
import { setRosterMode, RosterMode } from "../../../../../../reducers/ui/shifts/roster/rosterMode";
import { selectDay } from "../../../../../../reducers/ui/shifts/roster/selectedDay";
import { RosterType, setRosterType } from "../../../../../../reducers/ui/shifts/roster/rosterType";
import { featuresSelector } from "../../../../../../selectors/FeaturesSelector";
import { paidFeatureWarning } from "../../../../../../actions/paidFeatureWarning";
import { selectDailyNotesByDate } from "../../../../../../selectors/dailyNotesByDateSelector";
import { openModal } from "../../../../../../actions/modal";
import { DailyNoteModal } from "../../../components/DailyNoteModal/DailyNoteModal";
import { selectHolidayFinder } from "../../../../../../selectors/holidayMapSelector";
import { localizeFormat } from "../../../../../../helpers/dateFormatHelper";

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  return {
    dailyNotesMap: selectDailyNotesByDate(state),
    holiday: selectHolidayFinder(state)(ownProps.date, state.ui.selectedBranch),
    sessionInfo: selectSessionInfo(state),
    selectedBranch: state.ui.selectedBranch,
    branches: state.data.branches,
    rosterMode: state.ui.shifts.roster.rosterMode,
    features: featuresSelector(state),
    isV2: state.data.tenantInfo.isV2,
  };
};

type OwnProps = {
  date: string;
  isTemplate?: boolean;
  isKanbanHeader?: boolean;
  isInFuture?: boolean;
};

type State = {
  popOverId: string | undefined;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _WeekdayHeader extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      popOverId: "",
    };
  }

  render() {
    const { isTemplate, date, dispatch, dailyNotesMap, isV2 } = this.props;
    const dateMoment = simpleDateToMoment(date);
    const holiday = this.props.holiday;
    const dailyNotes = dailyNotesMap[date] || [];

    const dayViewHandler = () => {
      batch(() => {
        this.props.dispatch(setRosterMode(RosterMode.Day));
        this.props.dispatch(setRosterType(RosterType.Grid));
        this.props.dispatch(selectDay(date));
      });
    };

    const onVisibleChange = (id?: string) => {
      this.setState({ popOverId: id });
    };

    const isFutureDisabled = this.props.isV2 && this.props.isInFuture;
    const jumpToDateDisabled = isFutureDisabled || isTemplate;

    return (
      <div
        key={date as string}
        className={classNames({
          rosterWeekDayHeaderMain: true,
          weekday: true,
          isHoliday: holiday,
          isTemplate,
          cell: true,
          hasNote: dailyNotes.length,
          aStart: true,
          popoverIsOpen: this.state.popOverId !== "",
          isNow: toSimpleDate(moment()) === date,
          isKanbanHeader: this.props.isKanbanHeader,
          isInFutureGrey: isFutureDisabled,
        })}
      >
        <div className="fb row aCenter jCenter dateWrapper">
          <div className="iconWrapper">
            {!!holiday && <Icon type="flag" className="holidayIcon" data-rh={holiday.name} data-rh-at="bottom" />}
          </div>
          <div
            className="dayText"
            onClick={() => (!jumpToDateDisabled ? dayViewHandler() : null)}
            style={{ cursor: !jumpToDateDisabled ? "pointer" : "default" }}
            data-rh={jumpToDateDisabled ? null : lg.zur_tagesansicht}
          >
            <span>{isTemplate ? dateMoment.format("dddd") : dateMoment.format("dd")}</span>
            {!isTemplate && dateMoment.format(localizeFormat("DD.MM"))}
          </div>
          <div>
            {this.props.sessionInfo.hasManagerPermissions() && !isV2 && (
              <div
                className="createDailyNoteIcon"
                data-rh={lg.tagesnotiz_hinzufügen}
                data-rh-at="top"
                onClick={() => {
                  dispatch(openModal(DailyNoteModal, { date }));
                }}
              >
                <Icon type="read" className="addNoteIcon" />
              </div>
            )}
          </div>
        </div>
        <div className="dailyNotesWrapper">
          {dailyNotes.map((dailyNote) => (
            <div
              key={dailyNote.id}
              className="note"
              data-rh={
                this.props.selectedBranch
                  ? dailyNote.text
                  : `${this.props.branches.find((b) => b.id === dailyNote.branchId)?.name}: ${dailyNote.text}`
              }
              data-rh-at="top"
              style={{ backgroundColor: dailyNote.color, marginTop: "2px" }}
              onClick={() => dispatch(openModal(DailyNoteModal, { date, dailyNote }))}
            >
              {dailyNote.text}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export const WeekdayHeader = connect(mapStateToProps)(_WeekdayHeader);
