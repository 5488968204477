import React from "react";
import { connect } from "react-redux";
import { Button, Input, Popover } from "antd";
import { AppState } from "../../../../../../types/AppState";
import { BasicWorkSpace } from "../../BranchPopup";
import { DispatchBaseProps } from "../../../../../../frontend-core/types/DispatchBaseProps";
import AvFormField from "../../../../../../components/AvFormField/AvFormField";
import ButtonGroup from "antd/lib/button/button-group";
import { IWorkSpace } from "../../../../../../shared/entities/IWorkSpace";
import AvColorPicker from "../../../../../../components/AvColorPicker/AvColorPicker";
import { closestWithAttribute } from "../../../../../../helpers/general";
import "./styles.scss";
import { pastelColors, strongColors } from "../../../../../../frontend-core/helpers/colors";
import { busyInjector, BusyInjectorProps } from "../../../../../../components/BusyInjector/BusyInjector";

const mapStateToProps = (state: AppState, props: OwnProps) => {
  return {};
};

type State = {
  confirmDelete: boolean;
};

type OwnProps = {
  isCreating?: boolean;
  workSpace: BasicWorkSpace;
  removeWorkSpace?: () => void;
  updateWorkSpace: (ws: IWorkSpace) => void;
  saveWorkSpace: () => void;
  unfocus?: () => void;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;
class WorkSpaceForm extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      confirmDelete: false,
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.clickDetected);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.clickDetected);
  }

  clickDetected = (e: any) => {
    const isInsideForm = !!closestWithAttribute(e.target, "data-type", "work-space-edit");
    const isInsidePage = !!closestWithAttribute(e.target, "data-component", "page");
    if (!isInsideForm && isInsidePage && this.props.unfocus) {
      this.props.unfocus();
    }
  };

  render() {
    const { workSpace } = this.props;

    return (
      <div className="workSpaceActionWrapper" data-type="work-space-edit">
        <div style={{ marginRight: 8 }}>
          <AvColorPicker
            miniVersion
            colors={[...pastelColors, ...strongColors]}
            value={workSpace.color}
            onChange={(color: string) => this.props.updateWorkSpace({ ...workSpace, color })}
          />
        </div>
        <div
          style={{
            marginRight: 8,
            display: "flex",
            flex: "1 1 auto",
          }}
        >
          <Input
            type="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              this.props.updateWorkSpace({ ...workSpace, name: e.target.value });
            }}
            value={workSpace.name}
            defaultValue={workSpace.name}
            placeholder={workSpace.name}
            autoFocus
          />
        </div>
        <Button
          style={{ marginRight: 8, minWidth: "32px", marginLeft: "auto" }}
          children={lg.Speichern}
          loading={this.props.isLoading()}
          type="primary"
          id="workspace-remove"
          onClick={this.props.saveWorkSpace}
          disabled={!workSpace.name}
        />
        {!this.props.isCreating && !this.state.confirmDelete && (
          <Button
            icon="delete"
            type="default"
            id="workspace-remove"
            onClick={() => {
              this.setState({ confirmDelete: true });
            }}
            style={{ width: "32px" }}
          />
        )}
        {this.state.confirmDelete && (
          <Button
            data-rh={lg.wirklich_löschen}
            icon="question"
            type="danger"
            id="workspace-remove"
            onClick={this.props.removeWorkSpace}
            style={{ width: "32px" }}
          />
        )}
      </div>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(busyInjector(WorkSpaceForm));
