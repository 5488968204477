import React, { PureComponent } from "react";
import TZModal from "../../TZModal/TZModal";
import { Input, Button, notification } from "antd";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { connect } from "react-redux";
import { authRepository } from "../../../repositories/authRepository";
import { sitemap } from "../../../helpers/sitemap";
import { closeModal } from "../../../actions/modal";
import { environment } from "../../../env";
import { isV2Domain } from "../../../frontend-core/helpers/frontendHelpers";
import "./styles.scss";

const mapStateToProps = (state: AppState) => ({});

type State = {
  email: string;
  isLoading: boolean;
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class PasswordForgottenModalComponent extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: "",
      isLoading: false,
    };
  }

  saveClicked = async () => {
    this.setState({ isLoading: true });

    // TODO: some check, so we dont get hacked, to send millions of mails
    const _email = (this.state.email || "").toLowerCase();
    const isV2 = isV2Domain();
    const host = "https://" + window.location.host;
    const redirectUrl = `${host}${sitemap.passwordReset.url}`;

    try {
      await this.props.dispatch(authRepository.sendPasswordReset(_email, redirectUrl, isV2));
      notification.success({
        message: lg.sie_haben_eine_email_mit_einem_link_zum_passwort_reset_erhalten,
      });
      this.props.dispatch(closeModal());
    } catch (e: any) {
      console.error(e);
      notification.error({ message: e.message });
    }

    this.setState({ isLoading: false });
  };

  isEmailInputValid = () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.state.email).toLowerCase());
  };

  render() {
    return (
      <TZModal>
        <TZModal.Head title={lg.passwort_vergessen}></TZModal.Head>
        <TZModal.Body>
          <div className="passwordForgottenModalMain">
            <div className="infoText">
              {lg.fordern_sie_eine_email_an_mit_einem_link_zum_setzen_eines_neuen_passworts}
            </div>
            <div className="inputWrapper">
              <Input
                type="text"
                onChange={(e) => this.setState({ email: e.target.value })}
                value={this.state.email}
                autoFocus
              />
            </div>
          </div>
        </TZModal.Body>
        <TZModal.Footer isLoading={this.state.isLoading}>
          <Button
            id="password-forgotten-request"
            type="primary"
            onClick={this.saveClicked}
            children={lg.email_anfordern}
            disabled={!this.isEmailInputValid()}
          />
        </TZModal.Footer>
      </TZModal>
    );
  }
}

export const PasswordForgottenModal = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  PasswordForgottenModalComponent
);
