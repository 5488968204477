import { IUserFull, RoleType } from "./../shared/entities/IUser";
import { decryptUser } from "./../shared/helpers/userHelpers";
import { UserInfo } from "../shared/helpers/UserInfo";

import { AppState } from "../types/AppState";
import { createSelector } from "reselect";
import { IUser } from "../shared/entities/IUser";
import { selectSessionInfo } from "./SessionInfoSelector";

export const canManageAbsencesSelector = createSelector(
  [selectSessionInfo, (state: AppState) => state.data.rosterSettings[0]],
  (sessionInfo, rosterSettings): boolean => {
    const managersCanManageAbsences = rosterSettings.managersCanManageAbsences;
    return sessionInfo.isAdmin() || (sessionInfo.isManager() && managersCanManageAbsences);
  }
);
