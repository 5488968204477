import { IRosterSettings, IUserRosterSettings } from "./../entities/IRosterSettings";
import { IRuleSpecification } from "./../entities/IRuleSpecification";
import { IUser } from "./../entities/IUser";
import { ITimeClockSettings, IUserTimeClockSettings } from "./../entities/ITimeClockSettings";
import _ from "lodash";

export const isRuleApplyingToUser = (rule: IRuleSpecification | undefined, user: IUser): boolean => {
  if (!rule) {
    return true; // if there is no ruleSpecification defined it applies to all users by default
  }

  if (rule.userIds) {
    return rule.userIds.includes(user.id);
  }

  if (rule.exceptUserIds && rule.exceptUserIds.includes(user.id)) {
    return false;
  }

  if (rule.jobPositionIds && !_.intersection(rule.jobPositionIds, user.jobPositionIds).length) {
    return false;
  }

  if (rule.branchIds && !_.intersection(rule.branchIds, user.branchIds).length) {
    return false;
  }

  if (rule.roleTypes) {
    return rule.roleTypes.includes(user.role);
  }

  return true;
};

// The UserTimeClockSetting is the TimeClockSetting that is valid for a specific user.
// The 'specifications' prop holds the information, if a rule is actually valid for a specific user.
// Using that information the props of the TimeClockSettings can be flipped to 'false'.
export const generateUserTimeClockSettings = (setting: ITimeClockSettings, user: IUser): IUserTimeClockSettings => {
  const userSetting = { ...setting, userId: user.id };
  if (!setting.specifications) {
    return userSetting;
  }
  const ruleKeys = Object.keys(setting.specifications);
  ruleKeys.forEach((ruleKey) => {
    if (!!setting[ruleKey] && !isRuleApplyingToUser(setting.specifications![ruleKey], user)) {
      userSetting[ruleKey] = false;
    }
  });

  return userSetting;
};

// For the RosterSettings we use the same logic as TimClockSettings > this is just to chunk the types in
export const generateUserRosterSettings = (settings: IRosterSettings, user: IUser): IUserRosterSettings => {
  return generateUserTimeClockSettings(settings as any, user) as any;
};
