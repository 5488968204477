import { Icon, Popover } from "antd";
import React from "react";
import "./styles.scss";

type Props = {
  selectAllBranches: () => void;
  deseletAllBranches: () => void;
  selectedBranchIds: string[];
};

export class PickAllBranchesBox extends React.PureComponent<Props, {}> {
  render() {
    const { selectedBranchIds, selectAllBranches, deseletAllBranches } = this.props;
    const hasAllSelected = selectedBranchIds.length >= 10; // after 10 selected we offer only to deselect all

    return (
      <div className="pickAllBranchesBoxMain" onClick={hasAllSelected ? deseletAllBranches : selectAllBranches}>
        {hasAllSelected ? lg.alle_deselektieren : lg.alle_selektieren}
      </div>
    );
  }
}
