import { IAbsence } from "../../../../../../shared/entities/IAbsence";
import { simpleDateToMoment } from "../../../../../../shared/helpers/timeHelpers";

const getFirstDay = (absence: IAbsence, monthStart: string): number => {
  const startsInPrevMonth = absence.startDate < monthStart;
  return startsInPrevMonth ? 1 : simpleDateToMoment(absence.startDate).date();
};

const getLastDay = (absence: IAbsence, monthEnd: string): number => {
  const endsInNextMonth = absence.endDate > monthEnd;
  const monthLength = simpleDateToMoment(monthEnd).daysInMonth();
  return endsInNextMonth ? monthLength : simpleDateToMoment(absence.endDate).date();
};

export const getPosLeft = (absence: IAbsence, monthStart: string): number => {
  const firstDay = getFirstDay(absence, monthStart);
  return firstDay - 1;
};

export const getPosWidth = (
  absence: IAbsence,
  monthStart: string,
  monthEnd: string
): number => {
  const firstDay = getFirstDay(absence, monthStart);
  const lastDay = getLastDay(absence, monthEnd);
  return lastDay - firstDay + 1;
};
