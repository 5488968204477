import React from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { Button, Icon, Tooltip } from "antd";

import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";

import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { busyInjector, BusyInjectorProps } from "../../../BusyInjector/BusyInjector";
import { approveHandover, rejectHandover, acceptHandoverRecieve } from "../../../../actions/handOverRequest";
import { isHandoverAcceptButtonDisabled } from "../helper";
import { selectActiveHandOverRequests } from "../../../../selectors/HandOverRequestSelector";
import { IShift } from "../../../../shared/entities/IShift";
import { IShiftHandOverRequest, HandoverType } from "../../../../shared/entities/IShiftHandOverRequest";
import { IUser } from "../../../../shared/entities/IUser";
import { getUserName } from "../../../../shared/helpers/userHelpers";
import { shiftHandOverRequestRepository } from "../../../../repositories/shiftHandOverRequestRepository";
import { selectUserMap } from "../../../../selectors/mapSelectors";

const mapStateToProps = (state: AppState) => {
  return {
    sessionInfo: selectSessionInfo(state),
    users: state.data.users,
    userMap: selectUserMap(state),
    jobPositions: state.data.jobPositions,
    currentUser: selectSessionInfo(state).user,
    handOverRequests: selectActiveHandOverRequests(state),
  };
};

type OwnProps = {
  shift: IShift;
  userId: string;
  disabled?: boolean;
  handOverRequest: IShiftHandOverRequest;
  onEditModeChange?: (isEditing: boolean) => void;
};

type State = {
  isLoading: boolean;
};

type StoreProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class _HandoverRequestSection extends React.PureComponent<Props, State> {
  user: IUser;

  constructor(props: Props) {
    super(props);
    this.user = props.users.find((u) => u.id === props.userId)!;
    this.state = {
      isLoading: false,
    };
  }

  generateShiftHandoverReceiver = () => {
    const { handOverRequest, userMap, jobPositions } = this.props;
    return handOverRequest.type === HandoverType.direct
      ? getUserName(userMap[handOverRequest.toUserId!])
      : jobPositions.find((jP) => jP.id === handOverRequest.jobPositionId)!.name;
  };

  isShiftTakeable = () => {
    const { handOverRequest, sessionInfo } = this.props;
    const isUnaccepted = !handOverRequest.approvedByUserId;
    const isGroupRequest = handOverRequest.type === HandoverType.group;
    const hasJobposId = sessionInfo.user.jobPositionIds.includes(handOverRequest.jobPositionId);
    return (
      handOverRequest.fromUserId !== sessionInfo.user.id &&
      ((hasJobposId && isGroupRequest) || handOverRequest.toUserId === sessionInfo.user.id) &&
      isUnaccepted
    );
  };

  takeBackAcceptance = () => {
    return this.props.dispatch(
      shiftHandOverRequestRepository.update({
        ...this.props.handOverRequest,
        approvedByUserId: undefined,
      })
    );
  };

  render() {
    const { handOverRequest, shift, sessionInfo, load, dispatch, isLoading, userMap } = this.props;
    const canManage = sessionInfo.canManageBranch(handOverRequest.branchId);
    const isOwnRequest = sessionInfo.user.id === handOverRequest.fromUserId;
    const isGroupRequest = handOverRequest.type === HandoverType.group;

    let infoMessage = "";
    !canManage && handOverRequest.approvedByUserId && (infoMessage = lg.manager_bestätigung_ausstehend);
    !handOverRequest.approvedByUserId && (infoMessage = lg.mitarbeiter_bestätigung_ausstehend);

    return (
      <>
        <div className="handoverRequestShiftPopupMain aCenter">
          <label className="shiftPopupLabelMain">{lg.schichtabgabe}</label>
          <div className="content">
            <div className="fb row aCenter grow">
              <div className="handoverLabel">{lg.an}</div>
              <div className="handoverReceiver">{this.generateShiftHandoverReceiver()}</div>
            </div>
            {isGroupRequest && handOverRequest.approvedByUserId && (
              <div className="fb row aCenter grow">
                <div className="handoverLabel">{lg.angenommen_von}</div>
                <div className="handoverAcceptor">{getUserName(userMap[handOverRequest.approvedByUserId!])}</div>
              </div>
            )}
          </div>
          {infoMessage && (
            <div className="pendingText">
              <Icon type="info-circle" data-rh={infoMessage} />
            </div>
          )}
          <div className="buttonsWrapper">
            {canManage && (
              <Button
                id="hand-over-request-section-approve"
                type="primary"
                icon="check"
                children={this.isShiftTakeable() ? lg.selbst_übernehmen : lg.bestätigen}
                style={{ marginLeft: "8px" }}
                disabled={isHandoverAcceptButtonDisabled(handOverRequest, sessionInfo)}
                onClick={async () => load(dispatch(approveHandover(handOverRequest, shift)), "approving")}
                loading={isLoading("approving")}
              />
            )}
            {!canManage && this.isShiftTakeable() && (
              <Button
                id="hand-over-request-section-accept"
                type="primary"
                icon="check"
                children={lg.schicht_übernehmen}
                style={{ marginLeft: "8px" }}
                onClick={() => load(dispatch(acceptHandoverRecieve(handOverRequest)), "accepting")}
                loading={isLoading("accepting")}
              />
            )}
            {(canManage || (handOverRequest.toUserId === sessionInfo.user.id && !handOverRequest.approvedByUserId)) && (
              <Button
                id="hand-over-request-section-reject"
                type="default"
                icon="close"
                data-rh={lg.antrag_ablehnen}
                style={{ marginLeft: "8px" }}
                onClick={() => load(dispatch(rejectHandover(handOverRequest)), "rejecting")}
                loading={isLoading("rejecting")}
              />
            )}
            {isOwnRequest && (
              <Button
                id="hand-over-request-section-delete"
                type="default"
                icon="delete"
                data-rh={lg.antrag_löschen}
                style={{ marginLeft: "8px" }}
                onClick={() => load(dispatch(shiftHandOverRequestRepository.remove(handOverRequest.id)), "rejecting")}
                loading={isLoading("rejecting")}
              />
            )}
            {handOverRequest.approvedByUserId === sessionInfo.user.id && (
              <Button
                id="hand-over-request-section-revert-accept"
                type="default"
                icon="close"
                data-rh={lg.annahme_aufheben}
                style={{ marginLeft: "8px" }}
                onClick={() => load(this.takeBackAcceptance(), "takeBackAccept")}
                loading={isLoading("takeBackAccept")}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

export const HandoverRequestSection = connect(mapStateToProps)(busyInjector(_HandoverRequestSection));
