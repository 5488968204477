import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { generateOverlappingTooltip } from "../../../../helpers/general";
import { getUnavailableDuringShift, generateAvailabilityText } from "../../../../helpers/workingTimeHelpers";
import { selectActiveUsers } from "../../../../selectors/ActiveUserSelectors";
import { selectShiftOverlappingsMap } from "../../../../selectors/shiftOverlappingsSelector";
import { IShift } from "../../../../shared/entities/IShift";
import { selectAvailabilites } from "../../../../selectors/availabilitiesSelector";
import { featuresSelector } from "../../../../selectors/FeaturesSelector";
import cn from "classnames";
import "./styles.scss";
import { selectLabourLawWarning } from "../../../../selectors/laborLawWarningSelector";

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const { userId, date } = ownProps.shift;
  const isUserActive = !userId || !!selectActiveUsers(state, date!).find((u) => u.id === userId);
  return {
    availabilities: selectAvailabilites(state),
    labourLawWarningMap: selectLabourLawWarning(state),
    branches: state.data.branches,
    overlapping: ownProps.shift.id && selectShiftOverlappingsMap(state)[ownProps.shift.id],
    isUserActive,
    features: featuresSelector(state),
  };
};

type OwnProps = {
  shift: Partial<IShift>;
};

type StoreProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StoreProps & DispatchBaseProps;

class _ShiftPopupWarningRow extends React.PureComponent<Props> {
  render() {
    const { overlapping, shift, availabilities, isUserActive, labourLawWarningMap } = this.props;

    if (!shift.id || !shift.userId) {
      return null;
    }

    const labourLawWarning = labourLawWarningMap?.[shift.id];

    const unavailablity = getUnavailableDuringShift(shift as IShift, availabilities);

    const warnings = [
      {
        type: "overlapping",
        text: overlapping && generateOverlappingTooltip(overlapping),
        isVisible: !!overlapping,
      },
      {
        type: "unavailable",
        text: unavailablity && generateAvailabilityText([unavailablity]),
        isVisible: !!unavailablity,
      },
      {
        type: "inactive",
        text: !isUserActive && lg.mitarbeiter_ist_deaktiviert,
        isVisible: !isUserActive,
      },
      {
        type: "labourLaw",
        text: labourLawWarning as string,
        isVisible: !!labourLawWarning,
      },
    ].filter((w) => w.isVisible);

    if (!warnings.length) {
      return null;
    }

    return (
      <div className="ShiftPopupWarningRow">
        {warnings.map((w, i) => (
          <div key={i} className={cn({ warningRow: true })}>
            <div className="text">{w.text}</div>
          </div>
        ))}
      </div>
    );
  }
}

export const ShiftPopupWarningRow = connect(mapStateToProps)(_ShiftPopupWarningRow);
