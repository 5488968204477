import {
  minutesToDuration,
  minutesToHoursStr,
  minutesToTTime,
  simpleDateToFormat,
  tTimetoMinutes,
} from "../../../../shared/helpers/timeHelpers";
import { selectAbsenceTypeMap } from "../../../../selectors/absenceTypeMapSelector";
import { ReportColumns, ReportFilterData, ReportType } from "../../../../actions/reporting";
import { selectAbsencesByUser } from "../../../../selectors/absencesByUserSelector";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import { AppState } from "../../../../types/AppState";
import { generateDatesList } from "../../../../shared/helpers/dateHelpers";
import { ReportEntry } from "./ShiftReport";
import {
  getValidContract,
  getQuotaOnDate,
  getShiftCredit,
  getClockingCreditV2,
} from "../../../../shared/helpers/credit";
import { getHolidayCredit, getAbsenceEarningOfDate } from "../../../../actions/creditActions/creditHelpers";
import { selectCreditCorrectionsByUserByDate } from "../../../../selectors/creditCorrectiosByUserByDateSelector";
import { selectContractsByUser } from "../../../../selectors/contractsByUserSelector";
import { selectHolidayFinder } from "../../../../selectors/holidayMapSelector";
import _, { reduce } from "lodash";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import { saveDataAs } from "../../../../helpers/export";
import { unparse, parse } from "papaparse";
import { decryptUser, getUserName } from "../../../../shared/helpers/userHelpers";
import jsPDF from "jspdf";
import autoTable, { CellDef } from "jspdf-autotable";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import {
  selectBranchMap,
  selectHashtagMap,
  selectJobPositionMap,
  selectShiftAddressMap,
  selectTimeClockingMap,
  selectTrackingMap,
  selectUserDetailMap,
  selectUserMap,
  selectWorkSpaceMap,
} from "../../../../selectors/mapSelectors";
import { IUser, RoleType } from "../../../../shared/entities/IUser";
import { AbsenceStatus } from "../../../../shared/entities/IAbsence";
import { ITracking } from "../../../../shared/entities/ITracking";
import { shiftRepository } from "../../../../repositories/shiftRepository";
import { trackingRepository } from "../../../../repositories/TrackingRepository";
import { reportFiltersReducer } from "../../../../reducers/ui/shifts/reporting/reportFilters";
import { getCreditIntervals } from "../../../../actions/creditActions/getCreditIntervals";
import { getBalances } from "../../../../actions/creditActions/getBalances";
import { ReportEntryType } from "../reportHelpers";
import { getWeekDayString } from "../../../../shared/helpers/weekDaysList";
import PDFMerger from "pdf-merger-js/browser";
import { TimeFormat } from "../../../../reducers/ui/reportSettings/reportTimeFormat";
import * as XLSX from "xlsx";
import { getSurchargeTimes } from "../../../../frontend-core/helpers/surchargeHelpers";
import { masterDataFields } from "../../../Users/UserDetailsModal/MasterDataTab/MasterDataTab";
import StartEndTimeInput from "../../../../components/ShiftPopup/ShiftPopup/StartEndTimeInput/StartEndTimeInput";
import { IHashtag } from "../../../../shared/entities/IWorkSpace";
import { IShift } from "../../../../shared/entities/IShift";
import { selectInitialCreditByUser } from "../../../../selectors/initialCreditsByUserSelector";
import { IUserDetail } from "../../../../shared/entities/IUserDetail";
import { TypeOf } from "yup";
import { MasterDataFormField } from "../../../../shared/entities/IRosterSettings";
import { ReportMasterDataColumns } from "../../../../reducers/ui/reportSettings/reportMasterDataColumns";
import { ITimeClocking } from "../../../../shared/entities/ITimeClocking";
import { timeClockingRepository } from "../../../../repositories/timeClockingRepository";
import { isUserActive } from "../../../../selectors/ActiveUserSelectors";
import { reportColsToV2 } from "../../../../frontend-core/helpers/frontendHelpers";
import { ISurcharge } from "../../../../shared/entities/ISurcharge";

export const getFilteredExtraEntries =
  () =>
  (disp: DispFn, getState: () => AppState): ReportEntry[] => {
    // returns Entries of type absene/holiday/creditCorrection
    const state = getState();
    const isV2 = state.data.tenantInfo.isV2;
    const creditCorrectionsByUserByDate = selectCreditCorrectionsByUserByDate(state);
    const initialCreditByUser = selectInitialCreditByUser(state);
    const contractsByUser = selectContractsByUser(state);
    const holidayFinder = selectHolidayFinder(state);
    const creditsByUserByDate = state.data.credits; // This is core-data in form of a raw list of shift/tracking-durations
    const absencesByUser = selectAbsencesByUser(state);
    const userDetailMap = selectUserDetailMap(state);
    const excludedWeekDays = state.ui.reportSettings.reportExcludedWeekDays;
    const users = state.data.users;
    const reportFilters = state.ui.shifts.reporting.filters;
    const reportDataTypes = state.ui.reportSettings.reportDataTypes;
    const reportColumns = reportColsToV2(state.ui.reportSettings.reportColumns, isV2);

    const { filterStartDate, filterEndDate, filterJobPositionId, filterUserId, filterBranchId } = reportFilters;
    const filteredUsers = users.filter(
      (u) =>
        !u.isDeleted &&
        (!filterBranchId || u.branchIds.includes(filterBranchId)) &&
        (!filterUserId || u.id === filterUserId) &&
        (!filterJobPositionId || u.jobPositionIds.includes(filterJobPositionId))
    );
    const dates = generateDatesList(filterStartDate, filterEndDate);

    const entries: ReportEntry[] = [];

    dates
      .filter((date) => !excludedWeekDays.length || !excludedWeekDays.includes(getWeekDayString(date)))
      .forEach((date) => {
        filteredUsers.forEach((user) => {
          const absence = absencesByUser[user.id].find((a) => a.startDate <= date && a.endDate >= date);
          const creditCorrection = creditCorrectionsByUserByDate[user.id]?.[date];
          const ignoreAbsences = !reportDataTypes.absenceTypeIds.length;
          const initialCredit = initialCreditByUser[user.id];
          const contractStartDate = userDetailMap[user.id]?.contractStartDate;

          if (
            (reportFilters.filterLabelId && reportColumns.workSpaceName) ||
            (reportFilters.filterAddressId && reportColumns.addressName) ||
            (reportFilters.filterHashtagId && reportColumns.hashtags)
          ) {
            return; // if the labelFilter is aktive and the column is visible dont show ExtraEntries
          }

          if (reportDataTypes.holidays) {
            if ((!initialCredit || initialCredit.date <= date) && (!contractStartDate || contractStartDate <= date)) {
              // to ignore holidays before the potetnial hourAcount-startDate
              const contract = getValidContract(contractsByUser[user.id], date)!;
              if (holidayFinder(date, contract.mainBranchId)) {
                const quota = getQuotaOnDate(contract, date);
                const holidayCredit = getHolidayCredit(contract, quota, absence);

                if (holidayCredit && isUserActive(user, date)) {
                  entries.push({
                    date,
                    userId: user.id,
                    userName: getUserName(user),
                    staffNumber: userDetailMap[user.id]?.employNum,
                    type: ReportEntryType.holiday,
                    duration: holidayCredit,
                    isEarning: true,
                  });
                }
              }
            }
          }

          if (!ignoreAbsences && absence) {
            const shiftEarnings = creditsByUserByDate[`${user.id}_${date}`] || 0;
            const contract = getValidContract(contractsByUser[user.id], date)!;
            const quotaOfDate = getQuotaOnDate(contract, date);
            const earning = getAbsenceEarningOfDate({ date, quotaOfDate, absence, contract, shiftEarnings });

            if (reportDataTypes.absenceTypeIds.includes(absence.typeId) && !!earning) {
              entries.push({
                date,
                userId: user.id,
                userName: getUserName(user),
                staffNumber: userDetailMap[user.id]?.employNum,
                type: ReportEntryType.absence,
                absenceTypeId: absence.typeId,
                duration: earning,
                absence,
                isEarning: true,
              });
            }
          }

          if (reportDataTypes.creditCorrections && creditCorrection) {
            entries.push({
              date,
              userId: user.id,
              userName: getUserName(user),
              staffNumber: userDetailMap[user.id]?.employNum,
              type: ReportEntryType.creditCorrection,
              duration: creditCorrection.minutes,
              creditCorrection,
              isEarning: true,
            });
          }
        });
      });

    return entries;
  };

export const getFilteredShiftEntries =
  () =>
  (disp: DispFn, getState: () => AppState): ReportEntry[] => {
    const state = getState();
    const isV2 = state.data.tenantInfo.isV2;
    const reportFilters = state.ui.shifts.reporting.filters;
    const reportDataTypes = state.ui.reportSettings.reportDataTypes;
    const excludedWeekDays = state.ui.reportSettings.reportExcludedWeekDays;
    const surcharges = state.data.surcharges;
    const sessionInfo = selectSessionInfo(state);
    const rosterSettings = state.data.rosterSettings[0];
    const shifts = state.data.shifts;

    if (reportDataTypes.shifts === false || !reportFilters) {
      return [];
    }

    const isAdmin = sessionInfo.user.role === RoleType.admin;
    const { wagesCanBeEntered } = rosterSettings;
    const { filterBranchId, filterJobPositionId, filterUserId, filterLabelId, filterAddressId, filterHashtagId } =
      reportFilters;

    const absencesByUser = selectAbsencesByUser(state);
    const trackingMap = selectTrackingMap(state);
    const clockingMap = selectTimeClockingMap(state);
    const userMap = selectUserMap(state);
    const branchMap = selectBranchMap(state);
    const jobPosMap = selectJobPositionMap(state);
    const workSpaceMap = selectWorkSpaceMap(state);
    const userDetailMap = selectUserDetailMap(state);
    const shiftAddressMap = selectShiftAddressMap(state);
    const contractsByUser = selectContractsByUser(state);
    const hashtagMap = selectHashtagMap(state);
    const holidayFinder = selectHolidayFinder(state);
    const reportColumns = reportColsToV2(state.ui.reportSettings.reportColumns, isV2);

    return shifts
      .filter(
        (s) =>
          s.userId &&
          s.date <= reportFilters.filterEndDate &&
          s.date >= reportFilters.filterStartDate &&
          (!filterBranchId || filterBranchId === s.branchId) &&
          (!filterAddressId || filterAddressId === s.addressId) &&
          (!filterJobPositionId || filterJobPositionId === s.jobPositionId) &&
          (!filterUserId || filterUserId === s.userId) &&
          (!filterLabelId || !reportColumns.workSpaceName || s.workSpaceId === filterLabelId) &&
          (!filterHashtagId || !reportColumns.hashtags || s.hashtagIds?.includes(filterHashtagId)) &&
          !(absencesByUser[s.userId] || []).find(
            (a) => a.startDate <= s.date && a.endDate >= s.date && a.status === AbsenceStatus.active
          ) &&
          (isAdmin || sessionInfo.user.branchIds.includes(s.branchId)) &&
          (!excludedWeekDays.length || !excludedWeekDays.includes(getWeekDayString(s.date))) &&
          !s.isActiveClocking // this is relevant for V2
      )
      .map((shift) => {
        const tracking = trackingMap[shift.id] as ITracking | undefined;
        const clocking = clockingMap[shift.id] as ITimeClocking | undefined;
        const user = userMap[shift.userId!] as IUser;

        const jobPosition = jobPosMap[shift.jobPositionId];
        const branch = branchMap[shift.branchId];
        const workSpace = shift.workSpaceId ? workSpaceMap[shift.workSpaceId] : undefined;
        const address = shift.addressId ? shiftAddressMap[shift.addressId] : undefined;

        const surchargeMap: { [surchargeType: string]: any } = {}; // { mins: number; percent: number }

        surcharges.forEach((surcharge) => {
          const mins = getSurchargeTimes(surcharge, shift, tracking, contractsByUser, holidayFinder);
          mins && (surchargeMap[surcharge.type] = { mins, surcharge });
        });

        const trackedTime = tracking ? `${tracking.startTime} - ${tracking.endTime} / ${tracking.breakMinutes}` : "";
        const punchedTimeV2 = `${shift.startTime} - ${shift.endTime} / ${shift.breakMinutes}`;
        const duration = isV2 ? getClockingCreditV2(clocking!) : getShiftCredit(shift, tracking); // minutes

        const validContract = wagesCanBeEntered
          ? getValidContract(contractsByUser[shift.userId!], shift.date)
          : undefined;
        const hourlyWage = validContract?.hourlyWage || 0;
        const wage = hourlyWage * (duration / 60);

        const nightWage = getSurchgargeWage(hourlyWage, surchargeMap.night?.surcharge, surchargeMap.night?.mins);
        const holidayWage = getSurchgargeWage(hourlyWage, surchargeMap.holiday?.surcharge, surchargeMap.holiday?.mins);
        const sundayWage = getSurchgargeWage(hourlyWage, surchargeMap.sunday?.surcharge, surchargeMap.sunday?.mins);
        const customWage = getSurchgargeWage(hourlyWage, surchargeMap.custom?.surcharge, surchargeMap.custom?.mins);
        const wageWithSurcharge = _.sum([wage, nightWage, holidayWage, sundayWage, customWage]);

        return {
          date: shift.date,
          userId: user.id,
          userName: decryptUser(user).name,
          staffNumber: userDetailMap[user.id]?.employNum,
          jobPositionName: jobPosition.name,
          branchName: branch.name,
          addressName: address?.name || address?.address,
          shiftTime: `${shift.startTime} - ${shift.endTime} / ${shift.breakMinutes}`,
          trackedTime: isV2 ? punchedTimeV2 : trackedTime,
          nightSurcharge: surchargeMap.night?.mins,
          holidaySurcharge: surchargeMap.holiday?.mins,
          sundaySurcharge: surchargeMap.sunday?.mins,
          customSurcharge: surchargeMap.custom?.mins,
          isTrackingAccepted: isV2 ? !!clocking?.isAccepted : !!tracking?.isAccepted,
          duration,
          workSpaceName: workSpace?.name,
          hashtags: getShiftHashtagsAsString(shift, hashtagMap),
          comment: shift.comment,
          shift,
          tracking,
          breakIntervalls: getBreakIntervalls(shift, tracking, clocking),
          wage: wageWithSurcharge,
          type: ReportEntryType.shift,
          isEarning: true,
        };
      });
  };

const getVisibleReportColumnNames = (reportColumns: ReportColumns) => {
  const columnNames: string[] = [];
  reportColumns.shiftId && columnNames.push("Id");
  reportColumns.date && columnNames.push(lg.datum);
  reportColumns.userName && columnNames.push(lg.mitarbeiter);
  reportColumns.staffNumber && columnNames.push(lg.personalnr_punkt);
  reportColumns.jobPositionName && columnNames.push(lg.rolle);
  reportColumns.branchName && columnNames.push(lg.standort);
  reportColumns.addressName && columnNames.push(lg.adresse);
  reportColumns.workSpaceName && columnNames.push(lg.label);
  reportColumns.hashtags && columnNames.push(lg.hashtags);
  reportColumns.comment && columnNames.push(lg.kommentar);
  reportColumns.shiftTime && columnNames.push(lg.planzeit);
  reportColumns.trackedTime && columnNames.push(lg.zeiterfassung);
  reportColumns.breakIntervalls && columnNames.push(lg.pausenintervall);
  reportColumns.wage && columnNames.push(lg.lohn);
  /// surcharges START
  reportColumns.nightSurcharge && columnNames.push(lg.Nachtzuschlag);
  reportColumns.sundaySurcharge && columnNames.push(lg.Sonntagszuschlag);
  reportColumns.holidaySurcharge && columnNames.push(lg.Feiertagszuschalg);
  reportColumns.customSurcharge && columnNames.push(lg.Extrazuschlag);
  /// surcharges END
  reportColumns.duration && columnNames.push(lg.dauer);
  return columnNames;
};

type CellDefExt = CellDef & { rowData?: any };

export const exportMultiShiftReportAsPdf = (entriesByUser: { userId: ReportEntry[] }) => async (dispatch: DispFn) => {
  const docs: Blob[] = [];
  for (let userId in entriesByUser) {
    const entries = entriesByUser[userId];
    const [doc, title] = await dispatch(getShiftReportAsPdf(entries, userId));
    docs.push(doc.output("blob"));
  }

  const merger = new PDFMerger();

  await Promise.all(docs.map((file) => merger.add(file)));
  const randomId = uuid().substr(0, 5);
  await merger.save(`${randomId}.pdf`);
};

export const exportShiftReportAsPdf = (entries: ReportEntry[]) => async (dispatch: DispFn) => {
  const [doc, title] = await dispatch(getShiftReportAsPdf(entries));
  const randomId = uuid().substr(0, 5);
  doc.save(`${title} ${randomId}.pdf`);
};

export const getShiftReportAsPdf =
  (entries: ReportEntry[], _userId?: string) =>
  async (dispatch: DispFn, getState: () => AppState): Promise<[jsPDF, string]> => {
    const state = getState();
    const isV2 = state.data.tenantInfo.isV2;
    const visibleCols = reportColsToV2(state.ui.reportSettings.reportColumns, isV2);
    const extraData = state.ui.reportSettings.reportDataTypes;
    const reportTimeFormat = state.ui.reportSettings.reportTimeFormat;
    const showDecimalFormat = reportTimeFormat === TimeFormat.DecimalHours;
    const absenceTypeMap = selectAbsenceTypeMap(state);
    const userMap = selectUserMap(state);
    const reportFilters = state.ui.shifts.reporting.filters as ReportFilterData;

    const { filterStartDate, filterEndDate, filterUserId } = reportFilters;
    const startDate = moment(filterStartDate, SDateFormat).format("L");
    const endDate = moment(filterEndDate, SDateFormat).format("L");

    const doc = new jsPDF("l");
    doc.setFontSize(12);
    const fromUntil = `${startDate} - ${endDate}`;
    const userId = _userId || filterUserId; // possible to pass userId in > in case of multi-user pdf creation its neccessary
    const userName = userId ? decryptUser(userMap[userId]).name : "";
    const title = `Auswertung ${fromUntil} ${userName}`.trim();
    doc.text(title, 6, 10);

    const showAnyExtraData = extraData.holidays || extraData.creditCorrections || extraData.absenceTypeIds.length;
    const headerKeys = getVisibleReportColumnNames(visibleCols);
    const fixedCellWidthForKeys = {
      //["Id"]: 50,
      [lg.datum]: 24,
      [lg.mitarbeiter]: 40,
      [lg.personalnr_punkt]: 30,
      [lg.rolle]: "auto",
      [lg.standort]: 30,
      [lg.adresse]: 30,
      [lg.label]: "auto",
      [lg.kommentar]: "auto",
      [lg.planzeit]: 35,
      [lg.zeiterfassung]: 35,
      [lg.pausenintervall]: 35,
      [lg.lohn]: 16,
      [lg.dauer]: 16,
    };

    const formatDuration = (minutes: number) =>
      showDecimalFormat ? minutesToHoursStr(minutes) : minutesToDuration(minutes, { withSign: minutes < 0 });

    const data = entries.map((e) => {
      const tracking = e.tracking?.isAccepted ? e.tracking : undefined;
      const clocking = e.shift;
      const isHoliday = e.type === ReportEntryType.holiday;
      const isCorrection = e.type === ReportEntryType.creditCorrection;

      const isShift = !!e.shift;

      const durationFormated = formatDuration(e.duration);

      const row: any = {};

      row.Id = e.shift?.id;
      row[lg.datum] = moment(e.date, SDateFormat).format("DD.MM.YY dd");
      row[lg.mitarbeiter] = e.userName;
      row[lg.personalnr_punkt] = e.staffNumber;
      row[lg.rolle] = e.jobPositionName;
      row[lg.standort] = e.branchName;
      row[lg.adresse] = e.addressName;
      row[lg.label] = e.workSpaceName;
      row[lg.hashtags] = e.hashtags;
      row[lg.kommentar] = e.comment;
      /// shift-time
      if (e.shift && !e.shift.isDynamicClocked) {
        row[lg.planzeit] = `${e.shift.startTime} - ${e.shift.endTime} / ${e.shift.breakMinutes}`;
      }
      /// tracked-time
      if (!isV2 && tracking) {
        row[lg.zeiterfassung] = `${tracking.startTime} - ${tracking.endTime} / ${tracking.breakMinutes}`;
      }
      if (isV2 && clocking) {
        row[lg.zeiterfassung] = clocking.endTime
          ? `${clocking.startTime} - ${clocking.endTime} / ${clocking.breakMinutes}`
          : `${clocking.startTime}`;
      }
      row[lg.pausenintervall] = e.breakIntervalls;
      row[lg.lohn] = e.wage;

      /// surcharges
      e.nightSurcharge && (row[lg.Nachtzuschlag] = formatDuration(e.nightSurcharge));
      e.sundaySurcharge && (row[lg.Sonntagszuschlag] = formatDuration(e.sundaySurcharge));
      e.holidaySurcharge && (row[lg.Feiertagszuschalg] = formatDuration(e.holidaySurcharge));
      e.customSurcharge && (row[lg.Extrazuschlag] = formatDuration(e.customSurcharge));

      /// worked-time
      row[lg.dauer] = isShift ? durationFormated : undefined;

      // /// extra-data
      extraData.holidays && (row[lg.feiertag] = isHoliday ? durationFormated : undefined);
      extraData.creditCorrections && (row[lg.feiertag] = isCorrection ? durationFormated : undefined);
      /// extra-data-absences
      extraData.absenceTypeIds
        .filter((id) => absenceTypeMap[id]) // filtering here > because absenceTypes can be hard-delted and still be referenced by redux-store-ui
        .forEach((id) => {
          const absenceTypeName = absenceTypeMap[id].name;
          row[absenceTypeName] = e.absenceTypeId === id ? durationFormated : undefined;
        });
      /// Gutgeschrieben
      showAnyExtraData && (row.Total = durationFormated);
      return { ...row, raw: e, isHoliday, isCorrection };
    });

    const headCells: any = headerKeys.map((k) => {
      return {
        content: k,
        styles: {
          fillColor: "#000000",
          overflow: "ellipsize",
          fontSize: 9,
          cellWidth: fixedCellWidthForKeys[k],
        },
      };
    });

    const mainTableRows: any[][] = data.map((row) => {
      return headerKeys.map((k) => ({
        content: k === lg.dauer ? row[k] || row["Total"] : row[k] || "",
        styles: {
          // fillColor: "#000000",
          overflow: "ellipsize",
          fontSize: 9,
          cellWidth: fixedCellWidthForKeys[k],
        },
        rowData: row,
      }));
    });

    const totalPagesExp = "{total_pages_count_string}";
    autoTable(doc, {
      // theme: "",
      head: [headCells],
      body: mainTableRows,
      startY: 15,
      margin: { bottom: 10, left: 5, right: 5, top: 5 },
      // startX: 15,
      didDrawCell: (data) => {
        const rowData = (data.cell.raw as CellDefExt).rowData;
        if (
          data.section === "body" &&
          (rowData.isHoliday || rowData.isCorrection || rowData.raw.absenceTypeId) &&
          data.column.index === 2
        ) {
          let text: string = "";
          let backgroundColor: string = "";
          if (rowData.isHoliday) {
            text = lg.feiertag;
            backgroundColor = "#ff428f";
          } else if (rowData.isCorrection) {
            text = lg.korrektur;
            backgroundColor = "#198fff";
          } else if (rowData.raw.absenceTypeId) {
            text = absenceTypeMap[rowData.raw.absenceTypeId].name;
            backgroundColor = absenceTypeMap[rowData.raw.absenceTypeId].color;
          }
          doc.setTextColor("#ffffff");
          doc.setFillColor(backgroundColor);
          doc.roundedRect(data.cell.x, data.cell.y + 1, data.cell.width - 2, 5, 0.5, 0.5, "F");
          doc.text(text, data.cell.x + 3, data.cell.y + 4.5, {
            maxWidth: data.cell.width - 1,
          });
          doc.setTextColor("#000000");

          // var base64Img = 'data:image/jpeg;base64,iVBORw0KGgoAAAANS...'
          // doc.addImage(base64Img, 'JPEG', data.cell.x + 2, data.cell.y + 2, 10, 10)
        }
      },
      // didDrawPage: (data) => {
      //   // Footer
      //   let str = "Seite " + (doc.internal as any).getNumberOfPages();
      //   // Total page number plugin only available in jspdf v1.0+
      //   if (typeof doc.putTotalPages === "function") {
      //     str = str + " " + lg.von + " " + totalPagesExp;
      //   }
      //   doc.setFontSize(10);

      //   // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      //   const pageSize = doc.internal.pageSize;
      //   const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      //   doc.text(str, data.settings.margin.left, pageHeight - 5);
      // },
    });
    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }

    const sum = _.sum(entries.map((e) => e.duration));
    const sumFormated = showDecimalFormat ? minutesToHoursStr(sum) : minutesToDuration(sum, { withSign: sum < 0 });

    doc.setFontSize(10);
    doc.text(`${lg.summe}: ${sumFormated}`, 7, (doc as any).lastAutoTable.finalY + 6);
    return [doc, title];
  };

export const fetchShiftReportData = () => async (dispatch: DispFn, getState: () => AppState) => {
  const isV2 = getState().data.tenantInfo.isV2;
  const reportFilters = getState().ui.shifts.reporting.filters;
  const reportType = getState().ui.shifts.reporting.reportType;
  const reportColumns = getState().ui.reportSettings.reportColumns;
  const shiftsNeedToBeClocked = getState().data.timeClockSettings[0].shiftsNeedToBeClocked;
  const breakIntervallsColVisible = reportColumns.breakIntervalls;

  if (!reportFilters) {
    return;
  }

  const { filterStartDate, filterEndDate, filterBranchId, filterUserId } = reportFilters;
  const isShiftReport = reportType === ReportType.shift;

  let filter = [] as any;

  if (isShiftReport && filterUserId) {
    filter = ["userId_date", "between", [`${filterUserId}_${filterStartDate}`, `${filterUserId}_${filterEndDate}`]];
  } else if (isShiftReport && filterBranchId) {
    filter = [
      "branchId_date",
      "between",
      [`${filterBranchId}_${filterStartDate}`, `${filterBranchId}_${filterEndDate}`],
    ];
  } else {
    filter = ["date", "between", [filterStartDate, filterEndDate]];
  }

  const requests: Promise<any>[] = [];
  // V1 requests
  !isV2 && requests.push(dispatch(shiftRepository.fetchMany({ filter })));
  !isV2 && requests.push(dispatch(trackingRepository.fetchMany({ filter })));
  // V2 requests
  isV2 && requests.push(dispatch(timeClockingRepository.fetchMany({ filter })));
  if (!isV2 && shiftsNeedToBeClocked && breakIntervallsColVisible) {
    // because ITimeClockingDB does not have the compundKey "userId_date" we have to fetch all between dates
    // and therefore only fetch if we really need the data: We need it to display the breakIntervalls
    // Cave: after the deployment of V2 the compountKey userId_date is being added to entities, but already saved clockings,
    // dont have this key
    await dispatch(timeClockingRepository.fetchMany({ filter: ["date", "between", [filterStartDate, filterEndDate]] }));
  }
  return Promise.all(requests);
};

export const getShiftReportCsv =
  (entries: ReportEntry[], options: { replaceCommas?: boolean } = {}) =>
  async (dispatch: DispFn, getState: () => AppState): Promise<{ file: string; filename: string }> => {
    const state = getState();
    const isV2 = state.data.tenantInfo.isV2;
    const visibleCols = reportColsToV2(state.ui.reportSettings.reportColumns, isV2);
    const extraData = state.ui.reportSettings.reportDataTypes;
    const reportTimeFormat = state.ui.reportSettings.reportTimeFormat;
    const reportMasterDataColumns = state.ui.reportSettings.reportMasterDataColumns;
    const showDecimalFormat = reportTimeFormat === TimeFormat.DecimalHours;
    const absenceTypeMap = selectAbsenceTypeMap(state);
    const userMap = selectUserMap(state);
    const reportFilters = state.ui.shifts.reporting.filters as ReportFilterData;
    const { filterStartDate, filterEndDate, filterUserId } = reportFilters;
    const userName = filterUserId ? decryptUser(userMap[filterUserId]).name : "";
    const randomId = uuid().substr(0, 5);
    const startDate = moment(filterStartDate, SDateFormat).format("L");
    const endDate = moment(filterEndDate, SDateFormat).format("L");
    const userDetailMap = selectUserDetailMap(state);
    const rosterSetting = state.data.rosterSettings[0];
    const customMasterDataFields = rosterSetting.customMasterDataFields || {};
    const separator = options?.replaceCommas ? "." : ",";

    const formatDuration = (minutes: number) =>
      showDecimalFormat
        ? minutesToHoursStr(minutes, { separator })
        : minutesToDuration(minutes, { withSign: minutes < 0 });

    const data = entries.map((e) => {
      const tracking = isV2 ? e.shift : e.tracking?.isAccepted ? e.tracking : undefined; // in V2 we use the generated shift
      const isHoliday = e.type === ReportEntryType.holiday;
      const isCorrection = e.type === ReportEntryType.creditCorrection;
      const showAnyExtraData = extraData.holidays || extraData.creditCorrections || extraData.absenceTypeIds.length;
      const isShift = !!e.shift;

      const userDetail = userDetailMap[e.userId] || {};
      const durationFormated = formatDuration(e.duration);

      let row: any = {};
      visibleCols.shiftId && (row["Id"] = e.shift?.id);
      visibleCols.date && (row[lg.datum] = moment(e.date, SDateFormat).format("L"));
      visibleCols.userName && (row[lg.Mitarbeiter] = e.userName);
      visibleCols.staffNumber && (row[lg.personalnr_punkt] = e.staffNumber);
      visibleCols.jobPositionName && (row[lg.rolle] = e.jobPositionName);
      visibleCols.branchName && (row[lg.standort] = e.branchName);
      visibleCols.addressName && (row[lg.adresse] = e.addressName);
      visibleCols.workSpaceName && (row[lg.label] = e.workSpaceName);
      visibleCols.hashtags && (row[lg.hashtags] = e.hashtags);
      visibleCols.comment && (row[lg.kommentar] = e.comment && e.comment.replace(/\n/g, " ")); // replace linebreak by space > CSV-EXCEL issues
      /// shift-time
      if (visibleCols.shiftTime) {
        row[lg.start] = (e.shift && !e.shift.isDynamicClocked && e.shift.startTime) || undefined;
        row[lg.ende] = (e.shift && !e.shift.isDynamicClocked && e.shift.endTime) || undefined;
        row[lg.pause] = (e.shift && !e.shift.isDynamicClocked && minutesToDuration(e.shift.breakMinutes)) || undefined;
      }
      /// tracked-time
      if (visibleCols.trackedTime) {
        row[isV2 ? lg.start : lg.ist_start] = tracking?.startTime;
        row[isV2 ? lg.ende : lg.ist_ende] = tracking?.endTime;
        row[isV2 ? lg.pause : lg.ist_pause] = tracking && minutesToDuration(tracking.breakMinutes || 0);
      }
      ///
      visibleCols.breakIntervalls && (row[lg.pausenintervall] = e.breakIntervalls);
      visibleCols.wage && (row[lg.lohn] = e.wage ? e.wage.toFixed(2) : undefined);
      /// surcharges
      visibleCols.nightSurcharge &&
        (row[lg.Nachtzuschlag] = e.nightSurcharge ? formatDuration(e.nightSurcharge) : undefined);
      visibleCols.holidaySurcharge &&
        (row[lg.Feiertagszuschalg] = e.holidaySurcharge ? formatDuration(e.holidaySurcharge) : undefined);
      visibleCols.sundaySurcharge &&
        (row[lg.Sonntagszuschlag] = e.sundaySurcharge ? formatDuration(e.sundaySurcharge) : undefined);
      visibleCols.customSurcharge &&
        (row[lg.Extrazuschlag] = e.customSurcharge ? formatDuration(e.customSurcharge) : undefined);
      /// worked-time
      visibleCols.duration && (row[lg.arbeitszeit] = isShift ? durationFormated : undefined);
      /// extra-data
      extraData.holidays && (row[lg.feiertag] = isHoliday ? durationFormated : undefined);
      extraData.creditCorrections && (row[lg.korrektur] = isCorrection ? durationFormated : undefined);
      /// extra-data-absences
      extraData.absenceTypeIds
        .filter((id) => absenceTypeMap[id]) // filtering here > because absenceTypes can be hard-delted and still be referenced by redux-store-ui
        .forEach((id) => {
          const absenceTypeName = absenceTypeMap[id].name;
          row[absenceTypeName] = e.absenceTypeId === id ? durationFormated : undefined;
        });
      /// Gutgeschrieben
      showAnyExtraData && (row.Total = durationFormated);

      // userMasterData
      const masterDataMap = getMasterDataReportMap(userDetail, customMasterDataFields, reportMasterDataColumns);
      row = { ...row, ...masterDataMap };

      return row;
    });

    const filename = `${lg.auswertung} ${startDate} - ${endDate} ${userName} ${randomId}`;

    return {
      file: unparse(data),
      filename,
    };
  };

export const getMasterDataReportMap = (
  userDetail: IUserDetail,
  customMasterDataFields: { [key: string]: MasterDataFormField },
  reportMasterDataColumns: ReportMasterDataColumns
) => {
  const row = {};
  Object.values({ ...masterDataFields, ...customMasterDataFields }).forEach((field) => {
    if (reportMasterDataColumns[field.id]) {
      const userDetailFlat = { ...userDetail, ...(userDetail.customFields || {}) }; // flatten the nested customFields properties
      row[field.label] =
        userDetailFlat[field.id] &&
        (field.type === "date" ? simpleDateToFormat(userDetailFlat[field.id], "DD.MM.YYYY") : userDetailFlat[field.id]);
    }
  });
  return row;
};

export const exportShiftReportAsCsv =
  (entries: ReportEntry[]) => async (dispatch: DispFn, getState: () => AppState) => {
    const csv = await dispatch(getShiftReportCsv(entries));
    saveDataAs(csv.file, csv.filename + ".csv");
  };

export const exportShiftReportAsExcel =
  (entries: ReportEntry[]) => async (dispatch: DispFn, getState: () => AppState) => {
    const csv = await dispatch(getShiftReportCsv(entries, { replaceCommas: true })); // we replace commas with dots for excel > otherwiese the numbers can not be summed in excel.
    const worksheet = XLSX.utils.aoa_to_sheet(parse(csv.file, { dynamicTyping: true }).data as string[][]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "worksheet");
    XLSX.writeFileXLSX(workbook, csv.filename + ".xlsx", {});
  };

const getShiftHashtagsAsString = (shift: IShift, hashtagMap: { [id: string]: IHashtag }) => {
  return shift.hashtagIds ? _.sortBy(shift.hashtagIds.map((id) => hashtagMap[id].name)).join(", ") : undefined;
};

export const getBreakIntervalls = (_shift: IShift, _tracking?: ITracking, _clocking?: ITimeClocking): string => {
  const breakStartTime = _tracking?.breakStartTime || _shift.breakStartTime;
  const breakMinutes = _tracking ? _tracking.breakMinutes : _shift.breakMinutes;
  const breakActivities = _clocking?.breakActivities;
  let intervalls = "";

  if (breakActivities) {
    breakActivities.forEach((ba) => {
      ba.type === "start" && intervalls.length && (intervalls += " / ");
      intervalls += ba.time;
      ba.type === "start" && (intervalls += " - ");
    });
  } else if (breakStartTime && breakMinutes) {
    intervalls = breakStartTime + " - " + minutesToTTime(tTimetoMinutes(breakStartTime) + breakMinutes);
  }
  return intervalls;
};

export const getSurchgargeWage = (
  hourlyWage: number,
  surcharge: ISurcharge | undefined,
  mins: number | undefined
): number => {
  return surcharge?.applyToWage && surcharge?.percentage && hourlyWage && !!mins
    ? hourlyWage * (surcharge.percentage / 100) * (mins / 60)
    : 0;
};
