import { withRosterLoading } from "./rosterReducerHelpers";
import { getSimpleReducer } from "../../../helpers/reducerHelpers";
import { envi } from "../../../../env";

export const rosterTypeActionType = {
  SET: "@@AV/SET_ROSTER_TYPE",
};

export enum RosterType {
  Kanban,
  Grid,
  List,
}

export const setRosterType = (type: RosterType) =>
  withRosterLoading({
    type: rosterTypeActionType.SET,
    payload: type,
  });

const initialState = envi.isV2() ? RosterType.List : RosterType.Grid;

export const rosterTypeReducer = (state = initialState, a: any): string =>
  a.type === rosterTypeActionType.SET ? a.payload : state;
