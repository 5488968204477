import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import AbsencesActionBar from "./AbsencesActionBar/AbsencesActionBar";
import CalendarHead from "./CalendarHead/CalendarHead";
import CalendarBody from "./CalendarBody/CalendarBody";
import { getClickedUserID, getClickedAbsenceID } from "../helprs";
import { ThunkDispatch } from "redux-thunk";
import AbsenceModal from "../../../components/modals/AbsenceModal/AbsenceModal";
import { openModal } from "../../../actions/modal";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { featuresSelector } from "../../../selectors/FeaturesSelector";
import { paidFeatureWarning } from "../../../actions/paidFeatureWarning";
import { selectCan } from "../../../selectors/canSelector";
import { selectAbsenceMap } from "../../../selectors/mapSelectors";
//import { uiFilterAction } from "../../../reducers/ui/filters";

const mapStateToProps = (state: AppState) => ({
  year: state.ui.absences.selectedYear,
  sessionInfo: selectSessionInfo(state),
  absenceMap: selectAbsenceMap(state),
  features: featuresSelector(state),
  can: selectCan(state),
  //filters: state.ui.filters.absenceCalendar,
});

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & { dispatch: ThunkDispatch<{}, {}, any> };

class AbsenceCalendarPage extends React.PureComponent<Props> {
  render() {
    // const { searchInput, branchFilter, positionFilter } = this.props.filters;
    return (
      <div className="fb absencesPageMain">
        <div className="fb absencesTopbar">
          <AbsencesActionBar />
        </div>
        <div className="absenceCalendarMonth">
          <CalendarHead />
          <CalendarBody />
        </div>
      </div>
    );
  }
}

export default withErrorBoundary(
  connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(AbsenceCalendarPage)
);
