import { AppState } from "./../types/AppState";
import { nullifyProps, CrudOperation } from "./../shared/helpers/firebaseHelpers";
import { IShiftLog, IShiftLogDB } from "./../shared/entities/IShiftLog";
import { BaseRepository } from "../frontend-core/BaseRepository";
import _ from "lodash";
import { IShift } from "../shared/entities/IShift";
import { selectShiftMap } from "../selectors/shiftMapSelector";
import { selectSessionInfo } from "../selectors/SessionInfoSelector";
import { v4 as uuid } from "uuid";
import moment from "moment";
class ShiftLogRepository extends BaseRepository<IShiftLog, IShiftLogDB> {
  constructor() {
    super("shiftLogs");
  }

  createIndex(branchId: string, timestamp: number) {
    return `${branchId}_${timestamp}`;
  }

  toDbEntity(shiftLog: IShiftLog): IShiftLogDB {
    return nullifyProps({
      ...shiftLog,
      branchId_timestamp: this.createIndex(shiftLog.branchId, shiftLog.timestamp),
    });
  }

  toLocalEntity = (s: IShiftLogDB): IShiftLog => {
    return _.omit(s, ["branchId_timestamp"]);
  };
}

export const shiftLogRepository = new ShiftLogRepository();

export const getShiftLogUpdates = (operations: CrudOperation<IShift>[], state: AppState): {} => {
  const shiftsById = selectShiftMap(state);
  const sessionInfo = selectSessionInfo(state);

  if (state.ui.shifts.roster.rosterTemplateMode.active || !sessionInfo.hasManagerPermissions()) {
    return {};
  }

  const updtes = {};
  operations.forEach((op) => {
    const prevShift = shiftsById[op.entity.id];
    const nextShift = op.entity;
    const branchId = (prevShift || nextShift).branchId;
    const timestamp = moment().unix();
    const id = uuid();

    if (nextShift.isDynamicClocked || _.isEqual({ ...prevShift, noShow: null }, { ...nextShift, noShow: null })) {
      return;
    }

    updtes["shiftLogs/" + id] = nullifyProps({
      id: uuid(),
      branchId,
      timestamp,
      userId: sessionInfo.user.id,
      operation: op.operation,
      previous: prevShift && nullifyProps(prevShift),
      next: nextShift && nullifyProps(nextShift),
      branchId_timestamp: shiftLogRepository.createIndex(branchId, timestamp),
    } as IShiftLog);
  });

  return updtes;
};
