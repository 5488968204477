import { recentModifiedShiftsActionTypes } from "../../../../actions/recentModifiedShifts";

type RecentModifiedShifts = string[];

const initialState = [];

const recentModifiedShiftsReducer = (
  state: RecentModifiedShifts = initialState,
  action: any
): RecentModifiedShifts => {
  switch (action.type) {
    case recentModifiedShiftsActionTypes.ADD:
      return action.payload;
    case recentModifiedShiftsActionTypes.REMOVE:
      return [];
    default:
      return state;
  }
};

export default recentModifiedShiftsReducer;
