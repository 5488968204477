import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../types/AppState";
import "./styles.scss";
import { Button, Icon } from "antd";
import { trackingRepository } from "../../repositories/TrackingRepository";

import PrettyDate from "../PrettyDate/PrettyDate";

import PlanedAndTrackedTime from "../PlanedAndTrackedTime/PlanedAndTrackedTime";
import { busyInjector, BusyInjectorProps } from "../BusyInjector/BusyInjector";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { AvIcon } from "../AvIcon/AvIcon";
import { ITracking } from "../../shared/entities/ITracking";
import { DeviceType, ITimeClocking } from "../../shared/entities/ITimeClocking";
import { IShift } from "../../shared/entities/IShift";
import { isClockingEqualToTracking } from "../../shared/helpers/timeClockingHelpers";
import { getUserName } from "../../shared/helpers/userHelpers";
import { updateTracking } from "../../actions/tracking";
import { timeClockingRepository } from "../../repositories/timeClockingRepository";
import { getDuration } from "../../shared/helpers/timeHelpers";
import cn from "classnames";

const mapStateToProps = (state: AppState) => {
  return {
    branches: state.data.branches,
    users: state.data.users,
    sessionInfo: selectSessionInfo(state),
  };
};

type OwnProps = {
  clocking: ITimeClocking;
  onClick: (clockingId: string) => void;
  isLastItem?: boolean;
};

const getDurationDisplay = (c: ITimeClocking) => {
  const _minutes = c.endTime ? getDuration(c as any) : 0;
  const hours = Math.floor(_minutes / 60);
  const minutes = _minutes % 60;
  return !_minutes ? (
    ""
  ) : (
    <div className="duration">
      <b>{hours}</b> {lg.std} <b>{minutes}</b> {lg.min}
    </div>
  );
};

const getClockedIcon = (clocking: ITimeClocking) =>
  clocking.isAutoClockOut ? (
    <div
      className="timerIconWrapper autoClockedOut"
      data-rh={lg.automatisch_ausgestempelt_da_der_mitarbeiter_es_vergessen_hat}
    >
      <Icon type="export" />
    </div>
  ) : (
    <div className="timerIconWrapper clockeIconWrapper" data-rh={lg.per_stempeluhr_erfasst}>
      <AvIcon type="icon-timer-f" />
    </div>
  );

const getManualIcon = () => (
  <div className="timerIconWrapper manuallyEditedIconWrapper" data-rh={lg.manuell_eingetragene_zeit}>
    <Icon type="form" />
  </div>
);

const dateStyle = {
  background: "#2196f317",
  padding: "2px 8px",
  width: "83px",
  borderRadius: "2px",
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & BusyInjectorProps & DispatchBaseProps;

class _ClockingRequestItemV2 extends React.PureComponent<Props> {
  approveClocking = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { load, dispatch, clocking } = this.props;
    await load(dispatch(timeClockingRepository.update({ ...clocking, isAccepted: true })));
  };

  render() {
    const { clocking, isLoading, sessionInfo, isLastItem } = this.props;
    const canManage = sessionInfo.hasManagerPermissions();

    const canQuickAccept = !clocking.isAutoClockOut;
    const user = this.props.users.find((u) => u.id === clocking.userId)!;
    const manualKey = DeviceType.Manual;
    const isManually =
      !clocking.isAutoClockOut && (clocking.logS?.includes(manualKey) || clocking.logE?.includes(manualKey));

    // const durationMins = getDuration(clocking as any);
    // const durationDecimal = Math.floor(Math.floor(durationMins / 6) / 10);

    return (
      <div
        className={cn({ clockingRequestItemMain: true, isLastItem })}
        key={clocking.id}
        onClick={() => this.props.onClick(clocking.id)}
      >
        <div className="rowItem">
          <div className="listItem dateCell">
            <PrettyDate date={clocking.date} style={dateStyle} />
            <div className="timeBox">
              <div className="main">{clocking.startTime + " - " + (clocking.endTime || "????")}</div>
              <div className="bottom">
                <div className="durationDecimal">{getDurationDisplay(clocking)}</div>
                <div> {" / " + clocking.breakMinutes + " min Pause"}</div>
              </div>
            </div>
          </div>

          <div className="fb nameItem">
            <div className="user">{getUserName(user)}</div>
            {this.props.branches.length > 1 && (
              <div className="trackingBranch">
                {this.props.branches.find((branch) => branch.id === clocking.branchId)!.name}
              </div>
            )}
          </div>
          <div className="listItem typeIconCell">{isManually ? getManualIcon() : getClockedIcon(clocking)}</div>
          <div className="buttonsWrapper" style={{ visibility: canManage && canQuickAccept ? "visible" : "hidden" }}>
            <Button
              id="tracking-request-entry-accept"
              size="default"
              icon="check"
              onClick={(e) => this.approveClocking(e)}
              loading={isLoading()}
              data-rh={lg.akzeptieren}
            />
          </div>
        </div>
      </div>
    );
  }
}

export const ClockingRequestItemV2 = connect(mapStateToProps)(busyInjector(_ClockingRequestItemV2));
