import React from "react";
import { connect } from "react-redux";
import { Button, Icon } from "antd";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import _ from "lodash";
import { openModal } from "../../../../actions/modal";
import BranchPopup from "../branchPopup/BranchPopup";
import "./styles.scss";
import { selectActiveBranches } from "../../../../selectors/ActiveBranchesSelector";
import { withErrorBoundary } from "../../../../components/ErrorBoundary/ErrorBoundary";
import { selectActiveUsers } from "../../../../selectors/ActiveUserSelectors";
import moment from "moment";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import cn from "classnames";

const mapStateToProps = (state: AppState) => {
  return {
    branches: selectActiveBranches(state),
    activeUsers: selectActiveUsers(state, moment().format(SDateFormat)),
  };
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class BranchList extends React.PureComponent<Props> {
  getAllUsersInBranch = (branchId: string) => {
    const userAmount = this.props.activeUsers.filter((u) => u.branchIds.includes(branchId));
    return userAmount.length;
  };

  render() {
    return (
      <div className="branchListMain">
        <div className="fb jBetween aCenter row">
          <h2 className="grow">{lg.standorte}</h2>
        </div>
        <div className="branchCardsWrapper">
          {_.sortBy(this.props.branches, ["name"]).map((branch) => (
            <div
              key={branch.id}
              className={cn({ branchCard: true, isDisabled: branch.isDisabled })}
              onClick={() => this.props.dispatch(openModal(BranchPopup, { branch }, { maskClosable: false }))}
            >
              <div className="cardWrapperLeft">
                <Icon type="environment" style={{ paddingRight: "4px" }} />
                {branch.name}
                {branch.isDisabled && <div className="deactivatedBadge">{lg.deaktiviert}</div>}
              </div>
              <div className="cardWrapperRight">
                {this.getAllUsersInBranch(branch.id)} {lg.mitarbeiter_plural}
              </div>
            </div>
          ))}
        </div>
        <div className="buttonWrapper">
          <Button
            id="add-branch"
            type="primary"
            onClick={() => this.props.dispatch(openModal(BranchPopup, {}, { maskClosable: false }))}
          >
            {lg.standort_hinzufügen}
          </Button>
        </div>
      </div>
    );
  }
}

export default withErrorBoundary(
  connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(BranchList)
);
