import { IResource } from './IResource';


export enum CreditCorrectionType {
  manual = 'manual',
  initial = 'initial',
}

export type ICreditCorrection = IResource & {
  id: string;
  userId: string;
  minutes: number;
  date: string;
  type: CreditCorrectionType;
  note?: string;
};
