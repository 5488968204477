export const UPDATE_REPORT_TIME_FORMAT = "UPDATE_REPORT_TIME_FORMAT";

export enum TimeFormat {
  DecimalHours = "DecimalHours",
  HoursAndMinutes = "HoursAndMinutes",
}

export const updateReportTimeFormat = (payload: TimeFormat) => ({
  type: UPDATE_REPORT_TIME_FORMAT,
  payload,
});

const initialState: TimeFormat = TimeFormat.HoursAndMinutes;

export const reportTimeFormatReducer = (state = initialState, a: any): TimeFormat =>
  a.type === UPDATE_REPORT_TIME_FORMAT ? a.payload : state;
