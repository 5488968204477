import { BaseRepository } from '../frontend-core/BaseRepository';
import { IRosterTemplate } from '../shared/entities/IRosterTemplate';


class RosterTemplateRepository extends BaseRepository<IRosterTemplate> {
  constructor() {
    super('rosterTemplates');
  }
}
export const rosterTemplateRepository = new RosterTemplateRepository();
