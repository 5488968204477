import React from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { Button, Modal, Icon, message, Checkbox, notification } from "antd";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { isWorkingTimeInvalid } from "../../../../helpers/workingTimeHelpers";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import StartEndTimeInput from "../StartEndTimeInput/StartEndTimeInput";
import { closeModal } from "../../../../actions/modal";
import moment from "moment";
import { removeTracking, updateTracking, createTracking } from "../../../../actions/tracking";
import { busyInjector, BusyInjectorProps } from "../../../BusyInjector/BusyInjector";
import cn from "classnames";
import { ITracking } from "../../../../shared/entities/ITracking";
import { IUser, RoleType } from "../../../../shared/entities/IUser";
import { IShift } from "../../../../shared/entities/IShift";
import { ITimeClocking } from "../../../../shared/entities/ITimeClocking";
import { featuresSelector } from "../../../../selectors/FeaturesSelector";
import { paidFeatureWarning } from "../../../../actions/paidFeatureWarning";
import { getUserName } from "../../../../shared/helpers/userHelpers";
import { SDateTimeFormat } from "../../../../shared/helpers/SimpleTime";
import { getShiftEndDateTime } from "../../../../shared/helpers/shiftHelpers";
import { selectTimeClockSettingsByUser } from "../../../../selectors/timeClockSettingsByUserSelector";
import { trackingRepository } from "../../../../repositories/TrackingRepository";
import { createTracing } from "trace_events";
import { addTrackingsOfRosterListener } from "../../../../actions/roster";
import { selectIsManualTrackingEnaledForSessionUser } from "../../../../selectors/isManualTrackingEnaledForSessionUserSelector";

const mapStateToProps = (state: AppState) => {
  return {
    sessionInfo: selectSessionInfo(state),
    timeClockSettingsByUser: selectTimeClockSettingsByUser(state),
    users: state.data.users,
    features: featuresSelector(state),
    isManualTrackingEnabled: selectIsManualTrackingEnaledForSessionUser(state),
  };
};

const getInfoText = (tracking: ITracking, users: IUser[]): string => {
  const creator = users.find((u) => u.id === tracking.creatorUserId)!;
  const creatorName = getUserName(creator);
  const infoText = `Manuell eingetragen von ${creatorName} am ${moment(tracking.createdAt as string).format(
    "DD. MMM HH:mm"
  )}`;
  return infoText;
};

type OwnProps = {
  shift: IShift;
  onChange: (tracking?: ITracking) => void;
  tracking?: ITracking;
  isEditMode: boolean;
  onEditModeChange: (isEditing: boolean) => void;
  timeClocking?: ITimeClocking;
  onUpdateComplete?: () => void;
  updateNoShow: (isNoShwo: boolean) => void;
  withBreakStartTime?: boolean;
};

type State = { isConfirmDelete: boolean };
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;
const { confirm } = Modal;

class _Tracking extends React.PureComponent<Props, State> {
  initialTracking?: ITracking;
  nowDateTime: string;
  constructor(props) {
    super(props);

    this.initialTracking = props.tracking && { ...props.tracking };
    this.nowDateTime = moment().format(SDateTimeFormat);

    this.state = {
      isConfirmDelete: false,
    };
  }

  getInitializedTracking(): ITracking {
    const { shift, sessionInfo, users, tracking, timeClocking, timeClockSettingsByUser } = this.props;

    const userId = shift.userId!;
    const user = users.find((u) => u.id === userId)!;
    const userSettings = timeClockSettingsByUser[user.id];
    const { breaksNeedToBeClocked } = userSettings;

    if (tracking) {
      return tracking;
    }

    const newTracking: ITracking = {
      id: shift.id,
      jobPositionId: shift.jobPositionId,
      startTime: timeClocking ? timeClocking.startTime : shift.startTime || "",
      endTime: timeClocking && timeClocking.endTime ? timeClocking.endTime : shift.endTime || "",
      isAccepted: sessionInfo.hasManagerPermissions(),
      breakMinutes: timeClocking && breaksNeedToBeClocked ? timeClocking.breakMinutes : shift.breakMinutes,
      breakStartTime: shift.breakStartTime,
      creatorUserId: sessionInfo.user.id,
      createdAt: moment().toISOString(),
      userId: userId,
      branchId: shift.branchId,
      date: shift.date,
      isManaully: true,
    };
    return newTracking;
  }

  saveClicked = async () => {
    const { tracking, dispatch, onUpdateComplete, onEditModeChange, sessionInfo } = this.props;
    const canManage = sessionInfo.hasManagerPermissions();
    const nextTracking = {
      ...tracking!,
      isAccepted: canManage,
      breakMinutes: tracking!.breakMinutes || 0,
      creatorUserId: sessionInfo.user.id,
      createdAt: moment().toISOString(),
    };
    onEditModeChange(false);
    canManage && this.handleChange({ isAccepted: true });

    const freshTrackingPromise = this.isCreationMode()
      ? dispatch(createTracking(nextTracking))
      : dispatch(updateTracking(nextTracking));

    this.props.dispatch(closeModal());
    message.success(lg.zeiterfassung_gespeichert);
    await freshTrackingPromise;
    onUpdateComplete && onUpdateComplete();
  };

  onCreateTracking = () => {
    if (!this.props.features.trackings) {
      return this.props.dispatch(paidFeatureWarning());
    }

    this.handleChange(this.getInitializedTracking());
    this.props.onEditModeChange(true);
  };

  handleChange = (tracking: Partial<ITracking>) => {
    const freshTracking = {
      // at the time handleChange() is called we know the tracking exists
      ...this.props.tracking!,
      ...tracking,
    };
    this.props.onChange({ ...freshTracking });
  };

  deleteClicked = () => {
    this.state.isConfirmDelete ? this.deleteTracking() : this.setState({ isConfirmDelete: true });

    // This is done, so the tool-tip gets updated
    const node = document.getElementById("tracking-section-delete-button");
    if (!this.state.isConfirmDelete && node) {
      node.style.pointerEvents = "none";
      setTimeout(() => {
        node.style.pointerEvents = "auto";
      }, 100);
    }
  };

  deleteTracking = async () => {
    const { tracking, dispatch, setLoading, isLoading, onUpdateComplete } = this.props;
    if (isLoading("delete")) {
      return; // fixes a bug that occurs when double-clicking rapidly
    }

    setLoading("delete", true);
    await dispatch(removeTracking(tracking!));
    setLoading("delete", false);
    this.props.onChange(undefined);
    this.props.onEditModeChange(false);
    onUpdateComplete && onUpdateComplete();
    message.warning(lg.eintrag_wurde_gelöscht);
  };

  onInputChange = (value: any, name: string) => {
    this.handleChange({
      [name]: value,
    });
  };

  acceptClicked = async () => {
    const { onUpdateComplete, load, dispatch, tracking } = this.props;
    const accpetedTracking = { ...tracking, isAccepted: true } as ITracking;
    await load(dispatch(updateTracking(accpetedTracking)), "accepting");
    this.handleChange({ isAccepted: true });
    onUpdateComplete && onUpdateComplete();
    message.success(lg.zeiterfassung_bestätigt);
  };

  isCreationMode = () => {
    return !this.initialTracking;
  };

  cancelClicked = () => {
    this.props.onEditModeChange(false);

    this.isCreationMode() ? this.props.onChange(undefined) : this.props.onChange(this.initialTracking);
  };

  editClicked = () => {
    this.props.onEditModeChange(true);
  };

  // This is used for a production bug-fix for autoclocked out Trackings that got autoAccepted
  fixAutoClockedOutTracking = () => {
    this.props.tracking &&
      this.props.dispatch(
        trackingRepository.update({ ...this.props.tracking, isAutoClockOut: true, isAccepted: false })
      );
  };

  render() {
    const {
      tracking,
      users,
      sessionInfo,
      shift,
      timeClockSettingsByUser,
      withBreakStartTime,
      timeClocking,
      isManualTrackingEnabled,
    } = this.props;
    const user = users.find((u) => u.id === shift.userId)!;
    const userSettings = timeClockSettingsByUser[shift.userId!];
    const { shiftsNeedToBeClocked } = userSettings;
    const { isConfirmDelete } = this.state;

    const role = sessionInfo.user.role;
    const currentUser = sessionInfo.user;

    const isManger = role === RoleType.manager;
    const isAdmin = role === RoleType.admin;
    const canMangeBranch = isManger && currentUser.branchIds.includes(shift.branchId);

    const { isEditMode } = this.props;
    const hasAcceptedTracking = tracking && tracking.isAccepted;

    const canManageTracking = isAdmin || canMangeBranch;
    const canEditTracking =
      canManageTracking || (currentUser.id === shift.userId && !hasAcceptedTracking && isManualTrackingEnabled);

    const highlightTracking = !hasAcceptedTracking && !isEditMode && !!tracking;

    const isInvalid = tracking && isWorkingTimeInvalid(tracking.startTime, tracking.endTime, tracking.breakMinutes);
    const isShiftTimeVaid = !isWorkingTimeInvalid(shift.startTime, shift.endTime, shift.breakMinutes);
    const displayNoShowBox =
      !tracking &&
      (shift.isNoShow ||
        (shift.userId && this.nowDateTime > getShiftEndDateTime(shift) && shiftsNeedToBeClocked && isShiftTimeVaid));

    return (
      <>
        <div
          className={cn({
            tracking: true,
            aCenter: true,
            unApprovedTracking: highlightTracking,
          })}
        >
          <label className="shiftPopupLabelMain">{lg.zeiterfassung_label}</label>
          <div className="fb row aEnd grow">
            {!tracking && canEditTracking && (
              <Button
                id="tracking-section-add"
                children={lg.hinzufügen}
                onClick={this.onCreateTracking}
                style={{ marginLeft: 12 }}
              />
            )}
            {tracking && (
              <StartEndTimeInput
                onChange={this.onInputChange}
                startTime={tracking.startTime as string}
                endTime={tracking.endTime as string}
                breakMinutes={tracking.breakMinutes}
                breakStartTime={tracking.breakStartTime}
                disabled={!this.props.isEditMode}
                additionalTag={highlightTracking}
                noLabel
                isInvalid={isInvalid}
                withBreakStartTime={withBreakStartTime}
              />
            )}
            {tracking && (
              <div className="fb row aCenter jEnd grow actionsWrapper">
                {!isEditMode && tracking.isManaully && (
                  <div className="manuallyEditedIcon">
                    <Icon type="form" data-rh-at="bottom" data-rh={getInfoText(tracking, users)} />
                  </div>
                )}
                {!tracking.isAccepted && !isEditMode && canManageTracking && (
                  <Button
                    id="tracking-section-accept"
                    key="tracking-section-accept"
                    children={lg.bestätigen}
                    type="primary"
                    onClick={this.acceptClicked}
                    loading={this.props.isLoading("accepting")}
                    style={{ marginLeft: 8, padding: "0px 10px" }}
                  />
                )}
                {!tracking.isAccepted && !isEditMode && canEditTracking && (
                  <Button
                    id="tracking-section-edit"
                    key="tracking-section-edit"
                    icon="edit"
                    type="default"
                    onClick={this.editClicked}
                    data-rh={lg.bearbeiten}
                    data-rh-at="bottom"
                    style={{ marginLeft: 8 }}
                  />
                )}
                {tracking.isAccepted && !isEditMode && (
                  <Icon
                    type="check-circle"
                    theme="twoTone"
                    twoToneColor="#52c41a"
                    data-rh={lg.bestätigt}
                    data-rh-at="bottom"
                    style={{ marginLeft: 8, fontSize: "20px" }}
                    onClick={() =>
                      timeClocking?.isAutoClockOut &&
                      tracking &&
                      !tracking.isAutoClockOut &&
                      tracking.isAccepted &&
                      this.fixAutoClockedOutTracking()
                    }
                  />
                )}
                {isEditMode && [
                  <Button
                    id="tracking-section-save"
                    key="1"
                    children={lg.Speichern}
                    style={{ marginLeft: 8 }}
                    disabled={isInvalid}
                    type="primary"
                    onClick={async () => this.props.load(this.saveClicked(), "saving")}
                    loading={this.props.isLoading("saving")}
                  />,
                  <Button
                    id="tracking-section-close"
                    key="2"
                    icon="close"
                    style={{ marginLeft: 8 }}
                    onClick={this.cancelClicked}
                  />,
                ]}
                {!this.isCreationMode() && !isEditMode && canEditTracking && (
                  <Button
                    id="tracking-section-delete-button"
                    icon={isConfirmDelete ? "question" : "delete"}
                    style={{ marginLeft: 8 }}
                    type={isConfirmDelete ? "danger" : "default"}
                    onClick={this.deleteClicked}
                    data-rh={isConfirmDelete ? lg.wirklich_löschen : lg.zeiterfassung_löschen}
                    data-rh-at="bottom"
                    loading={this.props.isLoading("delete")}
                  />
                )}
              </div>
            )}
            {displayNoShowBox && (
              <div
                className={cn({ needsTrackingWarning: true, isActive: shift.isNoShow })}
                onClick={() => sessionInfo.hasManagerPermissions() && this.props.updateNoShow(!shift.isNoShow)}
                data-rh={
                  shift.isNoShow
                    ? lg.diese_schicht_wird_nicht_als_arbeitszeit_gezählt_weil_die_zeiterfassung_fehlt
                    : lg.hier_klicken_falls_die_schicht_nicht_angetreten_wurde_und_nicht_als_arbeitszeit_zu_werten_ist
                }
              >
                <Checkbox checked={shift.isNoShow} />
                {/* <Icon className="icon" type="warning" theme="filled" /> */}
                <div className="label">{lg.nicht_angetreten}</div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export const Tracking = connect(mapStateToProps)(busyInjector(_Tracking));
