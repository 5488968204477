import React, { useEffect, useState } from "react";
import { useSelector } from "../../helpers/redux";
import BusyWrapper from "../../components/BusyWrapper/BusyWrapper";
import "./styles.scss";
import { pushNoteRepository } from "../../repositories/pushNoteRepository";
import { useDispatch } from "react-redux";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { IPushNote, PushNoteTopic } from "../../shared/entities/IPushNote";
import { firebaseListenerRepo } from "../../repositories/firebaseListenerRepo";
import { Badge, Icon } from "antd";
import { push } from "connected-react-router";
import moment from "moment";
import { selectUnseenPushNotes } from "../../selectors/selectUnseenPushNotes";
import _ from "lodash";
import { busyInjector, BusyInjectorProps } from "../../components/BusyInjector/BusyInjector";

type Props = {};

const Comp = (props: Props & BusyInjectorProps) => {
  const sessionInfo = useSelector(selectSessionInfo);
  const [lastLoadedPushNotes, setLastLoadedPushNotes] = useState<IPushNote[]>([]);
  const dispatch = useDispatch<DispFn>();
  const allPushNotes = useSelector((s) => s.data.pushNotes).filter((p) => p.userId === sessionInfo.user.id);
  const unseenPushNotes = useSelector(selectUnseenPushNotes).filter(
    (p) => p.topic !== PushNoteTopic.chat && p.topic !== PushNoteTopic.announcement
  );

  const pushNotes = allPushNotes.filter(
    (p) => p.topic !== PushNoteTopic.chat && p.topic !== PushNoteTopic.announcement
  );

  const sortedPushNotes = _.orderBy(pushNotes, ["timestamp"], ["desc"]);

  const loadMore = async () => {
    const data = await dispatch(
      pushNoteRepository.addListener({
        key: "pushNotesPageListener",
        limitToLast: 30,
        filter: [
          "id",
          "between",
          [
            `${sessionInfo.user.id}`,
            lastLoadedPushNotes.length
              ? lastLoadedPushNotes[lastLoadedPushNotes.length - 1].id!
              : `${sessionInfo.user.id}_${32483580529}_9999`,
          ],
        ],
      })
    );

    setLastLoadedPushNotes(data);
  };

  useEffect(() => {
    props.load(loadMore());
    return () => {
      dispatch(pushNoteRepository.updateList(sortedPushNotes.map((p) => ({ ...p, hasSeen: true }))));
      dispatch(firebaseListenerRepo.remove("pushNotesPageListener"));
    };
  }, []);

  return (
    <div className="fb grow shrink aCenter pushNotesPage" style={{ background: "white", overflow: "hidden" }}>
      <div className="pushNotesListWrapper column">
        <div className="pushNotesListHead">
          <Icon type="bell" style={{ color: "#56524e", fontSize: 24, marginTop: 4, marginRight: 8 }} />
          {lg.benachrichtigungen}
          <Badge count={unseenPushNotes.length}>
            <span style={{ width: 50, marginLeft: 10 }}></span>
          </Badge>
        </div>
        <BusyWrapper style={{ flex: "1 1 auto" }} isBusy={props.isLoading()}>
          <div
            className="fb column pushNotesList"
            onScroll={(e) => {
              if (e.currentTarget.scrollTop === e.currentTarget.scrollHeight - e.currentTarget.clientHeight) {
                props.load(loadMore());
              }
            }}
          >
            {!sortedPushNotes.length && (
              <div className="noPushNotesBox">{lg.es_gibt_noch_keine_benachrichtigungen_für_sie}</div>
            )}
            {sortedPushNotes.map((pushNote) => {
              return (
                <div className="pushNoteListItemWrapper noGrow noShrink" key={pushNote.id}>
                  <div className="pushNoteListItem fb row">
                    <div className="noGrow noShrink fb column"></div>
                    <div className="grow shrink fb column">
                      <div className="noGrow noShrink title row">
                        {!pushNote.hasSeen && <div className="redBubble" />}
                        {pushNote.title}
                      </div>
                      <div className="grow shrink message">{pushNote.message}</div>
                      <div className="noGrow noShrink date">{moment.unix(pushNote.timestamp).format("LLL")}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </BusyWrapper>
      </div>
    </div>
  );
};

export const PushNotesPage = React.memo(busyInjector(Comp));
