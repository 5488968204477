import React from "react";
import { useSelector } from "../../../../../helpers/redux";
import { selectHeadCountOfDayGrid } from "../../../../../selectors/headCountOfDayGridSelector";
import cn from "classnames";
import "./styles.scss";

type Props = {
  workingHours: number[];
};

export const RosterDayGridHeadCount = React.memo((props: Props) => {
  const headCountMap = useSelector(selectHeadCountOfDayGrid);

  return (
    <div className="dayViewHeadMain rosterHeadCountRowMain">
      <div className="header cell firstColumn">{lg.headcount}</div>

      <div className="hourHead">
        {props.workingHours.map((h, i) => {
          return (
            <div key={h as number} className="rosterWeekDayHeaderMain weekday cell">
              <div className="fb row grow hourHeadCellContent">
                <div className={cn({ dayText: true, isEmpty: !headCountMap[h] })}>{headCountMap[h] || "0"}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});
