import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import { selectSessionInfo } from "./SessionInfoSelector";

export const selectUnseenPushNotes = createSelector(
  [(s: AppState) => s.data.pushNotes, selectSessionInfo],
  (pushNotes, sessionInfo) => {
    return pushNotes.filter((p) => !p.hasSeen && p.userId === sessionInfo.user.id);
  }
);
