import _ from "lodash";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { unparse, parse } from "papaparse";
import jsPDF from "jspdf";
import autoTable, { CellDef } from "jspdf-autotable";
import { AppState } from "../../../../types/AppState";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import { getColumnKey, ReportCol, ReportEntryType } from "../reportHelpers";
import { ReportEntry } from "../shiftReport/ShiftReport";
import {
  getFilteredExtraEntries,
  getFilteredShiftEntries,
  getMasterDataReportMap,
} from "../shiftReport/shiftReportHelpers";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import { ReportFilterData } from "../../../../actions/reporting";
import { minutesToDuration, minutesToHours, minutesToHoursStr } from "../../../../shared/helpers/timeHelpers";
import { saveDataAs } from "../../../../helpers/export";
import { selectActiveUsers } from "../../../../selectors/ActiveUserSelectors";
import { isRuleApplyingToUser } from "../../../../shared/helpers/settingsHelpers";
import { CSSProperties } from "react";
import { IUser } from "../../../../shared/entities/IUser";
import { decryptUser } from "../../../../shared/helpers/userHelpers";
import { getBalances } from "../../../../actions/creditActions/getBalances";
import { selectUserDetailMap, selectUserMap } from "../../../../selectors/mapSelectors";
import { selectInitialCreditByUser } from "../../../../selectors/initialCreditsByUserSelector";
import { getWeekDayString } from "../../../../shared/helpers/weekDaysList";
import { TimeFormat } from "../../../../reducers/ui/reportSettings/reportTimeFormat";
import * as XLSX from "xlsx";
import { numberToEuroPrice, reportColsToV2 } from "../../../../frontend-core/helpers/frontendHelpers";
import { selectUsersWithSharedBranch } from "../../../../selectors/UsersWithSharedBranchSelector";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";

export type RowData = {
  userId: string;
  userName: string;
  staffNumber?: string;
  durations: {
    amount: number;
    columnKey: string;
    isEarning: boolean;
  }[];
};

export const COL_STYLE: { [key: string]: CSSProperties } = {
  user: { width: 200, flex: "0 0 auto", paddingLeft: 8 },
  total: { minWidth: 25, fontWeight: "bold" },
  data: { minWidth: 25 },
  surcharge: { width: 60, flex: "0 0 auto", textAlign: "right", justifyContent: "flex-end" },
  wage: { width: 100, flex: "0 0 auto", textAlign: "right", justifyContent: "flex-end" },
};

export type StyledReportCol = ReportCol & {
  style: any;
  className: string;
  isDataColumn?: boolean;
  getCellValue: (col: ReportCol, row: RowData, asDecimal: boolean) => string;
};

const getUserCellValue = (col: ReportCol, row: RowData, adDecimal: boolean): string => row.userName;
const getStaffNumberValue = (col: ReportCol, row: RowData, adDecimal: boolean): string => row.staffNumber || "";

const getWageValue = (col: ReportCol, row: RowData, adDecimal: boolean): string => {
  const duration = row.durations.find((d) => d.columnKey === "wage");
  return numberToEuroPrice(duration?.amount);
};

const getDurationCellValue = (col: ReportCol, row: RowData, asDecimal: boolean): string => {
  const duration = row.durations.find((d) => d.columnKey === col.key);

  let cellValue: string = asDecimal ? "0" : "0:00";

  if (duration) {
    // const isCorrection = duration.columnKey === getColumnKey(ReportEntryType.creditCorrection);
    const withSign = duration.amount < 0;
    cellValue = asDecimal ? `${minutesToHoursStr(duration.amount)}` : minutesToDuration(duration.amount, { withSign });
  }

  return cellValue;
};

const getTotalCellValue = (col: ReportCol, row: RowData, asDecimal: boolean): string => {
  const duration = row.durations.reduce((acc, val) => acc + ((val.isEarning && val.amount) || 0), 0);
  const withSign = duration < 0;
  return asDecimal ? `${minutesToHoursStr(duration)}` : minutesToDuration(duration, { withSign });
};

export const getEntryColumns = (state: AppState): ReportCol[] => {
  const absenceTypes = state.data.absenceTypes;
  const isV2 = state.data.tenantInfo.isV2;
  const { reportDataTypes } = state.ui.reportSettings;

  // const renderSurchargeCheckboxes = () => [
  //   surchargeTyps.includes("night") && renderCheckbox("nightSurcharge", lg.Nachtzuschlag),
  //   existingSurchargeTypes["holiday"] && renderCheckbox("holidaySurcharge", lg.Feiertagszuschalg),
  //   existingSurchargeTypes["sunday"] && renderCheckbox("sundaySurcharge", lg.Sonntagszuschlag),
  //   existingSurchargeTypes["custom"] && renderCheckbox("customSurcharge", lg.Extrazuschlag),
  // ];

  return [
    {
      key: getColumnKey(ReportEntryType.shift),
      title: isV2 ? lg.zeiterfassung : lg.schichten,
      visible: reportDataTypes.shifts,
    },
    { key: getColumnKey(ReportEntryType.holiday), title: lg.feiertage, visible: reportDataTypes.holidays },
    {
      key: getColumnKey(ReportEntryType.creditCorrection),
      title: lg.korrekturen,
      visible: reportDataTypes.creditCorrections,
    },
    ...absenceTypes.map((at) => ({
      key: getColumnKey(ReportEntryType.absence, at.id),
      title: at.name,
      visible: reportDataTypes.absenceTypeIds.includes(at.id),
    })),
  ].filter((c) => c.visible);
};

export const getCreditColumns = (state: AppState) => {
  const { reportDataTypes } = state.ui.reportSettings;

  return [
    { key: getColumnKey(ReportEntryType.quota), title: lg.sollstunden, visible: reportDataTypes.quota },
    { key: getColumnKey(ReportEntryType.overtime), title: lg.überstunden, visible: reportDataTypes.overtime },
    { key: getColumnKey(ReportEntryType.hourAccount), title: lg.stundenkonto, visible: reportDataTypes.hourAccount },
  ].filter((c) => c.visible);
};

export const getSurchargeColumns = (state: AppState) => {
  const _surcharges = state.data.surcharges;
  const reportColumns = state.ui.reportSettings.reportColumns;
  const surcharges = _.uniqBy(_surcharges, (s) => s.type);

  return surcharges
    .map((sc) => ({
      key: getColumnKey(ReportEntryType.surcharge, sc.type),
      title: sc.shorthand + "%",
      tooltip: sc.name,
      visible: reportColumns[sc.type + "Surcharge"], // nightSurcharge / holidaySurcharge / sundaySurcharge / customSurcharge
    }))
    .filter((c) => c.visible);
};

/**
 * Only returns columns for data types which are included in the report and have not been filtered out.
 */
export const getAllColumns =
  () =>
  (dispatch: DispFn, getState: () => AppState): StyledReportCol[] => {
    const isV2 = getState().data.tenantInfo.isV2;
    const entryColumnNames = getEntryColumns(getState());
    const creditColumns = getCreditColumns(getState());
    const surchargeColumns = getSurchargeColumns(getState());
    const reportColumns = reportColsToV2(getState().ui.reportSettings.reportColumns, isV2);

    return [
      {
        key: "userName",
        title: lg.mitarbeiter,
        style: COL_STYLE.user,
        className: "userCell",
        getCellValue: getUserCellValue,
      },
      {
        key: "staffNumber",
        title: lg.personalnr_punkt,
        style: COL_STYLE.user,
        className: "userCell",
        getCellValue: getStaffNumberValue,
        isHidden: !reportColumns.staffNumber,
      },
      ...entryColumnNames.map((col) => ({
        ...col,
        style: COL_STYLE.data,
        className: "durationCell",
        isDataColumn: true,
        getCellValue: getDurationCellValue,
      })),
      {
        key: "total",
        title: lg.gesamt,
        style: COL_STYLE.total,
        className: "totalCell alignRight",
        isDataColumn: true,
        getCellValue: getTotalCellValue,
      },
      ...creditColumns.map((col) => ({
        ...col,
        style: COL_STYLE.data,
        className: "durationCell alignRight",
        isDataColumn: true,
        getCellValue: getDurationCellValue,
      })),
      {
        key: "wage",
        title: lg.lohn,
        style: COL_STYLE.wage,
        className: "durationCell alignRight",
        isDataColumn: true,
        getCellValue: getWageValue,
        isHidden: !reportColumns.wage,
      },
      ...surchargeColumns.map((col) => ({
        ...col,
        style: COL_STYLE.surcharge,
        className: "surchargeCell",
        isDataColumn: true,
        getCellValue: getDurationCellValue,
      })),
    ].filter((col) => !(col as any).isHidden);
  };

export const getUserRowTotals = (reportData: RowData[]): { [colKey: string]: number } => {
  const sums = {} as any;
  reportData.forEach((rowData) => {
    rowData.durations.forEach((duration) => {
      sums[duration.columnKey] = (sums[duration.columnKey] || 0) + duration.amount;
    });

    const totalEarnings = _.sum(rowData.durations.filter((d) => d.isEarning).map((d) => d.amount));
    sums["total"] = (sums["total"] || 0) + totalEarnings;
  });

  return sums;
};

const getDummyEntries =
  (users: IUser[]) =>
  (dispatch: DispFn, getState: () => AppState): ReportEntry[] => {
    const userDetailMap = selectUserDetailMap(getState());
    return users.map(
      (user) =>
        ({
          userId: user.id,
          userName: decryptUser(user).name,
          staffNumber: userDetailMap[user.id]?.employNum,
          type: ReportEntryType.shift,
          duration: 0,
        } as ReportEntry)
    );
  };

export const getCreditEntries =
  (users: IUser[]) =>
  (dispatch: DispFn, getState: () => AppState): ReportEntry[] => {
    const { reportColumns, reportDataTypes } = getState().ui.reportSettings;
    const usersMap = selectUserMap(getState());
    const userDetailMap = selectUserDetailMap(getState());
    const initialCreditMap = selectInitialCreditByUser(getState());
    const startDate = getState().ui.shifts.reporting.filters.filterStartDate;
    const endDate = getState().ui.shifts.reporting.filters.filterEndDate;
    const userIds = users.map((u) => u.id);

    let entries: ReportEntry[] = [];

    if (reportDataTypes.hourAccount || reportDataTypes.overtime || reportDataTypes.quota) {
      const balanceMap = dispatch(getBalances(userIds, startDate, endDate));

      Object.entries(balanceMap).forEach(([key, creditBalance]) => {
        const userId = key.split("_")[0];
        const date = key.split("_")[1];
        const initialCredit = initialCreditMap[userId] && initialCreditMap[userId].date < endDate;

        const userName = decryptUser(usersMap[userId]).name;
        const staffNumber = userDetailMap[userId]?.employNum;

        reportDataTypes.quota &&
          entries.push({
            userId,
            userName,
            staffNumber,
            date,
            type: ReportEntryType.quota,
            duration: creditBalance.quota,
            isEarning: false,
          });

        reportDataTypes.overtime &&
          entries.push({
            userId,
            userName,
            staffNumber,
            date,
            type: ReportEntryType.overtime,
            duration: creditBalance.credit - creditBalance.quota,
            isEarning: false,
          });

        reportDataTypes.hourAccount &&
          initialCredit &&
          date === endDate &&
          entries.push({
            userId,
            userName,
            staffNumber,
            date: endDate,
            type: ReportEntryType.hourAccount,
            duration: creditBalance.balance,
            isEarning: false,
          });
      });
    }

    return entries;
  };

export const getUserReportData =
  () =>
  (dispatch: DispFn, getState: () => AppState): RowData[] => {
    const userSpecification = getState().ui.reportSettings.reportUserSpecification;
    const sessionInfo = selectSessionInfo(getState());
    const excludedWeekDays = getState().ui.reportSettings.reportExcludedWeekDays;
    const surcharges = getState().data.surcharges;
    const { filterStartDate } = getState().ui.shifts.reporting.filters;
    const activeUsers = selectActiveUsers(getState(), filterStartDate);
    const usersWithShardBranch = selectUsersWithSharedBranch(getState());
    const activeUsersWithSharedBranch = activeUsers.filter(
      (user) => sessionInfo.isAdmin() || usersWithShardBranch.includes(user.id)
    );

    const includingUsers = userSpecification
      ? activeUsersWithSharedBranch.filter((u) => isRuleApplyingToUser(userSpecification, u))
      : activeUsersWithSharedBranch;
    const userIdMap = {}; // mapping the users for better performance

    includingUsers.forEach((user) => (userIdMap[user.id] = true));

    const entries = [
      ...dispatch(getFilteredShiftEntries()),
      ...dispatch(getFilteredExtraEntries()),
      ...dispatch(getCreditEntries(includingUsers)),
      ...dispatch(getDummyEntries(includingUsers)), // need to add dummyEntrys so even users with no entries get displayed
    ]
      .filter(
        (entry) =>
          !excludedWeekDays.length ||
          !excludedWeekDays.includes(getWeekDayString(entry.date)) ||
          !entry.date || // the dummyEntries have no date on purpose
          entry.type === ReportEntryType.hourAccount
      )
      .filter((entry) => userIdMap[entry.userId])
      .map((entry) => ({
        columnKey: getColumnKey(entry.type, entry.absence?.typeId),
        userId: entry.userId,
        userName: entry.userName,
        staffNumber: entry.staffNumber,
        duration: entry.duration,
        isEarning: entry.isEarning,
        raw: entry,
      }));

    // need to hack in entries here, by duplicating the shift-entrys that have surcharges and replacing the columnKey and duration
    const surchargeEntries: any = [];
    surcharges.forEach((sc) => {
      entries
        .filter((entry) => entry.raw[`${sc.type}Surcharge`])
        .forEach((entry) => {
          surchargeEntries.push({
            ...entry,
            columnKey: getColumnKey(ReportEntryType.surcharge, sc.type),
            duration: entry.raw[`${sc.type}Surcharge`],
            isEarning: false,
          });
        });
    });

    // need to hack in entries here, by duplicating the shift-entrys that have wages and replacing the columnKey and duration
    const wageEntries: any = [];
    entries
      .filter((entry) => entry.raw.wage)
      .forEach((entry) => {
        wageEntries.push({ ...entry, columnKey: "wage", duration: entry.raw.wage, isEarning: false });
      });

    const allEntries = [...entries, ...surchargeEntries, ...wageEntries];

    return _(allEntries)
      .groupBy((entry) => entry.userId)
      .map((userEntries) => ({
        userId: userEntries[0].userId,
        userName: userEntries[0].userName,
        staffNumber: userEntries[0].staffNumber,
        durations: _(userEntries)
          .groupBy((entry) => entry.columnKey)
          .map((el) => ({
            amount: _(el).sumBy((el) => el.duration),
            columnKey: el[0].columnKey,
            isEarning: el[0].isEarning,
          }))
          .value(),
      }))
      .value();
  };

type CellDefExt = CellDef & { rowData?: any };

export const exportUserReportAsPdf = (reportData: RowData[]) => async (dispatch: DispFn, getState: () => AppState) => {
  const state = getState();
  const reportFilters = state.ui.shifts.reporting.filters as ReportFilterData;
  const timeFormat = state.ui.reportSettings.reportTimeFormat;
  const asDecimals = timeFormat === TimeFormat.DecimalHours;
  const { filterStartDate, filterEndDate } = reportFilters;
  const randomId = uuid().substr(0, 5);
  const startDate = moment(filterStartDate, SDateFormat).format("L");
  const endDate = moment(filterEndDate, SDateFormat).format("L");
  const doc = new jsPDF("l");
  doc.setFontSize(12);
  const title = `Auswertung (${startDate} - ${endDate}) ${randomId}`;
  doc.text(title, 6, 10);
  const columns = dispatch(getAllColumns());

  const fixedCellWidthForKeys = {
    //["Id"]: 50,
    // [lg.datum]: 24,
    [lg.mitarbeiter]: 50,
  };

  const headCells: CellDefExt[] = columns.map((col, i) => {
    const isUserCol = i === 0;
    return {
      content: col.title,
      styles: {
        fillColor: "#000000",
        overflow: "ellipsize",
        fontSize: 9,
        cellWidth: isUserCol ? 45 : 25, // user-column should be wide
      },
    };
  });

  const mainTableRows: CellDefExt[][] = reportData.map((row) => {
    return columns.map((col, i) => ({
      content: col.getCellValue(col, row, asDecimals),
      styles: {
        // fillColor: "#000000",
        overflow: "ellipsize",
        fontSize: 9,
        cellWidth: i === 0 ? 45 : 25, // user-column should be wide
      },
      rowData: columns.map((c) => c.getCellValue(col, row, asDecimals)),
    }));
  });
  const totalPagesExp = "{total_pages_count_string}";
  autoTable(doc, {
    // theme: "",
    head: [headCells],
    body: mainTableRows,
    startY: 15,
    margin: { bottom: 10, left: 5, right: 5, top: 5 },
    // startX: 15,
    didDrawPage: (data) => {
      // Footer
      let str = "Seite " + (doc.internal as any).getNumberOfPages();
      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === "function") {
        str = str + " von " + totalPagesExp;
      }
      doc.setFontSize(10);
      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      const pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      doc.text(str, data.settings.margin.left, pageHeight - 5);
    },
  });
  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }

  doc.save(`${title}.pdf`);
};

export const getUserReportCsv =
  (rows: RowData[], options: { replaceCommas?: boolean } = {}) =>
  async (
    dispatch: DispFn,
    getState: () => AppState
  ): Promise<{
    file: string;
    filename: string;
  }> => {
    const randomId = uuid().substr(0, 5);
    const state = getState();
    const reportFilters = state.ui.shifts.reporting.filters as ReportFilterData;
    const timeFormat = state.ui.reportSettings.reportTimeFormat;
    const asDecimals = timeFormat === TimeFormat.DecimalHours;
    const userDetailMap = selectUserDetailMap(state);
    const rosterSetting = state.data.rosterSettings[0];
    const { filterStartDate, filterEndDate } = reportFilters;
    const customMasterDataFields = rosterSetting.customMasterDataFields || {};
    const reportMasterDataColumns = state.ui.reportSettings.reportMasterDataColumns;
    const startDate = moment(filterStartDate, SDateFormat).format("L");
    const endDate = moment(filterEndDate, SDateFormat).format("L");

    const data = rows.map((row) => {
      let rowData = {};

      dispatch(getAllColumns()).forEach((col) => {
        const val = col.getCellValue(col, row, asDecimals);
        const needToReplaceComma = col.isDataColumn && options?.replaceCommas;
        rowData[col.title] = needToReplaceComma ? val.replace(",", ".") : val;
      });

      // add masterData
      const userDetail = userDetailMap[row.userId] || {};
      const masterDataMap = getMasterDataReportMap(userDetail, customMasterDataFields, reportMasterDataColumns);
      rowData = { ...rowData, ...masterDataMap };

      return rowData;
    });

    return {
      file: unparse(data),
      filename: `${lg.auswertung} (${startDate} - ${endDate}) ${randomId}`,
    };
  };

export const exportUserReportAsCsv = (rows: RowData[]) => async (dispatch: DispFn, getState: () => AppState) => {
  const csv = await dispatch(getUserReportCsv(rows));
  saveDataAs(csv.file, `${csv.filename}.csv`);
};

export const exportUserReportAsExcel = (rows: RowData[]) => async (dispatch: DispFn, getState: () => AppState) => {
  const csv = await dispatch(getUserReportCsv(rows, { replaceCommas: true }));
  const worksheet = XLSX.utils.aoa_to_sheet(parse(csv.file, { dynamicTyping: true }).data as string[][]);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "worksheet");
  XLSX.writeFileXLSX(workbook, csv.filename + ".xlsx", {});
};
