import {
  toMomentUnsafe,
  toSimpleDate,
  toMoment
} from "../../../../../shared/helpers/timeHelpers";
import { batch, useDispatch } from "react-redux";
import { selectDay } from "../../../../../reducers/ui/shifts/roster/selectedDay";
import { selectWeek } from "../../../../../reducers/ui/shifts/roster/selectedWeek";
import { selectMonth } from "../../../../../reducers/ui/shifts/roster/selectedMonth";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";
import { AppState } from "../../../../../types/AppState";
import { Moment } from "moment";

// export const changeRosterStep = (step: number, stepType: number, date: string) => {
//   return (dispatch: DispFn, getState: () => AppState) => {
//     const steps = { 1: "day", 2: "week", 3: "month" };
//     const momDay = toMomentUnsafe(date as string)!.add(step, steps[stepType]);
//     const momWeek = toMomentUnsafe(date as string)!
//       .add(step, steps[stepType])
//       .startOf("isoWeek");

//     const momMonth = toMomentUnsafe(date as string)!
//       .add(step, steps[stepType])
//       .startOf("month");
// updateSelectedDate
// };

export const updateSelectedDate = (date: string) => {
  return (dispatch: DispFn, getState: () => AppState) => {
    batch(() => {
      dispatch(selectDay(toSimpleDate(toMoment(date))));
      dispatch(selectWeek(toSimpleDate(toMoment(date).startOf("isoWeek"))));
      dispatch(selectMonth(toSimpleDate(toMoment(date).startOf("month"))));
    });
  };
};
