import React, { ReactNode, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "./styles.scss";
import { Checkbox, Button, Alert, Select, Icon } from "antd";
import TZModal from "../../../../../components/TZModal/TZModal";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";
import { useSelector } from "../../../../../helpers/redux";
import { JobPositionSelect } from "../../../../../components/ShiftPopup/ShiftPopup/JobPositionSelect/JobPositionSelect";
import { selectActiveJobPositions } from "../../../../../selectors/ActiveJobPositionsSelector";
import { selectActiveWorkSpaces } from "../../../../../selectors/ActiveWorkSpacesSelector";
import AvTimeInput from "../../../../../components/AvTimeInput/AvTimeInput";
import cn from "classnames";
import { setRosterFilter } from "../../../../../reducers/ui/shifts/roster/rosterFilter";
import { closeModal } from "../../../../../actions/modal";
import {
  antDesignSelectFilterSearch,
  orderAddresses,
  orderByName,
} from "../../../../../frontend-core/helpers/frontendHelpers";
import { BasicSelect } from "../../../../../components/BasicSelect/BasicSelect";
import { selectHashtags } from "../../../../../selectors/hashtagSelector";

type Props = {
  onComplete?: () => any;
};

export const RosterFilterPopover = (props: Props) => {
  const dispatch = useDispatch<DispFn>();
  const jobPositoins = useSelector(selectActiveJobPositions);
  const workSpaces = useSelector(selectActiveWorkSpaces);
  const shiftAddress = useSelector((s) => s.data.shiftAddresses).filter((a) => !a.isInactive);
  const hashtags = useSelector(selectHashtags);
  const jobPositionRef = useRef<any>(null);
  const workSpaceRef = useRef(null);
  const addressRef = useRef(null);
  const hashtagRef = useRef(null);
  const rosterFilter = useSelector((state) => state.ui.shifts.roster.rosterFilter);

  const [jobPosIds, setJobPosIds] = useState(rosterFilter.jobPositions);
  const [workSpaceIds, setWorkSpaceIds] = useState(rosterFilter.workSpaces);
  const [addressIds, setAddressIds] = useState(rosterFilter.addresses);
  const [hashtagIds, setHashtagIds] = useState(rosterFilter.hashtags);
  const [timeSpan, setTimeSpan] = useState(rosterFilter.timeSpan);
  const [openDropDown, setOpenDropDown] = useState<"jobPos" | "label" | "address" | "hashtag" | undefined>();

  const renderCancelBtn = (val: any, cancelFunc: any) => (
    <div
      className={cn({ cancelButton: true, disabled: !val?.length })}
      data-rh={lg.filter_entfernen}
      onClick={() => cancelFunc([])}
    >
      <Icon type="close" />
    </div>
  );

  const saveClicked = () => {
    dispatch(
      setRosterFilter({
        jobPositions: jobPosIds,
        workSpaces: workSpaceIds,
        addresses: addressIds,
        hashtags: hashtagIds,
        timeSpan,
      })
    );
    // dispatch(closeModal());
    props.onComplete && props.onComplete();
  };

  return (
    <div className="rosterFilterMain">
      <div className="title" id="rosterFilterClickTarget">
        {lg.dienstplan_filtern}
      </div>
      <div className="rosterFilterModalBody">
        <div className="jobPosSection section">
          {/* <div className="label">Rollen</div> */}
          <div className="content">
            <BasicSelect
              open={openDropDown === "jobPos"}
              onDropdownVisibleChange={(isOpen) => setOpenDropDown(isOpen ? "jobPos" : undefined)}
              id="job-position-filter-select"
              mode="multiple"
              value={jobPosIds}
              ref={jobPositionRef}
              onChange={(values: any[]) => {
                setJobPosIds(values);
                setOpenDropDown(undefined);
              }}
              style={{ width: "100%" }}
              showSearch
              placeholder={lg.alle_rollen}
              options={jobPositoins.map((jP) => ({ value: jP.id, label: jP.name }))}
            />
          </div>
          {renderCancelBtn(jobPosIds, setJobPosIds)}
        </div>
        <div className="workSpaceSection section">
          {/* <div className="label">Labels</div> */}
          <div className="content">
            <BasicSelect
              open={openDropDown === "label"}
              onDropdownVisibleChange={(isOpen) => setOpenDropDown(isOpen ? "label" : undefined)}
              id="work-spaces-filter-select"
              mode="multiple"
              value={workSpaceIds}
              ref={workSpaceRef}
              onChange={(values: any[]) => {
                setWorkSpaceIds(values);
                setOpenDropDown(undefined);
              }}
              style={{ width: "100%" }}
              showSearch
              placeholder={lg.alle_labels}
              options={workSpaces.map((w) => ({ value: w.id, label: w.name }))}
            />
          </div>
          {renderCancelBtn(workSpaceIds, setWorkSpaceIds)}
        </div>
        {!!shiftAddress.length && (
          <div className="addressesSection section">
            {/* <div className="label">Labels</div> */}
            <div className="content">
              <BasicSelect
                open={openDropDown === "address"}
                onDropdownVisibleChange={(isOpen) => setOpenDropDown(isOpen ? "address" : undefined)}
                id="address-filter-select"
                mode="multiple"
                ref={addressRef}
                value={addressIds}
                onChange={(values: any[]) => {
                  setAddressIds(values);
                  setOpenDropDown(undefined);
                }}
                style={{ width: "100%" }}
                showSearch
                placeholder={lg.alle_adressen}
                options={orderAddresses(shiftAddress).map((w) => ({ value: w.id, label: w.name || w.address }))}
              />
            </div>
            {renderCancelBtn(addressIds, setAddressIds)}
          </div>
        )}
        {!!hashtags.length && (
          <div className="hashtagSection section">
            {/* <div className="label">Labels</div> */}
            <div className="content">
              <BasicSelect
                open={openDropDown === "hashtag"}
                onDropdownVisibleChange={(isOpen) => setOpenDropDown(isOpen ? "hashtag" : undefined)}
                id="hashtag-filter-select"
                mode="multiple"
                ref={hashtagRef}
                value={hashtagIds}
                onChange={(values: any[]) => {
                  setHashtagIds(values);
                  setOpenDropDown(undefined);
                }}
                style={{ width: "100%" }}
                showSearch
                placeholder={lg.alle_hashtags}
                options={orderByName(hashtags).map((w) => ({ value: w.id, label: w.name }))}
              />
            </div>
            {renderCancelBtn(hashtagIds, setHashtagIds)}
          </div>
        )}
        <div className="shiftTimeSection section">
          {/* <div className="label">Zeitintervall</div> */}
          <div className="content" style={{ justifyContent: "flex-end", flex: "0 0 auto", marginRight: "auto" }}>
            <AvTimeInput
              style={{ width: "60px" }}
              value={timeSpan[0]}
              onValueChange={(val) => setTimeSpan([val || "", timeSpan[1] || ""])}
            />
            <div className="space">-</div>
            <AvTimeInput
              style={{ width: "60px" }}
              value={timeSpan[1]}
              onValueChange={(val) => setTimeSpan([timeSpan[0] || "", val || ""])}
            />
          </div>
          {renderCancelBtn(timeSpan, setTimeSpan)}
        </div>
      </div>
      <div className="footer">
        <Button
          type="primary"
          disabled={(!!timeSpan[0] && !timeSpan[1]) || (!!timeSpan[1] && !timeSpan[0]) || timeSpan[0]! > timeSpan[1]!}
          onClick={saveClicked}
          children={lg.fertig}
        />
      </div>
    </div>
  );
};
