import React, { PureComponent, MouseEvent } from "react";
import cn from "classnames";
import { Icon } from "antd";
import "./styles.scss";

type Props = {
  column: number;
  enteredDays?: number; // editable days of an entry
  displayDays?: number | string; // read-only days of the cell
  onClick?: (event: MouseEvent<HTMLDivElement, MouseEventInit>) => void;
  color?: "blue";
  icon?: string;
  withSign?: boolean;
};

export default class EntitlementEntryCell extends PureComponent<Props, {}> {
  render() {
    const { enteredDays, displayDays, onClick, column, icon, withSign } = this.props;
    const hasEnteredDays = enteredDays !== undefined;
    const hasdisplayDays = displayDays !== undefined;
    const notClickable = !onClick;

    const columnClassName = `vacAccCol${column}`;
    const dynamicClassNames = cn({
      noEdit: hasEnteredDays,
      hasDefault: hasdisplayDays,
      notClickable,
    });

    const value = hasEnteredDays ? enteredDays : displayDays;

    return (
      <div className={dynamicClassNames + " vacAccountCell vacAccCol " + columnClassName} onClick={onClick}>
        <div className="vacAccCellWrap">
          {(hasEnteredDays || hasdisplayDays) && (
            <div
              className={
                cn({
                  isEntry: hasEnteredDays,
                  isNegative: enteredDays && enteredDays < 0,
                }) + " daysContent"
              }
            >
              <div className="value">
                {!!(value && withSign && value > 0) && "+"}
                {value}
              </div>
              {/* <div className="unit">Tage</div> */}
            </div>
          )}
          {icon && (
            <div className="iconWrapper">
              <Icon type={icon} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
