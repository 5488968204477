import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../../../helpers/redux";
import "./styles.scss";
import { Button, Checkbox, Icon, Input, InputNumber } from "antd";
import TZModal from "../../../../../components/TZModal/TZModal";
import { LocationSearchInput } from "../../../branches/branchPopup/LocationSearchInput";
import { IShiftAddress } from "../../../../../shared/entities/IShiftAddress";
import { v4 as uuid } from "uuid";
import AvColorPicker from "../../../../../components/AvColorPicker/AvColorPicker";
import { pastelColors, strongColors } from "../../../../../frontend-core/helpers/colors";
import _ from "lodash";
import AvFormField from "../../../../../components/AvFormField/AvFormField";
import { closeModal } from "../../../../../actions/modal";
import { busyInjector, BusyInjectorProps } from "../../../../../components/BusyInjector/BusyInjector";
import { shiftAddressRepository } from "../../../../../repositories/shiftAddressRepository";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";
import { decimalSeparatorLocal } from "../../../../../helpers/dateFormatHelper";

const DEFAULT_CLOCKIN_RADIUS_ADDRESS = 50;
const geofencingInfoText =
  lg.geofencing_aktivieren_um_das_mobile_ein_ausstempeln_nur_in_der_nähe_des_adresse_zu_erlauben;
const radiusWarningText =
  lg.es_wird_empfohlen_einen_radius_von_mindestens_radius_metern_anzugeben_um_zu_verhindern_dass_sich_mitarbeiter_mit_ungenauer_smartphone_lokalisierung_nicht_einstempeln_können(
    DEFAULT_CLOCKIN_RADIUS_ADDRESS
  );

type Props = {
  shiftAddress?: IShiftAddress;
  onComplete?: (shfitAddress: IShiftAddress) => void;
};

export const ShiftAddressModal = busyInjector((props: Props & BusyInjectorProps) => {
  const dispatch = useDispatch<DispFn>();
  const branches = useSelector((s) => s.data.branches);
  const isV2 = useSelector((s) => s.data.tenantInfo.isV2);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [isLocationSearchMounted, setIsLocationSearchMounted] = useState(true); // for a hacky way to re-mounte the component > so it reads the initial-state
  const [showConfirmDelete, setConfirmDelete] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [shiftAddress, setShiftAddress] = useState<IShiftAddress>({
    id: uuid(),
    name: "",
    color: _.sample(strongColors)!,
    address: "",
    googlePlaceId: "",
    locationLatitude: "",
    locationlongitude: "",
    isGeofencingEnabled: isV2 ? true : false,
    geofencingMeterRadius: 50,
  });

  useEffect(() => {
    props.shiftAddress && setShiftAddress({ ...props.shiftAddress });
    // remounting the google-address-comp to pass in the initialAddress
    setIsLocationSearchMounted(false);
    setTimeout(() => setIsLocationSearchMounted(true));
  }, []);

  const shiftAddressRef = useRef(shiftAddress);
  shiftAddressRef.current = shiftAddress;

  const updateShiftAddress = (partialShiftAddress: Partial<IShiftAddress>) => {
    const newShiftAddress = { ...shiftAddress, ...partialShiftAddress };
    newShiftAddress.name = (newShiftAddress.name || "").trim();
    setShiftAddress(newShiftAddress);
    setShowWarning(false);
    setTimeout(() => checkAndShowWarning(), 1500);
  };

  const checkAndShowWarning = () => {
    const _shiftAddress = shiftAddressRef.current;
    const showWarning =
      !!_shiftAddress.isGeofencingEnabled &&
      !!_shiftAddress.geofencingMeterRadius &&
      _shiftAddress.geofencingMeterRadius < DEFAULT_CLOCKIN_RADIUS_ADDRESS;
    setShowWarning(showWarning);
  };

  const hasMobileClocking = branches.some((b) => b.allowMobileClocking);

  useEffect(() => {}, []);

  const onLocationBlur = () => {
    setIsEditingAddress(false);
    setIsLocationSearchMounted(false);
    setTimeout(() => setIsLocationSearchMounted(true));
  };

  const onClearAddress = () => {
    updateShiftAddress({
      address: undefined,
      googlePlaceId: undefined,
      locationLatitude: undefined,
      locationlongitude: undefined,
    });
  };

  const deleteClicked = () => {
    if (!showConfirmDelete) {
      setConfirmDelete(true);
      return;
    }
    dispatch(shiftAddressRepository.create({ ...shiftAddress, isInactive: true }));
    dispatch(closeModal());
  };

  const saveClicked = async () => {
    const isCreationMode = !props.shiftAddress;

    const freshAddress = isCreationMode
      ? await dispatch(shiftAddressRepository.create(shiftAddress))
      : await dispatch(shiftAddressRepository.update(shiftAddress));

    props.onComplete && props.onComplete(freshAddress);
    dispatch(closeModal());
  };

  return (
    <TZModal>
      <TZModal.Head>{lg.schicht_adresse}</TZModal.Head>
      <TZModal.Body>
        <div className="shiftAddressPopupMain">
          <div className="fb row" onBlur={onLocationBlur} style={{ minHeight: 55 }}>
            {isLocationSearchMounted && (
              <LocationSearchInput
                setAddressBeingEdited={setIsEditingAddress}
                initialAddress={shiftAddress.address}
                initialPlaceId={shiftAddress.googlePlaceId}
                onClear={onClearAddress}
                onSelect={(a, p, latLng) =>
                  updateShiftAddress({
                    address: a,
                    googlePlaceId: p,
                    locationLatitude: latLng.lat,
                    locationlongitude: latLng.lng,
                  })
                }
              />
            )}
          </div>
          <div className="colorAndNameRow">
            <div className="colorPickerWrapper">
              <AvFormField label={lg.farbe}>
                <AvColorPicker
                  miniVersion
                  colors={[...pastelColors, ...strongColors]}
                  value={shiftAddress.color}
                  onChange={(color: string) => updateShiftAddress({ color })}
                />
              </AvFormField>
            </div>
            <div className="nameInputWrapper">
              <AvFormField label={lg.bezeichnung} description={` (${lg.optional})`}>
                <Input
                  value={shiftAddress.name}
                  onChange={(e) => updateShiftAddress({ name: e.target.value })}
                  placeholder={shiftAddress.address}
                />
              </AvFormField>
            </div>
          </div>
          {hasMobileClocking && (
            <div className="geofencingBox">
              <div className="flex row">
                <Checkbox
                  checked={shiftAddress.isGeofencingEnabled}
                  onChange={() => updateShiftAddress({ isGeofencingEnabled: !shiftAddress.isGeofencingEnabled })}
                  children={lg.geofencing_aktivieren}
                />
                <div className="infoIconWrapper" data-rh={geofencingInfoText}>
                  <Icon type="info-circle" />
                </div>
              </div>

              {!isV2 && (
                <div className="extraInfo">
                  {shiftAddress.isGeofencingEnabled
                    ? lg.schicht_kann_an_der_adresse_und_am_firmenstandort_gestempelt_werden
                    : lg.schicht_kann_von_überall_gestempelt_werden}
                </div>
              )}

              {shiftAddress.isGeofencingEnabled && (
                <div className="fb row aCenter radiusSetting">
                  {lg.entfernung_in_metern_zum_einstempeln}
                  <InputNumber
                    size="small"
                    style={{ marginLeft: "10px", width: 65 }}
                    decimalSeparator={decimalSeparatorLocal}
                    onChange={(m) => updateShiftAddress({ geofencingMeterRadius: m })}
                    value={shiftAddress.geofencingMeterRadius}
                    min={0}
                    max={99000}
                  />
                </div>
              )}
              {showWarning && (
                <div className="row warningBox" style={{ marginTop: 12 }}>
                  {radiusWarningText}
                </div>
              )}
            </div>
          )}
        </div>
      </TZModal.Body>
      <TZModal.Footer>
        {!!props.shiftAddress && (
          <Button
            style={{ marginRight: "auto" }}
            id="delete-address-button"
            type={showConfirmDelete ? "danger" : "ghost"}
            children={showConfirmDelete ? lg.wirklich_löschen : lg.löschen}
            onClick={deleteClicked}
          />
        )}
        <Button
          id="save-branch"
          type="primary"
          disabled={
            isEditingAddress ||
            !shiftAddress.address ||
            (shiftAddress.isGeofencingEnabled && !shiftAddress.geofencingMeterRadius)
          }
          onClick={async () => props.load(saveClicked(), "submitting")}
          loading={props.isLoading("submitting")}
          children={lg.Speichern}
        />
      </TZModal.Footer>
    </TZModal>
  );
});
