import React from "react";
import classNames from "classnames";
import { UserFilterInput } from "../components/UserFilterInput/UserFilterInput";
import "./styles.scss";
import { SortOrderButton } from "../RosterActionBar/SortOrderButton/SortOrderButton";
import { useSelector } from "../../../../helpers/redux";
import { toSimpleDate } from "../../../../shared/helpers/timeHelpers";
import moment from "moment";

type Props = {
  workingHours: number[];
  currentTime?: number;
};

export const RosterDayGridHead = React.memo((props: Props) => {
  const selectedDay = useSelector((s) => s.ui.shifts.roster.selectedDay);
  const isToday = selectedDay === toSimpleDate(moment());

  return (
    <div
      className="dayViewHeadMain fixed-header"
      style={{
        flexDirection: "row",
        overflow: "hidden",
      }}
    >
      <div className="header cell firstColumn userSearchField">
        <UserFilterInput />
        <SortOrderButton />
      </div>

      <div className="hourHead">
        {props.workingHours.map((h, i) => {
          return (
            <div
              key={h as number}
              className={classNames({
                rosterWeekDayHeaderMain: true,
                weekday: true,
                cell: true,
                isNow: props.currentTime === h && isToday,
              })}
            >
              <div className="fb row grow hourHeadCellContent">
                <div className="dayText">
                  {h}
                  <span style={{ opacity: 0.7, fontSize: "75%" }}>:00</span>
                </div>
                {/* <div className="dayText">{h > 9 ? h : "0" + h} </div> */}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});
