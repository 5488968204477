import { ITimeClockSettings } from "./../shared/entities/ITimeClockSettings";
import { BaseRepository } from "../frontend-core/BaseRepository";

// Note that there will always be only one entity in DB so we need to grab the timeClockSettings[0] from store
class TimeClockSettingsRepository extends BaseRepository<ITimeClockSettings> {
  constructor() {
    super("timeClockSettings");
  }
}
export const timeClockSettingsRepository = new TimeClockSettingsRepository();
