import React from "react";
import { useDispatch } from "react-redux";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";
import cn from "classnames";
import "./styles.scss";
import { openModal } from "../../../../../actions/modal";
import { ShiftPopup } from "../../../../../components/ShiftPopup/ShiftPopup/ShiftPopup";
import { IShift } from "../../../../../shared/entities/IShift";
import { useSelector } from "../../../../../helpers/redux";
import { selectShiftOverlappingsMap } from "../../../../../selectors/shiftOverlappingsSelector";
import { selectActiveUsers } from "../../../../../selectors/ActiveUserSelectors";
import { Icon } from "antd";
import { generateOverlappingTooltip } from "../../../../../helpers/general";
import { getUnavailableDuringShift } from "../../../../../helpers/workingTimeHelpers";
import { IJobPosition } from "../../../../../shared/entities/IJobPosition";
import { ShiftWarningIcon } from "../../../../../components/Shift/ShiftWarningIcon/ShiftWarningIcon";
import { ShiftTrackingIcon } from "../../../../../components/Shift/ShiftTrackingIcon/ShiftTrackingIcon";
import { ShiftHandOverIcon } from "../../../../../components/Shift/ShiftHandOverIcon/ShiftWarningIcon";
import { ShiftMoonIcon } from "../../../../../components/Shift/ShiftMoonIcon/ShiftMoonIcon";
import { Map } from "../../../../../shared/types/general";
import { IAbsence } from "../../../../../shared/entities/IAbsence";
import {
  selectBranchMap,
  selectShiftAddressMap,
  selectTimeClockingMap,
  selectTrackingMap,
  selectWorkSpaceMap,
} from "../../../../../selectors/mapSelectors";
import { selectAvailabilites } from "../../../../../selectors/availabilitiesSelector";
import { getBranchColor } from "../../../../../frontend-core/helpers/frontendHelpers";
import { selectLabourLawWarning } from "../../../../../selectors/laborLawWarningSelector";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { getDuration, toDoubleDigit, toSimpleTime } from "../../../../../shared/helpers/timeHelpers";
import { assignedShiftsSelector } from "../../../../../selectors/assignedShiftsSelector";
import { pastelColors } from "../../../../../frontend-core/helpers/colors";
import { OpenShiftIcon } from "../../../../../components/Shift/OpenShiftIcon/OpenShiftIcon";
import { RequiredShiftIcon } from "../../../../../components/Shift/RequiredShiftIcon/RequiredShiftIcon";
import { ITimeClocking } from "../../../../../shared/entities/ITimeClocking";
import { SDateTimeFormat } from "../../../../../shared/helpers/SimpleTime";
import moment from "moment";
import _ from "lodash";
import { selectGroupByJobPosition } from "../../../../../selectors/groupByJobPositionSelector";
import clockedInGifSrc from "./../../../../../frontend-core/assets/clockedinsignal.gif";
import { ShiftPunchingIcon } from "../../../../../components/Shift/ShiftPunchingIcon/ShiftPunchingIcon";
import { selectRosterSettingsByUser } from "../../../../../selectors/rosterSettingsByUserSelector";

type Props = {
  shift: IShift;
  hoursInDay: number[]; // visible hours of day-view for example [8,20]
  getTime: (time: string, format: string) => number;
  absence: IAbsence | undefined;
  fromPreviousDay: boolean;
  displayInSeparateRows: boolean;
  customKey: any;
  jobPosMap: Map<IJobPosition>;
  canClick: boolean;
  selectedDate: string;
  nowSimpleTime: string;
};

export const DayGridShift = React.memo((props: Props) => {
  const { shift, hoursInDay, getTime, absence, fromPreviousDay, displayInSeparateRows, nowSimpleTime } = props;

  const dispatch = useDispatch<DispFn>();
  const overlapping = useSelector(selectShiftOverlappingsMap)[shift.id];
  const date = useSelector((s) => s.ui.shifts.roster.selectedDay);
  const availabilities = useSelector(selectAvailabilites);
  const rosterSettingsByUser = useSelector(selectRosterSettingsByUser);
  const selectedBranch = useSelector((s) => s.ui.selectedBranch);
  const isV2 = useSelector((s) => s.data.tenantInfo.isV2);
  const branchMap = useSelector(selectBranchMap);
  const branch = branchMap[shift.branchId];
  const activeUsers = useSelector((state) => selectActiveUsers(state, date));
  const labourLawWarning = useSelector(selectLabourLawWarning)?.[shift.id];
  const workSpaceMap = useSelector(selectWorkSpaceMap);
  const addressMap = useSelector(selectShiftAddressMap);
  const isUserDeactivated = !!shift.userId && !activeUsers.find((u) => u.id === shift.userId);
  const isUserUnavailable = !!getUnavailableDuringShift(shift, availabilities);
  const tracking = useSelector(selectTrackingMap)[shift.id];
  const canManage = useSelector(selectSessionInfo).hasManagerPermissions();
  const sessinoUser = useSelector(selectSessionInfo).user;
  const assignedUsersAmount = useSelector(assignedShiftsSelector)[shift.id] || 0;
  const groupByJobPosition = useSelector(selectGroupByJobPosition);
  const timeClockingMap = useSelector(selectTimeClockingMap);
  const user = useSelector((state) => state.data.users.find((u) => u.id === shift.userId));
  const handoverRequest = useSelector((state) => state.data.shiftHandOverRequests.find((r) => r.id === shift.id));
  const changeRequest = useSelector((state) => state.data.changeRequests.find((cR) => cR.id === shift.id));
  const wasRecentlyModified = useSelector((state) => state.ui.shifts.roster.recentModifiedShifts.includes(shift.id));

  const userRosterSetting = rosterSettingsByUser[sessinoUser.id];
  const isOwnShift = shift.userId === sessinoUser.id;
  const timeClocking = timeClockingMap[shift.id];

  const oneHourWidth = 100 / hoursInDay.length;

  const startHour = getTime(shift.startTime, "HH");
  let startMinute = getTime(shift.startTime, "mm");
  let endHour = getTime(shift.endTime, "HH") || 24; // 0h get's turned into 24h
  const endMinute = getTime(shift.endTime, "mm");

  const firstHour = hoursInDay[0];
  const lastHour = hoursInDay[hoursInDay.length - 1];
  let startHourIndex = hoursInDay.indexOf(startHour);
  let endHourIndex = hoursInDay.indexOf(endHour);

  const visibleStartTime = toDoubleDigit(firstHour) + ":00";
  const visibleEndTime = toDoubleDigit(lastHour) + ":00";

  const shiftStartTimeOfDay = fromPreviousDay ? "00:00" : shift.startTime;
  const shiftEndTimeOfDay =
    shift.date === props.selectedDate && shift.startTime > shift.endTime ? "24:00" : shift.endTime;

  const visibleShiftStartTime = shiftStartTimeOfDay < visibleStartTime ? visibleStartTime : shiftStartTimeOfDay;
  const visibleShiftEndTime = shiftEndTimeOfDay > visibleEndTime ? visibleEndTime : shiftEndTimeOfDay;

  if (startHourIndex < 0) {
    startHourIndex = 0;
  }

  if (endHour === startHour && endMinute > startMinute) {
    endHourIndex = startHourIndex;
  }

  if (fromPreviousDay) {
    startHourIndex = 0;
    startMinute = 0;
  }

  const shiftPosition = displayInSeparateRows ? "relative" : "absolute";

  const getMinutesWidth = (min) => (((min / 60) * 100) / 100) * oneHourWidth;

  const getLeftPosition = () => {
    const leftHour = startHourIndex * oneHourWidth;
    const leftMinute = getMinutesWidth(startMinute);

    if (startHour < firstHour || fromPreviousDay) {
      return 0;
    }
    return leftHour + leftMinute;
  };

  const getWidth = () => {
    const widthHour = hoursInDay.slice(startHourIndex, endHourIndex).length * oneHourWidth;
    const _startMinute = hoursInDay.indexOf(startHour) < 0 ? 0 : startMinute; // in case the start of the shift is cuttn by the selected time interval
    const widthMinute = getMinutesWidth(endMinute) - getMinutesWidth(_startMinute);

    // If endHour greater then last hour we just subtracting left position from full width
    if (
      !fromPreviousDay &&
      (endHour > lastHour || (endHour === startHour && endMinute < startMinute) || endHour < startHour)
    ) {
      return 100 - getLeftPosition();
    }

    return widthHour + widthMinute;
  };

  const getBreakWidth = () => {
    const shiftDuration = getDuration({ startTime: visibleShiftStartTime, endTime: visibleShiftEndTime });
    return shift.breakStartTime && shift.breakMinutes && (shift.breakMinutes / shiftDuration) * 100;
  };

  const calcBreakWidth = (breakStartTime: string, breakEndTime: string): number => {
    const shiftDuration = getDuration({ startTime: visibleShiftStartTime, endTime: visibleShiftEndTime });
    const breakDuration = getDuration({ startTime: breakStartTime, endTime: breakEndTime });
    return (breakDuration / shiftDuration) * 100;
  };

  const getBreakPos = (breakStartTime: string) => {
    if (!breakStartTime) {
      return 0;
    }
    const shiftWidth = getWidth();
    const minsUntilBreakStart = getDuration({ startTime: shift.startTime, endTime: breakStartTime });
    const widthUntilBreakStart = oneHourWidth * (minsUntilBreakStart / 60);
    return widthUntilBreakStart < shiftWidth && (widthUntilBreakStart / shiftWidth) * 100;
  };

  const onShiftBarClick = (shift: IShift) => {
    dispatch(openModal(ShiftPopup, { shift }));
  };

  const renderComment = () => {
    const shiftWidth = getWidth();

    return shiftWidth >= 20 ? (
      <div className="commentStripe" data-rh={shift.comment}>
        {shift.comment || ""}
      </div>
    ) : (
      <div className="commentIconWrapper">
        <Icon type="message" theme="twoTone" data-rh={shift.comment} data-rh-at="bottom" />
      </div>
    );
  };

  const renderChangeReqIcon = () => (
    <div className="changeRequestIcon" data-rh-at="bottom" data-rh={lg.änderungsanfrage}>
      <Icon type="scissor" />
    </div>
  );

  const renderWarningIcon = () => (
    <ShiftWarningIcon
      key={"warning"}
      overlap={overlapping}
      isUserUnavailable={isUserUnavailable}
      isUserDeactivated={isUserDeactivated}
      labourLawWarning={labourLawWarning}
      user={user}
    />
  );

  const hasWarning =
    !shift.isActiveClocking && (overlapping || isUserUnavailable || isUserDeactivated || labourLawWarning);

  const renderNowClockedInIcon = () => {
    return <img src={clockedInGifSrc} style={{ width: 20, height: 20 }} data-rh={lg.ist_eingestempelt} />;
  };

  const renderIconsGroup = () => {
    const showTrackingIcon = tracking && !hasWarning;
    const showPunchingIconV2 = isV2 && timeClocking?.endTime && !timeClocking.isAccepted && !hasWarning;
    const showChangeReqIcon = changeRequest && !hasWarning;
    const showHandOverIcon = handoverRequest && !hasWarning;
    const doRender = showTrackingIcon || showPunchingIconV2 || showChangeReqIcon || hasWarning;

    return (
      doRender && [
        hasWarning && canManage && renderWarningIcon(),
        showPunchingIconV2 && <ShiftPunchingIcon key="punching" style={{ width: 20, height: 20 }} />,
        showTrackingIcon && <ShiftTrackingIcon tracking={tracking!} />,
        showChangeReqIcon && renderChangeReqIcon(),
        showHandOverIcon && <ShiftHandOverIcon />,
      ]
    );
  };

  const getBreakActivities = (): { start: string; end: string }[] => {
    let breakSlots: { start: string; end: string }[] = [];
    let breakActivities = _.cloneDeep(timeClocking?.breakActivities || []);

    if (breakActivities.length && breakActivities.length % 2) {
      breakActivities.push({ type: "end", time: timeClocking.endTime || nowSimpleTime });
    }

    breakActivities.forEach((a) => {
      a.time < shift.startTime && props.selectedDate === shift.date && (a.time = "24:00");
      a.time > shift.endTime && props.selectedDate > shift.date && (a.time = "00:00");
    });

    // breakActivities.forEach((a) => {
    //   a.time < visibleShiftStartTime && (a.time = visibleShiftStartTime);
    //   a.time > visibleShiftEndTime && (a.time = visibleShiftEndTime);
    // });

    breakActivities = _.orderBy(breakActivities, (a) => a.time);

    breakActivities?.forEach((a) => {
      a.type === "start" && breakSlots.push({ start: a.time } as any);
    });

    breakActivities?.forEach((a) => {
      if (a.type === "end") {
        const nextOne = breakSlots.find((s) => !s.end)!;
        nextOne.end = a.time;
      }
    });

    breakSlots = breakSlots.filter((b) => b.start !== b.end);
    return breakSlots;
  };

  const jobPosition = props.jobPosMap[shift.jobPositionId];
  const workSpace = shift.workSpaceId && workSpaceMap[shift.workSpaceId];
  const address = shift.addressId && addressMap[shift.addressId];
  const isNightShift = shift.startTime > shift.endTime;

  const canSeeComment = isOwnShift || canManage || userRosterSetting.employeesCanSeeShiftCommentsOfCoworker;

  // If shift starts after or before working hours we just hiding it
  if (
    (!fromPreviousDay && !isNightShift && startHour >= lastHour + 1) ||
    (!fromPreviousDay && !isNightShift && endHour <= firstHour) ||
    (!fromPreviousDay && startHour > lastHour) ||
    (fromPreviousDay && endHour <= firstHour)
  ) {
    return null;
  }

  return (
    <div
      key={props.customKey}
      data-key={props.customKey}
      onClick={(e) => {
        e.stopPropagation();
        props.canClick && onShiftBarClick(shift);
      }}
      className={cn({
        shiftBarMain: true,
        userIsAbsent: !!absence,
        glowing: wasRecentlyModified,
        isRequirement: shift.isRequirement,
        isRequirementComplete: (shift.requiredUsersAmount || 0) <= assignedUsersAmount,
      })}
      style={{
        marginLeft: `${getLeftPosition()}%`,
        width: `${getWidth()}%`,
        minWidth: `2%`,
        position: shiftPosition,
        borderColor: jobPosition.color,
      }}
    >
      {isV2 &&
        getBreakActivities()
          .slice(0, 3)
          .map((b) => (
            <div
              className="breakBar"
              style={{
                marginLeft: `${getBreakPos(b.start)}%`,
                width: `${calcBreakWidth(b.start, b.end)}%`,
              }}
            ></div>
          ))}
      {shift.breakStartTime && getBreakWidth() && !isV2 && (
        <div
          className="breakBar"
          style={{
            marginLeft: `${getBreakPos(shift.breakStartTime)}%`,
            width: `${getBreakWidth()}%`,
          }}
        ></div>
      )}
      <div className="content">
        <div className="shiftBarTop">
          <div className="shiftTimeFrameMain">
            {shift.isActiveClocking ? shift.startTime : `${shift.startTime} / ${shift.endTime}`}
          </div>

          {!shift.isRequirement && !shift.isActiveClocking && (
            <div className="shiftTimeFrameBreakMinutes">{`${shift.breakMinutes}`}</div>
          )}

          <div className="iconsWrapper">
            {!shift.userId && !shift.isRequirement && <OpenShiftIcon shift={shift} />}
            {shift.isRequirement && (
              <RequiredShiftIcon
                assignedUsersAmount={assignedUsersAmount || 0}
                requiredUsersAmount={shift.requiredUsersAmount || 0}
              />
            )}
            {renderIconsGroup()}
            {shift.isActiveClocking && renderNowClockedInIcon()}
          </div>
        </div>
        <div
          className={cn({
            shiftBarBottom: true,
            overlap: overlapping,
          })}
        >
          <div className="leftPart">
            {!selectedBranch && (
              <div className="tag branchIconWrapper" data-rh={branch.name} style={{ color: getBranchColor(branch) }}>
                <Icon type="shop" />
              </div>
            )}
            {(!shift.userId || !groupByJobPosition) && !isV2 && (
              <div
                className="tag"
                style={{
                  color: shift.isRequirement ? "#303037" : jobPosition.color,
                  marginLeft: 2,
                }}
                data-rh={jobPosition.name}
                data-rh-at="bottom"
              >
                {jobPosition?.name}
              </div>
            )}
          </div>
          <div className="rightPart">
            <div className="shiftBarBottomRight">
              {address && (
                <div
                  className="tag"
                  data-rh-at="bottom"
                  data-rh={address!.name || address!.address}
                  style={{
                    background: address!.color,
                    color: pastelColors.includes(address.color) ? "inherit" : "white",
                  }}
                >
                  {address!.name || address!.address}
                </div>
              )}
              {workSpace && (
                <div
                  className="tag"
                  data-rh-at="bottom"
                  data-rh={workSpace!.name}
                  style={{
                    background: workSpace!.color,
                    color: pastelColors.includes(workSpace.color) ? "inherit" : "white",
                  }}
                >
                  {workSpace!.name}
                </div>
              )}

              {isNightShift && <ShiftMoonIcon />}
              {shift.comment && canSeeComment && renderComment()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
