import { CreditBasesMap } from "./creditTypes";
import { Dispatch } from "redux";
import { selectCreditCorrectionsByUserByDate } from "../../selectors/creditCorrectiosByUserByDateSelector";
import { selectHolidayFinder } from "../../selectors/holidayMapSelector";
import { AbsenceEarning } from "../../shared/entities/IAbsenceType";
import { getAbsenceEarningOfDate, getHolidayCredit } from "./creditHelpers";
import { getQuotaOnDate } from "../../shared/helpers/credit";
import { selectContractsByUser } from "../../selectors/contractsByUserSelector";
import { selectInitialCreditByUser } from "../../selectors/initialCreditsByUserSelector";
import { generateDatesList } from "../../shared/helpers/dateHelpers";
import { AppState } from "../../types/AppState";
import { AbsenceStatus } from "../../shared/entities/IAbsence";
import { selectAbsencesByUser } from "../../selectors/absencesByUserSelector";
import { isUserActive } from "../../selectors/ActiveUserSelectors";
import { selectUserMap } from "../../selectors/mapSelectors";

export const getCreditBases =
  (
    ranges: {
      userId: string;
      fromDate: string;
      untilDate: string;
    }[]
  ) =>
  (dispatch: Dispatch, getState: () => AppState): CreditBasesMap => {
    const appState = getState();
    const creditBasesMap: any = {};
    const absencesByUser = selectAbsencesByUser(appState);
    const holidayFinder = selectHolidayFinder(appState);
    const initialCreditByUser = selectInitialCreditByUser(appState);
    const contractsByUser = selectContractsByUser(appState);
    const creditCorrectionsByUserByDate = selectCreditCorrectionsByUserByDate(appState);
    const creditsByUserByDate = appState.data.credits;
    const usersById = selectUserMap(appState);

    ranges.forEach(({ userId, fromDate, untilDate }) => {
      const allUserAbsences = absencesByUser[userId] || [];
      const userAbsences = allUserAbsences.filter((a) => a.status === AbsenceStatus.active);
      const initialCredit = initialCreditByUser[userId];
      const contracts = contractsByUser[userId];
      const singleContract = contracts.length === 1 && contracts[0];
      const dates = generateDatesList(fromDate, untilDate);
      const user = usersById[userId];

      dates.forEach((curDate) => {
        const workedMinutes = creditsByUserByDate[`${userId}_${curDate}`] || 0;
        const validContract =
          singleContract || contracts.find((wp) => wp.validFrom <= curDate && wp.validTo >= curDate)!;

        if (initialCredit && initialCredit.date > curDate) {
          // if a user has an initialCredit, we neglect earnings/quotas before that date
          return;
        }

        //const quota = 0; // getQuotaOnDate(validContract, curDate);
        const quota = getQuotaOnDate(validContract, curDate);

        //// ABSENCE CALCULATION /////
        const absenceOfCurDay = userAbsences.find((a) => a.startDate <= curDate && a.endDate >= curDate);

        const creditInAbsence = !absenceOfCurDay
          ? 0
          : getAbsenceEarningOfDate({
              date: curDate,
              quotaOfDate: quota,
              absence: absenceOfCurDay,
              contract: validContract,
              shiftEarnings: workedMinutes,
            });

        const isHolidayCredited =
          holidayFinder(curDate, validContract.mainBranchId) &&
          validContract.applyQuotaOnHolidays &&
          isUserActive(user, curDate) &&
          (!absenceOfCurDay || absenceOfCurDay.earning === AbsenceEarning.asPlaned);

        const holidayEarning = isHolidayCredited ? getHolidayCredit(validContract, quota, absenceOfCurDay) : 0;

        const correction = creditCorrectionsByUserByDate[userId]?.[curDate]?.minutes || 0;

        // on a day where employee is absent we ignore the planed hours
        //TODO: on halfDay Absences the workedMinutes need to be respected
        const creditByWork = absenceOfCurDay ? 0 : workedMinutes;

        const credit = creditByWork + creditInAbsence + correction + holidayEarning;

        creditBasesMap[`${userId}_${curDate}`] = {
          credit,
          creditInAbsence,
          quota: quota || 0,
        };
      });
    });
    return creditBasesMap;
  };
