import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { openModal } from "../../../../actions/modal";
import { CreditCorrectionModal } from "../../../../components/modals/CreditCorrectionModal/CreditCorrectionModal";
import { Icon, Tooltip, Button } from "antd";
import { CreditShiftEntry } from "./CreditShiftEntry/CreditShiftEntry";
import { CreditAbsenceEntry } from "./CreditAbsenceEntry/CreditAbsenceEntry";
import { CreditHolidayEntry } from "./CreditHolidayEntry/CreditHolidayEntry";
import { visibleShiftsSelector } from "../../../../selectors/publishedShiftsSelector";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { IAbsence, AbsenceStatus } from "../../../../shared/entities/IAbsence";
import { CreditCorrectionType, ICreditCorrection } from "../../../../shared/entities/ICreditCorrection";
import { AbsenceEarning, AbsenceTypeCode, IAbsenceType } from "../../../../shared/entities/IAbsenceType";
import { IHoliday } from "../../../../shared/entities/IHoliday";
import { generateDatesList } from "../../../../shared/helpers/dateHelpers";
import { minutesToHours } from "../../../../shared/helpers/timeHelpers";
import { simpleDateToMoment, minutesToDuration } from "../../../../shared/helpers/timeHelpers";
import { getShiftCredit } from "../../../../shared/helpers/credit";
import { unparse, parse } from "papaparse";
import { saveDataAs } from "../../../../helpers/export";
import { featuresSelector } from "../../../../selectors/FeaturesSelector";
import { selectHolidayFinder } from "../../../../selectors/holidayMapSelector";
import { getHolidayCredit } from "../../../../actions/creditActions/creditHelpers";
import { selectTimeClockingMap, selectTrackingMap, selectUserMap } from "../../../../selectors/mapSelectors";
import { selectAbsencesByUser } from "../../../../selectors/absencesByUserSelector";
import * as XLSX from "xlsx";
import {
  CreditListExportRow,
  creditRowToDuration,
  creditRowToHours,
  exportCreditsAsPdf,
  getCreditRowsSum,
  getExportTitle,
  oneEmptyCreditRow,
  translateCreditRow,
} from "./creditListHelpers";
import _ from "lodash";
import cn from "classnames";
import "./styles.scss";
import { CreditsHourAccountInfo } from "./CreditsHourAccountInfo/CreditsHourAccountInfo";
import { paidFeatureWarning } from "../../../../actions/paidFeatureWarning";
import { isUserActive } from "../../../../selectors/ActiveUserSelectors";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import moment from "moment";
import { ShiftPopup } from "../../../../components/ShiftPopup/ShiftPopup/ShiftPopup";

type AbsenceDay = { date: string; absence: IAbsence; credit: number };

/**
 * needed to make the export() instance method of CreditListComp
 * available to the parent component
 */
export let latestUserListInstance: CreditListComp | undefined;

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  //TODO: write a selector for all this to remove filters!
  const { userId, endDate, startDate } = ownProps;
  const absencesByUser = selectAbsencesByUser(state);

  return {
    selectedUser: selectUserMap(state)[userId]!,
    shifts: visibleShiftsSelector(state).filter((s) => s.userId === userId && s.date <= endDate && s.date >= startDate),
    absences: (absencesByUser[userId] || []).filter(
      (a) => a.startDate <= endDate && a.endDate >= startDate && a.status === AbsenceStatus.active
    ),
    creditCorrections: state.data.creditCorrections.filter(
      (c) => c.userId === userId && c.date <= endDate && c.date >= startDate
    ),
    initialCredit: state.data.creditCorrections.find(
      (c) => c.userId === userId && c.type === CreditCorrectionType.initial
    ),
    someoneHasInitialCredit: state.data.creditCorrections.some((c) => c.type === CreditCorrectionType.initial),
    creditBalances: state.data.creditBalances,
    isV2: state.data.tenantInfo.isV2,
    trackingsMap: selectTrackingMap(state),
    clockingsMap: selectTimeClockingMap(state),
    contracts: state.data.contracts.filter((u) => u.userId === userId),
    absenceTypes: state.data.absenceTypes,
    holidayFinder: selectHolidayFinder(state),
    isAdmin: selectSessionInfo(state).isAdmin(),
    canManage: selectSessionInfo(state).hasManagerPermissions(),
    features: featuresSelector(state),
    showHourAccountInfo: state.ui.shifts.credits.showHourAccountInfo,
    rosterSetting: state.data.rosterSettings[0],
    userMap: selectUserMap(state),
  };
};

type OwnProps = {
  userId: string;
  startDate: string;
  endDate: string;
  reloadCredits: () => void;
  isDataLoading: boolean;
  isNotPublished: boolean;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class CreditListComp extends PureComponent<Props> {
  today: string;
  constructor(props) {
    super(props);
    latestUserListInstance = this;
    this.today = moment().format(SDateFormat);
  }

  componentWillUnmount() {
    latestUserListInstance = undefined;
  }

  isPaid = (absence: IAbsence) => {
    return absence.earning !== AbsenceEarning.none;
  };

  isIllness = (absence: IAbsence) => {
    return AbsenceTypeCode.illness === this.props.absenceTypes.find((type) => type.id === absence.typeId)!.code;
  };

  getAbsenceOnDate = (date: string): IAbsence | undefined => {
    return this.props.absences.find((a) => a.startDate <= date && a.endDate >= date);
  };

  getAbsenceTypeOfAbsence = (absence: IAbsence): IAbsenceType => {
    return this.props.absenceTypes.find((a) => a.id === absence.typeId)!;
  };

  getCorrectionOnDate = (date: string): ICreditCorrection | undefined => {
    return this.props.creditCorrections.find((c) => c.date === date && c.type === CreditCorrectionType.manual);
  };

  getHoliday = (date: string): IHoliday | undefined => {
    const contract = this.getvalidContract(date);
    const absence = this.getAbsenceOnDate(date);
    const quota = this.getQuotaByDay(date);
    return getHolidayCredit(contract, quota, absence)
      ? this.props.holidayFinder(date, contract.mainBranchId)
      : undefined;
  };

  addCorrectionClicked = (date: string) => {
    if (!this.props.features.credits) {
      return this.props.dispatch(paidFeatureWarning());
    }

    this.props.dispatch(
      openModal(CreditCorrectionModal, {
        date,
        userId: this.props.userId,
        type: CreditCorrectionType.manual,
        onUpdateComplete: this.props.reloadCredits,
      })
    );
  };

  wasIllOnDate = (date: string) => {
    return !!this.props.absences.find((a) => this.isIllness(a) && a.startDate <= date && a.endDate >= date);
  };

  correctionClicked = (creditCorrection: ICreditCorrection) => {
    this.props.dispatch(
      openModal(CreditCorrectionModal, {
        date: creditCorrection.date,
        userId: creditCorrection.userId,
        type: creditCorrection.type,
        creditCorrection,
        onUpdateComplete: this.props.reloadCredits,
      })
    );
  };

  addInitialCreditClicked = (date: string) => {
    this.props.dispatch(
      openModal(CreditCorrectionModal, {
        date,
        userId: this.props.userId,
        type: CreditCorrectionType.initial,
        onUpdateComplete: this.props.reloadCredits,
      })
    );
  };

  getvalidContract = (data: string) => this.props.contracts.find((w) => w.validFrom <= data && w.validTo >= data)!;

  getBalanceByDay = (date: string): number => {
    const creditBalance = this.props.creditBalances[`${this.props.userId}_${date}`];
    return creditBalance?.balance || 0;
  };

  getQuotaByDay = (date: string): number => {
    const creditBalance = this.props.creditBalances[`${this.props.userId}_${date}`];
    return creditBalance?.quota || 0;
  };

  getCreditByDay = (date: string): number => {
    const creditBalance = this.props.creditBalances[`${this.props.userId}_${date}`];
    return creditBalance?.credit || 0;
  };

  getOvertimeByDay = (date: string): number => {
    return this.getCreditByDay(date) - this.getQuotaByDay(date);
  };

  getCreditInAbsenceByDay = (date: string): number => {
    const creditBalance = this.props.creditBalances[`${this.props.userId}_${date}`];
    return creditBalance?.creditInAbsence || 0;
  };

  getDateMap = (dates: string[], mapper: (date: string) => any): {} => {
    return dates.reduce((acc, date) => ({ ...acc, [date]: mapper(date) }), {});
  };

  getCsv() {
    //  do not change the name of this method, it is accessed by the parent
    const { startDate, endDate, dispatch } = this.props;
    const data = this.getRowsDenormalized().map(creditRowToHours).map(translateCreditRow);
    const filename = dispatch(getExportTitle(startDate, endDate));
    return { file: unparse(data), filename };
  }

  exportCsv() {
    const csv = this.getCsv();
    saveDataAs(csv.file, `${csv.filename}.csv`);
  }

  exportExcel() {
    const csv = this.getCsv();
    const worksheet = XLSX.utils.aoa_to_sheet(parse(csv.file, { dynamicTyping: true }).data as string[][]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "worksheet");
    XLSX.writeFileXLSX(workbook, csv.filename + ".xlsx", {});
  }

  exportPdf() {
    // do not change the name of this method, it is accessed by the parent
    const { startDate, endDate, dispatch } = this.props;
    const data = this.getRowsDenormalized().map(creditRowToDuration).map(translateCreditRow);
    const title = dispatch(getExportTitle(startDate, endDate));
    exportCreditsAsPdf(data, title);
  }

  getRowsDenormalized(): CreditListExportRow[] {
    const { startDate, endDate, userId, trackingsMap, initialCredit, shifts, dispatch } = this.props;
    const dates = generateDatesList(startDate, endDate);
    const correctionByDate = this.getDateMap(dates, this.getCorrectionOnDate);
    const absenceByDate = this.getDateMap(dates, this.getAbsenceOnDate);

    const rows: CreditListExportRow[] = generateDatesList(startDate, endDate).map((date) => {
      const shiftsOfDate = shifts.filter((s) => s.date === date && !absenceByDate[date]);
      const SollStd = this.getQuotaByDay(date);
      const Arbeitszeit = _.sum(shiftsOfDate.map((s) => getShiftCredit(s, trackingsMap[s.id])));
      const Feiertag = this.getHoliday(date) && !absenceByDate[date] ? this.getQuotaByDay(date) : 0;
      const Abwesend = absenceByDate[date] ? this.getCreditInAbsenceByDay(date) : 0;
      const Korrektur = correctionByDate[date] ? correctionByDate[date]!.minutes : 0;
      const AbwTyp = absenceByDate[date] ? this.getAbsenceTypeOfAbsence(absenceByDate[date]!).name : "-";

      // These get translated in the next step > before pdf / csv generation
      return {
        Datum: simpleDateToMoment(date).format("DD. MMM"),
        SollStd,
        Arbeitszeit,
        Feiertag,
        Abwesend,
        AbwTyp,
        Korrektur,
        Geleistet: Arbeitszeit + Feiertag + Abwesend + Korrektur,
        ÜberStd: this.getOvertimeByDay(date) ? this.getOvertimeByDay(date) : 0,
        StdKonto: initialCredit?.date === date ? initialCredit.minutes : this.getBalanceByDay(date),
      };
    });

    const creditRowSum = dispatch(getCreditRowsSum(rows, { startDate, endDate, userId }));
    return [...rows, oneEmptyCreditRow(rows), creditRowSum];
  }

  render() {
    const {
      startDate,
      endDate,
      canManage,
      isNotPublished,
      initialCredit,
      showHourAccountInfo,
      rosterSetting,
      someoneHasInitialCredit,
      selectedUser,
      trackingsMap,
      clockingsMap,
      isV2,
      dispatch,
      userId,
      userMap,
    } = this.props;

    const colWidth = [120, 100, 280, 100, 120];

    const dates = generateDatesList(startDate, endDate);
    const correctionByDate = this.getDateMap(dates, this.getCorrectionOnDate);
    const absenceByDate = this.getDateMap(dates, this.getAbsenceOnDate);
    const showHourAccount = this.props.features.credits;
    const hourAccountPremiumInfo = lg.das_stundenkonto_ist_nur_in_der_premium_version_verfügbar;
    const hasSeen_hourAccountFunction = rosterSetting.hinting?.hasSeen_hourAccountFunction;

    const user = userMap[userId];

    if (isNotPublished) {
      return null;
    }

    return (
      <div className="creditsListMain">
        {(showHourAccountInfo || (!hasSeen_hourAccountFunction && !someoneHasInitialCredit)) && canManage && (
          <CreditsHourAccountInfo />
        )}
        <div className="fb listHead">
          <div className="headCell" style={{ width: colWidth[0] }}>
            {lg.datum}
          </div>
          <div className="headCell" style={{ width: colWidth[1] }}>
            {lg.soll_std_punkt}
          </div>
          <div className="headCell" style={{ width: colWidth[2] }}>
            {lg.geleistet}
          </div>
          <div className="headCell" style={{ width: colWidth[3] }}>
            {lg.über_std_punkt}
          </div>
          <div className="headCell" style={{ width: colWidth[4] }}>
            {lg.stundenkonto}
          </div>
        </div>
        <div className="fb listBody t">
          {generateDatesList(startDate, endDate).map((date) => {
            const isBeforeIntialCredit = initialCredit && date < initialCredit.date;
            const isInFuture = date > this.today;
            const hideFutureData = isV2 && isInFuture;
            const shiftsOnDate = this.props.shifts.filter((s) => s.date === date);

            return (
              <div className={cn({ ghostDay: isBeforeIntialCredit }) + " fb row dayRow"} key={date}>
                <div className="fb cell" style={{ width: colWidth[0] }}>
                  <div className="date infoCell">{simpleDateToMoment(date).format("dd DD.MMM")}</div>
                </div>
                <div className="fb cell" style={{ width: colWidth[1] }}>
                  <div className="minutesToWork infoCell">{`${minutesToDuration(this.getQuotaByDay(date))}`}</div>
                </div>
                <div
                  className={cn({ hiddenCell: isBeforeIntialCredit }) + " fb cell entriesCell"}
                  style={{ width: colWidth[2] }}
                >
                  <div className="fb entriesWrapper">
                    {!absenceByDate[date] && (
                      <div className="fb shifts">
                        {_.orderBy(shiftsOnDate, (s) => s.startTime).map((s) => (
                          <CreditShiftEntry
                            key={s.id}
                            shift={s}
                            tracking={trackingsMap[s.id]}
                            clocking={clockingsMap[s.id]}
                            reloadCredits={this.props.reloadCredits}
                          />
                        ))}
                      </div>
                    )}
                    <div className="fb vacation">
                      {!!absenceByDate[date] && (
                        <CreditAbsenceEntry
                          date={date}
                          absence={absenceByDate[date]}
                          absenceType={this.getAbsenceTypeOfAbsence(absenceByDate[date]!)}
                          creditInAbsence={this.getCreditInAbsenceByDay(date)}
                          reloadCredits={this.props.reloadCredits}
                        />
                      )}
                    </div>

                    <div className="fb holiday">
                      {!!this.getHoliday(date) && isUserActive(selectedUser, date) && (
                        <CreditHolidayEntry holiday={this.getHoliday(date)!} quota={this.getQuotaByDay(date)} />
                      )}
                    </div>

                    <div className="fb correctionWrapper">
                      {!!correctionByDate[date] && (
                        <div
                          className="fb entry correctionEntry"
                          onClick={() => canManage && this.correctionClicked(correctionByDate[date]!)}
                        >
                          <div className="credit cell">
                            {minutesToDuration(correctionByDate[date]!.minutes, {
                              withSign: true,
                            })}
                          </div>
                          <div className="label cell">{lg.korrektur}</div>
                        </div>
                      )}
                    </div>
                    {canManage && !isInFuture && isV2 && (
                      <div
                        className={cn({
                          addShiftButton: true,
                          mini:
                            absenceByDate[date] ||
                            this.getHoliday(date) ||
                            correctionByDate[date] ||
                            shiftsOnDate.length,
                        })}
                        onClick={() => {
                          dispatch(
                            openModal(ShiftPopup, {
                              shift: { date, userId, branchId: user.branchIds[0] } as any,
                              onUpdateComplete: this.props.reloadCredits,
                            })
                          );
                        }}
                        data-rh={lg.zeiterfassung_hinzufuegen}
                      >
                        +
                      </div>
                    )}
                  </div>
                  <div className="addCorrectionWrapper">
                    {!correctionByDate[date] && // there can only be one correction per day.
                      !isBeforeIntialCredit && (
                        <div
                          data-rh={lg.korrektur_hinzufügen}
                          onClick={() => canManage && this.addCorrectionClicked(date)}
                          className={cn({
                            addCorrectionBtn: true,
                            hasPermission: canManage,
                          })}
                        >
                          <Icon type="form" />
                        </div>
                      )}
                  </div>
                </div>
                <div className="fb cell" style={{ width: colWidth[3] }}>
                  <div className="date infoCell">
                    {!!this.getOvertimeByDay(date) &&
                      !hideFutureData &&
                      minutesToDuration(this.getOvertimeByDay(date), {
                        withSign: true,
                      })}
                  </div>
                </div>
                <div className="fb cell" style={{ width: colWidth[4] }}>
                  {!showHourAccount && (
                    <div data-rh={hourAccountPremiumInfo} className="onlyPremiumMark">
                      ?
                    </div>
                  )}
                  {showHourAccount && initialCredit && (
                    <div className="creditWrapper">
                      {initialCredit.date === date && (
                        <div
                          className="initialCreditEntry"
                          onClick={() => canManage && this.correctionClicked(initialCredit!)}
                        >{`${lg.startwert}: ${minutesToDuration(initialCredit.minutes, {
                          withSign: true,
                        })}`}</div>
                      )}
                      {!hideFutureData && (
                        <div className="creditValue">
                          {minutesToDuration(this.getBalanceByDay(date), {
                            withSign: true,
                          })}
                        </div>
                      )}
                    </div>
                  )}
                  {showHourAccount && !initialCredit && canManage && (
                    <div className="emptyCreditWrapper" style={{ display: "flex" }}>
                      <div className="addInitialCreditBtn">
                        <div
                          id="hour-account-add-initial-value"
                          data-rh={lg.startwert_eintragen}
                          data-rh-at="right"
                          className="addButton"
                          onClick={() => this.addInitialCreditClicked(date)}
                        >
                          ?
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export const CreditList = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(CreditListComp);
