import { Button, Input } from "antd";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import { useSelector } from "../../../../helpers/redux";
import { userThreadInfoRepository } from "../../../../repositories/userThreadInfoRepository";
import { selectUserMap } from "../../../../selectors/mapSelectors";
import { selectRosterSettingsForCurrentUser } from "../../../../selectors/rosterSettingsByUserSelector";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { tierInfoSelector } from "../../../../selectors/TierInfoSelector";
import { selectUserFullMap } from "../../../../selectors/userFullMapSelector";
import { selectUserThreadInfos } from "../../../../selectors/userThreadInfoSelector";
import { IUserThreadInfo } from "../../../../shared/entities/IUserThreadInfo";
import { getChatTitle } from "../../chatHelpers";
import { ChatSidebarMode } from "../../ChatSidebarMode";
import { CreateNewThread } from "../CreateNewThread/CreateNewThread";
import { ThreadsListStyles } from "./styles";
import { ThreadListItem } from "./ThreadListItem";

type Props = {
  setMode: (m: ChatSidebarMode) => void;
  setSelectedThreadId: (id: string) => void;
  selectedThreadId?: string;
};

export const ThreadsList = React.memo((props: Props) => {
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch<DispFn>();
  const sessionInfo = useSelector(selectSessionInfo);
  const userMap = useSelector(selectUserMap);
  const userThreadInfos = useSelector(selectUserThreadInfos);
  const tierInfo = useSelector(tierInfoSelector);

  const rosterSettingsForCurrentUser = useSelector(selectRosterSettingsForCurrentUser);
  const { canChat } = rosterSettingsForCurrentUser;

  const filteredUserThreadInfos = userThreadInfos.filter((t) => {
    const title = getChatTitle(t, userMap, sessionInfo.user.id);
    return title?.toLowerCase().includes(searchText.toLowerCase());
  });

  const sortedUserThreadInfos = _.orderBy(filteredUserThreadInfos, ["lastMessageTimestamp"], ["desc"]);

  useEffect(() => {
    if (!initiallyLoaded && userThreadInfos.length > 0 && !props.selectedThreadId) {
      props.setSelectedThreadId(userThreadInfos[0]?.id);
      setInitiallyLoaded(true);
    }
  }, [sortedUserThreadInfos]);

  return (
    <>
      <div className="fb column shrink" style={ThreadsListStyles.wrapper}>
        {/* Head */}
        <div className="fb row" style={ThreadsListStyles.head}>
          <div className="fb row" style={ThreadsListStyles.headFirstRow}>
            {canChat && <Button onClick={() => props.setMode(ChatSidebarMode.CreateNewThread)} icon="plus" />}
          </div>
          <div className="fb row" style={ThreadsListStyles.headSecondRow}>
            <Input
              placeholder={lg.suche}
              style={{ borderRadius: 50, border: "none" }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>

        {/* Content */}

        <div className="fb column" style={ThreadsListStyles.content}>
          {sortedUserThreadInfos.map((t) => {
            return (
              <ThreadListItem
                key={t.id}
                selectedThreadId={props.selectedThreadId}
                userThreadInfo={t}
                sessionInfo={sessionInfo}
                userMap={userMap}
                setSelectedThreadId={props.setSelectedThreadId}
                isDisabled={tierInfo.isBasic && !userThreadInfos.find((u, index) => u.id === t.id && index <= 2)}
              />
            );
          })}
          {searchText && (
            <div
              style={{ opacity: 0.7, fontWeight: 500, fontSize: 12, textAlign: "center", marginTop: 12, padding: 12 }}
            >
              {lg.suchergebnisse_für_search_text(searchText)}
            </div>
          )}
        </div>
      </div>
    </>
  );
});
