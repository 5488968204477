import React, { ReactNode } from "react";

type RenderProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  placeholder: string;
  maxLength: number;
  ref: React.RefObject<HTMLInputElement>;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
};

type Props = {
  value: string;
  isDuration?: boolean;
  onChange: (v: string | undefined) => void;
  render: (props: RenderProps) => ReactNode;
};

type State = {
  rawValue: string;
};

class _TimeField extends React.Component<Props, State> {
  inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      rawValue: props.value || "",
    };
  }

  componentDidMount() {
    // (this.inputRef.current as HTMLInputElement).focus();
    // (this.inputRef.current as HTMLInputElement).setSelectionRange(0,0);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.value !== this.props.value && nextProps.value !== this.state.rawValue) {
      this.setState({ rawValue: nextProps.value });
    }
  }

  /**
   * Sets the start- or endTime state when entering a new value,
   * and simoultanously does some magic transformations to the value,
   * so the user has some shortcuts for entering a valid time.
   */
  changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const { isDuration } = this.props;
    const previousValue = this.state.rawValue;
    let newValue = value;

    if (isDuration && newValue.charAt(0) === "0") {
      // Don't allow a duration to start with "0", as
      // it only makes sense for
      newValue = "";
    }

    if (!isDuration && previousValue.length === 5 && newValue.length === 6) {
      // dont allow a time-input to be longer than 5 charachters
      return;
    }

    if (!isDuration && previousValue.length === 1 && newValue.length === 2 && !isNaN(Number(newValue.charAt(2)))) {
      // when the user enters the second digit, we automatically add ':'
      newValue = `${newValue}:`;
    }

    if (!isDuration && previousValue.length === 3 && newValue.length === 2) {
      // When the cursor of the user is at "02|" and he enters
      // another number, we automatically add the ":" between "2" and the new digit
      newValue = `${previousValue.substr(0, 1)}`;
    }

    if (newValue.length === 2 && !isNaN(Number(newValue.charAt(0))) && newValue.charAt(1) === ":") {
      // When the user enters "1:", we transform it to "01:"
      newValue = !isDuration ? `0${newValue.charAt(0)}:` : `${newValue.charAt(0)}:`;
    }

    const indexOfSemi = newValue.indexOf(":");
    const hasSemi = indexOfSemi > -1;

    if (
      (!isDuration && isNaN(Number(newValue.substr(0, 2)))) ||
      (isDuration && isNaN(Number(newValue.substr(0, hasSemi ? indexOfSemi : newValue.length - 1))))
    ) {
      // HOURS digits are not numeric
      return;
    }

    if (!isDuration && newValue.length >= 2 && Number(newValue.charAt(0)) > 2) {
      // IUser tries to enter a number outside of the day-hour range (23:59)
      newValue = newValue.charAt(0);
    }

    if (
      (!isDuration && newValue.length >= 3 && (newValue.charAt(2) !== ":" || isNaN(Number(newValue.substr(3, 5))))) ||
      (isDuration && hasSemi && isNaN(Number(newValue.substr(indexOfSemi + 1))))
    ) {
      // TIME digits are not numeric
      return;
    }

    if (!isDuration && Number(newValue.charAt(0)) === 2 && Number(newValue.charAt(1)) > 4) {
      // Second HOURS digit does not represent a valid time in combination
      // with the first HOURS digit
      return;
    }

    if (
      !isDuration &&
      Number(newValue.charAt(0)) === 2 &&
      Number(newValue.charAt(1)) === 4 &&
      (Number(newValue.charAt(3)) || Number(newValue.charAt(3)))
    ) {
      // when '24: ' > and trying to type in something else then 0 after that
      return;
    }

    if (hasSemi && newValue.length > indexOfSemi + 1 && Number(newValue.charAt(indexOfSemi + 1)) > 5) {
      // First MINUTES digit does not represent a valid time
      return;
    }

    if (!isDuration && previousValue.length === 2 && newValue.length === 3 && !isNaN(Number(newValue.charAt(3)))) {
      // When the cursor of the user is at "02|" and he enters
      // another number, we automatically add the ":" between "2" and the new digit
      newValue = `${previousValue}:${newValue.charAt(3)}`;
    }
    if (isDuration && newValue.length === 1 && Number(newValue) > 2) {
      // When the user enters a number from 3 to 9 as the first digit,
      // we automatically assume he means for example 08: (when typing 8)
      newValue = `0${newValue}:`;
      // } else if (hasSemi && (previousValue.length === indexOfSemi + 2 && newValue.length === indexOfSemi + 1)) {
      //   // When the user removes the last char of "00:0"
      //   // we automatically remove the ":" as well, so the focus goes to "00|"
      //   newValue = newValue.substr(0, 2);
    } else if (previousValue.length === indexOfSemi + 1 && newValue.length === indexOfSemi) {
      // When the user removes the last char of "12:"
      // we automatically remove the "2:", so the focus goes to "1|"
      // Keep in mind, that this can only happen, when the user starts to enter
      // 2 digits, and then removes one.
      newValue = newValue.substr(0, 1);
    } else if (isDuration && newValue.length === 2) {
      newValue = newValue + ":";
    }

    this.updateValue(newValue);
  };

  updateValue = (value: string) => {
    this.setState({
      rawValue: value,
    });

    if (value.length === 5) {
      // We trigger an update to the parent,
      // when the entered time has a valid length
      this.props.onChange(value);
    }

    if (!value.length) {
      this.props.onChange(undefined);
    }
  };

  /**
   * When bluring out, we fill up the rest of the empty slots with 0 digits
   */
  blurHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let newValue = value;
    if (value.length === 1) {
      newValue = `0${newValue}:00`;
    }
    if (value.length === 2) {
      newValue = `${newValue}:00`;
    }
    if (value.length === 3) {
      newValue = `${newValue}00`;
    }
    if (value.length === 4) {
      newValue = `${newValue}0`;
    }
    if (newValue !== value) {
      this.updateValue(newValue);
    }
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const current = this.inputRef.current;

    if (!current) {
      return;
    }

    if (current.selectionStart !== 0 || current.selectionEnd !== 0) {
      return;
    }

    if (this.state.rawValue.length > 0) {
      // When the user puts his cursor at the first index
      // of the input field, while there are already some digits
      // then we override the rest the current value by the newly entered
      // digit
      if (!isNaN(Number(event.key))) {
        const key = event.key;
        // We need to call this, after the original
        // onChange handler of the input field gets triggered.
        setTimeout(() => {
          this.updateValue(key);
        });
      }
    }
  };

  handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (this.state.rawValue === "00:00") {
      if (typeof (this.inputRef.current as HTMLInputElement)?.setSelectionRange === "function") {
        (this.inputRef.current as HTMLInputElement)?.setSelectionRange(0, 0);
      }
    }
  };

  render() {
    return this.props.render({
      onChange: this.changeHandler,
      onBlur: this.blurHandler,
      type: "text",
      value: this.state.rawValue,
      placeholder: "00:00",
      maxLength: 5,
      ref: this.inputRef,
      onKeyDown: this.handleKeyDown,
      onFocus: this.handleFocus,
    });
  }
}

export const TimeField = _TimeField;
