import { generateUserRosterSettings } from "../shared/helpers/settingsHelpers";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import { IUserRosterSettings } from "../shared/entities/IRosterSettings";
import { selectSessionInfo } from "./SessionInfoSelector";
import { relevantShiftsOfBranchSelector, relevantShiftsSelector } from "./RelevantShiftsSelector";
import { addSimpleDays } from "../shared/helpers/dateHelpers";
import { getDuration, minutesToTTime, tTimetoMinutes } from "../shared/helpers/timeHelpers";
import { IShift } from "../shared/entities/IShift";
import { string } from "yup";

// const asMinuteTime = (minuteTime: number) => minuteTime <= 1440 ? minuteTime : minuteTime - 1440 // just need the

// export type IShiftWarning = {
//   shift: IShift,
//   text: string,
//   detailedText: string,
// }

type ShiftsByUserByDay = { [userId: string]: { [date: string]: IShift[] } };

const getShiftsByUserByDay = (shifts: IShift[]): ShiftsByUserByDay => {
  const map: ShiftsByUserByDay = {};
  shifts.forEach((s) => {
    if (s.userId) {
      !map[s.userId] && (map[s.userId] = {});
      !map[s.userId][s.date] && (map[s.userId][s.date] = []);
      map[s.userId][s.date] = [...map[s.userId][s.date], s];
    }
  });
  return map;
};

export const selectLabourLawWarning = createSelector(
  [(state: AppState) => state.data.rosterSettings[0], relevantShiftsOfBranchSelector],
  (rosterSettings, relevantShfits) => {
    if (!rosterSettings.warningOnExceedingMaxWorkTime) {
      return {};
    }

    const warningByShfitId = {};
    const minRestMins = (rosterSettings.minRestHoursBetweenShifts || 0) * 60;
    const breakRules = rosterSettings.minimumBreakRules;

    if (minRestMins) {
      const shfitByUserByDate = getShiftsByUserByDay(relevantShfits);
      relevantShfits
        .filter((s) => s.userId)
        .forEach((s) => {
          const nextDay = addSimpleDays(s.date, 1);
          const shiftsOfNextDay = shfitByUserByDate[s.userId!]?.[nextDay];
          if (shiftsOfNextDay) {
            const isNightShifts = s.endTime < s.startTime;
            const endTimeAsMins = tTimetoMinutes(s.endTime);
            const earliestStartTimeAsMins = isNightShifts
              ? endTimeAsMins + minRestMins
              : endTimeAsMins + minRestMins - 1440;

            const earliestStartTime = minutesToTTime(earliestStartTimeAsMins);
            shiftsOfNextDay.forEach((_s) => {
              if (_s.startTime < earliestStartTime) {
                warningByShfitId[_s.id] = `${lg.gesetzliche_ruhezeit_unterschritten}: ${minRestMins / 60} h`;
              }
            });
          }
        });
    }

    if (breakRules) {
      relevantShfits
        .filter((s) => s.userId)
        .forEach((s) => {
          const shiftDuration = getDuration({ startTime: s.startTime, endTime: s.endTime });
          breakRules.forEach((rule) => {
            const ruleDuration = (rule.workingHours || 0) * 60 + (rule.workingMinutes || 0);
            if (shiftDuration > ruleDuration && (!s.breakMinutes || s.breakMinutes < rule.breakMinutes)) {
              warningByShfitId[s.id] = `${lg.gesetzliche_ruhepause_unterschritten}: ${rule.breakMinutes} ${lg.minuten}`;
            }
          });
        });
    }
    return warningByShfitId;
  }
);
