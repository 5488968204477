import { IUserTimeClockSettings } from "./../shared/entities/ITimeClockSettings";
import { generateUserTimeClockSettings } from "./../shared/helpers/settingsHelpers";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";

export const selectTimeClockSettingsByUser = createSelector(
  [(state: AppState) => state.data.users, (state: AppState) => state.data.timeClockSettings[0]],
  (users, timeClockSettings): { [userId: string]: IUserTimeClockSettings } => {
    const timeClockSettingsByUser = {};
    users.forEach((user) => {
      timeClockSettingsByUser[user.id] = generateUserTimeClockSettings(timeClockSettings, user);
    });
    return timeClockSettingsByUser;
  }
);
