import { UserInfo } from "../../../shared/helpers/UserInfo";
import { IShiftHandOverRequest } from "../../../shared/entities/IShiftHandOverRequest";
import { DeviceType, ITimeClocking } from "../../../shared/entities/ITimeClocking";
import { IShift } from "../../../shared/entities/IShift";
import { AppState } from "../../../types/AppState";
import { DispFn } from "../../../frontend-core/types/thunkTypes";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import moment from "moment";
import { getPunchingLog } from "../../../frontend-core/helpers/frontendHelpers";
import { selectTimeClockSettingsOfSesionUser } from "../../../selectors/timeClockSettingsOfSessionUser";

// this only goes for admins & managers
export const isHandoverAcceptButtonDisabled = (handoverRequest: IShiftHandOverRequest, sessionInfo: UserInfo) => {
  const isApproved = !!handoverRequest.approvedByUserId;
  const isUnapproved = !isApproved;
  const isDirectRequest = !!handoverRequest.toUserId;
  const isGroupRequest = !handoverRequest.toUserId;
  const hasJobposId = sessionInfo.user.jobPositionIds.includes(handoverRequest.jobPositionId);
  const isRequestReceiver = handoverRequest.toUserId === sessionInfo.user.id;
  const isEnabled = isApproved || (isGroupRequest && hasJobposId) || (isDirectRequest && isRequestReceiver);

  return !isEnabled;
};

export const shiftToV2Clocking =
  (shift: IShift, prevClocking?: ITimeClocking) => (dispatch: DispFn, getState: () => AppState) => {
    const sessionInfo = selectSessionInfo(getState());
    const sessionUserId = sessionInfo.user.id;
    const canManage = sessionInfo.hasManagerPermissions();
    const settings = selectTimeClockSettingsOfSesionUser(getState());
    const timestamp = moment().unix().toString();
    const isAccepted = (canManage || settings.manualClockingsAreAutopprovedV2) && !!shift.endTime;
    const startEdited = shift.startTime !== prevClocking?.startTime;
    const endEdited = shift.endTime !== prevClocking?.endTime;
    const breakEdited = (shift.breakMinutes || 0) !== (prevClocking?.breakMinutes || 0);
    const manualKey = DeviceType.Manual;

    return {
      id: shift.id,
      startTime: shift.startTime,
      endTime: shift.endTime,
      date: shift.date,
      breakMinutes: shift.breakMinutes,
      branchId: shift.branchId,
      userId: shift.userId!,
      jobPositionId: shift.jobPositionId,
      breakActivities: prevClocking?.breakActivities,
      isAutoClockOut: prevClocking?.isAutoClockOut,
      workSpaceId: shift.workSpaceId,
      comment: shift.comment,
      addressId: shift.addressId,
      hashtagIds: shift.hashtagIds,
      isAccepted,
      logS: startEdited ? getPunchingLog(manualKey, sessionUserId, timestamp) : prevClocking?.logS,
      logE: endEdited ? getPunchingLog(manualKey, sessionUserId, timestamp) : prevClocking?.logE,
      logB: breakEdited ? getPunchingLog(manualKey, sessionUserId, timestamp) : prevClocking?.logB,
    };
  };

export const getPunchingLogDevice = (type: DeviceType) => {
  return {
    M: lg.manuell,
    A: "App",
    B: "Browser",
    T: "Terminal",
  }[type];
};
