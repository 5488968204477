import { isNotifyOnPublishCheckedReducer } from "./isNotifyOnPublishChecked";
import { isMouseOverUserCellsReducer } from "./isMouseOverUserCells";
import { userNameFilter } from "./userNameFilter";
import { rosterWillRerenderReducer } from "./rosterWillRerender";
import { selectedDayReducer } from "./selectedDay";
import { emptyShiftRowsHiddenReducer } from "./emptyShiftRowsHidden";
import { selectedMonthReducer } from "./selectedMonth";
import { combineReducers } from "redux";
import recentModifiedShiftsReducer from "./recentModifiedShifts";
import draggingShiftReducer from "./draggingShift";
import { collapsedJobPositionsReducer } from "./collapsedJobPositions";
import { rosterTemplateModeReducer } from "./rosterTemplateMode";
import { rosterModeReducer } from "./rosterMode";
import { rosterTypeReducer } from "./rosterType";
import { groupByJobPositionsReducer } from "./groupByJobPositions";
import { selectedWeekReducer } from "./selectedWeek";
import { dayRosterTimeSpanReducer } from "./dayRosterTimeSpan";
import expireReducer from "redux-persist-expire";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { isOpenShiftsCollapsedReducer } from "./isOpenShiftsCollapsed";
import { isRequiredShiftsCollapsedReducer } from "./isRequiredShiftsCollapsed";
import { rosterFilterReducer } from "./rosterFilter";
import { isShiftTemplatesOpenReducer } from "./isShiftTemplatesOpen";
import { onlyShowActiveClockingsReducer } from "./onlyShowActiveClockings";

const rosterUIReducer = persistReducer(
  {
    key: "rosterUi",
    storage,
    whitelist: [
      "_groupByJobPosition",
      "collapsedJobPositions",
      "rosterMode",
      "rosterType",
      "dayRosterTimeSpan",
      "isNotifyOnPublishChecked",
      "isRequiredShiftsCollapsed",
      "isOpenShiftsCollapsed",
    ],
  },
  combineReducers({
    groupByJobPosition: groupByJobPositionsReducer, // dont use this directly > better use selectGroupByJobPosition because in V2 its always 'false'
    rosterTemplateMode: rosterTemplateModeReducer,
    recentModifiedShifts: recentModifiedShiftsReducer,
    draggingShift: draggingShiftReducer,
    emptyShiftRowsHidden: emptyShiftRowsHiddenReducer,
    collapsedJobPositions: collapsedJobPositionsReducer,
    onlyShowActiveClockings: onlyShowActiveClockingsReducer,
    selectedWeek: selectedWeekReducer, // is first day of week simpleDate
    selectedMonth: selectedMonthReducer, // is first day of month simpleDate
    selectedDay: selectedDayReducer,
    rosterMode: rosterModeReducer,
    rosterType: rosterTypeReducer,
    dayRosterTimeSpan: dayRosterTimeSpanReducer,
    rosterWillRerender: rosterWillRerenderReducer,
    userNameFilter: userNameFilter,
    isMouseOverUserCells: isMouseOverUserCellsReducer, // used to hide or display ui elements when mouse is over grid-userCells section
    isNotifyOnPublishChecked: isNotifyOnPublishCheckedReducer,
    isOpenShiftsCollapsed: isOpenShiftsCollapsedReducer,
    isRequiredShiftsCollapsed: isRequiredShiftsCollapsedReducer,
    rosterFilter: rosterFilterReducer,
    isShiftTemplatesOpen: isShiftTemplatesOpenReducer,
  })
);

export default rosterUIReducer;
