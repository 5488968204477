import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../../actions/modal";
import { useSelector } from "../../../helpers/redux";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { tierInfoSelector } from "../../../selectors/TierInfoSelector";
import { simpleDateToMoment } from "../../../shared/helpers/timeHelpers";
import "./styles.scss";

// we are doing this, so stripe gets only injected when the user is going to the payment modal
const TierPicker = React.lazy(() => import("../../TierPicker/TierPicker") as any) as any;

type Props = {};

export const UpgradeInfoBox = React.memo((props: Props) => {
  const tierInfo = useSelector(tierInfoSelector);
  const dispatch = useDispatch();
  const sessionInfo = useSelector(selectSessionInfo);
  if (tierInfo.isTrialMode || !tierInfo.isCore || tierInfo.isAlwaysFreePremiumTenant) {
    return null;
  }

  if (!sessionInfo.isAdmin()) {
    return null;
  }

  return (
    <div className="upgradeInfoBoxWrapper" data-rh-at="bottom" onClick={() => dispatch(openModal(TierPicker, {}))}>
      {/* <div className="upgradeInfoBox">{tierInfo.coreUsageNotAllowed ? lg.testphase_abgelaufen : lg.jetzt_upgraden}</div> */}
      <div className="upgradeInfoBox">{lg.jetzt_upgraden}</div>
    </div>
  );
});
