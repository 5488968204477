import { IUser, IUserFull } from "./../shared/entities/IUser";
import { decryptUser } from "./../shared/helpers/userHelpers";
import { UserInfo } from "./../shared/helpers/UserInfo";
import { AppState } from "./../types/AppState";
import { createSelector } from "reselect";

export const isUserActive = (user: IUser, date: string) => {
  // returns the users that are ACIVE or PASSIVE but not DEACTIVATED
  return !new UserInfo(user).isDeactivated(date);
};

export const getActiveUsersAtDate = (users: IUser[], date: string) => {
  return users.filter((user) => isUserActive(user, date));
};

// returns the users that are ACIVE or PASSIVE but not DEACTIVATED
export const selectActiveUsers = createSelector(
  // compare: https://github.com/reduxjs/reselect/issues/272
  [(state: AppState) => state.data.users, (state, date: string) => date],
  (users, date): IUser[] => getActiveUsersAtDate(users, date)
);

// returns the users that are ACIVE or PASSIVE but not DEACTIVATED
export const selectActiveUsersFull = createSelector(
  // compare: https://github.com/reduxjs/reselect/issues/272
  [(state: AppState) => state.data.users, (state, date: string) => date],
  (users, date): IUserFull[] => getActiveUsersAtDate(users, date).map((user) => decryptUser(user))
);
