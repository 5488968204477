import React from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { Col, Row, Button } from "antd";
import _ from "lodash";
import { getUserName } from "../../../shared/helpers/userHelpers";
import { selectUserMap } from "../../../selectors/mapSelectors";
import { envi } from "../../../env";

const mapStateToProps = (state: AppState) => {
  return {
    users: state.data.users,
    usersMap: selectUserMap(state),
  };
};

type State = {
  value: string;
  inputError?: boolean;
};
type OwnProps = {
  onSubmit: (value: string) => boolean;
  errorMessage?: string;
  maxLength?: number;
  userId: string;
  deselectUserId: () => void;
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _PinPad extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: "",
    };
  }

  circleButtonStyles = {
    width: 68,
    height: 68,
    fontSize: 23,
    lineHieght: 65,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 12,
    color: "white",
    border: "solid 1px rgba(255,255,255,0.2)",
    backgroundColor: envi.isV2() ? "#ffffff08" : "rgba(255,255,255,0.2)",
  };

  handleNumberClick = (i: number) => {
    if (this.state.value.length === this.props.maxLength) {
      this.setState({
        value: `${i}`,
      });
    } else {
      if (this.props.maxLength && this.state.value.length === this.props.maxLength - 1) {
        const finalValue = `${this.state.value}${i}`;
        this.setState({
          value: finalValue,
        });
        this.submit(finalValue);
      } else {
        this.setState({
          inputError: undefined,
          value:
            this.props.maxLength && this.state.value.length === this.props.maxLength
              ? this.state.value
              : `${this.state.value}${i}`,
        });
      }
    }
  };

  submit = (value: string = this.state.value) => {
    const success = this.props.onSubmit(value);
    if (!success) {
      this.setState({ inputError: true });
    }
  };

  renderNumberButton = (i: number) => {
    return (
      <Col xs={8} className="fb jCenter aCenter" key={i}>
        <Button
          id="pin-digit"
          type="default"
          shape="circle"
          children={i}
          style={this.circleButtonStyles}
          onClick={() => this.handleNumberClick(i)}
        />
      </Col>
    );
  };

  render() {
    return (
      <>
        <div className="fb row aCenter jStart userBox" style={{ paddingLeft: 66, marginTop: 10 }}>
          <Button
            icon={"arrow-left"}
            size="default"
            onClick={() => {
              this.props.deselectUserId();
            }}
          />
          {getUserName(this.props.usersMap[this.props.userId])}
        </div>
        <div className="pinPad">
          <div className="inputWrapper">
            <input
              autoComplete="off"
              autoFocus
              type="password"
              onChange={(e) => this.setState({ value: e.target.value })}
              value={this.state.value}
              maxLength={this.props.maxLength}
              className={this.state.inputError ? "inputError" : ""}
              onKeyDown={(e) => e.key === "Enter" && this.submit()}
            />
          </div>
          <Row type="flex" gutter={23} style={{ flexWrap: "wrap" }}>
            {_.drop(Array.from(Array(10).keys()), 1).map((i) => {
              return this.renderNumberButton(i);
            })}
            <Col xs={8} className="fb jCenter aCenter">
              <Button
                id="delte-pin-digit"
                type="danger"
                icon="delete"
                disabled={!this.state.value.length}
                shape="circle"
                style={{
                  ...this.circleButtonStyles,
                  background: "#ff4d4f",
                }}
                onClick={() => this.setState({ value: "" })}
              />
            </Col>
            {this.renderNumberButton(0)}
            <Col xs={8} className="fb jCenter aCenter">
              <Button
                id="confirm-pin"
                type="primary"
                icon="check"
                shape="circle"
                style={{ ...this.circleButtonStyles, background: envi.isV2() ? "#1680d5" : "none" }}
                onClick={() => this.submit()}
              />
            </Col>
          </Row>
          {/* <div className="fb aCenter">
          <Button
            size="large"
            style={{
              fontSize: 14,
              marginTop: 12,
              marginBottom: 12,
              background: "rgba(255,255,255,0.14)",
              color: "white",
              borderColor: "rgba(255,255,255,0.5)",
            }}
            id="deselect-user-for-pin-pad"
            children="Zurück"
            onClick={() => this.props.deselectUserId()}
          />
        </div> */}
        </div>
      </>
    );
  }
}

export const PinPad = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(_PinPad);
