import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";
import { Button, Icon, Modal, Switch, notification } from "antd";
import { MoreDropdown } from "./MoreDropdown";
import { busyInjector, BusyInjectorProps } from "../../../../components/BusyInjector/BusyInjector";
import { UnapprovedTrackingsButton } from "./UnapprovedTrackingsButton/UnapprovedTrackingsButton";
import { ShiftApplicationsButton } from "./ShiftApplicationsButton/ShiftApplicationsButton";
import { HandOverRequestsButton } from "./HandOverRequestsButton/HandOverRequestsButton";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { selectIsWeekPlanPublished } from "../../../../selectors/WeekPlanPublishedSelector";
import { Select } from "antd";
import { selectVisibleBranches } from "../../../../selectors/VisibleBranchesSelector";
import { openModal } from "../../../../actions/modal";
import { PublishWeekModal } from "../../../../components/modals/PublishWeekModal/PublishWeekModal";
import { ChangeRequestsButton } from "./ChangeRequestsButton/ChangeRequestsButton";
import cn from "classnames";
import { TimeSpan } from "./TimeSpan";
import { MonthStepper } from "./Steppers/MonthStepper/MonthStepper";
import { DayStepper } from "./Steppers/DayStepper/DayStepper";
import { WeekStepper } from "./Steppers/WeekStepper/WeekStepper";
import { RosterType, setRosterType } from "../../../../reducers/ui/shifts/roster/rosterType";
import { RosterMode } from "../../../../reducers/ui/shifts/roster/rosterMode";
import { RosterViewPicker } from "./RosterViewPicker/RosterViewPicker";
import { selectTrackingRequests } from "../../../../selectors/TrackingRequestSelector";
import { GroupByRoleButton } from "./GroupByRoleButton/GroupByRoleButton";
import { InviteUsersReminder } from "./InviteUsersReminder/InviteUsersReminder";
import { PublishWeekPlanButton } from "./PublishWeekPlanButton/PublishWeekPlanButton";
import { HideShiftRowsButton } from "./HideShiftRowsButton/HideShiftRowsButton";
import { environment } from "../../../../env";
import { sendPost } from "../../../../frontend-core/actions/send";
import { DevClockingBox } from "./DevClockingBox/DevClockingBox";
import { selectRosterSettingsByUser } from "../../../../selectors/rosterSettingsByUserSelector";
import { RosterFilterButton } from "./RosterFilterButton/RosterFilterButton";
import { antDesignSelectFilterSearch } from "../../../../frontend-core/helpers/frontendHelpers";
import _ from "lodash";
import { ShiftTemplatesButton } from "./ShiftTemplatesButton/ShiftTemplatesButton";
import { selectClockingRequestsV2 } from "../../../../selectors/clockingRequestSelectorV2";
import { UnapprovedClockingsButtonV2 } from "./UnapprovedClockingsButtonV2/UnapprovedClockingsButtonV2";
import { CurrentlyClockedInBox } from "./CurrentlyClockedInBox/CurrentlyClockedInBox";
import { selectGroupByJobPosition } from "../../../../selectors/groupByJobPositionSelector";
import { RosterBranchSelect } from "./RosterBranchSelect/RosterBranchSelect";
import { EnterClockingButtonV2 } from "../RosterListV2/EnterClockingButtonV2/EnterClockingButtonV2";
import { ShowOnlyClockedInsButton } from "./ShowOnlyClockedInsButton/ShowOnlyClockedInsButton";

const mapStateToProps = (state: AppState) => {
  const rosterTemplateMode = state.ui.shifts.roster.rosterTemplateMode;
  const isV2 = state.data.tenantInfo.isV2;
  return {
    selectedBranchId: state.ui.selectedBranch,
    branches: state.data.branches,
    sessionInfo: selectSessionInfo(state),
    canManage: selectSessionInfo(state).hasManagerPermissions(),
    isCurrentWeekPlanPublished: selectIsWeekPlanPublished(state),
    rosterTemplateMode,
    visibleBranches: selectVisibleBranches(state),
    generalRosterSettings: state.data.rosterSettings[0],
    rosterSettingsByUser: selectRosterSettingsByUser(state),
    selectedWeek: !rosterTemplateMode.active
      ? state.ui.shifts.roster.selectedWeek
      : rosterTemplateMode?.rosterTemplate?.shiftsStoredAtDate,
    selectedDay: !rosterTemplateMode.active
      ? state.ui.shifts.roster.selectedDay
      : rosterTemplateMode?.rosterTemplate?.shiftsStoredAtDate,
    rosterMode: state.ui.shifts.roster.rosterMode,
    rosterType: state.ui.shifts.roster.rosterType,
    groupByJobPositions: selectGroupByJobPosition(state),
    shiftAddresses: state.data.shiftAddresses,
    trackingRequests: selectTrackingRequests(state),
    clockingRequestsV2: selectClockingRequestsV2(state),
    users: state.data.users,
    isV2,
  };
};

type State = {
  weekPickerIsOpen: boolean;
  currentRosterType: string;
};

type OwnProps = {
  changeBranch: (branchId: string) => void;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class RosterActionBar_ extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      weekPickerIsOpen: false,
      currentRosterType: "Grid",
    };
  }

  handleSetRosterType = (e: any) => {
    const rosterType = e === "Kanban" ? RosterType.Kanban : RosterType.Grid;
    this.setState({ currentRosterType: e }, () => this.props.dispatch(setRosterType(rosterType)));
  };

  render() {
    const { trackingRequests, selectedBranchId, canManage, isV2, sessionInfo, clockingRequestsV2, rosterType } =
      this.props;
    const trackingsInBranch = trackingRequests.filter((t) => !selectedBranchId || t.branchId === selectedBranchId);

    const rosterSettings = sessionInfo.hasManagerPermissions()
      ? this.props.generalRosterSettings
      : this.props.rosterSettingsByUser[sessionInfo.user.id];

    const visibleBranchesOrdered = _.orderBy(this.props.visibleBranches, (o) => `${o.name}`.toLocaleLowerCase());
    const selectableBranches = visibleBranchesOrdered.filter(
      (b) => b.id === this.props.selectedBranchId || !b.isDisabled
    );

    return (
      <div className="rosterActionBarMain">
        <div className="fb row">
          {this.props.rosterMode === RosterMode.Month && <MonthStepper />}
          {this.props.rosterMode === RosterMode.Week && <WeekStepper />}
          {this.props.rosterMode === RosterMode.Day && <DayStepper />}
          {selectableBranches.length > 1 && (isV2 ? canManage : true) && (
            <RosterBranchSelect
              changeBranch={this.props.changeBranch}
              visibleBranches={visibleBranchesOrdered}
              selectableBranches={selectableBranches}
            />
          )}
          {this.props.rosterMode === RosterMode.Day && rosterType === RosterType.Grid && <TimeSpan />}
          {sessionInfo.isAdmin() && this.props.rosterMode !== RosterMode.Day && <InviteUsersReminder />}
          {!isV2 && <PublishWeekPlanButton />}
          {false && !environment.isProd() && <DevClockingBox />}
          {isV2 && canManage && <CurrentlyClockedInBox />}
          {isV2 && !!clockingRequestsV2.length && <UnapprovedClockingsButtonV2 />}
        </div>

        <div className="noGrow fb noShrink row rosterActionBarMainRightSide">
          <div className="actionButtons">
            {canManage && !isV2 && <ShiftTemplatesButton />}
            {canManage && !isV2 && <RosterFilterButton />}
            {canManage && !!trackingsInBranch.length && <UnapprovedTrackingsButton />}
            {canManage && rosterSettings.usersCanApplyToOpenShifts && <ShiftApplicationsButton />}
            {canManage && rosterSettings.usersCanSwapShifts && <HandOverRequestsButton />}
            {canManage && rosterSettings.usersCanMakeChangeRequests && <ChangeRequestsButton />}
            {!isV2 && <GroupByRoleButton />}
            {<HideShiftRowsButton />}
            {<ShowOnlyClockedInsButton />}
            <RosterViewPicker />
          </div>

          {canManage && !isV2 && <MoreDropdown />}
        </div>
      </div>
    );
  }
}

export const RosterActionBar = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(
  busyInjector(RosterActionBar_)
);
