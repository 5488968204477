import { selectRosterSettingsByUser } from "./rosterSettingsByUserSelector";
import { IAvailability } from "../shared/entities/IAvailability";
import { FarFuture } from "../actions/shift";
import { WeekDays } from "../shared/constants/WeekDays";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import { IUserThreadInfo } from "../shared/entities/IUserThreadInfo";

export const selectUserThreadInfos = createSelector(
  [(state: AppState) => state.data._userThreadInfos],
  (userThreadInfos): IUserThreadInfo[] => {
    // There are edge-cases where entities like this get created in the DB, so we filter them out here:
    //
    // "1643886316242_79213" : {
    //   "hasUnseenMessages" : false
    // },
    return userThreadInfos.filter((th) => !!th.id);
  }
);
