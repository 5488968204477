import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import "./styles.scss";
import { Button, Icon } from "antd";
import { EmptyContent } from "../../../components/EmptyContent/EmptyContent";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { openModal } from "../../../actions/modal";
import BusyWrapper from "../../../components/BusyWrapper/BusyWrapper";
import _ from "lodash";
import { HintPopover } from "../../../components/HintPopover/HintPopover";
import { useSelector } from "../../../helpers/redux";
import { useLoading } from "../../../helpers/useLoading";
import { AnnouncementAddEditModal } from "./AnnouncementAddEditModal/AnnouncementAddEditModal";
import { getUserName } from "../../../shared/helpers/userHelpers";
import { selectUserMap } from "../../../selectors/mapSelectors";
import moment from "moment";
import { AnnouncementViewerModal } from "./AnnouncementViewerModal/AnnouncementViewerModal";
import classNames from "classnames";
import { isRuleApplyingToUser } from "../../../shared/helpers/settingsHelpers";
import { rosterSettingsRepository } from "../../../repositories/rosterSettingsRepository";
import { RoleType } from "../../../shared/entities/IUser";
import { announcementRepository } from "../../../repositories/announcementRepository";
import { DispFn } from "../../../frontend-core/types/thunkTypes";
import cn from "classnames";
import { featuresSelector } from "../../../selectors/FeaturesSelector";
import { paidFeatureWarning } from "../../../actions/paidFeatureWarning";

type Props = {};

export const AnnouncementsPaper = (props: Props) => {
  const dispatch = useDispatch<DispFn>();

  const [isLoading, load, setLoading] = useLoading();
  const sessionInfo = useSelector(selectSessionInfo);
  const userMap = useSelector(selectUserMap);
  const rosterSetting = useSelector((s) => s.data.rosterSettings[0]);
  const announcements = useSelector((s) => s.data.announcements);
  const limitToLast = useRef(30);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(false);
  const features = useSelector(featuresSelector);

  const isModuleActive = rosterSetting.canMakeAnnouncements !== undefined;
  const canAnnounce =
    rosterSetting.canMakeAnnouncements &&
    isRuleApplyingToUser(rosterSetting.specifications?.canMakeAnnouncements, sessionInfo.user);

  useEffect(() => {
    fetchData(limitToLast.current);
  }, []);

  const fetchData = async (_limitToLast: number) => {
    const userId = sessionInfo.user.id;
    const repo = announcementRepository;
    const key = "announcementsByUser";

    const announcementsByUser = await load(
      dispatch(repo.addListener({ childNodes: ["announcementsByUser", userId], limitToLast: _limitToLast, key }))
    );
    const announcements = canAnnounce
      ? await load(dispatch(repo.addListener({ filter: ["by", "=", userId], limitToLast: _limitToLast })))
      : [];

    announcements.length === _limitToLast || announcementsByUser.length === _limitToLast
      ? setCanLoadMore(true)
      : setCanLoadMore(false);
  };

  const activateAnnouncemets = useCallback(() => {
    // its just for tenants, that registered before this feature was deployed ( we do this to avoid migration )
    dispatch(
      rosterSettingsRepository.update({
        ...rosterSetting,
        canMakeAnnouncements: true,
        specifications: {
          ...(rosterSetting.specifications || {}),
          canMakeAnnouncements: { roleTypes: [RoleType.admin, RoleType.manager] },
        },
      })
    );
  }, [rosterSetting]);

  const renderModuleInactiveBox = () => (
    // its just undefined for tenants, that registered before this feature was deployed ( we do this to avoid migration )
    <div className="needToActivate">
      <div className="text">
        {lg.ansagen_sind_nachrichten_an_mitarbeiter_die_per_klick_als_gelesen_markiert_werden_müssen}
        <div className="info">{lg.mit_lesebestätigungen_gehen_wichtige_informationen_nicht_mehr_unter}</div>
      </div>
      <div className="activateNowBtn">
        <Button type="primary" onClick={activateAnnouncemets} children={lg.ansagen_modul_aktivieren} />
      </div>
    </div>
  );

  const createAnnouncementClicked = () => {
    !features.announcements && announcements.length >= 5 // allow 5 announcements in any case
      ? dispatch(paidFeatureWarning())
      : dispatch(openModal(AnnouncementAddEditModal, {}));
  };

  const unreadAnnouncements = announcements.filter((a) => a.hasRead === false);
  const unreadCount = unreadAnnouncements.length;

  return (
    <div className="cell announcementsPaper">
      <div className="content">
        <HintPopover
          hideHint={!sessionInfo.hasManagerPermissions()}
          placement="right"
          hint={lg.mitarbeiter_werden_über_neue_ansagen_benachrichtigt_und_müssen_diese_bestätigen}
        >
          <div className="dashboardPaper">
            <div className="title fb row">
              {/* // <Icon type="scissor" style={{ marginRight: "12px", color: "#1a90ff" }} /> */}
              <Icon type="notification" theme="twoTone" style={{ marginRight: "12px", color: "#1a90ff" }} />
              {lg.ansagen}
              {!!unreadCount && (
                <div className="unreadCount">
                  {unreadCount} {lg.ungelesen}
                </div>
              )}
              {canAnnounce && (
                <Button
                  className="createNewAnnouncement"
                  icon="plus"
                  type="primary"
                  onClick={createAnnouncementClicked}
                  data-rh={lg.neue_ansage}
                ></Button>
              )}
            </div>
            <BusyWrapper isBusy={isLoading()} style={{ width: "100%" }}>
              {!isModuleActive && sessionInfo.isAdmin() && renderModuleInactiveBox()}
              {isModuleActive && (
                <div className="body">
                  <EmptyContent show={announcements.length ? false : true} text={lg.keine_ansagen} />
                  {_.orderBy(announcements, ["at"], ["desc"]).map((a, i) => {
                    const hasRead = a.hasRead || a.readByUserId?.[sessionInfo.user.id];
                    const isCreator = sessionInfo.user.id === a.by;
                    const hasToRead = !isCreator && !hasRead;
                    const readByValues = _.values(a.readByUserId);
                    const totalUsers = readByValues.length;
                    const readByUsers = readByValues.filter((r) => !!r).length;
                    const readByDisplay = `${readByUsers} / ${totalUsers}`;
                    const hasReadLabel = hasRead ? lg.gelesen : lg.ungelesen;

                    return (
                      <div
                        className={classNames({ listItem: true, hasToRead })}
                        key={a.id}
                        onClick={() => dispatch(openModal(AnnouncementViewerModal, { id: a.id }))}
                      >
                        <div className="rowOne">
                          <div className={cn({ titleText: true, hasToRead })}>{a.title}</div>
                        </div>

                        <div className="rowTwo">
                          <div className="createdAt">{moment(a.at).format("DD. MMMM")}</div>
                          <div className="createdBy">{`${lg.von_start} ${getUserName(userMap[a.by])}`}</div>
                          {isCreator ? (
                            <div
                              className={cn({ readByCount: true, allRead: readByUsers === totalUsers })}
                              data-rh={lg.gelesen_von}
                            >
                              {readByDisplay}
                            </div>
                          ) : (
                            <div className={cn({ hasReadTag: true, hasRead })}>
                              {hasRead && <Icon type="check" />}
                              <div className={cn({ text: true })}>{hasReadLabel}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  {canLoadMore && (
                    <div
                      className="loadeMoreBtn"
                      onClick={async () => {
                        if (!isLoading()) {
                          limitToLast.current = limitToLast.current + 30;
                          load(fetchData(limitToLast.current));
                        }
                      }}
                    >
                      {lg.weitere_laden}
                    </div>
                  )}
                </div>
              )}
            </BusyWrapper>
          </div>
        </HintPopover>
      </div>
    </div>
  );
};
