import { envi } from "./env";

const Zplano = envi.isV2() ? "Zeitguru" : "Aplano";
const zplano = envi.isV2() ? "zeitguru" : "aplano";

// @See https://textedit.tools/snakecase sneak-caseify
export const lgDictionary = {
  Mitarbeiter: { en: "Employees", de: "Mitarbeiter" },
  Typ: { en: "Type", de: "Typ" },
  Zeitraum: { en: "Dates", de: "Zeitraum" },
  Tage: { en: "days", de: "Tage" },
  effektive_tage: { en: "Effective days", de: "Effektive Tage" },
  Status: { en: "Status", de: "Status" },
  aplano_abwesenheiten_export: {
    en: (prettyStartDate, prettyEndDateDate) => `Aplano Abwesenheiten Export ${prettyStartDate} - ${prettyEndDateDate}`,
    de: (prettyStartDate, prettyEndDateDate) => `Aplano Absences Export ${prettyStartDate} - ${prettyEndDateDate}`,
  },
  zeitguru_abwesenheiten_export: {
    en: (prettyStartDate, prettyEndDateDate) =>
      `Zeitguru Abwesenheiten Export ${prettyStartDate} - ${prettyEndDateDate}`,
    de: (prettyStartDate, prettyEndDateDate) => `Zeitguru Absences Export ${prettyStartDate} - ${prettyEndDateDate}`,
  },
  seite_count: {
    en: (count) => `Page ${count}`,
    de: (count) => `Seite ${count}`,
  },
  von_count: {
    en: "of",
    de: "von",
  },
  durch_die_eingabe_eines_startwertes_wird: {
    de: "Durch die Eingabe eines Startwertes wird ab dem gewählten Datum ein laufendes Stundenkonto geführt.",
    en: "By entering a start value, starting from the selected date, a running hourly account is calculated.",
  },

  trage_in_der_Spalte_Stundenkonto_einen_Startwert_ein: {
    de: () => (
      <>
        Trage in der Spalte <b>Stundenkonto</b> einen Startwert ein, um für diesen Mitarbeiter ein laufendes
        Stundenkonto zu führen.
      </>
    ),
    en: () => (
      <>
        Enter a starting value in the <b>Hour Account</b> column to create a running hourly account for this Employee.
      </>
    ),
  },

  fuer_die_funktion_stundenkonto_ist_ein_upgrade_erforderlich: {
    de: () => (
      <>
        Für die Funktion <b>Stundenkonto</b> ist ein Upgrade erforderlich.
      </>
    ),
    en: () => (
      <>
        To use the <b>hour account</b> feature an upgrade ist needed.
      </>
    ),
  },

  Aplano_Abwesenheiten_Export_PDF: { de: "Aplano_Abwesenheiten_Export.pdf", en: "Aplano_Absences_Export.pdf" },
  Zeitguru_Abwesenheiten_Export_PDF: { de: "Zeitguru_Abwesenheiten_Export.pdf", en: "Zeitguru_Absences_Export.pdf" },

  mitarbeiter_wurden_benachrichtigt: { de: "Mitarbeiter wurden benachrichtigt", en: "Employees have been notified" },
  zahlungsinformationen_aktualisiert: { de: "Zahlungsinformationen aktualisiert", en: "Paymentdetails updated" },
  Speichern: { de: "Speichern", en: "Save" },
  schichtabgabe_akzeptiert: { de: "Schichtabgabe akzeptiert", en: "Shifthandover accepted" },
  schichtabgabe_aufgehoben: { de: "Schichtabgabe aufgehoben", en: "Shifthandover canceled" },
  schichtabgabe_abgelehnt: { de: "Schichtabgabe abgelehnt", en: "Shifthandover rejected" },
  für_diese_funktion_wird_ein_upgrade_benötigt: {
    de: "Für diese Funktion wird ein Upgrade benötigt.",
    en: "This feature requires an upgrade.",
  },
  um_die_funktion_feature_zu_nutzen_brauchst_du_ein_upgrade: {
    de: (f) => `Um die Funktion ${f} zu nutzen, ist ein Upgrade erforderlich.`,
    en: (f) => `To use the ${f} feature you require an upgrade.`,
  },
  auf_grund_einer_überfälligen_zahlung_vom_administrator_wurde_der_account_eingeschränkt: {
    de: "Auf Grund einer überfälligen Zahlung vom Administrator wurde der Account eingeschränkt.",
    en: "Due to an overdue payment from the administrator, the account has been restricted.",
  },
  nur_ein_admin_kann_ein_upgrade_durchführen: {
    de: "Nur ein Admin kann ein Upgrade durchführen",
    en: "Only an admin can upgrade your system",
  },
  jetzt_upgraden: { de: "Jetzt Upgraden", en: "Upgrade now" },
  mitarbeiter_wird_benachrichtigt: {
    de: "Mitarbeiter wird benachrichtigt",
    en: "Employee will get notified",
  },
  mitarbeiter_werden_benachrichtigt: {
    de: "Mitarbeiter werden benachrichtigt",
    en: "Employees will get notified",
  },
  schicht_wurde_verschoben: {
    de: "Schicht wurde verschoben",
    en: "Shift has been moved",
  },
  deine_schicht_vom_prev_date_wurde_auf_den_next_date_verschoben: {
    // ---------------------- AB HIER NICHT ÜBERSEZT
    de: (prevDateDisplay, nextDateDisplay) =>
      `Deine Schicht vom ${prevDateDisplay} wurde auf den ${nextDateDisplay} verschoben`,
    en: (prevDateDisplay, nextDateDisplay) => `Your shift at ${prevDateDisplay} was moved to the ${nextDateDisplay} `,
  },
  schichtzeit_geändert: {
    de: "Schichtzeit geändert",
    en: "Shifttime changed",
  },
  deine_neue_schichtzeit_am_date_ist_time: {
    de: (d, t) => `Deine neue Schichtzeit am ${d} ist: ${t}`,
    en: (d, t) => `Your new shifttime on ${d} is: ${t}`,
  },
  schicht_gelöscht: {
    de: "Schicht gelöscht",
    en: "Shift removed",
  },
  deine_schicht_am_date_wurde_entfernt: {
    de: (date) => `Deine Schicht am ${date} wurde entfernt.`,
    en: (date) => `Your shift from ${date} was removed.`,
  },
  du_wurdest_einer_schicht_zugewiesen: {
    de: "Du wurdest einer Schicht zugewiesen",
    en: "You were assigned to a shift",
  },
  die_schicht_ist_am_date_von_start_bis_end: {
    de: (d, s, e) => `Die Schicht ist am ${d} von ${s} bis ${e}`,
    en: (d, s, e) => `Your shift is on ${d} from ${s} to ${e}`,
  },
  eine_offene_schicht_wurde_erstellt: {
    de: "Eine offene Schicht wurde erstellt.",
    en: "A new open shift is available.",
  },
  bei_interesse_kannst_du_dich_auf_die_offene_schicht_am_date_von_start_bis_end_jetzt_bewerben: {
    de: (d, s, e) => `Bei Interesse kannst du dich auf die offene Schicht am ${d} von ${s} bis ${e} jetzt bewerben.`,
    en: (d, s, e) => `You can now apply for the shift of ${d} [${s} - ${e}].`,
  },
  bewerbung_auf_schicht_angenommen: {
    de: "Bewerbung auf Schicht angenommen",
    en: "Shift application accepted",
  },
  die_schicht_am_date_wurde_dir_zugewiesen: {
    de: (d) => `Die Schicht am ${d} wurde dir zugewiesen.`,
    en: (d) => `The shfit on ${d} was assigned to you.`,
  },
  user_möchte_die_schicht_am_date_time_an_dich_abgeben: {
    de: (u, d, t) => `${u} möchte die Schicht am ${d} (${t}) an dich abgeben.`,
    en: (u, d, t) => `${u} wants to handover his/her shift to you:  ${d} (${t})`,
  },
  user_möchte_die_schicht_am_date_time_abgeben: {
    de: (u, d, t) => `${u} möchte die Schicht am ${d} (${t}) abgeben.`,
    en: (u, d, t) => `${u} would like to handover his/her shift: ${d} (${t})`,
  },
  deine_schicht_am_date_wurde_an_user_abgegeben: {
    de: (d, u) => `Deine Schicht am ${d} wurde an ${u} abgegeben.`,
    en: (d, u) => `Your shift on ${d} was ${u} handed over.`,
  },
  die_schicht_von_user_am_date_wurde_dir_zugewiesen: {
    de: (date, user) => `Die Schicht von ${user} am ${date} wurde dir zugewiesen`,
    en: (date, user) => `The shift of ${user} on ${date} was assigned to you`, ///
  },
  schichtabgabe_angenommen: {
    de: "Schichtabgabe angenommen",
    en: "Shift handover was accepted",
  },
  schichtabgabe_abgehlehnt: {
    de: "Schichtabgabe abgehlehnt",
    en: "Shift handover was rejected",
  },
  from_user_möchte_die_schicht_am_date_an_to_user_abgeben: {
    de: (fromUser, date, toUser) => `${fromUser} möchte die Schicht am ${date} an ${toUser} abgeben`,
    en: (fromUser, date, toUser) => `${fromUser} wants to handover the shift on ${date} to ${toUser}`,
  },
  schichtabgabe_beantragt: {
    de: "Schichtabgabe beantragt",
    en: "Shift handover requested",
  },
  abwesenheitsantrag_wurde_angenommen: {
    de: "Abwesenheitsantrag wurde angenommen",
    en: "Absence request accepted",
  },
  abwesenheitszeitraum_dates: {
    de: (timeFrame) => `Abwesenheitszeitraum: ${timeFrame}`,
    en: (timeFrame) => `Period of absence: ${timeFrame}`,
  },
  schichtabgabe: {
    de: "Schichtabgabe",
    en: "Shift handover",
  },
  abwesenheitsanfrage_wurde_erstellt: {
    de: "Abwesenheitsanfrage wurde erstellt",
    en: "Absence request was made",
  },
  abwesenheitsantrag_wurde_abgelehnt: {
    de: "Abwesenheitsantrag wurde abgelehnt",
    en: "Absence request was rejected",
  },
  abwesenheitszeitraum: {
    de: "Abwesenheitszeitraum",
    en: "Absence period",
  },
  neuer_wochenplan_veröffentlicht: {
    en: "New weekly schedule was published",
    de: "Neuer Wochenplan veröffentlicht",
  },
  änderungen_im_wochenplan: {
    de: "Änderungen im Wochenplan",
    en: "Weekly schedule edited",
  },
  sieh_dir_den_wochenplan_der_kw_week_an_branch: {
    de: (week, branch) => `Sieh dir den Wochenplan der KW - ${week} an. ${branch}`,
    en: (week, branch) => `Check out the weekly schedule CW ${week}. ${branch}`,
  },
  änderungsantrag: {
    en: "Changerequest",
    de: "Änderungsantrag",
  },
  dein_änderungsantrag_für_die_schicht_am_date_wurde_akzeptiert: {
    de: (date) => `Dein Änderungsantrag für die Schicht am ${date} wurde akzeptiert`,
    en: (date) => `Your changerequest for the shift on ${date} was accepted`,
  },
  dein_änderungsantrag_für_die_schicht_am_date_wurde_abgelehnt: {
    de: (date) => `Dein Änderungsantrag für die Schicht am ${date} wurde abgelehnt`,
    en: (date) => `Your changerequest for the shift on ${date} was rejected`,
  },
  user_beantragt_eine_änderung_für_die_schicht_am_date: {
    de: (user, date) => `${user} beantragt eine Änderung für die Schicht am ${date}`,
    en: (user, date) => `${user} is requesting to edit the shift on ${date}`,
  },
  neue_ansage: {
    de: "Neue Ansage",
    en: "New Announcement",
  },
  Neue: {
    de: "Neue",
    en: "New",
  },
  eine_schicht_mit_bewerbungen_kann_nicht_verschoben_werden: {
    de: "Eine Schicht mit Bewerbungen kann nicht verschoben werden.",
    en: "A shift with open applications can not be moved.",
  },
  mitarbeiter_hat_nicht_die_erforderliche_rolle_für_die_schicht: {
    de: "Mitarbeiter hat nicht die erforderliche Rolle für die Schicht",
    en: "Employee does not have the required role for the shift",
  },
  mitarbeiter_gehört_nicht_zum_standort_der_schicht: {
    de: "Mitarbeiter gehört nicht zum Standort der Schicht",
    en: "Employee does not belong to the location of the shif",
  },
  die_rolle_stimmt_nicht_überein: {
    de: "Die Rolle stimmt nicht überein.",
    en: "The role does not match.",
  },
  schichtbewerbung_von_user_abgelehnt: {
    de: (user) => `Schichtbewerbung von ${user} abgelehnt.`,
    en: (user) => `Shift application of ${user} was rejected.`,
  },
  stempeluhr_beendet: {
    de: `Ausgestempelt`,
    en: `Clocked out`,
  },
  pause_gestartet_beendet: {
    de: (type: "start" | "end") => `Pause ${type === "start" ? "gestartet" : "beendet"}.`,
    en: (type: "start" | "end") => `Break ${type === "start" ? "started" : "ended"}.`,
  },
  stempeluhr_started: {
    de: `Stempeluhr gestartet.`,
    en: `Time clock started.`,
  },
  abwesend: {
    de: "Abwesend",
    en: "Absent",
  },
  auf_grund_von_überfälliger_rechnungen_oder_fehlgeschlagenen_lastschriftzahlungen_wurde_der_account_gesperrt_der_kontoinhaber_wurde_per_e_mail_kontaktiert_bei_fragen_wenden_sie_sich_bitte_an_unseren:
    {
      de: () => (
        <>
          Auf Grund von überfälliger Rechnungen oder fehlgeschlagenen Lastschriftzahlungen wurde der Account gesperrt.{" "}
          <b>Der Kontoinhaber wurde per E-Mail kontaktiert.</b> Bei Fragen wenden Sie sich bitte an unseren{" "}
          <a href={envi.isV2() ? "https://www.zeitguru.de/kontakt" : "https://www.aplano.de/kontakt"} target="_blank">
            Kundensupport
          </a>
        </>
      ),
      en: () => (
        /// TODO: replace german domain
        <>
          Due to overdue invoices or failed direct debit payments, the account was blocked.{" "}
          <b>The account holder has been contacted by e-mail.</b> Please contact our{" "}
          <a href={envi.isV2() ? "https://www.zeitguru.de/kontakt" : "https://www.aplano.de/kontakt"} target="_blank">
            customer support if you have any questions
          </a>
        </>
      ),
    },
  bitte_wenden_sie_sich_an_ihrem_manager: {
    de: "Bitte wenden Sie sich an Ihren Manager",
    en: "Please contact your manager",
  },
  zugang_vorübergehend_gesperrt: {
    de: "Zugang vorübergehend gesperrt",
    en: "Access temporarily frozen",
  },
  letzte_abo_zahlung_fehlgeschlagen: {
    de: "Letzte Abo-Zahlung fehlgeschlagen",
    en: "Last subscription payment failed",
  },
  die_letzte_zahlung_für_dein_tier_abo_ist_fehlgeschlagen_wenn_du_bald_keine_validen_zahlungsinformationen_angibst_wird_dein_tier_abo_automatisch_gekündigt:
    {
      de: (tier) =>
        `Die letzte Zahlung für dein ${tier}-Abo ist fehlgeschlagen. Wenn keine validen Zahlungsinformationen angegeben werden, wird das ${tier}-Abo gesperrt.`,
      en: (tier) =>
        `The last payment for your subscription failed. If you do not provide valid payment information soon, your subscription will be automatically canceled.`,
    },
  zahlungsinformationen_aktualisieren: {
    de: "Zahlungsinformationen aktualisieren",
    en: "Update payment information",
  },
  später: {
    de: "Später",
    en: "Later",
  },
  sie_besitzen_nicht_die_notwnedigen_rechte_um_die_seite_zu_öffnen_aus_diesem_grund_wurden_sie_zum_dashboard_weitergeleitet:
    {
      de: "Sie besitzen nicht die notwnedigen Rechte um die Seite zu öffnen. Aus diesem Grund wurden Sie zum Dashboard weitergeleitet.",
      en: "You do not have the necessary rights to open the page. Because of this, you have been redirected to the dashboard.",
    },
  neues_label: {
    de: "Neues Label",
    en: "New label",
  },
  mobile_zugang: {
    de: "Mobile Zugang",
    en: "Mobile access",
  },
  wir_senden_ihnen_einen_link_zum_download_der_mobile_app: {
    de: "Wir senden Ihnen einen Link zum Download der Mobile-App:",
    en: "We will send you a link to download the mobile app:",
  },
  fertig: {
    de: "Fertig",
    en: "Done",
  },
  rufnummer: {
    de: "Rufnummer",
    en: "Phone number",
  },
  ich_brauche_keinen_mobile_zugang: {
    de: "Ich brauche keinen Mobile Zugang",
    en: "I don't need mobile access",
  },
  farbe: {
    de: "Farbe",
    en: "Colour",
  },
  datei_hochladen: {
    de: "Datei Hochladen",
    en: "Upload file",
  },
  schichten_wurden_gelöscht: {
    de: "Schichten wurden gelöscht.",
    en: "Shifts were deleted.",
  },
  bulk_schichten_löschen: {
    de: "Bulk Schichten Löschen",
    en: "Bulk delete shifts",
  },
  von_start: {
    /// TODO: add von_person > english:By
    de: "Von",
    en: "from",
  },
  bis: {
    de: "Bis",
    en: "to",
  },
  alle_mitarbeiter: {
    de: "Alle Mitarbeiter",
    en: "All users",
  },
  alle_standorte: {
    de: "Alle Standorte",
    en: "All locations",
  },
  alle_rollen: {
    de: "Alle Rollen",
    en: "All roles",
  },
  alle_labels: {
    de: "Alle Labels",
    en: "All labels",
  },
  alle_adressen: {
    de: "Alle Adressen",
    en: "All addresses",
  },
  alle_hashtags: {
    de: "Alle Hashtags",
    en: "All hashtags",
  },
  wird_geladen: {
    de: "Wird geladen",
    en: "Is loading",
  },
  bitte_filter_wählen: {
    de: "Bitte Filter wählen",
    en: "Please specify filters",
  },
  filter_anwenden: {
    de: "Filter Anwenden",
    en: "Apply filters",
  },
  keine_schichten_im_filterbereich_vorhanden: {
    de: "Keine Schichten im Filterbereich vorhanden",
    en: "No shifts found with specified filters",
  },
  offen: {
    de: "OFFEN",
    en: "OPEN",
  },
  überfällig: {
    de: "überfällig",
    en: "overdue",
  },
  leider_ist_die_zahlung_zu_dieser_rechnung_von_ihnen_oder_ihrer_bank_zurückgezogen_worden_der_betrag_muss_aus_diesem_grund_nun_überwiesen_werden:
    {
      de: "Leider ist die Zahlung zu dieser Rechnung von Ihnen, oder Ihrer Bank zurückgezogen worden. Der Betrag muss aus diesem Grund nun überwiesen werden.",
      en: "Unfortunately, the payment for this invoice has been withdrawn by you or your bank. The amount must now be transferred for this reason.",
    },
  daten_werden_gelöscht: {
    de: "Daten werden gelöscht",
    en: "Data is being deleted",
  },
  schichten_wirklich_löschen: {
    de: "Schichten wirklich löschen?",
    en: "Confirm delete shifts",
  },
  schichten_löschen: {
    de: "Schichten Löschen",
    en: "Delete shifts",
  },
  stations_modus_beendet: {
    de: "Stations-Modus beendet",
    en: "Time clock terminal closed",
  },
  du_hast_nicht_die_notwendigen_rechte: {
    de: "Du hast nicht die notwendigen Rechte.",
    en: "You dont have sufficient rights.",
  },
  falsches_passwort: {
    de: "Falsches Passwort",
    en: "wrong password",
  },
  stations_modus_beenden: {
    de: "Stations-Modus beenden",
    en: "Exit time clock terminal",
  },
  passwort: {
    de: "Passwort",
    en: "Password",
  },
  e_mail_oder_passwort_falsch: {
    de: "E-Mail oder Passwort falsch",
    en: "Wrong email or password",
  },
  bist_du_sicher_dass_du_deinen_kompletten_firmen_account_unwiederuflich_löschen_möchtes_dann_gib_zur_bestätigung_dein_passwort_ein:
    {
      de: "Bist du sicher, dass du deinen kompletten Firmen-Account unwiederuflich löschen möchtes? Dann gib zur Bestätigung dein Passwort ein.",
      en: "Are you sure that you want to permanently delete your entire company account? Then enter your password to confirm.",
    },
  konto_endgültig_löschen: {
    de: "Konto endgültig löschen",
    en: "Delete account permanently",
  },
  unternehmen_unwiderruflich_löschen: {
    de: "Unternehmen unwiderruflich löschen",
    en: "Delete company account",
  },
  hiermit_wird_der_zugang_endgültig_für_ihr_unternehmen_gelöscht_kein_mitarbeiter_wird_sich_mehr_einloggen_können_alle_daten_werden_unwiderruflich_gelöscht:
    {
      de: "Hiermit wird der Zugang endgültig für Ihr Unternehmen gelöscht. Kein Mitarbeiter wird sich mehr einloggen können. Alle Daten werden unwiderruflich gelöscht.",
      en: "This will permanently delete the access for your company. No employee will be able to log in any more. All data will be irrevocably deleted.",
    },
  hiermit_wird_ihr_abo_zum_ende_des_abrechnungszyklus_gekündigt_und_der_zugang_für_ihr_unternehmen_aufgehoben_kein_mitarbeiter_wird_sich_mehr_einloggen_können:
    {
      de: "Hiermit wird Ihr Abo zum Ende des Abrechnungszyklus gekündigt und der Zugang für Ihr Unternehmen aufgehoben. Kein Mitarbeiter wird sich mehr einloggen können.",
      en: "This will cancel your subscription at the end of the billing cycle and remove access for your company. No employee will be able to log in anymore.",
    },
  bitte_erzählen_sie_uns_welche_funktionen_ihnen_gefehlt_haben: {
    de: "Bitte erzählen Sie uns, welche Funktionen Ihnen gefehlt haben:",
    en: "Please tell us which features you were missing:",
  },
  bitte_erzählen_sie_uns_den_grund_ihrer_kündigung: {
    de: "Bitte erzählen Sie uns den Grund Ihrer Kündigung:",
    en: "Please tell us the reason for your termination:",
  },
  was_hat_ihnen_gefehlt: {
    de: "Was hat Ihnen gefehlt?",
    en: "What have you been missing?",
  },
  abbrechen: {
    de: "Abbrechen",
    en: "Cancel",
  },
  zugang_löschen: {
    de: "Zugang löschen",
    en: "Delete account",
  },
  abo_kündigen: {
    de: "Abo kündigen",
    en: "Cancel subscription",
  },
  auf_das_Zeitkonto: {
    de: "auf das Zeitkonto",
    en: "added to the hour account",
  },
  wirklich_löschen: {
    de: "Wirklich löschen?",
    en: "Confirm delete",
  },
  wirklich_kündigen: {
    de: "Wirklich kündigen?",
    en: "Confirm subscription cancel",
  },
  bestätigung_erforderlich: {
    de: "Bestätigung erforderlich",
    en: "Confirmation needed",
  },
  bitte_bestätigen_sie_das_löschen_des_wochenplans_der_kalenderwoche_week_indem_sie_week_eingeben: {
    de: (week) => (
      <>
        Bitte bestätigen Sie das Löschen des Wochenplans der Kalenderwoche {week}, indem Sie <b>{week}</b> eingeben:
      </>
    ),
    en: (week) => (
      <>
        Please confirm the deletion of the weekly schedule for calendar week {week} by entering <b> {week} </b>:
      </>
    ),
  },
  wochenplan_löschen: {
    de: "Wochenplan löschen",
    en: "Delete weekly schedule",
  },

  zum_erstellen_von_vielene_einträgen_bulk_nutzen: {
    de: "Zum Eintragen von vielen Datensätzen BULK nutzen.",
    en: "Use the BULK create to insert data at once",
  },

  hoppla_es_ist_etwas_schief_gelaufen: {
    de: "Hoppla, es ist etwas schief gelaufen",
    en: "Oops, something went wrong",
  },
  neu_laden: {
    de: "Neu laden",
    en: "Try again",
  },
  ein_fehler_ist_aufgetreten: {
    de: "Ein Fehler ist aufgetreten:",
    en: "An error has occurred:",
  },
  willkommen_bei: {
    de: "Willkommen bei",
    en: "Welcome to",
  },
  einen_augenblick_bitte_ihre_daten_werden_vorgeladen: {
    de: "Einen Augenblick bitte. Ihre Daten werden vorgeladen",
    en: "Just a moment please. Loading account data.",
  },
  angenommen_von: {
    de: "Angenommen von",
    en: "Confirmed by",
  },
  mitarbeiter_bestätigung: {
    de: "Mitarbeiter Bestätigung",
    en: "Colleague confirmation",
  },
  ausstehend: {
    de: "Ausstehend",
    en: "Pending",
  },
  an: {
    de: "An",
    en: "to",
  },
  an_alle_mitarbeiter_mit_der_rolle: {
    de: (role) => `An alle Mitarbeiter mit der Rolle: ${role}`,
    en: (role) => `To all colleagues with same role: ${role}`,
  },
  tipp_zum_anlegen_einer_abwesenheit_kann_der_zeitraum_mit_der_maus_markiert_werden: {
    de: "Tipp: Zum Anlegen einer Abwesenheit kann der Zeitraum mit der Maus markiert werden.",
    en: "Hint: To create an absence, the period can be marked with the mouse.",
  },
  tipp_mit_der_drag_drop_funktion_lassen_sich_dienstpläne_in_kürzester_zeit_erstellen: {
    de: "Tipp: Mit der Drag & Drop Funktion lassen sich Dienstpläne in kürzester Zeit erstellen.",
    en: "Hint: With the drag & drop function, shifts can be moved or copy-pasted",
  },
  verstanden: {
    de: "Verstanden",
    en: "Got it",
  },
  hinweis: {
    de: "Hinweis",
    en: "Hint",
  },
  sie_haben_bereits_die_kosten_für_das_basic_paket_für_diesen_monat_beglichen_daher_wird_nur_der_preisunterschied_zum_pro_paket_auf_ihrer_nächsten_rechnung_hinzugefügt_dies_ermöglicht_ihnen_eine_sofortige_nutzung_des_pro_pakets_nach_dem_upgrade_ohne_eine_überzahlung_zu_verursachen:
    {
      de: "Sie haben bereits die Kosten für das Basic-Paket für diesen Monat beglichen. Daher wird nur der Preisunterschied zum Pro-Paket auf Ihrer nächsten Rechnung einmalig hinzugefügt. Dies ermöglicht Ihnen eine sofortige Nutzung des Pro-Pakets nach dem Upgrade, ohne eine Überzahlung zu verursachen.",
      en: "You have already covered the cost for the Basic package for this month. Therefore, only the price difference to the Pro package will be added to your next invoice. This allows you to immediately use the Pro package after the upgrade, without causing an overpayment.",
    },
  name: {
    de: "Name",
    en: "Name",
  },
  kontoinhaber: {
    de: "Kontoinhaber",
    en: "Account Holder",
  },
  gespeichert: {
    de: "Gespeichert",
    en: "Saved",
  },
  dadurch_wird_dieses_formularfeld_für_alle_mitarbeitern_entfernt_bereits_eingetragene_daten_dieses_feldes_werden_dabei_ebenfalls_gelöscht:
    {
      de: "Dadurch wird dieses Formularfeld für alle Mitarbeitern entfernt. Bereits eingetragene Daten dieses Feldes werden dabei ebenfalls gelöscht!",
      en: "This will remove this form field for all employees. Data already entered in this field will also be deleted!",
    },
  löschen: {
    de: "Löschen",
    en: "Delete",
  },
  entfernen: {
    de: "Entfernen",
    en: "Remove",
  },
  extra_stammdaten_felder: {
    de: "Extra Stammdaten Felder",
    en: "Extra master data fields",
  },
  diese_einstellungen_gelten_für_die_stammdaten_felder_aller_mitarbeiter: {
    de: "Diese Einstellungen gelten für die Stammdaten-Felder aller Mitarbeiter",
    en: "These settings apply to the master data fields of all employees",
  },
  deaktivierte_standorte_werden_im_dienstplan_nicht_mehr_angezeit: {
    de: "Deaktivierte Standorte werden im Dienstplan nicht mehr angezeigt, \n bleiben jedoch in den Auswertungen sichtbar",
    en: "Deaktivierte Standorte werden im Dienstplan nicht mehr angezeigt, \n bleiben jedoch in den Auswertungen sichtbar", // Missing
  },
  text: {
    de: "Text",
    en: "Text",
  },
  datum: {
    de: "Datum",
    en: "Date",
  },
  feld_hinzufügen: {
    de: "Feld Hinzufügen",
    en: "Add field",
  },
  feldname: {
    de: "Feldname",
    en: "Fieldname",
  },
  hinzufügen: {
    de: "Hinzufügen",
    en: "Add",
  },
  zurück: {
    de: "Zurück",
    en: "Back",
  },
  startdatum: {
    de: "Startdatum",
    en: "Start date",
  },
  enddatum: {
    de: "Enddatum",
    en: "End date",
  },
  kommentar: {
    de: "Kommentar",
    en: "Comment",
  },
  bestätigt: {
    de: "Bestätigt",
    en: "Confirmed",
  },
  beantragt: {
    de: "Beantragt",
    en: "Requested",
  },
  dokument_löschen: {
    de: "Dokument löschen",
    en: "Delete document",
  },
  die_datei_file_wirklich_löschen: {
    de: (file) => `Die Datei "${file}" wirklich löschen?`,
    en: (file) => `Confirm the deletion of the file "${file}" ?`,
  },
  anhänge: {
    de: "Anhänge",
    en: "Attachments",
  },
  download: {
    de: "Download",
    en: "Download",
  },
  editieren: {
    de: "Editieren",
    en: "Edit",
  },
  sollstunden: {
    de: "Sollstunden",
    en: "Quota",
  },
  sollstd_durchschnitt: {
    de: "Sollstd. Durchschnitt",
    en: "Mean contract hours",
  },
  sollstunden_durchschnitt: {
    de: "Sollstunden Durchschnitt",
    en: "Mean contract hours",
  },
  planstunden: {
    de: "Planstunden",
    en: "Planned hours",
  },
  unbezahlt: {
    de: "Unbezahlt",
    en: "Unpaid",
  },
  tage_gesamt: {
    de: "Tage gesamt",
    en: "Absent days",
  },
  tage_effektiv: {
    de: "Tage effektiv",
    en: "Effective days",
  },
  manuell_überschrieben: {
    de: "Manuell überschrieben",
    en: "Manually override",
  },
  überschreiben: {
    de: "überschreiben",
    en: "override",
  },
  gutschrift_pro_arbeitstag: {
    de: "Gutschrift pro Arbeitstag",
    en: "Credit per day",
  },
  monatliche_mindestarbeitszeit_eingeben: {
    de: "Monatliche Mindestarbeitszeit eingeben",
    en: "Monthly minimum worktime",
  },
  monatliche_maximalarbeitszeit_eingeben: {
    de: "Monatliche maximale Arbeitszeit eingeben",
    en: "Monthly maximum worktime",
  },
  monatlich_maximale_arbeitsstunden: {
    de: "Monatlich maximale Arbeitsstunden",
    en: "Monthly maximum working hours",
  },
  monatlich_maximale_arbeitsstunden_überschritten: {
    de: "Monatlich maximale Arbeitsstunden überschritten",
    en: "Monthly maximum working hours exceeded",
  },
  abwesenheit: {
    de: "Abwesenheit",
    en: "Absence",
  },
  halbtags: {
    de: "Halbtags",
    en: "Half-day",
  },
  startet_halbtags: {
    de: "Startet Halbtags",
    en: "Starts half-day",
  },
  endet_halbtags: {
    de: "Endet Halbtags",
    en: "Ends half-day", /// until here
  },
  anspruch_wird_überschritten_um_x_tage: {
    de: (days) => `Der Anspruch wird um ${days} Tage überschritten.`,
    en: (days) => `Entitlement is being exceeded by ${days} days.`,
  },
  es_gibt_eine_urlaubssperre_vom_bis: {
    de: (from: string, until: string) => `Urlaubssperre vom ${from} bis zum ${until}.`,
    en: (from: string, until: string) => `There is a vacation ban from ${from} until ${until}.`,
  },
  zeitraum_überschneidet_sich_mit_einer_weiteren_abwesenheit: {
    de: "Zeitraum überschneidet sich mit einer weiteren Abwesenheit.",
    en: "Period overlaps with another absence.",
  },
  anfrage_erstellt_am_date: {
    de: (date) => `Anfrage eingereicht am ${date}`,
    en: (date) => `Request created on ${date}`,
  },
  anfrage_genehmigt_von_am: {
    de: (name, date) => `Genehmigt von ${name} am ${date}`,
    en: (name, date) => `Accepted by ${name} on ${date}`,
  },
  erstellt_von_am: {
    de: (name, date) => `Erstellt von ${name} am ${date}`,
    en: (name, date) => `Created by ${name} on ${date}`,
  },
  zuletzt_editiert_von_am: {
    de: (name, date) => `Zuletzt editiert von ${name} am ${date}`,
    en: (name, date) => `Last edited by ${name} on ${date}`,
  },
  anhang: {
    de: "Anhang",
    en: "Attachment",
  },
  antrag_löschen: {
    de: "Antrag löschen",
    en: "Delete Request",
  },
  ablehnen: {
    de: "Ablehnen",
    en: "Reject",
  },
  genehmigen: {
    de: "Genehmigen",
    en: "Accept",
  },
  general: {
    de: "Allgemein",
    en: "General",
  },
  erweitert: {
    de: "Erweitert",
    en: "Additional",
  },
  beantragen: {
    de: "Beantragen",
    en: "Request",
  },
  löschen_nicht_möglich: {
    de: "Löschen nicht möglich",
    en: "Cannot delete",
  },
  das_löschen_von_bereits_verwendeten_abwesenheitstypen_ist_nicht_möglich: {
    de: "Das Löschen von bereits verwendeten Abwesenheitstypen ist nicht möglich.",
    en: "It is not possible to delete absence types that have already been used.",
  },
  ok: {
    de: "OK",
    en: "OK",
  },
  bereits_erstellte_abwesenheiten_bleiben_von_dieser_änderung_unbetroffen: {
    de: "Bereits erstellte Abwesenheiten bleiben von dieser Änderung unbetroffen.",
    en: "Absences that have already been created remain unaffected by this change.",
  },
  durchschnittliche_sollstunden: {
    de: "Durchschnittliche Sollstunden",
    en: "Mean contract hours",
  },
  durchschnittliche_sollstunden_explenation: {
    de: () => (
      <>
        {" "}
        Arbeitet ein Mitarbeiter zum Beispiel: <br />
        <div className="fb row">
          <div className="days">Mo , Di: </div>
          <b>6 Stunden</b> <br />
        </div>
        <div className="fb row">
          <div className="days">Mi , Do , Fr</div> <b>8 Stunden</b>
        </div>
        <br />
        beträgt die durchschnittlich tägliche Arbeitszeit: <br />( 6 + 6 + 8 + 8 + 8 ) / 5 Tage = 7.2 ={" "}
        <b>7 h und 12 min.</b>
        <br />
        <br />
        Pro abwesendem Arbeitstag erhält der Mitarbeiter 7:12 h gutgeschrieben.
        <br />
        <br />
        Ist der Mitarbeiter von <b>Montag</b> bis <b>Donnerstag</b> abwesend, so werden diesem 7,2 * 4 = 28,8 Stunden
        gutgeschrieben ( = <b>28 h und 48 min</b> ).
      </>
    ),
    en: () => (
      <>
        {" "}
        For example, if an employee works: <br />
        <div className="fb row">
          <div className="days">Mo , Tu: </div>
          <b>6 hours</b> <br />
        </div>
        <div className="fb row">
          <div className="days">We , Th , Fr</div> <b>8 hours</b>
        </div>
        <br />
        the average daily working time is: <br />( 6 + 6 + 8 + 8 + 8 ) / 5 days = 7.2 = <b>7 h und 12 min.</b>
        <br />
        <br />
        The employee is credited 7:12 h for each absent working day.
        <br />
        <br />
        If the employee is absent from <b> Monday </b> to <b> Thursday </b>, there will be 7.2 * 4 = 28.8 hours credited
        (= <b> 28 h and 48 min </b>).
      </>
    ),
  },
  tägliche_sollstunden: {
    de: "Tägliche Sollstunden",
    en: "Daily target hours",
  },
  sperre_gilt_fuer: {
    de: "Sperre gilt für",
    en: "Ban applies to",
  },
  tägliche_sollstunden_explenation: {
    de: () => (
      <>
        Arbeitet ein Mitarbeiter zum Beispiel: <br />
        <div className="fb row">
          <div className="days">Mo , Di: </div>
          <b>6 Stunden</b> <br />
        </div>
        <div className="fb row">
          <div className="days">Mi , Do , Fr</div> <b>8 Stunden</b>
        </div>
        <br />
        Ist der Mitarbeiter von <b>Montag</b> bis <b>Donnerstag</b> abwesend <br />
        werden diesem <b>26 Stunden</b> gutgeschrieben ( 6 + 6 + 8 + 8 ).
      </>
    ),
    en: () => (
      <>
        For example, if an employee works: <br />
        <div className="fb row">
          <div className="days">Mo , Tu: </div>
          <b>6 hours</b> <br />
        </div>
        <div className="fb row">
          <div className="days">We , Th , Fr</div> <b>8 hours</b>
        </div>
        <br />
        If the employee is absent from <b> Monday </b> to <b> Thursday </b> <br />
        <b> 26 hours </b> (6 + 6 + 8 + 8) will be credited.
      </>
    ),
  },
  dem_mitarbeiter_wird_die_zeit_gutgeschrieben_wie_dieser_im_dienstplan_eingeplant_ist: {
    de: "Dem Mitarbeiter wird die Zeit gutgeschrieben, wie dieser im Dienstplan eingeplant ist.",
    en: "The employee is credited with the time as planned in the duty roster.",
  },
  abwesenheitstyp: {
    de: "Abwesenheitstyp",
    en: "Absence type",
  },
  bezeichnung: {
    de: "Bezeichnung",
    en: "Name",
  },
  halbe_urlaubstage_erlauben: {
    de: "Halbe Urlaubstage erlauben",
    en: "Allow half days of vacation",
  },
  mit_kontingent: {
    de: "Mit Kontingent",
    en: "With Entitlement",
  },
  bezahlte_abwesenheit: {
    de: "Bezahlte abwesenheit",
    en: "Paid absence",
  },
  durchnittliche_sollstunden_gutschreiben: {
    de: "Durchnittliche Sollstunden gutschreiben",
    en: "Credit mean contract hours",
  },
  klicken_für_weitere_infos: {
    de: "Klicken für weitere Infos",
    en: "Click for more information",
  },
  tägliche_sollstunden_gutschreiben: {
    de: "Tägliche Sollstunden gutschreiben",
    en: "Credit daily contract hours",
  },
  planstunden_gutschreiben: {
    de: "Planstunden gutschreiben",
    en: "Credit planned hours",
  },
  kann_von_mitarbeitern_beantragt_werden: {
    de: "Kann von Mitarbeitern beantragt werden",
    en: "Can be requested by employees",
  },
  mitarbeiter_können_die_abwesenheiten_der_kollegen_sehen: {
    de: "Mitarbeiter können die Abwesenheiten der Kollegen sehen.",
    en: "Employees can see their colleagues absences.",
  },
  verfügbar: {
    de: "Verfügbar",
    en: "Available",
  },
  unverfügbar: {
    de: "Unverfügbar",
    en: "Unavailable",
  },
  unendlich: {
    de: "unendlich",
    en: "infinit",
  },
  eintägig: {
    de: "eintägig",
    en: "once",
  },
  einmalig: {
    de: "einmalig",
    en: "once",
  },
  Einmalig: {
    de: "Einmalig",
    en: "once",
  },
  mehrtägig: {
    de: "mehrtägig",
    en: "repeating",
  },
  ganzer_tag: {
    de: "Ganzer Tag",
    en: "Whole day",
  },
  von_bis_uhrzeit_angeben_oder_die_option_ganzer_tag_wählen: {
    de: "Von & Bis Uhrzeit angeben oder die option 'Ganzer Tag' wählen.",
    en: "Specify from & to time or select the option 'Whole day'.",
  },
  wähle_ein_zeit_intervall_oder_die_option_ganzer_tag: {
    de: "Wähle ein Zeit Intervall oder die Option 'Ganzer Tag' ",
    en: "Choose a time interval or the option 'All day'",
  },
  end_datum_darf_nicht_vor_dem_start_datum_liegen: {
    de: "End-Datum darf nicht vor dem Start Datum liegen",
    en: "End date cannot be before the start date",
  },
  end_zeit_darf_nicht_vor_der_start_zeit_liegen: {
    de: "End Zeit darf nicht vor der Start Zeit liegen",
    en: "End time cannot be before the start time",
  },
  wähle_mindestens_einen_wochentag_aus: {
    de: "Wähle mindestens einen Wochentag aus",
    en: "Select at least one day of the week",
  },
  das_datum_ist_erforderlich: {
    de: "Das Datum ist erforderlich",
    en: "The date is required",
  },
  das_start_datum_ist_erfordrlich: {
    de: "Das Start-Datum ist erfordrlich",
    en: "The start date is required",
  },
  verfügbarkeit_hinzufügen: {
    de: "Verfügbarkeit hinzufügen",
    en: "Add availability",
  },
  eintrag_löschen: {
    de: "Eintrag löschen",
    en: "Delete entry",
  },
  die_eingetragene_korrektur_wirklich_löschen: {
    de: "Die eingetragene Korrektur wirklich löschen ?",
    en: "Sure you want to delete the correction?",
  },
  korrigierter_urlaubsanspurch_für_das_jahr: {
    de: "Korrigierter Urlaubsanspurch für das Jahr",
    en: "Corrected vacation claim for the year",
  },
  plus: {
    de: "plus",
    en: "plus",
  },
  minus: {
    de: "minus",
    en: "minus",
  },
  notiz: {
    de: "Notiz",
    en: "Note",
  },
  korrektur_löschen: {
    de: "Korrektur löschen",
    en: "Delete correction",
  },
  diese_korrekutr_wirklich_löschen: {
    de: "Diese Korrekutr wirklich löschen ?",
    en: "Sure you want to delete this correction?",
  },
  korrektur: {
    de: "Korrektur",
    en: "Correction",
  },
  stundenkonto_startwert: {
    de: "Stundenkonto Startwert",
    en: "Hour account start value",
  },
  std: {
    de: "std",
    en: "h",
  },
  min: {
    de: "min",
    en: "min",
  },
  Std: {
    de: "Std",
    en: "h",
  },
  Min: {
    de: "Min",
    en: "min",
  },
  auch_alle_folgenden_wiederholungen_löschen: {
    de: "Auch alle folgenden Wiederholungen löschen?",
    en: "Also delete all the following repetitions?",
  },
  effektive_tage_überschreiben_mit: {
    de: "Effektive Tage überschreiben mit:",
    en: "Overwrite effective days with:",
  },
  jahr: {
    de: "Jahr",
    en: "Jahr",
  },
  anfrage_wurde_erstellt: {
    de: "Anfrage wurde erstellt",
    en: "Request has been created",
  },
  wem_soll_deine_schicht_angeboten_werden: {
    de: "Wem soll deine Schicht angeboten werden?",
    en: "Who should your shift be offered?",
  },
  schicht_am: {
    de: "Schicht am",
    en: "Shift on",
  },
  allen_mitarbeitern_der_rolle: {
    de: "Allen Mitarbeitern der Rolle",
    en: "All employees of the role",
  },
  einem_bestimmten_mitarbeiter: {
    de: "Einem bestimmten Mitarbeiter",
    en: "A specific colleague",
  },
  mitarbeiter_wählen: {
    de: "Mitarbeiter wählen",
    en: "Choose employee",
  },
  schichtabgabe_beantragen: {
    de: "Schichtabgabe beantragen",
    en: "Apply shift handover",
  },
  einführung: {
    de: "Einführung",
    en: "Introduction",
  },
  einrichtung: {
    de: "Einrichtung",
    en: "Setup",
  },
  wochenplan: {
    de: "Wochenplan",
    en: "Schedule",
  },
  stundenkonto: {
    de: "Stundenkonto",
    en: "Time account",
  },
  stundenkonto_subbar: {
    de: "Stundenkonto",
    en: "Timesheets",
  },
  offene_schichten: {
    de: "Offene Schichten",
    en: "Open shifts",
  },
  bewerber_offene_schichten: {
    de: "Bewerber / Offene Schichten",
    en: "applications / Open shifts",
  },
  eingeteilte_benötigte_mitarbeiter: {
    de: "eingeteilt / benötigt",
    en: "assigned / required",
  },
  abwesenheiten: {
    de: "Abwesenheiten",
    en: "Absences",
  },
  urlaubskonten: {
    de: "Urlaubskonten",
    en: "Entitlement",
  },
  urlaubskonto: {
    de: "Urlaubskonto",
    en: "Entitlement",
  },
  kontingent: {
    de: "Kontingent",
    en: "Entitlement",
  },
  kontingente: {
    de: "Kontingente",
    en: "Entitlement",
  },
  verfügbarkeiten: {
    de: "Verfügbarkeiten",
    en: "Availabilities",
  },
  dieser_eintrag_kann_nicht_editiert_werden: {
    de: "Dieser Eintrag kann nicht editiert werden.",
    en: "This entry can not be edited.",
  },
  anträge: {
    de: "Anträge",
    en: "Applications",
  },
  stempeluhr: {
    de: "Stempeluhr",
    en: "Time clock",
  },
  video_tutorials: {
    de: "Video Tutorials",
    en: "Video tutorials",
  },
  schließen: {
    de: "Schließen",
    en: "Close",
  },
  eingestempelte_mitarbeiter: {
    de: "Eingestempelte Mitarbeiter",
    en: "Clocked in employees",
  },
  mitarbeiter_in_der_pause: {
    de: "Mitarbeiter in der Pause",
    en: "Employees in the break",
  },
  verspätete_mitarbeiter: {
    de: "Verspätete Mitarbeiter",
    en: "Late employees",
  },
  die_schicht_wird_frei_gestempelt: {
    de: "Die Schicht wird frei gestempelt",
    en: "The shift is stamped freely",
  },
  ausstempeln: {
    de: "Ausstempeln",
    en: "Clock out",
  },
  schichtbeginn: {
    de: "Schichtbeginn",
    en: "Shift start",
  },
  eingestempelt: {
    de: "Eingestempelt",
    en: "Clocked In",
  },
  in_pause_seit: {
    de: "In Pause seit",
    en: "In break since",
  },
  im: {
    de: "im",
    en: "in",
  },
  zur_schicht: {
    de: "Zur Schicht",
    en: "Show shift",
  },
  um_einen_mitarbeiter_auszustempeln_musst_du_eine_zeiterfassung_anlegen: {
    de: "Um einen Mitarbeiter auszustempeln musst du eine Zeiterfassung anlegen.",
    en: "To clock out an employee, you have to create a time tracking.",
  },
  gestempelte_arbeitszeit: {
    de: "Gestempelte Arbeitszeit",
    en: "Clocked time",
  },
  mitarbeiter: {
    de: "Mitarbeiter",
    en: "Employee",
  },
  mitarbeiter_plural: {
    de: "Mitarbeiter",
    en: "Employees",
  },
  standort: {
    de: "Standort",
    en: "Location",
  },
  sie_haben_eine_email_mit_einem_link_zum_passwort_reset_erhalten: {
    de: `Sie haben eine Email mit einem Link zum Passwort Reset erhalten.`,
    en: `You have received an email with a link to the password reset.`,
  },
  passwort_vergessen: {
    de: "Passwort vergessen",
    en: "Forgot  Password?",
  },
  fordern_sie_eine_email_an_mit_einem_link_zum_setzen_eines_neuen_passworts: {
    de: "Fordern Sie eine Email an mit einem Link zum Setzen eines neuen Passworts.",
    en: "Request an email with a link to set a new password.",
  },
  Mitarbeiter_koennen_keine_abwesenheiten_beantragen: {
    de: "Mit der Urlaubssperre wird das Beantragen von Abwesenheiten gesperrt.",
    en: "The Leave Ban blocks the application for absences.",
  },
  email_anfordern: {
    de: "Email anfordern",
    en: "Request email",
  },
  wochenplan_kw_week_veröffentlichen: {
    de: (week) => `Wochenplan KW ${week} veröffentlichen`,
    en: (week) => `Publish schedule of CW ${week}`,
  },
  wochenpläne_veröffentlichen: {
    de: "Wochenpläne veröffentlichen",
    en: "Publish weekplans",
  },
  durch_das_veröffentlichen_des_wochenplans_werden_die_schichten_dieser_kalenderwoche_sichtbar_für_die_mitarbeiter: {
    de: "Durch das veröffentlichen des Wochenplans werden die Schichten dieser Kalenderwoche sichtbar für die Mitarbeiter.",
    en: "By publishing the weekly plan, the shifts of this week become visible to the employees.",
  },
  mitarbeiter_benachrichtigen: {
    de: "Mitarbeiter benachrichtigen",
    en: "Notify employees",
  },
  mehrere_wochenpläne_veröffentlichen: {
    de: "Mehrere Wochenpläne",
    en: "Publish multiple weekplans",
  },
  veröffentlichen: {
    de: "Veröffentlichen",
    en: "Publish",
  },
  diese_regel_gilt_für: {
    de: "Diese Regel gilt für:",
    en: "This rule applies to:",
  },
  das_eingetragene_urlaubskoningent_wirklich_löschen: {
    de: "Das eingetragene Urlaubskoningent wirklich löschen?",
    en: "Sure you want to delete the entered vacation entitlement?",
  },
  urlaubsanspurch_ab_dem_jahr: {
    de: "Urlaubsanspurch ab dem Jahr",
    en: "Vacation entitlement for the year",
  },
  bewerber_benötigtes_personal: {
    de: "Bewerber / Benötigtes Personal",
    en: "Applicants / required staff",
  },
  beworben: {
    de: "beworben",
    en: "applied",
  },
  fehler: {
    de: "Fehler",
    en: "Error",
  },
  die_zahlungsmethode_konnte_nicht_korrekt_verifiziert_werden_bitte_versuche_es_erneut: {
    de: "Die Zahlungsmethode konnte nicht korrekt verifiziert werden. Bitte versuche es erneut.",
    en: "The payment method could not be verified correctly. Please try again.",
  },
  eingabe_erforderlich: {
    de: "*Eingabe erforderlich",
    en: "*Input required",
  },
  bitte_valide_e_mail_adresse_eingeben: {
    de: "*Bitte valide E-Mail Adresse eingeben",
    en: "*Please enter valid e-mail address",
  },
  bitte_stimmen_sie_der_agb_zu: {
    de: "*Bitte stimmen Sie der AGB zu.",
    en: "*Please agree to the terms and conditions.",
  },
  zahlungsinformationen: {
    de: "Zahlungsinformationen",
    en: "Payment Information",
  },
  email: {
    de: "E-Mail",
    en: "E-Mail",
  },
  max_mustermann: {
    de: "Max Mustermann",
    en: "Name",
  },
  firmenname: {
    de: "Firmenname",
    en: "Company name",
  },
  muster_gmbh: {
    de: "Muster GmbH",
    en: "Template Corp.",
  },
  straße_und_hausnummer: {
    de: "Straße und Hausnummer",
    en: "Street and house number",
  },
  musterstraße_1: {
    de: "Musterstraße 1",
    en: "Template street 1",
  },
  postleitzahl: {
    de: "Postleitzahl",
    en: "Post Code",
  },
  postleitzahl_beispiel: {
    de: "22299",
    en: "",
  },
  stadt: {
    de: "Stadt",
    en: "City",
  },
  berlin: {
    de: "Berlin",
    en: "Berlin",
  },
  land: {
    de: "Land",
    en: "Country",
  },
  umsatzsteuer_id: {
    de: "Umsatzsteuer-ID",
    en: "VAT ID No.",
  },
  umsatzsteuer_id_beispiel: {
    de: "DE123456789",
    en: "",
  },
  kreditkarte: {
    de: "Kreditkarte",
    en: "Credit card",
  },
  sepa_lastschrift: {
    de: "SEPA Lastschrift",
    en: "SEPA direct debit",
  },
  überweisung: {
    de: "Überweisung",
    en: "bank transfer",
  },
  zahlungsmethode: {
    de: "Zahlungsmethode",
    en: "Payment method",
  },
  stripe_sepa_text: {
    de: `Durch die Eingabe und Bestätigung Ihrer Zahlungsinformationen autorisieren Sie (A)
    Aplano GmbH und Stripe, unseren Zahlungsdienstleister, Anweisungen an Ihre Bank zu
    senden um Ihr Konto zu belasten und (B) Ihre Bank Ihr Konto in Übereinstimmung mit
    diesen Anweisungen zu belasten. Im Rahmen Ihrer Rechte haben Sie Anspruch auf eine
    Rückerstattung von Ihrer Bank unter den vereinbarten Bedingungen mit Ihrer Bank. Eine
    Rückerstattung muss innerhalb von 8 Wochen beantragt werden, ab dem Datum, an dem Ihr
    Konto belastet wurde. Ihre Rechte werden in einer Erklärung erläutert, die Sie von
    Ihrer Bank erhalten können. Sie stimmen zu, Benachrichtigungen zu zukünftigen
    Belastungen bis zu 2 Tage vor ihrem Eintritt zu erhalten.`,
    en: `By providing your IBAN and confirming this payment, you are authorizing Aplano GmbH and Stripe, our payment service provider, to send instructions to your bank to debit your account and your bank to debit your account in accordance with those instructions. You are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited.`,
  },
  hiermit_stimme_ich_den_agb_zu_link: {
    de: () => (
      <>
        Hiermit stimme ich den
        <a
          href={envi.isV2() ? "https://www.zeitguru.de/agb" : "https://www.aplano.de/agb"}
          target="_blank"
          style={{ paddingRight: 6, paddingLeft: 6 }}
        >
          AGB
        </a>
        zu
      </>
    ),
    en: () => (
      <>
        I accept the
        <a
          href={envi.isV2() ? "https://www.zeitguru.de/agb" : "https://www.aplano.de/agb"}
          target="_blank"
          style={{ paddingRight: 6, paddingLeft: 6 }}
        >
          Terms and conditions
        </a>
      </>
    ),
  },
  GEPLANT: {
    de: "GEPLANT",
    en: "PLANNED",
  },
  BEANTRAGT: {
    de: "BEANTRAGT",
    en: "REQUESTED",
  },
  DIENSTFREI: {
    de: "DIENSTFREI",
    en: "OFF DUTY",
  },
  SOLL: {
    de: "SOLL",
    en: "PLANNED",
  },
  IST: {
    de: "IST",
    en: "TRACKED",
  },
  einen_augenblick_bitte_ihr_ausdruck_wird_vorbereitet: {
    de: "Einen Augenblick bitte. Ihr Ausdruck wird vorbereitet :)",
    en: "Just a moment please. Your print is being prepared :)",
  },
  vorlage_wurde_übernommen: {
    de: "Vorlage wurde übernommen",
    en: "Template was applied",
  },
  vorlage_importieren: {
    de: "Vorlage importieren",
    en: "Apply template",
  },
  keine_vorlagen_vorhanden: {
    de: "Keine Vorlagen vorhanden",
    en: "No templates available",
  },
  importieren: {
    de: "Importieren",
    en: "Apply",
  },
  neue_vorlage_erstellen: {
    de: `Neue Vorlage erstellen`,
    en: `Create a new template`,
  },
  wochenplan_als_vorlage_speichern: {
    de: `Wochenplan als Vorlage speichern`,
    en: `Save weekly schedule as a template`,
  },
  regel_gilt_nur_für_einen_teil_der_mitarbeiter: {
    de: "Regel gilt nur für einen Teil der Mitarbeiter",
    en: "Rule only applies to part of the employees",
  },
  regel_gilt_für_alle_mitarbeiter: {
    de: "Regel gilt für alle Mitarbeiter",
    en: "Rule applies to all employees",
  },
  alle: {
    de: "alle",
    en: "all",
  },
  Alle: {
    de: "Alle",
    en: "All",
  },
  alle_typen: {
    de: "Alle Typen",
    en: "All types",
  },
  nur_selektierte_mitarbeiter: {
    de: "Nur selektierte Mitarbeiter",
    en: "Only selected employees",
  },
  ausgenommen_selektierter_mitarbeiter: {
    de: "Ausgenommen selektierter Mitarbeiter",
    en: "Except for selected employees",
  },
  ausser_fuer: {
    de: "Außer für",
    en: "Except for",
  },
  nur_mitarbeiter_der_rolle: {
    de: "Nur Mitarbeiter der Rolle",
    en: "Only employees of the role",
  },
  rolle_wähen: {
    de: "Rolle wähen",
    en: "Select role",
  },
  nur_mitarbeiter_der_standorte: {
    de: "Nur Mitarbeiter der Standorte",
    en: "Only employees of the locations",
  },
  standort_wählen: {
    de: "Standort wählen",
    en: "Select location",
  },
  bitte_standort_wählen: {
    de: "Bitte Standort wählen",
    en: "Please select location",
  },
  nur_mitarbeiter_der_berechtigung: {
    de: "Nur Mitarbeiter der Berechtigung",
    en: "Only employees with rights",
  },
  berechtigung_wählen: {
    de: "Berechtigung wählen",
    en: "Select right",
  },
  schicht_endet_am_folgetag: {
    de: "Schicht endet am Folgetag",
    en: "Shift ends the next day",
  },
  bestätigte_zeiterfassung: {
    de: "Bestätigte Zeiterfassung",
    en: "Approved time trackings",
  },
  unbestätigte_zeiterfassung: {
    de: "Unbestätigte Zeiterfassung",
    en: "Unconfirmed time trackings",
  },
  nicht_verfügbar_während_dieser_schicht: {
    de: `Nicht verfügbar während dieser Schicht.`,
    en: `Not available during this shift.`,
  },
  mitarbeiter_ist_deaktiviert: {
    de: "Mitarbeiter ist deaktiviert",
    en: "Employee is deactivated",
  },
  schicht_nicht_gestempelt: {
    de: "Schicht nicht gestempelt",
    en: "Shift not clocked",
  },
  änderungsanfrage: {
    de: "Änderungsanfrage",
    en: "Change request",
  },
  dienstfrei_beantragt: {
    de: "Dienstfrei beantragt",
    en: "Off duty requested",
  },
  änderung_beantragt: {
    de: "Änderung beantragt",
    en: "Change requested",
  },
  änderung: {
    de: "Änderung",
    en: "Change",
  },
  dienstfrei_beantragen: {
    de: "Dienstfrei beantragen",
    en: "Request off duty",
  },
  schichtzeit_änderung: {
    de: "Schichtzeit Änderung",
    en: "Shift time change",
  },
  schicht_wurde_als_offene_schicht_freigegeben: {
    de: "Schicht wurde als offene Schicht freigegeben",
    en: "Shift turned to an open shift",
  },
  schichtzeit_wurde_angepasst: {
    de: "Schichtzeit wurde angepasst",
    en: "Shift time was adjusted",
  },
  anfrage_abgelehnt: {
    de: "Anfrage abgelehnt",
    en: "Request rejected",
  },
  anfrage_entfernt: {
    de: "Anfrage entfernt",
    en: "Request removed",
  },
  antrag_erstellt_am: {
    de: "Antrag erstellt am",
    en: "Request was made on",
  },
  annehmen: {
    de: "Annehmen",
    en: "Accept",
  },
  änderungsantrag_entfernen: {
    de: "Änderungsantrag entfernen",
    en: "Remove change request",
  },
  kommentar_gespeichert: {
    de: "Kommentar gespeichert.",
    en: "Comment saved.",
  },
  benötigtes_personal: {
    de: "Benötigtes personal",
    en: "Required staff",
  },
  zur_abwesenheit: {
    de: "zur Abwesenheit",
    en: "show Absence",
  },
  tag_wählen: {
    de: "Tag wählen",
    en: "Choose day",
  },
  schicht_einem_anderen_nutzer_zuweisen: {
    de: "Schicht einem anderen Nutzer zuweisen",
    en: "Assign shift to another user",
  },
  nutzer_entfernen_und_schicht_wieder_freigeben_als_offene_schicht: {
    de: "Nutzer entfernen und Schicht wieder freigeben als offene Schicht",
    en: "Unassign user and turn to open shift",
  },
  ohne_geplante_schichten_gestempelt: {
    de: "ohne geplante Schichten gestempelt",
    en: "Clocked without assigned shift",
  },
  ohne_schicht_gestempelt: {
    de: `Ohne Schicht gestempelt`,
    en: `Clocked without shift`,
  },
  gestempelt: {
    de: "Gestempelt",
    en: "Clocked",
  },
  gestempelt_label: {
    de: "Gestempelt",
    en: "Clocking",
  },
  auto_ausgestempelt: {
    de: "Auto-ausgestempelt",
    en: "Auto-clockout",
  },
  label: {
    de: "Label",
    en: "Label",
  },
  hashtags: {
    de: "Hashtags",
    en: "Hashtags",
  },
  hashtag: {
    de: "Hashtag",
    en: "Hashtag",
  },
  offene_schicht: {
    de: "Offene Schicht",
    en: "open shift",
  },
  bedarf: {
    de: "Bedarf",
    en: "Requirement",
  },
  personalbedarf: {
    de: "Personalbedarf",
    en: "Requirement",
  },
  alle_offenen_schichten_wurden_vergeben: {
    de: "Alle offenen Schichten wurden vergeben.",
    en: "All open shifts have been assigned.",
  },
  mitarbeiter_suchen: {
    de: "Mitarbeiter suchen",
    en: "Employee search",
  },
  bereits_eingeplant: {
    de: "Bereits eingeplant",
    en: "Already scheduled",
  },
  ist_eingestempelt: {
    de: "Ist eingestempelt",
    en: "Is clockd in",
  },
  zuweisen: {
    de: "zuweisen",
    en: "assign",
  },
  weise_diese_schicht_einem_anderen_mitarbeiter_zu: {
    de: "Weise diese Schicht einem anderen Mitarbeiter zu:",
    en: "Assign this shift to another employee:",
  },
  es_gibt_es_keine_offenen_bewerbungen_zu_dieser_schicht: {
    de: "Es gibt es keine offenen Bewerbungen zu dieser Schicht",
    en: "There are no applications for this shift",
  },
  adresse: {
    de: "Adresse",
    en: "Address",
  },
  neues_adresse_erstellen: {
    de: "Neue Adresse erstellen",
    en: "Create new address",
  },
  aufschlag: {
    de: "Aufschlag",
    en: "Surcharge",
  },
  zuschlag: {
    de: "Zuschlag",
    en: "Surcharge",
  },
  Sonntagszuschlag: {
    de: "Sonntagszuschlag",
    en: "Sundaysurcharge",
  },
  Feiertagszuschalg: {
    de: "Feiertagszuschlag",
    en: "Holidaysurcharge",
  },
  Nachtzuschlag: {
    de: "Nachtzuschlag",
    en: "Nightsurcharge",
  },
  Extrazuschlag: {
    de: "Extrazuschlag",
    en: "Extrasurcharge",
  },
  Zuschlaege: {
    de: "Zuschläge",
    en: "Surcharges",
  },
  zuletzt: {
    de: "Zuletzt",
    en: "Previously",
  },
  wiederholend: {
    de: "Wiederholend",
    en: "repeating",
  },
  abwesenheit_eintragen: {
    de: "Abwesenheit eintragen",
    en: "Add absence entry",
  },
  manager_bestätigung_ausstehend: {
    de: "Manager Bestätigung ausstehend",
    en: "Manager confirmation pending",
  },
  mitarbeiter_bestätigung_ausstehend: {
    de: "Mitarbeiter Bestätigung ausstehend",
    en: "Employee confirmation outstanding",
  },
  selbst_übernehmen: {
    de: "Selbst Übernehmen",
    en: "Take over yourself",
  },
  bestätigen: {
    de: "Bestätigen",
    en: "Accept",
  },
  selektiert: {
    de: "selektiert",
    en: "selected",
  },
  schicht_übernehmen: {
    de: "Schicht übernehmen",
    en: "Take over the shift",
  },
  antrag_ablehnen: {
    de: "Antrag ablehnen",
    en: "Reject Request",
  },
  annahme_aufheben: {
    de: "Annahme aufheben",
    en: "Cancel acceptance",
  },
  rolle: { de: "Rolle", en: "Role" },
  nicht_angetreten: {
    de: "Nicht Angetreten",
    en: "No show",
  },
  anzahl: {
    de: "Anzahl",
    en: "Required",
  },
  bewerber: {
    de: "Bewerber",
    en: "Applicants",
  },
  du_bewirbst_dich_für_diese_schicht: {
    de: "Du bewirbst dich für diese Schicht",
    en: "You are applying for this shift",
  },
  bewerbung_zurückziehen: {
    de: "Bewerbung zurückziehen",
    en: "Withdraw application",
  },
  bewerbung_zurückgezogen: {
    de: "Bewerbung zurückgezogen",
    en: "Application withdrawn",
  },
  auf_diese_schicht_bewerben: {
    de: "Auf diese Schicht bewerben",
    en: "Apply to this shift",
  },
  bewerbung_übernommen: {
    de: "Bewerbung übernommen",
    en: "Application submitted",
  },
  bis_zum: {
    de: "bis zum",
    en: "until",
  },
  ohne_enddatum: {
    de: "ohne Enddatum",
    en: "endless",
  },
  ändern: {
    de: "ändern",
    en: "edit",
  },
  enddatum_hinzufügen: {
    de: "Enddatum hinzufügen",
    en: "Add end date",
  },
  kommentar_speichern: {
    de: "Kommentar Speichern",
    en: "Save comment",
  },
  zeiterfassung_dauer: {
    de: "Zeiterfassung Dauer",
    en: "Tracked time duration",
  },
  arbeitszeit__schicht: {
    de: "Arbeitszeit",
    en: "Times",
  },
  arbeitszeit__vertrag: {
    de: "Arbeitszeit",
    en: "Contract",
  },
  arbeitszeit: {
    de: "Arbeitszeit",
    en: "working time",
  },
  arbeitszeiten: {
    de: "Arbeitszeiten",
    en: "working times",
  },
  pause_in_minuten: {
    de: "Pause in Minuten",
    en: "Break in minutes",
  },
  minuten: {
    de: "Minuten",
    en: "minutes",
  },
  zeiterfassung_gespeichert: {
    de: "Zeiterfassung gespeichert",
    en: "Time tracking saved",
  },
  eintrag_wurde_gelöscht: {
    de: "Eintrag wurde gelöscht.",
    en: "Entry has been deleted.",
  },
  zeiterfassung_bestätigt: {
    de: "Zeiterfassung bestätigt",
    en: "Time tracking approved",
  },
  zeiterfassung_bestätigen: {
    de: "Zeiterfassung bestätigen",
    en: "approved tracking",
  },
  zeiterfassung: {
    de: "Zeiterfassung",
    en: "Time Tracking",
  },
  zeiterfassung_label: {
    de: "Zeiterfassung",
    en: "Tracking",
  },
  bearbeiten: {
    de: "Bearbeiten",
    en: "Edit",
  },
  zeiterfassung_löschen: {
    de: "Zeiterfassung löschen",
    en: "Delete time tracking",
  },
  diese_schicht_wird_nicht_als_arbeitszeit_gezählt_weil_die_zeiterfassung_fehlt: {
    de: "Diese Schicht wird nicht als Arbeitszeit gezählt, weil die Zeiterfassung fehlt.",
    en: "This shift is not counted as working time because there is no time tracking.",
  },
  hier_klicken_falls_die_schicht_nicht_angetreten_wurde_und_nicht_als_arbeitszeit_zu_werten_ist: {
    de: "Hier klicken, falls die Schicht nicht angetreten wurde und nicht als Arbeitszeit zu werten ist",
    en: "Activate if the shift was not attended and should not be counted as working time",
  },

  filtern: {
    de: "filtern",
    en: "filter",
  },
  neues_label_erstellen: {
    de: "Neues Label erstellen",
    en: "Create a new label",
  },
  testphase_abgelaufen: {
    de: "Testphase abgelaufen",
    en: "Test phase expired",
  },
  ihre_testphase_von_aplano_ist_abgelaufen_bitte_führen_sie_ein_upgrade_durch_um_aplano_weiterhin_nutzen_zu_können: {
    de: `Ihre Testphase von ${Zplano} ist abgelaufen. Bitte führen Sie ein Upgrade durch, um ${Zplano} weiterhin nutzen zu können.`,
    en: `Your test phase of ${Zplano} has expired. Please upgrade to continue to use ${Zplano}.`,
  },
  diese_schicht_und_alle_folgenden_speichern: {
    de: "Diese Schicht und alle folgenden Speichern?",
    en: "Save changes for this shift and all of the following?",
  },
  dabei_werden_alle_folgenden_wiederholungen_auf_basis_dieser_schicht_neu_generiert: {
    de: "Dabei werden alle folgenden Wiederholungen auf Basis dieser Schicht neu generiert.",
    en: "All subsequent repetitions will be updated based on this shift.",
  },
  eine_schicht_mit_zeiterfassung_kann_nicht_gelöscht_werden: {
    de: "Eine Schicht mit Zeiterfassung kann nicht gelöscht werden",
    en: "A shift with a time tracking recored can not be deleted",
  },
  um_diese_funktion_zu_nutzen_brauchst_du_mindestens_den_pro_plan: {
    de: "Um diese Funktion zu nutzen, brauchst du mindestens den Pro-Plan.",
    en: "To use this feature, you need the pro tier.",
  },
  wiederholend_löschen: {
    de: "Wiederholend löschen",
    en: "Delete sequence",
  },
  diese_schicht_und_alle_folgenden_wiederholungen: {
    de: "Diese Schicht und alle folgenden Wiederholungen",
    en: "This shift and all following shifts of this sequence",
  },
  wiederholend_speichern: {
    de: "Wiederholend speichern",
    en: "Save Sequence",
  },
  kein_standort_mit_aktivierter_stempeluhr: {
    de: "Kein Standort mit aktivierter Stempeluhr",
    en: "No location with enabled time clock terminal",
  },
  die_stempeluhr_funktion_eines_standortes_können_sie_unter_einstellungen_standorte_aktivieren: {
    de: 'Die Stempeluhr Funktion eines Standortes können Sie unter "Einstellungen / Standorte" aktivieren.',
    en: "Time clocking for a location can be activated in the 'Settings' > 'Locations'.",
  },
  stempeluhr_station: {
    de: "Stempeluhr Station",
    en: "Time Clock Terminal",
  },
  standorte_auswählen: {
    de: "Standorte auswählen",
    en: "Select locations",
  },
  zum_aktivieren_der_stempeluhr_station_wird_aplano_beendet_somit_kann_ihr_zugang_nicht_von_unbefugten_verwendet_werden:
    {
      de: `Zum aktivieren der Stempeluhr Station wird ${Zplano} beendet. Somit kann Ihr Zugang nicht von Unbefugten verwendet werden.`,
      en: "To activate the time clock terminal you will be signed out, in order to prevent unauthorized access to your account.",
    },
  starten: {
    de: "Starten",
    en: "Start",
  },
  monatliche_kosten_basierend_auf_ihrer_mitarbeiteranzahl: {
    de: "Monatliche Kosten basierend auf Ihrer Mitarbeiteranzahl:",
    en: "Monthly costs based on number of employees:",
  },
  zzgl_mwst: {
    de: "(zzgl. MwSt.)",
    en: "(excl. VAT)",
  },
  upgrade_erfolgreich: {
    de: "Upgrade erfolgreich",
    en: "Upgrade successfully",
  },
  vielen_dank_für_dein_abonnement: {
    de: "Vielen Dank für dein Abonnement!",
    en: "Thank you for your subscription!",
  },
  zum_t_plan_wechseln: {
    de: (t) => `Zum ${t}-Plan wechseln`,
    en: (t) => `Change to ${t}-Tier`,
  },
  ihre_kostenlose_testphase_von_unseren_pro_features_läuft_nach_days_tagen_ab_wenn_sie_möchten_können_sie_aber_bereits_vorher_auf_einen_kostenpflichtigen_plan_wechseln:
    {
      de: (days) =>
        `Ihre kostenlose Testphase von unseren Pro-Features läuft nach ${days} Tagen ab. Sie können auch bereits vorher auf den Basic oder Pro Plan wechseln.`,
      en: (days) =>
        `Your free trial of our pro features will expire in $ {days} days. If you want, you can switch to a paid plan beforehand.`,
    },
  plan_auswählen: {
    de: "Plan auswählen",
    en: "Select tier",
  },
  wählen_sie_ihr_gewünschtes_paket_für_das_upgrade: {
    de: "Wählen Sie Ihr gewünschtes Paket für das Upgrade",
    en: "Choose your desired tier for upgrade",
  },
  zum_funktionsumfang_pro_paket: {
    de: "Zum Funktionsumfang pro Paket",
    en: "Check out the features of each tier",
  },
  alle_preise_zzgl_mw_st: {
    de: "Alle Preise zzgl. MwSt.",
    en: "All prices excluding VAT",
  },
  mind_value_pro_monat: {
    de: (value, currency) => `mind. ${value} ${currency} pro Monat`,
    en: (value, currency) => `minimum ${value} ${currency} per month`,
  },
  pro_nutzer_monat: {
    de: "pro Nutzer/Monat",
    en: "per employee/month",
  },
  derzeit: {
    de: "Derzeit",
    en: "Currently",
  },
  auswählen: {
    de: "Auswählen",
    en: "Switch",
  },
  haben_sie_fragen: {
    de: "Haben Sie Fragen?",
    en: "Do you need help?",
  },
  support_ist_online: {
    de: "Support ist online",
    en: "Support is online",
  },
  live_demo_vereinbaren: { de: "Live Demo vereinbaren", en: "Book a live demo" },
  pausiert: {
    de: "Pausiert",
    en: "Paused",
  },
  pro_noch_days_tage: {
    de: (days) => `Pro noch ${days} Tage`,
    en: (days) => `Pro: ${days} days remaining`,
  },
  logout: {
    de: "Logout",
    en: "Sign Out",
  },
  mein_profil: {
    de: "Mein Profil",
    en: "My Profile",
  },
  hilfe: {
    de: "Hilfe",
    en: "Help",
  },
  automatisch_ausgestempelt_da_der_mitarbeiter_es_vergessen_hat: {
    de: "Automatisch ausgestempelt, da der Mitarbeiter es vergessen hat",
    en: "Automatically clocked out, because the employee has forgotten it",
  },
  automatisch_ausgestempelt: {
    de: "Automatisch ausgestempelt",
    en: "Auto clocked out",
  },
  automatisch_ausgestempelt_12_stunden_nach_dem_einstempeln: {
    de: "Automatisch ausgestempelt ( 12 Stunden nach dem Einstempeln )",
    en: "Automatically clocked out after 12 hours",
  },
  per_stempeluhr_erfasst: {
    de: "Per Stempeluhr erfasst",
    en: "Tracking by time clock",
  },
  manuell_eingetragene_zeit: {
    de: "Manuell eingetragene Zeit",
    en: "Tracking manually entered",
  },
  manuell: {
    de: "Manuell",
    en: "Manually",
  },
  akzeptieren: {
    de: "Akzeptieren",
    en: "Accept",
  },
  standort_filter: {
    de: "Standort Filter",
    en: "Location filter",
  },
  rollen_filter: {
    de: "Rollen Filter",
    en: "Role filter",
  },
  folgende_email_adressen_sind_bereits_angelegt: {
    de: "Folgende Email Adressen sind bereits angelegt:",
    en: "Following email addresses are already used:",
  },
  email_adressen_eingeben_und_mitarbeiter_einladen: {
    de: "Email Adressen eingeben und Mitarbeiter einladen",
    en: "Enter email addresses and invite employees",
  },
  es_wird_ein_aktivierungs_link_an_die_mitarbeiter_geschickt: {
    de: "Es wird ein Aktivierungs-Link an die Mitarbeiter geschickt.",
    en: "An activation link will be sent to the employees.",
  },
  einladungen_versenden: {
    de: "Einladungen Versenden",
    en: "Send invitations",
  },
  leider_ist_die_eingestellte_zeit_ihres_betriebssystems_nicht_korrekt_bitte_korrigieren_sie_die_lokale_zeit_ihres_rechners_und_starten_die_anwendung_neu_vielen_dank:
    {
      de: "Die eingestellte Zeit Ihres Betriebssystems ist nicht korrekt. Bitte korrigieren Sie die lokale Zeit ihres Rechners und starten die Anwendung neu. Vielen Dank!",
      en: "The date or time of your operating system is incorrect. Please correct the local time of your computer and restart the application.",
    },
  überschneidung_mit_schicht: {
    de: "Überschneidung mit Schicht",
    en: "Overlapping with shift",
  },
  schicht_am_selben_tag: {
    de: "Schicht am selben Tag:",
    en: "Shift on same day:",
  },
  setup: {
    de: "Setup",
    en: "Setup",
  },
  app: {
    de: "App",
    en: "App",
  },
  chat: {
    de: "Chat",
    en: "Chat",
  },
  benachrichtigungen: {
    de: "Benachrichtigungen",
    en: "Notifications",
  },
  dashboard: {
    de: "Dashboard",
    en: "Dashboard",
  },
  zum_app_download: {
    de: "Zum App Download",
    en: "To app download",
  },
  hochgeladen_am: {
    de: "Hochgeladen am",
    en: "Uploaded on",
  },
  freigegebene_schicht_von: {
    de: "Freigegebene Schicht von",
    en: "Deassigned shift of",
  },
  dienstplanung: {
    de: "Dienstplanung",
    en: "Scheduling",
  },
  einstellungen: {
    de: "Einstellungen",
    en: "Settings",
  },
  abrechnung: {
    de: "Abrechnung",
    en: "Payments",
  },
  ansagen: {
    de: "Ansagen",
    en: "Announcements",
  },
  abwesenheitstypen: {
    de: "Abwesenheitstypen",
    en: "Absencetypes",
  },
  rollen: {
    de: "Rollen",
    en: "Roles",
  },
  feiertage: {
    de: "Feiertage",
    en: "Holidays",
  },
  standorte: {
    de: "Standorte",
    en: "Locations",
  },
  account: {
    de: "Account",
    en: "Account",
  },
  login: {
    de: "Login",
    en: "Login",
  },
  mobiler_zugriff: {
    de: "Mobiler Zugriff",
    en: "Mobile access",
  },
  kalender: {
    de: "Kalender",
    en: "Calendar",
  },
  liste: {
    de: "Liste",
    en: "List",
  },
  tabelle: {
    de: "Tabelle",
    en: "Table",
  },
  schichtplan: {
    de: "Schichtplan",
    en: "Schedule",
  },
  schichtenlog: {
    de: "Schichtenlog",
    en: "Change logs",
  },
  auswertung: {
    de: "Auswertung",
    en: "Reporting",
  },
  verfügbarkeit: {
    de: "Verfügbarkeit",
    en: "Availabilities",
  },
  wunschzeiten: {
    de: "Wunschzeiten",
    en: "Availabilities",
  },
  passwort_wiederherstellen: {
    de: "Passwort Wiederherstellen",
    en: "Reset password",
  },
  registrierung: {
    de: "Registrierung",
    en: "Registration",
  },
  kein_zugriff_auf_die_web_version: {
    de: "Kein Zugriff auf die Web Version",
    en: "No access to web version",
  },
  kw: {
    de: "KW",
    en: "CW",
  },
  kw_stepper: {
    de: "KW",
    en: "Week",
  },
  kein_arbeitstag: {
    de: "Kein Arbeitstag",
    en: "No work day",
  },
  ganztägig: {
    de: "Ganztägig",
    en: "whole day",
  },
  keine_anträge_vorhanden: {
    de: "Keine Anträge vorhanden",
    en: "No absence requests",
  },
  urlaub_beantragen: {
    de: "Urlaub beantragen",
    en: "Request vacation",
  },
  urlaubssperre: {
    de: "Urlaubssperre",
    en: "Vacation Ban",
  },
  urlaubssperren: {
    de: "Urlaubssperren",
    en: "Vacation Bans",
  },
  lust_auf_eine_live_demo_von_aplano: {
    de: "Wie wärs mit einer Live Demo ?",
    en: "Would you like to get a live demo ?",
  },
  dabei_zeigen_wir_ihnen_per_screen_sharing_wie: {
    de: "Wir zeigen Ihnen per Screen Sharing, wie Sie das Programm den Bedürfnissen Ihres Betriebes anpassen können.",
    en: "We will show you how you can adapt the program to the needs of your company via screen sharing.",
  },
  abwesenheit_beantragen: {
    de: "Abwesenheit beantragen",
    en: "Request absence",
  },
  termine_verfügbar: {
    de: "Termine verfügbar ",
    en: "slots available",
  },
  ja_gerne: {
    de: "Ja, gerne ",
    en: "Yes, please",
  },
  nein_danke: {
    de: "Nein, danke ",
    en: "No, thanks",
  },
  urlaubsanträge: {
    de: "Urlaubsanträge",
    en: "Vacation requests",
  },
  abwesenheitsanträge: {
    de: "Abwesenheitsanträge",
    en: "Absence requests",
  },
  neue_urlaubssperre: {
    de: "Urlaupssperre hinzufügen",
    en: "Add vacation ban",
  },
  sperren: {
    de: "Sperren",
    en: "Vacation Bans",
  },
  Sprache: {
    de: "Sprache",
    en: "Language",
  },
  leere_zeilen_ausblenden: {
    de: "Leere Zeilen Ausblenden",
    en: "Hide empty rows",
  },
  jahresansicht: {
    de: "Jahresansicht",
    en: "Yearly view",
  },
  monatsansicht: {
    de: "Monatsansicht",
    en: "Monthly view",
  },
  urlaubstage: {
    de: "Urlaubstage",
    en: "Vacation days",
  },
  urlaubsanspruch: {
    de: "Urlaubsanspruch",
    en: "Entitlement",
  },
  im_jahr: {
    de: "im Jahr",
    en: "in year",
  },
  effektive_tage_innerhalb_des_gewählten_zeitraums: {
    de: "Effektive Tage innerhalb des gewählten Zeitraums:",
    en: "Effective days within the chosen period:",
  },
  effektive_tage_wurden_überschrieben_dadurch_ist_keine_genau_angabe_der_effektiven_tage_im_gewählten_zeitraum_möglich:
    {
      de: "Effektive Tage wurden überschrieben. Dadurch ist keine genau Angabe der effektiven Tage im gewählten Zeitraum möglich.",
      en: "Effective days have been overwritten. Exact number of effective days in the chosen period cant be calculated.",
    },
  keine_daten_im_gewählten_zeitraum_vorhanden: {
    de: "Keine Daten im gewählten Zeitraum vorhanden",
    en: "No entries in the selected period",
  },
  korrektur_hinzufügen: {
    de: "Korrektur hinzufügen",
    en: "Add correction",
  },
  anspruch: {
    de: "Anspruch",
    en: "Entitlement",
  },
  anspruch_table: {
    de: "Anspruch",
    en: "Entitlement",
  },
  anspruch_korrigiert: {
    de: "Anspruch Korrigiert",
    en: "Entitlement Corrected",
  },
  genehmigt: {
    de: "Genehmigt",
    en: "Accepted",
  },
  resturlaub: {
    de: "Resturlaub",
    en: "Remaining Entitlement",
  },
  per_klick_uebernehmen: {
    de: "Per Klick übernehmen",
    en: "Click to take over days",
  },
  aktiv: {
    de: "Aktiv",
    en: "Active",
  },
  kein_token_vorhanden_in_der_url: {
    de: "Kein Token vorhanden in der URL",
    en: "No token available in the URL",
  },
  mindestens_4_zeichen: {
    de: "Mindestens 4 Zeichen!",
    en: "Minimum 4 characters!",
  },
  passowort_erfolgreich_geändert: {
    de: "Passowort erfolgreich geändert",
    en: "Password successfully changed",
  },
  account_wurde_aktiviert: {
    de: "Account wurde aktiviert.",
    en: "Account activated.",
  },
  passwörter_sind_ungleich: {
    de: "Passwörter sind ungleich",
    en: "Passwords are not equal",
  },
  neues_passwort: {
    de: "Neues Passwort",
    en: "New password",
  },
  passwort_wiederholen: {
    de: "Passwort wiederholen",
    en: "Repeat password",
  },
  vergeben_sie_für_ihren_aplano_zugang_ein_neues_passwort: {
    de: `Vergeben Sie für Ihren ${Zplano} Zugang ein neues Passwort.`,
    en: "Assign a new password for your account.",
  },
  legen_sie_für_ihren_aplano_zugang_ein_passwort_an: {
    de: `Legen Sie für Ihren ${Zplano} Zugang ein Passwort an.`,
    en: `Create a password for your ${Zplano} account.`,
  },
  link_ist_fehlerhaft: {
    de: "Link ist fehlerhaft",
    en: "Link is corrupted",
  },
  der_link_ist_unvollständig_oder_invalide: {
    de: "Der Link ist unvollständig oder invalide",
    en: "The link is incomplete or invalid",
  },
  wochenplan_ist_nicht_veröffentlicht: {
    de: "Wochenplan ist nicht veröffentlicht.",
    en: "Weekplan is not published yet.",
  },
  link_bereits_verwendet: {
    de: "Link bereits verwendet",
    en: "Link already used",
  },
  der_link_wurde_bereits_verwendet: {
    de: "Der Link wurde bereits verwendet",
    en: "The link has already been used",
  },
  du_kannst_dich_jetzt_mit_deiner_email_und_deinem_passwort_einloggen: {
    de: "Du kannst Dich jetzt mit Deiner Email und Deinem Passwort einloggen.",
    en: "You can now log in with your email and password.",
  },
  zum_login: {
    de: "Zum Login",
    en: "To the login",
  },
  passwort_zurücksetzen: {
    de: "Passwort zurücksetzen",
    en: "Reset password",
  },
  account_anlegen: {
    de: "Account anlegen",
    en: "Create account",
  },
  impressum: {
    de: "Impressum",
    en: "Imprint",
  },
  email_fehlt: {
    de: "Email fehlt",
    en: "Email missing",
  },
  email_format_inkorrekt: {
    de: "Email Format inkorrekt",
    en: "Not a valid email",
  },
  passwort_fehlt: {
    de: "Passwort fehlt",
    en: "Password is missing",
  },
  einloggen: {
    de: "Einloggen",
    en: "Log in",
  },
  registrieren: {
    de: "Registrieren",
    en: "Sign up",
  },
  aplano_app_verwenden: {
    de: `${Zplano} App Verwenden`,
    en: `Use ${Zplano} app`,
  },
  zur_mobilen_nutzung_von_aplano_laden_sie_bitte_die_aplano_app_herunter: {
    de: `Zur mobilen Nutzung von ${Zplano} laden Sie bitte die ${Zplano} App herunter`,
    en: `For mobile use of ${Zplano}, please download the ${Zplano} app`,
  },
  einrichtung_abgeschlossen: {
    de: "Setup abgeschlossen",
    en: "Setup completed",
  },
  erstellen_sie_nun_dienstpläne_im_browser_am_pc: {
    de: () => (
      <>
        Erstellen Sie nun Dienstpläne im <b>Browser</b> am PC.
      </>
    ),
    en: () => (
      <>
        Create schedules in the <b> browser </b> on PC / Mac.
      </>
    ),
  },
  um_mitarbeiter_anzulegen_und_dienstpläne_zu_erstellen_verwenden_sie_unsere_desktop_version_im_browser: {
    de: () => (
      <>
        Bitte verwenden Sie nun die <b>Browser</b> Version, um {Zplano} einzurichten.
      </>
    ),
    en: () => (
      <>
        Complete the configuration of your {Zplano} account on a desktop <b>browser</b>.
      </>
    ),
  },
  loggen_sie_sich_dafür_mit_ihren_zugangsdaten_unter_aplano_am_rechner_ein: {
    de: () => (
      <>
        Loggen Sie sich dafür mit Ihren Zugangsdaten am Rechner unter <b>{envi.isV2() ? "zeitguru.de" : "aplano.de"}</b>{" "}
        ein.
      </>
    ),
    en: () => (
      <>
        Sign in with your credentials using a desktop browser at <b> {envi.isV2() ? "zeitguru.de" : "aplano.de"} </b>.
      </>
    ),
  },
  mit_der_aplano_app_haben_auch_ihre_mitarbeiter_den_dienstplan_immer_dabei: {
    de: `Mit der ${Zplano} App haben auch Ihre Mitarbeiter den Dienstplan immer dabei.`,
    en: `With the ${Zplano} app, your employees always have access to the work schedule.`,
  },
  unser_mobile_app_gibt_es_im_app_store_und_im_play_store: {
    de: `Unser Mobile App gibt es im Apple App Store und im Google Play Store.`,
    en: `Our mobile app is available in the Apple App Store and Google Play Store.`,
  },
  neue_konversation: {
    de: "Neue Konversation",
    en: "New conversation",
  },
  suche: {
    de: "Suche",
    en: "Search",
  },
  ausgewählte_teilnehmer: {
    de: "Ausgewählte Teilnehmer:",
    en: "Selected members:",
  },
  gruppen_einstellung: {
    de: "Gruppen-Einstellung",
    en: "group settings",
  },
  mitglieder: {
    de: "Mitglieder",
    en: "Members",
  },
  user_aus_der_gruppe_entfernen: {
    de: (user) => `${user} aus der Gruppe entfernen?`,
    en: (user) => `remove ${user} from the group?`,
  },
  aus_gruppe_entfernen: {
    de: "Aus Gruppe entfernen",
    en: "Remove from group",
  },
  admin: {
    de: "Admin",
    en: "Admin",
  },
  mitarbeiter_auswählen: {
    de: "Mitarbeiter auswählen",
    en: "Select members",
  },
  mitarbeiter_hinzufügen: {
    de: "Mitarbeiter hinzufügen",
    en: "Add users",
  },
  stummschaltung_aufheben: {
    de: "Stummschaltung aufheben",
    en: "Unmute",
  },
  stumm_schalten: {
    de: "Stumm schalten",
    en: "Mute",
  },
  gruppe_löschen: {
    de: "Gruppe löschen",
    en: "Delete group",
  },
  title_wirklich_löschen: {
    de: (title) => `${title} wirklich löschen?`,
    en: (title) => `Confirm deletion of ${title}?`,
  },
  alle_nachrichten_in_der_gruppe_werden_endgültig_gelöscht: {
    de: "Alle Nachrichten in der Gruppe werden endgültig gelöscht.",
    en: "All messages in the group get permanently deleted.",
  },
  suchergebnisse_für_search_text: {
    de: (searchText) => `Suchergebnisse für: ${searchText}`,
    en: (searchText) => `Searchresults for: ${searchText}`,
  },
  unbekannt: {
    de: "Unbekannt",
    en: "Unknown",
  },
  konversation_erstellt_am_date_von_user: {
    de: (date, user) => `Konversation erstellt am ${date} von ${user}`,
    en: (date, user) => `Conversation created on ${date} by ${user}`,
  },
  schreib_eine_nachricht: {
    de: "Schreib eine Nachricht",
    en: "Write a message",
  },
  gestern: {
    de: "Gestern",
    en: "yesterday",
  },
  heute: {
    de: "Heute",
    en: "Today",
  },
  chat_modul_aktivieren_kostenlos: {
    de: "Chat-Modul aktivieren (kostenlos)",
    en: "Activate chat module",
  },
  das_chat_modul_wurde_noch_nicht_von_ihrem_administrator_aktiviert: {
    de: "Das Chat-Modul wurde noch nicht von Ihrem Administrator aktiviert.",
    en: "The chat module has not yet been activated by your administrator.",
  },
  titel: {
    de: "Titel",
    en: "Title",
  },
  ansage: {
    de: "Ansage",
    en: "Announcement",
  },
  nachricht: {
    de: "Nachricht",
    en: "Message",
  },
  ausgewählt: {
    de: "ausgewählt",
    en: "selected",
  },
  ansage_löschen: {
    de: "Ansage löschen?",
    en: "Delete announcement?",
  },
  ja: {
    de: "Ja",
    en: "yes",
  },
  nein: {
    de: "Nein",
    en: "No",
  },
  gelesen: {
    de: "gelesen",
    en: "read",
  },
  als_gelesen_markieren: {
    de: "Als gelesen markieren",
    en: "Mark as read",
  },
  ansage_als_ungelesen_markieren: {
    de: `Ansage als ungelesen markieren?`,
    en: `Mark announcement as unread?`,
  },
  noch_ungelesen: {
    de: "noch ungelesen",
    en: "not yet read",
  },
  ansagen_sind_nachrichten_an_mitarbeiter_die_per_klick_als_gelesen_markiert_werden_müssen: {
    de: "Ansagen sind Nachrichten an Mitarbeiter, die per Klick als gelesen markiert werden müssen.",
    en: "Announcements are messages that have to be confirmed as read by the employees.",
  },
  mit_lesebestätigungen_gehen_wichtige_informationen_nicht_mehr_unter: {
    de: "Mit Lesebestätigungen gehen wichtige Informationen nicht mehr unter.",
    en: "Important information is no longer lost with read confirmations.",
  },
  ansagen_modul_aktivieren: {
    de: "Ansagen Modul Aktivieren",
    en: "Activate announcements module",
  },
  mitarbeiter_werden_über_neue_ansagen_benachrichtigt_und_müssen_diese_bestätigen: {
    de: "Mitarbeiter werden über neue Ansagen informiert und müssen dies lesen und bestätigen.",
    en: "Employees are notified of new announcements and have to confirm them as read.",
  },
  ungelesen: {
    de: "ungelesen",
    en: "unread",
  },
  keine_ansagen: {
    de: "Keine Ansagen",
    en: "No announcements",
  },
  gelesen_von: {
    de: "gelesen von",
    en: "read by",
  },
  weitere_laden: {
    de: "Weitere laden",
    en: "Load more",
  },
  änderungsanträge: {
    de: "Änderungsanträge",
    en: "Change requests",
  },
  mitarbeiter_können_änderung_ihrer_schichten_beantragen_diese_kannst_du_hier_annehmen_oder_ablehnen: {
    de: "Mitarbeiter können Änderung ihrer Schichten beantragen. Diese kannst Du hier annehmen oder ablehnen.",
    en: "Employees can request changes to their shifts. You can accept or reject them here.",
  },
  keine_änderungsanträge: {
    de: "Keine Änderungsanträge",
    en: "No change requests",
  },
  deine_mitarbeiter_können_untereinander_schichten_tauschen_hier_kannst_du_die_anfragen_annehmen_oder_ablehnen: {
    de: "Mitarbeiter können untereinander Schichten Tauschen. Hier kannst Du die Anfragen annehmen oder ablehnen.",
    en: "Your employees can swap shifts with each other. Here you can accept or reject the requests.",
  },
  keine_beantragten_schichtabgaben: {
    de: "Keine beantragten Schichtabgaben",
    en: "No swap requests",
  },
  pause: {
    de: "Pause",
    en: "Break",
  },
  verspätet: {
    de: "Verspätet",
    en: "Late",
  },
  meine_schichten: {
    de: "Meine Schichten",
    en: "My shifts",
  },
  keine_schichten_vorhanden: {
    de: "Keine Schichten vorhanden.",
    en: "No shifts",
  },
  erstelle_offene_schichten_im_dienstplan_sodass_die_mitarbeiter_sich_auf_diese_bewerben_können: {
    de: "Erstelle offene Schichten im Dienstplan, sodass die Mitarbeiter sich auf diese bewerben können.",
    en: "Create open shifts in the roster so that employees can apply for them.",
  },
  schichten: {
    de: "Schichten",
    en: "Shifts",
  },
  offene_capital: {
    de: "Offene",
    en: "Open",
  },
  offene: {
    de: "offene",
    en: "open",
  },
  keine_offenen_schichten: {
    de: "Keine offenen Schichten.",
    en: "No open shifts.",
  },
  in_veröffentlichten_dienstplänen: {
    de: "in veröffentlichten Dienstplänen",
    en: "in published rosters",
  },
  hier_kannst_du_die_bewerbungen_deiner_mitarbeiter_auf_offene_schichten_akzeptieren_oder_ablehnen: {
    de: "Hier werden die Bewerbungen deiner Mitarbeiter auf offene Schichten angezeigt.",
    en: "Here you can accept or reject applications on open shifts.",
  },
  schichtbewerbung: {
    de: "Schichtbewerbung",
    en: "Shift applications",
  },
  keine_bewerbungen: {
    de: "Keine Bewerbungen",
    en: "No applications",
  },
  die_erfassten_arbeitszeiten_der_mitarbeiter_werden_aufgelistet_und_können_hier_bestätigt_oder_abgelehnt_werden: {
    de: "Die erfassten Arbeitszeiten der Mitarbeiter werden hier angezeigt und müssen von einem Admin / Manager bestätigt werden.",
    en: "The tracked times of employees are listed here to be confirmed or rejected.",
  },
  keine_unbestätigte_zeiterfassung: {
    de: "Keine unbestätigte Zeiterfassung.",
    en: "No unconfirmed time trackings.",
  },
  häufige_fragen: {
    de: "Häufige Fragen",
    en: "FAQs",
  },
  erklärvideos: {
    de: "Erklärvideos",
    en: "Video tutorials",
  },
  support_chat: {
    de: "Support-Chat",
    en: "Support-Chat",
  },
  email_gesendet: {
    de: "Email gesendet",
    en: "Email sent",
  },
  kuendigung: {
    de: "Kündigung",
    en: "Quit subscription",
  },
  kuerzel: {
    de: "Kürzel",
    en: "Shorthand",
  },
  prozent: {
    de: "Prozent",
    en: "Percent",
  },
  abo_kuendigen: {
    de: "Abo kündigen",
    en: "Quit subscription",
  },
  abonnement: {
    de: "Abonnement",
    en: "Subscription",
  },
  abo_ändern: {
    de: "Abo ändern",
    en: "Change subscription",
  },
  bitte_schreiben_sie_unserem_support: {
    de: "Bitte schreiben Sie unserem Support per Chat",
    en: "Please contact our support in the chat",
  },
  eine_email_wurde_versendet_an: {
    de: "Eine Email wurde versendet an:",
    en: "An email was sent to:",
  },
  passwort_ändern: {
    de: "Passwort Ändern",
    en: "Change password",
  },
  zum_ändern_des_passwortes_erhalten_sie_einen_link_per_email: {
    de: "Zum Ändern des Passwortes erhalten sie einen Link per Email.",
    en: "You will receive a link by email to change your password.",
  },
  mitarbeiter_können_sich_auf_offene_schichten_bewerben_wenn_es_in_den_einstellungen_erlaubt_ist: {
    de: "Mitarbeiter können sich auf offene Schichten bewerben, \n wenn es in den Einstellungen erlaubt ist.",
    en: "Employees can apply for open shifts, \n if the settings allow it.",
  },
  stempeluhr_pin: {
    de: "Stempeluhr PIN",
    en: "Time Clock PIN",
  },
  kalender_synchronisation: {
    de: "Kalender Synchronisation",
    en: "Calendar synchronization",
  },
  in_zwischenablage_kopieren: {
    de: "In Zwischenablage kopieren",
    en: "Copy to clipboard",
  },
  in_zwischenablage_kopiert: {
    de: "In Zwischenablage kopiert",
    en: "Copy to clipboard done",
  },
  plan: {
    de: "Plan",
    en: "Tier",
  },
  days_tage_pro_testphase: {
    de: (days) => `${days} Tage Testphase`,
    en: (days) => `${days} days test period`,
  },
  zum_basic_oder_pro_plan_wechseln: {
    de: "Zum Basic- oder Pro-Plan wechseln",
    en: "Switch to Basic / Pro Tier",
  },
  konto_löschen: {
    de: "Konto löschen",
    en: "Delete account",
  },
  verfügbare_offene_schichten: {
    de: "Verfügbare offene Schichten",
    en: "Available open shifts",
  },
  anfragen_von_schichtabgaben_an_gruppen: {
    de: "Anfragen von Schichtabgaben an Gruppen",
    en: "Shift handover requests",
  },
  annahmen_und_ablehnungen_von_schichtbewerbungen: {
    de: "Annahmen und Ablehnungen von Schichtbewerbungen",
    en: "Acceptance and rejection of shift applications",
  },
  veröffentlichungen_von_wochenplänen: {
    de: "Veröffentlichungen von Wochenplänen",
    en: "Publication of schedules",
  },
  schichtänderung_beantragt: {
    de: "Schichtänderung beantragt",
    en: "Shift change requests",
  },
  abwesenheit_beantragt: {
    de: "Abwesenheit beantragt",
    en: "Absence requested",
  },
  einstellungen_gespeichert: {
    de: "Einstellungen gespeichert",
    en: "Settings saved",
  },
  in_folgenden_fällen_möchte_ich_durch_handy_push_notes_benachrichtigt_werden: {
    de: "In folgenden Fällen möchte ich durch Handy Push-Notes benachrichtigt werden:",
    en: "I would like to be notified by Push-Notes in the following cases:",
  },
  es_gibt_noch_keine_benachrichtigungen_für_sie: {
    de: "Es gibt noch keine Benachrichtigungen für Sie",
    en: "There are no notifications yet",
  },
  manager_können_abwesenheiten_verwalten: {
    de: "Manager können Abwesenheiten verwalten.",
    en: "Managers can manage absences.",
  },
  schichten_von_abwesenden_mitarbeitern_freigeben: {
    de: "Schichten von abwesenden Mitarbeitern freigeben.",
    en: "Turn shifts of absent employees to open shifts",
  },
  beim_anlegen_der_abwesenheit_werden_die_schichten_des_mitarbeirs_zu_offene_schichten_umgewandelt: {
    de: "Beim Anlegen der Abwesenheit werden die Schichten des Mitarbeiters in offene Schichten umgewandelt.",
    en: "When the absence is created, the employee's shifts are converted into open shifts.",
  },
  managern_erlauben_abwesenheiten_zu_erstellen_und_editieren_sowie_urlaubsanträgen_zu_bearbeiten: {
    de: "Managern erlauben Abwesenheiten zu erstellen und zu editieren, sowie Urlaubsanträgen zu bearbeiten",
    en: "Allow managers to create and edit absences, as well as review absence requests of employees.",
  },
  ansagen_sind_nachrichten_an_alle_oder_einzelne_mitarbeiter_die_per_klick_als_gelesen_markiert_werden_müssen_mit_der_lesebestätigung_gehen_wichtige_informationen_im_betrieb_nicht_mehr_unter:
    {
      de: "Ansagen sind Nachrichten an alle oder einzelne Mitarbeiter, die per Klick als gelesen markiert werden müssen. Mit der Lesebestätigung gehen wichtige Informationen im Betrieb nicht mehr unter.",
      en: "Announcements are messages to all or individual employees that have to be marked as read with a click. With the read confirmation, important information is no longer lost in the company.",
    },
  ansagen_können_gemacht_werden_von: {
    de: "Ansagen können gemacht werden von:",
    en: "Announcements can be made by:",
  },
  keinem: {
    de: "Keinem",
    en: "no one",
  },
  abo: {
    de: "Abo",
    en: "Tier",
  },
  nächste_zahlung_am: {
    de: "Nächste Zahlung am",
    en: "Next payment on",
  },
  abo_gekündigt_zum: {
    de: "Abo gekündigt zum",
    en: "Subscription cancelled as of",
  },
  inkl_mwst: {
    de: "inkl. MwSt.",
    en: "incl. VAT",
  },
  gültig_bis: {
    de: "Gültig bis",
    en: "Valid until",
  },
  plan_wechseln: {
    de: "Plan wechseln",
    en: "Change tier",
  },
  rechnungen: {
    de: "Rechnungen",
    en: "Invoices",
  },
  bezahlt: {
    de: "bezahlt",
    en: "paid",
  },
  in_bearbeitung: {
    de: "in Bearbeitung",
    en: "Processing",
  },
  fehlgeschlagen: {
    de: "fehlgeschlagen",
    en: "failed",
  },
  es_wurde_noch_keine_rechnung_ausgestellt: {
    de: "Es wurde noch keine Rechnung ausgestellt.",
    en: "No invoice has been issued yet.",
  },
  standort_hinzufügen: {
    de: "Standort hinzufügen",
    en: "Add location",
  },
  wirklich_ausstempeln: {
    de: "Wirklich ausstempeln?",
    en: "Confirm clock out",
  },
  schicht_labels: {
    de: "Schicht Labels",
    en: "Shift labels",
  },
  Labels: {
    de: "Labels",
    en: "Labels",
  },
  mit_labels_können_schichten_im_dienstplan_zusätzlich_gekennzeichnet_werden: {
    de: "Mit Labels können Schichten im Dienstplan zusätzlich gekennzeichnet werden.",
    en: "Shifts can be labeled to group and filter them in the roster & reportings.",
  },
  labels_können_an_zeiterfassungen_angehängt_werden: {
    de: "Labels können an Zeiterfassungen angehängt werden.",
    en: "Labels can be attached to trackings.",
  },
  schichten_koennen_mit_mehreren_hashtags_versehen_werden: {
    de: "Schichten können mit mehreren Hashtags versehen werden",
    en: "Multiple hashtags can be appended to a shift.",
  },
  hashtags_können_an_zeiterfassungen_angehängt_werden: {
    de: "Hashtags können an Zeiterfassungen angehängt werden.",
    en: "Multiple hashtags can be appended to a tracking.",
  },
  labels_hinzufügen: {
    de: "Label hinzufügen",
    en: "Add label",
  },
  mitarbeiter_brauchen_mindestens_einen_standort: {
    de: "Mitarbeiter brauchen mindestens einen Standort.",
    en: "Employees need at least one location.",
  },
  um_einen_standort_zu_löschen_müssen_vorher_alle_zugewiesenen_mitarbeiter_entfernt_werden: {
    de: "Um einen Standort zu löschen, müssen vorher alle zugewiesenen Mitarbeiter entfernt werden.",
    en: "In order to delete a location, all assigned employees must first be removed.",
  },
  neuer_standort: {
    de: "Neuer Standort",
    en: "Add location",
  },
  in_diesem_standort_stempeluhr_zeiterfassung_aktivieren: {
    de: () => (
      <>
        In diesem Standort <b>Stempeluhr Zeiterfassung</b> aktivieren.
      </>
    ),
    en: () => (
      <>
        Activate <b>time clock</b> in this location.
      </>
    ),
  },
  arbeitszeit_kann_per_app_gestempelt_werden: {
    de: () => (
      <>
        Arbeitszeit kann per <b>App</b> gestempelt werden.
      </>
    ),
    en: () => (
      <>
        Employees can use the <b>mobile app</b> to clock times.
      </>
    ),
  },
  geofencing_der_mobilen_stempeluhr: {
    de: "Geofencing der mobilen Stempeluhr",
    en: "Geofencing of the mobile time clock",
  },
  geofencing_aktivieren_um_das_ein_ausstempeln_per_app_nur_in_der_nähe_des_arbeitsplatzes_zu_erlauben: {
    de: "Geofencing aktivieren um das Ein- & Ausstempeln per App nur in der Nähe des Arbeitsplatzes zu erlauben",
    en: "Activate geofencing to only allow clocking if the phone is located near the workspace",
  },
  entfernung_in_metern_zum_einstempeln: {
    de: "Entfernung in Metern zum Einstempeln:",
    en: "Distance in meters to allow clocking:",
  },
  mitarbeiter_vom_geofencing_ausschließen: {
    de: "Mitarbeiter vom Geofencing ausschließen",
    en: "Exclude employees from geofencing",
  },
  geofencing_gilt_für_alle_mitarbeiter: {
    de: "Geofencing gilt für alle Mitarbeiter",
    en: "Geofencing applies to all employees",
  },
  gilt_für: {
    de: "Gilt für ",
    en: "Applies to",
  },
  gueltig_ab: {
    de: "Gültig ab ",
    en: "Valid from",
  },
  gueltig_bis: {
    de: "Gültig bis ",
    en: "Valid to",
  },
  gilt_für_x_mitarbeiter: {
    de: (x: string | number) => `Gilt für ${x} Mitarbeiter`,
    en: (x: string | number) => `includes ${x} employees`,
  },
  gilt_für_geofenced_users_active_users_mitarbeiter: {
    de: (geofencedUsers, activeUsers) => `Gilt für ${geofencedUsers} / ${activeUsers} Mitarbeiter`,
    en: (geofencedUsers, activeUsers) => `applies to ${geofencedUsers} / ${activeUsers} users`,
  },
  um_das_einstempeln_per_app_zu_aktivieren_ist_die_adresse_des_standortes_erforderlich_sodass_mitarbeiter_sich_nur_einstempeln_können_wenn_ihr_handy_am_arbeitsplatz_geortet_werden_kann:
    {
      de: "Um das Einstempeln per App zu aktivieren, ist die Adresse des Standortes erforderlich. Sodass Mitarbeiter sich nur einstempeln können wenn ihr Handy am Arbeitsplatz geortet werden kann.",
      en: "The address of the location is required to enable the clock-in via app. So that employees can only clock in if their cell phone can be located at the workspace.",
    },
  es_wird_empfohlen_einen_radius_von_mindestens_radius_metern_anzugeben_um_zu_verhindern_dass_sich_mitarbeiter_mit_ungenauer_smartphone_lokalisierung_nicht_einstempeln_können:
    {
      de: (radius) =>
        `Es wird empfohlen, einen Radius von mindestens ${radius} Metern anzugeben, um zu verhindern, dass sich Mitarbeiter mit ungenauer Smartphone Lokalisierung nicht einstempeln können`,
      en: (radius) =>
        `It is recommended to specify a radius of at least ${radius} meters in order to prevent employees with imprecise smartphone localization from not being able to clock in`,
    },
  mitarbeiter_des_standortes: {
    de: "Mitarbeiter des Standortes",
    en: "Employees of the location",
  },
  aus_allen_standorten: {
    de: "Aus allen Standorten",
    en: "From all locations",
  },
  alle_selektieren: {
    de: "Alle selektieren",
    en: "Select all",
  },
  alle_deselektieren: {
    de: "Alle deselektieren",
    en: "Deselect all",
  },
  mitarbeiter_können_chatten: {
    de: "Mitarbeiter können chatten.",
    en: "Employees can chat.",
  },
  mitarbeiter_können_in_gruppen_chatten: {
    de: "Mitarbeiter können in Gruppen chatten",
    en: "Employees can use group chats",
  },
  mitarbeiter_können_standortübergreifend_chatten: {
    de: "Mitarbeiter können Standortübergreifend chatten",
    en: "Employees can chat with users of other locations",
  },
  für: {
    de: "für",
    en: "for",
  },
  für_alle_standorte: {
    de: "für alle Standorte",
    en: "for all locations",
  },
  wähle_das_bundesland_aus_um_die_gesetzlichen_feiertage_year_branch_zu_importieren: {
    de: (year, branch) => (
      <>
        Wähle das <b>Bundesland</b> aus um die gesetzlichen Feiertage <b>{year}</b>
        <b>{branch}</b> zu importieren:
      </>
    ),
    en: (year, branch) => (
      <>
        Select the <b>state</b> for the public holidays <b> {year} </b>
        <b> {branch} </b> to import:
      </>
    ),
  },
  feiertag_löschen: {
    de: "Feiertag löschen",
    en: "Delete holiday",
  },
  gesetzliche_feiertage_aus_dem_jahr_year_gelöscht: {
    de: (year) => `Gesetzliche Feiertage aus dem Jahr ${year} gelöscht.`,
    en: (year) => `Public holidays of year ${year} deleted.`,
  },
  standort_spezifische_feiertage: {
    de: "Standort Spezifische Feiertage",
    en: "Location specific holidays",
  },
  um_diese_funktion_zu_aktivieren_kontaktieren_sie_bitte_unseren_online_support_per_chat: {
    de: "Um diese Funktion zu aktivieren, kontaktieren Sie bitte unseren online Support per Chat",
    en: "To activate this function, please contact our online support via chat",
  },
  support_chat_öffnen: {
    de: "Support-Chat öffnen",
    en: "Open support chat",
  },
  standortübergreifend: {
    de: "Standortübergreifend",
    en: "All locations",
  },
  feiertage_gelten_für_alle_standorte: {
    de: "Feiertage gelten für alle Standorte",
    en: "Holidays apply to all locations",
  },
  feiertage_gelten_nicht_für_alle_standorte_fügen_sie_jedem_standort_die_gewünschten_feiertage_hinzu_es_ist_darauf_zu_achten_dass_für_jeden_mitarbeiter_der_hauptstandort_unter_den_arbeitszeiten_anzugeben_ist:
    {
      de: "Feiertage gelten nicht für alle Standorte. Fügen Sie jedem Standort die gewünschten Feiertage hinzu. Es ist darauf zu achten, dass für jeden Mitarbeiter der Hauptstandort unter den Arbeitszeiten anzugeben ist.",
      en: "Holidays do not apply to all locations. Add the holidays you want to each location. Also please ensure that the main-location of each employee is specified in the user-contract.",
    },
  gesetzliche_feiertage: {
    de: `Gesetzliche Feiertage`,
    en: `Legal holidays`,
  },
  liste_löschen: {
    de: "Liste löschen",
    en: "Delete list",
  },
  feiertage_importieren: {
    de: "Feiertage importieren",
    en: "Import holidays",
  },
  keine_einträge: {
    de: "Keine Einträge",
    en: "No entries",
  },
  extra_feiertage: {
    de: "Extra Feiertage",
    en: "Extra holidays",
  },
  maximal_50_zeichen: {
    de: "Maximal 50 Zeichen",
    en: "Maximum 50 characters",
  },
  es_können_nicht_alle_rollen_gelöscht_werden_es_muss_mind_1_vorhanden_sein: {
    de: "Es können nicht alle Rollen gelöscht werden. Es muss mind. 1 vorhanden sein.",
    en: "Not all roles can be deleted. There must be at least one.",
  },
  rolle_gelöscht: {
    de: "Rolle gelöscht",
    en: "Role deleted",
  },
  mitarbeiter_brauchen_mindestens_eine_rolle: {
    de: "Mitarbeiter brauchen mindestens eine Rolle.",
    en: "Employees need at least one role.",
  },
  rolle_editieren: {
    de: "Rolle Editieren",
    en: "Edit role",
  },
  neue_rolle: {
    de: "Neue Rolle",
    en: "Add rolle",
  },
  mitarbeiter_der_rolle: {
    de: "Mitarbeiter der Rolle",
    en: "Employees",
  },
  die_reihenfolge_der_rollen_kann_per_drag_drop_geändert_werden_der_dienstplan_folgt_dieser_sortierung: {
    de: "Die Reihenfolge der Rollen kann per Drag&Drop geändert werden. Der Dienstplan folgt dieser Sortierung.",
    en: "The order of the roles can be changed per drag & drop. This order is also applied in the roster.",
  },
  rolle_hinzufügen: {
    de: "Rolle hinzufügen",
    en: "Add Role",
  },
  schicht_adresse: {
    de: "Schicht Adresse",
    en: "Shift address",
  },
  geofencing_aktivieren_um_das_mobile_ein_ausstempeln_nur_in_der_nähe_des_adresse_zu_erlauben: {
    de: "Geofencing aktivieren um das Mobile Ein- & Ausstempeln nur in der Nähe der Adresse zu erlauben.",
    en: "Activate geofencing to allow mobile time clocking only near the address.",
  },
  optional: {
    de: "optional",
    en: "optional",
  },
  geofencing_aktivieren: {
    de: "Geofencing aktivieren",
    en: "Activate geofencing",
  },
  schicht_kann_an_der_adresse_und_am_firmenstandort_gestempelt_werden: {
    de: "Schicht kann an der Adresse und am Firmenstandort gestempelt werden",
    en: "Shift can be clocked at the address and at the company location",
  },
  schicht_kann_von_überall_gestempelt_werden: {
    de: "Schicht kann von überall gestempelt werden",
    en: "Shift can be clocked from anywhere",
  },
  schicht_adressen: {
    de: "Schicht Adressen",
    en: "Shift addresses",
  },
  Adressen: {
    de: "Adressen",
    en: "Addresses",
  },
  aufschläge: {
    de: "Aufschläge",
    en: "Surcharges",
  },
  diese_adressen_können_schichten_im_dienstplan_zugewiesen_werden: {
    de: "Adressen können Schichten im Dienstplan zugewiesen werden.",
    en: "Addresses can be assigned to shifts in the roster.",
  },
  adressen_können_an_zeiterfassungen_angehängt_werden: {
    de: "Adressen können an Zeiterfassungen angehängt werden.",
    en: "Addresses can be attached to time trackings.",
  },
  per_geofencing_kann_an_disen_adressen_mobil_gestempelt_werden: {
    de: "Per Geofencing kann an diesen Adressen mobil gestempelt werden.",
    en: "With Geofencing employees can clock in at these adresses.",
  },
  diese_funktion_ist_in_den_einstellungen_nicht_aktivert: {
    de: "Diese Funktion ist in den Einstellungen nicht aktivert.",
    en: "This function is not activated in the settings.",
  },
  aufschläge_werden_in_den_auswertungen_angezeigt: {
    de: "Die effektiven Zuschläge werden in den Auswertungen angezeigt.",
    en: "Surcharges are being displayed in the reports.",
  },
  adresse_hinzufügen: {
    de: "Adresse hinzufügen",
    en: "Add address",
  },
  hashtag_hinzufügen: {
    de: "Hashtag hinzufügen",
    en: "Add hashtag",
  },
  zuschlag_hinzufügen: {
    de: "Zuschlag hinzufügen",
    en: "Add surcharge",
  },
  pausenregelung: {
    de: "Pausenregelung",
    en: "Break regulation",
  },
  pausenintervall: {
    de: "Pausenintervall",
    en: "Break intervall",
  },
  stunden_arbeit: {
    de: "Stunden Arbeit",
    en: "Hours of work",
  },
  stundenlohn: {
    de: "Stundenlohn",
    en: "Hourly Wage",
  },
  lohn: {
    de: "Lohn",
    en: "Wage",
  },
  minuten_pause: {
    de: "Minuten Pause",
    en: "minutes break",
  },
  intervall_start_wählen: {
    de: "Intervall Start wählen",
    en: "Select intervall start",
  },
  ist_der_abrechnungszeitraum_nicht_der_kalnedermonat_kann_hier_der_starttag_angepasst_werden_somit_kann_z_b_ein_intervall_vom_16_bis_zum_15_des_folgemonats_definiert_werden:
    {
      de: "Ist der Abrechnungszeitraum nicht der Kalnedermonat, kann hier der Starttag angepasst werden. Somit kann z.B. ein Intervall vom 16. bis zum 15. des Folgemonats definiert werden.",
      en: "If the billing period is not the calendar month, the start day can be adjusted here. For example, an interval from the 16th to the 15th of the following month can be defined.",
    },
  gesetzliche_ruhezeit: {
    de: "Gesetzliche Ruhezeit",
    en: "Rest period",
  },
  gesetzliche_ruhezeit_unterschritten: {
    de: "Gesetzliche Ruhezeit unterschritten",
    en: "Rest period overstepped",
  },
  gesetzliche_ruhepause_unterschritten: {
    de: "Gesetzliche Ruhepause unterschritten",
    en: "Break regulation overstepped",
  },
  mindestruhezeit_zwischen_zwei_schichten: {
    de: "Mindestruhezeit zwischen zwei Schichten",
    en: "Minimum rest period between two shifts",
  },
  nach_beendigung_der_täglichen_arbeitszeit: {
    de: "nach Beendigung der täglichen Arbeitszeit",
    en: "after the end of the daily working hours",
  },
  stunden: {
    de: "Stunden",
    en: "hours",
  },
  schicht_pausen_automatisch_gemäß_der_pausenregelung_eintragen: {
    de: (onClick) => (
      <>
        Pausen automatisch gemäß der
        <span className="openBreakRulesBtn" onClick={onClick}>
          Pausenregelung
        </span>
        eintragen
      </>
    ),
    en: (onClick) => (
      <>
        Shift breaks get automatically added according to the
        <span className="openBreakRulesBtn" onClick={onClick}>
          break regulation
        </span>
      </>
    ),
  },

  mitarbeiter_koennen_eigene_schichten_erstellen_und_editieren: {
    de: "Mitarbeiter können eigene Schichten erstellen & editieren.",
    en: "Employees can add & edit own shifts.",
  },

  pausen_entsprechend_der_pausenregelung_zur_zeiterfassung_hinzufügen: {
    de: (onClick) => (
      <>
        Pausen gemäß der
        <span className="trackingBreakRulesBtn" onClick={onClick}>
          Pausenregelung
        </span>
        von der Zeiterfassung abziehen.
      </>
    ),
    en: (onClick) => (
      <>
        Add breaks according to the
        <span className="trackingBreakRulesBtn" onClick={onClick}>
          break rules
        </span>
        for time tracking.
      </>
    ),
  },

  pausen_entsprechend_der_pausenregelung_automatisch_abziehen: {
    de: "Dabei Pausenregelung automatich anwenden.",
    en: "Apply breaks according to the break rules.",
  },

  diese_zeiterfassungen_automatisch_bestätigen: {
    de: "Diese Zeiterfassungen automatisch bestätigen.",
    en: "Auto approve these time trackings.",
  },

  warnung_bei_unterschreitung_der_gesetzlichen_ruhezeit_ruhepausen: {
    de: (onClickRestHours, onClickBreakRules) => (
      <>
        Warnung bei Unterschreitung der gesetzlichen
        <span className="openBreakRulesBtn" onClick={onClickRestHours}>
          Ruhezeit
        </span>
        &
        <span className="openBreakRulesBtn" onClick={onClickBreakRules}>
          Ruhepausen
        </span>
      </>
    ),
    en: (onClickRestHours, onClickBreakRules) => (
      <>
        Show warning if the
        <span className="openBreakRulesBtn" onClick={onClickRestHours}>
          rest regulations
        </span>
        &
        <span className="openBreakRulesBtn" onClick={onClickBreakRules}>
          break regulations
        </span>
        get overstepped.
      </>
    ),
  },
  mitarbeiter_können_sich_auf_offene_schichten_bewerben: {
    de: () => (
      <>
        Mitarbeiter können sich auf <b>offene Schichten</b> bewerben
      </>
    ),
    en: () => (
      <>
        Employees can apply for <b>open shifts</b>
      </>
    ),
  },
  mitarbeiter_können_schichtänderung_beantragen: {
    de: () => (
      <>
        Mitarbeiter können <b>Schichtänderung</b> beantragen
      </>
    ),
    en: () => (
      <>
        Employees can request <b>shift changes</b>
      </>
    ),
  },
  mitarbeiter_können_schichttausch_beantragen: {
    de: () => (
      <>
        Mitarbeiter können <b>Schichttausch</b> beantragen
      </>
    ),
    en: () => (
      <>
        Employees can request <b>shift swaps</b>
      </>
    ),
  },
  mitarbeiter_können_verfügbare_zeiten_angeben: {
    de: () => (
      <>
        Mitarbeiter können <b>verfügbare Zeiten</b> angeben
      </>
    ),
    en: () => (
      <>
        Employees can enter <b>availabilities</b>
      </>
    ),
  },
  mitarbeiter_können_nicht_verfügbare_zeiten_angeben: {
    de: () => (
      <>
        Mitarbeiter können <b>nicht-verfügbare Zeiten</b> angeben
      </>
    ),
    en: () => (
      <>
        Employees can enter <b>unavailabilities</b>
      </>
    ),
  },
  mitarbeiter_können_die_schichten_der_kollegen_sehen: {
    de: () => (
      <>
        Mitarbeiter können die <b>Schichten der Kollegen</b> sehen
      </>
    ),
    en: () => (
      <>
        Employees can see the <b>shifts of colleagues</b>
      </>
    ),
  },
  mitarbeiter_können_die_schichten_kommentare_der_kollegen_sehen: {
    de: () => (
      <>
        Mitarbeiter können die <b>Schicht-Kommentare der Kollegen</b> sehen
      </>
    ),
    en: () => (
      <>
        Employees can see the <b>shifts-comments of colleagues</b>
      </>
    ),
  },
  mitarbeiter_sehen_nur_die_kollegen_mit_selber_rolle: {
    de: () => (
      <>
        Mitarbeiter können nur die <b>Kollegen der eigenen Rolle</b> sehen
      </>
    ),
    en: () => (
      <>
        Employees can only see <b>colleagues of own role</b>
      </>
    ),
  },
  aufschläge_können_pro_schicht_festgelegt_werden: {
    de: () => (
      <>
        <b>Aufschläge</b> können pro Schicht festgelegt werden
      </>
    ),
    en: () => (
      <>
        <b>Surcharges</b> can be defined per shift
      </>
    ),
  },
  personalbedarf_kann_im_dienstplan_eingetragen_werden: {
    de: () => (
      <>
        <b>Personalbedarf</b> kann im Dienstplan eingetragen werden
      </>
    ),
    en: () => (
      <>
        <b>Required shifts</b> can be defined in roster
      </>
    ),
  },
  mitarbeiter_an_freien_tagen_als_nicht_verfügbar_anzeigen: {
    de: () => (
      <>
        Mitarbeiter an <b>freien Tagen</b> als nicht-verfügbar anzeigen
      </>
    ),
    en: () => (
      <>
        Show employees on <b>days off</b> as unavailable
      </>
    ),
  },
  die_uhrzeit_der_pause_ist_beim_erstellen_einer_schicht_anzugeben: {
    de: () => (
      <>
        Die <b>Uhrzeit der Pause</b> ist beim Erstellen einer Schicht anzugeben
      </>
    ),
    en: () => (
      <>
        <b>Break start time</b> can be specified, in addition to the break duration
      </>
    ),
  },
  schicht_adressen_können_definiert_werden: {
    de: () => (
      <>
        <b>Schicht Adressen</b> können angelegt werden
      </>
    ),
    en: () => (
      <>
        <b>Shift addresses</b> can be added
      </>
    ),
  },
  stundenlohn_kann_hinterlegt_werden: {
    de: () => (
      <>
        <b>Stundenlohn</b> kann hinterlegt werden
      </>
    ),
    en: () => (
      <>
        <b>Hourly wages</b> can be entered
      </>
    ),
  },
  monatsintervall_beginnt_am_month_start_des_monats: {
    de: (monthStart) => `Monatsintervall beginnt am ${monthStart}. des Monats`,
    en: (monthStart) => `Month interval starts on ${monthStart}. of month`,
  },
  die_zeiterfassung_muss_zunächst_unter_einstellungen_standorte_aktivert_werden: {
    de: "Die Zeiterfassung muss zunächst unter Einstellungen > Standorte aktivert werden",
    en: "The time tracking must first be activated under Settings > Locations",
  },
  folgende_einstellungen_gelten_für_standorte_mit_aktivierter_stempeluhr_zeiterfassung: {
    de: "Folgende Einstellungen gelten für Standorte mit aktivierter Stempeluhr-Zeiterfassung.",
    en: "The following settings apply to locations with activated time clocking.",
  },
  arbeitszeiten_müssen_gestempelt_werden: {
    de: "Arbeitszeiten müssen gestempelt werden.",
    en: "Working times must be clocked.",
  },
  schichten_mit_fehlender_zeiterfassung_werden_als_nicht_angetreten_markiert: {
    de: "Schichten mit fehlender Zeiterfassung werden als 'nicht angetreten' markiert.",
    en: "Shifts with missing time trackings get marked as 'no show'.",
  },
  pausen_müssen_über_die_stempeluhr_erfasst_werden: {
    de: "Pausen müssen über die Stempeluhr erfasst werden.",
    en: "Breaks must be clocked using the time clock.",
  },
  pausen_werden_automatisch_von_der_gestempelten_zeit_abgezogen: {
    de: "Pausen werden automatisch von der gestempelten Zeit abgezogen.",
    en: "Breaks are automatically deducted from the stamped time.",
  },
  beim_einstempeln_vor_schichtbeginn_die_startzeit_der_schicht_übernehmen: {
    de: "Beim Einstempeln vor Schichtbeginn, die Startzeit der Schicht übernehmen.",
    en: "When clocking in before the start of the shift, apply the shift start time.",
  },
  beim_ausstempeln_schichtzeit_übernehmen_wenn_überschreitung_weniger_als_comp_minuten: {
    de: (Comp) => <>Beim Ausstempeln Schichtzeit übernehmen, wenn Überschreitung weniger als {Comp} Minuten.</>,
    en: (Comp) => <>When exceeding shift end time less then {Comp} minutes, apply shift end time.</>,
  },
  über_die_stempeluhr_erfasste_zeiten_automatisch_bestätigen: {
    de: "Über die Stempeluhr erfasste Zeiten automatisch bestätigen:",
    en: "Automatically confirm clocked times:",
  },
  bei_einer_differenz_der_geplanten_zur_gestempleten_zeit_von_unter_comp_minuten: {
    de: (Comp) => <>Bei einer Differenz der geplanten zur gestempleten Zeit von unter {Comp} Minuten.</>,
    en: (Comp) => <>If difference between the planned and clocked time is less than {Comp} minutes.</>,
  },
  arbeitszeiten_können_auch_ohne_geplante_schichten_gestempelt_werden: {
    de: "Arbeitszeiten können auch ohne geplante Schichten gestempelt werden.",
    en: "Working times can also be clocked without planned shifts.",
  },
  erlaubt_fuer_bis_zu_tagen_in_der_vergangenheit: {
    de: "Erlaubt für bis zu ",
    en: "Allowed for up to",
  },
  in_der_vergangenheit: {
    de: "Tage in der Vergangenheit.",
    en: "days in the past.",
  },
  nur_am_selben_tag_ist_0: {
    de: "Nur am selben Tag = 0",
    en: "Only on same day = 0",
  },
  wenn_weniger_als_die_geplante_pause_gestempelt_wird_geplante_pausendauer_übernehmen: {
    de: "Wenn weniger als die geplante Pause gestempelt wird, geplante Pausendauer übernehmen.",
    en: "If less than the planned break is clocked, use the planned break duration.",
  },
  wenn_weniger_als_die_hinterlegte_pause_gestempelt_wird_pausenregelung_anwenden: {
    de: "Wenn weniger als die hinterlegte Pause gestempelt wird, Pausenregelung anwenden.",
    en: "If less than the planned break is clocked, use the planned break duration.",
  },
  aufeinander_folgende_schichten_können_gemeinsam_gestempelt_werden: {
    de: "Aufeinander folgende Schichten können gemeinsam gestempelt werden.",
    en: "Successive shifts can be clocked together in one go.",
  },
  mitarbeiter_koennen_zeiterfassungen_manuell_eintragen: {
    de: "Mitarbeiter können Zeiterfassungen manuell eintragen.",
    en: "Emploeyees can enter timetrackings manually.",
  },
  mitarbeiter_koennen_zeiterfassungen_manuell_eintragen_und_editieren: {
    de: "Mitarbeiter können Zeiterfassungen manuell eintragen & editieren.",
    en: "Emploeyees can enter and edit timetrackings manually.",
  },
  gestempelte_arbeitszeiten_automatisch_bestaetigen: {
    de: "Gestempelte Arbeitszeiten automatisch bestätigen.",
    en: "Clocked worktimes are automatically confirmed.",
  },
  manuell_eingetragene_arbeitszeiten_automatisch_bestaetigen: {
    de: "Manuell eingetragene Arbeitszeiten automatisch bestätigen.",
    en: "Manually entered worktimes are automatically confirmed.",
  },
  manuell_eintragen_zeiterfassungen_muessen_anschliessend_von_einem_manager_oder_admin_bestaetigt_werden: {
    de: "Manuell eingetragene Zeiterfassungen müssen anschließend noch von einem Manager oder Admin bestätigt werden.",
    en: "Manually entered time trackings must then be confirmed by a manager or admin.",
  },
  so_kann_man_sich_zur_frühschicht_einstempeln_und_zum_ende_der_spätschicht_aussptempeln: {
    de: "So kann man sich zur Frühschicht einstempeln und zum Ende der Spätschicht aussptempeln",
    en: "So one can clock in for the first shift and clock out at the end of the last shift",
  },
  mitarbeiter_können_die_stempeluhr_über_den_eigenen_zugang_im_browser_verwenden: {
    de: "Mitarbeiter können die Stempeluhr über den eigenen Zugang im Browser verwenden.",
    en: "Employees can use the time clock via their own account in the browser.",
  },
  standorte_anlegen: {
    de: "Standorte anlegen",
    en: "Add locations",
  },
  in_diesem_schritt_geben_sie_bitte_die_bezeichnung_ihrer_standorte_an: {
    de: "In diesem Schritt geben Sie bitte die Bezeichnung Ihrer Standorte an.",
    en: "In this step, please enter the names of your locations.",
  },
  Standortbezeichnung: {
    de: "Standortbezeichnung",
    en: "Location name",
  },
  weitere_hinzufügen: {
    de: "Weitere hinzufügen",
    en: "Add more",
  },
  weitere_filter: {
    de: "Weitere Filter",
    en: "More filters",
  },
  weiter: {
    de: "Weiter",
    en: "Next",
  },
  bezeichnung_fehlt: {
    de: "Bezeichnung fehlt",
    en: "Name is missing",
  },
  rollen_anlegen: {
    de: "Rollen anlegen",
    en: "Add Employee Roles",
  },
  zum_beispiel_leitung_koch_lieferant: {
    de: "Zum Beispiel:  Kellner / Koch / Lieferant ",
    en: "For example: Waiter / Cook / Receptionist",
  },
  land_auswählen: {
    de: "Land auswählen",
    en: "Select Your Country",
  },
  notwendig_für_die_bestimmung_der_feiertage: {
    de: "Notwendig für die Bestimmung der Feiertage",
    en: "Needed to import public holidays",
  },
  benutzerkonto_aktiviert: {
    de: "Benutzerkonto aktiviert",
    en: "User account activated",
  },
  demodaten_generiert: {
    de: "Basisdaten generiert",
    en: "Base data generated",
  },
  hauptstandort: {
    de: "Hauptstandort",
    en: "Main location",
  },

  weitere_mitarbeiter_anlegen: {
    de: "Weitere Mitarbeiter anlegen",
    en: "Add Employees",
  },
  sie_können_die_mitarbeiter_auch_nachträglich_bearbeiten: {
    de: "Sie können Mitarbeiter auch nachträglich anlegen / bearbeiten",
    en: "You can add & edit the employees later on",
  },
  keine_weiteren_rollen_gefunden: {
    de: "Keine weiteren Rollen gefunden",
    en: "No further roles found",
  },
  überspringen: {
    de: "Überspringen",
    en: "Skip",
  },
  jeden: {
    de: "jeden",
    en: "every",
  },
  ab: {
    de: "ab",
    en: "from",
  },
  jeden_tag: {
    de: "jeden Tag",
    en: "every day",
  },
  vergangene_ausblenden: {
    de: "Vergangene ausblenden",
    en: "Hide past entries",
  },
  keine_einträge_vorhanden: {
    de: "Keine Einträge vorhanden",
    en: "No entries",
  },
  export: {
    de: "Export",
    en: "Export",
  },
  wie_werden_mitarbeiter_eingeladen: {
    de: "Wie werden Mitarbeiter eingeladen?",
    en: "How are employees invited?",
  },
  das_stundenkonto_ist_nur_in_der_premium_version_verfügbar: {
    de: "Das Stundenkonto ist nur in der Premium Version verfügbar",
    en: "The time account is only available in the pro version",
  },
  die_anwendng_wird_derzeitig_gewartet_bitte_habe_etwas_geduld_die_Anwendung_ist_in_kürze_wieder_verfügbar: {
    de: "Die Anwendung wird derzeitig gewartet. Bitte habe etwas geduld. Die Anwendung ist in Kürze wieder verfügbar.",
    en: "The application is currently being maintained. Please have some patience. The application will be available again shortly.",
  },
  version_ist_nicht_up_to_date: {
    de: `Version ist nicht up-to-date. Bitte laden Sie die Seite neu.`,
    en: `Version is not up-to-date. Please reload the page.`,
  },
  ende: {
    de: "Ende",
    en: "End",
  },
  zum: {
    de: "zum",
    en: "to",
  },
  startzeit_der_pause: {
    de: "Startzeit der Pause",
    en: "Start time of break",
  },
  gewählter_zeitraum_ist_nicht_veröffentlicht: {
    de: "Gewählter Zeitraum ist nicht veröffentlicht",
    en: "Selected period is not published",
  },
  geleistet: {
    de: "geleistet",
    en: "Credited",
  },
  überstunden: {
    de: "Überstunden",
    en: "Overtime",
  },
  feiertag: {
    de: "Feiertag",
    en: "Holiday",
  },
  min_pause: {
    de: "min Pause",
    en: "min break",
  },
  schicht_mit_zeiterfassung: {
    de: "Schicht mit Zeiterfassung",
    en: "Shift with time tracking",
  },
  inclusive_aufschlag_von: {
    de: "Inclusive Aufschlag von",
    en: "Including surcharge of",
  },
  soll_std_punkt: {
    de: "SollStd.",
    en: "Quota",
  },
  über_std_punkt: {
    de: "ÜberStd.",
    en: "Overtime",
  },
  startwert: {
    de: "Startwert",
    en: "Start value",
  },
  startwert_eintragen: {
    de: "Startwert eintragen",
    en: "Enter start value",
  },
  detaillierte_auswertung: {
    de: "Detaillierte Auswertung",
    en: "Detailed report",
  },
  summierte_auswertung: {
    de: "Summierte Auswertung",
    en: "Summed report",
  },
  detailliert: {
    de: "Detailliert",
    en: "Detailed",
  },
  summiert: {
    de: "Summiert",
    en: "Summed",
  },
  summierte_tage: {
    de: "Summierte Tage",
    en: "Summed days",
  },
  spalten_ein_ausblenden: {
    de: "Spalten ein- & ausblenden",
    en: "Hide & display columns",
  },
  ansicht: {
    de: "Ansicht",
    en: "View",
  },
  sichtbare_spalten: {
    de: "Sichtbare Spalten",
    en: "Displayed columns",
  },
  personalnummer: {
    de: "Personalnummer",
    en: "Staff number",
  },
  planzeit: {
    de: "Planzeit",
    en: "Planned time",
  },
  dauer: {
    de: "Dauer",
    en: "Duration",
  },
  datentypen: {
    de: "Datentypen",
    en: "Data types",
  },
  korrekturen: {
    de: "Korrekturen",
    en: "Correction",
  },
  zeitformat: {
    de: "Zeitformat",
    en: "Time format",
  },
  stunden_dezimal: {
    de: "Stunden Dezimal",
    en: "hours decimal",
  },
  wochentage: {
    de: "Wochentage",
    en: "Days of week",
  },
  zum_bestätigen_klicken: {
    de: "Zum Bestätigen klicken",
    en: "Click to confirm",
  },
  inklusive_aufschlag_von: {
    de: "Inklusive Aufschlag von",
    en: "Including surcharge of",
  },
  personalnr_punkt: {
    de: "Personalnr.",
    en: "Staff number",
  },
  summe: {
    de: "Summe",
    en: "Sum",
  },
  summe_der_tage: {
    de: "Summe der Tage",
    en: "Sum of days",
  },
  von: {
    de: "von",
    en: "from",
  },
  vonBy: {
    de: "von",
    en: "by",
  },
  von_bis: {
    de: "Von / Bis",
    en: "From / To",
  },
  ist_start: {
    de: "Ist-Start",
    en: "Tracked start",
  },
  ist_ende: {
    de: "Ist-Ende",
    en: "Tracked end",
  },
  ist_pause: {
    de: "Ist-Pause",
    en: "Tracked break",
  },
  start: {
    de: "Start",
    en: "Start",
  },
  gestartet: {
    de: "gestartet",
    en: "started",
  },
  ende_of: {
    de: "Ende",
    en: "End of",
  },
  gesamt: {
    de: "Gesamt",
    en: "Total",
  },
  tagesnotiz: {
    de: "Tagesnotiz",
    en: "Daily note",
  },
  standort_auswählen: {
    de: "Standort auswählen",
    en: "Select location",
  },
  tagesplan_ansicht: {
    de: "Tagesplan Ansicht",
    en: "Daily schedule view",
  },
  senden: {
    de: "senden",
    en: "send",
  },
  danke: {
    de: "danke",
    en: "thanks",
  },
  Danke: {
    de: "Danke",
    en: "Thanks",
  },
  Vielen_Dank: {
    de: "Vielen Dank",
    en: "Thanks a lot",
  },
  wie_sind_sie_auf_aplano_gestossen: {
    de: `Hey, wie sind Sie auf ${Zplano} gestoßen ?`,
    en: `How did you find out about ${Zplano} ?`,
  },
  wie_haben_sie_von_aplano_erfahren: {
    de: `Diese Info hilft uns sehr. Vielen Dank!`,
    en: `Where did you hear about ${Zplano} ? This information helps us to reduce advertisment costs :) `,
  },
  wie_sind_sie_initial_auf_aplano_gestossen: {
    de: `Wie sind Sie auf ${Zplano} gestoßen ?`,
    en: `How did you find out about ${Zplano} ?`,
  },
  stündlichen_headcount_in_der_dienstplan_kopfzeile_anzeigen: {
    de: "Stündlichen Headcount in der Dienstplan-Kopfzeile anzeigen",
    en: "Display hourly headcount in the roster header row",
  },
  anzahl_der_eingeplanten_mitbarbeiter_pro_stunde: {
    de: "Anzahl der eingeplanten Mitbarbeiter pro Stunde",
    en: "Number of employees scheduled per hour",
  },
  monatsplan_ansicht: {
    de: "Monatsplan Ansicht",
    en: "Monthly roster view",
  },
  das_label_der_schicht_anzeigen: {
    de: "Das Label der Schicht anzeigen.",
    en: "Display the label of the shift.",
  },
  falls_eine_schicht_kein_label_hat_wird_die_schichtzeit_angezeigt: {
    de: "Falls eine Schicht kein Label hat, wird die Schichtzeit angezeigt.",
    en: "If a shift has no label, the shift time is displayed.",
  },
  start_endzeit_der_schicht_anzeigen: {
    de: "Start- & Endzeit der Schicht anzeigen.",
    en: "Display start and end time of the shift.",
  },
  sollstunden_in_leeren_zellen_des_dienstplans_anzeigen: {
    de: "Sollstunden in leeren Zellen des Dienstplans Anzeigen.",
    en: "Show quota hours in empty cells of the roster.",
  },
  diese_werden_sichtbar_wenn_sich_die_maus_über_der_mitarbeiter_spalte_befindet: {
    de: "Diese werden sichtbar, wenn sich die Maus über der Mitarbeiter-Spalte befindet.",
    en: "These are visible when the mouse is over the employee column.",
  },
  intervall_des_monatsplans_kann_angepasst_werden_z_b_15_nov_14_dec: {
    de: "Intervall des Monatsplans kann angepasst werden. ( zB 15.Nov - 14.Dec )",
    en: "The interval of the monthly roster can be adjusted. (e.g. Nov 15 - Dec 14)",
  },
  personalnummer_zum_mitarbeiternamen_im_dienstplan_anzeigen: {
    de: "Personalnummer  im Dienstplan anzeigen.",
    en: "Show staff number in addition to employee name in the roster.",
  },
  mitarbeiter_typ_im_dienstplan_anzeigen: {
    de: "Mitarbeiter-Typ im Dienstplan anzeigen.",
    en: "Show employee type next to the name in the roster.",
  },
  filter_entfernen: {
    de: "Filter entfernen",
    en: "Remove filter",
  },
  dienstplan_filtern: {
    de: "Dienstplan Filtern",
    en: "Filter shifts",
  },
  keine_mitarbeiter_mit_dieser_rolle: {
    de: "Keine Mitarbeiter mit dieser Rolle",
    en: "No employees with this role",
  },
  reihenfolge_ändern: {
    de: "Reihenfolge ändern",
    en: "Change order",
  },
  reihenfolge_per_drag_drop_ändern: {
    de: "Reihenfolge per Drag & Drop ändern",
    en: "Change order per drag & drop",
  },
  alphabetisch: {
    de: "Alphabetisch",
    en: "Alphabetically",
  },
  editierbar: {
    de: "Editierbar",
    en: "Editable",
  },
  wochenplan_ansicht: {
    de: "Wochenplan Ansicht",
    en: "Weekly roster view",
  },
  pausen_in_schichten_des_wochenplans_anzeigen: {
    de: "Pausen in Schichten des Wochenplans anzeigen",
    en: "Display breaks of shifts in the weekly roster",
  },
  länge_der_schichten_im_wochenplan_anzeigen: {
    de: "Länge der Schichten im Wochenplan anzeigen",
    en: "Display total shift duration in the weekly roster",
  },
  samstage_im_wochenplan_ausblenden: {
    de: "Samstage im Wochenplan ausblenden",
    en: "Hide Saturdays in the weekly roster",
  },
  sonntage_im_wochenplan_ausblenden: {
    de: "Sonntage im Wochenplan ausblenden",
    en: "Hide Sundays in the weekly roster",
  },
  überstunden_im_dienstplan_anzeigen: {
    de: "Überstunden im Dienstplan anzeigen.",
    en: "Display overtimes of empleyees in the roster.",
  },
  gruppieren_nach_rolle: {
    de: "Gruppieren nach Rolle",
    en: "Group by role",
  },
  verfügbarkeiten_im_wochenplan_detailliert_anzigen: {
    de: "Verfügbarkeiten im Wochenplan detailliert anzeigen.",
    en: "Expanded display of availabilietes in weekplan",
  },
  um_den_dienstplan_einsehen_zu_können_benötigen_die_mitarbeiter_eine_einladung: {
    de: "Um den Dienstplan einsehen zu können, benötigen die Mitarbeiter eine Einladung.",
    en: "In order to be able to view the roster, the employees need an invitation.",
  },
  mitarbeier_mit_einem_zugang_können: {
    de: `Mitarbeier mit einem Zugang können`,
    en: `Employees with an ${Zplano} access can`,
  },
  aktuelle_dienstplän_von_überall_per_app_einsehen: {
    de: "Aktuelle Dienstplän von überall per App einsehen",
    en: "View current shifts from anywhere using the app",
  },
  benachrichtigung_bei_planänderungen_erhalten: {
    de: "Benachrichtigung bei Planänderungen erhalten",
    en: "Receive notification of shift changes",
  },
  schichttausch_beantragen: {
    de: "Schichttausch beantragen",
    en: "Apply for a shift swap",
  },
  sich_auf_offene_schichten_bewerben: {
    de: "Sich auf offene Schichten bewerben",
    en: "Apply for open shifts",
  },
  urlaubsanträge_einreichen: {
    de: "Urlaubsanträge einreichen",
    en: "Submit vacation requests",
  },
  arbeitszeiten_per_app_stempeln: {
    de: "Arbeitszeiten per App Stempeln",
    en: "Clock work times with the app",
  },
  jetzt_mitarbeiter_einladen: {
    de: "Jetzt Mitarbeiter Einladen",
    en: "Invite employees now",
  },
  mitarbeiter_ohne_zugang: {
    de: "Mitarbeiter ohne Zugang",
    en: "Employees without access",
  },
  veröffentlichung_aufheben: {
    de: "Veröffentlichung aufheben?",
    en: "Unpublish schedule?",
  },
  die_schichten_dieser_woche_werden_dadurch_für_mitarbeiter_nicht_mehr_sichtbar_sein: {
    de: () => (
      <>
        Die Schichten dieser <b>Woche</b> werden dadurch für Mitarbeiter nicht mehr sichtbar sein
      </>
    ),
    en: () => (
      <>
        The shifts of this <b> week </b> will no longer be visible to employees
      </>
    ),
  },
  wochenplan_ist_veröffentlicht: {
    de: "Wochenplan ist veröffentlicht",
    en: "Schedule is published",
  },
  wochenplan_veröffentlichen: {
    de: "Wochenplan veröffentlichen",
    en: "Publish weekly schedule",
  },
  wochenplan_ist_noch_nicht_veröffentlicht: {
    de: "Wochenplan ist noch nicht veröffentlicht",
    en: "Weekly schedule is not yet published",
  },
  wochenplan_unveröffentlicht: {
    de: "Wochenplan Unveröffentlicht",
    en: "Unpublish weekly schedule",
  },
  filter_aktiv: {
    de: "Filter Aktiv",
    en: "Filter active",
  },
  schicht_vorlagen: {
    de: "Schicht Vorlagen",
    en: "Shift Templates",
  },
  vorlage_aktiv: {
    de: "Vorlage aktiv",
    en: "Template active",
  },
  tabellen_ansicht: {
    de: "Tabellen-Ansicht",
    en: "Table view",
  },
  board_ansicht: {
    de: "Board-Ansicht",
    en: "Board view",
  },
  tages_ansicht: {
    de: "Tages-Ansicht",
    en: "Daily view",
  },
  wochen_ansicht: {
    de: "Wochen-Ansicht",
    en: "Weekly view",
  },
  monats_ansicht: {
    de: "Monats-Ansicht",
    en: "Monthly view",
  },
  schichtbewerbungen: {
    de: "Schichtbewerbungen",
    en: "Shift applications",
  },
  starttag_des_monatsintervalls: {
    de: "Starttag des Monatsintervalls",
    en: "Start day of monthly intervall",
  },
  woche_auswählen: {
    de: "Woche auswählen",
    en: "Select week",
  },
  zeiterfassungen: {
    de: "Zeiterfassungen",
    en: "Time trackings",
  },
  zeiterfassung_hinzufuegen: {
    de: "Zeiterfassungen hinzufügen",
    en: "Add time tracking",
  },
  unbestätigte_zeiterfassungen: {
    de: "Unbestätigte Zeiterfassungen",
    en: "Unconfirmed time trackings",
  },
  unbestätigt: {
    de: "unbestätigt",
    en: "Unconfirmed",
  },
  für_diese_funktion_müssen_sie_zunächst_den_dienstplan_standort_festlegen: {
    de: "Für diese Funktion müssen Sie zunächst den Dienstplan Standort festlegen",
    en: "For this function you must select one location for the roster. [ currently its all locations ]",
  },
  wochenVorlagen: {
    de: "Wochen-Vorlagen",
    en: "Week-Templates",
  },

  als_vorlage_speichern: {
    de: "Als Vorlage speichern",
    en: "Save as template",
  },
  änderungs_log: {
    de: "Änderungs Log",
    en: "Change Log",
  },
  drucken: {
    de: "Drucken",
    en: "Print",
  },
  bulk_löschen: {
    de: "Bulk löschen",
    en: "Bulk delete",
  },
  vorlage_löschen: {
    de: "Vorlage löschen",
    en: "Delete template",
  },
  soll_die_vorlage_template_gelöscht_werden: {
    de: (template) => `Soll die Vorlage ${template} gelöscht werden?`,
    en: (template) => `Do you want to delete the template ${template}?`,
  },
  vorlage: {
    de: "Vorlage",
    en: "Template",
  },
  leere_vorlage_erstellen: {
    de: "Leere Vorlage erstellen",
    en: "Create new template",
  },
  umbenennen: {
    de: "Umbenennen",
    en: "Rename",
  },
  headcount: {
    de: "Headcount",
    en: "Headcount",
  },
  schicht: {
    de: "Schicht",
    en: "Shift",
  },
  eine_schicht_mit_einer_zeiterfassung_kann_nicht_verschoben_werden: {
    de: "Eine Schicht mit einer Zeiterfassung kann nicht verschoben werden",
    en: "A shift with a time tracking cannot be moved",
  },
  work: {
    de: "work",
    en: "work",
  },
  frei: {
    de: "frei",
    en: "free",
  },
  die_schichten_der_kw_week_werden_für_mitarbeiter_nicht_mehr_sichtbar: {
    de: (week) => `Die Schichten der KW ${week} werden für Mitarbeiter nicht mehr sichtbar`,
    en: (week) => `The shifts of CW ${week} will no longer be visible to employees`,
  },
  zur_wochen_ansicht: {
    de: "zur Wochen-Ansicht",
    en: "to weekly schedule",
  },
  tagesnotiz_hinzufügen: {
    de: "Tagesnotiz hinzufügen",
    en: "Add daily note",
  },
  leere_zeilen_werden_ausgeblendet: {
    de: "Leere Zeilen werden ausgeblendet.",
    en: "Empty rows are not being displayed.",
  },
  wieder_einblenden: {
    de: "Wieder einblenden",
    en: "Display all rows",
  },
  stundenkonto_zu_m: {
    de: (m) => `Stundenkonto zu ${m}`,
    en: (m) => `Time account to ${m}`,
  },
  monatsbeginn: {
    de: "Monatsbeginn",
    en: "Month start",
  },
  stundenkonto_hier_eintragen: {
    de: "Stundenkonto hier aktivieren",
    en: "Activate hour account",
  },
  wochenbeginn: {
    de: "Wochenbeginn",
    en: "Week start",
  },
  istzeit: {
    de: "Istzeit",
    en: "Credited",
  },
  sollzeit: {
    de: "Sollzeit",
    en: "Quota",
  },
  zur_tagesansicht: {
    de: "zur Tagesansicht",
    en: "Jump to Daily view",
  },
  aufsteigend: {
    de: "Aufsteigend",
    en: "ascending",
  },
  absteigend: {
    de: "Absteigend",
    en: "descending",
  },
  arbeit: {
    de: "arbeit",
    en: "work",
  },
  Arbeit: {
    de: "Arbeit",
    en: "work",
  },
  für_diesen_standort_gibt_es_derzeitig_keine_vorlagen: {
    de: "Für diesen Standort gibt es derzeitig keine Vorlagen.",
    en: "There are no templates for this location.",
  },
  operation: {
    de: "Operation",
    en: "operation",
  },
  schichtzeit: {
    de: "Schichtzeit",
    en: "Shift time",
  },
  pausenstart: {
    de: "Pausenstart",
    en: "Break start",
  },
  geändert_am_von: {
    de: "Geändert am | von",
    en: "Edited on | by",
  },
  schichtdatum: {
    de: "Schichtdatum",
    en: "Shift date",
  },
  änderungen: {
    de: "Änderungen",
    en: "Changes",
  },
  erstellt: {
    de: "erstellt",
    en: "created",
  },
  gelöscht: {
    de: "gelöscht",
    en: "deleted",
  },
  pause_begonnen: {
    de: "Pause begonnen",
    en: "Break started",
  },
  pause_beendet: {
    de: "Pause beendet",
    en: "Break ended",
  },
  ausgestempelt: {
    de: "ausgestempelt",
    en: "clocked out",
  },
  ausgenommen: {
    de: "Ausgenommen",
    en: "Except",
  },
  ausgenommen_von: {
    de: "Ausgenommen von",
    en: "Except for",
  },
  ausschliessen: {
    de: "Ausschließen",
    en: "Exclude",
  },
  notiz_hinzufügen: {
    de: "Notiz hinzufügen",
    en: "Add note",
  },
  es_existiert_eventuell_bereits_eine_bestätigte_zeiterfassung_für_deine_schicht: {
    de: "Es existiert eventuell bereits eine bestätigte Zeiterfassung für deine Schicht.",
    en: "There may already be a confirmed time trackings for your shift.",
  },
  keine_schicht_zum_einstempeln: {
    de: "Keine Schicht zum Einstempeln",
    en: "No shift to clock in",
  },
  es_gibt_derzeit_keine_schicht_zum_einstempeln_für_dich_das_kann_folgende_gründe_haben: {
    de: `Es gibt derzeit keine Schicht zum Einstempeln für dich. Das kann folgende Gründe haben:`,
    en: `There is currently no shift to clock in for you. This can be due to the following reasons:`,
  },
  deine_schicht_befindet_sich_eventuell_an_einem_anderen_standort: {
    de: "Deine Schicht befindet sich eventuell an einem anderen Standort.",
    en: "Your shift may be in a different location.",
  },
  stempeluhr_bitte_neu_starten: {
    de: "Stempeluhr bitte neu starten",
    en: "Please restart the time clock",
  },
  sie_müssen_sich_erst_von_ihrer_derzeitigen_schicht_ausstempeln: {
    de: "Sie müssen sich erst von ihrer derzeitigen Schicht ausstempeln.",
    en: "You have to clock yourself out of your current shift first.",
  },
  stempeluhr_starten: {
    de: "Stempeluhr starten",
    en: "Start time clock",
  },
  derzeit_gibt_es_keine_verfügbaren_schichten_für_dich_zum_stempeln: {
    de: "Es gibt keine verfügbaren Schichten für Dich zum stempeln.",
    en: "There are currently no shifts available for you to clock.",
  },
  label_auswählen: {
    de: "Label auswählen",
    en: "Select label",
  },
  pause_beenden: {
    de: "Pause beenden",
    en: "End break",
  },
  pause_starten: {
    de: "Pause starten",
    en: "Start break",
  },
  folgende_email_adressen_sind_bereits_angelegt_oder_doppelt_n: {
    de: "Folgende Email Adressen sind bereits angelegt oder doppelt: \n",
    en: "The following email addresses are already created or duplicated: \n",
  },
  mitarbeiter_importieren: {
    de: "Mitarbeiter importieren",
    en: "Import employees",
  },
  mitarbeiter_typen: {
    de: "Mitarbeiter Typen",
    en: "Employee Types",
  },
  Mitarbeiter_typ: {
    de: "Mitarbeiter Typ",
    en: "Employee Type",
  },
  sie_können_mitarbeiter_importieren_indem_sie_eine_csv_datei_mit_den_folgenden_vier_spalten_erstellen_downloaden_sie_hierfür_auch_gerne_unsere_formatvorlage:
    {
      de: `Sie können Mitarbeiter importieren, indem Sie eine *.csv Datei mit den folgenden vier Spalten erstellen
    (downloaden Sie hierfür auch gerne unsere Formatvorlage):`,
      en: `You can import employees by creating a *.csv file with the following four columns
    (You can also download our template for this):`,
    },
  mehrere_standorte_können_kommagetrennt_angegeben_werden: {
    de: "Mehrere Standorte können kommagetrennt angegeben werden.",
    en: "Multiple locations can be specified separated by commas.",
  },
  erlaubte_werte: {
    de: "Erlaubte Werte",
    en: "Allowed values",
  },
  mehrere_rollen_können_kommagetrennt_angegeben_werden: {
    de: "Mehrere Rollen können kommagetrennt angegeben werden.",
    en: "Multiple roles can be specified separated by commas.",
  },
  bitte_prüfen_sie_ihren_import: {
    de: "Bitte prüfe die importierten Daten:",
    en: "Please check your import:",
  },
  import: {
    de: "import",
    en: "import",
  },
  jetzt_count_mitarbeiter_erstellen: {
    de: (count) => `Jetzt ${count} Mitarbeiter erstellen`,
    en: (count) => `Add ${count} employees`,
  },
  bitte_geben_sie_ein_passwort_ein: {
    de: "Bitte geben Sie ein Passwort ein",
    en: "Please enter a password",
  },
  mitarbeiter_account_wurde_aktiviert: {
    de: "Mitarbeiter Account wurde aktiviert",
    en: "Employee account has been activated",
  },
  mitarbeiter_passwort_vergeben: {
    de: "Mitarbeiter Passwort vergeben",
    en: "Assign employee password",
  },
  als_alternative_zur_email_aktivierung_können_sie_hier_selber_ein_passwort_vergeben_mit_dem_sich_der_mitarbeiter_einloggen_kann:
    {
      de: "Als alternative zur Email Aktivierung können Sie hier selber ein Passwort vergeben, mit dem sich der Mitarbeiter einloggen kann. ",
      en: "As an alternative to email activation, you can assign a password yourself, which the employee uses to log in.",
    },
  das_passwort_kann_vom_mitarbeiter_nach_dem_login_in_der_app_geändert_werden: {
    de: "Das Passwort kann vom Mitarbeiter nach dem login in der App geändert werden.",
    en: "The password can be changed by the employee after signing in.",
  },
  letzter_arbeitstag_wurde_übernommen: {
    de: "Letzter Arbeitstag wurde übernommen",
    en: "Last working day was saved",
  },
  mitarbeiter_deaktivieren: {
    de: "Mitarbeiter Deaktivieren",
    en: "Deactivate employee",
  },
  letzter_arbeitstag_von: {
    de: "Letzter Arbeitstag von",
    en: "Last working day of",
  },
  deaktivieren: {
    de: "Deaktivieren",
    en: "Deactivate",
  },
  reaktivieren: {
    de: "Reaktivieren",
    en: "Reactivate",
  },
  gelöschter_benutzer: {
    de: "gelöschter Benutzer",
    en: "deleted user",
  },
  mitarbeiter_wurde_gelöscht: {
    de: "Mitarbeiter wurde gelöscht!",
    en: "User deleted!",
  },
  mitarbeiter_löschen: {
    de: "Mitarbeiter Löschen",
    en: "Delete user",
  },
  user_löschen: {
    de: (user) => <>{user} löschen ?</>,
    en: (user) => <>remove {user} ?</>,
  },
  dadurch_werden_alle_daten_des_mitarbeiters_aus_dem_system_gelöscht_inklusive_vergangene_arbeitszeiten_und_abwesenheiten:
    {
      de: "Dadurch werden alle Daten des Mitarbeiters aus dem System gelöscht. Inklusive vergangene Arbeitszeiten und Abwesenheiten.",
      en: "This will delete all of the employee's data from the system. Including past working hours and absences.",
    },
  monatlich: {
    de: "Monatlich",
    en: "Monthly",
  },
  wöchentlich: {
    de: "Wöchentlich",
    en: "Weekly",
  },
  intervall_startet_am: {
    de: "Intervall startet am",
    en: "monthly interval starts on",
  },
  kalendermonat: {
    de: "Kalendermonat",
    en: "First of month",
  },
  an_feiertagen_sollstunden_gutschreiben: {
    de: "An Feiertagen Sollstunden gutschreiben",
    en: "Credit quota hours on public holidays",
  },
  kann_mitarbeiter_verwalten: {
    de: "Kann Mitarbeiter anlegen & editieren",
    en: "Can add & edit users",
  },
  gutschrift_pro_urlaubstag: {
    de: "Gutschrift pro Urlaubstag:",
    en: "Credit hours per vacation day:",
  },
  gutschrift_pro_krankheitstag: {
    de: "Gutschrift pro Krankheitstag:",
    en: "Credit hours per sick day:",
  },
  kein: {
    de: "kein",
    en: "none",
  },
  initiale_arbeitszeit: {
    de: "Initiale Arbeitszeit",
    en: "Initial contract",
  },
  gültig_ab: {
    de: "Gültig ab",
    en: "Valid from",
  },
  dateiname_ändern: {
    de: "Dateiname ändern",
    en: "Change filename",
  },
  kommentar_hinzufügen: {
    de: "Kommentar hinzufügen",
    en: "Add comment",
  },
  angelegt_von_user_am_time_size_kb: {
    de: (user, time, size) => `Angelegt von ${user} am ${time} / ${size} KB`,
    en: (user, time, size) => `Created by ${user} on ${time} / ${size} KB`,
  },
  noch_keine_dokumente_hochgeladen: {
    de: "Noch keine Dokumente hochgeladen",
    en: "No documents uploaded yet",
  },
  stammdaten_gespeichert: {
    de: `Stammdaten gespeichert`,
    en: `Master data saved`,
  },
  bitte_zunächst_daten_speichern: {
    de: "Bitte zunächst Daten speichern",
    en: "Please save your data first",
  },
  benutzerdefiniertes_extra_feld: {
    de: "Benutzerdefiniertes Extra-Feld",
    en: "Custom extra field",
  },
  straße_hausnummer: {
    de: "Straße & Hausnummer",
    en: "Street & number",
  },
  mobilnummer: {
    de: "Mobilnummer",
    en: "Phone number",
  },
  geburtsort: {
    de: "Geburtsort",
    en: "Birthplace",
  },
  geburtstag: {
    de: "Geburtstag",
    en: "Birth date",
  },
  arbeitsantritt: {
    de: "Arbeitsantritt",
    en: "Start of contract",
  },
  vertragsende: {
    de: "Vertragsende",
    en: "End of contract",
  },
  weitere_stammdaten_felder_anlegen_bearbeiten: {
    de: "Weitere Stammdaten-Felder anlegen & bearbeiten",
    en: "Create & edit further master data fields",
  },
  extra_felder: {
    de: "Extra Felder",
    en: "Extra fields",
  },
  ende_probezeit: {
    de: "Ende Probezeit",
    en: "End of probation",
  },
  profilbild: {
    de: "Profilbild",
    en: "Profile picture",
  },
  hier_klicken_um_ein_profilbild_hochzuladen: {
    de: "Hier klicken, um ein Profilbild hochzuladen",
    en: "Click here to upload a profile picture",
  },
  ungültig: {
    de: "ungültig",
    en: "invalid",
  },
  die_datei_darf_nicht_größer_als: {
    de: "Die Datei darf nicht größer als ",
    en: "File cannot be larger than",
  },
  profilbild_speichern: {
    de: "Profilbild speichern",
    en: "Save profile picture",
  },
  profilbild_ändern: {
    de: "Profilbild Ändern",
    en: "Change profile picture",
  },
  profilbild_löschen: {
    de: "Profilbild Löschen",
    en: "Delete profile picture",
  },
  daten_gespeichert: {
    de: `Daten gespeichert`,
    en: `Data saved`,
  },
  neuer_eintrag: {
    de: "Neuer Eintrag",
    en: "Add contract",
  },
  manager: {
    de: "Manager",
    en: "Manager",
  },
  an_user_wurde_eine_email_zur_registrierung_gesendet: {
    de: (user) => `An ${user} wurde eine Email zur Registrierung gesendet`,
    en: (user) => `An email was sent to ${user} to connect to ${Zplano}`,
  },
  mitarbeiterdaten_gespeichert: {
    de: `Mitarbeiterdaten gespeichert`,
    en: `Employee data saved`,
  },
  diese_e_mail_ist_bereits_im_system_vergeben: {
    de: "Diese E-Mail ist bereits im System vergeben.",
    en: "There is alreay an account using this email.",
  },
  mindestens_3_zeichen: {
    de: "Mindestens 3 Zeichen!",
    en: "Min 3 characters!",
  },
  mindestens_eine_rolle_wählen: {
    de: "Mindestens eine Rolle wählen",
    en: "Choose at least one role",
  },
  mindestens_einen_standort_wählen: {
    de: "Mindestens einen Standort wählen",
    en: "Choose at least one location",
  },
  ungültiges_email_format: {
    de: "Ungültiges Email Format",
    en: "Invalid email format",
  },
  vier_ziffern_eingeben: {
    de: "4 Ziffern eingeben",
    en: "Min 4 characters",
  },
  miterbeiter_wurde_eingeladen: {
    de: "Miterbeiter wurde eingeladen.",
    en: "Employee was invited.",
  },
  passwort_vergeben: {
    de: "Passwort vergeben",
    en: "Assign a password",
  },
  alternativ_können_sie_selber_ein_passwort_vergeben: {
    de: (PasswortVergebenButton) => <>Alternativ können Sie selber ein {PasswortVergebenButton}</>,
    en: (PasswortVergebenButton) => <>Alternatively, you can enter a {PasswortVergebenButton} yourself</>,
  },
  einige_felder_wurden_auf_basis_vorheriger_eingaben_vorbelegt: {
    de: "Einige Felder wurden auf Basis vorheriger Eingaben vorbelegt.",
    en: "Some fields have been pre-assigned based on previous entries.",
  },
  vor_nachname: {
    de: "Vor- & Nachname",
    en: "First & last name",
  },
  berechtigung: {
    de: "Berechtigung",
    en: "Rights",
  },
  neue_rolle_erstellen: {
    de: "Neue Rolle erstellen",
    en: "Create a new role",
  },
  view_stellige_pin: {
    de: "4 stellige PIN",
    en: "4 digit PIN",
  },
  aktivieren: {
    de: "Aktivieren",
    en: "Activate",
  },
  jetzt_aktivieren: {
    de: "Jetzt aktivieren",
    en: "Activate now",
  },
  speichern_einladen: {
    de: "Speichern & Einladen",
    en: "Save & invite",
  },
  allgemein: {
    de: "Allgemein",
    en: "General",
  },
  stammdaten: {
    de: "Stammdaten",
    en: "Master data",
  },
  dokumente: {
    de: "Dokumente",
    en: "Documents",
  },
  um_die_änderungen_nicht_zu_verwerfen_sollten_sie_diese_zunächst_speichern: {
    de: "Um die Änderungen nicht zu verwerfen, sollten Sie diese zunächst Speichern",
    en: "In order not to discard the changes, you should first save them",
  },
  neuer_mitarbeiter: {
    de: "Neuer Mitarbeiter",
    en: "Adding Employee",
  },
  plz: {
    de: "PLZ",
    en: "Postcode",
  },
  passiv: {
    de: "Passiv",
    en: "Passive",
  },
  woche: {
    de: "Woche",
    en: "Week",
  },
  monat: {
    de: "Monat",
    en: "Month",
  },
  mindestens_5_zeichen: {
    de: "Mindestens 5 Zeichen",
    en: "Minimum 5 characters",
  },
  ungueltiges_email_format: {
    de: "Ungültiges Email Format",
    en: "Invalid email format",
  },
  der_mitarbeiter_hat_kein_zugang_und_kann_die_App_nicht_benutzen: {
    de: "Der Mitarbeiter hat kein Zugang und kann die App nicht benutzen.",
    en: "This user does not have access to log in to App.",
  },
  der_mitarbeiter_ist_aktiviert_und_kann_sich_einloggen: {
    de: "Der Mitarbeiter ist aktiviert und kann sich einloggen.",
    en: "This user is active and can log in.",
  },
  dieser_account_ist_deaktiviert_der_mitarbeiter_kann_sich_nicht_einloggen: {
    de: "Dieser Account ist deaktiviert. Der Mitarbeiter kann sich nicht einloggen.",
    en: "This account is deactivated. The employee cannot log in.",
  },
  deaktiviert: {
    de: "Deaktiviert",
    en: "Deactivated",
  },
  reaktiviert: {
    de: "Reaktiviert",
    en: "Reactivated",
  },
  eingeladen: {
    de: "eingeladen",
    en: "invited",
  },
  es_wurde_eine_einladung_per_mail_an_den_mitarbeiter_versendet: {
    de: "Es wurde eine Einladung per Mail an den Mitarbeiter versendet.",
    en: "An invitation was sent to the employee by email.",
  },
  letzter_arbeitstag: {
    de: "Letzter Arbeitstag",
    en: "Last working day",
  },
  einladen: {
    de: "Einladen",
    en: "Invite",
  },
  es_wurde_eine_einladung_per_email_gesendet: {
    de: `Es wurde eine Einladung per Email gesendet`,
    en: `An invitation was sent by email`,
  },
  deaktivierte_mitarbeiter_werden_ausgeblendet: {
    de: "Deaktivierte Mitarbeiter werden ausgeblendet",
    en: "Deactivated employees are hidden",
  },
  deaktivierte_mitarbeiter_werden_angezeigt: {
    de: "Deaktivierte Mitarbeiter werden angezeigt",
    en: "Deactivated employees are displayed",
  },
  aus_csv_importieren: {
    de: "Aus CSV importieren",
    en: "Import from CSV",
  },
  csv_export: {
    de: "CSV Export",
    en: "CSV export",
  },
  mitarbeiter_ohne_einladung: {
    de: "Mitarbeiter ohne Einladung",
    en: "Employee without invitation",
  },
  deaktivierte_mitarbeiter_anzeigen: {
    de: "Deaktivierte Mitarbeiter anzeigen",
    en: "Show deactivated employees",
  },
  deaktivierte_mitarbeiter_ausblenden: {
    de: "Deaktivierte Mitarbeiter ausblenden",
    en: "Hide disabled employees",
  },
  alle_x_wochen: {
    de: (x) => `Alle ${x} Wochen`,
    en: (x) => `every ${x} weeks`,
  },
  date_user_die_abgabe_deiner_schicht_am_date_wurde_von_user_abgelehnt: {
    de: (date, user) => `Die Abgabe deiner Schicht am ${date} wurde von ${user} abgelehnt`,
    en: (date, user) => `The handover of your shift on ${date} was rejected by ${user}`,
  },
  stempeluhr_für_user_beendet: {
    de: (user) => `Stempeluhr für ${user} beendet`,
    en: (user) => `Clock out employee: ${user}`,
  },
  augestempelt: {
    de: "Ausgestempelt",
    en: "Clocked out",
  },
  zeit_oder_zeitzone_stimmt_nicht_mit_der_kontozeit_überein: {
    de: "Zeit oder Zeitzone stimmt nicht mit der Kontozeit überein",
    en: "Your device time does not match the account time",
  },
  bitte_überprüfen_sie_das_eingestellte_datum_und_die_uhrzeit_ihres_gerätes: {
    de: "Bitte überprüfen Sie das eingestellte Datum und die Uhrzeit Ihres Gerätes",
    en: "Please check the date and time set on your device",
  },
  fehlt: {
    de: "fehlt",
    en: "fehlt",
  },
  vorlagen_per_drag_and_drop_anwenden: {
    de: () => (
      <>
        Vorlagen per <b>drag & drop</b> anwenden.
      </>
    ),
    en: () => (
      <>
        Apply Templates by <b>drag & drop</b>
      </>
    ),
  },
  ein_admin_hat_volle_lese_und_schreiberechte: {
    de: "Ein Admin hat volle Lese- und Schreiberechte",
    en: "An admin has full read and write permissions.",
  },
  ein_manager_kann_alles_bis_auf_system_einstellungen_ändern_und_mitarbeiter_anlegen_bearbeiten: {
    de: "Ein Manager verfügt über volle Berechtigungen für den ihm zugewiesenen Standort. Darüber hinaus sind die Systemeinstellungen für den Manager gesperrt.. ",
    en: "A manager can do everything except changing system settings. A manager has no access to data of not assigned locations.",
  },
  mitarbeiter_könenn_ihre_eigenen_schichten_und_die_der_kollegen_einsehen: {
    de: "Mitarbeiter könenn ihre eigenen Schichten und die der Kollegen einsehen. Außerdem können Mitarbeiter Urlaub beantragen, Verfügbarkeiten anlegen, Arbeitszeiten erfassen und Schichttausch beantragen. Diese Berechtigungen können in den Einstellungen weiter eingeschränkt werden.",
    en: "Employees can see their own shifts and those of their colleagues. In addition, employees can request leave, create availabilities, record working times and apply for shift swaps. These permissions can be further restricted in the settings.",
  },
  tragen_sie_die_email_adresse_des_mitarbeiers_ein_und_klicken_sie_auf_speichern_einladen: {
    de: 'Tragen Sie die Email Adresse des Mitarbeiers ein und klicken Sie auf "Speichern & Einladen"',
    en: 'Enter the email address of an employee and click on "Save & Invite"',
  },
  der_mitarbeiter_erhält_so_eine_einladungs_email_um_ein_passwort_zu_wählen: {
    de: "Der Mitarbeiter erhält so eine Einladungs-Email um ein Passwort zu wählen.",
    en: "The employee receives an invitation email to choose a password.",
  },
  mit_der_email_adresse_und_dem_passwort_kann_der_mitarbeiter_sich_anschließend_in_der_app_einloggen: {
    de: "Mit der Email Adresse und dem Passwort kann der Mitarbeiter sich anschließend in der App einloggen.",
    en: "The employee can then log into the app with the email address and password.",
  },
  dieser_mitarbeiter_wird_nicht_im_dienstplan_angezeigt: {
    de: "Dieser Mitarbeiter wird nicht im Dienstplan angezeigt",
    en: "This employee is not displayed in the roster",
  },
  mitarbeiter_im_dienstplan_verbergen: {
    de: "Mitarbeiter im Dienstplan verbergen",
    en: "Hide this user in roster",
  },
  mitarbeiim_free_paket_können_nur_manager_admins_die_web_version_nutzenter_haben: {
    de: "Im Free-Paket können nur Manager & Admins die  Web-Version nutzen.",
    en: "In the free tier, only managers & admins can use the  web version.",
  },
  kein_web_zugang_für_mitarbeiter: {
    de: "Kein Web-Zugang für Mitarbeiter",
    en: "No Web-Access for employees",
  },
  konversation_endgültig_löschen: {
    de: "Konversation endgültig löschen",
    en: "Delete conversation permanently",
  },
  konversation_wirklich_löschen: {
    de: "Konversation wirklich löschen?",
    en: "Are you sure you want to do this?",
  },
  alle_nachrichten_dieser_konversation_werden_gelöscht: {
    de: "Alle Nachrichten dieser Konversation werden endgültig gelöscht.",
    en: "All messages of this conversation will be deleted permanently.",
  },
  nachricht_wirklich_löschen: {
    de: "Nachricht wirklich löschen?",
    en: "Do you really want to delete this message?",
  },
  leider_ist_eine_rechnung_überfällig_der_account_wird_demnächst_automatisch_eingeschränkt_falls_die_rechnung_nicht_beglichen_wird:
    {
      de: "Leider ist eine Rechnung überfällig. Der Account wird demnächst automatisch eingeschränkt, falls die Rechnung nicht beglichen wird.",
      en: "Unfortunately, an invoice is overdue. The account will soon be automatically restricted if the invoice is not paid.",
    },
  leider_ist_eine_rechnung_seit_einer_längerer_zeit_überfällig_der_account_wurde_aus_diesem_grund_automatisch_eingeschränkt_bitte_begleichen_sie_die_offene_rechnung_um_den_account_wieder_freizuschalten:
    {
      de: "Leider ist eine Rechnung seit längrer Zeit überfällig. Der Account wurde aus diesem Grund automatisch eingeschränkt. Bitte begleichen Sie die offene Rechnung, um den Account wieder freizuschalten.",
      en: "Unfortunately, an invoice has been overdue for a long time. The account was automatically restricted for this reason. Please settle the outstanding invoice to reactivate the account.",
    },
  der_account_ist_entweder_auf_wunsch_oder_auf_grund_einer_fehlgeschlagenen_lastschriftzahlung_auf_zahlung_per_rechnung_umgestellt_worden_dies_kann_nur_manuell_von_einem_support_mitarbeiter_geändert_werden_bitte_kontaktieren_sie_in_diesem_fall_unseren_support:
    {
      de: "Der Account ist, entweder auf Wunsch, oder auf Grund einer fehlgeschlagenen Lastschriftzahlung, auf Zahlung per Rechnung umgestellt worden. Dies kann nur manuell von einem Support-Mitarbeiter geändert werden. Bitte kontaktieren Sie in diesem Fall unseren Support.",
      en: "The account has been changed to payment by invoice, either by request or due to a failed direct debit payment. This can only be changed manually by an  support employee. In this case, please contact our support.",
    },
  rechnung_überfällig: {
    de: "Rechnung überfällig",
    en: "Invoice overdue",
  },
  kalendar_sync_warning: {
    de: `Sie können den folgenden Link jetzt nutzen, um in Ihrer Kalender App den Aplano Kalender zu abbonieren. Der Kalender wird nicht sofort synchronisiert, sodass neue Schichten möglicherweise nicht direkt angezeigt werden. Sie können in den Kalendar Einstellungen auf Ihrem Smartphone einen Intervall zur Aktualisierung selbständig festlegen.`,
    en: `You can now use the following link to subscribe to the Aplano calendar in your calendar app. The calendar is not synchronised immediately, so new shifts may not be displayed straight away. You can set an update interval yourself in the calendar settings on your smartphone.`,
  },
};
