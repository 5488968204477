import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { connect, useDispatch } from "react-redux";
import "./styles.scss";
import { startSetupWizard } from "../../../reducers/ui/setupWizard";
import _ from "lodash";
import firebase from "firebase/compat/app";
import RegisterOld from "./RegisterOld";
import { getUrlParam } from "../../../frontend-core/helpers/frontendHelpers";

// This component just starts the setupWizard and does a login via the token, which comes from the registration website
export const Register = (props: any) => {
  const dispatch = useDispatch();
  const [displayOldRegister, setDisplayOldRegister] = useState(false);
  useEffect(() => {
    const token = getUrlParam("token");

    if (!token) {
      setDisplayOldRegister(true);
    } else {
      console.log("token incoming");
      firebase
        .auth()
        .signInWithCustomToken(token!)
        .then((r) => {
          console.log("token r", r);
          dispatch(startSetupWizard());
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    }
  }, []);

  if (displayOldRegister) {
    return <RegisterOld {...props} />;
  } else {
    return null;
  }
};
