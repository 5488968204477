import { RosterSettings } from "../pages/settings/rosterSettings/RosterSettings";
import { BaseRepository } from "../frontend-core/BaseRepository";
import { IHinting, IRosterSettings } from "../shared/entities/IRosterSettings";
import { DispFn } from "../frontend-core/types/thunkTypes";
import { AppState } from "../types/AppState";
import { selectShiftMap } from "../selectors/shiftMapSelector";
import { shiftRepository } from "./shiftRepository";
import { IShiftAddress } from "../shared/entities/IShiftAddress";

// Note that there will always be only one entity in DB so we need to grab the resterSettings[0] from store
class ShiftAddressRepository extends BaseRepository<IShiftAddress, IShiftAddress> {
  constructor() {
    super("shiftAddresses");
  }
}

export const shiftAddressRepository = new ShiftAddressRepository();
