import { Button, Icon, Popover, Switch } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { BasicSelect } from "../../../../components/BasicSelect/BasicSelect";
import { getWeekSelectOptions, getYearSelectOptions, getAllMonthsAsOptions } from "../../../../helpers/uiHelpers";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";

const mapStateToProps = (state: AppState) => ({
  monthStartDay: state.ui.general.creditsCustomMonthStartDay,
  rosterStetings: state.data.rosterSettings[0],
});

type OwnProps = {
  week: number;
  month: number;
  year: number;
  isMonthly: boolean;
  onPeriodToggle: () => void;
  exportCsv: () => void;
  exportPdf: () => void;
  exportExcel: () => void;
  onWeekChange: (week: number, year: number) => void;
  onMonthChange: (month: number, year: number) => void;
  onMonthStartDayChange: (month: number) => void;
  isDataLoading: boolean;
};

type State = {
  isPopoverOpen?: boolean;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;
class HourAccountsActionBarComp extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isPopoverOpen: false,
    };
  }

  getPeriodOptions = () => {
    return this.props.isMonthly ? getAllMonthsAsOptions() : getWeekSelectOptions(this.props.year);
  };

  doExport = (type: "pdf" | "csv" | "excel") => {
    this.setState({ isPopoverOpen: false });
    type === "pdf" && this.props.exportPdf();
    type === "csv" && this.props.exportCsv();
    type === "excel" && this.props.exportExcel();
  };

  getPopoverContent = () => (
    <div className="exportCreditsPopoverMain">
      <div className="option" onClick={() => this.doExport("pdf")}>
        <Icon type="file-pdf" />
        PDF {lg.export}
      </div>
      <div className="option" onClick={() => this.doExport("csv")}>
        <Icon type="file-excel" />
        CSV {lg.export}
      </div>
      <div className="option last" onClick={() => this.doExport("excel")}>
        <Icon type="file-excel" />
        Excel {lg.export}
      </div>
    </div>
  );

  render() {
    const {
      week,
      month,
      year,
      isDataLoading,
      isMonthly,
      onWeekChange,
      onMonthChange,
      onMonthStartDayChange,
      monthStartDay,
      rosterStetings,
    } = this.props;

    const weeksInYear = (year: number) => moment().year(year).isoWeeksInYear();

    const showMonthIntervalStartPicker = rosterStetings.allowCustomMonthInterval;
    const isLastOfYear = isMonthly ? month === 11 : week === weeksInYear(year);
    const isFirstOfYear = isMonthly ? month === 0 : week === 1;

    const daysOfMonth = [...Array(31).keys()].map((i) => i + 1);
    const monthDayPickerOptions = daysOfMonth.map((day) => ({
      value: day,
      label: day,
      disabled: day > 29,
    }));

    return (
      <div className="hourAccountsActionBar">
        <div className="switchWrapper">
          <BasicSelect
            id="credits-interval-select"
            value={String(this.props.isMonthly)}
            options={[
              { value: "true", label: lg.monat },
              { value: "false", label: lg.woche },
            ]}
            onChange={this.props.onPeriodToggle}
            size="large"
            style={{
              width: "100%",
              color: "#1a90ff",
            }}
          />
        </div>
        <div className="dateFilter cell weekSelectWrapper">
          {/* <div className='label'>Bis</div> */}

          <div className="selectWrapper periodSelectWrapper">
            <BasicSelect
              id="credits-week/month-select"
              options={this.getPeriodOptions()}
              value={isMonthly ? month : week}
              onChange={(offset: number) => (isMonthly ? onMonthChange(offset, year) : onWeekChange(offset, year))}
              size="large"
              keepOrder
            />
          </div>
          {isMonthly && showMonthIntervalStartPicker && (
            <div className="selectWrapper customStartDaySelectWrapper " data-rh={lg.starttag_des_monatsintervalls}>
              <BasicSelect
                id="customMonthIntervalStartDaySelect"
                options={monthDayPickerOptions}
                value={monthStartDay}
                onChange={(day: number) => onMonthStartDayChange(day)}
                size="large"
                keepOrder
              />
            </div>
          )}
          <ButtonGroup size="large">
            <Button
              id="credits-prev-interval"
              type="primary"
              disabled={isDataLoading}
              onClick={() => {
                const newYear = isFirstOfYear ? year - 1 : year;
                isMonthly
                  ? onMonthChange(isFirstOfYear ? 11 : month - 1, newYear)
                  : onWeekChange(isFirstOfYear ? weeksInYear(year - 1) : week - 1, newYear);
              }}
            >
              <Icon type="left" />
            </Button>
            <Button
              id="credits-next-interval"
              type="primary"
              disabled={isDataLoading}
              onClick={() => {
                const newYear = isLastOfYear ? year + 1 : year;
                isMonthly
                  ? onMonthChange(isLastOfYear ? 0 : month + 1, newYear)
                  : onWeekChange(isLastOfYear ? 1 : week + 1, newYear);
              }}
            >
              <Icon type="right" />
            </Button>
          </ButtonGroup>
        </div>
        <div className="dateFilter yearSelectWrapper">
          {/* <div className='label'>Von</div> */}
          <div className="selectWrapper">
            <BasicSelect
              id="credits-year-select"
              options={getYearSelectOptions()}
              value={year}
              onChange={(_year: number) => {
                isMonthly ? onMonthChange(month, _year) : onWeekChange(week, _year);
              }}
              size="large"
              keepOrder
            />
          </div>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <Popover
            content={this.getPopoverContent()}
            trigger="click"
            visible={this.state.isPopoverOpen}
            onVisibleChange={(isPopoverOpen) => this.setState({ isPopoverOpen })}
            placement="bottomRight"
          >
            <Button id="credits-export-csv" type="default" icon="download" size="large" data-rh={lg.export}></Button>
          </Popover>
        </div>
      </div>
    );
  }
}

export const HourAccountsActionBar = connect<StoreProps, DispatchBaseProps, {}, AppState>(mapStateToProps)(
  HourAccountsActionBarComp
);
