import React from "react";
import { connect, batch } from "react-redux";
import { AppState } from "../../../../../types/AppState";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { Button, Modal, Icon } from "antd";
import { openModal } from "../../../../../actions/modal";
import { RosterMode } from "../../../../../reducers/ui/shifts/roster/rosterMode";
import {
  selectCurrentPublishedBranches,
  selectIsWeekPlanPublished,
} from "../../../../../selectors/WeekPlanPublishedSelector";
import { unpublishWeekPlan, unpublishWeekPlans } from "../../../../../actions/weekPlan";
import { PublishWeekModal } from "../../../../../components/modals/PublishWeekModal/PublishWeekModal";
import cn from "classnames";
import "./styles.scss";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { selectVisibleBranches } from "../../../../../selectors/VisibleBranchesSelector";
import moment from "moment";
import { SDateFormat } from "../../../../../shared/helpers/SimpleTime";
import { selectUserFullMap } from "../../../../../selectors/userFullMapSelector";
import { ShiftPopup } from "../../../../../components/ShiftPopup/ShiftPopup/ShiftPopup";
import { RoleType } from "../../../../../shared/entities/IUser";
import { selectTimeClockSettingsOfSesionUser } from "../../../../../selectors/timeClockSettingsOfSessionUser";
import { canEmployeAddEditPunchingOnDate } from "../../../../../frontend-core/helpers/frontendHelpers";

const mapStateToProps = (state: AppState) => {
  return {
    clockings: state.data.timeClockings,
    userFullMap: selectUserFullMap(state),
    sessionInfo: selectSessionInfo(state),
    selectedDay: state.ui.shifts.roster.selectedDay,
    userTimeClocksettings: selectTimeClockSettingsOfSesionUser(state),
  };
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class EnterClockingButtonV2_ extends React.PureComponent<Props> {
  render() {
    const { dispatch, sessionInfo, selectedDay, userTimeClocksettings } = this.props;
    const sessionUser = sessionInfo.user;
    const isEmployee = sessionUser.role === RoleType.employee;
    const userId = isEmployee ? sessionUser.id : undefined;
    const date = isEmployee ? moment().format(SDateFormat) : selectedDay;

    const endOfMonth = moment(selectedDay, SDateFormat).endOf("month").format(SDateFormat);
    const today = moment().format(SDateFormat);
    const canEmployeeEditClockingOfMonth = canEmployeAddEditPunchingOnDate(userTimeClocksettings, endOfMonth, today);
    const isCurrentMonth = moment(selectedDay, SDateFormat).month() === moment().month();

    if (
      isEmployee &&
      (!userTimeClocksettings.allowManualClockingsV2 || (!canEmployeeEditClockingOfMonth && !isCurrentMonth))
    ) {
      return null;
    }

    return (
      <div
        className="enterClockingButtonMain"
        onClick={() => {
          dispatch(openModal(ShiftPopup, { shift: { date, userId } }));
        }}
      >
        <Icon type="plus" />
        <div className="label">Zeiterfassung</div>
      </div>
    );
  }
}

export const EnterClockingButtonV2 = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(
  EnterClockingButtonV2_
);
