import { IPushNoteDB, IPushNote } from "./../entities/IPushNote";
import { ITrackingDB, ITracking } from "./../entities/ITracking";
import { nullifyProps } from "./firebaseHelpers";
import { IShift, IShiftDB } from "./../entities/IShift";
import { ITimeClocking, ITimeClockingDB } from "../entities/ITimeClocking";

export const shiftToDb = (s: IShift): IShiftDB => {
  return nullifyProps({
    ...s,
    branchId_date: `${s.branchId}_${s.date}`,
    userId_date: `${s.userId}_${s.date}`,
  });
};

export const trackingToDb = (s: ITracking): ITrackingDB => {
  return nullifyProps({
    ...s,
    branchId_date: `${s.branchId}_${s.date}`,
    userId_date: `${s.userId}_${s.date}`,
    userId_isAccepted: `${s.userId}_${s.isAccepted}`,
  });
};

export const pushNoteToDb = (s: IPushNote): IPushNoteDB => {
  return nullifyProps({
    ...s,
    userId_hasSeen: `${s.userId}_${s.hasSeen}`,
  });
};

export const timeClockingToDb = (s: ITimeClocking): ITimeClockingDB => {
  return nullifyProps({
    ...s,
    branchId_date: `${s.branchId}_${s.date}`,
    userId_date: `${s.userId}_${s.date}`,
  });
};
