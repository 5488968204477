import { DispFn } from "./types/thunkTypes";
import * as Sentry from "@sentry/browser";
import { authRepository } from "../repositories/authRepository";

type OperationCount = { [time: string]: number };

export class LoopProtector {
  static operationCount: OperationCount = {};

  static check = (dispatch: DispFn) => {
    // passing dispatch as a parameter for performance reasons
    const time = new Date().getTime() - (new Date().getTime() % 5000); // dont allow more than 200 operations per 5 seconds
    LoopProtector.operationCount[time] = (LoopProtector.operationCount[time] || 0) + 1;
    if (LoopProtector.operationCount[time] > 200) {
      console.log(LoopProtector.operationCount);
      Sentry.captureMessage("someone is looping");
      Sentry.addBreadcrumb({ data: { operationCount: LoopProtector.operationCount } });
      Sentry.captureException(new Error("loops over "));
      dispatch(authRepository.logout());
    }
    // if (LoopProtector.operationCount[time] > 1) {
    //   console.log(LoopProtector.operationCount);
    // }
  };
}
