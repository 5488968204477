import { closestWithAttribute } from "../../../../helpers/general";

type KanbanCellId = {
  date: string;
  jobPositionId: string | undefined;
};

export const getKanbanCellID = (e: any): KanbanCellId | undefined => {
  const cellNode = closestWithAttribute(e.target, "data-type", "kanban-cell");
  const id = cellNode?.getAttribute("data-kanban-cell-id");
  if (id) {
    return {
      date: id.split("_")[0],
      jobPositionId: id.split("_")[1] || undefined,
    };
  } else {
    return undefined;
  }
};
