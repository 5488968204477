import { BaseRepository } from '../frontend-core/BaseRepository';
import { IBranch } from '../shared/entities/IBranch';


class BranchRepository extends BaseRepository<IBranch> {
  constructor() {
    super('branches');
  }
}

export const branchRepository = new BranchRepository();
