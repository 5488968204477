import TagManager from "react-gtm-module";
import { ITenantInfo } from "../shared/entities/ITenantInfo";
import { environment } from "../env";

export class Analytics {
  static hasBeenInitialized: boolean;
  static profitwellInitialized: boolean;

  static initialize = () => {
    if (!Analytics.hasBeenInitialized) {
      TagManager.initialize({
        gtmId: environment.isV2() ? "GTM-PZJFWVZ" : "GTM-NC735K2",
      });

      Analytics.hasBeenInitialized = true;
    }
  };

  // static initializeProfitWell = (tenantInfo: ITenantInfo) => {
  //   if (!Analytics.profitwellInitialized) {
  //     // profitWell
  //     const s = document.createElement("script");
  //     s.type = "text/javascript";
  //     s.async = true;
  //     s.id = "profitwell-js";
  //     s["data-pw-auth"] = "167ade22062edd51589e904af410c963";

  //     s.innerHTML = `
  //     (function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
  //     a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
  //     s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
  //     })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');

  //     profitwell('start', { 'user_id': "${tenantInfo.stripeCustomerId}" });

  //   `;
  //     document.body.appendChild(s);
  //     Analytics.profitwellInitialized = true;
  //   }
  // };

  static tagManager = TagManager;
}
