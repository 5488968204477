import { reportColumnsReducer } from "./../../reportSettings/reportColumns";
import { reportFiltersReducer } from "./reportFilters";
import { combineReducers } from "redux";
import { getSimpleReducer } from "../../../helpers/reducerHelpers";
import { reportTypeReducer } from "./reportType";

export const reportingUIReducer = combineReducers({
  filters: reportFiltersReducer,
  reportType: reportTypeReducer,
});
