import { IAbsenceType } from "./../shared/entities/IAbsenceType";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";

export const selectAbsenceTypeMap: (state: AppState) => { [absenceId: string]: IAbsenceType } = createSelector(
  [(state: AppState) => state.data.absenceTypes],
  (absenceTypes) => {
    const map: { [k: string]: IAbsenceType } = {};
    absenceTypes.forEach((s) => (map[s.id] = s));
    return map;
  }
);
