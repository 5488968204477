import React from "react";
import { connect, batch } from "react-redux";
import { AppState } from "../../../../../../types/AppState";
import { DispatchBaseProps } from "../../../../../../frontend-core/types/DispatchBaseProps";
import { Button, Icon, DatePicker } from "antd";
import "./styles.scss";
import _ from "lodash";
import moment, { Moment } from "moment";
import ButtonGroup from "antd/lib/button/button-group";
import {
  toSimpleDate,
  toMomentUnsafe,
  simpleDateToMoment,
  toMoment,
} from "../../../../../../shared/helpers/timeHelpers";
import { SDateFormat } from "../../../../../../shared/helpers/SimpleTime";
import { selectDay } from "../../../../../../reducers/ui/shifts/roster/selectedDay";
import { selectWeek } from "../../../../../../reducers/ui/shifts/roster/selectedWeek";
import { selectMonth } from "../../../../../../reducers/ui/shifts/roster/selectedMonth";
import { updateSelectedDate } from "../helpers";
import { addSimpleDays } from "../../../../../../shared/helpers/dateHelpers";

const mapStateToProps = (state: AppState) => {
  return {
    selectedDay: state.ui.shifts.roster.selectedDay,
    isV2: state.data.tenantInfo.isV2,
  };
};

type State = {
  datePickerIsOpen: boolean;
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _DayStepper extends React.PureComponent<Props, State> {
  today: string;
  constructor(props: Props) {
    super(props);
    this.today = moment().format(SDateFormat);
    this.state = {
      datePickerIsOpen: false,
    };
  }

  step = (step: number) => {
    const { selectedDay } = this.props;
    const newDate = toSimpleDate(toMoment(selectedDay).add(step, "days"));
    this.props.dispatch(updateSelectedDate(newDate));
  };

  render() {
    const { selectedDay, isV2 } = this.props;
    const weekDay = simpleDateToMoment(selectedDay).format("dd"); //.slice(0, 2)
    const day = simpleDateToMoment(selectedDay).format("DD"); //.slice(0, 2)
    const month = simpleDateToMoment(selectedDay).format("MMM").slice(0, 3);

    const canStepForward = !isV2 || addSimpleDays(selectedDay, 1) <= this.today;

    return (
      <div className="dayStepperMain">
        <span className="selectedDay" onClick={() => this.setState({ datePickerIsOpen: !this.state.datePickerIsOpen })}>
          <>
            <span className="weekDay">{weekDay}</span>
            <span className="daySpan">{day}</span>
            <span className="month">{month}</span>
          </>
        </span>
        <span className="arrow-icons">
          <ButtonGroup>
            <Button id="roster-prev-week" onClick={() => this.step(-1)}>
              <Icon type="left" />
            </Button>
            {/* <Button>
              <Icon type="vertical-align-bottom" />
            </Button> */}
            <Button id="roster-next-week" onClick={() => this.step(1)} disabled={!canStepForward}>
              <Icon type="right" />
            </Button>
          </ButtonGroup>
        </span>
        <DatePicker
          open={this.state.datePickerIsOpen}
          onChange={(m) => this.props.dispatch(updateSelectedDate(toSimpleDate(m as Moment)))}
          placeholder="Select week"
          value={moment(this.props.selectedDay, SDateFormat)}
          allowClear={false}
          format={"DD. MMM YYYY"}
          onOpenChange={(v) => this.setState({ datePickerIsOpen: v })}
          disabledDate={(m) => !!(m && m.year() > 2999)}
          id="rosterWeekPicker"
        />
      </div>
    );
  }
}

export const DayStepper = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(_DayStepper);
