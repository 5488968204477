import React from "react";
import { connect } from "react-redux";
import { NavLinkProps, NavLink } from "react-router-dom";
import { AppState } from "../../types/AppState";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import { ISitemapEntry } from "../../helpers/sitemap";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { RoleType } from "../../shared/entities/IUser";
import "./styles.scss";

export type SubBarRoute = NavLinkProps & { requiredRole?: RoleType };

const mapStateToProps = (state: AppState) => ({
  sessionInfo: selectSessionInfo(state),
  creditCorrection: state.data.creditCorrections,
  state: state,
});

type OwnProps = { links: ISitemapEntry[] };
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class SideBarComponent extends React.PureComponent<Props> {
  render() {
    const { sessionInfo } = this.props;
    const userRole = sessionInfo.user.role;

    return (
      <div className="sideBarMain">
        <div className="wrapper sideBarMainWrapper">
          <div className="container">
            {this.props.links.map((link) =>
              link.hasRequiredRights(sessionInfo.user) ? (
                <NavLink to={link.url} key={link.url as string} className="linkSub">
                  {link.title}
                </NavLink>
              ) : null
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const SideBar = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(SideBarComponent);
