import {
  getClockInToShift,
  generateTrackingByTimeClocking,
  generateShiftOfDynamicClocking,
  generateTrackingOfDynamicClocking,
  getBreakMinutesOfPunchingV2,
  capPunchingStartTime,
} from "./../shared/helpers/timeClockingHelpers";
import { IShift } from "./../shared/entities/IShift";
import { STimeFormat, SDateFormat } from "./../shared/helpers/SimpleTime";
import { selectTimeClockingMap, selectUserMap } from "./../selectors/mapSelectors";
import { generateUserRosterSettings, generateUserTimeClockSettings } from "./../shared/helpers/settingsHelpers";
import { toSimpleDate, toSimpleTime } from "./../shared/helpers/timeHelpers";
import { AppState } from "../types/AppState";
import { DispFn } from "../frontend-core/types/thunkTypes";
import moment from "moment";
import { timeClockingRepository } from "../repositories/timeClockingRepository";
import { notification, Modal } from "antd";
import { shiftRepository } from "../repositories/shiftRepository";
import { DeviceType, ITimeClocking } from "../shared/entities/ITimeClocking";
import { finalizeTimeClockingOnFinish, calcBreakMinutes } from "../shared/helpers/timeClockingHelpers";
import { trackingRepository } from "../repositories/TrackingRepository";
import { getUserName } from "../shared/helpers/userHelpers";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import { IRosterSettings } from "../shared/entities/IRosterSettings";
import { selectSessionInfo } from "../selectors/SessionInfoSelector";
import { getPunchingLogDevice } from "../components/ShiftPopup/ShiftPopup/helper";
import { getPunchingLog } from "../frontend-core/helpers/frontendHelpers";
import { selectTimeClockSettingsOfSesionUser } from "../selectors/timeClockSettingsOfSessionUser";

export const stopTimeClocking = (clocking: ITimeClocking) => {
  return async (dispatch: DispFn, getState: () => AppState) => {
    if (clocking.isDynamicClocking) {
      return dispatch(stopDynamicTimeClocking(clocking));
    }
    const state = getState();
    const user = selectUserMap(state)[clocking.userId];
    const timeClockSettings = state.data.timeClockSettings[0];
    const rosterSettings = state.data.rosterSettings[0];
    const nowSimpleTime = toSimpleTime(moment());
    const userTimeClockSettings = generateUserTimeClockSettings(timeClockSettings, user);
    const userRosterSettings = generateUserRosterSettings(rosterSettings, user);
    const finalizedClocking = finalizeTimeClockingOnFinish(clocking, nowSimpleTime);
    const tracking = generateTrackingByTimeClocking(finalizedClocking, userTimeClockSettings, userRosterSettings);

    await Promise.all([
      dispatch(timeClockingRepository.update(finalizedClocking)),
      dispatch(trackingRepository.create(tracking)),
    ]);

    notification.success({ message: lg.stempeluhr_beendet });
  };
};

export const toggleTimeClockingBreak = (timeClocking: ITimeClocking, type: "start" | "end") => {
  return async (dispatch: DispFn, getState: () => AppState) => {
    const isTimeClockMode = !!getState().ui.timeClockMode;
    const isV2 = getState().data.tenantInfo.isV2;
    const deviceType = isTimeClockMode ? DeviceType.Terminal : DeviceType.Browser; // Mobile the devicetypes differ in mobile

    const breakActivities = [
      ...(timeClocking.breakActivities || []),
      {
        time: toSimpleTime(moment()),
        type: type,
      },
    ];
    await dispatch(
      timeClockingRepository.update({
        ...timeClocking,
        breakActivities: breakActivities,
        breakMinutes: calcBreakMinutes(breakActivities, toSimpleTime(moment())),
        logB: isV2 ? deviceType : undefined,
      })
    );
    notification.success({
      message: lg.pause_gestartet_beendet(type),
    });
  };
};

export const startTimeClocking = (shiftId: string) => {
  return async (dispatch: DispFn, getState: () => AppState) => {
    const shift = (await dispatch(shiftRepository.fetchOne(shiftId)))!;
    notification.success({ message: lg.stempeluhr_started });

    const clocking = getClockInToShift(shift, toSimpleTime(moment()));
    return dispatch(timeClockingRepository.create(clocking));
  };
};

export const startDynamicTimeClocking =
  (baseClocking: {
    userId: string;
    branchId: string;
    jobPositionId: string;
    workSpaceId?: string;
    startTime?: string; // Just for DEV purposes
  }) =>
  (dispatch: DispFn): Promise<ITimeClocking> => {
    const { userId, branchId, jobPositionId, workSpaceId } = baseClocking;
    const id = uuid();
    const date = moment().format(SDateFormat);
    const startTime = baseClocking.startTime || moment().format(STimeFormat);
    notification.success({ message: lg.stempeluhr_started });

    return dispatch(
      timeClockingRepository.create({
        id,
        userId,
        date,
        startTime,
        branchId,
        jobPositionId,
        shiftStartTime: startTime,
        shiftEndTime: startTime,
        shiftBreakMinutes: 0,
        breakActivities: [],
        breakMinutes: 0,
        workSpaceId,
        isDynamicClocking: true,
      })
    );
  };

export const stopDynamicTimeClocking = (_timeClocking: ITimeClocking) => {
  return async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const user = selectUserMap(state)[_timeClocking.userId];
    const timeClockSetting = generateUserTimeClockSettings(state.data.timeClockSettings[0], user);
    const rosterSetting = generateUserRosterSettings(state.data.rosterSettings[0], user);
    const nowSimpleTime = toSimpleTime(moment());

    const clocking = finalizeTimeClockingOnFinish(_timeClocking, nowSimpleTime);
    const tracking = generateTrackingOfDynamicClocking(clocking, clocking.endTime!, timeClockSetting, rosterSetting);
    const shift = generateShiftOfDynamicClocking(clocking, tracking);

    await dispatch(shiftRepository.create(shift));
    await Promise.all([
      dispatch(timeClockingRepository.update(clocking)),
      dispatch(trackingRepository.create(tracking)),
    ]);

    notification.success({ message: lg.stempeluhr_für_user_beendet(getUserName(user)) });
  };
};

export const startPunching =
  ({
    userId,
    branchId,
    addressId,
    comment,
    workSpaceId,
  }: {
    userId: string;
    branchId: string;
    addressId?: string;
    comment?: string;
    workSpaceId?: string;
  }) =>
  (dispatch: DispFn, getState: () => AppState): Promise<ITimeClocking> => {
    const deviceType = getState().ui.timeClockMode.activeSince ? DeviceType.Terminal : DeviceType.Browser; // TodoMobile the devicetypes differ in mobile
    const id = uuid();
    const date = moment().format(SDateFormat);
    const usersMap = selectUserMap(getState());
    const startTime = moment().format(STimeFormat);
    notification.success({ message: lg.stempeluhr_started });
    const timestamp = moment().unix().toString();

    return dispatch(
      timeClockingRepository.create({
        id,
        date,
        startTime,
        breakMinutes: 0,
        userId: userId,
        branchId: branchId,
        addressId: addressId,
        comment: comment,
        workSpaceId: workSpaceId,
        jobPositionId: usersMap[userId].jobPositionIds[0],
        logS: getPunchingLog(deviceType, userId, timestamp),
      })
    );
  };

export const stopPunching = (_clocking: ITimeClocking) => async (dispatch: DispFn, getState: () => AppState) => {
  const deviceType = getState().ui.timeClockMode.activeSince ? DeviceType.Terminal : DeviceType.Browser; // TodoMobile the devicetypes differ in mobile
  const user = selectUserMap(getState())[_clocking.userId];
  const timeClockSetting = generateUserTimeClockSettings(getState().data.timeClockSettings[0], user);
  const rosterSettings = generateUserRosterSettings(getState().data.rosterSettings[0], user);
  const isManager = selectSessionInfo(getState()).hasManagerPermissions();
  const now = toSimpleTime(moment());
  const clocking = capPunchingStartTime(_clocking, timeClockSetting);
  const timestamp = moment().unix().toString();
  const finishedClocking = {
    ...clocking,
    logE: getPunchingLog(deviceType, _clocking.userId, timestamp),
    isAccepted: timeClockSetting.clockingsAreAutoApprovedV2 || isManager,
    endTime: now,
    breakMinutes: calcBreakMinutes(clocking.breakActivities || [], now),
  };

  finishedClocking.breakMinutes = getBreakMinutesOfPunchingV2(finishedClocking, rosterSettings, timeClockSetting);

  notification.success({ message: lg.ausgestempelt });
  return dispatch(timeClockingRepository.update(finishedClocking));
};

export const acceptPunchingV2 = (timeClockingId: string) => {
  return async (dispatch: DispFn, getState: () => AppState) => {
    const timeClocking = selectTimeClockingMap(getState())[timeClockingId];
    return dispatch(timeClockingRepository.update({ ...timeClocking, isAccepted: true }));
  };
};
