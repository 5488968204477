import { Button, Icon, Checkbox } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../actions/modal";
import "./styles.scss";
import { busyInjector, BusyInjectorProps } from "../../../../../components/BusyInjector/BusyInjector";
import TZModal from "../../../../../components/TZModal/TZModal";
import { useSelector } from "../../../../../helpers/redux";
import _ from "lodash";
import { rosterSettingsRepository } from "../../../../../repositories/rosterSettingsRepository";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";

export const MonthPlanSettingsModal = busyInjector((props: {} & BusyInjectorProps) => {
  const dispatch = useDispatch<DispFn>();
  const rosterSettings = useSelector((s) => s.data.rosterSettings[0])!;
  const userDetails = useSelector((s) => s.data.userDetails);
  const [isLoading, setLoading] = useState(false);
  const [showQuotaInMonthlyRosterSlots, setQuotaInSlots] = useState(rosterSettings.showQuotaInMonthlyRosterSlots);
  const [showStaffNumberInRoster, setShowStaffNumber] = useState(rosterSettings.showStaffNumberInRoster);
  const [showUserTypeInRoster, setShowUserType] = useState(rosterSettings.showUserTypeInRoster);
  const [showLabelInMonthlyRosterShifts, setLabelInShifts] = useState(rosterSettings.showLabelInMonthlyRosterShifts);
  const [monthlyRosterCanChangeMonthStart, setCanChangeMonthStart] = useState(
    rosterSettings.monthlyRosterCanChangeMonthStart
  );
  const hasUsersWithStaffNumber = userDetails.some((u) => u.employNum);

  const saveClicked = async () => {
    setLoading(true);
    await dispatch(
      rosterSettingsRepository.update({
        ...rosterSettings,
        showQuotaInMonthlyRosterSlots,
        showLabelInMonthlyRosterShifts,
        showStaffNumberInRoster,
        showUserTypeInRoster,
        monthlyRosterCanChangeMonthStart,
      })
    );
    dispatch(closeModal());
  };

  return (
    <TZModal className="weekPlanSettingsModal">
      <TZModal.Head>{lg.monatsplan_ansicht}</TZModal.Head>
      <TZModal.Body>
        <div className="content ">
          <div className="row">
            <Checkbox
              checked={showLabelInMonthlyRosterShifts}
              onChange={() => setLabelInShifts(!showLabelInMonthlyRosterShifts)}
              children={lg.das_label_der_schicht_anzeigen}
            />
            <div
              className="infoIconWrapper"
              data-rh={lg.falls_eine_schicht_kein_label_hat_wird_die_schichtzeit_angezeigt}
            >
              <Icon type="info-circle" />
            </div>
          </div>
          <div className="row">
            <Checkbox
              checked={!showLabelInMonthlyRosterShifts}
              onChange={() => setLabelInShifts(!showLabelInMonthlyRosterShifts)}
              children={lg.start_endzeit_der_schicht_anzeigen}
            />
          </div>
          <div className="row withBorderTop">
            <Checkbox
              checked={showQuotaInMonthlyRosterSlots}
              onChange={() => setQuotaInSlots(!showQuotaInMonthlyRosterSlots)}
              children={lg.sollstunden_in_leeren_zellen_des_dienstplans_anzeigen}
            />
            <div
              className="infoIconWrapper"
              data-rh={lg.diese_werden_sichtbar_wenn_sich_die_maus_über_der_mitarbeiter_spalte_befindet}
            >
              <Icon type="info-circle" />
            </div>
          </div>
          <div className="row">
            <Checkbox
              checked={monthlyRosterCanChangeMonthStart}
              onChange={() => setCanChangeMonthStart(!monthlyRosterCanChangeMonthStart)}
              children={lg.intervall_des_monatsplans_kann_angepasst_werden_z_b_15_nov_14_dec}
            />
          </div>
          {hasUsersWithStaffNumber && (
            <div className="row">
              <Checkbox
                checked={showStaffNumberInRoster}
                onChange={() => setShowStaffNumber(!showStaffNumberInRoster)}
                children={lg.personalnummer_zum_mitarbeiternamen_im_dienstplan_anzeigen}
              />
            </div>
          )}
          <div className="row">
            <Checkbox
              checked={showUserTypeInRoster}
              onChange={() => setShowUserType(!showUserTypeInRoster)}
              children={lg.mitarbeiter_typ_im_dienstplan_anzeigen}
            />
          </div>
        </div>
      </TZModal.Body>
      <TZModal.Footer>
        <Button
          style={{ width: 120 }}
          id="cancel-sortorder-modal"
          onClick={saveClicked}
          type="primary"
          icon={isLoading ? "loading" : undefined}
          children={isLoading ? "" : lg.Speichern}
        />
      </TZModal.Footer>
    </TZModal>
  );
});
