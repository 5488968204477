import { IShift } from "./../../../shared/entities/IShift";
import { IUser, RoleType } from "./../../../shared/entities/IUser";
import { closestWithAttribute } from "../../../helpers/general";
import { IRosterSettings, IUserRosterSettings } from "../../../shared/entities/IRosterSettings";
import { TierInfo } from "../../../selectors/TierInfoSelector";
import { UserInfo } from "../../../shared/helpers/UserInfo";
import { IJobPosition } from "../../../shared/entities/IJobPosition";
import memoize from "micro-memoize";
import { MicroMemoize } from "micro-memoize";
import moment from "moment";
import { SDateFormat } from "../../../shared/helpers/SimpleTime";

export const canUserEditOwnShiftsOfDate = (rosterSetting: IUserRosterSettings, date: string) => {
  const { usersCanAddEditOwnShifts } = rosterSetting;
  if (usersCanAddEditOwnShifts) {
    const minDay = moment().add(usersCanAddEditOwnShifts.min, "d").format(SDateFormat);
    const maxDay = moment().add(usersCanAddEditOwnShifts.max, "d").format(SDateFormat);
    return date >= minDay && date <= maxDay;
  } else {
    return false;
  }
};

export const getClickedShiftKey = (e: any): string | undefined | null => {
  const shiftNode = closestWithAttribute(e.target, "data-type", "grid-shift");
  return shiftNode?.getAttribute("data-shift-id");
};

type SlotKey = {
  date: string;
  userId: string | undefined;
  jobPositionId: string | undefined;
  isRequirement: boolean;
  isDisabled: boolean;
};

export const getClickedSlotID = (e: any): SlotKey | undefined => {
  const slotNode = closestWithAttribute(e.target, "data-type", "grid-slot");
  const id = slotNode?.getAttribute("data-slot-id");
  const isRequirement = !!slotNode?.getAttribute("data-is-requirement-slot");
  const isDisabled = slotNode?.getAttribute("data-is-disabled") === "true";

  if (id) {
    return {
      date: id.split("_")[0],
      userId: id.split("_")[1] || undefined,
      jobPositionId: id.split("_")[2] || undefined,
      isRequirement: !!isRequirement,
      isDisabled: !!isDisabled,
    };
  } else {
    return undefined;
  }
};

export const getShiftCoreBySlotKey = (slotKey: SlotKey): Partial<IShift> => ({
  date: slotKey.date,
  jobPositionId: slotKey.jobPositionId || undefined,
  userId: slotKey.userId || undefined,
});

export const getShiftsOfRow = (
  shifts: IShift[],
  currentBranchId: string | null,
  userId: string | undefined,
  jobPositionId: string | undefined,
  isRequirement: boolean | undefined,
  sessionUser: IUser
) =>
  shifts.filter(
    (s: IShift) =>
      (!userId ? !s.userId : s.userId === userId) &&
      (!userId || !jobPositionId || s.jobPositionId === jobPositionId) &&
      (userId || !!isRequirement === !!s.isRequirement) &&
      (currentBranchId
        ? s.branchId === currentBranchId
        : sessionUser.role === RoleType.admin || sessionUser.branchIds.includes(s.branchId))
  );

// Other Shifts on Same day Of Row
export const getOtherShifts = (
  shifts: IShift[],
  currentBranchId: string | null,
  userId: string | undefined,
  jobPositionId: string | undefined
) =>
  shifts.filter(
    (s: IShift) =>
      !!userId &&
      s.userId === userId &&
      (jobPositionId
        ? s.jobPositionId !== jobPositionId || s.branchId !== currentBranchId
        : s.branchId !== currentBranchId)
  );

export const isMouseOverGridUserCells = (e: any) => {
  const x = e.clientX - e.currentTarget.getBoundingClientRect().left;
  const USER_CELL_WIDTH = 200;
  return x <= USER_CELL_WIDTH;
};

export const shouldPresentCreditFunction = (
  sessionInfo: UserInfo,
  rosterSettings: IRosterSettings,
  tierInfo: TierInfo,
  jobPositions: IJobPosition[]
) =>
  (sessionInfo.hasManagerPermissions() && sessionInfo.user.email!.includes("apo")) ||
  (jobPositions.find((jp) => jp.name.includes("apo")) && // to only show for pharmacy users
    (tierInfo.isTrialMode || !tierInfo.isPro));
