import { createSelector } from "reselect";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import moment from "moment";
import { SDateFormat } from "../../../../../shared/helpers/SimpleTime";
import { selectAbsenceTypeMap } from "../../../../../selectors/absenceTypeMapSelector";
import { isAbsenceVisible } from "../../../../../selectors/absencesByUserSelector";
import { rangesOverlap } from "../../../../../shared/helpers/dateHelpers";

export const selectVisibleAbsencesInYear = createSelector(
  [(s) => s.data.absences, selectSessionInfo, (s) => s.ui.absences, selectAbsenceTypeMap],
  (absences, sessionInfo, absencesUi, absenceTypeMap) => {
    const mom = moment().year(absencesUi.selectedYear);
    const startOfYear = mom.startOf("year").format(SDateFormat);
    const endOfYear = mom.endOf("year").format(SDateFormat);
    const absencesInYear = absences.filter((a) => rangesOverlap(startOfYear, endOfYear, a.startDate, a.endDate));

    return absencesInYear.filter((a) => isAbsenceVisible(a, absenceTypeMap[a.typeId], sessionInfo.user));
  }
);
