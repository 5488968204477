import React from "react";

import { isAndroid, isIOS } from "mobile-device-detect";
import { Redirect } from "react-router";
import { sitemap } from "../../helpers/sitemap";

type Props = {};

export const MobileDownloadRedirector = (props: Props) => {
  if (!isAndroid && !isIOS) {
    return <Redirect to={"/"} />;
  }

  if (isAndroid) {
    window.location.href = "https://play.google.com/store/apps/details?id=com.aplano";
  } else if (isIOS) {
    window.location.href = "https://apps.apple.com/de/app/aplano/id1497688145";
  }

  return <Redirect to={"/"} />;
};
