import React from "react";
import "./styles.scss";

type Props = {
  planedTime: {
    startTime: string;
    endTime: string;
    breakMinutes: number;
  };
  requestedTime: {
    startTime?: string;
    endTime?: string;
    breakMinutes?: number;
  };
};

const PlanedAndRequestedTime = ({ planedTime, requestedTime }: Props) => {
  return (
    <div className="plannedAndRequestedTimeMain">
      <div className="timesCell">
        <div className="row">
          <div className="label">{lg.GEPLANT}</div>
          <div className="times" style={{ color: "#545454" }}>
            {`${planedTime.startTime} - ${planedTime.endTime}`}
            {" | " + planedTime.breakMinutes + " "}
          </div>
        </div>
        <div className="row requestRow">
          <div className="label">{lg.BEANTRAGT}</div>
          <div className="times" style={{ color: "#1a90ff" }}>
            {requestedTime.startTime ? (
              <div>
                {`${requestedTime.startTime} - ${requestedTime.endTime}`}
                {" | " + requestedTime.breakMinutes + " "}
              </div>
            ) : (
              <div>{lg.DIENSTFREI}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanedAndRequestedTime;
