import { createSelector } from "reselect";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { AbsenceTypeCode } from "../../../../../shared/entities/IAbsenceType";
import moment from "moment";
import { SDateFormat } from "../../../../../shared/helpers/SimpleTime";
import { selectAbsenceTypeMap } from "../../../../../selectors/absenceTypeMapSelector";
import { isAbsenceVisible } from "../../../../../selectors/absencesByUserSelector";
import { rangesOverlap } from "../../../../../shared/helpers/dateHelpers";

export const selectVisibleAbsencesInMonth = createSelector(
  [(s) => s.data.absences, selectSessionInfo, (s) => s.ui.absences, selectAbsenceTypeMap],
  (absences, sessionInfo, absencesUi, absenceTypeMap) => {
    const mom = moment().year(absencesUi.selectedYear).month(absencesUi.selectedMonth);
    const startOfMonth = mom.startOf("month").format(SDateFormat);
    const endOfMonth = mom.endOf("month").format(SDateFormat);

    const absencesInMonth = absences.filter((a) => rangesOverlap(startOfMonth, endOfMonth, a.startDate, a.endDate));
    return absencesInMonth.filter((a) => isAbsenceVisible(a, absenceTypeMap[a.typeId], sessionInfo.user));
  }
);
