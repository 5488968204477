import React from "react";
import cn from "classnames";
import { useDrop } from "react-dnd";
import { IShift } from "../../../../../shared/entities/IShift";
import { IAvailability } from "../../../../../shared/entities/IAvailability";
import { Moment } from "moment";
import { IUser } from "../../../../../shared/entities/IUser";
import {
  filterAvailabilitiesForDate,
  generateAvailabilityText,
  getUnavailableDuringShift,
} from "../../../../../helpers/workingTimeHelpers";
import { getotherShiftsOnSameDayTooltip } from "../../../../../helpers/general";
import { IJobPosition } from "../../../../../shared/entities/IJobPosition";

import { DispFn } from "../../../../../frontend-core/types/thunkTypes";
import { AvIcon } from "../../../../../components/AvIcon/AvIcon";
import { AbsenceTypeCode, IAbsenceType } from "../../../../../shared/entities/IAbsenceType";
import { toSimpleDate, tTimetoMinutes, minutesToDuration } from "../../../../../shared/helpers/timeHelpers";
import { orderBy } from "lodash";
import { Map } from "../../../../../shared/types/general";
import { ShiftDropArea } from "../../../../../components/ShiftDropArea/ShiftDropArea";
import { cloneShift, moveShift } from "../../../../../actions/shift";
import { ITracking } from "../../../../../shared/entities/ITracking";
import { IShiftHandOverRequest } from "../../../../../shared/entities/IShiftHandOverRequest";
import { DraggableMonthShift } from "./DraggableMonthShift";
import { useSelector } from "../../../../../helpers/redux";
import { notification } from "antd";
import { shfitDragActionTypes } from "../../../../../reducers/ui/shifts/roster/draggingShift";
import { ITimeClocking } from "../../../../../shared/entities/ITimeClocking";
import { IContract, WorkInterval, WeekDays } from "../../../../../shared/entities/IContract";
import { IWorkSpace } from "../../../../../shared/entities/IWorkSpace";
import { IBranch } from "../../../../../shared/entities/IBranch";
import { IAbsence } from "../../../../../shared/entities/IAbsence";
import { UserInfo } from "../../../../../shared/helpers/UserInfo";
import { IShiftAddress } from "../../../../../shared/entities/IShiftAddress";
import { AbsenceIcon } from "../../../../../components/AbsenceIcon/AbsenceIcon";
import { IShiftOverlap } from "../../../../../shared/entities/IShiftOverlap";
import { setOpenShiftsCollapsed } from "../../../../../reducers/ui/shifts/roster/isOpenShiftsCollapsed";
import _ from "lodash";
import { setRequiredShiftsCollapsed } from "../../../../../reducers/ui/shifts/roster/isRequiredShiftsCollapsed";

const getSlotKey = (date: string, userId?: string, jobPositionId?: string) => {
  return [date, userId, jobPositionId].filter((s) => !!s).join("_");
};

type Props = {
  shiftsOfDay: IShift[];
  absence?: IAbsence;
  absenceTypeMap: Map<IAbsenceType>;
  otherShiftsOfDay: IShift[];
  width: string;
  isHoliday: boolean;
  date: Moment;
  simpleDate: string;
  user?: IUser;
  jobPositionId?: string;
  branchMap: Map<IBranch>;
  jobPosMap: Map<IJobPosition>;
  workSpaceMap: Map<IWorkSpace>;
  addressMap: Map<IShiftAddress>;
  selectedBranchId: string | null;
  dispatch: DispFn;
  overlapMap?: { [userId: string]: IShiftOverlap };
  printMode: boolean;
  recentModifiedShifts: string[];
  showQuota?: boolean;
  availabilitiesByUser: Map<IAvailability[]>;
  trackingMap: Map<ITracking>;
  handOverRequestMap: Map<IShiftHandOverRequest>;
  timeClockingMap: Map<ITimeClocking>;
  contract?: IContract;
  showLabelOfShift?: boolean;
  sessionInfo: UserInfo;
  showOpenShiftsCount?: boolean;
  showRequirementCount?: boolean;
  labourLawWarningMap?: Map<string>;
  isRequirement?: boolean;
  assignedShiftsMap: Map<number>;
  canEditOwnShifts?: boolean;
  isV2?: boolean;
  isInFuture?: boolean;
  isToday?: boolean;
};

export const RosterMonthGridSlot = React.memo((props: Props) => {
  const {
    shiftsOfDay,
    absence,
    otherShiftsOfDay,
    availabilitiesByUser,
    width,
    isHoliday,
    date,
    user,
    simpleDate,
    jobPositionId,
    jobPosMap,
    selectedBranchId,
    overlapMap,
    printMode,
    recentModifiedShifts,
    trackingMap,
    handOverRequestMap,
    timeClockingMap,
    workSpaceMap,
    addressMap,
    labourLawWarningMap,
    showRequirementCount,
    isV2,
  } = props;
  const draggingShift = useSelector((s) => s.ui.shifts.roster.draggingShift);

  const [{ isOver: isOverMoveToDropArea }, moveToDropRef] = useDrop({
    accept: "SHIFT",
    canDrop: (shift: IShift) => {
      const hasClocking = !!timeClockingMap[shift.id];
      const hasTracking = !!trackingMap[shift.id];
      if (hasClocking || hasTracking) {
        notification.error({
          message: lg.eine_schicht_mit_einer_zeiterfassung_kann_nicht_verschoben_werden,
        });
      }
      return !hasClocking && !hasTracking;
    },

    drop: (shift: IShift) => {
      props.dispatch({ type: shfitDragActionTypes.END });
      props.dispatch(moveShift(shift.id, toSimpleDate(date), user?.id, jobPositionId, props.isRequirement));
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const [{ isOver: isOverCloneDropArea }, cloneDropRef] = useDrop({
    accept: "SHIFT",
    drop: (item: IShift) => {
      return props.dispatch(cloneShift(item.id, toSimpleDate(date), user?.id, jobPositionId, props.isRequirement));
    },
    collect: (monitor) => {
      return {
        isOver: !!monitor.isOver(),
      };
    },
  });

  const [{ isOverSlot }, slotRef] = useDrop({
    accept: "SHIFT",
    collect: (monitor) => ({
      isOverSlot: !!monitor.isOver(),
    }),
  });

  const userAvailabilities =
    user?.id && props.availabilitiesByUser[user.id]
      ? filterAvailabilitiesForDate(props.availabilitiesByUser[user.id], simpleDate)
      : [];

  const unavailabilities = userAvailabilities.filter((a) => !a.isAvailable);
  const availabilities = userAvailabilities.filter((a) => a.isAvailable);
  const weekDay = date.isoWeekday() - 1;
  const isWeekend = weekDay >= 6;

  const isSameSlot =
    (!user || draggingShift?.userId === user?.id) &&
    (!user || draggingShift?.jobPositionId === props.jobPositionId) &&
    draggingShift?.date === props.simpleDate &&
    !!draggingShift?.isRequirement === !!props.isRequirement;

  const dailyQuota = props.contract?.dailyQuota[WeekDays[weekDay]];
  const hasWeeklyContract = props.contract?.interval === WorkInterval.weekly;

  const canManage = props.sessionInfo.hasManagerPermissions();
  const sessionUserId = props.sessionInfo.user.id;
  const isSessionUserSlot = sessionUserId === props.user?.id;
  const canManageOrSee = canManage || isSessionUserSlot;

  // when dragging:
  const isOpeningShift = draggingShift && !user && draggingShift.userId && !draggingShift.isRequirement;
  const isAssigningOpenShift = draggingShift && !!user && !draggingShift?.userId && !draggingShift.isRequirement;
  const draggingFromToOpenShift = !props.isRequirement && (!!isOpeningShift || !!isAssigningOpenShift);
  const canDragMove = (isSameSlot || draggingFromToOpenShift) && !draggingShift?.isTemplate;

  const openShiftsCount =
    props.showOpenShiftsCount && shiftsOfDay.length && _.sumBy(shiftsOfDay, (s) => s.requiredUsersAmount || 0);

  const requiredShiftsCount =
    props.showRequirementCount && shiftsOfDay.length && _.sumBy(shiftsOfDay, (s) => s.requiredUsersAmount || 0);

  const assignedShiftsCount =
    props.showRequirementCount &&
    shiftsOfDay.length &&
    _.sumBy(shiftsOfDay, (s) => props.assignedShiftsMap?.[s.id] || 0);

  const clickable = isV2
    ? !props.isInFuture && (canManage || props.canEditOwnShifts)
    : canManage || props.canEditOwnShifts;

  return (
    <div
      key={simpleDate}
      ref={slotRef}
      style={{ width }}
      data-type="grid-slot"
      data-is-disabled={!clickable}
      data-slot-id={getSlotKey(simpleDate, user?.id, jobPositionId)}
      data-is-requirement-slot={props.isRequirement || null}
      className={cn({
        slotMain: true,
        hasAbsence: !!absence,
        cell: true,
        isOpenShiftSlot: !user,
        isRequirementSlot: props.isRequirement,
        isUserShiftSlot: !!user,
        hasShift: !!shiftsOfDay.length,
        clickable: clickable,
        isHoliday,
        isWeekend,
        isOverSlot,
        isToday: props.isToday,
        showOpenShiftsCount: (props.showOpenShiftsCount || props.showRequirementCount) && !!props.shiftsOfDay.length,
      })}
    >
      {!isV2 && (
        <ShiftDropArea
          hideMove={!canDragMove}
          hideCopy={canDragMove}
          isOverCloneDropArea={isOverCloneDropArea}
          cloneDropRef={cloneDropRef}
          isOverMoveToDropArea={isOverMoveToDropArea}
          moveToDropRef={moveToDropRef}
        />
      )}
      {!isV2 && canManage && props.showQuota && props.contract && (!!hasWeeklyContract || !dailyQuota) && (
        <div className={cn({ quota: true, freeDay: !dailyQuota })}>
          {dailyQuota ? (hasWeeklyContract ? minutesToDuration(dailyQuota) : lg.work) : lg.frei}
        </div>
      )}
      {!!shiftsOfDay.length && !user && (canManage || props.canEditOwnShifts) && (
        <div className="addShiftButtonMonthView">+</div>
      )}
      {!shiftsOfDay.length && !isV2 && (!!userAvailabilities.length || !!otherShiftsOfDay.length) && canManageOrSee && (
        <div className="indicatorWrapper availabilityIndicators">
          {!!otherShiftsOfDay.length && !absence && (
            <div
              className="indicator"
              style={{ backgroundColor: "#0083ff" }}
              data-rh={getotherShiftsOnSameDayTooltip(otherShiftsOfDay, selectedBranchId, props.branchMap, jobPosMap)}
            />
          )}
          {!!availabilities.length && (
            <div
              className="indicator"
              style={{ backgroundColor: "#18c756" }}
              data-rh={generateAvailabilityText(availabilities)}
            />
          )}
          {!!unavailabilities.length && (
            <div
              className="indicator"
              style={{ backgroundColor: "#f5222d" }}
              data-rh={generateAvailabilityText(unavailabilities)}
            />
          )}
        </div>
      )}
      {absence && (
        <div className="absence fb aCenter jCenter">
          <AbsenceIcon
            typeCode={absence.typeCode}
            color={props.absenceTypeMap[absence.typeId].color}
            style={{ fontSize: "28px" }}
          />
        </div>
      )}
      {!!shiftsOfDay.length && !props.showOpenShiftsCount && !props.showRequirementCount && (
        <div className="shiftsWrapper">
          {orderBy(shiftsOfDay, [
            (s) => tTimetoMinutes(s.startTime),
            "duration",
            "breakMinutes",
            "jobPositionId",
            "workSpaceId",
            "id",
          ]).map((shift) => {
            const userUnavailable =
              shift.userId && availabilitiesByUser[shift.userId]
                ? !!getUnavailableDuringShift(shift, availabilitiesByUser[shift.userId])
                : false;

            return (
              <DraggableMonthShift
                key={shift.id}
                user={user}
                shift={shift}
                printMode={printMode}
                recentModifiedShifts={recentModifiedShifts}
                handOverRequestMap={handOverRequestMap}
                simpleDate={simpleDate}
                jobPositionId={shift.jobPositionId}
                absence={absence}
                trackingMap={trackingMap}
                overlap={overlapMap && overlapMap[shift.id]}
                userUnavailable={userUnavailable}
                jobPosMap={jobPosMap}
                workSpaceMap={workSpaceMap}
                addressMap={addressMap}
                showLabel={props.showLabelOfShift}
                canManage={canManage}
                labourLawWarning={labourLawWarningMap?.[shift.id]}
                assignedUsersAmount={props.assignedShiftsMap[shift.id] || 0}
                isV2={isV2}
              />
            );
          })}
        </div>
      )}
      {props.showOpenShiftsCount && !!openShiftsCount && (
        <div
          className="shiftCountDisplay"
          data-rh={openShiftsCount + " " + lg.offene + " " + (openShiftsCount === 1 ? lg.schicht : lg.schichten)}
          onClick={() => props.dispatch(setOpenShiftsCollapsed(false))}
        >
          {openShiftsCount}
        </div>
      )}
      {props.showRequirementCount && !!shiftsOfDay.length && (
        <div
          style={{ fontSize: 12 }}
          className={cn({
            shiftCountDisplay: true,
            isRequirement: true,
            isRequirementComplete: (assignedShiftsCount || 0) >= (requiredShiftsCount || 0),
          })}
          onClick={() => props.dispatch(setRequiredShiftsCollapsed(false))}
        >
          {assignedShiftsCount + " / " + requiredShiftsCount}
        </div>
      )}
    </div>
  );
});
