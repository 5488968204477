import { useEffect, useState } from "react";
import { useSelector } from "../../helpers/redux";
import { environment } from "../../env";
import Cookies from "js-cookie";
import { Analytics } from "../../helpers/analytics";
import { getUrlParam } from "../../frontend-core/helpers/frontendHelpers";

export const GoogleTagManagerInjector = () => {
  const isLoggedIn = useSelector((s) => !!s.data.auth?.session);
  const tenantInfo = useSelector((s) => s.data.tenantInfo);

  const inject = () => {
    if (!environment.isProd()) {
      return;
    }

    // if (isLoggedIn && tenantInfo?.stripeCustomerId) {
    //   Analytics.initializeProfitWell(tenantInfo);
    // }

    if (getUrlParam("consent") === "true" || isLoggedIn) {
      Cookies.set("tracking_consent_given", "true", { expires: 356 });

      Analytics.initialize();
    }
  };

  useEffect(() => {
    inject();
  }, [isLoggedIn, tenantInfo]);

  useEffect(() => {
    inject();
  }, []);

  return null;
};
