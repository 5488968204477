import { getDuration, makeSimpleDateTime } from "./timeHelpers";
import { addSimpleDays } from "./dateHelpers";
import { ITimeClocking } from "./../entities/ITimeClocking";
import { IBranch } from "./../entities/IBranch";
import { IUserTimeClockSettings } from "./../entities/ITimeClockSettings";
import { IShift } from "../entities/IShift";
import { BreakRule, IUserRosterSettings } from "../entities/IRosterSettings";
import _ from "lodash";

export const shiftTimesDiffer = (shiftOne: IShift, shiftTwo: IShift): boolean => {
  return (
    shiftOne.startTime !== shiftTwo.startTime ||
    shiftOne.endTime !== shiftTwo.endTime ||
    shiftOne.breakMinutes !== shiftTwo.breakMinutes
  );
};

export const getShiftStartDateTime = (shift: IShift): string => {
  return makeSimpleDateTime(shift.date, shift.startTime);
};

export const getShiftEndDateTime = (shift: IShift): string => {
  return shift.startTime > shift.endTime
    ? makeSimpleDateTime(addSimpleDays(shift.date, 1), shift.endTime)
    : makeSimpleDateTime(shift.date, shift.endTime);
};

export const getMatchingBreakRule = (
  startTime: string,
  endTime: string,
  breakRules: BreakRule[]
): BreakRule | undefined => {
  if (!breakRules.length) {
    return undefined;
  }

  const durationMinutes = getDuration({ startTime, endTime });
  const sortedBeakRules = _.orderBy(breakRules, (b) => b.workingHours, "desc");
  return sortedBeakRules.find((rule) => {
    const ruleDuration = (rule.workingHours || 0) * 60 + (rule.workingMinutes || 0);
    return durationMinutes >= ruleDuration;
  });
};

export const getApplicableBreakRuleMinutes = (
  userRosterSetting: IUserRosterSettings,
  times: { startTime: string; endTime: string }
): number => {
  const breakRules = userRosterSetting.breakRules || [];
  const validRule = getMatchingBreakRule(times.startTime, times.endTime, breakRules);
  return validRule?.breakMinutes || 0;
};
