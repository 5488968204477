import React, { useEffect, useRef, useCallback, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { useSelector } from "../../helpers/redux";
import { simpleDateTimeToMoment, getEndDateTime, toSimpleDate } from "../../shared/helpers/timeHelpers";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import { shiftRepository } from "../../repositories/shiftRepository";
import { trackingRepository } from "../../repositories/TrackingRepository";
import { timeClockingRepository } from "../../repositories/timeClockingRepository";
import { IShift } from "../../shared/entities/IShift";
import { selectActiveUsers } from "../../selectors/ActiveUserSelectors";
import { AbsenceStatus } from "../../shared/entities/IAbsence";
import { Icon, Modal, notification } from "antd";
import { selectBranchMap, selectTrackingMap } from "../../selectors/mapSelectors";
import { getUserName } from "../../shared/helpers/userHelpers";
import { generateUserTimeClockSettings } from "../../shared/helpers/settingsHelpers";
import { ITimeClocking } from "../../shared/entities/ITimeClocking";
import { getRelevantShiftsWithoutTrackingInBranch } from "../../shared/helpers/timeClockingHelpers";
import { selectTimeClockSettingsByUser } from "../../selectors/timeClockSettingsByUserSelector";
import { SDateFormat } from "../../shared/helpers/SimpleTime";
import { envi } from "../../env";

type Props = {
  setSelectedUserIdForPinPad: (id: string | undefined) => any;
};

let SelectableUsersListTimeoutRef: any;

/**
 * THIS COMPONENT CONTAINS SOME COPIED CODE FROM THE
 * MOBILE REPO --- WHEN CHANGING SOMETHING HERE,
 * PLEASE ALSO CHANGE IT IN THE MOBILE REPO
 */
export const SelectableClockingUsersList = React.memo((props: Props) => {
  // required to emulate a force update (@see: https://stackoverflow.com/questions/53215285/how-can-i-force-component-to-re-render-with-hooks-in-react)
  const [, updateState] = useState<Object>();
  const forceUpdate = useCallback(() => updateState({}), []);

  const timeClockSettingsByUser = useSelector(selectTimeClockSettingsByUser);
  const dispatch = useDispatch<DispFn>();

  const timeClockBranchIds = useSelector((s) => s.ui.timeClockMode.branchIds)!;
  const isV2 = useSelector((s) => s.data.tenantInfo.isV2);
  const users = useSelector((s) => s.data.users);
  const absences = useSelector((s) => s.data.absences);
  const today = toSimpleDate(moment());
  const activeUsersAtSelectedbranch = useSelector((s) =>
    selectActiveUsers(s, today).filter(
      (u) =>
        u.branchIds.find((b) => timeClockBranchIds.includes(b)) &&
        !absences.find(
          (a) =>
            a.status === AbsenceStatus.active &&
            a.startDate <= today &&
            a.endDate >= today &&
            a.userId === u.id &&
            !((a.startsHalfDay && today === a.startDate) || (a.endsHalfDay && today === a.endDate))
        )
    )
  );
  const shifts = useSelector((s) => s.data.shifts);
  const trackingMap = useSelector(selectTrackingMap);
  const timeClockings = useSelector((s) => s.data.timeClockings);

  let clockableShiftsOrClockings: Array<IShift | ITimeClocking> = isV2
    ? []
    : getRelevantShiftsWithoutTrackingInBranch(undefined, shifts, trackingMap, timeClockBranchIds);

  const yesterday = moment().add(-1, "days").format(SDateFormat);
  const activeTimeClockings = timeClockings.filter((t) => !t.endTime && !t.isAutoClockOut && t.date >= yesterday);

  clockableShiftsOrClockings = _.uniqBy(
    [...clockableShiftsOrClockings, ...activeTimeClockings].filter((c) => !!c) as IShift[],
    (s) => s.id
  );

  const usersWithClockableShift = _.sortBy(
    _.uniqBy(
      clockableShiftsOrClockings.map((c) => users.find((u) => u.id === c.userId)!),
      (u) => u.id
    ),
    ["name"]
  );

  const fetchRelevantShiftsAndClockings = async () => {
    // fetch relevant shifts to select
    const branchIdDateFilter = {
      filter: [
        "date",
        "between",
        [`${toSimpleDate(moment().add(-3, "days"))}`, `${toSimpleDate(moment().add(3, "days"))}`],
      ],
    } as any;

    // await dispatch(fetchAbsencesOfDay(today));
    await dispatch(shiftRepository.addListener({ ...branchIdDateFilter, key: "shiftsAround3Days" }));
    await dispatch(trackingRepository.addListener({ ...branchIdDateFilter, key: "trackingsAround3Days" }));

    return await dispatch(timeClockingRepository.addListener(branchIdDateFilter));
  };

  useEffect(() => {
    !isV2 && fetchRelevantShiftsAndClockings();
    !isV2 && updateTimerLoop();
  }, []);

  const mounted = useRef(false);

  // every 10 seconds we re-render this component, to display all relevant users who have a shift
  const updateTimerLoop = () => {
    setTimeout(() => {
      if (mounted.current) {
        forceUpdate();
        updateTimerLoop();
      }
    }, 10 * 1000); // every 10 seconds
  };

  const usersUnique = _.uniqBy([...activeUsersAtSelectedbranch], (u) => u.id);

  const sortedClockableUsers = _.orderBy(
    usersUnique,
    [(u) => (!!usersWithClockableShift.find((uC) => u.id === uC.id) ? "a" : "b"), (u) => getUserName(u).toLowerCase()],
    ["asc", "asc"]
  );

  return (
    <div className="selectableClockingUsersListContainer">
      {sortedClockableUsers.map((u) => {
        const hasShift = usersWithClockableShift.find((uC) => uC.id === u.id);
        const timeClockSettings = timeClockSettingsByUser[u.id];
        const canBeSelected = !!(hasShift || timeClockSettings.isFreeClockingAllowed || isV2);
        const highlight = isV2 || hasShift;

        return (
          <div
            key={u.id}
            onClick={() => {
              if (canBeSelected) {
                props.setSelectedUserIdForPinPad(u.id);
              } else {
                Modal.warning({
                  title: lg.keine_schicht_zum_einstempeln,
                  content: (
                    <div>
                      {lg.es_gibt_derzeit_keine_schicht_zum_einstempeln_für_dich_das_kann_folgende_gründe_haben}
                      <ul>
                        <li>{lg.es_existiert_eventuell_bereits_eine_bestätigte_zeiterfassung_für_deine_schicht}</li>
                        <li>{lg.deine_schicht_befindet_sich_eventuell_an_einem_anderen_standort}</li>
                      </ul>
                    </div>
                  ),
                });
              }
            }}
            style={{
              borderRadius: 100,
              backgroundColor: envi.isV2() ? "#1e1d1d" : highlight ? "#ffffff1f" : "#1a90ff",
              border: "solid 1px",
              opacity: highlight ? 1 : 0.7,
              borderColor: envi.isV2() ? "rgba(255,255,255,0.9)" : "rgba(255,255,255,0.6)",
              marginBottom: 10,
              padding: 12,
              paddingLeft: 20,
              paddingBottom: 12,
              minWidth: "100%",
              cursor: "pointer",
            }}
            className="selectableUserItem"
          >
            <div
              style={{
                opacity: 0.82,
                fontSize: 18,
                color: envi.isV2() ? "white" : "white",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div>{getUserName(u)}</div>
              <Icon type="right" style={{ marginLeft: "auto", marginRight: 2, fontSize: 18 }} className="arrowIcon" />
            </div>
          </div>
        );
      })}
    </div>
  );
});
