
import { BaseRepository } from '../frontend-core/BaseRepository';
import { INotificationSettings } from '../shared/entities/INotificationSettings';

class NotificationSettingsRepository extends BaseRepository<INotificationSettings> {
  constructor() {
    super('notificationSettings');
  }
}

export const notificationSettingsRepository = new NotificationSettingsRepository();
