import _ from "lodash";
import moment from "moment";
import { createSelector } from "reselect";
import { SDateFormat } from "../shared/helpers/SimpleTime";
import { AppState } from "../types/AppState";
import { tierInfoSelector } from "./TierInfoSelector";

/**
 * CHANGES NEED TO BE COPIED TO THE MOBILE REPO AS WELL!
 */

export type Features = {
  shiftComments?: boolean;
  shiftLabels?: boolean;
  shiftDragAndDrop?: boolean;
  absences?: boolean;
  availabilities?: boolean;
  trackings?: boolean;
  rosterTemplates?: boolean;
  dailyNotes?: boolean;
  openShifts?: boolean;
  shiftApplications?: boolean;
  dataExports?: boolean;
  timeClock?: boolean;
  credits?: boolean;
  activityProtocol?: boolean;
  betaFeatures?: boolean;
  shfitReports?: boolean;
  repeatingShifts?: boolean;
  shiftAddresses?: boolean;
  groupChats?: boolean;
  fileUploads?: boolean;
  announcements?: boolean;
  rosterMonthlyView?: boolean;
  surcharges?: boolean;
  multiBranches?: boolean;
  multiAdmins?: boolean;
  absenceRequests?: boolean;
  absenceBlocks?: boolean;
};

const noFeatures: Features = Object.freeze({});

const featuresByDate: { [date: string]: { [Tier: string]: Features } } = {
  ["2019-01-01"]: {
    Basic: {
      shiftComments: true,
      shiftDragAndDrop: true,
      absences: true,
      availabilities: true,
      trackings: true,
      rosterTemplates: true,
      shfitReports: true,
      shiftLabels: true,
      repeatingShifts: true,
      rosterMonthlyView: true,
    },
    Pro: {
      dailyNotes: true,
      openShifts: true,
      shiftApplications: true,
      dataExports: true,
      timeClock: true,
      credits: true,
      activityProtocol: true,
      betaFeatures: true,
      shiftAddresses: true,
      groupChats: true,
      fileUploads: true,
      announcements: true,
      rosterMonthlyView: true,
    },
  },
  ["2020-12-30"]: {
    Basic: {
      shiftComments: true,
      shiftDragAndDrop: true,
      absences: true,
      availabilities: true,
      trackings: true,
      rosterTemplates: true,
      repeatingShifts: true,
      rosterMonthlyView: true,
    },
    Pro: {
      dailyNotes: true,
      openShifts: true,
      shiftApplications: true,
      dataExports: true,
      timeClock: true,
      credits: true,
      activityProtocol: true,
      betaFeatures: true,
      shfitReports: true,
      shiftLabels: true,
      shiftAddresses: true,
      groupChats: true,
      fileUploads: true,
      announcements: true,
      rosterMonthlyView: true,
    },
  },
  ["2021-02-02"]: {
    Basic: {
      shiftDragAndDrop: true,
      absences: true,
      availabilities: true,
      rosterTemplates: true,
      repeatingShifts: true,
      rosterMonthlyView: true,
    },
    Pro: {
      shiftComments: true,
      dailyNotes: true,
      openShifts: true,
      shiftApplications: true,
      dataExports: true,
      timeClock: true,
      credits: true,
      activityProtocol: true,
      betaFeatures: true,
      shfitReports: true,
      shiftLabels: true,
      shiftAddresses: true,
      trackings: true,
      groupChats: true, // CHAT HAS SPECIAL INLINE CONFIGURATION! BY COMPARING IS BASIC / IS PRO
      fileUploads: true,
      announcements: true,
    },
  },
  ["2022-04-05"]: {
    Basic: {
      absences: true,
      rosterTemplates: true,
      repeatingShifts: true,
      rosterMonthlyView: true,
      announcements: true,
      activityProtocol: true,
    },
    Pro: {
      shiftComments: true,
      dailyNotes: true,
      openShifts: true,
      shiftApplications: true,
      dataExports: true,
      timeClock: true,
      credits: true,

      betaFeatures: true,
      shfitReports: true,
      shiftLabels: true,
      shiftAddresses: true,
      trackings: true,
      groupChats: true, // CHAT HAS SPECIAL INLINE CONFIGURATION! BY COMPARING IS BASIC / IS PRO
      fileUploads: true,
      //
      availabilities: true,
      surcharges: true,
      multiBranches: true,
      multiAdmins: true,
      absenceRequests: true,
      absenceBlocks: true,
    },
  },
};

/**
 * CHANGES NEED TO BE COPIED TO THE MOBILE REPO AS WELL!
 */

export const featuresSelector = createSelector(
  [tierInfoSelector, (s: AppState) => s.data.tenantInfo],
  (tierInfo, tenantInfo): Features => {
    if (tierInfo.unpaidSinceTwoWeeks) {
      return noFeatures;
    }

    const dates = _.orderBy(_.keys(featuresByDate), [(v) => v], ["asc"]);
    // We add 2 weeks since nobody upgrades in the first 2 weeks
    const creationDate = moment(tenantInfo.createdAt).add(2, "weeks").format(SDateFormat);
    let validDate = dates[0];
    dates.forEach((d, i) => {
      if (d > creationDate) {
        return;
      } else {
        if (i < dates.length - 1) {
          if (dates[i + 1] > creationDate) {
            validDate = d;
          }
        } else {
          validDate = d;
        }
      }
    });

    if (tierInfo.isPro || tierInfo.isAlwaysFreePremiumTenant || tierInfo.isTrialMode) {
      return {
        ...featuresByDate[validDate].Basic,
        ...featuresByDate[validDate].Pro,
      };
    } else if (tierInfo.isBasic) {
      return featuresByDate[validDate].Basic;
    } else {
      return noFeatures;
    }
  }
);
