import { AppState } from "../types/AppState";
import { createSelector } from "reselect";
import moment from "moment";

export const selectIsFreshTenant = createSelector(
  [(s: AppState) => s.data.tenantInfo, (s) => s.data.users],
  (tenantInfo, users) => {
    const creationMoment = moment(tenantInfo.createdAt);

    return (
      (users.length === 1 && moment().isBefore(creationMoment.clone().add(14, "days"))) ||
      (users.length > 1 && moment().isBefore(creationMoment.clone().add(4, "days")))
    );
  }
);
