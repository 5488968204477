import React from "react";
import { connect } from "react-redux";
import { NavLinkProps, NavLink } from "react-router-dom";
import "./styles.scss";

import { AppState } from "../../types/AppState";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import { ISitemapEntry } from "../../helpers/sitemap";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { IUser, RoleType } from "../../shared/entities/IUser";

const mapStateToProps = (state: AppState) => ({
  sessionInfo: selectSessionInfo(state),
  creditCorrection: state.data.creditCorrections,
  state: state,
});

type OwnProps = { links: ISitemapEntry[] };
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class SubBarComponent extends React.PureComponent<Props> {
  render() {
    const { sessionInfo } = this.props;

    return (
      <div className="subBarMain">
        <div className="wrapper subBarMainWrapper">
          <div className="container">
            {this.props.links.map((link) => {
              return link.hasRequiredRights(sessionInfo.user) ? (
                <NavLink to={link.url} key={link.url as string} className="linkSub">
                  {link.title}
                </NavLink>
              ) : null;
            })}
          </div>
        </div>
      </div>
    );
  }
}

export const SubBar = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(SubBarComponent);
