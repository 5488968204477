import React from "react";
import { connect, batch } from "react-redux";
import _ from "lodash";
import { Formik, FormikProps, Form } from "formik";
import { Button } from "antd";
import { AppState } from "../../types/AppState";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import TZModal from "../TZModal/TZModal";
import AvFormikInput from "../AvFormikInput/AvFormikInput";
import { rosterTemplateRepository } from "../../repositories/rosterTemplateRepository";
import { closeModal } from "../../actions/modal";

import { shiftRepository } from "../../repositories/shiftRepository";
import { setRosterTemplateMode } from "../../actions/rosterTemplate";

import { IShift } from "../../shared/entities/IShift";
import { Raw } from "../../shared/entities/IResource";
import { generateWeekdays, simpleDateToMoment, toSimpleDate, toMomentUnsafe } from "../../shared/helpers/timeHelpers";
import { IRosterTemplate } from "../../shared/entities/IRosterTemplate";
import { addSimpleDays } from "../../shared/helpers/dateHelpers";
import { v4 as uuid } from "uuid";
import { setRosterType, RosterType } from "../../reducers/ui/shifts/roster/rosterType";
import { getRosterFilteredShifts } from "../../selectors/RelevantShiftsSelector";

const mapStateToProps = (state: AppState) => {
  return {
    sessionInfo: selectSessionInfo(state),
    branches: state.data.branches,
    shifts: state.data.shifts,
    selectedWeek: state.ui.shifts.roster.selectedWeek,
    rosterTemplates: state.data.rosterTemplates,
    selectedBranch: state.ui.selectedBranch,
    rosterFilter: state.ui.shifts.roster.rosterFilter,
  };
};

type State = {};
type OwnProps = {
  basedOnDate?: string; // if its undefined -> we are creating a new empty template
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

export type RosterTemplateFormValue = {
  name: string;
};

class _RosterTemplatePopup extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    // for safety reasons we refetch, because its important for calcuations to
    // be up to date regarding the templates at this point.
    const res = await this.props.dispatch(rosterTemplateRepository.fetchMany());
  }

  renderForm = (formikProps: FormikProps<RosterTemplateFormValue>) => {
    return (
      <Form className="">
        <div style={{ padding: 30 }}>
          <AvFormikInput autoFocus label={lg.name} fieldName="name" type="string" formikProps={formikProps} />
        </div>
        <TZModal.Footer>
          <div className="row jEnd aCenter fb" style={{ marginLeft: "auto" }}>
            <Button
              id="save-roster-template-name"
              type="primary"
              onClick={formikProps.submitForm}
              loading={formikProps.isSubmitting}
              disabled={!formikProps.values.name}
              children={lg.Speichern}
            />
          </div>
        </TZModal.Footer>
      </Form>
    );
  };

  generateTemplateShifts(shifts: IShift[], basedOnDate: string, branchId: string, weekDateToStoreShifts: string) {
    const totalShifts: Raw<IShift>[] = [];
    const weekdays = generateWeekdays(basedOnDate);

    weekdays.forEach((weekday, weekdayIndex) => {
      const date = toSimpleDate(weekday);
      const shiftsOfDay = shifts.filter((s) => s.date === date && s.branchId === branchId);
      // apply rosterFilters if there are some filters active
      const shiftsOfDayFiltered = getRosterFilteredShifts(shiftsOfDay, this.props.rosterFilter);
      const shiftsForWeekday = shiftsOfDayFiltered.map((s) => {
        return {
          ..._.omit(s, ["date", "id"]),
          repeatId: undefined,
          appliedUserIds: [],
          date: toSimpleDate(toMomentUnsafe(weekDateToStoreShifts as string)!.weekday(weekdayIndex)),
        } as Raw<IShift>;
      });
      totalShifts.push(...shiftsForWeekday);
    });
    return totalShifts;
  }

  handleSubmit = async (values: RosterTemplateFormValue) => {
    const { rosterTemplates, selectedBranch, basedOnDate, shifts } = this.props;
    // We decided to save the shifts of a week template in the year 3000+
    // Even though it is a bit hacky, it saves a lot of confusing code in
    // the UI Components, since we don't have to add so many checks to separate
    // Week Template Shifts and normal Shifts.
    // Starting from 30.12.2999 we store the shifts of a template in a Calendar Week.
    const templateBaseDates = rosterTemplates.map((t) => t.shiftsStoredAtDate);
    const lastTempalteBaseDate = templateBaseDates.sort().reverse()[0];

    let weekDateToStoreShifts = rosterTemplates.length ? addSimpleDays(lastTempalteBaseDate, 7) : "2999-12-30";

    if (basedOnDate) {
      const _shifts = this.generateTemplateShifts(shifts, basedOnDate, selectedBranch, weekDateToStoreShifts);
      await this.props.dispatch(shiftRepository.createList(_shifts));
    }

    const templateData = await this.props.dispatch(
      rosterTemplateRepository.create({
        name: values.name,
        shiftsStoredAtDate: weekDateToStoreShifts,
        branchId: this.props.selectedBranch,
      })
    );
    batch(() => {
      this.props.dispatch(setRosterType(RosterType.Grid));
      this.props.dispatch(setRosterTemplateMode(true, templateData));
      this.props.dispatch(closeModal());
    });
  };

  render() {
    const initialValues = {
      name: "",
    };

    const creatingEmptyTemplate = !this.props.basedOnDate;

    return (
      <TZModal>
        <TZModal.Head>
          {creatingEmptyTemplate ? lg.neue_vorlage_erstellen : lg.wochenplan_als_vorlage_speichern}
        </TZModal.Head>
        <TZModal.Body>
          <Formik
            initialValues={initialValues}
            onSubmit={this.handleSubmit}
            enableReinitialize
            children={this.renderForm}
          />
        </TZModal.Body>
      </TZModal>
    );
  }
}

export const RosterTemplatePopup = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(_RosterTemplatePopup);
