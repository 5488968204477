import React from "react";
import EntitlementHead from "./EntitlementHead/EntitlementHead";
import EntitlementBody from "./EntitlementBody/EntitlementBody";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { connect } from "react-redux";
import { setSelectedAbsenceType, setSelectedYear } from "../../../actions/absence";
import { YearStepper } from "./YearStepper/YearStepper";
import "./styles.scss";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { AbsenceTypeCode } from "../../../shared/entities/IAbsenceType";
import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import { BasicSelect } from "../../../components/BasicSelect/BasicSelect";
import { _selectedTypeId } from "../../../selectors/absenceEntitlementByUserSelector";

const mapStateToProps = (state: AppState) => ({
  year: state.ui.absences.selectedYear,
  absenceTypes: state.data.absenceTypes,
  selectedTypeId: state.ui.absences.selectedTypeId,
});

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class AbsenceEntitlementPage extends React.PureComponent<Props> {
  componentDidMount() {
    const { absenceTypes, selectedTypeId } = this.props;
    const selectedAbsenceType = absenceTypes.find((a) => a.id === selectedTypeId)!;
    if (
      selectedTypeId &&
      selectedAbsenceType.code !== AbsenceTypeCode.vacation &&
      !selectedAbsenceType.hasEntitlement
    ) {
      // In case the selectedTypeId was switched to a absence-type that has no entitlement we resett it here
      // This can happen because selectedTypeId is being used by other pages as well to filter absences
      this.props.dispatch(setSelectedAbsenceType(null));
    }
  }

  changYear = (year: number) => {
    this.props.dispatch(setSelectedYear(year));
  };

  render() {
    const { selectedTypeId, absenceTypes } = this.props;
    const vacationType = absenceTypes.find((a) => a.code === AbsenceTypeCode.vacation);
    const _selectedTypeId = selectedTypeId || vacationType!.id;
    const absenceTypeOptions = absenceTypes
      .filter((a) => a.code === AbsenceTypeCode.vacation || a.hasEntitlement)
      .map((a) => ({ value: a.id, label: a.name }));

    return (
      <div className="fb vacationAccountsPageMain">
        <div className="actionBarMain">
          <YearStepper year={this.props.year} onChange={this.changYear} />
          {/* <div className="typeLabel">{lg.Typ + ":"}</div> */}
          <div className="selectWrapper typeSelectWrapper">
            <BasicSelect
              disabled={absenceTypeOptions.length === 1}
              id="absence-list-types-select"
              value={_selectedTypeId}
              onChange={(id) => this.props.dispatch(setSelectedAbsenceType(id))}
              options={absenceTypeOptions}
            />
          </div>
        </div>
        <div className="grid fb column">
          <EntitlementHead />
          <EntitlementBody selectedTypeId={_selectedTypeId} />
        </div>
      </div>
    );
  }
}

export default withErrorBoundary(
  connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(busyInjector(AbsenceEntitlementPage))
);
