import { ICreditCorrection, CreditCorrectionType } from "../shared/entities/ICreditCorrection";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";

export type InitialUserCreditsMap = { [userId: string]: ICreditCorrection };
export const selectInitialCreditByUser = createSelector(
  [(state: AppState) => state.data.creditCorrections],
  (creditCorrections): InitialUserCreditsMap => {
    const map = {};
    creditCorrections.forEach((c) => {
      if (c.type === CreditCorrectionType.initial) {
        map[c.userId] = c;
      }
    });
    return map;
  }
);
