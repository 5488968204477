import { IResource } from "./../entities/IResource";
import { StandardFilterExpression } from "./queryParams";
import { IFilterExpression } from "./../types/queryParams";
import * as Sentry from "@sentry/browser";
import _ from "lodash";

export enum Operation {
  create = "create",
  update = "update",
  remove = "remove",
}

export type FbQueryOptions<T> = {
  filter?: IFilterExpression<T>;
  limitToLast?: number;
  limitToFirst?: number;
  childNodes?: string[]; // use this to listen to deeper nodes of the tree
  noStoreUpdate?: boolean;
};

export type CrudOperation<T extends IResource> = {
  operation: Operation;
  entity: T;
};

export const toCreateCrudOperations = <T extends IResource>(entities: T[]): CrudOperation<T>[] => {
  return entities.map((entity) => ({
    operation: Operation.create,
    entity,
  }));
};

export const toUpdateCrudOperations = <T extends IResource>(entities: T[]): CrudOperation<T>[] => {
  return entities.map((entity) => ({
    operation: Operation.update,
    entity,
  }));
};

export const firebaseWrite = async <T extends IResource>(
  payload: CrudOperation<T>[],
  dbRef: any,
  extraUpdates: {} = {},
  options: { skip?: boolean } = {}
): Promise<T[]> => {
  const createEntities = [] as T[];
  const updateEntities = [] as T[];
  const updates = {} as any;
  const tenantRef = dbRef.parent;
  const dbKey = dbRef.key;

  if (options.skip) {
    return [];
  }

  payload.forEach(({ operation, entity }) => {
    if (operation === Operation.remove) {
      updates[dbKey + "/" + (entity as T).id] = null;
    }

    if (operation === Operation.create) {
      const _entity = nullifyProps(entity);
      updates[dbKey + "/" + _entity.id] = _entity;
      createEntities.push(_entity);
    }

    if (operation === Operation.update) {
      const _entity = nullifyProps(entity) as T;
      updates[dbKey + "/" + (entity as T).id] = _entity;
      updateEntities.push(_entity);
    }
  });

  Sentry.addBreadcrumb({
    message: `Firebase > ${payload[0]?.operation}:${dbKey}`,
    data: { entities: payload.map((p) => p.entity) },
  });

  const updateValue = { ...updates, ...extraUpdates };
  if (!_.isEmpty(updateValue)) {
    await tenantRef.update({ ...updates, ...extraUpdates });
  }
  return [...createEntities, ...updateEntities] as T[];
};

export const getFirebaseQuery = <T>(baseRef: any, options?: FbQueryOptions<T>): any => {
  let fbQuery = baseRef as any;

  if (options?.filter) {
    const exp = new StandardFilterExpression<any>(options?.filter);

    // if filed is 'id' we use the orderByKey > they are identical > so we dont need to add an INDEX on the child 'id'
    fbQuery = exp.field === "id" ? baseRef.orderByKey() : baseRef.orderByChild(exp.field as string);

    if (exp.operator === "=") {
      fbQuery = fbQuery.equalTo(exp.value);
    }
    if (exp.operator === ">=") {
      fbQuery = fbQuery.startAt(exp.value);
    }
    if (exp.operator === "<=") {
      fbQuery = fbQuery.endAt(exp.value);
    }
    if (exp.operator === "between") {
      fbQuery = fbQuery.startAt(exp.value[0]);
      fbQuery = fbQuery.endAt(exp.value[1]);
    }
  }

  if (options?.limitToLast) {
    fbQuery = fbQuery.limitToLast(options.limitToLast);
  }

  if (options?.limitToFirst) {
    fbQuery = fbQuery.limitToFirst(options.limitToFirst);
  }

  return fbQuery;
};

// turnes undefined properties of an object to null
export const nullifyProps = <T extends {}>(obj: T): T => {
  const newObj = {};
  Object.entries(obj).forEach(([key, val]) => {
    newObj[key] = val === undefined ? null : val;
  });
  return newObj as T;
};
