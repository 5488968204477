import React from "react";
import { useSelector } from "react-redux";
import DayViewIcon from "../RosterViewIcons/DayViewIcon";
import WeekViewIcon from "../RosterViewIcons/WeekViewIcon";
import MonthViewIcon from "../RosterViewIcons/MonthViewIcon";
import "./styles.scss";
import { AppState } from "../../../../../types/AppState";
import { RosterViewOption } from "./RosterViewOption";
import { RosterMode } from "../../../../../reducers/ui/shifts/roster/rosterMode";
import { RosterType } from "../../../../../reducers/ui/shifts/roster/rosterType";
import { Icon } from "antd";
import { featuresSelector } from "../../../../../selectors/FeaturesSelector";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { RoleType } from "../../../../../shared/entities/IUser";

type Props = {};

export const RosterViewPicker = (props: Props) => {
  const rosterMode = useSelector((s: AppState) => s.ui.shifts.roster.rosterMode);
  const rosterType = useSelector((s: AppState) => s.ui.shifts.roster.rosterType);
  const tenantInfo = useSelector((s: AppState) => s.data.tenantInfo);
  const sessionInfo = useSelector(selectSessionInfo);
  const features = useSelector(featuresSelector);
  const isEmployee = sessionInfo.user.role === RoleType.employee;

  const options = [
    {
      rosterMode: isEmployee ? RosterMode.Month : RosterMode.Day,
      rosterType: RosterType.List,
      dataRh: lg.liste,
      rosterIcon: <Icon type="bars" />,
      id: "list",
    },
    {
      rosterMode: RosterMode.Day,
      rosterType: RosterType.Grid,
      dataRh: lg.tages_ansicht,
      rosterIcon: <DayViewIcon isActive={rosterMode === RosterMode.Day && rosterType === RosterType.Grid} />,
      id: "dayGrid",
    },
    {
      rosterMode: RosterMode.Week,
      rosterType: RosterType.Grid,
      dataRh: lg.wochen_ansicht,
      rosterIcon: <WeekViewIcon isActive={rosterMode === RosterMode.Week && rosterType === RosterType.Grid} />,
      id: "weekGrid",
    },
    {
      rosterMode: RosterMode.Month,
      rosterType: RosterType.Grid,
      dataRh: lg.monats_ansicht,
      featureBlocked: !features.rosterMonthlyView,
      rosterIcon: <MonthViewIcon isActive={rosterMode === RosterMode.Month && rosterType === RosterType.Grid} />,
      id: "monthGrid",
    },
    {
      rosterMode: RosterMode.Week,
      rosterType: RosterType.Kanban,
      dataRh: lg.board_ansicht,
      rosterIcon: <Icon type="project" />,
      id: "kanban",
    },
  ].filter((o) =>
    tenantInfo.isV2
      ? isEmployee
        ? o.id === "list"
        : o.id === "list" || o.id === "weekGrid" || o.id === "dayGrid"
      : o.id === "monthGrid" || o.id === "weekGrid" || o.id === "dayGrid" || o.id === "kanban"
  );

  if (isEmployee && tenantInfo.isV2) {
    return null;
  }

  return (
    <div className="rosterViewPicker">
      {options.map((o) => (
        <RosterViewOption
          key={o.dataRh}
          rosterType={o.rosterType}
          rosterMode={o.rosterMode}
          dataRh={o.dataRh}
          rosterIcon={o.rosterIcon}
          featureBlocked={o.featureBlocked}
        />
      ))}
    </div>
  );
};
