import React, { PureComponent } from "react";
import "./styles.scss";
import { connect } from "react-redux";
import { Button } from "antd";
import moment from "moment";
import _ from "lodash";
import { AppState } from "../../types/AppState";
import { selectActiveUsers } from "../../selectors/ActiveUserSelectors";
import { toSimpleDate } from "../../shared/helpers/timeHelpers";
import { IRuleSpecification } from "../../shared/entities/IRuleSpecification";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import { busyInjector, BusyInjectorProps } from "../BusyInjector/BusyInjector";
import { timeClockSettingsRepository } from "../../repositories/timeClockSettingsRepository";
import { rosterSettingsRepository } from "../../repositories/rosterSettingsRepository";
import { nullifyProps } from "../../shared/helpers/firebaseHelpers";
import { closeModal } from "../../actions/modal";
import TZModal from "../TZModal/TZModal";
import { RuleSpecificationForm } from "../RuleSpecificationForm/RuleSpecificationForm";
import { IRosterSettings } from "../../shared/entities/IRosterSettings";
import { ITimeClockSettings } from "../../shared/entities/ITimeClockSettings";

const mapStateToProps = (state: AppState) => ({
  timeClockSetting: state.data.timeClockSettings[0],
  rosterSetting: state.data.rosterSettings[0],
  activeUsers: selectActiveUsers(state, toSimpleDate(moment())),
  jobPositions: state.data.jobPositions,
  branches: state.data.branches,
});

type State = IRuleSpecification;
type OwnProps = {
  type: "RosterSettings" | "TimeClockSettings";
  ruleKey: keyof IRosterSettings | keyof ITimeClockSettings;
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class _RuleSpecificationModal extends PureComponent<Props, State> {
  specifications: any;
  constructor(props: Props) {
    super(props);

    timeClockSettingsRepository.update({ ...props.timeClockSetting, specifications: {} });
    rosterSettingsRepository.update({ ...props.rosterSetting, specifications: {} });

    this.specifications = {} as any;
    props.type === "TimeClockSettings" && (this.specifications = props.timeClockSetting.specifications || {});
    props.type === "RosterSettings" && (this.specifications = props.rosterSetting.specifications || {});
    const ruleSpecifications = (this.specifications[props.ruleKey] || {}) as IRuleSpecification;
    this.state = ruleSpecifications;
  }

  isNoneSelected = () => {
    const { jobPositionIds, userIds, exceptUserIds, branchIds, roleTypes } = this.state;
    return !userIds && !exceptUserIds && !jobPositionIds && !branchIds && !roleTypes;
  };

  saveClicked = async () => {
    const { dispatch, load, type, timeClockSetting, rosterSetting } = this.props;
    if (this.isSaveDisabled() || this.props.isLoading()) {
      return;
    }
    const newRuleSpecification = this.isNoneSelected() ? null : nullifyProps(this.state); // firebase does not allow undefined
    const newSpecifications = { ...this.specifications, [this.props.ruleKey]: newRuleSpecification };
    ///
    let next = {} as any;
    type === "TimeClockSettings" && (next = { ...timeClockSetting, specifications: newSpecifications });
    type === "RosterSettings" && (next = { ...rosterSetting, specifications: newSpecifications });
    ///
    type === "TimeClockSettings" && (await load(dispatch(timeClockSettingsRepository.update(next))));
    type === "RosterSettings" && (await load(dispatch(rosterSettingsRepository.update(next))));
    ///
    dispatch(closeModal());
  };

  isSaveDisabled = () => {
    const { jobPositionIds, userIds, exceptUserIds, branchIds, roleTypes } = this.state;
    // if an option is checked, there needs to be selected IDs. Otherwise disable saving
    return (
      (userIds && !userIds.length) ||
      (exceptUserIds && !exceptUserIds.length) ||
      (jobPositionIds && !jobPositionIds.length) ||
      (branchIds && !branchIds.length) ||
      (roleTypes && !roleTypes.length)
    );
  };

  render() {
    return (
      <TZModal>
        <TZModal.Head title={`${lg.diese_regel_gilt_für}`}></TZModal.Head>
        <TZModal.Body>
          <RuleSpecificationForm
            ruleSpecification={this.state}
            onChange={(rule: IRuleSpecification) => {
              this.setState({ ...rule });
            }}
          />
        </TZModal.Body>
        <TZModal.Footer>
          <Button
            id="rule-specification-modal-save-button"
            type="primary"
            onClick={this.saveClicked}
            loading={this.props.isLoading("saving")}
            children={lg.Speichern}
            disabled={this.isSaveDisabled()}
          />
        </TZModal.Footer>
      </TZModal>
    );
  }
}

export const RuleSpecificationModal = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  busyInjector(_RuleSpecificationModal)
);
