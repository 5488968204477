import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../types/AppState";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";
import { withErrorBoundary } from "../../../../../components/ErrorBoundary/ErrorBoundary";
import cn from "classnames";
import { setGroupByJobPositions } from "../../../../../actions/groupByJobPositions";
import { Icon } from "antd";
import { busyInjector, BusyInjectorProps } from "../../../../../components/BusyInjector/BusyInjector";
import { selectGroupByJobPosition } from "../../../../../selectors/groupByJobPositionSelector";

const mapStateToProps = (state: AppState) => {
  return {
    groupByJobPositions: selectGroupByJobPosition(state),
  };
};

type State = {};
type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class _GroupByRoleButton extends React.PureComponent<Props, State> {
  render() {
    return (
      <div
        className={cn({
          groupeByRoleBtn: true,
          isActive: this.props.groupByJobPositions,
        })}
        data-rh={lg.gruppieren_nach_rolle}
        onClick={(v) => {
          this.props.setLoading("main", true);
          setTimeout(() => {
            this.props.dispatch(setGroupByJobPositions(!this.props.groupByJobPositions));
            this.props.setLoading("main", false);
          }, 50);
        }}
      >
        {this.props.isLoading() ? <Icon type="loading" /> : <Icon type="database" />}
      </div>
    );
  }
}

export const GroupByRoleButton = withErrorBoundary(
  connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(busyInjector(_GroupByRoleButton))
);
