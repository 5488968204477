import {
  FilterOperator,
  IFilterExpression,
  IStandardFilterExpression,
} from "./../types/queryParams";

export class StandardFilterExpression<
  TEntity,
  TOperator extends FilterOperator = FilterOperator,
  TValue = any
> implements IStandardFilterExpression<TEntity, TOperator, TValue> {
  field: keyof TEntity;
  operator: TOperator;
  value: TValue;

  constructor(expression: IFilterExpression<TEntity>) {
    this.field = expression[0];
    this.operator = (expression[1] as any) as TOperator;
    this.value = (expression[2] as any) as TValue;
  }
}

export class QueryParams<TEntity> implements QueryParams<TEntity> {
  filter: StandardFilterExpression<TEntity>[] = [];
}
