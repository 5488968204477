import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import "./styles.scss";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { UserFilter } from "../../../../components/UserFilter/UserFilter";
import { setAbsenceCalenarFilter, AbsenceUiFilter } from "../../../../reducers/ui/filters/absenceCalendarFilter";
import { openModal } from "../../../../actions/modal";
import AbsenceEntitlementModal from "../../../../components/modals/AbsenceEntitlementModal/AbsenceEntitlementModal";
import { AbsenceEntitlementBulkModal } from "../../../../components/modals/AbsenceEntitlementBulkModal/AbsenceEntitlementBulkModal";

export const VAC_USER_COLUMN_WIDTH = 240;

const mapStateToProps = (state: AppState) => ({
  filters: state.ui.filters.absenceCalendar,
});

type State = {};
type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class EntitlementHead extends PureComponent<Props, State> {
  updateFilter = (filter: Partial<AbsenceUiFilter>) => {
    this.props.dispatch(setAbsenceCalenarFilter(filter));
  };

  render() {
    const { branchFilter, positionFilter } = this.props.filters;
    return (
      <div className="vacationAccountsHeadMain">
        <div className="fb cornerBox" style={{ width: VAC_USER_COLUMN_WIDTH }}>
          <UserFilter
            onSearchChange={(searchInput?: string) => this.updateFilter({ searchInput })}
            onBranchChange={(branchFilter?: string) => this.updateFilter({ branchFilter })}
            onPositionChange={(positionFilter?: string) => this.updateFilter({ positionFilter })}
            searchInput={this.props.filters.searchInput}
            branchFilter={this.props.filters.branchFilter}
            positionFilter={this.props.filters.positionFilter}
          />
        </div>

        <div className="colHeaders">
          <div className="cell vacAccCol vacAccCol1">
            {lg.anspruch_table}
            <div
              className="bulkBtn"
              data-rh={lg.zum_erstellen_von_vielene_einträgen_bulk_nutzen}
              onClick={() => this.props.dispatch(openModal(AbsenceEntitlementBulkModal, {}))}
            >
              BULK
            </div>
          </div>
          <div className="cell vacAccCol vacAccCol2">{lg.anspruch_korrigiert}</div>
          <div className="cell vacAccCol vacAccCol3">{lg.beantragt}</div>
          <div className="cell vacAccCol vacAccCol4">{lg.genehmigt}</div>
          {/* <div className="cell vacAccCol vacAccCol5">Korrektur</div> */}
          <div className="cell vacAccCol vacAccCol5">{lg.resturlaub}</div>
        </div>
      </div>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(EntitlementHead);
