import { Button, Icon, Input } from "antd";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import { getNameInitials } from "../../../../helpers/general";
import { useSelector } from "../../../../helpers/redux";
import { threadInfoRepository } from "../../../../repositories/threadInfoRepository";
import { userThreadInfoRepository } from "../../../../repositories/userThreadInfoRepository";
import { selectUserMap } from "../../../../selectors/mapSelectors";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { selectUserFullMap } from "../../../../selectors/userFullMapSelector";
import { IUserThreadInfo } from "../../../../shared/entities/IUserThreadInfo";
import { decryptUser, getUserName } from "../../../../shared/helpers/userHelpers";
import { JobPositionsSelect } from "./JobPositionsSelect";
import { ThreadsListStyles } from "../ThreadsList/styles";
import { ThreadListItem } from "../ThreadsList/ThreadListItem";
import { paidFeatureWarning } from "../../../../actions/paidFeatureWarning";
import { featuresSelector } from "../../../../selectors/FeaturesSelector";
import { selectActiveUsersWithSharedBranch } from "../../../../selectors/UsersWithSharedBranchSelector";
import { selectActiveUsers } from "../../../../selectors/ActiveUserSelectors";
import moment from "moment";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import { ChatSidebarMode } from "../../ChatSidebarMode";
import { selectRosterSettingsForCurrentUser } from "../../../../selectors/rosterSettingsByUserSelector";
import { tierInfoSelector } from "../../../../selectors/TierInfoSelector";
import AvatarDefaultImageSource from "./../../avatar-default-image.png";
import { selectUserThreadInfos } from "../../../../selectors/userThreadInfoSelector";

type Props = {
  setMode: (m: ChatSidebarMode) => void;
  setSelectedThreadId: (id: string) => void;
};

export const CreateNewThread = React.memo((props: Props) => {
  const [searchText, setSearchText] = useState("");
  const [threadTitle, setThreadTitle] = useState("");
  const features = useSelector(featuresSelector);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [displayTitleStep, setDisplayTitleStep] = useState<boolean>(false);
  const dispatch = useDispatch<DispFn>();

  const sessionInfo = useSelector(selectSessionInfo);

  const rosterSettingsForCurrentUser = useSelector(selectRosterSettingsForCurrentUser);
  const { canGroupChat, canChatWithUsersFromOtherBranch } = rosterSettingsForCurrentUser;

  const userThreadInfos = useSelector(selectUserThreadInfos);
  const activeUsers = useSelector((state) => selectActiveUsers(state, moment().format(SDateFormat)));

  const activeUsersWithSharedBranch = useSelector(selectActiveUsersWithSharedBranch);
  const selectableUsers = canChatWithUsersFromOtherBranch
    ? activeUsers
    : activeUsers.filter((u) => activeUsersWithSharedBranch.includes(u.id));

  const filteredUsers = selectableUsers.filter(
    (u) => u.id !== sessionInfo.user.id && getUserName(u).toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
  );

  const create = async () => {
    if (selectedUserIds.length === 1) {
      const existingThread = userThreadInfos.find(
        (uTI) => uTI.userIds.length === 2 && uTI.userIds.includes(selectedUserIds[0])
      );
      if (existingThread) {
        props.setSelectedThreadId(existingThread.id);
        props.setMode(ChatSidebarMode.ThreadsList);
        return;
      }
    }

    const newThread = await dispatch(
      threadInfoRepository.create({
        userIds: _.uniq([sessionInfo.user.id, ...selectedUserIds]),
        title: threadTitle,
      })
    );

    props.setSelectedThreadId(newThread.id);
    props.setMode(ChatSidebarMode.ThreadsList);
  };

  return (
    <div className="fb column shrink" style={ThreadsListStyles.wrapper}>
      {/* Head */}
      <div className="fb row" style={ThreadsListStyles.head}>
        <div className="fb row" style={ThreadsListStyles.headFirstRow}>
          <Button onClick={() => props.setMode(ChatSidebarMode.ThreadsList)} icon="close" />
          {!displayTitleStep && <span style={{ fontSize: 16, opacity: 0.8 }}>{lg.neue_konversation}</span>}
          {!displayTitleStep && (
            <div>
              <Button
                disabled={selectedUserIds.length === 0}
                onClick={() => (selectedUserIds.length === 1 ? create() : setDisplayTitleStep(true))}
                icon="arrow-right"
              />
            </div>
          )}
        </div>
        {!displayTitleStep && (
          <>
            <div
              className="fb column"
              style={{
                background: "#f6f6f6",
                boxShadow: "0 1px 1px rgba(0,0,0,0.13)",
                zIndex: 10,
              }}
            >
              <div className="fb row" style={{ padding: "12px 6px" }}>
                <Input
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder={lg.suche}
                  style={{ borderRadius: 50, border: "none" }}
                  value={searchText}
                />
              </div>
              <div
                style={{
                  fontSize: 12,
                  padding: "0 12px 8px 12px",
                  color: "rgba(0,0,0,0.64)",
                }}
              >
                {lg.ausgewählte_teilnehmer} {selectedUserIds.length}
              </div>
            </div>
          </>
        )}
      </div>

      {/* Content */}

      <div
        className="fb column shrink"
        style={{ ...ThreadsListStyles.content, background: displayTitleStep ? "#ededed" : "#ffffff" }}
      >
        {displayTitleStep && (
          <div className="fb noGrow column" style={{ padding: 12 }}>
            <div
              style={{
                width: 80,
                height: 80,
                borderRadius: "100%",
                background: "gray",
                alignSelf: "center",
                marginBottom: 24,
              }}
            ></div>
            <div style={{ opacity: 0.7, marginBottom: 6, fontSize: 14, textAlign: "center" }}>
              Geben Sie der Gruppe einen Namen
            </div>
            <Input
              onKeyPress={(e) => e.key === "Enter" && create()}
              onChange={(e) => setThreadTitle(e.target.value)}
              placeholder="Gruppenname"
              style={{ borderRadius: 50, border: "none" }}
              value={threadTitle}
            />
            <Button
              type="primary"
              disabled={threadTitle?.length < 3}
              style={{ marginTop: 12, alignSelf: "center" }}
              onClick={create}
              children={lg.Speichern}
            />
          </div>
        )}
        {!displayTitleStep &&
          filteredUsers.map((u) => {
            const isSelected = selectedUserIds.includes(u.id);
            return (
              <div
                onClick={() => {
                  if (!!selectedUserIds.length && !isSelected && !features.groupChats) {
                    dispatch(paidFeatureWarning());
                    return;
                  }
                  setSelectedUserIds(
                    isSelected ? selectedUserIds.filter((uId) => uId !== u.id) : [...selectedUserIds, u.id]
                  );
                }}
                className="fb row"
                style={{ ...ThreadsListStyles.listItem }}
              >
                <div className="fb row aCenter">
                  <img
                    src={decryptUser(u).photoUrl || AvatarDefaultImageSource}
                    style={{
                      ...ThreadsListStyles.profileBox,
                    }}
                  />
                  <div>{getUserName(u)}</div>
                </div>

                {isSelected && <Icon type="check-circle" style={{ color: "#18c756", fontSize: 24 }} />}
              </div>
            );
          })}

        {searchText && (
          <div style={{ opacity: 0.7, fontWeight: 500, fontSize: 12, textAlign: "center", marginTop: 12, padding: 12 }}>
            Suchergebnisse für: {searchText}
          </div>
        )}
      </div>
    </div>
  );
});
