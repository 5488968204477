import { AppState } from "../types/AppState";
import { DispFn } from "../frontend-core/types/thunkTypes";

import { Reducer } from "redux";
import { IThreadInfo } from "../shared/entities/IThreadInfo";
import { selectSessionInfo } from "../selectors/SessionInfoSelector";
import { Raw } from "../shared/entities/IResource";
import moment from "moment";
import "firebase/database";
import firebase from "firebase/compat/app";
import { Map } from "../shared/types/general";
import _ from "lodash";
import { IUserThreadInfo } from "../shared/entities/IUserThreadInfo";
import { UserThreadInfoAT } from "./userThreadInfoRepository";
import { nullifyProps } from "../shared/helpers/firebaseHelpers";
import { LoopProtector } from "../frontend-core/LoopProtector";
import { selectUserThreadInfos } from "../selectors/userThreadInfoSelector";

export enum ThreadInfoAT {
  addList = "@AV_THREAD_ADD_LIST",
  add = "@AV_THREAD_CREATE",
  remove = "@AV_THREAD_REMOVE",
  update = "@AV_THREAD_UPDATE",
  clear = "@AV_THREAD_CLEAR",
}

type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];

type Action =
  | { type: ThreadInfoAT.addList; payload: IThreadInfo[] }
  | { type: ThreadInfoAT.add; payload: IThreadInfo }
  | { type: ThreadInfoAT.remove; payload: string }
  | { type: ThreadInfoAT.update; payload: IThreadInfo }
  | { type: ThreadInfoAT.clear; payload: undefined };

class ThreadInfoRepository {
  listener?: any;

  getRef = (tenantId: string) => {
    const db = firebase.database();
    return db.ref(this.getRefPath(tenantId));
  };

  getRefPath = (tenantId: string) => {
    return `tenants/${tenantId}/threadInfos`;
  };

  addListener = (threadId: string) => async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const tenantId = state.data.auth.session!.tenantId;
    const ref = this.getRef(tenantId).child(threadId);

    this.listener = ref.on("value", (snap: any) => {
      if (snap.val()) {
        dispatch({
          type: ThreadInfoAT.update,
          payload: snap.val(),
        });
      } else {
        dispatch({
          type: ThreadInfoAT.remove,
          payload: threadId,
        });
      }
    });
  };

  removeListener = (threadId: string) => async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const tenantId = state.data.auth.session!.tenantId;

    const ref = this.getRef(tenantId).child(threadId);

    if (this.listener) {
      ref.off("value", this.listener);
    }
  };

  updateSharedKeyValue = <T extends Extract<keyof IUserThreadInfo, keyof IThreadInfo>>(
    threadId: string,
    key: T,
    value: PropType<IUserThreadInfo, T>
  ) => async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const tenantId = state.data.auth.session!.tenantId;
    const threadInfo = state.data.threadInfos.find((t) => t.id === threadId)!;
    const allUserThreadInfos = selectUserThreadInfos(state);
    const userThreadInfo = allUserThreadInfos.find((t) => t.id === threadId)!;

    const updates: any = {
      [`${this.getRefPath(tenantId)}/${threadId}/${key}`]: value || null,
    };

    threadInfo.userIds.forEach((uId) => {
      updates[`tenants/${tenantId}/userThreadInfos/${uId}/${threadId}/${key}`] = value || null;
    });

    LoopProtector.check(dispatch);
    await firebase.database().ref().update(updates);

    dispatch({
      type: ThreadInfoAT.update,
      payload: { ...threadInfo, [key]: value },
    });
    dispatch({
      type: UserThreadInfoAT.update,
      payload: { ...userThreadInfo, [key]: value },
    });
  };

  delete = (threadId: string) => async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const tenantId = state.data.auth.session!.tenantId;
    const threadInfo = state.data.threadInfos.find((t) => t.id === threadId)!;

    const updates: any = {
      [`${this.getRefPath(tenantId)}/${threadId}`]: null,
      [`tenants/${tenantId}/messagesByThreadId/${threadId}`]: null,
    };

    threadInfo.userIds.forEach((uId) => {
      updates[`tenants/${tenantId}/userThreadInfos/${uId}/${threadId}`] = null;
    });

    LoopProtector.check(dispatch);
    await firebase.database().ref().update(updates);

    dispatch({
      type: ThreadInfoAT.remove,
      payload: threadId,
    });
    dispatch({
      type: UserThreadInfoAT.remove,
      payload: threadId,
    });
  };

  addUser = (userId: string, threadId: string) => async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const tenantId = state.data.auth.session!.tenantId;
    const threadInfo = state.data.threadInfos.find((t) => t.id === threadId)!;

    const newThreadInfo: IThreadInfo = {
      ...threadInfo,
      userIds: [...threadInfo.userIds, userId],
    };

    const newUserThreadInfo: IUserThreadInfo = {
      id: newThreadInfo.id,
      userIds: newThreadInfo.userIds,
      title: newThreadInfo.title,
      hasUnseenMessages: true,
      lastMessageTimestamp: moment().valueOf(),
    };

    const threadInfoRefPath = `${this.getRefPath(tenantId)}/${newThreadInfo.id}`;
    const userThreadInfoRefPath = `tenants/${tenantId}/userThreadInfos/${userId}/${newThreadInfo.id}`;
    const updates = {
      [threadInfoRefPath]: nullifyProps(newThreadInfo),
      [userThreadInfoRefPath]: nullifyProps(newUserThreadInfo),
    };

    LoopProtector.check(dispatch);
    await firebase.database().ref().update(updates);

    dispatch({
      type: ThreadInfoAT.update,
      payload: newThreadInfo,
    });
  };

  removeUser = (userId: string, threadId: string) => async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const tenantId = state.data.auth.session!.tenantId;
    const threadInfo = state.data.threadInfos.find((t) => t.id === threadId)!;
    const allUserThreadInfos = selectUserThreadInfos(state);
    const userThreadInfo = allUserThreadInfos.find((t) => t.id === threadId)!;
    const newUserIds = [...threadInfo.userIds].filter((uId) => uId !== userId);

    const updates = {
      [`${this.getRefPath(tenantId)}/${threadInfo.id}/userIds`]: [...threadInfo.userIds].filter(
        (uId) => uId !== userId
      ),
      [`tenants/${tenantId}/userThreadInfos/${userId}/${threadInfo.id}`]: null,
    };

    newUserIds.forEach((uId) => {
      updates[`tenants/${tenantId}/userThreadInfos/${uId}/${threadInfo.id}/userIds`] = newUserIds;
    });

    LoopProtector.check(dispatch);
    await firebase.database().ref().update(updates);

    dispatch({
      type: ThreadInfoAT.update,
      payload: {
        ...threadInfo,
        userIds: newUserIds,
      },
    });

    dispatch({
      type: UserThreadInfoAT.update,
      payload: {
        ...userThreadInfo,
        userIds: newUserIds,
      },
    });
  };

  generateId = () => `${Date.now()}_${Math.floor(Math.random() * 100000)}`;

  create = (opt: { userIds: string[]; title?: string }) => async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const tenantId = state.data.auth.session!.tenantId;
    const sessionInfo = selectSessionInfo(state);

    const newThread: IThreadInfo = {
      createdByUserId: sessionInfo.user.id,
      adminUserIds: opt.userIds.length > 2 ? [sessionInfo.user.id] : undefined,
      title: opt.title,
      userIds: opt.userIds,
      creationTimestamp: moment().valueOf(),
      id: this.generateId(),
    };

    const newUserThreadInfo: IUserThreadInfo = {
      id: newThread.id,
      userIds: newThread.userIds,
      title: newThread.title,
      hasUnseenMessages: false,
      lastMessageTimestamp: newThread.creationTimestamp,
    };

    const threadInfoRefPath = `${this.getRefPath(tenantId)}/${newThread.id}`;

    const updates: any = {
      [threadInfoRefPath]: nullifyProps(newThread),
    };

    newUserThreadInfo.userIds.forEach((uId) => {
      updates[`tenants/${tenantId}/userThreadInfos/${uId}/${newThread.id}`] = nullifyProps(newUserThreadInfo);
    });

    LoopProtector.check(dispatch);
    await firebase.database().ref().update(updates);

    dispatch({
      type: UserThreadInfoAT.add,
      payload: newUserThreadInfo,
    });

    dispatch({
      type: ThreadInfoAT.add,
      payload: newThread,
    });

    return newThread;
  };

  getReducer(): Reducer<IThreadInfo[], Action> {
    return (state: IThreadInfo[] = [], action: Action): IThreadInfo[] => {
      switch (action.type) {
        case ThreadInfoAT.add:
        case ThreadInfoAT.update:
          return [...state.filter((e) => e.id !== action.payload.id), action.payload];
        case ThreadInfoAT.remove:
          return state.filter((e) => e.id !== action.payload);
        case ThreadInfoAT.addList:
          return _.unionBy(action.payload, state, "id");
        case ThreadInfoAT.clear:
          return [];
        default:
          return state;
      }
    };
  }
}

export const threadInfoRepository = new ThreadInfoRepository();
