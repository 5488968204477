import { IRuleSpecification } from "../../../shared/entities/IRuleSpecification";

export const userFilterRule = {
  SET: "@@AV/USER_FILTER_RULE_SET",
};

export const setReportUserSpecification = (payload: IRuleSpecification | null) => ({
  type: userFilterRule.SET,
  payload,
});

const initialState: IRuleSpecification | null = null;

export const reportUserSpecification = (s = initialState, a: any): IRuleSpecification =>
  a.type === userFilterRule.SET ? a.payload : s;
