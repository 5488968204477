import { Icon } from "antd";
import cn from "classnames";
import React from "react";
import "./styles.scss";
import { minutesToDuration } from "../../../../../shared/helpers/timeHelpers";

type EntryBarIcon = {
  type?: string;
  style?: React.CSSProperties;
  tooltip?: string;
};

type Props = {
  minutes: number;
  durationTooltip?: string;
  label?: string;
  tag?: string;
  tagColor?: string; // color in hexadecimal
  tagTooltip?: string;
  icons?: EntryBarIcon[];
  onClick?: () => void;
};

const defaultTagColor = "#cb68ea";

export class CreditEntryBar extends React.PureComponent<Props> {
  render() {
    const { durationTooltip, tagTooltip, tagColor, tag, label, minutes, icons, onClick } = this.props;
    return (
      <div className={cn({ creditEntryBarMain: true, canHover: !!onClick })} onClick={onClick || undefined}>
        <div className="credit cell" data-rh={durationTooltip}>
          {minutesToDuration(minutes)}
        </div>
        {label && <div className="label cell">{label}</div>}
        {tag && (
          <div
            className="tag"
            data-rh-at="bottom"
            data-rh={tagTooltip}
            style={{ backgroundColor: tagColor || defaultTagColor }}
          >
            {tag}
          </div>
        )}
        {icons && (
          <div className="iconsWrapper">
            {icons.map((icon) => (
              <Icon key={icon.tooltip} data-rh={icon.tooltip} type={icon.type} style={icon.style} />
            ))}
          </div>
        )}
      </div>
    );
  }
}
