import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../../types/AppState";
import { getPosLeft, getPosWidth } from "./localHelpers";
import { Icon } from "antd";
import cn from "classnames";
import "./styles.scss";
import { DispatchBaseProps } from "../../../../../../frontend-core/types/DispatchBaseProps";
import { IAbsence } from "../../../../../../shared/entities/IAbsence";
import { simpleDateToMoment } from "../../../../../../shared/helpers/timeHelpers";
import { AbsenceIcon } from "../../../../../../components/AbsenceIcon/AbsenceIcon";

const mapStateToProps = (state: AppState) => ({
  absenceTypes: state.data.absenceTypes,
});

type OwnProps = {
  absence: IAbsence;
  monthStart: string;
  monthEnd: string;
  daysInMonth: number;
  isClickable: boolean;
};
type StoreProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StoreProps & DispatchBaseProps;

class AbsenceBarMonthView extends React.PureComponent<Props, {}> {
  render() {
    const { absence, monthStart, monthEnd, absenceTypes } = this.props;
    const absenceType = absenceTypes.find((a) => a.id === absence.typeId)!;

    const left = getPosLeft(absence, monthStart);
    const width = getPosWidth(absence, monthStart, monthEnd);

    const extendsLeft = absence.startDate < monthStart;
    const extendsRight = absence.endDate > monthEnd;

    const getStartEndShort = () => {
      const start = simpleDateToMoment(absence.startDate).format("DD MMM");
      const end = simpleDateToMoment(absence.endDate).format("DD MMM");
      return start + " - " + end;
    };

    return (
      <div
        className={cn({ absenceMonthBarMain: true })}
        style={{
          left: left * (100 / this.props.daysInMonth) + "%",
          width: width * (100 / this.props.daysInMonth) + "%",
        }}
        data-type={this.props.isClickable ? "absence-bar" : ""}
        data-absence-id={absence.id}
        data-user={absence.userId}
      >
        <div
          className={cn({
            innerBox: true,
            clickable: this.props.isClickable,
            requested: absence.status === "requested",
          })}
          style={{ backgroundColor: absenceType.color }}
        >
          {extendsLeft && (
            <div className="arrowIndicator icon icon-arrow_back">
              <Icon type="left" />
            </div>
          )}
          <div className="content">{width > 90 && getStartEndShort()}</div>
          {extendsRight && (
            <div className="arrowIndicator icon icon-arrow_forward">
              <Icon type="right" />
            </div>
          )}
          {absence.note && (
            <div className="commentIconWrapper" key="comment">
              <Icon type="message" data-rh={absence.note} data-rh-at="bottom" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(AbsenceBarMonthView);
