export const ThreadStyles: { [s: string]: React.CSSProperties } = {
  messageRow: {
    padding: "0 6px 6px 6px",
  },
  messageBox: {
    borderRadius: 6,
    padding: "6px 12px",
    maxWidth: "80%",
    boxShadow: "0 1px .5px rgba(0,0,0,.13)",
  },
  messageDateText: {
    opacity: 0.5,
    fontSize: 12,
    textAlign: "right",
    marginBottom: -4,
    marginRight: -6,
  },
  systemMessage: {
    alignSelf: "center",
    padding: "6px 20px",
    backgroundColor: "rgba(0,0,0,0.07)",
    borderRadius: 50,
    opacity: 0.8,
    textAlign: "center",
  },
  content: {
    paddingTop: 6,
    overflow: "auto",
    background: "#e5ddd5",
    flexDirection: "column-reverse",
  },
  messageText: {
    display: "flex",
    wordBreak: "break-all",
  },
  messageUserNameText: {
    fontSize: 11,
    fontWeight: 500,
    opacity: 0.7,
  },
  head: {
    background: "#ededed",
    padding: 12,
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 1px .5px rgba(0,0,0,.13)",
    zIndex: 10,
    height: 60,
    overflow: "hidden",
  },
  footer: {
    padding: 12,
    background: "#ededed",
  },
  headImage: {
    width: 40,
    height: 40,
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    marginRight: 12,
    fontSize: 16,
    flexShrink: 0,
  },
};
