import React from "react";
import { Select } from "antd";
import "./styles.scss";
import { SelectProps, OptionProps } from "antd/lib/select";
import { antDesignSelectFilterSearch } from "../../frontend-core/helpers/frontendHelpers";
import _ from "lodash";

type Props = {
  value: any;
  options: Array<{ value: any; label: string | number; content?: React.ReactNode }> & OptionProps;
  onChange: (value: any) => void;
  keepOrder?: boolean; // by default options get orderted alphabetically > use this prop to prevent auto-sorting
} & SelectProps<any>;

export class BasicSelect extends React.PureComponent<Props, {}> {
  render() {
    const { options, keepOrder } = this.props;
    const optionsSorted = keepOrder ? options : _.orderBy(options, (o) => `${o.label}`.toLocaleLowerCase());

    return (
      <Select style={{ width: "100%" }} {...this.props} filterOption={antDesignSelectFilterSearch}>
        {optionsSorted.map((o) => (
          <Select.Option {...o} value={o.value} key={o.value} title={o.label + ""}>
            {o.content || o.label}
          </Select.Option>
        ))}
      </Select>
    );
  }
}
