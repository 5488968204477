import React from "react";
import { connect, batch } from "react-redux";
import { AppState } from "../../../../../types/AppState";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { Button, Modal, Icon } from "antd";
import { openModal } from "../../../../../actions/modal";
import { RosterMode } from "../../../../../reducers/ui/shifts/roster/rosterMode";
import {
  selectCurrentPublishedBranches,
  selectIsWeekPlanPublished,
} from "../../../../../selectors/WeekPlanPublishedSelector";
import { unpublishWeekPlan, unpublishWeekPlans } from "../../../../../actions/weekPlan";
import { PublishWeekModal } from "../../../../../components/modals/PublishWeekModal/PublishWeekModal";
import cn from "classnames";
import "./styles.scss";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { selectVisibleBranches } from "../../../../../selectors/VisibleBranchesSelector";

const mapStateToProps = (state: AppState) => {
  const publishedBranches = selectCurrentPublishedBranches(state);
  const visibleBranches = selectVisibleBranches(state);
  const isCurrentWeekPlanPublished = selectIsWeekPlanPublished(state);
  return {
    isCurrentWeekPlanPublished,
    rosterMode: state.ui.shifts.roster.rosterMode,
    shifts: state.data.shifts,
    publishedWeeks: state.data.publishedWeeks,
    sessionInfo: selectSessionInfo(state),
    publishedBranches,
    visibleBranches,
    selectedBranch: state.ui.selectedBranch,
    displayGreenEye: state.ui.selectedBranch
      ? isCurrentWeekPlanPublished
      : publishedBranches.length === visibleBranches.length,
  };
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class PublishWeekPlanButton_ extends React.PureComponent<Props> {
  showConfirm = () => {
    Modal.confirm({
      title: lg.veröffentlichung_aufheben,
      content: <div>{lg.die_schichten_dieser_woche_werden_dadurch_für_mitarbeiter_nicht_mehr_sichtbar_sein()}</div>,
      onOk: () => {
        this.props.dispatch(
          unpublishWeekPlans(
            !this.props.selectedBranch ? this.props.visibleBranches.map((b) => b.id) : [this.props.selectedBranch]
          )
        );
      },
    });
  };

  render() {
    const { displayGreenEye, rosterMode, dispatch, shifts, publishedWeeks, sessionInfo } = this.props;
    const canManage = sessionInfo.hasManagerPermissions();

    if (this.props.rosterMode === RosterMode.Month) {
      return null;
    }

    const hasCreatedSomeShifts = shifts.length >= 5;
    const hasNoPublishedWeek = publishedWeeks.length === 0;
    const showLabel = canManage && hasNoPublishedWeek && hasCreatedSomeShifts && !displayGreenEye; // for new users to understand this button

    return (
      <div
        id="publish-unpublish-roster"
        style={{ marginLeft: 12 }}
        className={cn({
          publishUnpublishButton: true,
          isClickable: canManage,
          isPublished: displayGreenEye,
          isDayMode: rosterMode === RosterMode.Day,
          disabled: rosterMode === RosterMode.Day,
        })}
        onClick={async () => {
          if (canManage) {
            displayGreenEye ? this.showConfirm() : dispatch(openModal(PublishWeekModal, {}));
          }
        }}
        data-rh={
          displayGreenEye
            ? lg.wochenplan_ist_veröffentlicht
            : canManage
            ? lg.wochenplan_veröffentlichen
            : lg.wochenplan_ist_noch_nicht_veröffentlicht
        }
        data-rh-at="bottom"
      >
        <div className="iconWrapper">
          <Icon type={displayGreenEye ? "eye" : "eye-invisible"} />
          {!this.props.selectedBranch && !displayGreenEye && (
            <span style={{ fontSize: 14, marginLeft: 6, fontWeight: 500 }}>
              {this.props.publishedBranches.length} / {this.props.visibleBranches.length}
            </span>
          )}
        </div>
        {showLabel && <div className="labelWrapper">{lg.wochenplan_unveröffentlicht}</div>}
      </div>
    );
  }
}

export const PublishWeekPlanButton =
  connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(PublishWeekPlanButton_);
