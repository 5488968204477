// this is rarely used if the user wants the month view to go from e.g: 16.SEP > 15.NOV
// in that case the customMonthStartDay is 15

export const CREDITS_CUSTOM_MONTH_START_DAY_SELECT = "CREDITS_CUSTOM_MONTH_START_DAY_SELECT";

export const selectCreditsMonthStartDay = (day: number) => ({
  type: CREDITS_CUSTOM_MONTH_START_DAY_SELECT,
  payload: day,
});

const initialState = 1;

export const creditsMonthStartDayReducer = (state = initialState, a: any): number =>
  a.type === CREDITS_CUSTOM_MONTH_START_DAY_SELECT ? a.payload : state;
