import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TZModal from "../TZModal/TZModal";
import _ from "lodash";
import { useSelector } from "../../helpers/redux";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import { Button, Icon, Input, Modal, notification } from "antd";
import { BasicSelect } from "../BasicSelect/BasicSelect";
import moment from "moment";
import "./styles.scss";
import { rosterSettingsRepository } from "../../repositories/rosterSettingsRepository";
import { MasterDataFormField } from "../../shared/entities/IRosterSettings";
import { notificationSettingsRepository } from "../../repositories/notificationSettingsRepository";
import { closeModal, openModal } from "../../actions/modal";
import UserDetailsModal from "../../pages/Users/UserDetailsModal/UserDetailsModal";
import { UserTab } from "../../pages/Users/UserDetailsModal/UserTabBar/UserTabBar";

type Props = {
  userId: string;
};

const getUid = () => "_" + moment().unix(); // timestamped so it can be ordered

export const MasterDataFormFieldManager = (props: Props) => {
  const dispatch = useDispatch<DispFn>();
  const [newField, setNewField] = useState<Partial<MasterDataFormField> | undefined>();
  const [fields, setFields] = useState<MasterDataFormField[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  // const [isLoading, setLoading] = useState(false);
  const rosterSetting = useSelector((s) => s.data.rosterSettings[0]);
  // const masterData = useSelector((s) => s.data.userDetails.find((u) => u.id === props.userId));

  useEffect(() => {
    const fieldsObj = rosterSetting.customMasterDataFields || {};
    fieldsObj && setFields(Object.values(fieldsObj));
  }, []);

  const saveClicked = async () => {
    const fieldsObj = _.keyBy(fields, (f) => f.id);
    const newRosterSetting = { ...rosterSetting, customMasterDataFields: fieldsObj };
    setLoading(true);
    await dispatch(rosterSettingsRepository.update(newRosterSetting));
    setLoading(false);
    notification.success({ message: lg.gespeichert });
  };

  const addFieldClicked = () => {
    setNewField({ id: getUid() });
  };

  const delteFieldClicked = (fieldId: string) => {
    const isAlreadyInDB = rosterSetting?.customMasterDataFields?.[fieldId];

    if (!isAlreadyInDB) {
      return removeField(fieldId);
    }

    Modal.confirm({
      title: lg.wirklich_löschen,
      content:
        lg.dadurch_wird_dieses_formularfeld_für_alle_mitarbeitern_entfernt_bereits_eingetragene_daten_dieses_feldes_werden_dabei_ebenfalls_gelöscht,
      okText: lg.löschen,
      cancelText: lg.abbrechen,
      onOk: () => removeField(fieldId),
    });
  };

  const removeField = (fieldId: string) => {
    setFields(fields.filter((f) => f.id !== fieldId));
    setDirty(true);
  };

  return (
    <TZModal style={{ width: 540 }}>
      <TZModal.Head>{lg.extra_stammdaten_felder}</TZModal.Head>
      <TZModal.Body>
        <div className="masterDataFormFieldManagerMain">
          <div className="allUserNote">
            {/* <Icon type="warning" /> */}
            <Icon type="warning" theme="filled" />
            {lg.diese_einstellungen_gelten_für_die_stammdaten_felder_aller_mitarbeiter}
          </div>
          <div className="content">
            <div className="listWrapper">
              {_.orderBy(fields, "id", "asc").map((field) => (
                <div className="fieldRow">
                  <div className="type cell">
                    {/* <div className="exp">typ:</div> */}
                    {field.type === "text" ? lg.text : lg.datum}
                  </div>
                  <div className="label cell">{field.label}</div>
                  <div className="deleteBtnWrapper deleteCell">
                    <Button type="link" size="small" icon="delete" onClick={() => delteFieldClicked(field.id)} />
                  </div>
                </div>
              ))}
            </div>
            <div className="addWrapper">
              {!newField && <Button icon="plus" children={lg.feld_hinzufügen} onClick={addFieldClicked} />}
            </div>
            {newField && (
              <div className="newFieldEntry">
                <div className="typeSelectWrapper">
                  <BasicSelect
                    value={newField!.type}
                    onChange={(type: any) => setNewField({ ...newField, type })}
                    options={[
                      { value: "text", label: lg.text },
                      { value: "date", label: lg.datum },
                    ]}
                    placeholder="Feldtyp"
                  />
                </div>
                <div className="labelSelectWrapper">
                  <Input
                    value={newField!.label}
                    onChange={(e) => setNewField({ ...newField, label: e.target.value })}
                    placeholder={lg.feldname}
                  />
                </div>
                <Button
                  children={lg.hinzufügen}
                  type="primary"
                  disabled={newField && (!newField.label || !newField?.type)}
                  onClick={() => {
                    setFields([...fields, { ...(newField as MasterDataFormField) }]);
                    setNewField(undefined);
                    setDirty(true);
                  }}
                />
                <Button className="closeBtn" icon="close" onClick={() => setNewField(undefined)} />
              </div>
            )}
          </div>
        </div>
      </TZModal.Body>
      <TZModal.Footer>
        <Button
          id="back-to-user-from-custom-fields-btn"
          type="link"
          onClick={() => {
            dispatch(closeModal());
            const userId = props.userId;
            const selectedTab = UserTab.userInfo;
            setTimeout(() => dispatch(openModal(UserDetailsModal, { userId, selectedTab })), 100);
          }}
          icon="arrow-left"
          style={{ marginLeft: "auto" }}
          children={lg.zurück}
        />
        <Button
          id="batch-delte-popup-button"
          type={isDirty ? "primary" : "default"}
          onClick={saveClicked}
          loading={isLoading}
          disabled={!!newField}
          children={lg.Speichern}
        />
      </TZModal.Footer>
    </TZModal>
  );
};
