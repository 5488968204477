import { BaseRepository } from "../frontend-core/BaseRepository";
import { IJobPosition } from "../shared/entities/IJobPosition";

class JobPositionRepository extends BaseRepository<IJobPosition> {
  constructor() {
    super("jobPositions");
  }
}

export const jobPositionRepository = new JobPositionRepository();
