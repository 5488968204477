import jsPDF from "jspdf";
// import { CellDef } from 'jspdf-autotable';
import autoTable, { CellDef, Styles as CellDefStyles } from "jspdf-autotable";
import { round } from "lodash";
import { object } from "yup";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import { CreditPageSelection } from "../../../../reducers/ui/shifts/credits";
import { AppState, GetState } from "../../../../types/AppState";
import moment from "moment";
import { minutesToDuration, minutesToHours, minutesToHoursStr, toMoment } from "../../../../shared/helpers/timeHelpers";
import { decryptUser } from "../../../../shared/helpers/userHelpers";
import { getLocaleLang, localizeFormat } from "../../../../helpers/dateFormatHelper";

export const translateCreditRow = (row: CreditListExportRow): {} => {
  if (getLocaleLang() === "de") {
    return row; // no need to translate, keys are already in german
  }
  // translate german keys to english ones
  const next = {};
  creditRowEnglishMapper.forEach(({ en, de }) => {
    row.hasOwnProperty(de) && (next[en] = row[de]);
  });
  return next;
};

const creditRowEnglishMapper: { de: keyof CreditListExportRow; en: string }[] = [
  { de: "Datum", en: "Date" },
  { de: "SollStd", en: "Quota" },
  { de: "Arbeitszeit", en: "Worked" },
  { de: "Feiertag", en: "Holiday" },
  { de: "AbwTyp", en: "AbsenceType" },
  { de: "Abwesend", en: "Absence" },
  { de: "Korrektur", en: "Correction" },
  { de: "Geleistet", en: "Credited" },
  { de: "ÜberStd", en: "Overtime" },
  { de: "StdKonto", en: "TimeAccount" },
];

export type CreditListExportRow = {
  Datum: string;
  SollStd: number;
  Arbeitszeit: number;
  Feiertag: number;
  AbwTyp: string;
  Abwesend: number;
  Korrektur: number;
  Geleistet: number;
  ÜberStd: number;
  StdKonto: string | number;
};

const isNum = (x: any) => typeof x === "number";

const sumIfNum = (numbers: number[]): number => {
  return numbers.reduce((prev, cur) => (isNum(cur) ? cur + prev : prev), 0);
};

export const oneEmptyCreditRow = (rows: CreditListExportRow[]): CreditListExportRow => {
  return Object.keys(rows[0]).reduce((prev, cur) => ({ ...prev, [cur]: "" }), {}) as any;
};

export const getCreditRowsSum = (rows: CreditListExportRow[], { startDate, endDate, userId }) => (
  disp: DispFn,
  getState: GetState
): CreditListExportRow => {
  const creditInterval = getState().data.creditIntervals[`${userId}_${startDate}_${endDate}`] || {};
  const { quota, credit, creditInAbsence } = creditInterval;

  return {
    Datum: lg.summe,
    SollStd: quota,
    Arbeitszeit: sumIfNum(rows.map((r) => r.Arbeitszeit)),
    Feiertag: sumIfNum(rows.map((r) => r.Feiertag)),
    Abwesend: creditInAbsence,
    AbwTyp: "-",
    Korrektur: sumIfNum(rows.map((r) => r.Korrektur)),
    Geleistet: credit,
    ÜberStd: credit - quota,
    StdKonto: "",
  };
};

export const creditRowToHours = (row: CreditListExportRow): CreditListExportRow =>
  Object.keys(row).reduce(
    (prev, cur) => ({ ...prev, [cur]: isNum(row[cur]) ? minutesToHoursStr(row[cur]) : row[cur] }),
    {}
  ) as any;

export const creditRowToDuration = (row: CreditListExportRow): CreditListExportRow =>
  Object.keys(row).reduce((prev, _key) => {
    const key = _key as keyof CreditListExportRow;
    const withSign = row[key] < 0 || key === "ÜberStd" || key === "StdKonto";
    return { ...prev, [key]: isNum(row[key]) ? minutesToDuration(row[key] as number, { withSign }) : row[key] };
  }, {}) as any;

//// PDF /////
type CellDefExt = CellDef & { rowData?: any };

export const exportCreditsAsPdf = (_rows: any[], title: string) => {
  const doc = new jsPDF("p");
  doc.setFontSize(12);
  doc.text(title, 8, 8);

  // filter out the row before the SUM-ROW which is empty
  const rows = _rows.filter((row) => row.Datum || row.Date);
  const headerKeys = Object.keys(rows[0]);

  const headCells: CellDefExt[] = headerKeys.map((k) => {
    return {
      content: k,
      styles: {
        fillColor: "#ffffff",
        textColor: "#444444",
        overflow: "ellipsize",
        minCellWidth: 18,
        fontSize: 9,
      },
    };
  });

  const mainTableRows: CellDefExt[][] = rows.map((row, i) => {
    return headerKeys.map((key) => {
      return {
        content: row[key],
        styles: {
          overflow: "ellipsize",
          minCellWidth: 18,
          fontSize: 9,
          textColor: row[key] === 0 || row[key] === "-" || row[key] === "0:00" ? "#aaaaaa" : "#333333",
          fontStyle: i === rows.length - 1 ? "bold" : "normal", // last row is sum-row > bold
        },
        rowData: row,
      };
    });
  });

  autoTable(doc, {
    // theme: "",
    head: [headCells],
    body: mainTableRows,
    startY: 12,
    margin: { bottom: 6, left: 6, right: 6, top: 6 },
    // startX: 15,
    didDrawCell: (data) => {
      const rowData = (data.cell.raw as CellDefExt).rowData;
      let text: string = "";
      doc.setTextColor("#ffffff");
      doc.text(text, data.cell.x + 3, data.cell.y + 4.5, { maxWidth: data.cell.width - 1 });
      doc.setTextColor("#000000");
    },
  });
  doc.save(`${title}.pdf`);
};

export const getExportTitle = (startDate: string, endDate: string) => (disp: DispFn, getState: GetState) => {
  const getDisplayDate = (date: string) => toMoment(date).format(localizeFormat("DD.MM.YY"));
  const monthStartDay = getState().ui.general.creditsCustomMonthStartDay;
  const selection = getState().ui.shifts.credits;
  const users = getState().data.users;
  const user = users.find((u) => u.id === selection.userId)!;
  const userFull = decryptUser(user);

  let displayDate = "";
  if (!selection.isMonthly) {
    displayDate = lg.kw + " " + selection.week + " - " + selection.year;
  }
  if (selection.isMonthly && monthStartDay === 1) {
    displayDate = moment().year(selection.year).month(selection.month).format("MMMM YYYY");
  }
  if (selection.isMonthly && monthStartDay !== 1) {
    displayDate = getDisplayDate(startDate) + " - " + getDisplayDate(endDate);
  }
  return userFull.name + " - " + displayDate;
};
