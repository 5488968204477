import React from "react";
import { FormikProps } from "formik";
import { Form, DatePicker } from "antd";
import "./styles.scss";
import { DatePickerProps } from "antd/lib/date-picker/interface";
import { Moment } from "moment";
import moment from "moment";
import AvFormField from "../AvFormField/AvFormField";
import { SDateFormat } from "../../shared/helpers/SimpleTime";
import { toMomentUnsafe } from "../../shared/helpers/timeHelpers";

type Props = {
  formikProps: FormikProps<any>;
  fieldName: string;
  noErrorMessage?: boolean; // to hide the error-message-div
  noError?: boolean; // to hide any error displaying ( red-border / icon / message )
  label?: string;
  onChange?: (date: string | Moment | undefined) => void;
} & DatePickerProps;

export default class AvFormikDatePicker extends React.PureComponent<Props, {}> {
  update = (date: string | null) => {
    this.props.formikProps.setFieldValue(this.props.fieldName, date);
    this.props.formikProps.setFieldTouched(this.props.fieldName, true);
    this.props.onChange && this.props.onChange(date || undefined);
  };

  render() {
    const {
      fieldName,
      formikProps,
      label,
      noErrorMessage,
      onChange,
      noError,
      ...otherProps
    } = this.props;

    const isTouched = !!formikProps.touched[fieldName];
    const error: string | undefined | false =
      isTouched && (formikProps.errors[fieldName] as any);

    return (
      <AvFormField
        label={label}
        errorMessage={!noError && !noErrorMessage && error}
      >
        <Form.Item
          style={{ marginBottom: 0 }}
          validateStatus={!noError && error ? "error" : undefined}
          hasFeedback={true}
          className="avDatePicker"
        >
          <DatePicker
            format="DD. MMM YYYY"
            onChange={(mom) => {
              this.update(mom && moment(mom).format(SDateFormat));
            }}
            value={toMomentUnsafe(formikProps.values[fieldName])}
            placeholder=""
            name={fieldName}
            {...otherProps}
          />
        </Form.Item>
      </AvFormField>
    );
  }
}
