import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Input, AutoComplete, Icon, Tabs, Radio } from "antd";
import "./styles.scss";
import { busyInjector, BusyInjectorProps } from "../../components/BusyInjector/BusyInjector";
import { Button } from "antd";
import { Redirect, Route, Switch } from "react-router-dom";
import { AdminTablePage } from "./AdminTablePage";
import { AdminStatisticsPage } from "./AdminStatisticsPage";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useSelector } from "../../helpers/redux";
import { setAdminData } from "../../actions/adminData";
import { IAdminData } from "../../shared/entities/IAdminData";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import { environment } from "../../env";

export const AdminDashboard = busyInjector((props: BusyInjectorProps) => {
  const adminData = useSelector((s) => s.adminData);
  const [token, setToken] = useState<string>(sessionStorage.getItem("admin-token") || "");
  const dispatch = useDispatch<DispFn>();

  let inputValue = "";

  const fetchAll = async (): Promise<IAdminData> => {
    const _token = token || (sessionStorage.getItem("admin-token") as string);
    const adminData = (await (
      await fetch(`https://web.aplano.de/api/admin?token=${_token}`, {
        method: "GET",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
      })
    ).json()) as any;

    console.log(adminData);

    dispatch(setAdminData(adminData));
    return adminData;
  };

  useEffect(() => {
    if (!token) {
      dispatch(setAdminData(null));
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (!token || props.isLoading()) {
        return;
      }
      sessionStorage.setItem("admin-token", token);
      fetchAll();
    })();
  }, [token]);

  return (
    <div className="adminPanelWrapper">
      <h2 className="avTitle">Admin Panel</h2>
      {!adminData?.tenantInfos && (
        <div className="tokenInput">
          <Input
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                setToken(inputValue);
              }
            }}
            type="password"
            onChange={(event) => {
              inputValue = event.target.value;
            }}
          ></Input>
          <Button loading={props.isLoading()} onClick={async () => setToken(inputValue)}>
            Submit
          </Button>
        </div>
      )}
      {adminData?.tenantInfos && (
        <>
          <div className="row fb">
            <Button icon="reload" loading={props.isLoading()} onClick={fetchAll} style={{ marginRight: 12 }}>
              Reload Data
            </Button>
            <Radio.Group defaultValue="table" buttonStyle="solid">
              <Radio.Button value="table" onClick={() => dispatch(push("/admin/table"))}>
                Table
              </Radio.Button>
              <Radio.Button value="statistic" onClick={() => dispatch(push("/admin/statistics"))}>
                Statistic
              </Radio.Button>
            </Radio.Group>
          </div>
          <Switch>
            <Route path={"/admin/table"} component={AdminTablePage} />
            <Route path={"/admin/statistics"} component={AdminStatisticsPage} />
            <Redirect exact from={"/admin"} to={"/admin/table"} />
          </Switch>
        </>
      )}
    </div>
  );
});
