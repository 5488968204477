import { selectRosterSettingsByUser } from "./rosterSettingsByUserSelector";
import { IAvailability } from "../shared/entities/IAvailability";
import { FarFuture } from "../actions/shift";
import { WeekDays } from "../shared/constants/WeekDays";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import { IHashtag, IWorkSpace } from "../shared/entities/IWorkSpace";

export const selectHashtags = createSelector(
  [(state: AppState) => state.data._workSpaces],
  (workspaces): IHashtag[] => workspaces.filter((ws) => !!ws.isHashtag) as IHashtag[]
);
