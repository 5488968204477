import React from "react";
import _ from "lodash";
import "./styles.scss";
import { generateWeekdays, simpleDateToMoment } from "../../../../../shared/helpers/timeHelpers";
import "./styles.scss";
import { SDateFormat } from "../../../../../shared/helpers/SimpleTime";
import { KanbanColumn } from "../KanbanColumn/KanbanColumn";
import { useSelector } from "../../../../../helpers/redux";
import { Icon } from "antd";
import { useDispatch } from "react-redux";
import { toggleJobPositionExpanded } from "../../../../../actions/collapsedJobPositions";

export type Props = {
  jobPositionId?: string;
  startDate: string;
};

export const KanbanRow = React.memo(({ jobPositionId, startDate }: Props) => {
  const dispatch = useDispatch();
  const rosterSettings = useSelector((s) => s.data.rosterSettings[0]);
  const jobPositions = useSelector((s) => s.data.jobPositions);
  const collapsedJobPositions = useSelector((s) => s.ui.shifts.roster.collapsedJobPositions) as string[];

  const weekdaysMom = generateWeekdays(startDate, {
    noSaturday: rosterSettings.hideSaturdaysInWeekPlan,
    noSunday: rosterSettings.hideSundaysInWeekPlan,
  });

  const weekdays = weekdaysMom.map((m) => m.format(SDateFormat));

  const jobPosition = jobPositions.find((j) => j.id === jobPositionId);

  const toggleJobpositionRows = (jpId: string) => {
    dispatch(toggleJobPositionExpanded(jpId));
  };

  const isCollapsed = jobPositionId && collapsedJobPositions.includes(jobPositionId);

  return (
    <div className="kanbanShiftsRow">
      {jobPositionId && (
        <div
          style={{ color: jobPosition?.color }}
          className="kanbanRowHeader"
          onClick={() => toggleJobpositionRows(jobPositionId)}
        >
          {/* <div className="kanbanRowHeader" style={{ color: jobPosition!.color }}> */}
          <div className="iconWrapper">{isCollapsed && <Icon type="caret-right" />}</div>
          <div className="text">{jobPosition!.name}</div>
        </div>
      )}
      {isCollapsed ? (
        <div className="collapsedRow"></div>
      ) : (
        <div className="content">
          {weekdays.map((date) => (
            <KanbanColumn key={date} jobPositionId={jobPositionId} date={date} />
          ))}
        </div>
      )}
    </div>
  );
});
