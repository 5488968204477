import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../../../helpers/redux";
import "./styles.scss";
import { Button, Checkbox, Icon, Input, InputNumber } from "antd";
import TZModal from "../../../../../components/TZModal/TZModal";
import { LocationSearchInput } from "../../../branches/branchPopup/LocationSearchInput";
import { v4 as uuid } from "uuid";
import AvColorPicker from "../../../../../components/AvColorPicker/AvColorPicker";
import { pastelColors, strongColors } from "../../../../../frontend-core/helpers/colors";
import _ from "lodash";
import AvFormField from "../../../../../components/AvFormField/AvFormField";
import { closeModal } from "../../../../../actions/modal";
import { busyInjector, BusyInjectorProps } from "../../../../../components/BusyInjector/BusyInjector";
import { shiftAddressRepository } from "../../../../../repositories/shiftAddressRepository";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";
import { decimalSeparatorLocal } from "../../../../../helpers/dateFormatHelper";
import { IHashtag } from "../../../../../shared/entities/IWorkSpace";
import { workSpaceRepository } from "../../../../../repositories/workSpaceRepository";

type Props = {
  hashtag?: IHashtag;
};

export const HashtagModal = busyInjector((props: Props & BusyInjectorProps) => {
  const dispatch = useDispatch<DispFn>();
  const [showConfirmDelete, setConfirmDelete] = useState(false);
  const [hashtag, setHashtag] = useState<IHashtag>({
    id: uuid(),
    name: "",
    color: "#1a90ff",
    isHashtag: true,
  });

  useEffect(() => {
    props.hashtag && setHashtag({ ...props.hashtag });
  }, []);

  const updateShiftAddress = (name: string) => {
    setHashtag({ ...hashtag, name });
  };

  const deleteClicked = () => {
    if (!showConfirmDelete) {
      setConfirmDelete(true);
      return;
    }
    dispatch(workSpaceRepository.create({ ...hashtag, isInactive: true }));
    dispatch(closeModal());
  };

  const saveClicked = async () => {
    const isCreationMode = !props.hashtag;

    isCreationMode ? dispatch(workSpaceRepository.create(hashtag)) : dispatch(workSpaceRepository.update(hashtag));

    dispatch(closeModal());
  };

  return (
    <TZModal>
      <TZModal.Head># Hashtag</TZModal.Head>
      <TZModal.Body>
        <div className="hashtagPopupMain">
          <div className="nameInputWrapper">
            <AvFormField label={lg.bezeichnung}>
              <Input value={hashtag.name} onChange={(e) => updateShiftAddress(e.target.value || "")} />
            </AvFormField>
          </div>
        </div>
      </TZModal.Body>
      <TZModal.Footer>
        {!!props.hashtag && (
          <Button
            style={{ marginRight: "auto" }}
            id="delete-address-button"
            type={showConfirmDelete ? "danger" : "ghost"}
            children={showConfirmDelete ? lg.wirklich_löschen : lg.löschen}
            onClick={deleteClicked}
          />
        )}
        <Button
          id="save-branch"
          type="primary"
          disabled={!hashtag.name}
          onClick={() => saveClicked()}
          children={lg.Speichern}
        />
      </TZModal.Footer>
    </TZModal>
  );
});
