import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../types/AppState";
import "./styles.scss";
import PrettyDate from "../PrettyDate/PrettyDate";

import { busyInjector, BusyInjectorProps } from "../BusyInjector/BusyInjector";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";

import PlanedAndRequestedTime from "../PlanedAndRequestedTime/PlanedAndRequestedTime";
import { IChangeRequest } from "../../shared/entities/IChangeRequest";
import { IShift } from "../../shared/entities/IShift";
import { getUserName } from "../../shared/helpers/userHelpers";

const mapStateToProps = (state: AppState) => {
  return {
    branches: state.data.branches,
    users: state.data.users,
  };
};

type OwnProps = {
  changeRequest: IChangeRequest;
  shift: IShift;
  openModal: () => void;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & BusyInjectorProps & DispatchBaseProps;

class _ChangeRequestItem extends React.PureComponent<Props> {
  render() {
    const { changeRequest, shift } = this.props;
    const isMultiBranch = this.props.branches.filter((b) => !b.isInactive).length > 1;
    const user = this.props.users.find((u) => u.id === changeRequest.userId)!;
    const userName = getUserName(user);

    return (
      <div className="changeRequestItemMain" key={changeRequest.id} onClick={this.props.openModal}>
        <div className="rowItem">
          <div className="listItem dateCell">
            <PrettyDate date={shift.date} />
          </div>
          <div className="listItem dateCell">
            <PlanedAndRequestedTime planedTime={shift} requestedTime={changeRequest} />
          </div>
          <div className="fb nameItem">
            <div className="user">{userName}</div>
            {isMultiBranch && (
              <div className="changeRequestBranch">
                {this.props.branches.find((branch) => branch.id === shift.branchId)!.name}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const ChangeRequestItem = connect(mapStateToProps)(busyInjector(_ChangeRequestItem));
