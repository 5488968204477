import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import TZModal from "../../TZModal/TZModal";
import { busyInjector, BusyInjectorProps } from "../../BusyInjector/BusyInjector";
import { Button, Icon } from "antd";
import { closeModal } from "../../../actions/modal";
import YouTube from "react-youtube";
import { useSelector } from "../../../helpers/redux";
import cN from "classnames";
import _ from "lodash";
import { DispFn } from "../../../frontend-core/types/thunkTypes";
import { intoVideos } from "./intoVideoList";
import "./styles.scss";

type OwnProps = {};
type Props = BusyInjectorProps & OwnProps;

const _IntroVideoModal = (props: Props) => {
  const dispatch = useDispatch<DispFn>();
  const [selectedVideoId, setSelectedVideoId] = useState<string>(intoVideos[0].videoId);

  return (
    <TZModal className="introVideoModal">
      <>
        <TZModal.Head title={lg.video_tutorials}></TZModal.Head>
        <TZModal.Body>
          <div className="row fb grow">
            <div className="videoWrapper fb grow">
              <YouTube videoId={selectedVideoId} opts={{ playerVars: { autoplay: 1 } }} />
            </div>
            <div className="videoSidebar">
              {_.orderBy(Object.values(intoVideos), "sortOrder").map((v, i) => {
                return (
                  <div
                    className={cN({
                      videoListItem: true,
                      fb: true,
                      row: true,
                      isActive: selectedVideoId === v.videoId,
                    })}
                    key={v.videoId}
                    onClick={() => setSelectedVideoId(v.videoId)}
                  >
                    <div className="row fb grow">
                      <div className="videoNumber">{i + 1}</div>
                      <div className="videoName fb grow">{v.title}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </TZModal.Body>
        <TZModal.Footer>
          <Button
            id="close-intro-video-modal"
            onClick={() => {
              dispatch(closeModal());
            }}
          >
            {lg.schließen}
          </Button>
        </TZModal.Footer>
      </>
    </TZModal>
  );
};

export const IntroVideoModal = React.memo(busyInjector(_IntroVideoModal));
