import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { Button, Checkbox, Icon, Input, InputNumber } from "antd";
import _ from "lodash";
import { ISurcharge } from "../../../../../shared/entities/ISurcharge";
import { InputProps } from "antd/lib/input";
import { InputNumberProps } from "antd/lib/input-number";
import WeekDayPicker from "../../../../../components/modals/AvailabilityModal/weekDayPicker/WeekDayPicker";
import { WeekDay } from "../../../../../shared/constants/WeekDays";
import { BasicSelect } from "../../../../../components/BasicSelect/BasicSelect";
import AvTimeInput from "../../../../../components/AvTimeInput/AvTimeInput";
import cn from "classnames";
import moment from "moment";
import { arrayToMap, mapToArray } from "./SurchargeModal";
import { useSelector } from "../../../../../helpers/redux";
import { AppState } from "../../../../../types/AppState";

type Props = {
  surcharge: ISurcharge;
  onChange: (s: Partial<ISurcharge>) => void;
};

const allWeekDays = { mo: true, tu: true, we: true, th: true, fr: true, sa: true, su: true };

const typeOptions = [
  { label: "Nachtzuschlag", value: "night", shorthand: "NZ" },
  { label: "Feiertagszuschlag", value: "holiday", shorthand: "FZ" },
  { label: "Sonntagszuschlag", value: "sunday", shorthand: "SZ" },
  { label: "Benutzerdefiniert", value: "custom", shorthand: "" },
];

export const SurchargeModalBasicTab = (props: Props) => {
  // const dispatch = useDispatch<DispFn>();
  const rosterSetting = useSelector((s) => s.data.rosterSettings[0]);

  const { surcharge, onChange } = props;

  const renderInputField = (
    key: keyof ISurcharge,
    label: string,
    inputProps?: InputProps,
    style?: React.CSSProperties,
    hide?: boolean
  ) =>
    hide ? null : (
      <div className="formWrapper" style={style || {}} key={key}>
        <div className="labelWrapper">{label}</div>
        <Input
          value={surcharge[key] as string}
          type="text"
          onChange={(e) => onChange({ [key]: e.target.value })}
          name={label}
          {...(inputProps || {})}
        />
      </div>
    );

  const renderNumberField = (
    key: keyof ISurcharge,
    label: string,
    inputProps?: InputNumberProps,
    style?: React.CSSProperties
  ) => (
    <div className="formWrapper" style={style || {}} key={key}>
      <div className="labelWrapper">{label}</div>
      <InputNumber
        value={surcharge[key] as number}
        onChange={(val) => onChange({ [key]: val })}
        name={label}
        {...(inputProps || {})}
      />
    </div>
  );

  const onTypeChange = (type: typeof surcharge.type) => {
    const option = typeOptions.find((o) => o.value === type)!;
    const weekdays = type === "night" ? allWeekDays : type === "sunday" ? { su: true } : undefined;
    const startTime = type === "night" ? "23:00" : "00:00";
    const endTime = type === "night" ? "06:00" : "24:00";
    const onlyOnHolidays = type === "holiday";
    const exceptOnHolidays = type === "sunday";

    onChange({
      type,
      name: type === "custom" ? "" : option.label,
      shorthand: option.shorthand,
      weekdays,
      startTime,
      endTime,
      onlyOnHolidays,
      exceptOnHolidays,
    });
  };

  return (
    <div className="surchargeModalGeneralTabMain">
      <div className="formRow">
        <div className="formWrapper" style={{ width: 180 }}>
          <div className="labelWrapper">{lg.Typ}</div>
          <BasicSelect options={typeOptions} value={surcharge.type} onChange={onTypeChange} keepOrder={true} />
        </div>
        {[
          renderInputField("name", lg.bezeichnung, {}, { width: 210 }, surcharge.type !== "custom"),
          renderInputField("shorthand", lg.kuerzel, { maxLength: 4 }, { width: 75 }),
          renderNumberField("percentage", lg.prozent),
        ]}
      </div>
      <div className="formRow">
        <div className="formWrapper">
          <div className="labelWrapper">
            {lg.wochentage}
            <div className="weekDayCount">{mapToArray(surcharge.weekdays || {}).length || "keine"}</div>
          </div>
          <WeekDayPicker
            mainStyle={{ borderRadius: 4, overflow: "hidden" }}
            dayStyle={{ marginRight: 0, borderRadius: 0, height: 28, width: 44 }}
            value={mapToArray(surcharge.weekdays || {}) as Array<WeekDay>}
            onChange={(wds: WeekDay[]) =>
              onChange({
                weekdays: arrayToMap(wds),
                onlyOnHolidays: undefined,
              })
            }
          />
        </div>
        <div className="formWrapper" style={{ paddingTop: 10, paddingLeft: 10 }}>
          {/* <div className="labelWrapper">Feiertage</div> */}
          {/* <BasicSelect
            style={{ width: 200 }}
            options={holidayOptions}
            value={holidayOptions.find((opt) => surcharge[opt.value])?.value || "alsoOnHolidays"}
            onChange={(val: string) =>
              onChange({
                onlyOnHolidays: val === "onlyOnHolidays",
                alsoOnHolidays: val === "alsoOnHolidays",
                exceptOnHolidays: val === "exceptOnHolidays",
              })
            }
          /> */}
          <div className="holidayCheckboxWrapper">
            <Checkbox
              checked={surcharge.onlyOnHolidays}
              onChange={(e) =>
                onChange({
                  onlyOnHolidays: e.target.checked,
                  exceptOnHolidays: undefined,
                  weekdays: {},
                })
              }
              children={"Nur an Feiertagen"}
            />
          </div>
          <div className="holidayCheckboxWrapper">
            <Checkbox
              checked={surcharge.exceptOnHolidays}
              onChange={(e) =>
                onChange({
                  exceptOnHolidays: e.target.checked,
                  onlyOnHolidays: undefined,
                })
              }
              children={"Außer an Feiertagen"}
            />
          </div>
        </div>
      </div>
      <div className="formRow">
        <div className="formWrapper">
          <div className="labelWrapper">{lg.von_bis}</div>
          <div className="fb row">
            <div className="cell">
              <AvTimeInput
                value={surcharge.startTime}
                onValueChange={(startTime) => onChange({ startTime })}
                placeholder="00:00"
                style={{
                  textAlign: "center",
                  fontSize: 15,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  width: 90,
                }}
              />
            </div>
            <div className="cell">
              <AvTimeInput
                value={surcharge.endTime}
                onValueChange={(endTime) => onChange({ endTime })}
                placeholder="24:00"
                style={{
                  textAlign: "center",
                  fontSize: 15,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  marginLeft: -1,
                  width: 90,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {rosterSetting.wagesCanBeEntered && (
        <div className="fromRow" style={{ paddingLeft: 14 }}>
          <Checkbox
            checked={!!surcharge.applyToWage}
            onChange={(e) => onChange({ applyToWage: e.target.checked })}
            children={"Zuschlag auf den Lohn anrechnen"}
          />
        </div>
      )}
    </div>
  );
};
