import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../types/AppState";
import { userRepository } from "../../repositories/userRepository";
import { branchRepository } from "../../repositories/branchRepository";
import { jobPositionRepository } from "../../repositories/jobPositionRepository";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import { contractRepository } from "../../repositories/contractRepository";
import { rosterTemplateRepository } from "../../repositories/rosterTemplateRepository";
import { creditCorrectionRepository } from "../../repositories/creditCorrectionRepository";
import { selectBranch } from "../../actions/branchSelection";
import { holidayRepository } from "../../repositories/holidaysRepository";
import { absenceTypeRepository } from "../../repositories/absenceTypeRepository";
import { publishedWeekRepository } from "../../repositories/publishedWeekRepository";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { availabilityRepository } from "../../repositories/availabilityRepository";
import { rosterSettingsRepository } from "../../repositories/rosterSettingsRepository";
import { workSpaceRepository } from "../../repositories/workSpaceRepository";
import { timeClockSettingsRepository } from "../../repositories/timeClockSettingsRepository";
import { baseDataLoaded, DataStatus, baseDataLoading } from "../../reducers/dataStatus";
import { absenceRepository } from "../../repositories/absenceRepository";
import { dailyNoteRepository } from "../../repositories/dailyNoteRepository";
import { shiftHandOverRequestRepository } from "../../repositories/shiftHandOverRequestRepository";
import { changeRequestRepository } from "../../repositories/ChangeRequestRepository";
import { trackingRepository } from "../../repositories/TrackingRepository";
import { shiftRepository } from "../../repositories/shiftRepository";
import { creditRepository } from "../../repositories/creditRepository";
import { SDateFormat } from "../../shared/helpers/SimpleTime";
import moment from "moment";
import { maintenanceRepo } from "../../repositories/maintenanceRepo";
import { startSetupWizard } from "../../reducers/ui/setupWizard";
import { contingentCorrectionRepository } from "../../repositories/contingentCorrectionRepository";
import { absenceEntitlementRepository } from "../../repositories/absenceEntitlementRepository";
import { setSentryUser } from "../../frontend-core/helpers/sentryFrontend";
import { tenantInfoRepository } from "../../repositories/tenantInfoRepository";
import { shiftRepeatRepository } from "../../repositories/shiftRepeatRepository";
import { RoleType } from "../../shared/entities/IUser";
import { environment } from "../../env";
import { shiftAddressRepository } from "../../repositories/shiftAddressRepository";
import { pushNoteRepository } from "../../repositories/pushNoteRepository";
import { announcementRepository } from "../../repositories/announcementRepository";
import { userDetailRepository } from "../../repositories/userDetailRepository";
import { userThreadInfoRepository } from "../../repositories/userThreadInfoRepository";
import { checkServerTime } from "../../actions/checkServerTime";
import { Hinter } from "../../actions/hinting";
import { surchargeRepository } from "../../repositories/surchargeRepository";
import { timeClockingRepository } from "../../repositories/timeClockingRepository";

const mapStateToProps = (state: AppState) => {
  return {
    sessionInfo: selectSessionInfo(state),
    selectedBranch: state.ui.selectedBranch,
    branches: state.data.branches,
    session: state.data.auth.session!,
    isLoaded: state.dataStatus === DataStatus.isLoaded,
    users: state.data.users,
    isV2: state.data.tenantInfo.isV2,
  };
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class PreloaderComponent extends React.PureComponent<Props> {
  async componentDidMount() {
    if (environment.isRunningLocally() && this.props.isLoaded) {
      return;
    }
    this.props.dispatch(maintenanceRepo.addListener());
    await this.props.dispatch(tenantInfoRepository.addListener());
    await this.props.dispatch(branchRepository.fetchMany());

    this.props.branches.length // base data exists
      ? this.addInitialListeners()
      : this.props.dispatch(startSetupWizard());
  }

  UNSAFE_componentWillUpdate(nextProps: Props) {
    const { selectedBranch, isLoaded, sessionInfo } = nextProps;
    const noBranchSelected = selectedBranch === "" && isLoaded; // This needs to be "", as null means, that all - branches is selected
    if (noBranchSelected) {
      this.props.dispatch(selectBranch(sessionInfo.user.branchIds[0]));
    }
  }

  addInitialListeners = async () => {
    const { dispatch, session } = this.props;
    dispatch(baseDataLoading());
    const userIdFilter = { filter: ["userId", "=", session.userId] } as any;
    const today = moment().format(SDateFormat);
    const recentPast = moment().add(-30, "days").format(SDateFormat);
    const in90Days = moment().add(90, "days").format(SDateFormat);
    const userId = session.userId;
    const canManage = session.role !== RoleType.employee;

    const baseRequests = [
      userRepository.addListener(),
      branchRepository.addListener(),
      jobPositionRepository.addListener(),
      absenceTypeRepository.addListener(),
      rosterSettingsRepository.addListener(),
      timeClockSettingsRepository.addListener(),
      holidayRepository.addListener(),
      dailyNoteRepository.addListener(),
      publishedWeekRepository.addListener(),
      workSpaceRepository.addListener(),
      shiftHandOverRequestRepository.addListener(),
      changeRequestRepository.addListener(),
      absenceRepository.addListener(),
      shiftAddressRepository.addListener(),
      userThreadInfoRepository.addListener(), // So we can display the badge in the topbar
      pushNoteRepository.addListener({
        filter: ["userId_hasSeen", "=", `${userId}_false`],
      }),
    ];

    const conditionalRequests =
      session.role === RoleType.employee
        ? [
            creditRepository.addListener(userId), // This Repo is not of type BaseRepo: signature differs
            contractRepository.addListener(userIdFilter),
            availabilityRepository.addListener(userIdFilter),
            creditCorrectionRepository.addListener(userIdFilter),
            shiftRepeatRepository.addListener(userIdFilter),
            contingentCorrectionRepository.addListener(userIdFilter),
            absenceEntitlementRepository.addListener(userIdFilter),
            shiftRepository.addListener({
              // This data gets used in the dashboard > MyUpcomingShifts
              filter: ["userId_date", "between", [`${userId}_${today}`, `${userId}_${in90Days}`]],
              key: "myUpcomingShifts",
            }),
            trackingRepository.addListener({
              filter: ["userId_isAccepted", "=", `${userId}_false`],
              key: "trackingRequests",
            }),
          ]
        : [
            creditRepository.addListener(),
            contractRepository.addListener(),
            rosterTemplateRepository.addListener(),
            availabilityRepository.addListener(),
            creditCorrectionRepository.addListener(),
            shiftRepeatRepository.addListener(),
            contingentCorrectionRepository.addListener(),
            absenceEntitlementRepository.addListener(),
            userDetailRepository.addListener(), // to show the staffNumber in different views.
            surchargeRepository.addListener(),
            trackingRepository.addListener({
              filter: ["isAccepted", "=", false],
              key: "trackingRequests",
            }),
          ];

    const initialRequests = [...baseRequests, ...conditionalRequests];

    // Fetching open shifts
    initialRequests.push(
      shiftRepository.addListener({
        filter: ["userId_date", "between", [`undefined_${recentPast}`, `undefined_${in90Days}`]],
        key: "openShifts",
      })
    );

    if (this.props.isV2 && canManage) {
      const filter = ["isAccepted", "=", false] as any;
      initialRequests.push(timeClockingRepository.addListener({ filter, key: "clockingRequests" }));
    }

    // to show the unread-announcements-badge in topbar
    announcementRepository.fetchMany({
      childNodes: ["announcementsByUser", this.props.sessionInfo.user.id],
      filter: ["hasRead", "<=", false],
    });

    await Promise.all(initialRequests.map(dispatch));
    dispatch(baseDataLoaded());
    dispatch(Hinter.loadedBaseData());
    console.log("data loaded");
    dispatch(checkServerTime());
    setSentryUser(this.props.sessionInfo.user, session.tenantId);
  };

  render() {
    return this.props.isLoaded ? this.props.children : null;
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(PreloaderComponent as any); // hacky workaround - typescript error with props.children
