import React from "react";

const styles = {
  padding: "15px 24px",
  background: "rgb(255, 255, 255)",
  color: "#00000073",
  flex: "1 1 auto",
  display: "flex",
};

type Props = {
  show: boolean;
  text: string;
  tooltip?: string;
};

export const EmptyContent = (props: Props) => {
  if (!props.show) {
    return null;
  } else {
    return (
      <div style={styles}>
        <div data-rh={props.tooltip || null}>{props.text}</div>
      </div>
    );
  }
};
