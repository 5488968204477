import { createSelector, OutputSelector } from "reselect";
import { IResource } from "../entities/IResource";
import { Map } from "../types/general";
import groupBy from "lodash/groupBy";
import { Dictionary } from "lodash";

export function createMapSelector<T extends IResource, S>(
  getStoreItem: (s: S) => T[],
  groupByKey: keyof T
): OutputSelector<S, Map<T[]>, (res: T[]) => Map<T[]>>;

export function createMapSelector<T extends IResource, S>(
  getStoreItem: (s: S) => T[]
): OutputSelector<S, Map<T>, (res: T[]) => Map<T>>;

export function createMapSelector<T extends IResource, S>(getStoreItem: (s: S) => T[], groupByKey?: keyof T) {
  if (groupByKey) {
    return createSelector([getStoreItem], (entities) => {
      return groupBy(entities, groupByKey);
    });
  } else {
    return createSelector([getStoreItem], (entities) => {
      const map: Map<T> = {};
      for (var i = 0, n = entities.length; i < n; ++i) {
        map[entities[i].id] = entities[i];
      }
      return map;
    });
  }
}
