import { DispFn } from "../frontend-core/types/thunkTypes";
import { AppState } from "../types/AppState";
import { shiftHandOverRequestRepository } from "../repositories/shiftHandOverRequestRepository";
import { message, notification } from "antd";
import { selectSessionInfo } from "../selectors/SessionInfoSelector";
import { shiftRepository } from "../repositories/shiftRepository";
import { Pusher } from "./pushNote";
import moment from "moment";
import { closeModal } from "./modal";
import { IShift } from "../shared/entities/IShift";
import { HandoverType, IShiftHandOverRequest } from "../shared/entities/IShiftHandOverRequest";
import _ from "lodash";
import * as Sentry from "@sentry/browser";

export const createHandOverRequest = (shift: IShift, toUserId?: string) => async (
  dispatch: DispFn,
  getState: () => AppState
): Promise<any> => {
  await dispatch(
    shiftHandOverRequestRepository.create({
      id: shift.id,
      branchId: shift.branchId,
      fromUserId: selectSessionInfo(getState()).user.id,
      toUserId,
      jobPositionId: shift.jobPositionId,
      createdAt: moment().toISOString(),
      date: shift.date,
      type: toUserId ? HandoverType.direct : HandoverType.group,
    })
  );

  await dispatch(Pusher.handOverRequest(shift, toUserId));
};

export const approveHandover = (handoverRequest: IShiftHandOverRequest, shift: IShift) => async (
  dispatch: DispFn,
  getState: () => AppState
) => {
  const state = getState();
  const userInfo = selectSessionInfo(state);
  const canManage = userInfo.isAdmin() || userInfo.isManagingBranch(shift.branchId);
  const currentUserId = selectSessionInfo(state).user.id;
  const shiftSwapNeedsManagerAceptance = state.data.rosterSettings[0].shiftSwapNeedsManagerAceptance;
  const doHandOver = canManage || !shiftSwapNeedsManagerAceptance;
  const { fromUserId, approvedByUserId } = handoverRequest;
  const updatedShift = { ...shift, userId: approvedByUserId };
  const hasJobPosId = selectSessionInfo(state).user.jobPositionIds.includes(handoverRequest.jobPositionId);

  Sentry.addBreadcrumb({
    message: "approved handoverRequest",
    data: { handoverRequest, shift },
  });

  if (doHandOver) {
    if (canManage) {
      if (
        currentUserId === handoverRequest.toUserId ||
        (!handoverRequest.toUserId && hasJobPosId && !handoverRequest.approvedByUserId)
      ) {
        // if manager or admin recieve a handover request, they instantly assign themselves
        // as userId, and make the handOverRequest happen.
        updatedShift.userId = currentUserId;
      }
      await dispatch(shiftRepository.update(updatedShift));
    } else if (!shiftSwapNeedsManagerAceptance) {
      //The `!shiftSwapNeedsManagerAceptance` option is currently disabled.
      //A Cloud function needs to be implemented to breake through ACL and update cretids
      //
      //await dispatch(shiftRepository.selfAssign(shift, currentUserId));
    }

    await dispatch(shiftHandOverRequestRepository.remove(handoverRequest.id));
    const toUserId = updatedShift.userId || currentUserId;
    dispatch(Pusher.handOverAccepted(shift, fromUserId, toUserId));
    message.success(lg.schichtabgabe_akzeptiert);
  } else {
    // here only the target-employe accepted the handover. We dont update any shift,
    // until the manager accepts it.
    const acceptedRequest = { ...handoverRequest, approvedByUserId: currentUserId };
    await dispatch(shiftHandOverRequestRepository.update(acceptedRequest));
    dispatch(Pusher.handOverManagerCheck(shift, fromUserId, currentUserId));
  }

  dispatch(closeModal());
  return;
};

export const rejectHandover = (handoverRequest: IShiftHandOverRequest) => async (
  dispatch: DispFn,
  getState: () => AppState
) => {
  const state = getState();
  const shift = getState().data.shifts.find((s) => s.id === handoverRequest.id)!;
  const user = selectSessionInfo(state).user;

  // If handoverRequest only speifies jobPosition and user clicks -accept- , clicking -reject- right after
  // should not delete the handoverRequest but update the userId to undefined
  if (handoverRequest.approvedByUserId === user.id) {
    const rejectedRequest = { ...handoverRequest, approvedByUserId: undefined };
    await dispatch(shiftHandOverRequestRepository.update(rejectedRequest));
  } else {
    await dispatch(shiftHandOverRequestRepository.remove(handoverRequest.id));
    dispatch(Pusher.handOverRejected(shift, handoverRequest.fromUserId, user.id));
  }

  const userIsRequestCreator = handoverRequest.fromUserId === user.id;
  notification.warning({
    message: userIsRequestCreator ? lg.schichtabgabe_aufgehoben : lg.schichtabgabe_abgelehnt,
  });
};

export const acceptHandoverRecieve = (handoverRequest: IShiftHandOverRequest) => async (
  dispatch: DispFn,
  getState: () => AppState
) => {
  const state = getState();
  const user = selectSessionInfo(state).user;

  await dispatch(
    shiftHandOverRequestRepository.update({
      ...handoverRequest,
      approvedByUserId: user.id,
    })
  );

  notification.warning({ message: lg.schichtabgabe_akzeptiert });
};
