import React from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import TZModal from "../../../components/TZModal/TZModal";
import { DatePicker, Button, Icon, notification } from "antd";
import moment, { Moment } from "moment";
import { closeModal } from "../../../actions/modal";

import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import { userRepository } from "../../../repositories/userRepository";
import { IUser } from "../../../shared/entities/IUser";
import { SDateFormat } from "../../../shared/helpers/SimpleTime";
import { shiftRepository } from "../../../repositories/shiftRepository";
import { IFilterExpression } from "../../../shared/types/queryParams";
import { delteAllShiftRelations } from "../../../actions/shift";
import { addSimpleDays } from "../../../shared/helpers/dateHelpers";
import { shiftRepeatRepository } from "../../../repositories/shiftRepeatRepository";
import { getUserName } from "../../../shared/helpers/userHelpers";
import { deleteUserShifts } from "../../../actions/user";
import { uploadRepository } from "../../../repositories/uploadRepository";
import { removeUploadList } from "../../../actions/uploads";

const mapStateToProps = (state: AppState, props: OwnProps) => {
  return {};
};

type OwnProps = {
  user: IUser;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;
type State = { deleteConfirmed: boolean };

class _UserDeleteModal extends React.PureComponent<Props, State> {
  state = { deleteConfirmed: false };

  deleteUserClicked = async () => {
    const { dispatch, load, user } = this.props;

    if (!this.state.deleteConfirmed) {
      this.setState({ deleteConfirmed: true });
      return;
    }

    const _user = {
      ...user,
      email: `deleted-${moment().unix()}@aplano.de`,
      name: lg.gelöschter_benutzer,
      isDeleted: true,
    };

    const uploads = await dispatch(uploadRepository.fetchMany({ filter: ["userId", "=", user.id] }));
    uploads.length && (await dispatch(removeUploadList(uploads)));

    await load(Promise.all([dispatch(userRepository.update(_user)), dispatch(deleteUserShifts(user.id, true))]));
    notification.success({ message: lg.mitarbeiter_wurde_gelöscht });
    dispatch(closeModal());
    dispatch(closeModal());
  };

  render() {
    const { user, isLoading } = this.props;

    return (
      <>
        <TZModal style={{ width: "400px" }}>
          <TZModal.Head
            title={lg.mitarbeiter_löschen}
            icon="question-circle"
            iconColor="#FF9800"
            headStyle={{ border: "none" }}
          >
            <Icon type="check"></Icon>
          </TZModal.Head>
          <div className="userDeleteModalBody">
            <div className="delteRow">
              {!this.state.deleteConfirmed && (
                <div className="infoText">
                  {lg.user_löschen(<span style={{ fontWeight: "bold" }}>{getUserName(user)}</span>)}
                </div>
              )}
              {this.state.deleteConfirmed && (
                <div className="infoText">
                  {
                    lg.dadurch_werden_alle_daten_des_mitarbeiters_aus_dem_system_gelöscht_inklusive_vergangene_arbeitszeiten_und_abwesenheiten
                  }
                </div>
              )}
              <Button
                id="delete-user"
                type="danger"
                style={{ marginTop: 12 }}
                children={this.state.deleteConfirmed ? lg.mitarbeiter_löschen : lg.löschen}
                onClick={() => !isLoading() && this.deleteUserClicked()}
                loading={isLoading()}
              />
            </div>
          </div>
        </TZModal>
      </>
    );
  }
}

export const UserDeleteModal = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  busyInjector(_UserDeleteModal)
);
