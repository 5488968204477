import React, { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import loaderGifUrl from "./../loader.gif";
import clockLoaderWhiteUrl from "./../clockLoaderWhite.gif";
import { Icon, Popover } from "antd";
import _ from "lodash";
import moment from "moment";
import { toggleTimeClockingBreak, stopTimeClocking, startPunching, stopPunching } from "../../../actions/timeClocking";
import { BusyLoad } from "../../../components/BusyInjector/BusyInjector";
import { DispFn } from "../../../frontend-core/types/thunkTypes";
import { selectJobPositionMap, selectWorkSpaceMap, selectBranchMap } from "../../../selectors/mapSelectors";
import { ITimeClocking } from "../../../shared/entities/ITimeClocking";
import { IUser } from "../../../shared/entities/IUser";
import { generateUserTimeClockSettings } from "../../../shared/helpers/settingsHelpers";
import { calcBreakMinutes } from "../../../shared/helpers/timeClockingHelpers";
import { minutesToDuration, getDuration, toSimpleTime } from "../../../shared/helpers/timeHelpers";
import { TimeClockLogBox } from "../TimeClockLogBox/TimeClockLogBox";
import { useSelector } from "../../../helpers/redux";
import "./styles.scss";
import { PunchClockUser } from "../PunchClockUser/PunchClockUser";
import { PunchClockOptionsBar } from "../PunchClockOptionsBar/PunchClockOptionsBar";
import { decryptUser } from "../../../shared/helpers/userHelpers";
import cn from "classnames";
import { timeClockingRepository } from "../../../repositories/timeClockingRepository";
import { PunchClockBranchBar } from "./PunchClockBranchBar/PunchClockBranchBar";
import { featuresSelector } from "../../../selectors/FeaturesSelector";
import { paidFeatureWarning } from "../../../actions/paidFeatureWarning";

type Props = {
  activeClocking?: ITimeClocking;
  prevClocking?: ITimeClocking;
  clockableBranchIds: string[];
  load: BusyLoad;
  currentUser: IUser;
  deselectUser?: () => void;
  isWebMode?: boolean;
};

export const PunchClockUserContent = React.memo(
  ({ load, clockableBranchIds, activeClocking, currentUser, deselectUser, prevClocking, isWebMode }: Props) => {
    const dispatch = useDispatch<DispFn>();
    const features = useSelector(featuresSelector);

    const [preClocking, setPreClocking] = useState<Partial<ITimeClocking>>({ userId: currentUser.id }); // this obj ist used to populate data like workSpaceId or comment before there is an activeClocking

    const [wantsToFinishClocking, setWantsToFinishClocking] = useState(false);

    const branchMap = useSelector(selectBranchMap);
    const clockableBranchIdsOfUser = clockableBranchIds.filter((bid) => currentUser.branchIds.includes(bid));
    const clockableBranchesOfUser = clockableBranchIdsOfUser.map((bid) => branchMap[bid]);

    const timeClockModeBranchIds = useSelector((s) => s.ui.timeClockMode.branchIds);

    const _timeClockSettings = useSelector((state) => state.data.timeClockSettings[0]);
    const timeClockSettings = generateUserTimeClockSettings(_timeClockSettings, currentUser);
    const now = toSimpleTime(moment());

    const toggleBreak = async (activeClocking: ITimeClocking, type: "start" | "end") => {
      await dispatch(toggleTimeClockingBreak(activeClocking, type));
    };

    const branchId = timeClockModeBranchIds
      ? timeClockModeBranchIds.find((bId) => currentUser.branchIds.includes(bId))!
      : currentUser.branchIds.filter((bId) => !branchMap[bId].isDisabled)[0]!;

    const toggleTimeClocking = async () => {
      if (!features.timeClock) {
        dispatch(paidFeatureWarning());
        return;
      }

      if (activeClocking && !wantsToFinishClocking) {
        setWantsToFinishClocking(true);
        return;
      }

      console.log("preClocking::");
      console.log(preClocking);

      const punching = activeClocking
        ? await load(dispatch(stopPunching(activeClocking)))
        : await load(dispatch(startPunching({ userId: currentUser.id, branchId: branchId, ...preClocking })));

      setPreClocking({ userId: currentUser.id }); // clear out the preClocking state
      setWantsToFinishClocking(false);
      deselectUser && deselectUser();
    };

    const isCurrentlyInBreak = () =>
      activeClocking?.breakActivities?.length &&
      activeClocking?.breakActivities[activeClocking.breakActivities.length - 1].type === "start";

    const getCurrentClockingDuration = (clocking: ITimeClocking): string => {
      return minutesToDuration(
        getDuration({
          startTime: clocking.startTime,
          endTime: clocking.endTime || now,
          breakMinutes: clocking.breakActivities ? calcBreakMinutes(clocking.breakActivities!, now) : 0,
        }),
        { fillUpHourZeros: true }
      );
    };

    const isInBreak = isCurrentlyInBreak();
    const primaryButtonText = wantsToFinishClocking
      ? lg.wirklich_ausstempeln
      : activeClocking
      ? lg.ausstempeln
      : lg.starten;

    const mainDurationColor = isInBreak ? "orange" : activeClocking && !activeClocking?.endTime ? "#1a90FF" : "#000000";

    const breakButtonTimeDisplay = !activeClocking?.breakActivities
      ? "00:00"
      : minutesToDuration(calcBreakMinutes(activeClocking.breakActivities!, now), { fillUpHourZeros: true });

    const fullUser = decryptUser(currentUser);

    return (
      <div className="punchClockUserContent">
        <div>
          <div className="counterBoxWrapper">
            <div className="counterPunchBox">
              <div className="upperBar">
                <div className="left">
                  <div className="userName">{fullUser.name}</div>
                </div>
                <div className="right">
                  {!activeClocking && <div className="date">{moment().format("dddd DD.MM.YY")}</div>}
                  {activeClocking && <div className="startTime">{`${activeClocking.startTime} ${lg.gestartet}`}</div>}
                </div>
              </div>
              <div className="fb row counterPunchBoxRow">
                {!isInBreak && activeClocking && !activeClocking.endTime && (
                  <img
                    src={loaderGifUrl}
                    style={{
                      width: "45px",
                      height: "45px",
                      position: "absolute",
                      left: "25%",
                      marginLeft: "0px",
                      top: "31px",
                    }}
                  />
                )}
                <div className="counterPunchText" style={{ color: "white" }}>
                  {!!activeClocking ? getCurrentClockingDuration(activeClocking) : "00:00"}
                </div>
              </div>
              <PunchClockBranchBar
                selectableBranches={clockableBranchesOfUser}
                branchId={branchMap[activeClocking?.branchId || preClocking?.branchId || branchId].id}
                changeBranchId={(bid) => {
                  activeClocking
                    ? dispatch(timeClockingRepository.update({ ...activeClocking, branchId: bid }))
                    : setPreClocking({ ...preClocking, branchId: bid });
                }}
              />
            </div>

            {/* Log Row */}
            <TimeClockLogBox timeClocking={activeClocking} />

            {prevClocking && !activeClocking && (
              <div className="fb row prevClockingRow">
                <div className="label">{lg.zuletzt + ":"}</div>
                <div className="content">
                  <div>{prevClocking.startTime + " - " + prevClocking.endTime!}</div>
                </div>
              </div>
            )}

            {
              <PunchClockOptionsBar
                timeClocking={activeClocking}
                preClocking={preClocking}
                updateClocking={(c: Partial<ITimeClocking>) => {
                  activeClocking
                    ? dispatch(timeClockingRepository.update({ ...activeClocking, ...c }))
                    : setPreClocking({ ...preClocking, ...c });
                }}
              />
            }
          </div>
        </div>

        <div className="togglePunchingButtonWrapper">
          {/* Pause Button */}
          {timeClockSettings.breaksNeedToBeClocked && activeClocking && (
            <div className="columnbreakPunchButtonWrapper">
              <div
                className="breakPunchButton"
                onClick={() => toggleBreak(activeClocking!, isInBreak ? "end" : "start")}
              >
                <div className="fb row aCenter">
                  <Icon type={isInBreak ? "stop" : "pause"} style={{ marginTop: -1, fontSize: 19, marginLeft: 8 }} />
                  <div className="breakButtonTextV2">{isInBreak ? lg.pause_beenden : lg.pause_starten}</div>
                </div>
                <div className={"breakDurationTextV2"}>{breakButtonTimeDisplay}</div>
                {!!isInBreak && (
                  <img src={clockLoaderWhiteUrl} style={{ width: 30, height: 30, position: "absolute", right: 95 }} />
                )}
              </div>
            </div>
          )}
          <div
            className={cn({ togglePunchingButton: true, isClockedIn: activeClocking, isInBreak })}
            style={{ backgroundColor: wantsToFinishClocking ? "#ef0f12" : undefined }}
            onClick={toggleTimeClocking}
            children={
              <div className="fb row" id="user-own-time-clock-button">
                <Icon type="timer" style={{ color: "white", flex: 0 }} />
                <div className={"toggleClockButtonText"}>{primaryButtonText}</div>
              </div>
            }
          />
        </div>
      </div>
    );
  }
);
