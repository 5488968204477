/**
 * Format for simple dates
 */
export const SDateFormat = "YYYY-MM-DD";
export const STimeFormat = "HH:mm";
export const SDateTimeFormat = `${SDateFormat} ${STimeFormat}`;

export const simpleTimeRegex = /^\d{2}:\d{2}$/;
export const simpleDateRegex = /^\d{4}-\d{2}-\d{2}$/;
export const simpleDateTimeRegex = /^\d{2}:\d{2} \d{4}-\d{2}-\d{2}$/;

export const DisplayDateFormat = "DD. MMM YYYY";
