import { Input, Checkbox, Icon, InputNumber, Button } from "antd";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import AvColorPicker from "../../../../components/AvColorPicker/AvColorPicker";
import AvFormField from "../../../../components/AvFormField/AvFormField";
import { busyInjector, BusyInjectorProps } from "../../../../components/BusyInjector/BusyInjector";
import TZModal from "../../../../components/TZModal/TZModal";
import { strongColors, pastelColors } from "../../../../frontend-core/helpers/colors";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import { useSelector } from "../../../../helpers/redux";
import { BreakRule } from "../../../../shared/entities/IRosterSettings";
import { IShiftAddress } from "../../../../shared/entities/IShiftAddress";
import { LocationSearchInput } from "../../branches/branchPopup/LocationSearchInput";
import cn from "classnames";
import "./styles.scss";
import { rosterSettingsRepository } from "../../../../repositories/rosterSettingsRepository";
import { closeModal } from "../../../../actions/modal";
import { decimalSeparatorLocal } from "../../../../helpers/dateFormatHelper";

type Props = {
  hours?: number;
  onComplete: (hours: number) => void;
};

export const standardRestHours = 11;

export const MinRestHoursModal = busyInjector((props: Props & BusyInjectorProps) => {
  const dispatch = useDispatch<DispFn>();
  const [hours, setHours] = useState<number>();

  useEffect(() => {
    setHours(props.hours || standardRestHours);
  }, []);

  const saveClicked = () => {
    props.onComplete(hours as number);
    dispatch(closeModal());
  };

  return (
    <div>
      <TZModal>
        <TZModal.Head>{lg.gesetzliche_ruhezeit}</TZModal.Head>
        <TZModal.Body>
          <div className="breakRulesModalMain">
            <div className="label">{lg.mindestruhezeit_zwischen_zwei_schichten}</div>
            <div className="extraInfo">{lg.nach_beendigung_der_täglichen_arbeitszeit}</div>
            <div className="inputRow">
              <InputNumber
                size="small"
                style={{ marginRight: "8px", width: 85 }}
                decimalSeparator={decimalSeparatorLocal}
                onChange={setHours}
                value={hours}
                min={0}
                max={99000}
              />
              <div className="unit">{lg.stunden}</div>
            </div>
          </div>
        </TZModal.Body>
        <TZModal.Footer>
          <Button
            id="save-branch"
            type="primary"
            disabled={hours === undefined || (hours as any) === ""}
            onClick={saveClicked}
            children={lg.Speichern}
          />
        </TZModal.Footer>
      </TZModal>
    </div>
  );
});
