import { IResource } from "./IResource";

export enum HandoverType {
  group = "group",
  direct = "direct",
}

export type IShiftHandOverRequest = IResource & {
  toUserId?: string;
  branchId: string;
  fromUserId: string;
  date: string;
  jobPositionId: string;
  createdAt: string;
  approvedByUserId?: string;
  type: HandoverType;
  comment?: string;
};
