// dd12
import React, { useState, useCallback } from "react";
import { v4 as uuid } from "uuid";

export type load = <T>(p: Promise<T>, _s?: string) => Promise<T>;

export const useLoading = (): [(key?: string) => boolean, load, (value: boolean, key?: string) => void] => {
  const [loadingEntities, setLoadingEntities] = useState<{ [key: string]: boolean }>({});
  const isLoading = useCallback(
    (key?: string) => {
      return key ? !!loadingEntities[key] : !!Object.values(loadingEntities).find((x) => !!x);
    },
    [loadingEntities]
  );

  const setLoading = useCallback(
    (value: boolean, key: string = "_all") => {
      setLoadingEntities({
        ...loadingEntities,
        [key]: value,
      });
    },
    [loadingEntities]
  );

  const load = useCallback(async <T>(p: Promise<T>, _s?: string) => {
    const s = _s || uuid();
    let result: T;

    setLoadingEntities({
      ...loadingEntities,
      [s]: true,
    });

    try {
      result = await p;
    } catch (e) {
      setLoadingEntities({
        ...loadingEntities,
        [s]: false,
      });
      throw e;
    }

    setLoadingEntities({
      ...loadingEntities,
      [s]: false,
    });

    return result;
  }, []);

  return [isLoading, load, setLoading];
};
