import React, { HTMLAttributes, DetailedHTMLProps } from "react";
import "./styles.scss";
import cn from "classnames";
import { WeekDay, WeekDays, getWeekDayLabels } from "../../../../shared/entities/IContract";
import { getLocaleLang } from "../../../../helpers/dateFormatHelper";

type Props = {
  onChange: (value: Array<WeekDay>) => void;
  value: Array<WeekDay>;
  disabled?: boolean;
  mainStyle?: React.CSSProperties;
  dayStyle?: React.CSSProperties;
  alwaysActiveWeekDays?: Array<WeekDay>;
};

export default class WeekDayPicker extends React.PureComponent<Props, {}> {
  weekDayClicked = (w: WeekDay) => {
    if (this.props.alwaysActiveWeekDays && this.props.alwaysActiveWeekDays.includes(w)) {
      return;
    }
    const newValue = this.props.value.includes(w)
      ? this.props.value.filter((wd) => wd !== w)
      : [...this.props.value, w];
    this.props.onChange(newValue);
  };

  render() {
    const { value, mainStyle, dayStyle, disabled } = this.props;
    const lang = getLocaleLang();

    return (
      <div className={cn({ weekDayPickerMain: true, disabled })} style={mainStyle || {}}>
        {WeekDays.map((w, i) => (
          <div
            style={dayStyle || {}}
            key={w}
            onClick={() => {
              if (!this.props.disabled) {
                this.weekDayClicked(w);
              }
            }}
            className={cn({
              selected: value.includes(w),
              weekdayButton: true,
              disabled: this.props.disabled,
            })}
          >
            {getWeekDayLabels(lang)[w]}
          </div>
        ))}
      </div>
    );
  }
}
