export enum ReportEntryType {
  shift,
  absence,
  creditCorrection,
  holiday,
  overtime,
  quota,
  hourAccount,
  surcharge,
  wage,
}

export type ReportCol = { key: string; title: string; visible?: boolean };
export const getColumnKey = (type: ReportEntryType, subType?: string) => `${type}_${subType || ""}`;
