export const ThreadsListStyles: { [s: string]: React.CSSProperties } = {
  head: {
    flexDirection: "column",
    flex: "0 0 auto",
  },
  headFirstRow: {
    height: 60,
    background: "#ededed",
    alignItems: "center",
    padding: 6,
    justifyContent: "space-between",
  },
  headSecondRow: {
    minHeight: 50,
    padding: 6,
    alignItems: "center",
    justifyContent: "center",
    background: "#f6f6f6",
    boxShadow: "0 1px 1px rgba(0,0,0,0.10)",
    zIndex: 10,
  },
  content: {
    overflow: "auto",
    flex: "1 1 auto",
  },
  wrapper: {
    flexGrow: 1,
    width: 320,
    overflow: "hidden",
    background: "#ffffff",
    borderRight: "solid 1px #dcdcdc",
  },
  listItem: {
    padding: 12,
    borderBottom: "solid 1px #f2f2f2",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "space-between",
    height: 65,
  },
  unseenMessagesbubble: {
    width: 14,
    marginLeft: 6,
    height: 14,
    borderRadius: "100%",
    background: "#F5232D",
  },
  lastMessageDate: {
    opacity: 0.7,
    fontSize: 12,
  },
  profileBox: {
    width: 40,
    height: 40,
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    marginRight: 12,
    fontSize: 16,
    flexShrink: 0,
  },
};
