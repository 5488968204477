import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";
import { Modal, Button, Input, Select, Icon } from "antd";
import { rosterTemplateRepository } from "../../../../repositories/rosterTemplateRepository";
import { setRosterTemplateMode, onDeleteTemplateConfirm } from "../../../../actions/rosterTemplate";
import { MoreDropdown } from "../RosterActionBar/MoreDropdown";
import { busyInjector, BusyInjectorProps } from "../../../../components/BusyInjector/BusyInjector";
import { BasicSelect } from "../../../../components/BasicSelect/BasicSelect";
import { RosterTemplatePopup } from "../../../../components/RosterTemplatePopup/RosterTemplatePopup";
import { openModal } from "../../../../actions/modal";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { selectRosterTemplates } from "../../../../selectors/rosterTemplatesSelector";
import { selectBranch } from "../../../../actions/branchSelection";
import { selectVisibleBranches } from "../../../../selectors/VisibleBranchesSelector";
import { GroupByRoleButton } from "../RosterActionBar/GroupByRoleButton/GroupByRoleButton";
import { HideShiftRowsButton } from "../RosterActionBar/HideShiftRowsButton/HideShiftRowsButton";

const mapStateToProps = (state: AppState) => {
  const rosterTemplateMode = state.ui.shifts.roster.rosterTemplateMode;
  return {
    sessionInfo: selectSessionInfo(state),
    rosterTemplateMode,
    selectedBranchId: state.ui.selectedBranch,
    rosterTemplates: selectRosterTemplates(state),
    allRosterTemplates: state.data.rosterTemplates,
    branches: state.data.branches,
    visibleBranches: selectVisibleBranches(state),
  };
};

type State = {
  editRosterTemplateName: null | string;
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class RosterActionBarTemplateMode_ extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      editRosterTemplateName: null,
    };
  }

  deleteTemplateClicked = () => {
    Modal.confirm({
      title: lg.vorlage_löschen,
      content: lg.soll_die_vorlage_template_gelöscht_werden(this.props.rosterTemplateMode.rosterTemplate!.name!),
      onOk: async () => {
        const { shiftsStoredAtDate } = this.props.rosterTemplateMode.rosterTemplate!;
        const rosterTemplate = this.props.rosterTemplateMode.rosterTemplate!;
        this.props.dispatch(onDeleteTemplateConfirm(shiftsStoredAtDate, rosterTemplate));
      },
      okText: lg.löschen,
      cancelText: lg.abbrechen,
    });
  };

  render() {
    const { dispatch } = this.props;
    return (
      <div className="rosterTemplateActionBarMain">
        <div className="fb row aCenter templateActions">
          <span className="templateTitle fb row aCenter">
            <Button
              id="leave-roster-template-mode"
              style={{ marginRight: 18, width: 80, color: "grey" }}
              icon="arrow-left"
              onClick={() => dispatch(setRosterTemplateMode(false))}
            />
            <div className="templateHead">{lg.vorlage} </div>
            {this.props.branches.length > 1 && (
              <div className="noGrow noShrink" id="rosterTemplateBranchPicker">
                <Select
                  value={this.props.selectedBranchId || undefined}
                  style={{ marginLeft: -12, width: 180, display: "block" }}
                  onChange={(bId) => {
                    if (bId !== this.props.selectedBranchId) {
                      this.props.dispatch(selectBranch(bId));
                      this.props.dispatch(
                        setRosterTemplateMode(
                          true,
                          this.props.allRosterTemplates.find((r) => r.branchId === bId)
                        )
                      );
                    }
                  }}
                >
                  {this.props.visibleBranches.map((br) => (
                    <Select.Option value={br.id} key={br.id}>
                      <Icon type="environment" style={{ paddingRight: "6px" }} />
                      {br.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}
            {this.state.editRosterTemplateName === null ? (
              <>
                {!this.props.rosterTemplates.length && (
                  <Button
                    id="create-empty-roster-template"
                    style={{ marginLeft: 12, width: 230 }}
                    children={lg.leere_vorlage_erstellen}
                    onClick={() => this.props.dispatch(openModal(RosterTemplatePopup, {}))}
                  />
                )}
                {!!this.props.rosterTemplates.length && (
                  <BasicSelect
                    id="roster-select-template"
                    style={{
                      marginLeft: 6,
                      width: 230,
                      color: "#1a90ff",
                      fontWeight: "bold",
                    }}
                    options={this.props.rosterTemplates.map((rt) => ({
                      value: rt.id,
                      label: rt.name,
                    }))}
                    value={this.props.rosterTemplateMode?.rosterTemplate?.id}
                    onChange={(id: any) =>
                      this.props.dispatch(
                        setRosterTemplateMode(
                          true,
                          this.props.rosterTemplates.find((r) => r.id === id)
                        )
                      )
                    }
                  />
                )}
                <Button
                  id="edit-roster-template"
                  icon="edit"
                  disabled={!this.props.rosterTemplateMode?.rosterTemplate?.id}
                  className="noShrink"
                  style={{ marginLeft: 6 }}
                  data-rh={lg.umbenennen}
                  onClick={() =>
                    this.setState({
                      editRosterTemplateName: this.props.rosterTemplateMode.rosterTemplate!.name,
                    })
                  }
                />
                <Button
                  id="delete-roster-template"
                  icon="delete"
                  type="dashed"
                  disabled={!this.props.rosterTemplateMode?.rosterTemplate?.id}
                  className="noShrink"
                  data-rh={lg.vorlage_löschen}
                  style={{ marginLeft: 6, background: "none" }}
                  onClick={this.deleteTemplateClicked}
                />
              </>
            ) : (
              <>
                <Input
                  autoFocus
                  style={{ marginLeft: 12 }}
                  value={this.state.editRosterTemplateName}
                  onChange={(e) => this.setState({ editRosterTemplateName: e.target.value })}
                />
                <Button
                  id="save-roster-template-name"
                  children={lg.Speichern}
                  style={{ marginLeft: 6 }}
                  disabled={!this.state.editRosterTemplateName.length}
                  onClick={() => {
                    dispatch(
                      rosterTemplateRepository.update({
                        ...this.props.rosterTemplateMode.rosterTemplate!,
                        name: this.state.editRosterTemplateName as string,
                      })
                    );
                    this.setState({ editRosterTemplateName: null });
                  }}
                />
              </>
            )}
          </span>
        </div>
        <div className="noGrow fb noShrink row">
          <Button
            id="create-empty-roster-template"
            icon="plus"
            data-rh={lg.leere_vorlage_erstellen}
            className="noShrink"
            // style={{ marginRight: 6, marginLeft: 6 }}
            onClick={() => this.props.dispatch(openModal(RosterTemplatePopup, {}))}
          />
          <HideShiftRowsButton />
          <GroupByRoleButton />
          <MoreDropdown />
        </div>
      </div>
    );
  }
}

export const RosterActionBarTemplateMode = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(
  busyInjector(RosterActionBarTemplateMode_)
);
