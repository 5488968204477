import { environment } from "./../../env";
import * as Sentry from "@sentry/browser";
import firebase from "firebase/compat/app";
import { Dispatch } from "react";
import { AppState } from "../../types/AppState";
import { DispFn } from "../types/thunkTypes";
import { useSelector } from "react-redux";

export class HttpError extends Error {
  constructor(public message: string, public statusCode: number) {
    super(message);
  }
}

const send = (method: "POST" | "GET", url: string, body?: {}) => async (disp: DispFn, getState: () => AppState) => {
  const host = environment.getCloudFunctionsHost();
  const fullUrl = host + url;

  const authToken = await firebase.auth().currentUser?.getIdToken();

  return fetch(fullUrl, {
    method,
    body: body && JSON.stringify(body),
    mode: "cors",
    headers: {
      Authorization: authToken ? `Bearer ${authToken}` : "",
      "Content-Type": "application/json",
    },
  });
};

export const sendPost =
  (url: string, body: Object) =>
  async (disp: DispFn, getState: () => AppState): Promise<void | { data: any } | string> => {
    Sentry.addBreadcrumb({ message: "sendPost", data: { url, body } });

    const rosterSetting = getState().data.rosterSettings[0];
    const timezone = rosterSetting?.timezone || "Europe/Berlin";
    const language = rosterSetting?.language || "de";
    const data: any = { timezone, language, ...body };

    // SET isV2 if user is logged in and its tenant is a V2
    !!getState().data.tenantInfo.isV2 && (data.isV2 = true);

    const res = await disp(send("POST", url, { data }));
    if (!res.ok) {
      throw new HttpError(await res.text(), res.status);
    }

    const contentType = res.headers.get("content-type");
    if (contentType && contentType.includes("json")) {
      return await res.json();
    }

    return res.text();
  };
