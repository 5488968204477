import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Slot } from "./Slot/Slot";
import _ from "lodash";
import GridUserCell from "./GridUserCell/GridUserCell";
import { IUser } from "../../../../shared/entities/IUser";

import { generateSimpleWeekdays } from "../../../../shared/helpers/timeHelpers";

import cn from "classnames";

import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { otherShiftsOfRowSelector, shiftsOfRowSelector } from "../../../../selectors/RelevantShiftsSelector";
import { selectContractsByUser } from "../../../../selectors/contractsByUserSelector";

import { useSelector } from "../../../../helpers/redux";
import { selectShiftOverlappingsMap } from "../../../../selectors/shiftOverlappingsSelector";
import { selectCreditSpanForUser } from "../../../../selectors/creditSpansSelector";
import { featuresSelector } from "../../../../selectors/FeaturesSelector";
import { selectInitialCreditByUser } from "../../../../selectors/initialCreditsByUserSelector";
import { selectRosterDateRange } from "../../../../selectors/rosterDateRangeSelector";
import {
  selectAvailabilitiesByUserMap,
  selectBranchMap,
  selectChangeRequestMap,
  selectHandOverRequestMap,
  selectJobPositionMap,
  selectTimeClockingMap,
  selectUserMap,
  selectWorkSpaceMap,
  selectTrackingMap,
  selectShiftAddressMap,
  selectUserDetailMap,
} from "../../../../selectors/mapSelectors";
import { useDispatch } from "react-redux";
import { getValidContract } from "../../../../shared/helpers/credit";
import { WeekDays } from "../../../../shared/constants/WeekDays";
import { selectVisibleAbsencesByUser } from "../../../../selectors/absencesByUserSelector";
import { selectAbsenceTypeMap } from "../../../../selectors/absenceTypeMapSelector";
import { Icon } from "antd";
import { RosterRowCollapseButton } from "../components/RosterRowCollapseButton/RosterRowCollapseButton";
import { selectLabourLawWarning } from "../../../../selectors/laborLawWarningSelector";
import { monthlyMaxHoursWarningSelector } from "../../../../selectors/monthlyMaxHoursWarningSelector";
import { isRequiredShiftsCollapsedReducer } from "../../../../reducers/ui/shifts/roster/isRequiredShiftsCollapsed";
import { assignedShiftsSelector } from "../../../../selectors/assignedShiftsSelector";
import { OpenShiftsRowInfoIcon } from "./OpenShiftsRowInfoIcon/AddUserRow";
import { tierInfoSelector } from "../../../../selectors/TierInfoSelector";
import { canUserEditOwnShiftsOfDate, shouldPresentCreditFunction } from "../helpers";
import { push } from "connected-react-router";
import { shouldComponentUpdate } from "react-window";
import { RosterSettings } from "../../../settings/rosterSettings/RosterSettings";
import { selectRosterSettingsByUser } from "../../../../selectors/rosterSettingsByUserSelector";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import moment from "moment";
import { selectGroupByJobPosition } from "../../../../selectors/groupByJobPositionSelector";
import { selectTimeClockSettingsByUser } from "../../../../selectors/timeClockSettingsByUserSelector";
import { canEmployeAddEditPunchingOnDate } from "../../../../frontend-core/helpers/frontendHelpers";

type Props = {
  userId?: string;
  isRequirement: boolean;
  startDate: string;
  jobPositionId?: string;
  style?: any;
  today: string;
};

const _RosterWeekGridRow = (props: Props) => {
  const { jobPositionId, isRequirement } = props;
  const sessionInfo = useSelector(selectSessionInfo);
  const usersMap = useSelector(selectUserMap);
  const user = props.userId ? usersMap[props.userId] : undefined;
  const recentModifiedShifts = useSelector((s) => s.ui.shifts.roster.recentModifiedShifts, _.isEqual);
  const shiftsOfRow = useSelector((s) => shiftsOfRowSelector(s, jobPositionId, user?.id, isRequirement), _.isEqual);
  const otherShifts = useSelector((s) => otherShiftsOfRowSelector(s, props.jobPositionId, user?.id), _.isEqual);
  const dispatch = useDispatch();
  const rosterMode = useSelector((s) => s.ui.shifts.roster.rosterMode);
  const tenantInfo = useSelector((s) => s.data.tenantInfo);
  const absencesByUser = useSelector(selectVisibleAbsencesByUser);
  const absenceTypeMap = useSelector(selectAbsenceTypeMap);
  const selectedBranchId = useSelector((s) => s.ui.selectedBranch);
  const isOpenShiftsCollapsed = useSelector((s) => s.ui.shifts.roster.isOpenShiftsCollapsed);
  const isRequiredShiftsCollapsed = useSelector((s) => s.ui.shifts.roster.isRequiredShiftsCollapsed);
  const jobPosMap = useSelector(selectJobPositionMap);
  const jobPositions = useSelector((s) => s.data.jobPositions);
  const workSpaceMap = useSelector(selectWorkSpaceMap);
  const addressMap = useSelector(selectShiftAddressMap);
  const overlappingsMap = useSelector(selectShiftOverlappingsMap);
  const availabilitiesByUser = useSelector(selectAvailabilitiesByUserMap);
  const trackingMap = useSelector(selectTrackingMap);
  const timeClockingMap = useSelector(selectTimeClockingMap);
  const branchMap = useSelector(selectBranchMap);
  const handOverRequestMap = useSelector(selectHandOverRequestMap);
  const changeRequestMap = useSelector(selectChangeRequestMap);
  const contractsMap = useSelector(selectContractsByUser);
  const features = useSelector(featuresSelector);
  const initialUserCredits = useSelector(selectInitialCreditByUser);
  const userDetailMap = useSelector(selectUserDetailMap);
  const rosterSettings = useSelector((s) => s.data.rosterSettings[0]);
  const rosterSettingsByUser = useSelector(selectRosterSettingsByUser);
  const timeClockSettingsByUser = useSelector(selectTimeClockSettingsByUser);
  const rosterDateRange = useSelector(selectRosterDateRange);
  const isTemplateMode = useSelector((s) => s.ui.shifts.roster.rosterTemplateMode.active);
  const groupByJobPosition = useSelector(selectGroupByJobPosition);
  const userContracts = user && contractsMap[user.id];
  const labourLawWarningMap = useSelector(selectLabourLawWarning);
  const maxHoursExceedingMap = useSelector(monthlyMaxHoursWarningSelector);
  const assignedShiftsMap = useSelector(assignedShiftsSelector);
  const tierInfo = useSelector(tierInfoSelector);
  const canManage = sessionInfo.hasManagerPermissions();
  const showCredits = (user && canManage) || undefined;
  const validContract = showCredits && getValidContract(userContracts!, props.startDate);
  const creditSpan = useSelector((s) => showCredits && selectCreditSpanForUser(s, user!.id), _.isEqual);
  const userTypes = rosterSettings.userTypes || {};
  const userType = validContract?.userTypeId ? userTypes[validContract.userTypeId] : undefined;
  const isCollapsed = props.isRequirement ? isRequiredShiftsCollapsed : isOpenShiftsCollapsed;
  const isV2 = tenantInfo.isV2;
  const today = moment().format(SDateFormat);

  const doPresentCreditFunction = shouldPresentCreditFunction(sessionInfo, rosterSettings, tierInfo, jobPositions);

  const weekdays = useMemo(
    () =>
      generateSimpleWeekdays(props.startDate!, {
        noSaturday: rosterSettings.hideSaturdaysInWeekPlan,
        noSunday: rosterSettings.hideSundaysInWeekPlan,
      }),
    [props.startDate, rosterSettings]
  );

  return (
    <div style={props.style}>
      <div
        className={cn({
          gridRow: true,
          unassignedShiftsRow: !user && !props.isRequirement,
          requiredShiftsRow: props.isRequirement,
        })}
      >
        <div className="user cell firstColumn">
          {!user && (
            <div className="openShiftsCell">
              {props.isRequirement ? lg.personalbedarf : lg.offene_schichten}
              {props.isRequirement ? null : <OpenShiftsRowInfoIcon />}
              <RosterRowCollapseButton isRequirement={!!props.isRequirement} />
            </div>
          )}
          {user && (
            <GridUserCell
              user={user}
              key={user.id}
              startDate={props.startDate}
              rosterMode={rosterMode}
              creditSpan={creditSpan}
              contract={getValidContract(contractsMap[user.id], rosterDateRange.rangeStart)!}
              features={features}
              initialUserCredit={initialUserCredits[user.id]}
              rosterDateRange={rosterDateRange}
              isTemplateMode={isTemplateMode}
              customMonthStartDay={rosterSettings.customMonthIntervalStart || 1}
              jobPosMap={jobPosMap}
              canManage={sessionInfo.hasManagerPermissions()}
              showOvertime={!rosterSettings.hideOvertimeInRoster}
              staffNumber={rosterSettings.showStaffNumberInRoster ? userDetailMap[user.id]?.employNum : undefined}
              userType={rosterSettings.showUserTypeInRoster ? userType : undefined}
              maxHoursExceeding={maxHoursExceedingMap[user.id]}
              doPresentCreditFunction={doPresentCreditFunction}
              dispatch={dispatch}
              isV2={isV2}
            />
          )}
        </div>
        <div className="slots">
          {weekdays.map((date: string, i) => {
            const shiftOfDay = shiftsOfRow.filter((s) => s.date === date);
            const otherShiftsOfDay = otherShifts.filter((s) => s.date === date);
            const isOwnShift = user?.id === sessionInfo.user.id;

            const canEditOwnShifts =
              !canManage &&
              isOwnShift &&
              (isV2
                ? canEmployeAddEditPunchingOnDate(timeClockSettingsByUser[user!.id], date, today)
                : canUserEditOwnShiftsOfDate(rosterSettingsByUser[user!.id], date));

            return (
              <Slot
                userId={user?.id}
                jobPositionId={props.jobPositionId}
                isRequirement={props.isRequirement}
                date={date}
                shifts={shiftOfDay}
                otherShiftsOnSameDay={otherShiftsOfDay}
                recentModifiedShifts={recentModifiedShifts}
                key={date}
                dispatch={dispatch}
                absencesByUser={absencesByUser}
                absenceTypeMap={absenceTypeMap}
                availabilitiesByUser={availabilitiesByUser}
                handOverRequestMap={handOverRequestMap}
                changeRequestMap={changeRequestMap}
                branchMap={branchMap}
                addressMap={addressMap}
                trackingMap={trackingMap}
                timeClockingMap={timeClockingMap}
                sessionInfo={sessionInfo}
                selectedBranchId={selectedBranchId}
                groupByJobPosition={groupByJobPosition}
                rosterSettings={rosterSettingsByUser[sessionInfo.user.id]}
                jobPosMap={jobPosMap}
                workSpaceMap={workSpaceMap}
                usersMap={usersMap}
                overlapMap={overlappingsMap}
                labourLawWarningMap={labourLawWarningMap}
                contract={validContract}
                weekDay={WeekDays[i]}
                showQuota={rosterSettings.showQuotaInWeeklyRosterSlots}
                showOpenShiftsCount={!user && !props.isRequirement && isCollapsed}
                showRequirementCount={props.isRequirement && isCollapsed}
                assignedShiftsMap={assignedShiftsMap}
                canEditOwnShifts={canEditOwnShifts}
                isV2={isV2}
                isInFuture={date > props.today}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const RosterWeekGridRow = React.memo(_RosterWeekGridRow);
