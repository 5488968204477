import { selectAbsencesExtended } from "./absencesExtendedSelector";
import { getAbsenceDaysSumByUser } from "./absenceDaysSumSelector";
import { AppState } from "../types/AppState";
import { createSelector } from "reselect";
import { AbsenceStatus } from "../shared/entities/IAbsence";

const year = (state: AppState) => state.ui.absences.selectedYear;
const selectedTypeId = (state: AppState) => state.ui.absences.selectedTypeId;

export const absenceRequestDaysSumSelector = createSelector(
  [selectAbsencesExtended, year, selectedTypeId],
  (absences, year, _selectedTypeId) => {
    return getAbsenceDaysSumByUser(absences, year, AbsenceStatus.requested, _selectedTypeId);
  }
);
