import React from "react";
import { IShift } from "../../../shared/entities/IShift";
import cn from "classnames";
import "./styles.scss";

type Props = {
  shift: IShift;
};

export class OpenShiftIcon extends React.PureComponent<Props> {
  render() {
    const hasApplications = !!this.props.shift.appliedUserIds?.length;
    return (
      <div
        className={cn({
          requiredUsersBubble: true,
          hasApplications,
        })}
        data-rh={hasApplications ? lg.bewerber_offene_schichten : lg.offene_schichten}
      >
        {this.props.shift.appliedUserIds?.length
          ? `${this.props.shift.appliedUserIds?.length} / ${this.props.shift.requiredUsersAmount}`
          : this.props.shift.requiredUsersAmount}
      </div>
    );
  }
}
