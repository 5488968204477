import React from "react";
import { envi } from "../../env";
import WhiteZeitguruLogo from "./WhiteZeitguruLogo";
import WhiteAplanoLogo from "./WhiteAplanoLogo";

function WhiteZplanoLogo({ style = {} }) {
  return envi.isV2() ? <WhiteZeitguruLogo style={style} /> : <WhiteAplanoLogo style={style} />;
}

export default WhiteZplanoLogo;
