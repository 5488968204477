import React, { useEffect } from "react";
import cn from "classnames";
import "./styles.scss";
import { Badge, Icon } from "antd";
import { useSelector } from "../../../helpers/redux";
import { PushNoteTopic } from "../../../shared/entities/IPushNote";
import { selectUnseenPushNotes } from "../../../selectors/selectUnseenPushNotes";
import { selectUserThreadInfos } from "../../../selectors/userThreadInfoSelector";

type Props = {
  isIconActive: boolean;
};

export const TopBarChatIcon = React.memo(({ isIconActive }: Props) => {
  const userThreadInfos = useSelector(selectUserThreadInfos);
  const unreadUserThreadInfosCount = userThreadInfos.filter((t) => t.hasUnseenMessages).length;

  return (
    <div className="topbarIconWrapper">
      <div
        className={cn({ topbarIcon: true, fb: true, row: true, pushNotesIcon: true })}
        style={{ padding: 2, marginTop: 4 }}
      >
        <Badge count={unreadUserThreadInfosCount}>
          <Icon type="message" style={{ fontSize: 22 }} />
        </Badge>
      </div>
    </div>
  );
});
