import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { Menu, Dropdown, Icon } from "antd";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import "./styles.scss";
import { push } from "connected-react-router";
import { sitemap } from "../../../helpers/sitemap";
import { openModal } from "../../../actions/modal";
import { IntroVideoModal } from "../../modals/IntroVideoModal/IntroVideoModal";
import { authRepository } from "../../../repositories/authRepository";
import { StartTimeClockModePopup } from "../../StartTimeClockModePopup/StartTimeClockModePopup";
import { selectVisibleBranches } from "../../../selectors/VisibleBranchesSelector";
import { featuresSelector } from "../../../selectors/FeaturesSelector";
import { paidFeatureWarning } from "../../../actions/paidFeatureWarning";

const mapStateToProps = (state: AppState) => {
  return {
    session: state.data.auth.session,
    sessionInfo: selectSessionInfo(state),
    features: featuresSelector(state),
  };
};

type OwnProps = {};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _UserProfileDropdown extends React.PureComponent<Props> {
  routeTo = (route: string) => {
    this.props.dispatch(push(route));
  };

  logout = async () => {
    await this.props.dispatch(authRepository.logout());
  };

  render() {
    const canManage = this.props.sessionInfo.hasManagerPermissions();

    return (
      <Dropdown
        placement="bottomRight"
        trigger={["click"]}
        overlay={
          <Menu style={{ minWidth: 200 }}>
            <Menu.Item onClick={this.logout}>
              <Icon style={{ fontSize: 16, color: "#1a90ff" }} type="logout" />
              {lg.logout}
            </Menu.Item>
            <Menu.Item onClick={() => this.routeTo(sitemap.myProfile.url)}>
              <Icon style={{ fontSize: 16, color: "#1a90ff" }} type="user" />
              {lg.mein_profil}
            </Menu.Item>
            {canManage && (
              <Menu.Item onClick={() => this.routeTo(sitemap.help.url)}>
                <Icon style={{ fontSize: 16, color: "#1a90ff" }} type="question" />
                {lg.hilfe}
              </Menu.Item>
            )}
            {canManage && (
              <Menu.Item onClick={() => this.props.dispatch(openModal(StartTimeClockModePopup, {}))}>
                <Icon style={{ fontSize: 16, color: "#1a90ff" }} type="clock-circle" />
                {lg.stempeluhr_station}
              </Menu.Item>
            )}
          </Menu>
        }
      >
        <span className="userProfileButtonMain">
          <Icon type="user" style={{ fontSize: 20 }} />
        </span>
        {/* <div style={{ backgroundImage: `url(${UserImageSrc})` }} className="user" /> */}
      </Dropdown>
    );
  }
}

export const UserProfileDropdown = connect(mapStateToProps)(_UserProfileDropdown);
