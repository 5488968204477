// ddasd
import React from "react";
import * as Sentry from "@sentry/browser";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { Button, Upload, notification } from "antd";
import { defaultContract } from "../UserDetailsModal/UserGeneralTab/UserGeneralTab";
import { IUser, RoleType, IUserFull } from "../../../shared/entities/IUser";
import { parse, unparse } from "papaparse";
import TZModal from "../../../components/TZModal/TZModal";
import { useSelector } from "../../../helpers/redux";
import { closeModal } from "../../../actions/modal";
import { saveDataAs } from "../../../helpers/export";
import _ from "lodash";
import { createUser, getExistingEmails } from "../../../actions/user";
import cn from "classnames";
import "./styles.scss";
import { DispFn } from "../../../frontend-core/types/thunkTypes";
import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import "./styles.scss";
import { selectJobPositionMap, selectBranchMap } from "../../../selectors/mapSelectors";
import { selectUsersFull } from "../../../selectors/usersFullSelector";

type Props = {} & BusyInjectorProps;

export const ImportUsersModal = busyInjector((props: Props) => {
  const dispatch = useDispatch<DispFn>();

  const jobPositions = useSelector((state) => state.data.jobPositions);
  const branches = useSelector((state) => state.data.branches);
  const activeBranches = branches.filter((b) => !b.isInactive);
  const branchMap = useSelector(selectBranchMap);
  const jobPosMap = useSelector(selectJobPositionMap);
  const alreadyExistingUsers = useSelector(selectUsersFull);

  const [importedUsers, setImportedUsers] = useState<Partial<IUserFull>[]>([]);

  const isImportCorrect = () => {
    return (
      importedUsers.length && importedUsers.every((u) => u.name && u.branchIds?.length && u.jobPositionIds?.length)
    );
  };

  const createUsersClicked = async () => {
    if (props.isLoading()) {
      return;
    }

    props.setLoading("main", true);
    const emails = importedUsers.map((u) => u.email).filter((e) => !!e) as string[];
    const duplicateEmails = await dispatch(getExistingEmails(emails));

    if (duplicateEmails.length) {
      const _emails = duplicateEmails.join(", ");
      const message = lg.folgende_email_adressen_sind_bereits_angelegt_oder_doppelt_n + _emails;
      return notification.warning({ message });
    }

    for (const user of importedUsers) {
      await dispatch(createUser(user, defaultContract));
    }
    props.setLoading("main", false);
    dispatch(closeModal());
  };

  return (
    <TZModal className="importUsersModal">
      <TZModal.Head>{lg.mitarbeiter_importieren}</TZModal.Head>
      <TZModal.Body>
        {!importedUsers.length && (
          <div>
            <p>
              {
                lg.sie_können_mitarbeiter_importieren_indem_sie_eine_csv_datei_mit_den_folgenden_vier_spalten_erstellen_downloaden_sie_hierfür_auch_gerne_unsere_formatvorlage
              }
            </p>
            <ol>
              <li>
                <b>{lg.name}</b>
              </li>
              <li>
                <b>{lg.email}</b>
              </li>
              <li>
                <b>{lg.standort}</b>
                <span style={{ marginLeft: "1em" }}>
                  {/* {lg.erlaubte_werte}: {activeBranches.map((b) => b.name).join(", ")}.<br /> */}
                  {lg.mehrere_standorte_können_kommagetrennt_angegeben_werden}
                </span>
              </li>
              <li>
                <b>{lg.rolle}</b>
                <span style={{ marginLeft: "1em" }}>
                  {/* {lg.erlaubte_werte}: {jobPositions.map((jp) => jp.name).join(", ")}.<br /> */}
                  {lg.mehrere_rollen_können_kommagetrennt_angegeben_werden}
                </span>
              </li>
            </ol>
          </div>
        )}
        <div>
          {!!importedUsers.length && (
            <>
              {lg.bitte_prüfen_sie_ihren_import}
              <hr />
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>{lg.name}</th>
                    <th>{lg.email}</th>
                    <th>{lg.standorte}</th>
                    <th>{lg.rolle}</th>
                  </tr>
                </thead>
                <tbody>
                  {importedUsers.map((u) => {
                    const branchNames = (u.branchIds || []).map((id) => branchMap[id]["name"]).join(", ");
                    const jobPosNames = (u.jobPositionIds || []).map((id) => jobPosMap[id]["name"]).join(", ");
                    return (
                      <tr key={u.email}>
                        <td className={cn({ missing: !u.name })}>{u.name}</td>
                        <td>{u.email}</td>
                        <td className={cn({ missing: !branchNames })}>{branchNames}</td>
                        <td className={cn({ missing: !jobPosNames })}>{jobPosNames}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
      </TZModal.Body>
      <TZModal.Footer>
        {!importedUsers.length && (
          <Button
            id="downloade-csv-for-user-importing-btn"
            onClick={async () => {
              saveDataAs(
                unparse(
                  [...Array(30).keys()].map((i) => ({
                    [lg.name]: `${lg.mitarbeiter}${i}`,
                    ["Email"]: `${lg.mitarbeiter}${i}@demomail.com`,
                    [lg.standort]: _.sample(activeBranches)!.name,
                    [lg.rolle]: _.sample(jobPositions)!.name,
                  }))
                ),
                `${lg.mitarbeiter}-${lg.import}-${lg.vorlage}.csv`
              );
            }}
            icon="file-excel"
            type="link"
            children={`${lg.vorlage}.csv`}
          />
        )}
        <Upload
          showUploadList={false}
          accept=".csv"
          action={async (file) => {
            parse(file, {
              skipEmptyLines: true,
              complete: async (results) => {
                const rows = results.data as [string, string, string, string][];
                Sentry.addBreadcrumb({ data: { rows } });
                const users: Partial<IUserFull>[] = rows
                  .filter((r, i) => i !== 0 || r[0] !== lg.name) // filter out first row if its a title-row
                  .map((r) => ({
                    name: r[0],
                    email: r[1],
                    role: RoleType.employee,
                    branchIds: branches
                      .filter((b) =>
                        r[2]
                          .split(",")
                          .map((name) => name.trim().toLocaleLowerCase())
                          .includes(b.name.toLocaleLowerCase())
                      )
                      .map((b) => b.id),
                    jobPositionIds: jobPositions
                      .filter((jp) =>
                        r[3]
                          .split(",")
                          .map((name) => name.trim().toLocaleLowerCase())
                          .includes(jp.name.toLocaleLowerCase())
                      )
                      .map((b) => b.id),
                  }));

                const usersWithoutEmail = users.filter((u) => !u.email);
                const usersWithEmail = users.filter((u) => u.email);
                const uniqueUsersWithEmail = _.uniqBy(usersWithEmail, (u) => u.email).filter(
                  (u) => !alreadyExistingUsers.some((u2) => u.email && u2.email === u.email) // prevent conflicts with existing emails
                );

                setImportedUsers([...uniqueUsersWithEmail, ...usersWithoutEmail]);
              },
            });

            return "";
          }}
        >
          {!importedUsers.length && (
            <Button
              id="uploade-users-csv-button"
              style={{ marginLeft: "0.25em" }}
              children={`CSV ${lg.import}`}
              icon="cloud-upload"
              type="primary"
            />
          )}
        </Upload>
        {!!importedUsers.length && (
          <Button
            id="create-imported-users-btn"
            onClick={createUsersClicked}
            type="primary"
            icon={props.isLoading() ? "loading" : undefined}
            disabled={!isImportCorrect()}
            children={lg.jetzt_count_mitarbeiter_erstellen(importedUsers.length)}
          />
        )}
      </TZModal.Footer>
    </TZModal>
  );
});
