import { IRosterSettings } from "./../shared/entities/IRosterSettings";
import { UserInfo } from "./../shared/helpers/UserInfo";
import { selectSessionInfo } from "./SessionInfoSelector";
import { createSelector } from "reselect";

type ICan = {
  manageAbsences: boolean;
};

/**
 * Permissions if a ser CAN do something according to his Permissions and Settings
 */
export const selectCan = createSelector(
  [selectSessionInfo, (state) => state.data.rosterSettings[0]],
  (sessionInfo: UserInfo, rosterSettings: IRosterSettings): ICan => {
    const isAdmin = sessionInfo.isAdmin();
    const isManager = sessionInfo.isManager();

    return {
      manageAbsences: isAdmin || (isManager && rosterSettings.managersCanManageAbsences),
    };
  }
);
