import React, { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { shiftRepository } from "../../repositories/shiftRepository";
import { timeClockingRepository } from "../../repositories/timeClockingRepository";
import { stopTimeClocking, startTimeClocking, toggleTimeClockingBreak } from "../../actions/timeClocking";
import { ITimeClocking } from "../../shared/entities/ITimeClocking";
import { selectShiftMap } from "../../selectors/shiftMapSelector";
import { useSelector } from "../../helpers/redux";
import { featuresSelector } from "../../selectors/FeaturesSelector";
import { generateUserTimeClockSettings } from "../../shared/helpers/settingsHelpers";
import { IShift } from "../../shared/entities/IShift";
import { TimeClockShiftPicker } from "./TimeClockShiftPicker";
import { TimeClockLogBox } from "./TimeClockLogBox/TimeClockLogBox";
import { selectBranchMap, selectWorkSpaceMap, selectJobPositionMap } from "../../selectors/mapSelectors";
import { calcBreakMinutes } from "../../shared/helpers/timeClockingHelpers";
import { getDuration, toSimpleTime, minutesToDuration } from "../../shared/helpers/timeHelpers";
import moment from "moment";
import { IUser, IUserFull } from "../../shared/entities/IUser";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import _ from "lodash";
import { BusyLoad } from "../../components/BusyInjector/BusyInjector";
import { paidFeatureWarning } from "../../actions/paidFeatureWarning";
import { Icon } from "antd";
import { DropDownSelector } from "./DropDownSelector";
import { CommentEditButtonTimeClock } from "./CommentEditButtonTimeClock";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import loaderGifUrl from "./loader.gif";
import clockLoaderWhiteUrl from "./clockLoaderWhite.gif";

type Props = {
  selectedShiftOrClockingId?: string;
  setSelectedShiftOrClockingId: (id: string | undefined) => void;
  deselectShift: () => void;
  load: BusyLoad;
  currentUser: IUser;
  clockableBranchIds: string[];
  isFreeClockingAllowed: boolean | undefined;
  deselectUserId?: () => void;
};

export const TimeClockUserContent = React.memo(
  ({
    selectedShiftOrClockingId,
    setSelectedShiftOrClockingId,
    isFreeClockingAllowed,
    deselectShift,
    load,
    currentUser,
    deselectUserId,
    clockableBranchIds,
  }: Props) => {
    const dispatch = useDispatch<DispFn>();
    const features = useSelector(featuresSelector);
    const timeClockings = useSelector((state) => state.data.timeClockings);
    const [wantsToFinishClocking, setWantsToFinishClocking] = useState(false);
    const trackings = useSelector((state) => state.data.trackings);
    const shiftsMap = useSelector(selectShiftMap);
    const jobPosMap = useSelector(selectJobPositionMap);
    const workSpaceMap = useSelector(selectWorkSpaceMap);
    const branchMap = useSelector(selectBranchMap);
    const shift = selectedShiftOrClockingId ? shiftsMap[selectedShiftOrClockingId] : undefined;
    const clockableBranchIdsOfUser = clockableBranchIds.filter((bid) => currentUser.branchIds.includes(bid));
    const activeTimeClocking = selectedShiftOrClockingId
      ? timeClockings.find((t) => t.id === selectedShiftOrClockingId && !t.endTime)
      : timeClockings.find(
          (t) => !t.endTime && t.isDynamicClocking && t.userId === currentUser.id && !t.isAutoClockOut
        );
    const _timeClockSettings = useSelector((state) => state.data.timeClockSettings[0]);
    const timeClockSettings = generateUserTimeClockSettings(_timeClockSettings, currentUser);

    useEffect(() => {
      setWantsToFinishClocking(false);
      if (selectedShiftOrClockingId) {
        dispatch(shiftRepository.fetchOne(selectedShiftOrClockingId));
        dispatch(timeClockingRepository.fetchOne(selectedShiftOrClockingId));
      }
    }, []);

    const toggleBreak = async (activeTimeClocking: ITimeClocking, type: "start" | "end") => {
      await dispatch(toggleTimeClockingBreak(activeTimeClocking, type));
    };

    const toggleTimeClocking = async () => {
      if (!features.timeClock) {
        dispatch(paidFeatureWarning());
        return;
      }

      if (activeTimeClocking) {
        if (!wantsToFinishClocking) {
          setWantsToFinishClocking(true);
          return;
        }
        // Stop the time clock
        await load(dispatch(stopTimeClocking(activeTimeClocking)));
        deselectShift();
        deselectUserId && deselectUserId();

        setWantsToFinishClocking(false);
      } else {
        // Start the time clock
        await load(dispatch(startTimeClocking(shift!.id)));
        deselectShift();
        deselectUserId && deselectUserId();
      }
    };

    const isCurrentlyInBreak = () => {
      return (
        activeTimeClocking?.breakActivities?.length &&
        activeTimeClocking?.breakActivities[activeTimeClocking.breakActivities.length - 1].type === "start"
      );
    };

    if (shift && trackings.find((t) => t.id === shift.id && t.isAccepted)) {
      deselectShift();
    }

    const isInBreak = isCurrentlyInBreak();
    const finishedTimeClocking = shift && timeClockings.find((c) => c.id === shift.id && c.endTime);
    const isDynamicClocking = !!activeTimeClocking?.isDynamicClocking;

    const primaryButtonText = wantsToFinishClocking
      ? lg.wirklich_ausstempeln
      : activeTimeClocking
      ? lg.ausstempeln
      : lg.starten;

    const mainDurationColor = isInBreak
      ? "orange"
      : activeTimeClocking && !activeTimeClocking?.endTime
      ? "#1a90FF"
      : "#000000";

    const timeClocking = activeTimeClocking || finishedTimeClocking;

    const selectedShiftOrActiveClocking = shift || activeTimeClocking;
    return (
      <>
        {!selectedShiftOrClockingId && (
          <TimeClockShiftPicker
            setSelectedShiftOrClockingId={setSelectedShiftOrClockingId}
            currentUser={currentUser}
            clockableBranchIds={clockableBranchIdsOfUser}
            isFreeClockingAllowed={isFreeClockingAllowed}
          />
        )}
        {selectedShiftOrClockingId && selectedShiftOrActiveClocking && (
          <div
            style={{
              flexGrow: 1,
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                // alignItems: "center",
                // justifyContent: "flex-start",
                flexGrow: 1,
                flexShrink: 1,
                padding: 12,
                paddingBottom: 0,
                width: "100%",
              }}
            >
              <div style={{ flexShrink: 1, flexGrow: 1 }}>
                <div
                  className="fb row"
                  style={{ padding: 8, marginBottom: 4, justifyContent: "space-between", color: "white", fontSize: 18 }}
                >
                  {/* Shift Time */}
                  <div>
                    {shift && (
                      <>{`${selectedShiftOrActiveClocking.startTime} - ${shift.endTime} / ${shift.breakMinutes}`}</>
                    )}
                    {!shift && (
                      <>
                        {lg.start}: {selectedShiftOrActiveClocking.startTime}
                      </>
                    )}
                  </div>

                  {/* JobPosition */}
                  <DropDownSelector
                    disabled={!isDynamicClocking || currentUser.jobPositionIds.length === 1}
                    data={currentUser.jobPositionIds.map((jId) => ({
                      label: jobPosMap[jId].name,
                      key: jId,
                    }))}
                    onChange={(newOption) =>
                      dispatch(timeClockingRepository.update({ ...timeClocking!, jobPositionId: newOption.key }))
                    }
                  >
                    <div>
                      <div className={"clockingShiftJobPosText"} style={{ cursor: "pointer" }}>
                        {jobPosMap[selectedShiftOrActiveClocking.jobPositionId]?.name}
                      </div>
                    </div>
                  </DropDownSelector>
                </div>

                <div className={"counterBox"}>
                  <div
                    className="fb row"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: 7,

                      position: "relative",
                    }}
                  >
                    {!isInBreak && activeTimeClocking && !activeTimeClocking.endTime && (
                      <img
                        src={loaderGifUrl}
                        style={{ width: 43, height: 43, position: "absolute", left: "50%", marginLeft: -120, top: 16 }}
                      />
                    )}
                    <div
                      className="counterText"
                      style={{
                        color: mainDurationColor,
                      }}
                    >
                      {!!timeClocking
                        ? minutesToDuration(
                            getDuration({
                              startTime: timeClocking.startTime,
                              endTime: timeClocking.endTime || toSimpleTime(moment()),
                              breakMinutes: timeClocking.breakActivities
                                ? calcBreakMinutes(timeClocking.breakActivities!, toSimpleTime(moment()))
                                : 0,
                            }),
                            {
                              fillUpHourZeros: true,
                            }
                          )
                        : "00:00"}
                    </div>
                  </div>
                  <DropDownSelector
                    disabled={!isDynamicClocking || clockableBranchIdsOfUser.length === 1}
                    data={clockableBranchIdsOfUser.map((id) => ({
                      label: branchMap[id].name,
                      key: id,
                    }))}
                    onChange={(newOption) =>
                      dispatch(timeClockingRepository.update({ ...timeClocking!, branchId: newOption.key }))
                    }
                  >
                    <div className={"fb row counterPositionBoxWrapper"} style={{ cursor: "pointer" }}>
                      <Icon type="environment" style={{ marginRight: 8, color: "rgba(0,0,0,0.3)", fontSize: 18 }} />
                      <div className={"counterPositionText"}>
                        {branchMap[selectedShiftOrActiveClocking.branchId]?.name}
                      </div>
                    </div>
                  </DropDownSelector>
                </div>

                {/* Log Row */}
                <TimeClockLogBox timeClocking={activeTimeClocking || finishedTimeClocking} />

                {/*  Label & Comment Row */}
                <div className={"fb column subDetailsWrapper"}>
                  <div
                    className="fb row"
                    style={{ justifyContent: "space-between", alignItems: "center", marginBottom: 12 }}
                  >
                    <DropDownSelector
                      disabled={!isDynamicClocking || !_.values(workSpaceMap).length}
                      data={_.values(workSpaceMap).map((ws) => ({
                        label: ws.name,
                        key: ws.id,
                      }))}
                      onChange={(newOption) =>
                        dispatch(timeClockingRepository.update({ ...timeClocking!, workSpaceId: newOption.key }))
                      }
                    >
                      {selectedShiftOrActiveClocking.workSpaceId || isDynamicClocking ? (
                        <div
                          className="fb workSpaceBox"
                          style={{
                            cursor: "pointer",
                            backgroundColor: selectedShiftOrActiveClocking.workSpaceId
                              ? workSpaceMap[selectedShiftOrActiveClocking.workSpaceId]?.color
                              : "#ffffff",
                          }}
                        >
                          <div className={"workSpaceText"}>
                            {selectedShiftOrActiveClocking.workSpaceId
                              ? workSpaceMap[selectedShiftOrActiveClocking.workSpaceId]?.name
                              : lg.label_auswählen}
                          </div>
                        </div>
                      ) : (
                        <div />
                      )}
                    </DropDownSelector>
                    <CommentEditButtonTimeClock
                      disabled={!shift && !isDynamicClocking}
                      comment={shift?.comment || timeClocking?.comment || ""}
                      onSubmit={(comment) => {
                        shift
                          ? load(dispatch(shiftRepository.editComment(shift.id, comment)))
                          : load(dispatch(timeClockingRepository.update({ ...timeClocking!, comment })));
                      }}
                    />
                  </div>
                  {!!selectedShiftOrActiveClocking.comment && (
                    <div className={"fb column shiftCommentWrapper"}>
                      <div
                        style={{
                          color: "white",
                          maxHeight: 200,
                          overflow: "hidden",
                          flexShrink: 1,
                          textOverflow: "elipsis",
                        }}
                      >
                        {selectedShiftOrActiveClocking.comment}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Pause Button */}
              {(timeClockSettings.breaksNeedToBeClocked || isDynamicClocking) && !!activeTimeClocking && (
                <div className="fb column" style={{ flexShrink: 0, flexGrow: 0, width: "100%" }}>
                  <div
                    id={`TimeClock_Break_${isInBreak ? "end" : "start"}_${selectedShiftOrActiveClocking.id}`}
                    className="fb row breakButtonStyle"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    onClick={() => toggleBreak(activeTimeClocking!, isInBreak ? "end" : "start")}
                  >
                    <div className="fb row aCenter">
                      <Icon
                        type={isInBreak ? "stop" : "pause"}
                        style={{ marginTop: -1, color: "white", fontSize: 19, marginLeft: 8 }}
                      />
                      <div className={"breakButtonText"}>{isInBreak ? lg.pause_beenden : lg.pause_starten}</div>
                    </div>

                    <div className={"breakDurationText"}>
                      {!activeTimeClocking.breakActivities
                        ? "00:00"
                        : minutesToDuration(
                            calcBreakMinutes(activeTimeClocking.breakActivities!, toSimpleTime(moment())),
                            {
                              fillUpHourZeros: true,
                            }
                          )}
                    </div>
                    {!!isInBreak && (
                      <img
                        src={clockLoaderWhiteUrl}
                        style={{ width: 30, height: 30, position: "absolute", right: 95 }}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Start / Stop Button */}
            {!finishedTimeClocking && (
              <div
                className="fb column"
                style={{
                  padding: 12,
                }}
              >
                <div
                  className="fb row toggleClockingButton"
                  style={{
                    backgroundColor: wantsToFinishClocking ? "#ff4d4f" : undefined,
                  }}
                  onClick={toggleTimeClocking}
                  id={`TimeClock_${primaryButtonText}_${selectedShiftOrActiveClocking.startTime}_${selectedShiftOrActiveClocking.id}`}
                  children={
                    <div className="fb row" id="user-own-time-clock-button">
                      <Icon type="timer" style={{ color: "white", flex: 0 }} />
                      <div className={"toggleClockButtonText"}>{primaryButtonText}</div>
                    </div>
                  }
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  }
);
