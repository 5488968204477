import React from "react";
import AccessControl from "../AccessControl";
import { replace } from "connected-react-router";
import { sitemap, ISitemapEntry } from "../../helpers/sitemap";
import { Route, RouteProps } from "react-router";
import { connect } from "react-redux";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import _ from "lodash";
import { notification } from "antd";

type Props = RouteProps &
  DispatchBaseProps & {
    fallbackUrl?: string;
    route: ISitemapEntry;
  };

export const ACLRoute = connect(null)((props: Props) => {
  return (
    <Route
      {..._.omit(props, ["fallbackUrl", "hasRequiredRights", "path", "component"])}
      path={props.route.url}
      render={(routeProps) => (
        <>
          <AccessControl
            onUnauthorized={() => {
              notification.error({
                duration: 10000,
                message:
                  lg.sie_besitzen_nicht_die_notwnedigen_rechte_um_die_seite_zu_öffnen_aus_diesem_grund_wurden_sie_zum_dashboard_weitergeleitet,
              });
              props.dispatch(replace(props.fallbackUrl || sitemap.dashboard.url));
            }}
            hasRequiredRights={props.route.hasRequiredRights}
          >
            {props.component ? (
              <props.component {...routeProps} />
            ) : props.render ? (
              props.render(routeProps)
            ) : (
              props.children
            )}
          </AccessControl>
        </>
      )}
    />
  );
});
