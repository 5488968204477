import { selectSessionInfo } from "./SessionInfoSelector";
import { getEffectiveDaysByYear } from "./../shared/helpers/absences";
import { IAbsenceExtended } from "./../shared/entities/IAbsence";
import { selectContractsByUser } from "./contractsByUserSelector";

import { AppState } from "../types/AppState";
import { createSelector } from "reselect";
import _ from "lodash";
import { selectHolidayFinder } from "./holidayMapSelector";

export const absences = (state: AppState) => state.data.absences;
export const holidays = (state: AppState) => state.data.holidays;
export const contractMap = selectContractsByUser;

export const selectAbsencesExtended = createSelector(
  [absences, selectHolidayFinder, contractMap, selectSessionInfo],
  (absences, holidayFinder, contractMap, sessionInfo) => {
    if (!Object.keys(contractMap).length) {
      return []; // data not yet loaded
    }

    return absences.map((a) => {
      const calculateDays = sessionInfo.hasManagerPermissions() || sessionInfo.user.id === a.userId;
      // we dont calcualte the effectiveDays of other emplyees if the logged in user is an emplyee
      // because we dont have the contracts of the other users loaded to do this, and dont need to show them.
      return {
        ...a,
        effectiveDays:
          a.effectiveDaysOverride ||
          (calculateDays ? getEffectiveDaysByYear(a, holidayFinder, contractMap[a.userId]) : { 2020: 0 }),
      } as IAbsenceExtended;
    });
  }
);
