import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import "./styles.scss";
import { HourAccountsActionBar } from "./CreditsActionBar/CreditsActionBar";
import moment from "moment";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { CreditList, latestUserListInstance } from "./CreditsList/CreditsList";
import { CreditsUserBar } from "./CreditsUserBar/CreditsUserBar";
import { shiftRepository } from "../../../repositories/shiftRepository";
import { CreditsInfoCard } from "./CreditsInfoCard/CreditsInfoCard";
import { trackingRepository } from "../../../repositories/TrackingRepository";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import Page from "../../../components/Page/Page";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import _ from "lodash";
import { getActiveUsersAtDate } from "../../../selectors/ActiveUserSelectors";
import { SDateFormat } from "../../../shared/helpers/SimpleTime";
import { getBalances } from "../../../actions/creditActions/getBalances";
import { BalanceAction } from "../../../repositories/creditBalanceRepository";
import { getCreditIntervals } from "../../../actions/creditActions/getCreditIntervals";
import { CreditIntervalAction } from "../../../repositories/creditIntervalRepository";
import { featuresSelector } from "../../../selectors/FeaturesSelector";
import { selectCreditsMonthStartDay } from "../../../reducers/ui/general/creditsMonthStartDay";
import { CreditPageSelection, updateCreditsUISelection } from "../../../reducers/ui/shifts/credits";
import { toMoment } from "../../../shared/helpers/timeHelpers";
import { selectBranchMap } from "../../../selectors/mapSelectors";
import { timeClockingRepository } from "../../../repositories/timeClockingRepository";

const mapStateToProps = (state: AppState) => ({
  users: state.data.users,
  userInfo: selectSessionInfo(state),
  publishedWeeks: state.data.publishedWeeks,
  features: featuresSelector(state),
  customMonthStartDay: state.ui.general.creditsCustomMonthStartDay,
  selection: state.ui.shifts.credits,
  branchMap: selectBranchMap(state),
  isV2: state.data.tenantInfo.isV2,
});

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

const LOAD = {
  userList: "userList",
  singleUser: "singleUser",
};

class HourAccountsPageComp extends React.PureComponent<Props> {
  isEmployee = this.props.userInfo.isEmployee();
  isManager = this.props.userInfo.isManager();
  sessionUserId = this.props.userInfo.user.id;
  selectedUserId = this.props.selection.userId;

  async componentDidMount() {
    const selectedUserId = this.props.selection.userId;
    this.isEmployee && (await this.updateSelection({ userId: this.sessionUserId }));
    this.isManager && selectedUserId === "u1" && (await this.updateSelection({ userId: this.sessionUserId }));
    this.calculateAllUsersCredits();
    this.loadSelectedUserData();
  }

  componentWillUnmount() {
    const { selection, dispatch } = this.props;
    selection.showHourAccountInfo && dispatch(updateCreditsUISelection({ showHourAccountInfo: false }));
  }

  isSelectionPublished = () => {
    const { publishedWeeks, selection, userInfo, branchMap, isV2 } = this.props;
    const endDate = this.getEndDate();
    const mondayToCheck = selection.isMonthly
      ? toMoment(endDate).startOf("isoWeek").format(SDateFormat)
      : this.getStartDate();

    const allUserBranchesPublished =
      isV2 || // In V2 we have no publishing functionality > its all visible
      userInfo.user.branchIds
        .map((bid) => branchMap[bid])
        .filter((userBranch) => !userBranch.isDisabled)
        .every((userBranch) =>
          publishedWeeks.find((pw) => pw.branchId === userBranch.id && pw.startDate === mondayToCheck && pw.published)
        );

    return allUserBranchesPublished;
  };

  updateSelection = (selection: Partial<CreditPageSelection>): Promise<void> => {
    this.props.dispatch(updateCreditsUISelection(selection));
    return new Promise((resolve) => setTimeout(() => resolve()));
  };

  handleWeekChange = async (week: number, year: number) => {
    await this.updateSelection({ week, year });
    this.calculateAllUsersCredits();
    this.loadSelectedUserData();
  };

  handleMonthChange = async (month: number, year: number) => {
    await this.updateSelection({ month, year });
    this.calculateAllUsersCredits();
    this.loadSelectedUserData();
  };

  handleMonthStartDayChange = (month: number) => {
    this.props.dispatch(selectCreditsMonthStartDay(month));
    setTimeout(() => {
      this.calculateAllUsersCredits();
      this.loadSelectedUserData();
    });
  };

  updateUser = async (userId: string) => {
    await this.updateSelection({ userId });
    this.loadSelectedUserData();
  };

  getStartDate = () => {
    const { year, week, month, isMonthly } = this.props.selection;
    const { customMonthStartDay } = this.props;

    return (isMonthly
      ? moment().year(year).month(month).date(customMonthStartDay)
      : moment().isoWeekYear(year).isoWeek(week).startOf("isoWeek")
    ).format(SDateFormat);
  };

  getEndDate = () => {
    const { year, week, month, isMonthly } = this.props.selection;
    const { customMonthStartDay } = this.props;
    const startOfMonth = moment().year(year).month(month).date(customMonthStartDay);
    const daysInMonth = startOfMonth.daysInMonth();
    const endOfMonth = startOfMonth.add(daysInMonth - 1, "days");
    const endOfWeek = moment().isoWeekYear(year).isoWeek(week).endOf("isoWeek");
    return (isMonthly ? endOfMonth : endOfWeek).format(SDateFormat);
  };

  calculateAllUsersCredits = async () => {
    if (!this.props.userInfo.hasManagerPermissions()) {
      return;
    }

    setTimeout(() => {
      const userIds = this.props.users.map((u) => u.id);
      const balanceDate = this.props.isV2 ? this.getStartDate() : this.getEndDate();
      const balances = this.props.dispatch(getBalances(userIds, balanceDate, balanceDate));
      this.props.dispatch({ type: BalanceAction.SET, payload: balances });
    }, 5);
  };

  loadSelectedUserData = async () => {
    const { dispatch, load, isV2 } = this.props;
    const userId = this.props.selection.userId;
    const requestPromises: Promise<any>[] = [];
    const startDate = this.getStartDate();
    const endDate = this.getEndDate();
    const startDateComp = `${userId}_${startDate}`;
    const endDateComp = `${userId}_${endDate}`;
    const filter = ["userId_date", "between", [startDateComp, endDateComp]] as any;

    !isV2 && requestPromises.push(dispatch(shiftRepository.fetchMany({ filter })));
    !isV2 && requestPromises.push(dispatch(trackingRepository.fetchMany({ filter })));
    requestPromises.push(dispatch(timeClockingRepository.fetchMany({ filter })));

    await load(Promise.all(requestPromises), LOAD.singleUser);

    const balances = dispatch(getBalances([userId], startDate, endDate));
    const intervals = dispatch(getCreditIntervals([userId], startDate, endDate));
    dispatch({ type: BalanceAction.SET, payload: balances });
    dispatch({ type: CreditIntervalAction.SET, payload: intervals });
  };

  handlePeriodToggle = async () => {
    await this.updateSelection({
      isMonthly: !this.props.selection.isMonthly,
      month: moment().month(),
      week: moment().isoWeek(),
    });

    this.calculateAllUsersCredits();
    this.loadSelectedUserData();
  };

  render() {
    const { userId, week, month, year, isMonthly } = this.props.selection;
    const { userInfo, isV2 } = this.props;
    const activeUsers = getActiveUsersAtDate(this.props.users, this.getStartDate());
    const canManage = userInfo.hasManagerPermissions();

    if (!canManage && userId !== userInfo.user.id) {
      // This is for the initial milliseconds where a nonManager automatically picks its userId
      // to prevent the first render with the default userId 'u1' ( initial reducer value ).
      return null;
    }

    return (
      <Page>
        <div className="creditsMainPage">
          {canManage && (
            <div className="userBarWrapper">
              <CreditsUserBar
                userId={userId}
                userIds={activeUsers.map((u) => u.id)}
                selectUser={this.updateUser}
                endDate={this.getEndDate()}
                startDate={this.getStartDate()}
                isDataLoading={this.props.isLoading()}
              />
            </div>
          )}
          <div className="content">
            <div className="actionBarWarapper">
              <HourAccountsActionBar
                week={week}
                month={month}
                year={year}
                isMonthly={isMonthly}
                exportCsv={() => latestUserListInstance!.exportCsv()}
                exportExcel={() => latestUserListInstance!.exportExcel()}
                exportPdf={() => latestUserListInstance!.exportPdf()}
                onPeriodToggle={() => this.handlePeriodToggle()}
                onWeekChange={this.handleWeekChange}
                onMonthChange={this.handleMonthChange}
                onMonthStartDayChange={this.handleMonthStartDayChange}
                isDataLoading={this.props.isLoading()}
              />
            </div>

            <div className="infoCardWrapper">
              <CreditsInfoCard
                userId={userId}
                isMonthly={isMonthly}
                startDate={this.getStartDate()}
                endDate={this.getEndDate()}
                isDataLoading={this.props.isLoading()}
                isNotPublished={this.isEmployee && !this.isSelectionPublished()}
                isV2={isV2}
              />
            </div>

            <div className="creditListWrapper">
              <CreditList
                userId={userId}
                startDate={this.getStartDate()}
                endDate={this.getEndDate()}
                reloadCredits={this.loadSelectedUserData}
                isDataLoading={this.props.isLoading()}
                isNotPublished={this.isEmployee && !this.isSelectionPublished()}
              />
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export const HourAccountsPage = withErrorBoundary(
  connect<StoreProps, DispatchBaseProps, {}, AppState>(mapStateToProps)(busyInjector(HourAccountsPageComp))
);
