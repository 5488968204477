import { IShift } from "../entities/IShift";
import { addSimpleDays } from "./dateHelpers";
import { toSimpleDate, simpleDateToMoment } from "./timeHelpers";
import { v4 as uuid } from "uuid";
import { getWeekDayString } from "./weekDaysList";
import moment from "moment";
import { RepeatingShiftsMaxDays } from "../constants/repeatingShiftsMaxDays";
import { IShiftRepeat } from "../entities/IShiftRepeat";

export const generateRepeatingShifts = (_shift: IShift, shiftRepeat: IShiftRepeat, startFrom?: string): IShift[] => {
  const shift: IShift = { ..._shift, repeatId: shiftRepeat.id };
  const startDate = startFrom || shift.date;
  const endDate = shiftRepeat.endDate;

  // Max end date is today + 6 months
  let maxEndDate = toSimpleDate(moment().add(RepeatingShiftsMaxDays, "days"));
  let lastDateToGenerate = endDate && endDate < maxEndDate ? endDate : maxEndDate;

  const result: IShift[] = [];
  if (startDate > lastDateToGenerate) {
    return [];
  }

  let dateStep = startDate;
  do {
    const needToCreateShiftForDate = needToCreateRepeatingShiftForDate(shift, shiftRepeat, dateStep);

    if (needToCreateShiftForDate) {
      result.push(generateRepeatingShiftForDate(shift, dateStep));
    }

    dateStep = addSimpleDays(dateStep, 1);
  } while (dateStep <= lastDateToGenerate);

  return result;
};

const needToCreateRepeatingShiftForDate = (shift: IShift, shiftRepeat: IShiftRepeat, dateToCheck: string) => {
  if (!shiftRepeat.weekDays.includes(getWeekDayString(dateToCheck))) {
    return false;
  }

  if (shiftRepeat.repeatEvery === 1) {
    // early exit for this case, to boost performance
    return true;
  }

  const startOfWeekOfStartMoment = simpleDateToMoment(shiftRepeat.startDate).startOf("isoWeek");
  const startOfWeekOfDateToCheck = simpleDateToMoment(dateToCheck).startOf("isoWeek");

  const weeksDiff = startOfWeekOfStartMoment.diff(startOfWeekOfDateToCheck, "weeks");

  if (weeksDiff % shiftRepeat.repeatEvery !== 0) {
    return false;
  }

  return true;
};

const generateRepeatingShiftForDate = (shift: IShift, date: string) => {
  return {
    ...shift,
    date,
    id: uuid(),
    appliedUserIds: [],
  };
};
