import { IUserFull } from "./../shared/entities/IUser";
import { encryptUser } from "./../shared/helpers/userHelpers";
import { environment } from "./../env";
import { sendPost } from "./../frontend-core/actions/send";
import { AppState } from "./../types/AppState";
import { BaseRepository } from "../frontend-core/BaseRepository";

import { DispFn } from "../frontend-core/types/thunkTypes";
import { IUser } from "../shared/entities/IUser";
import { Analytics } from "../helpers/analytics";
import { selectSessionInfo } from "../selectors/SessionInfoSelector";
import { nullifyProps } from "../shared/helpers/firebaseHelpers";

class UserRepository extends BaseRepository<IUser, IUser> {
  constructor() {
    super("users");
  }

  toDbEntity(user: IUser): IUser {
    return nullifyProps(encryptUser(user as any));
  }

  toLocalEntity(user: IUser): IUser {
    return encryptUser(user as any);
  }

  editMyTimeClockId = (timeClockId: string) => async (dispatch: DispFn, getState: () => AppState) => {
    const tenantId = getState().data.auth.session!.tenantId;
    const userId = getState().data.auth.session!.userId;
    const ref = this.getBaseRef(tenantId);
    await ref.child(userId).child("timeClockId").set(timeClockId);
  };

  sendInvitationMail = (user: IUser) => async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const sessionInfo = selectSessionInfo(state);
    const invitedUsers = state.data.users.filter((u) => u.isInvited || u.accountId);
    if (invitedUsers.length === 4) {
      if (Analytics.hasBeenInitialized) {
        if (!!(window as any).fbq) {
          (window as any).fbq("track", "Lead", {
            external_id: state.data.auth.session?.tenantId,
          });
        }
        Analytics.tagManager.dataLayer({
          dataLayer: {
            event: "fiveUsersInvited",
          },
        });
      }
    }

    await dispatch(
      sendPost("/api/send-invitation", {
        toUserId: user.id,
        fromUserName: sessionInfo.user.name,
        host: `${"https://" + window.location.host}`,
      })
    );

    dispatch({
      type: this.actionTypes.update,
      payload: { ...user, isInvited: true },
    });
  };

  // used as a fallback to create a userAccount if the invitation-mail doesnt arrive
  createAccountRaw = (user: IUser, password: string) => async (dispatch: DispFn) => {
    await dispatch(sendPost("/api/create-account-raw", { user, password }));
  };

  batchUpdatBranches =
    (userBranchesMapper: { [userId: string]: string[] }) => async (dispatch: DispFn, getState: () => AppState) => {
      const tenantId = getState().data.auth.session!.tenantId;
      const ref = this.getBaseRef(tenantId);
      const updates = {};
      Object.entries(userBranchesMapper).forEach(([userId, branchIds]) => (updates[`${userId}/branchIds`] = branchIds));
      await ref.update(updates);
    };

  batchUpdatJobPositions =
    (userJobPosMap: { [userId: string]: string[] }) => async (dispatch: DispFn, getState: () => AppState) => {
      const tenantId = getState().data.auth.session!.tenantId;
      const ref = this.getBaseRef(tenantId);
      const updates = {};
      Object.entries(userJobPosMap).forEach(([userId, jobPosIds]) => (updates[`${userId}/jobPositionIds`] = jobPosIds));
      await ref.update(updates);
    };
}

export const userRepository = new UserRepository();
