import { IAbsence } from "../../../../../../shared/entities/IAbsence";
import { simpleDateToMoment, toMoment } from "../../../../../../shared/helpers/timeHelpers";
import moment from "moment";

const getDaysToFirstDay = (absence: IAbsence, yearStartDate: string): number => {
  const startsInPrevYear = absence.startDate < yearStartDate;

  let yearStartMom = toMoment(yearStartDate);
  let startDateMom = toMoment(absence.startDate);

  const daysToStartDate = startDateMom.diff(yearStartMom, "days"); // = number

  return startsInPrevYear ? 0 : daysToStartDate;
};

const getDaysToLastDay = (absence: IAbsence, yearStartDate: string, yearEndDate: string): number => {
  const endsInNextYear = absence.endDate > yearEndDate;

  let yearStartMom = toMoment(absence.endDate).startOf("year");
  let endDateMom = toMoment(absence.endDate);

  const daysToEndDate = endsInNextYear ? toMoment(yearEndDate).dayOfYear() : endDateMom.diff(yearStartMom, "days");

  return daysToEndDate;
};

export const getPosLeftYearly = (absence: IAbsence, yearStartDate: string): number => {
  const firstDay = getDaysToFirstDay(absence, yearStartDate);
  return firstDay;
};

export const getPosWidthYearly = (absence: IAbsence, yearStartDate: string, yearEndDate: string): number => {
  const firstDay = getDaysToFirstDay(absence, yearStartDate);
  const lastDay = getDaysToLastDay(absence, yearStartDate, yearEndDate);

  const startsInPrevYear = absence.startDate < yearStartDate;

  return startsInPrevYear ? lastDay : lastDay - firstDay;
};
