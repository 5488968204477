export type MouseOverAbsenceCell = {
  userId?: string,
  day?: number,
};

export const SET_MOUSE_OVER_ABSECE_CELL = "SET_MOUSE_OVER_ABSECE_CELL";

export const updateMouseOverAbsenceCell = (payload: MouseOverAbsenceCell) => ({
  type: SET_MOUSE_OVER_ABSECE_CELL,
  payload,
});

const initialState: MouseOverAbsenceCell = {

};

export const mouseOverAbsenceCellReducer = (state = initialState, a: any): MouseOverAbsenceCell =>
  a.type === SET_MOUSE_OVER_ABSECE_CELL ? { ...state, ...a.payload } : state;
