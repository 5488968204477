import React from "react";
import { FormikProps } from "formik";
import { Select, Form, Divider, Icon } from "antd";
import "./styles.scss";
import { SelectProps } from "antd/lib/select";
import AvFormField from "../AvFormField/AvFormField";

type Props = {
  formikProps: FormikProps<any>;
  options: Array<{
    value: any;
    label: string;
    content?: React.ReactNode;
    disabled?: boolean;
  }>;
  fieldName: string;
  noErrorMessage?: boolean; // to hide the error-message-div
  label?: string;
  isRequired?: boolean;
  onChangeCallback?: (x?: any) => void;
  additionalDropDownComponent?: React.ReactNode;
  extraBox?: React.ReactNode;
  preProcess?: (prev: any, next: any) => any;
} & SelectProps<any>;

export default class AvFormikSelect extends React.PureComponent<Props, {}> {
  render() {
    const {
      formikProps,
      label,
      noErrorMessage,
      fieldName,
      options,
      isRequired,
      onChangeCallback,
      extraBox,
      preProcess,
      ...otherProps
    } = this.props;
    const isTouched = formikProps.touched[fieldName];
    const error: string | undefined | false = isTouched && (formikProps.errors[fieldName] as any);

    return (
      <AvFormField label={label} errorMessage={!noErrorMessage && error} isRequired={isRequired} extraBox={extraBox}>
        <Form.Item style={{ marginBottom: 0 }} validateStatus={error ? "error" : undefined}>
          <Select
            value={formikProps.values[fieldName]}
            onChange={(value: string) => {
              const prevVal = formikProps.values[fieldName];
              const nextVal = preProcess ? preProcess(prevVal, value) : value;
              formikProps.setFieldValue(fieldName, nextVal);
              onChangeCallback && onChangeCallback(nextVal);
            }}
            filterOption={(input: any, option: any) =>
              option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onBlur={(e: any) => {
              formikProps.setFieldTouched(fieldName, true);
            }}
            style={{ width: "100%" }}
            {...otherProps}
            dropdownRender={
              this.props.additionalDropDownComponent
                ? (menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      {this.props.additionalDropDownComponent}
                    </div>
                  )
                : undefined
            }
          >
            {options.map((o) => (
              <Select.Option value={o.value} key={o.value} title={o.label} disabled={o.disabled}>
                {o.content || o.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </AvFormField>
    );
  }
}
