import { IResource } from "./IResource";

export type ITimeClocking = IResource & {
  startTime: string;
  endTime?: string;
  date: string; // this is the shift-date
  breakMinutes: number;
  branchId: string;
  userId: string;
  jobPositionId: string;
  shiftStartTime?: string;
  shiftEndTime?: string;
  shiftBreakMinutes?: number;
  breakActivities?: IBreakActivity[];
  isAutoClockOut?: boolean;
  isDynamicClocking?: boolean;
  //isV2?: boolean;
  workSpaceId?: string;
  comment?: string;
  isAccepted?: boolean;
  addressId?: string;
  hashtagIds?: string[];
  ///
  logS?: string; // M_u1_10290399 > DeviceType_UserId_Timestamp
  logE?: string; // M_u1_10290399 > DeviceType_UserId_Timestamp
  logB?: string; // M_u1_10290399 > DeviceType_UserId_Timestamp
};

export type ITimeClockingDB = ITimeClocking & {
  branchId_date: string;
  userId_date: string;
};

// Please note, that this is being saved inside a jsonB property
// of the ITimeClocking, without any id's
export type IBreakActivity = {
  time: string;
  type: "start" | "end";
};

export enum DeviceType {
  Manual = "M",
  App = "A",
  Terminal = "T",
  Browser = "B",
}
