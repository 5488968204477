import { StandardFilterExpression } from "../../shared/helpers/queryParams";
import { IResource } from "../../shared/entities/IResource";

const comparer = {
  "=": (a, b) => a === b,
  "<=": (a, b) => a <= b,
  ">=": (a, b) => a >= b,
  between: (a, [b, c]) => a >= b && a <= c,
};

export const excludeByFilterQuery = <T extends IResource>(store: T[], filter: StandardFilterExpression<T>) => {
  const field = filter.field as string;
  const { value, operator } = filter;
  const isCompoundField = field.includes("_");
  let field1 = field;
  let value1 = value;
  let field2: string;
  let value2: any;

  if (isCompoundField) {
    field1 = field.split("_")[0];
    value1 = operator === "between" ? [value[0].split("_")[0], value[1].split("_")[0]] : value.split("_")[0];
    field2 = field.split("_")[1];
    value2 = operator === "between" ? [value[0].split("_")[1], value[1].split("_")[1]] : value.split("_")[1];
  }

  return store.filter((e) => {
    return !(isCompoundField
      ? e[field1] === value1 && comparer[operator](e[field2], value2)
      : comparer[operator](e[field1], value1));
  });
};
