import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { message, Input, DatePicker, InputNumber, Button, Icon, notification } from "antd";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";
import { closeModal, openModal } from "../../../../actions/modal";
import { busyInjector, BusyInjectorProps } from "../../../../components/BusyInjector/BusyInjector";
import _ from "lodash";
import { IUserDetail } from "../../../../shared/entities/IUserDetail";
import { userDetailRepository } from "../../../../repositories/userDetailRepository";
import { v4 as uuid } from "uuid";
import TZModal from "../../../../components/TZModal/TZModal";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import BusyWrapper from "../../../../components/BusyWrapper/BusyWrapper";
import { IUserFull } from "../../../../shared/entities/IUser";
import { ProfilePictureEditor } from "../ProfilePictureEditor/ProfilePictureEditor";
import { toMoment, toMomentUnsafe, toSimpleDateUnsafe } from "../../../../shared/helpers/timeHelpers";
import { MasterDataFormFieldManager } from "../../../../components/MasterDataFormFieldManager/MasterDataFormFieldManager";
import { MasterDataFormField } from "../../../../shared/entities/IRosterSettings";
import { TypeOf } from "yup";

export const masterDataFields: { [key: string]: MasterDataFormField } = {
  adresse: { id: "adresse", label: lg.straße_hausnummer, type: "text" },
  postalCode: { id: "postalCode", label: lg.postleitzahl, type: "text" },
  city: { id: "city", label: lg.stadt, type: "text" },
  mobileNum: { id: "mobileNum", label: lg.mobilnummer, type: "text" },
  birthPlace: { id: "birthPlace", label: lg.geburtsort, type: "text" },
  birthDate: { id: "birthDate", label: lg.geburtstag, type: "date" },
  employNum: { id: "employNum", label: lg.personalnummer, type: "text" },
  contractStartDate: { id: "contractStartDate", label: lg.arbeitsantritt, type: "date" },
  probationEndDate: { id: "probationEndDate", label: lg.ende_probezeit, type: "date" },
  contractEndDate: { id: "contractEndDate", label: lg.vertragsende, type: "date" },
  additional: { id: "additional", label: lg.notiz, type: "text" },
};

export const removeEmptyStrings = (obj: any): IUserDetail => {
  const newObj = {} as any;
  Object.entries(obj).forEach(([key, val]) => {
    newObj[key as any] = val === "" ? undefined : val;
  });
  return newObj;
};

const mapStateToProps = (state: AppState) => {
  return {
    rosterSetting: state.data.rosterSettings[0],
  };
};

type State = Partial<IUserDetail>;

type OwnProps = {
  userId: string;
  user: IUserFull;
  isCreationMode: boolean;
  gotDirty: (isDirty?: boolean) => void;
  isFormDirty?: boolean;
  closeOnSave: boolean;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class _MasterDataTab extends React.PureComponent<Props, State> {
  isCreationMode = true;

  constructor(props: Props) {
    super(props);
    this.state = {
      id: this.props.userId,
    };
  }

  componentDidMount = async () => {
    const { userId, dispatch, load } = this.props;
    let userDetails = await load(dispatch(userDetailRepository.fetchOne(userId)), "main");
    if (userDetails) {
      this.isCreationMode = false;
      this.setState(userDetails);
    }
  };

  showUserSavedNotification = () => {
    message.success(lg.stammdaten_gespeichert);
  };

  saveData = async () => {
    this.props.setLoading("save", true);
    const { dispatch, closeOnSave } = this.props;

    const userDetail = removeEmptyStrings(this.state);

    this.isCreationMode
      ? await dispatch(userDetailRepository.create(userDetail))
      : await dispatch(userDetailRepository.update(userDetail));

    this.props.gotDirty(false);
    message.success(lg.stammdaten_gespeichert);
    this.props.setLoading("save", false);
    closeOnSave && dispatch(closeModal());
  };

  onChange = (key: keyof State, value: string | undefined, isCustomField?: boolean) => {
    this.props.gotDirty();
    isCustomField
      ? this.setState({ customFields: { ...(this.state.customFields || {}), [key]: value } })
      : this.setState({ [key]: value });
  };

  customFieldsBtnClicked = () => {
    const { dispatch, userId } = this.props;
    if (this.props.isFormDirty) {
      return notification.info({ message: lg.bitte_zunächst_daten_speichern });
    }
    dispatch(closeModal());
    setTimeout(() => dispatch(openModal(MasterDataFormFieldManager, { userId })), 100);
  };

  renderPushPinIcon = () => (
    <div className="extraIconWrapper" data-rh={lg.benutzerdefiniertes_extra_feld}>
      <Icon type="pushpin" />
    </div>
  );

  renderField = (field: MasterDataFormField) => {
    return field.type === "date"
      ? this.renderDateField(field.id, field.label)
      : this.renderInputField(field.id, field.label);
  };

  renderInputField = (key: any, label: string, isCustomField?: boolean) => (
    <div className="formWrapper">
      <div className="labelWrapper">
        {isCustomField && this.renderPushPinIcon()}
        {label}
      </div>
      <Input
        value={isCustomField ? this.state.customFields?.[key] : this.state[key]}
        type="text"
        onChange={(e) => this.onChange(key, e.target.value, isCustomField)}
        name={label}
      />
    </div>
  );

  renderDateField = (key: any, label: string, isCustomField?: boolean) => (
    <div className="formWrapper">
      <div className="labelWrapper">
        {isCustomField && this.renderPushPinIcon()}
        {label}
      </div>
      <DatePicker
        value={toMomentUnsafe(isCustomField ? this.state.customFields?.[key] : this.state[key])}
        format={"L"}
        onChange={(mom) => this.onChange(key, toSimpleDateUnsafe(mom), isCustomField)}
        allowClear={true}
      />
    </div>
  );

  getExtraFieldRows = (): MasterDataFormField[][] => {
    // returns a matrix of rows and cells > more easy to render like this
    const fields = _.orderBy(Object.values(this.props.rosterSetting.customMasterDataFields || {}), "id");
    return _.chunk(fields, 3);
  };

  render() {
    return (
      <div className="userDetailsTabMain">
        <BusyWrapper isBusy={this.props.isLoading("main")}>
          <TZModal.Body style={{ background: "#f9f9f9" }}>
            <div className="content">
              <div className="row">
                {this.renderField(masterDataFields.adresse)}
                {this.renderField(masterDataFields.postalCode)}
                {this.renderField(masterDataFields.city)}
              </div>
              <div className="row">
                {this.renderField(masterDataFields.mobileNum)}
                {this.renderField(masterDataFields.birthPlace)}
                {this.renderField(masterDataFields.birthDate)}
              </div>

              <div className="row">{this.renderField(masterDataFields.employNum)}</div>

              <div className="row">
                {this.renderField(masterDataFields.contractStartDate)}
                {this.renderField(masterDataFields.probationEndDate)}
                {this.renderField(masterDataFields.contractEndDate)}
              </div>

              <div className="row" style={{ marginBottom: 10 }}>
                <div className="lastFormWrapper" style={{ marginLeft: 0 }}>
                  <div className="labelWrapper">{masterDataFields.additional.label}</div>
                  <Input.TextArea
                    placeholder={lg.notiz}
                    onChange={(e) => this.onChange("additional", e.target.value)}
                    value={this.state.additional || undefined}
                    name={lg.notiz}
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </div>
              </div>
              {this.getExtraFieldRows().map((fields) => (
                <div className="row">
                  {fields.map((field) =>
                    field.type === "text"
                      ? this.renderInputField(field.id as any, field.label, true)
                      : this.renderDateField(field.id as any, field.label, true)
                  )}
                </div>
              ))}
            </div>
          </TZModal.Body>
        </BusyWrapper>

        <TZModal.Footer>
          <Button
            id="add-custom-master-data-field-button"
            type="default"
            onClick={this.customFieldsBtnClicked}
            icon="pushpin"
            data-rh={lg.weitere_stammdaten_felder_anlegen_bearbeiten}
            children={lg.extra_felder}
          />
          <Button
            id="save-user-contract"
            type="primary"
            onClick={this.saveData}
            loading={this.props.isLoading()}
            children={lg.Speichern}
          />
        </TZModal.Footer>
      </div>
    );
  }
}

export const MasterDataTab = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  busyInjector(_MasterDataTab)
);
