import React from "react";
import { useSelector } from "../../../../helpers/redux";
import { useDispatch } from "react-redux";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import { BasicSelect } from "../../../../components/BasicSelect/BasicSelect";
import { selectTime } from "../../../../actions/dayRosterTimeSpan";

export const TimeSpan = React.memo(() => {
  const dispatch = useDispatch<DispFn>();
  const dayRosterTimeSpan = useSelector((state) => state.ui.shifts.roster.dayRosterTimeSpan);

  const hoursInDay = Array.from(Array(25).keys());

  const getTimeFromNumber = (t: number) => `${t > 9 ? t : "0" + t}:00`;

  const startOptions = hoursInDay.map((h) => ({
    value: h,
    label: getTimeFromNumber(h),
  }));

  const endOptions = hoursInDay
    .filter((h) => h > dayRosterTimeSpan.dayStart)
    .map((h) => ({
      value: h,
      label: getTimeFromNumber(h),
    }));

  const changeDayTime = (key: "dayStart" | "dayEnd", _val: number) => {
    dispatch(
      selectTime({
        ...dayRosterTimeSpan,
        dayStart: key === "dayStart" ? _val : dayRosterTimeSpan.dayStart,
        dayEnd: key === "dayEnd" ? _val : _val >= dayRosterTimeSpan.dayEnd ? _val + 1 : dayRosterTimeSpan.dayEnd,
      })
    );
  };

  return (
    <div className="fb row">
      <div className="fb timeSelect">
        <BasicSelect
          id="startTime-select"
          value={getTimeFromNumber(dayRosterTimeSpan.dayStart)}
          onChange={(val) => changeDayTime("dayStart", val)}
          options={startOptions}
          style={{ width: 80 }}
          keepOrder
        />
      </div>
      <div className="fb timeSelect">
        <BasicSelect
          id="endTime-select"
          value={getTimeFromNumber(dayRosterTimeSpan.dayEnd)}
          onChange={(val) => changeDayTime("dayEnd", val)}
          options={endOptions}
          style={{ width: 80 }}
          keepOrder
        />
      </div>
    </div>
  );
});
