import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { getYearsArray, getMonthsArray, getNextYM, getPrevYM } from "./localHelpers";
import { AppState } from "../../../../types/AppState";
import "./styles.scss";
import { setSelectedYear, selectMonth, setSelectedAbsenceType, toggleCalendarView } from "../../../../actions/absence";
import HideAbsenceRowsButton from "./HideAbsenceRowsButton/HideAbsenceRowsButton";
import AbsenceRequestTracker from "./AbsenceRequestTracker/AbsenceRequestTracker";
import { LeftRightStepper } from "../../../../components/LeftRightStepper/LeftRightStepper";
import { BasicSelect } from "../../../../components/BasicSelect/BasicSelect";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { openModal } from "../../../../actions/modal";
import AbsenceModal from "../../../../components/modals/AbsenceModal/AbsenceModal";
import AbsenceEntitlementModal from "../../../../components/modals/AbsenceEntitlementModal/AbsenceEntitlementModal";
import { VacationBansTracker } from "./VacationBansTracker/VacationBansTracker";

const mapStateToProps = (state: AppState) => {
  return {
    selectedYear: state.ui.absences.selectedYear,
    selectedMonth: state.ui.absences.selectedMonth,
    sessionInfo: selectSessionInfo(state),
    isYearView: state.ui.absences.isYearView,
  };
};

type State = {};
type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class AbsencesActionBar extends PureComponent<Props, State> {
  changeYear = (year: number) => {
    this.props.dispatch(setSelectedYear(year));
  };

  changeMonth = (month: number) => {
    this.props.dispatch(selectMonth(month));
  };

  onChangeAbsenceType = (val: string) => {
    this.props.dispatch(setSelectedAbsenceType(val || null));
  };

  stepForward = () => {
    const nextYM = getNextYM(this.props.selectedYear, this.props.selectedMonth);

    this.props.selectedYear !== nextYM.year && this.changeYear(nextYM.year);
    this.props.isYearView && this.changeYear(this.props.selectedYear + 1);
    !this.props.isYearView && this.changeMonth(nextYM.month);
  };

  stepBack = () => {
    const prevYM = getPrevYM(this.props.selectedYear, this.props.selectedMonth);
    this.props.selectedYear !== prevYM.year && this.changeYear(prevYM.year);
    this.props.isYearView && this.changeYear(this.props.selectedYear - 1);
    !this.props.isYearView && this.changeMonth(prevYM.month);
  };

  requestVacationClicked = () => {
    const userId = this.props.sessionInfo.user.id;
    this.props.dispatch(openModal(AbsenceModal, { userId }));
  };

  toggleYearView = (isYearView: boolean) => {
    this.props.dispatch(toggleCalendarView(isYearView));
  };

  getViewOptions = () => [
    {
      value: "year",
      label: lg.jahresansicht,
    },
    {
      value: "month",
      label: lg.monatsansicht,
    },
  ];

  render() {
    const { selectedYear, selectedMonth, dispatch } = this.props;

    return (
      <div className="fb absenceActionBarMain">
        <div className="fb absenceActionBarContent">
          <div className="fb yearSelect">
            <BasicSelect
              id="absence-bar-year-select"
              value={selectedYear}
              onChange={this.changeYear}
              options={getYearsArray().map((y) => ({ value: y, label: y }))}
              keepOrder
            />
          </div>

          {!this.props.isYearView && (
            <div className="fb monthSelect">
              <BasicSelect
                id="absence-bar-month-select"
                value={selectedMonth}
                onChange={this.changeMonth}
                options={getMonthsArray().map((m, i) => ({ value: i, label: m }))}
                keepOrder
              />
            </div>
          )}

          <div className="monthStepper">
            <LeftRightStepper onLeft={this.stepBack} onRight={this.stepForward} />
          </div>

          <div className="hideAbsenceRows">
            <HideAbsenceRowsButton />
          </div>

          <div className="vacationButton">
            <AbsenceRequestTracker />
          </div>

          <div className="rightPart">
            <div className="vacationBanButton">
              <VacationBansTracker />
            </div>

            <div className="absenceViewSelector">
              <BasicSelect
                id="absence-bar-year-month-view"
                value={this.props.isYearView ? "year" : "month"}
                options={this.getViewOptions()}
                onChange={(val: "month" | "year") => this.toggleYearView(val === "year")}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(AbsencesActionBar);
