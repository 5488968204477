import { Modal } from "antd";
import React from "react";
import { ITenantInfo } from "../../shared/entities/ITenantInfo";
import moment from "moment";
import { IAccount } from "../../shared/entities/IAccount";
import { IAdminTenantData } from "../../shared/entities/IAdminData";

export interface IActionLog {
  entity: string;
  operation: string;
  userId: string;
}
export interface ILogMap {
  [logKey: string]: IActionLog;
}

const oneWeekAgo = moment().add(-7, "days");
const oneWeekAgoIso = oneWeekAgo.toISOString();
export const isActiveTenant = (t: IAdminTenantData) =>
  t.numberOfAccounts && t.numberOfAccounts > 2 && t.lastActivityLog && t.lastActivityLog.isoDate >= oneWeekAgoIso;

export const showAccounts = (props: { accounts: IAccount[] }) => {
  return Modal.info({
    title: `Tenant Accounts`,
    content: (
      <ul style={{ marginTop: "1em" }}>
        {props.accounts.map((a) => (
          <li>{`${a.name} (${a.email})`}</li>
        ))}
      </ul>
    ),
  });
};
