import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import TZModal from "../../TZModal/TZModal";
import { Button } from "antd";
import "./styles.scss";
import { ShiftPopupHead } from "../components/ShiftPopupHead/ShiftPopupHead";
import StartEndTimeInput from "../ShiftPopup/StartEndTimeInput/StartEndTimeInput";

import { moveShift } from "../../../actions/shift";
import { Input } from "antd";
import { closeModal, openModal } from "../../../actions/modal";

import { selectActiveUsersFull } from "../../../selectors/ActiveUserSelectors";
import { IShift } from "../../../shared/entities/IShift";
import { IUserFull } from "../../../shared/entities/IUser";
import { ShiftPopup } from "../ShiftPopup/ShiftPopup";

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  return {
    activeUsers: selectActiveUsersFull(state, ownProps.shift.date),
  };
};

type OwnProps = {
  shift: IShift;
};

type State = {
  loadingUserId?: string;
  userFilter: string;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _ReassignShiftPopup extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loadingUserId: undefined,
      userFilter: "",
    };
  }

  assignUserClicked = async (userId: string) => {
    const { shift, dispatch } = this.props;
    this.setState({ loadingUserId: userId });

    await dispatch(moveShift(shift.id, shift.date, userId, shift.jobPositionId, undefined));
    dispatch(closeModal());
  };

  goBack = () => {
    this.props.dispatch(closeModal());
    this.props.dispatch(openModal(ShiftPopup, { shift: this.props.shift as IShift }));
  };

  isUserVisible = (user: IUserFull) =>
    !this.state.userFilter || user.name.toLowerCase().includes(this.state.userFilter.toLowerCase());

  render() {
    // if shift is not there anymore / because last open position got assigned to user
    // exit here to prevent errors
    if (!this.props.shift) {
      return null;
    }

    const { shift } = this.props;
    const { startTime, endTime, breakMinutes, breakStartTime, branchId } = shift;

    return (
      <TZModal style={{ minWidth: 600 }} className="shiftReAssignmentPopupMain">
        <ShiftPopupHead shift={this.props.shift} />
        <StartEndTimeInput
          onChange={() => {}}
          startTime={startTime as string}
          endTime={endTime as string}
          breakMinutes={breakMinutes}
          withBorderBottom
          withBreakStartTime={!!breakStartTime}
          breakStartTime={breakStartTime}
          disabled={true}
        />
        <div className="content">
          <div className="discription">{lg.weise_diese_schicht_einem_anderen_mitarbeiter_zu}</div>
          <div className="userList">
            <div className="searchInputWrapper">
              <Input.Search
                placeholder={lg.mitarbeiter_suchen}
                onChange={(e) => this.setState({ userFilter: e.target.value })}
                style={{ width: 200 }}
              />
            </div>
            {this.props.activeUsers
              .filter(
                (user) =>
                  user.branchIds.includes(branchId) &&
                  user.jobPositionIds.includes(shift.jobPositionId) &&
                  this.isUserVisible(user) &&
                  user.id !== shift.userId
              )
              .map((user) => (
                <div className="fb row aCenter userItem" key={user.id}>
                  <span style={{ marginRight: "auto" }}>{user.name}</span>
                  <Button
                    id="reassign-shift"
                    type="primary"
                    size="small"
                    loading={this.state.loadingUserId === user.id}
                    onClick={() => this.assignUserClicked(user.id)}
                  >
                    {this.state.loadingUserId === user.id ? "" : lg.zuweisen}
                  </Button>
                </div>
              ))}
          </div>
        </div>
        <TZModal.Footer style={{ justifyContent: "flex-start" }}>
          <Button
            id="reassign-shifts-goback"
            type="default"
            icon="arrow-left"
            children={lg.zurück}
            onClick={this.goBack}
          />
        </TZModal.Footer>
      </TZModal>
    );
  }
}

export const ReassignShiftPopup = connect(mapStateToProps)(_ReassignShiftPopup);
