import { getWeekStartsInRange, getMonthStartsInRange } from "./dateHelpers";
import { IAbsence } from "./../entities/IAbsence";

import { CrudOperation, Operation } from "./firebaseHelpers";
import { Map } from "../types/general";

const getRawUpdates = (absence: IAbsence, doRemove = false) => {
  const updates = {} as any;
  const weeklyDates = getWeekStartsInRange(absence.startDate, absence.endDate);
  const monthlyDates = getMonthStartsInRange(absence.startDate, absence.endDate);
  weeklyDates.forEach((date) => {
    updates["absencesByWeek/" + date + "/" + absence.id] = doRemove ? null : absence;
  });
  monthlyDates.forEach((date) => {
    updates["absencesByMonth/" + date + "/" + absence.id] = doRemove ? null : absence;
  });
  return updates;
};

export const getAbsenceFanOutUpdates = (crudOperations: CrudOperation<IAbsence>[], absencesMap: Map<IAbsence>): {} => {
  let updates = {} as any;
  crudOperations.forEach((op) => {
    const absence = op.entity;
    const prevAbsence = absencesMap[absence.id];
    if (op.operation === Operation.create) {
      updates = { ...updates, ...getRawUpdates(absence) };
    }
    if (op.operation === Operation.remove) {
      updates = { ...updates, ...getRawUpdates(prevAbsence, true) };
    }
    if (op.operation === Operation.update) {
      updates = { ...updates, ...getRawUpdates(prevAbsence, true) };
      updates = { ...updates, ...getRawUpdates(absence) };
    }
  });
  return updates;
};
