import React from "react";
import moment from "moment";
import "./styles.scss";
import { SDateFormat } from "../../shared/helpers/SimpleTime";
import cn from "classnames";

type Props = {
  date: string;
  isGrey?: boolean;
  style?: any;
};

const PrettyDate = ({ isGrey, date, style }: Props) => {
  const _date = moment(date as string, SDateFormat);
  const day = _date.format("DD");
  const weekday = _date.format("dd");
  const month = _date.format("MMM").substring(0, 3);

  return (
    <div className="prettyDate" style={style || {}}>
      <div className={cn({ dayNum: true, isGrey })}>{day}</div>
      <div className="dayAndMonth">
        <div className="dayName">{weekday.toUpperCase()}</div>
        <div className={cn({ monthName: true, isGrey })}>{month.toUpperCase()}</div>
      </div>
    </div>
  );
};

export default PrettyDate;
