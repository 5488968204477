import { Button, Input, InputNumber, Popover } from "antd";
import React, { useEffect, useState } from "react";
import cn from "classnames";
import "./styles.scss";

type Props = {
  hourlyWage?: number;
  updateHourlyWage: (h?: number) => void;
};

export const HourlyWageEditor = React.memo((props: Props) => {
  const [isPopoverOpen, togglePopover] = useState<boolean>(false);
  const [wageInput, setWageInput] = useState<number | undefined>(props.hourlyWage || undefined);

  const save = () => {
    props.updateHourlyWage(wageInput);
    togglePopover(false);
  };

  const renderPopover = () => {
    return (
      <div className="wageEditorPopoverMain col fb">
        <div className="fb row"></div>
        <div className="fb row contentRow ">
          {isPopoverOpen && (
            <InputNumber
              autoFocus
              value={wageInput}
              onChange={(hours) => setWageInput(hours)}
              placeholder="0"
              onKeyDown={(e) => e.key === "Enter" && save()}
              decimalSeparator=","
            />
          )}
          <div className="unit">{"€ / " + lg.stunden}</div>
          <Button type="primary" children={lg.fertig} onClick={save} />
        </div>
      </div>
    );
  };

  return (
    <div className="wageEditorMain">
      <Popover
        content={renderPopover()}
        title={<div style={{ color: "#1a90ff" }}>{lg.stundenlohn}</div>}
        trigger="click"
        visible={isPopoverOpen}
        onVisibleChange={(isOpen: boolean) => {
          setWageInput(props.hourlyWage);
          togglePopover(isOpen);
        }}
        children={
          <div className={"minWrapper element " + cn({ editing: isPopoverOpen })}>
            {props.hourlyWage && <div className="value">{`${props.hourlyWage} € / Std`}</div>}
            {!props.hourlyWage && <div className="wageLabel">{lg.stundenlohn}</div>}
          </div>
        }
      />
    </div>
  );
});
