import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../types/AppState";
import "./styles.scss";
import { openModal } from "../../actions/modal";

import BusyWrapper from "../BusyWrapper/BusyWrapper";

import PrettyDate from "../PrettyDate/PrettyDate";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import { busyInjector, BusyInjectorProps } from "../BusyInjector/BusyInjector";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { IShiftHandOverRequest } from "../../shared/entities/IShiftHandOverRequest";
import { IShift } from "../../shared/entities/IShift";
import { ShiftPopup } from "../ShiftPopup/ShiftPopup/ShiftPopup";
import { selectShiftMap } from "../../selectors/shiftMapSelector";
import { getUserName } from "../../shared/helpers/userHelpers";
import { Icon } from "antd";

const mapStateToProps = (state: AppState) => {
  return {
    users: state.data.users,
    shiftMap: selectShiftMap(state),
    jobPositions: state.data.jobPositions,
    sessionInfo: selectSessionInfo(state),
    branches: state.data.branches,
  };
};

type OwnProps = {
  handOverRequest: IShiftHandOverRequest;
  shift: IShift;
  onOpenShiftPopup?: () => void;
  border?: string;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class _HandOverRequestListItem extends React.PureComponent<Props> {
  openShiftPopup = async () => {
    const shiftId = this.props.handOverRequest.id;
    const shift = this.props.shiftMap[shiftId] as IShift;
    this.props.onOpenShiftPopup && this.props.onOpenShiftPopup();
    this.props.dispatch(openModal(ShiftPopup, { shift }));
  };

  generateStatusText = () => {
    const request = this.props.handOverRequest;
    const approvedById = request.approvedByUserId;
    const approvedBy = approvedById && this.props.users.find((u) => u.id === approvedById);

    return (
      <div className="status">
        <div className="label">{approvedBy ? lg.angenommen_von : lg.mitarbeiter_bestätigung}</div>
        <div className={"statusVal " + (request.approvedByUserId ? "pending" : "open")}>
          {approvedBy ? getUserName(approvedBy) : lg.ausstehend}
          <div className="iconWrapper">
            {request.approvedByUserId ? <Icon type="check" /> : <Icon type="hourglass" />}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { handOverRequest, shift, jobPositions, users, border, branches, sessionInfo } = this.props;
    const jobPosition = jobPositions.find((jp) => jp.id === handOverRequest.jobPositionId);
    const toUser = users.find((u) => u.id === handOverRequest.toUserId);
    const branch = branches.find((b) => b.id === shift.branchId);
    const isMultiBranch = this.props.branches.filter((b) => !b.isInactive).length > 1;
    const canManage = sessionInfo.hasManagerPermissions();
    const { approvedByUserId } = handOverRequest;
    const showAsGrey = canManage && !approvedByUserId;

    // If the setting `shiftSwapNeedsManagerAceptance` is activated:
    // a request doesnt get deleted after approving. -> rather its propterty `approvedByUserId``
    // gets set. And a Manager needs to approve the request until the swap is made.
    // and the request finally gets deleted.

    const fromUser = this.props.users.find((u) => u.id === handOverRequest.fromUserId)!;
    const fromUserName = getUserName(fromUser);

    return (
      <BusyWrapper isBusy={this.props.isLoading()}>
        <div className="handOverRequest" key={handOverRequest.id} style={{ borderBottom: border }}>
          <div className="rowItem" onClick={this.openShiftPopup}>
            <div className="listItem dateCell">
              <PrettyDate date={handOverRequest.date} isGrey={showAsGrey} />
            </div>
            <div className="listItem timeCell" style={{ color: showAsGrey ? "grey" : "#1a90ff" }}>
              <div className="time">{`${shift.startTime} - ${shift.endTime}`}</div>
              {isMultiBranch && <div className="branch">{branch?.name}</div>}
            </div>
            <div className="namesCell" style={{ flexDirection: "column" }}>
              <div className="row jStart">
                <div className="label">{lg.von_start}</div>
                <div>{fromUserName}</div>
              </div>
              <div className="row jStart">
                <div className="label">{lg.an}</div>
                {handOverRequest.toUserId ? (
                  <div className="userName">{getUserName(toUser!)}</div>
                ) : (
                  <div
                    className="jobPositionName"
                    data-rh={lg.an_alle_mitarbeiter_mit_der_rolle(jobPosition?.name)}
                    style={{ background: jobPosition!.color }}
                  >
                    {jobPosition!.name}
                  </div>
                )}
              </div>
            </div>
            {this.generateStatusText()}
          </div>
        </div>
      </BusyWrapper>
    );
  }
}

export const HandOverRequestListItem = connect(mapStateToProps)(busyInjector(_HandOverRequestListItem));
