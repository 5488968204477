import { branchSelectionActionTypes } from "../../../actions/branchSelection";

export const selectedBranchReducer = (state = "", action: any): string | null => {
  switch (action.type) {
    case branchSelectionActionTypes.SELECT:
      return action.payload;
    default:
      return state;
  }
};
