import React from "react";
import { Icon } from "antd";
import { useDispatch } from "react-redux";
import "./styles.scss";
import { useSelector } from "../../../../../helpers/redux";
import { IJobPosition } from "../../../../../shared/entities/IJobPosition";
import _ from "lodash";
import { selectVisibleUsersOfRosterByJobPosition } from "../../../../../selectors/visibleUsersOfRosterSelector";
import { IUser } from "../../../../../shared/entities/IUser";
import { selectIsFreshTenant } from "../../../../../selectors/selectIsFreshTenant";
import { toggleJobPositionExpanded } from "../../../../../actions/collapsedJobPositions";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";
import { selectJobPositionMap } from "../../../../../selectors/mapSelectors";
import cn from "classnames";

type Props = {
  jobPositionId: string;
  isOpen: boolean;
  style: any;
};

export const RosterJobPositionLine = React.memo(({ jobPositionId, isOpen, style }: Props) => {
  const dispatch = useDispatch();
  const jobPosition = useSelector((s) => selectJobPositionMap(s)[jobPositionId]);
  const users = useSelector((s) => selectVisibleUsersOfRosterByJobPosition(s)[jobPosition.id]);
  const _isOpen = !users.length || isOpen;

  return (
    <div className="jobPositionRosterLineMain" style={{ display: "flex", ...style }}>
      <div
        className={cn({ isClosed: !_isOpen, notClickable: !users.length }) + " label cell firstColumn"}
        style={{ backgroundColor: _isOpen ? jobPosition.color : "initial" }}
        onClick={() => dispatch(toggleJobPositionExpanded(jobPosition.id))}
      >
        <div className="arrowWrapper">
          <Icon type={_isOpen ? "down" : "right"} style={{ paddingTop: "3px" }} />
        </div>
        <div className="jobPositionText">{jobPosition.name}</div>
      </div>
      {_isOpen && !users.length && (
        <div className="noUsersOfJobPositionHint">{lg.keine_mitarbeiter_mit_dieser_rolle}</div>
      )}
    </div>
  );
});
