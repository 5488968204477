import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../types/AppState";

import PrettyDate from "../PrettyDate/PrettyDate";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { IShift } from "../../shared/entities/IShift";
import { selectWorkSpaces } from "../../selectors/_workSpacesSelector";

const mapStateToProps = (state: AppState) => {
  return {
    users: state.data.users,
    jobPositions: state.data.jobPositions,
    branches: state.data.branches,
    currentUser: selectSessionInfo(state).user,
    workSpaces: selectWorkSpaces(state),
  };
};

type OwnProps = {
  key: string;
  shift: IShift;

  openModal: () => void;
  displayCheck?: boolean;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _MyUpcomingShiftItem extends React.PureComponent<Props> {
  render() {
    const { key, shift, jobPositions, branches, workSpaces } = this.props;
    const branch = branches.find((branch) => branch.id === shift.branchId)!;
    const workSpace = shift.workSpaceId ? workSpaces.find((ws) => ws.id === shift.workSpaceId)! : undefined;
    const isMultiBranch = this.props.branches.filter((b) => !b.isInactive).length > 1;

    return (
      <div className="openShiftItem" key={key} onClick={this.props.openModal}>
        <div className="openShiftDetails">
          <div className="dateCell">
            <PrettyDate date={shift.date} />
          </div>
          <div className="time">{`${shift.startTime} - ${shift.endTime}`}</div>
          <div className="branch">{isMultiBranch ? branch.name : workSpace?.name}</div>
          <div className="jobPos">{jobPositions.find((jP) => jP.id === shift.jobPositionId)?.name}</div>
        </div>
      </div>
    );
  }
}

export const MyUpcomingShiftItem = connect(mapStateToProps)(_MyUpcomingShiftItem);
