import { generateUserRosterSettings } from "../shared/helpers/settingsHelpers";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import { IUserRosterSettings } from "../shared/entities/IRosterSettings";
import { selectSessionInfo } from "./SessionInfoSelector";
import { relevantShiftsOfBranchSelector, relevantShiftsSelector } from "./RelevantShiftsSelector";
import _ from "lodash";
import { toDoubleDigit } from "../shared/helpers/timeHelpers";
import { IShift } from "../shared/entities/IShift";
import { selectAbsencesOfRosterByUser } from "./absencesByUserSelector";

const timesList = _.times(37 * 12, (i) => {
  const totalMins = i * 5;
  const hours = Math.floor(totalMins / 60);
  const minutes = totalMins % 60;
  return toDoubleDigit(hours) + ":" + toDoubleDigit(minutes);
  // we create an array like this: [ '00:0', '00:05', '00:15' ... '36:00' ]
  // we run over 24:00 beacause we use extended time-format for nightshifts adding up to 24:00
});

// shfits have same: label/branch/jobPosition/address
export const doesShiftMatch = (requirement: IShift, shift: IShift) =>
  requirement.jobPositionId === shift.jobPositionId &&
  requirement.branchId === shift.branchId &&
  (!requirement.workSpaceId || requirement.workSpaceId === shift.workSpaceId) &&
  (!requirement.addressId || requirement.addressId === shift.addressId);

export const getShiftWithDayExtendingTime = (s: IShift) =>
  s.endTime < s.startTime ? { ...s, endTime: getDayExtendingTime(s.endTime) } : s;

const getDayExtendingTime = (endTime: string) => {
  return 24 + parseInt(endTime.substr(0, 2)) + ":" + endTime.substr(3, 2);
  // adding numbers like 18:00 > 03:30 to 27:30
};

export const orderShifts = (shifts: IShift[]) =>
  _.orderBy(shifts, [
    "startTime",
    "endTime",
    "breakMinutes",
    "jobPositionId",
    (s) => s.userId || "",
    (s) => s.workSpaceId || "",
    (s) => s.id,
  ]);

export const assignedShiftsSelector = createSelector(
  [relevantShiftsSelector, selectAbsencesOfRosterByUser, (s) => s.data.rosterSettings[0]],
  (
    shifts,
    absencesByUser,
    rosterSettings
  ): {
    [shiftId: string]: number;
  } => {
    const map = {};
    const requiredShifts = shifts.filter((s) => s.isRequirement);

    if (!requiredShifts.length) {
      return {};
    }

    if (rosterSettings.requiremetsUseExactMatching) {
      const _map = assignedShiftsExactMapper(shifts, absencesByUser);
      console.log(_.mapValues(_map, (s) => s.length));
      return _.mapValues(_map, (s) => s.length);
    }

    const userShifts = shifts.filter(
      (s) => !!s.userId && !absencesByUser[s.userId]?.find((a) => a.startDate <= s.date && a.endDate >= s.date)
    );

    // turning a endTime of 03:30 to 27:30
    const extendedShifts = userShifts.map(getShiftWithDayExtendingTime);
    const requiredShiftsExtended = requiredShifts.map(getShiftWithDayExtendingTime);

    requiredShiftsExtended.forEach((requiredShift) => {
      const shfitsOfDay = extendedShifts.filter((s) => s.date === requiredShift.date && !s.isRequirement);
      let minCount = 0;

      const reqShiftTimesList = timesList.filter(
        (time) => time >= requiredShift.startTime && time <= requiredShift.endTime
      );

      reqShiftTimesList.forEach((time, iteration) => {
        const count = shfitsOfDay.filter(
          (s) =>
            s.startTime <= time && //
            s.endTime >= time && //
            doesShiftMatch(requiredShift, s) // has same: label/branch/jobPosition/address
        ).length;

        if (iteration === 0 || count < minCount) {
          minCount = count;
        }
      });

      map[requiredShift.id] = minCount;
    });

    return map;
  }
);

export const assignedShiftsExactMapper = (relevantShifts: IShift[], absencesByUser: {}): { [id: string]: IShift[] } => {
  const requirements = relevantShifts.filter((s) => s.isRequirement);
  const userShifts = relevantShifts.filter(
    (s) => !!s.userId && !absencesByUser[s.userId]?.find((a) => a.startDate <= s.date && a.endDate >= s.date)
  );

  const _shifts = orderShifts(userShifts);
  const _requirements = orderShifts(requirements);

  const map = {};
  const matchedShiftIds = {}; // if a shfit is already matched to a requirement, id is beaing memorized to not match it twice

  _requirements.forEach((req) => {
    map[req.id] = [];
    const shfitsOfDay = _shifts.filter((s) => s.date === req.date);
    const matchedShifts = shfitsOfDay
      .filter((s) => s.startTime === req.startTime && s.endTime === req.endTime && doesShiftMatch(req, s))
      .filter((s) => !matchedShiftIds[s.id])
      .slice(0, req.requiredUsersAmount || 1);

    matchedShifts.forEach((shift) => {
      matchedShiftIds[shift.id] = true;
      map[req.id].push(shift);
    });
  });

  return map;
};
