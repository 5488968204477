import { Dispatch } from "redux";

export const recentModifiedShiftsActionTypes = {
  ADD: "SHIFTS_ROSTER_RECENT_MODIFIED_SHITS_ADD",
  REMOVE: "SHIFTS_ROSTER_RECENT_MODIFIED_SHITS_REMOVE",
};

export const setRecentModifiedShifts = (shiftIds: string[]) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: recentModifiedShiftsActionTypes.ADD,
      payload: shiftIds,
    });
    setTimeout(
      () =>
        dispatch({
          type: recentModifiedShiftsActionTypes.REMOVE,
        }),
      1000
    );
  };
};
