import React from "react";
import { connect } from "react-redux";
import moment, { Moment } from "moment";
import { Button, Icon, Checkbox, Modal } from "antd";
import AbsenceTypeSelect from "./AbsenceTypeSelect";
import AbsenceNotesSection from "./absenceNotesSection";
import {
  absenceOverlaps,
  getAbsenceCreateLog,
  getAbsenceEditLog,
  getRemainingEntitlementDays,
  getViolatingVacationBan,
  isUploaded,
} from "./localHelpers";
import "./styles.scss";
import TZModal from "../../TZModal/TZModal";
import { closeModal } from "../../../actions/modal";
import { AppState } from "../../../types/AppState";
import { absenceRepository } from "../../../repositories/absenceRepository";
import TotalAbsenceDaysSection from "./TotalAbsenceDaysSection/TotalAbsenceDaysSection";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { Pusher } from "../../../actions/pushNote";
import { BusyInjectorProps, busyInjector } from "../../BusyInjector/BusyInjector";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { getAbsenceDaysSumByUser } from "../../../selectors/absenceDaysSumSelector";
import { AbsenceDateSelect } from "./AbsenceDateSelect/AbsenceDateSelect";
import { IAbsence, AbsenceStatus, AbsenceType } from "../../../shared/entities/IAbsence";
import { AbsenceTypeCode, IAbsenceType } from "../../../shared/entities/IAbsenceType";
import { toSimpleDate, getSimpleDateYear, SDateToDisplayDate } from "../../../shared/helpers/timeHelpers";
import { Raw } from "../../../shared/entities/IResource";
import _ from "lodash";
import * as Sentry from "@sentry/browser";
import { selectContractsByUser } from "../../../selectors/contractsByUserSelector";
import { getEffectiveDaysByYear } from "../../../shared/helpers/absences";
import { selectHolidayFinder } from "../../../selectors/holidayMapSelector";
import { selectAbsencesExtended } from "../../../selectors/absencesExtendedSelector";
import { getUserName } from "../../../shared/helpers/userHelpers";
import { openUpShiftsInAbsence, removeNoShowsOfPastShifts } from "../../../actions/absence";
import { AvUploader } from "../../AvUploader/AvUploader";
import { IUpload, IUploadBase } from "../../../shared/entities/IUpload";
import { uploadRepository } from "../../../repositories/uploadRepository";
import { v4 as uuid } from "uuid";
import { AbsenceUploadsSection } from "./AbsenceUploadsSection/AbsenceUploadsSection";
import { removeUploadList, uploadFile, uploadFileList } from "../../../actions/uploads";
import { RepoKey } from "../../../shared/types/RepoKeys";
import { selectUserMap } from "../../../selectors/mapSelectors";
import { isRuleApplyingToUser } from "../../../shared/helpers/settingsHelpers";
import { selectUserFullMap } from "../../../selectors/userFullMapSelector";
import { SDateTimeFormat } from "../../../shared/helpers/SimpleTime";
import { AbsenceStatusSelect } from "./AbsenceStatusSelect";
import { selectAbsenceTypeMap } from "../../../selectors/absenceTypeMapSelector";

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
  selectedYear: state.ui.absences.selectedYear,
  selectedMonth: state.ui.absences.selectedMonth,
  isYearView: state.ui.absences.isYearView,
  contractsMap: selectContractsByUser(state),
  userAbsences: state.data.absences.filter((wp) => wp.userId === ownProps.userId),
  absenceTypes: state.data.absenceTypes,
  absenceTypeMap: selectAbsenceTypeMap(state),
  users: state.data.users,
  usersMap: selectUserMap(state),
  sessionInfo: selectSessionInfo(state),
  holidays: state.data.holidays,
  holidayFinder: selectHolidayFinder(state),
  absenceEntitlements: state.data._absenceEntitlements,
  contingentCorrections: state.data._contingentCorrections,
  absencesExtended: selectAbsencesExtended(state),
  managersCanManageAbsences: state.data.rosterSettings[0].managersCanManageAbsences,
  openUpShiftsInAbsence: state.data.rosterSettings[0].openUpShiftsInAbsence,
  vacationBans: state.data.rosterSettings[0].vacationBans,
});

type State = {
  absence: Partial<IAbsence>;
  errorMessage?: string;
  showOverlapWarning: boolean;
  showDeleteConfirm: boolean;
  isModalClosing?: boolean;
  saveAsRequest?: boolean;
  uploads: Array<IUpload | IUploadBase>;
};

type OwnProps = {
  absence?: IAbsence;
  userId: string;
  startDate?: string;
  endDate?: string;
  typeId?: string;
  onUpdateComplete?: (absence?: IAbsence) => void;
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & BusyInjectorProps & DispatchBaseProps;

class AbsenceModal extends React.PureComponent<Props, State> {
  isCreationMode: boolean;
  canManageAbsences: boolean;
  isCreatingRequest: boolean;
  vacationType: IAbsenceType;
  filesMap: { [uploadId: string]: File } = {}; // used to hold the ref to the Files until they get uploaded

  constructor(props: Props) {
    super(props);

    const { absence, userId, sessionInfo, absenceTypes, managersCanManageAbsences, absenceTypeMap } = this.props;

    this.canManageAbsences = sessionInfo.isAdmin() || (sessionInfo.isManager() && managersCanManageAbsences);
    this.isCreationMode = !absence;
    this.isCreatingRequest = !absence && !this.canManageAbsences;
    this.vacationType = absenceTypes.find((a) => a.code === AbsenceTypeCode.vacation)!;

    const absenceType = this.isCreatingRequest
      ? absenceTypes.find((t) => t.canBeRequested)!
      : absenceTypes.find((t) => t.id === props.typeId);

    const initialAbsence: Partial<IAbsence> = {
      id: uuid(),
      userId: userId,
      typeId: absenceType?.id,
      typeCode: absenceType?.code,
      status: this.isCreatingRequest ? AbsenceStatus.requested : AbsenceStatus.active,
      earning: absenceType?.earning,
      startDate: props.startDate,
      endDate: props.endDate,
      requestedAt: this.isCreatingRequest ? moment().toISOString() : undefined,
    };

    this.state = {
      absence: absence || initialAbsence,
      uploads: [],
      showOverlapWarning: false,
      showDeleteConfirm: false,
      saveAsRequest: false,
    };
  }

  componentDidMount = async () => {
    console.log(this.props.absence);
    const { userId, absence, dispatch } = this.props;

    Sentry.addBreadcrumb({
      message: "opened absence modal",
      data: { absence, userId },
    });

    const uploads =
      absence && (await dispatch(uploadRepository.fetchMany({ filter: ["relatedEntityId", "=", absence.id] })));

    console.log(uploads);

    uploads?.length && this.setState({ uploads });
  };

  datesChanged = (startDateMom: Moment | undefined, endDateMom?: Moment) => {
    const startDate = startDateMom ? toSimpleDate(startDateMom.startOf("day")) : undefined;
    let endDate = endDateMom ? toSimpleDate(endDateMom.startOf("day")) : undefined;

    const isEndDateBeforeStartDate = startDate && endDate && endDate < startDate;
    isEndDateBeforeStartDate && (endDate = startDate);

    this.updateAbsence({
      startDate,
      endDate,
      effectiveDaysOverride: undefined,
    });

    setTimeout(() => {
      this.setState({ showOverlapWarning: this.overlapsWithOtherAbsence() });
    }, 200);
  };

  deleteClicked = async () => {
    return this.state.showDeleteConfirm ? this.delete() : this.setState({ showDeleteConfirm: true });
  };

  delete = async () => {
    if (this.actionsAreEnabled()) {
      const { dispatch } = this.props;
      const uploads = this.state.uploads.filter((u) => isUploaded(u)) as IUpload[];
      uploads.length && (await dispatch(removeUploadList(uploads)));
      await dispatch(absenceRepository.remove(this.state.absence.id!));
      this.closeModal();
    }
  };

  updateAbsence = (partialAbsence: Partial<IAbsence>) => {
    this.setState({ absence: { ...this.state.absence, ...partialAbsence } });
  };

  save = async (isAccepting: boolean) => {
    if (this.actionsAreEnabled()) {
      const { dispatch, absenceTypeMap } = this.props;
      const absence = { ...this.state.absence } as IAbsence;
      Sentry.addBreadcrumb({ message: "saved absence modal", data: { absence } });

      absence.createdLog = dispatch(getAbsenceCreateLog(absence));
      absence.editedLog = dispatch(getAbsenceEditLog(absence));

      if (this.state.saveAsRequest && absenceTypeMap[absence.typeId]?.canBeRequested) {
        absence.status = AbsenceStatus.requested;
      }

      if (isAccepting) {
        absence.status = AbsenceStatus.active;
        absence.acceptedAt = Date.now() / 1000;
        absence.acceptedBy = this.props.sessionInfo.user.id;
        dispatch(Pusher.absenceRequestAccepted(absence));
      }

      this.isCreationMode
        ? await dispatch(absenceRepository.create(absence))
        : await dispatch(absenceRepository.update(absence));

      this.isCreatingRequest && dispatch(Pusher.absenceRequestCreated(absence));

      this.getUnsavedUploades().length && (await this.uploadFiles());
      this.closeModal(absence);

      // we do the followig operations after closing the popup
      await dispatch(removeNoShowsOfPastShifts(absence, this.props.absence));
      dispatch(openUpShiftsInAbsence(absence));
    }
  };

  reject = async (): Promise<void> => {
    if (this.actionsAreEnabled()) {
      const { dispatch } = this.props;
      const { absence } = this.state;
      await dispatch(absenceRepository.remove(absence.id as string));
      dispatch(Pusher.absenceRequestRejected(absence as IAbsence));
      this.closeModal();
    }
  };

  actionsAreEnabled = () => {
    return !this.props.isLoading() && !this.state.isModalClosing;
  };

  closeModal = (freshAbsence?: IAbsence) => {
    this.props.onUpdateComplete && this.props.onUpdateComplete(freshAbsence);
    this.setState({ isModalClosing: true });
    this.props.dispatch(closeModal());
  };

  overlapsWithOtherAbsence = () => {
    const { startDate, endDate } = this.state.absence;
    return !!startDate && !!endDate && absenceOverlaps(this.state.absence, this.props.userAbsences);
  };

  onAbsenceTypeChange = (typeId: string) => {
    const absenceType = this.props.absenceTypes.find((t) => t.id === typeId)!;
    this.updateAbsence({
      typeId,
      typeCode: absenceType.code,
      earning: absenceType.earning,
    });
  };

  selectedFiles = (files: File[]) => {
    const uploadBases: IUploadBase[] = [];
    files.forEach((file) => {
      const id = uuid();
      this.filesMap[id] = file; // storing the files in a map to use them later on when saving the Modal.
      uploadBases.push({
        id,
        name: file.name,
        displayName: file.name,
        relatedRepoKey: "absences",
        relatedEntityId: this.state.absence.id!,
      });
    });
    this.setState({ uploads: [...this.state.uploads, ...uploadBases] });
  };

  getUnsavedUploades = (): IUploadBase[] => {
    return this.state.uploads.filter((u) => !isUploaded(u));
  };

  uploadFiles = async () => {
    try {
      const uploadeBases = this.getUnsavedUploades();
      const uploadJobs = uploadeBases.map((uploadBase) => ({
        file: this.filesMap[uploadBase.id]!,
        uploadBase: {
          id: uuid(),
          name: uploadBase.name,
          displayName: uploadBase.name,
          userId: this.state.absence.userId!,
          relatedRepoKey: "absences" as RepoKey,
          relatedEntityId: this.state.absence.id!,
        },
      }));
      await this.props.load(this.props.dispatch(uploadFileList(uploadJobs)), "upload");
    } catch (e) {
      console.error(e);
      this.props.setLoading("upload", false);
    }
  };

  render() {
    const { showOverlapWarning, absence, uploads, saveAsRequest } = this.state;
    const {
      absenceTypes,
      holidayFinder,
      users,
      selectedYear,
      selectedMonth,
      contractsMap,
      load,
      absencesExtended,
      isLoading,
      usersMap,
      vacationBans,
      absenceTypeMap,
    } = this.props;

    const {
      typeId,
      startDate,
      endDate,
      note,
      status,
      startsHalfDay,
      endsHalfDay,
      userId,
      typeCode,
      requestedAt,
      earning,
    } = absence;

    const isSingleDayAbsence = startDate && endDate && startDate === endDate;
    const canManage = this.canManageAbsences;
    const isAccepted = absence.status === AbsenceStatus.active;
    const isRequest = absence.status === AbsenceStatus.requested;
    const hasUnsavedUploads = this.state.uploads.find((u) => !isUploaded(u));

    const violatingBan = getViolatingVacationBan(usersMap[userId!], absence, vacationBans);

    const showBtn = {
      save:
        (canManage && isAccepted) ||
        (!canManage && isRequest && !this.isCreationMode) ||
        (hasUnsavedUploads && !this.isCreationMode),
      delete: (canManage && isAccepted && !this.isCreationMode) || (!canManage && isRequest && !this.isCreationMode),
      accept: canManage && isRequest,
      reject: canManage && isRequest,
      request: !canManage && isRequest && this.isCreationMode,
    };

    const isComplete = startDate && endDate && typeId && !showOverlapWarning;
    const user = users.find((u) => u.id === userId)!;

    const userContracts = contractsMap[this.props.userId];
    const startDateYear = startDate ? getSimpleDateYear(startDate) : this.props.selectedYear;
    const effectiveDays =
      (startDate && endDate && getEffectiveDaysByYear(absence, holidayFinder, userContracts)[startDateYear!]) ||
      undefined;

    const selectedAbsenceType = absenceTypes.find((t) => t.id === typeId);

    const _contingentCorrections = this.props.contingentCorrections
      .map((c) => ({ ...c, absenceTypeId: c.absenceTypeId || this.vacationType.id })) // backward-compatibility : absenceTypeId was added recently > default is vacationType
      .filter((c) => c.userId === userId && c.year === startDateYear && c.absenceTypeId === absence.typeId);

    const contingentCorrection = _contingentCorrections.find((c) => !c.isOverride);
    const contingentOverrideCorrection = _contingentCorrections.find((c) => c.isOverride);

    const absenceDaysByUser = getAbsenceDaysSumByUser(
      absencesExtended.filter((a) => a.id !== absence.id), // dont counting the current absence with
      startDateYear,
      AbsenceStatus.active,
      absence.typeId
    );
    const takenAbsenceDaysByUser = absenceDaysByUser?.[userId!] || 0;

    const entitlementsOfUser = this.props.absenceEntitlements.filter(
      (c) => c.userId === userId && (c.absenceTypeId || this.vacationType.id) === absence.typeId
    ); // absenceTypeId was added recently > default is vacationType

    const remainingEntitlement = getRemainingEntitlementDays(
      contingentCorrection,
      contingentOverrideCorrection,
      takenAbsenceDaysByUser,
      entitlementsOfUser,
      startDateYear
    );

    const isOversteppingEntitlement =
      remainingEntitlement !== undefined && effectiveDays && remainingEntitlement < effectiveDays;

    const acceptedByName = absence.acceptedBy && getUserName(usersMap[absence.acceptedBy]);
    const acceptedAt = absence.acceptedAt && moment.unix(absence.acceptedAt).format("L");

    const canAbsenceTypeBeRequested = typeId && absenceTypeMap[typeId]?.canBeRequested;

    const [createdByUserId, createdAt] = (absence.createdLog || "_").split("_");
    const [editedByUserId, editedAt] = (absence.editedLog || "_").split("_");
    const createdByUserName = createdByUserId && getUserName(usersMap[createdByUserId]);
    const editedByUserName = editedByUserId && getUserName(usersMap[editedByUserId]);
    const createdUnix = parseInt(createdAt);
    const editedUnix = parseInt(editedAt);

    return (
      <TZModal>
        <TZModal.Head>{`${getUserName(user)} | ${lg.abwesenheit}`}</TZModal.Head>
        <TZModal.Body style={{ minWidth: 400 }}>
          <div className="absenceModalMain">
            <div className="section standadWidth absenceTypeSection">
              <AbsenceTypeSelect
                selectedTypeId={typeId}
                selectType={(typeId: string) => this.onAbsenceTypeChange(typeId)}
                absenceTypes={absenceTypes}
                readOnly={(canManage && isRequest) || (!canManage && !isRequest)}
                isRequest={isRequest}
                canMangaAbsences={canManage}
              />
            </div>

            {canManage && this.isCreationMode && typeId && canAbsenceTypeBeRequested && (
              <div className="section statusSection">
                <AbsenceStatusSelect
                  selectedStatus={saveAsRequest ? AbsenceStatus.requested : AbsenceStatus.active}
                  selectStatus={(status: AbsenceStatus) => {
                    this.setState({ saveAsRequest: status === AbsenceStatus.requested });
                  }}
                />
              </div>
            )}

            <div className="section standadWidth dateRangeSection">
              {/* <div className="label">Zeitraum</div> */}

              <AbsenceDateSelect
                startDate={this.state.absence?.startDate}
                endDate={this.state.absence?.endDate}
                selectedYear={selectedYear}
                selectedMonth={selectedMonth}
                onDatesChange={(a, b) => this.datesChanged(a, b)}
                readOnly={(canManage && isRequest) || (!canManage && status === AbsenceStatus.active)}
                isYearView={this.props.isYearView}
              />
              {((startDate && endDate && selectedAbsenceType?.allowHalfDay) || startsHalfDay || endsHalfDay) && (
                <div className="halfDayCheckBoxesWrapper">
                  <Checkbox
                    checked={startsHalfDay}
                    onChange={(e) => this.updateAbsence({ startsHalfDay: e.target.checked })}
                  >
                    {isSingleDayAbsence ? lg.halbtags : lg.startet_halbtags}
                  </Checkbox>
                  {!isSingleDayAbsence && (
                    <Checkbox
                      checked={endsHalfDay}
                      onChange={(e) => this.updateAbsence({ endsHalfDay: e.target.checked })}
                    >
                      {lg.endet_halbtags}
                    </Checkbox>
                  )}
                </div>
              )}
              {isOversteppingEntitlement && (
                <div className="remainingVacDaysWrapper">
                  {lg.anspruch_wird_überschritten_um_x_tage(Math.abs(remainingEntitlement! - effectiveDays!))}
                </div>
              )}
              {violatingBan && (
                <div className="remainingVacDaysWrapper">
                  {lg.es_gibt_eine_urlaubssperre_vom_bis(
                    SDateToDisplayDate(violatingBan.from),
                    SDateToDisplayDate(violatingBan.until)
                  )}
                </div>
              )}
            </div>
            {showOverlapWarning && (
              <div className="overlapWarning">{lg.zeitraum_überschneidet_sich_mit_einer_weiteren_abwesenheit}</div>
            )}
            {!!startDate && !!endDate && typeId && !showOverlapWarning && (
              <TotalAbsenceDaysSection
                absence={this.state.absence as IAbsence}
                contracts={userId ? contractsMap[userId] : []}
                holidays={[]}
                updateAbsence={this.updateAbsence}
                earning={earning!}
              />
            )}
            <div className="section standadWidth commentSection">
              {/* If its read only: show the Label */}
              {!canManage && !isRequest && <div className="label">{lg.kommentar}</div>}
              <div style={{ width: 364 }}>
                <AbsenceNotesSection
                  note={note}
                  changeNote={(note) => this.updateAbsence({ note })}
                  readOnly={!canManage && !isRequest}
                />
              </div>
            </div>

            {createdByUserName && (
              <div className="createdAt" data-rh-at="right" data-rh={moment.unix(createdUnix).format("L / HH:mm")}>
                {lg.erstellt_von_am(createdByUserName, moment.unix(createdUnix).format("L"))}
              </div>
            )}

            {this.props.absence?.requestedAt && (
              <div className="requestedAt">{lg.anfrage_erstellt_am_date(moment(requestedAt).format("L"))}</div>
            )}

            {this.props.absence?.acceptedBy && (
              <div className="acceptedAt">{lg.anfrage_genehmigt_von_am(acceptedByName, acceptedAt)}</div>
            )}

            {editedByUserName && (
              <div className="editedAt" data-rh-at="right" data-rh={moment.unix(editedUnix).format("L / HH:mm")}>
                {lg.zuletzt_editiert_von_am(editedByUserName, moment.unix(editedUnix).format("L"))}
              </div>
            )}

            {!!uploads.length && (
              <AbsenceUploadsSection
                uploads={this.state.uploads}
                onChange={(upload) => this.setState({ uploads: uploads.map((u) => (u.id === upload.id ? upload : u)) })}
                onDelete={(uploadId) => this.setState({ uploads: uploads.filter((u) => u.id !== uploadId) })}
              />
            )}
          </div>
        </TZModal.Body>

        <TZModal.Footer>
          {/* Commnet back in when ready to do uploader */}
          <AvUploader
            data-rh={lg.datei_hochladen}
            buttonType="default"
            label={lg.anhang}
            onChange={(files) => this.selectedFiles(files)}
            style={{ marginRight: "auto" }}
          />
          {showBtn.delete && (
            <Button
              id="delete-absence"
              type={this.state.showDeleteConfirm ? "danger" : "dashed"}
              onClick={() => load(this.deleteClicked(), "deleting")}
              data-rh={absence.status === AbsenceStatus.active ? lg.löschen : lg.antrag_löschen}
              loading={isLoading("deleting")}
              children={this.state.showDeleteConfirm ? lg.wirklich_löschen : <Icon type="delete" />}
            />
          )}
          {showBtn.reject && (
            <Button
              id="reject-absence"
              type="danger"
              onClick={async () => load(this.reject(), "rejecting")}
              loading={isLoading("rejecting")}
              children={lg.ablehnen}
            />
          )}
          {showBtn.accept && (
            <Button
              id="accept-absence"
              type="primary"
              icon="check"
              onClick={async () => load(this.save(true), "accepting")}
              loading={isLoading("accepting")}
              disabled={!isComplete}
              children={lg.genehmigen}
            />
          )}
          {showBtn.save && (
            <Button
              id="save-absence"
              type="primary"
              onClick={() => load(this.save(false), "saving")}
              loading={isLoading("saving")}
              disabled={!isComplete || (isRequest && !canManage && !!violatingBan)}
              children={lg.Speichern}
            />
          )}
          {showBtn.request && (
            <Button
              id="request-absence"
              type="primary"
              onClick={async () => load(this.save(false), "saving")}
              loading={isLoading("saving")}
              disabled={!isComplete || !!violatingBan}
              children={lg.beantragen}
            />
          )}
        </TZModal.Footer>
      </TZModal>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(busyInjector(AbsenceModal));
