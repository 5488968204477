import { IUserTimeClockSettings } from "./../entities/ITimeClockSettings";
import { ITimeClocking, IBreakActivity } from "../entities/ITimeClocking";
import { getDuration, simpleDateTimeToMoment, toSimpleDateTime, getEndDateTime, minutesToTTime } from "./timeHelpers";
import moment from "moment";
import _ from "lodash";
import { ITracking } from "../entities/ITracking";
import { Raw } from "../entities/IResource";
import { IShift } from "../entities/IShift";
import { IUserRosterSettings } from "../entities/IRosterSettings";
import { getApplicableBreakRuleMinutes, getShiftEndDateTime } from "./shiftHelpers";
import { Map } from "../types/general";
import { SDateFormat } from "./SimpleTime";

export const calcBreakMinutes = (breakActivities: IBreakActivity[], nowSimpleTime: string) => {
  // When the amount of breakActivities is odd, we know, that there is still
  // an ongoing break. Therefore, we add a manual "end" break entity, so we can
  // calculate the total breakMinutes for exact this moment.
  const next = !(breakActivities.length % 2)
    ? [...breakActivities]
    : [...breakActivities, { type: "end", time: nowSimpleTime }];

  return Math.floor(
    _.chunk(next, 2)
      .map((tuple) => {
        return getDuration({
          startTime: tuple[0].time,
          endTime: tuple[1].time,
        });
      })
      .reduce((acc, currentValue) => acc + currentValue, 0)
  );
};

export const finalizeTimeClockingOnFinish = (timeClocking: ITimeClocking, endTime: string): ITimeClocking => {
  const next = { ...timeClocking, breakMinutes: 0, endTime };
  if (next.breakActivities && next.breakActivities.length) {
    const lastBreakActivity = next.breakActivities[next.breakActivities.length - 1];
    if (lastBreakActivity.type === "start") {
      // if the user clocks out during a break > add a break-end activity
      next.breakActivities = [...next.breakActivities, { time: endTime, type: "end" }];
    }
    next.breakMinutes = calcBreakMinutes(next.breakActivities, endTime);
  }
  timeClocking.isDynamicClocking && (next.shiftEndTime = next.endTime!);
  return next;
};

const getTrackingBreakMinutesOfClocking = (
  c: ITimeClocking,
  times: { startTime: string; endTime: string },
  rosterSettings: IUserRosterSettings,
  timeClockSettings: IUserTimeClockSettings
): number => {
  const { breaksNeedToBeClocked, applyAtLeastPlannedBreakTime, freeClockingApplyBreakeRule } = timeClockSettings;
  const { applyBreakRulesOnShifts } = rosterSettings;

  const breakRuleMinutes = getApplicableBreakRuleMinutes(rosterSettings, times) || 0;
  const planedBreakMinutes = applyBreakRulesOnShifts ? breakRuleMinutes : c.shiftBreakMinutes || 0;
  let breakMinutes = breaksNeedToBeClocked ? c.breakMinutes : planedBreakMinutes;

  if (applyAtLeastPlannedBreakTime && breakMinutes < planedBreakMinutes) {
    breakMinutes = planedBreakMinutes;
  }

  if (c.isDynamicClocking) {
    breakMinutes = freeClockingApplyBreakeRule && c.breakMinutes < breakRuleMinutes ? breakRuleMinutes : c.breakMinutes;
  }

  return breakMinutes || 0;
};

export const getBreakMinutesOfPunchingV2 = (
  c: ITimeClocking,
  rosterSettings: IUserRosterSettings,
  timeClockSettings: IUserTimeClockSettings
): number => {
  const { breaksNeedToBeClocked, applyAtLeastPlannedBreakTime } = timeClockSettings;
  const times = { startTime: c.startTime, endTime: c.endTime || c.startTime }; // endTime can be empty for autocloclouts

  const breakRuleMinutes = rosterSettings?.breakRules ? getApplicableBreakRuleMinutes(rosterSettings, times) : 0;
  let breakMinutes = breaksNeedToBeClocked ? c.breakMinutes : breakRuleMinutes;

  if (applyAtLeastPlannedBreakTime && c.breakMinutes < breakRuleMinutes) {
    breakMinutes = breakRuleMinutes;
  }

  return breakMinutes || 0;
};

export const generateTrackingByTimeClocking = (
  c: ITimeClocking,
  timeClockSettings: IUserTimeClockSettings,
  rosterSettings: IUserRosterSettings,
  isAutoClockOut = undefined
): ITracking => {
  const shiftDuration = getDuration({
    startTime: c.shiftStartTime!,
    endTime: c.shiftEndTime!,
    breakMinutes: c.shiftBreakMinutes,
  });

  let timeClockingEndTime = c.endTime || c.shiftEndTime || c.startTime;

  const {
    autoCapOverTimeMinutes,
    noEarlyClockin,
    clockingsAreApprovedOnDefault,
    autoApproveClockingMaxTimeDiff,
    freeClockingIsAutoApproved,
  } = timeClockSettings;

  // auto-cap
  if (autoCapOverTimeMinutes && !isAutoClockOut) {
    let timeClockingEndTimeMoment = simpleDateTimeToMoment(`${c.date} ${timeClockingEndTime}`);
    let shiftEndTimeMoment = simpleDateTimeToMoment(`${c.date} ${c.shiftEndTime}`);
    let shiftEndTimeWithCapMoment = shiftEndTimeMoment.clone().add(autoCapOverTimeMinutes, "minutes");
    if (
      timeClockingEndTimeMoment.isBefore(shiftEndTimeWithCapMoment) &&
      timeClockingEndTimeMoment.isAfter(shiftEndTimeMoment)
    ) {
      timeClockingEndTime = c.shiftEndTime!;
    }
  }

  const startTime = noEarlyClockin && c.startTime < c.shiftStartTime! ? c.shiftStartTime! : c.startTime;
  const times = { startTime, endTime: timeClockingEndTime };
  const breakMinutes = getTrackingBreakMinutesOfClocking(c, times, rosterSettings, timeClockSettings);
  const breakStartTime = _.orderBy(c.breakActivities || [], "time")[0]?.time; // take first breake entry

  const timeClockingDuration = getDuration({
    startTime: startTime,
    endTime: timeClockingEndTime!,
    breakMinutes: breakMinutes,
  });

  const trackingShiftDiff = Math.abs(timeClockingDuration - shiftDuration);

  const autoApprove =
    !isAutoClockOut &&
    (c.isDynamicClocking
      ? freeClockingIsAutoApproved
      : clockingsAreApprovedOnDefault && trackingShiftDiff <= autoApproveClockingMaxTimeDiff);

  return {
    id: c.id,
    date: c.date,
    startTime: startTime,
    endTime: isAutoClockOut ? c.shiftEndTime! : timeClockingEndTime!,
    comment: "",
    isAccepted: !!autoApprove,
    breakMinutes: breakMinutes || 0,
    breakStartTime: breakStartTime,
    creatorUserId: c.userId,
    createdAt: moment().toISOString(),
    userId: c.userId,
    branchId: c.branchId,
    jobPositionId: c.jobPositionId,
    isAutoClockOut,
  };
};

export const isClockingEqualToTracking = (timeClocking?: Partial<ITimeClocking>, tracking?: Partial<ITracking>) => {
  if (!timeClocking || !tracking) {
    return false;
  }

  return (
    timeClocking.startTime === tracking.startTime &&
    timeClocking.endTime === tracking.endTime &&
    timeClocking.breakMinutes === tracking.breakMinutes
  );
};

export const getClockInToShift = (shift: IShift, startTime: string): ITimeClocking => ({
  startTime: startTime,
  date: shift.date,
  userId: shift.userId!,
  shiftStartTime: shift.startTime,
  shiftEndTime: shift.endTime,
  id: shift.id,
  shiftBreakMinutes: shift.breakMinutes,
  branchId: shift.branchId,
  breakActivities: [],
  breakMinutes: 0,
  jobPositionId: shift.jobPositionId,
  comment: shift.comment,
  workSpaceId: shift.workSpaceId,
});

/**
 * Returns all shifts for the current user, that could be relevant
 * to display in the timeclock view. Not all of these shifts are clockable.
 * They might be disabled, in case they already have a tracking
 */
export const getRelevantShifts = (currentUserId: string | undefined, shifts: IShift[]) => {
  const now = moment();
  const nowDateTime = toSimpleDateTime(now);

  return shifts.filter((s) => {
    if (!s.userId || (currentUserId && s.userId !== currentUserId)) {
      return false;
    }

    // shfits are clockable if they are on the same day, or if the DIFF between now and the start time of the shift is less than 5 hours
    const startMoment = simpleDateTimeToMoment(`${s.date} ${s.startTime}`);
    const endDateTime = getEndDateTime(s);

    if (nowDateTime > endDateTime) {
      return false;
    }

    if (now.isAfter(startMoment.add(-3, "hours"))) {
      return true;
    }

    return false;
  });
};

/**
 * Returns all shifts for the current user, that are clockable
 */
export const getRelevantShiftsWithoutTrackingInBranch = (
  currentUserId: string | undefined,
  shifts: IShift[],
  trackingMap: Map<ITracking>,
  branchIds: string[]
) => {
  const relevantShifts = getRelevantShifts(currentUserId, shifts);
  return relevantShifts.filter((s) => !trackingMap[s.id] && branchIds.includes(s.branchId));
};

export const isClockingExceedingShiftTime = (c: ITimeClocking, nowSimpleDateTime: string) => {
  const miniShift = { date: c.date, startTime: c.shiftStartTime!, endTime: c.shiftEndTime! } as IShift;
  const shiftEndDateTime = getShiftEndDateTime(miniShift);
  return nowSimpleDateTime > shiftEndDateTime;
};

export const generateShiftOfDynamicClocking = (clocking: ITimeClocking, tracking: ITracking): IShift => ({
  id: clocking.id,
  date: clocking.date,
  userId: clocking.userId,
  branchId: clocking.branchId,
  jobPositionId: clocking.jobPositionId,
  startTime: clocking.startTime,
  endTime: tracking.endTime, // Tracking endTime
  breakMinutes: tracking.breakMinutes || 0, // Tracking breakMinutes
  workSpaceId: clocking.workSpaceId,
  comment: clocking.comment,
  isDynamicClocked: true,
});

export const generateTrackingOfDynamicClocking = (
  c: ITimeClocking,
  endTime: string,
  timeClockSettings: IUserTimeClockSettings,
  rosterSettings: IUserRosterSettings,
  isAutoClockOut?: boolean
): ITracking => {
  const breakStartTime = _.orderBy(c.breakActivities || [], "time")[0]?.time; // take first breake entry
  const times = { startTime: c.startTime, endTime: c.endTime || c.shiftEndTime || c.startTime }; // endTime can be empty for autocloclouts & shiftEndTime can be empty for freeClockings
  const breakMinutes = getTrackingBreakMinutesOfClocking(c, times, rosterSettings, timeClockSettings);
  return {
    id: c.id,
    date: c.date,
    userId: c.userId,
    branchId: c.branchId,
    jobPositionId: c.jobPositionId,
    startTime: c.startTime,
    endTime: endTime,
    breakMinutes: breakMinutes,
    breakStartTime: breakStartTime,
    comment: c.comment,
    isAccepted: !!timeClockSettings.freeClockingIsAutoApproved && !isAutoClockOut,
    creatorUserId: c.userId,
    createdAt: moment().toISOString(),
    isAutoClockOut,
  };
};

export const getActiveTimeClocking = (
  timeClockings: ITimeClocking[],
  currentUserId: string,
  trackingMap: Map<ITracking>
) => {
  const yesterday = moment().add(-1, "days").format(SDateFormat);
  return timeClockings.find(
    (t) => !t.endTime && !t.isAutoClockOut && t.userId === currentUserId && !trackingMap[t.id] && t.date >= yesterday
  );
};

export const capPunchingStartTime = (clocking: ITimeClocking, setting: IUserTimeClockSettings): ITimeClocking => {
  const hasCapTime = setting.capEarlyClockinsV2 && setting.capEarlyClockinsAtTimeV2;
  const capTime = hasCapTime ? setting.capEarlyClockinsAtTimeV2! : "00:00";
  return {
    ...clocking,
    startTime: capTime > clocking.startTime ? capTime : clocking.startTime,
  };
};
