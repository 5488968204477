import { AppState } from "../types/AppState";
import { createSelector } from "reselect";
import _ from "lodash";
import { selectAbsenceEntitlements } from "./absenceEntitlementsSelector";
import { selectContingentCorrections } from "./contingentCorrectionsSelector";
import { AbsenceTypeCode } from "../shared/entities/IAbsenceType";

export const _year = (state: AppState) => state.ui.absences.selectedYear;
export const _selectedTypeId = (state: AppState) => state.ui.absences.selectedTypeId;
export const _absenceTypes = (state: AppState) => state.data.absenceTypes;

export const absenceEntitlementByUserSelector = createSelector(
  [selectAbsenceEntitlements, selectContingentCorrections, _absenceTypes, _selectedTypeId, _year],
  (absenceEntitlements, contingentCorrections, absenceTypes, selectedTypeId, year) => {
    const vacationType = absenceTypes.find((a) => a.code === AbsenceTypeCode.vacation);
    const typeId = selectedTypeId || vacationType?.id; // defaults to vacation-type

    const daysByUser = {} as any;

    const entitlementsOfType = absenceEntitlements.filter((a) => a.absenceTypeId === typeId);
    const correctionsOfType = contingentCorrections.filter((c) => c.absenceTypeId === typeId);

    _.sortBy(entitlementsOfType, (c) => c.year).forEach((c) => {
      if (c.year <= year) {
        // only consider current and past years
        daysByUser[c.userId] = c.days;
      }
    });

    correctionsOfType // contingent corrections override the contingent for a specific year
      .filter((c) => c.isOverride && c.year === year)
      .forEach((c) => {
        daysByUser[c.userId] = c.days;
      });

    return daysByUser;
  }
);
