import { batch } from "react-redux";
import { IFilterExpression } from "./../shared/types/queryParams";
import { CLEAR_DATA_ACTION_TYPE } from "../repositories/maintenanceRepo";
import { firebaseListenerRepo } from "./../repositories/firebaseListenerRepo";
import { FbQueryOptions, CrudOperation } from "./../shared/helpers/firebaseHelpers";
import { Operation, firebaseWrite, getFirebaseQuery, nullifyProps } from "./../shared/helpers/firebaseHelpers";
import { AppState } from "./../types/AppState";
import { Reducer } from "redux";
import { IResource, Raw } from "../shared/entities/IResource";
import { DispFn, Thunk } from "./types/thunkTypes";
import { v4 as uuid } from "uuid";
import firebase from "firebase/compat/app";
import "firebase/database";
import _ from "lodash";
import * as Sentry from "@sentry/browser";
import moment from "moment";
import { StandardFilterExpression } from "../shared/helpers/queryParams";
import { excludeByFilterQuery } from "./helpers/baseRepoHelpers";
import { IActivityLogEntry } from "../shared/entities/IActivityLogEntry";
import { RepoKey } from "../shared/types/RepoKeys";
import { authRepository } from "../repositories/authRepository";
import { LoopProtector } from "./LoopProtector";

export interface ICrudRepository<State> {
  actionTypes: CrudActionTypes;
  repoKey: RepoKey;
  getReducer(): Reducer<State, any>;
}

export type CrudActionTypes = {
  add: string;
  update: string;
  remove: string;
  addList: string;
  updateList: string;
  removeList: string;
  clear: string;
  clearSelection: string;
};

type CrudOptions = {
  firebaseUpdates?: {}; // pass in update-Objects to write to DB atomically as a singel transaction
  skipStoreUpdate?: boolean;
  // potentially every crud action gets dispatched twice.
  // Once when calling the crud method. And a second time if there is a firebase listener.
  // If the developer is sure that a listener is registered, to avoid the first dispatch,
  // the `skipSotreUpdate` can be used.
};

type OperationCount = { [time: string]: number };

export const getActionTypes = (repoKey: string) => ({
  add: `@@AV_ADD_${repoKey}`,
  update: `@@AV_UPDATE_${repoKey}`,
  remove: `@@AV_REMOVE_${repoKey}`,
  addList: `@@AV_ADD_LIST_${repoKey}`,
  updateList: `@@AV_UPDATE_LIST_${repoKey}`,
  removeList: `@@AV_REMOVE_LIST_${repoKey}`,
  clear: `@@AV_CLEAR_${repoKey}`,
  clearSelection: `@@AV_CLEAR_SELECTION_${repoKey}`,
});

export class BaseRepository<TEntity extends IResource, TEntityDB extends TEntity = TEntity>
  implements ICrudRepository<TEntity[]>
{
  actionTypes: CrudActionTypes;
  static operationCount: OperationCount;
  listeners: {
    [key: string]: {
      child_added: (x: any) => void;
      child_changed: (x: any) => void;
      child_removed: (x: any) => void;
    };
  };

  constructor(public repoKey: RepoKey) {
    this.listeners = {};
    BaseRepository.operationCount = {};
    this.actionTypes = getActionTypes(repoKey);
  }

  doSkipCallInV2 = (state: AppState) => {
    const nonV2Repos = [
      "shifts",
      "trackings",
      "availabilities",
      "shiftRepeats",
      "shiftHandOverRequests",
      "publishedWeeks",
      "changeRequests",
    ];
    return state.data.tenantInfo.isV2 && nonV2Repos.includes(this.repoKey);
  };

  orderByFilterKey = (entities: TEntityDB[], filter?: IFilterExpression<TEntityDB>): TEntityDB[] => {
    return filter ? _.orderBy(entities, (e) => e[filter[0]], "desc") : entities;
  };

  getAction = (type: keyof CrudActionTypes, payload?: any) => {
    return { type: this.actionTypes[type], payload };
  };

  withId = (entity: Raw<TEntity>): TEntity => ({
    ...(entity as TEntity),
    id: (entity as TEntity).id || uuid(),
  });

  getBaseRef = (tenantId: string, childNodes?: string[]) => {
    const db = firebase.database();
    const tenatnRef = db.ref("tenants").child(tenantId);
    return childNodes ? childNodes.reduce((acc, node) => acc.child(node), tenatnRef) : tenatnRef.child(this.repoKey);
  };

  logActivity = (operation: Operation, tenantId: string, userId: string) => {
    const log: IActivityLogEntry = {
      operation,
      entity: this.repoKey,
      userId,
      isoDate: moment().toISOString(),
    };
    firebase.database().ref(`tenantMiscs/${tenantId}/lastActivityLog`).set(log);
  };

  public create =
    (_entity: Raw<TEntity> | TEntity, options: CrudOptions = {}) =>
    async (dispatch: DispFn, getState: () => AppState) => {
      LoopProtector.check(dispatch);
      const { firebaseUpdates, skipStoreUpdate } = options;
      const skip = this.doSkipCallInV2(getState());
      const tenantId = getState().data.auth.session!.tenantId;
      const userId = getState().data.auth.session!.userId;
      const dbRef = this.getBaseRef(tenantId);
      const entityWithId = this.withId(_entity);
      const entity = this.preProcess([entityWithId])[0];
      const payload = [{ operation: Operation.create, entity: this.toDbEntity(entity) }];
      const extraUpdates = {
        ...(firebaseUpdates || {}),
        ...dispatch(this.getExtraUpdates(payload)),
      };
      this.logActivity(Operation.create, tenantId, userId);
      await dispatch(this.preUpdateHook(payload));
      await firebaseWrite(payload, dbRef, extraUpdates, { skip });
      !skipStoreUpdate && dispatch(this.getAction("add", entity));
      !skipStoreUpdate && dispatch(this.storeActionFollowUp([entity]));
      return entity;
    };

  public update =
    (_entity: TEntity, options: CrudOptions = {}) =>
    async (dispatch: DispFn, getState: () => AppState): Promise<TEntity> => {
      LoopProtector.check(dispatch);
      const skip = this.doSkipCallInV2(getState());
      const { firebaseUpdates, skipStoreUpdate } = options;
      const tenantId = getState().data.auth.session!.tenantId;
      const userId = getState().data.auth.session!.userId;
      const entity = this.preProcess([_entity])[0];
      const dbRef = this.getBaseRef(tenantId);
      const payload = [
        {
          operation: Operation.update,
          entity: this.toDbEntity(entity),
        },
      ];
      const extraUpdates = {
        ...(firebaseUpdates || {}),
        ...dispatch(this.getExtraUpdates(payload)),
      };
      this.logActivity(Operation.update, tenantId, userId);
      await dispatch(this.preUpdateHook(payload));
      await firebaseWrite(payload, dbRef, extraUpdates, { skip });
      !skipStoreUpdate && dispatch(this.getAction("update", entity));
      !skipStoreUpdate && dispatch(this.storeActionFollowUp([entity]));
      return entity;
    };

  public remove =
    (id: string, options: CrudOptions = {}) =>
    async (dispatch: DispFn, getState: () => AppState) => {
      LoopProtector.check(dispatch);
      const skip = this.doSkipCallInV2(getState());
      const { firebaseUpdates, skipStoreUpdate } = options;
      const tenantId = getState().data.auth.session!.tenantId;
      const userId = getState().data.auth.session!.userId;
      const dbRef = this.getBaseRef(tenantId);
      const payload = [{ operation: Operation.remove, entity: { id } as TEntity }];
      const extraUpdates = {
        ...(firebaseUpdates || {}),
        ...dispatch(this.getExtraUpdates(payload)),
      };
      this.logActivity(Operation.remove, tenantId, userId);
      await dispatch(this.preUpdateHook(payload));
      await firebaseWrite(payload, dbRef, extraUpdates, { skip });
      !skipStoreUpdate && dispatch(this.getAction("remove", id));
      !skipStoreUpdate && dispatch(this.storeActionFollowUp([], [id]));
    };

  //TODO: REMOVE tenantId HERE its just for seeding the new system
  public createList =
    (_entities: Raw<TEntity>[], options: CrudOptions = {}, tenantId?: string) =>
    async (dispatch: DispFn, getState: () => AppState) => {
      LoopProtector.check(dispatch);
      const skip = this.doSkipCallInV2(getState());
      const { firebaseUpdates, skipStoreUpdate } = options;
      // const tenantId = getState().data.auth.session!.tenantId;
      const dbRef = this.getBaseRef(tenantId || getState().data.auth.session!.tenantId);
      const entitiesWithId = _entities.map(this.withId);
      const entities = this.preProcess(entitiesWithId);
      const payload = entities.map((entity) => ({
        operation: Operation.create,
        entity: this.toDbEntity(entity),
      }));
      const extraUpdates = {
        ...(firebaseUpdates || {}),
        ...dispatch(this.getExtraUpdates(payload)),
      };
      await dispatch(this.preUpdateHook(payload));
      await firebaseWrite(payload, dbRef, extraUpdates, { skip });
      !skipStoreUpdate && dispatch(this.getAction("addList", entities));
      !skipStoreUpdate && dispatch(this.storeActionFollowUp(entities));
      return entities;
    };

  public updateList =
    (__entities: TEntity[], options: CrudOptions = {}) =>
    async (dispatch: DispFn, getState: () => AppState) => {
      LoopProtector.check(dispatch);
      const skip = this.doSkipCallInV2(getState());
      const { firebaseUpdates, skipStoreUpdate } = options;
      const tenantId = getState().data.auth.session!.tenantId;
      const dbRef = this.getBaseRef(tenantId);
      const entities = this.preProcess(__entities);
      const payload = entities.map((entity) => ({
        operation: Operation.update,
        entity: this.toDbEntity(entity),
      }));
      const extraUpdates = {
        ...(firebaseUpdates || {}),
        ...dispatch(this.getExtraUpdates(payload)),
      };
      await dispatch(this.preUpdateHook(payload));
      await firebaseWrite(payload, dbRef, extraUpdates, { skip });
      !skipStoreUpdate && dispatch(this.getAction("updateList", entities));
      !skipStoreUpdate && dispatch(this.storeActionFollowUp(entities));
      return entities;
    };

  public removeList =
    (ids: string[], options: CrudOptions = {}) =>
    async (dispatch: DispFn, getState: () => AppState) => {
      LoopProtector.check(dispatch);
      const skip = this.doSkipCallInV2(getState());
      const { firebaseUpdates, skipStoreUpdate } = options;
      const tenantId = getState().data.auth.session!.tenantId;
      const dbRef = this.getBaseRef(tenantId);
      const entities = ids.map((id) => ({ id })) as TEntity[];
      const payload = entities.map((entity) => ({ operation: Operation.remove, entity }));
      const extraUpdates = {
        ...(firebaseUpdates || {}),
        ...dispatch(this.getExtraUpdates(payload)),
      };
      await dispatch(this.preUpdateHook(payload));
      await firebaseWrite(payload, dbRef, extraUpdates, { skip });
      !skipStoreUpdate && dispatch(this.getAction("removeList", ids));
      !skipStoreUpdate && dispatch(this.storeActionFollowUp([], ids));
    };

  /// FETCH ONCE ///
  public fetchOne =
    (id: string) =>
    async (dispatch: DispFn, getState: () => AppState): Promise<TEntity | undefined> => {
      LoopProtector.check(dispatch);
      const tenantId = getState().data.auth.session!.tenantId;
      const base = this.getBaseRef(tenantId);
      this.addSentryBreadCrumb("fetchOne", { id });

      const _entity = (await base.child(id).once("value")).val() as TEntityDB;
      if (!_entity) {
        dispatch(this.getAction("remove", id)); // in case the entity was delted from DB and was fetched before, cleare from store
        dispatch(this.storeActionFollowUp([], [id]));
        return undefined;
      }
      const entity = this.toLocalEntity(_entity);
      dispatch(this.getAction("addList", [entity]));
      dispatch(this.storeActionFollowUp([entity]));
      return entity;
    };

  /// FETCH MANY ///
  public fetchMany =
    (options: FbQueryOptions<TEntityDB> = {}, returnRaw?: boolean) =>
    async (
      // returnRaw just used for a Shift - DB bug > can be deleted later on
      dispatch: DispFn,
      getState: () => AppState
    ) => {
      LoopProtector.check(dispatch);
      if (this.doSkipCallInV2(getState())) {
        return []; // early exist to save resources in V2
      }
      const tenantId = getState().data.auth.session!.tenantId;
      const base = this.getBaseRef(tenantId, options.childNodes);
      const fbQuery = getFirebaseQuery(base, options);
      this.addSentryBreadCrumb("fetchMany", options);

      const result = (await fbQuery.once("value")).val() as any;
      let _entities = (result ? Object.values(result) : []) as TEntityDB[];
      _entities = this.orderByFilterKey(_entities, options.filter);
      const entities = _entities.map((e) => this.toLocalEntity(e));
      if (!options.noStoreUpdate) {
        dispatch(this.clearSelection(options.filter || "all")); // previously fetched entities that were delted in db need to get cleared out
        entities.length && dispatch(this.getAction("addList", entities));
        entities.length && dispatch(this.storeActionFollowUp(entities, [], options.filter || "all"));
      }
      return returnRaw ? _entities : entities;
    };

  /// FETCH BY IDS ///
  // sends out multiple requests - dont fetch more then 100 entities please
  public fetchByIds = (ids: string[]) => async (dispatch: DispFn, getState: () => AppState) => {
    LoopProtector.check(dispatch);
    const tenantId = getState().data.auth.session!.tenantId;
    const base = this.getBaseRef(tenantId);
    this.addSentryBreadCrumb("fetchByIds", ids);
    const results = await Promise.all(ids.map((id) => base.child(id).once("value")));
    const entities = results.map((res) => this.toLocalEntity(res.val()));
    dispatch(this.getAction("removeList", ids));
    dispatch(this.getAction("addList", entities));
    dispatch(this.storeActionFollowUp(entities, ids));
    return entities;
  };

  clear = () => {
    return { type: this.actionTypes.clear };
  };

  // add a key if multiple listeners are bing registered for the same entity-type
  // e.g.: shift-of-week and open-shifts
  public addListener =
    (opt?: FbQueryOptions<TEntityDB> & { key?: string }) =>
    async (dispatch: DispFn, getState: () => AppState): Promise<TEntity[]> => {
      LoopProtector.check(dispatch);
      if (this.doSkipCallInV2(getState())) {
        return []; // early exist to save resources in V2
      }
      const tenantId = getState().data.auth.session!.tenantId;
      const baseRef = this.getBaseRef(tenantId, opt?.childNodes);
      const listenerKey = opt?.key || this.repoKey;
      this.addSentryBreadCrumb("adding listener", opt);

      // remove potentially previous listener to avoid double-listening
      dispatch(firebaseListenerRepo.remove(listenerKey));
      const fbQueryRef = getFirebaseQuery(baseRef, opt) as firebase.database.Reference;
      const callBacks = {} as any;
      const valueSnap = (await fbQueryRef.once("value")).val();
      let _entities = (valueSnap ? Object.values(valueSnap) : []) as TEntityDB[];
      _entities = this.orderByFilterKey(_entities, opt?.filter);
      const entities = _entities.map((e) => this.toLocalEntity(e));
      const initialCount = entities.length;
      dispatch(this.clearSelection(opt?.filter || "all")); // previously fetched entities that were delted in db need to get cleared out
      dispatch(this.getAction("addList", entities));
      dispatch(this.storeActionFollowUp(entities, [], opt?.filter || "all"));
      dispatch(this.onListenerEvent(entities));

      let queue: Function[] = [];
      const executeQueue = () => {
        batch(() => queue.forEach((task) => dispatch(task())));
        queue = [];
      };

      let currentCount = 0;
      callBacks["child_added"] = fbQueryRef.on("child_added", (snap) => {
        currentCount++;
        if (currentCount <= initialCount) {
          return;
        }
        const entity = this.toLocalEntity(snap.val());
        queue.push(() => this.getAction("addList", [entity]));
        queue.push(() => this.storeActionFollowUp([entity]));
        queue.push(() => this.onListenerEvent([entity]));
        this.addSentryBreadCrumb("childAdded", entity);
        setTimeout(() => executeQueue(), 100);
      });

      callBacks["child_changed"] = fbQueryRef.on("child_changed", (snap: any) => {
        const entity = this.toLocalEntity(snap.val());
        queue.push(() => this.getAction("update", entity));
        queue.push(() => this.storeActionFollowUp([entity]));
        queue.push(() => this.onListenerEvent([entity]));
        this.addSentryBreadCrumb("childChanged", entity);
        setTimeout(() => executeQueue(), 100);
      });

      callBacks["child_removed"] = fbQueryRef.on("child_removed", (snap: any) => {
        queue.push(() => this.getAction("remove", snap.key));
        queue.push(() => this.storeActionFollowUp([], [snap.key]));
        this.addSentryBreadCrumb("childRemoved", snap.key);
        setTimeout(() => executeQueue(), 100);
      });
      dispatch(firebaseListenerRepo.add(listenerKey, fbQueryRef, callBacks));
      return entities;
    };

  clearSelection(filter: IFilterExpression<TEntityDB> | "all") {
    return filter === "all"
      ? this.getAction("clear")
      : this.getAction("clearSelection", new StandardFilterExpression(filter));
  }

  addSentryBreadCrumb(event: string, data: any) {
    Sentry.addBreadcrumb({
      message: event + ": " + this.repoKey,
      data,
    });
  }

  // can be used to manipulate props before sending to DB and Redux-Store
  preProcess(entities: TEntity[]): TEntity[] {
    return entities;
  }

  // can be used to add extry props, needed for Firebase querying ( e.g.: userId_date)
  toDbEntity(entity: TEntity): TEntityDB {
    return nullifyProps(entity as any) as TEntityDB;
  }

  toLocalEntity(entity: TEntityDB): TEntity {
    return entity as any as TEntity;
  }

  // this method can be overriden by child classes to react to ADD and CHANGE events.
  // e.g. load an entity that is being referenced and is not in the store
  onListenerEvent(entities: TEntity[]) {
    return (dispatch: DispFn, getState: () => AppState) => {};
  }

  // returns a firebase update object, to be applied atomically when writing data
  getExtraUpdates(writes: CrudOperation<TEntity>[]) {
    return (dispatch: DispFn, getState: () => AppState): {} => {
      return {};
    };
  }

  // this hook gets called just before the DB and Store update
  preUpdateHook(writes: CrudOperation<TEntity>[]) {
    return (dispatch: DispFn, getState: () => AppState) => {};
  }

  // A Repo can extend this function to return a Thunk if it wants to follow up with logic, after store updates of its entity type
  storeActionFollowUp(
    writingEntities: TEntity[],
    removingIds?: string[],
    clearSelection?: IFilterExpression<TEntityDB> | "all"
  ) {
    return (dispatch: DispFn, getState: () => AppState) => {};
  }

  getReducer(): Reducer<TEntity[]> {
    const { actionTypes } = this;
    return (state: TEntity[] = [], action: any): TEntity[] => {
      switch (action.type) {
        case actionTypes.add:
        case actionTypes.update:
          return [...state.filter((e) => e.id !== action.payload.id), action.payload];
        case actionTypes.remove:
          return state.filter((e) => e.id !== action.payload);
        case actionTypes.addList:
        case actionTypes.updateList:
          return _.unionBy(action.payload, state, "id");
        case actionTypes.removeList:
          return state.filter((e) => !action.payload.find((id: string) => id === e.id));
        case actionTypes.clear:
        case CLEAR_DATA_ACTION_TYPE:
          return [];
        case actionTypes.clearSelection: {
          const filter = action.payload as StandardFilterExpression<TEntityDB>;
          return excludeByFilterQuery(state as any[], filter);
        }
        default:
          return state;
      }
    };
  }
}
