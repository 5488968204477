import React, { useMemo } from "react";
import _ from "lodash";
import "./styles.scss";
import { useSelector } from "../../../../../helpers/redux";
import { tTimetoMinutes } from "../../../../../shared/helpers/timeHelpers";
import { AbsenceStatus, IAbsence } from "../../../../../shared/entities/IAbsence";
import { getUnavailableDuringShift } from "../../../../../helpers/workingTimeHelpers";
import "./styles.scss";
import { relevantShiftsOfBranchSelector } from "../../../../../selectors/RelevantShiftsSelector";
import DraggableShift from "../../RosterWeekGrid/Slot/DraggableShift";
import cn from "classnames";
import { useDrop } from "react-dnd";
import { moveShift, cloneShift } from "../../../../../actions/shift";
import { shfitDragActionTypes } from "../../../../../reducers/ui/shifts/roster/draggingShift";
import { useDispatch } from "react-redux";
import { Icon } from "antd";
import { ShiftDropArea } from "../../../../../components/ShiftDropArea/ShiftDropArea";
import { createSelector } from "reselect";
import { AppState } from "../../../../../types/AppState";
import { selectShiftOverlappingsMap } from "../../../../../selectors/shiftOverlappingsSelector";
import {
  selectBranchMap,
  selectJobPositionMap,
  selectShiftAddressMap,
  selectUserMap,
  selectWorkSpaceMap,
} from "../../../../../selectors/mapSelectors";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { selectAvailabilites } from "../../../../../selectors/availabilitiesSelector";
import { selectRosterSettingsByUser } from "../../../../../selectors/rosterSettingsByUserSelector";
import { selectVisibleAbsencesByUser } from "../../../../../selectors/absencesByUserSelector";
import { selectAbsenceTypeMap } from "../../../../../selectors/absenceTypeMapSelector";
import { IShift } from "../../../../../shared/entities/IShift";
import { IUser } from "../../../../../shared/entities/IUser";
import { canUserEditOwnShiftsOfDate } from "../../helpers";
import { selectGroupByJobPosition } from "../../../../../selectors/groupByJobPositionSelector";

export type Props = {
  jobPositionId?: string;
  date: string;
};

const createShiftsForColumnCreator = () =>
  createSelector(
    [
      relevantShiftsOfBranchSelector,
      (_: AppState, jobPositionId: string | undefined) => jobPositionId,
      (_: AppState, _2: string | undefined, date: string) => date,
    ],
    (shifts, jobPositionId, date) => {
      return shifts.filter(
        (s) => s.date === date && !s.isRequirement && (!jobPositionId || s.jobPositionId === jobPositionId)
      );
    }
  );

export const KanbanColumn = React.memo((props: Props) => {
  const { jobPositionId, date } = props;
  const dispatch = useDispatch();
  const selectShiftsForColumn = useMemo(createShiftsForColumnCreator, []);
  const absencesByUser = useSelector(selectVisibleAbsencesByUser);
  const absenceTypeMap = useSelector(selectAbsenceTypeMap);
  const sessionInfo = useSelector(selectSessionInfo);
  const shifts = useSelector((s) => selectShiftsForColumn(s, jobPositionId, date));
  const users = useSelector((s) => s.data.users);
  const isV2 = useSelector((s) => s.data.tenantInfo.isV2);
  const selectedBranch = useSelector((s) => s.ui.selectedBranch);
  const generalRosterSettings = useSelector((s) => s.data.rosterSettings[0]);
  const userRosterSettings = useSelector(selectRosterSettingsByUser)[sessionInfo.user.id];
  const availabilities = useSelector(selectAvailabilites);

  const handOverRequests = useSelector((s) => s.data.shiftHandOverRequests);
  const changeRequests = useSelector((s) => s.data.changeRequests);
  const timeClockings = useSelector((s) => s.data.timeClockings);
  const trackings = useSelector((s) => s.data.trackings);
  const isGroupedByJobPos = useSelector(selectGroupByJobPosition);
  const recentModifiedShifts = useSelector((s) => s.ui.shifts.roster.recentModifiedShifts);
  const draggingShift = useSelector((s) => s.ui.shifts.roster.draggingShift);

  const jobPosMap = useSelector(selectJobPositionMap);
  const workSpaceMap = useSelector(selectWorkSpaceMap);
  const usersMap = useSelector(selectUserMap);
  const overlappingsMap = useSelector(selectShiftOverlappingsMap);
  const branchMap = useSelector(selectBranchMap);
  const addressMap = useSelector(selectShiftAddressMap);

  const sessionUser = sessionInfo.user;
  const canManage = sessionInfo.hasManagerPermissions();
  const userCanApplyToOpenShifts = userRosterSettings.usersCanApplyToOpenShifts;
  const { employeesCanNotSeeShiftsOfCoWorkers, usersOfOtherRolesAreHidden } = generalRosterSettings;
  const _employeesCanNotSeeShiftsOfCoWorkers = employeesCanNotSeeShiftsOfCoWorkers || isV2;

  const hasMatchingJobPos = (user: IUser, shift: IShift) => user.jobPositionIds.includes(shift.jobPositionId);

  const visibleShifts = shifts.filter(
    (s) =>
      canManage ||
      s.userId === sessionUser.id ||
      (!s.userId && userCanApplyToOpenShifts && hasMatchingJobPos(sessionUser, s)) ||
      (s.userId &&
        !_employeesCanNotSeeShiftsOfCoWorkers &&
        (!usersOfOtherRolesAreHidden || hasMatchingJobPos(sessionUser, s)))
  );

  const shiftGroupsOfDay = _.orderBy(
    _.values(
      _.groupBy(
        _.orderBy(visibleShifts, [(s) => !s.userId || users.find((u) => u.id === s.userId)?.createdAt, (s) => s.id]),
        (s) =>
          s.startTime + s.endTime + s.workSpaceId + s.addressId + s.jobPositionId + s.branchId + (!s.userId ? s.id : "") // we do this, so open shifts don't get grouped!
      )
    ),
    [(sG) => tTimetoMinutes(sG[0].startTime), "duration", "breakMinutes", "id"]
  );

  // Create DnD Drop Hook
  const [{ isOver: isOverMoveToDropArea }, moveToDropRef] = useDrop({
    accept: "SHIFT",
    drop: (item: IShift) => {
      dispatch({ type: shfitDragActionTypes.END });
      dispatch(moveShift(item.id, props.date as string, item.userId, props.jobPositionId, undefined));
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const [{ isOver: isOverCloneDropArea }, cloneDropRef] = useDrop({
    accept: "SHIFT",
    drop: (item: IShift) =>
      dispatch(cloneShift(item.id, props.date as string, item.userId, props.jobPositionId, undefined)),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const [{ isOverSlot }, slotRef] = useDrop({
    accept: "SHIFT",
    collect: (monitor) => ({
      isOverSlot: !!monitor.isOver(),
    }),
  });

  const canEditOwnShifts =
    !canManage &&
    (!jobPositionId || sessionUser.jobPositionIds.includes(jobPositionId!)) &&
    canUserEditOwnShiftsOfDate(userRosterSettings, date);

  return (
    <div ref={slotRef} className={cn({ isOverSlot: isOverSlot, kanbanColGroupMain: true })}>
      <ShiftDropArea
        isOverMoveToDropArea={isOverMoveToDropArea}
        isOverCloneDropArea={isOverCloneDropArea}
        cloneDropRef={cloneDropRef}
        moveToDropRef={moveToDropRef}
        hideMove={!!draggingShift?.isTemplate}
      />
      {_.flattenDeep(
        shiftGroupsOfDay.map((shiftGroup) => {
          return shiftGroup.map((shift, i) => {
            let absence: IAbsence | undefined;
            if (shift.userId) {
              const userAbsences = absencesByUser[shift.userId] || [];
              absence = userAbsences.find(
                (a) => a.startDate <= date && a.endDate >= date && a.status === AbsenceStatus.active
              );
            }

            let additionalClassNames = "kanbanShift";
            !shift.userId && (additionalClassNames += " openShiftKanban");

            return (
              <DraggableShift
                isFirstShiftOfGroup={shiftGroup.length > 1 && i === 0}
                isLastShiftOfGroup={shiftGroup.length > 1 && i === shiftGroup.length - 1}
                shiftGroupLength={shiftGroup.length}
                isSlimShift={i > 0}
                userUnavailable={!!shift.userId && !!getUnavailableDuringShift(shift, availabilities)}
                data={shift}
                key={shift.id}
                absence={absence}
                absenceType={absence && absenceTypeMap[absence.typeId]}
                userId={shift.userId}
                wasRecentlyModified={recentModifiedShifts.includes(shift.id)}
                handOverRequest={handOverRequests.find((r) => r.id === shift.id)}
                changeRequest={changeRequests.find((cR) => cR.id === shift.id)}
                tracking={trackings.find((t) => t.id === shift.id)}
                clocking={timeClockings.find((c) => c.id === shift.id)}
                additionalClassNames={additionalClassNames}
                showUserName={!!shift.userId}
                isKanbanShift={true}
                isGroupedByJobPos={isGroupedByJobPos}
                dispatch={dispatch}
                jobPosMap={jobPosMap}
                workSpaceMap={workSpaceMap}
                usersMap={usersMap}
                branchMap={branchMap}
                addressMap={addressMap}
                shiftOverlap={overlappingsMap[shift.id]}
                canManage={canManage}
                isOwnShift={sessionUser.id === shift.userId}
                showBranch={!selectedBranch}
                isV2={isV2}
              />
            );
          });
        })
      )}
      <div
        className={cn({ addShiftWrapper: true, canNotManage: !canManage && !canEditOwnShifts })}
        data-type="kanban-cell"
        data-kanban-cell-id={date + "_" + jobPositionId}
      >
        <div className="addShift">+</div>
      </div>
    </div>
  );
});
