import React from "react";
import "./styles.scss";

type Props = {
  planedTime?: {
    startTime: string;
    endTime: string;
    breakMinutes: number;
  };
  trackedTime: {
    startTime: string;
    endTime: string;
    breakMinutes: number;
  };
};

const PlanedAndTrackedTime = ({ planedTime, trackedTime }: Props) => {
  return (
    <div className="plannedAndRequestedTimeMain">
      <div className="timesCell">
        {planedTime && (
          <div className="row">
            <div className="label">{lg.SOLL}</div>
            <div className="times" style={{ color: "#545454" }}>
              {`${planedTime.startTime} - ${planedTime.endTime}`}
              {" | " + planedTime.breakMinutes + " "}
            </div>
          </div>
        )}
        <div className="row">
          <div className="label" style={{ marginBottom: "1px" }}>
            {lg.IST}
          </div>
          <div className="times" style={{ color: "#1a90ff" }}>
            {`${trackedTime.startTime} - ${trackedTime.endTime}`}
            {" | " + trackedTime.breakMinutes + " "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanedAndTrackedTime;
