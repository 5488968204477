import { Button } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../actions/modal";
import { deleteRepeatingShift, deleteShift } from "../../../actions/shift";
import { IShift } from "../../../shared/entities/IShift";
import { busyInjector, BusyInjectorProps } from "../../BusyInjector/BusyInjector";
import TZModal from "../../TZModal/TZModal";
import { IShiftRepeat } from "../../../shared/entities/IShiftRepeat";

type Props = {
  shift: IShift;
  shiftRepeat: IShiftRepeat;
  onUpdateComplete?: () => void;
};

export const DeleteRepeatingShiftModal = busyInjector((props: Props & BusyInjectorProps) => {
  const dispatch = useDispatch();

  const onComplete = () => {
    props.onUpdateComplete && props.onUpdateComplete();
    dispatch(closeModal());
    dispatch(closeModal());
  };

  const onlyDeleteCurrentShift = async () => {
    // Doing it this way, as the props.load type is broken
    props.setLoading("deleteOnlyCurrentShift", true);
    await dispatch(deleteShift(props.shift as IShift));
    props.setLoading("deleteOnlyCurrentShift", false);
    onComplete();
  };

  const deleteRepeatingShifts = async () => {
    // Doing it this way, as the props.load type is broken
    props.setLoading("deleteRepeatingShifts", true);
    await dispatch(deleteRepeatingShift(props.shift, props.shiftRepeat));
    props.setLoading("deleteRepeatingShifts", false);
    onComplete();
  };

  return (
    <TZModal>
      <TZModal.Head title="Wiederholend löschen"></TZModal.Head>
      <TZModal.Body>
        <div className="fb column" style={{ padding: 24 }}>
          <Button
            id="delete-repeating-shfit-modal-confirm-btn"
            loading={props.isLoading("deleteRepeatingShifts")}
            type="danger"
            style={{ marginBottom: 12 }}
            onClick={deleteRepeatingShifts}
            children={lg.auch_alle_folgenden_wiederholungen_löschen}
          />
        </div>
      </TZModal.Body>
    </TZModal>
  );
});
