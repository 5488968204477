import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../../types/AppState";
import * as Sentry from "@sentry/browser";
import { Button } from "antd";

const mapStateToProps = (state: AppState) => {
  return {};
};

type OwnProps = {
  children?: React.ReactNode;
};

type State = {
  error?: Error;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & { dispatch: ThunkDispatch<any, any, any> };

class _ErrorBoundary extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    Sentry.captureException(error);
    return { error };
  }

  render() {
    if (this.state.error) {
      return (
        // eslint-disable-next-line jsx-a11y/accessible-emoji
        <span
          style={{
            flex: "1 1 auto",
            background: "#d8e8f6",
            color: "#2f6292",
            justifyContent: "center",
            alignItems: "center",
            padding: 4,
            borderRadius: 5,
            border: "solid 2px #1990ff26",
            textOverflow: "ellipsis",
            wordWrap: "break-word",
            cursor: "default",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span style={{ fontSize: 30 }}>😯</span>
          {lg.hoppla_es_ist_etwas_schief_gelaufen}
          <div style={{ marginTop: 12 }}>
            <Button id="sorry-error-occured-reload" onClick={() => window.location.reload()}>
              {lg.neu_laden}
            </Button>
          </div>
        </span>
      );
    }
    return this.props.children;
  }
}

export const ErrorBoundary = connect(mapStateToProps)(_ErrorBoundary);
export const withErrorBoundary =
  <T extends {}>(Component: React.ComponentType<T>) =>
  (props: T) =>
    (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    );
