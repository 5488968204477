import { BaseRepository } from '../frontend-core/BaseRepository';
import { ICreditCorrection } from '../shared/entities/ICreditCorrection';

class CreditCorrectionRepository extends BaseRepository<ICreditCorrection> {
  constructor() {
    super('creditCorrections');
  }
}

export const creditCorrectionRepository = new CreditCorrectionRepository();
