import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import Page from "../../../components/Page/Page";
import { Button, notification, Alert, Spin } from "antd";
import { push } from "connected-react-router";
import "./styles.scss";
import * as Yup from "yup";
import { FormikProps, Form, Formik } from "formik";
import { authRepository } from "../../../repositories/authRepository";
import { sitemap } from "../../../helpers/sitemap";
import AvFormikPasswordInput from "../../../components/AvFormikPasswordInput/AvFormikPasswordInput";
import BlueAplanoLogo from "../../../components/Logos/BlueAplanoLogo";
import { RoleType } from "../../../shared/entities/IUser";
import { isMobile, isTablet } from "mobile-device-detect";
import * as Sentry from "@sentry/browser";
import { HttpError } from "../../../frontend-core/actions/send";
import { BusyInjectorProps, busyInjector } from "../../../components/BusyInjector/BusyInjector";
import { getUrlParam } from "../../../frontend-core/helpers/frontendHelpers";
import { envi } from "../../../env";
import BlackZeitguruLogo from "../../../components/Logos/BlackZeitguruLogo";

enum tokenError {
  NO_TOKEN,
  TOKEN_INACTIVE,
}

const mapStateToProps = (state: AppState) => {
  return {
    isLoggedIn: !!state.data.auth.session,
  };
};

type OwnProps = {};

type State = {
  isPasswordReset: boolean;
  error?: tokenError;
  password?: string;
};

type FormValues = {
  pw1: string;
  pw2: string;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class AccountActivationComponent extends React.PureComponent<Props, State> {
  submitAttempted: boolean;
  email?: string;

  constructor(props: Props) {
    super(props);

    this.state = {
      isPasswordReset: false,
      error: undefined,
      password: undefined,
    };

    this.submitAttempted = false;
  }

  componentDidMount = async () => {
    if (this.props.isLoggedIn) {
      this.props.dispatch(authRepository.logout());
    }

    if (window.location.pathname.includes(sitemap.passwordReset.url)) {
      this.setState({ isPasswordReset: true });
    }

    const token = this.getTokenFromUrl();
    if (!token) {
      const msg = "No Token In URL during accountActivation/passwordReset";
      console.error(msg);
      Sentry.captureMessage(msg);
      Sentry.captureException(new Error(msg));
      notification.error({ message: lg.kein_token_vorhanden_in_der_url });
      return;
    }

    try {
      const res = await this.props.load(this.props.dispatch(authRepository.isTokenActive(token)));
      this.email = (res as any).data.email;
      console.log("this.email");
      console.log(this.email);
    } catch (e: any) {
      if (e.statusCode === 406) {
        this.setState({ error: tokenError.TOKEN_INACTIVE });
      }
      if (e.statusCode === 404) {
        this.setState({ error: tokenError.NO_TOKEN });
      }
    }
  };

  validationSchema = Yup.object().shape({
    pw1: Yup.string().min(4, lg.mindestens_4_zeichen).required(lg.eingabe_erforderlich),
    pw2: Yup.string().min(4, lg.mindestens_4_zeichen).required(lg.eingabe_erforderlich),
  });

  getTokenFromUrl = () => {
    return getUrlParam("token") as string;
  };

  handleSubmit = async (values: FormValues, formikBag: any) => {
    const { dispatch, load } = this.props;
    this.submitAttempted = true;
    const errors = await formikBag.validateForm();
    const isValid = !Object.values(errors).length;
    this.setState({ password: values.pw1 });

    if (!isValid) {
      return;
    }

    const token = this.getTokenFromUrl();

    try {
      const res = this.state.isPasswordReset
        ? await load(dispatch(authRepository.resetPassword(values.pw1, token)))
        : await load(dispatch(authRepository.completeInvitation(values.pw1, token)));

      const role = (res as any).data.role;
      this.onSuccess(role);
    } catch (e: any) {
      console.log(e.statusCode);
      notification.error({ message: e.message });
    }
  };

  onSuccess = (role: RoleType) => {
    const { dispatch, load } = this.props;
    notification.success({
      message: this.state.isPasswordReset ? lg.passowort_erfolgreich_geändert : lg.account_wurde_aktiviert,
    });

    const isMobileDevice = isMobile && !isTablet;
    const credentials = { email: this.email!, password: this.state.password! };

    if (isMobileDevice) {
      dispatch(push(sitemap.noMobile.url));
    } else {
      dispatch(push(sitemap.login.url));
      load(dispatch(authRepository.login(credentials)));
    }
  };

  validate = (values: FormValues) => {
    const { pw1, pw2 } = values;

    const error = pw1 && pw2 && pw1 !== pw2 && this.submitAttempted ? { pw2: lg.passwörter_sind_ungleich } : {};

    return error;
  };

  renderForm = (formikProps: FormikProps<FormValues>) => {
    const passwordLabel = this.state.isPasswordReset ? lg.neues_passwort : lg.passwort;

    return (
      <Form>
        <AvFormikPasswordInput
          fieldName="pw1"
          label={passwordLabel}
          formikProps={formikProps}
          size="large"
          style={{ marginBottom: "8px" }}
        />
        <AvFormikPasswordInput fieldName="pw2" label={lg.passwort_wiederholen} formikProps={formikProps} size="large" />
        <Button
          id="new-password-save"
          style={{ marginTop: "14px" }}
          type="primary"
          size="large"
          onClick={formikProps.submitForm}
          loading={formikProps.isSubmitting}
        >
          {lg.Speichern}
        </Button>
      </Form>
    );
  };

  render() {
    const { error, isPasswordReset } = this.state;
    const isV2 = envi.isV2();
    const impressumUrl = isV2 ? `https://www.zeitguru.de/impressum` : `https://www.aplano.de/impressum`;

    const passwordDescription = isPasswordReset
      ? lg.vergeben_sie_für_ihren_aplano_zugang_ein_neues_passwort
      : lg.legen_sie_für_ihren_aplano_zugang_ein_passwort_an;

    if (this.props.isLoading()) {
      return (
        <Page>
          <div className="fb jCenter aCenter" style={{ height: "100%", width: "100%" }}>
            <Spin />
          </div>
        </Page>
      );
    }

    return (
      <Page>
        <div className="userRegistrationMain">
          <div className="logoWrapper">
            {isV2 && <BlackZeitguruLogo style={{ width: 300, height: 80, marginBottom: 30 }} />}
            {!isV2 && <BlueAplanoLogo style={{ width: 300, height: 80, marginBottom: 30 }} />}
          </div>
          <div className="fb jCenter aCenter">
            {error === tokenError.NO_TOKEN && (
              <Alert
                message={lg.link_ist_fehlerhaft}
                description={lg.der_link_ist_unvollständig_oder_invalide}
                type="warning"
                showIcon
                style={{ width: "340px" }}
              />
            )}
            {error === tokenError.TOKEN_INACTIVE && (
              <Alert
                message={lg.link_bereits_verwendet}
                description={
                  isPasswordReset
                    ? lg.der_link_wurde_bereits_verwendet
                    : lg.du_kannst_dich_jetzt_mit_deiner_email_und_deinem_passwort_einloggen
                }
                type="warning"
                showIcon
                style={{ width: "340px" }}
              />
            )}
            {error === tokenError.TOKEN_INACTIVE && !isPasswordReset && (
              <Button
                style={{
                  marginTop: 24,
                  width: 210,
                }}
                type="primary"
                onClick={() => {
                  this.props.dispatch(push(sitemap.login.url));
                }}
              >
                {lg.zum_login}
              </Button>
            )}
            {!error && (
              <>
                <div className="message jCenter" style={{ marginBottom: "20px", display: "flex" }}>
                  <Alert
                    message={isPasswordReset ? lg.passwort_zurücksetzen : lg.account_anlegen}
                    description={passwordDescription}
                    type="info"
                    showIcon
                    style={{ width: "340px" }}
                  />
                </div>
                <div className="passwordInputWrapper">
                  <Formik
                    initialValues={{ pw1: "", pw2: "" }}
                    onSubmit={this.handleSubmit}
                    enableReinitialize
                    validationSchema={this.validationSchema}
                    validate={this.validate}
                    children={this.renderForm}
                  />
                </div>
              </>
            )}
          </div>
          <div className="impressumLink">
            <a
              href={impressumUrl}
              target="_blank"
              style={{ opacity: 0.7 }}
              rel="noopener noreferrer"
              className="avetiqLink"
            >
              {lg.impressum}
            </a>
          </div>
        </div>
      </Page>
    );
  }
}

export const AccountActivate = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(
  busyInjector(AccountActivationComponent)
);

//     Mob    Desk
// Adm NoMob  Login
// MA  NoWeb NoWeb
