import { IUserFull, IUser } from "./../entities/IUser";
import { cipher, decipher } from "./cypher";

const myCipher = cipher("wayTooSa" + "lty");
const myDecypher = decipher("wayTooSa" + "lty");

export const simpleEncrypt = (text: string): string => (text.startsWith("-cryp-") ? text : "-cryp-" + myCipher(text)); // if already encrpyted > just return the string
export const simpleDecrypt = (text: string): string => (text.startsWith("-cryp-") ? myDecypher(text.substr(6)) : text); // if its not encrypted > just return the string

export const encryptUser = (user: IUserFull): IUser =>
  // the type IUserEncryped does noes has 'name' and 'email' prop > so reading/setting them shows an error
  // but we have them to store the encrpyted values and to stay backwards compatible
  ({
    ...user,
    name: simpleEncrypt(user.name),
    email: user.email ? simpleEncrypt(user.email) : user.email,
    photoUrl: user.photoUrl ? simpleEncrypt(user.photoUrl) : user.photoUrl,
  } as any);

export const decryptUser = (user: IUser): IUserFull => {
  const email = (user as any).email;
  const photoUrl = (user as any).photoUrl;
  const name = (user as any).name;
  return {
    ...user,
    name: simpleDecrypt(name),
    email: email ? simpleDecrypt(email) : email,
    photoUrl: photoUrl ? simpleDecrypt(photoUrl) : photoUrl,
  };
};

export const getUserName = (user: IUser): string => simpleDecrypt((user as any).name);
export const getUserEmail = (user: IUser): string | undefined => {
  const email = (user as any).email;
  return email ? simpleDecrypt(email) : email;
};
