import React, { PureComponent } from "react";
import AbsenceBar from "./AbsenceBarYearView/AbsenceBarYearView";
import "./styles.scss";
import moment from "moment";

import { AppState } from "../../../../../types/AppState";
import { connect } from "react-redux";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { IAbsence } from "../../../../../shared/entities/IAbsence";
import { IHoliday } from "../../../../../shared/entities/IHoliday";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import AbsenceBarYearView from "./AbsenceBarYearView/AbsenceBarYearView";

const mapStateToProps = (state: AppState) => ({
  sessionInfo: selectSessionInfo(state),
});

type OwnProps = {
  absences: Array<IAbsence>;
  yearStartDate: string;
  yearEndDate: string;
  canManageAbsences: boolean;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _YearCell extends PureComponent<Props> {
  render() {
    const { yearStartDate, yearEndDate, absences, sessionInfo } = this.props;
    const absencesInYear = absences.filter((abs) => abs.startDate <= yearEndDate && abs.endDate >= yearStartDate);

    return (
      <div className="absenceYearCell" style={{ width: "100%" }}>
        {absencesInYear.map((a) => (
          <AbsenceBarYearView
            key={a.id}
            absence={a}
            yearStartDate={yearStartDate}
            yearEndDate={yearEndDate}
            isClickable={this.props.canManageAbsences || a.userId === sessionInfo.user.id}
          />
        ))}
      </div>
    );
  }
}

export const YearCell = connect(mapStateToProps)(_YearCell);
