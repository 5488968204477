import React from "react";
import aplanoLogoSrc from "../../logos/aplano-logo-black.svg";
import zeitguruLogoSrc from "../../logos/zeitguru-logo-black.svg";
import { isV2Domain } from "../../frontend-core/helpers/frontendHelpers";
type Props = {};

export const SetupWizardHead = (props: Props) => {
  const logoSrc = isV2Domain() ? zeitguruLogoSrc : aplanoLogoSrc;

  return (
    <div className="setupWizardHead">
      <div className="fb aStart logoWrapper">
        <img src={logoSrc} style={{ marginBottom: 20, opacity: 0.7 }} width="100" />
      </div>
      <h2 style={{}}>{lg.einrichtung}</h2>
    </div>
  );
};
