import React from "react";
import _ from "lodash";
import "./styles.scss";
import TZModal from "../../TZModal/TZModal";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { AvIcon } from "../../AvIcon/AvIcon";
import { Button, Modal } from "antd";
import { IUser } from "../../../shared/entities/IUser";
import { toSimpleDate } from "../../../shared/helpers/timeHelpers";
import moment from "moment";
import { closeModal, openModal } from "../../../actions/modal";
import { ShiftPopup } from "../../ShiftPopup/ShiftPopup/ShiftPopup";
import { decryptUser } from "../../../shared/helpers/userHelpers";
import { useSelector } from "../../../helpers/redux";
import { SelectClockingStatus } from "../../../selectors/ClockingStatusSelector";
import { useDispatch } from "react-redux";
import { selectUserMap } from "../../../selectors/mapSelectors";
import { IShift } from "../../../shared/entities/IShift";
import { ITimeClocking } from "../../../shared/entities/ITimeClocking";
import { stopDynamicTimeClocking, stopTimeClocking } from "../../../actions/timeClocking";

export enum LiveStatusType {
  usersInBreak = "usersInBreak",
  usersClockedIn = "usersClockedIn",
  usersLate = "usersLate",
}

type Props = {
  type: LiveStatusType;
};

const getTitleByType = (type: LiveStatusType) => {
  switch (type) {
    case LiveStatusType.usersClockedIn:
      return lg.eingestempelte_mitarbeiter;
    case LiveStatusType.usersInBreak:
      return lg.mitarbeiter_in_der_pause;
    case LiveStatusType.usersLate:
      return lg.verspätete_mitarbeiter;
  }
};

export const LiveStatusModal = React.memo((props: Props) => {
  const { type } = props;
  const dispatch = useDispatch();
  const sessionInfo = useSelector(selectSessionInfo);
  const title = getTitleByType(type);
  const usersMap = useSelector(selectUserMap);
  const shifts = useSelector((state) => state.data.shifts);
  const branches = useSelector((state) => state.data.branches);
  const clockingStatus = useSelector(SelectClockingStatus);
  const data =
    type === LiveStatusType.usersLate
      ? clockingStatus.unclockedShifts
      : type === LiveStatusType.usersInBreak
      ? clockingStatus.activeClockingsInBreak
      : clockingStatus.activeClockings;

  const hasMultipleBranches = sessionInfo.user.branchIds.length > 1;
  const modalHeadColor = {
    usersInBreak: "rgb(253, 157, 26)",
    usersClockedIn: "rgb(26, 144, 255)",
    usersLate: "rgb(245, 35, 45)",
  };

  const renderIsTime = (entity: IShift | ITimeClocking) => {
    const hasMultipleBranches = sessionInfo.user.branchIds.length > 1;
    const isClocking = (entity as ITimeClocking).isDynamicClocking;
    if (type === LiveStatusType.usersClockedIn) {
      return (
        <>
          <div className="singleTrackedShouldTime">{(entity as ITimeClocking).shiftStartTime}</div>
          <div className="singleTrackedIsTime">{entity.startTime}</div>
          <Button
            id={"life-status-modal-clockout-btn-" + entity.userId + "-" + entity.date + "-" + entity.startTime}
            title={isClocking ? lg.die_schicht_wird_frei_gestempelt : undefined}
            size="small"
            style={{ width: "20%" }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              isClocking ? dispatch(stopTimeClocking(entity as ITimeClocking)) : handleClockOutClick(entity.id);
            }}
          >
            {lg.ausstempeln}
          </Button>
        </>
      );
    }
    if (type === LiveStatusType.usersInBreak) {
      return (
        <>
          <div className="singleTrackedShouldTime">{(entity as ITimeClocking).shiftStartTime}</div>
          <div className="singleTrackedIsTime">
            {(entity as ITimeClocking).breakActivities?.slice(-1)[0]?.time || "?"}
          </div>
        </>
      );
    }
    if (type === LiveStatusType.usersLate) {
      return (
        <div className="singleTrackedShouldTime" style={{ paddingLeft: hasMultipleBranches ? 30 : 100 }}>
          {entity.startTime}
        </div>
      );
    }
  };

  const renderTableHeader = () => {
    const hasMultipleBranches = sessionInfo.user.branchIds.length > 1;
    if (type === LiveStatusType.usersClockedIn) {
      return (
        <>
          <div className="topbarItem">{lg.schichtbeginn}</div>
          <div className="topbarItem" style={{ color: "rgb(26, 144, 255)" }}>
            {lg.eingestempelt}
          </div>
          <div className="topbarItem" style={{ color: "rgb(26, 144, 255)" }}></div>
        </>
      );
    }
    if (type === LiveStatusType.usersInBreak) {
      return (
        <>
          <div className="topbarItem">Schichtbeginn</div>
          <div className="topbarItem" style={{ color: "rgb(26, 144, 255)" }}>
            {lg.in_pause_seit}
          </div>
        </>
      );
    }
    if (type === LiveStatusType.usersLate) {
      return (
        <div style={{ paddingLeft: hasMultipleBranches ? 30 : 100 }} className="topbarItem">
          {lg.schichtbeginn}
        </div>
      );
    }
  };

  const handleClockOutClick = (shiftId: string) => {
    Modal.confirm({
      title: lg.zur_schicht,
      content: lg.um_einen_mitarbeiter_auszustempeln_musst_du_eine_zeiterfassung_anlegen,
      okText: lg.ok,
      cancelText: lg.abbrechen,
      onOk: async () => {
        dispatch(
          openModal(ShiftPopup, {
            onUpdateComplete: () => dispatch(closeModal()),
            shift: shifts.find((s) => s.id === shiftId)!,
          })
        );
      },
    });
  };

  const renderBranchCell = (d: IShift | ITimeClocking) => {
    const branchName = branches.find((b) => b.id === d.branchId)?.name;
    return <div className="branchOfClockedShift">{branchName || "-"}</div>;
  };

  return (
    <TZModal style={{ minWidth: hasMultipleBranches ? "800px" : "640px" }}>
      <TZModal.Head>
        <div className="liveStatusModalHead" style={{ color: modalHeadColor[type] }}>
          <AvIcon
            style={{ fontSize: "25px", paddingRight: 10 }}
            type="icon-timer-f"
            data-rh-at="bottom"
            data-rh={lg.gestempelte_arbeitszeit}
          />
          {title}
        </div>
      </TZModal.Head>
      <TZModal.Body style={{ minHeight: "50px" }}>
        <div className="trackedUserTopbar" style={{ paddingRight: data.length > 14 ? "20px" : undefined }}>
          <div className="userHeaderTopBar">{lg.mitarbeiter_plural}</div>
          {hasMultipleBranches && <div className="branchHeaderTopBar">{lg.standort}</div>}
          {renderTableHeader()}
        </div>
        <div className="listOfTrackedUsers">
          {(data as any).map((s: IShift | ITimeClocking) => {
            if (!s.userId) {
              return null;
            }
            const u = usersMap[s.userId];
            const userFull = decryptUser(u);
            const isClocking = (s as ITimeClocking).isDynamicClocking;
            const isShift = !isClocking;

            return (
              <div
                className="singleTrackedUser"
                key={s.id}
                onClick={() => isShift && dispatch(openModal(ShiftPopup, { shift: s as IShift }))}
              >
                <div className="singleTrackedUserName">{userFull.name}</div>
                {hasMultipleBranches && renderBranchCell(s)}
                {renderIsTime(s)}
              </div>
            );
          })}
        </div>
      </TZModal.Body>
    </TZModal>
  );
});
