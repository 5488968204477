import React from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { Button, Icon, notification } from "antd";

import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import moment from "moment";
import { BusyInjectorProps, busyInjector } from "../../../BusyInjector/BusyInjector";

import "./styles.scss";
import { acceptChangeRequest, rejectChangeRequest } from "../../../../actions/changeRequest";
import { closeModal } from "../../../../actions/modal";
import { IShift } from "../../../../shared/entities/IShift";
import { IChangeRequest } from "../../../../shared/entities/IChangeRequest";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { changeRequestRepository } from "../../../../repositories/ChangeRequestRepository";
import StartEndTimeInput from "../../ShiftPopup/StartEndTimeInput/StartEndTimeInput";

const mapStateToProps = (state: AppState) => ({
  canManage: selectSessionInfo(state).hasManagerPermissions(),
});

type OwnProps = {
  shift: IShift;
  changeRequest: IChangeRequest;
  onChangeRequestAccepted: (changeRequest: IChangeRequest) => void;
};

type StoreProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class _ChangeRequestReviewRow extends React.PureComponent<Props> {
  acceptClicked = async () => {
    const { changeRequest, shift } = this.props;
    await this.props.load(this.props.dispatch(acceptChangeRequest(changeRequest, shift)), "accept");
    this.props.onChangeRequestAccepted(changeRequest);

    const message = changeRequest.isFreeRequest
      ? lg.schicht_wurde_als_offene_schicht_freigegeben
      : lg.schichtzeit_wurde_angepasst;

    notification.success({ message });
    if (changeRequest.isFreeRequest) {
      this.props.dispatch(closeModal());
    }
  };

  rejectClicked = async () => {
    const { load, dispatch, changeRequest } = this.props;
    await load(dispatch(rejectChangeRequest(changeRequest)), "reject");
    notification.info({ message: lg.anfrage_abgelehnt });
  };

  removeClicked = async () => {
    const { load, dispatch, changeRequest } = this.props;
    await load(dispatch(changeRequestRepository.remove(changeRequest.id)), "remove");
    notification.info({ message: lg.anfrage_entfernt });
  };

  render() {
    const { changeRequest, canManage } = this.props;

    const displayDate = moment(changeRequest.createdAt as string).format("L - HH:mm");
    const dateInfoText = `${lg.antrag_erstellt_am} ${displayDate}`;

    return (
      <div className="changeRequestReviewRowMain">
        <label className="shiftPopupLabelMain">{lg.änderung}</label>
        <div className="content">
          {changeRequest.isFreeRequest && (
            <div className="fb row jCenter freeTextWrapper ">
              <div className="freeText">{lg.dienstfrei_beantragt}</div>
              <div className="infoIconWrapper" data-rh={displayDate}>
                <Icon type="info" />
              </div>
            </div>
          )}
          {!changeRequest.isFreeRequest && (
            <div className="timeRequestWrapper">
              <StartEndTimeInput
                onChange={() => {}}
                startTime={changeRequest.startTime as string}
                endTime={changeRequest.endTime as string}
                breakMinutes={changeRequest.breakMinutes}
                disabled
                noLabel
              />
              <div className="requestedTag" data-rh={dateInfoText}>
                {lg.beantragt}
              </div>
            </div>
          )}
          <div className="actionButtons">
            {canManage && (
              <Button
                id="change-request-entry-accept"
                type="primary"
                data-rh={lg.annehmen}
                icon="check"
                onClick={this.acceptClicked}
                loading={this.props.isLoading("accept")}
              />
            )}
            {canManage && (
              <Button
                id="change-request-entry-reject"
                type="danger"
                icon="close"
                data-rh={lg.ablehnen}
                onClick={this.rejectClicked}
                loading={this.props.isLoading("reject")}
              />
            )}
            {!canManage && (
              <Button
                id="change-request-entry-remove"
                type="default"
                icon="close"
                data-rh={lg.änderungsantrag_entfernen}
                onClick={this.removeClicked}
                loading={this.props.isLoading("remove")}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const ChangeRequestReviewRow = connect(mapStateToProps)(busyInjector(_ChangeRequestReviewRow));
