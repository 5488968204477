import React from "react";
import { connect } from "react-redux";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { Popover, Button, Badge } from "antd";
import { HandOverRequestListItem } from "../../../../../components/HandOverRequestListItem/HandOverRequestListItem";
import "./styles.scss";
import { AppState } from "../../../../../types/AppState";
import _ from "lodash";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { selectVisibleHandoverRequestItems } from "../../../../../selectors/HandOverRequestSelector";
import { selectShiftMap } from "../../../../../selectors/shiftMapSelector";

const mapStateToProps = (state: AppState) => {
  return {
    handOverRequests: selectVisibleHandoverRequestItems(state),

    shiftMap: selectShiftMap(state),
    sessionInfo: selectSessionInfo(state),
    selectedBranchId: state.ui.selectedBranch,
  };
};

type State = {
  popoverIsVisible: boolean;
};
type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

// This is a managers-only component
class HandOverRequestsButton_ extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      popoverIsVisible: false,
    };
  }

  render() {
    const { handOverRequests, shiftMap, selectedBranchId } = this.props;

    const handOverRequestsInBranch = handOverRequests.filter(
      (hR) => !selectedBranchId || hR.branchId === selectedBranchId
    );

    const sortedHandOverRequests = _.sortBy(handOverRequestsInBranch, [(o) => o.approvedByUserId]);

    if (!handOverRequests.length) {
      return null;
    }

    return (
      <>
        <Popover
          trigger={"click"}
          placement="bottomRight"
          visible={this.state.popoverIsVisible}
          onVisibleChange={(v) => this.setState({ popoverIsVisible: v })}
          content={
            <div className="openHandOverRequestsPopover fb column">
              <div className="head">{lg.schichtabgabe}</div>
              <div className="body">
                {sortedHandOverRequests.map((request) => {
                  return (
                    <HandOverRequestListItem
                      key={request.id}
                      shift={shiftMap[request.id]}
                      handOverRequest={request}
                      onOpenShiftPopup={() => this.setState({ popoverIsVisible: false })}
                      border="1px solid #ededed"
                    />
                  );
                })}
              </div>
              <div className="footer"></div>
            </div>
          }
        >
          <Badge count={handOverRequestsInBranch.length}>
            <Button
              id="hand-over-requests-button"
              style={{ marginLeft: 12 }}
              disabled={!handOverRequestsInBranch.length}
              data-rh={lg.schichtabgabe}
              data-rh-at="bottom"
              icon="team"
            />
          </Badge>
        </Popover>
      </>
    );
  }
}

export const HandOverRequestsButton =
  connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(HandOverRequestsButton_);
