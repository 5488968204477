import React from "react";
import "./styles.scss";
import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { WorkInterval } from "../../../../../shared/entities/IContract";

type Props = {
  state: WorkInterval;
  onChange: (type: WorkInterval) => void;
};

export default class WorkIntervalField extends React.PureComponent<Props, {}> {
  render() {
    const { state, onChange } = this.props;

    return (
      <div className="formFieldRow">
        <div className="">
          <Radio.Group
            onChange={(e: RadioChangeEvent) => {
              onChange(e.target.value);
            }}
          >
            <Radio.Button value={WorkInterval.monthly} checked={state === WorkInterval.monthly}>
              {lg.monatlich}
            </Radio.Button>
            <Radio.Button value={WorkInterval.weekly} checked={state === WorkInterval.weekly}>
              {lg.wöchentlich}
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>
    );
  }
}
