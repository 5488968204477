import { Button, Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../actions/modal";
import { envi, environment } from "../../env";
import { sendPost } from "../../frontend-core/actions/send";
import { useSelector } from "../../helpers/redux";
import { tierInfoSelector } from "../../selectors/TierInfoSelector";
import TZModal from "../TZModal/TZModal";
import "./styles.scss";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import { getCurrencySymbolByCode } from "../../shared/helpers/currencyHelpers";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { ICal } from "../../shared/types/ICal";
import { BusyInjectorProps, busyInjector } from "../BusyInjector/BusyInjector";
import BusyWrapper from "../BusyWrapper/BusyWrapper";

type Props = {};

export const CalendarSyncModal = busyInjector((props: Props & BusyInjectorProps) => {
  const tierInfo = useSelector(tierInfoSelector);
  const dispatch = useDispatch<DispFn>();
  const [iCalToken, setICalToken] = useState<string>();
  const tenantInfo = useSelector((s) => s.data.tenantInfo);
  const sessionInfo = useSelector(selectSessionInfo);
  const hasICalSyncing = sessionInfo.user.hasICalSyncing;

  const iCalLink = iCalToken
    ? `webcal://storage.googleapis.com/${envi.getFirebaseEnv()}.appspot.com/${tenantInfo.id}/ics/${
        sessionInfo.user.id
      }/${iCalToken}.ics`
    : "";

  useEffect(() => {
    if (hasICalSyncing) {
      getICal();
    }
  }, []);

  const getICal = async () => {
    const { iCalToken } = (await props.load(dispatch(sendPost("/api/get-i-cal", {})))) as any;
    setICalToken(iCalToken);
  };

  return (
    <TZModal className="tierPicker">
      <BusyWrapper isBusy={props.isLoading()}>
        <TZModal.Head>{lg.kalender_synchronisation}</TZModal.Head>
        <TZModal.Body className="content" style={{ padding: 24 }}>
          {!hasICalSyncing && <Button type="primary" onClick={getICal} children={lg.jetzt_aktivieren} />}
          {hasICalSyncing && iCalToken && (
            <>
              <p style={{ maxWidth: 450 }}>
                {lg.Adressen}
              </p>
              <input value={iCalLink} disabled />
              <Button
                style={{ marginTop: 12 }}
                children={lg.in_zwischenablage_kopieren}
                onClick={() => {
                  navigator.clipboard.writeText(iCalLink);
                  notification.success({
                    message: lg.in_zwischenablage_kopiert,
                  });
                }}
              />
            </>
          )}
        </TZModal.Body>
      </BusyWrapper>
    </TZModal>
  );
});
