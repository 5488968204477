import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { Button, Checkbox, Icon, Input } from "antd";
import TZModal from "../../TZModal/TZModal";
import { closeModal } from "../../../actions/modal";
import "./styles.scss";

import { Formik, Form, FormikProps, FormikValues } from "formik";
import AvFormikDatePicker from "../../AvFormikDatePicker/AvFormikDatePicker";
import AvFormikTimePicker from "../../AvFormikTimePicker/AvFormikTimePicker";
import AvOption from "../../AvOption/AvOption";
import WeekDayPicker from "./weekDayPicker/WeekDayPicker";
import { availabilityRepository } from "../../../repositories/availabilityRepository";
import { busyInjector, BusyInjectorProps } from "../../BusyInjector/BusyInjector";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import moment from "moment";
import { SDateFormat } from "../../../shared/helpers/SimpleTime";
import { IAvailability } from "../../../shared/entities/IAvailability";
import { Raw } from "../../../shared/entities/IResource";
import { WeekDays } from "../../../shared/entities/IContract";
import { selectRosterSettingsByUser } from "../../../selectors/rosterSettingsByUserSelector";
import TextArea from "antd/lib/input/TextArea";
import AvFormikTextArea from "../../AvFormikTextArea/AvFormikTextArea";

const lockedButtonStyle = {
  background: "#fecaff",
  color: "#fb22ff",
  borderColor: "#fb22ff",
};

type FormValues = Partial<IAvailability>;

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
  userRosterSetting: selectRosterSettingsByUser(state)[ownProps.userId],
  sessionInfo: selectSessionInfo(state),
});

type State = {
  showGeneralErrors: boolean;
  isAvailable: boolean;
  showNoteField: boolean;
};

type OwnProps = {
  availability?: IAvailability;
  userId: string;
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & BusyInjectorProps & DispatchBaseProps;

class AvailabilityModal extends PureComponent<Props, State> {
  isCreationMode = !this.props.availability;

  constructor(props: Props) {
    super(props);

    this.state = {
      showGeneralErrors: false,
      // keeping track of this form-prop, because its needed outside the form aswell ( in the modal-head )
      isAvailable: props.availability ? props.availability.isAvailable : true,
      showNoteField: !!props.availability?.note,
    };
  }

  handleSubmit = async (values: FormikValues) => {
    // formik has strange behaviour that I couldnt understand/fix so I am handling
    // error like this manually and dirty... what a shame
    const errors = this.validate(values);
    if (Object.values(errors)[0]) {
      return;
    }

    const freshAvailability: Raw<IAvailability> = {
      ...this.props.availability,
      ...values,
      userId: this.isCreationMode ? this.props.userId : this.props.availability!.userId,
      endDate: values.isSingleDay ? values.startDate : values.endDate,
      weekDays: values.isSingleDay ? WeekDays : values.weekDays,
    } as Raw<IAvailability>;

    await this.props.load(
      this.props.dispatch(
        this.isCreationMode
          ? availabilityRepository.create(freshAvailability)
          : availabilityRepository.update(freshAvailability as IAvailability)
      ),
      "saving"
    );

    this.props.dispatch(closeModal());
  };

  delete = async () => {
    await this.props.dispatch(availabilityRepository.remove(this.props.availability!.id));
    this.props.dispatch(closeModal());
  };

  renderForm = (formikProps: FormikProps<FormValues>) => {
    const toggledWholeDay = (checked: boolean) => {
      formikProps.setFieldValue("isWholeDay", checked);

      if (checked) {
        formikProps.setFieldValue("startTime", undefined);
        formikProps.setFieldValue("endTime", undefined);
      }
    };

    // formik has strange behaviour that I douldnt understand/fix so I am handling
    // error like this manually and dirty... what a shame
    const errors = this.validate(formikProps.values);
    const error = Object.values(errors)[0];

    return (
      <Form className="fb userFormMain">
        {error && !!formikProps.submitCount && <div className="errorBar">{error as string}</div>}

        <div className="formContent">
          <div className="fb row">
            <div className="cell">
              <AvOption
                options={[
                  {
                    label: lg.verfügbar,
                    value: true,
                    icon: "check",
                    style: { width: 172, textAlign: "center" },
                    disabled:
                      !this.props.sessionInfo.hasManagerPermissions() &&
                      !this.props.userRosterSetting.usersCanSetAvailabilities,
                  },
                  {
                    label: lg.unverfügbar,
                    value: false,
                    icon: "stop",
                    style: { width: 172, textAlign: "center" },
                    disabled:
                      !this.props.sessionInfo.hasManagerPermissions() &&
                      !this.props.userRosterSetting.usersCanSetUnavailabilities,
                  },
                ]}
                value={!!formikProps.values["isAvailable"]}
                onChange={(value: boolean) => {
                  formikProps.setFieldValue("isAvailable", value);
                  this.setState({ isAvailable: value });
                }}
              ></AvOption>
            </div>
          </div>
          <div className="fb row">
            <div className="cell">
              <AvOption
                options={[
                  { label: lg.Einmalig, value: true, style: { width: 172, textAlign: "center" } },
                  { label: lg.wiederholend, value: false, style: { width: 172, textAlign: "center" } },
                ]}
                value={!!formikProps.values["isSingleDay"]}
                onChange={(isSingleDay: boolean) => {
                  formikProps.setFieldValue("isSingleDay", isSingleDay);
                  !isSingleDay && formikProps.setFieldValue("endDate", undefined);
                }}
              ></AvOption>
            </div>
          </div>
          {!formikProps.values["isSingleDay"] ? (
            <>
              <div className="fb row datePickerRow">
                <div className="cell">
                  <AvFormikDatePicker
                    label={lg.startdatum}
                    fieldName="startDate"
                    formikProps={formikProps}
                    noError={true}
                  />
                </div>
                <div className="cell">
                  <AvFormikDatePicker
                    label={lg.enddatum}
                    fieldName="endDate"
                    formikProps={formikProps}
                    noError={true}
                    placeholder={lg.unendlich}
                  />
                </div>
              </div>
              <div className="fb row">
                <WeekDayPicker
                  value={formikProps.values["weekDays"] || []}
                  onChange={(value) => formikProps.setFieldValue("weekDays", value)}
                ></WeekDayPicker>
              </div>
            </>
          ) : (
            <div className="fb row">
              <div className="cell">
                <AvFormikDatePicker
                  label={lg.datum}
                  fieldName="startDate"
                  onChange={(m) => {
                    formikProps.setFieldValue("endDate", m); //endDate is hidden, only startDate is set for SingleDay
                  }}
                  formikProps={formikProps}
                  noError={true}
                />
              </div>
            </div>
          )}
          <div className="fb row">
            <div className="cell">
              <AvFormikTimePicker
                label={lg.von_start}
                fieldName="startTime"
                formikProps={formikProps}
                disabled={!!formikProps.values["isWholeDay"]}
                noError={true}
              />
            </div>
            <div className="cell">
              <AvFormikTimePicker
                label={lg.bis}
                fieldName="endTime"
                formikProps={formikProps}
                disabled={!!formikProps.values["isWholeDay"]}
                noError={true}
              />
            </div>
            <div className="cell wholeDayCell ">
              <Checkbox
                checked={!!formikProps.values["isWholeDay"]}
                onChange={(e) => {
                  toggledWholeDay(e.target.checked);
                }}
              >
                {lg.ganzer_tag}
              </Checkbox>
            </div>
          </div>
          <div className="fb row" style={{ paddingTop: 12 }}>
            {this.state.showNoteField ? (
              <AvFormikTextArea
                // style={{ margin: "16px 0", height: 100 }}
                fieldName="note"
                formikProps={formikProps}
                placeholder={lg.notiz}
                autoSize={{ minRows: 2 }}
              />
            ) : (
              <Button
                id="availability-add-note-btn"
                style={{ width: 168 }}
                children={lg.notiz_hinzufügen}
                icon={"form"}
                onClick={() => {
                  this.setState({ showNoteField: true });
                }}
              />
            )}
          </div>
        </div>
        <TZModal.Footer>
          <div
            className="lockWrapper"
            data-rh={`Ist ${formikProps.values["isLocked"] ? "nicht" : ""} editarbar vom Mitarbeiter`}
          >
            {this.props.sessionInfo.hasManagerPermissions() && (
              <AvOption
                options={[
                  {
                    label: "",
                    value: true,
                    icon: "lock",

                    style: formikProps.values["isLocked"] ? lockedButtonStyle : {},
                  },
                  {
                    label: "",
                    value: false,
                    icon: "unlock",
                  },
                ]}
                value={!!formikProps.values["isLocked"]}
                onChange={(value: boolean) => formikProps.setFieldValue("isLocked", value)}
              />
            )}
            {!this.props.sessionInfo.hasManagerPermissions() && formikProps.values["isLocked"] && (
              <div className="isLockedBox">
                <Icon type="lock" />
              </div>
            )}
          </div>
          <Button
            id="save-availability"
            type="primary"
            onClick={formikProps.submitForm}
            loading={this.props.isLoading("saving")}
            disabled={!this.props.sessionInfo.hasManagerPermissions() && !!formikProps.values["isLocked"]}
          >
            {lg.Speichern}
          </Button>
          {!this.isCreationMode && (
            <Button
              id="delete-availability"
              type="danger"
              icon="delete"
              onClick={async () =>
                this.props.load(
                  this.props.dispatch((e: any) => this.delete()),
                  "deleting"
                )
              }
              loading={this.props.isLoading("deleting")}
              disabled={!this.props.sessionInfo.hasManagerPermissions() && !!formikProps.values["isLocked"]}
            />
          )}
        </TZModal.Footer>
      </Form>
    );
  };

  validate = (values: FormValues) => {
    const errors: any = {};

    const { startDate, endDate, startTime, endTime, weekDays, isWholeDay, isSingleDay } = values;

    if (!isWholeDay && (!startTime || !endTime)) {
      errors.isWholeDay = lg.von_bis_uhrzeit_angeben_oder_die_option_ganzer_tag_wählen;
    }

    if (!startTime && !endTime && !isWholeDay) {
      errors.isWholeDay = lg.wähle_ein_zeit_intervall_oder_die_option_ganzer_tag;
    }

    if (!isSingleDay && startDate && endDate && startDate > endDate) {
      errors.endDate = lg.end_datum_darf_nicht_vor_dem_start_datum_liegen;
    }

    if (startTime && endTime && startTime > endTime) {
      errors.endTime = lg.end_zeit_darf_nicht_vor_der_start_zeit_liegen;
    }

    if (!isSingleDay && (!weekDays || !weekDays.length)) {
      errors.weekDays = lg.wähle_mindestens_einen_wochentag_aus;
    }

    if (!startDate) {
      errors.startDate = isSingleDay ? lg.das_datum_ist_erforderlich : lg.das_start_datum_ist_erfordrlich;
    }

    return errors;
  };

  render() {
    const availability = this.props.availability;
    const iconType = this.state.isAvailable ? "check-circle" : "stop";
    const iconColor = this.state.isAvailable ? "#4caf50" : "#ff4d4f";
    const initialValues =
      availability ||
      ({
        startDate: moment().format(SDateFormat),
        endDate: moment().add(1, "year").format(SDateFormat),
        startTime: undefined,
        endTime: undefined,
        isAvailable: !!this.state.isAvailable,
        weekDays: WeekDays,
        isWholeDay: true,
        isSingleDay: true,
        isLocked: this.props.sessionInfo.hasManagerPermissions(),
      } as Raw<IAvailability>);

    return (
      <TZModal>
        <TZModal.Head
          icon={iconType}
          iconColor={iconColor}
          iconSize="20px"
          title={this.state.isAvailable ? lg.verfügbar : lg.unverfügbar}
        ></TZModal.Head>
        <TZModal.Body>
          <div className="availabilityModalMain">
            <Formik
              initialValues={initialValues}
              onSubmit={this.handleSubmit}
              children={this.renderForm}
              validate={this.validate}
            />
          </div>
        </TZModal.Body>
      </TZModal>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  busyInjector(AvailabilityModal)
);
