import { toDoubleDigit } from "./timeHelpers";
import { IContract } from "./../entities/IContract";
import { isWorkDay } from "./credit";
import { getWeekDay } from "./weekDaysList";
import { WeekDay, WeekDays } from "../entities/IContract";
import { SDateFormat } from "./SimpleTime";
import moment from "moment";

export const makeSimpleDate = (year: number, month: number, day: number) => {
  year < 1000 && console.error("YEAR NEEDS TO BE DOUBLE DIGIT: " + year);
  return `${year}-${toDoubleDigit(month)}-${toDoubleDigit(day)}`;
};

const isLeapYear = (year: number): boolean => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const getDaysInMonth = (date: string): number => {
  const month = parseInt(date.substring(5, 7));
  const year = parseInt(date.substring(0, 4));
  const monthsWith31Days = [1, 3, 5, 7, 8, 10, 12];
  const hasMonth31Days = monthsWith31Days.includes(month);
  if (isLeapYear(year) && month === 2) {
    return 29;
  }
  if (!isLeapYear(year) && month === 2) {
    return 28;
  }
  return hasMonth31Days ? 31 : 30;
};

export const addSimpleDays = (date: string, daysToAdd: number): string => {
  const day = parseInt(date.substring(8, 10));
  const month = parseInt(date.substring(5, 7));
  const year = parseInt(date.substring(0, 4));

  let currentMonth = month;
  let currentYear = year;
  let currentDay = day;
  let currentDate = date;

  if (day + daysToAdd <= getDaysInMonth(date)) {
    currentDay = day + daysToAdd;
    currentDate = makeSimpleDate(year, month, currentDay);
  } else {
    for (let i = 1; i <= daysToAdd; i++) {
      const daysInMonth = getDaysInMonth(currentDate);
      const isLastDayOfMonth = currentDay === daysInMonth;
      const isLastMonthOfYear = currentMonth === 12;
      const nextMonth = isLastMonthOfYear ? 1 : currentMonth + 1;

      currentDay = isLastDayOfMonth ? 1 : currentDay + 1;
      isLastDayOfMonth && (currentMonth = nextMonth);
      isLastDayOfMonth && isLastMonthOfYear && currentYear++;
      currentDate = makeSimpleDate(currentYear, currentMonth, currentDay);
    }
  }
  const monthStr = toDoubleDigit(currentMonth);
  const dayStr = toDoubleDigit(currentDay);
  return `${currentYear}-${monthStr}-${dayStr}`;
};

export const generateDatesList = (startDate: string, endDate: string): string[] => {
  const datesList: string[] = [];
  let curDate = startDate;
  while (curDate <= endDate) {
    datesList.push(curDate);
    curDate = addSimpleDays(curDate, 1);
  }
  return datesList;
};

export const getMonthIntervalStartDate = (date: string, intervalStartDay = 1): string => {
  // generally the first of an monthly interval is the first day of the month
  // if there is a monthly contract the intervalStartDay can differ so a month intervall can go from 16.SEP to 15 OKT
  // so the he month-intervall could start in the previous month
  let year = parseInt(date.substring(0, 4));
  let month = parseInt(date.substring(5, 7));
  let day = parseInt(date.substring(8, 10));
  const isNextMonth = day < intervalStartDay;
  year = isNextMonth && month === 1 ? year - 1 : year;
  month = isNextMonth ? (month === 1 ? 12 : month - 1) : month;
  day = intervalStartDay;
  return makeSimpleDate(year, month, day);
};

export const getMonthIntervalEndDate = (date: string, intervalStartDay = 1): string => {
  const startDate = getMonthIntervalStartDate(date, intervalStartDay);
  const daysInMonth = getDaysInMonth(startDate);
  return addSimpleDays(startDate, daysInMonth - 1);
};

export const getWorkDaysInMonth = (date: string, contract: IContract): number => {
  const intervalStartDate = getMonthIntervalStartDate(date, contract.intervalStartDay);
  const firstDay: WeekDay = WeekDays[getWeekDay(intervalStartDate)];
  const monthLength = getDaysInMonth(intervalStartDate);

  let workDays = 0;
  let curDayIndex: number = WeekDays.indexOf(firstDay);

  for (let i = 0; i < monthLength; i++) {
    const curDay = WeekDays[curDayIndex];
    isWorkDay(contract.dailyQuota[curDay]) && workDays++;
    curDayIndex = curDayIndex === 6 ? 0 : curDayIndex + 1;
  }

  return workDays;
};

export const getMomList = (startMom: moment.Moment, endMom: moment.Moment): moment.Moment[] => {
  const count = endMom.diff(startMom, "days");
  const currentDay = startMom;
  const days = [] as any;
  for (let i = 0; i <= count; i++) {
    days.push(currentDay.clone());
    currentDay.add(1, "day");
  }
  return days;
};

export const getWeekStartsInRange = (startDate: string, endDate: string): string[] => {
  const startMom = moment(startDate, SDateFormat);
  const endMom = moment(endDate, SDateFormat);
  const momList = getMomList(startMom, endMom);

  const mondays = {} as any;
  momList.forEach((mom) => {
    mondays[mom.startOf("isoWeek").format(SDateFormat)] = true;
  });
  return Object.keys(mondays);
};

export const getMonthStartsInRange = (startDate: string, endDate: string): string[] => {
  const startMom = moment(startDate, SDateFormat);
  const endMom = moment(endDate, SDateFormat);
  const momList = getMomList(startMom, endMom);
  const mondays = {} as any;
  momList.forEach((mom) => {
    mondays[mom.startOf("month").format(SDateFormat)] = true;
  });
  return Object.keys(mondays);
};

export const getPrevSimpleDate = (date: string) => {
  return moment(date, SDateFormat).add(-1, "day").format(SDateFormat);
};

type OverlapOptions = { equalIsOverlap: boolean };
const defaultOverlapOptions = { equalIsOverlap: true };
type RangesOverlap = (
  xs: string | string,
  xe: string | string,
  ys: string | string,
  ye: string | string,
  overlapOptions?: OverlapOptions
) => boolean;
export const rangesOverlap: RangesOverlap = (xStart, xEnd, yStart, yEnd, overlapOptions = defaultOverlapOptions) => {
  return overlapOptions.equalIsOverlap ? yStart <= xEnd && yEnd >= xStart : yStart < xEnd && yEnd > xStart;
};
