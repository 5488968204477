import React from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { Icon } from "antd";
import _ from "lodash";

import { AppState } from "../../../types/AppState";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { EmptyContent } from "../../../components/EmptyContent/EmptyContent";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { openModal } from "../../../actions/modal";
import { OpenShiftItem } from "../../../components/OpenShiftItem/OpenShiftItem";
import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import BusyWrapper from "../../../components/BusyWrapper/BusyWrapper";
import { IShift } from "../../../shared/entities/IShift";
import { ShiftPopup } from "../../../components/ShiftPopup/ShiftPopup/ShiftPopup";
import { HintPopover } from "../../../components/HintPopover/HintPopover";
import moment from "moment";
import { SDateFormat } from "../../../shared/helpers/SimpleTime";
import { openUpcomingShiftsSelector } from "../../../selectors/openUpcomingShiftsSelector";
import cn from "classnames";
import { CountToggler } from "../CountToggler/CountToggler";

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const sessionInfo = selectSessionInfo(state);
  return {
    sessionInfo,
    publishedOpenShifts: openUpcomingShiftsSelector(state),
    canManage: sessionInfo.hasManagerPermissions(),
    currentUser: sessionInfo.user,
  };
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;
type State = { isOpen: boolean };

class _OpenShiftsPaper extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isOpen: false };
  }
  openModal = (shift: IShift) => {
    this.props.dispatch(openModal(ShiftPopup, { shift }));
  };

  render() {
    const { publishedOpenShifts, canManage, sessionInfo } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="cell">
        <div className="content">
          <HintPopover
            hideHint={!sessionInfo.hasManagerPermissions()}
            hint={lg.erstelle_offene_schichten_im_dienstplan_sodass_die_mitarbeiter_sich_auf_diese_bewerben_können}
          >
            <div className="dashboardPaper openShiftsPaperContent">
              <div className={cn({ isOpen }) + " title expandable"} onClick={() => this.setState({ isOpen: !isOpen })}>
                <Icon type="exception" style={{ marginRight: "12px", color: "#1a90ff" }} />
                {lg.offene_capital} <span style={{ color: "#1a90ff", marginLeft: 6 }}>{lg.schichten}</span>
                <CountToggler
                  count={publishedOpenShifts.length}
                  isOpen={this.state.isOpen}
                  style={{ marginLeft: "auto" }}
                />
              </div>
              {isOpen && (
                <BusyWrapper isBusy={this.props.isLoading()} style={{ width: "100%" }}>
                  <div className="body">
                    <EmptyContent
                      show={!publishedOpenShifts.length}
                      text={lg.keine_offenen_schichten}
                      tooltip={canManage ? lg.in_veröffentlichten_dienstplänen : undefined}
                    />
                    {_.sortBy(publishedOpenShifts, "date").map((openShift) => (
                      <OpenShiftItem
                        key={openShift.id}
                        appliedShift={openShift}
                        openModal={() => this.openModal(openShift)}
                        displayCheck={!canManage && true}
                      />
                    ))}
                  </div>
                </BusyWrapper>
              )}
            </div>
          </HintPopover>
        </div>
      </div>
    );
  }
}

export const OpenShiftsPaper = withErrorBoundary(connect(mapStateToProps)(busyInjector(_OpenShiftsPaper)));
