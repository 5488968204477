import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import { getNameInitials } from "../../../../helpers/general";
import { IUser, IUserFull } from "../../../../shared/entities/IUser";
import { IUserThreadInfo } from "../../../../shared/entities/IUserThreadInfo";
import { STimeFormat } from "../../../../shared/helpers/SimpleTime";
import { decryptUser, getUserName } from "../../../../shared/helpers/userHelpers";
import { UserInfo } from "../../../../shared/helpers/UserInfo";
import { Map } from "../../../../shared/types/general";
import { getChatTitle, getImageOfThread } from "../../chatHelpers";
import { ThreadsListStyles } from "./styles";
import AvatarDefaultImageSource from "./../../avatar-default-image.png";
import GroupAvatarDefaultImageSource from "./../../group-avatar-default-image.png";

type Props = {
  userThreadInfo: IUserThreadInfo;
  userMap: Map<IUser>;
  setSelectedThreadId: (id: string) => void;
  selectedThreadId: string | undefined;
  sessionInfo: UserInfo;
  isDisabled?: boolean;
};

export const ThreadListItem = React.memo(({ userMap, userThreadInfo, ...props }: Props) => {
  const dispatch = useDispatch<DispFn>();

  const t = userThreadInfo;
  const title = getChatTitle(t, userMap, props.sessionInfo.user.id);
  const isSelected = props.selectedThreadId === t.id;
  const isGroupChat = !!userThreadInfo?.title?.length;

  const now = moment();
  const dateText = () => {
    const m = moment(t.lastMessageTimestamp);
    const base = m.clone().set("hours", 0).set("minutes", 0);
    const daysDiff = now.diff(base, "days");
    if (daysDiff === 0) {
      return m.format("HH:mm");
    } else if (daysDiff === 1) {
      return "Gestern";
    } else if (daysDiff < 6) {
      return m.format("dddd");
    } else {
      return m.format("L");
    }
  };

  if (userThreadInfo.userIds.length === 0) {
    return null;
  }

  const photoUrl = getImageOfThread(userThreadInfo, userMap, props.sessionInfo.user.id);

  return (
    <div
      onClick={() => {
        if (!props.isDisabled) {
          props.setSelectedThreadId(t.id);
        }
      }}
      className="fb row shrink"
      style={{
        ...ThreadsListStyles.listItem,
        background: isSelected ? "#ebebeb" : "#ffffff",
        opacity: props.isDisabled ? 0.7 : 1,
      }}
    >
      <div className="fb row aCenter shrink" style={{ overflow: "hidden" }}>
        {photoUrl ? (
          <img src={photoUrl} style={ThreadsListStyles.profileBox} />
        ) : (
          <div style={ThreadsListStyles.profileBox} />
        )}
        <div className="fb column shrink" style={{ overflow: "hidden" }}>
          <div
            style={{
              fontSize: 16,
              textOverflow: "ellipsis",
              overflow: "hidden",
              flex: "1 1 auto",
              whiteSpace: "pre",
              marginRight: 24,
            }}
          >
            {title || "NO TITLE"}
          </div>
          {!!t.lastMessageText && (
            <div
              style={{
                opacity: 0.6,
                fontSize: 12,
                flexShrink: 1,
                textOverflow: "ellipsis",
                overflow: "hidden",
                flex: "1 1 auto",
                whiteSpace: "nowrap",
                display: "inline-block",
                maxWidth: "95%",
              }}
            >
              {t.lastMessageText}
            </div>
          )}
        </div>
      </div>
      <div className="fb row aCenter">
        <div style={ThreadsListStyles.lastMessageDate}>{dateText()}</div>
        {t.hasUnseenMessages && <div style={ThreadsListStyles.unseenMessagesbubble} />}
      </div>
    </div>
  );
});
