import React from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import TZModal from "../../../components/TZModal/TZModal";
import { DatePicker, Button, Icon, notification } from "antd";
import moment, { Moment } from "moment";
import { closeModal } from "../../../actions/modal";

import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import { userRepository } from "../../../repositories/userRepository";
import { IUser } from "../../../shared/entities/IUser";
import { SDateFormat } from "../../../shared/helpers/SimpleTime";
import { shiftRepository } from "../../../repositories/shiftRepository";
import { IFilterExpression } from "../../../shared/types/queryParams";
import { delteAllShiftRelations } from "../../../actions/shift";
import { addSimpleDays } from "../../../shared/helpers/dateHelpers";
import { shiftRepeatRepository } from "../../../repositories/shiftRepeatRepository";
import { getUserName } from "../../../shared/helpers/userHelpers";
import { deleteUserShifts } from "../../../actions/user";
import { localizeFormat } from "../../../helpers/dateFormatHelper";
import _ from "lodash";

const mapStateToProps = (state: AppState, props: OwnProps) => {
  return {};
};

type OwnProps = {
  user: IUser;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;
type State = {
  selectedDate: Moment;
  deleteConfirmed: boolean;
};

class _UserDeactivateModal extends React.PureComponent<Props, State> {
  state = {
    selectedDate: moment(),
    deleteConfirmed: false,
  };

  deactivateUser = async () => {
    const lastWorkDay = moment(this.state.selectedDate).format(SDateFormat);
    const _user = { ...this.props.user, lastWorkDay };

    const today = moment().format(SDateFormat);
    const effectiveLastWorkDay = _.max([today, lastWorkDay]);

    await this.props.load(
      Promise.all([
        this.props.dispatch(userRepository.update(_user)),
        this.props.dispatch(deleteUserShifts(this.props.user.id, false, effectiveLastWorkDay)),
      ])
    );

    this.props.dispatch(closeModal());
    notification.success({
      message: lg.letzter_arbeitstag_wurde_übernommen,
      duration: 1.2,
    });
  };

  render() {
    const { user, isLoading } = this.props;

    return (
      <>
        <TZModal style={{ width: "400px" }}>
          <TZModal.Head
            title={lg.mitarbeiter_deaktivieren}
            icon="question-circle"
            iconColor="#FF9800"
            headStyle={{ border: "none" }}
          >
            <Icon type="check"></Icon>
          </TZModal.Head>
          <div className="userDeactivateModalBody">
            <div className="infoText">
              {lg.letzter_arbeitstag_von} <span style={{ fontWeight: "bold" }}>{getUserName(user)}</span>:
            </div>
            <div className="deactivateRow">
              <DatePicker
                defaultValue={moment()}
                format={"L"}
                style={{ width: "140px", border: "none" }}
                placeholder={lg.datum}
                onChange={(date) => date && this.setState({ selectedDate: date })}
                allowClear={false}
              />
              <Button
                id="deactivate-user"
                type="primary"
                style={{ marginLeft: 8 }}
                children={lg.deaktivieren}
                onClick={() => !isLoading() && this.deactivateUser()}
                loading={isLoading()}
              />
            </div>
          </div>
        </TZModal>
      </>
    );
  }
}

export const UserDeactivateModal = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  busyInjector(_UserDeactivateModal)
);
