import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../types/AppState";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import TZModal from "../TZModal/TZModal";
import { selectVisibleBranches } from "../../selectors/VisibleBranchesSelector";
import { Button } from "antd";
import { startTimeClockMode } from "../../actions/timeClockMode";
import { BasicSelect } from "../BasicSelect/BasicSelect";
import { closeModal } from "../../actions/modal";

const mapStateToProps = (state: AppState) => {
  return {
    sessionInfo: selectSessionInfo(state),
    selectableBranches: selectVisibleBranches(state).filter((b) => b.isClockingEnabled),
  };
};

type State = {
  selectedBranchIds: string[];
};
type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _StartTimeClockModePopup extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedBranchIds: props.selectableBranches.length > 1 ? [] : [props.selectableBranches[0]?.id],
    };
  }

  render() {
    if (!this.props.selectableBranches.length) {
      return (
        <TZModal>
          <TZModal.Head>{lg.kein_standort_mit_aktivierter_stempeluhr}</TZModal.Head>
          <TZModal.Body style={{ padding: 22 }}>
            {lg.die_stempeluhr_funktion_eines_standortes_können_sie_unter_einstellungen_standorte_aktivieren}
          </TZModal.Body>
        </TZModal>
      );
    }

    return (
      <TZModal style={{ width: 460 }}>
        <TZModal.Head>{lg.stempeluhr_station}</TZModal.Head>
        <TZModal.Body>
          <div style={{ padding: 20 }}>
            {this.props.selectableBranches.length > 1 && (
              <div style={{ marginBottom: 20 }}>
                <BasicSelect
                  mode="multiple"
                  id="pick-branch"
                  value={this.state.selectedBranchIds}
                  placeholder={lg.standorte_auswählen}
                  options={this.props.selectableBranches.map((b) => ({
                    value: b.id,
                    label: b.name,
                  }))}
                  onChange={(v) => this.setState({ selectedBranchIds: v })}
                />
              </div>
            )}

            <div>
              {
                lg.zum_aktivieren_der_stempeluhr_station_wird_aplano_beendet_somit_kann_ihr_zugang_nicht_von_unbefugten_verwendet_werden
              }
            </div>

            <Button
              id="start-time-clock-mode"
              disabled={!this.state.selectedBranchIds?.length}
              children={lg.starten}
              style={{ marginTop: 20, width: "100%" }}
              type="primary"
              onClick={() => {
                this.props.dispatch(closeModal());
                this.props.dispatch(startTimeClockMode(this.state.selectedBranchIds));
              }}
            />
          </div>
        </TZModal.Body>
      </TZModal>
    );
  }
}

export const StartTimeClockModePopup =
  connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(_StartTimeClockModePopup);
