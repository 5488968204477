import { changeRequestRepository } from "./../repositories/ChangeRequestRepository";
import { shiftRepository } from "../repositories/shiftRepository";
import moment from "moment";
import { Pusher } from "./pushNote";
import { DispFn } from "../frontend-core/types/thunkTypes";
import { IChangeRequest } from "../shared/entities/IChangeRequest";
import { IShift } from "../shared/entities/IShift";
import * as Sentry from "@sentry/browser";

export const acceptChangeRequest = (
  changeRequest: IChangeRequest,
  shift: IShift
) => async (dispatch: DispFn): Promise<any> => {
  Sentry.addBreadcrumb({
    message: "accept changeRequest",
    data: { changeRequest, shift },
  });

  await dispatch(changeRequestRepository.remove(changeRequest.id));

  if (changeRequest.isFreeRequest) {
    await dispatch(
      shiftRepository.update({
        ...shift,
        userId: undefined,
        requiredUsersAmount: 1,
        repeatId: undefined,
      })
    );
  } else {
    await dispatch(
      shiftRepository.update({
        ...shift,
        startTime: changeRequest.startTime as string,
        endTime: changeRequest.endTime as string,
        breakMinutes: changeRequest.breakMinutes || 0,
      })
    );
  }

  dispatch(Pusher.changeRequestAccepted(changeRequest));
};

export const rejectChangeRequest = (changeRequest: IChangeRequest) => async (
  dispatch: DispFn
): Promise<any> => {
  await dispatch(changeRequestRepository.remove(changeRequest.id));
  dispatch(Pusher.changeRequestRejected(changeRequest));
};

export const createChangeRequest = (
  shift: IShift,
  isFreeRequest: boolean,
  startTime: string,
  endTime: string,
  breakMinutes: number
) => async (dispatch: DispFn): Promise<any> => {
  if (!shift.userId) {
    return;
  }
  await dispatch(
    changeRequestRepository.create({
      id: shift.id,
      userId: shift.userId,
      date: shift.date,
      branchId: shift.branchId,
      createdAt: moment().toISOString(),
      isFreeRequest,
      startTime,
      endTime,
      breakMinutes,
    })
  );
  dispatch(Pusher.changeRequestCreated(shift));
};
