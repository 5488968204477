import { Moment } from "moment";
import React from "react";
import { simpleDateToChatText } from "../chatHelpers";

type Props = {
  date: string;
  now: Moment;
};

export const DateRow = React.memo(({ date, now }: Props) => {
  return (
    <div
      className="fb row"
      style={{
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: 10,
        paddingTop: 4,
      }}
    >
      <div
        style={{
          padding: 4,
          paddingLeft: 6,
          paddingRight: 6,
          borderRadius: 6,
          boxShadow: "0 1px .5px rgba(0,0,0,.13)",
          backgroundColor: "#ffffff",
          alignItems: "center",
        }}
      >
        {simpleDateToChatText(date, now)}
      </div>
    </div>
  );
});
