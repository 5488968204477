import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import "./styles.scss";
import { Icon } from "antd";
import { EmptyContent } from "../../../components/EmptyContent/EmptyContent";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";

import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { openModal } from "../../../actions/modal";
import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import BusyWrapper from "../../../components/BusyWrapper/BusyWrapper";
import _ from "lodash";
import { ChangeRequestItem } from "../../../components/ChangeRequestItem/ChangeRequestItem";
import { selectChangeRequests } from "../../../selectors/changeRequestsSelector";
import { IShift } from "../../../shared/entities/IShift";
import { ShiftPopup } from "../../../components/ShiftPopup/ShiftPopup/ShiftPopup";
import { HintPopover } from "../../../components/HintPopover/HintPopover";
import { selectShiftMap } from "../../../selectors/shiftMapSelector";
import { selectIsFreshTenant } from "../../../selectors/selectIsFreshTenant";
import cn from "classnames";

const mapStateToProps = (state: AppState) => {
  return {
    changeRequests: selectChangeRequests(state),
    sessionInfo: selectSessionInfo(state),
    shiftMap: selectShiftMap(state),
    isFreshTenant: selectIsFreshTenant(state),
  };
};
type State = { isOpen: boolean };
type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class _ChangeRequestsPaper extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  openModal = (shift: IShift) => {
    this.props.dispatch(openModal(ShiftPopup, { shift }));
  };

  render() {
    const { shiftMap, changeRequests, sessionInfo, isFreshTenant } = this.props;
    const { isOpen } = this.state;
    const sortedChangeRequests = _.sortBy(changeRequests, "date").reverse();

    if (!isFreshTenant && !changeRequests.length) {
      return null;
    }

    return (
      <div className="cell">
        <div className="content">
          <HintPopover
            hideHint={!sessionInfo.hasManagerPermissions()}
            placement="right"
            hint={lg.mitarbeiter_können_änderung_ihrer_schichten_beantragen_diese_kannst_du_hier_annehmen_oder_ablehnen}
          >
            <div className="dashboardPaper">
              <div className={cn({ isOpen }) + " title expandable"} onClick={() => this.setState({ isOpen: !isOpen })}>
                <Icon type="scissor" style={{ marginRight: "12px", color: "#1a90ff" }} />
                {lg.änderungsanträge}
              </div>
              {isOpen && (
                <BusyWrapper isBusy={this.props.isLoading()} style={{ width: "100%" }}>
                  <div className="body">
                    <EmptyContent show={sortedChangeRequests.length ? false : true} text={lg.keine_änderungsanträge} />
                    {sortedChangeRequests.map((changeRequest) => {
                      const shift = shiftMap[changeRequest.id];
                      return (
                        <ChangeRequestItem
                          key={changeRequest.id}
                          changeRequest={changeRequest}
                          shift={shift}
                          openModal={() => this.openModal(shift)}
                        />
                      );
                    })}
                  </div>
                </BusyWrapper>
              )}
            </div>
          </HintPopover>
        </div>
      </div>
    );
  }
}

export const ChangeRequestsPaper = withErrorBoundary(connect(mapStateToProps)(busyInjector(_ChangeRequestsPaper)));
