import React from "react";
import "./styles.scss";
import { SelectProps } from "antd/lib/select";
import { connect } from "react-redux";
import Search from "antd/lib/input/Search";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { AppState } from "../../../types/AppState";
import { BasicSelect } from "../../../components/BasicSelect/BasicSelect";
import { selectActiveBranches } from "../../../selectors/ActiveBranchesSelector";
import { selectActiveJobPositions } from "../../../selectors/ActiveJobPositionsSelector";

const mapStateToProps = (state: AppState) => {
  return {
    jobPositions: selectActiveJobPositions(state),
    branches: selectActiveBranches(state),
  };
};

type State = {};

type OwnProps = {
  onSearchChange: (searchValue: string) => void;
  onBranchChange: (branchValue: string) => void;
  onPositionChange: (positionValue: string) => void;
  branchFilter?: string;
  positionFilter?: string;
  searchInput: string;
  isNameFilterVisible: boolean;
  isJobPosFilterVisible: boolean;
  isBranchFilterVisible: boolean;
} & SelectProps<any>;

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _FiltersRow extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      jobPositions,
      branches,
      branchFilter,
      positionFilter,
      searchInput,
      isNameFilterVisible,
      isJobPosFilterVisible,
      isBranchFilterVisible,
    } = this.props;

    return (
      <>
        <div className="filtersWrapper">
          {isNameFilterVisible && (
            <div className="searchWrapper">
              <Search
                value={searchInput}
                onChange={(e) => this.props.onSearchChange(e.target.value)}
                style={{ width: "100%", background: "white" }}
                placeholder={lg.filtern}
                allowClear
                autoFocus
              />
            </div>
          )}
          {this.props.branches.length > 1 && isBranchFilterVisible && (
            <div className="branchFilterWrapper">
              <BasicSelect
                id="user-list-filter-branch"
                value={branchFilter}
                onChange={(id: string) => {
                  this.props.onBranchChange(id);
                }}
                options={branches.map((o) => ({ label: o.name, value: o.id }))}
                placeholder={lg.alle_standorte}
                allowClear
              />
            </div>
          )}
          {isJobPosFilterVisible && (
            <div className="positionFilterWrapper">
              <BasicSelect
                id="user-list-filter-jobPos"
                value={positionFilter}
                onChange={(id: string) => {
                  this.props.onPositionChange(id);
                }}
                options={jobPositions.map((o) => ({ label: o.name, value: o.id }))}
                placeholder={lg.alle_rollen}
                allowClear
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

export const FiltersRow = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(_FiltersRow);
