import { Icon } from "antd";
import { IconProps } from "antd/lib/icon";
import React from "react";
import { AbsenceTypeCode } from "../../shared/entities/IAbsenceType";
import { AvIcon } from "../AvIcon/AvIcon";
import "./styles.scss";

type Props = IconProps & {
  typeCode: AbsenceTypeCode;
  color?: string;
};

export const AbsenceIcon = ({ typeCode, ...props }: Props) => {
  const isIllness = typeCode === AbsenceTypeCode.illness;
  const isVacation = typeCode === AbsenceTypeCode.vacation;

  return (
    <div
      className="slotAbsenceIconWrapperMain"
      style={{
        color: props.color || "inherit",
        opacity: isIllness || isVacation ? "0.6" : "0.9",
      }}
    >
      {isIllness && <AvIcon {...props} type="icon-flat_band_aid-patch-plaster" />}
      {isVacation && <AvIcon {...props} type="icon-beach_access" />}
      {!isIllness && !isVacation && <Icon {...props} type="user-delete" />}
    </div>
  );
};
