import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useSelector } from "../../../../helpers/redux";
import "./styles.scss";
import { Button, Checkbox, Icon, Input, InputNumber } from "antd";
import TZModal from "../../../../components/TZModal/TZModal";
import { LocationSearchInput } from "../../branches/branchPopup/LocationSearchInput";
import { IShiftAddress } from "../../../../shared/entities/IShiftAddress";
import { v4 as uuid } from "uuid";
import AvColorPicker from "../../../../components/AvColorPicker/AvColorPicker";
import { pastelColors, strongColors } from "../../../../frontend-core/helpers/colors";
import _ from "lodash";
import AvFormField from "../../../../components/AvFormField/AvFormField";
import { shiftAddressRepository } from "../../../../repositories/shiftAddressRepository";
import { closeModal, openModal } from "../../../../actions/modal";
import { busyInjector, BusyInjectorProps } from "../../../../components/BusyInjector/BusyInjector";
import { HashtagModal } from "./HashtagModal/HashtagModal";
import { orderAddresses } from "../../../../frontend-core/helpers/frontendHelpers";
import { SettingsListCollapser } from "../SettingsListCollapser/SettingsListCollapser";
import { selectHashtags } from "../../../../selectors/hashtagSelector";
import { IHashtag, IWorkSpace } from "../../../../shared/entities/IWorkSpace";

type Props = {};

export const HashtagSection = busyInjector((props: Props & BusyInjectorProps) => {
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState<boolean>();
  const hashtags = useSelector(selectHashtags);
  const isV2 = useSelector((s) => s.data.tenantInfo.isV2);

  const hashtagClicked = useCallback((hashtag?: IHashtag) => {
    dispatch(openModal(HashtagModal, { hashtag }));
  }, []);

  const activeHashtags = _.orderBy(hashtags, (a) => a.name).filter((a) => !a.isInactive);
  const count = activeHashtags.length;

  return (
    <div className="hashtagsSectionMain">
      <div className="fb title">Hashtags</div>
      <div className="titleDescription">
        {isV2
          ? lg.hashtags_können_an_zeiterfassungen_angehängt_werden
          : lg.schichten_koennen_mit_mehreren_hashtags_versehen_werden}
      </div>
      <SettingsListCollapser
        isCollapsed={isCollapsed}
        onCollapsedChange={(isCollapsed) => setIsCollapsed(isCollapsed)}
        count={count}
        label={"Hashtags"}
      />
      {!isCollapsed && (
        <div className="content">
          <div className="listContainer">
            {activeHashtags.map((hashtag) => (
              <div className="addressListElement" onClick={() => hashtagClicked(hashtag)} key={hashtag.id}>
                <div className="colorRect" style={{ background: "#1a90ff" }}></div>
                <div className="text">{hashtag.name}</div>
                <div className="tag">#</div>
              </div>
            ))}
          </div>
          <div className="addButtonWrapper">
            <Button id="add-workspace" icon="plus" children={lg.hashtag_hinzufügen} onClick={() => hashtagClicked()} />
          </div>
        </div>
      )}
    </div>
  );
});
