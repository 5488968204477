export const setHourAccountFilterAT = "@AV/setHourAccountFilter";

export const setHourAccountFilter = (filter: Partial<HourAccountUiFilter>) => ({
  type: setHourAccountFilterAT,
  payload: filter,
});

export type HourAccountUiFilter = {
  searchInput?: string;
  positionFilter?: string;
  branchFilter?: string;
};

export const getHourAccountFilterReducer = (
  state = {},
  action: any
): HourAccountUiFilter => {
  switch (action.type) {
    case setHourAccountFilterAT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
