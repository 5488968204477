import { BaseRepository } from '../frontend-core/BaseRepository';
import { IHoliday } from '../shared/entities/IHoliday';

class HolidayRepository extends BaseRepository<IHoliday> {
  constructor() {
    super('holidays');
  }
}

export const holidayRepository = new HolidayRepository();
