import { AppState } from "../types/AppState";
import { DispFn } from "../frontend-core/types/thunkTypes";
import _ from "lodash";
import { BaseRepository } from "../frontend-core/BaseRepository";
import { IAnnouncement, IAnnouncementBase } from "../shared/entities/IAnnouncement";
import firebase from "firebase/compat/app";
import { CrudOperation, Operation } from "../shared/helpers/firebaseHelpers";
import { Map } from "../shared/types/general";
import { selectAnnouncementMap } from "../selectors/mapSelectors";
import { addSimpleDays } from "../shared/helpers/dateHelpers";

const getBaseAnnouncement = (a: IAnnouncement, userId: string): IAnnouncementBase => ({
  id: a.id,
  title: a.title,
  by: a.by,
  at: a.at,
  hasRead: !!a.readByUserId?.[userId],
});

const getRawUpdates = (a: IAnnouncement, doRemove = false) => {
  const userIds = Object.keys(a.readByUserId || {});
  const updates = {} as any;
  userIds.forEach((userId) => {
    updates[`announcementsByUser/${userId}/${a.id}`] = doRemove ? null : getBaseAnnouncement(a, userId);
  });
  return updates;
};

class AnnouncementRepository extends BaseRepository<IAnnouncement> {
  constructor() {
    super("announcements");
  }

  preProcess = (announcements: IAnnouncement[]): IAnnouncement[] => {
    // removing the creatorUserId from the readByUserId-map just in case a user assignes it to himslefe
    // we need to keep the creatorUserId out because the frontend fetches all announcements that are assigned to him
    // and separatly all announcements that he crated > if they intersect > they will override each other in redux
    return announcements.map((a) => {
      const readByUserId = { ...a.readByUserId };
      readByUserId.hasOwnProperty(a.by) && delete readByUserId[a.by];
      return { ...a, readByUserId };
    });
  };

  toggleHasRead = (a: IAnnouncement) => async (dispatch: DispFn, getState: () => AppState) => {
    const session = getState().data.auth.session!;
    const { userId, tenantId } = session;

    const hasRead = !a.readByUserId?.[userId];
    const update = {};
    update["announcements/" + a.id + "/readByUserId/" + userId] = hasRead;
    update["announcementsByUser/" + userId + "/" + a.id + "/hasRead"] = hasRead;

    const db = firebase.database();
    const tenatnRef = db.ref("tenants").child(tenantId);
    console.log(update); // dont remove these logs > useful to debugg in production
    return tenatnRef.update(update);
  };

  getExtraUpdates = (actions: CrudOperation<IAnnouncement>[]) => (dispatch: DispFn, getState: () => AppState) => {
    const announcementsMap = selectAnnouncementMap(getState());
    let updates = {} as any;
    actions.forEach((op) => {
      const next = op.entity;
      const prev = announcementsMap[next.id];
      if (op.operation === Operation.create) {
        updates = { ...updates, ...getRawUpdates(next) };
      }
      if (op.operation === Operation.remove) {
        updates = { ...updates, ...getRawUpdates(prev, true) };
      }
      if (op.operation === Operation.update) {
        updates = { ...updates, ...getRawUpdates(prev, true) };
        updates = { ...updates, ...getRawUpdates(next) };
      }
    });
    return updates;
  };
}

export const announcementRepository = new AnnouncementRepository();
