import React from "react";
import { IShift } from "../../../shared/entities/IShift";
import cn from "classnames";
import "./styles.scss";

type Props = {
  requiredUsersAmount: number;
  assignedUsersAmount: number;
};

export class RequiredShiftIcon extends React.PureComponent<Props> {
  render() {
    return (
      <div
        className={cn({
          requiredShiftIconMain: true,
          isComplete: this.props.assignedUsersAmount >= this.props.requiredUsersAmount,
        })}
        data-rh={lg.eingeteilte_benötigte_mitarbeiter}
      >
        {`${this.props.assignedUsersAmount} / ${this.props.requiredUsersAmount}`}
      </div>
    );
  }
}
