import React from "react";
import "./styles.scss";
import { Icon, Button } from "antd";
import cn from "classnames";
import TZModal from "../../../../components/TZModal/TZModal";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { connect } from "react-redux";
import { number } from "yup";
import { busyInjector, BusyInjectorProps } from "../../../../components/BusyInjector/BusyInjector";
import { rosterSettingsRepository } from "../../../../repositories/rosterSettingsRepository";
import { WorkInterval } from "../../../../shared/entities/IContract";
import { contractRepository } from "../../../../repositories/contractRepository";
import { closeModal } from "../../../../actions/modal";
import { selectCustomMonthStartDay } from "../../../../reducers/ui/general/rosterMonthStartDay";
import { selectCreditsMonthStartDay } from "../../../../reducers/ui/general/creditsMonthStartDay";

const mapStateToProps = (state: AppState) => ({
  rosterSettings: state.data.rosterSettings[0], // setting-entities are always tables with only one row -> so we grab the index 0
  contracts: state.data.contracts,
});

type State = {
  selectedDay: number | undefined;
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

export class _CustomMonthStartModal extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedDay: props.rosterSettings.customMonthIntervalStart,
    };
  }

  daySelected = (_day: number) => {
    const selectedDay = _day === 1 ? undefined : _day;
    this.setState({ selectedDay });
  };

  saveClicked = async () => {
    this.props.setLoading("main", true);
    const { rosterSettings, dispatch, contracts } = this.props;
    const intervalStartDay = this.state.selectedDay;
    const newRosterSettings = {
      ...rosterSettings,
      customMonthIntervalStart: intervalStartDay,
    };

    const monthlyContracts = contracts.filter((c) => c.interval === WorkInterval.monthly);
    const newContracts = monthlyContracts.map((c) => ({ ...c, intervalStartDay }));

    await dispatch(rosterSettingsRepository.update(newRosterSettings));
    await dispatch(contractRepository.updateList(newContracts));
    this.props.setLoading("main", false);

    dispatch(selectCustomMonthStartDay(intervalStartDay || 1)); // adjusting this already for the user, can be changed from the creditPage
    dispatch(selectCreditsMonthStartDay(intervalStartDay || 1)); // adjusting this already for the user, can be changed from the rosterPage
    dispatch(closeModal());
  };

  render() {
    const days: number[] = [];
    for (let i = 1; i <= 31; i++) {
      days.push(i);
    }

    const _selectedDay = this.state.selectedDay || 1;
    const isChanged = this.state.selectedDay === this.props.rosterSettings.customMonthIntervalStart;

    return (
      <>
        <TZModal style={{ width: "500px" }}>
          <TZModal.Head
            title={lg.intervall_start_wählen}
            icon="calendar"
            iconColor="#FF9800"
            // headStyle={{ border: "none" }}
          ></TZModal.Head>
          <div className="intervalStartDayModalMain">
            <div className="infoText">
              {
                lg.ist_der_abrechnungszeitraum_nicht_der_kalnedermonat_kann_hier_der_starttag_angepasst_werden_somit_kann_z_b_ein_intervall_vom_16_bis_zum_15_des_folgemonats_definiert_werden
              }
            </div>
            <div className="content">
              {days.map((day) => (
                <div
                  key={day}
                  className={cn({
                    dayBox: true,
                    selected: day === _selectedDay,
                    disabled: day > 28,
                  })}
                  onClick={() => {
                    this.daySelected(day);
                  }}
                >
                  {day}
                </div>
              ))}
            </div>
          </div>
          <TZModal.Footer>
            <Button
              id="CustomMonthStartModal-save-button"
              type="default"
              onClick={this.saveClicked}
              loading={this.props.isLoading()}
              style={{ marginLeft: "auto" }}
              disabled={this.props.isLoading() || isChanged}
              children={lg.Speichern}
            />
          </TZModal.Footer>
        </TZModal>
      </>
    );
  }
}

export const CustomMonthStartModal = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  busyInjector(_CustomMonthStartModal)
);
