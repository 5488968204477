import React from "react";
import { connect, useDispatch } from "react-redux";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { Icon, Badge } from "antd";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import "./styles.scss";
import { openModal } from "../../../actions/modal";
import { IntroVideoModal } from "../../modals/IntroVideoModal/IntroVideoModal";
import moment from "moment";
import { useSelector } from "../../../helpers/redux";
import { openCrisp } from "../../../helpers/crisp";
import cn from "classnames";
import { tierInfoSelector } from "../../../selectors/TierInfoSelector";

export const GotQuestionsBox = React.memo(() => {
  const isOnline = moment().hour() >= 8 && moment().hour() <= 21;
  const tierInfo = useSelector(tierInfoSelector);

  // if (!createdRecently) {
  //   return null;
  // }

  // if (!sessionInfo.isAdmin()) {
  //   return null;
  // }

  if (tierInfo.isCoreWithoutProTrial) {
    return null;
  }

  return (
    <div className="gotQuestionsBoxWrapper" id="gotQuestionsBoxWrapper" onClick={() => openCrisp()}>
      <div className={cn({ gotQuestionsBox: true, isOnline })}>
        <div className="main">{lg.haben_sie_fragen}</div>
        {isOnline && (
          <div className="info">
            <div className="onlineTag"></div>
            {lg.support_ist_online}
          </div>
        )}
      </div>
    </div>
  );
});
