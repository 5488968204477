import moment from "moment";
import { useSelector } from "react-redux";
import AbsenceTypeSelect from "../components/modals/AbsenceModal/AbsenceTypeSelect";
import { DispFn } from "../frontend-core/types/thunkTypes";
import { RosterMode } from "../reducers/ui/shifts/roster/rosterMode";
import { absenceRepository } from "../repositories/absenceRepository";
import { shiftRepository } from "../repositories/shiftRepository";
import { selectAbsenceTypeMap } from "../selectors/absenceTypeMapSelector";
import { selectRosterDateRange } from "../selectors/rosterDateRangeSelector";
import { selectSessionInfo } from "../selectors/SessionInfoSelector";
import { IAbsence, AbsenceStatus, AbsenceType } from "../shared/entities/IAbsence";
import { AbsenceEarning } from "../shared/entities/IAbsenceType";
import { Raw } from "../shared/entities/IResource";
import { SDateFormat } from "../shared/helpers/SimpleTime";
import { toSimpleDate } from "../shared/helpers/timeHelpers";
import { AppState } from "../types/AppState";
import { cloneShift, moveShift } from "./shift";
import { v4 as uuid } from "uuid";
import { IShift } from "../shared/entities/IShift";

export const absenceActionType = {
  selectMonth: "absence_setSelectedMonth",
  setSelectedYear: "absence_setSelectedYear",
  setSelectedType: "absence_setSelectedType",
  HIDE_EMPTY_ROWS: "@@AV/ABSENCE_HIDE_EMPTY_ROWS",
  isYearView: "absence_isYearView",
};

export const setSelectedYear = (year: number) => ({
  type: absenceActionType.setSelectedYear,
  payload: year,
});

export const selectMonth = (month: number) => ({
  type: absenceActionType.selectMonth,
  payload: month,
});

export const setSelectedAbsenceType = (absenceTypeId: string | null) => ({
  type: absenceActionType.setSelectedType,
  payload: absenceTypeId,
});

export const hideEmptyAbsenceRows = (doHide: boolean) => ({
  type: absenceActionType.HIDE_EMPTY_ROWS,
  payload: doHide,
});

export const toggleCalendarView = (isYearView: boolean) => ({
  type: absenceActionType.isYearView,
  payload: isYearView,
});

export const removeNoShowsOfPastShifts = (absence: Raw<IAbsence>, prevAbsence?: IAbsence) => {
  // if the absence is being created for a past day we need to potentially remove noshowFlags of shifts
  return async (dispatch: DispFn, getState: () => AppState) => {
    const today = toSimpleDate(moment());
    const userId = absence.userId;
    const isCreation = !prevAbsence;
    const startDateChanged = prevAbsence && prevAbsence.startDate !== absence.startDate;
    const endDateChanged = prevAbsence && prevAbsence.endDate !== absence.endDate;
    const statusChanged = prevAbsence && prevAbsence.status !== absence.status;
    const needToCheckForNoShows =
      absence.status === AbsenceStatus.active &&
      absence.startDate <= today &&
      (isCreation || startDateChanged || endDateChanged || statusChanged);

    if (!needToCheckForNoShows) {
      return;
    }

    const { startDate, endDate } = absence;
    const filter = ["userId_date", "between", [`${userId}_${startDate}`, `${userId}_${endDate}`]] as any;
    const shifts = await dispatch(shiftRepository.fetchMany({ filter }));
    const shiftsWithNoShows = shifts.filter((s) => s.isNoShow);
    const shiftsWithRemovedNoShows = shiftsWithNoShows.map((s) => ({ ...s, isNoShow: undefined }));
    return dispatch(shiftRepository.updateList(shiftsWithRemovedNoShows));
  };
};

export const fetchAbsencesOfRoster = () => {
  return async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const absenceTypes = state.data.absenceTypes;
    const canManage = selectSessionInfo(state).hasManagerPermissions();

    if (!absenceTypes.some((at) => at.isVisibleToColleagues) && !canManage) {
      // not permitted to fetch these absences > exit here
      return;
    }

    let date = selectRosterDateRange(getState()).rangeStart;
    // in case of dayMode fetch Absences of that week
    const isDayMode = state.ui.shifts.roster.rosterMode === RosterMode.Day;
    const isMonthMode = state.ui.shifts.roster.rosterMode === RosterMode.Month;
    isDayMode && (date = moment(date).startOf("isoWeek").format(SDateFormat));
    const key = isMonthMode ? "absencesByMonth" : "absencesByWeek";

    return await dispatch(absenceRepository.fetchMany({ childNodes: [key, date] }));
  };
};

export const openUpShiftsInAbsence = (a: Raw<IAbsence>) => {
  return async (dispatch: DispFn, getState: () => AppState) => {
    const { openUpShiftsInAbsence } = getState().data.rosterSettings[0];
    const today = toSimpleDate(moment());

    if (openUpShiftsInAbsence && a.status === AbsenceStatus.active) {
      const shiftsInAbsence = await dispatch(
        shiftRepository.fetchMany({
          filter: ["userId_date", "between", [`${a.userId}_${a.startDate}`, `${a.userId}_${a.endDate}`]],
        })
      );

      const shiftsToOpen = shiftsInAbsence
        .filter((s) => s.date > today)
        .map((s) => ({
          ...s,
          id: a.earning === AbsenceEarning.asPlaned ? uuid() : s.id, // in case of asPlaned we copy the shift
          userId: undefined,
          requiredUsersAmount: 1,
          repeatId: undefined,
          deassignedUserId: s.userId,
        }));

      await dispatch(shiftRepository.updateList(shiftsToOpen));
    }
  };
};
