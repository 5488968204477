import { SDateFormat } from "../shared/helpers/SimpleTime";
import { AppState } from "../types/AppState";
import { createSelector } from "reselect";
import { selectSessionInfo } from "./SessionInfoSelector";
import moment from "moment";

export const myUpcomingShiftsSelector = createSelector(
  [selectSessionInfo, (state: AppState) => state.data.shifts, (state: AppState) => state.data.publishedWeeks],
  (sessionInfo, shifts, publishedWeeks) => {
    const today = moment().format(SDateFormat);
    const futurePublishedWeeks = publishedWeeks.filter((w) => w.endDate >= today); // to make the algorithm more efficint
    return shifts.filter((s) => {
      return (
        s.userId === sessionInfo.user.id &&
        futurePublishedWeeks.find(
          (w) => w.branchId === s.branchId && w.startDate <= s.date && w.endDate >= s.date && w.published
        )
      );
    });
  }
);
