import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { Button, Checkbox, Icon, Input, InputNumber } from "antd";
import _ from "lodash";
import { ISurcharge } from "../../../../../shared/entities/ISurcharge";
import { InputProps } from "antd/lib/input";
import { InputNumberProps } from "antd/lib/input-number";
import WeekDayPicker from "../../../../../components/modals/AvailabilityModal/weekDayPicker/WeekDayPicker";
import { WeekDay } from "../../../../../shared/constants/WeekDays";
import { BasicSelect } from "../../../../../components/BasicSelect/BasicSelect";
import AvTimeInput from "../../../../../components/AvTimeInput/AvTimeInput";
import cn from "classnames";
import moment from "moment";
import { arrayToMap, mapToArray } from "./SurchargeModal";
import { isUserActive } from "../../../../../selectors/ActiveUserSelectors";
import { selectUsersFull } from "../../../../../selectors/usersFullSelector";
import { dummyUserTypes } from "../../../../Users/UserDetailsModal/ContractEntry/UserTypeEditor/UserTypeEditor";
import { useSelector } from "../../../../../helpers/redux";
import { toSimpleDate } from "../../../../../shared/helpers/timeHelpers";
import UserPickerList from "../../../branches/UserPickerList/UserPickerList";

type Props = {
  surcharge: ISurcharge;
  onChange: (s: Partial<ISurcharge>) => void;
};

const userOptions = [
  { label: "Alle Mitarbeiter", value: "all-users" },
  { label: "Mitarbeiter vom Typ", value: "users-of-type" },
  { label: "Ausgewählte Mitarbeiter", value: "selected-users" },
];

export const SurchargeModalUsersTab = (props: Props) => {
  const { surcharge, onChange } = props;
  const rosterSetting = useSelector((s) => s.data.rosterSettings)[0];
  const userTypes = rosterSetting.userTypes ? Object.values(rosterSetting.userTypes) : dummyUserTypes;
  const users = useSelector(selectUsersFull);
  const today = useRef(toSimpleDate(moment()));

  const excludingUsersOptions = users
    .filter((u) => (surcharge.excludingUsers || {})[u.id] || isUserActive(u, today.current)) // filter out inactive users / if they arent selected
    .map((u) => ({ label: u.name, value: u.id }));

  const includingUsers = surcharge.includingUsers || {};

  return (
    <div className="surchargeModalUserTabMain">
      <div className="formRow">
        <div className="formCol">
          <div className="formWrapper" style={{ marginBottom: 30, width: 240 }}>
            <div className="labelWrapper">{lg.gilt_für}</div>
            <BasicSelect
              options={userOptions}
              value={surcharge.appliesTo}
              onChange={(appliesTo: any) => {
                onChange({
                  appliesTo,
                  userTypes: {},
                  includingUsers: {},
                  excludingUsers: {},
                });
              }}
            />
          </div>

          {surcharge.appliesTo === "users-of-type" && (
            <div className="formWrapper" style={{ marginBottom: 30, width: 240 }}>
              <div className="labelWrapper">{lg.mitarbeiter_typen}</div>
              <BasicSelect
                options={userTypes.map((ut) => ({ label: ut.name, value: ut.id }))}
                value={mapToArray(surcharge.userTypes || {})}
                onChange={(userTypes: any) => onChange({ userTypes: arrayToMap(userTypes) })}
                mode="multiple"
              />
            </div>
          )}

          {surcharge.appliesTo !== "selected-users" && (
            <div className="formWrapper" style={{ marginBottom: 30, width: 240 }}>
              <div className="labelWrapper">{lg.ausser_fuer}</div>
              <BasicSelect
                id="excluding-users"
                mode="multiple"
                options={excludingUsersOptions}
                onChange={(ids: string[]) => onChange({ excludingUsers: arrayToMap(ids || []) })}
                value={mapToArray(surcharge.excludingUsers || {})}
                placeholder={lg.mitarbeiter}
                showSearch
              />
            </div>
          )}
        </div>
        <div className="formCol" style={{ marginLeft: "auto" }}>
          {surcharge.appliesTo === "selected-users" && (
            <div className="formWrapper">
              <UserPickerList
                selectedUserIds={mapToArray(surcharge.includingUsers || {})}
                toggleUser={(uid) =>
                  onChange({ includingUsers: _.omitBy({ ...includingUsers, [uid]: !includingUsers[uid] }, (e) => !e) })
                }
                selectList={(uids) => onChange({ includingUsers: arrayToMap(uids) })}
                showCount={true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
