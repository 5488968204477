import React from "react";
import "./styles.scss";
import { Row as AntRow, Col, Icon } from "antd";

type Props = React.HTMLAttributes<HTMLDivElement> & {};

type HeadProps = Props & {
  title?: string;
  headStyle?: React.CSSProperties;
  //antd icons used here
  icon?: string;
  iconSize?: string;
  iconColor?: string;
};
type FooterProps = Props & { isLoading?: boolean; style?: React.CSSProperties };

class Head extends React.PureComponent<HeadProps> {
  render() {
    // if title is provided -> props.children is neglected

    const { title, headStyle, iconColor, iconSize, icon, ...otherProps } = this.props;
    let className = "tzModalHead";
    if (this.props.className) {
      className = className + " " + this.props.className;
    }

    return (
      <div {...otherProps} className={className} style={headStyle as any}>
        {icon && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon type={icon} style={{ paddingRight: "10px", color: iconColor, fontSize: iconSize }} />
          </div>
        )}
        {title ? <div className="title">{title}</div> : this.props.children}
      </div>
    );
  }
}

class Body extends React.PureComponent<Props> {
  render() {
    let className = "tzModalBody";
    if (this.props.className) {
      className = className + " " + this.props.className;
    }
    return (
      <div {...this.props} className={className}>
        {this.props.children}
      </div>
    );
  }
}

class Footer extends React.PureComponent<FooterProps> {
  render() {
    const { isLoading, style, ...footerProps } = this.props;

    let className = "tzModalFooter";
    if (this.props.className) {
      className = className + " " + this.props.className;
    }

    return (
      <div {...footerProps} className={className} style={style || {}}>
        {isLoading ? <div className="loadingFooter">loading...</div> : this.props.children}
      </div>
    );
  }
}

class Row extends React.PureComponent<Props & { dividerBottom: boolean }> {
  static defaultProps = { dividerBottom: true };

  render() {
    const { dividerBottom, ...rowProps } = this.props;

    let className = `tzModalRow ${dividerBottom ? "dividerBottom" : ""}`;
    if (rowProps.className) {
      className = className + " " + rowProps.className;
    }
    const children = React.Children.toArray(rowProps.children);
    return (
      <div {...rowProps} className={className}>
        <AntRow type="flex" gutter={16}>
          {children.map((child, i) => {
            return (
              <Col xs={Math.floor(24 / children.length)} key={`col-${i}`}>
                {child}
              </Col>
            );
          })}
        </AntRow>
      </div>
    );
  }
}

export default class TZModal extends React.PureComponent<Props> {
  static Head = Head;
  static Body = Body;
  static Footer = Footer;
  static Row = Row;

  render() {
    let className = "tzModalMain";
    if (this.props.className) {
      className = className + " " + this.props.className;
    }
    return (
      <div {...this.props} className={className}>
        {this.props.children}
      </div>
    );
  }
}
