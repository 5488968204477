export const getLocale = (): string => {
  const localeStorageLang = localStorage.getItem("language");

  if (localeStorageLang && typeof localeStorageLang === "string") {
    return localeStorageLang;
  }

  return (navigator.language || navigator.languages[0] || "en-GB") as any;
};

export const getLocaleLang = (): "en" | "de" => {
  return getLocale().includes("de") ? "de" : "en";
};

const _locale = getLocale(); // to cache the value - to avoid possible perfomrance issues
export const decimalSeparatorLocal = _locale.includes("en") ? "." : ",";
export const localizeFormat = (format: string) => {
  if (_locale !== "en-US") {
    // only localize dateFormats in case of US
    return format;
  }

  if (format.includes("MMM")) {
    // no need to change the date format if the month is spelled out
    return format;
  }

  if (format.includes("DD.MM")) {
    return format.replace("DD.MM", "MM.DD");
  }
};
