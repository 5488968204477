import React, { useCallback } from "react";
import { DragElementWrapper, DragSourceOptions } from "react-dnd";
import cn from "classnames";
import { Icon } from "antd";
import { ShiftTimeFrame } from "./ShiftTimeFrame/ShiftTimeFrame";
import "./styles.scss";
import { ShiftTrackingIcon } from "./ShiftTrackingIcon/ShiftTrackingIcon";
import { ShiftWarningIcon } from "./ShiftWarningIcon/ShiftWarningIcon";
import { ShiftMoonIcon } from "./ShiftMoonIcon/ShiftMoonIcon";
import { IShift } from "../../shared/entities/IShift";
import { IAbsence } from "../../shared/entities/IAbsence";
import { IShiftHandOverRequest } from "../../shared/entities/IShiftHandOverRequest";
import { IChangeRequest } from "../../shared/entities/IChangeRequest";
import { ITracking } from "../../shared/entities/ITracking";
import { ITimeClocking } from "../../shared/entities/ITimeClocking";
import { OpenShiftIcon } from "./OpenShiftIcon/OpenShiftIcon";
import { ShiftHandOverIcon } from "./ShiftHandOverIcon/ShiftWarningIcon";
import { IJobPosition } from "../../shared/entities/IJobPosition";
import { IUser } from "../../shared/entities/IUser";
import { pastelColors } from "../../frontend-core/helpers/colors";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import { IWorkSpace } from "../../shared/entities/IWorkSpace";
import { IShiftOverlap } from "../../shared/entities/IShiftOverlap";
import { Map } from "../../shared/types/general";
import { IBranch } from "../../shared/entities/IBranch";
import { decryptUser } from "../../shared/helpers/userHelpers";
import { IShiftAddress } from "../../shared/entities/IShiftAddress";
import { getBranchColor } from "../../frontend-core/helpers/frontendHelpers";
import { AbsenceIcon } from "../AbsenceIcon/AbsenceIcon";
import { IAbsenceType } from "../../shared/entities/IAbsenceType";
import { RequiredShiftIcon } from "./RequiredShiftIcon/RequiredShiftIcon";
import clockedInGifSrc from "./../../frontend-core/assets/clockedinsignal.gif";
import { ShiftPunchingIcon } from "./ShiftPunchingIcon/ShiftPunchingIcon";

const renderWorkSpaceBox = (workSpace: IWorkSpace | undefined) =>
  workSpace && (
    <div
      key={"workSpace"}
      className="tag darkFont workSpaceTag"
      data-rh-at="bottom"
      data-rh={workSpace.name}
      style={{
        background: workSpace.color,
        color: pastelColors.includes(workSpace.color) ? "inherit" : "white",
      }}
    >
      {workSpace.name}
    </div>
  );

const renderAddressBox = (address: IShiftAddress | undefined, isMini?: boolean) =>
  address && (
    <div
      key={"address"}
      className={"tag darkFont addressTag " + cn({ isMini })}
      data-rh-at="bottom"
      data-rh={address.name || address.address}
      style={{
        background: address.color,
        color: pastelColors.includes(address.color) ? "inherit" : "white",
      }}
    >
      {isMini ? <Icon type="environment" /> : address.name || address.address}
    </div>
  );

const renderBranchIcon = (branch: IBranch) => (
  <div
    key={"branchIcon"}
    className="tag brightFont branchIconWrapper"
    data-rh-at="bottom"
    data-rh={branch.name}
    style={{ color: getBranchColor(branch) }}
  >
    {/* <Icon type="shop" /> */}
    {branch.name}
  </div>
);

const renderJobPosition = (jobPosition: IJobPosition, inBlack?: boolean) => (
  <div
    key={"jobPosition"}
    className="tag brightFont jobPosBox"
    data-rh-at="bottom"
    data-rh={jobPosition.name}
    style={{ color: inBlack ? "black" : jobPosition.color }}
  >
    {jobPosition.name}
  </div>
);

const renderUserName = (user: IUser) => {
  const userFull = decryptUser(user);
  return (
    <div key={"userName"} className="tag userNameTag" style={{ color: "#1a90ffe3" }}>
      {userFull.name}
    </div>
  );
};

const renderComment = (comment: string, justIcon: boolean) => (
  <div className="commentIconWrapper" key="comment">
    {justIcon ? (
      <Icon type="message" theme="twoTone" data-rh={comment} data-rh-at="bottom" />
    ) : (
      <div className="shiftCommentStripe" data-rh={comment}>
        {comment}
      </div>
    )}
  </div>
);

const renderChangeReqIcon = () => (
  <div key="changeReq" className="changeRequestIcon" data-rh-at="bottom" data-rh={lg.änderungsanfrage}>
    <Icon type="scissor" />
  </div>
);

const renderAbsenceIcon = (absence?: IAbsence, absenceType?: IAbsenceType) => {
  if (!absence || !absenceType) {
    return null;
  }
  return (
    <div className="shiftAbsenceIcon" key="absenceIcon">
      <AbsenceIcon typeCode={absence.typeCode} color={absenceType.color} style={{ fontSize: "28px" }} />
    </div>
  );
};

export const SHIFT_HEIGHT = 44;

export type ShiftProps = {
  data: IShift;
  disableDnD?: boolean;
  absence?: IAbsence;
  absenceType?: IAbsenceType;
  userId?: string;
  userUnavailable?: boolean;
  handOverRequest?: IShiftHandOverRequest;
  wasRecentlyModified?: boolean;
  changeRequest?: IChangeRequest;
  dragRef?: DragElementWrapper<DragSourceOptions>;
  additionalClassNames?: string;
  tracking?: ITracking;
  clocking?: ITimeClocking;
  style?: React.CSSProperties;
  timeRowStyle?: React.CSSProperties;
  indicatorColor?: string;
  showJobPosition?: boolean;
  showUserName?: boolean;
  showBranch?: boolean;
  isFirstShiftOfGroup?: boolean;
  isSlimShift?: boolean;
  isLastShiftOfGroup?: boolean;
  shiftGroupLength?: number;
  isKanbanShift?: boolean;
  isGroupedByJobPos: boolean;
  showBreakes?: boolean;
  showDurations?: boolean;
  dispatch: DispFn;
  branchMap: Map<IBranch>;
  jobPosMap: Map<IJobPosition>;
  workSpaceMap: Map<IWorkSpace>;
  addressMap: Map<IShiftAddress>;
  usersMap: Map<IUser>;
  shiftOverlap?: IShiftOverlap;
  canManage: boolean;
  isOwnShift: boolean;
  labourLawWarning?: string;
  assignedUsersAmount?: number; // relevant for shifts of type 'isRequirement'
  showComment?: boolean;
  isV2?: boolean;
};

export const Shift = React.memo((props: ShiftProps) => {
  const {
    changeRequest,
    style,
    showUserName,
    showJobPosition,
    handOverRequest,
    tracking,
    clocking,
    isFirstShiftOfGroup,
    isSlimShift,
    shiftGroupLength,
    isKanbanShift,
    absence,
    shiftOverlap,
    canManage,
    isOwnShift,
    showBranch,
    branchMap,
    absenceType,
    labourLawWarning,
    showComment,
    isV2,
  } = props;

  const { userId, workSpaceId, addressId, jobPositionId, isNoShow } = props.data;
  const isOpenShift = !userId && !props.data.isRequirement;
  const user = userId ? props.usersMap[userId] : undefined;
  const workSpace = workSpaceId ? props.workSpaceMap[workSpaceId] : undefined;
  const address = addressId ? props.addressMap[addressId] : undefined;
  const jobPosition = props.jobPosMap[jobPositionId];
  const userIsAbsent = !!props.absence;
  const { data } = props;
  const showNoShow = isNoShow && !tracking;

  const hasHandOverIcon = props.handOverRequest;
  const hasWarning =
    !data.isActiveClocking && (shiftOverlap || props.userUnavailable || showNoShow || labourLawWarning);

  const isNightShift = data.startTime > data.endTime;

  const canClick = canManage || !data.userId || isOwnShift;
  const canOpen = isOwnShift || canManage;

  const renderWarningIcon = useCallback(
    () => (
      <ShiftWarningIcon
        key={"warning"}
        overlap={shiftOverlap}
        isNoShow={showNoShow}
        isUserUnavailable={!!props.userUnavailable}
        labourLawWarning={props.labourLawWarning}
        user={user}
      />
    ),
    [shiftOverlap, user, showNoShow, props.userUnavailable, props.labourLawWarning]
  );

  const renderClockedInIcon = useCallback(() => {
    return <img src={clockedInGifSrc} style={{ width: 20, height: 20 }} data-rh={lg.ist_eingestempelt} />;
  }, []);

  const renderIconsGroup = useCallback(() => {
    const showTrackingIcon = tracking && !hasWarning;
    const showPunchingIconV2 = isV2 && clocking?.endTime && !clocking.isAccepted && !hasWarning;
    const showChangeReqIcon = changeRequest && !hasWarning;
    const showHandOverIcon = handOverRequest && !hasWarning;
    const doRender = showTrackingIcon || showPunchingIconV2 || showChangeReqIcon || hasWarning || showHandOverIcon;

    if (!canOpen) {
      return null;
    }

    return (
      doRender && [
        hasWarning && renderWarningIcon(),
        showTrackingIcon && <ShiftTrackingIcon key="tracking" tracking={tracking!} />,
        showPunchingIconV2 && <ShiftPunchingIcon key="punching" />,
        showChangeReqIcon && renderChangeReqIcon(),
        showHandOverIcon && <ShiftHandOverIcon key="handOver" />,
      ]
    );
  }, [handOverRequest, changeRequest, hasWarning, tracking, canManage, isOwnShift, renderWarningIcon, data]);

  if (!jobPosition) {
    console.error("No job-postion found in shift.tsx");
    return null;
  }

  const showAbsenceIcon = absence && isKanbanShift && !isSlimShift;
  const infoBoxCount = Number(Boolean(workSpace)) + Number(Boolean(address)) + Number(Boolean(showJobPosition));
  const isRequirementComplete = (props.assignedUsersAmount || 0) >= (data.requiredUsersAmount || 0);

  return (
    <div
      id={`grid-shift-${data.date}-${data.userId}-${data.jobPositionId}`}
      style={{ height: SHIFT_HEIGHT, borderLeftColor: jobPosition.color, borderLeftWidth: 3, ...(style || {}) }}
      data-type="grid-shift"
      data-shift-id={data.id}
      ref={props.dragRef}
      className={
        cn({
          isSlimShift: props.isSlimShift,
          isFirstShiftOfGroup: props.isFirstShiftOfGroup,
          isLastShiftOfGroup: props.isLastShiftOfGroup,
          shiftMain: true,
          userIsAbsent,
          glowing: props.wasRecentlyModified,
          clickable: canClick,
          noPointerEvents: !canClick && !(showComment && data.comment),
        }) +
        " " +
        props.additionalClassNames
      }
    >
      <div>
        {showAbsenceIcon && renderAbsenceIcon(absence, absenceType)}
        <div
          className={
            cn({
              isRequirement: data.isRequirement,
              isRequirementComplete,
            }) + " fb column jBetween mainContent"
          }
        >
          {!props.isSlimShift && (
            <div className="timeRow " style={props.timeRowStyle || {}}>
              <ShiftTimeFrame
                startTime={data.startTime}
                endTime={data.isActiveClocking ? undefined : data.endTime}
                breakMinutes={data.breakMinutes}
                showBreakes={props.showBreakes}
                showDuration={props.showDurations}
                hideEndTime={props.isV2 && clocking?.isAutoClockOut && !clocking?.endTime}
                isV2={isV2}
              />
              {
                <div className="iconsWrapper">
                  {isOpenShift && !data.isTemplate && <OpenShiftIcon shift={data} />}
                  {data.isRequirement && (
                    <RequiredShiftIcon
                      assignedUsersAmount={props.assignedUsersAmount || 0}
                      requiredUsersAmount={data.requiredUsersAmount || 0}
                    />
                  )}
                  {clocking && !clocking.endTime && !clocking.isAutoClockOut && !tracking && renderClockedInIcon()}
                  {renderIconsGroup()}
                  {!isOpenShift && shiftGroupLength! > 1 && !hasWarning && (
                    <div className="shiftCount">{shiftGroupLength}</div>
                  )}
                </div>
              }
            </div>
          )}
          {(showJobPosition ||
            (user && showUserName) ||
            data.comment ||
            hasHandOverIcon ||
            isNightShift ||
            (props.isFirstShiftOfGroup && hasWarning) ||
            address ||
            showBranch ||
            workSpace) && (
            <div className="fb row extraRow">
              {showBranch && renderBranchIcon(branchMap[data.branchId])}
              {showJobPosition && renderJobPosition(jobPosition, data.isRequirement)}
              {user && showUserName && renderUserName(user)}
              {(data.comment ||
                hasHandOverIcon ||
                isNightShift ||
                workSpace ||
                isSlimShift ||
                isFirstShiftOfGroup ||
                address) && (
                <div className="iconsWrapper">
                  {!isSlimShift && renderAddressBox(address, (showJobPosition || showUserName) && !!workSpace)}
                  {!isSlimShift && renderWorkSpaceBox(workSpace)}
                  {data.comment && renderComment(data.comment, !showComment || infoBoxCount >= 2)}
                  {isNightShift && !isSlimShift && <ShiftMoonIcon />}
                  {isSlimShift && renderIconsGroup()}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
