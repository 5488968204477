import React from "react";
import { connect } from "react-redux";
import { Formik, FormikProps, FormikHelpers } from "formik";
import * as Yup from "yup";
import "./styles.scss";
import { Input, Button } from "antd";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { AppState } from "../../../types/AppState";
import { IFormStepProps } from "./form/IFormStepProps";
import { shouldDisplayFieldError } from "./form/helpers";
import { IRegisterTenant } from "../../../shared/types/IRegisterTenant";

const mapStateToProps = (state: AppState) => {
  return {
    isV2: state.data.tenantInfo.isV2,
  };
};

type State = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = IFormStepProps<IRegisterTenant> & StoreProps & DispatchBaseProps;

interface IFormValues {
  tenantName: string;
  email: string;
  password: string;
  userName: string;
}

class Form extends React.PureComponent<Props, State> {
  validationSchema = Yup.object().shape({
    userName: Yup.string().min(5, lg.mindestens_5_zeichen).required("*Eingabe erforderlich"),
    email: Yup.string().email(lg.ungueltiges_email_format).required("*Eingabe erforderlich"),
    password: Yup.string().min(5, lg.mindestens_5_zeichen).required("*Eingabe erforderlich"),
  });

  handleSubmit = async (values: IFormValues, actions: FormikHelpers<IFormValues>) => {
    actions.validateForm();
    const errors = await actions.validateForm();
    if (Object.values(errors).length) {
      return;
    }
    this.props.onNext(values);
  };

  render() {
    const domain = this.props.isV2 ? "https://zeitguru.de" : "https://aplano.de";

    return (
      <Formik
        initialValues={this.props.values as IFormValues}
        onSubmit={this.props.onNext}
        validationSchema={this.validationSchema}
        initialErrors={this.props.errors}
        render={(formikProps: FormikProps<IFormValues>) => (
          <form onSubmit={formikProps.handleSubmit}>
            <div className="firstForm">
              <div className="formField">
                <div className="label">Firmenname</div>
                <Input
                  type="text"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.tenantName}
                  placeholder="Firmenname"
                  name="tenantName"
                  size="large"
                />
                <span>
                  {shouldDisplayFieldError(formikProps, "tenantName") && (
                    <div className="errorMessage">{formikProps.errors.tenantName}</div>
                  )}
                </span>
              </div>
              <div className="formField">
                <div className="label">Name</div>
                <Input
                  type="text"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.userName}
                  placeholder="Vor- und Nachname"
                  name="userName"
                  size="large"
                />
                <span>
                  {shouldDisplayFieldError(formikProps, "userName") && (
                    <div className="errorMessage">{formikProps.errors.userName}</div>
                  )}
                </span>
              </div>
              <div className="formField">
                <div className="label">E-Mail</div>
                <Input
                  type="email"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.email}
                  placeholder="E-Mail"
                  name="email"
                  size="large"
                />
                <span>
                  {(shouldDisplayFieldError(formikProps, "email") || this.props.errors.email) && (
                    <div className="errorMessage">{formikProps.errors.email}</div>
                  )}
                </span>
              </div>
              <div className="formField">
                <div className="label">Neues Passwort</div>
                <Input.Password
                  type="text"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.password}
                  placeholder="Passwort"
                  name="password"
                  size="large"
                  onPressEnter={formikProps.submitForm}
                />
                <span>
                  {shouldDisplayFieldError(formikProps, "password") && (
                    <div className="errorMessage">{formikProps.errors.password}</div>
                  )}
                </span>
              </div>
            </div>
            <Button
              id="go-next"
              type="primary"
              onClick={formikProps.submitForm}
              size="large"
              loading={formikProps.isSubmitting}
              style={{ marginTop: "10px" }}
            >
              {this.props.submitButtonName || "weiter"}
            </Button>
            <div className="fb jCenter aCenter">
              <div className="agbAcceptance">
                Mit Abschluss der Anmeldung stimmen Sie unseren <a href={`${domain}/agb`}>AGB</a> und{" "}
                <a href={`${domain}/datenschutz`}>Datenschutzbestimmungen</a> zu.
                {/* Es
                sind weder Zahlungsdaten noch eine Kündigung in der Testphase
                erforderlich. */}
              </div>
              {/* <Button
                id="go-back"
                style={{ marginTop: "10px" }}
                type="link"
                size="default"
                onClick={() => this.props.goPrevious(formikProps.values)}
              >
                Zurück
              </Button> */}
            </div>
          </form>
        )}
      />
    );
  }
}

export default connect<StoreProps, {}, IFormStepProps<IRegisterTenant>, AppState>(mapStateToProps)(Form);
