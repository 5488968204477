import React, { PureComponent } from "react";
import "./styles.scss";
import moment from "moment";

import { AppState } from "../../../../../types/AppState";
import { connect } from "react-redux";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { IAbsence } from "../../../../../shared/entities/IAbsence";
import { IHoliday } from "../../../../../shared/entities/IHoliday";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import AbsenceBarMonthView from "./AbsenceBarMonthView/AbsenceBarMonthView";

const mapStateToProps = (state: AppState) => ({
  sessionInfo: selectSessionInfo(state)
});

type OwnProps = {
  absences: Array<IAbsence>;
  monthStart: string;
  monthEnd: string;
  month: number; // 0 - 11
  year: number;
  holidays: IHoliday[];
  canManageAbsences: boolean;
  userId: string;
  mouseOverDay?: number;
  daysInMonth: number;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _MonthCell extends PureComponent<Props> {
  getDaysArray = () => {
    const { year, month } = this.props;
    const length = moment()
      .year(year)
      .month(month)
      .daysInMonth();
    const monthArr: number[] = [];
    for (let i = 1; i <= length; i++) {
      monthArr.push(i);
    }
    return monthArr;
  };

  render() {
    const { monthStart, monthEnd, month, year, absences, sessionInfo, userId, mouseOverDay } = this.props;
    const absencesInMonth = absences.filter(abs => abs.startDate <= monthEnd && abs.endDate >= monthStart);

    return (
      <div className="absenceMonthCell" style={{ width: "100%" }}>
        {}
        {absencesInMonth.map(a => (
          <AbsenceBarMonthView
            key={a.id}
            absence={a}
            daysInMonth={this.props.daysInMonth}
            monthStart={monthStart}
            monthEnd={monthEnd}
            isClickable={this.props.canManageAbsences || a.userId === sessionInfo.user.id}
          />
        ))}
      </div>
    );
  }
}

export const MonthCell = connect(mapStateToProps)(_MonthCell);
