import { RoleType } from "./../shared/entities/IUser";
import { UserInfo } from "./../shared/helpers/UserInfo";
import { AppState } from "../types/AppState";
import { createSelector } from "reselect";
import { selectSessionInfo } from "./SessionInfoSelector";
import { IBranch } from "../shared/entities/IBranch";

export const selectBranches = (state: AppState) => state.data.branches;

/**
 * Admin can see all branches / Mangers and Employees can only see their branches
 */
export const selectVisibleBranches = createSelector(
  selectSessionInfo,
  selectBranches,
  (sessionInfo: UserInfo, branches: IBranch[]): IBranch[] => {
    const currentUser = sessionInfo.user;
    const isAdmin = sessionInfo.user.role === RoleType.admin;

    const activeBranches = branches.filter((b) => !b.isInactive);

    return isAdmin ? activeBranches : activeBranches.filter((b) => currentUser.branchIds.includes(b.id));
  }
);
