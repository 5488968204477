import { CLEAR_DATA_ACTION_TYPE } from "../repositories/maintenanceRepo";
export const dataStatusAction = {
  LOADED: "LOADED",
  LOADING: "LOADING",
};

export enum DataStatus {
  isLoaded = "isLoaded",
  isLoading = "isLoading",
  initial = "initial",
}

export const baseDataLoaded = () => ({
  type: dataStatusAction.LOADED,
});

export const baseDataLoading = () => ({
  type: dataStatusAction.LOADING,
});

export const dataStatusRedcuer = (
  state = DataStatus.initial,
  action: any
): DataStatus => {
  switch (action.type) {
    case dataStatusAction.LOADED:
      return DataStatus.isLoaded;
    case dataStatusAction.LOADING:
      return DataStatus.isLoading;
    case CLEAR_DATA_ACTION_TYPE:
      return DataStatus.initial;
    default:
      return state;
  }
};
