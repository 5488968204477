import { withRosterLoading } from "./rosterReducerHelpers";
import { SDateFormat } from "./../../../../shared/helpers/SimpleTime";
import moment from "moment";

export const SHIFTS_ROSTER_MONTH_SELECT = "SHIFTS_ROSTER_MONTH_SELECT";

export const selectMonth = (date: string) =>
  withRosterLoading({
    type: SHIFTS_ROSTER_MONTH_SELECT,
    payload: date,
  });

const initialState = moment().startOf("month").format(SDateFormat);

export const selectedMonthReducer = (state = initialState, a: any): string =>
  a.type === SHIFTS_ROSTER_MONTH_SELECT ? a.payload : state;
