import { toMoment, toSimpleDate } from "../shared/helpers/timeHelpers";
import { SDateFormat } from "../shared/helpers/SimpleTime";
import { addSimpleDays } from "../shared/helpers/dateHelpers";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import { RosterMode } from "../reducers/ui/shifts/roster/rosterMode";
import moment from "moment";

export type RosterDateRange = { rangeStart: string; rangeEnd: string };
export const selectRosterDateRange: (state: AppState) => { rangeStart: string; rangeEnd: string } = createSelector(
  [
    (state: AppState) => state.ui.shifts.roster.rosterMode,
    (state: AppState) => state.ui.shifts.roster.selectedDay,
    (state: AppState) => state.ui.shifts.roster.selectedWeek,
    (state: AppState) => state.ui.shifts.roster.selectedMonth,
    (state: AppState) => state.ui.general.rosterCustomMonthStartDay,
  ],
  (
    mode: RosterMode,
    dayDate: string,
    weekFirstDate: string,
    monthFirstDate: string,
    monthStartDay // if the month range shoult not start at the 1. but goes e.g. from 16 SEP to 15 OKT
  ) => {
    if (mode === RosterMode.Week) {
      return {
        rangeStart: weekFirstDate,
        rangeEnd: addSimpleDays(weekFirstDate, 6),
      };
    }

    if (mode === RosterMode.Month) {
      const rangeStart = toSimpleDate(toMoment(monthFirstDate).date(monthStartDay));
      const daysInMonth = toMoment(rangeStart).daysInMonth();
      const rangeEnd = toSimpleDate(toMoment(rangeStart).add(daysInMonth - 1, "days"));
      return { rangeStart, rangeEnd };
    }

    //if (mode === RosterMode.Day) { // ITs commented out to satisfy TYPESCRIPTs return type
    return { rangeStart: dayDate, rangeEnd: dayDate };
    //}
  }
);
