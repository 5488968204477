import React, { useEffect, useState } from "react";
import Stripe from "stripe";
import { sendPost } from "../../../frontend-core/actions/send";
import { useSelector } from "../../../helpers/redux";
import { tierInfoSelector } from "../../../selectors/TierInfoSelector";
import moment from "moment";

import amexLogo from "./logos/amex.png";
import cartesLogo from "./logos/cartes.png";
import dinersLogo from "./logos/diners.png";
import discoverLogo from "./logos/discover.png";
import jcbLogo from "./logos/jcb.png";
import masterLogo from "./logos/mastercard.png";
import visaLogo from "./logos/visa.png";
import unionLogo from "./logos/unionpay.png";

import "./styles.scss";
import { Button, Icon, Modal, notification } from "antd";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../../actions/modal";
import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import BusyWrapper from "../../../components/BusyWrapper/BusyWrapper";
import { IBillingDetails } from "../../../shared/entities/IBillingDetails";
import { openUpdatePaymentDetailsModal } from "../../../actions/billingActions";
import { DispFn } from "../../../frontend-core/types/thunkTypes";
import { CancelSubscriptionModal } from "../../../components/CancelSubscriptionModal/CancelSubscriptionModal";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { Tiers } from "../../../shared/constants/Tiers";
import { ITenantMisc } from "../../../shared/entities/ITenantMisc";

// we are doing this, so stripe gets only injected when the user is going to the payment modal
const TierPicker = React.lazy(() => import("../../../components/TierPicker/TierPicker") as any) as any;

const fillUpWithZeros = (value: string, targetLength: number) => {
  let newValue = value;
  while (newValue.length < targetLength) {
    newValue = "0" + newValue;
  }
  return newValue;
};

const formatPrice = (cents) => {
  return Number(cents / 100).toLocaleString(undefined, {
    minimumFractionDigits: 2,
  });
};

type Props = {};

const formatUnixDate = (unix: number) => moment.unix(unix).format("L");

enum InvoiceStatus {
  PAID,
  OVERDUE,
  PROCESSING,
  OPEN,
  DISPUTE,
}

export const BillingPage = busyInjector((props: Props & BusyInjectorProps) => {
  const tenantInfo = useSelector((s) => s.data.tenantInfo);
  const isV2 = useSelector((s) => s.data.tenantInfo.isV2);
  const tierInfo = useSelector(tierInfoSelector);
  const dispatch = useDispatch<DispFn>();
  const [upcomingInvoice, setUpcomingInvoice] = useState<Stripe.Invoice | undefined | null>();
  const sessionInfo = useSelector(selectSessionInfo);
  const user = sessionInfo.user;
  const isAdmin = sessionInfo.isAdmin();
  const [invoices, setInvoices] = useState<Stripe.Invoice[] | undefined>();
  const [billingDetails, setBillingDetails] = useState<IBillingDetails | undefined>();
  const [canceledUntil, setCanceledUntil] = useState<number | undefined | null>();
  const [disputedInvoiceStatuses, setDisputedInvoiceStatuses] = useState<
    { invoiceId: string; status: "resolved" | "unresolved" }[] | null
  >();
  const [tenantMisc, setTenantMisc] = useState<ITenantMisc | null>(null);
  const showQuitSubscriptionBtn = !canceledUntil && isAdmin && user.id === "u1" && tierInfo.tier !== Tiers.Core;

  const loadData = async () => {
    props.setLoading("all", true);

    const {
      billingDetails: billingDetailsRes,
      invoices: invoicesRes,
      upcomingInvoice: upcomingInvoiceRes,
      canceledUntil: canceledUntilRes,
      tenantMisc: tenantMiscRes,
      disputedInvoiceStatuses: disputedInvoiceStatusesRes,
    } = (await dispatch(sendPost("/api/get-billing-info", {}))) as any;

    setUpcomingInvoice(upcomingInvoiceRes);
    setInvoices(invoicesRes);
    setBillingDetails(billingDetailsRes);
    setCanceledUntil(canceledUntilRes);
    setTenantMisc(tenantMiscRes);
    setDisputedInvoiceStatuses(disputedInvoiceStatusesRes);

    props.setLoading("all", false);
  };

  useEffect(() => {
    loadData();
  }, [tierInfo.tier]);

  const creditBrands = {
    amex: amexLogo,
    cartes_bancaires: cartesLogo,
    diners_club: dinersLogo,
    discover: discoverLogo,
    jcb: jcbLogo,
    mastercard: masterLogo,
    visa: visaLogo,
    unionpay: unionLogo,
  };

  return (
    <BusyWrapper isBusy={props.isLoading("all")} style={{ display: "flex", flex: "1 1 auto" }}>
      <div className="pagePadding">
        <h2 className="avTitle">
          {lg.abo}: {tierInfo.tier}
        </h2>
        <div className="billingPageContent">
          {canceledUntil && (
            <div className="canceledUntilContainer">
              <div>
                {lg.abo_gekündigt_zum}
                {": "}
                <span className="canceledUntilDate">{formatUnixDate(canceledUntil)}</span>
              </div>
            </div>
          )}
          {/* Nächste Zahlung (Menge) */}
          {upcomingInvoice && (
            <>
              <div className="nextPaymentContainer">
                <div>
                  {lg.nächste_zahlung_am}{" "}
                  <span className="nextPaymentPeriod">{formatUnixDate(upcomingInvoice.period_end!)}</span>
                  <div className="nextPaymentAmount">
                    {formatPrice(upcomingInvoice?.total)} €
                    <span className="perMonth">
                      {upcomingInvoice.customer_address?.country === "DE" && <> ({lg.inkl_mwst})</>}
                    </span>
                  </div>
                </div>

                {/* <div className="nextPaymentAmountRow"></div> */}
              </div>
              <div className="currentPaymentMethod">
                {/* <Icon style={{ marginRight: 6 }} type="credit-card"></Icon> */}
                {!!tenantInfo.byInvoice && (
                  <>
                    <div className="sepaDebitInfo">
                      <span>
                        {lg.zahlungsmethode}: <b>{lg.überweisung}</b>
                      </span>
                      {tenantMisc?.virtualBankAccount && (
                        <div>
                          <div>
                            {lg.kontoinhaber}: <b>Aplano GmbH</b>
                          </div>
                          <div>
                            IBAN: <b>{tenantMisc?.virtualBankAccount.iban.toUpperCase()}</b>
                          </div>
                          <div>
                            BIC: <b>{tenantMisc?.virtualBankAccount.bic.toUpperCase()}</b>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}

                {!!billingDetails?.paymentMethod && !tenantInfo.byInvoice && (
                  <>
                    {billingDetails?.paymentMethod.type === "card" && (
                      <>
                        <div className="cardBrand">
                          <img
                            src={creditBrands[billingDetails?.paymentMethod.card?.brand!]}
                            className="cardBrandLogo"
                          />
                          <span className="lastDigits">****{billingDetails?.paymentMethod.card?.last4}</span>
                        </div>

                        <div className="cardExpiry">
                          {lg.gültig_bis}:
                          <span className="expiryDate">
                            {fillUpWithZeros(billingDetails?.paymentMethod.card?.exp_month + "", 2)}/
                            {billingDetails?.paymentMethod.card?.exp_year}
                          </span>
                        </div>
                      </>
                    )}
                    {billingDetails?.paymentMethod.type === "sepa_debit" && (
                      <>
                        <div className="sepaDebitInfo">
                          <span>{lg.sepa_lastschrift}:</span>
                          <span className="lastDigits">IBAN ****{billingDetails?.paymentMethod.sepa_debit?.last4}</span>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          )}
          <div className="fb row" style={{ marginBottom: 20 }}>
            <Button
              id="acutalize-payment-data-btn"
              size="large"
              onClick={() => dispatch(openUpdatePaymentDetailsModal(() => loadData()))}
            >
              {lg.zahlungsinformationen_aktualisieren}
            </Button>
            {!isV2 && (
              <Button
                id="change-plan-button"
                style={{ marginLeft: 12 }}
                onClick={() => {
                  dispatch(openModal(TierPicker, {}));
                }}
                size="large"
              >
                {lg.plan_wechseln}
              </Button>
            )}
            {showQuitSubscriptionBtn && (
              <Button
                style={{ marginLeft: 12 }}
                type="danger"
                size="large"
                id="quit-subscription-button"
                children={lg.abo_kuendigen}
                onClick={() => dispatch(openModal(CancelSubscriptionModal, { callback: loadData }))}
              />
            )}
          </div>
          <div>
            <div className="billsWrapper">
              <h3>{lg.rechnungen}</h3>
              <div className="billsContent">
                {invoices &&
                  invoices.map((invoice, i) => {
                    const dispute = disputedInvoiceStatuses?.find((iv) => iv.invoiceId === invoice.id);
                    let status: InvoiceStatus = InvoiceStatus.OPEN;
                    const isOverdue = invoice.due_date && invoice.due_date < Math.floor(Date.now() / 1000);

                    if (invoice.collection_method === "send_invoice" && invoice.status === "open" && !isOverdue) {
                      status = InvoiceStatus.OPEN;
                    }

                    if (
                      invoice.status === "open" &&
                      isOverdue &&
                      (invoice.collection_method === "send_invoice" || !(invoice as any).paymentProcessing)
                    ) {
                      status = InvoiceStatus.OVERDUE;
                    }

                    if (
                      invoice.status === "open" &&
                      (invoice as any).paymentProcessing // manually added bool from the backend - this only applies to charge_automatically invoices
                    ) {
                      status = InvoiceStatus.PROCESSING;
                    }

                    if (invoice.status === "paid" && (!dispute || (dispute && dispute.status === "resolved"))) {
                      status = InvoiceStatus.PAID;
                    }

                    if (invoice.status === "paid" && dispute && dispute.status === "unresolved") {
                      status = InvoiceStatus.DISPUTE;
                    }

                    return (
                      <div
                        key={invoice.id}
                        className={i % 2 === 0 ? "fb row noGrow billRow bright" : "fb row noGrow billRow"}
                      >
                        <div className="billDate grow">{formatUnixDate(invoice.period_end!)}</div>
                        <div className="billStatus noGrow">
                          {invoice.customer_address?.country === "DE" && (
                            <span style={{ opacity: 0.6, fontSize: 12 }}>
                              ({lg.inkl_mwst}){"  "}
                            </span>
                          )}
                          {formatPrice(invoice.total)} €
                          {status === InvoiceStatus.OPEN && <span className="openBadge">{lg.offen}</span>}
                          {status === InvoiceStatus.OVERDUE && <span className="overdueBadge">{lg.überfällig}</span>}
                          {status === InvoiceStatus.PAID && <span className="paidBadge">{lg.bezahlt}</span>}
                          {status === InvoiceStatus.PROCESSING && (
                            <span className="processingBadge">{lg.in_bearbeitung}</span>
                          )}
                          {status === InvoiceStatus.DISPUTE && (
                            <span
                              className="disputeBadge"
                              data-rh={
                                lg.leider_ist_die_zahlung_zu_dieser_rechnung_von_ihnen_oder_ihrer_bank_zurückgezogen_worden_der_betrag_muss_aus_diesem_grund_nun_überwiesen_werden
                              }
                            >
                              {lg.fehlgeschlagen}
                            </span>
                          )}
                        </div>
                        <a
                          href={invoice.invoice_pdf!}
                          target="_blank"
                          data-rh-at="right"
                          data-rh="Rechnung herunterladen"
                          className="billPdf noGrow"
                        >
                          <Icon style={{ fontSize: 20 }} type="file-pdf"></Icon>
                        </a>
                      </div>
                    );
                  })}
                {invoices && !invoices.length && (
                  <div style={{ opacity: 0.4 }}>{lg.es_wurde_noch_keine_rechnung_ausgestellt}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BusyWrapper>
  );
});
