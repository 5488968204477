import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../types/AppState";

import "./styles.scss";
import { openModal } from "../../../../../actions/modal";
import AbsenceEntitlementModal from "../../../../../components/modals/AbsenceEntitlementModal/AbsenceEntitlementModal";
import { ThunkDispatch } from "redux-thunk";
import EntitlementUserCell from "./EntitlementUserCell/EntitlementUserCell";
import EntitlementEntryCell from "./EntitlementEntryCell/EntitlementEntryCell";
import ContingentCorrectionModal from "../../../../../components/modals/ContingentCorrectionModal/ContingentCorrectionModal";
import {
  getRemainingEntitlementDays,
  getLatestEntitlementEntry,
} from "../../../../../components/modals/AbsenceModal/localHelpers";
import TakenDaysCell from "./TakenDaysCell/TakenDaysCell";
import { IUser, IUserFull } from "../../../../../shared/entities/IUser";
import { IAbsenceEntitlement } from "../../../../../shared/entities/IAbsenceEntitlement";
import { IContingentCorrection } from "../../../../../shared/entities/IContingentCorrection";
import { contingentCorrectionRepository } from "../../../../../repositories/contingentCorrectionRepository";
import { v4 as uuid } from "uuid";

const mapStateToProps = (state: AppState) => ({
  month: state.ui.absences.selectedMonth,
  year: state.ui.absences.selectedYear,
});

type State = {};
type OwnProps = {
  user: IUserFull;
  absenceEntitlements: IAbsenceEntitlement[];
  takenAbsenceDays?: number;
  requestedAbsenceDays?: number;
  absenceTypeId: string;
  contingentCorrection?: IContingentCorrection;
  contingentOverrideCorrection?: IContingentCorrection;
  // prev Year Data
  takenAbsenceDaysPrevYear?: number;
  contingentCorrectionPrevYear?: IContingentCorrection;
  contingentOverrideCorrectionPrevYear?: IContingentCorrection;
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & { dispatch: ThunkDispatch<any, any, any> };

class EntitlementRow extends PureComponent<Props, State> {
  getCurrentContingentEntry = () => {
    return this.props.absenceEntitlements.find((c) => c.year === this.props.year);
  };

  contingentCellClicked = (event: any) => {
    const { year, user, dispatch, absenceEntitlements, absenceTypeId } = this.props;

    const currentEntry = this.getCurrentContingentEntry();
    const latestEntry = getLatestEntitlementEntry(absenceEntitlements, year);

    dispatch(
      openModal(AbsenceEntitlementModal, {
        absenceTypeId: absenceTypeId,
        absenceEntitlement: currentEntry,
        userId: user.id,
        year,
        displayDays: latestEntry ? latestEntry.days : undefined,
      })
    );
  };

  contingentCorrectionCellClicked = (isOverride: boolean) => {
    const { year, user, contingentCorrection, contingentOverrideCorrection, dispatch, absenceTypeId } = this.props;

    const correction = isOverride ? contingentOverrideCorrection : contingentCorrection;

    dispatch(
      openModal(ContingentCorrectionModal, {
        year,
        isOverride,
        userId: user.id,
        correction,
        absenceTypeId,
      })
    );
  };

  takeOverRemainingDays = (remainingDays: number, currenYearDays: number = 0) => {
    this.props.dispatch(
      contingentCorrectionRepository.create({
        id: uuid(),
        userId: this.props.user.id,
        days: remainingDays + currenYearDays,
        year: this.props.year,
        note: "Resturlaub übernommen",
        isOverride: true,
        absenceTypeId: this.props.absenceTypeId,
      })
    );
  };

  render() {
    const {
      user,
      contingentCorrection,
      contingentOverrideCorrection,
      takenAbsenceDays,
      takenAbsenceDaysPrevYear,
      contingentCorrectionPrevYear,
      contingentOverrideCorrectionPrevYear,
      requestedAbsenceDays,
      absenceEntitlements,
      year,
    } = this.props;
    const currentContingent = this.getCurrentContingentEntry();
    const latestContingent = getLatestEntitlementEntry(absenceEntitlements, year);

    const remainingEntitlement = getRemainingEntitlementDays(
      contingentCorrection,
      contingentOverrideCorrection,
      takenAbsenceDays,
      absenceEntitlements,
      year
    );

    const remainingEntitlementLastYear = getRemainingEntitlementDays(
      contingentCorrectionPrevYear,
      contingentOverrideCorrectionPrevYear,
      takenAbsenceDaysPrevYear,
      absenceEntitlements,
      year - 1
    );

    const validContingentOfYear = currentContingent ? currentContingent.days : latestContingent?.days || 0;

    return (
      <div className="vacationAccountConfigsMain">
        <EntitlementUserCell
          user={user}
          remainingDaysPrevYear={remainingEntitlementLastYear}
          year={year}
          isOvertakePossible={!contingentOverrideCorrection}
          overtakeRemainingDays={() => this.takeOverRemainingDays(validContingentOfYear, remainingEntitlementLastYear)}
        />
        <EntitlementEntryCell
          column={1}
          onClick={this.contingentCellClicked}
          enteredDays={currentContingent ? currentContingent.days : undefined}
          displayDays={latestContingent ? latestContingent.days : undefined}
        />
        <EntitlementEntryCell
          column={2}
          onClick={() => this.contingentCorrectionCellClicked(true)}
          enteredDays={contingentOverrideCorrection ? contingentOverrideCorrection.days : undefined}
          // icon={contingentOverrideCorrection?.note ? "mail" : undefined}
        />
        <EntitlementEntryCell column={3} displayDays={requestedAbsenceDays || "-"} />
        {/* <EntitlementEntryCell column={4} displayDays={takenVacationDays || '-'} /> */}
        <TakenDaysCell
          column={4}
          value={takenAbsenceDays}
          correction={contingentCorrection}
          correctionClicked={() => this.contingentCorrectionCellClicked(false)}
        />
        {/* <EntitlementEntryCell
          column={5}
          onClick={() => this.contingentCorrectionCellClicked(false)}
          enteredDays={contingentCorrection ? contingentCorrection.days : undefined}
          icon={contingentCorrection?.note ? 'mail' : undefined}
          withSign={true}
        /> */}
        <EntitlementEntryCell column={5} displayDays={remainingEntitlement} />
      </div>
    );
  }
}

export default connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(EntitlementRow);
