import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useState } from "react";
import TZModal from "../TZModal/TZModal";

import { Input, Button, DatePicker, notification } from "antd";
import { closeModal } from "../../actions/modal";
import { useSelector } from "../../helpers/redux";
import { deleteAllShiftsOfWeek, delteAllShiftRelations } from "../../actions/shift";
import {
  simpleDateToMoment,
  toMoment,
  toMomentUnsafe,
  toSimpleDate,
  toSimpleDateUnsafe,
} from "../../shared/helpers/timeHelpers";
import { shiftRepository } from "../../repositories/shiftRepository";
import { fetchBatchDelteShifts } from "./batchDelteHelpers";
import { BasicSelect } from "../BasicSelect/BasicSelect";
import { selectActiveUsersFull } from "../../selectors/ActiveUserSelectors";
import { selectActiveBranches } from "../../selectors/ActiveBranchesSelector";
import { selectActiveJobPositions } from "../../selectors/ActiveJobPositionsSelector";
import moment from "moment";
import { selectActiveWorkSpaces } from "../../selectors/ActiveWorkSpacesSelector";
import "./styles.scss";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import { selectUserFullMap } from "../../selectors/userFullMapSelector";
import {
  selectBranchMap,
  selectJobPositionMap,
  selectShiftAddressMap,
  selectWorkSpaceMap,
} from "../../selectors/mapSelectors";
import _ from "lodash";

type Props = {};

export type BatchDeleteFilter = {
  startDate?: string;
  endDate?: string;
  userId?: string;
  jobPositionId?: string;
  labelId?: string;
  shiftAddressId?: string;
  branchId?: string;
  onlyOpenShifts?: boolean;
};

export const BatchDeletePopup = (props: Props) => {
  const dispatch = useDispatch<DispFn>();
  // const [checkValue, setCheckValue] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isFilterDirty, setFilterDirty] = useState(false);
  const [filter, setFilter] = useState<BatchDeleteFilter>({});
  const [showConfirm, setShowConfirm] = useState(false);

  const activeUsers = useSelector((s) => selectActiveUsersFull(s, toSimpleDate(moment())));
  const activeBranches = useSelector(selectActiveBranches);
  const activeJobPositions = useSelector(selectActiveJobPositions);
  const activeWorkSpaces = useSelector(selectActiveWorkSpaces);
  const shiftAddresses = useSelector((s) => s.data.shiftAddresses);
  const shifts = useSelector((s) => s.data.shifts);

  const userMap = useSelector(selectUserFullMap);
  const branchMap = useSelector(selectBranchMap);
  const jobPosMap = useSelector(selectJobPositionMap);
  const workSpaceMap = useSelector(selectWorkSpaceMap);
  const shiftAddressMap = useSelector(selectShiftAddressMap);

  const getSelectOptions = (list: { id: string; name: string }[]) => {
    return list.map((l) => ({ value: l.id, label: l.name }));
  };

  const updateFilter = (_filter: Partial<BatchDeleteFilter>) => {
    const nextFilter = { ...filter, ..._filter };
    setFilter(nextFilter);
    setFilterDirty(true);
    setShowConfirm(false);
  };

  const loadData = async () => {
    setLoading(true);
    await dispatch(fetchBatchDelteShifts(filter));
    setLoading(false);
    setFilterDirty(false);
  };

  const deleteClicked = () => {
    showConfirm ? delteShifts() : setShowConfirm(true);
  };

  const delteShifts = async () => {
    const shiftIds = shiftsFiltered.map((s) => s.id);
    const length = shiftsFiltered.length;
    setIsDeleting(true);
    await dispatch(delteAllShiftRelations(shiftsFiltered));
    await dispatch(shiftRepository.removeList(shiftIds));
    setIsDeleting(false);
    notification.success({ message: length + " " + lg.schichten_wurden_gelöscht });
  };

  const isDatesMissing = !filter.startDate || !filter.endDate;

  const { startDate, endDate, userId, branchId, shiftAddressId, jobPositionId, labelId } = filter;
  const shiftsFiltered = _.orderBy(
    shifts.filter(
      (s) =>
        !isDatesMissing &&
        !isFilterDirty &&
        s.date >= startDate! &&
        s.date <= endDate! &&
        (!userId || s.userId === userId!) &&
        (!branchId || s.branchId === branchId!) &&
        (!shiftAddressId || s.addressId === shiftAddressId!) &&
        (!jobPositionId || s.jobPositionId === jobPositionId!) &&
        (!labelId || s.workSpaceId === labelId!)
    ),
    ["date", "startTime"]
  );

  return (
    <TZModal style={{ width: 1000 }}>
      <TZModal.Head>{lg.bulk_schichten_löschen}</TZModal.Head>
      <TZModal.Body>
        <div className="batchDeltePopupMain">
          <div className="sideBar">
            <div className="cell startDate">
              <DatePicker
                value={toMomentUnsafe(filter.startDate)}
                onChange={(mom) => updateFilter({ startDate: toSimpleDateUnsafe(mom) })}
                format="DD. MMM YYYY"
                placeholder={lg.von_start}
              />
            </div>
            <div className="cell endDate">
              <DatePicker
                value={toMomentUnsafe(filter.endDate)}
                onChange={(mom) => updateFilter({ endDate: toSimpleDateUnsafe(mom) })}
                format="DD. MMM YYYY"
                placeholder={lg.bis}
              />
            </div>
            <div className="cell user">
              <BasicSelect
                value={filter.userId}
                onChange={(userId: string) => updateFilter({ userId })}
                options={getSelectOptions(activeUsers)}
                placeholder={lg.alle_mitarbeiter}
                allowClear={true}
              />
            </div>
            <div className="cell branch">
              <BasicSelect
                value={filter.branchId}
                onChange={(branchId: string) => updateFilter({ branchId })}
                options={getSelectOptions(activeBranches)}
                placeholder={lg.alle_standorte}
                allowClear={true}
              />
            </div>
            <div className="cell jobPos">
              <BasicSelect
                value={filter.jobPositionId}
                onChange={(jobPositionId: string) => updateFilter({ jobPositionId })}
                options={getSelectOptions(activeJobPositions)}
                placeholder={lg.alle_rollen}
                allowClear={true}
              />
            </div>
            {activeWorkSpaces.length && (
              <div className="cell label">
                <BasicSelect
                  value={filter.labelId}
                  onChange={(labelId: string) => updateFilter({ labelId })}
                  options={getSelectOptions(activeWorkSpaces)}
                  placeholder={lg.alle_labels}
                  allowClear={true}
                />
              </div>
            )}
            {shiftAddresses.length && (
              <div className="cell address">
                <BasicSelect
                  value={filter.shiftAddressId}
                  onChange={(shiftAddressId: string) => updateFilter({ shiftAddressId })}
                  options={shiftAddresses.map((a) => ({ value: a.id, label: a.name || a.address }))}
                  placeholder={lg.alle_adressen}
                  allowClear={true}
                />
              </div>
            )}
          </div>
          <div className="content">
            {isLoading && <div className="loading">{lg.wird_geladen}...</div>}
            {isDatesMissing && !isLoading && <div className="filterMissingNote"> {lg.bitte_filter_wählen} </div>}
            {isFilterDirty && !isDatesMissing && !isLoading && (
              <Button
                type="primary"
                disabled={isDatesMissing || !isFilterDirty}
                icon={isLoading ? "loading" : undefined}
                children={isLoading ? "" : lg.filter_anwenden}
                onClick={loadData}
              />
            )}
            {!isDatesMissing && !isFilterDirty && !isLoading && (
              <div className="shiftListWrapper">
                {!shiftsFiltered.length && (
                  <div className="noShifts">{lg.keine_schichten_im_filterbereich_vorhanden}</div>
                )}
                {!!shiftsFiltered.length && (
                  <div className="shiftList">
                    {shiftsFiltered.map((s) => (
                      <div className="shiftRow" key={s.id}>
                        <div className="cell date">{toMoment(s.date).format("DD MMM")}</div>
                        <div className="cell time">{`${s.startTime} - ${s.endTime}`}</div>
                        <div className="cell userId">{s.userId ? userMap[s.userId]?.name : lg.offen}</div>
                        {!!activeBranches.length && <div className="cell branchId">{branchMap[s.branchId]?.name}</div>}
                        <div className="cell jobPosId">{jobPosMap[s.jobPositionId]?.name}</div>
                        <div className="cell labelId">{s.workSpaceId && workSpaceMap[s.workSpaceId]?.name}</div>
                        {!!shiftAddresses.length && (
                          <div className="cell adderssId">{s.addressId && shiftAddressMap[s.addressId]?.name}</div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </TZModal.Body>
      <TZModal.Footer>
        <Button
          id="batch-delte-popup-button"
          type={showConfirm ? "default" : "danger"}
          onClick={deleteClicked}
          loading={isDeleting}
          style={{ marginLeft: "auto" }}
          disabled={!shiftsFiltered.length}
          children={
            isDeleting
              ? lg.daten_werden_gelöscht
              : showConfirm
              ? (shiftsFiltered.length || "") + " " + lg.schichten_wirklich_löschen
              : (shiftsFiltered.length || "") + " " + lg.schichten_löschen
          }
        />
      </TZModal.Footer>
    </TZModal>
  );
};
