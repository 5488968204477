import { selectRosterSettingsByUser } from "./rosterSettingsByUserSelector";
import { IAvailability } from "./../shared/entities/IAvailability";
import { FarFuture } from "./../actions/shift";
import { WeekDays } from "./../shared/constants/WeekDays";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";

export const selectAvailabilites = createSelector(
  [
    (state: AppState) => state.data._availabilities,
    (s) => s.data.contracts,
    selectRosterSettingsByUser,
    (s) => s.data.rosterSettings[0],
    (s) => s.data.tenantInfo.isV2,
  ],
  (availabilities, contract, rosterSettingsByUser, generalRosterSettings, isV2): IAvailability[] => {
    if (isV2) {
      return [];
    }

    if (!generalRosterSettings.markFreeDaysAsUnavailable) {
      return availabilities;
    }

    const generatedList: IAvailability[] = [];

    contract.forEach((contract) => {
      const markFreeDaysAsUnavailable = rosterSettingsByUser[contract.userId]?.markFreeDaysAsUnavailable;
      const freeWeekDays = WeekDays.filter(
        (wd) => contract.dailyQuota[wd] === undefined || contract.dailyQuota[wd] === null
      );
      if (markFreeDaysAsUnavailable) {
        generatedList.push({
          id: contract.validFrom + contract.userId,
          userId: contract.userId,
          startDate: contract.validFrom,
          endDate: contract.validTo >= FarFuture ? undefined : contract.validTo,
          weekDays: freeWeekDays,
          isWholeDay: true,
          isAvailable: false,
          isSingleDay: false,
          isGeneratedByContract: true,
        });
      }
    });

    return [...availabilities, ...generatedList];
  }
);
