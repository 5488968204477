export const setAbsenceCalenarFilterAT = "@AV/setAbsenceCalenarFilter";

export const setAbsenceCalenarFilter = (filter: Partial<AbsenceUiFilter>) => ({
  type: setAbsenceCalenarFilterAT,
  payload: filter,
});

export type AbsenceUiFilter = {
  searchInput?: string;
  positionFilter?: string;
  branchFilter?: string;
};

export const getAbsenceCalendarFilterReducer = (
  state = {},
  action: any
): AbsenceUiFilter => {
  switch (action.type) {
    case setAbsenceCalenarFilterAT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
