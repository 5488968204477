import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "./styles.scss";
import Page from "../../components/Page/Page";
import { PinPad } from "./pinPad/PinPad";
import { Button, Modal } from "antd";
import { Clock } from "../../components/Clock/Clock";
import { authRepository } from "../../repositories/authRepository";
import { useSelector } from "../../helpers/redux";
import { SelectableClockingUsersList } from "./SelectableClockingUsersList";
import { featuresSelector } from "../../selectors/FeaturesSelector";
import { getUserName } from "../../shared/helpers/userHelpers";
import { openModal } from "../../actions/modal";
import { CloseTimeClockStationModal } from "../../components/CloseTimeClockStationModal/CloseTimeClockStationModal";
import WhiteAplanoLogo from "../../components/Logos/WhiteAplanoLogo";
import { TimeClockUser } from "./TimeClockUser";
import { withErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";
import { TimeZoneWarning } from "../../components/TimeZoneWarning/TimeZoneWarning";
import { PunchClockUser } from "./PunchClockUser/PunchClockUser";
import cn from "classnames";
import WhiteZeitguruLogo from "../../components/Logos/WhiteZeitguruLogo";

type Props = {};

export const TimeClock = withErrorBoundary((props: Props) => {
  const timeClockMode = useSelector((s) => s.ui.timeClockMode);
  const isV2 = useSelector((s) => s.data.tenantInfo.isV2);
  const dispatch = useDispatch();
  const branches = useSelector((s) => s.data.branches);
  const users = useSelector((s) => s.data.users);

  const [selectedUserIdForPinPad, setSelectedUserIdForPinPad] = useState<string | null>();
  const [selectedShiftOrClockingId, setSelectedShiftOrClockingId] = useState<string>();
  const [selectedUserId, setSelectedUserId] = useState<string | null>();
  const wrongTimeZone = useSelector((s) => s.ui.wrongTimeZone);

  const handlePinPadSubmit = (submitValue: string) => {
    const user = users.find((u) => {
      return u.id === selectedUserIdForPinPad && u.timeClockId === submitValue;
    });
    if (user) {
      setSelectedUserId(user.id);
      setSelectedUserIdForPinPad(null);
      return true;
    } else {
      return false;
    }
  };

  const deselectUserIdForPinPad = () => {
    setSelectedUserIdForPinPad(null);
  };

  const deselectUserId = () => {
    setSelectedUserId(null);
  };

  const handleCloseClick = () => {
    dispatch(openModal(CloseTimeClockStationModal, {}));
  };

  console.log("TimeClock.txs");

  if (!timeClockMode.branchIds) {
    return <>{lg.stempeluhr_bitte_neu_starten}</>;
  }

  if (wrongTimeZone) {
    return <TimeZoneWarning />;
  }

  return (
    <Page noWrapper className={cn({ timeClockStationPage: true, isV2 })}>
      <Button
        id="time-clock-close"
        icon="close"
        style={{
          position: "absolute",
          right: 40,
          top: 36,
          background: isV2 ? "none" : "#1a90ff",
          color: "white",
          opacity: 0.7,
        }}
        onClick={handleCloseClick}
        type="ghost"
      />
      <div className="fb column grow shrink">
        <div
          className="fb column timeClock aCenter grow shrink"
          style={{ width: selectedUserId ? 560 : 400, height: "100%" }}
        >
          <div className="fb grow shrink" style={{ width: "100%", height: "100%" }}>
            <h1 style={{ color: "white", textAlign: "center", margin: 20 }}>{lg.stempeluhr}</h1>
            {!selectedUserIdForPinPad && !selectedUserId && (
              <SelectableClockingUsersList setSelectedUserIdForPinPad={setSelectedUserIdForPinPad} />
            )}
            {selectedUserIdForPinPad && (
              <PinPad
                maxLength={4}
                onSubmit={handlePinPadSubmit}
                userId={selectedUserIdForPinPad}
                deselectUserId={deselectUserIdForPinPad}
              />
            )}
            {selectedUserId && isV2 && <PunchClockUser userId={selectedUserId} deselectUserId={deselectUserId} />}
            {selectedUserId && !isV2 && (
              <TimeClockUser
                userId={selectedUserId}
                deselectUserId={deselectUserId}
                selectedShiftOrClockingId={selectedShiftOrClockingId}
                setSelectedShiftOrClockingId={setSelectedShiftOrClockingId}
                setSelectedUserId={setSelectedUserId}
              />
            )}
          </div>
        </div>
        {isV2 ? (
          <WhiteZeitguruLogo style={{ width: 140, position: "absolute", left: 30, bottom: 10 }} />
        ) : (
          <WhiteAplanoLogo style={{ width: 140, position: "absolute", left: 20, bottom: 20 }} />
        )}
      </div>
    </Page>
  );
});
