import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Statistic, Row, Col, Button, Card, Switch, DatePicker } from "antd";
import { IAdminTenantData } from "../../../shared/entities/IAdminData";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  simpleDateTimeToMoment,
  simpleDateToMoment,
} from "../../../shared/helpers/timeHelpers";
import { SDateFormat } from "../../../shared/helpers/SimpleTime";

type Props = {
  tenants: IAdminTenantData[];
};

export const AdminRegistrationsGraph = (props: Props) => {
  const [startDate, setStartDate] = useState("2020-09-15");
  const startDateMoment = simpleDateToMoment(startDate);
  const todayMoment = moment();
  const momentsRange = _.reverse(
    _.times(todayMoment.diff(startDateMoment, "days"), (i) => {
      return todayMoment.clone().add(-1 * i, "days");
    })
  );
  const data = momentsRange.map((m) => {
    return {
      x: m.format(SDateFormat),
      y: props.tenants.filter((t) => moment(t.createdAt).isSame(m, "day")).length,
    };
  });
  console.log(data);
  const disabledStart = moment("2020-06-15", SDateFormat);
  return (
    <div>
      <div className="fb row aCenter" style={{ marginBottom: 12, marginLeft: 50 }}>
        <h3 style={{ margin: 0 }}>Registrierungen</h3>
        <span style={{ marginLeft: 20 }}>
          Ab dem:
          <DatePicker
            style={{ marginLeft: 5 }}
            disabledDate={(m) =>
              !!m?.isBefore(disabledStart) || !!m?.isAfter(todayMoment)
            }
            allowClear={false}
            value={startDateMoment}
            onChange={(m) => setStartDate(m?.format(SDateFormat)!)}
          />
        </span>
      </div>

      <ResponsiveContainer height={200} width="100%">
        <LineChart data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
          <Line type="monotone" dataKey="y" stroke="#1a90ff" />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis
            dataKey="x"
            tickFormatter={(v) => simpleDateToMoment(v).format("DD.MMM")}
          />
          <YAxis dataKey="y" />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
