import React from "react";
import { Icon } from "antd";
import { getLocaleLang } from "../../helpers/dateFormatHelper";
import { envi } from "../../env";

export const MadeWithLoveLogo = () => (
  <>
    <a
      href={envi.isV2() ? "https://zeitguru.de" : "https://aplano.de"}
      target="_blank"
      rel="noopener noreferrer"
      className="avetiqLink"
    >
      Made with <Icon type="heart" theme="filled" style={{ color: "#ff4d4f", fontSize: 11 }} /> in Germany
    </a>
    <a
      href={
        getLocaleLang() === "de"
          ? envi.isV2()
            ? "https://www.zeitguru.de/impressum"
            : "https://www.aplano.de/impressum"
          : envi.isV2()
          ? "https://www.zeitguru.de/impressum"
          : "https://www.getaplano.com/imprint"
      }
      target="_blank"
      style={{ opacity: 0.7 }}
      rel="noopener noreferrer"
      className="avetiqLink"
    >
      {lg.impressum}
    </a>
  </>
);
