import { Button, Icon, Input } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { DispFn } from "../../../frontend-core/types/thunkTypes";
import { useSelector } from "../../../helpers/redux";
import { messageRepository } from "../../../repositories/messageRepository";
import { threadInfoRepository } from "../../../repositories/threadInfoRepository";
import { userThreadInfoRepository } from "../../../repositories/userThreadInfoRepository";
import { selectUserMap } from "../../../selectors/mapSelectors";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { decryptUser, getUserName } from "../../../shared/helpers/userHelpers";
import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import { getChatTitle, getImageOfThread, timestampToSimpleDate } from "../chatHelpers";
import { Message } from "./Message";
import { ThreadStyles } from "./styles";
import AvatarDefaultImageSource from "./../avatar-default-image.png";
import { ChatSidebarMode } from "../ChatSidebarMode";
import { IMessage } from "../../../shared/entities/IMessage";
import { DateRow } from "./DateRow";
import { selectRosterSettingsForCurrentUser } from "../../../selectors/rosterSettingsByUserSelector";
import { localizeFormat } from "../../../helpers/dateFormatHelper";
import { selectUserThreadInfos } from "../../../selectors/userThreadInfoSelector";

type Props = {
  selectedThreadId: string;
  setMode: (m: ChatSidebarMode) => void;
};

type ListItem = {
  type: string;
  date: string;
  message?: IMessage;
  id: string;
};

const Comp = (props: Props & BusyInjectorProps) => {
  const dispatch = useDispatch<DispFn>();
  const [newMessageText, setNewMessageText] = useState("");
  const messagesRef = useRef<HTMLDivElement | null>(null);
  const userMap = useSelector(selectUserMap);
  const now = moment();
  const sessionInfo = useSelector(selectSessionInfo);
  const { selectedThreadId } = props;
  const userThreadInfos = useSelector(selectUserThreadInfos);
  const threadInfos = useSelector((s) => s.data.threadInfos);
  const userThreadInfo = userThreadInfos.find((t) => t.id === selectedThreadId)!;
  const threadInfo = threadInfos.find((t) => t.id === selectedThreadId);
  const messages = useSelector((s) => s.data.messages)[selectedThreadId] || [];
  const title = getChatTitle(userThreadInfo, userMap, sessionInfo.user.id);

  const rosterSettingsForCurrentUser = useSelector(selectRosterSettingsForCurrentUser);
  const { canChat, canGroupChat, canChatWithUsersFromOtherBranch } = rosterSettingsForCurrentUser;

  useEffect(() => {
    setNewMessageText("");
    const promises: Promise<any>[] = [
      dispatch(userThreadInfoRepository.markAsSeen(selectedThreadId)),
      dispatch(messageRepository.addListener(selectedThreadId)),
      dispatch(threadInfoRepository.addListener(selectedThreadId)),
    ];
    props.load(Promise.all(promises));

    return () => {
      dispatch(messageRepository.removeListener(selectedThreadId));
      dispatch(threadInfoRepository.removeListener(selectedThreadId));
    };
  }, [selectedThreadId]);

  const send = () => {
    if (!newMessageText) {
      return;
    }
    dispatch(
      messageRepository.create({
        text: newMessageText,
        threadId: selectedThreadId,
      })
    );
    setNewMessageText("");
  };

  const isGroupChat = !!userThreadInfo?.title?.length;
  let photoUrl: any = getImageOfThread(userThreadInfo, userMap, sessionInfo.user.id);

  const inputDisabled =
    !canChat ||
    (!canGroupChat && isGroupChat) ||
    (!canChatWithUsersFromOtherBranch &&
      userThreadInfo.userIds.find(
        (uId) =>
          uId !== sessionInfo.user.id && !_.intersection(userMap[uId].branchIds, sessionInfo.user.branchIds).length
      ));

  const generatedItems = useMemo(() => {
    const groupsByDate = _.groupBy(messages, (m) => timestampToSimpleDate(m.id.split("_")[0]));
    const initialAcc: ListItem[] = [];
    const result: ListItem[] = _.reduce(
      groupsByDate,
      (acc, value, key) => {
        return [
          ...acc,
          ...value.map((message) => ({ message: message, type: "message", date: key, id: message.id })),
          {
            type: "date",
            date: key,
            id: key,
          },
        ];
      },
      initialAcc
    );
    return result;
  }, [messages]);

  const renderItem = (item: ListItem) => {
    if (item.type === "date") {
      return <DateRow key={item.id} date={item.date} now={now} />;
    }
    const message = item.message!;

    const messageIndex = messages.findIndex((m) => m.id === message.id);
    // eslint-disable-next-line no-undef-init
    let previousMessage: IMessage | undefined = undefined;
    if (messages.length > 1 && messageIndex !== messages.length - 1) {
      previousMessage = messages[messageIndex + 1];
    }

    const messageTimeStamp = parseInt(message.id.split("_")[0]);
    const messageIsOlderThan3Hours = moment.utc(messageTimeStamp).isBefore(moment().add(-3, "hours"));
    const canDelete = sessionInfo.isAdmin() || (sessionInfo.user.id === message.userId && !messageIsOlderThan3Hours);

    const onDeleteClick = () => {
      dispatch(
        messageRepository.delete({
          threadId: message.threadId,
          messageId: message.id,
          previousMessage,
          isLatestMessage: messageIndex === 0,
        })
      );
    };

    return (
      <Message
        sessionInfo={sessionInfo}
        userMap={userMap}
        userThreadInfo={userThreadInfo}
        message={message}
        key={item.id}
        canDelete={canDelete}
        onDeleteClick={onDeleteClick}
      />
    );
  };

  return (
    <div className="fb column grow shrink" style={{ minWidth: 530, maxWidth: 530 }}>
      <div className="fb row" style={ThreadStyles.head}>
        <div className="fb row aCenter shrink" style={{ fontSize: 16, overflow: "hidden" }}>
          {photoUrl && <img src={photoUrl} style={ThreadStyles.headImage} />}

          <div className="threadTitle shrink">{title}</div>
          <Button
            shape="circle"
            icon={"info"}
            style={{ marginLeft: 12 }}
            onClick={() => props.setMode(isGroupChat ? ChatSidebarMode.GroupThreadInfo : ChatSidebarMode.ThreadInfo)}
          />
        </div>
      </div>
      <div
        className="fb column shrink grow"
        style={{ ...ThreadStyles.content }}
        ref={messagesRef}
        onScroll={(e) => {
          if (-1 * e.currentTarget.scrollTop === e.currentTarget.scrollHeight - e.currentTarget.clientHeight) {
            props.load(dispatch(messageRepository.loadMore(selectedThreadId)));
          }
        }}
      >
        {generatedItems.map(renderItem)}
        {props.isLoading() && (
          <div className="fb row aCenter jCenter noGrow noShrink" style={{ padding: 12 }}>
            <Icon type="loading" style={{ fontSize: "40px", color: "#1990ff" }} spin />
          </div>
        )}
        {!props.isLoading() && isGroupChat && threadInfo && (
          <div className="fb row aCenter noGrow noShrink jCenter" style={{ padding: 12 }}>
            <div style={ThreadStyles.systemMessage}>
              {lg.konversation_erstellt_am_date_von_user(
                moment(threadInfo.creationTimestamp).format(localizeFormat("ddd DD.MM.YYYY HH:mm")),
                getUserName(userMap[threadInfo.createdByUserId])
              )}
            </div>
          </div>
        )}
      </div>
      <div className="fb row" style={ThreadStyles.footer}>
        <Input
          value={newMessageText}
          onChange={(e) => setNewMessageText(e.target.value)}
          autoFocus
          onKeyPress={(e) => e.key === "Enter" && send()}
          style={{ borderRadius: 50 }}
          placeholder={lg.schreib_eine_nachricht}
          disabled={!!inputDisabled}
        />
        <Button onClick={send} icon="caret-right" style={{ marginLeft: 12 }} shape="circle" />
      </div>
    </div>
  );
};

export const Thread = React.memo(busyInjector(Comp));
