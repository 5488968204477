import { getTrackingMiniUpdates } from "./../shared/helpers/creditSyncHelpers";
import { CrudOperation } from "./../shared/helpers/firebaseHelpers";
import { trackingToDb } from "./../shared/helpers/dbTransforms";
import { selectShiftMap } from "./../selectors/shiftMapSelector";
import { AppState } from "./../types/AppState";
import { DispFn } from "./../frontend-core/types/thunkTypes";
import { ITrackingDB } from "./../shared/entities/ITracking";
import { BaseRepository } from "../frontend-core/BaseRepository";
import { ITracking } from "../shared/entities/ITracking";
import _ from "lodash";
import { shiftRepository } from "./shiftRepository";
import { selectTrackingMap } from "../selectors/mapSelectors";

class TrackingRepository extends BaseRepository<ITracking, ITrackingDB> {
  constructor() {
    super("trackings");
  }

  toDbEntity(s: ITracking): ITrackingDB {
    return trackingToDb(s);
  }

  toLocalEntity(s: ITrackingDB): ITracking {
    return _.omit(s, ["branchId_date", "userId_date", "userId_isAccepted"]);
  }

  onListenerEvent(trackings: ITracking[]) {
    return (dispatch: DispFn, getState: () => AppState): void => {
      const shiftMap = selectShiftMap(getState());
      const missingShiftIds = trackings.map((t) => t.id).filter((shiftId) => !shiftMap[shiftId]);

      if (missingShiftIds.length) {
        dispatch(shiftRepository.fetchByIds(missingShiftIds));
      }
    };
  }

  getExtraUpdates = (actions: CrudOperation<ITracking>[]) => {
    return (dispatch: DispFn, getState: () => AppState) => {
      const trackingMag = selectTrackingMap(getState());
      return getTrackingMiniUpdates(actions, trackingMag);
    };
  };
}

export const trackingRepository = new TrackingRepository();
