import { batch } from "react-redux";
import { CLEAR_DATA_ACTION_TYPE } from "./maintenanceRepo";
import { firebaseListenerRepo } from "./firebaseListenerRepo";
import { updateCreditsOfRoster } from "../actions/creditActions/updateCreditsOfRoster";
import { CreditsMap, CreditsPayload } from "../actions/creditActions/creditTypes";
import { DispFn } from "./../frontend-core/types/thunkTypes";
import { AppState } from "./../types/AppState";
import { Reducer, AnyAction } from "redux";
import firebase from "firebase/compat/app";
import { LoopProtector } from "../frontend-core/LoopProtector";

const CreditAction = {
  SET: "@@AV/SET_CREDITS",
};

const getUserOfKey = (creditKey: string) => {
  return creditKey.split("_")[0];
};

const getSurcharge = (
  duration: number,
  surchargeAbsolute: number | undefined,
  surchargePercent: number | undefined
) => {
  let surchargeAbs = (surchargeAbsolute || 0) && Math.round(surchargeAbsolute! * 60);
  let surchargePerent = (surchargePercent || 0) && Math.round((duration * surchargePercent!) / 100);
  return surchargeAbs + surchargePerent;
};

class CreditRepository {
  creditsPayloadToMap = (payload: CreditsPayload): CreditsMap => {
    const creditsMap = {} as any;

    Object.entries(payload).forEach(([userDate, entries]) => {
      const keySplitted = userDate.split("_");
      const userId = keySplitted[0];
      const date = keySplitted[1];
      creditsMap[`${userId}_${date}`] = 0; // neccessary to erase previous entry

      let sum = 0;

      Object.entries(entries).forEach(([shiftId, entity]) => {
        let duration = entity.T === 0 ? 0 : entity.C || entity.T || entity.S || 0;
        let surcharge = entity.AA || entity.AP ? getSurcharge(duration, entity.AA, entity.AP) : 0;
        sum += duration + surcharge;
      });

      creditsMap[`${userId}_${date}`] = sum;
    });
    return creditsMap;
  };

  private getUpdateAction = (snapKey: string, snapVal: {}): AnyAction => {
    const userId_date = snapKey as string;
    const _payload: CreditsPayload = { [userId_date]: snapVal };
    const _creditByUserByDate = this.creditsPayloadToMap(_payload);

    return {
      type: CreditAction.SET,
      payload: _creditByUserByDate,
    };
  };

  private getCreditsRef = (tenantId: string, userId?: string): firebase.database.Query => {
    // If userId is undefined the ref includes all user-credits
    const baseRef = firebase.database().ref("tenants/" + tenantId + "/credits");
    return userId ? baseRef.orderByKey().startAt(`${userId}_2000-01-01`).endAt(`${userId}_2100-01-01`) : baseRef;
  };

  // used by mobile app
  public fetchUserCredits = (userId: string) => async (dispatch: DispFn, getState: () => AppState): Promise<any> => {
    LoopProtector.check(dispatch);
    const tenantId = getState().data.auth.session!.tenantId;
    const snap = await this.getCreditsRef(tenantId, userId).once("value");
    const creditByUserByDate = this.creditsPayloadToMap(snap.val() || ({} as CreditsPayload));

    dispatch({
      type: CreditAction.SET,
      payload: creditByUserByDate,
    });
    return creditByUserByDate;
  };

  // used by webapp
  public addListener = (userId?: string) => async (dispatch: DispFn, getState: () => AppState): Promise<any> => {
    const repoKey = "credits";
    const tenantId = getState().data.auth.session!.tenantId;
    const creditsRef = this.getCreditsRef(tenantId, userId);
    const valueSnap = (await creditsRef.once("value")).val() || ({} as CreditsPayload);

    const creditsList = Object.keys(valueSnap);
    const initialCount = creditsList.length;
    const creditByUserByDate = this.creditsPayloadToMap(valueSnap);
    const callbacks = {} as any;

    dispatch({
      type: CreditAction.SET,
      payload: creditByUserByDate,
    });

    let queue1: Function[] = [];
    let queue2: Function[] = [];

    const executeQueue = () => {
      batch(() => queue1.forEach((task) => dispatch(task())));
      batch(() => queue2.forEach((task) => dispatch(task())));
      queue1 = [];
      queue2 = [];
    };

    let currentCount = 0;
    callbacks["child_added"] = creditsRef.on("child_added", async (snap) => {
      currentCount++;
      if (currentCount > initialCount) {
        queue1.push(() => this.getUpdateAction(snap.key as string, snap.val()));
        queue2.push(() => updateCreditsOfRoster([getUserOfKey(snap.key!)]));
        setTimeout(() => executeQueue(), 200);
      }
    });
    callbacks["child_changed"] = creditsRef.on("child_changed", (snap) => {
      queue1.push(() => this.getUpdateAction(snap.key as string, snap.val()));
      queue2.push(() => updateCreditsOfRoster([getUserOfKey(snap.key!)]));
      setTimeout(() => executeQueue(), 200);
    });
    callbacks["child_removed"] = creditsRef.on("child_removed", (snap) => {
      queue1.push(() => this.getUpdateAction(snap.key as string, {}));
      queue2.push(() => updateCreditsOfRoster([getUserOfKey(snap.key!)]));
      setTimeout(() => executeQueue(), 200);
    });
    dispatch(firebaseListenerRepo.add(repoKey, creditsRef.ref, callbacks));
  };

  getReducer(): Reducer<CreditsMap> {
    return (state: CreditsMap = {}, action: any): CreditsMap => {
      switch (action.type) {
        case CreditAction.SET:
          return { ...state, ...action.payload };
        case CLEAR_DATA_ACTION_TYPE:
          return {};
        default:
          return state;
      }
    };
  }
}

export const creditRepository = new CreditRepository();
