import React, { PureComponent } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { Dispatch } from "redux";
import { Icon, Tooltip } from "antd";
import cn from "classnames";
import { AppState } from "../../../../../types/AppState";
import { hideEmptyShiftRows } from "../../../../../actions/hiddenShiftRows";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { BusyInjectorProps, busyInjector } from "../../../../../components/BusyInjector/BusyInjector";
import { RosterTypePicker } from "../RosterTypePicker/RosterTypePicker";
import { RosterType } from "../../../../../reducers/ui/shifts/roster/rosterType";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { RoleType } from "../../../../../shared/entities/IUser";

const mapStateToProps = (state: AppState) => ({
  emptyShiftRowsHidden: state.ui.shifts.roster.emptyShiftRowsHidden,
  rosterType: state.ui.shifts.roster.rosterType,
  sessionInfo: selectSessionInfo(state),
  tenantInfo: state.data.tenantInfo,
});

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class _HideShiftRowsButton extends PureComponent<Props, {}> {
  render() {
    const { dispatch, emptyShiftRowsHidden, setLoading, rosterType, isLoading, sessionInfo, tenantInfo } = this.props;
    const isRosterGridType = rosterType === RosterType.Grid;
    const isEmployee = sessionInfo.user.role === RoleType.employee;

    if (!isRosterGridType) {
      return null;
    }

    return (
      <div
        data-rh={lg.leere_zeilen_ausblenden}
        className={
          cn({ selected: emptyShiftRowsHidden && isRosterGridType, noClick: !isRosterGridType }) +
          " hideEmptyShiftRowsBtn"
        }
        onClick={(v) => {
          if (isRosterGridType) {
            setLoading("main", true);
            setTimeout(() => {
              dispatch(hideEmptyShiftRows(!emptyShiftRowsHidden));
              setLoading("main", false);
            }, 50);
          }
        }}
      >
        {isLoading() ? <Icon type="loading" /> : <Icon type="vertical-align-middle" />}
      </div>
    );
  }
}

export const HideShiftRowsButton = connect<StoreProps, { dispatch: Dispatch }, OwnProps, AppState>(mapStateToProps)(
  busyInjector(_HideShiftRowsButton)
);
