import React, { PureComponent } from "react";
import { connect } from "react-redux";
import UserCell from "./UserCell/UserCell";
import { AppState } from "../../../../types/AppState";

import moment from "moment";
import "./styles.scss";

import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { absenceDaysSumSelector } from "../../../../selectors/absenceDaysSumSelector";
import { absenceEntitlementByUserSelector } from "../../../../selectors/absenceEntitlementByUserSelector";
import { getClickedAbsenceID, getClickedUserID } from "../../helprs";

import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { MonthCell } from "./MonthCell/MonthCell";

import { selectActiveUsers } from "../../../../selectors/ActiveUserSelectors";
import _ from "lodash";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import { IUser, IUserFull } from "../../../../shared/entities/IUser";
import { toMoment, toSimpleDate } from "../../../../shared/helpers/timeHelpers";
import { selectCan } from "../../../../selectors/canSelector";
import { openModal } from "../../../../actions/modal";
import { paidFeatureWarning } from "../../../../actions/paidFeatureWarning";
import AbsenceModal from "../../../../components/modals/AbsenceModal/AbsenceModal";
import { featuresSelector } from "../../../../selectors/FeaturesSelector";
import { selectAbsenceCalendarVisibleUsers } from "./selectors/absenceCalendarVisibleUsersSelector";
import { selectVisibleAbsencesInMonth } from "./selectors/visibleAbsencesInMonthSelector";
import { makeSimpleDate } from "../../../../shared/helpers/dateHelpers";
import { Icon } from "antd";
import { IAbsence } from "../../../../shared/entities/IAbsence";
import { Hinter, updateHinting } from "../../../../actions/hinting";
import { selectAbsenceMap } from "../../../../selectors/mapSelectors";
import { YearCell } from "./YearCell/YearCell";
import { selectVisibleAbsencesInYear } from "./selectors/visibleAbsencesInYearSelector";
import { selectContingentCorrections } from "../../../../selectors/contingentCorrectionsSelector";
import { AbsenceTypeCode, IAbsenceType } from "../../../../shared/entities/IAbsenceType";

export const AbsenceUserCellWidth = 260; // width in pixel
export const AbsenceUserCellMiniWidth = 220; // width in pixel

const RowHeight = 32; // width in pixel

const mapStateToProps = (state: AppState) => {
  const month = state.ui.absences.selectedMonth;
  const year = state.ui.absences.selectedYear;
  const firstOfTheMonth = moment().year(year).month(month).date(1).format(SDateFormat);

  return {
    month,
    year,
    users: selectActiveUsers(state, firstOfTheMonth),
    absenceDaysByUser: absenceDaysSumSelector(state),
    entitlementByUser: absenceEntitlementByUserSelector(state),
    visibleUsers: selectAbsenceCalendarVisibleUsers(state),
    visibleAbsencesInMonth: selectVisibleAbsencesInMonth(state),
    visibleAbsencesInYear: selectVisibleAbsencesInYear(state),
    contingentCorrections: selectContingentCorrections(state),
    absenceFilter: state.ui.absences,
    sessionInfo: selectSessionInfo(state),
    holidays: state.data.holidays,
    filters: state.ui.filters.absenceCalendar,
    rosterSettings: state.data.rosterSettings[0],
    can: selectCan(state),
    selectedTypeId: state.ui.absences.selectedTypeId,
    absenceMap: selectAbsenceMap(state),
    features: featuresSelector(state),
    absenceTypes: state.data.absenceTypes,
    isYearView: state.ui.absences.isYearView,
  };
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class CalendarBody extends PureComponent<Props> {
  mouseOverDay?: number;
  mouseOverUserId?: string;
  markingUserId?: string;
  markStartDay?: number;
  maxEndDay?: number;
  daysInMonth?: number;
  addAbsenceHtmlNode?: HTMLElement | null;
  lastCreatedAbsenceType?: string;

  componentDidMount() {
    document.addEventListener("click", this.clickDetected);
    this.addAbsenceHtmlNode = document.getElementById("addAbsenceBox");
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.clickDetected);
  }

  clickDetected = (e: any) => {
    const { absenceMap, sessionInfo, can } = this.props;
    const absenceId = getClickedAbsenceID(e);
    const userId = getClickedUserID(e); // when a userCell gets clicked

    if (absenceId) {
      const absence = absenceMap[absenceId];
      if (absence.userId === sessionInfo.user.id || can.manageAbsences) {
        this.props.features.absences
          ? this.props.dispatch(openModal(AbsenceModal, { absence, userId: absence.userId }))
          : this.props.dispatch(paidFeatureWarning());
      }
    }

    if (!absenceId && userId && can.manageAbsences) {
      this.props.features.absences
        ? this.props.dispatch(openModal(AbsenceModal, { userId }))
        : this.props.dispatch(paidFeatureWarning());
    }
  };

  onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const x = e.clientX - e.currentTarget!.getBoundingClientRect().left;
    const y = e.clientY + e.currentTarget!.scrollTop - e.currentTarget.getBoundingClientRect().top;
    const calendarWidth = e.currentTarget!.clientWidth - AbsenceUserCellWidth;
    const widthPerDay = calendarWidth / this.daysInMonth!;
    const isMouseOverCalendar = x > AbsenceUserCellWidth;
    const userIndex = Math.floor(y / RowHeight);
    const node = this.addAbsenceHtmlNode!;

    if (x > e.currentTarget!.clientWidth - 4) {
      // this check is implemented because the scroll-bar of browsers is causing issues when clicked > opens absenceModal
      this.onMouseLeave();
      return;
    }

    if (!isMouseOverCalendar) {
      this.clearMouseOverData();
      return;
    }

    const userId = this.markingUserId || this.props.visibleUsers[userIndex]?.id;
    let day = Math.floor((x - AbsenceUserCellWidth) / widthPerDay) + 1;
    // dont let day get dragged into a absence-entry
    this.markStartDay && this.maxEndDay && this.maxEndDay < day && (day = this.maxEndDay);
    // dont let day to go to past
    this.markStartDay && day < this.markStartDay && (day = this.markStartDay);

    if (userId && day && (userId !== this.mouseOverUserId || day !== this.mouseOverDay)) {
      const isLeftOfStart = this.markStartDay && day < this.markStartDay;
      const dayHasAbsence = this.dayHasAbsence(userId, day);

      const prevEl = document.getElementsByClassName("absence-day-glowing")[0];
      // @ts-ignore
      prevEl?.classList.remove("absence-day-glowing");
      const nextEl = document.getElementById("absence-day-cell-" + day);
      // @ts-ignore
      nextEl?.classList.add("absence-day-glowing");

      if (dayHasAbsence && !this.markingUserId) {
        this.clearMouseOverData(false);
        return;
      }

      this.mouseOverUserId = userId;
      this.mouseOverDay = day;

      const startDay = isLeftOfStart ? this.markStartDay! : this.markStartDay || this.mouseOverDay!;
      const daysCount = this.markStartDay ? day - this.markStartDay + 1 : 1;

      node!.style.display = "flex";
      node!.style.top = this.props.visibleUsers.map((u) => u.id).indexOf(this.mouseOverUserId) * RowHeight + "px";
      node!.style.left = (startDay - 1) * (100 / this.daysInMonth!) + "%";
      node!.style.width = daysCount * (100 / this.daysInMonth!) + "%";
    }
  };

  clearMouseOverData = (removeDayGlow = true) => {
    this.addAbsenceHtmlNode && (this.addAbsenceHtmlNode.style.display = "none");
    this.markingUserId = undefined;
    this.markStartDay = undefined;
    this.maxEndDay = undefined;
    this.mouseOverDay = undefined;
    this.mouseOverUserId = undefined;
    // remove highlighted head-cell
    if (removeDayGlow) {
      const prevEl = document.getElementsByClassName("absence-day-glowing")[0];
      // @ts-ignore
      prevEl?.classList.remove("absence-day-glowing"); // @ts-ignore
    }
  };

  onMouseLeave = () => {
    // if mouse leaves, while dragging a shift until the endOfMonth > we open the absencePopup
    this.mouseOverDay && this.mouseOverDay === this.daysInMonth! && this.onMouseUp(true);
    // otherwise just clearData
    this.clearMouseOverData();
  };

  onMouseDown = () => {
    if (this.mouseOverUserId && this.mouseOverDay) {
      const mouseOverDate = makeSimpleDate(this.props.year, this.props.month + 1, this.mouseOverDay);
      const userAbsences = this.props.visibleAbsencesInMonth.filter((a) => a.userId === this.mouseOverUserId);
      const userAbsencesPost = userAbsences.filter((a) => a.startDate > mouseOverDate);
      const userAbsencesSorted = _.sortBy(userAbsencesPost, (a) => a.startDate);
      const nextAbsence = userAbsencesSorted[0];
      nextAbsence && (this.maxEndDay = toMoment(nextAbsence.startDate).date() - 1);

      this.markingUserId = this.mouseOverUserId;
      this.markStartDay = this.mouseOverDay;
    }
  };

  dayHasAbsence = (userId: string, day: number): boolean => {
    const date = makeSimpleDate(this.props.year, this.props.month + 1, day);
    const userAbsences = this.props.visibleAbsencesInMonth.filter((a) => a.userId === userId);
    return userAbsences.some((a) => date >= a.startDate && date <= a.endDate);
  };

  onMouseUp = (noEndDate?: boolean) => {
    if (this.markingUserId) {
      const { year, month } = this.props;
      const dayExceedsMonth = this.mouseOverDay! > this.daysInMonth!; // edge case that happens in firefox -> when mouse leaves the calendar to the right edge, while marking days
      const userId = this.markingUserId;
      const _month = month + 1; // momentJs works with months from 0 - 11
      const startDate = makeSimpleDate(year, _month, this.markStartDay!);
      const endDate = dayExceedsMonth || noEndDate ? undefined : makeSimpleDate(year, _month, this.mouseOverDay!);
      this.openAbsenceModal(userId, startDate, endDate);
    }
    this.clearMouseOverData();
  };

  openAbsenceModal = (userId: string, startDate: string, endDate?: string) => {
    const { dispatch, features } = this.props;
    const typeId = this.lastCreatedAbsenceType;

    const onUpdateComplete = (absence?: IAbsence) => {
      this.lastCreatedAbsenceType = absence?.typeId;
      dispatch(Hinter.createdAbsence(startDate, endDate));
    };

    features.absences
      ? dispatch(openModal(AbsenceModal, { userId, startDate, endDate, typeId, onUpdateComplete }))
      : dispatch(paidFeatureWarning());
  };

  render() {
    const {
      month,
      year,
      absenceDaysByUser,
      entitlementByUser,
      absenceFilter,
      visibleUsers,
      visibleAbsencesInMonth,
      visibleAbsencesInYear,
      can,
      isYearView,
      absenceTypes,
      selectedTypeId,
    } = this.props;

    const mom = moment().year(absenceFilter.selectedYear).month(absenceFilter.selectedMonth);
    this.daysInMonth = mom.daysInMonth();
    const monthEnd = toSimpleDate(moment().year(year).month(month).endOf("month"));
    const monthStart = toSimpleDate(moment().year(year).month(month).startOf("month"));

    const selectedAbsenceType = absenceTypes.find((a) => {
      return selectedTypeId ? a.id === selectedTypeId : a.code === AbsenceTypeCode.vacation;
    }) as IAbsenceType;

    const isMonthView = !isYearView;

    const correctionByUser = {};
    this.props.contingentCorrections
      .filter((c) => !c.isOverride && c.year === year)
      .forEach((c) => {
        correctionByUser[c.userId] = c.days;
      });

    const yearStartDate = moment().year(year).startOf("year").format(SDateFormat);
    const yearEndDate = moment().year(year).endOf("year").format(SDateFormat);

    return (
      <div
        className="absenceCalendarBodyMain"
        onMouseMove={(e) => can.manageAbsences && isMonthView && this.onMouseMove(e)}
        onMouseDown={(e) => isMonthView && this.onMouseDown()}
        onMouseUp={() => isMonthView && this.onMouseUp()}
        onMouseLeave={(e) => isMonthView && this.onMouseLeave()}
      >
        {visibleUsers.map((user, i) => {
          const correction = correctionByUser[user.id] || 0;
          return (
            <div className="absenceRowMain" key={user.id} style={{ height: RowHeight }}>
              <UserCell
                key={user.id}
                user={user}
                userName={user.name}
                year={year}
                absenceSum={absenceDaysByUser[user.id] + correction}
                canManageAbsences={this.props.can.manageAbsences}
                currentUser={this.props.sessionInfo.user}
                entitlement={entitlementByUser[user.id]}
                selectedAbsenceType={selectedAbsenceType}
                width={isYearView ? AbsenceUserCellMiniWidth : AbsenceUserCellWidth}
              />
              {this.props.isYearView ? (
                <YearCell
                  absences={visibleAbsencesInYear.filter((a) => a.userId === user.id)}
                  yearStartDate={yearStartDate}
                  yearEndDate={yearEndDate}
                  canManageAbsences={can.manageAbsences}
                />
              ) : (
                <MonthCell
                  key={user.id + "-MonthCell"}
                  userId={user.id}
                  absences={visibleAbsencesInMonth.filter((a) => a.userId === user.id)}
                  monthStart={monthStart}
                  monthEnd={monthEnd}
                  month={month}
                  year={year}
                  holidays={this.props.holidays}
                  canManageAbsences={this.props.can.manageAbsences}
                  daysInMonth={this.daysInMonth!}
                />
              )}
            </div>
          );
        })}
        <div className="addBoxWrapper">
          <div className="addBoxUserFrame" style={{ width: AbsenceUserCellWidth }}></div>
          <div className="addBoxMonthFrame">
            <div className="addAbsenceBox" id="addAbsenceBox" style={{ height: RowHeight }}>
              <Icon type="double-right" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(CalendarBody);
