import { UserInfo } from "./../shared/helpers/UserInfo";
import { AppState } from "../types/AppState";
import { createSelector } from "reselect";
import { selectSessionInfo } from "./SessionInfoSelector";
import _ from "lodash";
import { IUser } from "../shared/entities/IUser";
import { selectActiveUsers } from "./ActiveUserSelectors";
import moment from "moment";
import { SDateFormat } from "../shared/helpers/SimpleTime";

export const usersHaveIntersectingBranch = (user1: IUser, user2: IUser) =>
  _.intersection(user1.branchIds, user2.branchIds).length;

export const selectUsersWithSharedBranch = createSelector(
  [selectSessionInfo, (state: AppState) => state.data.users],
  (sessionInfo: UserInfo, users: IUser[]): string[] =>
    users.filter((u) => usersHaveIntersectingBranch(u, sessionInfo.user)).map((u) => u.id)
);

export const selectActiveUsersWithSharedBranch = createSelector(
  selectSessionInfo,
  (s) => selectActiveUsers(s, moment().format(SDateFormat)),
  (sessionInfo, users: IUser[]): string[] => {
    const currentUser = sessionInfo.user;

    return users.filter((u) => !!_.intersection(u.branchIds, currentUser.branchIds).length).map((u) => u.id);
  }
);
