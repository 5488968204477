import moment from "moment";

export type CreditPageSelection = {
  userId: string;
  isMonthly: boolean;
  week: number;
  month: number;
  year: number;
  showHourAccountInfo?: boolean;
};

export const UPDATE_CREDITS_VIEW_SELECTION = "UPDATE_CREDITS_VIEW_SELECTION";

export const updateCreditsUISelection = (selection: Partial<CreditPageSelection>) => ({
  type: UPDATE_CREDITS_VIEW_SELECTION,
  payload: selection,
});

const initialState: CreditPageSelection = {
  userId: "u1",
  isMonthly: false,
  week: moment().isoWeek(),
  month: moment().month(),
  year: moment().isoWeekYear(),
};

export const creditsUIReducer = (state = initialState, a: any): CreditPageSelection =>
  a.type === UPDATE_CREDITS_VIEW_SELECTION ? { ...state, ...a.payload } : state;
