import React from "react";
import { Radio, Icon } from "antd";
import "./styles.scss";
import { RadioChangeEvent, RadioGroupProps } from "antd/lib/radio";
import _ from "lodash";

type IOption = {
  value: any;
  label: string;
  content?: React.ReactNode;
  disabled?: boolean;
  icon?: string;
  style?: React.CSSProperties;
};

interface Props extends RadioGroupProps {
  options: IOption[];
  disabled?: boolean;
  asRadio?: boolean;
  onChange: (value: any) => void;
  value: any;
  buttonStyle?: "solid" | "outline";
}

export default class AvOption extends React.PureComponent<Props, {}> {
  render() {
    const { options, onChange, value, asRadio, buttonStyle } = this.props;

    const RadioButtonComponent = asRadio ? Radio : Radio.Button;

    return (
      <div className="AvOptionMain">
        <Radio.Group
          buttonStyle={buttonStyle || "outline"}
          onChange={(e: RadioChangeEvent) => onChange(e.target.value)}
          {..._.omit(this.props, ["onChange", "options"])}
        >
          {options.map((o: IOption) => (
            <RadioButtonComponent
              key={o.value + ""}
              value={o.value}
              checked={value === o.value}
              disabled={o.disabled}
              style={o.style}
            >
              {o.icon && <Icon type={o.icon} className="icon" style={{ marginRight: o.label ? 6 : 0 }} />}
              {o.label}
            </RadioButtonComponent>
          ))}
        </Radio.Group>
      </div>
    );
  }
}
