import React, { useState, useEffect } from "react";
import { Icon, Input } from "antd";
import { useDispatch } from "react-redux";
import { setRosterUserNameFilter } from "../../../../../reducers/ui/shifts/roster/userNameFilter";
import _ from "lodash";
import "./styles.scss";
import { useSelector } from "../../../../../helpers/redux";
import cn from "classnames";

type Props = {};

export const UserFilterInput = (props: Props) => {
  const dispatch = useDispatch();
  const searchTerm = useSelector((s) => s.ui.shifts.roster.userNameFilter);
  const [searchValue, updateSearchValue] = useState("");

  useEffect(() => {
    updateSearchValue(searchTerm);
  }, []);

  const updateUserSearchTerm = (searchTerm: string) => {
    updateSearchValue(searchTerm);
    _.debounce(() => {
      dispatch(setRosterUserNameFilter(searchTerm));
    }, 200)();
  };

  return (
    <Input
      className={cn({ userFilterInputFieldMain: true, hasValue: !!searchValue })}
      value={searchValue}
      placeholder={lg.mitarbeiter}
      autoFocus
      type="text"
      onChange={(e) => updateUserSearchTerm(e.target.value)}
      suffix={<Icon type="search" />}
      style={{ height: "100%" }}
    />
  );
};
