import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import moment from "moment";
import { selectSessionInfo } from "./SessionInfoSelector";

import { selectShiftMap } from "./shiftMapSelector";
import { toSimpleDate } from "../shared/helpers/timeHelpers";

export const selectChangeRequests = createSelector(
  [(state: AppState) => state.data.changeRequests, selectShiftMap, (state: AppState) => selectSessionInfo(state)],
  (changeRequests, shiftMap, sessionInfo) => {
    const today = toSimpleDate(moment());
    const canManage = sessionInfo.hasManagerPermissions();
    const isAdmin = sessionInfo.isAdmin();

    return changeRequests
      .filter((changeReq) => changeReq.date >= today)
      .filter((c) => shiftMap[c.id]) // to make shure shift data is loaded to avoid null-pointers
      .filter((changeReq) =>
        canManage
          ? isAdmin || sessionInfo.user.branchIds.includes(changeReq.branchId)
          : changeReq.userId === sessionInfo.user.id
      );
  }
);
