import { Button, Dropdown, Icon, Menu, Popover, Input } from "antd";
import React, { useEffect, useState } from "react";

type Props = {
  disabled?: boolean;
  comment: string;
  onSubmit: (c: string) => any;
};

export const CommentEditButtonTimeClock = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newComment, setNewComment] = useState(props.comment);
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Popover
      trigger={"click"}
      visible={isOpen}
      placement={"bottom"}
      onVisibleChange={(v) => {
        if (!v) {
          setIsOpen(false);
        }
        setNewComment(props.comment);
      }}
      content={
        <div className="fb" style={{ padding: 12 }}>
          <Input.TextArea
            value={newComment}
            style={{ width: 350, height: 150 }}
            autoFocus
            placeholder={lg.notiz_hinzufügen}
            onChange={(e) => setNewComment(e.target.value)}
            // onKeyDown={(e) => e.key === "Enter" && props.onSubmit(newComment)}
          />
          <Button
            id="save-comment-in-clocking"
            style={{ marginTop: 9 }}
            type="primary"
            onClick={() => {
              props.onSubmit(newComment);
              setIsOpen(false);
            }}
            children={lg.Speichern}
          />
        </div>
      }
    >
      <div
        className="fb row aCenter"
        style={{ fontSize: 18, cursor: "pointer" }}
        onClick={() => {
          if (!props.disabled) {
            setIsOpen(!isOpen);
          }
        }}
      >
        <Icon style={{ color: "white" }} type="edit" />
        <div style={{ color: "white", marginLeft: 10 }}>Notiz</div>
      </div>
    </Popover>
  );
};
