import { Icon } from "antd";
import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { ITimeClocking } from "../../../shared/entities/ITimeClocking";
import { TimeClockDetailStyles as S } from "./styles";

type Props = {
  timeClocking: ITimeClocking | undefined;
};

export const TimeClockLogBox = ({ timeClocking }: Props) => {
  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const isMounted = useRef(false);

  const updateTimerLoop = () => {
    setTimeout(() => {
      forceUpdate();
      if (isMounted.current) {
        updateTimerLoop();
      }
    }, 1000);
  };

  useEffect(() => {
    isMounted.current = true;

    updateTimerLoop();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const renderRow = useCallback((iconName: string, timeString: string, text: string, index: any) => {
    return (
      <div className="fb row aCenter" style={S.row} key={index}>
        <Icon type={iconName} style={{ marginBottom: 2 }} />
        <div style={S.text}>
          {timeString} - {text}
        </div>
      </div>
    );
  }, []);

  if (!timeClocking) {
    return null;
  }

  if (!timeClocking.breakActivities) {
    return null;
  }

  return (
    <div className="fb column detailsBox" style={S.box}>
      {/* {renderRow("caret-right", timeClocking.startTime, lg.eingestempelt, -1)} */}
      {timeClocking.breakActivities?.map((bA, index) => {
        if (bA.type === "start") {
          const breakEnd = timeClocking.breakActivities?.[index + 1];
          const timeStr = breakEnd ? `${bA.time} - ${breakEnd.time}` : bA.time;
          return renderRow("pause-circle", timeStr, breakEnd ? lg.pause : lg.pause_begonnen, index);
        } else {
          return null;
        }
      })}
      {!!timeClocking.endTime && renderRow("check-circle", timeClocking.endTime, lg.ausgestempelt, 100)}
    </div>
  );
};
