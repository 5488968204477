import { IHoliday } from "./../shared/entities/IHoliday";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import _ from "lodash";

/**
 * Creates a cached finder that retries all holidays valid for a date and a branch.
 *
 * If no branch is passed, all holidays not associated with a branch are returned since
 * they count for all branches.
 */
export const selectHolidayFinder: (
  state: AppState
) => (date: string, branchId?: string) => IHoliday | undefined = createSelector(
  [(state: AppState) => state.data.holidays],
  (holidays) => {
    const mapper = _(holidays)
      // note: if branchId does not exist key will be grouped via string "undefined"
      .groupBy((h) => h.branchId)
      .mapValues((hGroup) =>
        _(hGroup)
          .groupBy((h) => h.date)
          .value()
      )
      .value();

    return (date: string, branchId?: string) => {
      // note: holidays without branch are grouped via string "undefined"
      const generalHolidays = mapper[undefined + ""]?.[date] || [];
      const branchHolidays = branchId ? mapper[branchId]?.[date] || [] : [];

      return branchId ? [...generalHolidays, ...branchHolidays][0] : generalHolidays[0];
    };
  }
);
