import React from "react";
import { Icon, Button } from "antd";
import { useDispatch } from "react-redux";
import "./styles.scss";
import { openModal } from "../../../../../actions/modal";
import UserDetailsModal from "../../../../Users/UserDetailsModal/UserDetailsModal";
import { useSelector } from "../../../../../helpers/redux";

import cn from "classnames";

export const AddUserRow = () => {
  const dispatch = useDispatch();
  const users = useSelector((s) => s.data.users);
  const shouldBeAnimated = users.length === 1;
  const selectedBranchId = useSelector((s) => s.ui.selectedBranch);
  return (
    <div className="addUserInRosterMain">
      <div
        className={cn({
          button: true,
          shouldBeAnimated,
        })}
        onClick={() => {
          dispatch(
            openModal(
              UserDetailsModal,
              {
                userId: undefined,
                branchId: selectedBranchId,
              },
              { maskClosable: false }
            )
          );
        }}
      >
        <div className="text">{lg.mitarbeiter_hinzufügen}</div>
        <div className="icon">
          <Icon type="plus" />
        </div>
      </div>
    </div>
  );
};
