import { Button, Icon } from "antd";
import React from "react";
import "./styles.scss";

type Props = {
  onConfirm?: () => void;
  title?: string;
  children: any;
};

export const HintModal = (props: Props) => {
  return (
    <div className="hintModalMain">
      <div className="frame">
        <div className="header">
          {/* <Icon type="bulb" /> */}
          {/* <Icon type="meh" theme="twoTone" /> */}
          <Icon type="bulb" />
          {/* <div className="tip"> Tip</div> */}
          <div className="title">{props.title}</div>
        </div>
        <div className="content">{props.children}</div>
      </div>
      <div className="footer">
        <Button id="hint-understood-btn" type="primary" onClick={props.onConfirm} children={lg.verstanden} />
      </div>
    </div>
  );
};
