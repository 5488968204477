import { IResource, Raw } from "./IResource";

export enum WorkInterval {
  weekly = "weekly",
  monthly = "monthly",
}

export type IContract = IResource & {
  userId: string;
  dailyQuota: DailyQuota;
  validFrom: string;
  validTo: string;
  totalHours: number;
  applyQuotaOnHolidays?: boolean;
  interval: WorkInterval;
  intervalStartDay?: number; // used if the monthly interval is not the calendarmonth.
  canNotExceedTotalHours?: boolean;
  vacationDayCredit?: number;
  illnessDayCredit?: number;
  mainBranchId?: string;
  monthlyMaxHours?: number;
  userTypeId?: string;
  hourlyWage?: number;
};

export type IContractRaw = Raw<IContract>;

export type IContractCore = Omit<IContractRaw, "userId">;

export type DailyQuota = {
  mo: number | null;
  tu: number | null;
  we: number | null;
  th: number | null;
  fr: number | null;
  sa: number | null;
  su: number | null;
};

export type WeekdayKey = keyof DailyQuota;

// Because Tade is a dirty programmer
export type { WeekDay } from "../../shared/constants/WeekDays";
export { getWeekDayLabels, WeekDays } from "../../shared/constants/WeekDays";
