import React from "react";
import "./styles.scss";

import { Icon } from "antd";
import { ITracking } from "../../../shared/entities/ITracking";

type Props = {
  tracking: ITracking;
};

export class ShiftTrackingIcon extends React.PureComponent<Props> {
  render() {
    return (
      <div
        data-rh={this.props.tracking.isAccepted ? lg.bestätigte_zeiterfassung : lg.unbestätigte_zeiterfassung}
        className="shiftIcon counterBatch trackingIconMain"
        style={
          this.props.tracking.isAccepted
            ? { color: "#1a90ff", fontSize: 14, background: "white" }
            : { background: "#ff9213" }
        }
      >
        <Icon type="history" />
      </div>
    );
  }
}
