import { Dropdown, Menu } from "antd";
import React from "react";

export type DropDownSelectorOption = { label: string; key: string };
type Props = {
  disabled?: boolean;
  data: DropDownSelectorOption[];
  onChange: (o: DropDownSelectorOption) => void;
  children: React.ReactNode;
};

export const DropDownSelector = (props: Props) => {
  const menu = (
    <Menu>
      {props.data.map((d) => (
        <Menu.Item key={d.key} onClick={() => props.onChange(d)}>
          {d.label}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Dropdown disabled={props.disabled} overlay={menu}>
      {props.children}
    </Dropdown>
  );
};
