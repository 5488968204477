import { addSimpleDays } from "./../shared/helpers/dateHelpers";
import { AppState } from "./../types/AppState";
import { DispFn } from "../frontend-core/types/thunkTypes";
import { publishedWeekRepository } from "../repositories/publishedWeekRepository";
import { IPublishedWeek } from "../shared/entities/IPublishedWeek";
import { Raw } from "../shared/entities/IResource";

export const publishWeekPlan =
  (branchId?: string, week?: string) =>
  (dispatch: DispFn, getState: () => AppState): Promise<any> => {
    const branchIds = branchId ? [branchId] : [getState().ui.selectedBranch];
    return dispatch(publishUnpublishWeekPlans(true, branchIds, week));
  };

export const unpublishWeekPlan =
  (branchId?: string, week?: string) =>
  (dispatch: DispFn, getState: () => AppState): Promise<any> => {
    const branchIds = branchId ? [branchId] : [getState().ui.selectedBranch];
    return dispatch(publishUnpublishWeekPlans(false, branchIds, week));
  };

export const publishWeekPlans =
  (branchIds: string[], week?: string) =>
  (dispatch: DispFn, getState: () => AppState): Promise<any> => {
    return dispatch(publishUnpublishWeekPlans(true, branchIds, week));
  };

export const unpublishWeekPlans =
  (branchIds: string[], week?: string) =>
  (dispatch: DispFn, getState: () => AppState): Promise<any> => {
    return dispatch(publishUnpublishWeekPlans(false, branchIds, week));
  };

const publishUnpublishWeekPlans =
  (published: boolean, branchIds: string[], week?: string) =>
  async (dispatch: DispFn, getState: () => AppState): Promise<any> => {
    const updates: IPublishedWeek[] = [];
    const creates: Raw<IPublishedWeek>[] = [];

    branchIds.map((branchId) => {
      const date = week || getState().ui.shifts.roster.selectedWeek;
      const publishedWeekPlans = getState().data.publishedWeeks;
      const weekPlan = publishedWeekPlans.find((wp) => wp.branchId === branchId && wp.startDate === date);

      weekPlan
        ? updates.push({ ...weekPlan, published })
        : creates.push({
            published,
            startDate: date,
            endDate: addSimpleDays(date, 6),
            branchId: branchId!,
          });
    });

    updates.length && dispatch(publishedWeekRepository.updateList(updates));
    creates.length && dispatch(publishedWeekRepository.createList(creates));
  };
