import { IContract } from "./../shared/entities/IContract";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";

export type ContractsMap = { [userId: string]: IContract[] };
export const selectContractsByUser = createSelector([(state: AppState) => state.data.contracts], (contracts): {
  [userId: string]: IContract[];
} => {
  const map = {};
  contracts.forEach((c) => {
    if (!map[c.userId]) {
      map[c.userId] = [];
    }
    map[c.userId].push(c);
  });
  return map;
});
