import React from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import cn from "classnames";
import "./styles.scss";
import { AppState } from "../../../../../types/AppState";
import { RosterMode, setRosterMode } from "../../../../../reducers/ui/shifts/roster/rosterMode";
import { RosterType, setRosterType } from "../../../../../reducers/ui/shifts/roster/rosterType";
import { paidFeatureWarning } from "../../../../../actions/paidFeatureWarning";

type Props = {
  rosterMode: RosterMode;
  rosterType: RosterType;
  dataRh: string;
  rosterIcon: any;
  featureBlocked?: boolean;
};

export const RosterViewOption = (props: Props) => {
  const dispatch = useDispatch();
  const rosterMode = useSelector((s: AppState) => s.ui.shifts.roster.rosterMode);
  const rosterType = useSelector((s: AppState) => s.ui.shifts.roster.rosterType);

  const handleRosterViewChange = (rosterMode, rosterType) => {
    batch(() => {
      dispatch(setRosterMode(rosterMode));
      dispatch(setRosterType(rosterType));
    });
  };

  return (
    <div
      className={cn({
        rosterViewOption: true,
        isActive: rosterMode === props.rosterMode && rosterType === props.rosterType,
      })}
      data-rh={props.dataRh}
      onClick={() => {
        if (props.featureBlocked) {
          dispatch(paidFeatureWarning());
          return;
        }

        handleRosterViewChange(props.rosterMode, props.rosterType);
      }}
    >
      {props.rosterIcon}
    </div>
  );
};
