import { toSimpleDate } from "../../../../shared/helpers/timeHelpers";
import moment from "moment";

export const availabilitiesUIActionTypes = {
  UPDATE: "@@AV/AVAILABILITIES_UPDATE",
};

export type AvailabiliteisUIFilter = {
  startDate?: string;
  endDate?: string;
  selectedUserId?: string;
  filterJobPositionId?: string;
  filterBranchId?: string;
  onlyAvailable?: boolean;
  onlyUnavailable?: boolean;
  hideEmptyUsers?: boolean;
  showAllFilters?: boolean;
};

const initialState: AvailabiliteisUIFilter = {
  startDate: toSimpleDate(moment()),
  endDate: undefined,
  selectedUserId: undefined,
  filterJobPositionId: undefined,
  filterBranchId: undefined,
  onlyAvailable: undefined,
  onlyUnavailable: undefined,
  hideEmptyUsers: undefined,
  showAllFilters: undefined,
};

export const availabilitiesUIReducer = (state = initialState, a: any): AvailabiliteisUIFilter =>
  a.type === availabilitiesUIActionTypes.UPDATE ? { ...state, ...a.payload } : state;
