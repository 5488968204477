import {
  ICreditCorrection,
  CreditCorrectionType,
} from "../shared/entities/ICreditCorrection";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";

// only manual credit corretions!
export const selectCreditCorrectionsByUserByDate = createSelector(
  [(state: AppState) => state.data.creditCorrections],
  (creditCorrections): { [userId: string]: { [date: string]: ICreditCorrection } } => {
    const map = {};
    creditCorrections.forEach((c) => {
      if (c.type === CreditCorrectionType.manual) {
        if (!map[c.userId]) {
          map[c.userId] = {};
        }
        map[c.userId][c.date] = c;
      }
    });
    return map;
  }
);
