import React from "react";
import { connect } from "react-redux";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";
import { AppState } from "../../types/AppState";
import { Button } from "antd";
import { SetupWizardHead } from "./SetupWizardHead";
import _ from "lodash";
import { CountrySelector, ICountrySelectorFormValues } from "../../components/CountrySeclector/CountrySelector";
import { isV2Domain } from "../../frontend-core/helpers/frontendHelpers";

const mapStateToProps = (state: AppState) => ({});

type State = {
  isValid: boolean;
};

type OwnProps = {
  formValues?: ICountrySelectorFormValues;
  setRegionStepResult: (result: ICountrySelectorFormValues) => void;
  changeStep: (n: number) => void;
  isV2: boolean;
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class RegionStep extends React.PureComponent<Props, State> {
  isV2: boolean;
  constructor(props: Props) {
    super(props);

    this.state = {
      isValid: false,
    };

    this.isV2 = isV2Domain();
  }

  nextStepClicked = () => {
    const skipJobPosStep = this.props.isV2;
    this.props.setRegionStepResult(this.props.formValues!);
    this.props.changeStep(skipJobPosStep ? 3 : 2);
  };

  render() {
    return (
      <>
        <div className="content regionStep">
          <SetupWizardHead />
          <div className="stepTitle">{lg.land_auswählen}</div>
          <div className="wizMiniDesc">{lg.notwendig_für_die_bestimmung_der_feiertage}</div>
          <CountrySelector
            formValues={this.props.formValues}
            onChange={(countryAndState, isComplete) => {
              this.props.setRegionStepResult(countryAndState);
              this.setState({ isValid: isComplete });
            }}
          />
        </div>
        <div className="footer fb jBetween row">
          <Button
            id="registration-next-step"
            onClick={this.nextStepClicked}
            type="primary"
            children={lg.weiter}
            size="large"
            disabled={!this.state.isValid}
          />
        </div>
      </>
    );
  }
}

export default connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(RegionStep);
