import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useSelector } from "../../../../../helpers/redux";
import { useDispatch } from "react-redux";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";
import { RosterMonthGridRow } from "./RosterMonthGridRow";
import { rosterElementClicked } from "../../../../../actions/rosterUi";
import { RosterJobPositionLine } from "../../components/RosterJobPositionLine/RosterJobPositionLine";
import cn from "classnames";
import {
  rosterRowDataSelector,
  rosterRowDataSelectorEqualityCheck,
} from "../../../../../selectors/rosterRowDataSelector";
import memoizeOne from "memoize-one";
import { VariableSizeList as List, ListChildComponentProps, areEqual } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { SimpleList } from "../../../../../components/SimpleList/SimpleList";
import _ from "lodash";
import moment from "moment";
import { SDateFormat } from "../../../../../shared/helpers/SimpleTime";
import { selectGroupByJobPosition } from "../../../../../selectors/groupByJobPositionSelector";

type Props = {
  scrollListener: (obj: { scrollOffset: number }) => void;
  isRosterLoading?: boolean;
};

const createData = memoizeOne((isRosterLoading, rows) => ({
  isRosterLoading,
  rows,
}));

const generateRow = React.memo(({ index, style, data }: ListChildComponentProps) => {
  // Index of row within collection // Style object to be applied to row (to position it)
  const { rows, isRosterLoading } = data;
  const today = moment().format(SDateFormat);
  const { jobPositionId, user, isJobPositionHeader, isJobPositionHeaderOpen, isRequirement } = rows[index];
  if (isJobPositionHeader) {
    return <RosterJobPositionLine style={style} jobPositionId={jobPositionId!} isOpen={isJobPositionHeaderOpen!} />;
  }

  if (isRosterLoading) {
    return <div style={{ height: 47 }} className="fb row"></div>;
  }

  return (
    <RosterMonthGridRow
      userId={user?.id}
      jobPositionId={jobPositionId}
      isRosterLoading={isRosterLoading}
      isRequirement={isRequirement}
      style={style}
      today={today}
    />
  );
}, areEqual);

export const RosterMonthGridBody = React.memo((props: Props) => {
  const RVListRef = useRef(null) as any;

  const dispatch = useDispatch<DispFn>();
  const groupByJobPosition = useSelector(selectGroupByJobPosition);
  const rows = useSelector(rosterRowDataSelector, rosterRowDataSelectorEqualityCheck);
  const printMode = useSelector((s) => s.ui.printMode);

  const clickDetected = useCallback((e: any) => {
    dispatch(rosterElementClicked(e));
  }, []);

  useEffect(() => {
    document.addEventListener("click", clickDetected);
    return () => {
      document.removeEventListener("click", clickDetected);
    };
  }, []);

  useEffect(() => {
    RVListRef?.current?.resetAfterIndex(0);
  }, [rows]);

  const maxHeight = useMemo(() => _.sumBy(rows, (r) => r.height), [rows]);

  // @See: https://react-window.now.sh/#/examples/list/memoized-list-items
  const data = createData(props.isRosterLoading, rows);

  const needToVirtualize = rows.length > 26;

  return (
    <div
      className="fb column"
      style={{ flex: "1 1 auto", maxHeight: maxHeight, overflowY: !needToVirtualize ? "hidden" : undefined }}
    >
      {!needToVirtualize ? (
        <SimpleList
          className={cn({ userGridContent: true, isGrouped: groupByJobPosition })}
          onScroll={props.scrollListener}
          itemData={data}
          generateRow={generateRow}
          itemKey={(index, data) =>
            `${data.rows[index].jobPositionId}` + `${data.rows[index].user?.id}` + `${data.rows[index].isRequirement}`
          }
        />
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <List
              className={cn({ userGridContent: true, isGrouped: groupByJobPosition })}
              ref={RVListRef}
              onScroll={props.scrollListener}
              height={Math.min(maxHeight, height)}
              itemData={data}
              itemCount={rows.length}
              itemSize={(index: number) => rows[index].height}
              width={width}
              children={generateRow}
              itemKey={(index, data) =>
                `${data.rows[index].jobPositionId}` +
                `${data.rows[index].user?.id}` +
                `${data.rows[index].isRequirement}`
              }
            />
          )}
        </AutoSizer>
      )}
    </div>
  );
});
