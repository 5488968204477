import React from "react";
import { useSelector } from "../../../../helpers/redux";
import { useDispatch } from "react-redux";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import throttle from "lodash/throttle";
import { RosterMonthGridBody } from "./RosterMonthGridBody/RosterMonthGridBody";
import { setExpandedJobPositions } from "../../../../actions/collapsedJobPositions";
import moment from "moment";
import BusyWrapper from "../../../../components/BusyWrapper/BusyWrapper";
import { busyInjector } from "../../../../components/BusyInjector/BusyInjector";
import { RosterMonthGridHead } from "./RosterMonthGridHead/RosterMonthGridHead";
import "./styles.scss";
import { handleScrolledDownClassIfNeeded } from "../../../../helpers/general";
import { isMouseOverGridUserCells } from "../helpers";
import { mouseEnteredUserCells, mouseLeftUserCells } from "../../../../reducers/ui/shifts/roster/isMouseOverUserCells";
import { EmptyRowsAreHiddenRow } from "../RosterWeekGrid/EmptyRowsAreHiddenRow/EmptyRowsAreHiddenRow";

export const RosterMonthGrid = (props: { isRosterLoading?: boolean }) => {
  const isMouseOverUserCells = useSelector((s) => s.ui.shifts.roster.isMouseOverUserCells);
  const hideEmptyRows = useSelector((s) => s.ui.shifts.roster.emptyShiftRowsHidden);
  const dispatch = useDispatch();

  const scrollListener = ({ scrollOffset }) => {
    handleScrolledDownClassIfNeeded(scrollOffset !== 0, "userGridWrapper");
  };

  return (
    <div className="userGridWrapper">
      <div
        className="weeklyRoster monthlyRoster usersGrid"
        onMouseMove={(e) => {
          const isInside = isMouseOverGridUserCells(e);
          isInside && !isMouseOverUserCells && dispatch(mouseEnteredUserCells());
          !isInside && isMouseOverUserCells && dispatch(mouseLeftUserCells());
        }}
        onMouseLeave={() => dispatch(mouseLeftUserCells())}
      >
        <RosterMonthGridHead />
        <RosterMonthGridBody scrollListener={scrollListener} isRosterLoading={props.isRosterLoading} />
        {hideEmptyRows && <EmptyRowsAreHiddenRow />}
      </div>
    </div>
  );
};
