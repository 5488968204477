import { Button, Icon, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../actions/modal";
import "./styles.scss";
import { busyInjector, BusyInjectorProps } from "../../../../../components/BusyInjector/BusyInjector";
import TZModal from "../../../../../components/TZModal/TZModal";
import { useSelector } from "../../../../../helpers/redux";
import _ from "lodash";
import { rosterSettingsRepository } from "../../../../../repositories/rosterSettingsRepository";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";

export const WeekPlanSettingsModal = busyInjector((props: {} & BusyInjectorProps) => {
  const dispatch = useDispatch<DispFn>();
  const rosterSettings = useSelector((s) => s.data.rosterSettings[0])!;
  const userDetails = useSelector((s) => s.data.userDetails);
  const [isLoading, setLoading] = useState(false);
  const [showBreaksInWeekPlan, setShowBreaks] = useState(rosterSettings.showBreaksInWeekPlan);
  const [showStaffNumberInRoster, setShowStaffNumber] = useState(rosterSettings.showStaffNumberInRoster);
  const [showUserTypeInRoster, setShowUserType] = useState(rosterSettings.showUserTypeInRoster);
  const [showDurationsInWeekPlan, setShowDurations] = useState(rosterSettings.showDurationsInWeekPlan);
  const [hideSaturdaysInWeekPlan, setHideSaturdays] = useState(rosterSettings.hideSaturdaysInWeekPlan);
  const [hideSundaysInWeekPlan, setHideSundays] = useState(rosterSettings.hideSundaysInWeekPlan);
  const [showQuotaInWeeklyRosterSlots, setQuotaInSlots] = useState(rosterSettings.showQuotaInWeeklyRosterSlots);
  const [hideOvertimeInRoster, setShowOvertime] = useState(rosterSettings.hideOvertimeInRoster);
  const [expandAvailabilitesInWeeklyRoster, setExpandAvailabilites] = useState(
    rosterSettings.expandAvailabilitesInWeeklyRoster
  );

  const hasUsersWithStaffNumber = userDetails.some((u) => u.employNum);

  const saveClicked = async () => {
    setLoading(true);
    await dispatch(
      rosterSettingsRepository.update({
        ...rosterSettings,
        showBreaksInWeekPlan,
        showStaffNumberInRoster,
        showUserTypeInRoster,
        showDurationsInWeekPlan,
        hideSaturdaysInWeekPlan,
        hideSundaysInWeekPlan,
        showQuotaInWeeklyRosterSlots,
        hideOvertimeInRoster,
        expandAvailabilitesInWeeklyRoster,
      })
    );
    dispatch(closeModal());
  };

  return (
    <TZModal className="weekPlanSettingsModal">
      <TZModal.Head>{lg.wochenplan_ansicht}</TZModal.Head>
      <TZModal.Body>
        <div className="content">
          <div className="row">
            <Checkbox
              checked={showBreaksInWeekPlan}
              onChange={() => setShowBreaks(!showBreaksInWeekPlan)}
              children={lg.pausen_in_schichten_des_wochenplans_anzeigen}
            />
          </div>
          <div className="row">
            <Checkbox
              checked={showDurationsInWeekPlan}
              onChange={() => setShowDurations(!showDurationsInWeekPlan)}
              children={lg.länge_der_schichten_im_wochenplan_anzeigen}
            />
          </div>
          <div className="row withBorderTop">
            <Checkbox
              checked={hideSaturdaysInWeekPlan}
              onChange={() => setHideSaturdays(!hideSaturdaysInWeekPlan)}
              children={lg.samstage_im_wochenplan_ausblenden}
            />
          </div>
          <div className="row">
            <Checkbox
              checked={hideSundaysInWeekPlan}
              onChange={() => setHideSundays(!hideSundaysInWeekPlan)}
              children={lg.sonntage_im_wochenplan_ausblenden}
            />
          </div>
          <div className="row withBorderTop">
            <Checkbox
              checked={showQuotaInWeeklyRosterSlots}
              onChange={() => setQuotaInSlots(!showQuotaInWeeklyRosterSlots)}
              children={lg.sollstunden_in_leeren_zellen_des_dienstplans_anzeigen}
            />
            <div
              className="infoIconWrapper"
              data-rh={lg.diese_werden_sichtbar_wenn_sich_die_maus_über_der_mitarbeiter_spalte_befindet}
            >
              <Icon type="info-circle" />
            </div>
          </div>
          {hasUsersWithStaffNumber && (
            <div className="row">
              <Checkbox
                checked={showStaffNumberInRoster}
                onChange={() => setShowStaffNumber(!showStaffNumberInRoster)}
                children={lg.personalnummer_zum_mitarbeiternamen_im_dienstplan_anzeigen}
              />
            </div>
          )}
          <div className="row">
            <Checkbox
              checked={showUserTypeInRoster}
              onChange={() => setShowUserType(!showUserTypeInRoster)}
              children={lg.mitarbeiter_typ_im_dienstplan_anzeigen}
            />
          </div>
          <div className="row">
            <Checkbox
              checked={!hideOvertimeInRoster}
              onChange={() => setShowOvertime(!hideOvertimeInRoster)}
              children={lg.überstunden_im_dienstplan_anzeigen}
            />
          </div>
          <div className="row">
            <Checkbox
              checked={expandAvailabilitesInWeeklyRoster}
              onChange={() => setExpandAvailabilites(!expandAvailabilitesInWeeklyRoster)}
              children={lg.verfügbarkeiten_im_wochenplan_detailliert_anzigen}
            />
          </div>
        </div>
      </TZModal.Body>
      <TZModal.Footer>
        <Button
          style={{ width: 120 }}
          id="cancel-sortorder-modal"
          onClick={saveClicked}
          type="primary"
          icon={isLoading ? "loading" : undefined}
          children={isLoading ? "" : lg.Speichern}
        />
      </TZModal.Footer>
    </TZModal>
  );
});
