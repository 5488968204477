import { createSelector } from "reselect";
import { selectSessionInfo } from "./SessionInfoSelector";
import _ from "lodash";
import { selectTimeClockSettingsByUser } from "./timeClockSettingsByUserSelector";
import { selectVisibleBranches } from "./VisibleBranchesSelector";

export const webUserTimeClockEnabledSelector = createSelector(
  [selectSessionInfo, selectVisibleBranches, selectTimeClockSettingsByUser],
  (sessionInfo, visibleBranches, timeClockSettingsByUser) => {
    const { user } = sessionInfo;
    const timeClockSettings = timeClockSettingsByUser[user.id]!;
    return timeClockSettings.allowUserTimClockInBrowser && !!visibleBranches.find((b) => b.isClockingEnabled);
  }
);
