import React, { PureComponent } from "react";

import "./styles.scss";
import cn from "classnames";
import { IUser } from "../../../../../shared/entities/IUser";
import { AbsenceTypeCode, IAbsenceType } from "../../../../../shared/entities/IAbsenceType";

type Props = {
  user: IUser;
  userName: string;
  absenceSum?: number;
  entitlement?: number;
  year: number;
  canManageAbsences: boolean;
  currentUser: IUser;
  width: number;
  selectedAbsenceType: IAbsenceType;
};

export default class UserCell extends PureComponent<Props, {}> {
  render() {
    const { user, userName, absenceSum, entitlement, year, canManageAbsences, currentUser, width } = this.props;
    const isVacation = this.props.selectedAbsenceType.code === AbsenceTypeCode.vacation;

    const tooltipText =
      (isVacation ? lg.urlaubstage : lg.tage_gesamt) +
      " " +
      (entitlement ? " / " + (isVacation ? lg.urlaubsanspruch : lg.kontingent) : "") +
      ` ${lg.im_jahr}` +
      ` ${year} ` +
      (isVacation ? "" : `(${this.props.selectedAbsenceType.name})`);

    return (
      <div className="absenceUserCellMain _unselectable" style={{ width }}>
        {canManageAbsences && (
          <div
            className="fb addAbsenceBtn"
            data-type="absence-user"
            data-user={user.id}
            data-rh={lg.abwesenheit_eintragen}
            data-rh-at="right"
          >
            +
          </div>
        )}

        <div className={cn({ fb: true, userName: true, transform: canManageAbsences })}>{userName}</div>

        {(canManageAbsences || currentUser.id === user.id) && (
          <div className="totalDaysInfo" data-rh={tooltipText} data-rh-at="right">
            <div className="daysSum">{absenceSum || 0}</div>
            {entitlement && <div className="contingent">{"/ " + entitlement}</div>}
          </div>
        )}
      </div>
    );
  }
}
