import { decryptUser } from "./../shared/helpers/userHelpers";
import { DispFn } from "../frontend-core/types/thunkTypes";
import jsPDF from "jspdf";
import autoTable, { CellDef, Styles as CellDefStyles } from "jspdf-autotable";
import { AppState } from "../types/AppState";
import {
  selectVisibleUsersOfRoster,
  selectVisibleUsersOfRosterByJobPosition,
} from "../selectors/visibleUsersOfRosterSelector";
import { generateWeekdays, simpleDateToMoment, toMomentUnsafe, toSimpleDate } from "../shared/helpers/timeHelpers";
import { RosterMode } from "../reducers/ui/shifts/roster/rosterMode";
import moment, { Moment } from "moment";
import _ from "lodash";
import { selectRosterDateRange } from "../selectors/rosterDateRangeSelector";
import { getMonthMomentDates } from "../helpers/general";
import { relevantShiftsOfBranchSelector } from "../selectors/RelevantShiftsSelector";
import { IShift } from "../shared/entities/IShift";
import color from "color";
import { AbsenceStatus } from "../shared/entities/IAbsence";
import { localizeFormat } from "../helpers/dateFormatHelper";
import { selectWorkSpaceMap } from "../selectors/mapSelectors";
import { selectWorkSpaces } from "../selectors/_workSpacesSelector";
import { selectGroupByJobPosition } from "../selectors/groupByJobPositionSelector";

export const printModeActionTypes = {
  SET: "@@AV/PRINT_MODE_SET",
};

type CellDefExtended = CellDef & {
  shifts: IShift[];
  userId?: string;
  jobPositionId?: string;
  day: Moment;
};

export const startPrintMode = () => {
  return (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const usersByJobPos = selectVisibleUsersOfRosterByJobPosition(state);
    const visibleUsers = selectVisibleUsersOfRoster(state);
    const workSpaceMap = selectWorkSpaceMap(state);
    const branchId = state.ui.selectedBranch;
    const branch = state.data.branches.find((b) => b.id === branchId);
    const jobPositions = state.data.jobPositions;
    const workSpaces = selectWorkSpaces(state);
    const groupByJobPosition = selectGroupByJobPosition(state);
    const rosterMode = state.ui.shifts.roster.rosterMode;
    const rosterSettings = state.data.rosterSettings[0];
    const absenceTypes = state.data.absenceTypes;
    const { rangeStart, rangeEnd } = selectRosterDateRange(state);
    const kw = toMomentUnsafe(rangeStart)!.isoWeek();
    const rangeStartMoment = simpleDateToMoment(rangeStart);
    const shifts = relevantShiftsOfBranchSelector(state);
    const dailyNotes = state.data.dailyNotes;
    const isV2 = getState().data.tenantInfo.isV2;

    const collapsedJobPositions = state.ui.shifts.roster.collapsedJobPositions;
    const absences = state.data.absences;

    const isMM = rosterMode === RosterMode.Month;
    const isWM = rosterMode === RosterMode.Week;

    const showLabels = isMM && rosterSettings.showLabelInMonthlyRosterShifts;

    const title = isWM
      ? `${branch?.name} - KW ${kw} ${rangeStartMoment.year()}`
      : `${branch?.name} - ${rangeStartMoment.format("MMM YYYY")}`;

    const getLabelName = (labelId: string) => {
      return workSpaceMap[labelId]!.name.substr(0, 3);
    };

    const getDailyNotes = (date: string) => {
      let txt = "";
      dailyNotes
        .filter((n) => n.date === date && (!branchId || n.branchId == branchId))
        .forEach((n) => (txt += "\n" + n.text.substr(0, 15)));
      return txt;
    };

    dispatch({
      type: printModeActionTypes.SET,
      payload: true,
    });

    const shiftRowHeight = isWM ? 5 : 7;
    const jobPosRowHeight = 2;
    const getShiftHeight = (s: IShift) => {
      if (isWM) {
        let v = shiftRowHeight;
        if (!s.userId || !groupByJobPosition) {
          v = v + shiftRowHeight;
        }
        if (s.workSpaceId) {
          v = v + shiftRowHeight;
        }
        return v + 1;
      } else {
        return shiftRowHeight;
      }
    };
    const doc = new jsPDF("l");
    const Product = isV2 ? "Zeitguru" : "Aplano";
    doc.setFontSize(14);
    doc.text(Product + " Export: " + title, 7, 12);

    const baseFirstColumnCellStyles: Partial<CellDefStyles> = {
      cellWidth: isWM ? 30 : 24,
      overflow: "ellipsize",
      fontStyle: "normal",
      textColor: "black",
      fontSize: 9,
    };

    // ---------------- Head-Row ----------------
    const headCells: CellDef[] = [
      {
        content: "",
        styles: {
          ...baseFirstColumnCellStyles,
          // fillColor: "#000000",
          fillColor: "white",
        },
      },
    ];

    let days: Moment[];

    if (isWM) {
      days = generateWeekdays(rangeStart, {
        noSaturday: rosterSettings.hideSaturdaysInWeekPlan,
        noSunday: rosterSettings.hideSundaysInWeekPlan,
      });

      days.forEach((m, i) => {
        const notes = getDailyNotes(toSimpleDate(m));
        headCells.push({
          content: m.format(localizeFormat("dd DD.MM")) + notes,
          styles: {
            fillColor: notes.length ? "lightblue" : "white",
            lineColor: "white",
            lineWidth: 1,
            textColor: "black",
            fontStyle: "normal",
            halign: "center",
          },
        });
      });
    } else {
      // monthly roster mode
      days = getMonthMomentDates(rangeStart);
      days.forEach((m, i) => {
        headCells.push({
          content: m.format("dd DD"),
          styles: {
            fillColor: "#f1f1f1",
            textColor: "black",
            fontSize: 8,
            valign: "middle",
            halign: "center",
            cellPadding: 1,
            fontStyle: "normal",
            overflow: "linebreak",
          },
        });
      });
    }

    // ---------------- Body ----------------

    const generateSlotCells = (day: Moment, jobPositionId: string | undefined, userId: string): CellDefExtended => {
      const simpleDate = toSimpleDate(day);

      const absence = absences.find(
        (a) =>
          a.status === AbsenceStatus.active &&
          a.startDate <= simpleDate &&
          a.endDate >= simpleDate &&
          a.userId === userId &&
          !((a.startsHalfDay && simpleDate === a.startDate) || (a.endsHalfDay && simpleDate === a.endDate))
      );

      const absenceType = absence ? absenceTypes.find((aT) => aT.id === absence.typeId) : undefined;
      const absenceColor = absenceType?.color;

      const cellShifts = _.orderBy(
        shifts.filter(
          (s) =>
            s.date === simpleDate &&
            userId === s.userId &&
            (!jobPositionId || jobPositionId === s.jobPositionId) &&
            !absence
        ),
        (s) => s.startTime
      );

      const rowHeight = _.sum(cellShifts.map(getShiftHeight));
      return {
        day,
        jobPositionId,
        userId,
        content:
          isWM && absence
            ? (absenceType?.name?.length || 0) > 8
              ? absenceType?.name?.substr(0, 8) + "..."
              : absenceType?.name
            : "",
        shifts: cellShifts,
        styles: {
          minCellHeight: isWM ? (cellShifts.length ? rowHeight + 2 + cellShifts.length * 1 : undefined) : rowHeight + 1,
          cellPadding: isWM && absence ? { left: 1, right: 1 } : 0,
          fillColor: absence ? color(absenceColor).lighten(0.76).hex() : undefined,
          textColor: absence ? absenceColor : undefined,
          valign: absence ? "middle" : undefined,
          halign: absence ? "center" : undefined,
          overflow: "hidden",
        },
      };
    };

    const mainTableRows: CellDef[][] = [];

    // const openShiftsRow: CellDef[] = [
    //   {
    //     content: "Offene Schichten",
    //     styles: {
    //       cellWidth: 30,
    //     },
    //   },
    //   ...days.map((wd) => generateSlotCells(wd, undefined, undefined)),
    // ];
    // rows.push(openShiftsRow);

    if (groupByJobPosition) {
      const orderedJobPositions = _.orderBy(jobPositions, ["sortIndex"]);
      orderedJobPositions.forEach((jp) => {
        if (!usersByJobPos[jp.id].length && jp.isInactive) {
          return;
        }

        if (collapsedJobPositions.find((jId) => jId === jp.id)) {
          return;
        }

        // ---------- JobPositionLine
        mainTableRows.push([
          {
            content: jp.name,
            styles: {
              ...baseFirstColumnCellStyles,
              textColor: "white",
              fillColor: jp.color,
              minCellHeight: jobPosRowHeight,
              fontSize: 8.5,
              cellPadding: { top: 1, bottom: 1, left: 1 },
            },
          },
          ...days.map((m) => ({
            styles: {
              fillColor: jp.color,
              minCellHeight: jobPosRowHeight,
              fontSize: 1,
            },
            content: "",
          })),
        ]);

        // ---------- UserRow (grouped)
        usersByJobPos[jp.id].forEach((u, i) => {
          const user = decryptUser(u);
          mainTableRows.push([
            {
              content: user.name,
              styles: {
                ...baseFirstColumnCellStyles,
                fillColor: color(jp.color).lighten(0.76).hex(),
              },
            },
            ...days.map((m) => generateSlotCells(m, jp.id, u.id)),
          ]);
        });
      });
    } else {
      visibleUsers.forEach((u) => {
        // ---------- UserRow (un-grouped)
        const user = decryptUser(u);
        mainTableRows.push([
          {
            content: user.name,
            styles: baseFirstColumnCellStyles,
          },
          ...days.map((m) => generateSlotCells(m, undefined, u.id)),
        ]);
      });
    }

    // ---------- Draw Main Table
    autoTable(doc, {
      theme: "grid",
      head: [headCells],
      rowPageBreak: "avoid",
      body: mainTableRows,
      startY: 20,
      margin: { top: 7, left: 7 },
      columnStyles: isMM
        ? {}
        : {
            0: { cellWidth: 36 },
            1: { cellWidth: 36 },
            2: { cellWidth: 36 },
            3: { cellWidth: 36 },
            4: { cellWidth: 36 },
            5: { cellWidth: 36 },
            6: { cellWidth: 36 },
            7: { cellWidth: 36 },
          },

      didDrawCell: (dataSlot) => {
        if (dataSlot.row.section === "body") {
          const cellExt = dataSlot.cell.raw as CellDefExtended;
          if (!cellExt?.shifts?.length) {
            return;
          }
          // ---------- Draw Slot Table
          autoTable(doc, {
            theme: "plain",
            startY: dataSlot.cell.y + (isWM ? 1 : 0.3),
            margin: { left: dataSlot.cell.x + (isWM ? 1 : 0.3) },
            tableWidth: dataSlot.cell.width - (isWM ? 2 : 0.6),
            styles: {
              // fillColor: isMM ? false : "#f1f1f1",
              // fillColor: "red",
              // lineWidth: 1,
              // cellPadding: isWM ? 1 : 0.3,
            },
            body: [
              ...cellExt.shifts.map((s) => {
                const displayLabel = showLabels && s.workSpaceId;
                return [
                  {
                    shift: s,
                    content: "",
                    styles: {
                      minCellHeight: getShiftHeight(s) + (isWM ? 1 : 0),
                      lineWidth: isWM ? 1 : 0.3,
                      lineColor: "#ffffff",
                      //fillColor: "#f1f1f1",
                      fillColor: displayLabel ? workSpaceMap[s.workSpaceId!].color : "#f1f1f1",
                      cellPadding: 0,
                    },
                  },
                ];
              }),
            ],
            didDrawCell: (dataShift) => {
              // ---------- Shift ----------
              const { shift } = dataShift.cell.raw as CellDef & { shift: IShift };
              const displayLabel = showLabels && shift.workSpaceId;
              const firstRow: CellDef[] = [
                {
                  content: isWM
                    ? shift.startTime + " - " + shift.endTime
                    : displayLabel
                    ? getLabelName(shift.workSpaceId!)
                    : shift.startTime + " " + shift.endTime,
                  styles: {
                    // cellWidth: 18,
                    cellPadding: 0,
                    overflow: isWM ? "visible" : "linebreak",
                    halign: isMM ? "center" : "left",
                    valign: isMM ? "middle" : "top",
                    fillColor: displayLabel ? workSpaceMap[shift.workSpaceId!].color : false,
                    minCellHeight: getShiftHeight(shift) - 1,
                  },
                },
              ];
              if (rosterSettings.showBreaksInWeekPlan && shift.userId && !isMM) {
                firstRow.push({
                  content: shift.breakMinutes,
                  styles: {
                    cellWidth: 7,
                    cellPadding: 0,
                    textColor: "#9e9e9e",
                    overflow: "visible",
                    halign: "right",
                  },
                });
              }

              // Open shifts stuff
              // if (!shift.userId) {
              //   doc.setDrawColor("#ff0000");
              //   doc.setFillColor("#ff0000");
              //   doc.roundedRect(dataShift.cell.x + dataShift.cell.width - 6, dataShift.cell.y + 1, 5, 4, 0.5, 0.5, "F");
              //   doc.setTextColor("white");
              //   doc.setFontSize(9);
              //   doc.text(
              //     shift.requiredUsersAmount + "",
              //     dataShift.cell.x + dataShift.cell.width - 2,
              //     dataShift.cell.y + 4,
              //     { align: "right" }
              //   );
              // }

              // ---------- Draw Shift Time Table
              autoTable(doc, {
                theme: "plain",
                startY: dataShift.cell.y + (isWM ? 2 : 0.3),
                margin: { left: dataShift.cell.x + (isWM ? 2 : 0.3) },
                tableWidth: dataShift.cell.width - (isWM ? 4 : 1),
                styles: {
                  cellPadding: 0,
                  fillColor: false,
                  fontSize: isWM ? 9 : 7,
                },
                body: [firstRow],
              });

              if (isWM) {
                const extraRows: CellDef[][] = [];

                if (!shift.userId || !groupByJobPosition) {
                  const jp = jobPositions.find((jP) => jP.id === shift.jobPositionId);
                  extraRows.push([
                    {
                      content: jp?.name,
                      styles: {
                        textColor: jp?.color,
                      },
                    },
                  ]);
                }

                if (shift.workSpaceId) {
                  const wS = workSpaces.find((wS) => wS.id === shift.workSpaceId);
                  extraRows.push([
                    {
                      content: wS?.name,
                    },
                  ]);
                }

                // ---------- Draw Shift JobPosition/Workspace Table
                autoTable(doc, {
                  theme: "plain",
                  startY: dataShift.cell.y + 7,
                  margin: { left: dataShift.cell.x + 2 },
                  tableWidth: dataShift.cell.width - 2,
                  styles: {
                    cellPadding: 0,
                    fillColor: false,
                    fontSize: 9,
                    overflow: "ellipsize",
                    minCellHeight: jobPosRowHeight,
                  },
                  body: extraRows,
                });
              }
            },
          });
        }
      },
    });

    // ---------- Download PDF
    doc.save(`${Product} Export ${title}.pdf`);
    setTimeout(() => {
      // if (rosterMode === RosterMode.Month || rosterMode === RosterMode.Day) {
      //   window.print();
      // }
      dispatch(endPrintMode());
    }, 1);
  };
};

export const endPrintMode = () => {
  return (dispatch: DispFn) => {
    dispatch({
      type: printModeActionTypes.SET,
      payload: false,
    });
  };
};
