import React from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { shiftRepository } from "../../../../repositories/shiftRepository";
import { trackingRepository } from "../../../../repositories/TrackingRepository";
import Page from "../../../../components/Page/Page";
import ReportActionBar from "../ReportActionBar/ReportActionBar";
import BusyWrapper from "../../../../components/BusyWrapper/BusyWrapper";
import { busyInjector, BusyInjectorProps } from "../../../../components/BusyInjector/BusyInjector";
import _ from "lodash";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { setReportFilterData, ReportFilterData } from "../../../../actions/reporting";
import { withErrorBoundary } from "../../../../components/ErrorBoundary/ErrorBoundary";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { minutesToDuration, minutesToHours, minutesToHoursStr } from "../../../../shared/helpers/timeHelpers";
import { selectAbsencesByUser } from "../../../../selectors/absencesByUserSelector";
import {
  selectBranchMap,
  selectUserMap,
  selectJobPositionMap,
  selectWorkSpaceMap,
  selectTrackingMap,
  selectAbsenceMap,
} from "../../../../selectors/mapSelectors";
import { selectAbsenceTypeMap } from "../../../../selectors/absenceTypeMapSelector";
import { selectCreditCorrectionMap } from "../../../../selectors/creditCorrectionMapSelector";
import { fetchShiftReportData } from "../shiftReport/shiftReportHelpers";
import {
  COL_STYLE,
  exportUserReportAsCsv,
  exportUserReportAsExcel,
  exportUserReportAsPdf,
  getAllColumns,
  getUserReportData,
  getUserRowTotals,
  RowData,
  StyledReportCol,
} from "./userReportHelpers";
import cn from "classnames";
import { getColumnKey, ReportEntryType } from "../reportHelpers";
import { closeModal } from "../../../../actions/modal";
import { TimeFormat } from "../../../../reducers/ui/reportSettings/reportTimeFormat";
import { selectWorkSpaces } from "../../../../selectors/_workSpacesSelector";
import { numberToEuroPrice } from "../../../../frontend-core/helpers/frontendHelpers";

const mapStateToProps = (state: AppState) => {
  return {
    users: state.data.users, // show all users including inactive ones for retrospective data
    shifts: state.data.shifts,
    workSpaces: selectWorkSpaces(state),
    trackingMap: selectTrackingMap(state),
    userMap: selectUserMap(state),
    branchMap: selectBranchMap(state),
    jobPosMap: selectJobPositionMap(state),
    workSpaceMap: selectWorkSpaceMap(state),
    jobPositions: state.data.jobPositions,
    branches: state.data.branches,
    absencesByUser: selectAbsencesByUser(state),
    absenceMap: selectAbsenceMap(state),
    correctionMap: selectCreditCorrectionMap(state),
    reportFilters: state.ui.shifts.reporting.filters,
    userInfo: selectSessionInfo(state),
    reportSettings: state.ui.reportSettings,
    absenceTypeMap: selectAbsenceTypeMap(state),
  };
};

type State = {
  sortBy: string;
  sortDirection: "asc" | "desc";
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & DispatchBaseProps & BusyInjectorProps;

class ReportPage extends React.PureComponent<Props, State> {
  reportData: RowData[] = [];
  visibleColumns: StyledReportCol[] = [];

  constructor(props: Props) {
    super(props);

    this.state = {
      sortBy: "userName",
      sortDirection: "asc",
    };
  }

  componentDidMount() {
    this.props.load(this.fetchData());
  }

  fetchData = async () => {
    this.visibleColumns = this.props.dispatch(getAllColumns());
    await this.props.dispatch(fetchShiftReportData());
    this.reportData = this.props.dispatch(getUserReportData());
  };

  getRowRenderer =
    (rows: RowData[]) =>
    ({ index, style }) => {
      const row = rows[index];
      const asDecimals = this.props.reportSettings.reportTimeFormat === TimeFormat.DecimalHours;

      return (
        <div style={style} className="entryRow" key={row.userId}>
          {this.visibleColumns.map((col) => {
            const cellValue = col.getCellValue(col, row, asDecimals);
            return (
              <div
                key={col.key}
                className={cn({
                  cell: true,
                  [col.className]: true,
                  [col.key]: true,
                  isEmpty: cellValue === "0:00" || cellValue === "0" || cellValue === "0 €",
                })}
                style={col.style}
              >
                {cellValue}
              </div>
            );
          })}
        </div>
      );
    };

  renderFooterTotals = () => {
    const userRowTotals = getUserRowTotals(this.reportData);
    const asDecimal = this.props.reportSettings.reportTimeFormat === TimeFormat.DecimalHours;

    return (
      <div className="footer">
        <div className="cell userCell sumCell" style={COL_STYLE.user}>
          {lg.summe}:
        </div>
        {this.visibleColumns
          .filter((col) => col.isDataColumn)
          .map((col) => {
            const duration = userRowTotals[col.key] || 0;
            const isHourAccount = col.key === getColumnKey(ReportEntryType.hourAccount);
            const withSign = duration < 0;
            let cellValue = asDecimal ? minutesToHoursStr(duration) : minutesToDuration(duration, { withSign });
            duration === 0 && (cellValue = asDecimal ? "0" : "0:00");
            isHourAccount && (cellValue = "");

            col.key === "wage" && (cellValue = numberToEuroPrice(duration));

            return (
              <div
                key={col.title}
                className={cn({ cell: true, durationCell: true, isEmpty: !duration }) + " " + col.className}
                style={col.style}
              >
                {cellValue}
              </div>
            );
          })}
      </div>
    );
  };

  exportReport = async (type: "pdf" | "csv" | "excel" | "multi-pdf", options?: {}) => {
    // multi-pdf is just used in case of shiftReport > needs sma signatur
    // options is just used in case of shiftReport > needs sma signatur
    // needs to be async because shiftReport 'exportReport-method' is also async > need same signature

    const { dispatch } = this.props;
    const reportData = this.getOrderedEntries(this.reportData);
    type === "csv" && (await dispatch(exportUserReportAsCsv(reportData)));
    type === "excel" && (await dispatch(exportUserReportAsExcel(reportData)));
    type === "pdf" && (await dispatch(exportUserReportAsPdf(reportData)));
  };

  headCellClicked = (key: string) => {
    const { sortDirection, sortBy } = this.state;
    if (key === sortBy) {
      this.setState({ sortDirection: sortDirection === "asc" ? "desc" : "asc" });
    } else {
      this.setState({
        sortBy: key,
        sortDirection: "asc",
      });
    }
  };

  getOrderedEntries = (entries: RowData[]): RowData[] => {
    return _.orderBy(
      entries,
      [
        (rowData) =>
          this.state.sortBy === "userName"
            ? rowData.userName
            : rowData.durations.find((d) => d.columnKey === this.state.sortBy),
      ],
      [this.state.sortDirection]
    );
  };

  handleActionBarFilterUpdate = (filters: ReportFilterData) => {
    this.props.setLoading("main", true);
    this.props.dispatch(setReportFilterData(filters));
    setTimeout(async () => {
      await this.fetchData();
      this.props.setLoading("main", false);
    });
  };

  render() {
    console.log("visibleColumns");
    console.log(this.visibleColumns);

    const { isLoading } = this.props;
    const reportData = this.getOrderedEntries(this.reportData);
    return (
      <Page>
        <div className={`shiftsReportMain`}>
          <ReportActionBar
            filters={this.props.reportFilters}
            createExport={this.exportReport}
            updateFilters={this.handleActionBarFilterUpdate}
          />
          <div className="mainReportTable mainUserReportTable">
            <div className="head">
              {this.visibleColumns.map((col) => (
                <div
                  className={"cell headCell " + col.key + " " + col.className}
                  key={col.key}
                  style={col.style}
                  onClick={() => this.headCellClicked(col.key)}
                >
                  {col.title}
                </div>
              ))}
            </div>
            <BusyWrapper isBusy={isLoading()} style={{ height: "100%" }}>
              <div className="body">
                {!reportData.length && !this.props.isLoading() && (
                  <div className="noData">{lg.keine_daten_im_gewählten_zeitraum_vorhanden}</div>
                )}
                {!this.props.isLoading() && (
                  <>
                    <AutoSizer>
                      {({ height, width }) => (
                        <List
                          height={height}
                          itemCount={reportData.length}
                          itemSize={38}
                          width={width}
                          style={{ overflowY: "scroll" }}
                        >
                          {this.getRowRenderer(reportData)}
                        </List>
                      )}
                    </AutoSizer>
                  </>
                )}
              </div>
            </BusyWrapper>
            {this.renderFooterTotals()}
          </div>
        </div>
      </Page>
    );
  }
}

export const UserReport = withErrorBoundary(
  connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(busyInjector(ReportPage))
);
