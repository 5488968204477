import React from "react";
import { InputNumber, Icon } from "antd";
import "./styles.scss";
import { IShift } from "../../../../shared/entities/IShift";
import AvOption from "../../../AvOption/AvOption";
import { decimalSeparatorLocal } from "../../../../helpers/dateFormatHelper";

type Props = {
  updateShiftValue: (key: keyof IShift, value: any) => void;
  shift: Partial<IShift>;
  collapseField: () => any;
};

export class SurchargeShiftRow extends React.PureComponent<Props> {
  render() {
    const { updateShiftValue, shift, collapseField } = this.props;

    return (
      <div className="fb row aEnd surchargeShiftRowMain">
        <div className="fb inputWrapper">
          <div className="avInputFieldMain">
            <div className="labelWrapper">
              <label>{lg.aufschlag}</label>
            </div>
            <InputNumber
              decimalSeparator={decimalSeparatorLocal}
              onChange={(value) => updateShiftValue("surcharge", value)}
              value={shift.surcharge}
              name={"surcharge"}
            />
          </div>
        </div>
        <div className="typeWrapper">
          <AvOption
            style={{
              marginBottom: 1,
              marginRight: 24,
              marginLeft: -16,
            }}
            asRadio={true}
            onChange={(v: any) => updateShiftValue("surchargeInPercentage", v === "percentage")}
            options={[
              { label: "%", value: "percentage" },
              { label: "h", value: "hours" },
            ]}
            value={shift.surchargeInPercentage ? "percentage" : "hours"}
            buttonStyle={"solid"}
          />
          <div className="infoText">{lg.auf_das_Zeitkonto}</div>
        </div>
        <div
          className="closeExpandedFieldButton"
          onClick={() => {
            updateShiftValue("surcharge", undefined);
            updateShiftValue("surchargeInPercentage", undefined);
            collapseField();
          }}
        >
          <Icon type="close" />
        </div>
      </div>
    );
  }
}
