import { Button } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { closeModal } from "../../actions/modal";
import { pastelColors } from "../../frontend-core/helpers/colors";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import WorkSpaceEditor from "../../pages/settings/branches/branchPopup/workspaceEditor/WorkSpaceEditor";
import WorkSpaceForm from "../../pages/settings/branches/branchPopup/workspaceEditor/workspaceForm/WorkSpaceForm";
import { workSpaceRepository } from "../../repositories/workSpaceRepository";
import { IWorkSpace } from "../../shared/entities/IWorkSpace";
import { busyInjector, BusyInjectorProps } from "../BusyInjector/BusyInjector";
import TZModal from "../TZModal/TZModal";
import "./styles.scss";

type Props = {
  onComplete: (w: IWorkSpace) => void;
};

export const AddWorkSpaceModal = busyInjector((props: Props & BusyInjectorProps) => {
  const [workSpace, updateWorkSpace] = useState<IWorkSpace>({
    color: _.sample(pastelColors)!,
    id: uuid(),
    name: "",
    createdAt: moment().unix(),
  });
  const dispatch = useDispatch<DispFn>();
  const save = async () => {
    const ws = await props.load(dispatch(workSpaceRepository.create(workSpace)));
    props.onComplete(ws);
    dispatch(closeModal());
  };
  return (
    <TZModal className="addWorkSpaceModal">
      <TZModal.Head title={lg.neues_label}></TZModal.Head>
      <TZModal.Body style={{ minHeight: "none" }}>
        <WorkSpaceForm
          key={"adding"}
          workSpace={workSpace}
          isCreating={true}
          updateWorkSpace={updateWorkSpace}
          saveWorkSpace={save}
        />
      </TZModal.Body>
    </TZModal>
  );
});
