import { IUser } from "./../../shared/entities/IUser";
import * as Sentry from "@sentry/browser";
import { getUserName } from "../../shared/helpers/userHelpers";

export const configureSentry = (sentry: { init: (options?: any) => void }, environment: string) => {
  sentry.init({
    dsn: "https://58f2799e9258490784c0036068c38fa2@sentry.io/4918357",
    integrations: [
      new Sentry.Integrations.GlobalHandlers({
        onunhandledrejection: false,
        onerror: false,
      }),
    ],
    environment,
  });
};

export const setSentryUser = (user: IUser, tenantId: string) => {
  Sentry.configureScope((scope) => {
    scope.setTag("tenantId", tenantId);
    scope.setUser({
      ...user,
      username: getUserName(user), // senstry wants "username"
    });
  });
};
