import React, { useEffect, useState } from "react";
import "./styles.scss";
import _ from "lodash";
import { RosterDayGridBody } from "./RosterDayGridBody";
import { RosterDayGridHead } from "./RosterDayGridHead";
import { useSelector } from "../../../../helpers/redux";
import { simpleTimeToMoment, toSimpleTime } from "../../../../shared/helpers/timeHelpers";
import { handleScrolledDownClassIfNeeded } from "../../../../helpers/general";
import { EmptyRowsAreHiddenRow } from "../RosterWeekGrid/EmptyRowsAreHiddenRow/EmptyRowsAreHiddenRow";
import { RosterDayGridHeadCount } from "./RosterDayGridHeadCount/RosterDayGridHeadCount";
import moment from "moment";

type Props = {
  selectedDay: string;
};

const RosterDayGrid = (props: Props) => {
  const currentBranchId = useSelector((state) => state.ui.selectedBranch);
  const dayRosterTimeSpan = useSelector((s) => s.ui.shifts.roster.dayRosterTimeSpan);
  const hideEmptyRows = useSelector((s) => s.ui.shifts.roster.emptyShiftRowsHidden);
  const rosterSettings = useSelector((s) => s.data.rosterSettings[0]);
  const [unixNow, updateUnixNow] = useState(0); // this is just to rerender periodacally to reposition the shifts and now-line

  const rerenderNow = () => {
    updateUnixNow(Date.now());
  };

  useEffect(() => {
    const interval = setInterval(rerenderNow, 1000 * 60);
    return () => clearInterval(interval);
  }, []);

  const scrollListener = ({ scrollOffset }) => {
    handleScrolledDownClassIfNeeded(scrollOffset !== 0, "userGridWrapper");
  };

  const getWorkingHours = () => {
    const hoursPerDay = Array.from(Array(24).keys());
    return hoursPerDay.filter((i) => i >= dayRosterTimeSpan.dayStart && i < dayRosterTimeSpan.dayEnd);
  };

  const currentTime = getWorkingHours().find((h) => h && +h === new Date().getHours());
  const nowSimpleTime = toSimpleTime(moment());
  const getTime = (time, format) => +simpleTimeToMoment(time).format(format);

  return (
    <div className="userGridWrapper">
      <div className="dailyRoster usersGrid">
        <RosterDayGridHead workingHours={getWorkingHours()} currentTime={currentTime} />
        {rosterSettings.showHeadCountInDayPlan && <RosterDayGridHeadCount workingHours={getWorkingHours()} />}
        <RosterDayGridBody
          scrollListener={scrollListener}
          hoursInDay={getWorkingHours()}
          getTime={getTime}
          selectedDay={props.selectedDay}
          currentBranchId={currentBranchId}
          nowSimpleTime={nowSimpleTime}
        />
        {hideEmptyRows && <EmptyRowsAreHiddenRow />}
      </div>
    </div>
  );
};

export default RosterDayGrid;
