import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { InputNumber, Button, Icon, Input, Modal } from "antd";
import TZModal from "../../TZModal/TZModal";
import { closeModal } from "../../../actions/modal";
import "./styles.scss";
import { contingentCorrectionRepository } from "../../../repositories/contingentCorrectionRepository";
import { busyInjector, BusyInjectorProps } from "../../BusyInjector/BusyInjector";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import AvOption from "../../AvOption/AvOption";
import { IContingentCorrection } from "../../../shared/entities/IContingentCorrection";
import { Raw } from "../../../shared/entities/IResource";
import { selectUsersFull } from "../../../selectors/usersFullSelector";
import { decimalSeparatorLocal } from "../../../helpers/dateFormatHelper";

const mapStateToProps = (state: AppState) => ({
  users: selectUsersFull(state),
});

type State = { days?: number; note?: string; isPositive: boolean };
type OwnProps = {
  correction?: IContingentCorrection;
  absenceTypeId: string;
  year: number;
  userId: string;
  isOverride: boolean;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & BusyInjectorProps & DispatchBaseProps;

class ContingentCorrectionModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { correction } = this.props;

    this.state = {
      days: correction && Math.abs(correction.days),
      note: correction?.note,
      isPositive: !correction || correction.days >= 0,
    };
  }

  onChange = (_days?: number | string) => {
    // when the user clears the input-field, '' is the value
    const days = typeof _days === "string" ? undefined : _days;
    this.setState({ days });
  };

  save = async () => {
    if (this.state.days === undefined) {
      return;
    }

    const days = this.state.isPositive ? this.state.days : -this.state.days;
    const isCreationMode = !this.props.correction;

    this.props.setLoading("save", true);
    if (isCreationMode) {
      const creationProps: Raw<IContingentCorrection> = {
        year: this.props.year,
        userId: this.props.userId,
        days: days || 0,
        note: this.state.note,
        isOverride: this.props.isOverride,
        absenceTypeId: this.props.absenceTypeId,
      };
      await this.props.dispatch(contingentCorrectionRepository.create(creationProps));
    } else {
      const editedCorrection: IContingentCorrection = {
        ...this.props.correction!,
        days: days || 0,
        note: this.state.note,
      };
      await this.props.dispatch(contingentCorrectionRepository.update(editedCorrection));
    }
    this.props.setLoading("save", false);
    this.props.dispatch(closeModal());
  };

  deleteClicked = () => {
    Modal.confirm({
      title: lg.eintrag_löschen,
      content: lg.die_eingetragene_korrektur_wirklich_löschen,
      onOk: () => {
        this.delete();
      },
      onCancel: () => {},
      okText: lg.löschen,
      cancelText: lg.abbrechen,
    });
  };

  delete = async () => {
    await this.props.load(
      this.props.dispatch(contingentCorrectionRepository.remove(this.props.correction!.id)),
      "delete"
    );
    this.props.dispatch(closeModal());
  };

  render() {
    const { users } = this.props;
    const user = users.find((u) => u.id === this.props.userId);
    const userName = user!.name;
    const isCreationMode = !this.props.correction;

    return (
      <TZModal>
        <TZModal.Head title={userName}></TZModal.Head>
        <TZModal.Body>
          <div className="contingentCorrectionModalMain">
            <div className="row">
              {this.props.isOverride && (
                <div className="label">
                  {lg.korrigierter_urlaubsanspurch_für_das_jahr} {this.props.year}
                </div>
              )}
              <div className="daysInputWrapper">
                {!this.props.isOverride && (
                  <div className="signWrapper">
                    <AvOption
                      value={this.state.isPositive}
                      options={[
                        { value: true, label: lg.plus },
                        { value: false, label: lg.minus },
                      ]}
                      onChange={(isPositive) => this.setState({ isPositive })}
                    />
                  </div>
                )}
                <InputNumber
                  decimalSeparator={decimalSeparatorLocal}
                  autoFocus={true}
                  value={this.state.days}
                  onChange={this.onChange}
                  style={{
                    color: this.state.isPositive ? "#1a90ff" : "red",
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.save();
                    }
                  }}
                />
                <div className="unit">{lg.Tage}</div>
              </div>
            </div>
            <div className="row withLine">
              <div className="inputWrapper">
                <Input.TextArea
                  placeholder={lg.notiz}
                  value={this.state.note || undefined}
                  onChange={(e) => this.setState({ note: e.target.value })}
                  autoSize={{ minRows: 2, maxRows: 4 }}
                />
              </div>
            </div>
          </div>
        </TZModal.Body>
        <TZModal.Footer>
          {!isCreationMode && (
            <Button
              id="delete-contingent-correction"
              type="danger"
              onClick={this.deleteClicked}
              loading={this.props.isLoading("delete")}
            >
              <Icon type="delete" />
            </Button>
          )}
          <Button
            id="save-contingent-correction"
            type="primary"
            onClick={this.save}
            loading={this.props.isLoading("save")}
            disabled={this.state.days === undefined}
          >
            {lg.Speichern}
          </Button>
        </TZModal.Footer>
      </TZModal>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  busyInjector(ContingentCorrectionModal)
);
