import React, { useEffect, useState } from "react";
import Stripe from "stripe";
import { sendPost } from "../../../frontend-core/actions/send";
import { useSelector } from "../../../helpers/redux";
import { tierInfoSelector } from "../../../selectors/TierInfoSelector";
import moment from "moment";

import "./styles.scss";
import { Button, Checkbox, Icon, message, Modal, notification } from "antd";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../../actions/modal";
import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import BusyWrapper from "../../../components/BusyWrapper/BusyWrapper";
import { IBillingDetails } from "../../../shared/entities/IBillingDetails";
import { openUpdatePaymentDetailsModal } from "../../../actions/billingActions";
import { RuleSpecificationBtn } from "../../../components/RuleSpecificationBtn/RuleSpecificationBtn";
import { IRosterSettings } from "../../../shared/entities/IRosterSettings";
import { rosterSettingsRepository } from "../../../repositories/rosterSettingsRepository";
import { DispFn } from "../../../frontend-core/types/thunkTypes";

type Props = {};

export const ChatSettings = busyInjector((props: Props & BusyInjectorProps) => {
  const dispatch = useDispatch<DispFn>();
  const rosterSettings = useSelector((s) => s.data.rosterSettings[0]);
  const [settingsState, setSettingsState] = useState<IRosterSettings>({ ...rosterSettings });

  const optionClicked = (key: keyof IRosterSettings) => {
    const isDisablingChat = key === "canChat" && !!settingsState[key];
    setSettingsState({
      ...settingsState,
      canGroupChat: isDisablingChat ? false : settingsState.canGroupChat,
      canChatWithUsersFromOtherBranch: isDisablingChat ? false : settingsState.canChatWithUsersFromOtherBranch,
      [key]: !settingsState[key],
    });
  };

  const renderCheckbox = (
    key: keyof IRosterSettings,
    label: React.ReactNode,
    options: {
      hidden?: boolean;
      styles?: React.CSSProperties;
      infoText?: string;
      classes?: string;
      withoutSpecification?: boolean;
    } = {}
  ) => {
    if (options.hidden) {
      return null;
    }
    return (
      <div
        className={"checkboxWrapper borderTop ruleBlock" + " " + options?.classes}
        key={key}
        style={options.styles || {}}
      >
        <Checkbox checked={settingsState[key] as boolean} onChange={() => optionClicked(key)} />
        <div className="label" onClick={() => optionClicked(key)}>
          {label}
        </div>
        {options.infoText && (
          <div className="infoIconWrapper" data-rh={options.infoText}>
            <Icon type="info-circle" />
          </div>
        )}
        {!options.withoutSpecification && settingsState[key] && (
          <RuleSpecificationBtn
            type="RosterSettings"
            ruleKey={key}
            style={{
              position: "absolute",
              right: 0,
              bottom: 8,
            }}
          />
        )}
      </div>
    );
  };

  const saveSettings = async () => {
    await dispatch(
      rosterSettingsRepository.update({
        ...rosterSettings,
        ...settingsState,
      })
    );
  };

  return (
    <div className="chatSettingsMain">
      {/* <h2 className="avTitle">Chat Einstellungen</h2> */}
      <h2>Chat</h2>
      <div className="content">
        <div className="basicCheckBoxesList">
          {renderCheckbox("canChat", lg.mitarbeiter_können_chatten, {
            // infoText: "Schichten mit fehlender Zeiterfassung werden als 'nicht angetreten' markiert.",
            styles: { borderTop: "none" },
          })}
          {renderCheckbox("canGroupChat", lg.mitarbeiter_können_in_gruppen_chatten, {
            // infoText: "Schichten mit fehlender Zeiterfassung werden als 'nicht angetreten' markiert.",
          })}
          {renderCheckbox("canChatWithUsersFromOtherBranch", lg.mitarbeiter_können_standortübergreifend_chatten, {
            // infoText: "Schichten mit fehlender Zeiterfassung werden als 'nicht angetreten' markiert.",
          })}
        </div>
        <div className="saveButtonWrapper">
          <Button
            id="save-chat-settings"
            type="primary"
            children={lg.Speichern}
            onClick={() => props.load(saveSettings())}
            loading={props.isLoading()}
          ></Button>
        </div>
      </div>
    </div>
  );
});
