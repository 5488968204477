import React from "react";
import { connect } from "react-redux";
import { Button, DatePicker, Icon, Popover } from "antd";
import "./styles.scss";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { BasicSelect } from "../../../../components/BasicSelect/BasicSelect";

import { selectActiveJobPositions } from "../../../../selectors/ActiveJobPositionsSelector";
import { selectUsersWithSharedBranch } from "../../../../selectors/UsersWithSharedBranchSelector";
import { selectVisibleBranches } from "../../../../selectors/VisibleBranchesSelector";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import { toMomentUnsafe, toSimpleDate } from "../../../../shared/helpers/timeHelpers";
import { ReportType, ReportFilterData } from "../../../../actions/reporting";
import { openModal } from "../../../../actions/modal";
import { ReportColumnsModal } from "../ReportColumnsModal/ReportColumnsModal";
import { featuresSelector } from "../../../../selectors/FeaturesSelector";
import { paidFeatureWarning } from "../../../../actions/paidFeatureWarning";
import { getUserName } from "../../../../shared/helpers/userHelpers";
import { UserSpecificationModal } from "../../../../components/modals/UserSpecificationModal/UserSpecificationModal";
import { setReportUserSpecification } from "../../../../reducers/ui/reportSettings/reportUserSpecification";
import moment, { Moment } from "moment";
import { selectActiveUsers } from "../../../../selectors/ActiveUserSelectors";
import { isRuleApplyingToUser } from "../../../../shared/helpers/settingsHelpers";
import cn from "classnames";
import { setReportType } from "../../../../reducers/ui/shifts/reporting/reportType";
import _ from "lodash";
import { orderAddresses, orderByName } from "../../../../frontend-core/helpers/frontendHelpers";
import { localizeFormat } from "../../../../helpers/dateFormatHelper";
import { ReportExportPopover } from "./ReportExportPopover/ReportExportPopover";
import { RoleType } from "../../../../shared/entities/IUser";
import { selectWorkSpaces } from "../../../../selectors/_workSpacesSelector";
import { selectActiveHashtags } from "../../../../selectors/ActiveHashtagsSelector";

const mapStateToProps = (state: AppState) => {
  return {
    users: state.data.users, // show all users including inactive ones for retrospective data
    labels: selectWorkSpaces(state),
    userSpecification: state.ui.reportSettings.reportUserSpecification,
    reportColumns: state.ui.reportSettings.reportColumns,
    reportType: state.ui.shifts.reporting.reportType,
    shiftAddresses: state.data.shiftAddresses,
    jobPositions: selectActiveJobPositions(state),
    hashtags: selectActiveHashtags(state),
    usersWithShardBranch: selectUsersWithSharedBranch(state),
    visibleBranches: selectVisibleBranches(state),
    sessionInfo: selectSessionInfo(state),
    features: featuresSelector(state),
    activeUsers: selectActiveUsers(state, toSimpleDate(moment())),
  };
};

type OwnProps = {
  filters: ReportFilterData;
  updateFilters: (f: ReportFilterData) => any;
  createExport: (type: "pdf" | "csv" | "excel" | "multi-pdf", options?: { userIds: string[] }) => Promise<any>;
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class ReportActionBar extends React.PureComponent<Props> {
  updateFilter = (val: Record<string, any>) => {
    if (!this.props.features.shfitReports) {
      this.props.dispatch(paidFeatureWarning());
      return;
    }
    this.props.updateFilters({
      ...this.props.filters,
      ...val,
    });
  };

  settingsButtonClicked = () => {
    if (!this.props.features.shfitReports) {
      this.props.dispatch(paidFeatureWarning());
      return;
    }
    const { dispatch, updateFilters } = this.props;
    const reloadData = () => updateFilters(this.props.filters);
    dispatch(openModal(ReportColumnsModal, { reloadData }, { maskClosable: false }));
  };

  render() {
    const {
      visibleBranches,
      users,
      usersWithShardBranch,
      sessionInfo,
      userSpecification,
      dispatch,
      reportType,
      reportColumns,
    } = this.props;

    const activeUsersWithSharedBranch = this.props.activeUsers.filter(
      (user) => sessionInfo.isAdmin() || usersWithShardBranch.includes(user.id)
    );

    const includingUsers = activeUsersWithSharedBranch
      .filter((user) => !user.isDeleted)
      .filter((u) => isRuleApplyingToUser(this.props.userSpecification, u));

    const hasManagerPermissions = sessionInfo.hasManagerPermissions();
    const todaySimpleDate = moment().format(SDateFormat);

    const selectableUsers = users
      .filter((user) => !user.isDeleted)
      .filter((user) => sessionInfo.isAdmin() || usersWithShardBranch.includes(user.id));

    const showingAllUsers = activeUsersWithSharedBranch.length === includingUsers.length;

    return (
      <div className="reportActionRowMain">
        {hasManagerPermissions && (
          <div className="row fb reportTypeRow">
            {/* <div className="rowLabel">Auswertungstyp</div>
          <div className="separatorLine"></div> */}

            <div
              className={cn({ reportTypeBtn: true, isActive: reportType === ReportType.shift })}
              onClick={() => dispatch(setReportType(ReportType.shift))}
            >
              {lg.detaillierte_auswertung}
            </div>
            <div className="separatorLine"></div>
            <div
              className={cn({ reportTypeBtn: true, isActive: reportType === ReportType.user })}
              onClick={() => dispatch(setReportType(ReportType.user))}
            >
              {lg.summierte_auswertung}
            </div>
          </div>
        )}
        <div className="row fb">
          {/* <div className="fieldWrapper" style={{ width: 140 }}>
            <BasicSelect
              id="reporting-filter-aggregation"
              options={[
                {
                  value: ReportType.shift,
                  label: "Detailliert",
                },
                {
                  value: ReportType.user,
                  label: "Summiert",
                },
              ]}
              onChange={(value: any) => this.updateFilter({ filterReportType: value })}
              value={this.props.filters.filterReportType}
            />
          </div> */}

          {this.props.reportType === ReportType.user && (
            <div
              className="userSpecificationBtn"
              onClick={() => {
                dispatch(
                  openModal(UserSpecificationModal, {
                    ruleSpecification: userSpecification,
                    onComplete: (specification) => {
                      dispatch(setReportUserSpecification(specification));
                      setTimeout(() => this.updateFilter({}));
                    },
                  })
                );
              }}
            >
              <div className="count">
                {showingAllUsers
                  ? `${includingUsers.length}`
                  : `${includingUsers.length} / ${activeUsersWithSharedBranch.length}`}
              </div>

              <div className="label">{lg.mitarbeiter}</div>
              <div className="iconWrapper">
                <Icon type="user" />
              </div>
            </div>
          )}
          {this.props.reportType === ReportType.shift && (
            <>
              {hasManagerPermissions && (
                <div className="fieldWrapper" style={{ width: 200 }}>
                  <BasicSelect
                    id="reporting-filter-user"
                    options={selectableUsers.map((u) => ({
                      //USERS_SELECT
                      value: u.id,
                      label: getUserName(u),
                    }))}
                    onChange={(v: any) => this.updateFilter({ filterUserId: v })}
                    value={this.props.filters.filterUserId}
                    placeholder={lg.mitarbeiter}
                    allowClear
                    showSearch
                  />
                </div>
              )}
              {hasManagerPermissions && (
                <div className="fieldWrapper" style={{ width: 160 }}>
                  <BasicSelect
                    id="reporting-filter-jobPos"
                    options={this.props.jobPositions.map((j) => ({
                      value: j.id,
                      label: j.name,
                    }))}
                    onChange={(v: any) => this.updateFilter({ filterJobPositionId: v })}
                    value={this.props.filters.filterJobPositionId}
                    placeholder={lg.rolle}
                    allowClear
                  />
                </div>
              )}
              {visibleBranches.length > 1 && hasManagerPermissions && (
                <div className="fieldWrapper" style={{ width: 180 }}>
                  <BasicSelect
                    id="reporting-filter-branch"
                    options={visibleBranches.map((u) => ({
                      value: u.id,
                      label: u.name,
                    }))}
                    onChange={(v: any) => this.updateFilter({ filterBranchId: v })}
                    value={this.props.filters.filterBranchId}
                    placeholder={lg.standort}
                    showSearch={visibleBranches.length > 7}
                    allowClear
                  />
                </div>
              )}
              {reportColumns.workSpaceName && (
                <div className="fieldWrapper" style={{ width: 180 }}>
                  <BasicSelect
                    id="reporting-filter-label"
                    options={this.props.labels.map((u) => ({
                      value: u.id,
                      label: u.name,
                    }))}
                    onChange={(v: any) => this.updateFilter({ filterLabelId: v })}
                    value={this.props.filters.filterLabelId}
                    placeholder={lg.label}
                    showSearch={this.props.labels.length > 7}
                    allowClear
                  />
                </div>
              )}
              {reportColumns.addressName && (
                <div className="fieldWrapper" style={{ width: 140 }}>
                  <BasicSelect
                    id="reporting-filter-address"
                    options={orderAddresses(this.props.shiftAddresses)
                      .filter((a) => !a.isInactive)
                      .map((a) => ({
                        value: a.id,
                        label: a.name || a.address,
                      }))}
                    onChange={(v: any) => this.updateFilter({ filterAddressId: v })}
                    value={this.props.filters.filterAddressId}
                    placeholder={lg.adresse}
                    showSearch={this.props.shiftAddresses.length > 7}
                    allowClear
                  />
                </div>
              )}
              {reportColumns.hashtags && (
                <div className="fieldWrapper" style={{ width: 140 }}>
                  <BasicSelect
                    id="reporting-filter-hashtags"
                    options={orderByName(this.props.hashtags).map((a) => ({ value: a.id, label: a.name }))}
                    onChange={(v: any) => this.updateFilter({ filterHashtagId: v })}
                    value={this.props.filters.filterHashtagId}
                    showSearch={this.props.hashtags.length > 7}
                    placeholder={lg.hashtag}
                    allowClear
                  />
                </div>
              )}
            </>
          )}
          <div className="fieldWrapper" style={{ width: 280 }}>
            <DatePicker.RangePicker
              allowClear={false}
              format={"L"}
              placeholder={[lg.von_start, lg.bis]}
              onChange={([startMom, endMom]) =>
                this.updateFilter({
                  filterStartDate: startMom!.format(SDateFormat),
                  filterEndDate: endMom!.format(SDateFormat),
                })
              }
              value={[
                toMomentUnsafe(this.props.filters.filterStartDate as string)!,
                toMomentUnsafe(this.props.filters.filterEndDate! as string)!,
              ]}
              disabledDate={(e) => {
                // Employees should not be able to pick dates in the future
                return hasManagerPermissions ? false : !!(e && e.format(SDateFormat) > todaySimpleDate);
              }}
            />
          </div>
          <Button
            id="reporting-toggle-cols-modal-button"
            data-rh={lg.spalten_ein_ausblenden}
            icon="layout"
            type="default"
            onClick={this.settingsButtonClicked}
            style={{ marginLeft: "auto" }}
            children={reportColumns.workSpaceName && reportColumns.addressName ? "" : lg.ansicht} // need to save space
          />
          <ReportExportPopover createExport={this.props.createExport} hasManagerPermissions={hasManagerPermissions} />
        </div>
      </div>
    );
  }
}

export default connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(ReportActionBar);
