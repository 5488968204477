import { Button, Input, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { closeModal, openModal } from "../../../../../actions/modal";
import "./styles.scss";
import { busyInjector, BusyInjectorProps } from "../../../../../components/BusyInjector/BusyInjector";
import TZModal from "../../../../../components/TZModal/TZModal";
import _ from "lodash";
import { IDailyNote } from "../../../../../shared/entities/IDailyNote";
import { useSelector } from "../../../../../helpers/redux";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { AppState } from "../../../../../types/AppState";
import AvColorPicker from "../../../../../components/AvColorPicker/AvColorPicker";
import { dailyNoteRepository } from "../../../../../repositories/dailyNoteRepository";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { selectVisibleBranches } from "../../../../../selectors/VisibleBranchesSelector";
import { featuresSelector } from "../../../../../selectors/FeaturesSelector";
import { paidFeatureWarning } from "../../../../../actions/paidFeatureWarning";
import { ClockingRequestItemV2 } from "../../../../../components/ClockingRequestItemV2/ClockingRequestItemV2";
import { ShiftPopup } from "../../../../../components/ShiftPopup/ShiftPopup/ShiftPopup";
import { selectClockingRequestsV2 } from "../../../../../selectors/clockingRequestSelectorV2";
import { selectShiftMap } from "../../../../../selectors/shiftMapSelector";

const mapStateToProps = (state: AppState) => {
  return {
    clockingRequests: selectClockingRequestsV2(state),
    selectedBranch: state.ui.selectedBranch,
    sessionInfo: selectSessionInfo(state),
    shiftMap: selectShiftMap(state),
  };
};

type OwnProps = {};

type State = {};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class _UnapprovedClockingsModal extends React.PureComponent<Props, State> {
  state: State;

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  close = () => {
    this.props.dispatch(closeModal());
  };

  openShiftModal = async (clockingId: string) => {
    this.setState({ popoverIsVisible: false });
    const shift = this.props.shiftMap[clockingId];
    this.props.dispatch(openModal(ShiftPopup, { shift }));
  };

  render() {
    const { clockingRequests } = this.props;
    const count = clockingRequests.length;

    return (
      <TZModal>
        <TZModal.Head>{lg.unbestätigte_zeiterfassungen}</TZModal.Head>
        <TZModal.Body>
          <div className="unapprovedClockingsModalMain">
            <div>
              {_.sortBy(clockingRequests, (c) => c.date + c.startTime)
                .reverse()
                .map((clocking, i) => {
                  return (
                    <ClockingRequestItemV2
                      clocking={clocking}
                      key={clocking.id}
                      onClick={(id) => this.openShiftModal(id)}
                      isLastItem={i === count - 1}
                    />
                  );
                })}
            </div>
          </div>
        </TZModal.Body>
        {/* <TZModal.Footer>
          <Button id="daily-note-save" type="primary" children={lg.Speichern} onClick={() => {}} />
        </TZModal.Footer> */}
      </TZModal>
    );
  }
}

export const UnapprovedClockingsModal = connect(mapStateToProps)(busyInjector(_UnapprovedClockingsModal));
