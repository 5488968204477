import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import { AbsenceTypeCode } from "../shared/entities/IAbsenceType";

// This selector is needed because of backwards compatibility.
// 'absenceTypeId' was added to contingentCorrections > we set the default to the absence-type-id
export const selectContingentCorrections = createSelector(
  [
    (state: AppState) => state.data._contingentCorrections,
    (state: AppState) => state.data.absenceTypes,
    (state: AppState) => state.ui.absences.selectedTypeId,
  ],
  (contingentCorrections, absenceTypes, _selectedTypeId) => {
    const vacationType = absenceTypes.find((a) => a.code === AbsenceTypeCode.vacation);
    const selectedTypeId = _selectedTypeId || vacationType?.id; // default is vacation-type-id

    return contingentCorrections
      .map((a) => ({ ...a, absenceTypeId: a.absenceTypeId || vacationType?.id }))
      .filter((a) => a.absenceTypeId === selectedTypeId);
  }
);
