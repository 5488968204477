import React from "react";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";
import "./styles.scss";
import { AppState } from "../../../../../types/AppState";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { Button, Icon } from "antd";
import cn from "classnames";
import { BasicWorkSpace } from "../BranchPopup";
import WorkSpaceForm from "./workspaceForm/WorkSpaceForm";
import { IWorkSpace } from "../../../../../shared/entities/IWorkSpace";
import { workSpaceRepository } from "../../../../../repositories/workSpaceRepository";
import moment from "moment";
import _ from "lodash";
import { pastelColors } from "../../../../../frontend-core/helpers/colors";
import { SettingsListCollapser } from "../../../rosterSettings/SettingsListCollapser/SettingsListCollapser";
import { selectWorkSpaces } from "../../../../../selectors/_workSpacesSelector";

const mapStateToProps = (state: AppState, props: OwnProps) => {
  return {
    workSpaces: selectWorkSpaces(state),
    isV2: state.data.tenantInfo.isV2,
  };
};

type State = {
  editingId: string;
  editingName: string;
  editingColor: string;
  isCollapsed?: boolean;
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class WorkSpaceEditor extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editingId: "",
      editingName: "",
      editingColor: "",
      isCollapsed: false,
    };
  }

  selectWorkspace = (workSpaceId: string) => {
    const ws = this.props.workSpaces.find((ws) => ws.id === workSpaceId)!;
    this.setState({
      editingId: ws.id,
      editingName: ws.name,
      editingColor: ws.color,
    });
  };

  clearState = () => {
    this.setState({
      editingId: "",
      editingName: "",
      editingColor: "",
    });
  };

  updateWorkSpace = (ws: IWorkSpace) => {
    this.setState({
      editingId: ws.id,
      editingName: ws.name,
      editingColor: ws.color,
    });
  };

  addWorkSpace = () => {
    // need to use timeout because of strange behaviour in WorkSpaceForm Component
    // the document.clickListener of it gets triggered ( used to deselect the form )
    setTimeout(() => {
      this.setState({
        editingId: uuid(),
        editingName: "",
        editingColor: _.sample(pastelColors)!,
      });
    });
  };

  saveWorkSpace = async () => {
    const prevWorkSpace = this.props.workSpaces.find((ws) => ws.id === this.state.editingId);

    const freshWorkSpace = {
      ...(prevWorkSpace || {}),
      id: this.state.editingId,
      name: this.state.editingName || "-",
      color: this.state.editingColor,
    };

    const isCreation = !prevWorkSpace;
    await this.props.dispatch(
      isCreation
        ? workSpaceRepository.create({ ...freshWorkSpace, createdAt: moment().unix() })
        : workSpaceRepository.update(freshWorkSpace)
    );
    this.clearState();
  };

  removeWorkSpace = () => {
    const id = this.state.editingId;
    const ws = this.props.workSpaces.find((ws) => ws.id === id);
    ws && this.props.dispatch(workSpaceRepository.update({ ...ws, isInactive: true }));
    this.clearState();
  };

  render() {
    const { workSpaces, isV2 } = this.props;
    const { isCollapsed } = this.state;
    const isCreating = this.state.editingId && !workSpaces.find((ws) => ws.id === this.state.editingId);
    const editableWorkspaces = this.props.workSpaces.filter((ws) => !ws.isInactive);
    const count = editableWorkspaces.length;

    const editingWorkSpance = {
      id: this.state.editingId,
      name: this.state.editingName,
      color: this.state.editingColor,
    };

    return (
      <>
        <div className={cn({ workSpaceEditorMain: true, isEditing: !!this.state.editingId })}>
          <div className="fb title">{lg.Labels}</div>
          <div className="description">
            {isV2
              ? lg.labels_können_an_zeiterfassungen_angehängt_werden
              : lg.mit_labels_können_schichten_im_dienstplan_zusätzlich_gekennzeichnet_werden}
          </div>
          <SettingsListCollapser
            isCollapsed={isCollapsed}
            onCollapsedChange={(isCollapsed) => this.setState({ isCollapsed })}
            count={count}
            label={"Labels"}
          />
          {!isCollapsed && (
            <div className={cn({ workSpaceContentWrapper: true })}>
              {_.sortBy(editableWorkspaces, (ws) => ws.name.toLowerCase()).map((wS: BasicWorkSpace | IWorkSpace) =>
                this.state.editingId !== wS.id ? (
                  <div key={wS.id} className="workSpace" onClick={() => this.selectWorkspace(wS.id)}>
                    <div className="workSpaceColor" style={{ background: wS.color }}></div>
                    {wS.name}
                  </div>
                ) : (
                  <WorkSpaceForm
                    key={wS.id}
                    workSpace={editingWorkSpance}
                    removeWorkSpace={this.removeWorkSpace}
                    saveWorkSpace={this.saveWorkSpace}
                    updateWorkSpace={this.updateWorkSpace}
                    unfocus={this.clearState}
                  />
                )
              )}
              {isCreating && (
                <WorkSpaceForm
                  key={this.state.editingId}
                  workSpace={editingWorkSpance}
                  isCreating={isCreating}
                  removeWorkSpace={this.removeWorkSpace}
                  saveWorkSpace={this.saveWorkSpace}
                  updateWorkSpace={this.updateWorkSpace}
                  unfocus={this.clearState}
                />
              )}
            </div>
          )}
          {!isCollapsed && (
            <div className="addWorkspaceButtonWrapper">
              <Button
                id="add-workspace"
                icon="plus"
                children={lg.labels_hinzufügen}
                disabled={!!this.state.editingId}
                onClick={this.addWorkSpace}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(WorkSpaceEditor);
