import React from "react";
import cn from "classnames";
import "./styles.scss";

export enum UserTab {
  general = "general",
  workingTimes = "workingTimes",
  userInfo = "userInfo",
  documents = "documents",
}

type OwnProps = {
  changeTab: (tab: UserTab) => void;
  selectedTab: UserTab;
};

type Props = OwnProps;

export class UserTabBar extends React.PureComponent<Props> {
  render() {
    const tabs = [
      {
        tabKey: UserTab.general,
        label: lg.allgemein,
      },
      {
        tabKey: UserTab.workingTimes,
        label: lg.arbeitszeit__vertrag,
      },
      {
        tabKey: UserTab.userInfo,
        label: lg.stammdaten,
      },
      {
        tabKey: UserTab.documents,
        label: lg.dokumente,
      },
    ];

    return (
      <div className="userTabBarMain">
        {tabs.map((tab) => (
          <div
            key={tab.tabKey}
            id={"user-modal-tab-" + tab.tabKey}
            className={cn({ active: this.props.selectedTab === tab.tabKey }) + " tabBarItem"}
            onClick={() => tab.tabKey !== this.props.selectedTab && this.props.changeTab(tab.tabKey)}
          >
            {tab.label}
          </div>
        ))}
      </div>
    );
  }
}
