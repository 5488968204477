import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useSelector } from "../../../../helpers/redux";
import "./styles.scss";
import { Button, Checkbox, Icon, Input, InputNumber } from "antd";
import TZModal from "../../../../components/TZModal/TZModal";
import { LocationSearchInput } from "../../branches/branchPopup/LocationSearchInput";
import { IShiftAddress } from "../../../../shared/entities/IShiftAddress";
import { v4 as uuid } from "uuid";
import AvColorPicker from "../../../../components/AvColorPicker/AvColorPicker";
import { pastelColors, strongColors } from "../../../../frontend-core/helpers/colors";
import _ from "lodash";
import AvFormField from "../../../../components/AvFormField/AvFormField";
import { shiftAddressRepository } from "../../../../repositories/shiftAddressRepository";
import { closeModal, openModal } from "../../../../actions/modal";
import { busyInjector, BusyInjectorProps } from "../../../../components/BusyInjector/BusyInjector";
import { ShiftAddressModal } from "./ShiftAddressModal/ShiftAddressModal";
import { orderAddresses } from "../../../../frontend-core/helpers/frontendHelpers";
import { SettingsListCollapser } from "../SettingsListCollapser/SettingsListCollapser";

type Props = {};

export const AddressesSection = busyInjector((props: Props & BusyInjectorProps) => {
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState<boolean>();
  const shiftAddresses = useSelector((s) => s.data.shiftAddresses);
  const rosterSetting = useSelector((s) => s.data.rosterSettings[0]);
  const isActive = rosterSetting.shiftAddressesActive;
  const isV2 = useSelector((s) => s.data.tenantInfo.isV2);

  const addressClicked = useCallback((shiftAddress?: IShiftAddress) => {
    dispatch(openModal(ShiftAddressModal, { shiftAddress }));
  }, []);

  const activeShiftAddresses = orderAddresses(shiftAddresses).filter((a) => !a.isInactive);
  const count = activeShiftAddresses.length;

  return (
    <div className="addressSectionMain">
      <div className="fb title">{lg.Adressen}</div>
      <div className="titleDescription">
        {isV2
          ? lg.per_geofencing_kann_an_disen_adressen_mobil_gestempelt_werden
          : lg.diese_adressen_können_schichten_im_dienstplan_zugewiesen_werden}
      </div>
      {!isActive && (
        <div className="notActiveBox">
          <Icon type="caret-right" />
          {lg.diese_funktion_ist_in_den_einstellungen_nicht_aktivert}
        </div>
      )}
      {isActive && (
        <SettingsListCollapser
          isCollapsed={isCollapsed}
          onCollapsedChange={(isCollapsed) => setIsCollapsed(isCollapsed)}
          count={count}
          label={"Adressen"}
        />
      )}
      {isActive && !isCollapsed && (
        <div className="content">
          <div className="listContainer">
            {activeShiftAddresses.map((shiftAddress) => (
              <div className="addressListElement" onClick={() => addressClicked(shiftAddress)} key={shiftAddress.id}>
                <div className="colorRect" style={{ background: shiftAddress.color }}></div>
                <div className="text">{shiftAddress.name || shiftAddress.address}</div>
                <Icon type="environment" className="locationIcon" style={{}} />
              </div>
            ))}
          </div>
          <div className="addButtonWrapper">
            <Button id="add-workspace" icon="plus" children={lg.adresse_hinzufügen} onClick={() => addressClicked()} />
          </div>
        </div>
      )}
    </div>
  );
});
