import { getAbsenceFanOutUpdates } from "./../shared/helpers/absenceFanOut";
import { AppState } from "./../types/AppState";
import { DispFn } from "./../frontend-core/types/thunkTypes";
import { CrudOperation, Operation, nullifyProps } from "./../shared/helpers/firebaseHelpers";
import firebase from "firebase/compat/app";
import "firebase/database";
import { IAbsence } from "./../shared/entities/IAbsence";
import { BaseRepository } from "../frontend-core/BaseRepository";
import { selectAbsenceMap } from "../selectors/mapSelectors";
import _ from "lodash";
import { sendPost } from "../frontend-core/actions/send";
import {
  getCalenderNeedsSyncUpdate,
  getSimpleDateToday,
  isUserActiveOnDate,
} from "../frontend-core/helpers/frontendHelpers";

class AbsenceRepository extends BaseRepository<IAbsence> {
  constructor() {
    super("absences");
  }

  toDbEntity = (absence: IAbsence): IAbsence =>
    nullifyProps({
      ...absence,
      effectiveDays: null, // after a renaming of effectiveDays -> effectiveDaysOverride // to avoid a migration. Remove this after migration
    } as any);

  toLocalEntity = (s: IAbsence): IAbsence => {
    return _.omit(s, ["effectiveDays"]) as any; // after a renaming of effectiveDays -> effectiveDaysOverride // to avoid a migration. Remove this after migration
  };

  getExtraUpdates = (actions: CrudOperation<IAbsence>[]) => {
    return (dispatch: DispFn, getState: () => AppState) => {
      const absencesById = selectAbsenceMap(getState());
      return getAbsenceFanOutUpdates(actions, absencesById);
    };
  };

  preUpdateHook(writes: CrudOperation<IAbsence>[]) {
    return async (dispatch: DispFn, getState: () => AppState) => {
      const iCalUsers = getState().data.users.filter((u) => u.hasICalSyncing);
      const iCalUsersMap = _.keyBy(iCalUsers, "id");
      if (!iCalUsers.length) {
        return;
      }
      const absenceMap = selectAbsenceMap(getState());
      const absences = writes.map(
        (write) => (write.operation === Operation.remove ? absenceMap[write.entity.id] : write.entity) // neccesarry because in case fo remove the entitys are hollow  like this: ( { id: '1234' })
      );
      const today = getSimpleDateToday();
      const absencesOfICalUsers = absences.filter((a) => a && a.userId && iCalUsersMap[a.userId]);
      const absentICalUsers = absencesOfICalUsers.map((a) => iCalUsersMap[a.userId]);
      const activeAbsentICalUsers = absentICalUsers.filter((u) => isUserActiveOnDate(u, today));
      const affectedUserIds = _.uniq(activeAbsentICalUsers.map((s) => s.id));

      if (affectedUserIds.length) {
        const tenantId = getState().data.auth.session!.tenantId;
        const calendarUpdates = getCalenderNeedsSyncUpdate(tenantId, affectedUserIds);
        firebase.database().ref().update(calendarUpdates);
      }
    };
  }
}

export const absenceRepository = new AbsenceRepository();
