import { Button, Input, notification } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { busyInjector, BusyInjectorProps } from "../../BusyInjector/BusyInjector";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { HintModal } from "../HintModal/HintModal";
import { useSelector } from "../../../helpers/redux";
import "./styles.scss";
import { updateHinting } from "../../../actions/hinting";
import { closeModal } from "../../../actions/modal";
import dragAndDropUrl from "./dragAndDrop.gif";
export const DragAndDropHintModal = busyInjector((props: {} & BusyInjectorProps) => {
  const dispatch = useDispatch();
  const submit = async () => {
    dispatch(updateHinting({ hasCreatedAbsenceByPulling: true }));
    dispatch(closeModal());
  };

  return (
    <HintModal title="Drag & Drop" onConfirm={submit}>
      <div className="dragAndDropHintMain">
        <div className="text">
          {lg.tipp_mit_der_drag_drop_funktion_lassen_sich_dienstpläne_in_kürzester_zeit_erstellen}
        </div>
        <div className="gifWrapper">
          <img className="gif" style={{ width: 460 }} src={dragAndDropUrl} alt="loading..." />
        </div>
      </div>
    </HintModal>
  );
});
