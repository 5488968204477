import React from "react";
import "./styles.scss";
import { Icon } from "antd";
import cn from "classnames";

type Props = {
  label: string;
  isFilterVisible: boolean;
  toggleFilter: (isVisible: boolean) => void;
};

class _UserHeadCell extends React.PureComponent<Props> {
  render() {
    const { isFilterVisible, toggleFilter, label } = this.props;

    return (
      <div className={cn({ userHeadCellMain: true, isFilterVisible })}>
        <div className="text">{label}</div>
        <div
          className="filterIconWrapper"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleFilter(!isFilterVisible);
          }}
        >
          <Icon type="filter" />
        </div>
      </div>
    );
  }
}

export const UserHeadCell = _UserHeadCell;
