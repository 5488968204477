import React from "react";
import "./styles.scss";

import { IAbsenceType } from "../../../../shared/entities/IAbsenceType";
import { Select } from "antd";

type Props = {
  absenceTypes: IAbsenceType[];
  selectType: (absenceTypeId: string) => void;
  selectedTypeId?: string;
  readOnly?: boolean;
  isRequest?: boolean;
  canMangaAbsences: boolean;
  withOptionAllTypes?: boolean;
  placeholder?: string;
};

const AbsenceTypeSelect = (props: Props) => {
  const { selectedTypeId, selectType, absenceTypes, readOnly, withOptionAllTypes, placeholder } = props;

  const selectableAbsenceTypes = props.canMangaAbsences
    ? absenceTypes
    : absenceTypes.filter((a) => a.canBeRequested || a.id === selectedTypeId);

  const options = selectableAbsenceTypes.map((a) => ({
    value: a.id,
    color: a.color,
    name: a.name,
  }));

  if (withOptionAllTypes) {
    options.push({
      value: undefined as any,
      color: "gray",
      name: lg.Alle,
    });
  }

  if (!readOnly) {
    return (
      <div className="absenceTypeSelectMain">
        <Select
          id="absence-type-select"
          value={selectedTypeId}
          onChange={selectType}
          style={{ width: "100%" }}
          placeholder={placeholder || lg.abwesenheitstyp}
        >
          {options.map((o) => (
            <Select.Option value={o.value} key={o.value || "x"}>
              <div className="row fb aCenter">
                <div
                  style={{
                    background: o.color,
                    height: 20,
                    width: 20,
                    borderRadius: 5,
                    marginRight: 12,
                    flexShrink: 0,
                  }}
                />
                {o.name}
              </div>
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  } else {
    const absenceType = absenceTypes.find((a) => a.id === selectedTypeId)!;
    return (
      <div className="readOnlyTypeWrapper">
        <div className="absenceTypeBadge" style={{ background: absenceType.color }}>
          {absenceType.name}
        </div>
      </div>
    );
  }
};

export default AbsenceTypeSelect;
