import React from 'react';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { AppState } from '../../../types/AppState';
import { DispatchBaseProps } from '../../../frontend-core/types/DispatchBaseProps';
import { DatePicker } from 'antd';
import './styles.scss';

const mapStateToProps = (state: AppState) => {
  return {};
};

type State = {
  activateDebugDate?: boolean;
  debugDate: Moment;
};
type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _DebugDateChanger extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      debugDate: moment(),
    };

    this.resetMomentToDefault();
  }

  changeDebugDate = (m: Moment | null) => {
    if (!m) {
      return;
    }
    moment.now = function () {
      return +m.toDate();
    };

    this.setState({ debugDate: m });
  };

  resetMomentToDefault = () => {
    moment.now = function () {
      return +new Date();
    };
  };

  onDebugCheckboxChange = (e: CheckboxChangeEvent) => {
    this.setState({ activateDebugDate: e.target.checked });
    if (!e.target.checked) {
      this.resetMomentToDefault();
      this.setState({ debugDate: moment() });
    }
  };

  render() {
    return (
      <div className="debugDateChanger">
        <b style={{ marginBottom: 12, textAlign: 'center', opacity: '#ffffff59' }}>
          DEBUG ONLY
        </b>
        <Checkbox
          children="Change Default Date"
          checked={this.state.activateDebugDate}
          onChange={this.onDebugCheckboxChange}
        />
        <DatePicker
          onChange={this.changeDebugDate}
          format="DD. MMM YYYY"
          disabled={!this.state.activateDebugDate}
          value={this.state.debugDate}
        />
      </div>
    );
  }
}

export const DebugDateChanger = connect<StoreProps, {}, OwnProps, AppState>(
  mapStateToProps
)(_DebugDateChanger);
