import { withRosterLoading } from "./rosterReducerHelpers";
import { DispFn } from "./../../../../frontend-core/types/thunkTypes";
import { toSimpleDate } from "../../../../shared/helpers/timeHelpers";
import moment from "moment";

const SHIFTS_ROSTER_DAY_SELECT = "SHIFTS_ROSTER_DAY_SELECT";

export const selectDay = (date: string) =>
  withRosterLoading({
    type: SHIFTS_ROSTER_DAY_SELECT,
    payload: date,
  });

const initialState = toSimpleDate(moment());

export const selectedDayReducer = (state = initialState, a: any): string =>
  a.type === SHIFTS_ROSTER_DAY_SELECT ? a.payload : state;
