import React from "react";
import { connect, batch } from "react-redux";
import { AppState } from "../../../../../types/AppState";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { Button, Modal, Icon, Checkbox } from "antd";
import { openModal } from "../../../../../actions/modal";
import { RosterMode } from "../../../../../reducers/ui/shifts/roster/rosterMode";
import {
  selectCurrentPublishedBranches,
  selectIsWeekPlanPublished,
} from "../../../../../selectors/WeekPlanPublishedSelector";
import { unpublishWeekPlan, unpublishWeekPlans } from "../../../../../actions/weekPlan";
import { PublishWeekModal } from "../../../../../components/modals/PublishWeekModal/PublishWeekModal";
import cn from "classnames";
import "./styles.scss";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { selectVisibleBranches } from "../../../../../selectors/VisibleBranchesSelector";
import moment from "moment";
import { SDateFormat } from "../../../../../shared/helpers/SimpleTime";
import { selectUserFullMap } from "../../../../../selectors/userFullMapSelector";
import { setOnlyShowActiveClockings } from "../../../../../actions/onlyShowActiveClockings";

const mapStateToProps = (state: AppState) => {
  return {
    onlyShowActiveClockings: state.ui.shifts.roster.onlyShowActiveClockings,
    clockings: state.data.timeClockings,
    userFullMap: selectUserFullMap(state),
  };
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class CurrentlyClockedInBox_ extends React.PureComponent<Props> {
  yesterday: string;
  constructor(props: Props) {
    super(props);

    this.yesterday = moment().add(-1, "days").format(SDateFormat);
  }

  render() {
    const { dispatch, clockings, userFullMap, onlyShowActiveClockings } = this.props;
    const activeClockings = clockings.filter((c) => c.date >= this.yesterday && !c.endTime && !c.isAutoClockOut);
    const count = activeClockings.length;

    let tooltip = "";
    activeClockings.forEach((c) => (tooltip += userFullMap[c.userId].name + "\n"));

    return (
      <div
        style={{ marginLeft: 12 }}
        className={cn({
          currentlyClockedInBoxMain: true,
          isSomeoneClockedIn: !!count,
        })}
        data-rh={tooltip || null}
        data-rh-at="bottom"
        // onClick={() => {
        //   dispatch(setOnlyShowActiveClockings(!onlyShowActiveClockings));
        // }}
      >
        {/* <Checkbox checked={!!onlyShowActiveClockings} onChange={(e) => {}} /> */}
        <div className="label">{"Zurzeit eingestempelt"}</div>
        <div className="count">{count}</div>
      </div>
    );
  }
}

export const CurrentlyClockedInBox = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(
  CurrentlyClockedInBox_
);
