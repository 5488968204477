import React from "react";
import { Icon } from "antd";

import { generateOverlappingTooltip } from "../../../helpers/general";
import "./styles.scss";
import { IShiftOverlap } from "../../../shared/entities/IShiftOverlap";
import { IUser } from "../../../shared/entities/IUser";

type Props = {
  overlap?: IShiftOverlap;
  isUserUnavailable?: boolean;
  isUserDeactivated?: boolean;
  isNoShow?: boolean;
  user?: IUser;
  labourLawWarning?: string;
};

export class ShiftWarningIcon extends React.PureComponent<Props> {
  render() {
    const { overlap, isUserUnavailable, isUserDeactivated, isNoShow, labourLawWarning } = this.props;

    let warning = "";

    if (overlap) {
      warning = generateOverlappingTooltip(overlap);
    }
    if (isUserUnavailable) {
      warning = lg.nicht_verfügbar_während_dieser_schicht;
    }
    if (isUserDeactivated) {
      warning = lg.mitarbeiter_ist_deaktiviert;
    }
    if (isNoShow) {
      warning = lg.schicht_nicht_gestempelt;
    }

    if (labourLawWarning) {
      warning = labourLawWarning;
    }

    if (!warning) {
      return null;
    }

    return (
      <div className="shiftWarningIconMain shiftIcon" data-rh-at="bottom" data-rh={warning}>
        <Icon type="exclamation" />
      </div>
    );
  }
}
