import React, { PureComponent } from "react";
import "./styles.scss";
import { Button, Icon } from "antd";

type Props = {
  year: number;
  onChange: (y: number) => void;
};

export class YearStepper extends PureComponent<Props> {
  render() {
    const { year, onChange } = this.props;

    return (
      <div className="yearStepperMain">
        <Button.Group>
          <Button id="year-stepper-left" type="primary" onClick={() => onChange(year - 1)}>
            <Icon type="left" />
          </Button>
          <Button type="primary" className="noButon">
            {year}
          </Button>
          <Button id="year-stepper-right" type="primary" onClick={() => onChange(year + 1)}>
            <Icon type="right" />
          </Button>
        </Button.Group>
      </div>
    );
  }
}
