import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { ThunkDispatch } from "redux-thunk";
import { Button, DatePicker } from "antd";
import { RangePickerValue } from "antd/lib/date-picker/interface";
import { BasicSelect } from "../../../../components/BasicSelect/BasicSelect";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { selectActiveJobPositions } from "../../../../selectors/ActiveJobPositionsSelector";
import { selectUsersWithSharedBranch } from "../../../../selectors/UsersWithSharedBranchSelector";
import { selectVisibleBranches } from "../../../../selectors/VisibleBranchesSelector";
import { AbsenceStatus } from "../../../../shared/entities/IAbsence";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import { getUserName } from "../../../../shared/helpers/userHelpers";
import moment from "moment";
import "./styles.scss";
import { featuresSelector } from "../../../../selectors/FeaturesSelector";
import { paidFeatureWarning } from "../../../../actions/paidFeatureWarning";
import { AbsenceCalendarPageState } from "../../helprs";
import cn from "classnames";
import { push } from "connected-react-router";
import { ISitemapEntry, sitemap } from "../../../../helpers/sitemap";
import { RouteComponentProps, withRouter } from "react-router-dom";

const AbsenceStatusOptions = [
  { value: AbsenceStatus.active, label: lg.aktiv },
  { value: AbsenceStatus.requested, label: lg.beantragt },
];

const mapStateToProps = (state: AppState) => ({
  jobPositions: selectActiveJobPositions(state),
  absenceTypes: state.data.absenceTypes,
  users: state.data.users,
  canManaga: selectSessionInfo(state).hasManagerPermissions(),
  usersWithSharedBranch: selectUsersWithSharedBranch(state),
  sessionInfo: selectSessionInfo(state),
  visibleBranches: selectVisibleBranches(state),
  features: featuresSelector(state),
});

type OwnProps = {
  settings: AbsenceCalendarPageState;
  updateSettings: (settings: Partial<AbsenceCalendarPageState>) => void;
  exportPdf: () => void;
  exportCsv: () => void;
  exportExcel: () => void;
  type: "detailed" | "summed";
} & RouteComponentProps;

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & { dispatch: ThunkDispatch<{}, {}, any> };

class AbsenceListPageActionBar extends React.PureComponent<Props, {}> {
  onFilterDatesChange = ([startDateMom, endDateMom]: RangePickerValue) => {
    if (!startDateMom || !endDateMom) {
      return;
    }
    const startDate = startDateMom.format(SDateFormat);
    const endDate = endDateMom.format(SDateFormat);
    this.props.updateSettings({ startDate, endDate });
  };

  onTypeChange = (typeId: string) => {
    this.props.updateSettings({ selectedTypeId: typeId });
  };

  getUserOptions = () => {
    const { branchFilter, jobPositionFilter } = this.props.settings;
    const isAdmin = this.props.sessionInfo.isAdmin();

    const users = this.props.users.filter((user) => !user.isDeleted);

    return users
      .filter((u) => !branchFilter || u.branchIds.includes(branchFilter))
      .filter((u) => !jobPositionFilter || u.jobPositionIds.includes(jobPositionFilter))
      .filter((u) => isAdmin || this.props.usersWithSharedBranch.includes(u.id))
      .map((u) => ({ value: u.id, label: getUserName(u) }));
  };

  isRouteActive = (route: ISitemapEntry) => {
    return this.props.location.pathname.includes(route.url);
  };

  render() {
    const {
      updateSettings,
      jobPositions,
      absenceTypes,
      canManaga,
      visibleBranches,
      sessionInfo,
      dispatch,
      type,
    } = this.props;

    const {
      startDate,
      endDate,
      selectedTypeId,
      branchFilter,
      jobPositionFilter,
      userFilter,
      statusFilter,
    } = this.props.settings;

    const startMom = moment(startDate as string, SDateFormat);
    const endMom = moment(endDate as string, SDateFormat);
    const hasManagerPermissions = sessionInfo.hasManagerPermissions();

    return (
      <div className="absenceListPageActionBar">
        {hasManagerPermissions && (
          <div className="row fb reportTypeRow">
            <div
              className={cn({ reportTypeBtn: true, isActive: this.isRouteActive(sitemap.absenceList) })}
              onClick={() => dispatch(push(sitemap.absenceList.url))}
            >
              {lg.detailliert}
            </div>
            <div className="separatorLine"></div>
            <div
              className={cn({ reportTypeBtn: true, isActive: this.isRouteActive(sitemap.absenceTable) })}
              onClick={() => dispatch(push(sitemap.absenceTable.url))}
            >
              {lg.summierte_tage}
            </div>
          </div>
        )}
        <div className="fb row">
          {type === "detailed" && (
            <div className="typeFilterWrapper filterWrapper">
              <div className="selectWrapper typeSelectWrapper">
                <BasicSelect
                  id="absence-list-types-select"
                  value={selectedTypeId}
                  onChange={this.onTypeChange}
                  options={absenceTypes.map((type) => ({ value: type.id, label: type.name }))}
                  placeholder={lg.alle_typen}
                  allowClear
                />
              </div>
            </div>
          )}
          <div className="dateFilter filterWrapper dateFilterWrapper">
            <div className="selectWrapper dateSelectWrapper">
              <DatePicker.RangePicker
                value={[startMom, endMom] as any}
                onChange={this.onFilterDatesChange}
                format="DD. MMM YYYY"
                allowClear={false}
              />
            </div>
          </div>
          {canManaga && visibleBranches.length > 1 && (
            <div className="branchFilter filterWrapper">
              <div className="selectWrapper branchSelectWrapper">
                <BasicSelect
                  id="absence-list-branch-select"
                  value={branchFilter}
                  onChange={(id: string) => {
                    updateSettings({ branchFilter: id });
                  }}
                  options={visibleBranches.map((o) => ({ label: o.name, value: o.id }))}
                  placeholder={lg.standort}
                  allowClear
                />
              </div>
            </div>
          )}
          {canManaga && (
            <div className="positionFilter filterWrapper">
              <div className="selectWrapper positionSelectWrapper">
                <BasicSelect
                  id="absence-list-jobPos-select"
                  showSearch
                  value={jobPositionFilter}
                  onChange={(id: string) => {
                    updateSettings({ jobPositionFilter: id });
                  }}
                  options={jobPositions.map((o) => ({
                    label: o.name,
                    value: o.id,
                  }))}
                  placeholder={lg.rolle}
                  allowClear
                />
              </div>
            </div>
          )}
          {canManaga && type === "detailed" && (
            <div className="userFilter filterWrapper">
              <div className="selectWrapper userSelectWrapper">
                <BasicSelect
                  id="absence-list-user-select"
                  showSearch
                  value={userFilter}
                  onChange={(id: string) => {
                    updateSettings({ userFilter: id });
                  }}
                  options={this.getUserOptions()} //USERS_SELECT
                  placeholder={lg.mitarbeiter}
                  allowClear
                />
              </div>
            </div>
          )}
          {type === "detailed" && (
            <div className="statusFilterWrapper filterWrapper">
              <div className="selectWrapper statusSelectWrapper">
                <BasicSelect
                  id="absence-list-status-select"
                  value={statusFilter}
                  onChange={(statusFilter: AbsenceStatus) => {
                    updateSettings({ statusFilter });
                  }}
                  options={AbsenceStatusOptions}
                  placeholder={lg.Status}
                  allowClear
                />
              </div>
            </div>
          )}
          {canManaga && (
            <Button
              id="absence-list-export-pdf-button"
              icon="printer"
              children="pdf"
              type="default"
              style={{ marginLeft: "auto" }}
              onClick={() => {
                this.props.features.dataExports ? this.props.exportPdf() : this.props.dispatch(paidFeatureWarning());
              }}
            />
          )}
          {canManaga && type === "summed" && (
            <Button
              id="absence-list-export-excel-button"
              icon="file-excel"
              children="excel"
              type="default"
              style={{ marginLeft: 8 }}
              onClick={() => {
                this.props.features.dataExports ? this.props.exportExcel() : this.props.dispatch(paidFeatureWarning());
              }}
            />
          )}
          {canManaga && type === "summed" && (
            <Button
              id="absence-list-export-csv-button"
              icon="file-excel"
              children="csv"
              type="default"
              style={{ marginLeft: 8 }}
              onClick={() => {
                this.props.features.dataExports ? this.props.exportCsv() : this.props.dispatch(paidFeatureWarning());
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(connect<StoreProps, {}, {}, AppState>(mapStateToProps)(AbsenceListPageActionBar));
