export const setRequiredShiftsCollapsed = (isCollapsed: Boolean) => ({
  type: ROSTER_IS_REQUIRED_SHIFTS_COLLAPSED,
  payload: isCollapsed,
});

const ROSTER_IS_REQUIRED_SHIFTS_COLLAPSED = "ROSTER_IS_REQUIRED_SHIFTS_COLLAPSED";

const initialState = false;

export const isRequiredShiftsCollapsedReducer = (state: boolean = initialState, action: any): boolean => {
  return action.type === ROSTER_IS_REQUIRED_SHIFTS_COLLAPSED ? action.payload : state;
};
