import React from "react";

import { useDispatch } from "react-redux";
import { useState } from "react";
import TZModal from "../TZModal/TZModal";

import { Input, Button } from "antd";
import { closeModal } from "../../actions/modal";
import { useSelector } from "../../helpers/redux";
import { deleteAllShiftsOfWeek } from "../../actions/shift";
import { simpleDateToMoment } from "../../shared/helpers/timeHelpers";
import { sendPost } from "../../frontend-core/actions/send";
import { DispFn } from "../../frontend-core/types/thunkTypes";

type Props = {};

export const DeleteTenantModal = (props: Props) => {
  const dispatch = useDispatch<DispFn>();
  const [deleteReason, setDeleteReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSure, setIsSure] = useState(false);

  return (
    <TZModal>
      <TZModal.Head>{lg.unternehmen_unwiderruflich_löschen}</TZModal.Head>
      <TZModal.Body>
        <TZModal.Row className="noPadding">
          <div>
            <div style={{ marginBottom: 24, color: "red", maxWidth: 470 }}>
              {
                lg.hiermit_wird_der_zugang_endgültig_für_ihr_unternehmen_gelöscht_kein_mitarbeiter_wird_sich_mehr_einloggen_können_alle_daten_werden_unwiderruflich_gelöscht
              }
            </div>
            <div style={{ margin: "10px 0 0 0", fontWeight: 900 }}>
              {lg.bitte_erzählen_sie_uns_welche_funktionen_ihnen_gefehlt_haben}{" "}
            </div>
            <Input.TextArea
              style={{ margin: "16px 0", height: 100 }}
              value={deleteReason}
              placeholder={lg.was_hat_ihnen_gefehlt}
              onChange={(e) => setDeleteReason(e.target.value)}
            />
          </div>
        </TZModal.Row>
      </TZModal.Body>
      <TZModal.Footer>
        <Button
          id="cancel-delete-my-tenant"
          onClick={async () => {
            dispatch(closeModal());
          }}
          children={lg.abbrechen}
        />
        {!isSure && (
          <Button
            id="delete-week"
            type="danger"
            onClick={async () => {
              setIsSure(true);
            }}
            style={{ marginLeft: "auto" }}
            disabled={deleteReason.length <= 5}
            children={lg.zugang_löschen}
          />
        )}
        {isSure && (
          <Button
            id="delete-week"
            type="danger"
            onClick={async () => {
              setIsLoading(true);
              await dispatch(sendPost("/api/delete-my-tenant", { reason: deleteReason }));
              dispatch(closeModal());
            }}
            loading={isLoading}
            style={{ marginLeft: "auto" }}
            disabled={deleteReason.length <= 5}
            children={lg.wirklich_löschen}
          />
        )}
      </TZModal.Footer>
    </TZModal>
  );
};
