import { updateHinting } from "./hinting";
import { SDateFormat } from "./../shared/helpers/SimpleTime";
import { toMoment } from "./../shared/helpers/timeHelpers";
import { IShiftRepeat } from "./../shared/entities/IShiftRepeat";
import { selectShiftMap } from "./../selectors/shiftMapSelector";
import { timeClockingRepository } from "./../repositories/timeClockingRepository";
import { changeRequestRepository } from "./../repositories/ChangeRequestRepository";
import { message, notification } from "antd";
import { DispFn } from "../frontend-core/types/thunkTypes";
import { shiftRepository } from "../repositories/shiftRepository";
import { AppState } from "../types/AppState";
import _ from "lodash";
import { EmployeeWillGetNotifiedMsg, EmployeesWillGetNotifiedMsg, Pusher } from "./pushNote";

import { trackingRepository } from "../repositories/TrackingRepository";
import { shiftHandOverRequestRepository } from "../repositories/shiftHandOverRequestRepository";
import { isShiftInPublishedWeek } from "../helpers/general";
import { IShift } from "../shared/entities/IShift";
import { Raw } from "../shared/entities/IResource";
import { shiftTimesDiffer } from "../shared/helpers/shiftHelpers";
import { addSimpleDays, getPrevSimpleDate } from "../shared/helpers/dateHelpers";
import * as Sentry from "@sentry/browser";
import { generateRepeatingShifts } from "../shared/helpers/repeatingShiftHelpers";
import { v4 as uuid } from "uuid";

import { shiftRepeatRepository } from "../repositories/shiftRepeatRepository";
import { featuresSelector } from "../selectors/FeaturesSelector";
import { paidFeatureWarning } from "./paidFeatureWarning";
import { selectUserFullMap } from "../selectors/userFullMapSelector";
import { isRequiredShiftsCollapsedReducer } from "../reducers/ui/shifts/roster/isRequiredShiftsCollapsed";
import { relevantShiftsSelector } from "../selectors/RelevantShiftsSelector";
import { isOpenShiftsIdentical } from "../helpers/shiftHelpers";
import { selectSessionInfo } from "../selectors/SessionInfoSelector";

type MoveShiftOptions = { noNotification?: boolean };
type CloneShiftOptions = { noNotification?: boolean };

export const FarFuture: string = "2200-12-31";
export const FarPast: string = "2000-01-01";

const getCombinedRequiredUsers = (shift: IShift, identicalShift: IShift) =>
  (shift!.userId ? 1 : shift!.requiredUsersAmount || 1) + (identicalShift.requiredUsersAmount || 1);

const getIdenticalOpenShift = (shifts: IShift[], shift: IShift, isRequirement?: boolean): IShift | undefined => {
  const potentialMatches = shifts.filter((s) => (isRequirement ? s.isRequirement : !s.userId && !s.isRequirement));
  return potentialMatches.find((s) => isOpenShiftsIdentical(s, shift));
};

//
// MOVE SHIFT
//
export const moveShift = (
  shiftId: string,
  toDate: string,
  toUserId: string | undefined,
  toJobPosId: string | undefined, // if grid is grouped by JobPosition the cells containt a jobPositionId
  toRequirement: boolean | undefined,
  options?: MoveShiftOptions
) => {
  return async (dispatch: DispFn, getState: () => AppState) => {
    Sentry.addBreadcrumb({
      message: "roster - moveShift",
      data: { shiftId, toDate, toUserId, toJobPosId },
    });

    const state = getState();
    const features = featuresSelector(state);
    const shift = selectShiftMap(state)[shiftId] as IShift;
    const shifts = relevantShiftsSelector(state);
    const publishedWeeks = state.data.publishedWeeks;
    const users = state.data.users;

    const fromUserId = shift.userId;
    const toUser = toUserId ? users.find((u) => u.id === toUserId) : undefined;

    const newJobPos = toJobPosId || shift.jobPositionId;
    const userHasRequiredJobPos = toUser?.jobPositionIds.includes(newJobPos);
    const userHasRequiredBranch = toUser?.branchIds.includes(shift.branchId);

    const includesRequirement = shift.isRequirement || toRequirement;
    const movingRequiredShift = shift.isRequirement && toRequirement;
    const isAssigningRequirement = shift.isRequirement && !toRequirement;

    const assigningOpenShift = !fromUserId && !!toUserId && !includesRequirement;
    const openingAssignedShift = fromUserId && !toUserId && !includesRequirement;
    const movingOpenShift = !fromUserId && !toUserId && !includesRequirement;

    const userChanged = shift.userId !== toUserId;
    const dateChanged = shift.date !== toDate;
    const jobPosChanged = toJobPosId && shift.jobPositionId !== toJobPosId;

    const isMovingToSameCell = !userChanged && !dateChanged && !jobPosChanged;

    if (isMovingToSameCell) {
      return;
    }

    if (!fromUserId && shift.appliedUserIds?.length) {
      const message = lg.eine_schicht_mit_bewerbungen_kann_nicht_verschoben_werden;
      notification.warning({ message });
      return;
    }

    if (toUser && !userHasRequiredJobPos) {
      const message = lg.mitarbeiter_hat_nicht_die_erforderliche_rolle_für_die_schicht;
      notification.warning({ message });
      return;
    }

    if (toUser && !userHasRequiredBranch) {
      const message = lg.mitarbeiter_gehört_nicht_zum_standort_der_schicht;
      notification.warning({ message });
      return;
    }

    // if (!features.shiftDragAndDrop) {  drag&drop is now available in all tiers
    //   dispatch(paidFeatureWarning());
    //   return;
    // }

    if (openingAssignedShift && !features.openShifts) {
      dispatch(paidFeatureWarning());
      return;
    }

    const newShift = {
      ...shift,
      date: toDate,
      userId: toUserId,
      jobPositionId: newJobPos,
      repeatId: undefined,
      isRequirement: toRequirement || undefined,
    };

    if (!toUserId) {
      // if moving an identical openShift or requirementShift > we just increse the requiredUsersAmount
      const identicalShift = getIdenticalOpenShift(shifts, newShift, toRequirement);
      if (identicalShift) {
        const requiredUsersAmount = getCombinedRequiredUsers(shift, identicalShift);
        await dispatch(shiftRepository.update({ ...identicalShift, requiredUsersAmount }));
        await dispatch(shiftRepository.remove(shift.id));
        return;
      }
    }

    newShift.requiredUsersAmount = undefined;
    newShift.appliedUserIds = [];

    if (assigningOpenShift) {
      // This Case is handled by its own action because it requires a bit more logic
      dispatch(assignOpenShiftToUser(shiftId, toUserId!, newJobPos, toDate));
      return;
    } else if (isAssigningRequirement) {
      // This Case is just cloning a requirement because we dont want to remove the requirement
      shift.jobPositionId !== newJobPos
        ? message.error(lg.die_rolle_stimmt_nicht_überein)
        : dispatch(cloneShift(shiftId, toDate, toUserId, newJobPos, false, options));
      return;
    } else if (openingAssignedShift) {
      newShift.requiredUsersAmount = 1;
      newShift.jobPositionId = shift.jobPositionId;
    } else if (movingOpenShift || movingRequiredShift) {
      newShift.requiredUsersAmount = shift.requiredUsersAmount;
    }

    const freshShift = await dispatch(shiftRepository.update(newShift, { skipStoreUpdate: true }));

    // Sending notifications
    if (isShiftInPublishedWeek(publishedWeeks, shift) && !toRequirement && !options?.noNotification) {
      if (userChanged && toUserId && fromUserId) {
        const feedbackText = EmployeesWillGetNotifiedMsg; // in plural
        dispatch(Pusher.shiftAssigned(newShift, [newShift.userId!], { feedbackText }));
        dispatch(Pusher.shiftDeleted(shift));
      } else if (dateChanged && toUserId && fromUserId) {
        const feedbackText = EmployeeWillGetNotifiedMsg;
        dispatch(Pusher.shiftDateChanged(shift.date, newShift, { feedbackText }));
      } else if (openingAssignedShift) {
        const feedbackText = EmployeeWillGetNotifiedMsg;
        dispatch(Pusher.shiftDeleted(shift, { feedbackText }));
      }
    }
    return freshShift;
  };
};

//
// CLONE SHIFT
//
export const cloneShift = (
  shiftId: string,
  toDate: string,
  toUserId: string | undefined,
  toJobPosId: string | undefined,
  toRequirement: boolean | undefined,
  options?: CloneShiftOptions
) => {
  return async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();

    const hinting = getState().data.rosterSettings[0].hinting || {};
    const selectedBranch = getState().ui.selectedBranch;
    !hinting.hasDragAndDropped && dispatch(updateHinting({ hasDragAndDropped: true }));

    const features = featuresSelector(state);
    const publishedWeeks = state.data.publishedWeeks;

    const users = state.data.users;
    const shift = selectShiftMap(state)[shiftId] as IShift;
    const shifts = relevantShiftsSelector(state);
    const toUser = toUserId ? users.find((u) => u.id === toUserId) : undefined;

    const newJobPos = toJobPosId || shift.jobPositionId;
    const userHasRequiredJobPos = !toUser || toUser.jobPositionIds.includes(newJobPos);
    const userHasRequiredBranch = toUser?.branchIds.includes(shift.branchId);

    if (toUser && !userHasRequiredJobPos) {
      const message = lg.mitarbeiter_hat_nicht_die_erforderliche_rolle_für_die_schicht;
      notification.warning({ message });
      return;
    }

    if (toUser && !userHasRequiredBranch && !shift.isTemplate) {
      const message = lg.mitarbeiter_gehört_nicht_zum_standort_der_schicht;
      notification.warning({ message });
      return;
    }

    // if (!features.shiftDragAndDrop) {  drag&drop is now available in all tiers
    //   dispatch(paidFeatureWarning());
    //   return;
    // }

    const newShift: IShift = {
      ...shift,
      id: uuid(),
      date: toDate,
      userId: toUserId,
      branchId: shift.isTemplate ? selectedBranch : shift.branchId,
      isTemplate: undefined,
      appliedUserIds: [],
      jobPositionId: toJobPosId || shift.jobPositionId,
      repeatId: undefined,
      isRequirement: toRequirement || undefined,
      requiredUsersAmount: toUserId ? undefined : shift.requiredUsersAmount || 1,
    };

    if (!toUserId) {
      // if cloning an identical openShift or requirementShift > we just increse the requiredUsersAmount
      const identicalShift = getIdenticalOpenShift(shifts, newShift, toRequirement);
      if (identicalShift) {
        const requiredUsersAmount = getCombinedRequiredUsers(shift, identicalShift);
        await dispatch(shiftRepository.update({ ...identicalShift, requiredUsersAmount }));
        return;
      }
    }

    await dispatch(shiftRepository.create(newShift, { skipStoreUpdate: true }));

    // Sending notifications
    if (
      isShiftInPublishedWeek(publishedWeeks, newShift) &&
      newShift.userId &&
      !newShift.isRequirement &&
      !options?.noNotification
    ) {
      dispatch(
        Pusher.shiftAssigned(newShift, [newShift.userId!], {
          feedbackText: EmployeeWillGetNotifiedMsg,
        })
      );
    }
  };
};

//
// ASSIGN OPEN SHIFT
//
export const assignOpenShiftToUser =
  (shiftId: string, userId: string, jobPositionId: string, newDate?: string, isApplicationAcception?: boolean) =>
  async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const shift = selectShiftMap(state)[shiftId] as IShift;
    const publishedWeeks = state.data.publishedWeeks;

    if (shift.jobPositionId !== jobPositionId) {
      message.error(lg.die_rolle_stimmt_nicht_überein);
      return;
    }

    if (shift.requiredUsersAmount && shift.requiredUsersAmount > 1) {
      const updatingShift = {
        ...shift,
        requiredUsersAmount: shift.requiredUsersAmount - 1,
        appliedUserIds: shift.appliedUserIds?.filter((_userId) => _userId !== userId),
      };
      await dispatch(shiftRepository.update(updatingShift));
    } else {
      await dispatch(shiftRepository.remove(shift.id));
    }

    const creatingShift: IShift = {
      ...shift,
      id: uuid(),
      date: newDate || shift.date,
      userId: userId,
      requiredUsersAmount: undefined,
      appliedUserIds: undefined,
      repeatId: undefined,
      isRequirement: undefined,
      isTemplate: undefined,
    };

    if (isApplicationAcception) {
      dispatch(Pusher.ShiftApplicationAccepted(creatingShift, { feedbackText: EmployeeWillGetNotifiedMsg }));
    }

    await dispatch(shiftRepository.create(creatingShift));

    if (isShiftInPublishedWeek(publishedWeeks, shift) && !isApplicationAcception) {
      const feedbackText = EmployeeWillGetNotifiedMsg;
      dispatch(Pusher.shiftAssigned(creatingShift, [creatingShift.userId!], { feedbackText }));
    }
  };

//
// Reject Shift Application
//
export const rejectShiftApplication =
  (shiftId: string, userId: string) => async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const shift = selectShiftMap(state)[shiftId] as IShift;
    const next: IShift = { ...shift };
    const user = selectUserFullMap(state)[userId];

    next.appliedUserIds = shift.appliedUserIds?.filter((appliedUserId) => appliedUserId !== userId);

    await dispatch(shiftRepository.update(next));
    message.success(lg.schichtbewerbung_von_user_abgelehnt(user.name));
  };

//
// Delete Shift
//
export const deleteShift =
  (shift: IShift) =>
  async (dispatch: DispFn, getState: () => AppState): Promise<IShift> => {
    const publishedWeeks = getState().data.publishedWeeks;

    if (!selectShiftMap(getState())[shift.id]) {
      return shift; // workaround of rare cases, when a shift is trying to get delted multiple times. Exit here because shift is already delted.
    }

    const [tracking, clocking, handOver, changeRequest] = await Promise.all([
      dispatch(trackingRepository.fetchOne(shift.id)),
      dispatch(timeClockingRepository.fetchOne(shift.id)),
      dispatch(shiftHandOverRequestRepository.fetchOne(shift.id)),
      dispatch(changeRequestRepository.fetchOne(shift.id)),
    ]);

    const requests = [shiftRepository.remove(shift.id)] as any;
    tracking && requests.push(trackingRepository.remove(shift.id));
    clocking && requests.push(timeClockingRepository.remove(shift.id));
    handOver && requests.push(shiftHandOverRequestRepository.remove(shift.id));
    changeRequest && requests.push(changeRequestRepository.remove(shift.id));

    await Promise.all(requests.map(dispatch));

    if (isShiftInPublishedWeek(publishedWeeks, shift) && shift.userId) {
      dispatch(Pusher.shiftDeleted(shift));
    }
    return shift;
  };

//
// CREATE SHIFT
//
export const createShift =
  (_shift: Raw<IShift>, shiftRepeat?: IShiftRepeat) =>
  async (dispatch: DispFn): Promise<IShift> => {
    const shift = { id: uuid(), ..._shift };

    if (shiftRepeat) {
      shift.repeatId = shiftRepeat.id;
      const shifts = generateRepeatingShifts(shift, shiftRepeat);
      await Promise.all([
        dispatch(shiftRepeatRepository.create(shiftRepeat)),
        dispatch(shiftRepository.createList(shifts, { skipStoreUpdate: true })),
      ]);
    } else {
      await dispatch(shiftRepository.create(shift));
      dispatch(notifyOfShiftCreationIfNeeded([shift]));
    }
    return shift;
  };

//
// CREATE MULTIPLE SHIFT
//
export const createMultiShifts =
  (shifts: IShift[], shiftRepeat?: IShiftRepeat) => async (dispatch: DispFn, getState: () => AppState) => {
    if (shiftRepeat) {
      let shiftsList = [] as IShift[];
      let shiftRepeats = [] as IShiftRepeat[];
      shifts.forEach((s) => {
        const _shiftRepeat = { ...shiftRepeat, id: uuid(), userId: s.userId };
        shiftRepeats = [...shiftRepeats, _shiftRepeat];
        shiftsList = [...shiftsList, ...generateRepeatingShifts(s, _shiftRepeat)];
      });

      await dispatch(shiftRepeatRepository.createList(shiftRepeats));
      await dispatch(shiftRepository.createList(shiftsList, { skipStoreUpdate: true }));
    } else {
      dispatch(notifyOfShiftCreationIfNeeded(shifts));
      await dispatch(shiftRepository.createList(shifts));
    }
  };

//
// Delete repeating shift
//
export const deleteRepeatingShift = (shift: IShift, shiftRepeat: IShiftRepeat) => async (dispatch: DispFn) => {
  const upcomingShifts = await dispatch(getUpcomingRepeatingShfitsOfUser(shift));
  const upcomingShiftIds = upcomingShifts.map((s) => s.id);
  const newEndDate = getPrevSimpleDate(shift.date);
  const newRepeatingShift = { ...shiftRepeat, endDate: newEndDate };

  shift.date === shiftRepeat.startDate
    ? await dispatch(shiftRepeatRepository.remove(shiftRepeat.id))
    : await dispatch(shiftRepeatRepository.update(newRepeatingShift));

  if (shift.userId) {
    await dispatch(delteAllShiftRelations(upcomingShifts));
  }

  await dispatch(shiftRepository.removeList(upcomingShiftIds));
};

//
// Edit repeating shift
//
export const editRepeatingShift =
  (shift: IShift, shiftRepeat: IShiftRepeat) => async (dispatch: DispFn, getState: () => AppState) => {
    const upcomingShifts = await dispatch(getUpcomingRepeatingShfitsOfUser(shift));
    const updatedShifts = generateRepeatingShifts(shift, shiftRepeat, shift.date);

    await dispatch(shiftRepository.removeList(upcomingShifts.map((s) => s.id)));
    await dispatch(shiftRepository.createList(updatedShifts));
    await dispatch(shiftRepeatRepository.update(shiftRepeat));
  };

//
// Edit shift
//
export const editShift =
  (shift: IShift) =>
  async (dispatch: DispFn, getState: () => AppState): Promise<IShift> => {
    const prevShift = selectShiftMap(getState())[shift.id];
    const publishedWeeks = getState().data.publishedWeeks;

    if (
      isShiftInPublishedWeek(publishedWeeks, shift) &&
      shift.userId && // is not open shift
      shiftTimesDiffer(shift, prevShift)
    ) {
      dispatch(Pusher.shiftTimeChanged(shift));
      message.success(EmployeeWillGetNotifiedMsg);
    }

    return dispatch(shiftRepository.update({ ...shift }));
  };

//
// Delete all shifts of week
//
export const deleteAllShiftsOfWeek = () => async (dispatch: DispFn, getState: () => AppState) => {
  const state = getState();
  const cw = state.ui.shifts.roster.selectedWeek;
  const branchId = state.ui.selectedBranch!;
  const from = cw;
  const to = addSimpleDays(from, 6);
  const fromComp = `${branchId}_${from}`;
  const toComp = `${branchId}_${to}`;
  const crudOptions = { skipStoreUpdate: true };

  const handOvers = getState().data.shiftHandOverRequests.filter(
    // repo has no compound-key and index in DB -> so we take them from the store
    (h) => (!branchId || h.branchId === state.ui.selectedBranch) && h.date >= from && h.date <= to
  );
  const changeRequests = getState().data.changeRequests.filter(
    // repo has no compound-key and index in DB -> so we take them from the store
    (c) => (!branchId || c.branchId === state.ui.selectedBranch) && c.date >= from && c.date <= to
  );

  const handOverIds = handOvers.map((e) => e.id);
  const changeRequestIds = changeRequests.map((e) => e.id);

  await dispatch(shiftHandOverRequestRepository.removeList(handOverIds, crudOptions));
  await dispatch(changeRequestRepository.removeList(changeRequestIds, crudOptions));

  const connectedEntityRepos = [timeClockingRepository, trackingRepository, shiftRepository];

  for (const repo of connectedEntityRepos) {
    const entities = await dispatch(
      (repo as any).fetchMany({
        filter: branchId ? ["branchId_date", "between", [fromComp, toComp]] : ["date", "between", [from, to]],
      })
    );
    if (entities.length) {
      const ids = entities.map((e) => e.id);
      await dispatch(repo.removeList(ids, crudOptions));
    }
  }
};

const getUpcomingRepeatingShfitsOfUser = (shift: IShift) => async (dispatch: DispFn) => {
  const fromKey = `${shift.userId}_${shift.date}`;
  const toKey = `${shift.userId}_${FarFuture}`;
  return (
    await dispatch(
      shiftRepository.fetchMany({
        filter: ["userId_date", "between", [fromKey, toKey]],
      })
    )
  ).filter((s) => s.repeatId === shift.repeatId);
};

const notifyOfShiftCreationIfNeeded = (shifts: IShift[]) => async (dispatch: DispFn, getState: () => AppState) => {
  const shift = shifts[0];
  const userIds = shifts.map((s) => s.userId!);
  const isOpenShift = !shift.userId;
  const publishedWeeks = getState().data.publishedWeeks;
  const sessionUser = selectSessionInfo(getState()).user;

  if (!isShiftInPublishedWeek(publishedWeeks, shift)) {
    return;
  }

  if (shift.isRequirement) {
    return;
  }

  if (shift.userId === sessionUser.id) {
    // dont need to nofiy if user himself is creator
    return;
  }

  isOpenShift ? dispatch(Pusher.openShiftAvailable(shift)) : dispatch(Pusher.shiftAssigned(shift, userIds));

  isOpenShift || shifts.length > 1
    ? message.success(EmployeesWillGetNotifiedMsg) // plural
    : message.success(EmployeeWillGetNotifiedMsg);
};

export const delteAllShiftRelations = (shifts: IShift[]) => async (dispatch: DispFn, getState: () => AppState) => {
  if (!shifts.length) {
    return;
  }

  const startDate = _.orderBy(shifts, (s) => s.date)[0].date;
  const endDate = _.orderBy(shifts, (s) => s.date)[shifts.length - 1].date;
  const userIds = shifts.map((s) => s.userId);
  const allSameUser = userIds.every((uid) => uid === userIds[0]);
  const userId = allSameUser ? userIds[0] : undefined;
  const shiftIds = shifts.map((s) => s.id);

  const propKey = allSameUser ? "userId_date" : "date";
  const fromKey = allSameUser ? `${userId}_${startDate}` : startDate;
  const toKey = allSameUser ? `${userId}_${endDate}` : endDate;

  const filter = [propKey, "between", [fromKey, toKey]] as any;
  const [trackings, clockings] = await Promise.all([
    dispatch(trackingRepository.fetchMany({ filter })),
    dispatch(timeClockingRepository.fetchMany({ filter })),
  ]);

  const allHandOverRequests = getState().data.shiftHandOverRequests;
  const allChangeRequets = getState().data.changeRequests;

  const handOvers = allHandOverRequests.filter((h) => userId && h.fromUserId === userId && h.date >= startDate);
  const changeRequets = allChangeRequets.filter((h) => userId && h.userId === userId && h.date >= startDate);

  if (handOvers.length) {
    const handOverIds = handOvers.map((h) => h.id);
    const ids = _.intersection(handOverIds, shiftIds);
    ids && (await dispatch(shiftHandOverRequestRepository.removeList(ids)));
  }

  if (changeRequets.length) {
    const changeRequetIds = changeRequets.map((h) => h.id);
    const ids = _.intersection(changeRequetIds, shiftIds);
    ids && (await dispatch(changeRequestRepository.removeList(ids)));
  }

  if (trackings.length) {
    const trackingIds = trackings.map((h) => h.id);
    const ids = _.intersection(trackingIds, shiftIds);
    ids && (await dispatch(trackingRepository.removeList(ids)));
  }

  if (clockings.length) {
    const clockingIds = clockings.map((h) => h.id);
    const ids = _.intersection(clockingIds, shiftIds);
    ids && (await dispatch(timeClockingRepository.removeList(ids)));
  }
};
