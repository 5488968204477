import { getPrevSimpleDate, getMonthIntervalStartDate, getMonthIntervalEndDate } from "../shared/helpers/dateHelpers";
import { createCachedSelector } from "re-reselect";
import { selectRosterDateRange } from "./rosterDateRangeSelector";
import { getShiftCredit } from "../shared/helpers/credit";
import { selectContractsByUser } from "./contractsByUserSelector";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import moment from "moment";
import { relevantShiftsSelector } from "./RelevantShiftsSelector";
import _ from "lodash";
import { toSimpleDate } from "../shared/helpers/timeHelpers";
import { getValidContract } from "../shared/helpers/credit";
import { IContract, WorkInterval } from "../shared/entities/IContract";
import { RosterMode } from "../reducers/ui/shifts/roster/rosterMode";
import { IShift } from "../shared/entities/IShift";
import { BalancesMap, CreditIntervalsMap } from "../actions/creditActions/creditTypes";
import { selectContractsByUserMap } from "./mapSelectors";

type CreditOfMonth = number;
type MaxHoursWarning = { [userId: string]: CreditOfMonth };

export const monthlyMaxHoursWarningSelector = createSelector(
  [
    (state: AppState) => state.data.contracts,
    (state: AppState) => state.data.creditIntervals,
    (state: AppState) => state.ui.shifts.roster.selectedDay,
    (state: AppState) => state.data.rosterSettings,
    (state: AppState) => state.ui.shifts.roster.rosterTemplateMode.active,
  ],
  (contracts, creditIntervals, selectedDate, rosterSettings, templateModeIsActive): MaxHoursWarning => {
    if (templateModeIsActive) {
      return {};
    }

    if (!contracts.some((c) => c.monthlyMaxHours)) {
      return {};
    }

    const monthStartDay = rosterSettings[0].customMonthIntervalStart || 1;
    const startOfMonth = getMonthIntervalStartDate(selectedDate, monthStartDay);
    const endOfMonth = getMonthIntervalEndDate(selectedDate, monthStartDay);
    const validContracts = contracts.filter((c) => c.validFrom <= startOfMonth && c.validTo >= startOfMonth);
    const relevantContracts = validContracts.filter((c) => c.monthlyMaxHours);

    const warnings: { [userId: string]: number } = {};

    relevantContracts.forEach((c) => {
      const interval = creditIntervals[`${c.userId}_${startOfMonth}_${endOfMonth}`];
      if (interval && interval.credit > c.monthlyMaxHours! * 60) {
        warnings[c.userId] = interval.credit;
      }
    });

    return warnings;
  }
);
