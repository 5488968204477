import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import cn from "classnames";
import BusyWrapper from "../../../../components/BusyWrapper/BusyWrapper";
import { minutesToDuration } from "../../../../shared/helpers/timeHelpers";
import { CreditCorrectionType } from "../../../../shared/entities/ICreditCorrection";
import "./styles.scss";
import _ from "lodash";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import moment from "moment";
import { featuresSelector } from "../../../../selectors/FeaturesSelector";
import { getUserName } from "../../../../shared/helpers/userHelpers";

type OwnProps = {
  userId: string;
  startDate: string;
  endDate: string;
  isDataLoading: boolean;
  isMonthly: boolean;
  isNotPublished: boolean;
  isV2?: boolean;
};

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const { userId, startDate, endDate, isV2 } = ownProps;
  const balanceDate = isV2 ? startDate : endDate;
  return {
    users: state.data.users,
    creditCorrections: state.data.creditCorrections,
    creditBalances: state.data.creditBalances[`${userId}_${balanceDate}`],
    creditInterval: state.data.creditIntervals[`${userId}_${startDate}_${endDate}`] || {},
    customMonthStartDay: state.ui.general.creditsCustomMonthStartDay,
    isV2: state.data.tenantInfo.isV2,
    features: featuresSelector(state),
  };
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class CreditsInfoCardComp extends React.PureComponent<Props, {}> {
  render() {
    const {
      users,
      userId,
      creditInterval,
      creditBalances,
      isMonthly,
      creditCorrections,
      startDate,
      endDate,
      customMonthStartDay,
      isNotPublished,
      isV2,
    } = this.props;
    const user = users.find((u) => u.id === userId)!;

    // const displayEndDateCredit = minutesToDuration(balance, {
    //   dropZeroMinutes: true,
    //   withSign: true,
    // });

    const initialCredit = creditCorrections.find((c) => c.type === CreditCorrectionType.initial && c.userId === userId);

    const overtimeSum = creditInterval.credit - creditInterval.quota;

    const balance = creditBalances?.balance || 0;
    const showHourAccount = this.props.features.credits;
    const hourAccountPremiumInfo = lg.das_stundenkonto_ist_nur_in_der_premium_version_verfügbar;

    return (
      <BusyWrapper isBusy={this.props.isDataLoading} style={{ minHeight: "auto", width: "100%" }}>
        <div className="mainCreditInfoCard">
          <div className="col mainCol">
            <div className="userNameTitle">{getUserName(user)}</div>
            {initialCredit && !isNotPublished && (
              <div className="overtimeInfo">
                <div
                  data-rh={showHourAccount ? null : hourAccountPremiumInfo}
                  className={cn({ hours: true, negative: balance < 0 })}
                >
                  {showHourAccount ? minutesToDuration(balance, { withSign: true }) : "?"}
                </div>
                <div className="description">
                  <div className="unit">{lg.stundenkonto}</div>
                  <div className="extraInfo">
                    {isMonthly
                      ? (isV2 ? "" : lg.ende_of) + " " + moment(startDate, SDateFormat).format("MMMM")
                      : lg.kw + " " + moment(startDate, SDateFormat).isoWeek()}
                  </div>
                </div>
              </div>
            )}
            {isNotPublished && (
              <div className="notPublishedNote"> {lg.gewählter_zeitraum_ist_nicht_veröffentlicht} </div>
            )}
          </div>
          {!isNotPublished && (
            <div className="col hourSumsCol">
              <div className="row">
                <div className="label">{lg.sollstunden}</div>
                <b>{minutesToDuration(creditInterval.quota || 0)} h</b>
              </div>
              <div className="row">
                <div className="label">{lg.geleistet}</div>
                <b>{minutesToDuration(creditInterval.credit || 0)} h</b>
              </div>
              <div className="row overtimeRow">
                <div className="label">{lg.überstunden}</div>
                <b
                  className={cn({
                    isNegative: overtimeSum < 0,
                    isPositive: overtimeSum > 0,
                  })}
                >
                  {minutesToDuration(overtimeSum || 0, { withSign: true })} h
                </b>
              </div>
            </div>
          )}
        </div>
      </BusyWrapper>
    );
  }
}

export const CreditsInfoCard = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  CreditsInfoCardComp
);
