import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { InputNumber, Button, Icon } from "antd";
import TZModal from "../../TZModal/TZModal";
import { closeModal } from "../../../actions/modal";
import { Modal } from "antd";
import { Omit } from "lodash";
import { absenceEntitlementRepository } from "../../../repositories/absenceEntitlementRepository";
import "./styles.scss";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { busyInjector, BusyInjectorProps } from "../../BusyInjector/BusyInjector";
import { IAbsenceEntitlement } from "../../../shared/entities/IAbsenceEntitlement";
import { selectUsersFull } from "../../../selectors/usersFullSelector";
import { decimalSeparatorLocal } from "../../../helpers/dateFormatHelper";

const mapStateToProps = (state: AppState) => ({
  users: selectUsersFull(state),
});

type State = { days?: number; confirmDelete?: boolean };
type OwnProps = {
  absenceEntitlement?: IAbsenceEntitlement;
  absenceTypeId: string;
  year: number;
  userId: string;
  displayDays?: number; // can be provided to set defaultValue to the days-formField
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class AbsenceEntitlementModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      days: this.props.absenceEntitlement?.days,
      confirmDelete: false,
    };
  }

  onChange = (_days?: number | string) => {
    // when the user clears the input-field, '' is the value
    const days = typeof _days === "string" ? undefined : _days;
    this.setState({ days });
  };

  async save() {
    const { year, userId, absenceEntitlement, absenceTypeId } = this.props;
    if (this.state.days === undefined) {
      return;
    }

    const isCreationMode = !this.props.absenceEntitlement;

    const newContingent: Partial<IAbsenceEntitlement> = isCreationMode
      ? { absenceTypeId, year, userId, days: this.state.days }
      : { ...absenceEntitlement, days: this.state.days };

    isCreationMode
      ? await this.props.dispatch(absenceEntitlementRepository.create(newContingent as Omit<IAbsenceEntitlement, "id">))
      : await this.props.dispatch(absenceEntitlementRepository.update(newContingent as IAbsenceEntitlement));

    this.props.dispatch(closeModal());
  }

  deleteClicked = () => {
    this.state.confirmDelete ? this.delete() : this.setState({ confirmDelete: true });
  };

  delete = () => {
    this.props.load(
      this.props.dispatch(absenceEntitlementRepository.remove(this.props.absenceEntitlement!.id)),
      "delete"
    );

    this.props.dispatch(closeModal());
  };

  render() {
    const { users, year, userId, displayDays } = this.props;
    const user = users.find((u) => u.id === userId);
    const userName = user!.name;
    const isCreationMode = !this.props.absenceEntitlement;

    return (
      <TZModal>
        <TZModal.Head title={userName}></TZModal.Head>
        <TZModal.Body>
          <div className="vacationContingentModalMain">
            <div className="label">{`${lg.urlaubsanspurch_ab_dem_jahr} ${year}:`}</div>
            <div className="inputWrapper">
              <InputNumber
                autoFocus={true}
                decimalSeparator={decimalSeparatorLocal}
                value={this.state.days}
                placeholder={displayDays ? `${displayDays}` : ""}
                onChange={this.onChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.save();
                  }
                }}
              />
              <div className="days">{lg.Tage}</div>
            </div>
          </div>
        </TZModal.Body>
        <TZModal.Footer>
          {!isCreationMode && (
            <Button
              id="cancel-vacation-contingent"
              type="danger"
              onClick={() => this.deleteClicked()}
              loading={this.props.isLoading("delete")}
              data-rh={this.state.confirmDelete ? lg.wirklich_löschen : null}
            >
              {this.state.confirmDelete ? "?" : <Icon type="delete" />}
            </Button>
          )}
          <Button
            id="save-vacation-contingent"
            type="primary"
            onClick={() => this.props.load(this.save(), "save")}
            disabled={this.state.days === undefined}
            loading={this.props.isLoading("save")}
          >
            {lg.Speichern}
          </Button>
        </TZModal.Footer>
      </TZModal>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  busyInjector(AbsenceEntitlementModal)
);
