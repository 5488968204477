import { persistReducer } from "redux-persist";
import { adminDataActionTypes } from "../../actions/adminData";
import { getSimpleReducer } from "../helpers/reducerHelpers";
import storage from "redux-persist/lib/storage";
import { combineReducers, Reducer } from "redux";
import createCompressor from "redux-persist-transform-compress";
import { IAdminData } from "../../shared/entities/IAdminData";

const compressor = createCompressor();

const reducer = getSimpleReducer<IAdminData | null>(adminDataActionTypes.SET, null);

export const adminDataReducer = persistReducer(
  {
    key: "adminData",
    storage,
    transforms: [compressor],
  },
  reducer as Reducer<IAdminData | null, any>
) as Reducer<IAdminData | null, any>;
