import React, { useState } from "react";
import { useSelector } from "../../helpers/redux";
import { tierInfoSelector } from "../../selectors/TierInfoSelector";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { Icon } from "antd";
import { envi } from "../../env";

export const AppMismatchBanner = React.memo(() => {
  const isV2 = useSelector((s) => s.data.tenantInfo.isV2);

  if (!!isV2 === !!envi.isV2()) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        right: 0,
        left: 0,
        background: "red",
        color: "white",
        padding: 24,
        zIndex: 100,
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 16,
        paddingLeft: 100,
        paddingRight: 100,
      }}
    >
      Wrong App: Aplano / Zeitguru
    </div>
  );
});
