export const dayRosterTimeSpanTypes = {
  SELECT: 'SHIFTS_ROSTER_TIME_SPAN_SELECT',
};

type ISelectedTimeSpan = {
  dayStart: number,
  dayEnd: number,
}

export const selectTime = (selectedTimeSpan: ISelectedTimeSpan) => ({
  type: dayRosterTimeSpanTypes.SELECT,
  payload: selectedTimeSpan
});
