import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import "./styles.scss";
import { Icon } from "antd";
import { EmptyContent } from "../../../components/EmptyContent/EmptyContent";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { openModal } from "../../../actions/modal";
import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import BusyWrapper from "../../../components/BusyWrapper/BusyWrapper";
import _ from "lodash";
import { IShift } from "../../../shared/entities/IShift";
import { ShiftPopup } from "../../../components/ShiftPopup/ShiftPopup/ShiftPopup";
import { selectShiftMap } from "../../../selectors/shiftMapSelector";
import { myUpcomingShiftsSelector } from "../../../selectors/myUpcomingShiftsSelector";
import { MyUpcomingShiftItem } from "../../../components/MyUpcomingShiftItem/MyUpcomingShiftItem";

const mapStateToProps = (state: AppState) => {
  return {
    myUpcomingShifts: myUpcomingShiftsSelector(state),
    sessionInfo: selectSessionInfo(state),
  };
};
type State = {};
type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class _MyUpcomingShiftsPaper extends React.PureComponent<Props, State> {
  openModal = (shift: IShift) => {
    this.props.dispatch(openModal(ShiftPopup, { shift }));
  };

  render() {
    const { myUpcomingShifts } = this.props;
    const sortedShifts = _.sortBy(myUpcomingShifts, "date");

    return (
      <div className="cell">
        <div className="content">
          <div className="dashboardPaper">
            <div className="title">
              <Icon type="bars" style={{ marginRight: "12px", color: "#1a90ff" }} />
              {lg.meine_schichten}
            </div>
            <BusyWrapper isBusy={this.props.isLoading()} style={{ width: "100%" }}>
              <div className="body">
                <EmptyContent show={sortedShifts.length ? false : true} text={lg.keine_schichten_vorhanden} />
                {sortedShifts.map((shift) => (
                  <MyUpcomingShiftItem key={shift.id} shift={shift} openModal={() => this.openModal(shift)} />
                ))}
              </div>
            </BusyWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export const MyUpcomingShiftsPaper = withErrorBoundary(connect(mapStateToProps)(busyInjector(_MyUpcomingShiftsPaper)));
