import { isWorkDay } from "./credit";
import { DailyQuota } from "./../entities/IContract";
import { getWeekDay } from "./weekDaysList";
import { addSimpleDays } from "./dateHelpers";
import { WeekDays, WeekDay } from "../entities/IContract";
import { Raw } from "../entities/IResource";
import { IContract } from "../entities/IContract";
import { IHoliday } from "../entities/IHoliday";
import { IAbsence, EffectiveAbsenceDays } from "../entities/IAbsence";
import _ from "lodash";

export const getMeanDailyQuota = (dailyQuota: DailyQuota): number => {
  const workDaysCount = Object.values(dailyQuota).filter(isWorkDay).length;
  return _.sum(Object.values(dailyQuota).filter(isWorkDay)) / workDaysCount;
};

export const getMinutesToWork = (date: string, validContract: Raw<IContract>): number => {
  const day = getWeekDay(date);
  const weekDay: WeekDay = WeekDays[day];
  return validContract?.dailyQuota[weekDay] || 0;
};

export const getEffectiveDaysByYear = (
  a: Partial<IAbsence>,
  holidayFinder: (date: string, branchId?: string) => IHoliday | undefined,
  contracts: Raw<IContract>[],
  options?: { fromDate?: string; untilDate?: string }
): EffectiveAbsenceDays => {
  const { fromDate, untilDate } = options || {};
  const effectiveDays: EffectiveAbsenceDays = {};
  let curDay = a.startDate!;

  while (curDay <= a.endDate!) {
    const validContract = contracts.find((wp) => wp.validFrom <= curDay && wp.validTo >= curDay)!;
    const isWorkingDay = !!getMinutesToWork(curDay, validContract);
    const isHoliday = holidayFinder(curDay, validContract.mainBranchId);
    const year = curDay.substring(0, 4);

    if (!effectiveDays[year]) {
      effectiveDays[year] = 0;
    }

    if (isWorkingDay && !isHoliday) {
      const isHalfDay = (curDay === a.startDate && a.startsHalfDay) || (curDay === a.endDate && a.endsHalfDay);
      const dayToAdd = isHalfDay ? 0.5 : 1;
      const excludeDay = (fromDate && curDay < fromDate) || (untilDate && curDay > untilDate);
      !excludeDay && (effectiveDays[year] += dayToAdd);
    }

    curDay = addSimpleDays(curDay, 1);
  }
  return effectiveDays;
};

export const getEffectiveDaysOfAbsence = (
  a: Partial<IAbsence>,
  holidayFinder: (date: string, branchId?: string) => IHoliday | undefined,
  contracts: Raw<IContract>[],
  options: { fromDate?: string; untilDate?: string }
): number => {
  return _.sum(Object.values(getEffectiveDaysByYear(a, holidayFinder, contracts, options)));
};
