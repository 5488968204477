import React from "react";
import { useSelector } from "../../../../../helpers/redux";
import { selectActiveBranches } from "../../../../../selectors/ActiveBranchesSelector";
import cn from "classnames";
import "./styles.scss";

type Props = {
  selectedBranchId?: string;
  onChange: (branchId?: string) => void;
};

export const MainBranchPicker = (props: Props) => {
  const activeBranches = useSelector(selectActiveBranches);
  const { selectedBranchId, onChange } = props;

  return (
    <div className="mainBranchPicker">
      {activeBranches.map((branch) => (
        <div
          className={cn({ branchOption: true, isSelected: selectedBranchId === branch.id })}
          onClick={() => {
            onChange(branch.id);
          }}
        >
          {branch.name}
        </div>
      ))}
      <div
        className="branchOption noBranch"
        onClick={() => {
          onChange(undefined);
        }}
      >
        {lg.kein}
      </div>
    </div>
  );
};
