import React from "react";
import { connect } from "react-redux";
import { Formik, FormikProps } from "formik";
import "./styles.scss";
import { Input, Button, message, notification } from "antd";
import { RouteComponentProps } from "react-router";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { AppState } from "../../../types/AppState";
import { PasswordForgottenModal } from "../../../components/modals/PasswordForgottenModal/PasswordForgottenModal";
import { openModal } from "../../../actions/modal";
import { sitemap } from "../../../helpers/sitemap";
import { push } from "connected-react-router";
import { envi, environment } from "../../../env";
import BlueAplanoLogo from "../../../components/Logos/BlueAplanoLogo";
import { MadeWithLoveLogo } from "../../../components/Logos/MadeWithLoveLogo";
import { BackgroundSquareAnimation } from "../../../components/BackgroundSquareAnimation/BackgroundSquareAnimation";
import { DebugDateChanger } from "../debugDateChanger/debugDateChanger";
import { QuickLoginBar } from "../quickLoginBar/QuickLoginBar";
import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import { authRepository } from "../../../repositories/authRepository";
import { isV2Domain } from "../../../frontend-core/helpers/frontendHelpers";
import BlackZeitguruLogo from "../../../components/Logos/BlackZeitguruLogo";

const mapStateToProps = () => {
  return {};
};

type State = {};
type OwnProps = {} & RouteComponentProps;
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;
interface IFormValues {
  email: string;
  password: string;
}

const isValidEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

class Login extends React.PureComponent<Props, State> {
  handleSubmit = async (values: IFormValues) => {
    const { dispatch, load } = this.props;

    if (!values.email) {
      notification.error({ message: lg.email_fehlt });
      return;
    }

    if (!isValidEmail(values.email)) {
      notification.error({ message: lg.email_format_inkorrekt });
      return;
    }

    if (!values.password) {
      notification.error({ message: lg.passwort_fehlt });
      return;
    }

    try {
      const success = await load(dispatch(authRepository.login(values)));
      success && this.props.dispatch(push(sitemap.shiftsRoster.url));
      console.log(success);
    } catch (error: any) {
      message.error(error?.message);
    }
  };

  render() {
    const isV2 = envi.isV2();
    const domain = isV2 ? `https://www.zeitguru.de` : `https://www.aplano.de`;

    return (
      <div className="loginPage">
        <BackgroundSquareAnimation />
        <div className="content">
          <div className="fb aCenter">
            {isV2 && <BlackZeitguruLogo style={{ height: 60, marginBottom: 30 }} />}
            {!isV2 && <BlueAplanoLogo style={{ width: 300, height: 80, marginBottom: 20 }} />}
          </div>
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={this.handleSubmit}
            render={(props: FormikProps<IFormValues>) => (
              <div className="loginForm">
                <form onSubmit={props.handleSubmit}>
                  <div className="formField">
                    <Input
                      type="email"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      defaultValue={props.values.email}
                      placeholder={lg.email}
                      name="email"
                      size="large"
                    />
                    <span>
                      {props.touched.email && props.errors.email && (
                        <div className="errorMessage">{props.errors.email}</div>
                      )}
                    </span>
                  </div>
                  <div className="formField">
                    <Input.Password
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      defaultValue={props.values.password}
                      placeholder={lg.passwort}
                      name="password"
                      size="large"
                    />
                    <span>
                      {props.touched.password && props.errors.password && (
                        <div className="errorMessage">{props.errors.password}</div>
                      )}
                    </span>
                  </div>
                  <Button
                    id="login"
                    type="primary"
                    htmlType="submit"
                    size="large"
                    loading={this.props.isLoading()}
                    disabled={!!Object.values(props.errors).length}
                  >
                    {lg.einloggen}
                  </Button>
                  <Button
                    id="forgotPassword"
                    type="link"
                    className="forgotPassword"
                    onClick={() => this.props.dispatch(openModal(PasswordForgottenModal, {}))}
                  >
                    {lg.passwort_vergessen}
                  </Button>
                  <div className="registerButtonWrapper">
                    <a href={environment.isProd() ? `${domain}/registrieren` : "/account/register"}>
                      <Button id="register">{lg.registrieren}</Button>
                    </a>
                  </div>
                  {/* )} */}
                </form>
              </div>
            )}
          />
        </div>
        <MadeWithLoveLogo />
        {!environment.isProd() && <QuickLoginBar handleSubmit={this.handleSubmit} />}
        {!environment.isProd() && <DebugDateChanger />}
      </div>
    );
  }
}

export default connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(busyInjector(Login));
