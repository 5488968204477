import React from "react";
import { Switch, Redirect, RouteComponentProps } from "react-router-dom";
import { Route } from "react-router-dom";
import Roster from "./RosterPage/RosterPage";
import { HourAccountsPage } from "./CreditsPage/CreditsPage";
import { ShiftLogPage } from "./ShiftLogPage/ShiftLogPage";
import { ISitemapEntry, sitemap } from "../../helpers/sitemap";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import { AppState } from "../../types/AppState";
import { connect } from "react-redux";
import Availabilities from "./availabilities/Availabilities";
import { Report } from "./ReportPage/Report";
import { SubBar } from "../../components/SubBar/SubBar";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";

const mapStateToProps = (state: AppState) => {
  return {
    isV2: state.data.tenantInfo.isV2,
  };
};

type OwnProps = {} & Pick<RouteComponentProps, "history">;
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class ShiftsPage extends React.PureComponent<Props> {
  subBarLinks: Array<ISitemapEntry>;
  constructor(props: Props) {
    super(props);

    this.subBarLinks = [sitemap.shiftsRoster, sitemap.hourAccounts, sitemap.shiftsReports, sitemap.availabilities]
      .filter((i: ISitemapEntry) => !props.isV2 || i !== sitemap.availabilities)
      .map((l: ISitemapEntry) => ({ ...l, title: (props.isV2 && l.titleV2) || l.title }));
  }

  render() {
    return (
      <>
        <SubBar links={this.subBarLinks} />
        <Switch>
          <Route path={sitemap.shiftsRoster.url} component={Roster as any} />
          <Route path={sitemap.hourAccounts.url} component={HourAccountsPage as any} />
          <Route path={sitemap.availabilities.url} component={Availabilities as any} />
          <Route path={sitemap.shiftsReports.url} component={Report as any} />
          <Route path={sitemap.shiftLogs.url} component={ShiftLogPage as any} />
          <Redirect exact from={sitemap.shifts.url} to={sitemap.shiftsRoster.url} />
        </Switch>
      </>
    );
  }
}

export default connect(mapStateToProps)(ShiftsPage);
