import React from "react";
import "./styles.scss";
import cn from "classnames";
type Props = {
  isIconActive: boolean;
};

export class TopBarShiftplanIcon extends React.PureComponent<Props> {
  render() {
    return (
      <div className="topbarIconWrapper">
        <svg
          width="28px"
          height="28px"
          viewBox="0 0 856 856"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          className={cn({ topbarIcon: true })}
        >
          <g id="008-calendar-1" fill={"rgba(255, 255, 255, 0.5)"}>
            <path
              d="M755.6875,66.875 L715.5625,66.875 L715.5625,0 L648.6875,0 L648.6875,66.875 L207.3125,66.875 L207.3125,0 L140.4375,0 L140.4375,66.875 L100.3125,66.875 C45.0001875,66.875 0,111.875187 0,167.1875 L0,755.6875 C0,810.999812 45.0001875,856 100.3125,856 L755.6875,856 C810.999812,856 856,810.999812 856,755.6875 L856,167.1875 C856,111.875187 810.999812,66.875 755.6875,66.875 Z M789.125,755.6875 C789.125,774.124938 774.124938,789.125 755.6875,789.125 L100.3125,789.125 C81.8750625,789.125 66.875,774.124938 66.875,755.6875 L66.875,314.3125 L789.125,314.3125 L789.125,755.6875 Z M789.125,247.4375 L66.875,247.4375 L66.875,167.1875 C66.875,148.750062 81.8750625,133.75 100.3125,133.75 L140.4375,133.75 L140.4375,200.625 L207.3125,200.625 L207.3125,133.75 L648.6875,133.75 L648.6875,200.625 L715.5625,200.625 L715.5625,133.75 L755.6875,133.75 C774.124938,133.75 789.125,148.750062 789.125,167.1875 L789.125,247.4375 Z"
              id="Shape"
            ></path>
            <rect id="Rectangle-path" x="127" y="385" width="67" height="67"></rect>
            <rect id="Rectangle-path" x="261" y="385" width="67" height="67"></rect>
            <rect id="Rectangle-path" x="395" y="385" width="67" height="67"></rect>
            <rect id="Rectangle-path" x="528" y="385" width="67" height="67"></rect>
            <rect id="Rectangle-path" x="662" y="385" width="67" height="67"></rect>
            <rect id="Rectangle-path" x="127" y="518" width="67" height="67"></rect>
            <rect id="Rectangle-path" x="261" y="518" width="67" height="67"></rect>
            <rect id="Rectangle-path" x="395" y="518" width="67" height="67"></rect>
            <rect id="Rectangle-path" x="528" y="518" width="67" height="67"></rect>
            <rect id="Rectangle-path" x="127" y="652" width="67" height="67"></rect>
            <rect id="Rectangle-path" x="261" y="652" width="67" height="67"></rect>
            <rect id="Rectangle-path" x="395" y="652" width="67" height="67"></rect>
            <rect id="Rectangle-path" x="528" y="652" width="67" height="67"></rect>
            <rect id="Rectangle-path" x="662" y="518" width="67" height="67"></rect>
          </g>
        </svg>
      </div>
    );
  }
}
