import { IShift } from "../../../../shared/entities/IShift";

export const shfitDragActionTypes = {
  BEGIN: 'SHIFT_DRAG_BEGIN',
  END: 'SHIFT_DRAG_END',
};

const draggingShiftReducer = (
  state: null | IShift = null,
  action: any
): IShift | null => {
  switch (action.type) {
    case shfitDragActionTypes.BEGIN:
      return action.payload!;
    case shfitDragActionTypes.END:
      return null;
    default:
      return state;
  }
};

export default draggingShiftReducer;
