import React from "react";
import "./styles.scss";
import _ from "lodash";
import { useSelector } from "../../helpers/redux";
import { tierInfoSelector } from "../../selectors/TierInfoSelector";
import { featuresSelector } from "../../selectors/FeaturesSelector";

type Props = {
  noWrapper?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Page = (props) => {
  const features = useSelector(featuresSelector);
  return (
    <div
      data-component="page" // used for check if on-click events are inside a page or on an overlay
      {..._.omit(props, ["noWrapper"])}
      className={`pageComponentMain ${features.dataExports ? "canExport" : ""} ${
        props.className ? props.className : ""
      }`}
    >
      {props.noWrapper ? props.children : <div className="wrapper fb column">{props.children}</div>}
    </div>
  );
};

export default Page;
