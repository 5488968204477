import { DispFn } from "../frontend-core/types/thunkTypes";
import { push } from "connected-react-router";
import { sitemap } from "../helpers/sitemap";
import moment from "moment";

export const timeClockModeActionTypes = {
  START: "@@AV/TIME_CLOCK_MODE_START",
};

export const startTimeClockMode = (branchIds: string[]) => {
  return (dispatch: DispFn) => {
    dispatch({
      type: timeClockModeActionTypes.START,
      payload: {
        branchIds,
        activeSince: moment().toISOString(),
      },
    });
    return dispatch(push(sitemap.timeClock.url));
  };
};
