import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import Table from "../../../../components/Table/Table";
import { RowInfo, Column } from "react-table-v6";
import { AbsenceCalendarPageState, AbsenceRow, getAbsenceStatusLabel } from "../../helprs";
import moment from "moment";
import AbsenceListPageActionBar from "../AbsenceListPageActionBar/AbsenceListPageActionBar";
import "./styles.scss";
import { openModal } from "../../../../actions/modal";
import AbsenceModal from "../../../../components/modals/AbsenceModal/AbsenceModal";
import _ from "lodash";

import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";

import { getTotalDaysCount } from "../../../../components/modals/AbsenceModal/localHelpers";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { withErrorBoundary } from "../../../../components/ErrorBoundary/ErrorBoundary";

import { selectUsersWithSharedBranch } from "../../../../selectors/UsersWithSharedBranchSelector";
import { Icon } from "antd";
import { IAbsence, AbsenceStatus, EffectiveAbsenceDays } from "../../../../shared/entities/IAbsence";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import { selectAbsencesExtended } from "../../../../selectors/absencesExtendedSelector";
import { getUserName } from "../../../../shared/helpers/userHelpers";
import { RoleType } from "../../../../shared/entities/IUser";
import { exportAbsenceList } from "../../../../actions/absenceList";
import { paidFeatureWarning } from "../../../../actions/paidFeatureWarning";
import { featuresSelector } from "../../../../selectors/FeaturesSelector";
import { rangesOverlap } from "../../../../shared/helpers/dateHelpers";
import { getEffectiveDaysByYear } from "../../../../shared/helpers/absences";
import { selectHolidayFinder } from "../../../../selectors/holidayMapSelector";
import { selectContractsByUser } from "../../../../selectors/contractsByUserSelector";
import { toMoment } from "../../../../shared/helpers/timeHelpers";
import { localizeFormat } from "../../../../helpers/dateFormatHelper";

const mapStateToProps = (state: AppState) => ({
  users: state.data.users,
  absences: selectAbsencesExtended(state),
  abseneTypes: state.data.absenceTypes,
  canManaga: selectSessionInfo(state).hasManagerPermissions(),
  currentUser: selectSessionInfo(state).user,
  usersWithSharedBranch: selectUsersWithSharedBranch(state),
  sessionInfo: selectSessionInfo(state),
  filters: state.ui.filters.absenceCalendar,
  features: featuresSelector(state),
  holidayFinder: selectHolidayFinder(state),
  contractsByUser: selectContractsByUser(state),
});

type OwnProps = {};

type State = AbsenceCalendarPageState;
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

// we pass down the whole state to the props of a child-component.
// for that exporting the state type

class _AbsenceListDetailed extends React.PureComponent<Props, State> {
  canManage: boolean;

  constructor(props: Props) {
    super(props);

    this.state = {
      branchFilter: props.filters.branchFilter,
      jobPositionFilter: props.filters.positionFilter,
      userFilter: props.canManaga ? undefined : props.currentUser.id,
      statusFilter: undefined,
      selectedTypeId: undefined,
      startDate: moment().startOf("year").format(SDateFormat),
      endDate: moment().endOf("year").format(SDateFormat),
    };
  }

  getUserName = (userId: string): string => {
    return getUserName(this.props.users.find((u) => u.id === userId)!);
  };

  getAbsenceTypeLabel = (absenceTypeId: string): string => {
    return this.props.abseneTypes.find((a) => a.id === absenceTypeId)!.name;
  };

  getEffctiveDaysVal = (effectiveDaysByYear: EffectiveAbsenceDays = {}): number => {
    return _.sum(Object.values(effectiveDaysByYear));
  };

  getEffectiveDaysInRangeVal = (absence: IAbsence) => {
    // only counts the effecive days that are includedin the date-range of the filter
    const { holidayFinder, contractsByUser } = this.props;
    const fromDate = this.state.startDate;
    const untilDate = this.state.endDate;
    const userContracts = contractsByUser[absence.userId];
    const effDaysByYear = getEffectiveDaysByYear(absence, holidayFinder, userContracts, { fromDate, untilDate });
    return this.getEffctiveDaysVal(effDaysByYear);
  };

  generateAbsenceList = (): AbsenceRow[] => {
    const newList = this.getFilteredAbsences().map((absence) => {
      const userName = this.getUserName(absence.userId);
      const absenceType = this.getAbsenceTypeLabel(absence.typeId);
      const absenceDates = this.getAbsenceDatesDisplay(absence);
      const effDays = this.getEffctiveDaysVal(absence.effectiveDays);
      const effDaysInRange = this.getEffectiveDaysInRangeVal(absence);
      const days = getTotalDaysCount(absence);
      const absenceStatus = getAbsenceStatusLabel(absence.status);
      const isEffectiveDaysOverriden = !!absence.effectiveDaysOverride;

      const generatedAbsence: AbsenceRow = {
        ...absence,
        userName: userName,
        displayType: absenceType,
        displayDateRange: absenceDates,
        displayEffectiveDays: effDays,
        effectiveDaysInRangeVal: effDays !== effDaysInRange && !isEffectiveDaysOverriden ? effDaysInRange : undefined,
        isEffectiveDaysOverriden,
        totalDays: days,
        displayStatus: absenceStatus!,
      };

      return generatedAbsence;
    });
    return _.sortBy(newList, (a) => a.startDate);
  };

  getFilteredAbsences = () => {
    // TODO use selector here
    const { users, sessionInfo, usersWithSharedBranch } = this.props;
    const {
      startDate,
      endDate,
      selectedTypeId,
      userFilter,
      branchFilter,
      jobPositionFilter,
      statusFilter,
    } = this.state;
    return this.props.absences
      .filter((a) => !selectedTypeId || a.typeId === selectedTypeId)
      .filter((a) => !statusFilter || a.status === statusFilter)
      .filter((a) => !userFilter || a.userId === userFilter)
      .filter((a) => !branchFilter || users.find((u) => u.id === a.userId)!.branchIds.includes(branchFilter))
      .filter(
        (a) => !jobPositionFilter || users.find((u) => u.id === a.userId)!.jobPositionIds.includes(jobPositionFilter)
      )
      .filter((a) => rangesOverlap(startDate, endDate, a.startDate, a.endDate))
      .filter((a) => sessionInfo.isAdmin() || usersWithSharedBranch.includes(a.userId));
  };

  updateFilterSettings = (settings: Partial<State>) => {
    this.setState(settings as State);
  };

  getAbsenceDatesDisplay = (absence: IAbsence) => {
    const { startDate, endDate } = absence;
    const startDateMom = moment(startDate as string, SDateFormat);
    const endDateMom = moment(endDate as string, SDateFormat);

    const startDateFormated = startDateMom.format(localizeFormat("DD.MM.YY"));
    const endDateFormated = endDateMom.format(localizeFormat("DD.MM.YY"));

    return startDate === endDate ? endDateFormated : startDateFormated + "  - " + endDateFormated;
  };

  renderAbsenceTypeBadge = (absence: AbsenceRow) => {
    const absenceColor = this.props.abseneTypes.find((a) => absence.typeCode === a.code)?.color;
    return (
      <div className="absenceTypeBadge" style={{ background: absenceColor }}>
        {absence.displayType}
      </div>
    );
  };

  renderAbsenceStatus = (absence: AbsenceRow) => {
    const iconType = absence.status === AbsenceStatus.active ? "check" : "ellipsis";
    const iconColor = absence.status === AbsenceStatus.active ? "#8bc34a" : "#ff9800";
    return (
      <div>
        <Icon type={iconType} style={{ marginRight: "10px", color: iconColor }} />
        {absence.displayStatus}
      </div>
    );
  };

  tableColumns: Column[] = [
    {
      Header: lg.mitarbeiter,
      accessor: "userName",
      // Footer: (
      //   <span>
      //     <strong>Summe:</strong>
      //   </span>
      // ),
    },
    {
      Header: lg.Typ,
      accessor: "displayType",
      Cell: (props) => {
        return this.renderAbsenceTypeBadge(props.original);
      },
    },
    {
      Header: lg.Zeitraum,
      accessor: "startDate",
      Cell: (cell: any) => this.getAbsenceDatesDisplay(cell.original),
    },
    {
      Header: lg.Tage,
      accessor: "totalDays",
      width: 100,
      // Footer: props => (
      //   <span>
      //     <strong>
      //       {this.getFilteredAbsences()
      //         .map(a => getTotalDaysCount(a))
      //         .reduce((v, acc) => v + acc, 0)}
      //     </strong>
      //   </span>
      // ),
    },
    {
      Header: lg.effektive_tage,
      accessor: "displayEffectiveDays",
      width: 140,
      Cell: (props) => {
        const startDate = toMoment(this.state.startDate).format("DD MMMM");
        const endDate = toMoment(this.state.endDate).format("DD MMMM");
        const interval = startDate + " > " + endDate;
        return (
          <div className="effDaysCell">
            <div className="base">{props.original.displayEffectiveDays}</div>
            {props.original.effectiveDaysInRangeVal && (
              <div className="extra" data-rh={lg.effektive_tage_innerhalb_des_gewählten_zeitraums + " " + interval}>
                {props.original.effectiveDaysInRangeVal}
              </div>
            )}
            {props.original.isEffectiveDaysOverriden && (
              <div
                className="extra"
                data-rh={
                  lg.effektive_tage_wurden_überschrieben_dadurch_ist_keine_genau_angabe_der_effektiven_tage_im_gewählten_zeitraum_möglich
                }
              >
                !
              </div>
            )}
          </div>
        );
      },
      // Footer: props => (
      //   <span>
      //     <strong>
      //       {this.getFilteredAbsences()
      //         .map(a => this.getEffctiveDays(a.effectiveDays))
      //         .reduce((v, acc) => v + acc, 0)}
      //     </strong>
      //   </span>
      // ),
    },
    {
      Header: lg.Status,
      accessor: "displayStatus",
      width: 160,
      Cell: (props) => {
        return this.renderAbsenceStatus(props.original);
      },
    },
  ];

  getTrProps = (_: any, rowInfo: RowInfo) => {
    const updatedAbsence = this.props.absences.find((a) => a.id === rowInfo.original.id);
    return {
      onClick: () => {
        if (!this.props.features.absences) {
          this.props.dispatch(paidFeatureWarning());
          return;
        }
        this.props.dispatch(
          openModal(AbsenceModal, {
            absence: updatedAbsence,
            userId: rowInfo.original.userId,
          })
        );
      },
      style: { cursor: "pointer" },
    };
  };

  render() {
    const absenceList = this.generateAbsenceList();
    return (
      <div className="absenceListPage">
        <div className="content">
          <div className="actionBar">
            <AbsenceListPageActionBar
              type="detailed"
              settings={this.state}
              updateSettings={this.updateFilterSettings}
              exportCsv={() => {}}
              exportExcel={() => {}}
              exportPdf={() =>
                this.props.dispatch(exportAbsenceList(absenceList, this.state.startDate, this.state.endDate))
              }
            />
          </div>
          <div className="tableWrapper">
            <Table
              data={absenceList}
              columns={this.tableColumns}
              showPagination={false}
              pageSize={absenceList.length}
              getTrProps={this.getTrProps as any}
              //style={{ minHeight: 200 }}
              NoDataComponent={() => <div className="noData">{lg.keine_daten_im_gewählten_zeitraum_vorhanden}</div>}
            />
          </div>
        </div>
      </div>
    );
  }
}

const ConnectedComp = connect<StoreProps, DispatchBaseProps, StoreProps, AppState>(mapStateToProps)(
  _AbsenceListDetailed
);

export const AbsenceListDetailed = withErrorBoundary(ConnectedComp);
