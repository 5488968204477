import { SDateFormat } from "./SimpleTime";
import moment from "moment";
import { WeekDays } from "../constants/WeekDays";

export const getWeekDayString = (simpleDate: string) => WeekDays[getWeekDay(simpleDate)];

export const getWeekDay = (simpleDate: string) => {
  const day = parseInt(simpleDate.substr(8, 2));
  const firstDaySDate = simpleDate.substring(0, 8) + "01";
  const weekDayOfFirst = weekDaysList[firstDaySDate];
  if (!weekDayOfFirst) {
    return moment(simpleDate, SDateFormat).isoWeekday() - 1;
  }
  return ((weekDayOfFirst + day - 1) % 7 || 7) - 1;
};

const weekDaysList = {
  "2017-07-01": 6,
  "2017-08-01": 2,
  "2017-09-01": 5,
  "2017-10-01": 7,
  "2017-11-01": 3,
  "2017-12-01": 5,
  "2018-01-01": 1,
  "2018-02-01": 4,
  "2018-03-01": 4,
  "2018-04-01": 7,
  "2018-05-01": 2,
  "2018-06-01": 5,
  "2018-07-01": 7,
  "2018-08-01": 3,
  "2018-09-01": 6,
  "2018-10-01": 1,
  "2018-11-01": 4,
  "2018-12-01": 6,
  "2019-01-01": 2,
  "2019-02-01": 5,
  "2019-03-01": 5,
  "2019-04-01": 1,
  "2019-05-01": 3,
  "2019-06-01": 6,
  "2019-07-01": 1,
  "2019-08-01": 4,
  "2019-09-01": 7,
  "2019-10-01": 2,
  "2019-11-01": 5,
  "2019-12-01": 7,
  "2020-01-01": 3,
  "2020-02-01": 6,
  "2020-03-01": 7,
  "2020-04-01": 3,
  "2020-05-01": 5,
  "2020-06-01": 1,
  "2020-07-01": 3,
  "2020-08-01": 6,
  "2020-09-01": 2,
  "2020-10-01": 4,
  "2020-11-01": 7,
  "2020-12-01": 2,
  "2021-01-01": 5,
  "2021-02-01": 1,
  "2021-03-01": 1,
  "2021-04-01": 4,
  "2021-05-01": 6,
  "2021-06-01": 2,
  "2021-07-01": 4,
  "2021-08-01": 7,
  "2021-09-01": 3,
  "2021-10-01": 5,
  "2021-11-01": 1,
  "2021-12-01": 3,
  "2022-01-01": 6,
  "2022-02-01": 2,
  "2022-03-01": 2,
  "2022-04-01": 5,
  "2022-05-01": 7,
  "2022-06-01": 3,
  "2022-07-01": 5,
  "2022-08-01": 1,
  "2022-09-01": 4,
  "2022-10-01": 6,
  "2022-11-01": 2,
  "2022-12-01": 4,
  "2023-01-01": 7,
  "2023-02-01": 3,
  "2023-03-01": 3,
  "2023-04-01": 6,
  "2023-05-01": 1,
  "2023-06-01": 4,
  "2023-07-01": 6,
  "2023-08-01": 2,
  "2023-09-01": 5,
  "2023-10-01": 7,
  "2023-11-01": 3,
  "2023-12-01": 5,
  "2024-01-01": 1,
  "2024-02-01": 4,
  "2024-03-01": 5,
  "2024-04-01": 1,
  "2024-05-01": 3,
  "2024-06-01": 6,
  "2024-07-01": 1,
  "2024-08-01": 4,
  "2024-09-01": 7,
  "2024-10-01": 2,
  "2024-11-01": 5,
  "2024-12-01": 7,
  "2025-01-01": 3,
  "2025-02-01": 6,
  "2025-03-01": 6,
  "2025-04-01": 2,
  "2025-05-01": 4,
  "2025-06-01": 7,
  "2025-07-01": 2,
  "2025-08-01": 5,
  "2025-09-01": 1,
  "2025-10-01": 3,
  "2025-11-01": 6,
  "2025-12-01": 1,
  "2026-01-01": 4,
  "2026-02-01": 7,
  "2026-03-01": 7,
  "2026-04-01": 3,
  "2026-05-01": 5,
  "2026-06-01": 1,
  "2026-07-01": 3,
  "2026-08-01": 6,
  "2026-09-01": 2,
  "2026-10-01": 4,
  "2026-11-01": 7,
  "2026-12-01": 2,
  "2027-01-01": 5,
  "2027-02-01": 1,
  "2027-03-01": 1,
  "2027-04-01": 4,
  "2027-05-01": 6,
  "2027-06-01": 2,
  "2027-07-01": 4,
  "2027-08-01": 7,
  "2027-09-01": 3,
  "2027-10-01": 5,
  "2027-11-01": 1,
  "2027-12-01": 3,
  "2028-01-01": 6,
  "2028-02-01": 2,
  "2028-03-01": 3,
  "2028-04-01": 6,
  "2028-05-01": 1,
  "2028-06-01": 4,
  "2028-07-01": 6,
  "2028-08-01": 2,
  "2028-09-01": 5,
  "2028-10-01": 7,
  "2028-11-01": 3,
  "2028-12-01": 5,
  "2029-01-01": 1,
  "2029-02-01": 4,
  "2029-03-01": 4,
  "2029-04-01": 7,
  "2029-05-01": 2,
  "2029-06-01": 5,
  "2029-07-01": 7,
  "2029-08-01": 3,
  "2029-09-01": 6,
  "2029-10-01": 1,
  "2029-11-01": 4,
  "2029-12-01": 6,
  "2030-01-01": 2,
  "2030-02-01": 5,
  "2030-03-01": 5,
  "2030-04-01": 1,
  "2030-05-01": 3,
  "2030-06-01": 6,
  "2030-07-01": 1,
  "2030-08-01": 4,
  "2030-09-01": 7,
  "2030-10-01": 2,
  "2030-11-01": 5,
} as any;
