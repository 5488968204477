import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Icon } from "antd";
import cn from "classnames";
import { useSelector } from "../../helpers/redux";

type Props = {};

export const PrintModeLoader = (props: Props) => {
  const printMode = useSelector((s) => s.ui.printMode);

  if (!printMode) {
    return null;
  }

  return (
    <div
      className={cn({
        printModeLoadingOverlay: true,
        isVisibleAnimate: true,
      })}
    >
      <Icon
        type="loading"
        style={{
          fontSize: "130px",
          color: "white",
          marginBottom: 80,
        }}
        spin
      />
      <h1 style={{ fontWeight: 100 }}>{lg.einen_augenblick_bitte_ihr_ausdruck_wird_vorbereitet}</h1>
    </div>
  );
};
