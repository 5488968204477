import React from "react";
import { Moment } from "moment";
import { WeekdayHeader } from "../RosterWeekGrid/RosterWeekGridHead/WeekdayHeader/WeekdayHeader";
import { toSimpleDate } from "../../../../shared/helpers/timeHelpers";
import { useSelector } from "../../../../helpers/redux";

type Props = {
  startDate: string;
  weekdays: Moment[];
};

export const KanbanWeekdaysHeaderRow = React.memo((props: Props) => {
  const isTemplateMode = useSelector((s) => s.ui.shifts.roster.rosterTemplateMode.active);
  return (
    <div className="kanbanWeekdaysHeaderRow">
      <div className="weekdayHeaderContent">
        {props.weekdays.map((weekday, i) => (
          <div className="WeekdayHeaderWrapper" key={i}>
            <WeekdayHeader
              date={toSimpleDate(weekday)}
              key={i}
              isTemplate={isTemplateMode}
              isKanbanHeader={true}
            />
          </div>
        ))}
      </div>
    </div>
  );
});
