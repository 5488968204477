import React from "react";
import { Icon, Button } from "antd";
import { useDispatch } from "react-redux";
import "./styles.scss";
import { openModal } from "../../../../../actions/modal";
import UserDetailsModal from "../../../../Users/UserDetailsModal/UserDetailsModal";
import { useSelector } from "../../../../../helpers/redux";
import cn from "classnames";
import { selectIsFreshTenant } from "../../../../../selectors/selectIsFreshTenant";

export const OpenShiftsRowInfoIcon = () => {
  const isFreshTenant = useSelector(selectIsFreshTenant);

  if (!isFreshTenant) {
    return null;
  }

  return (
    <div
      className="userCellInfoIconWrapper"
      data-rh={lg.mitarbeiter_können_sich_auf_offene_schichten_bewerben_wenn_es_in_den_einstellungen_erlaubt_ist}
      data-rh-at="right"
    >
      <Icon type="info-circle" theme="filled" />
    </div>
  );
};
