import { ReportType } from "../../../actions/reporting";
import { DispFn } from "../../../frontend-core/types/thunkTypes";
import { WeekDay } from "../../../shared/constants/WeekDays";
import { AppState } from "../../../types/AppState";

export type ReportDataTypes = {
  shifts: boolean;
  absenceTypeIds: string[];
  creditCorrections: boolean;
  holidays: boolean;
  quota: boolean;
  overtime: boolean;
  hourAccount: boolean;
};

export const UPDATE_REPORT_DATA_TYPES = "UPDATE_REPORT_DATA_TYPES";
//
export const updateReportDataTypes = (payload: ReportDataTypes) => ({
  type: UPDATE_REPORT_DATA_TYPES,
  payload,
});

const initialState: ReportDataTypes = {
  shifts: true,
  absenceTypeIds: [],
  creditCorrections: true,
  holidays: true,
  quota: false,
  overtime: false,
  hourAccount: false,
};

export const reportDataTypesReducer = (state = initialState, a: any): ReportDataTypes =>
  a.type === UPDATE_REPORT_DATA_TYPES ? { ...state, ...a.payload } : state;
