import React, { useState } from "react";
import { useSelector } from "../../helpers/redux";
import { tierInfoSelector } from "../../selectors/TierInfoSelector";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { Icon } from "antd";

export const OverdueWarningBanner = React.memo(() => {
  const tierInfo = useSelector(tierInfoSelector);
  const sessionInfo = useSelector(selectSessionInfo);
  const [canBeOpen, setCanBeOpen] = useState(true);

  if (!sessionInfo.isManager() && !sessionInfo.isAdmin()) {
    return null;
  }

  if (!tierInfo.unpaidSinceOneWeek) {
    return null;
  }

  if (!canBeOpen && !tierInfo.unpaidSinceTwoWeeks) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        right: 0,
        left: 0,
        background: "red",
        color: "white",
        padding: 24,
        zIndex: 100,
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 16,
        paddingLeft: 100,
        paddingRight: 100,
      }}
    >
      {tierInfo.unpaidSinceTwoWeeks
        ? lg.leider_ist_eine_rechnung_seit_einer_längerer_zeit_überfällig_der_account_wurde_aus_diesem_grund_automatisch_eingeschränkt_bitte_begleichen_sie_die_offene_rechnung_um_den_account_wieder_freizuschalten
        : lg.leider_ist_eine_rechnung_überfällig_der_account_wird_demnächst_automatisch_eingeschränkt_falls_die_rechnung_nicht_beglichen_wird}

      {!tierInfo.unpaidSinceTwoWeeks && (
        <Icon
          onClick={() => setCanBeOpen(false)}
          type="close-circle"
          style={{ color: "white", marginLeft: 12, fontSize: 20, marginBottom: -12 }}
        ></Icon>
      )}
    </div>
  );
});
