import moment from "moment";
import React from "react";
import firebase from "firebase/compat/app";
import { connect } from "react-redux";
import "./styles.scss";
import { AppState } from "../../types/AppState";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import "./styles.scss";
import Page from "../../components/Page/Page";
import { Button, Modal, notification } from "antd";
import { authRepository } from "../../repositories/authRepository";
import { sitemap } from "../../helpers/sitemap";
import { withErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";
import { openModal } from "../../actions/modal";
import { tierInfoSelector } from "../../selectors/TierInfoSelector";
import { Tiers } from "../../shared/constants/Tiers";
import { DeleteTenantModal } from "../../components/DeleteTenantModal/DeleteTenantModal";
import { ProfilePictureEditor } from "../Users/UserDetailsModal/ProfilePictureEditor/ProfilePictureEditor";
import { openCrisp } from "../../helpers/crisp";
import AvOption from "../../components/AvOption/AvOption";
import { getLocale } from "../../helpers/dateFormatHelper";
import { push } from "connected-react-router";
import { CalendarSyncModal } from "../../components/CalendarSyncModal/CalendarSyncModal";
import { env } from "process";
import { envi } from "../../env";

// we are doing this, so stripe gets only injected when the user is going to the payment modal
const TierPicker = React.lazy(() => import("../../components/TierPicker/TierPicker") as any) as any;

const mapStateToProps = (state: AppState) => {
  return {
    sessionInfo: selectSessionInfo(state),
    branches: state.data.branches,
    jobPositions: state.data.jobPositions,
    tierInfo: tierInfoSelector(state),
    users: state.data.users,
    isV2: state.data.tenantInfo.isV2,
    tenantInfo: state.data.tenantInfo,
  };
};

type State = {
  clickedOnIndicatorCounter: number;
};
type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class MyProfileComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      clickedOnIndicatorCounter: 0,
    };
  }

  showEmailSentNotification = () => {
    notification.success({
      message: lg.email_gesendet,
      description: lg.eine_email_wurde_versendet_an + " " + this.props.sessionInfo.user.email,
    });
  };

  quitSubscriptionClicked = () => {
    notification.success({
      message: lg.kuendigung,
      description: lg.bitte_schreiben_sie_unserem_support,
    });
    openCrisp();
  };

  resetPasswordClicked = () => {
    Modal.confirm({
      title: lg.passwort_ändern,
      content: lg.zum_ändern_des_passwortes_erhalten_sie_einen_link_per_email,
      okText: lg.email_anfordern,
      onOk: async () => {
        await this.props.dispatch(
          authRepository.sendPasswordReset(
            this.props.sessionInfo.user.email!,
            `${window.location.origin}${sitemap.passwordReset.url}`,
            !!this.props.isV2
          )
        );
        this.showEmailSentNotification();
      },
      onCancel() {},
    });
  };

  renderBranches = () => {
    const userBranches = this.props.sessionInfo.user.branchIds.map(
      (bId) => this.props.branches.find((br) => br.id === bId)!.name
    );
    const userBranchTags = userBranches.map((branch) => {
      return (
        <div key={branch} className="userBranchMyProfile">
          {branch}
        </div>
      );
    });
    return userBranchTags;
  };

  renderPositions = () => {
    const userJobPositions = this.props.sessionInfo.user.jobPositionIds.map((jpId) =>
      this.props.jobPositions.find((jp) => jp.id === jpId)
    );

    const userJobPositionTags = userJobPositions.map((jP) => {
      return (
        <div className="userJpMyProfile" style={{ background: jP!.color }}>
          {jP!.name}
        </div>
      );
    });
    return userJobPositionTags;
  };

  clickOnIndicator = async () => {
    if (this.state.clickedOnIndicatorCounter === 2) {
      setTimeout(() => {
        this.setState({ clickedOnIndicatorCounter: 0 });
      }, 4000);
    }

    if (this.state.clickedOnIndicatorCounter === 4) {
      const db = firebase.database();
      await db.ref(`tenantInfos/${this.props.tenantInfo.id}/createdAt`).set(moment().toISOString());
    } else {
      this.setState({ clickedOnIndicatorCounter: this.state.clickedOnIndicatorCounter + 1 });
    }
  };

  render() {
    const isDemoUser = this.props.sessionInfo.user.email === "demo@avetiq.de";
    const isAdmin = this.props.sessionInfo.isAdmin();
    const canManage = this.props.sessionInfo.hasManagerPermissions();
    const user = this.props.sessionInfo.user;
    const { tierInfo } = this.props;
    const showBillingSettings = isAdmin && tierInfo.tier !== Tiers.Core && !tierInfo.isAlwaysFreePremiumTenant;

    const hasClockingId = !!this.props.sessionInfo.user.timeClockId;

    return (
      <Page>
        <div className="myProfileMain pagePadding">
          <h2 className="avTitle noIndicator" style={{ marginTop: 24 }}>
            <div className="indikator" onClick={this.clickOnIndicator}></div>
            {lg.mein_profil}
          </h2>
          <div className="fb row">
            <div style={{ maxWidth: 600, minWidth: 500 }}>
              {/* <AvFormField label={"Stempeluhr-ID"}>
              <Input disabled value={this.props.sessionInfo.user.timeClockId} />
            </AvFormField> */}
              <div className="mainInfoWrapper">
                <div className="infoWrapper">
                  <div className="infoHeader">{lg.name}</div>
                  <div className="infoText">{this.props.sessionInfo.user.name}</div>
                </div>
                <div className="infoWrapper">
                  <div className="infoHeader">{lg.email}</div>
                  <div className="infoText">{this.props.sessionInfo.user.email}</div>
                </div>
                <div className="infoWrapper">
                  <div className="infoHeader">{lg.standort}</div>
                  <div className="infoText">
                    <div className="userBranchesWrapperMyProfile">{this.renderBranches()}</div>
                  </div>
                </div>
                <div className="infoWrapper">
                  <div className="infoHeader">{lg.rolle}</div>
                  <div className="infoText">
                    <div className="userJpsWrapperMyProfile">{this.renderPositions()}</div>
                  </div>
                </div>
                {hasClockingId && (
                  <div className="infoWrapper">
                    <div className="infoHeader">{lg.stempeluhr_pin}</div>
                    <div className="infoText">{this.props.sessionInfo.user.timeClockId}</div>
                  </div>
                )}
                {!envi.isV2() && (
                  <div className="infoWrapper">
                    <div className="infoHeader">{lg.kalender_synchronisation}</div>
                    <Button
                      size="small"
                      id="calendar-syncing-button"
                      style={{ marginTop: 6, minWidth: 100 }}
                      type={this.props.sessionInfo.user.hasICalSyncing ? "default" : "primary"}
                      onClick={() => this.props.dispatch(openModal(CalendarSyncModal, {}))}
                      children={this.props.sessionInfo.user.hasICalSyncing ? lg.aktiv : lg.aktivieren}
                    />
                  </div>
                )}
              </div>

              <div className="infoWrapper">
                <div className="infoHeader">{lg.Sprache}</div>
                <AvOption
                  style={{ marginTop: 6 }}
                  options={[
                    {
                      label: "Deutsch",
                      value: "de",
                    },
                    {
                      label: "English",
                      value: "en-GB",
                    },
                    // {
                    //   label: "en-US",
                    //   value: "en-US",
                    // },
                  ]}
                  value={getLocale()}
                  onChange={(value: string) => {
                    localStorage.setItem("language", value);
                    window.location.reload();
                  }}
                ></AvOption>
              </div>

              <div className="infoWrapper">
                <div className="infoHeader">{lg.passwort}</div>
                <Button
                  style={{ minWidth: 100, marginTop: 6, marginBottom: 6 }}
                  id="change-password"
                  children={lg.passwort_ändern}
                  onClick={this.resetPasswordClicked}
                />
              </div>

              {isAdmin && !this.props.tierInfo.isAlwaysFreePremiumTenant && (
                <div className="adminInfoWrapper">
                  <div className="infoHeader">{lg.plan}</div>
                  <div className="infoText">
                    {this.props.tierInfo.isTrialMode
                      ? lg.days_tage_pro_testphase(this.props.tierInfo.daysUntilTrialEnd)
                      : this.props.tierInfo.tier}
                  </div>
                  {this.props.tierInfo.tier === Tiers.Core && (
                    <>
                      <Button
                        id="pick-a-paid-plan-button"
                        style={{ marginTop: 6, minWidth: 100 }}
                        type="primary"
                        onClick={() => this.props.dispatch(openModal(TierPicker, {}))}
                      >
                        {lg.zum_basic_oder_pro_plan_wechseln}
                      </Button>
                      {this.props.sessionInfo.isAdmin() && (
                        <Button
                          style={{ marginTop: 12, marginLeft: 12, marginBottom: 6, minWidth: 100 }}
                          id="delete-tenant"
                          type="danger"
                          children={lg.konto_löschen}
                          onClick={() => this.props.dispatch(openModal(DeleteTenantModal, {}))}
                        />
                      )}
                    </>
                  )}

                  {showBillingSettings && (
                    <Button
                      style={{ marginTop: 6, minWidth: 100 }}
                      id="go-to-subscription-button"
                      type="primary"
                      size="large"
                      children={lg.abo_ändern}
                      onClick={() => this.props.dispatch(push(sitemap.billingSettings.url))}
                    />
                  )}
                </div>
              )}
            </div>
            <div className="whiteBox" style={{ marginLeft: 12, alignSelf: "flex-start" }}>
              <ProfilePictureEditor user={user} />
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export const MyProfile = withErrorBoundary(
  connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(MyProfileComponent)
);
