import { IModal, modalActionTypes } from "../../../actions/modal";
import _ from "lodash";

type ModalState = IModal[];

export const modalReducer = (state: ModalState = [], action: any): ModalState => {
  switch (action.type) {
    case modalActionTypes.OPEN:
      return [...state, action.payload];
    case modalActionTypes.CLOSE:
      if (action.payload.id) {
        return state.filter((m) => m.id !== action.payload.id);
      } else {
        return state.slice(0, state.length - 1);
      }
    default:
      return state;
  }
};
