import React, { useMemo, useState, useEffect } from "react";
import moment, { Moment } from "moment";
import { DayCell } from "./DayCell/DayCell";
import "./styles.scss";
import { getMonthMomentDates, closestWithAttribute } from "../../../../../helpers/general";
import { toSimpleDate } from "../../../../../shared/helpers/timeHelpers";
import { useSelector } from "../../../../../helpers/redux";
import { useDispatch, batch } from "react-redux";
import { setRosterMode, RosterMode } from "../../../../../reducers/ui/shifts/roster/rosterMode";
import { selectWeek } from "../../../../../reducers/ui/shifts/roster/selectedWeek";
import _ from "lodash";
import { UserFilterInput } from "../../components/UserFilterInput/UserFilterInput";
import { SortOrderButton } from "../../RosterActionBar/SortOrderButton/SortOrderButton";
import { selectRosterDateRange } from "../../../../../selectors/rosterDateRangeSelector";
import { selectDailyNotesByDate } from "../../../../../selectors/dailyNotesByDateSelector";
import { openModal } from "../../../../../actions/modal";
import { DailyNoteModal } from "../../components/DailyNoteModal/DailyNoteModal";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { selectVisibleBranches } from "../../../../../selectors/VisibleBranchesSelector";
import { selectHolidayFinder } from "../../../../../selectors/holidayMapSelector";

export const DAY_WIDTH = 32;

export const RosterMonthGridHead = React.memo(() => {
  const dispatch = useDispatch();
  const sessionInfo = useSelector(selectSessionInfo);
  const holidayFinder = useSelector(selectHolidayFinder);
  const publishedWeeks = useSelector((s) => s.data.publishedWeeks);
  const selectedBranchId = useSelector((s) => s.ui.selectedBranch);
  const isV2 = useSelector((s) => s.data.tenantInfo.isV2);
  const dailyNotesByDate = useSelector(selectDailyNotesByDate);
  const { rangeStart, rangeEnd } = useSelector(selectRosterDateRange);
  const monthStartDay = useSelector((s) => s.ui.general.rosterCustomMonthStartDay);
  const today = toSimpleDate(moment());
  const canManage = sessionInfo.hasManagerPermissions();
  const dates = useMemo(() => getMonthMomentDates(rangeStart) as Moment[], [rangeStart]);

  const visibleBranches = useSelector(selectVisibleBranches);

  useEffect(() => {
    addClickListener();

    return () => removeClickListener();
  }, []);

  const redirectToWeekView = (cw: string) => {
    batch(() => {
      dispatch(setRosterMode(RosterMode.Week));
      dispatch(selectWeek(cw));
    });
  };

  const addClickListener = () => {
    canManage && document.addEventListener("click", clickDetected);
  };

  const removeClickListener = () => {
    canManage && document.removeEventListener("click", clickDetected);
  };

  const clickDetected = (e: any) => {
    if (!canManage) {
      return;
    }
    const btn = closestWithAttribute(e.target, "data-type", "month-grid-add-daily-note");
    const date = btn?.getAttribute("data-add-daily-note-date");
    date && dispatch(openModal(DailyNoteModal, { date }));
  };

  return (
    <div className="rosterCalendarHeadMain">
      <div className="header cell firstColumn cornerCell">
        <div className="fb row rosterOptionsWrapper">
          <UserFilterInput />
          <SortOrderButton />
        </div>
      </div>
      <div className="monthHead">
        {dates.map((mom, idx) => {
          const date = toSimpleDate(mom);
          const holiday = holidayFinder(date, selectedBranchId);

          const publishedBranches = visibleBranches.filter(
            (b) => isV2 || !!publishedWeeks.find((wp) => wp.branchId === b.id && wp.startDate === date && wp.published)
          );

          const isWeekPublished =
            isV2 ||
            !!publishedWeeks.find(
              (w) => w.startDate <= date && w.endDate >= date && selectedBranchId === w.branchId && w.published
            );

          const displayGreenEye = selectedBranchId
            ? isWeekPublished
            : publishedBranches.length === visibleBranches.length;

          return (
            <DayCell
              key={idx}
              width={DAY_WIDTH}
              mom={mom}
              isNow={today === date}
              daysInMonth={dates.length}
              holiday={holiday ? holiday.name : null}
              selectedBranchId={selectedBranchId}
              publishedBranches={publishedBranches}
              displayGreenEye={displayGreenEye}
              dispatch={dispatch}
              showMonth={monthStartDay !== 1}
              isFirstDay={rangeStart === date}
              isLastDay={rangeEnd === date}
              dailyNotes={dailyNotesByDate[date] || []}
              canManage={canManage}
              redirectToWeekView={() => redirectToWeekView(toSimpleDate(mom.startOf("isoWeek")))}
              isV2={isV2}
              isInFuture={date > today}
            />
          );
        })}
      </div>
    </div>
  );
});
