import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../types/AppState";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import { getActiveUsersAtDate } from "../../selectors/ActiveUserSelectors";
import { toSimpleDate } from "../../shared/helpers/timeHelpers";
import moment from "moment";
import { ITimeClockSettings } from "../../shared/entities/ITimeClockSettings";
import "./styles.scss";
import { RuleSpecificationModal } from "../RuleSpecificationModal/RuleSpecificationModal";
import { openModal } from "../../actions/modal";
import { Icon } from "antd";
import cn from "classnames";
import { isRuleApplyingToUser } from "../../shared/helpers/settingsHelpers";
import { IRuleSpecification } from "../../shared/entities/IRuleSpecification";
import { IRosterSettings } from "../../shared/entities/IRosterSettings";

const mapStateToProps = (state: AppState) => ({
  timeClockSettings: state.data.timeClockSettings[0],
  rosterSettings: state.data.rosterSettings[0],
  users: state.data.users,
});

type OwnProps = {
  ruleKey: keyof IRosterSettings | keyof ITimeClockSettings;
  type: "RosterSettings" | "TimeClockSettings";
  style?: React.CSSProperties;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _RuleSpecificationBtn extends React.PureComponent<Props> {
  render() {
    const { ruleKey, dispatch, timeClockSettings, rosterSettings, users, type } = this.props;
    let _specifications = {} as any;
    type === "RosterSettings" && (_specifications = rosterSettings.specifications || {});
    type === "TimeClockSettings" && (_specifications = timeClockSettings.specifications || {});

    const specification = _specifications[ruleKey] as IRuleSpecification;
    const activeUsers = getActiveUsersAtDate(users, toSimpleDate(moment()));
    const includedUsers = activeUsers.filter((u) => isRuleApplyingToUser(specification, u));
    const countText = `${includedUsers.length} / ${activeUsers.length}`;

    const validForText = specification
      ? lg.regel_gilt_nur_für_einen_teil_der_mitarbeiter
      : lg.regel_gilt_für_alle_mitarbeiter;

    return (
      <div
        className={cn({
          ruleSpecificationsButtonMain: true,
          active: !!specification,
        })}
        style={this.props.style || {}}
        onClick={() => dispatch(openModal(RuleSpecificationModal, { ruleKey, type }))}
        data-rh={validForText}
      >
        <div>{specification ? countText : lg.Alle}</div>
        <Icon type="user" />
      </div>
    );
  }
}

export const RuleSpecificationBtn = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  _RuleSpecificationBtn
);
