import React from "react";
import { connect, batch } from "react-redux";
import { AppState } from "../../../../../../types/AppState";
import { DispatchBaseProps } from "../../../../../../frontend-core/types/DispatchBaseProps";
import { Button, Icon, DatePicker } from "antd";
import "./styles.scss";
import _ from "lodash";
import moment, { Moment } from "moment";
import ButtonGroup from "antd/lib/button/button-group";
import { toSimpleDate, toMomentUnsafe, toMoment } from "../../../../../../shared/helpers/timeHelpers";
import { selectWeek } from "../../../../../../reducers/ui/shifts/roster/selectedWeek";
import { selectDay } from "../../../../../../reducers/ui/shifts/roster/selectedDay";
import { selectMonth } from "../../../../../../reducers/ui/shifts/roster/selectedMonth";
import { updateSelectedDate } from "../helpers";
import cn from "classnames";
import { SDateFormat } from "../../../../../../shared/helpers/SimpleTime";
import { addSimpleDays } from "../../../../../../shared/helpers/dateHelpers";

const mapStateToProps = (state: AppState) => {
  return {
    selectedWeekFirstDay: state.ui.shifts.roster.selectedWeek,
    isV2: state.data.tenantInfo.isV2,
  };
};

type State = {
  weekPickerIsOpen: boolean;
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _WeekStepper extends React.PureComponent<Props, State> {
  today: string;
  constructor(props: Props) {
    super(props);
    this.today = moment().format(SDateFormat);
    this.state = {
      weekPickerIsOpen: false,
    };
  }

  step = (step: number) => {
    const { selectedWeekFirstDay } = this.props;
    const newDate = toSimpleDate(toMoment(selectedWeekFirstDay).add(step, "weeks"));
    this.props.dispatch(updateSelectedDate(newDate));
  };

  render() {
    const { selectedWeekFirstDay, isV2 } = this.props;
    const isoWeekYear = toMoment(selectedWeekFirstDay).isoWeekYear();
    const currentYear = moment().year();
    const showYear = isoWeekYear !== currentYear;

    const canStepForward = !isV2 || addSimpleDays(selectedWeekFirstDay, 7) <= this.today;

    return (
      <div className="selectedWeekBox">
        <span className="cw" onClick={() => this.setState({ weekPickerIsOpen: !this.state.weekPickerIsOpen })}>
          <div className="fb row">
            <div className="labelWrapper">
              <div className={cn({ calendarWeek: true, mini: showYear })}>{lg.kw_stepper}</div>
              {showYear && <div className="year">{isoWeekYear}</div>}
            </div>
            <span className="number">{toMomentUnsafe(selectedWeekFirstDay)!.isoWeek()}</span>
          </div>
        </span>
        <span className="arrow-icons">
          <ButtonGroup>
            <Button id="roster-prev-week" onClick={() => this.step(-1)}>
              <Icon type="left" />
            </Button>
            <Button id="roster-next-week" onClick={() => this.step(1)} disabled={!canStepForward}>
              <Icon type="right" />
            </Button>
          </ButtonGroup>
        </span>
        <DatePicker.WeekPicker
          open={this.state.weekPickerIsOpen}
          onChange={(m) => this.props.dispatch(updateSelectedDate(toSimpleDate(m as Moment)))}
          placeholder={lg.woche_auswählen}
          value={toMomentUnsafe(selectedWeekFirstDay)!}
          allowClear={false}
          format={"DD. MMM YYYY"}
          onOpenChange={(v) => this.setState({ weekPickerIsOpen: v })}
          disabledDate={(m) => !!(m && m.year() > 2999)}
          id="rosterWeekPicker"
        />
      </div>
    );
  }
}

export const WeekStepper = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(_WeekStepper);
