import React from "react";
import "./styles.scss";

type PropsToPass = Record<string, any>;

type Props = {
  errorMessage?: string | false;
  label?: string;
  description?: string;
  wrapperClassName?: string;
  isRequired?: boolean;
  extraBox?: React.ReactNode;
} & PropsToPass;

export default class AvFormField extends React.PureComponent<Props, {}> {
  render() {
    const { label, errorMessage, isRequired, description, extraBox } = this.props;

    return (
      <div className={`avInputFieldMain ${this.props.wrapperClassName ? this.props.wrapperClassName : ""}`}>
        <div className="labelWrapper">
          {label && <label>{label}</label>}
          {isRequired && <span style={{ color: "red", marginLeft: 3 }}>*</span>}
          {description && <div className="description">{description}</div>}
          {extraBox ? extraBox : null}
        </div>

        {this.props.children}

        {errorMessage && <div className="errorMessage">{errorMessage}</div>}
      </div>
    );
  }
}
