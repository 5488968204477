import React from "react";

import "./styles.scss";
import { CreditEntryBar } from "../CreditEntryBar/CreditEntryBar";

import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { AppState } from "../../../../../types/AppState";
import { connect } from "react-redux";
import { openModal } from "../../../../../actions/modal";
import AbsenceModal from "../../../../../components/modals/AbsenceModal/AbsenceModal";
import { IAbsenceType } from "../../../../../shared/entities/IAbsenceType";
import { IAbsence } from "../../../../../shared/entities/IAbsence";
import { paidFeatureWarning } from "../../../../../actions/paidFeatureWarning";
import { featuresSelector } from "../../../../../selectors/FeaturesSelector";

const mapStateToProps = (state: AppState) => ({
  features: featuresSelector(state),
});

type OwnProps = {
  creditInAbsence: number;
  absenceType: IAbsenceType;
  absence: IAbsence;
  reloadCredits?: () => void;
  date: string;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class CreditAbsenceEntryComp extends React.PureComponent<Props> {
  onClick = () => {
    if (!this.props.features.absences) {
      this.props.dispatch(paidFeatureWarning());
      return;
    }
    this.props.dispatch(
      openModal(AbsenceModal, {
        absence: this.props.absence,
        userId: this.props.absence.userId,
        onUpdateComplete: this.props.reloadCredits,
      })
    );
  };

  render() {
    const { absence, date, creditInAbsence } = this.props;

    const isHalfDay =
      (absence.startsHalfDay && date === absence.startDate) || (absence.endsHalfDay && date === absence.endDate);

    return (
      <CreditEntryBar
        label={isHalfDay ? lg.halbtags : undefined}
        minutes={creditInAbsence}
        tag={this.props.absenceType.name}
        tagColor={this.props.absenceType.color}
        onClick={this.onClick}
      />
    );
  }
}
export const CreditAbsenceEntry =
  connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(CreditAbsenceEntryComp);
