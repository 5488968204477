import React from 'react';
import omit from 'lodash/omit';

import './styles.scss';
import { Icon } from 'antd';

type Props = {
  isBusy?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const BusyWrapper = (props: Props) => {
  return (
    <div
      {...omit(props, ['isBusy'])}
      className={`busyWrapper ${props.className ? props.className : ''}`}
    >
      {props.isBusy ? (
        <div className={`overlay ${props.isBusy ? 'showLoader' : ''}`}>
          <Icon type="loading" style={{ fontSize: '40px', color: '#1990ff' }} spin />
        </div>
      ) : null}
      {props.children}
    </div>
  );
};

export default BusyWrapper;
