// this is rarely used if the user wants the month view to go from e.g: 16.SEP > 15.NOV
// in that case the customMonthStartDay is 15

export const SHIFTS_CUSTOM_MONTH_START_DAY_SELECT =
  "SHIFTS_CUSTOM_MONTH_START_DAY_SELECT";

export const selectCustomMonthStartDay = (day: number) => ({
  type: SHIFTS_CUSTOM_MONTH_START_DAY_SELECT,
  payload: day,
});

const initialState = 1;

export const rosterMonthStartDayReducer = (state = initialState, a: any): string =>
  a.type === SHIFTS_CUSTOM_MONTH_START_DAY_SELECT ? a.payload : state;
