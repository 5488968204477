import React, { PureComponent } from "react";
import { Icon } from "antd";
import cn from "classnames";
import "./styles.scss";
import { IContingentCorrection } from "../../../../../../shared/entities/IContingentCorrection";

const withSign = (num: number) => {
  const sign = num === 0 ? "" : num < 0 ? "-" : "+";
  return `${sign} ${Math.abs(num)}`;
};

type Props = {
  column: number;
  value?: number;
  correction?: IContingentCorrection;
  correctionClicked: () => void;
};

export default class TakenDaysCell extends PureComponent<Props, {}> {
  render() {
    const { value, correction, correctionClicked, column } = this.props;

    const columnClassName = `vacAccCol${column}`;

    return (
      <div className={"noEdit vacAccountCell vacAccCol takenDaysMain " + columnClassName}>
        <div className="vacAccCellWrap">
          <div className=" daysContent">
            <div className="value">{value}</div>
          </div>
          <div className="addCorrectionWrapper">
            {correction ? (
              <div className={cn({ isNegative: correction.days < 0, correctionBox: true })} onClick={correctionClicked}>
                {withSign(correction.days)}
              </div>
            ) : (
              <div className="editIcon" onClick={correctionClicked} data-rh={lg.korrektur_hinzufügen}>
                <Icon type="form" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
