import React from "react";
import { Icon } from "antd";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../../../helpers/redux";
import { shiftsOfRowSelector } from "../../../../../selectors/RelevantShiftsSelector";
import _ from "lodash";
import "./styles.scss";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";
import { setOpenShiftsCollapsed } from "../../../../../reducers/ui/shifts/roster/isOpenShiftsCollapsed";
import cn from "classnames";
import { setRequiredShiftsCollapsed } from "../../../../../reducers/ui/shifts/roster/isRequiredShiftsCollapsed";

type Props = {
  isRequirement: boolean;
};

export const RosterRowCollapseButton = React.memo(({ isRequirement }: Props) => {
  const dispatch = useDispatch<DispFn>();

  const isOpenShiftsCollapsed = useSelector((s) => s.ui.shifts.roster.isOpenShiftsCollapsed);
  const isRequiredShiftsCollapsed = useSelector((s) => s.ui.shifts.roster.isRequiredShiftsCollapsed);
  const isCollapsed = isRequirement ? isRequiredShiftsCollapsed : isOpenShiftsCollapsed;

  //const visibleShifts = useSelector((s) => shiftsOfRowSelector(s, undefined, undefined, isRequirement));
  //const hasTwoInSameDay = visibleShifts.some((s) => visibleShifts.some((_s) => s.date === s.date && _s.id !== s.id));

  // if (!hasTwoInSameDay) {
  //   return null;
  // }

  return (
    <div
      className={cn({ expandOpenShiftsIcon: true, isCollapsed })}
      data-rh={isCollapsed ? "expandieren" : "minimieren"}
      onClick={() =>
        dispatch(isRequirement ? setRequiredShiftsCollapsed(!isCollapsed) : setOpenShiftsCollapsed(!isCollapsed))
      }
    >
      {isCollapsed ? <Icon type="column-height" /> : <Icon type="vertical-align-middle" />}
    </div>
  );
});
