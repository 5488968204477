import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { Button, Icon, Input } from "antd";
import TZModal from "../../TZModal/TZModal";

import { closeModal } from "../../../actions/modal";
import { Modal } from "antd";
import { Omit } from "lodash";
import { creditCorrectionRepository } from "../../../repositories/creditCorrectionRepository";

import "./styles.scss";

import AvOption from "../../AvOption/AvOption";
import { BusyInjectorProps, busyInjector } from "../../BusyInjector/BusyInjector";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import DurationInput from "../../../pages/Users/UserDetailsModal/ContractEntry/DurationInput/DurationInput";
import { ICreditCorrection, CreditCorrectionType } from "../../../shared/entities/ICreditCorrection";
import { simpleDateToMoment } from "../../../shared/helpers/timeHelpers";
import * as Sentry from "@sentry/browser";

const mapStateToProps = (state: AppState) => ({});

type State = {
  date: string;
  minutesAbsolute?: number;
  note?: string;
  isPositive: boolean;
};

type OwnProps = {
  onUpdateComplete?: () => void;
  creditCorrection?: ICreditCorrection;
  date: string;
  userId: string;
  type: CreditCorrectionType;
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & BusyInjectorProps & DispatchBaseProps;

class CreditCorrectionModalComponent extends PureComponent<Props, State> {
  isCreationMode = true;

  constructor(props: Props) {
    super(props);

    const { creditCorrection } = this.props;
    this.isCreationMode = !creditCorrection;

    this.state = {
      minutesAbsolute: creditCorrection && Math.abs(creditCorrection.minutes),
      note: creditCorrection && creditCorrection.note,
      date: this.props.date,
      isPositive: !creditCorrection || creditCorrection.minutes >= 0,
    };

    Sentry.addBreadcrumb({
      message: "opening CreditCorrectionModalComponent",
      data: { date: props.date, userId: props.userId, type: props.type },
    });
  }

  onChange = (minutesAbsolute: number) => {
    this.setState({ minutesAbsolute });
  };

  save = async () => {
    if (this.state.minutesAbsolute === undefined) {
      return;
    }

    const { minutesAbsolute, note, date, isPositive } = this.state;
    const { userId, type } = this.props;

    Sentry.addBreadcrumb({
      message: "creditCorrectionsModal: save clicked",
      data: { minutesAbsolute, note, date, isPositive, userId, type },
    });

    const minutes = isPositive ? minutesAbsolute : -minutesAbsolute;

    const newContingent: Partial<ICreditCorrection> = this.isCreationMode
      ? { userId, date, note, minutes, type }
      : { ...this.props.creditCorrection!, date, note, minutes };

    await this.props.dispatch(
      this.isCreationMode
        ? creditCorrectionRepository.create(newContingent as Omit<ICreditCorrection, "id">)
        : creditCorrectionRepository.update(newContingent as ICreditCorrection)
    );

    if (this.props.onUpdateComplete) {
      this.props.onUpdateComplete();
    }

    // TODO wait for promise and show loadingSpinner meanwhile
    this.props.dispatch(closeModal());
  };

  deleteClicked = () => {
    Modal.confirm({
      title: lg.korrektur_löschen,
      content: lg.diese_korrekutr_wirklich_löschen,
      onOk: () => {
        this.delete();
      },
      onCancel: () => {},
      okText: lg.löschen,
      cancelText: lg.abbrechen,
    });
  };

  delete = async () => {
    await this.props.dispatch(creditCorrectionRepository.remove(this.props.creditCorrection!.id));
    if (this.props.onUpdateComplete) {
      this.props.onUpdateComplete();
    }
    this.props.dispatch(closeModal());
  };

  getTitle = () => {
    switch (this.props.type) {
      case CreditCorrectionType.manual:
        return lg.korrektur;
      case CreditCorrectionType.initial:
        return lg.stundenkonto_startwert;
    }
  };

  render() {
    const isInitial = this.props.type === CreditCorrectionType.initial;

    return (
      <TZModal>
        <TZModal.Head title={this.getTitle()}></TZModal.Head>
        <TZModal.Body>
          <div className="creditCorrectionModalComponentMain">
            <div className="row">
              <div className="label">{`${simpleDateToMoment(this.state.date).format("dddd / DD MMM YYYY")}`}</div>
            </div>
            <div className="row withLine">
              <div className="hoursInputWrapper">
                <div className="signWrapper">
                  <AvOption
                    value={this.state.isPositive}
                    options={[
                      { value: true, label: "+" },
                      { value: false, label: "-" },
                    ]}
                    buttonStyle="solid"
                    onChange={(isPositive) => this.setState({ isPositive })}
                  />
                </div>
                <DurationInput
                  value={this.state.minutesAbsolute}
                  onChange={this.onChange}
                  size={"default"}
                  inputWidth={60}
                  hoursPlaceholder={lg.std}
                  minutesPlaceholder={lg.min}
                  autoFocus
                  showZero
                />
                {/* <InputNumber
                  min={0}
                  decimalSeparator={decimalSeparatorLocal}
                  autoFocus={true}
                  value={this.state.hours}
                  onChange={this.onChange}
                  style={{
                    fontWeight: 500,
                    color: this.state.isPositive ? '#1a90ff' : 'red',
                  }}
                /> */}
                {/* <div className="unit">h | min</div> */}
              </div>
            </div>
            <div className="row withLine">
              {isInitial ? (
                <div className="initialCreditInfoText">{lg.durch_die_eingabe_eines_startwertes_wird}</div>
              ) : (
                <div className="inputWrapper">
                  <Input.TextArea
                    placeholder={lg.notiz}
                    value={this.state.note || undefined}
                    onChange={(e) => this.setState({ note: e.target.value })}
                    autoSize={{ minRows: 2, maxRows: 4 }}
                  />
                </div>
              )}
            </div>
          </div>
        </TZModal.Body>
        <TZModal.Footer>
          {!this.isCreationMode && (
            <Button id="delete-credit-correction" type="danger" onClick={(e: any) => this.deleteClicked()}>
              <Icon type="delete" />
            </Button>
          )}
          <Button
            id="save-credit-correction"
            type="primary"
            disabled={this.state.minutesAbsolute === undefined}
            onClick={async () =>
              this.props.load(
                this.props.dispatch((e: any) => this.save()),
                "saving"
              )
            }
            loading={this.props.isLoading("saving")}
          >
            {lg.Speichern}
          </Button>
        </TZModal.Footer>
      </TZModal>
    );
  }
}

export const CreditCorrectionModal = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  busyInjector(CreditCorrectionModalComponent)
);
