import { IAvailability } from './../shared/entities/IAvailability';

import { BaseRepository } from '../frontend-core/BaseRepository';

class AvailabilityRepository extends BaseRepository<IAvailability> {
  constructor() {
    super('availabilities');
  }
}

export const availabilityRepository = new AvailabilityRepository();
