import React from "react";
import { Button, Icon } from "antd";
import "./styles.scss";
import { ButtonProps } from "antd/lib/button";

type Props = {
  onLeft: () => void;
  onRight: () => void;
} & ButtonProps;

export class LeftRightStepper extends React.PureComponent<Props, {}> {
  render() {
    const { onLeft, onRight, ...otherProps } = this.props;

    return (
      <Button.Group {...otherProps}>
        <Button id="step-left" onClick={onLeft} type="primary">
          <Icon type="left" />
        </Button>
        <Button id="step-right" onClick={onRight} type="primary">
          <Icon type="right" />
        </Button>
      </Button.Group>
    );
  }
}
