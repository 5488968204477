import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import Page from "../../../components/Page/Page";
import { Button, Checkbox, DatePicker, notification } from "antd";
import { openModal } from "../../../actions/modal";
import AvailabilityModal from "../../../components/modals/AvailabilityModal/AvailabilityModal";
import { availabilityRepository } from "../../../repositories/availabilityRepository";
import "./styles.scss";
import { BasicSelect } from "../../../components/BasicSelect/BasicSelect";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import moment from "moment";
import { selectActiveUsers } from "../../../selectors/ActiveUserSelectors";
import AvailabilitiesColumn from "./AvailabilitiesList";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { SDateFormat } from "../../../shared/helpers/SimpleTime";
import { IAvailability } from "../../../shared/entities/IAvailability";
import { featuresSelector } from "../../../selectors/FeaturesSelector";
import { paidFeatureWarning } from "../../../actions/paidFeatureWarning";
import { usersHaveIntersectingBranch } from "../../../selectors/UsersWithSharedBranchSelector";
import { getUserEmail, getUserName } from "../../../shared/helpers/userHelpers";
import { selectAvailabilites } from "../../../selectors/availabilitiesSelector";
import { selectRosterSettingsByUser } from "../../../selectors/rosterSettingsByUserSelector";
import { notificationSettingsRepository } from "../../../repositories/notificationSettingsRepository";
import { toMoment, toMomentUnsafe } from "../../../shared/helpers/timeHelpers";
import { selectVisibleBranches } from "../../../selectors/VisibleBranchesSelector";
import { selectActiveJobPositions } from "../../../selectors/ActiveJobPositionsSelector";
import { IUserFull } from "../../../shared/entities/IUser";
import { getWeekDay } from "../../../shared/helpers/weekDaysList";
import { WeekDays } from "../../../shared/constants/WeekDays";
import _ from "lodash";
import { AbsenceDateSelect } from "../../../components/modals/AbsenceModal/AbsenceDateSelect/AbsenceDateSelect";
import {
  AvailabiliteisUIFilter,
  availabilitiesUIActionTypes,
} from "../../../reducers/ui/shifts/availabilities/availabilityFilters";

const datePickerIconStyle = {
  background: "#ececec",
  padding: "0px 8px",
  borderRadius: "23px",
  border: "1px solid #f8f8f9",
  color: "#6d6d6e",
  margin: "0px 5px",
};

const mapStateToProps = (state: AppState) => {
  const sessionInfo = selectSessionInfo(state);
  const rosterSettings = state.data.rosterSettings[0];
  const userRostrSettings = selectRosterSettingsByUser(state)[sessionInfo.user.id];
  return {
    sessionInfo,
    _availabilities: selectAvailabilites(state),
    branches: state.data.branches,
    activeUsers: selectActiveUsers(state, moment().format(SDateFormat)),
    rosterSettings,
    features: featuresSelector(state),
    visibleBranches: selectVisibleBranches(state),
    jobPositions: selectActiveJobPositions(state),
    availabilitiesFilter: state.ui.shifts.availabilites,
    canEditAvailabilities:
      selectSessionInfo(state).hasManagerPermissions() ||
      userRostrSettings.usersCanSetAvailabilities ||
      userRostrSettings.usersCanSetUnavailabilities,
  };
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class Availabilities extends React.PureComponent<Props> {
  componentDidMount = () => {
    if (!this.props.sessionInfo.hasManagerPermissions()) {
      this.updateFilter({ selectedUserId: this.props.sessionInfo.user.id });
    }
  };

  availabilityClicked = (availability: IAvailability) => {
    if (!this.props.sessionInfo.hasManagerPermissions() && availability.isLocked) {
      notification.warning({ message: lg.dieser_eintrag_kann_nicht_editiert_werden });
      return;
    }

    if (
      this.props.sessionInfo.hasManagerPermissions() ||
      (availability.isAvailable
        ? this.props.rosterSettings.usersCanSetAvailabilities
        : this.props.rosterSettings.usersCanSetUnavailabilities)
    ) {
      this.props.dispatch(
        openModal(AvailabilityModal, {
          userId: availability.userId,
          availability,
        })
      );
    }
  };

  updateFilter = (filter: Partial<AvailabiliteisUIFilter>) => {
    this.props.dispatch({
      type: availabilitiesUIActionTypes.UPDATE,
      payload: filter,
    });
  };

  render() {
    const { sessionInfo, _availabilities } = this.props;
    const {
      selectedUserId,
      filterBranchId,
      filterJobPositionId,
      startDate,
      endDate,
      onlyAvailable,
      onlyUnavailable,
      hideEmptyUsers,
      showAllFilters,
    } = this.props.availabilitiesFilter;

    const selectableUsers = this.props.activeUsers.filter(
      (u) => sessionInfo.isAdmin() || usersHaveIntersectingBranch(u, sessionInfo.user)
    );

    const canManage = sessionInfo.hasManagerPermissions();

    const _users = this.props.activeUsers
      .map((u) => ({ ...u, name: getUserName(u) } as IUserFull))
      .filter((u) => !selectedUserId || u.id === selectedUserId)
      .filter((u) => !filterBranchId || u.branchIds.includes(filterBranchId))
      .filter((u) => !filterJobPositionId || u.jobPositionIds.includes(filterJobPositionId));

    const usersOrdred = _.orderBy(_users, (u) => u.name);

    const availabilities = _availabilities.filter((a) => !a.isGeneratedByContract);

    return (
      <Page>
        <div className="fb column availabilitiesMain grow shrink">
          <h2 className="avTitle">{lg.verfügbarkeiten}</h2>
          <div className="mainContent">
            <div className="fb row headRow">
              {canManage && (
                <div className="fieldWrapper userSelect">
                  <BasicSelect
                    id="availabilites-user-filter"
                    placeholder={lg.mitarbeiter}
                    allowClear
                    showSearch
                    style={{ minWidth: 180 }}
                    value={selectedUserId}
                    onChange={(selectedUserId: string) =>
                      this.updateFilter({
                        selectedUserId,
                        hideEmptyUsers: false,
                      })
                    }
                    options={selectableUsers.map((u) => ({
                      value: u.id,
                      label: getUserName(u),
                    }))}
                  />
                </div>
              )}

              {canManage && !showAllFilters && (
                <div className="fieldWrapper">
                  <Button
                    children={lg.weitere_filter}
                    icon="plus"
                    onClick={() => {
                      this.updateFilter({
                        showAllFilters: !showAllFilters,
                      });
                    }}
                  />
                </div>
              )}

              {canManage && showAllFilters && (
                <div className="fieldWrapper" style={{ width: 180 }}>
                  <BasicSelect
                    id="reporting-filter-jobPos"
                    options={this.props.jobPositions.map((j) => ({
                      value: j.id,
                      label: j.name,
                    }))}
                    onChange={(v: any) => this.updateFilter({ filterJobPositionId: v })}
                    value={filterJobPositionId}
                    placeholder={lg.rolle}
                    allowClear
                  />
                </div>
              )}
              {this.props.visibleBranches.length > 1 && canManage && showAllFilters && (
                <div className="fieldWrapper" style={{ width: 180 }}>
                  <BasicSelect
                    id="reporting-filter-branch"
                    options={this.props.visibleBranches.map((u) => ({
                      value: u.id,
                      label: u.name,
                    }))}
                    onChange={(v: any) => this.updateFilter({ filterBranchId: v })}
                    value={filterBranchId}
                    placeholder={lg.standort}
                    allowClear
                  />
                </div>
              )}

              {canManage && showAllFilters && (
                <div className="fieldWrapper" style={{ width: 180 }}>
                  <BasicSelect
                    keepOrder
                    options={[
                      { value: "available", label: "Nur verfügbar" },
                      { value: "unavailable", label: "Nur unverfügbar" },
                      { value: "both", label: "Beides" },
                    ]}
                    onChange={(v: any) =>
                      this.updateFilter({
                        onlyAvailable: v === "available",
                        onlyUnavailable: v === "unavailable",
                      })
                    }
                    value={onlyAvailable ? "available" : onlyUnavailable ? "unavailable" : undefined}
                    placeholder={"Einträge"}
                    allowClear
                  />
                </div>
              )}
              {!!availabilities.length && showAllFilters && (
                <div className="availabilityDatePickerWrapper">
                  <AbsenceDateSelect
                    allowClear
                    startDate={startDate}
                    endDate={endDate}
                    arrowIconStyle={datePickerIconStyle}
                    onDatesChange={(startMom, endMom) =>
                      this.updateFilter({
                        startDate: startMom?.format(SDateFormat),
                        endDate: endMom?.format(SDateFormat),
                      })
                    }
                  />
                </div>
              )}
            </div>

            {canManage && availabilities.length > 10 && showAllFilters && (
              <div className="fb row subHeader">
                <Checkbox
                  checked={!!hideEmptyUsers}
                  onChange={() => this.updateFilter({ hideEmptyUsers: !hideEmptyUsers })}
                />
                <div className="txt">Leere Zeilen ausblenden</div>
              </div>
            )}

            <div className="fb col usersListWrapper">
              {usersOrdred.map((user) => {
                const userAvailabilities = availabilities.filter(
                  (a) =>
                    (!onlyAvailable || a.isAvailable) &&
                    (!onlyUnavailable || !a.isAvailable) &&
                    a.userId === user.id &&
                    (!endDate || a.startDate <= endDate) &&
                    (!startDate || !a.endDate || a.endDate >= startDate) &&
                    (!startDate ||
                      !a.weekDays ||
                      startDate !== endDate ||
                      a.weekDays?.includes(WeekDays[getWeekDay(startDate)])) // if its only one day selected > we want filter out availabiliteis that dont include the weekday
                );

                const userAvailabilitiesFiltered = _.sortBy(userAvailabilities, "startDate");

                if (hideEmptyUsers && !userAvailabilitiesFiltered.length) {
                  return null;
                }

                return (
                  <div style={{ marginTop: 20 }} className="fb grow shrink column pageContentWrapper">
                    <AvailabilitiesColumn
                      availabilityClicked={this.availabilityClicked}
                      user={user}
                      availabilities={userAvailabilitiesFiltered}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export default withErrorBoundary(
  connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(Availabilities)
);
