import { SDateFormat, STimeFormat, SDateTimeFormat, DisplayDateFormat } from "./SimpleTime";
import { Moment } from "moment";
import moment from "moment";
import { addSimpleDays } from "./dateHelpers";
import { ITimeClocking } from "../entities/ITimeClocking";
import { getSurcharge } from "./credit";

export const toDoubleDigit = (_num: number | string): string => {
  const num = _num.toString();
  return num.length === 1 ? `0${num}` : num;
};

export const toTripleDigit = (_num: number | string): string => {
  const num = _num.toString();
  return num.length === 1 ? `00${num}` : num.length === 2 ? `0${num}` : num;
};

export const to4Digits = (_num: number | string): string => {
  const num = _num.toString();
  const digitsToAdd = 4 - num.length;
  const zeros = `0000`;
  const zerosToAdd = zeros.substr(0, digitsToAdd);
  return zerosToAdd + num;
};

export const toSimpleDate = (mom: Moment): string => mom.format(SDateFormat);
export const toSimpleTime = (mom: Moment): string => mom.format(STimeFormat);
export const toSimpleDateTime = (mom: Moment): string => mom.format(SDateTimeFormat);
export const simpleDateToMoment = (sDate: string): Moment => moment(sDate, SDateFormat);
export const toMoment = (sDate: string): Moment => moment(sDate, SDateFormat);
export const simpleTimeToMoment = (t: string): Moment => moment(t, STimeFormat);
export const simpleDateTimeToMoment = (t: string): Moment => moment(t, SDateTimeFormat);
export const makeSimpleDateTime = (simpleDate: string, simpleTime: string) => `${simpleDate} ${simpleTime}`;
export const toSimpleDateUnsafe = (mom?: Moment | null): string | undefined => mom?.format(SDateFormat);

// this function can be used as a performant way to extract the year
export const getSimpleDateYear = (sDate: string) => {
  return parseInt(sDate.substr(0, 4), 10);
};

export const SDateToDisplayDate = (simpleDate: string) => {
  return moment(simpleDate, SDateFormat).format(DisplayDateFormat);
};

export const isValidTDate = (value: string | undefined) => {
  if (!value || value.length !== 10) {
    return false;
  }
  const splitted = value.split("-");
  if (
    splitted.length !== 3 ||
    splitted[0].length !== 4 ||
    splitted[1].length !== 2 ||
    splitted[2].length !== 2 ||
    (parseInt(splitted[1], 10) <= 0 && parseInt(splitted[1], 10) > 12) ||
    (parseInt(splitted[2], 10) <= 0 && parseInt(splitted[2], 10) > 31)
  ) {
    return false;
  }
  return true;
};

export const toMomentUnsafe = (value: string | undefined): Moment | undefined => {
  if (isValidTDate(value)) {
    return simpleDateToMoment(value!);
  } else {
    return undefined;
  }
};

export const toTDateUnsafe = (value: Moment | undefined): string | undefined => {
  if (value) {
    return toSimpleDate(value);
  } else {
    return undefined;
  }
};

/**
 * Generates an array with Moment dates
 * for each day in a week starting from the startDate
 */
export const generateWeekdays = (
  startDate: string, // needs to be a monday
  options?: { noSaturday?: boolean; noSunday?: boolean }
): Moment[] => {
  const startDateMom = toMoment(startDate);
  const weekdays: Moment[] = [startDateMom];
  for (let i = 1; i < 7; i++) {
    const skip = (i === 5 && options?.noSaturday) || (i === 6 && options?.noSunday);
    if (!skip) {
      weekdays[i] = startDateMom.clone().add(i, "days");
    }
  }
  return weekdays;
};

/**
 * Generates an array with simple dates
 * for each day in a week starting from the startDate
 */
export const generateSimpleWeekdays = (
  startDate: string, // needs to be a monday
  options?: { noSaturday?: boolean; noSunday?: boolean }
) => {
  const weekdays: string[] = [startDate];
  for (let i = 1; i < 7; i++) {
    const skip = (i === 5 && options?.noSaturday) || (i === 6 && options?.noSunday);
    if (!skip) {
      weekdays[i] = addSimpleDays(startDate, i);
    }
  }
  return weekdays;
};

export const tTimetoMinutes = (time: string): number => {
  return Number(time.substr(0, 2)) * 60 + Number(time.substr(3, 5));
};

export const getDuration = (timeSpan: { startTime: string; endTime: string; breakMinutes?: number }) => {
  const { startTime, endTime } = timeSpan;
  const breakMinutes = timeSpan.breakMinutes || 0;

  const endTimeMinutes = tTimetoMinutes(endTime);
  const startTimeMinutes = tTimetoMinutes(startTime);

  const duration =
    startTime <= endTime
      ? endTimeMinutes - startTimeMinutes - breakMinutes
      : 1440 - startTimeMinutes + endTimeMinutes - breakMinutes; // shift, which goes beyond midnight

  return duration >= 0 ? duration : 0; // dont allow negative values
};

export const minutesToTTime = (_minutes: number): string => {
  const fullHours = toDoubleDigit(Math.floor(_minutes / 60));
  const fullMinutes = toDoubleDigit(_minutes % 60);
  return `${fullHours}:${fullMinutes}`;
};

export const minutesToHours = (minutes: number): number => Math.round((minutes / 60) * 100) / 100; // round to two decimal digits

export const minutesToHoursStr = (minutes: number, options?: { separator: string }): string => {
  const hoursStr = `${minutesToHours(minutes)}`; // round to two decimal digits
  return hoursStr.replace(".", options?.separator || ",");
};

type DurationOptions = {
  onlyHours?: boolean;
  dropZeroMinutes?: boolean; // to always include ':00' after the hours - by efault they get skipped
  withSign?: boolean;
  showOneDecimal?: boolean; // when rounding to hours, this can add sthing like ,2
  fillUpHourZeros?: boolean; // default false
};

// duration has a foormat like 124:44 or 4,3
export const minutesToDuration = (_minutes: number, options: DurationOptions = {}): string => {
  const sign = _minutes === 0 ? "" : _minutes < 0 ? "-" : "+";
  const minutes = Math.abs(_minutes);
  const hours = options.fillUpHourZeros ? toDoubleDigit(Math.floor(minutes / 60)) : Math.floor(minutes / 60);
  const mins = Math.round(minutes % 60);

  let absoluteDuration =
    options.onlyHours || (!mins && options.dropZeroMinutes) ? `${hours}` : `${hours}:${toDoubleDigit(mins)}`;

  if (options.showOneDecimal) {
    const moduloMinutes = _minutes % 60;
    const decimal = Math.floor((moduloMinutes / 60) * 10);
    if (moduloMinutes && decimal) {
      absoluteDuration += decimal ? `,${decimal}` : "";
    }
  }

  return (options.withSign ? `${sign} ${absoluteDuration}` : absoluteDuration).trim();
};

export const simpleDateToFormat = (simpleDate: string, format: string = "DD.MM.YY") => {
  return moment(simpleDate as string, SDateTimeFormat).format(format);
};

export const getEndDateTime = (shift: { startTime: string; endTime: string; date: string }) => {
  const isNightShift = shift.startTime > shift.endTime;
  const fixedDate = isNightShift ? addSimpleDays(shift.date, 1) : shift.date;
  return `${fixedDate} ${shift.endTime}`;
};
