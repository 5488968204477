import React from "react";
import { Switch, RouteComponentProps, Redirect } from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import Absences from "./absences/Absences";
import { Settings } from "./settings/Settings";
import Shifts from "./shiftsPage/ShiftsPage";
import TopBar from "../components/TopBar/TopBar";
import { sitemap } from "../helpers/sitemap";
import { MyProfile } from "./myProfile/MyProfile";
import { DispatchBaseProps } from "../frontend-core/types/DispatchBaseProps";
import { AppState } from "../types/AppState";
import { connect } from "react-redux";
import { ExceptionsVisualizer } from "../components/ExceptionsVisualizer/ExceptionsVisualizer";
import { TimeClock } from "./timeClock/TimeClock";
import { ACLRoute } from "../components/ACLRoute/ACLRoute";
import { NotificationSettings } from "./notificationSettings/NotificationSettings";
import { CrispInjector } from "../helpers/crisp";
import { isMobile, isTablet } from "mobile-device-detect";
import { push } from "connected-react-router";
import { HelpPage } from "./helpPage/HelpPage";
import UsersPage from "./Users/UsersPage";
import { tierInfoSelector } from "../selectors/TierInfoSelector";
import { environment } from "../env";
import { selectSessionInfo } from "../selectors/SessionInfoSelector";
import { setLastLogin } from "../actions/setLastLogin";
import { webUserTimeClockEnabledSelector } from "../selectors/webUserTimeClockEnabledSelector";
import { TimeClockWeb } from "./timeClock/TimeClockWeb";
import { AccountWarnings } from "../components/AccountWarnings/AccountWarnings";
import { AskForPhone } from "../components/AskForPhoneModal/AskForPhone";
import { CrispSupportMessage } from "../components/CrispSupportMessage/CrispSupportMessage";
import { Chat } from "./Chat/Chat";
import { PushNotesPage } from "./PushNotes/PushNotesPage";
import * as Sentry from "@sentry/browser";
import { ShiftTemplateDrawer } from "../components/ShiftTemplateDrawer/ShiftTemplateDrawer";
import { AppMismatchBanner } from "../components/AppMismatchBanner/AppMismatchBanner";
import { OverdueWarningBanner } from "../components/OverdueWarningBanner/OverdueWarningBanner";

//test

const mapStateToProps = (state: AppState) => {
  return {
    timeClockModeIsActive: !!state.ui.timeClockMode.branchIds,
    session: state.data.auth.session,
    sessionInfo: selectSessionInfo(state),
    tenantInfo: state.data.tenantInfo,
    tierInfo: tierInfoSelector(state),
    userTimeClockEnabled: webUserTimeClockEnabledSelector(state),
    users: state.data.users,
  };
};

type OwnProps = {} & RouteComponentProps;
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class Pages extends React.PureComponent<Props> {
  componentDidMount() {
    const { tenantInfo, sessionInfo, session } = this.props;

    if (isMobile && !isTablet) {
      if (sessionInfo.isAdmin() && this.props.users.filter((u) => !!u.accountId).length <= 3) {
        this.props.dispatch(push(sitemap.noMobileAdmin.url));
      } else {
        this.props.dispatch(push(sitemap.noMobile.url));
      }
    } else {
      this.props.dispatch(setLastLogin());
    }

    if (!environment.isProd()) {
      // Please keep this always for dev/testing for debugging
      console.log("TenantInfo & TierInfo", tenantInfo, this.props.tierInfo);
    }

    setTimeout(() => {
      if (environment.isProd()) {
        try {
          (window as any).hj &&
            (window as any).hj("identify", session?.accountId || "@AV/NO", {
              tenantId: session?.tenantId,
              userId: session?.userId,
              accountId: session?.accountId,
              createdAt: tenantInfo.createdAt,
              tenantInfo: tenantInfo.name,
            });
        } catch (e) {
          Sentry.setTag("hotjar-identify-failed", session?.userId + " " + session?.accountId);
          console.log("hotjar-recording-tag failed:");
          console.log(e);
          Sentry.captureException(e);
        }
      }
    }, 2000);
  }

  render() {
    const canManage = this.props.sessionInfo.hasManagerPermissions();
    const allowTimeClockWeb = canManage || this.props.userTimeClockEnabled;

    if (!this.props.session || !this.props.sessionInfo?.user) {
      return null;
    }

    if (this.props.timeClockModeIsActive) {
      return <TimeClock />;
    }

    return (
      <>
        <AppMismatchBanner />
        <OverdueWarningBanner />
        {/* {isAdmin && <CrispSupportMessage />} */}
        <AccountWarnings />
        {/* {isAdmin && <AskForPhone />} */}
        {canManage && (
          <CrispInjector
            name={this.props.sessionInfo.user?.name}
            email={this.props.sessionInfo.user?.email}
            tenantId={this.props.session?.tenantId}
            role={this.props.session?.role}
            accountId={this.props.session?.accountId}
            billableUsers={this.props.tenantInfo.billableUsers}
            tier={this.props.tierInfo.tier}
            daysUntilTrialEnd={this.props.tierInfo.daysUntilTrialEnd}
          />
        )}
        <TopBar />
        <Switch>
          <>
            <ACLRoute route={sitemap.help} component={HelpPage} />
            <ACLRoute route={sitemap.dashboard} component={Dashboard} />
            <ACLRoute route={sitemap.shifts} component={Shifts} />
            <ACLRoute route={sitemap.absences} component={Absences} />
            <ACLRoute route={sitemap.users} component={UsersPage} />
            <ACLRoute route={sitemap.chat} component={Chat} />
            <ACLRoute route={sitemap.pushNotes} component={PushNotesPage} />
            <ACLRoute route={sitemap.settings} component={Settings} />
            <ACLRoute route={sitemap.myProfile} component={MyProfile} />
            <ACLRoute route={sitemap.notifications} component={NotificationSettings} />
            {allowTimeClockWeb && <ACLRoute route={sitemap.timeClockUser} component={TimeClockWeb} />}
            <ACLRoute
              exact
              route={sitemap.app}
              render={() => <Redirect strict exact from={sitemap.app.url} to={sitemap.shiftsRoster.url} />}
            />
          </>
        </Switch>
        <ShiftTemplateDrawer />
        <ExceptionsVisualizer />
      </>
    );
  }
}

export default connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(Pages);
