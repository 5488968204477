import { combineReducers } from "redux";
import rosterUIReducer from "./roster";
import { reportingUIReducer } from "./reporting";
import { creditsUIReducer } from "./credits";
import { availabilitiesUIReducer } from "./availabilities/availabilityFilters";

const shiftsUIReducer = combineReducers({
  roster: rosterUIReducer,
  reporting: reportingUIReducer,
  credits: creditsUIReducer,
  availabilites: availabilitiesUIReducer,
});

export default shiftsUIReducer;
