import { usersPageSettingRedcuer } from "./usersPageSetting";
import { rosterMonthStartDayReducer } from "./rosterMonthStartDay";
import { creditsMonthStartDayReducer } from "./creditsMonthStartDay";
import { combineReducers } from "redux";

const generalUIReducer = combineReducers({
  creditsCustomMonthStartDay: creditsMonthStartDayReducer,
  rosterCustomMonthStartDay: rosterMonthStartDayReducer,
  usersPageSetting: usersPageSettingRedcuer,
});

export default generalUIReducer;
