import React from "react";
import { connect } from "react-redux";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";
import { AppState } from "../../../../../types/AppState";
import _ from "lodash";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { selectChangeRequests } from "../../../../../selectors/changeRequestsSelector";
import { Popover, Badge, Button } from "antd";
import { ChangeRequestItem } from "../../../../../components/ChangeRequestItem/ChangeRequestItem";

import { openModal } from "../../../../../actions/modal";
import { IChangeRequest } from "../../../../../shared/entities/IChangeRequest";
import { selectShiftMap } from "../../../../../selectors/shiftMapSelector";
import { ShiftPopup } from "../../../../../components/ShiftPopup/ShiftPopup/ShiftPopup";

const mapStateToProps = (state: AppState) => {
  return {
    changeRequests: selectChangeRequests(state),
    shiftMap: selectShiftMap(state),
    sessionInfo: selectSessionInfo(state),
    selectedBranchId: state.ui.selectedBranch,
  };
};

type State = {
  popoverIsVisible: boolean;
};
type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

// This is a managers-only component
class _ChangeRequestsButton extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      popoverIsVisible: false,
    };
  }

  openModal = async (changeRequest: IChangeRequest) => {
    this.setState({ popoverIsVisible: false });
    const shift = this.props.shiftMap[changeRequest.id];
    this.props.dispatch(openModal(ShiftPopup, { shift }));
  };

  render() {
    const { changeRequests, shiftMap, selectedBranchId } = this.props;

    const changeRequestsInBranch = changeRequests.filter((cR) => !selectedBranchId || cR.branchId === selectedBranchId);

    const sortedChangeRequests = _.sortBy(changeRequestsInBranch, "createdAt");
    if (!sortedChangeRequests.length) {
      return null;
    }
    return (
      <>
        <Popover
          trigger={"click"}
          placement="bottomRight"
          visible={this.state.popoverIsVisible}
          onVisibleChange={(v) => this.setState({ popoverIsVisible: v })}
          content={
            <div className="openHandOverRequestsPopover fb column">
              <div className="head">{lg.änderungsantrag}</div>
              <div className="body">
                {sortedChangeRequests.map((request) => {
                  return (
                    <ChangeRequestItem
                      key={request.id}
                      shift={shiftMap[request.id]!}
                      changeRequest={request}
                      openModal={() => this.openModal(request)}
                      // border="1px solid #ededed"
                    />
                  );
                })}
              </div>
              <div className="footer"></div>
            </div>
          }
        >
          <Badge count={sortedChangeRequests.length}>
            <Button
              id="change-requests-button"
              style={{ marginLeft: 12 }}
              disabled={!sortedChangeRequests.length}
              data-rh={lg.änderungsantrag}
              data-rh-at="bottom"
              icon="scissor"
            />
          </Badge>
        </Popover>
      </>
    );
  }
}

export const ChangeRequestsButton = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(_ChangeRequestsButton);
