import { wrongDeviceTimeActionType } from "../../actions/checkServerTime";

export const wrongDeviceTimeReducer = (state = false, action: any): boolean => {
  switch (action.type) {
    case wrongDeviceTimeActionType.SET:
      return action.payload;
    default:
      return state;
  }
};
