import React from "react";
import "./styles.scss";

import { Icon } from "antd";
import { ITracking } from "../../../shared/entities/ITracking";

type Props = {
  style?: React.CSSProperties;
};

export class ShiftPunchingIcon extends React.PureComponent<Props> {
  render() {
    return (
      <div
        data-rh={lg.unbestätigte_zeiterfassung}
        className="shiftIcon counterBatch punchingIconMain"
        style={{ ...(this.props.style || {}), background: "#ff9213" }}
      >
        <Icon type="history" />
      </div>
    );
  }
}
