import React, { PureComponent } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { Dispatch } from "redux";
import { Icon, Tooltip } from "antd";
import cn from "classnames";
import { AppState } from "../../../../../types/AppState";
import { hideEmptyShiftRows } from "../../../../../actions/hiddenShiftRows";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { BusyInjectorProps, busyInjector } from "../../../../../components/BusyInjector/BusyInjector";
import { RosterTypePicker } from "../RosterTypePicker/RosterTypePicker";
import { RosterType } from "../../../../../reducers/ui/shifts/roster/rosterType";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { RoleType } from "../../../../../shared/entities/IUser";
import { setOnlyShowActiveClockings } from "../../../../../actions/onlyShowActiveClockings";

const mapStateToProps = (state: AppState) => ({
  onlyShowActiveClockings: state.ui.shifts.roster.onlyShowActiveClockings,
  rosterType: state.ui.shifts.roster.rosterType,
  sessionInfo: selectSessionInfo(state),
  tenantInfo: state.data.tenantInfo,
  users: state.data.users,
});

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class _ShowOnlyClockedInsButton extends PureComponent<Props, {}> {
  render() {
    const { dispatch, onlyShowActiveClockings, setLoading, rosterType, isLoading, sessionInfo, users } = this.props;

    const isEmployee = sessionInfo.user.role === RoleType.employee;

    if (rosterType !== RosterType.List || isEmployee || users.length < 15) {
      return null;
    }

    console.log(onlyShowActiveClockings);
    console.log(onlyShowActiveClockings);

    const tooltip = onlyShowActiveClockings
      ? "Es werden nur laufende Zeiterfassungen angezeigt"
      : "Nur laufende Zeiterfassungen anzeigen";

    return (
      <div
        data-rh={tooltip}
        className={cn({ selected: onlyShowActiveClockings }) + " showOnlyClockedInsButton"}
        onClick={(v) => {
          dispatch(setOnlyShowActiveClockings(!onlyShowActiveClockings));
        }}
      >
        <Icon type="security-scan" />
      </div>
    );
  }
}

export const ShowOnlyClockedInsButton = connect<StoreProps, { dispatch: Dispatch }, OwnProps, AppState>(
  mapStateToProps
)(busyInjector(_ShowOnlyClockedInsButton));
