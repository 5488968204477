import { CLEAR_DATA_ACTION_TYPE } from "./maintenanceRepo";
import { BalancesMap } from "../actions/creditActions/creditTypes";
import { Reducer } from "redux";

export const BalanceAction = {
  SET: "@@AV/SET_BALANCES",
};

class CreditBalanceRepository {
  getReducer(): Reducer<BalancesMap> {
    return (state: BalancesMap = {}, action: any): BalancesMap => {
      switch (action.type) {
        case BalanceAction.SET:
          return { ...state, ...action.payload };
        case CLEAR_DATA_ACTION_TYPE:
          return {};
        default:
          return state;
      }
    };
  }
}

export const creditBalanceRepository = new CreditBalanceRepository();
