import { IJobPosition } from "./../shared/entities/IJobPosition";
import { IBranch } from "./../shared/entities/IBranch";
import { IShift } from "./../shared/entities/IShift";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import { visibleShiftsSelector } from "./publishedShiftsSelector";
import { IShiftOverlap } from "../shared/entities/IShiftOverlap";
import { createCachedSelector } from "re-reselect";
import _ from "lodash";
import { Map } from "../shared/types/general";
import { selectBranchMap, selectJobPositionMap } from "./mapSelectors";
import { getShiftEndDateTime, getShiftStartDateTime } from "../shared/helpers/shiftHelpers";
import { rangesOverlap } from "../shared/helpers/dateHelpers";
import { getActiveUsersAtDate, selectActiveUsers } from "./ActiveUserSelectors";
import { toSimpleDate } from "../shared/helpers/timeHelpers";
import moment from "moment";

export type ShiftOverlapMap = { [shiftId: string]: IShiftOverlap };
export const getShiftOverlappingsMap = (shifts: IShift[], branchMap: Map<IBranch>, jobPosMap: Map<IJobPosition>) => {
  const overlappingsMap: ShiftOverlapMap = {};
  const userShifts = shifts.filter((s) => s.userId);
  userShifts.forEach((shift) => {
    const overlappings = userShifts.filter(
      (s) => s.date === shift.date && s.id !== shift.id && doShiftsOverlap(shift, s)
    );

    if (overlappings.length) {
      overlappingsMap[shift.id] = {
        shiftId: shift.id,
        userId: shift.userId!,
        overlapsWith: overlappings.map((s) => ({
          startTime: s.startTime,
          endTime: s.endTime,
          date: s.date,
          branchName: branchMap[s.branchId]?.name || "-",
          jobPositionName: jobPosMap[s.jobPositionId]?.name || "",
          shiftId: s.id,
        })),
      };
    }
  });
  return overlappingsMap;
};

export const selectShiftOverlappingsMap = createSelector(
  (_s: AppState) => visibleShiftsSelector(_s),
  (_s: AppState) => selectBranchMap(_s),
  (_s: AppState) => selectJobPositionMap(_s),
  (_s: AppState) => _s.data.users,
  (shifts, branchMap, jobPosMap, users) => {
    const activeUsers = getActiveUsersAtDate(users, toSimpleDate(moment()));
    let overlappingMap: { [shiftId: string]: IShiftOverlap } = {};
    activeUsers.forEach((u) => {
      const shiftsOfUser = shifts.filter((s) => s.userId === u.id);
      overlappingMap = {
        ...overlappingMap,
        ...getShiftOverlappingsMap(shiftsOfUser, branchMap, jobPosMap),
      };
    });
    return overlappingMap;
  }
);

export const doShiftsOverlap = (shift1: IShift, shift2: IShift) => {
  return rangesOverlap(
    getShiftStartDateTime(shift1),
    getShiftEndDateTime(shift1),
    getShiftStartDateTime(shift2),
    getShiftEndDateTime(shift2),
    { equalIsOverlap: false }
  );
};
