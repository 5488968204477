import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../types/AppState";
import "./styles.scss";

import PrettyDate from "../PrettyDate/PrettyDate";
import BusyWrapper from "../BusyWrapper/BusyWrapper";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { IShift } from "../../shared/entities/IShift";

const mapStateToProps = (state: AppState) => {
  return {
    users: state.data.users,
    jobPositions: state.data.jobPositions,
    branches: state.data.branches,
    currentUser: selectSessionInfo(state).user,
  };
};

type OwnProps = {
  key: string;
  appliedShift: IShift;
  border?: string;
  openModal: () => void;
  displayCheck?: boolean;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _OpenShiftItem extends React.PureComponent<Props> {
  renderUserBadge = () => {
    const appliedShift = this.props.appliedShift!;
    const appliedUserIds = appliedShift.appliedUserIds;
    const badgeType = (appliedUserIds?.length || 0) < (appliedShift.requiredUsersAmount || 0) ? "open" : "filled";
    return (
      <div className="badgeWrapper">
        <div className={`${badgeType} userBadge`} data-rh={lg.bewerber_benötigtes_personal}>
          {appliedUserIds?.length
            ? `${appliedUserIds?.length} / ${appliedShift.requiredUsersAmount}`
            : `0 / ${appliedShift.requiredUsersAmount}`}
        </div>
      </div>
    );
  };

  render() {
    const { appliedShift, key, border, currentUser, jobPositions } = this.props;
    const isMultiBranch = this.props.branches.filter((b) => !b.isInactive).length > 1;

    return (
      <div className="openShiftItem" key={key} onClick={this.props.openModal} style={{ borderBottom: border }}>
        <div className="openShiftDetails">
          <div className="dateCell">
            <PrettyDate date={appliedShift.date} />
          </div>
          <div className="time">{`${appliedShift.startTime} - ${appliedShift.endTime}`}</div>
          <div className="branch">
            {isMultiBranch && this.props.branches.find((branch) => branch.id === appliedShift.branchId)!.name}
          </div>
          <div className="jobPos">{jobPositions.find((jP) => jP.id === appliedShift.jobPositionId)?.name}</div>
          {this.props.displayCheck && appliedShift.appliedUserIds?.includes(currentUser.id) ? (
            <div className="appliedCheck">{lg.beworben}</div>
          ) : (
            this.renderUserBadge()
          )}
        </div>
      </div>
    );
  }
}

export const OpenShiftItem = connect(mapStateToProps)(_OpenShiftItem);
