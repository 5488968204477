export const pastelColors = [
  "#b7fcbb",
  "#ccf586",
  "#dfc9ff",
  "#aad1ff",
  "#ffaade",
  "#ffc7cd",
  "#f7f28b",
  "#fcec01",
  "#ffbf80",
  "#e8e5e5",
  "#f4c998",
  "#fcb30b",
  "#f3989f",
  "#eeabd7",
  "#a4dcf2",
  "#7ae3d8",
  "#9ae6a4",
  "#cdcdce",
  "#ffca03",
  "#f8972c",
];

export const strongColors = [
  "#41b955",
  "#12aca4",
  "#2c7dc1",
  "#35afe2",
  "#993497",
  "#d746a4",
  "#f02a33",
  "#f66422",
  "#373133",
];
