import { getSimpleReducer } from "./../../helpers/reducerHelpers";
import { combineReducers } from "redux";
import moment from "moment";
import { absenceActionType } from "../../../actions/absence";
import { mouseOverAbsenceCellReducer } from "./mouseOverAbsenceCellReducer";

const CURRENT_MONTH = moment().month();
const CURRENT_YEAR = moment().year();

const absencesReducer = combineReducers({
  selectedYear: getSimpleReducer<number>(absenceActionType.setSelectedYear, CURRENT_YEAR),
  selectedMonth: getSimpleReducer<number>(absenceActionType.selectMonth, CURRENT_MONTH), // going frmo 0 - 11 (like moment.js does)
  selectedTypeId: getSimpleReducer<string | null>(absenceActionType.setSelectedType, null),
  emptyAbsenceRowsHidden: getSimpleReducer<boolean>(absenceActionType.HIDE_EMPTY_ROWS, false),
  mouseOverAbsenceCell: mouseOverAbsenceCellReducer,
  isYearView: getSimpleReducer<boolean>(absenceActionType.isYearView, false),
});

export default absencesReducer;
