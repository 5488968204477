import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import { RosterType } from "../reducers/ui/shifts/roster/rosterType";
import { RosterMode } from "../reducers/ui/shifts/roster/rosterMode";
import moment from "moment";
import { SDateFormat } from "../shared/helpers/SimpleTime";
import { IShift } from "../shared/entities/IShift";
import { toDoubleDigit } from "../shared/helpers/timeHelpers";
import { relevantShiftsOfBranchSelector } from "./RelevantShiftsSelector";
import { getPrevSimpleDate } from "../shared/helpers/dateHelpers";
import { selectAbsencesByUser } from "./absencesByUserSelector";
import { AbsenceStatus, IAbsence } from "../shared/entities/IAbsence";

const isAbsentOnDate = (userAbsences: IAbsence[] = [], date: string) => {
  return !!userAbsences.find((a) => a.status === AbsenceStatus.active && a.startDate <= date && a.endDate >= date);
};

export const selectHeadCountOfDayGrid = createSelector(
  [
    relevantShiftsOfBranchSelector,
    (s) => s.ui.shifts.roster.rosterMode,
    (s) => s.ui.shifts.roster.selectedDay,
    (s) => s.ui.shifts.roster.dayRosterTimeSpan,
    selectAbsencesByUser,
  ],
  (_shifts, rosterType, date, dayRosterTimeSpan, absencesByUser) => {
    if (rosterType !== RosterMode.Day) {
      return {};
    }

    const getWorkingHours = Array.from(Array(24).keys()).filter(
      (i) => i >= dayRosterTimeSpan.dayStart && i < dayRosterTimeSpan.dayEnd
    );

    const dayBefore = getPrevSimpleDate(date);
    const shifts = _shifts.filter((s) => s.userId && !isAbsentOnDate(absencesByUser[s.userId], s.date));
    const shiftsOfDay = shifts.filter((s) => s.date === date);
    const shiftsOfDayBefore = shifts.filter((s) => s.date === dayBefore);

    const map = {};

    const cappedShifts = [
      ...shiftsOfDay.map((s) => (s.endTime < s.startTime ? { ...s, endTime: "24:00" } : s)),
      ...shiftsOfDayBefore.map((s) => (s.endTime < s.startTime ? { ...s, startTime: "00:00" } : undefined)),
    ].filter(Boolean) as IShift[];

    cappedShifts.forEach((s) => {
      getWorkingHours.forEach((h) => {
        const startTime = toDoubleDigit(h) + ":59";
        const endTime = toDoubleDigit(h) + ":00";
        if (s.startTime <= startTime && s.endTime > endTime) {
          map[h] = map[h] ? map[h] + 1 : 1;
        }
      });
    });

    return map;
  }
);
