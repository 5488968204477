import { setupWizardReducer } from "./setupWizard/index";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import shiftsUIReducer from "./shifts";
import absencesReducer from "./absences";
import { modalReducer } from "./modal";
import { selectedBranchReducer } from "./selectedBranch";
import expireReducer from "redux-persist-expire";
import { timeClockModeReducer } from "./timeClockMode";
import filtersReducer from "./filters";
import { wrongDeviceTimeReducer } from "./wrongDeviceTime";
import generalUIReducer from "./general";
import { printModeReducer } from "./printMode";
import { reportSettingsReducer } from "./reportSettings";
import { wrongTimeZoneReducer } from "./wrongTimeZone";

export const uiReducer = persistReducer(
  {
    key: "_ui",
    storage,
    whitelist: ["reportSettings", "general", "timeClockMode", "selectedBranch", "filtersReducer"], // Dont whitelist what is not serializable
  },
  combineReducers({
    shifts: shiftsUIReducer,
    filters: filtersReducer,
    absences: absencesReducer,
    modals: modalReducer,
    selectedBranch: selectedBranchReducer,
    timeClockMode: timeClockModeReducer,
    setupWizard: setupWizardReducer,
    wrongDeviceTime: wrongDeviceTimeReducer,
    wrongTimeZone: wrongTimeZoneReducer,
    general: generalUIReducer,
    printMode: printModeReducer,
    reportSettings: reportSettingsReducer,
  })
);
