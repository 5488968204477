import { AppState } from "../types/AppState";
import { DispFn } from "../frontend-core/types/thunkTypes";

import { Reducer } from "redux";
import { IUserThreadInfo } from "../shared/entities/IUserThreadInfo";
import { selectSessionInfo } from "../selectors/SessionInfoSelector";
import { Raw } from "../shared/entities/IResource";
import moment from "moment";
import { v4 as uuid } from "uuid";
import "firebase/database";
import firebase from "firebase/compat/app";
import { Map } from "../shared/types/general";
import _ from "lodash";
import { useSelector } from "react-redux";
import { threadInfoRepository } from "./threadInfoRepository";
import { selectUserThreadInfos } from "../selectors/userThreadInfoSelector";

export enum UserThreadInfoAT {
  addList = "@AV_USER_THREAD_ADD_LIST",
  add = "@AV_USER_THREAD_CREATE",
  remove = "@AV_USER_THREAD_REMOVE",
  update = "@AV_USER_THREAD_UPDATE",
  clear = "@AV_USER_THREAD_CLEAR",
}

type Action =
  | { type: UserThreadInfoAT.addList; payload: IUserThreadInfo[] }
  | { type: UserThreadInfoAT.add; payload: IUserThreadInfo }
  | { type: UserThreadInfoAT.remove; payload: string }
  | { type: UserThreadInfoAT.update; payload: IUserThreadInfo }
  | { type: UserThreadInfoAT.clear; payload: undefined };

class UserThreadInfoRepository {
  listeners: {
    child_added?: any;
    child_changed?: any;
    child_removed?: any;
  } = {};

  markAsSeen = (threadId: string) => async (dispatch: DispFn, getState: () => AppState) => {
    const sessionInfo = selectSessionInfo(getState());
    const tenantId = getState().data.auth.session!.tenantId;
    const ref = this.getRef(tenantId, sessionInfo.user.id).child(threadId).child("hasUnseenMessages");
    await ref.set(false);
  };

  getRef = (tenantId: string, userId: string) => {
    const db = firebase.database();
    return db.ref(`tenants/${tenantId}/userThreadInfos/${userId}`);
  };

  getUserThreadRefPaths = (tenantId: string, threadId: string, userIds: string[]) => {
    return userIds.map((uId) => {
      return {
        userId: uId,
        path: `tenants/${tenantId}/userThreadInfos/${uId}/${threadId}`,
      };
    });
  };

  mute = (mute: boolean, threadId: string) => async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const tenantId = state.data.auth.session!.tenantId;
    const allUserThreadInfos = selectUserThreadInfos(state);
    const userThreadInfo = allUserThreadInfos.find((t) => t.id === threadId)!;

    await this.getRef(tenantId, state.data.auth.session?.userId!).child(threadId).child("muted").set(mute);

    dispatch({
      type: UserThreadInfoAT.update,
      payload: { ...userThreadInfo, muted: mute },
    });
  };

  addListener = () => async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const tenantId = state.data.auth.session!.tenantId;
    const ref = this.getRef(tenantId, state.data.auth.session?.userId!);

    const initialValueSnap = (await ref.once("value")).val();
    const initialValue: IUserThreadInfo[] = Object.values(initialValueSnap || {});

    dispatch({
      type: UserThreadInfoAT.addList,
      payload: initialValue,
    });

    let currentCount = 0;
    this.listeners.child_added = ref.on("child_added", (snap: any) => {
      currentCount++;
      if (currentCount <= initialValue.length) {
        return;
      }
      dispatch({
        type: UserThreadInfoAT.addList,
        payload: [snap.val()],
      });
    });

    this.listeners.child_changed = ref.on("child_changed", (snap: any) => {
      dispatch({
        type: UserThreadInfoAT.update,
        payload: snap.val(),
      });
    });

    this.listeners.child_removed = ref.on("child_removed", (snap: any) => {
      dispatch({
        type: UserThreadInfoAT.remove,
        payload: snap.key,
      });
    });

    return initialValue;
  };

  removeListener = () => async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const tenantId = state.data.auth.session!.tenantId;

    const ref = this.getRef(tenantId, state.data.auth.session!.userId);

    if (this.listeners) {
      ref.off("child_added", this.listeners.child_added);
      ref.off("child_removed", this.listeners.child_removed);
      ref.off("child_changed", this.listeners.child_changed);
    }
  };

  getReducer(): Reducer<IUserThreadInfo[], Action> {
    return (state: IUserThreadInfo[] = [], action: Action): IUserThreadInfo[] => {
      switch (action.type) {
        case UserThreadInfoAT.add:
        case UserThreadInfoAT.update:
          return [...state.filter((e) => e.id !== action.payload.id), action.payload];
        case UserThreadInfoAT.remove:
          return state.filter((e) => e.id !== action.payload);
        case UserThreadInfoAT.addList:
          return _.unionBy(action.payload, state, "id");
        case UserThreadInfoAT.clear:
          return [];
        default:
          return state;
      }
    };
  }
}

export const userThreadInfoRepository = new UserThreadInfoRepository();
