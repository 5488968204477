import { IShift } from "../shared/entities/IShift";
import { addSimpleDays } from "../shared/helpers/dateHelpers";

export const isOpenShiftsIdentical = (a: IShift, b: IShift) =>
  a.startTime === b.startTime &&
  a.endTime === b.endTime &&
  a.breakMinutes === b.breakMinutes &&
  a.date === b.date &&
  a.branchId === b.branchId &&
  a.jobPositionId === b.jobPositionId &&
  a.workSpaceId === b.workSpaceId &&
  a.addressId === b.addressId &&
  a.breakStartTime === b.breakStartTime;

export const isShiftInCurrentWeekPlan = (shift: IShift, currentRosterDate: string, currentBranch: string): boolean => {
  const startOfWeek = currentRosterDate;
  const endOfWeek = addSimpleDays(currentRosterDate, 6);

  return shift.branchId === currentBranch && shift.date >= startOfWeek && shift.date <= endOfWeek;
};
