import React, { useState } from "react";
import "./styles.scss";
import { useSelector } from "../../helpers/redux";
import { TimeClockUser } from "./TimeClockUser";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import Page from "../../components/Page/Page";
import { Button, Icon } from "antd";
import { withErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";
import { TimeZoneWarning } from "../../components/TimeZoneWarning/TimeZoneWarning";
import { getActiveTimeClocking } from "../../shared/helpers/timeClockingHelpers";
import { selectTrackingMap } from "../../selectors/mapSelectors";
import { PunchClockUser } from "./PunchClockUser/PunchClockUser";
import cn from "classnames";

type Props = {};

export const TimeClockWeb = withErrorBoundary((props: Props) => {
  const [selectedShiftOrClockingId, setSelectedShiftOrClockingId] = useState<string>();
  const sessionInfo = useSelector(selectSessionInfo);
  const tenantInfo = useSelector((s) => s.data.tenantInfo);
  const isV2 = tenantInfo.isV2;
  const wrongTimeZone = useSelector((s) => s.ui.wrongTimeZone);
  const timeClockings = useSelector((state) => state.data.timeClockings);
  const trackingMap = useSelector(selectTrackingMap);
  const activeTimeClocking = getActiveTimeClocking(timeClockings, sessionInfo.user.id, trackingMap);

  if (wrongTimeZone) {
    return <TimeZoneWarning />;
  }

  return (
    <div className={"fb aCenter jCenter timeClockWeb " + (isV2 ? "isV2" : "")}>
      <div className="fb row aCenter jCenter headlineRow" style={{ position: "relative" }}>
        {selectedShiftOrClockingId && !activeTimeClocking && (
          <Button
            id="selecect-shift-of-clocking-station"
            icon="arrow-left"
            type="primary"
            onClick={() => setSelectedShiftOrClockingId(undefined)}
          ></Button>
        )}

        <h2>{lg.stempeluhr}</h2>
      </div>

      <Page noWrapper className={cn({ UserTimeClockPage: true, isV2 })}>
        <div className="fb column grow shrink">
          <div className="fb column timeClock aCenter grow shrink" style={{ width: 560, height: "100%" }}>
            {isV2 ? (
              <PunchClockUser userId={sessionInfo.user.id} isWebMode />
            ) : (
              <TimeClockUser
                userId={sessionInfo.user.id}
                selectedShiftOrClockingId={selectedShiftOrClockingId}
                setSelectedShiftOrClockingId={setSelectedShiftOrClockingId}
                isWebMode
              />
            )}
          </div>
        </div>
      </Page>
    </div>
  );
});
