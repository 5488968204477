
import { BaseRepository } from '../frontend-core/BaseRepository';
import { IContingentCorrection } from '../shared/entities/IContingentCorrection';

class ContingentCorrectionRepository extends BaseRepository<IContingentCorrection> {
  constructor() {
    super('contingentCorrections');
  }
}

export const contingentCorrectionRepository = new ContingentCorrectionRepository();
