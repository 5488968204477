import { Button, Icon, Popover, Select } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { closeModal, openModal } from "../../../../../actions/modal";
import { pastelColors } from "../../../../../frontend-core/helpers/colors";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";
import WorkSpaceEditor from "../../../../settings/branches/branchPopup/workspaceEditor/WorkSpaceEditor";
import WorkSpaceForm from "../../../../settings/branches/branchPopup/workspaceEditor/workspaceForm/WorkSpaceForm";
import { workSpaceRepository } from "../../../../../repositories/workSpaceRepository";
import { IWorkSpace } from "../../../../../shared/entities/IWorkSpace";
import { busyInjector, BusyInjectorProps } from "../../../../../components/BusyInjector/BusyInjector";
import TZModal from "../../../../../components/TZModal/TZModal";
import "./styles.scss";
import { RosterFilterPopover } from "../../components/RosterFilterPopover/RosterFilterPopover";
import { useSelector } from "../../../../../helpers/redux";
import cn from "classnames";
import { antDesignSelectFilterSearch } from "../../../../../frontend-core/helpers/frontendHelpers";
import { IBranch } from "../../../../../shared/entities/IBranch";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";

export const RosterBranchSelect = (props: {
  selectableBranches: IBranch[];
  visibleBranches: IBranch[];
  changeBranch: (branchId: string) => void;
}) => {
  const selectedBranchId = useSelector((s) => s.ui.selectedBranch);
  const sessionInfo = useSelector(selectSessionInfo);

  return (
    <div className="noGrow noShrink branchSelectWrapper" id="rosterBranchPicker">
      <Select
        value={selectedBranchId || "av-all-branches"}
        style={{ marginLeft: 12, width: 220 }}
        onChange={props.changeBranch}
        filterOption={antDesignSelectFilterSearch}
        showSearch={props.visibleBranches.length > 8}
      >
        {props.selectableBranches.map((br) => (
          <Select.Option value={br.id} key={br.id} title={br.name}>
            <Icon type="environment" style={{ paddingRight: "6px" }} />
            {br.name}
          </Select.Option>
        ))}
        {props.visibleBranches.length > 1 && (sessionInfo.user.branchIds.length > 1 || sessionInfo.isAdmin()) && (
          <Select.Option value={"av-all-branches"} key={"all-branches"}>
            <Icon type="environment" style={{ paddingRight: "6px", color: "#1a90ff" }} />
            <span style={{ color: "#1a90ff", fontWeight: "bold" }}>{lg.alle_standorte}</span>
          </Select.Option>
        )}
      </Select>
    </div>
  );
};
