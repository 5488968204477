import { createSelector } from "reselect";
import { selectSessionInfo } from "./SessionInfoSelector";
import _ from "lodash";
import { isRuleApplyingToUser } from "../shared/helpers/settingsHelpers";

export const alwaysClockableBranchIdsSelector = createSelector(
  [selectSessionInfo, (s) => s.data.branches],
  (sessionInfo, branches) => {
    const { user } = sessionInfo;
    const userBranches = user.branchIds.map((bId) => branches.find((b) => b.id === bId)!);

    return userBranches
      .filter((b) => {
        if (!b.isClockingEnabled) {
          return false;
        }

        if (b.isGeofencingDisabled) {
          return true;
        } else {
          if (b.geofencingUserSpecification) {
            return !isRuleApplyingToUser(b.geofencingUserSpecification, user);
          }
        }

        return false;
      })
      .map((b) => b.id);
  }
);
