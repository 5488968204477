import React from "react";
import { connect } from "react-redux";

import "./styles.scss";
import { Button, Icon, Badge, Tooltip, Input, InputNumber, Modal, notification } from "antd";
import { AppState } from "../../../types/AppState";
import { isWorkingTimeInvalid } from "../../../helpers/workingTimeHelpers";
import { JobPositionSelect } from "./JobPositionSelect/JobPositionSelect";
import { AddressSelect } from "./AddressSelect/AddressSelect";
import _ from "lodash";
import { closeModal, openModal } from "../../../actions/modal";
import StartEndTimeInput from "./StartEndTimeInput/StartEndTimeInput";
import AvOption from "../../AvOption/AvOption";
import {
  createShift,
  editShift,
  deleteShift,
  createMultiShifts,
  editRepeatingShift,
  moveShift,
} from "../../../actions/shift";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";

import { Tracking } from "./Tracking/Tracking";
import { WorkSpaceSelect } from "./WorkSpaceSelect/WorkSpaceSelect";
import { ShiftPopupWarningRow } from "../components/ShiftPopupWarningRow/ShiftPopupWarningRow";
import { ShiftPopupHead } from "../components/ShiftPopupHead/ShiftPopupHead";
import { OpenShiftAssignmentPopup } from "../OpenShiftAssignmentPopup/OpenShiftAssignmentPopup";
import { selectActiveUsers } from "../../../selectors/ActiveUserSelectors";
import { setRecentModifiedShifts } from "../../../actions/recentModifiedShifts";
import { BusyInjectorProps, busyInjector } from "../../BusyInjector/BusyInjector";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { HandoverRequestSection } from "./HandoverRequestSection/HandoverRequestSection";
import { TimeClockingRow } from "../components/TimeClockingRow/TimeClockingRow";
import { timeClockingRepository } from "../../../repositories/timeClockingRepository";
import moment from "moment";
import { selectActiveHandOverRequests } from "../../../selectors/HandOverRequestSelector";
import { selectChangeRequests } from "../../../selectors/changeRequestsSelector";
import { ChangeRequestReviewRow } from "../components/ChangeRequestReviewRow/ChangeRequestReviewRow";
import { WithDevider } from "./WithDevider";
import { IShift } from "../../../shared/entities/IShift";
import { ITracking } from "../../../shared/entities/ITracking";
import { Raw } from "../../../shared/entities/IResource";
import { toSimpleDate, simpleDateToMoment, getDuration } from "../../../shared/helpers/timeHelpers";
import { isClockingEqualToTracking, getClockInToShift } from "../../../shared/helpers/timeClockingHelpers";
import TZModal from "../../TZModal/TZModal";
import * as Sentry from "@sentry/browser";
import { IChangeRequest } from "../../../shared/entities/IChangeRequest";
import { UserSelectShiftRow } from "./UserSelectShiftRow/UserSelectShiftRow";
import { OpenShiftActions } from "./OpenShiftActions/OpenShiftActions";
import {
  ExpandableShiftFields,
  ExpandableFieldKey,
  expandableFields,
} from "./ExpandableShiftFields/ExpandableShiftFields";
import { SurchargeShiftRow } from "./SurchargeShiftRow/SurchargeShiftRow";
import { v4 as uuid } from "uuid";
import { IUser } from "../../../shared/entities/IUser";
import cn from "classnames";
import { IAbsence, AbsenceStatus } from "../../../shared/entities/IAbsence";
import { RepeatingShiftOptions } from "./RepeatingShiftOptions/RepeatingShiftOptions";
import { DeleteRepeatingShiftModal } from "../../modals/DeleteRepeatingShiftModal/DeleteRepeatingShiftModal";
import { IShiftRepeat } from "../../../shared/entities/IShiftRepeat";
import { featuresSelector } from "../../../selectors/FeaturesSelector";
import { Hinter } from "../../../actions/hinting";
import { ShiftCommentField } from "./ShiftCommentField/ShiftCommentField";
import { shiftRepository } from "../../../repositories/shiftRepository";
import { HandOverRequestModal } from "../../modals/HandOverRequestModal/HandOverRequestModal";
import { isShiftInPublishedWeek, waitAsync } from "../../../helpers/general";
import { OpenShiftApplySection } from "./OpenShiftApplySection/OpenShiftApplySection";
import { ChangeRequestCreateRow } from "../components/ChangeRequestCreateRow/ChangeRequestCreateRow";
import { environment } from "../../../env";
import { SDateFormat, SDateTimeFormat, STimeFormat } from "../../../shared/helpers/SimpleTime";
import { selectRosterSettingsByUser } from "../../../selectors/rosterSettingsByUserSelector";
import { selectAbsencesByUser } from "../../../selectors/absencesByUserSelector";
import { getApplicableBreakRuleMinutes, getMatchingBreakRule } from "../../../shared/helpers/shiftHelpers";
import { paidFeatureWarning } from "../../../actions/paidFeatureWarning";
import { ShiftBranchSelect } from "./ShiftBranchSelect/ShiftBranchSelect";
import { ShiftPopupAbsenceRow } from "../components/ShiftPopupAbsenceRow/ShiftPopupAbsenceRow";
import { tierInfoSelector } from "../../../selectors/TierInfoSelector";
import { ThreadsListStyles } from "../../../pages/Chat/Sidebar/ThreadsList/styles";
import { trackingRepository } from "../../../repositories/TrackingRepository";
import { selectHolidayFinder } from "../../../selectors/holidayMapSelector";
import {
  selectBranchMap,
  selectContractsByUserMap,
  selectJobPositionMap,
  selectTimeClockingMap,
  selectTrackingMap,
} from "../../../selectors/mapSelectors";
import { getValidContract } from "../../../shared/helpers/credit";
import { addSimpleDays } from "../../../shared/helpers/dateHelpers";
import { stopTimeClocking, toggleTimeClockingBreak } from "../../../actions/timeClocking";
import { IUserRosterSettings } from "../../../shared/entities/IRosterSettings";
import { selectTimeClockSettingsByUser } from "../../../selectors/timeClockSettingsByUserSelector";
import { canUserEditOwnShiftsOfDate } from "../../../pages/shiftsPage/RosterPage/helpers";
import { selectWorkSpaces } from "../../../selectors/_workSpacesSelector";
import { selectHashtags } from "../../../selectors/hashtagSelector";
import { HashtagSelectField } from "./HashtagSelectField/HashtagSelectField";
import { selectUserFullMap } from "../../../selectors/userFullMapSelector";
import { AvIcon } from "../../AvIcon/AvIcon";
import { shiftToV2Clocking } from "./helper";
import { selectTimeClockSettingsOfSesionUser } from "../../../selectors/timeClockSettingsOfSessionUser";
import { canEmployeAddEditPunchingOnDate } from "../../../frontend-core/helpers/frontendHelpers";
import { ThreadListItem } from "../../../pages/Chat/Sidebar/ThreadsList/ThreadListItem";
import { selectIsMultiJobPos } from "../../../selectors/isMultiJobPos";
import { selectIsManualTrackingEnaledForSessionUser } from "../../../selectors/isManualTrackingEnaledForSessionUserSelector";

const mapStateToProps = (state: AppState, props: OwnProps) => {
  const userId = props.shift.userId;
  return {
    jobPositions: state.data.jobPositions,
    activeUsers: selectActiveUsers(state, props.shift.date!),
    users: state.data.users,
    isV2: state.data.tenantInfo.isV2,
    sessionInfo: selectSessionInfo(state),
    selectedBranch: state.ui.selectedBranch,
    tracking: props.shift.id ? selectTrackingMap(state)[props.shift.id] : undefined,
    timeClocking: props.shift.id ? selectTimeClockingMap(state)[props.shift.id] : undefined,
    workSpaces: selectWorkSpaces(state),
    handOverRequests: selectActiveHandOverRequests(state),
    userRosterSetting: userId
      ? selectRosterSettingsByUser(state)[userId]
      : (state.data.rosterSettings[0] as IUserRosterSettings),
    userTimeClockSetting: userId ? selectTimeClockSettingsByUser(state)[userId] : state.data.timeClockSettings[0],
    isRosterTemplateMode: state.ui.shifts.roster.rosterTemplateMode.active,
    changeRequests: selectChangeRequests(state),
    absencesByUser: selectAbsencesByUser(state),
    features: featuresSelector(state),
    tierInfo: tierInfoSelector(state),
    shiftRepeat: props.shift?.repeatId ? state.data.shiftRepeats.find((r) => r.id === props.shift.repeatId) : undefined,
    surcharges: state.data.surcharges,
    holidayFinder: selectHolidayFinder(state),
    contractsByUser: selectContractsByUserMap(state),
    publishedWeeks: state.data.publishedWeeks,
    hashtags: selectHashtags(state),
    userFullMap: selectUserFullMap(state),
    jobPosMap: selectJobPositionMap(state),
    branchesMap: selectBranchMap(state),
    sessionUserTimeClockSettings: selectTimeClockSettingsOfSesionUser(state),
    isManualTrackingEnabled: selectIsManualTrackingEnaledForSessionUser(state),
    shifts: state.data.shifts,
    isMultiJobPos: selectIsMultiJobPos(state),
  };
};

type OwnProps = {
  shift: Partial<IShift> | IShift; // in creationMode its a partial - otherwise a IShift
  isRequirement?: boolean;
  onUpdateComplete?: () => void;
  isUserPickerMode?: boolean;
};

type State = {
  tracking?: ITracking;
  isTrackingEditMode: boolean;
  isChangeRequestCreation: boolean;
  showConfirmDelete?: boolean;
  showRepeatingDeleteOption?: boolean;
  shift: Partial<IShift>;
  expandedFields: ExpandableFieldKey[];
  selectedUserIds: string[]; // user in isUserPickerMode
  enforcingOpenShift: boolean; // relevant for isUserPickerMode
  isUserPickerMode?: boolean;
  shiftRepeat?: IShiftRepeat;
  resettingDeleteBtnPointer?: boolean; // little hack to update tooltip while mouse is over a button
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & BusyInjectorProps & DispatchBaseProps;

class _ShiftPopup extends React.PureComponent<Props, State> {
  state: State;
  popoverContentRef: React.RefObject<any>; // has to do something with click-outside logic | dirty David stuff
  originalShift: Partial<IShift>; // used to do diffing to track changes
  isCreation: boolean;
  today: string;
  autoFillBreak: boolean;
  canAddEditShift: boolean;
  canManage: boolean;

  constructor(props: Props) {
    super(props);
    const { sessionInfo, userRosterSetting, sessionUserTimeClockSettings, selectedBranch } = props;

    this.isCreation = !props.shift.id;
    this.today = moment().format(SDateFormat);
    this.canManage = sessionInfo.hasManagerPermissions();
    const isOwnShift = sessionInfo.user.id === props.shift.userId;
    const isOpenShift = !props.shift.userId;
    const shiftUser = props.shift.userId ? props.users.find((u) => u.id === props.shift.userId) : undefined;
    const isV2 = props.isV2;
    const _shift = { ...props.shift };
    const tempShift = {
      ..._shift,
      branchId: _shift.branchId || selectedBranch || sessionInfo.user.branchIds[0],
    } as IShift;
    const canEmployeeEditShift = canUserEditOwnShiftsOfDate(userRosterSetting, _shift.date!);
    const canEmployeeEditClocking = canEmployeAddEditPunchingOnDate(
      sessionUserTimeClockSettings,
      _shift.date!,
      this.today
    );
    const isShiftPublished = isShiftInPublishedWeek(props.publishedWeeks, tempShift);

    this.canAddEditShift = isV2
      ? this.canManage || (isOwnShift && canEmployeeEditClocking)
      : this.canManage || (isOwnShift && canEmployeeEditShift && isShiftPublished);

    if (props.isRequirement) {
      _shift.isRequirement = true;
    }

    if (this.isCreation && !_shift.branchId) {
      _shift.branchId = this.props.selectedBranch || undefined;
      shiftUser?.branchIds.length === 1 && (_shift.branchId = shiftUser.branchIds[0]);
    }

    if (this.isCreation && !_shift.userId) {
      _shift.appliedUserIds = [];
      _shift.requiredUsersAmount = 1;
    }

    if (!_shift.jobPositionId && !isOpenShift && (shiftUser?.jobPositionIds.length === 1 || isV2)) {
      _shift.jobPositionId = shiftUser?.jobPositionIds[0];
    }

    if (isV2 && !props.timeClocking?.endTime) {
      _shift.endTime = undefined;
    }

    const expandedFields: ExpandableFieldKey[] = [];
    (_shift.workSpaceId || _shift.isTemplate) && expandedFields.push("workSpace"); // templates should always have it open initially
    _shift.surcharge && expandedFields.push("surcharge");
    _shift.repeatId && expandedFields.push("repeatOptions");
    _shift.comment && expandedFields.push("comment");
    _shift.addressId && expandedFields.push("address");
    _shift.hashtagIds && expandedFields.push("hashtags");
    _shift.userId && !this.canAddEditShift && expandedFields.push("comment"); // for nonManagers open commentField

    this.state = {
      tracking: props.tracking,
      isTrackingEditMode: false,
      isChangeRequestCreation: false,
      shift: _shift,
      expandedFields,
      isUserPickerMode: props.isUserPickerMode,
      selectedUserIds: [], // user in isUserPickerMode
      enforcingOpenShift: false, // relevant for isUserPickerMode
    };

    this.handleEnter = this.handleEnter.bind(this);
    this.popoverContentRef = React.createRef();
    this.originalShift = { ..._shift }; // used to make a diffing for notifications
    this.autoFillBreak = !!props.userRosterSetting.applyBreakRulesOnShifts;
  }

  handleEnter(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      this.submit();
    }
  }

  async componentDidMount() {
    const { shift, dispatch, shiftRepeat, sessionInfo, isV2, selectedBranch } = this.props;
    const canManage = sessionInfo.hasManagerPermissions();

    if (environment.isV2() && !this.props.features.timeClock) {
      this.props.dispatch(closeModal());
      this.props.dispatch(paidFeatureWarning());
      return;
    }

    Sentry.addBreadcrumb({
      message: "opened shiftPopup",
      data: { shift: this.props.shift },
    });

    // dont remove this console.log > its nice for life debugging
    console.log(this.state.shift);
    console.log(this.props.timeClocking);

    const isCreationMode = !this.props.shift.id;
    const jobPositionIds = this.props.jobPositions.filter((jp) => !jp.isInactive).map((jp) => jp.id);

    window.addEventListener("keydown", this.handleEnter);

    if (shift?.id) {
      dispatch(timeClockingRepository.fetchOne(shift.id));
      dispatch(trackingRepository.fetchOne(shift.id));
    }

    if (isCreationMode && !this.props.shift.userId && jobPositionIds.length === 1) {
      this.updateShift({ jobPositionId: jobPositionIds[0] });
    }

    if (isV2 && !shift.userId) {
      // inV2 when clicking on `Add-tracking-btn` we preselect the sessionUnser, only if he is also assigned to the selectedBranch
      const userHasSelectedBranch = sessionInfo.user.branchIds.includes(selectedBranch) || !selectedBranch;
      userHasSelectedBranch && this.changeShiftUserIdV2(sessionInfo.user.id);
    }

    if (shift.repeatId) {
      shiftRepeat ? this.setState({ shiftRepeat }) : this.updateShift({ repeatId: undefined });
      // Because of a Bug that delted shiftRepeats we do remove the shiftId if we couldnt find the shiftRepeat
    }

    if (isCreationMode && !canManage && !this.canAddEditShift && !isV2) {
      // this is the case, when the weekplan is not published
      notification.warning({ message: lg.wochenplan_ist_nicht_veröffentlicht });
      dispatch(closeModal());
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleEnter);
  }

  generateShiftData = (): IShift => {
    const pShift = this.props.shift;
    const sShift = this.state.shift;
    const shift = { ...pShift, ...sShift };

    return {
      ...shift,
      id: shift.id || uuid(),
      jobPositionId: sShift.jobPositionId!,
      appliedUserIds: shift.userId ? undefined : shift.appliedUserIds,
      requiredUsersAmount: shift.userId ? undefined : shift.requiredUsersAmount,
      breakMinutes: sShift.breakMinutes || 0,
      surcharge: sShift.surcharge || undefined, // to clear out possible empty string
      surchargeInPercentage: sShift.surcharge ? sShift.surchargeInPercentage : undefined,
    } as IShift;
  };

  isSubmitDisabled = (): boolean => {
    const { isTrackingEditMode, selectedUserIds, isUserPickerMode } = this.state;
    const { shifts, sessionInfo, handOverRequests, shift } = this.props;
    const today = moment().format(SDateFormat);
    const yesterday = moment().add(-1, "days").format(SDateFormat);
    const isV2 = !!this.props.isV2;

    const hasRunningClocking =
      isV2 &&
      !!shifts.find(
        (s) => s.id !== shift.id && s.userId === this.state.shift.userId && s.date >= yesterday && s.isActiveClocking
      );

    const _endTime =
      isV2 && !this.state.shift.endTime && this.state.shift.date === today // inV2 you can enter a Clocking without endTime for today
        ? this.state.shift.startTime
        : this.state.shift.endTime;

    const isWorkTimeValid = !isWorkingTimeInvalid(this.state.shift.startTime, _endTime, this.state.shift.breakMinutes);
    const hasHandoverRequest = !!handOverRequests.find((hR) => hR.id === shift.id);
    const isValid = this.state.shift.jobPositionId && this.state.shift.branchId && isWorkTimeValid;
    const didNotPickUsers = !!isUserPickerMode && !selectedUserIds.length;
    const isRequiredUsersInvalid = !this.state.shift.userId && this.state.shift.requiredUsersAmount === 0;

    const isDisabled =
      isTrackingEditMode ||
      hasHandoverRequest ||
      !isValid ||
      didNotPickUsers ||
      isRequiredUsersInvalid ||
      !this.canAddEditShift ||
      (isV2 && !this.state.shift.userId) ||
      (isV2 && !this.state.shift.endTime && this.state.shift.date! < today) ||
      (isV2 && !this.state.shift.endTime && hasRunningClocking) ||
      (isV2 && !this.state.shift.endTime && this.isPunchingRequestV2());

    return isDisabled;
  };

  submit = async (): Promise<void> => {
    const closeInstantly = !this.state.shiftRepeat;
    const { publishedWeeks, sessionInfo, isV2 } = this.props;

    if (this.props.tierInfo.unpaidSinceTwoWeeks) {
      return this.props.dispatch(paidFeatureWarning());
    }

    if (
      !sessionInfo.hasManagerPermissions() &&
      !isShiftInPublishedWeek(publishedWeeks, this.generateShiftData()) &&
      !isV2
    ) {
      // in case users can addEdit own shits we need to prevent shift creation in unpublished weeks
      return notification.warning({ message: lg.wochenplan_ist_nicht_veröffentlicht });
    }
    // to do it optimistically here
    if (!this.isSubmitDisabled()) {
      closeInstantly && this.props.dispatch(closeModal());
      await this.saveShift();
      !closeInstantly && this.props.dispatch(closeModal());
      this.props.onUpdateComplete && this.props.onUpdateComplete();
      !isV2 && this.props.dispatch(Hinter.closedShiftModal());
    }
  };

  saveEditsRepeatingly = async () => {
    if (!this.isSubmitDisabled()) {
      const shift = this.generateShiftData();
      await this.props.dispatch(editRepeatingShift(shift, this.state.shiftRepeat!));
      this.props.dispatch(closeModal());
      this.props.onUpdateComplete && this.props.onUpdateComplete();
    }
  };

  saveShift = async (): Promise<IShift> => {
    const { isUserPickerMode, selectedUserIds } = this.state;
    const { dispatch, isV2 } = this.props;
    const shift = this.generateShiftData() as IShift;
    Sentry.addBreadcrumb({ message: "saved shift", data: { shift } });

    if (isUserPickerMode) {
      // userPickerMode is only in creationMode
      const shifts = selectedUserIds.map((userId) => ({ ...shift, id: uuid(), userId }));
      dispatch(setRecentModifiedShifts(shifts.map((s) => s.id)));
      await dispatch(createMultiShifts(shifts, this.state.shiftRepeat));
    } else if (isV2) {
      await this.savePunching(shift);
    } else {
      dispatch(setRecentModifiedShifts([shift.id]));
      await this.props.dispatch(this.props.shift.id ? editShift(shift) : createShift(shift, this.state.shiftRepeat));
    }
    return shift;
  };

  savePunching = (shift: IShift) => {
    const isCreation = !this.props.shift.id;
    const { dispatch, timeClocking } = this.props;
    const punching = this.props.dispatch(shiftToV2Clocking(shift, timeClocking));

    return isCreation
      ? dispatch(timeClockingRepository.create(punching))
      : dispatch(timeClockingRepository.update(punching));
  };

  handleTrackingChange = (tracking?: ITracking) => {
    this.setState({ tracking });
  };

  openDeleteRepeatingModal = () => {
    this.props.dispatch(
      openModal(DeleteRepeatingShiftModal, {
        shift: this.props.shift as IShift,
        shiftRepeat: this.state.shiftRepeat!,
        onUpdateComplete: this.props.onUpdateComplete,
      })
    );
  };

  deleteClick = async () => {
    const { dispatch, shift, timeClocking, isV2 } = this.props;
    if (!this.state.showConfirmDelete) {
      this.setState({ resettingDeleteBtnPointer: true }); // hacky way to update tooltip on delte-button while mouse is still over it
      setTimeout(() => {
        this.setState({
          showConfirmDelete: true,
          resettingDeleteBtnPointer: false,
          showRepeatingDeleteOption: !!this.state.shiftRepeat,
        });
      }, 50);
      return;
    }
    dispatch(closeModal());

    isV2
      ? await dispatch(timeClockingRepository.remove(timeClocking!.id))
      : await dispatch(deleteShift(shift as IShift));

    this.props.onUpdateComplete && this.props.onUpdateComplete();
  };

  updateShift = (_shift: Partial<IShift>): Partial<IShift> => {
    const shift = { ...this.state.shift, ..._shift };
    this.setState({ shift });
    return shift;
  };

  updateShiftValue = (field: keyof IShift, value: any) => {
    const newShift = this.updateShift({ [field]: value });

    const shiftTimeChanged = field === "startTime" || field === "endTime";
    this.autoFillBreak && shiftTimeChanged && this.applyBreakRules(newShift);
  };

  applyBreakRules = (shift: Partial<IShift>) => {
    const { startTime, endTime, breakMinutes } = shift;

    if (!isWorkingTimeInvalid(startTime, endTime)) {
      const userRosterSetting = this.props.userRosterSetting as IUserRosterSettings;
      const validBreakMinutes = getApplicableBreakRuleMinutes(userRosterSetting, {
        startTime: startTime!,
        endTime: endTime!,
      });
      const needToUpdateBreak = validBreakMinutes !== breakMinutes;
      // needs to use setTimeout to not override previous setState in `updateShiftValue` > push it to next render-cycle
      needToUpdateBreak && setTimeout(() => this.updateShiftValue("breakMinutes", validBreakMinutes));
    }
  };

  isDirty = () => {
    const prevShift = this.props.shift;
    const currentShift = this.state.shift;
    const isEqual = _.isEqual(prevShift, currentShift);
    return !isEqual;
  };

  onChangeRequestAccepted = (changeRequest: IChangeRequest) => {
    const { startTime, endTime, breakMinutes } = changeRequest;
    const changedShift = {
      ...this.state.shift,
      startTime,
      endTime,
      breakMinutes,
    };
    this.setState({
      shift: changedShift,
    });
  };

  assignOpenShiftClicked = async () => {
    if (this.isCreation) {
      this.setState({ isUserPickerMode: true });
      return;
    }

    let shift = this.props.shift;
    if (this.isDirty()) {
      shift = await this.props.load(this.saveShift(), "saveBeforeAssign");
    }
    this.props.dispatch(closeModal());
    this.props.dispatch(openModal(OpenShiftAssignmentPopup, { shiftId: shift.id! }));
  };

  collapseField = (field: ExpandableFieldKey) => {
    this.setState({
      expandedFields: this.state.expandedFields.filter((f) => f !== field),
    });
  };

  removeAssignedUser = async () => {
    const { id, date, jobPositionId, userId } = this.state.shift as IShift;
    await this.props.load(this.props.dispatch(moveShift(id!, date, undefined, jobPositionId, false)));
    this.props.dispatch(closeModal());
    // this.setState({
    //   shift: {
    //     ...this.state.shift,
    //     requiredUsersAmount: 1,
    //     userId: undefined,
    //     repeatId: undefined,
    //   },
    //   shiftRepeat: undefined,
    // });
  };

  expandField = (field: ExpandableFieldKey) => {
    if (field === "repeatOptions") {
      if (!this.props.features.repeatingShifts) {
        this.props.dispatch(paidFeatureWarning());
        return;
      }
    }
    this.setState({
      expandedFields: [...this.state.expandedFields, field],
    });
  };

  repeatEndDateHasChanged = () =>
    !this.isCreation && this.props.shiftRepeat?.endDate !== this.state.shiftRepeat?.endDate;

  updateSelectedUserIds = (userIds: string[]) => {
    if (!this.state.shift.jobPositionId && userIds.length === 1) {
      // When a user is no jobPosition selected yet, we set the first jobPos of the first user
      const selectedUser = this.props.users.find((u) => u.id === userIds[0]) as IUser;
      this.updateShiftValue("jobPositionId", selectedUser.jobPositionIds[0]);
    }
    this.setState({ selectedUserIds: userIds });
  };

  collapseRepeatingShiftField = () => {
    this.collapseField("repeatOptions");
    this.setState({ shiftRepeat: undefined });
  };

  updateShiftRepeat = (shiftRepeat: IShiftRepeat) => {
    this.setState({ shiftRepeat });
  };

  saveRepeatinglyClicked = () => {
    Modal.confirm({
      title: lg.diese_schicht_und_alle_folgenden_speichern,
      content: lg.dabei_werden_alle_folgenden_wiederholungen_auf_basis_dieser_schicht_neu_generiert,
      onOk: () => this.props.load(this.saveEditsRepeatingly(), "editRepeate"),
      okText: lg.Speichern,
      cancelText: lg.abbrechen,
    });
  };

  saveShiftComment = async () => {
    const { dispatch, isV2 } = this.props;
    const { shift } = this.state;
    if (shift.id) {
      isV2
        ? dispatch(timeClockingRepository.editComment(shift.id, shift.comment || ""))
        : dispatch(shiftRepository.editComment(shift.id, shift.comment || ""));
    }
  };

  openHandOverRequestModal = async () => {
    this.props.dispatch(closeModal());
    // await waitAsync(10);
    this.props.dispatch(openModal(HandOverRequestModal, { shift: this.props.shift as IShift }));
  };

  onTrackingUpdateComplete = () => {
    const { shift, onUpdateComplete, dispatch } = this.props;
    const comment = this.state.shift.comment;
    // because the shiftModal gets closed when saving a Tracking. We also save the comment to DB if it was edited.
    shift.comment !== comment && shift.id && dispatch(shiftRepository.editComment(shift.id, comment));
    onUpdateComplete && onUpdateComplete();
  };

  updateBranchId = (branchId: string) => {
    this.setState({
      shift: { ...this.state.shift, branchId },
      shiftRepeat: this.state.shiftRepeat && { ...this.state.shiftRepeat, branchId },
    });
  };

  isPunchingRequestV2 = (): boolean => {
    const { shift, timeClocking, isV2 } = this.props;
    const canManage = this.props.sessionInfo.hasManagerPermissions();
    return !!(isV2 && canManage && !shift.isActiveClocking && timeClocking && !timeClocking.isAccepted);
  };

  changeShiftUserIdV2 = (userId: string) => {
    const { userFullMap, jobPosMap, branchesMap } = this.props;
    const user = userFullMap[userId];
    const nextJobPositionId = user.jobPositionIds.filter((jobPosId) => !jobPosMap[jobPosId].isInactive)[0];
    const nextBranchId = user.branchIds.filter((jobPosId) => !branchesMap[jobPosId].isInactive)[0];
    const needToUpdateJobPos = !user.jobPositionIds.includes(this.state.shift.jobPositionId || "x");
    const needToUpdateBranchId = !user.branchIds.includes(this.state.shift.branchId || "x");
    const jobPositionId = needToUpdateJobPos ? nextJobPositionId : this.state.shift.jobPositionId;
    const branchId = needToUpdateBranchId ? nextBranchId : this.state.shift.branchId;
    this.updateShift({ userId, jobPositionId, branchId });
  };

  render() {
    const {
      tracking,
      handOverRequests,
      shift,
      isRosterTemplateMode,
      timeClocking,
      changeRequests,
      onUpdateComplete,
      isLoading,
      load,
      userRosterSetting,
      userTimeClockSetting,
      features,
      dispatch,
      hashtags,
      userFullMap,
      isV2,
      isMultiJobPos,
      isManualTrackingEnabled,
    } = this.props;
    const { isTrackingEditMode, showConfirmDelete, expandedFields, isUserPickerMode } = this.state;
    const { startTime, endTime, breakMinutes, breakStartTime } = this.state.shift;

    let absence: IAbsence | undefined;
    if (shift.userId) {
      const userAbsences = this.props.absencesByUser[shift.userId] || [];
      absence = userAbsences.find(
        (a) =>
          a.startDate <= (shift as IShift).date &&
          a.endDate >= (shift as IShift).date &&
          a.status === AbsenceStatus.active
      );
    }

    const canManage = this.props.sessionInfo.hasManagerPermissions();
    const isCreationMode = !this.props.shift.id;
    const sessionUser = this.props.sessionInfo.user;
    const isOwnShift = sessionUser.id === shift.userId;
    const hasHandoverRequest = !!handOverRequests.find((hR) => hR.id === shift.id);
    const changeRequest = changeRequests.find((cR) => cR.id === shift.id);
    const hasTracking = !!tracking;
    const shiftIsInFuture = shift.date && shift.date > toSimpleDate(moment());
    const { usersCanMakeChangeRequests, usersCanSwapShifts, usersCanApplyToOpenShifts } = userRosterSetting;

    const dateWasChanged = this.props.shift.date !== this.state.shift.date;

    const deleteDisabledTooltipText = lg.eine_schicht_mit_zeiterfassung_kann_nicht_gelöscht_werden;
    const isProFeatureText = lg.um_diese_funktion_zu_nutzen_brauchst_du_mindestens_den_pro_plan;

    const isBreakStartTimeValid = !breakStartTime || moment(breakStartTime!, STimeFormat).isValid();
    const isWorkTimeValid = !isWorkingTimeInvalid(startTime, endTime, breakMinutes) && isBreakStartTimeValid;
    const canAddEditShift = this.canAddEditShift;
    const isValid = this.state.shift.jobPositionId && isWorkTimeValid;

    const isUserEditable =
      !!this.state.shift.userId &&
      !hasTracking &&
      !hasHandoverRequest &&
      !changeRequest &&
      !timeClocking &&
      !isCreationMode;

    const showTrackingRow =
      !isCreationMode &&
      shift.userId &&
      !hasHandoverRequest &&
      !isRosterTemplateMode &&
      !shiftIsInFuture &&
      (canManage || (isOwnShift && !!tracking) || (isOwnShift && isManualTrackingEnabled));

    const isTimeInputDisabled =
      !canAddEditShift || isTrackingEditMode || hasHandoverRequest || !!changeRequest || !!timeClocking;

    const _isTemplate = isRosterTemplateMode || shift.isTemplate;

    let _expandableFields = expandableFields.filter(
      (f) =>
        !this.state.expandedFields.includes(f.key) &&
        (f.key !== "repeatOptions" || (isCreationMode && canManage && !_isTemplate && !isV2)) &&
        (f.key !== "address" || userRosterSetting.shiftAddressesActive) &&
        (f.key !== "hashtags" || hashtags.length) &&
        (f.key !== "surcharge" || (canManage && !this.state.shift.isRequirement && !isV2))
    );

    return (
      <TZModal className="shiftEditorPopup shiftPopup">
        <TZModal.Body>
          <div ref={this.popoverContentRef} className="shiftEditor">
            <div>
              <ShiftPopupHead
                isUserEditable={isUserEditable}
                onUserRemove={this.removeAssignedUser}
                shift={{ ...this.state.shift }}
                tracking={tracking}
                clocking={timeClocking}
                changeShiftDate={(date) => this.updateShiftValue("date", date)}
                isRosterTemplateMode={isRosterTemplateMode}
                showUserSelectV2={isV2 && !this.props.shift.userId}
                changeShiftUserIdV2={this.changeShiftUserIdV2}
                isCreation={this.isCreation}
                title={
                  !shift.userId &&
                  !this.state.isUserPickerMode &&
                  (this.state.shift.isRequirement
                    ? lg.bedarf
                    : this.state.shift.isTemplate
                    ? lg.vorlage
                    : lg.offene_schicht)
                }
              />
              <ShiftPopupWarningRow shift={this.state.shift} />
              {absence && canManage && <ShiftPopupAbsenceRow absence={absence} />}
              <StartEndTimeInput
                onChange={(value, name) => this.updateShiftValue(name, value)}
                startTime={(this.state.shift.startTime as string) || ""}
                endTime={(this.state.shift.endTime as string) || ""}
                breakMinutes={this.state.shift.breakMinutes}
                breakStartTime={this.state.shift.breakStartTime}
                isInvalid={!isWorkTimeValid}
                withBorderBottom
                disabled={isV2 ? !this.canAddEditShift : isTimeInputDisabled}
                showDuration={!this.state.shift.isRequirement}
                isDynamicClocked={shift.isDynamicClocked}
                withBreakStartTime={userRosterSetting.canEnterBreakStartTime}
                extraDuration={tracking && getDuration(tracking)}
                hideBreakInput={this.state.shift.isRequirement}
                userFullMap={this.props.userFullMap}
                isV2={isV2}
                clockingV2={timeClocking}
              />
              <div className="baseContent">
                {isCreationMode && isUserPickerMode && (
                  <div
                    className={cn({
                      darkOverlay: true,
                      invisible: isWorkTimeValid,
                    })}
                  ></div>
                )}
                {!isCreationMode && timeClocking && !isV2 && (
                  <div className="timeClockingRowWrapper">
                    <TimeClockingRow shift={shift as IShift} timeClocking={timeClocking} />
                  </div>
                )}
                {showTrackingRow && !isV2 && (
                  <div className="trackingWrapper">
                    <Tracking
                      shift={this.state.shift as IShift}
                      tracking={this.state.tracking}
                      isEditMode={this.state.isTrackingEditMode}
                      onEditModeChange={(isTrackingEditMode) => this.setState({ isTrackingEditMode })}
                      onChange={this.handleTrackingChange}
                      timeClocking={this.props.timeClocking}
                      onUpdateComplete={this.onTrackingUpdateComplete}
                      updateNoShow={(isNoShow) => this.updateShift({ isNoShow })}
                      withBreakStartTime={userRosterSetting.canEnterBreakStartTime}
                    />
                  </div>
                )}
                {changeRequest && (canManage || isOwnShift) && (
                  <WithDevider>
                    <ChangeRequestReviewRow
                      changeRequest={changeRequest}
                      shift={shift as IShift}
                      onChangeRequestAccepted={this.onChangeRequestAccepted}
                    />
                  </WithDevider>
                )}
                {this.state.isChangeRequestCreation && (
                  <ChangeRequestCreateRow
                    shift={this.props.shift as IShift}
                    cancelEditing={() => this.setState({ isChangeRequestCreation: false })}
                  />
                )}
                {hasHandoverRequest && (
                  <div className="handoverRequestWrapper">
                    <HandoverRequestSection
                      shift={shift as IShift}
                      userId={shift.userId!}
                      handOverRequest={handOverRequests.find((hR) => hR.id === shift.id)!}
                    />
                  </div>
                )}
                {!this.props.selectedBranch && (
                  <ShiftBranchSelect
                    userId={this.state.shift.userId}
                    selectedUserIds={this.state.selectedUserIds}
                    value={this.state.shift.branchId}
                    getPopupContainer={() => this.popoverContentRef.current}
                    disabled={isTrackingEditMode || hasHandoverRequest}
                    onChange={this.updateBranchId}
                    isDisplayOnly={!canAddEditShift}
                  />
                )}
                {isV2 && (!this.state.shift.userId || !isMultiJobPos) ? null : (
                  <JobPositionSelect
                    userId={this.state.shift.userId}
                    selectedUserIds={this.state.selectedUserIds}
                    value={this.state.shift.jobPositionId}
                    getPopupContainer={() => this.popoverContentRef.current}
                    disabled={isTrackingEditMode || hasHandoverRequest}
                    onChange={(value: string) => this.updateShiftValue("jobPositionId", value)}
                    isDisplayOnly={!canAddEditShift}
                  />
                )}
                {((!this.state.shift.userId && !isUserPickerMode) || this.state.enforcingOpenShift) &&
                  !shift.isTemplate &&
                  !isV2 &&
                  canManage && (
                    <OpenShiftActions
                      shift={this.props.shift as IShift | undefined}
                      setRequiredUsersAmount={(n: number) => this.updateShiftValue("requiredUsersAmount", n)}
                      requiredUsersAmount={this.state.shift.requiredUsersAmount || 1}
                      appliedUserIds={this.props.shift.appliedUserIds}
                      assignOpenShiftClicked={this.assignOpenShiftClicked}
                      canDirectAssign={!this.props.isUserPickerMode}
                      isDirectAssignDisabled={!isValid}
                      isLoading={this.props.isLoading("saveBeforeAssign")}
                    />
                  )}
                {!shift.userId &&
                  !canManage &&
                  sessionUser.jobPositionIds.includes(shift?.jobPositionId || "") &&
                  usersCanApplyToOpenShifts && <OpenShiftApplySection shift={shift as IShift} />}
                {isUserPickerMode && !this.state.enforcingOpenShift && canManage && (
                  <UserSelectShiftRow
                    date={shift.date!}
                    jobPositionId={this.state.shift.jobPositionId}
                    selectedUserIds={this.state.selectedUserIds}
                    updateSelectedUserIds={this.updateSelectedUserIds}
                    turnIntoOpenShift={() => this.setState({ isUserPickerMode: false })}
                    startTime={this.state.shift.startTime}
                    endTime={this.state.shift.endTime}
                  />
                )}

                {(this.isCreation || this.state.shift.repeatId) &&
                  expandedFields.includes("repeatOptions") &&
                  canManage && (
                    <RepeatingShiftOptions
                      shift={this.state.shift}
                      shiftRepeat={this.state.shiftRepeat}
                      isCreationMode={isCreationMode}
                      updateShiftRepeat={this.updateShiftRepeat}
                      collapseField={this.collapseRepeatingShiftField}
                    />
                  )}

                {expandedFields.includes("workSpace") && (
                  <WorkSpaceSelect
                    value={this.state.shift.workSpaceId}
                    getPopupContainer={() => this.popoverContentRef.current}
                    disabled={isTrackingEditMode || !features.shiftLabels}
                    data-rh={features.shiftLabels ? null : isProFeatureText}
                    onChange={(value: string) => this.updateShiftValue("workSpaceId", value)}
                    closeField={() => {
                      this.updateShiftValue("workSpaceId", undefined);
                      this.collapseField("workSpace");
                    }}
                    isDisplayOnly={!canAddEditShift}
                    noAutoFocus={!!shift.isTemplate}
                  />
                )}

                {expandedFields.includes("address") && (
                  <AddressSelect
                    value={this.state.shift.addressId}
                    getPopupContainer={() => this.popoverContentRef.current}
                    disabled={isTrackingEditMode || !features.shiftLabels}
                    data-rh={features.shiftLabels ? null : isProFeatureText}
                    onChange={(value: string) => this.updateShiftValue("addressId", value)}
                    closeField={() => {
                      this.updateShiftValue("addressId", undefined);
                      this.collapseField("address");
                    }}
                    isDisplayOnly={!canAddEditShift}
                  />
                )}

                {expandedFields.includes("hashtags") && (
                  <HashtagSelectField
                    selectedIds={this.state.shift.hashtagIds}
                    getPopupContainer={() => this.popoverContentRef.current}
                    disabled={isTrackingEditMode || !features.shiftLabels}
                    data-rh={features.shiftLabels ? null : isProFeatureText}
                    onChange={(value?: string[]) => this.updateShiftValue("hashtagIds", value)}
                    closeField={() => {
                      this.updateShiftValue("hashtagIds", undefined);
                      this.collapseField("hashtags");
                    }}
                    isDisplayOnly={!canAddEditShift}
                  />
                )}

                {expandedFields.includes("comment") && (
                  <ShiftCommentField
                    value={this.state.shift.comment}
                    saveShiftComment={this.saveShiftComment}
                    onChange={(val: string) => this.updateShiftValue("comment", val)}
                    disabled={isTrackingEditMode}
                    hastFeatureShiftComment={features.shiftComments}
                    canManage={canAddEditShift}
                    isDisplayOnly={!canManage && !isOwnShift}
                    closeField={() => this.collapseField("comment")}
                  />
                )}

                {expandedFields.includes("surcharge") && canManage && (
                  <SurchargeShiftRow
                    shift={this.state.shift}
                    updateShiftValue={this.updateShiftValue}
                    collapseField={() => {
                      this.collapseField("surcharge");
                      this.updateShiftValue("surcharge", undefined);
                    }}
                  />
                )}
                {canAddEditShift && (
                  <ExpandableShiftFields
                    fields={_expandableFields}
                    expandField={this.expandField}
                    shift={this.props.shift}
                  />
                )}

                {!canManage && this.props.shift.id && (
                  // empty Footer for employees
                  <div className="fb jStart aCenter footer ownShiftFooter">
                    <div style={{ width: 120 }}></div>
                    {isOwnShift && !hasHandoverRequest && !changeRequest && shiftIsInFuture && usersCanSwapShifts && (
                      <Button
                        className="actionButton"
                        id="shift-popup-own-shift-handover-button"
                        icon="team"
                        children={lg.schichtabgabe}
                        onClick={this.openHandOverRequestModal}
                      />
                    )}
                    {isOwnShift &&
                      !changeRequest &&
                      !hasHandoverRequest &&
                      !this.state.isChangeRequestCreation &&
                      shiftIsInFuture &&
                      usersCanMakeChangeRequests && (
                        <Button
                          className="actionButton"
                          id="shift-popup-own-shift-change-request-button"
                          icon="scissor"
                          children={lg.änderungsantrag}
                          onClick={() => {
                            this.setState({ isChangeRequestCreation: true });
                          }}
                        />
                      )}
                  </div>
                )}

                {canAddEditShift && (
                  <div className="fb jEnd aCenter footer">
                    {!environment.isProd() && !this.isCreation && !timeClocking && (
                      <Button
                        style={{ marginRight: 10 }}
                        children="Clock IN"
                        icon={isLoading("clockIn") ? "loading" : undefined}
                        onClick={() => {
                          const clocking = getClockInToShift(shift as IShift, "06:00");
                          //const clocking = getClockInToShift(shift as IShift, shift.startTime!);
                          load(dispatch(timeClockingRepository.create(clocking)), "clockIn");
                        }}
                      />
                    )}
                    {!environment.isProd() && !this.isCreation && timeClocking && !tracking && (
                      <Button
                        style={{ marginRight: 10 }}
                        children={"Break " + ((timeClocking.breakActivities?.length || 0) % 2 ? "end" : "start")}
                        onClick={() =>
                          dispatch(
                            toggleTimeClockingBreak(
                              timeClocking!,
                              (timeClocking.breakActivities?.length || 0) % 2 ? "end" : "start"
                            )
                          )
                        }
                      />
                    )}
                    {!environment.isProd() && !this.isCreation && timeClocking && !tracking && (
                      <Button
                        style={{ marginRight: 10 }}
                        children="Clock Out"
                        onClick={() => dispatch(stopTimeClocking(timeClocking!))}
                      />
                    )}
                    {shift.deassignedUserId && !shift.userId && canManage && (
                      <div className="deassignedUserBox" data-rh={lg.freigegebene_schicht_von}>
                        <div className="iconWrapper">
                          <AvIcon type="icon-beach_access" />
                        </div>
                        <div className="label">{userFullMap[shift.deassignedUserId!].name}</div>
                      </div>
                    )}
                    {this.state.showRepeatingDeleteOption && canManage && (
                      <Button
                        type="default"
                        className="deleteRepeatingButton"
                        onClick={this.openDeleteRepeatingModal}
                        children={lg.wiederholend_löschen}
                      />
                    )}
                    {!environment.isProd() && isV2 && timeClocking && (
                      <Button
                        style={{ marginRight: 10 }}
                        children="NoAccept"
                        onClick={() => dispatch(timeClockingRepository.update({ ...timeClocking, isAccepted: false }))}
                      />
                    )}
                    {this.props.shift.id && (
                      <Tooltip placement="top" title={hasTracking ? deleteDisabledTooltipText : null}>
                        <Button
                          key={showConfirmDelete ? "confirmDelete" : "delete"}
                          id="shift-popup-delete-button"
                          data-rh={showConfirmDelete ? lg.wirklich_löschen : lg.löschen}
                          icon={showConfirmDelete ? "question" : "delete"}
                          type={showConfirmDelete ? "danger" : "dashed"}
                          onClick={async () => this.props.load(this.deleteClick(), "deleting")}
                          disabled={hasTracking || isTrackingEditMode || !!changeRequest}
                          loading={this.props.isLoading("deleting")}
                          style={{
                            padding: "0px 6px",
                            pointerEvents: this.state.resettingDeleteBtnPointer ? "none" : "auto",
                          }}
                        />
                      </Tooltip>
                    )}
                    {!this.isCreation && this.state.shiftRepeat && !dateWasChanged && canManage && (
                      <Button
                        id="shift-popup-save-edits-button-repeating"
                        className="shiftSaveEditsButtonRepeating"
                        type="default"
                        icon={isLoading("editRepeate") ? "loading" : undefined}
                        disabled={this.isSubmitDisabled()}
                        data-rh={lg.diese_schicht_und_alle_folgenden_wiederholungen}
                        children={lg.wiederholend_speichern}
                        onClick={this.saveRepeatinglyClicked}
                      />
                    )}
                    {!this.repeatEndDateHasChanged() && (
                      <Button
                        style={{ minWidth: 100 }}
                        id="shift-popup-save-button"
                        className="shiftSaveButton"
                        type="primary"
                        icon={isLoading("submitting") ? "loading" : this.isPunchingRequestV2() ? "check" : undefined}
                        disabled={this.isSubmitDisabled()}
                        children={
                          isLoading("submitting")
                            ? undefined
                            : this.isPunchingRequestV2()
                            ? lg.zeiterfassung_bestätigen
                            : lg.Speichern
                        }
                        onClick={() => load(this.submit(), "submitting")}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </TZModal.Body>
      </TZModal>
    );
  }
}

export const ShiftPopup = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(busyInjector(_ShiftPopup));
