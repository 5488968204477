import { createSelector } from "reselect";
import { RoleType } from "../shared/entities/IUser";
import { AppState } from "../types/AppState";
import { selectSessionInfo } from "./SessionInfoSelector";
import { selectShiftMap } from "./shiftMapSelector";

export const selectClockingRequestsV2 = createSelector(
  [(state: AppState) => state.data.timeClockings, selectSessionInfo],
  (clockings, sessionInfo) => {
    const role = sessionInfo.user.role;
    const currentUser = sessionInfo.user;

    return clockings
      .filter((c) => !c.isAccepted)
      .filter((c) => !!c.endTime || c.isAutoClockOut)
      .filter((c) => {
        return role === RoleType.admin
          ? true
          : role === RoleType.manager
          ? currentUser.branchIds.includes(c.branchId)
          : currentUser.id === c.userId;
      });
  }
);
