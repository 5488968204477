import React from "react";
import { Select } from "antd";
import "./styles.scss";
import { SelectProps } from "antd/lib/select";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { connect } from "react-redux";
import { selectActiveJobPositions } from "../../../../selectors/ActiveJobPositionsSelector";
import { selectUserMap } from "../../../../selectors/mapSelectors";
import _ from "lodash";
import { antDesignSelectFilterSearch } from "../../../../frontend-core/helpers/frontendHelpers";
import { BasicSelect } from "../../../BasicSelect/BasicSelect";
import { IJobPosition } from "../../../../shared/entities/IJobPosition";
import { RoleType } from "../../../../shared/entities/IUser";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";

const mapStateToProps = (state: AppState) => {
  return {
    jobPositions: state.data.jobPositions,
    userMap: selectUserMap(state),
    sessionUser: selectSessionInfo(state).user,
  };
};

type State = {};
type OwnProps = {
  onChange: (value: string) => void;
  value: string | undefined;
  userId?: string;
  selectedUserIds: string[]; // in case of userPickerMode
  isDisplayOnly?: boolean;
} & SelectProps<any>;

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _JobPositionSelect extends React.PureComponent<Props, {}> {
  render() {
    const { value, onChange, jobPositions, userMap, userId, selectedUserIds, sessionUser, ...otherProps } = this.props;
    const users = [...selectedUserIds, userId].filter((uid) => !!uid).map((uid) => userMap[uid as string]);

    const canSeeJobPos = (jobPos: IJobPosition): boolean =>
      sessionUser.role == RoleType.admin ||
      sessionUser.role === RoleType.manager ||
      sessionUser.jobPositionIds.includes(jobPos.id);

    const jobPositionsSorted = _.orderBy(jobPositions, (jp) =>
      jp.sortIndex === undefined ? jp.name.toLowerCase() : jp.sortIndex
    );

    // allow only jobPositions that belong to the userId-prop
    // if the userId-prop is undefined, allow all jobPositions
    const options = jobPositionsSorted.filter((jp) => {
      if (jp.id === value) {
        return true; // the selected one should always be included in the options
      }

      if (jp.isInactive) {
        return false;
      }

      if (users.length) {
        // if one ore more users are selected > only allow jobPositions that the users have
        return users.every((u) => u.jobPositionIds.includes(jp.id));
      }

      if (!users.length) {
        return canSeeJobPos(jp);
      }
    });

    const selectedJobPos = value && jobPositions.find((jp) => jp.id === value);

    return (
      <div className="avInputFieldMain jobPosSelectMainField">
        <span className="labelWrapper">
          <label>{lg.rolle}</label>
        </span>
        {this.props.isDisplayOnly ? (
          <div className="row fb aCenter jobPosRect ">
            <div className="colorRectJobPosSelect" style={{ background: selectedJobPos?.color }}></div>
            <div>{selectedJobPos?.name}</div>
          </div>
        ) : (
          <BasicSelect
            id="job-position-select"
            value={value}
            onChange={(value: string) => this.props.onChange(value)}
            style={{ width: "100%" }}
            allowClear={true}
            showSearch={jobPositions.length > 6}
            options={options.map((o) => ({
              value: o.id,
              label: o.name,
              content: (
                <div className="row fb aCenter">
                  <div className="colorRectJobPosSelect" style={{ background: o.color }} />
                  {o.name}
                </div>
              ),
            }))}
            {...otherProps}
          />
        )}
      </div>
    );
  }
}

export const JobPositionSelect = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(_JobPositionSelect);
