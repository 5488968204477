import React, { useEffect } from "react";
import cn from "classnames";
import "./styles.scss";
import { Badge, Icon } from "antd";
import { useSelector } from "../../../helpers/redux";
import { PushNoteTopic } from "../../../shared/entities/IPushNote";
import { selectUnseenPushNotes } from "../../../selectors/selectUnseenPushNotes";

type Props = {
  isIconActive: boolean;
};

export const TopBarPushNotesIcon = React.memo(({ isIconActive }: Props) => {
  const unseenPushNotes = useSelector(selectUnseenPushNotes).filter(
    (p) => p.topic !== PushNoteTopic.chat && p.topic !== PushNoteTopic.announcement
  );

  return (
    <div className="topbarIconWrapper">
      <div
        className={cn({ topbarIcon: true, fb: true, row: true, pushNotesIcon: true })}
        style={{ padding: 2, marginTop: 4 }}
      >
        <Badge count={unseenPushNotes.length}>
          <Icon type="bell" style={{ fontSize: 22 }} />
        </Badge>
      </div>
    </div>
  );
});
