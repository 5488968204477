import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import { RosterMode } from "../reducers/ui/shifts/roster/rosterMode";

export const selectRosterStartDate = createSelector(
  [
    (state: AppState) => state.ui.shifts.roster.rosterMode,
    (state: AppState) => state.ui.shifts.roster.selectedDay,
    (state: AppState) => state.ui.shifts.roster.selectedWeek,
    (state: AppState) => state.ui.shifts.roster.selectedMonth,
  ],
  (rosterMode, selectedDay, selectedWeek, selectedMonth) => {
    switch (rosterMode) {
      case RosterMode.Day:
        return selectedDay;
      case RosterMode.Week:
        return selectedWeek;
      case RosterMode.Month:
        return selectedMonth;
    }
  }
);
