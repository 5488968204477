import { Input, Checkbox, Icon, InputNumber, Button, Popover } from "antd";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../actions/modal";
import { paidFeatureWarning } from "../../../../../actions/paidFeatureWarning";
import { ReportType } from "../../../../../actions/reporting";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";
import { featuresSelector } from "../../../../../selectors/FeaturesSelector";
import { AppState } from "../../../../../types/AppState";
import { ReportMultiPdfExportModal } from "../ReportMultiPdfExportModal/ReportMultiPdfExportModal";
import "./styles.scss";

type Props = {
  createExport: (type: "pdf" | "csv" | "excel" | "multi-pdf", options?: { userIds: string[] }) => Promise<any>;
  hasManagerPermissions: boolean;
};

export const standardRestHours = 11;

export const ReportExportPopover = (props: Props) => {
  const features = useSelector(featuresSelector);
  const reportType = useSelector((s: AppState) => s.ui.shifts.reporting.reportType);
  const dispatch = useDispatch<DispFn>();
  const [isOpen, setOpen] = useState<boolean>(false);

  const openMultiPdfModal = () => {
    dispatch(openModal(ReportMultiPdfExportModal, { createExport: props.createExport }, { maskClosable: false }));
  };

  return (
    <Popover
      trigger="click"
      visible={isOpen}
      onVisibleChange={setOpen}
      placement="bottomRight"
      children={
        <Button
          id="reporting-export-dropdown"
          type="default"
          icon="download"
          style={{ marginLeft: 8 }}
          data-rh={isOpen ? null : "Export"}
        />
      }
      content={
        <div className="exportReportPopoverMain" onClick={() => setOpen(false)}>
          <div
            className="option"
            onClick={() => (features.dataExports ? props.createExport("csv") : dispatch(paidFeatureWarning()))}
            id="reporting-export-csv-button"
          >
            <Icon type="file-excel" />
            CSV {lg.export}
          </div>
          <div
            className="option"
            onClick={() => (features.dataExports ? props.createExport("excel") : dispatch(paidFeatureWarning()))}
            id="reporting-export-excel-button"
          >
            <Icon type="file-excel" />
            Excel {lg.export}
          </div>
          <div
            className="option"
            onClick={() => (features.dataExports ? props.createExport("pdf") : dispatch(paidFeatureWarning()))}
            id="reporting-export-pdf-button"
          >
            <Icon type="file-pdf" />
            PDF {lg.export}
          </div>
          {reportType === ReportType.shift && props.hasManagerPermissions && (
            <div
              className="option"
              onClick={() => (features.dataExports ? openMultiPdfModal() : dispatch(paidFeatureWarning()))}
              id="reporting-export-multi-pdf-button"
            >
              <Icon type="file-pdf" />
              Multi PDF {lg.export}
            </div>
          )}
        </div>
      }
    />
  );
};
