const ROSTER_WILL_RERENDER_AT = "ROSTER_WILL_RERENDER";

export const rosterWillRerender = () => ({
  type: ROSTER_WILL_RERENDER_AT,
  payload: new Date().getTime(),
});

const initialState = new Date().getTime();

// the value of this reducer is a unix-timetap that gets updated when needed
// so it triggers compoentWillUpdate.
// its not a boolean because we use it like an event, and not a state
export const rosterWillRerenderReducer = (state = initialState, a: any): string =>
  a.type === ROSTER_WILL_RERENDER_AT ? a.payload : state;
