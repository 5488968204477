import React from "react";
import { useSelector } from "react-redux";
import { selectIsFreshTenant } from "../../selectors/selectIsFreshTenant";
import { Popover } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import { AvIcon } from "../AvIcon/AvIcon";
import "./styles.scss";
import { RoleType } from "../../shared/entities/IUser";

type Props = {
  children?: any;
  hideHint?: boolean;
  hint: string;
  placement?: TooltipPlacement;
};

export const HintPopover = (props: Props) => {
  const isFreshTenant = useSelector(selectIsFreshTenant);

  if (!isFreshTenant) {
    return props.children;
  } else {
    return (
      <Popover
        trigger="hover"
        placement={props.placement || "left"}
        title={null}
        content={
          <div className="fb row hintPopoverContent">
            <div className="hintPopoverTitle">
              <AvIcon type="icon-Idea" />
              {/* <span>{lg.hinweis}</span> */}
            </div>
            <div className="hint">{props.hint}</div>
          </div>
        }
      >
        {props.children}
      </Popover>
    );
  }
};
