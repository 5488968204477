import { IUserFull, RoleType } from "./../shared/entities/IUser";
import { decryptUser } from "./../shared/helpers/userHelpers";
import { UserInfo } from "../shared/helpers/UserInfo";

import { AppState } from "../types/AppState";
import { createSelector } from "reselect";
import { IUser } from "../shared/entities/IUser";

export const selectAuthSession = (state: AppState) => state.data.auth.session;

export const selectSessionInfo = createSelector(
  [(state: AppState) => state.data.auth.session, (state: AppState) => state.data.users],
  (session, users): UserInfo => {
    const user = users.find((u) => u.id === session!.userId) as IUser;

    if (!user) {
      // just for HotReloading > in production it should not enter this
      return new UserInfo(({ id: "XXX", name: "XXX", role: RoleType.employee, branchIds: [] } as any) as IUser);
    }

    return new UserInfo(user);
  }
);
