import { selectRosterSettingsByUser } from "./../selectors/rosterSettingsByUserSelector";
import { selectUserMap } from "./../selectors/mapSelectors";
import { UserInfo } from "./../shared/helpers/UserInfo";
import { RoleType } from "./../shared/entities/IUser";
import { pushNoteRepository } from "./../repositories/pushNoteRepository";
import { AppState } from "../types/AppState";
import { DispFn } from "../frontend-core/types/thunkTypes";
import moment from "moment";
import { notification } from "antd";
import { selectActiveUsers } from "../selectors/ActiveUserSelectors";
import { SDateFormat } from "../shared/helpers/SimpleTime";
import { simpleDateToMoment } from "../shared/helpers/timeHelpers";
import { Raw } from "../shared/entities/IResource";
import { IShift } from "../shared/entities/IShift";
import { IAbsence } from "../shared/entities/IAbsence";
import { IPushNoteBase, IPushNote, PushNoteTopic, PushNoteType } from "../shared/entities/IPushNote";
import { IChangeRequest } from "../shared/entities/IChangeRequest";
import _ from "lodash";
import { getUserName } from "../shared/helpers/userHelpers";
import { IMessage } from "../shared/entities/IMessage";
import { IThreadInfo } from "../shared/entities/IThreadInfo";
import { selectSessionInfo } from "../selectors/SessionInfoSelector";
import { IAnnouncement } from "../shared/entities/IAnnouncement";

export const EmployeeWillGetNotifiedMsg = lg.mitarbeiter_wird_benachrichtigt;
export const EmployeesWillGetNotifiedMsg = lg.mitarbeiter_werden_benachrichtigt; // Plural

const simpleToDisplayDate = (date: string): string => {
  return simpleDateToMoment(date).format("dd DD. MMM");
};

const getDisplayDate = (shift: Raw<IShift>): string => {
  return simpleToDisplayDate(shift.date);
};

const getAbsenceDates = (absence: IAbsence) => {
  const startDate = simpleDateToMoment(absence.startDate).format("L");
  const endDate = simpleDateToMoment(absence.endDate).format("L");
  return `${startDate} - ${endDate}`;
};

type PushNoteOptions = {
  feedbackText?: string;
};

export class Pusher {
  static sendNotification =
    (userIds: string[], _pushNote: IPushNoteBase, options: PushNoteOptions = {}) =>
    async (dispatch: DispFn, getState: () => AppState) => {
      const sessionUserId = getState().data.auth.session!.userId;
      const isV2 = getState().data.tenantInfo.isV2;
      const today = moment().format(SDateFormat);
      const activeUsers = selectActiveUsers(getState(), today).filter((u) => !!u.accountId);
      const activeUserIds = activeUsers.map((u) => u.id);
      const usersToNotify = userIds.filter((uId) => uId !== sessionUserId).filter((uId) => activeUserIds.includes(uId));
      const topic = _pushNote.topic;

      if (!usersToNotify.length) {
        return;
      }

      if (
        isV2 &&
        (topic === PushNoteTopic.shift || topic === PushNoteTopic.roster || topic === PushNoteTopic.handover)
      ) {
        return;
      }

      const pushNotes: IPushNote[] = usersToNotify.map((uId) => ({
        ..._pushNote,
        id: pushNoteRepository.generateId(uId),
        timestamp: moment().unix(),
        creator: sessionUserId,
        hasSeen: false,
        userId: uId,
        needsToBeSent: true,
      }));

      dispatch(pushNoteRepository.createList(pushNotes));

      options.feedbackText && notification.info({ message: options.feedbackText, duration: 1.8 });
    };

  static shiftDateChanged =
    (prevDate: string, shift: IShift, options: PushNoteOptions = {}) =>
    async (dispatch: DispFn) => {
      const prevDateDisplay = simpleDateToMoment(prevDate).format("dd DD. MMM");

      dispatch(
        Pusher.sendNotification(
          [shift.userId!],
          {
            title: lg.schicht_wurde_verschoben,
            message: lg.deine_schicht_vom_prev_date_wurde_auf_den_next_date_verschoben(prevDate, getDisplayDate(shift)),
            topic: PushNoteTopic.roster,
            params: { date: shift.date, branchId: shift.branchId },
            type: PushNoteType.shiftDateChanged,
          },
          options
        )
      );
    };

  static shiftTimeChanged =
    (shift: IShift, options: PushNoteOptions = {}) =>
    async (dispatch: DispFn) => {
      const shiftTime = `${shift.startTime} - ${shift.endTime}`;
      dispatch(
        Pusher.sendNotification(
          [shift.userId!],
          {
            title: lg.schichtzeit_geändert,
            message: lg.deine_neue_schichtzeit_am_date_ist_time(getDisplayDate(shift), shiftTime),
            topic: PushNoteTopic.roster,
            params: { date: shift.date, branchId: shift.branchId },
            type: PushNoteType.shiftTimeChanged,
          },
          options
        )
      );
    };

  static shiftDeleted =
    (shift: IShift, options: PushNoteOptions = {}) =>
    async (dispatch: DispFn) => {
      dispatch(
        Pusher.sendNotification(
          [shift.userId!],
          {
            title: lg.schicht_gelöscht,
            message: lg.deine_schicht_am_date_wurde_entfernt(getDisplayDate(shift)),
            topic: PushNoteTopic.roster,
            params: { date: shift.date },
            type: PushNoteType.shiftDeleted,
          },
          options
        )
      );
    };

  static shiftAssigned =
    (shift: Raw<IShift>, userIds: string[], options: PushNoteOptions = {}) =>
    async (dispatch: DispFn) => {
      dispatch(
        Pusher.sendNotification(
          userIds,
          {
            title: lg.du_wurdest_einer_schicht_zugewiesen,
            message: lg.die_schicht_ist_am_date_von_start_bis_end(
              getDisplayDate(shift),
              shift.startTime,
              shift.endTime
            ),
            topic: PushNoteTopic.roster,
            params: { date: shift.date, branchId: shift.branchId },
            type: PushNoteType.shiftAssigned,
          },
          options
        )
      );
    };

  static openShiftAvailable =
    (shift: Raw<IShift>, options: PushNoteOptions = {}) =>
    async (dispatch: DispFn, getState: () => AppState) => {
      const state = getState();
      const rosterSettingsByUser = selectRosterSettingsByUser(state);
      if (shift.requiredUsersAmount === 0) {
        return;
      }

      const filteredUserIds = selectActiveUsers(state, shift.date)
        .filter((u) => u.jobPositionIds.includes(shift.jobPositionId))
        .filter((u) => rosterSettingsByUser[u.id].usersCanApplyToOpenShifts)
        .filter((u) => u.branchIds.includes(shift.branchId))
        .map((u) => u.id);

      dispatch(
        Pusher.sendNotification(
          filteredUserIds,
          {
            title: lg.eine_offene_schicht_wurde_erstellt,
            message: lg.bei_interesse_kannst_du_dich_auf_die_offene_schicht_am_date_von_start_bis_end_jetzt_bewerben(
              getDisplayDate(shift),
              shift.startTime,
              shift.endTime
            ),
            topic: PushNoteTopic.roster,
            params: { date: shift.date, branchId: shift.branchId },
            type: PushNoteType.openShiftAvailable,
          },
          options
        )
      );
    };

  static ShiftApplicationAccepted =
    (shift: IShift, options: PushNoteOptions = {}) =>
    async (dispatch: DispFn) => {
      dispatch(
        Pusher.sendNotification(
          [shift.userId!],
          {
            title: lg.bewerbung_auf_schicht_angenommen,
            message: lg.die_schicht_am_date_wurde_dir_zugewiesen(getDisplayDate(shift)),
            topic: PushNoteTopic.roster,
            params: { date: shift.date },
            type: PushNoteType.shiftApplicationResolved,
          },
          options
        )
      );
    };

  static handOverRequest = (shift: IShift, toUserId?: string) => async (dispatch: DispFn, getState: () => AppState) => {
    const isDirectHandOver = !!toUserId;
    const shiftTime = `${shift.startTime} - ${shift.endTime}`;

    const usersWithSameJobPosition = getState()
      .data.users.filter((u) => u.jobPositionIds.includes(shift.jobPositionId) && u.branchIds.includes(shift.branchId))
      .map((u) => u.id);

    const targetUserIds = isDirectHandOver ? [toUserId!] : usersWithSameJobPosition;
    const user = selectUserMap(getState())[shift.userId!];
    const userName = getUserName(user);

    const shiftDateDisplay = getDisplayDate(shift);

    const message = isDirectHandOver
      ? lg.user_möchte_die_schicht_am_date_time_an_dich_abgeben(userName, shiftDateDisplay, shiftTime)
      : lg.user_möchte_die_schicht_am_date_time_abgeben(userName, shiftDateDisplay, shiftTime);

    dispatch(
      Pusher.sendNotification(targetUserIds, {
        title: lg.schichtabgabe,
        message,
        topic: PushNoteTopic.handover,
        params: { date: shift.date, branchId: shift.branchId },
        type: isDirectHandOver ? PushNoteType.handOverProposed : PushNoteType.handOverAvailable,
      })
    );
  };

  static handOverAccepted =
    (shift: IShift, fromUser: string, toUser: string) => async (dispatch: DispFn, getState: () => AppState) => {
      const toUserName = getUserName(selectUserMap(getState())[toUser]);
      const shiftDateDisplay = getDisplayDate(shift);
      const fromUserMessage = lg.deine_schicht_am_date_wurde_an_user_abgegeben(shiftDateDisplay, toUserName);
      const toUserMessage = lg.die_schicht_von_user_am_date_wurde_dir_zugewiesen(shiftDateDisplay, fromUser);

      dispatch(
        Pusher.sendNotification([fromUser], {
          title: lg.schichtabgabe_angenommen,
          message: fromUserMessage,
          topic: PushNoteTopic.handover,
          params: { date: shift.date, branchId: shift.branchId },
          type: PushNoteType.handOverResolved,
        })
      );

      dispatch(
        Pusher.sendNotification([toUser], {
          title: lg.schichtabgabe_angenommen,
          message: toUserMessage,
          topic: PushNoteTopic.handover,
          params: { date: shift.date, branchId: shift.branchId },
          type: PushNoteType.handOverResolved,
        })
      );
    };

  static handOverRejected =
    (shift: IShift, fromUser: string, rejectedByUser: string) => async (dispatch: DispFn, getState: () => AppState) => {
      const userName = getUserName(selectUserMap(getState())[rejectedByUser]);
      const shiftDateDisplay = getDisplayDate(shift);
      const message = lg.date_user_die_abgabe_deiner_schicht_am_date_wurde_von_user_abgelehnt(
        shiftDateDisplay,
        userName
      );

      dispatch(
        Pusher.sendNotification([fromUser], {
          title: lg.schichtabgabe_abgehlehnt,
          message,
          topic: PushNoteTopic.handover,
          params: { date: shift.date, branchId: shift.branchId },
          type: PushNoteType.handOverResolved,
        })
      );
    };

  static handOverManagerCheck =
    (shift: IShift, fromUser: string, toUser: string) => async (dispatch: DispFn, getState: () => AppState) => {
      const { users } = getState().data;

      const fromUserName = getUserName(selectUserMap(getState())[fromUser]);
      const toUserName = getUserName(selectUserMap(getState())[toUser]);

      const shiftDate = getDisplayDate(shift);

      const managersInCharge = users
        .filter(
          (user) =>
            user.branchIds.includes(shift.branchId) && (user.role === RoleType.manager || user.role === RoleType.admin)
        )
        .map((user) => user.id);

      const message = lg.from_user_möchte_die_schicht_am_date_an_to_user_abgeben(fromUserName, shiftDate, toUserName);

      dispatch(
        Pusher.sendNotification(managersInCharge, {
          title: lg.schichtabgabe_beantragt,
          message,
          topic: PushNoteTopic.handover,
          params: { date: shift.date, branchId: shift.branchId },
          type: PushNoteType.pendingHandOverRequest,
        })
      );
    };

  static absenceRequestAccepted = (absence: IAbsence) => async (dispatch: DispFn) => {
    dispatch(
      Pusher.sendNotification([absence.userId!], {
        title: lg.abwesenheitsantrag_wurde_angenommen,
        message: lg.abwesenheitszeitraum_dates(getAbsenceDates(absence)),
        topic: PushNoteTopic.absence,
        params: { date: absence.startDate },
        type: PushNoteType.absenceRequestResolved,
      })
    );
  };

  static absenceRequestCreated = (absence: IAbsence) => async (dispatch: DispFn, getState: () => AppState) => {
    const { users, auth, absenceTypes } = getState().data;
    const requestingUser = users.find((u) => u.id === auth.session?.userId)!;
    const managersInCharge = users
      .filter(
        (user) =>
          (user.role === RoleType.manager || user.role === RoleType.admin) &&
          _.intersection(user.branchIds, requestingUser.branchIds).length
      )
      .map((user) => user.id);

    const absenceType = absenceTypes.find((aT) => aT.id === absence.typeId);

    dispatch(
      Pusher.sendNotification(managersInCharge, {
        title: lg.abwesenheitsanfrage_wurde_erstellt,
        message: `${getUserName(requestingUser)} | ${absenceType?.name} | ${getAbsenceDates(absence)}`,
        topic: PushNoteTopic.absence,
        params: { date: absence.startDate },
        type: PushNoteType.absenceRequestCreated,
      })
    );
  };

  static absenceRequestRejected = (absence: IAbsence) => async (dispatch: DispFn) => {
    dispatch(
      Pusher.sendNotification([absence.userId!], {
        title: lg.abwesenheitsantrag_wurde_abgelehnt,
        message: `${lg.abwesenheitszeitraum}: ${getAbsenceDates(absence)}`,
        topic: PushNoteTopic.absence,
        params: { date: absence.startDate },
        type: PushNoteType.absenceRequestResolved,
      })
    );
  };

  static weekPlanPublished =
    (userIds: string[], startOfWeek: string, isInitial: boolean, branch: string) =>
    async (dispatch: DispFn, getState: () => AppState) => {
      const hasMultipleBranches = !!(getState().data.branches.length > 1);
      const isoWeek = simpleDateToMoment(startOfWeek).isoWeek();

      dispatch(
        Pusher.sendNotification(userIds, {
          title: isInitial ? lg.neuer_wochenplan_veröffentlicht : lg.änderungen_im_wochenplan,
          message: lg.sieh_dir_den_wochenplan_der_kw_week_an_branch(isoWeek, hasMultipleBranches ? branch : ""),
          topic: PushNoteTopic.roster,
          params: { date: startOfWeek },
          type: PushNoteType.weekPlanPublished,
        })
      );
    };

  static changeRequestAccepted = (changeRequest: IChangeRequest) => async (dispatch: DispFn) => {
    const shiftDate = simpleToDisplayDate(changeRequest.date);
    dispatch(
      Pusher.sendNotification([changeRequest.userId!], {
        title: lg.änderungsantrag,
        message: lg.dein_änderungsantrag_für_die_schicht_am_date_wurde_akzeptiert(shiftDate),
        topic: PushNoteTopic.shift,
        params: { date: changeRequest.date },
        type: PushNoteType.changeRequestResolved,
      })
    );
  };

  static changeRequestRejected = (changeRequest: IChangeRequest) => async (dispatch: DispFn) => {
    const shiftDate = simpleToDisplayDate(changeRequest.date);
    dispatch(
      Pusher.sendNotification([changeRequest.userId!], {
        title: lg.änderungsantrag,
        message: lg.dein_änderungsantrag_für_die_schicht_am_date_wurde_abgelehnt(shiftDate),
        topic: PushNoteTopic.shift,
        params: { date: changeRequest.date },
        type: PushNoteType.changeRequestResolved,
      })
    );
  };

  static changeRequestCreated = (shift: IShift) => async (dispatch: DispFn, getState: () => AppState) => {
    const managingUsers = getState()
      .data.users.filter((user) => new UserInfo(user).hasManagerPermissions())
      .filter((user) => user.branchIds.includes(shift.branchId))
      .map((user) => user.id);

    const shiftDate = getDisplayDate(shift);
    const requestingUser = getState().data.users.find((u) => u.id === shift.userId)!;
    const requestingUserName = getUserName(requestingUser);
    dispatch(
      Pusher.sendNotification(managingUsers, {
        title: lg.änderungsantrag,
        message: lg.user_beantragt_eine_änderung_für_die_schicht_am_date(requestingUserName, shiftDate),
        topic: PushNoteTopic.shift,
        params: { date: shift.date },
        type: PushNoteType.changeRequested,
      })
    );
  };

  static announcementCreated =
    (announcement: IAnnouncement, userIds?: string[]) => async (dispatch: DispFn, getState: () => AppState) => {
      let title = lg.neue_ansage;
      dispatch(
        Pusher.sendNotification(userIds || _.keys(announcement.readByUserId), {
          title,
          message: `${announcement.title}`,
          topic: PushNoteTopic.announcement,
          params: { announcementId: announcement.id },
          type: PushNoteType.announcementCreated,
        })
      );
    };

  static chatMessage = (message: IMessage, threadId: string) => async (dispatch: DispFn, getState: () => AppState) => {
    const state = getState();
    const sessionInfo = selectSessionInfo(state);
    const threadInfo = state.data.threadInfos.find((t) => t.id === threadId) as IThreadInfo;

    if (!threadInfo) {
      return;
    }

    dispatch(
      Pusher.sendNotification(threadInfo.userIds, {
        title:
          threadInfo.userIds.length <= 2 ? sessionInfo.user.name : `${sessionInfo.user.name} @ ${threadInfo.title}`,
        message: message.text || "",
        topic: PushNoteTopic.chat,
        params: { threadId: threadInfo.id },
        type: PushNoteType.chatMessage,
      })
    );
  };
}
