import React from "react";
import { connect } from "react-redux";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { AppState } from "../../../types/AppState";
import "./styles.scss";
import { EmptyContent } from "../../../components/EmptyContent/EmptyContent";
import BusyWrapper from "../../../components/BusyWrapper/BusyWrapper";
import { HandOverRequestListItem } from "../../../components/HandOverRequestListItem/HandOverRequestListItem";
import { Icon } from "antd";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import _ from "lodash";

import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import { selectVisibleHandoverRequestItems } from "../../../selectors/HandOverRequestSelector";
import { HintPopover } from "../../../components/HintPopover/HintPopover";
import { selectShiftMap } from "../../../selectors/shiftMapSelector";
import { selectIsFreshTenant } from "../../../selectors/selectIsFreshTenant";
import cn from "classnames";
import { CountToggler } from "../CountToggler/CountToggler";

const mapStateToProps = (state: AppState) => {
  return {
    handOverRequests: selectVisibleHandoverRequestItems(state),
    sessionInfo: selectSessionInfo(state),
    shiftMap: selectShiftMap(state),
    users: state.data.users,
    isFreshTenant: selectIsFreshTenant(state),
  };
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;
type State = { isOpen: boolean };

class _HandOverRequestPaper extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  render() {
    const { handOverRequests, shiftMap, sessionInfo, isFreshTenant } = this.props;
    const { isOpen } = this.state;
    const canManage = sessionInfo.hasManagerPermissions();
    const sortedHandOverRequests = _.sortBy(handOverRequests, [(o) => o.approvedByUserId]);

    if (!isFreshTenant && !handOverRequests.length) {
      return null;
    }

    return (
      <div className="cell">
        <div className="content">
          <HintPopover
            hideHint={!sessionInfo.hasManagerPermissions()}
            placement="left"
            hint={
              lg.deine_mitarbeiter_können_untereinander_schichten_tauschen_hier_kannst_du_die_anfragen_annehmen_oder_ablehnen
            }
          >
            <div className="dashboardPaper">
              <BusyWrapper isBusy={this.props.isLoading()} style={{ minHeight: "auto", width: "100%" }}>
                <div
                  className={cn({ isOpen }) + " title expandable"}
                  onClick={() => this.setState({ isOpen: !isOpen })}
                >
                  <Icon type="team" style={{ marginRight: "12px", color: "#1a90ff" }} />
                  {lg.schichtabgabe}
                  <CountToggler
                    count={handOverRequests.length}
                    isOpen={this.state.isOpen}
                    style={{ marginLeft: "auto" }}
                  />
                </div>
                {isOpen && (
                  <div className="body">
                    <EmptyContent show={!handOverRequests.length} text={lg.keine_beantragten_schichtabgaben} />
                    {sortedHandOverRequests.map((request) => (
                      <HandOverRequestListItem
                        key={request.id}
                        handOverRequest={request}
                        shift={shiftMap[request.id]}
                      />
                    ))}
                  </div>
                )}
              </BusyWrapper>
            </div>
          </HintPopover>
        </div>
      </div>
    );
  }
}

export const HandOverRequestPaper = withErrorBoundary(connect(mapStateToProps)(busyInjector(_HandOverRequestPaper)));
