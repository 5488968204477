import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import TZModal from "../../../components/TZModal/TZModal";
import cn from "classnames";
import "./styles.scss";
import UserGeneralTab from "./UserGeneralTab/UserGeneralTab";
import { getDeactivationLabel } from "../UsersPage";
import { IUser, IUserFull } from "../../../shared/entities/IUser";
import * as Sentry from "@sentry/browser";
import { UserContractsTab } from "./UserContractsTab/UserContractsTab";
import { decryptUser } from "../../../shared/helpers/userHelpers";
import { selectUsersFull } from "../../../selectors/usersFullSelector";
import { MasterDataTab } from "./MasterDataTab/MasterDataTab";
import { UserTab, UserTabBar } from "./UserTabBar/UserTabBar";
import { Modal, notification } from "antd";
import { UserDocumentsTab } from "./DocumentsTab/UserDocumentsTab";

const mapStateToProps = (state: AppState, props: OwnProps) => {
  return {
    users: selectUsersFull(state),
  };
};

type OwnProps = {
  userId?: string;
  branchId?: string;
  selectedTab?: UserTab;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;
type State = {
  selectedTab: UserTab;
  userId?: string;
  isFormDirty?: boolean;
  closeOnSave: boolean;
};

class UserDetailsModal extends React.PureComponent<Props, State> {
  isCreationMode = !this.props.userId;
  state = {
    selectedTab: this.props.selectedTab || UserTab.general,
    userId: this.props.userId,
    isFormDirty: false,
    closeOnSave: true,
  };

  tabItemClicked = (tab: UserTab) => {
    this.setState({ selectedTab: tab });
  };

  componentDidMount = () => {
    const { users } = this.props;
    const { userId } = this.state;
    const user = userId && users.find((u) => u.id === userId);

    console.log(userId); // dont remove these logs > useful to debugg in production
    console.log(user); // dont remove these logs > useful to debugg in production

    Sentry.addBreadcrumb({
      message: "User Modal opened",
      data: { user, creationMode: !user },
    });
  };

  changeTab = (tab: UserTab) => {
    if (this.state.isFormDirty) {
      Modal.warning({
        title: lg.Speichern,
        content: lg.um_die_änderungen_nicht_zu_verwerfen_sollten_sie_diese_zunächst_speichern,
      });
    }

    this.setState({
      selectedTab: this.state.isFormDirty ? this.state.selectedTab : tab,
      isFormDirty: false,
      closeOnSave: !this.state.isFormDirty, // if the user just wanted to change the tab with unsaved chagnes, we dont close the modal on save
    });
  };

  formgotDirty = (dirty = true) => {
    this.setState({ isFormDirty: dirty });
  };

  render() {
    const { selectedTab } = this.state;
    const user = this.props.users.find((u) => u.id === this.state.userId)!;
    const lastWorkDay = user?.lastWorkDay;
    const isCreation = !this.props.userId;

    return (
      <TZModal>
        <TZModal.Head title={user ? user.name : lg.neuer_mitarbeiter}></TZModal.Head>
        {lastWorkDay && <div className="row userInactivationBox">{getDeactivationLabel(lastWorkDay)}</div>}
        {!isCreation && <UserTabBar changeTab={this.changeTab} selectedTab={this.state.selectedTab} />}
        <div className="userDetailsModalMain">
          <div className="content">
            {selectedTab === UserTab.general && (
              <UserGeneralTab
                user={user}
                branchId={this.props.branchId}
                gotDirty={this.formgotDirty}
                isCreationMode={this.isCreationMode}
                closeOnSave={this.state.closeOnSave}
              />
            )}
            {selectedTab === UserTab.workingTimes && (
              <UserContractsTab
                gotDirty={this.formgotDirty}
                user={user as IUser}
                isCreationMode={this.isCreationMode}
                closeOnSave={this.state.closeOnSave}
              />
            )}
            {selectedTab === UserTab.userInfo && (
              <MasterDataTab
                gotDirty={this.formgotDirty}
                userId={this.props.userId!}
                isCreationMode={this.isCreationMode}
                user={user}
                isFormDirty={this.state.isFormDirty}
                closeOnSave={this.state.closeOnSave}
              />
            )}
            {selectedTab === UserTab.documents && (
              <UserDocumentsTab
                gotDirty={this.formgotDirty}
                userId={this.props.userId!}
                isCreationMode={this.isCreationMode}
              />
            )}
          </div>
        </div>
      </TZModal>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(UserDetailsModal);
