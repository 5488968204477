import { environment } from "./../env";
import { firebaseListenerRepo } from "./firebaseListenerRepo";
import { AppState } from "../types/AppState";
import { DispFn } from "../frontend-core/types/thunkTypes";
import firebase from "firebase/compat/app";
import { Reducer } from "redux";
import { getSimpleReducer } from "../reducers/helpers/reducerHelpers";
import { LoopProtector } from "../frontend-core/LoopProtector";
import { IMaintenanceState } from "../shared/entities/IMaintenanceState";

export const CLEAR_DATA_ACTION_TYPE = "@@AV/CLEAR_DATA";

class MaintenanceRepo {
  getAction = (maintenance: IMaintenanceState) => ({
    type: "SET_MAINTAINANCE",
    payload: maintenance,
  });

  addListener = () => (dispatch: DispFn, getState: () => AppState) => {
    const ref = firebase.database().ref("maintenance");
    const tenatnId = getState().data.auth.session?.tenantId || "";

    ref.on("value", (snap) => {
      const next = (snap.val() || {}) as IMaintenanceState;
      const prev = getState().data.maintenance;

      const maintenanteModeStarted = !prev.maintenanceMode && next.maintenanceMode;
      const tenantDisconnected = !prev.disconnectedTenants?.[tenatnId] && next.disconnectedTenants?.[tenatnId];

      dispatch(this.getAction(snap.val()));

      if (maintenanteModeStarted || tenantDisconnected) {
        dispatch({ type: CLEAR_DATA_ACTION_TYPE });
        dispatch(firebaseListenerRepo.removeAll());
      }

      // There is no add-add-all Listeners method to activate the listeners again.
      // When the Maintainence Mode is over, the App mounts again, The <Preloader> adds the Fb-Listeners on Mount
    });
  };

  getReducer(): Reducer<IMaintenanceState> {
    const initialState = {
      maintenanceMode: false,
      webVersion: "",
      mobileVersion: environment.getEnv().APP_VERSION,
    };
    return getSimpleReducer("SET_MAINTAINANCE", initialState) as any;
  }
}

export const maintenanceRepo = new MaintenanceRepo();
