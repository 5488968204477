import { IWorkSpace } from './../shared/entities/IWorkSpace';
import { BaseRepository } from '../frontend-core/BaseRepository';


class WorkSpaceRepository extends BaseRepository<IWorkSpace> {
  constructor() {
    super('workSpaces');
  }
}

export const workSpaceRepository = new WorkSpaceRepository();
