import React, { useEffect, useRef } from "react";
import { useSelector } from "../../../../helpers/redux";
import "./styles.scss";
import _ from "lodash";
import { DayGridRow } from "./DayGridRow";
import { RosterJobPositionLine } from "../components/RosterJobPositionLine/RosterJobPositionLine";
import { relevantShiftsOfBranchSelector } from "../../../../selectors/RelevantShiftsSelector";
import cn from "classnames";
import { rosterRowDataSelector } from "../../../../selectors/rosterRowDataSelector";
import { VariableSizeList as List, ListChildComponentProps, areEqual } from "react-window";
import { SimpleList } from "../../../../components/SimpleList/SimpleList";
import memoizeOne from "memoize-one";
import { getPrevSimpleDate } from "../../../../shared/helpers/dateHelpers";
import moment from "moment";
import { toDoubleDigit, toSimpleDate, toSimpleTime, tTimetoMinutes } from "../../../../shared/helpers/timeHelpers";
import { selectGroupByJobPosition } from "../../../../selectors/groupByJobPositionSelector";

type Props = {
  scrollListener: ({ scrollOffset }) => void;
  hoursInDay: number[];
  getTime: (time: string, format: string) => number;
  selectedDay: string;
  currentBranchId: string | null;
  nowSimpleTime: string;
};

const createData = memoizeOne((rows) => ({
  rows,
}));

const _RosterDayGridBody = (props: Props) => {
  const groupByJobPosition = useSelector(selectGroupByJobPosition);
  const prevDay = getPrevSimpleDate(props.selectedDay);
  const shifts = useSelector(relevantShiftsOfBranchSelector);
  const shiftsOfDay = shifts.filter((s) => s.date === props.selectedDay);
  const nightShiftsOfPrevDay = shifts.filter((s) => s.date === prevDay && s.startTime > s.endTime);
  const rows = useSelector(rosterRowDataSelector);
  const isV2 = useSelector((s) => s.data.tenantInfo.isV2);
  const isToday = props.selectedDay === toSimpleDate(moment());

  const lastVisibleHour = props.hoursInDay[props.hoursInDay.length - 1];
  const visibleStartTime = toDoubleDigit(props.hoursInDay[0]) + "00";
  const visibleEndTime = toDoubleDigit(lastVisibleHour) + "00";
  const visibleStartTimeMins = tTimetoMinutes(visibleStartTime);
  const visibleEndTimeMins = tTimetoMinutes(visibleEndTime);
  const nowSimpleTime = toSimpleTime(moment());
  const showNowLine = isToday && nowSimpleTime >= visibleStartTime && nowSimpleTime <= visibleEndTime;
  const nowMins = tTimetoMinutes(nowSimpleTime);
  const nowLinePos = ((nowMins - visibleStartTimeMins) / (visibleEndTimeMins - visibleStartTimeMins + 60)) * 100;

  // ATTENRION! THE DAY GRID RE_RENDERS ALL ROWS ON ANY MINOR --- IF YOU WANT TO AVOID THIS
  // YOU NEED TO USE THE memoizeOne method which I did in the week grid
  // currently I did not do it in the daily roster, because it only has a few cells
  const renderRow = ({
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
  }: ListChildComponentProps) => {
    const { jobPositionId, user, isJobPositionHeader, isJobPositionHeaderOpen, isRequirement } = rows[index];
    if (isJobPositionHeader) {
      return <RosterJobPositionLine style={style} jobPositionId={jobPositionId!} isOpen={isJobPositionHeaderOpen!} />;
    }

    const shiftsOfDayOfRow = [...shiftsOfDay, ...nightShiftsOfPrevDay].filter((s) =>
      user ? s.userId === user!.id : !s.userId && !!isRequirement == !!s.isRequirement
    );

    return (
      <DayGridRow
        style={style}
        groupByJobPosition={groupByJobPosition}
        userId={user?.id}
        hoursInDay={props.hoursInDay}
        date={props.selectedDay}
        getTime={props.getTime}
        jobPositionId={jobPositionId}
        isRequirement={isRequirement}
        shiftsOfDay={shiftsOfDayOfRow}
        nowSimpleTime={props.nowSimpleTime}
      />
    );
  };

  const data = createData(rows);

  // We decided not to virtualize the day grid, as there are not so many rows
  // and because the night shifts mess up the height calculation :)
  return (
    <div className="fb column" style={{ flex: "0 1 auto", overflowY: "hidden" }}>
      <SimpleList
        className={cn({ userGridContent: true, isGrouped: groupByJobPosition })}
        onScroll={props.scrollListener}
        itemData={data}
        generateRow={renderRow}
        itemKey={(index, data) =>
          `${data.rows[index].jobPositionId}` + `${data.rows[index].user?.id}` + `${data.rows[index].isRequirement}`
        }
      />
      {/* <AutoSizer>
        {({ height, width }) => (
          <List
            className={cn({ userGridContent: true, isGrouped: groupByJobPosition })}
            ref={RVListRef}
            onScroll={props.scrollListener}
            height={height}
            itemData={rows}
            itemCount={rows.length}
            itemSize={(index: number) => rows[index].height}
            itemKey={(index) => "" + rows[index].jobPositionId + rows[index].user?.id}
            width={width}
            children={renderRow}
          />
        )}
      </AutoSizer> */}
      {isV2 && showNowLine && (
        <div className="nowLineBox">
          {/* <div className="spacing"></div> */}

          <div className="nowLine" style={{ left: nowLinePos + "%" }}>
            <div className="wedge"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export const RosterDayGridBody = React.memo(_RosterDayGridBody);
