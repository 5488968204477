import { dayRosterTimeSpanTypes } from "../../../../actions/dayRosterTimeSpan";

type dayRosterTimeSpanState = {
  dayStart: number;
  dayEnd: number;
};

const initialState = {
  dayStart: 6,
  dayEnd: 22,
};

export const dayRosterTimeSpanReducer = (
  state: dayRosterTimeSpanState = initialState,
  action: any
): dayRosterTimeSpanState => {
  switch (action.type) {
    case dayRosterTimeSpanTypes.SELECT:
      return action.payload;
    default:
      return state;
  }
};
