import React from "react";
import { Badge, Button, Checkbox, Switch, notification } from "antd";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { AvStepper } from "../../../AvStepper/AvStepper";
import { closeModal, openModal } from "../../../../actions/modal";
import { ShiftApplicationsPopup } from "../../ShiftApplicationsPopup/ShiftApplicationsPopup";
import "./styles.scss";
import { IShift } from "../../../../shared/entities/IShift";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { busyInjector, BusyInjectorProps } from "../../../BusyInjector/BusyInjector";
import { shiftRepository } from "../../../../repositories/shiftRepository";

const mapStateToProps = (state: AppState) => ({
  sessionUser: selectSessionInfo(state).user,
});

type OwnProps = {
  shift: IShift;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class _OpenShiftApplySection extends React.PureComponent<Props> {
  openApplicantsPopup = () => {};

  render() {
    const { shift, sessionUser, isLoading, dispatch, load } = this.props;

    return (
      <div className="fb row openShiftApplySectionMain" style={{ marginTop: "20px" }}>
        <div className="requiredAmountLabel">{lg.anzahl}</div>
        <div className="requiredAmountNumber">{shift.requiredUsersAmount}</div>
        <div className="applicantsBox">
          <div className="label">{lg.bewerber}</div>
          <div className="number">{shift.appliedUserIds?.length || 0}</div>
        </div>
        {shift.appliedUserIds?.includes(sessionUser.id) ? (
          <div className="alreadyAppliedBox">
            <div className="youDidApplie">{lg.du_bewirbst_dich_für_diese_schicht}</div>
            <Button
              className="undoApplicationButton"
              type="default"
              id="revert-shift-application-button"
              data-rh={lg.bewerbung_zurückziehen}
              icon="cross"
              loading={isLoading()}
              onClick={async () => {
                await load(dispatch(shiftRepository.removeApplication(shift, sessionUser.id)));
                notification.success({ message: lg.bewerbung_zurückgezogen });
                dispatch(closeModal());
              }}
            />
          </div>
        ) : (
          <Button
            className="applyButton"
            type="primary"
            id="apply-to-open-shift-button"
            children={lg.auf_diese_schicht_bewerben}
            loading={isLoading()}
            onClick={async () => {
              await load(dispatch(shiftRepository.addApplication(shift, sessionUser.id)));
              notification.success({ message: lg.bewerbung_übernommen });
              dispatch(closeModal());
            }}
          />
        )}
      </div>
    );
  }
}

export const OpenShiftApplySection = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(
  busyInjector(_OpenShiftApplySection)
);
