import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../types/AppState";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { Popover, Button, Badge } from "antd";
import { openModal } from "../../../../../actions/modal";

import "./styles.scss";
import { TrackingRequestItem } from "../../../../../components/TrackingRequestItem/TrackingRequestItem";
import { withErrorBoundary } from "../../../../../components/ErrorBoundary/ErrorBoundary";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { busyInjector, BusyInjectorProps } from "../../../../../components/BusyInjector/BusyInjector";
import _ from "lodash";
import { selectTrackingRequests } from "../../../../../selectors/TrackingRequestSelector";
import { ITracking } from "../../../../../shared/entities/ITracking";
import { ShiftPopup } from "../../../../../components/ShiftPopup/ShiftPopup/ShiftPopup";
import { selectShiftMap } from "../../../../../selectors/shiftMapSelector";

const mapStateToProps = (state: AppState) => {
  return {
    trackingRequests: selectTrackingRequests(state),
    shiftMap: selectShiftMap(state),
    users: state.data.users,
    sessionInfo: selectSessionInfo(state),
    selectedBranchId: state.ui.selectedBranch,
  };
};

type State = {
  popoverIsVisible: boolean;
};
type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class UnapprovedTrackingsButton_ extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      popoverIsVisible: false,
    };
  }

  openModal = async (tracking: ITracking) => {
    this.setState({ popoverIsVisible: false });
    const shift = this.props.shiftMap[tracking.id];
    this.props.dispatch(openModal(ShiftPopup, { shift }));
  };

  render() {
    const { shiftMap, trackingRequests, selectedBranchId } = this.props;

    const trackingsInBranch = trackingRequests.filter((t) => !selectedBranchId || t.branchId === selectedBranchId);

    return (
      <>
        <Popover
          trigger={"click"}
          placement="bottomRight"
          visible={!!trackingsInBranch.length && this.state.popoverIsVisible}
          onVisibleChange={(v) => this.setState({ popoverIsVisible: v })}
          content={
            <div className="unapprovedTrackingsPopover fb column">
              <div className="head">{lg.zeiterfassungen}</div>
              <div className="body">
                {_.sortBy(trackingsInBranch, "date")
                  .reverse()
                  .map((tracking) => {
                    const shift = shiftMap[tracking.id];
                    return (
                      <TrackingRequestItem
                        tracking={tracking}
                        shift={shift}
                        key={tracking.id}
                        openModal={() => this.openModal(tracking)}
                      />
                    );
                  })}
              </div>
              <div className="footer"></div>
            </div>
          }
        >
          <Badge count={trackingsInBranch.length}>
            <Button
              id="unapproved-trackings-button"
              icon="history"
              style={{ marginLeft: 12 }}
              disabled={!trackingsInBranch.length}
              data-rh={lg.unbestätigte_zeiterfassungen}
            />
          </Badge>
        </Popover>
      </>
    );
  }
}

export const UnapprovedTrackingsButton = withErrorBoundary(
  connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(busyInjector(UnapprovedTrackingsButton_))
);
