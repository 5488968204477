import { selectUsersFull } from "./../selectors/usersFullSelector";
import { getDouplicates } from "./../helpers/general";
import { nullifyProps } from "./../shared/helpers/firebaseHelpers";
import { environment } from "./../env";
import { sendPost } from "./../frontend-core/actions/send";
import { DispFn } from "./../frontend-core/types/thunkTypes";
import { AppState } from "./../types/AppState";
import moment from "moment";
import { userRepository } from "../repositories/userRepository";
import { IUser, IUserFull } from "../shared/entities/IUser";
import { IContractCore, IContract } from "../shared/entities/IContract";
import * as Sentry from "@sentry/browser";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import { addSimpleDays } from "../shared/helpers/dateHelpers";
import { shiftRepository } from "../repositories/shiftRepository";
import { delteAllShiftRelations } from "./shift";
import { shiftRepeatRepository } from "../repositories/shiftRepeatRepository";

export const getNextUserId = () => (dispatch: DispFn, getState: () => AppState) => {
  const users = getState().data.users;
  const userNrs = users.map((u) => parseInt(u.id.split("u")[1])); //  does this: 'u32' -> 32
  return `u${_.max(userNrs)! + 1}`; // userIds are incremental
};

const syncStripeUsage = (user: IUser) => (dispatch: DispFn, getState: () => AppState) => {
  try {
    Sentry.addBreadcrumb({
      message: "Calling sync-stripe-subscription-usage",
      data: { user },
    });
    dispatch(sendPost("/api/sync-stripe-subscription-usage", {}));
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
};

export const createUser = (userData: Partial<IUserFull>, _contract: IContractCore) => {
  return async (dispatch: DispFn, getState: () => AppState) => {
    const userId = dispatch(getNextUserId());
    const createdAt = moment().toISOString();

    const user = { id: userId, createdAt, ...userData } as IUserFull;
    if (user.email) {
      user.email = user.email.toLowerCase();
    }
    const contractId = uuid();
    const contract = { ..._contract, userId, id: contractId } as IContract;
    const contractUpdate = { ["contracts/" + contractId]: nullifyProps(contract) };
    await dispatch(userRepository.create(user, { firebaseUpdates: contractUpdate }));
    dispatch(syncStripeUsage(user));
    return user;
  };
};

export const updateUser = (_user: IUserFull) => {
  return async (dispatch: DispFn, getState: () => AppState) => {
    const user = {
      ..._user,
      email: _user.email ? _user.email.toLowerCase() : undefined,
    };
    const freshUser = await dispatch(userRepository.update(user));
    if (freshUser.isDeleted || freshUser.lastWorkDay) {
      // so we avoid too many update calls
      syncStripeUsage(freshUser);
    }

    return freshUser;
  };
};

export const doesEmailExist = (email: string) => {
  return async (dispatch: DispFn, getState: () => AppState): Promise<boolean> => {
    if (selectUsersFull(getState()).find((u) => u.email === email)) {
      return true; // user might exist without an account
    }

    const res = await dispatch(sendPost("/api/do-emails-exist", { emails: [email] }));
    return (res as any).data.emails.length !== 0;
  };
};

export const getExistingEmails = (emails: string[]) => {
  return async (dispatch: DispFn, getState: () => AppState): Promise<string[]> => {
    const currentUserEmails = selectUsersFull(getState())
      .map((u) => u.email)
      .filter((e) => !!e);

    let douplicates = getDouplicates([...emails, ...currentUserEmails]);

    const res = await dispatch(sendPost("/api/do-emails-exist", { emails: emails }));
    const existingAccounts = (res as any).data.emails;

    return _.uniq([...douplicates, ...existingAccounts]);
  };
};

export const deleteUserShifts = (userId: string, isDeletingUser: boolean, lastWorkDay?: string) => async (
  dispatch: DispFn
) => {
  const date = isDeletingUser ? "2000-01-01" : addSimpleDays(lastWorkDay!, 1);
  const shifts = await dispatch(
    shiftRepository.fetchMany({
      filter: ["userId_date", "between", [`${userId}_${date}`, `${userId}_5000-01-01`]],
    })
  );
  const shiftIds = shifts.map((s) => s.id);

  await dispatch(endShiftRepeats(userId, isDeletingUser, lastWorkDay));
  await dispatch(delteAllShiftRelations(shifts));
  await dispatch(shiftRepository.removeList(shiftIds));
};

export const endShiftRepeats = (userId: string, isDeletingUser: boolean, lastWorkDay?: string) => async (
  dispatch: DispFn
) => {
  const shiftRepeats = await dispatch(shiftRepeatRepository.fetchMany());
  const shiftRepeatsOfUser = shiftRepeats.filter((r) => r.userId === userId);

  if (!shiftRepeatsOfUser.length) {
    return;
  }

  if (isDeletingUser) {
    const shiftRepeateIds = shiftRepeatsOfUser.map((r) => r.id);
    await dispatch(shiftRepeatRepository.removeList(shiftRepeateIds));
  } else {
    const shiftRepeatsToEnd = shiftRepeatsOfUser.filter((r) => !r.endDate || r.endDate > lastWorkDay!);
    const nextShiftRepeats = shiftRepeatsToEnd.map((r) => ({ ...r, endDate: lastWorkDay }));
    await dispatch(shiftRepeatRepository.updateList(nextShiftRepeats));
  }
};
