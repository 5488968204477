import React from "react";
import cn from "classnames";
import { Icon } from "antd";
import { DragElementWrapper } from "react-dnd";
import "./styles.scss";
import { useSelector } from "../../helpers/redux";

type Props = {
  isOverMoveToDropArea: boolean;
  moveToDropRef: DragElementWrapper<any>;
  isOverCloneDropArea: boolean;
  cloneDropRef: DragElementWrapper<any>;
  hideMove?: boolean;
  hideCopy?: boolean;
};

export const ShiftDropArea = React.memo((props: Props) => {
  const draggingShift = useSelector((s) => s.ui.shifts.roster.draggingShift);
  if (!draggingShift) {
    return null;
  }
  return (
    <div className={cn({ shiftDropAreaWrapper: true })}>
      {!props.hideMove && (
        <div
          className={cn({
            moveToArea: true,
            dropArea: true,
            isOver: props.isOverMoveToDropArea,
          })}
          ref={props.moveToDropRef}
        >
          <Icon type="arrow-right" />
        </div>
      )}
      {!props.hideCopy && (
        <div
          className={cn({
            cloneArea: true,
            dropArea: true,
            isOver: props.isOverCloneDropArea,
          })}
          ref={props.cloneDropRef}
        >
          <Icon type="copy" />
        </div>
      )}
    </div>
  );
});
