import { Button, Checkbox, Input, InputNumber, Popover } from "antd";
import React, { useEffect, useState } from "react";
import cn from "classnames";
import "./styles.scss";
import { useSelector } from "../../../../../helpers/redux";
import { IUserType } from "../../../../../shared/entities/IRosterSettings";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../../actions/modal";
import { UserTypesModal } from "../../../UserTypesModal/UserTypesModal";
import { rosterSettingsRepository } from "../../../../../repositories/rosterSettingsRepository";
import { RosterSettings } from "../../../../settings/rosterSettings/RosterSettings";

type Props = {
  typeId?: string;
  onTypeSelected: (id?: string) => void;
};

export const dummyUserTypes: IUserType[] = [
  {
    id: "ut001",
    name: "Vollzeit",
    slug: "VZ",
  },
  {
    id: "ut002",
    name: "Teilzeit",
    slug: "TZ",
  },
  // {
  //   id: "ut003",
  //   name: "Azubi",
  //   slug: "AZUB",
  // },
];

export const UserTypeEditor = React.memo((props: Props) => {
  const [isOpen, setOpenPopover] = useState(false);
  const rosterSetting = useSelector((s) => s.data.rosterSettings[0]);
  const userTypes = rosterSetting.userTypes ? Object.values(rosterSetting.userTypes) : dummyUserTypes;

  const dispatch = useDispatch();
  const userType = props.typeId && userTypes.find((ut) => ut.id === props.typeId);

  const onTypeSelect = (typeId: string) => {
    props.onTypeSelected(typeId === props.typeId ? undefined : typeId); // deselecting when clicking on a selected type
    setOpenPopover(false);

    /// this part is to write the dummyUserTypes to the DB > for reasons of backward compatibility.
    if (!rosterSetting.userTypes) {
      const userTypesObj = userTypes.reduce((acc, ut) => ({ ...acc, [ut.id]: ut }), {});
      dispatch(rosterSettingsRepository.update({ ...rosterSetting, userTypes: userTypesObj }));
    }
  };

  const renderPopover = () => {
    return (
      <div className="selectUserTypePopover">
        <div className="userTypesList">
          {userTypes
            .filter((ut) => ut.id === props.typeId || !ut.isDisabled)
            .map((ut) => (
              <div
                className={cn({ userTypeRow: 1, selected: ut.id === props.typeId })}
                onClick={() => onTypeSelect(ut.id)}
              >
                <div className="name">{ut.name}</div>
                <div className="slug">{ut.slug}</div>
              </div>
            ))}
        </div>
        <div className="editUserTypesBtn">
          <Button
            style={{ width: "100%" }}
            children={lg.weitere_hinzufügen}
            icon={"plus"}
            // size="small"
            onClick={() => {
              setOpenPopover(false);
              dispatch(openModal(UserTypesModal, { userTypes }));
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="userTypeEditorBtnMain">
      <Popover
        content={renderPopover()}
        title={<div style={{ color: "#1a90ff" }}>{lg.Mitarbeiter_typ}</div>}
        placement="bottom"
        trigger="click"
        visible={isOpen}
        onVisibleChange={(isOpen: boolean) => {
          setOpenPopover(isOpen);
        }}
        children={
          <div className={"minWrapper element"} data-rh={isOpen ? null : lg.Mitarbeiter_typ}>
            {"Typ"}
            {userType && <div className="value">{userType.slug}</div>}
          </div>
        }
      />
    </div>
  );
});
