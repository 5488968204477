import React, { useState } from "react";
import _ from "lodash";
import { Checkbox, Button, Radio } from "antd";
import "./styles.scss";
import TZModal from "../../../../../components/TZModal/TZModal";
import { useDispatch, useSelector } from "react-redux";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";
import { IRuleSpecification } from "../../../../../shared/entities/IRuleSpecification";
import { IRosterSettings } from "../../../../../shared/entities/IRosterSettings";
import { RuleSpecificationForm } from "../../../../../components/RuleSpecificationForm/RuleSpecificationForm";
import { IUser } from "../../../../../shared/entities/IUser";
import { AppState } from "../../../../../types/AppState";
import "./styles.scss";
import {
  selectBranchMap,
  selectJobPositionMap,
  selectShiftAddressMap,
  selectWorkSpaceMap,
} from "../../../../../selectors/mapSelectors";
import { toDisplayDate } from "../../../../Users/userPageHelpers";
import { selectActiveUsers } from "../../../../../selectors/ActiveUserSelectors";
import { toSimpleDate } from "../../../../../shared/helpers/timeHelpers";
import moment from "moment";
import { closeModal } from "../../../../../actions/modal";

type Props = {
  createExport: (type: "pdf" | "csv" | "multi-pdf", options?: { userIds: string[] }) => Promise<void>;
};
//
export const ReportMultiPdfExportModal = (props: Props) => {
  const dispatch = useDispatch<DispFn>();
  const reportFilters = useSelector((s: AppState) => s.ui.shifts.reporting.filters);

  const activeUsers = useSelector((s: AppState) => selectActiveUsers(s, toSimpleDate(moment())));
  const branchMap = useSelector(selectBranchMap);
  const jobPosMap = useSelector(selectJobPositionMap);
  const addressMap = useSelector(selectShiftAddressMap);
  const labelMap = useSelector(selectWorkSpaceMap);

  const [ruleSpecification, setRuleSpecification] = useState<IRuleSpecification>({});
  const [includedUsers, setIncludedUsers] = useState<IUser[]>(activeUsers);
  const [isLoading, setLoading] = useState<boolean>(false);

  const startDate = toDisplayDate(reportFilters.filterStartDate);
  const endDate = toDisplayDate(reportFilters.filterEndDate);

  const { filterBranchId, filterJobPositionId, filterLabelId, filterAddressId } = reportFilters;

  return (
    <TZModal className="exportMultiReportModalMain">
      <TZModal.Body>
        <div className="title">{lg.Mitarbeiter} PDF Export</div>
        <div className="description">
          <div className="row">{`${lg.Zeitraum}: ${startDate} - ${endDate}`}</div>
          {filterBranchId && <div className="row">{`${lg.standort}: ${branchMap[filterBranchId]?.name} `}</div>}
          {filterJobPositionId && <div className="row">{`${lg.rolle}: ${jobPosMap[filterJobPositionId]?.name} `}</div>}
          {filterLabelId && <div className="row">{`${lg.label}: ${labelMap[filterLabelId]?.name} `}</div>}
          {filterAddressId && <div className="row">{`${lg.adresse}: ${addressMap[filterAddressId]?.name} `}</div>}
        </div>
        <div className="buttonWrapper">
          <Button
            type="primary"
            icon="download"
            children="PDF Export"
            disabled={isLoading}
            loading={isLoading}
            onClick={async () => {
              setLoading(true);
              const userIds = includedUsers.map((u) => u.id);
              await props.createExport("multi-pdf", { userIds });
              setLoading(false);
              dispatch(closeModal());
            }}
          />
        </div>
        <div className="contentWrapper">
          <RuleSpecificationForm
            ruleSpecification={ruleSpecification}
            onChange={(_ruleSpecification: IRuleSpecification, _includedUsers: IUser[]) => {
              setRuleSpecification(_ruleSpecification);
              setIncludedUsers(_includedUsers);
            }}
          />
        </div>
      </TZModal.Body>
    </TZModal>
  );
};
