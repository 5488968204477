import { envi } from "../../../../env";
import { withRosterLoading } from "./rosterReducerHelpers";

export const rosterModeActionType = {
  SET: "@@AV/SHIFT_ROSTER_MODE",
};

export enum RosterMode {
  Week,
  Day,
  Month,
}

export const setRosterMode = (mode: RosterMode) =>
  withRosterLoading({
    type: rosterModeActionType.SET,
    payload: mode,
  });

const initialState = envi.isV2() ? RosterMode.Day : RosterMode.Week;

export const rosterModeReducer = (state = initialState, a: any): RosterMode =>
  a.type === rosterModeActionType.SET ? a.payload : state;
