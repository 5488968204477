import React, { useEffect } from "react";
import "./styles.scss";
import { useDispatch, useSelector, batch } from "react-redux";
import { SelectClockingStatus } from "../../../selectors/ClockingStatusSelector";
import { AppState } from "../../../types/AppState";
import { openModal } from "../../../actions/modal";
import { LiveStatusType, LiveStatusModal } from "../../../components/modals/LiveStatusModal/LiveStatusModal";
import { SDateFormat } from "../../../shared/helpers/SimpleTime";
import moment from "moment";
import { timeClockingRepository } from "../../../repositories/timeClockingRepository";
import { shiftRepository } from "../../../repositories/shiftRepository";
import { DispFn } from "../../../frontend-core/types/thunkTypes";
import { trackingRepository } from "../../../repositories/TrackingRepository";

export const LiveStatusPaper = React.memo(() => {
  const statusData = useSelector(SelectClockingStatus);
  const timeClockSettings = useSelector((s: AppState) => s.data.timeClockSettings[0]);
  const dispatch = useDispatch<DispFn>();

  useEffect(() => {
    // TODO: Add loading indicator
    const yesterday = moment().add(-1, "day").format(SDateFormat);
    const today = moment().format(SDateFormat);
    const filter = ["date", "between", [yesterday, today]] as any;
    batch(() => {
      dispatch(shiftRepository.fetchMany({ filter }));
      dispatch(timeClockingRepository.addListener({ filter }));
      dispatch(trackingRepository.addListener({ filter, key: "trackingsOfLifeStatus" }));
    });
  }, []);

  const openUserListModal = (type: LiveStatusType) => {
    dispatch(
      openModal(LiveStatusModal, {
        type: type,
      })
    );
  };

  return (
    <div className="cell">
      <div className="content">
        <div className="dashboardPaper">
          <div className="liveClockingWrapper">
            <div
              className="liveClockingItem"
              onClick={() => openUserListModal(LiveStatusType.usersClockedIn)}
              style={{ borderRight: "1px solid #ededed" }}
            >
              <div className="liveAmount" style={{ color: "#1A90FF" }}>
                {statusData.activeClockings.length}
              </div>
              <div className="itemTitle" style={{ color: "#1A90FF" }}>
                {lg.eingestempelt}
              </div>
            </div>
            {timeClockSettings.breaksNeedToBeClocked && (
              <div
                className="liveClockingItem"
                onClick={() => openUserListModal(LiveStatusType.usersInBreak)}
                style={{ borderRight: "1px solid #ededed" }}
              >
                <div className="liveAmount" style={{ color: "#fd9d1a" }}>
                  {statusData.activeClockingsInBreak.length}
                </div>
                <div className="itemTitle" style={{ color: "#fd9d1a" }}>
                  {lg.pause}
                </div>
              </div>
            )}
            {timeClockSettings.shiftsNeedToBeClocked && (
              <div className="liveClockingItem" onClick={() => openUserListModal(LiveStatusType.usersLate)}>
                <div className="liveAmount" style={{ color: "#F5232D" }}>
                  {statusData.unclockedShifts.length}
                </div>
                <div className="itemTitle" style={{ color: "#F5232D" }}>
                  {lg.verspätet}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
