import { Icon, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../actions/modal";
import { SortOrderModal } from "../../components/SortOrderModal/SortOrderModal";
import "./styles.scss";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";

export const SortOrderButton = () => {
  const dispatch = useDispatch();
  const sessionInfo = useSelector(selectSessionInfo);

  if (!sessionInfo.isAdmin()) {
    return null;
  }

  return (
    <Tooltip title={lg.reihenfolge_ändern}>
      <div
        className="sortOrderButton"
        onClick={() => {
          dispatch(openModal(SortOrderModal, {}));
        }}
      >
        <Icon type="sort-ascending" />
      </div>
    </Tooltip>
  );
};
