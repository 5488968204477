import React from "react";
import { Button, notification } from "antd";
import { Formik, FormikProps, FormikHelpers } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { jobPositionRepository } from "../../../../repositories/jobPositionRepository";
import { closeModal } from "../../../../actions/modal";
import TZModal from "../../../../components/TZModal/TZModal";
import AvColorPicker from "../../../../components/AvColorPicker/AvColorPicker";
import { AppState } from "../../../../types/AppState";
import "./styles.scss";
import AvFormikInput from "../../../../components/AvFormikInput/AvFormikInput";
import AvFormField from "../../../../components/AvFormField/AvFormField";
import { busyInjector, BusyInjectorProps } from "../../../../components/BusyInjector/BusyInjector";
import { IJobPosition } from "../../../../shared/entities/IJobPosition";
import _ from "lodash";
import { userRepository } from "../../../../repositories/userRepository";
import { selectUserFullMap } from "../../../../selectors/userFullMapSelector";
import UserPickerList from "../../branches/UserPickerList/UserPickerList";
import moment from "moment";
import { selectActiveUsersFull } from "../../../../selectors/ActiveUserSelectors";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";

const mapStateToProps = (state: AppState) => {
  return {
    jobPositions: state.data.jobPositions,
    userMap: selectUserFullMap(state),
    activeUsers: selectActiveUsersFull(state, moment().format(SDateFormat)),
  };
};

type FormValues = {
  name: string;
  color: string;
};

type OwnProps = {
  data?: IJobPosition;
  onComplete?: (newJobPosition: IJobPosition) => void;
  hideUserPicker?: boolean;
};

type State = {
  showDeleteConfirm: boolean;
  addedUserIds: string[];
  removedUserIds: string[];
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class JobPositionsModal extends React.PureComponent<Props, State> {
  id: string;
  constructor(props: Props) {
    super(props);

    this.id = props.data?.id || `p${props.jobPositions.length + 1}`;

    this.state = {
      showDeleteConfirm: false,
      addedUserIds: [],
      removedUserIds: [],
    };
  }

  validationSchema = Yup.object().shape({
    name: Yup.string().max(50, lg.maximal_50_zeichen).required(lg.eingabe_erforderlich),
    color: Yup.string().required(lg.eingabe_erforderlich),
  });

  handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    const { data, dispatch, jobPositions } = this.props;
    const acitveJobPositions = jobPositions.filter((jp) => !jp.isInactive);
    const maxSortIndex = _.max(acitveJobPositions.map((jp) => jp.sortIndex)) || 0;
    const sortIndex = data?.sortIndex === undefined ? maxSortIndex + 1 : data.sortIndex;
    const res = data?.id
      ? await dispatch(jobPositionRepository.update({ ...data, ...values, sortIndex }))
      : await dispatch(jobPositionRepository.create({ ...values, id: this.id, sortIndex }));

    if (this.state.addedUserIds.length || this.state.removedUserIds.length) {
      await this.saveUserJobPosIds();
    }

    if (this.props.onComplete) {
      this.props.onComplete(res);
    }
    actions.setSubmitting(false);
    this.props.dispatch(closeModal());
  };

  setJobPositionInactive = async () => {
    if (!this.state.showDeleteConfirm) {
      this.setState({ showDeleteConfirm: true });
      return;
    }

    const acitveJobPositions = this.props.jobPositions.filter((jp) => !jp.isInactive);
    if (acitveJobPositions.length === 1) {
      notification.warning({
        message: lg.es_können_nicht_alle_rollen_gelöscht_werden_es_muss_mind_1_vorhanden_sein,
      });
      return;
    }

    await this.props.load(
      this.props.dispatch(
        jobPositionRepository.update({
          ...this.props.data,
          isInactive: true,
        } as IJobPosition)
      ),
      "setInactive"
    );

    notification.success({ message: lg.rolle_gelöscht });
    this.props.dispatch(closeModal());
  };

  saveUserJobPosIds = async () => {
    const { addedUserIds, removedUserIds } = this.state;
    const { userMap, dispatch } = this.props;
    const jobPosMap = {};
    addedUserIds.forEach((uid) => (jobPosMap[uid] = _.uniq([...userMap[uid].jobPositionIds, this.id])));
    removedUserIds.forEach((uid) => (jobPosMap[uid] = userMap[uid].jobPositionIds.filter((bid) => bid !== this.id)));
    await dispatch(userRepository.batchUpdatJobPositions(jobPosMap));
  };

  hasUserJobPos = (userId: string) =>
    (this.state.addedUserIds.includes(userId) || this.props.userMap[userId].jobPositionIds.includes(this.id)) &&
    !this.state.removedUserIds.includes(userId);

  toggledUser = (userId: string) => {
    const user = this.props.userMap[userId];
    const isAdding = !this.hasUserJobPos(userId);
    if (!isAdding && user.jobPositionIds.includes(this.props.data?.id!) && user.jobPositionIds.length === 1) {
      notification.info({ message: lg.mitarbeiter_brauchen_mindestens_eine_rolle });
      return;
    }

    const removedUserIds = this.state.removedUserIds.filter((uid) => user.id !== uid);
    const addedUserIds = this.state.addedUserIds.filter((uid) => user.id !== uid);
    isAdding ? addedUserIds.push(user.id) : removedUserIds.push(user.id);

    this.setState({ removedUserIds, addedUserIds });
  };

  render() {
    const { data, activeUsers } = this.props;
    const { showDeleteConfirm } = this.state;
    const isEditMode = data?.id;

    return (
      <TZModal>
        <TZModal.Head title={isEditMode ? lg.rolle_editieren : lg.neue_rolle}></TZModal.Head>
        <Formik
          initialValues={{
            name: data?.name || "",
            color: data?.color || "",
          }}
          onSubmit={this.handleSubmit}
          enableReinitialize
          validationSchema={this.validationSchema}
          render={(props: FormikProps<FormValues>) => (
            <form onSubmit={props.handleSubmit}>
              <TZModal.Body>
                <div className="jobPositionsFormMain formContent">
                  <div className="fb row">
                    <div className="fb col" style={{ minWidth: 360 }}>
                      <div className="formField">
                        <AvFormikInput formikProps={props} fieldName="name" label={lg.name} autoFocus></AvFormikInput>
                      </div>

                      <div className="formField">
                        <AvFormField label={lg.farbe} errorMessage={props.touched.color && props.errors.color}>
                          <AvColorPicker
                            value={props.values.color}
                            onChange={(value: string) => props.setFieldValue("color", value)}
                          />
                        </AvFormField>
                      </div>
                    </div>
                    {activeUsers.length > 10 && !this.props.hideUserPicker && (
                      <div className="fb column">
                        <div className="fb userPickerWrapper">
                          <div className="fb title">{lg.mitarbeiter_der_rolle}</div>
                          <UserPickerList
                            selectedUserIds={activeUsers.filter((user) => this.hasUserJobPos(user.id)).map((u) => u.id)}
                            toggleUser={this.toggledUser}
                            selectList={(addedUserIds) => this.setState({ addedUserIds, removedUserIds: [] })}
                            showBranchFilter={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </TZModal.Body>
              <TZModal.Footer>
                {!!this.props.data && (
                  <Button
                    id="delete-job-positions"
                    type={showDeleteConfirm ? "danger" : "default"}
                    style={{ marginRight: "auto" }}
                    onClick={this.setJobPositionInactive}
                    children={showDeleteConfirm ? lg.wirklich_löschen : lg.löschen}
                    loading={this.props.isLoading("setInactive")}
                  />
                )}
                <Button
                  id="save-job-positions"
                  type="primary"
                  onClick={props.submitForm}
                  loading={props.isSubmitting}
                  disabled={!!Object.values(props.errors).length}
                >
                  {lg.Speichern}
                </Button>
              </TZModal.Footer>
            </form>
          )}
        />
      </TZModal>
    );
  }
}

export default connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(busyInjector(JobPositionsModal));
