export const intoVideos = [
  {
    title: lg.einführung,
    videoId: "OLhiBqVeuVk",
  },
  {
    title: lg.einrichtung,
    videoId: "L34d9olFTfA",
  },
  {
    title: lg.wochenplan,
    videoId: "ZawRaO-fDyo",
  },
  {
    title: lg.stundenkonto,
    videoId: "p4fJwiCj5AU",
  },
  {
    title: lg.offene_schichten,
    videoId: "2KQmKV5xw1w",
  },
  {
    title: lg.abwesenheiten,
    videoId: "qrasc3L7gMA",
  },
  {
    title: lg.kontingente,
    videoId: "a1OZBWpq1-I",
  },
  {
    title: lg.verfügbarkeiten,
    videoId: "ZBnSkanKBFg",
  },
  {
    title: lg.anträge,
    videoId: "xRMRmQjhcZE",
  },
  {
    title: lg.stempeluhr,
    videoId: "ZhVBxIjCj3Q",
  },
];
