import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import { selectSessionInfo } from "./SessionInfoSelector";
import { selectShiftMap } from "./shiftMapSelector";

export const selectTrackingRequests = createSelector(
  [(state: AppState) => state.data.trackings, selectSessionInfo, selectShiftMap],
  (trackings, sessionInfo, shiftsMap) => {
    const canManage = sessionInfo.hasManagerPermissions();
    const isAdmin = sessionInfo.isAdmin();
    const currentUser = sessionInfo.user;

    const currentTrackings = canManage
      ? trackings.filter((t) => isAdmin || currentUser.branchIds.includes(t.branchId))
      : trackings.filter((t) => t.userId === currentUser.id);

    return currentTrackings.filter((t) => !t.isAccepted).filter((t) => shiftsMap[t.id]); // to make shure shift data is loaded and avoid null-pointers
  }
);
