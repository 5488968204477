import React from "react";
import { Moment } from "moment";
import cn from "classnames";
import "./styles.scss";
import { getWeekDayLabels } from "../../../../../../shared/constants/WeekDays";
import { Icon, Modal } from "antd";
import { unpublishWeekPlan, unpublishWeekPlans } from "../../../../../../actions/weekPlan";
import { PublishWeekModal } from "../../../../../../components/modals/PublishWeekModal/PublishWeekModal";
import { openModal } from "../../../../../../actions/modal";
import { DispFn } from "../../../../../../frontend-core/types/thunkTypes";
import { toSimpleDate } from "../../../../../../shared/helpers/timeHelpers";
import { IDailyNote } from "../../../../../../shared/entities/IDailyNote";
import { DailyNoteModal } from "../../../components/DailyNoteModal/DailyNoteModal";
import { IBranch } from "../../../../../../shared/entities/IBranch";
import { getLocaleLang } from "../../../../../../helpers/dateFormatHelper";

type Props = {
  mom: Moment;
  width: number;
  daysInMonth: number;
  holiday: string | null;
  isNow?: boolean;
  redirectToWeekView: () => void;
  showMonth?: boolean;
  displayGreenEye: boolean;
  publishedBranches: IBranch[];
  dispatch: DispFn;
  isFirstDay: boolean;
  isLastDay: boolean;
  selectedBranchId: string;
  dailyNotes: IDailyNote[];
  canManage: boolean;
  isV2?: boolean;
  isInFuture?: boolean;
};

export const DayCell = React.memo((props: Props) => {
  const date = toSimpleDate(props.mom);
  const dayOfMonth = props.mom.date();
  const monthName = props.mom.format("MMM").substr(0, 3);
  const dayOfWeek = props.mom.isoWeekday();
  const isSunday = dayOfWeek === 7;
  const isSaturday = dayOfWeek === 6;
  // const isWeekend = dayOfWeek >= 6;
  const calendarWeek = props.mom.isoWeek();
  const { holiday, showMonth, isFirstDay, isLastDay, canManage, isV2, isInFuture } = props;

  const isNarrowCell = isLastDay || (isFirstDay && isSunday);

  const width = 100 / props.daysInMonth + "%";

  const publishClicked = () => {
    props.displayGreenEye
      ? Modal.confirm({
          title: lg.veröffentlichung_aufheben,
          content: lg.die_schichten_der_kw_week_werden_für_mitarbeiter_nicht_mehr_sichtbar(calendarWeek),
          onOk: () => {
            props.dispatch(
              unpublishWeekPlans(
                !props.selectedBranchId ? props.publishedBranches.map((b) => b.id) : [props.selectedBranchId],
                toSimpleDate(props.mom.startOf("isoWeek"))
              )
            );
          },
        })
      : props.dispatch(
          openModal(PublishWeekModal, {
            week: toSimpleDate(props.mom.startOf("isoWeek")),
          })
        );
  };

  return (
    <div
      className={cn({
        fb: true,
        rosterDayCellMain: true,
        isNow: props.isNow,
        isFutureGrey: isInFuture && isV2,
        su: isSunday,
        sa: isSaturday,
        holiday,
      })}
      style={{ width, flexBasis: width }}
    >
      {/* <div className="dayLine"></div> */}
      {(isFirstDay || dayOfWeek === 1) && !(isV2 && isInFuture) && (
        <div className="fb weekDisplay">
          <span
            onClick={props.redirectToWeekView}
            className="kwDisplay"
            data-rh-at="top"
            data-rh={lg.zur_wochen_ansicht}
          >
            {!isNarrowCell && lg.kw + " " + calendarWeek}
          </span>
          {!props.isV2 && (
            <div
              data-rh-at="top"
              data-rh={
                props.displayGreenEye
                  ? canManage
                    ? lg.veröffentlichung_aufheben
                    : lg.wochenplan_ist_veröffentlicht
                  : canManage
                  ? lg.wochenplan_veröffentlichen
                  : lg.wochenplan_ist_noch_nicht_veröffentlicht
              }
              className={cn({
                publishWeekEye: true,
                canClick: canManage,
                isUnpublishedWeekEye: !props.displayGreenEye,
              })}
              style={{ color: props.displayGreenEye ? "#18c756" : "#aaabb2" }}
              onClick={canManage ? publishClicked : undefined}
            >
              <Icon type={props.displayGreenEye ? "eye" : "eye-invisible"} />
            </div>
          )}
        </div>
      )}
      <div data-rh={holiday} className="content-wrapper">
        <div className={cn({ fb: true, weekDay: true, holiday: holiday })}>
          {Object.values(getWeekDayLabels(getLocaleLang()))[dayOfWeek - 1]}
        </div>
        <div className={cn({ fb: true, monthDay: true, holiday: holiday })}>
          <div className="date">{dayOfMonth}</div>
          {showMonth && <div className="monthName">{monthName}</div>}

          <div className="fb dailyNotesWrapper">
            {canManage && (
              <div
                className="dailyNote addDailyNote"
                data-rh={lg.tagesnotiz_hinzufügen}
                data-type="month-grid-add-daily-note"
                data-add-daily-note-date={date}
              >
                +
              </div>
            )}
            {props.dailyNotes
              .filter((d, i) => i < 3)
              .map((dailyNote) => (
                <div
                  data-rh={dailyNote.text}
                  className="dailyNote"
                  style={{ backgroundColor: dailyNote.color }}
                  onClick={() =>
                    props.dispatch(
                      openModal(DailyNoteModal, {
                        date,
                        dailyNote,
                      })
                    )
                  }
                >
                  {/* {dailyNote.text.substr(0, 1)} */}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
});
