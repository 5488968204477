import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import TZModal from "../../TZModal/TZModal";
import { Button } from "antd";
import { closeModal, openModal } from "../../../actions/modal";
import { assignOpenShiftToUser, rejectShiftApplication } from "../../../actions/shift";
import ButtonGroup from "antd/lib/button/button-group";
import "./styles.scss";
import { ShiftPopupHead } from "../components/ShiftPopupHead/ShiftPopupHead";
import StartEndTimeInput from "../ShiftPopup/StartEndTimeInput/StartEndTimeInput";
import { OpenShiftInfoRow } from "../components/OpenShiftInfoRow/OpenShiftInfoRow";
import * as Sentry from "@sentry/browser";
import { busyInjector, BusyInjectorProps } from "../../BusyInjector/BusyInjector";
import { IShift } from "../../../shared/entities/IShift";
import { ShiftPopup } from "../ShiftPopup/ShiftPopup";
import { selectUsersFull } from "../../../selectors/usersFullSelector";
import { doShiftsOverlap } from "../../../selectors/shiftOverlappingsSelector";
import { selectShiftsByUserMap } from "../../../selectors/mapSelectors";
import { selectContractsByUser } from "../../../selectors/contractsByUserSelector";
import { featuresSelector } from "../../../selectors/FeaturesSelector";
import { selectInitialCreditByUser } from "../../../selectors/initialCreditsByUserSelector";
import { selectRosterDateRange } from "../../../selectors/rosterDateRangeSelector";
import { selectCreditSpanForUser } from "../../../selectors/creditSpansSelector";
import { UserCreditDetails } from "../../../pages/shiftsPage/RosterPage/components/UserCreditDetails/UserCreditDetails";
import { getValidContract } from "../../../shared/helpers/credit";

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
  users: selectUsersFull(state),
  shift: state.data.shifts.find((s) => s.id === ownProps.shiftId),
  shiftsByUser: selectShiftsByUserMap(state),
  jobPositions: state.data.jobPositions,
  rosterMode: state.ui.shifts.roster.rosterMode,
  rosterDateRange: selectRosterDateRange(state),
  contractsMap: selectContractsByUser(state),
  state: state, // yes, super dirty > workaround is a lot of work
  features: featuresSelector(state),
  initialUserCreditsByUser: selectInitialCreditByUser(state),
  isTemplateMode: state.ui.shifts.roster.rosterTemplateMode.active,
  rosterSettings: state.data.rosterSettings[0],
});

type OwnProps = {
  shiftId: string;
};

type State = {
  acceptedUserIds: Array<string>;
  deniedUserIds: Array<string>;
  isLoading: boolean;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class _ShiftApplicationsPopup extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      acceptedUserIds: [],
      deniedUserIds: [],
      isLoading: false,
    };
  }

  resolveApplication = async (userId: string, accept: boolean) => {
    const closeModalAfterRequest = accept && this.props.shift?.requiredUsersAmount === 1;

    Sentry.addBreadcrumb({
      message: "resolve shiftApplication",
      data: { userId, accept, shiftId: this.props.shiftId },
    });

    await this.props.load(
      this.props.dispatch(
        accept
          ? assignOpenShiftToUser(
              this.props.shiftId,
              userId,
              this.props.shift?.jobPositionId as string,
              undefined,
              accept
            )
          : rejectShiftApplication(this.props.shiftId, userId)
      ),
      userId
    );
    if (closeModalAfterRequest) {
      this.props.dispatch(closeModal());
    }
  };

  getJobPosName = (shift: IShift) => {
    return this.props.jobPositions.find((jp) => jp.id === shift.jobPositionId)!.name;
  };

  render() {
    // if shift is not there anymore / because last open position got assigned to user
    // exit here to prevent errors
    if (!this.props.shift) {
      return null;
    }

    const shift = this.props.shift!;
    const { rosterDateRange, contractsMap } = this.props;
    const { startTime, endTime, breakMinutes, breakStartTime } = this.props.shift;

    return (
      <TZModal style={{ minWidth: 600 }} className="shiftApplicationsPopup">
        <ShiftPopupHead shift={this.props.shift} title={lg.offene_schicht} />
        <StartEndTimeInput
          onChange={() => {}}
          startTime={startTime as string}
          endTime={endTime as string}
          breakMinutes={breakMinutes}
          withBorderBottom
          disabled={true}
          withBreakStartTime={!!breakStartTime}
          breakStartTime={breakStartTime}
        />
        <div className="content">
          <div className="shiftInfoRowWrapper">
            <OpenShiftInfoRow shift={this.props.shift} />
          </div>
          <div className="appliedUsersWrapper">
            {!this.props.shift.appliedUserIds?.length && (
              <div className="fb aCenter jCenter noApplicationsMsg" style={{ padding: 32 }}>
                {lg.es_gibt_es_keine_offenen_bewerbungen_zu_dieser_schicht}
              </div>
            )}
            {this.props.shift.appliedUserIds?.map((appliedUserId) => {
              const user = this.props.users.find((u) => u.id === appliedUserId)!;
              const overlapShift = this.props.shiftsByUser[user.id]?.find((s) => doShiftsOverlap(shift, s));

              return (
                <div className="fb row aCenter application" key={appliedUserId}>
                  <span className="userWrapper">{user.name}</span>
                  <div className="userCreditDetailsWrapper">
                    <UserCreditDetails
                      user={user}
                      startDate={this.props.rosterDateRange.rangeStart}
                      rosterMode={this.props.rosterMode}
                      creditSpan={selectCreditSpanForUser(this.props.state, user.id)} // yes, super dirty > workaround is a lot of work
                      contract={getValidContract(contractsMap[user.id], rosterDateRange.rangeStart)!}
                      features={this.props.features}
                      initialUserCredit={this.props.initialUserCreditsByUser[user.id]}
                      rosterDateRange={this.props.rosterDateRange}
                      isTemplateMode={this.props.isTemplateMode}
                      customMonthStartDay={this.props.rosterSettings.customMonthIntervalStart || 1}
                      showOvertime={true}
                    />
                  </div>
                  <div
                    className="overlapIndicator"
                    style={{ backgroundColor: "#ff847e", visibility: overlapShift ? "visible" : "hidden" }}
                    data-rh={
                      overlapShift &&
                      `${lg.bereits_eingeplant}:
                      ${overlapShift.startTime} - ${overlapShift.endTime} | ${this.getJobPosName(overlapShift)}`
                    }
                  />

                  <ButtonGroup>
                    <Button
                      // style={{ padding: '4px' }}
                      id="shift-application-entry-accept"
                      type="default"
                      icon="check"
                      disabled={!this.props.shift?.requiredUsersAmount}
                      loading={this.props.isLoading(appliedUserId)}
                      data-rh={lg.annehmen}
                      // size="small"
                      onClick={() => this.resolveApplication(appliedUserId, true)}
                    />
                    <Button
                      // style={{ padding: '4px' }}
                      id="shift-application-entry-reject"
                      type="default"
                      icon="close"
                      data-rh={lg.ablehnen}
                      // size="small"
                      loading={this.props.isLoading(appliedUserId)}
                      onClick={() => this.resolveApplication(appliedUserId, false)}
                    />
                  </ButtonGroup>
                </div>
              );
            })}
          </div>
        </div>
        <TZModal.Footer style={{ justifyContent: "flex-start" }}>
          <Button
            id="shift-application-go-to-shift"
            type="default"
            icon="arrow-left"
            children={lg.zur_schicht}
            onClick={() => {
              this.props.dispatch(closeModal());
              this.props.dispatch(openModal(ShiftPopup, { shift: this.props.shift as IShift }));
            }}
          />
        </TZModal.Footer>
      </TZModal>
    );
  }
}

export const ShiftApplicationsPopup = connect(mapStateToProps)(busyInjector(_ShiftApplicationsPopup));
