import React from "react";
import { useDrag } from "react-dnd";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { shfitDragActionTypes } from "../../../../../reducers/ui/shifts/roster/draggingShift";
import { ShiftWarningIcon } from "../../../../../components/Shift/ShiftWarningIcon/ShiftWarningIcon";
import { pastelColors } from "../../../../../frontend-core/helpers/colors";
import { IShift } from "../../../../../shared/entities/IShift";
import { IUser } from "../../../../../shared/entities/IUser";
import { IAbsence } from "../../../../../shared/entities/IAbsence";
import { ITracking } from "../../../../../shared/entities/ITracking";
import { Map } from "../../../../../shared/types/general";
import { IShiftHandOverRequest } from "../../../../../shared/entities/IShiftHandOverRequest";
import { IJobPosition } from "../../../../../shared/entities/IJobPosition";
import { IWorkSpace } from "../../../../../shared/entities/IWorkSpace";
import { IShiftAddress } from "../../../../../shared/entities/IShiftAddress";
import { configConsumerProps } from "antd/lib/config-provider";
import { getDuration } from "../../../../../shared/helpers/timeHelpers";
import clockedInGifSrc from "./../../../../../frontend-core/assets/clockedinsignal.gif";

type Props = {
  shift: IShift;
  user?: IUser;
  simpleDate: string;
  jobPositionId: string;
  absence?: IAbsence;
  printMode: any;
  recentModifiedShifts: any;
  trackingMap: Map<ITracking>;
  handOverRequestMap: Map<IShiftHandOverRequest>;
  overlap: any;
  userUnavailable: any;
  jobPosMap: Map<IJobPosition>;
  workSpaceMap: Map<IWorkSpace>;
  addressMap: Map<IShiftAddress>;
  showLabel?: boolean;
  canManage: boolean;
  labourLawWarning?: string;
  assignedUsersAmount: number;
  isV2?: boolean;
};

const removeZero = (time: string) => {
  let timeCut = time.substring(0, time.length - 3);
  let editedTime = timeCut.charAt(0) === "0" ? timeCut.substring(1) : timeCut;
  return editedTime;
};

export const DraggableMonthShift = ({
  shift,
  user,
  simpleDate,
  jobPositionId,
  absence,
  printMode,
  recentModifiedShifts,
  trackingMap,
  handOverRequestMap,
  overlap,
  userUnavailable,
  jobPosMap,
  workSpaceMap,
  addressMap,
  showLabel,
  canManage,
  labourLawWarning,
  assignedUsersAmount,
  isV2,
}: Props) => {
  const dispatch = useDispatch();
  const hasWarning = !shift.isActiveClocking && canManage && (overlap || userUnavailable || labourLawWarning);
  const jobPositionColor = jobPosMap[shift.jobPositionId].color;

  const [{ isDragging }, dragRef] = useDrag<IShift, any, { isDragging: boolean }>({
    type: "SHIFT",
    canDrag: () => canManage && !handOverRequestMap[shift.id] && !trackingMap[shift.id],
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    item: () => {
      // using setTimeout here to make it possible to pick up a shift, because
      // as soon as a shift is picked up we add `pointer-events:none` to all shifts.
      setTimeout(() => {
        dispatch({ type: shfitDragActionTypes.BEGIN, payload: shift });
      });
      return shift;
    },
    end: () => {
      dispatch({ type: shfitDragActionTypes.END });
    },
  });

  const workSpace = shift.workSpaceId ? workSpaceMap[shift.workSpaceId] : undefined;
  const address = shift.addressId ? addressMap[shift.addressId] : undefined;
  const durationMins = shift.endTime ? getDuration(shift) : 0; // strange bug that sometimes shift has no endTime V2
  const durationDecimal = Math.floor(Math.floor(durationMins / 6) / 10) + "h";
  const isClockedIn = shift.isActiveClocking;
  const displayTimeShort = isV2 ? durationDecimal : `${removeZero(shift.startTime)} ${removeZero(shift.endTime)}`;
  // color: pastelColors.includes(workSpace.color) ? "inherit" : "white",
  const startEndTime = isClockedIn ? shift.startTime : `${shift.startTime} ${shift.endTime}`;

  return (
    <div
      key={shift.id}
      ref={dragRef}
      id={`grid-shift-${simpleDate}-${user?.id}-${jobPositionId}`}
      data-type="grid-shift"
      data-shift-id={shift.id}
      className={cn({
        fb: true,
        shiftMain: true,
        userIsAbsent: !!absence,
        isUnassignedShiftsRow: !user,
        withIcon: hasWarning || isClockedIn,
        withTime: true,
        monthlyShift: true,
        printMode,
        glowing: recentModifiedShifts.includes(shift.id),
        isDragging,
        isNotDragging: !isDragging,
        isRequirement: shift.isRequirement,
        isRequirementcomplete: (shift.requiredUsersAmount || 0) <= assignedUsersAmount,
      })}
      style={{
        borderTop: `2px solid ${jobPositionColor}`,
      }}
      // hacky trick to avoid many div's
      data-text-short={showLabel && (shift.workSpaceId || shift.addressId) ? null : displayTimeShort}
      data-text-long={(shift.workSpaceId || shift.addressId) && showLabel ? null : startEndTime}
    >
      {!user && (
        <div className="requiredUsersBox" style={{ background: jobPositionColor }}>
          {shift.requiredUsersAmount}
        </div>
      )}
      {hasWarning && !isClockedIn && !printMode && (
        <ShiftWarningIcon
          overlap={overlap}
          isUserUnavailable={userUnavailable}
          user={user}
          labourLawWarning={labourLawWarning}
        />
      )}

      {isClockedIn && <img src={clockedInGifSrc} style={{ width: 22, height: 22 }} data-rh={lg.ist_eingestempelt} />}

      {!hasWarning && !isClockedIn && showLabel && (workSpace || address) && (
        <div
          data-rh={workSpace?.name || address?.name || address?.address}
          className="shiftLabelBox"
          style={{
            backgroundColor: (workSpace || address)!.color,
            top: user ? "3px" : "auto",
            color: pastelColors.includes((workSpace || address)!.color) ? "#313139" : "white",
          }}
        >
          {(workSpace?.name || address?.name || address?.address)!.substring(0, 3)}
        </div>
      )}
    </div>
  );
};
