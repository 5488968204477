import React, { useEffect } from "react";
import cn from "classnames";
import "./styles.scss";
import { Icon } from "antd";
import { alwaysClockableBranchIdsSelector } from "../../../selectors/AlwaysClockableBranchIdsSelector";
import { useSelector } from "../../../helpers/redux";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { toSimpleDate } from "../../../shared/helpers/timeHelpers";
import moment from "moment";
import { useDispatch } from "react-redux";
import { DispFn } from "../../../frontend-core/types/thunkTypes";
import { shiftRepository } from "../../../repositories/shiftRepository";
import { timeClockingRepository } from "../../../repositories/timeClockingRepository";
import { trackingRepository } from "../../../repositories/TrackingRepository";
import { getActiveTimeClocking } from "../../../shared/helpers/timeClockingHelpers";
import { selectTrackingMap } from "../../../selectors/mapSelectors";

type Props = {
  isIconActive: boolean;
};

export const TopBarTimeClockIcon = React.memo(({ isIconActive }: Props) => {
  const dispatch = useDispatch<DispFn>();
  const sessionInfo = useSelector(selectSessionInfo);
  const currentUser = sessionInfo.user;
  const alwaysClockableBranchIds = useSelector(alwaysClockableBranchIdsSelector);
  const trackings = useSelector((state) => state.data.trackings);
  const isV2 = useSelector((state) => state.data.tenantInfo.isV2);
  const timeClockings = useSelector((state) => state.data.timeClockings);
  const trackingMap = useSelector(selectTrackingMap);
  const activeTimeClocking = getActiveTimeClocking(timeClockings, sessionInfo.user.id, trackingMap);
  const getActiveClockableClocking = () => {
    if (activeTimeClocking && !trackings.find((t) => t.id === activeTimeClocking?.id)) {
      return activeTimeClocking;
    }
  };

  const isClockedIn = !!getActiveClockableClocking();

  const isCurrentlyInBreak = () => {
    return (
      activeTimeClocking?.breakActivities?.length &&
      activeTimeClocking?.breakActivities[activeTimeClocking.breakActivities.length - 1].type === "start"
    );
  };
  const isInBreak = isCurrentlyInBreak();

  const activeText = isInBreak ? lg.pausiert : lg.eingestempelt;

  useEffect(() => {
    const userDateFilter = {
      filter: [
        "userId_date",
        "between",
        [
          `${currentUser.id}_${toSimpleDate(moment().add(-1, "days"))}`,
          `${currentUser.id}_${toSimpleDate(moment().add(1, "days"))}`,
        ],
      ],
    } as any;

    !isV2 && dispatch(shiftRepository.addListener({ ...userDateFilter, key: "shiftsAroundOndeDay" }));
    !isV2 && dispatch(trackingRepository.addListener({ ...userDateFilter, key: "trackingsAroundOndeDay" }));
    dispatch(
      timeClockingRepository.addListener({
        filter: ["date", "between", [toSimpleDate(moment().add(-1, "days")), toSimpleDate(moment().add(1, "days"))]],
      })
    );
  }, []);

  return (
    <div className="topbarIconWrapper">
      <div className="topbarIcon fb row clockinTopbarItem" style={{ padding: 2, marginTop: 4 }}>
        <Icon type="clock-circle" style={{ fontSize: 22 }} />
        {isV2 && isClockedIn && (
          <div
            className="indicator"
            style={{ background: isInBreak ? "#ffc762" : "#6ff36f" }}
            data-rh={activeText}
          ></div>
        )}
        {!isV2 && isClockedIn && <div className="clockedInLabel">{activeText}</div>}
        {/* {isV2 && <div className="clockedInLabel">{isClockedIn ? activeText : lg.stempeluhr}</div>} */}
      </div>
    </div>
  );
});
