import { DispFn } from "../frontend-core/types/thunkTypes";
import { sendPost } from "../frontend-core/actions/send";
import moment from "moment";
import * as Sentry from "@sentry/browser";
import { SDateTimeFormat } from "../shared/helpers/SimpleTime";
import { simpleDateTimeToMoment } from "../shared/helpers/timeHelpers";
import { AppState } from "../types/AppState";

export const wrongDeviceTimeActionType = { SET: "@@AV/SET_WRONG_DEVICE_TIME" };
export const wrongTimeZoneActionType = { SET: "@@AV/SET_WRONG_TIME_ZONE" };

export const checkServerTime = () => {
  return async (dispatch: DispFn, getState: () => AppState) => {
    if (getState().data.rosterSettings[0]?.noTimeZoneCheck) {
      return;
    }

    const data = (await dispatch(sendPost("/api/current-server-time-v2", {}))) as any;

    if (!data?.unixTime || !data?.dateTime) {
      Sentry.captureException(new Error("@AV: Endpoint didnt return data: /api/current-server-time"));
      return;
    }

    const serverTimeMintes =
      simpleDateTimeToMoment(data.dateTime).hours() * 60 + simpleDateTimeToMoment(data.dateTime).minutes();
    const localTimeMinutes = moment().hours() * 60 + moment().minutes();

    const isUnixTimeWrong = Math.abs(data.unixTime - moment().unix()) > 60 * 5; // 5 min in seconds
    const isTimeZoneWrong = Math.abs(serverTimeMintes - localTimeMinutes) > 2;
    // we accept a 2 min difference sometimes the request takes a while and the minutes differ by 1 min

    dispatch({ type: wrongDeviceTimeActionType.SET, payload: isUnixTimeWrong });
    dispatch({ type: wrongTimeZoneActionType.SET, payload: isTimeZoneWrong ? data.dateTime : false });

    if (isUnixTimeWrong) {
      const msg = "A user has wrong unix time:" + moment().unix() + " | " + data.unixTime;
      Sentry.captureException(new Error(msg));
    }

    if (isTimeZoneWrong) {
      const msg = "A user has wrong timeZone:" + moment().format(SDateTimeFormat) + " | " + data.dateTime;
      Sentry.captureException(new Error(msg));
    }
  };
};
