import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../types/AppState";
import "./styles.scss";
import { FirstLevelLink } from "./FirstLevelLink/FirstLevelLink";
import { sitemap, ISitemapEntry, useV2Title } from "../../helpers/sitemap";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import { withRouter, RouteComponentProps, NavLink } from "react-router-dom";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { UserProfileDropdown } from "./UserProfileDropdown/UserProfileDropdown";
import { GotQuestionsBox } from "./GotQuestionsBox/GotQuestionsBox";
import { LiveDemo } from "./LiveDemo/LiveDemo";
import { TrialRemainingDaysInfoBox } from "./TrialRemainingDaysInfoBox/TrialRemainingDaysInfoBox";
import { UpgradeInfoBox } from "./UpgradeInfoBox/UpgradeToPremiumBox";
import { alwaysClockableBranchIdsSelector } from "../../selectors/AlwaysClockableBranchIdsSelector";
import { webUserTimeClockEnabledSelector } from "../../selectors/webUserTimeClockEnabledSelector";
import WhiteZplanoLogo from "../Logos/WhiteZplanoLogo";

const insert = (arr, index, newItem) => [...arr.slice(0, index), newItem, ...arr.slice(index)];

const mapStateToProps = (state: AppState) => ({
  currentUser: selectSessionInfo(state).user,
  users: state.data.users,
  sessionInfo: selectSessionInfo(state),
  userTimeClockEnabled: webUserTimeClockEnabledSelector(state),
  announcements: state.data.announcements,
  isV2: state.data.tenantInfo.isV2,
});

type State = { calendlyModalActive: boolean };
type OwnProps = {} & RouteComponentProps;
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class TopBar extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      calendlyModalActive: false,
    };
  }

  isRouteActive = (route: ISitemapEntry) => {
    return this.props.location.pathname.includes(route.url);
  };

  render() {
    const { isV2, sessionInfo } = this.props;
    const unreadAnnouncements = this.props.announcements.filter((a) => a.hasRead === false);
    const canManage = sessionInfo.hasManagerPermissions();
    const mainRoutes = [sitemap.dashboard, sitemap.shifts, sitemap.absences, sitemap.users, sitemap.settings]
      .filter((s) => !isV2 || s !== sitemap.dashboard) // no dashboard in timetracking
      .map((s) => (isV2 ? useV2Title(s) : s));

    if (isV2 && (this.props.userTimeClockEnabled || canManage)) {
      mainRoutes.splice(1, 0, sitemap.timeClockUser);
    }

    return (
      <div className="topBarMain">
        <div className="firstLevel">
          <div className="wrapper row flex">
            <div className="logoWrapper">
              {/* <NavLink
                to={sitemap.dashboard.url}
                exact={true}
                style={{ display: "flex", alignItems: "center" }}
              > */}
              <WhiteZplanoLogo style={{ width: 100, height: 28 }} />
              {/* </NavLink> */}
            </div>
            <div className="navigationLeft">
              {mainRoutes.map((route) => (
                <FirstLevelLink
                  key={route.url}
                  sitemapEntry={route}
                  isActive={this.isRouteActive(route)}
                  badgeCount={route === sitemap.dashboard ? unreadAnnouncements.length : undefined}
                />
              ))}
              {/* {(this.props.userTimeClockEnabled || canManage) && isV2 && (
                <FirstLevelLink
                  key={sitemap.timeClockUser.title}
                  sitemapEntry={sitemap.timeClockUser}
                  isActive={this.isRouteActive(sitemap.timeClockUser)}
                />
              )} */}
            </div>

            <div className="navigationRight">
              <TrialRemainingDaysInfoBox />
              <GotQuestionsBox />
              <UpgradeInfoBox />
              {this.props.userTimeClockEnabled && !isV2 && (
                <FirstLevelLink
                  key={sitemap.timeClockUser.title}
                  sitemapEntry={sitemap.timeClockUser}
                  isActive={this.isRouteActive(sitemap.timeClockUser)}
                />
              )}
              <FirstLevelLink
                key={sitemap.chat.title}
                sitemapEntry={sitemap.chat}
                isActive={this.isRouteActive(sitemap.chat)}
              />
              {!isV2 && (
                <FirstLevelLink
                  key={sitemap.pushNotes.title}
                  sitemapEntry={sitemap.pushNotes}
                  isActive={this.isRouteActive(sitemap.pushNotes)}
                />
              )}
              <UserProfileDropdown />
              {/* {isDevOrTesting && (
                <div className="testingUserName">{this.props.currentUser.name}</div>
              )} */}
            </div>
            {/* {isV2 && <div className="punchClockV2">Stempeluhr</div>} */}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(TopBar));
