export const setRosterFilter = (rosterFilter?: RosterFilter) => ({
  type: ROSTER_FILTER_SET,
  payload: rosterFilter || initialState,
});

const ROSTER_FILTER_SET = "ROSTER_FILTER_SET";

export type RosterFilter = {
  jobPositions: string[];
  workSpaces: string[];
  addresses: string[];
  hashtags: string[];
  timeSpan: [string, string] | [];
};

const initialState: RosterFilter = {
  jobPositions: [],
  workSpaces: [],
  addresses: [],
  hashtags: [],
  timeSpan: [],
};

export const rosterFilterReducer = (state: RosterFilter = initialState, action: any): RosterFilter => {
  return action.type === ROSTER_FILTER_SET ? action.payload : state;
};
