import { selectRosterSettingsByUser } from "./rosterSettingsByUserSelector";
import { selectSessionInfo } from "./SessionInfoSelector";
import _, { isArray } from "lodash";
import { createSelector } from "reselect";
import { getShiftsOfRow } from "../pages/shiftsPage/RosterPage/helpers";
import { RosterMode } from "../reducers/ui/shifts/roster/rosterMode";
import { IJobPosition } from "../shared/entities/IJobPosition";
import { IShift } from "../shared/entities/IShift";
import { IUser } from "../shared/entities/IUser";
import { AppState } from "../types/AppState";
import { relevantShiftsFilteredSelector, relevantShiftsSelector } from "./RelevantShiftsSelector";
import { selectIsFreshTenant } from "./selectIsFreshTenant";
import { doShiftsOverlap } from "./shiftOverlappingsSelector";
import { selectVisibleUsersOfRoster, selectVisibleUsersOfRosterByJobPosition } from "./visibleUsersOfRosterSelector";
import { selectGroupByJobPosition } from "./groupByJobPositionSelector";

export type IRosterRowData = {
  user?: IUser;
  jobPositionId?: string;
  height: number;
  isJobPositionHeader?: boolean;
  isJobPositionHeaderOpen?: boolean;
  isRequirement?: boolean;
};

const defaultShiftHeight = 46;
const defaultJpLineHeight = 20;
const openShiftsBorderBottom = 14;
const rowBorderBottomWidth = 1;

export const rosterRowDataSelectorEqualityCheck = (a: IRosterRowData[], b: IRosterRowData[]) => {
  return _.isEqual(
    a.map((ai) => ai.height + (ai.user?.id || `${ai.isRequirement}` || ai.jobPositionId || "")),
    b.map((bi) => bi.height + (bi.user?.id || `${bi.isRequirement}` || bi.jobPositionId || ""))
  );
};

export const rosterRowDataSelector = createSelector(
  [
    relevantShiftsFilteredSelector,
    selectVisibleUsersOfRosterByJobPosition,
    selectVisibleUsersOfRoster,
    (state: AppState) => state.ui.selectedBranch as string | null,
    (state: AppState) => state.data.jobPositions,
    (state: AppState) => state.data.tenantInfo,
    selectIsFreshTenant,
    selectSessionInfo,
    selectRosterSettingsByUser,
    (state: AppState) => state.ui.shifts.roster,
    selectGroupByJobPosition,
  ],
  (
    shifts,
    usersByJobPos,
    visibleUsers,
    currentBranchId,
    jobPositions,
    tenantInfo,
    isFreshTenant,
    sessionInfo,
    rosterSettingsByUser,
    rosterUi,
    groupByJobPosition
  ): IRosterRowData[] => {
    const { isV2 } = tenantInfo;
    const { collapsedJobPositions, rosterMode, isOpenShiftsCollapsed, isRequiredShiftsCollapsed } = rosterUi;

    const getShiftsMaxLength = (shifts: IShift[]) => {
      const shiftsOfDay = _.values(_.groupBy(shifts, (s) => s.date));
      let shiftsLengths = shiftsOfDay.length ? shiftsOfDay.map((s) => s.length) : 1;
      if (rosterMode === RosterMode.Day) {
        // In the day view, the shifts can be horizontally next to each other, in case they dont overlap with the time
        const someShiftsOverlap = shifts.some((shift) =>
          shifts.some((_shift) => _shift.id !== shift.id && doShiftsOverlap(shift, _shift))
        );
        if (!someShiftsOverlap) {
          return 1;
        }
      }
      return isArray(shiftsLengths) ? Math.max(...shiftsLengths) : 1;
    };

    const openShifts = isOpenShiftsCollapsed
      ? []
      : getShiftsOfRow(shifts, currentBranchId, undefined, undefined, undefined, sessionInfo.user);
    const requiredShifts = isRequiredShiftsCollapsed
      ? []
      : getShiftsOfRow(shifts, currentBranchId, undefined, undefined, true, sessionInfo.user);

    const openShiftsMaxLength = getShiftsMaxLength(openShifts);
    const requiredShiftsMaxLength = getShiftsMaxLength(requiredShifts);

    const calcUserRowHeight = (u: IUser, jpId?: string) => {
      const shiftsOfRow = getShiftsOfRow(shifts, currentBranchId, u?.id, jpId, undefined, sessionInfo.user);
      const shiftsMaxLength = getShiftsMaxLength(shiftsOfRow);
      return shiftsMaxLength * defaultShiftHeight || defaultShiftHeight;
    };

    const rows: IRosterRowData[] = [];
    const canManage = sessionInfo.hasManagerPermissions();
    const userRosterSetting = rosterSettingsByUser[sessionInfo.user.id];
    const canApplyToOpenShifts = userRosterSetting.usersCanApplyToOpenShifts;
    const canSeeOtherRoles = canManage || !userRosterSetting.usersOfOtherRolesAreHidden;

    // Required Shifts Row
    if (canManage && userRosterSetting.showRequirementRowInRoster && !isV2) {
      rows.push({ isRequirement: true, height: (requiredShiftsMaxLength || 1) * defaultShiftHeight + 1 });
    }

    // Open Shifts Row
    if ((canManage || canApplyToOpenShifts) && !isV2) {
      rows.push({
        isJobPositionHeader: false,
        height:
          (openShiftsMaxLength * defaultShiftHeight || defaultShiftHeight) +
          (!groupByJobPosition ? openShiftsBorderBottom : 0),
      });
    }

    if (groupByJobPosition) {
      const orderedJobPositions = _.orderBy(jobPositions, ["sortIndex"]);
      orderedJobPositions
        .filter((jp) => canSeeOtherRoles || sessionInfo.user.jobPositionIds.includes(jp.id))
        .forEach((jp) => {
          if (!usersByJobPos[jp.id].length && (jp.isInactive || !isFreshTenant)) {
            return;
          }

          const isJobPositionHeaderOpen = !collapsedJobPositions.includes(jp.id);

          rows.push({
            isJobPositionHeader: true,
            jobPositionId: jp.id,
            height: defaultJpLineHeight,
            isJobPositionHeaderOpen,
          });
          if (usersByJobPos[jp.id].length && isJobPositionHeaderOpen) {
            usersByJobPos[jp.id].forEach((u, i) => {
              rows.push({
                jobPositionId: jp.id,
                height: calcUserRowHeight(u, jp.id) + rowBorderBottomWidth,
                user: u,
              });
            });
          }
        });
    } else {
      visibleUsers.forEach((u) => {
        rows.push({
          height: calcUserRowHeight(u) + rowBorderBottomWidth,
          user: u,
        });
      });
    }

    return rows;
  }
);
