import React from "react";
import { useDispatch } from "react-redux";
import { Badge, Icon } from "antd";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { ISitemapEntry, sitemap } from "../../../helpers/sitemap";
import "./styles.scss";
import cn from "classnames";
import { useSelector } from "../../../helpers/redux";
import { push } from "connected-react-router";

type Props = {
  isActive: boolean;
  sitemapEntry: ISitemapEntry;
  notificationsCount?: number;
  badgeCount?: number;
};

export const FirstLevelLink = (props: Props) => {
  const user = useSelector(selectSessionInfo).user;
  const dispatch = useDispatch();
  const { sitemapEntry, isActive } = props;

  if (!sitemapEntry.hasRequiredRights(user)) {
    return null;
  }

  return (
    <div key={sitemapEntry.title} className="linkWrapper">
      <div
        id={`topbar-route-${sitemapEntry.title}`}
        onClick={() => dispatch(push(sitemapEntry.url))}
        data-rh={sitemapEntry.title}
        className={cn({
          firstLevelLink: true,
          isActive: isActive,
        })}
      >
        <Badge count={props.badgeCount} style={{ marginTop: 5, color: "white", boxShadow: "none" }}>
          {props.sitemapEntry.renderIcon ? (
            props.sitemapEntry.renderIcon(isActive)
          ) : (
            <div className="topbarIconWrapper">
              <div className={cn({ topbarIcon: true })} style={{ padding: 2, marginTop: 4 }}>
                <Icon type={props.sitemapEntry.iconName} style={{ fontSize: 22 }} />
              </div>
            </div>
          )}
        </Badge>
      </div>
      <div className="seperationLine"></div>
    </div>
  );
};
