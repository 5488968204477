import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import Table from "../../../../components/Table/Table";
import { RowInfo, Column } from "react-table-v6";
import { AbsenceCalendarPageState, AbsenceRow, getAbsenceStatusLabel } from "../../helprs";
import moment from "moment";
import AbsenceListPageActionBar from "../AbsenceListPageActionBar/AbsenceListPageActionBar";
import "./styles.scss";
import { openModal } from "../../../../actions/modal";
import AbsenceModal from "../../../../components/modals/AbsenceModal/AbsenceModal";
import _ from "lodash";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { getTotalDaysCount } from "../../../../components/modals/AbsenceModal/localHelpers";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { withErrorBoundary } from "../../../../components/ErrorBoundary/ErrorBoundary";
import {
  selectUsersWithSharedBranch,
  usersHaveIntersectingBranch,
} from "../../../../selectors/UsersWithSharedBranchSelector";
import { Icon } from "antd";
import { IAbsence, AbsenceStatus, EffectiveAbsenceDays } from "../../../../shared/entities/IAbsence";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import { selectAbsencesExtended } from "../../../../selectors/absencesExtendedSelector";
import { getUserName } from "../../../../shared/helpers/userHelpers";
import { RoleType } from "../../../../shared/entities/IUser";
import { exportAbsenceList } from "../../../../actions/absenceList";
import { paidFeatureWarning } from "../../../../actions/paidFeatureWarning";
import { featuresSelector } from "../../../../selectors/FeaturesSelector";
import { rangesOverlap } from "../../../../shared/helpers/dateHelpers";
import { getEffectiveDaysByYear, getEffectiveDaysOfAbsence } from "../../../../shared/helpers/absences";
import { selectHolidayFinder } from "../../../../selectors/holidayMapSelector";
import { selectContractsByUser } from "../../../../selectors/contractsByUserSelector";
import { toMoment } from "../../../../shared/helpers/timeHelpers";
import { localizeFormat } from "../../../../helpers/dateFormatHelper";
import { selectUsersFull } from "../../../../selectors/usersFullSelector";
import { selectUserFullMap } from "../../../../selectors/userFullMapSelector";
import { absenceRepository } from "../../../../repositories/absenceRepository";
import { exportAbsenceTableCsv, exportAbsenceTableExcel } from "../../../../actions/absenceTableCsvExport";
import { exportAbsenceTablePdf } from "../../../../actions/absenceTablePdfExport";
import { selectUserDetailMap } from "../../../../selectors/mapSelectors";

const mapStateToProps = (state: AppState) => ({
  users: selectUsersFull(state),
  userDetailMap: selectUserDetailMap(state),
  userDetails: state.data.userDetails,
  usersMap: selectUserFullMap(state),
  absences: selectAbsencesExtended(state),
  abseneTypes: state.data.absenceTypes,
  canManaga: selectSessionInfo(state).hasManagerPermissions(),
  usersWithSharedBranch: selectUsersWithSharedBranch(state),
  sessionInfo: selectSessionInfo(state),
  filters: state.ui.filters.absenceCalendar,
  holidayFinder: selectHolidayFinder(state),
  contractsByUser: selectContractsByUser(state),
});

type OwnProps = {};
type State = AbsenceCalendarPageState;
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _AbsenceListSummed extends React.PureComponent<Props, State> {
  showEmployeeNum: boolean;
  sortKey?: string;
  sortType?: "asc" | "desc";

  constructor(props: Props) {
    super(props);

    this.showEmployeeNum = props.userDetails.filter((u) => u.employNum).length >= 2;

    this.state = {
      branchFilter: props.filters.branchFilter,
      jobPositionFilter: props.filters.positionFilter,
      startDate: moment().startOf("year").format(SDateFormat),
      endDate: moment().endOf("year").format(SDateFormat),
    };
  }

  componentDidMount = async () => {
    await this.props.dispatch(absenceRepository.fetchMany());
  };

  getAbsencesDaysMatrix = (userIds: string[]) => {
    const { holidayFinder, contractsByUser } = this.props;
    const dates = { fromDate: this.state.startDate, untilDate: this.state.endDate };
    const dataMatrix = {};
    userIds.forEach((uid) => {
      dataMatrix[uid] = {};
      this.props.abseneTypes.forEach((a) => (dataMatrix[uid][a.id] = 0));
    });

    this.props.absences
      .filter((a) => a.startDate <= this.state.endDate && a.endDate)
      .forEach((a) => {
        // dataMatrix[a.userId][a.typeId]
        if (dataMatrix[a.userId]) {
          const days = getEffectiveDaysOfAbsence(a, holidayFinder, contractsByUser[a.userId], dates);
          dataMatrix[a.userId][a.typeId] = (dataMatrix[a.userId][a.typeId] || 0) + days;
        }
      });
    return dataMatrix;
  };

  getVisibleUserIds = () => {
    const { users, sessionInfo } = this.props;
    const { branchFilter, jobPositionFilter, startDate } = this.state;
    const managingUsers = sessionInfo.isAdmin()
      ? users
      : users.filter((u) => usersHaveIntersectingBranch(u, sessionInfo.user));
    const activeManagingUsers = managingUsers.filter(
      (u) => !u.isDeleted && (!u.lastWorkDay || u.lastWorkDay >= startDate)
    );
    const activeManagingUsersFiltered = activeManagingUsers.filter(
      (u) =>
        (!branchFilter || u.branchIds.includes(branchFilter)) &&
        (!jobPositionFilter || u.jobPositionIds.includes(jobPositionFilter))
    );

    return _.orderBy(activeManagingUsersFiltered, (u) => u.name).map((u) => u.id);
  };

  generateAbsenceList = (): Array<{}> => {
    const { usersMap, userDetailMap } = this.props;
    const absenceDaysMatrix = this.getAbsencesDaysMatrix(this.getVisibleUserIds()) as any;
    const absenceDaysList = Object.entries(absenceDaysMatrix).map(([uid, data]) =>
      this.showEmployeeNum
        ? { userName: usersMap[uid].name, employNum: userDetailMap[uid]?.employNum || "", ...(data as any) }
        : { userName: usersMap[uid].name, ...(data as any) }
    );

    return absenceDaysList;
  };

  sort = (entries: Array<{}>): Array<{}> => {
    return this.sortKey ? _.orderBy(entries, this.sortKey!, this.sortType) : entries;
  };

  updateFilterSettings = (settings: Partial<State>) => {
    this.setState(settings as State);
  };

  getTableColumns = (): Column[] => {
    return [
      {
        Header: lg.mitarbeiter,
        accessor: "userName",
      },
      {
        Header: lg.personalnummer,
        accessor: "employNum",
        isHidden: !this.showEmployeeNum,
      },
      ...this.props.abseneTypes.map((a) => ({
        Header: a.name,
        accessor: a.id,
        Cell: (props) => (
          <div className="" style={{ color: props.value === 0 ? "#d3d3d3" : "#2f3036" }}>
            {props.value}
          </div>
        ),
      })),
    ].filter((u) => !(u as any).isHidden);
  };

  render() {
    const absenceList = this.generateAbsenceList();
    const tableColumns = this.getTableColumns();

    return (
      <div className="absenceListPage">
        <div className="content">
          <div className="actionBar">
            <AbsenceListPageActionBar
              type="summed"
              settings={this.state}
              updateSettings={this.updateFilterSettings}
              exportPdf={() =>
                this.props.dispatch(
                  exportAbsenceTablePdf(this.sort(absenceList), tableColumns, this.state.startDate, this.state.endDate)
                )
              }
              exportCsv={() =>
                exportAbsenceTableCsv(this.sort(absenceList), tableColumns, this.state.startDate, this.state.endDate)
              }
              exportExcel={() =>
                exportAbsenceTableExcel(this.sort(absenceList), tableColumns, this.state.startDate, this.state.endDate)
              }
            />
          </div>
          <div className="tableWrapper">
            <Table
              data={absenceList}
              columns={tableColumns}
              showPagination={false}
              pageSize={absenceList.length}
              NoDataComponent={() => <div className="noData">{lg.keine_daten_im_gewählten_zeitraum_vorhanden}</div>}
              onSortedChange={(sort: any) => {
                this.sortKey = sort[0]?.id;
                this.sortType = sort[0]?.desc === true ? "desc" : "asc";
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const ConnectedComp = connect<StoreProps, DispatchBaseProps, StoreProps, AppState>(mapStateToProps)(_AbsenceListSummed);
export const AbsenceListSummed = withErrorBoundary(ConnectedComp);
