import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../../types/AppState";
import { DispatchBaseProps } from "../../../../../../frontend-core/types/DispatchBaseProps";
import { Button, Icon, Select, Popover } from "antd";
import "./styles.scss";
import _ from "lodash";
import { BasicSelect } from "../../../../../../components/BasicSelect/BasicSelect";
import moment from "moment";
import ButtonGroup from "antd/lib/button/button-group";
import {
  getMonthsArray,
  getYearsArray,
} from "../../../../../absences/AbsenceCalendarPage/AbsencesActionBar/localHelpers";
import { toSimpleDate, toMomentUnsafe, toMoment } from "../../../../../../shared/helpers/timeHelpers";
import { selectMonth } from "../../../../../../reducers/ui/shifts/roster/selectedMonth";
import { updateSelectedDate } from "../helpers";
import cn from "classnames";
import { selectCustomMonthStartDay } from "../../../../../../reducers/ui/general/rosterMonthStartDay";
import { selectSessionInfo } from "../../../../../../selectors/SessionInfoSelector";
import { SDateFormat } from "../../../../../../shared/helpers/SimpleTime";
import { addSimpleDays } from "../../../../../../shared/helpers/dateHelpers";

const mapStateToProps = (state: AppState) => {
  return {
    selectedMonthFirstDay: state.ui.shifts.roster.selectedMonth,
    customMonthStartDay: state.ui.general.rosterCustomMonthStartDay, // this is rarely used if the user wants the month view to go from e.g: 16.SEP > 15.NOV
    rosterStetings: state.data.rosterSettings[0],
    sessionInfo: selectSessionInfo(state),
    isV2: state.data.tenantInfo.isV2,
  };
};

type State = {
  isDayPickerOpen: boolean;
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _MonthStepper extends React.PureComponent<Props, State> {
  today: string;
  constructor(props: Props) {
    super(props);
    this.today = moment().format(SDateFormat);
    this.state = {
      isDayPickerOpen: false,
    };
  }

  changeYear = (year: number) => {
    const { dispatch, selectedMonthFirstDay } = this.props;
    const newDate = toSimpleDate(moment(selectedMonthFirstDay).year(year));
    dispatch(selectMonth(newDate));
  };

  changeMonth = (month: number) => {
    const { selectedMonthFirstDay } = this.props;
    const newDate = toSimpleDate(toMoment(selectedMonthFirstDay).month(month));
    this.props.dispatch(updateSelectedDate(newDate));
  };

  monthStep = (step: number) => {
    const { selectedMonthFirstDay } = this.props;
    const newDate = toSimpleDate(toMoment(selectedMonthFirstDay).add(step, "month"));
    this.props.dispatch(updateSelectedDate(newDate));
  };

  getIntervalPickerPopover = () => {
    if (!this.state.isDayPickerOpen) {
      return null;
    }

    const selectedDay = this.props.customMonthStartDay || 1;

    return (
      <div className="intervalStartDayBoxWrapper">
        {[...Array(31).keys()].map((i) => {
          const day = i + 1;
          return (
            <div
              key={day}
              className={cn({
                dayBox: true,
                selected: day === selectedDay,
                disabled: day > 28,
              })}
              onClick={() => {
                this.props.dispatch(selectCustomMonthStartDay(day));
                this.setState({ isDayPickerOpen: false });
              }}
            >
              {day}
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const { selectedMonthFirstDay, rosterStetings, isV2 } = this.props;
    const canManage = this.props.sessionInfo.hasManagerPermissions();

    const showIntervalStartPicker =
      canManage && (rosterStetings.allowCustomMonthInterval || rosterStetings.monthlyRosterCanChangeMonthStart);

    //[...Array(31).keys()]

    const canStepForward = !isV2 || addSimpleDays(selectedMonthFirstDay, 27) <= this.today;

    return (
      <div className="fb row monthStepperMain ">
        <div className="fb yearSelect">
          <BasicSelect
            id="absence-bar-year-select"
            value={moment(selectedMonthFirstDay).year()}
            onChange={this.changeYear}
            options={getYearsArray().map((y) => ({ value: y, label: y }))}
            style={{ width: 90 }}
            keepOrder
          />
        </div>
        {showIntervalStartPicker && (
          <Popover
            content={this.getIntervalPickerPopover()}
            title={null}
            trigger="click"
            visible={this.state.isDayPickerOpen}
            onVisibleChange={(isOpen) => this.setState({ isDayPickerOpen: isOpen })}
          >
            <div className="intervalStartDayWrapper" data-rh={lg.starttag_des_monatsintervalls}>
              {this.props.customMonthStartDay + "."}
            </div>
          </Popover>
        )}
        <div className="fb monthSelect">
          <BasicSelect
            id="absence-bar-month-select"
            value={moment(selectedMonthFirstDay).month()}
            onChange={this.changeMonth}
            options={getMonthsArray().map((m, i) => ({ value: i, label: m }))}
            keepOrder
          />
        </div>
        <span className="arrow-icons">
          <ButtonGroup>
            <Button id="roster-prev-week" onClick={() => this.monthStep(-1)}>
              <Icon type="left" />
            </Button>
            <Button id="roster-next-week" onClick={() => this.monthStep(1)} disabled={!canStepForward}>
              <Icon type="right" />
            </Button>
          </ButtonGroup>
        </span>
      </div>
    );
  }
}

export const MonthStepper = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(_MonthStepper);
