import { selectAbsencesExtended } from "./absencesExtendedSelector";
import { AbsenceTypeCode } from "../shared/entities/IAbsenceType";
import { AbsenceStatus, IAbsenceExtended } from "../shared/entities/IAbsence";
import { AppState } from "../types/AppState";
import { createSelector } from "reselect";

export const getAbsenceDaysSumByUser = (
  absences: IAbsenceExtended[],
  year: number,
  status: AbsenceStatus,
  typeId: string | undefined
): { [userId: string]: number } => {
  const daysByUser: { [userId: string]: number } = {};
  absences
    .filter((a) => (typeId ? a.typeId === typeId : a.typeCode === AbsenceTypeCode.vacation))
    .filter((a) => a.status === status)
    .filter((a) => !!a.effectiveDays[year])
    .forEach((a) => {
      daysByUser[a.userId] = daysByUser[a.userId] || 0; // initialize with 0
      daysByUser[a.userId] += a.effectiveDays[year] || 0;
    });
  return daysByUser;
};

const year = (state: AppState) => state.ui.absences.selectedYear;
const selectedTypeId = (state: AppState) => state.ui.absences.selectedTypeId;

export const absenceDaysSumSelector = createSelector(
  [selectAbsencesExtended, year, selectedTypeId],
  (absences, year, absenceTypeId) => {
    return getAbsenceDaysSumByUser(absences, year, AbsenceStatus.active, absenceTypeId);
  }
);

export const absenceDaysPrevYearSumSelector = createSelector(
  [selectAbsencesExtended, year, selectedTypeId],
  (absences, year, absenceTypeId) => {
    return getAbsenceDaysSumByUser(absences, year - 1, AbsenceStatus.active, absenceTypeId);
  }
);
