import { AnyAction } from "redux";
import { DispFn } from "./../../../../frontend-core/types/thunkTypes";
import { rosterWillRerender } from "./rosterWillRerender";

// dispatches an initial rerender action to show a loading bar, before rerendering the whole dom
// ( which leads to a freeze after the click and bad user experience )
export const withRosterLoading = (action: AnyAction) => (dispatch: DispFn) => {
  dispatch(rosterWillRerender());
  setTimeout(() => {
    dispatch(action);
  }, 20);
};
