import { DispFn } from "./../../../../frontend-core/types/thunkTypes";
import { withRosterLoading } from "./rosterReducerHelpers";
import { Dispatch } from "redux";
import { toSimpleDate } from "../../../../shared/helpers/timeHelpers";
import moment from "moment";

export const SHIFTS_ROSTER_Week_SELECT = "SHIFTS_ROSTER_Week_SELECT";

export const selectWeek = (date: string) =>
  withRosterLoading({
    type: SHIFTS_ROSTER_Week_SELECT,
    payload: date,
  });

const initialState = toSimpleDate(moment().isoWeekday(1));

export const selectedWeekReducer = (state = initialState, action: any): string =>
  action.type === SHIFTS_ROSTER_Week_SELECT ? action.payload : state;
