import { Icon } from "antd";
import React from "react";
import { useSelector } from "../../helpers/redux";
import "./styles.scss";

type Props = {};

export const TimeZoneWarning = ({}: Props) => {
  const wrongDateTime = useSelector((s) => s.ui.wrongTimeZone);

  return (
    <div className="wrongTimeZoneBar">
      <div className="warningIcon">
        <Icon type="warning" />
      </div>
      <div className="mainText">
        {lg.zeit_oder_zeitzone_stimmt_nicht_mit_der_kontozeit_überein + ": " + wrongDateTime}
      </div>
      <div className="extraText">{lg.bitte_überprüfen_sie_das_eingestellte_datum_und_die_uhrzeit_ihres_gerätes}</div>
    </div>
  );
};
