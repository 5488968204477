import React from "react";
import { Select, Icon, Divider } from "antd";
import { SelectProps } from "antd/lib/select";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";
import { pastelColors } from "../../../../frontend-core/helpers/colors";
import { closeModal, openModal } from "../../../../actions/modal";
import { sitemap } from "../../../../helpers/sitemap";
import { push } from "connected-react-router";
import _ from "lodash";
import { antDesignSelectFilterSearch, orderByName } from "../../../../frontend-core/helpers/frontendHelpers";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { selectHashtags } from "../../../../selectors/hashtagSelector";
import { IHashtag } from "../../../../shared/entities/IWorkSpace";

const mapStateToProps = (state: AppState) => {
  return {
    hashtags: selectHashtags(state),
    canManage: selectSessionInfo(state).hasManagerPermissions(),
  };
};

type State = {};
type OwnProps = {
  onChange: (value?: string[]) => void;
  selectedIds?: string[];
  closeField: () => void;
  isDisplayOnly?: boolean;
} & SelectProps<any>;

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _HashtagSelectField extends React.PureComponent<Props, {}> {
  renderDisplayOnly = (hashtags: IHashtag[]) => (
    <div className="row fb aCenter rectBox">
      {hashtags.map((hashtag) => (
        <div className="displayHashtag">{hashtag.name}</div>
      ))}
    </div>
  );

  render() {
    const { selectedIds, onChange, hashtags, isDisplayOnly, canManage, ...otherProps } = this.props;
    const selectableHashtags = orderByName(hashtags).filter((ws) => !ws.isInactive);
    const selectedHashtags = selectedIds?.map((id) => hashtags.find((hashtag) => hashtag.id === id)!) || [];

    selectedHashtags.forEach((hashtag) => {
      hashtag.isInactive && selectableHashtags.push(hashtag); // need to make sure inactive WS is among options, if its selected
    });

    return (
      <div className="avInputFieldMain">
        <span className="labelWrapper">
          <label>Hashtags</label>
        </span>
        <div className="hashtagsSelectFieldMain">
          {isDisplayOnly ? (
            selectedHashtags && this.renderDisplayOnly(selectedHashtags)
          ) : (
            <Select
              id="shift-hashtags-select"
              value={selectedIds}
              onChange={(value: string[]) => this.props.onChange(value)}
              style={{ width: "100%" }}
              showSearch={selectableHashtags.length > 6}
              autoFocus={canManage && !selectedIds} // autofocus only when expanding
              showAction={["focus", "click"]} // needed for autofocus to type in for search
              filterOption={antDesignSelectFilterSearch}
              mode="multiple"
              {...otherProps}
            >
              {selectableHashtags.map((ad) => (
                <Select.Option value={ad.id} key={ad.id} title={ad.name}>
                  {ad.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
        {!isDisplayOnly && (
          <div
            className="closeExpandedFieldButton"
            onClick={() => {
              this.props.onChange(undefined);
              this.props.closeField();
            }}
          >
            <Icon type="close" />
          </div>
        )}
      </div>
    );
  }
}

export const HashtagSelectField = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(_HashtagSelectField);
