import React from 'react';
import { FormikProps } from 'formik';
import { Input, Form } from 'antd';
import './styles.scss';
import { TextAreaProps } from 'antd/lib/input';
import AvFormField from '../AvFormField/AvFormField';

type Props = {
  formikProps: FormikProps<any>;
  fieldName: string;
  noErrorMessage?: boolean; // to hide the error-message-div
  label?: string;
} & TextAreaProps;

export default class AvFormikTextArea extends React.PureComponent<Props, {}> {
  render() {
    const { fieldName, formikProps, label, noErrorMessage, ...otherProps } = this.props; // fieldName is causing issues when passing along to <input>
    const isTouched = formikProps.touched[fieldName];
    const error: string | undefined | false =
      isTouched && (formikProps.errors[fieldName] as any);

    return (
      <AvFormField label={label} errorMessage={!noErrorMessage && error}>
        <Form.Item
          style={{ marginBottom: 0 }}
          validateStatus={error ? 'error' : undefined}
          hasFeedback={true}
        >
          <Input.TextArea
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            value={formikProps.values[fieldName]}
            name={fieldName}
            {...otherProps}
          />
        </Form.Item>
      </AvFormField>
    );
  }
}
