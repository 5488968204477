import React from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../helpers/redux";
import { selectUserMap } from "../../../selectors/mapSelectors";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { Icon } from "antd";
import cn from "classnames";
import "./styles.scss";

type Props = {
  isOpen: boolean;
  count: number;
  style?: React.CSSProperties;
};

export const CountToggler = React.memo((props: Props) => {
  if (!props.count) {
    return null;
  }

  return (
    <div className="countTogglerMain" style={props.style || {}}>
      <div className={cn({ count: true, isActive: !!props.count })}>{props.count}</div>
      {props.count !== 0 && (
        <div className="iconWrapper">{props.isOpen ? <Icon type="down" /> : <Icon type="right" />}</div>
      )}
    </div>
  );
});
