import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { IShift } from "../../../../shared/entities/IShift";
import { IAbsence } from "../../../../shared/entities/IAbsence";
import moment from "moment";
import { Icon } from "antd";
import { closeModal, openModal } from "../../../../actions/modal";
import AbsenceModal from "../../../modals/AbsenceModal/AbsenceModal";
import { featuresSelector } from "../../../../selectors/FeaturesSelector";
import { paidFeatureWarning } from "../../../../actions/paidFeatureWarning";
import "./styles.scss";
import { selectAbsenceTypeMap } from "../../../../selectors/absenceTypeMapSelector";

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  return {
    features: featuresSelector(state),
    absenceTypeMap: selectAbsenceTypeMap(state),
  };
};

type OwnProps = {
  absence: IAbsence;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _ShiftPopupAbsenceRow extends React.PureComponent<Props> {
  absenceClicked = () => {
    if (!this.props.features.absences) {
      this.props.dispatch(paidFeatureWarning());
      return;
    }

    const absence = this.props.absence!;
    const userId = absence.userId;
    this.props.dispatch(closeModal());
    setTimeout(() => {
      this.props.dispatch(openModal(AbsenceModal, { absence, userId }));
    }, 100);
  };

  render() {
    const { absence, absenceTypeMap } = this.props;
    const absenceType = absenceTypeMap[absence.typeId];
    const startDate = moment(absence.startDate).format("DD. MMMM");
    const endDate = moment(absence.endDate).format("DD. MMMM");
    const absenceText = `${startDate} > ${endDate}`;

    return (
      <div className="shiftPopupAbsenceRowMain">
        <div className="label"></div>
        <div className="content" onClick={this.absenceClicked}>
          <div className="absenceType">{absenceType.name}</div>
          <div className="absenceDetails">{absenceText}</div>
          <div className="openAbsenceBtn">
            {lg.zur_abwesenheit} <Icon className="arrowIcon" type="arrow-right" />
          </div>
        </div>
      </div>
    );
  }
}

export const ShiftPopupAbsenceRow = connect(mapStateToProps)(_ShiftPopupAbsenceRow);
