// dd
import React from "react";
import { TopBarAbsenceIcon } from "../components/TopBar/TopBarIcons/TobbarAbsenceIcon";
import { TopBarDashboardIcon } from "../components/TopBar/TopBarIcons/TopbarDashboardIcon";
import { TopBarPushNotesIcon } from "../components/TopBar/TopBarIcons/TopBarPushNotesIcon";
import { TopBarShiftplanIcon } from "../components/TopBar/TopBarIcons/TopbarShiftplanIcon";
import { TopBarTimeClockIcon } from "../components/TopBar/TopBarIcons/TopBarTimeClockIcon";
import { TopBarChatIcon } from "../components/TopBar/TopBarIcons/TopBarChatIcon";
import { IUser, RoleType } from "../shared/entities/IUser";
import { AppState } from "../types/AppState";

const hasAdminRights = (user: IUser) => user.role === RoleType.admin;
const hasManagerRights = (user: IUser) => user.role === RoleType.admin || user.role === RoleType.manager;
const hasEmployeeRights = (user: IUser) => true;

export interface ISitemap {
  [key: string]: ISitemapEntry;
}

export interface ISitemapEntry {
  url: string;
  title: string;
  titleV2?: string;
  hasRequiredRights: (user: IUser) => boolean;
  iconName?: string;
  renderIcon?: (isActive: boolean) => React.ReactNode;
}

/**
 * everything below this url segment needs an access token
 */
export const secureUrlPrefix = "app";

/**
 * just needed to provide better intelli sense for sitemap keys
 */
function asSitemap<T extends ISitemap>(sitemap: T): T {
  return sitemap;
}

export const useV2Title = (l: ISitemapEntry): ISitemapEntry => ({ ...l, title: l.titleV2 || l.title });

export const sitemap = asSitemap({
  setupWizard: {
    url: "/setup/",
    title: lg.setup,
    hasRequiredRights: hasEmployeeRights,
  },
  app: {
    url: "/app/",
    title: lg.app,
    hasRequiredRights: hasEmployeeRights,
  },
  chat: {
    url: "/app/chat",
    title: lg.chat,
    hasRequiredRights: hasEmployeeRights,
    renderIcon: (isActive) => <TopBarChatIcon isIconActive={isActive} />,
  },
  pushNotes: {
    url: "/app/push-notes",
    title: lg.benachrichtigungen,
    hasRequiredRights: hasEmployeeRights,
    renderIcon: (isActive) => <TopBarPushNotesIcon isIconActive={isActive} />,
  },
  dashboard: {
    url: "/app/dashboard",
    title: lg.dashboard,
    hasRequiredRights: hasEmployeeRights,
    renderIcon: (isActive) => <TopBarDashboardIcon isIconActive={isActive} />,
  },
  help: {
    url: "/app/help",
    title: "Hilfe",
    hasRequiredRights: hasEmployeeRights,
  },
  mobileDownload: {
    url: "/mobile-app-download",
    title: lg.zum_app_download,
    hasRequiredRights: hasEmployeeRights,
  },
  absences: {
    url: "/app/absences",
    title: lg.abwesenheiten,
    hasRequiredRights: hasEmployeeRights,
    renderIcon: (isActive) => <TopBarAbsenceIcon isIconActive={isActive} />,
  },
  shifts: {
    url: "/app/shifts",
    title: lg.dienstplanung,
    titleV2: lg.arbeitszeiten,
    hasRequiredRights: hasEmployeeRights,
    renderIcon: (isActive) => <TopBarShiftplanIcon isIconActive={isActive} />,
  },
  // timeTracking: {
  //   url: '/app/time-tracking'
  // },
  timeClock: {
    url: "/app/time-clock",
    title: lg.stempeluhr,
    hasRequiredRights: hasManagerRights,
  },
  timeClockUser: {
    url: "/app/time-clock-user",
    title: lg.stempeluhr,
    hasRequiredRights: hasEmployeeRights,
    renderIcon: (isActive) => <TopBarTimeClockIcon isIconActive={isActive} />,
  },
  settings: {
    url: "/app/settings",
    title: lg.einstellungen,
    hasRequiredRights: hasAdminRights,
    iconName: "setting",
  },
  users: {
    url: "/app/users",
    title: lg.mitarbeiter_plural,
    iconName: "team",
    hasRequiredRights: (user) => user.role === RoleType.admin || !!user.canManageEmployees,
  },
  billingSettings: {
    url: "/app/settings/billing",
    title: lg.abrechnung,
    hasRequiredRights: hasAdminRights,
  },
  rosterSettings: {
    url: "/app/settings/roster",
    title: lg.dienstplanung,
    titleV2: lg.allgemein,
    hasRequiredRights: hasAdminRights,
  },
  absenceSettings: {
    url: "/app/settings/absences",
    title: lg.abwesenheiten,
    hasRequiredRights: hasAdminRights,
  },
  timeClockSettings: {
    url: "/app/settings/time-clock",
    title: lg.zeiterfassung,
    hasRequiredRights: hasAdminRights,
  },
  chatSettings: {
    url: "/app/settings/chat",
    title: lg.chat,
    hasRequiredRights: hasAdminRights,
  },
  announcementSettings: {
    url: "/app/settings/announcements",
    title: lg.ansagen,
    hasRequiredRights: hasAdminRights,
  },
  absenceSettingsAbsenceTypes: {
    url: "/app/settings/absences/types",
    title: lg.abwesenheitstypen,
    hasRequiredRights: hasAdminRights,
  },
  jobPositionSettings: {
    url: "/app/settings/job-positions",
    title: lg.rollen,
    hasRequiredRights: hasAdminRights,
  },
  holidaySettings: {
    url: "/app/settings/holidays",
    title: lg.feiertage,
    hasRequiredRights: hasAdminRights,
  },
  branchList: {
    url: "/app/settings/branches",
    title: lg.standorte,
    hasRequiredRights: hasAdminRights,
  },
  account: {
    url: "/account",
    title: lg.account,
    hasRequiredRights: hasEmployeeRights,
  },
  login: {
    url: "/account/login",
    title: lg.login,
    hasRequiredRights: hasEmployeeRights,
  },
  noMobileAdmin: {
    url: "/account/no-mobile-admin",
    title: lg.mobiler_zugriff,
    hasRequiredRights: hasEmployeeRights,
  },
  noMobile: {
    url: "/account/no-mobile",
    title: lg.mobiler_zugriff,
    hasRequiredRights: hasEmployeeRights,
  },
  register: {
    // only used in dev and testing. In production we just use the static website-version
    url: "/account/register",
    title: "Register",
    hasRequiredRights: hasEmployeeRights,
  },
  absenceCalendar: {
    url: "/app/absences/calendar",
    title: lg.kalender,
    hasRequiredRights: hasEmployeeRights,
  },
  absenceList: {
    url: "/app/absences/list",
    title: lg.liste,
    hasRequiredRights: hasEmployeeRights,
  },
  absenceTable: {
    url: "/app/absences/table",
    title: lg.tabelle,
    hasRequiredRights: hasEmployeeRights,
  },
  absenceEntitlement: {
    url: "/app/absences/absence-entitlement",
    title: lg.urlaubskonto,
    hasRequiredRights: hasManagerRights,
  },
  shiftsRoster: {
    url: "/app/shifts/roster",
    title: lg.schichtplan,
    titleV2: lg.kalender,
    hasRequiredRights: hasEmployeeRights,
  },
  hourAccounts: {
    url: "/app/shifts/hour-accounts",
    title: lg.stundenkonto_subbar,
    hasRequiredRights: hasEmployeeRights,
  },
  shiftLogs: {
    url: "/app/shifts/logs",
    title: lg.schichtenlog,
    hasRequiredRights: hasManagerRights,
  },
  shiftsReports: {
    url: "/app/shifts/reports",
    title: lg.auswertung,
    hasRequiredRights: hasEmployeeRights,
  },
  availabilities: {
    url: "/app/shifts/availabilities",
    title: lg.verfügbarkeit,
    hasRequiredRights: hasEmployeeRights,
  },
  myProfile: {
    url: "/app/my-profile",
    title: lg.mein_profil,
    hasRequiredRights: hasEmployeeRights,
  },
  notifications: {
    url: "/app/notifications",
    title: lg.benachrichtigungen,
    hasRequiredRights: hasEmployeeRights,
  },
  passwordReset: {
    url: "/password-reset",
    title: lg.passwort_wiederherstellen,
    hasRequiredRights: hasEmployeeRights,
  },
  activation: {
    url: "/registration",
    title: lg.registrierung,
    hasRequiredRights: hasEmployeeRights,
  },
  noWebAccess: {
    url: "/employee-no-web-access",
    title: lg.kein_zugriff_auf_die_web_version,
    hasRequiredRights: hasEmployeeRights,
  },
});
