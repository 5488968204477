import React from "react";
import "../styles.scss";
import { ICreditCorrection } from "../../../../shared/entities/ICreditCorrection";
import { REPORT_COL_STYLE } from "./ShiftReport";
import { IShift } from "../../../../shared/entities/IShift";
import { IAbsence } from "../../../../shared/entities/IAbsence";
import {
  toMomentUnsafe,
  minutesToDuration,
  minutesToTTime,
  minutesToHoursStr,
} from "../../../../shared/helpers/timeHelpers";
import { Icon } from "antd";
import cn from "classnames";
import { ReportEntryType } from "../reportHelpers";
import { IUserDetail } from "../../../../shared/entities/IUserDetail";
import { ReportColumns } from "../../../../actions/reporting";
import { ITracking } from "../../../../shared/entities/ITracking";
import { ITimeClocking } from "../../../../shared/entities/ITimeClocking";
import _ from "lodash";
import { numberToEuroPrice } from "../../../../frontend-core/helpers/frontendHelpers";

type Props = {
  shift?: IShift;
  clocking?: ITimeClocking;
  tracking?: ITracking;
  staffNumber?: string;
  absence?: IAbsence;
  type: any;
  creditCorrection?: ICreditCorrection;
  reportColumns: ReportColumns;
  absenceTypeMap: any;
  duration: any;
  date: any;
  userName: any;
  absenceTypeId: any;
  jobPositionName: any;
  trackedTime: any;
  breakIntervalls?: string;
  wage?: number;
  isMultiBranch: any;
  branchName: any;
  addressName?: string;
  workSpaceName: any;
  hashtags?: string;
  comment: any;
  isTrackingAccepted: any;
  isLoadingTracking: any;
  reportShowDecimals: boolean;
  style: any;
  hasManagerPermissions: boolean;
  isV2: boolean;
  /// surcharges
  nightSurcharge?: number;
  holidaySurcharge?: number;
  sundaySurcharge?: number;
  customSurcharge?: number;
};

export class ReportRow extends React.PureComponent<Props> {
  render() {
    const {
      isLoadingTracking,
      reportColumns,
      absenceTypeMap,
      type,
      shift,
      clocking,
      tracking,
      staffNumber,
      addressName,
      absence,
      workSpaceName,
      hashtags,
      comment,
      trackedTime,
      isTrackingAccepted,
      creditCorrection,
      branchName,
      duration,
      date,
      userName,
      absenceTypeId,
      jobPositionName,
      isMultiBranch,
      reportShowDecimals,
      style,
      hasManagerPermissions,
      breakIntervalls,
      wage,
      isV2,
    } = this.props;

    const isCorrection = type === ReportEntryType.creditCorrection;
    const isAbsence = type === ReportEntryType.absence;
    const isHoliday = type === ReportEntryType.holiday;
    const withSign = isCorrection && duration < 0;
    const isAutoClockout = tracking?.isAutoClockOut || clocking?.isAutoClockOut;

    const formatDuration = (minutes: number) =>
      reportShowDecimals ? minutesToHoursStr(minutes) : minutesToDuration(minutes, { withSign });

    const durationStr = formatDuration(duration);

    if (type !== ReportEntryType.shift) {
      // For Types like Absences or Holidays or Korrections
      return (
        <div
          className="entryRow extraDataRow"
          style={style}
          data-absence-id={absence?.id}
          data-correction-id={creditCorrection?.id}
          data-report-row={isAbsence ? "absence" : isCorrection ? "correction" : "holiday"}
        >
          {reportColumns.shiftId && (
            <div className="cell userCell" style={REPORT_COL_STYLE.shiftId}>
              {shift?.id}
            </div>
          )}
          {reportColumns.date && (
            <div className="cell dateCell" style={REPORT_COL_STYLE.date}>
              {toMomentUnsafe(date as string)!.format("dd DD.MMM")}
            </div>
          )}
          {reportColumns.userName && (
            <div className="cell userCell" style={REPORT_COL_STYLE.userName}>
              {userName}
            </div>
          )}
          {reportColumns.staffNumber && (
            <div className="cell userCell" style={REPORT_COL_STYLE.staffNumber}>
              {staffNumber}
            </div>
          )}
          <div
            className={cn({
              extraDataCell: true,
              correction: isCorrection,
              absence: isAbsence,
              holiday: isHoliday,
            })}
            style={{ background: isAbsence ? absenceTypeMap[absenceTypeId!].color : "default" }}
          >
            <div className="label">
              {isAbsence && absenceTypeMap[absenceTypeId!].name}
              {isCorrection && lg.korrektur}
              {isHoliday && lg.feiertag}
            </div>
          </div>
          <div className="cell durationCell" style={REPORT_COL_STYLE.duration}>
            {durationStr}
          </div>
        </div>
      );
    }

    return (
      <div
        className="entryRow"
        key={shift?.id || `${date}-${type}-${absenceTypeId}`}
        style={style}
        data-shift-id={shift?.id}
        data-report-row={"shift"}
      >
        {reportColumns.shiftId && (
          <div className="cell shiftIdCell" style={REPORT_COL_STYLE.shiftId}>
            {shift?.id}
          </div>
        )}
        {reportColumns.date && (
          <div className="cell dateCell" style={REPORT_COL_STYLE.date}>
            {toMomentUnsafe(date as string)!.format("dd DD.MMM")}
          </div>
        )}
        {reportColumns.userName && (
          <div className="cell userCell" style={REPORT_COL_STYLE.userName}>
            {userName}
          </div>
        )}
        {reportColumns.staffNumber && (
          <div className="cell staffNumberCell" style={REPORT_COL_STYLE.staffNumber}>
            {staffNumber}
          </div>
        )}
        {reportColumns.jobPositionName && (
          <div className="cell jobPositionCell" style={REPORT_COL_STYLE.jobPosition}>
            {jobPositionName}
          </div>
        )}
        {reportColumns.branchName && isMultiBranch && (
          <div className="cell branchCell" style={REPORT_COL_STYLE.branchName}>
            {branchName}
          </div>
        )}
        {reportColumns.addressName && (
          <div className="cell shiftAddressCell" style={REPORT_COL_STYLE.userName}>
            {addressName}
          </div>
        )}
        {reportColumns.workSpaceName && (
          <div className="cell workSpaceCell" style={REPORT_COL_STYLE.workSpace}>
            {workSpaceName || "-"}
          </div>
        )}
        {reportColumns.hashtags && (
          <div className="cell workSpaceCell" style={REPORT_COL_STYLE.hashtags}>
            {hashtags || "-"}
          </div>
        )}
        {reportColumns.comment && (
          <div className="cell commentCell" style={REPORT_COL_STYLE.comment}>
            {comment || "-"}
          </div>
        )}
        {reportColumns.shiftTime && !isV2 && (
          <div className="cell timesCell" style={REPORT_COL_STYLE.times}>
            {shift && !shift?.isDynamicClocked && `${shift?.startTime} - ${shift?.endTime} / ${shift?.breakMinutes}`}
          </div>
        )}
        {reportColumns.trackedTime && (
          <div className="cell trackingCell" style={REPORT_COL_STYLE.tracking}>
            {trackedTime ? (
              <div className="trackingEntry">
                <div className="time"> {trackedTime}</div>
                {isTrackingAccepted && (
                  <Icon className="trackingStatus accepted" type="check-circle" data-rh={lg.bestätigt} />
                )}
                {!isTrackingAccepted && (
                  <div data-tracking-button={isAutoClockout ? "false" : "true"} data-tracking-id={shift?.id}>
                    <Icon
                      className="trackingStatus pending"
                      type={isLoadingTracking ? "loading" : "hourglass"}
                      data-rh={hasManagerPermissions ? lg.zum_bestätigen_klicken : lg.unbestätigte_zeiterfassung}
                    />
                  </div>
                )}
              </div>
            ) : (
              `-`
            )}
          </div>
        )}

        {reportColumns.breakIntervalls && (
          <div className="cell timesCell" style={REPORT_COL_STYLE.breakIntervalls}>
            {breakIntervalls || "-"}
          </div>
        )}

        {["nightSurcharge", "holidaySurcharge", "sundaySurcharge", "customSurcharge"].map((key) =>
          !reportColumns[key] ? null : (
            <div className="cell surchargeCell" style={REPORT_COL_STYLE[key]} key={key}>
              {this.props[key] ? formatDuration(this.props[key]) : "-"}
            </div>
          )
        )}

        {reportColumns.wage && (
          <div className="cell wageCell" style={REPORT_COL_STYLE.wage}>
            {wage ? numberToEuroPrice(wage) : "-"}
          </div>
        )}

        {reportColumns.duration && (
          <div className="cell durationCell" style={REPORT_COL_STYLE.duration}>
            {shift?.surcharge && (
              <div
                className="shiftSurcharge"
                data-rh={`${lg.inklusive_aufschlag_von} ${shift?.surcharge} ${
                  shift?.surchargeInPercentage ? "%" : "h"
                }`}
                data-rh-at="bottom"
              >
                <Icon type="pie-chart" />
              </div>
            )}
            {durationStr}
          </div>
        )}
      </div>
    );
  }
}
