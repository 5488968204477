import React, { PureComponent } from "react";
import "./styles.scss";
import { connect } from "react-redux";
import { Button } from "antd";
import moment from "moment";
import { selectActiveUsers } from "../../../../../selectors/ActiveUserSelectors";
import { toSimpleDate } from "../../../../../shared/helpers/timeHelpers";
import { IRuleSpecification } from "../../../../../shared/entities/IRuleSpecification";
import { closeModal } from "../../../../../actions/modal";
import { RuleSpecificationForm } from "../../../../../components/RuleSpecificationForm/RuleSpecificationForm";
import { AppState } from "../../../../../types/AppState";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import TZModal from "../../../../../components/TZModal/TZModal";
import { nullifyProps } from "../../../../../shared/helpers/firebaseHelpers";

const mapStateToProps = (state: AppState) => ({
  activeUsers: selectActiveUsers(state, toSimpleDate(moment())),
  jobPositions: state.data.jobPositions,
  branches: state.data.branches,
});

type State = IRuleSpecification;

type OwnProps = {
  ruleSpecification?: IRuleSpecification;
  onComplete: (rule: IRuleSpecification) => void;
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _GeofencingSpecificationModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const ruleSpecifications = (props.ruleSpecification || {}) as IRuleSpecification;
    this.state = ruleSpecifications;
  }

  isNoneSelected = () => {
    const { jobPositionIds, userIds, exceptUserIds, branchIds } = this.state;
    return !userIds && !exceptUserIds && !jobPositionIds && !branchIds;
  };

  saveClicked = async () => {
    const newSpecification = this.isNoneSelected() ? {} : nullifyProps(this.state); // firebase doens allow undefined
    this.props.onComplete(newSpecification as IRuleSpecification);
    this.props.dispatch(closeModal());
  };

  isSaveDisabled = () => {
    const { jobPositionIds, userIds, exceptUserIds, branchIds } = this.state;
    // if an option is checked, there needs to be selected IDs. Otherwise disable saving
    return (
      (userIds && !userIds.length) ||
      (exceptUserIds && !exceptUserIds.length) ||
      (jobPositionIds && !jobPositionIds.length) ||
      (branchIds && !branchIds.length)
    );
  };

  render() {
    return (
      <TZModal>
        <TZModal.Head title={"Geofencing gilt für:"}></TZModal.Head>
        <TZModal.Body>
          <RuleSpecificationForm
            ruleSpecification={this.state}
            onChange={(rule: IRuleSpecification) => {
              this.setState({ ...rule });
            }}
          />
        </TZModal.Body>
        <TZModal.Footer>
          <Button
            id="geofencing-specification-modal-save-button"
            type="primary"
            onClick={this.saveClicked}
            children={lg.Speichern}
            disabled={this.isSaveDisabled()}
          />
        </TZModal.Footer>
      </TZModal>
    );
  }
}

export const GeofencingSpecificationModal = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  _GeofencingSpecificationModal
);
