import { Button, Icon, Checkbox } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../actions/modal";
import "./styles.scss";
import { busyInjector, BusyInjectorProps } from "../../../../../components/BusyInjector/BusyInjector";
import TZModal from "../../../../../components/TZModal/TZModal";
import { useSelector } from "../../../../../helpers/redux";
import _ from "lodash";
import { rosterSettingsRepository } from "../../../../../repositories/rosterSettingsRepository";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";

export const DayPlanSettingsModal = busyInjector((props: {} & BusyInjectorProps) => {
  const dispatch = useDispatch<DispFn>();
  const rosterSettings = useSelector((s) => s.data.rosterSettings[0])!;
  const [isLoading, setLoading] = useState(false);
  const [showHeadCountInDayPlan, setLabelInShifts] = useState(rosterSettings.showHeadCountInDayPlan);

  const saveClicked = async () => {
    setLoading(true);
    await dispatch(
      rosterSettingsRepository.update({
        ...rosterSettings,
        showHeadCountInDayPlan,
      })
    );
    dispatch(closeModal());
  };

  return (
    <TZModal className="dayPlanSettingsModal">
      <TZModal.Head>{lg.tagesplan_ansicht}</TZModal.Head>
      <TZModal.Body>
        <div className="content">
          <div className="row">
            <Checkbox
              checked={showHeadCountInDayPlan}
              onChange={() => setLabelInShifts(!showHeadCountInDayPlan)}
              children={lg.stündlichen_headcount_in_der_dienstplan_kopfzeile_anzeigen}
            />
            <div className="infoIconWrapper" data-rh={lg.anzahl_der_eingeplanten_mitbarbeiter_pro_stunde}>
              <Icon type="info-circle" />
            </div>
          </div>
        </div>
      </TZModal.Body>
      <TZModal.Footer>
        <Button
          style={{ width: 120 }}
          id="cancel-sortorder-modal"
          onClick={saveClicked}
          type="primary"
          icon={isLoading ? "loading" : undefined}
          children={isLoading ? "" : lg.Speichern}
        />
      </TZModal.Footer>
    </TZModal>
  );
});
