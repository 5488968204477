import { AppState } from "../types/AppState";
import { createSelector } from "reselect";
import { IJobPosition } from "../shared/entities/IJobPosition";

export const selectIsMultiJobPos = createSelector(
  (state: AppState) => state.data.jobPositions,
  (jobPositions: IJobPosition[]): boolean => {
    return jobPositions.filter((jp) => !jp.isInactive).length > 1;
  }
);
