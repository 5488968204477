export const startSetupWizard = () => ({
  type: setupWizardActionTypes.START,
});

export const endSetupWizard = () => ({
  type: setupWizardActionTypes.END,
});

export const setupWizardActionTypes = {
  START: "@@AV/SETUP_WIZARD_START",
  END: "@@AV/SETUP_WIZARD_END",
};

// persist reducer for 10 minutes

export const setupWizardReducer = (state = false, action: any): boolean => {
  switch (action.type) {
    case setupWizardActionTypes.START:
      return true;
    case setupWizardActionTypes.END:
      return false;
    default:
      return state;
  }
};
