import React from "react";
import { connect } from "react-redux";
import { Formik, FormikProps, Form } from "formik";
import * as Yup from "yup";
import { Button, DatePicker } from "antd";
import { Moment } from "moment";
import { AppState } from "../../types/AppState";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import TZModal from "../TZModal/TZModal";
import AvFormikInput from "../AvFormikInput/AvFormikInput";
import { closeModal } from "../../actions/modal";
import { holidayRepository } from "../../repositories/holidaysRepository";
import moment from "moment";

import AvFormField from "../AvFormField/AvFormField";
import { Form as AntForm } from "antd";
import { SDateFormat } from "../../shared/helpers/SimpleTime";

const mapStateToProps = (state: AppState) => {
  return {
    sessionInfo: selectSessionInfo(state),
  };
};

type State = {};
type OwnProps = {
  year: number;
  selectedBranchId?: string;
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

export type RosterTemplateFormValue = {
  name: string;
  date?: string; // TDate
};

class _HolidayPopup extends React.PureComponent<Props, State> {
  validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    date: Yup.string().required("Required"),
  });

  renderForm = (formikProps: FormikProps<RosterTemplateFormValue>) => {
    const { values } = formikProps;
    return (
      <Form className="">
        <TZModal.Row>
          <AvFormikInput label={lg.name} fieldName="name" type="string" formikProps={formikProps} />
          <div className="row fb">
            <AvFormField label="Datum">
              <AntForm.Item style={{ marginBottom: 0 }} className="avDatePicker">
                <DatePicker
                  format="DD. MMM YYYY"
                  value={values.date ? moment(values.date, SDateFormat) : undefined}
                  defaultPickerValue={moment().year(this.props.year).month(0).date(1)}
                  onChange={(date) => {
                    const newDate = date ? date.format(SDateFormat) : undefined;
                    formikProps.setFieldValue("date", newDate);
                  }}
                  disabledDate={(d: Moment | null) => !!(d && d.year() !== this.props.year)}
                />
              </AntForm.Item>
            </AvFormField>
          </div>
        </TZModal.Row>
        <TZModal.Footer>
          <Button
            id="save-holiday"
            type="primary"
            onClick={formikProps.submitForm}
            style={{ marginLeft: "auto" }}
            loading={formikProps.isSubmitting}
            disabled={!values.date || !values.name}
            children={lg.Speichern}
          />
        </TZModal.Footer>
      </Form>
    );
  };

  handleSubmit = async (values: RosterTemplateFormValue) => {
    await this.props.dispatch(
      holidayRepository.create({
        name: values.name,
        date: values.date!,
        isLegalHoliday: false,
        branchId: this.props.selectedBranchId,
      })
    );
    this.props.dispatch(closeModal());
  };

  render() {
    return (
      <TZModal>
        <TZModal.Head>Manuellen Feiertag hinzufügen</TZModal.Head>
        <TZModal.Body>
          <Formik
            initialValues={{
              name: "",
              date: undefined,
            }}
            onSubmit={this.handleSubmit}
            enableReinitialize
            validationSchema={this.validationSchema}
            children={this.renderForm}
          />
        </TZModal.Body>
      </TZModal>
    );
  }
}

export const HolidayPopup = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(_HolidayPopup);
