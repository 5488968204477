import React, { useEffect, useState } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import TZModal from "../../../../components/TZModal/TZModal";
import { useSelector } from "../../../../helpers/redux";
import { selectActiveBranches } from "../../../../selectors/ActiveBranchesSelector";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { selectAnnouncementMap, selectUserMap } from "../../../../selectors/mapSelectors";
import { selectActiveJobPositions } from "../../../../selectors/ActiveJobPositionsSelector";
import _ from "lodash";
import { AnnouncementFormValues } from "./AnnouncementFormValues";
import "./styles.scss";
import { Button, Checkbox, Divider, Icon, Input, Modal, Select } from "antd";
import { v4 as uuid } from "uuid";
import { closeModal } from "../../../../actions/modal";
import { useLoading } from "../../../../helpers/useLoading";
import { announcementRepository } from "../../../../repositories/announcementRepository";
import { useDispatch } from "react-redux";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import { saveAnnouncement } from "../../../../actions/announcements";
import BusyWrapper from "../../../../components/BusyWrapper/BusyWrapper";
import { selectVisibleBranches } from "../../../../selectors/VisibleBranchesSelector";
import { selectActiveUsersWithSharedBranch } from "../../../../selectors/UsersWithSharedBranchSelector";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import { selectActiveUsers } from "../../../../selectors/ActiveUserSelectors";
import moment from "moment";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { getUserName } from "../../../../shared/helpers/userHelpers";
import { antDesignSelectFilterSearch, downloadFile } from "../../../../frontend-core/helpers/frontendHelpers";
import { IUpload, IUploadBase } from "../../../../shared/entities/IUpload";
import { NewUpload, removeUpload, removeUploadList, uploadFile } from "../../../../actions/uploads";
import { uploadRepository } from "../../../../repositories/uploadRepository";
import { AvUploader } from "../../../../components/AvUploader/AvUploader";
import { prependOnceListener } from "process";
import { setUser } from "@sentry/minimal";

type Props = {
  id?: string;
};

const schema = yup.object().shape({
  title: yup.string().required().min(1),
  message: yup.string(),
});

/**
 * Please refactor and kill this file, before it lays eggs! :D
 */
export const AnnouncementAddEditModal = (props: Props) => {
  const sessionInfo = useSelector(selectSessionInfo);
  const branches = useSelector(selectActiveBranches);
  const [isLoading, load, setLoading] = useLoading();
  const dispatch = useDispatch<DispFn>();
  const allUploads = useSelector((s) => s.data.uploads);

  const [newUploads, setNewUploads] = useState<NewUpload[]>([]);
  const announcementMap = useSelector(selectAnnouncementMap);
  const jobPositions = useSelector(selectActiveJobPositions);
  const visibleBranches = useSelector(selectVisibleBranches);
  const userMap = useSelector(selectUserMap);
  const usersWithSharedBranch = useSelector(selectActiveUsersWithSharedBranch);
  const activeUsers = useSelector((s) => selectActiveUsers(s, moment().format(SDateFormat)));
  const _visibleUsers = sessionInfo.isAdmin() ? activeUsers : usersWithSharedBranch.map((uId) => userMap[uId]);
  const visibleUsers = _visibleUsers.filter((u) => u.id !== sessionInfo.user.id);

  const existingAnnouncement = props.id ? announcementMap[props.id] : undefined;

  const [selectedUserIds, setUserIds] = useState<string[]>(
    existingAnnouncement ? _.keys(existingAnnouncement.readByUserId) : []
  );
  const [selectedBranchIds, setBranchIds] = useState(
    existingAnnouncement?.branchIds || (branches.length === 1 ? [branches[0].id] : [])
  );
  const [selectedJobPositionIds, setJobPositionIds] = useState(existingAnnouncement?.jobPositionIds || []);

  const existingUploads = allUploads.filter((u) => props.id && u.relatedEntityId === props.id);

  const uplaodsToDisplay = _.uniqBy([...(existingUploads || []), ...(newUploads || [])], (u) => u.id);
  // using uniqBy > because during saving the upload, the existing and new upload appear both at the same time

  useEffect(() => {
    if (props.id) {
      dispatch(announcementRepository.fetchOne(props.id));
      load(dispatch(uploadRepository.fetchMany({ filter: ["relatedEntityId", "=", props.id] })));
    }
  }, []);

  const {
    control,
    formState: { isValid },
    handleSubmit,
    setValue,
    getValues,
  } = useForm<AnnouncementFormValues>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      id: existingAnnouncement?.id || `an${Date.now()}`, // its important to have the ID timestamped, because we want to fetch the frist-X entities by creationTime
      title: existingAnnouncement?.title,
      message: existingAnnouncement?.message,
    },
  });

  const submit = async (values: AnnouncementFormValues) => {
    if (!isValid) {
      return;
    }

    await load(
      dispatch(
        saveAnnouncement(
          { ...values, userIds: selectedUserIds, branchIds: selectedBranchIds, jobPositionIds: selectedJobPositionIds },
          newUploads,
          existingAnnouncement
        )
      )
    );
    dispatch(closeModal());
  };

  const selectedFiles = async (files: File[]) => {
    const nextNewUploads: NewUpload[] = [];
    const id = getValues("id");
    files.forEach((file) => {
      nextNewUploads.push({
        id: uuid(),
        name: file.name,
        displayName: file.name,
        file,
        relatedEntityId: id,
        relatedRepoKey: "announcements",
      });
    });

    setNewUploads([...nextNewUploads, ...newUploads]);
    setValue("title", getValues("title"), { shouldDirty: true }); // a hacky way to turn the form dirty
  };

  const deleteFile = (upload: IUpload) => {
    Modal.confirm({
      title: lg.dokument_löschen,
      content: lg.die_datei_file_wirklich_löschen(upload.name),
      onOk: async () => {
        await dispatch(removeUpload(upload));
      },
      cancelText: lg.abbrechen,
      okText: lg.löschen,
      okType: "danger",
    });
  };

  const deleteClicked = (upload: IUpload | NewUpload) => {
    const isAlreadyUploaded = (upload as any).url;
    isAlreadyUploaded ? deleteFile(upload as IUpload) : setNewUploads(newUploads.filter((u) => u.id !== upload.id));
  };

  const renderFileName = (upload: IUpload | NewUpload) =>
    !!(upload as any).url ? (
      <a
        href={(upload as IUpload).url}
        target="_blank"
        onClick={(event) => event.stopPropagation()}
        children={upload.displayName}
      />
    ) : (
      <div className="fileName">{upload.displayName}</div>
    );

  const reselectUsers = (newBranchIds: string[], newJobPosIds: string[]) => {
    const newUserIds = activeUsers
      .filter((u) => _.intersection(u.branchIds, newBranchIds).length)
      .filter((u) => _.intersection(u.jobPositionIds, newJobPosIds).length)
      .filter((u) => u.id !== sessionInfo.user.id) // a creator can not be a target of an announcemnt!
      .map((u) => u.id);

    setUserIds(newUserIds);
    setBranchIds(newBranchIds);
    setJobPositionIds(newJobPosIds);
  };

  const setSelectedBranchIds = (_branchIds: string[]) => {
    reselectUsers(_branchIds, selectedJobPositionIds);
  };

  const setSelectedJobPositionIds = (_jobPosIds: string[]) => {
    reselectUsers(selectedBranchIds, _jobPosIds);
  };

  const toggleAllBranchIds = () => {
    const branchIds = visibleBranches.map((b) => b.id);
    reselectUsers(branchIds, selectedJobPositionIds);
  };

  const toggleAllJobPositions = () => {
    const jobPosIds = jobPositions.map((b) => b.id);
    reselectUsers(selectedBranchIds, jobPosIds);
  };

  const deleteAnnouncementClicked = async () => {
    const ann = existingAnnouncement!;
    existingUploads?.length && (await load(dispatch(removeUploadList(existingUploads))));
    await load(dispatch(announcementRepository.remove(ann!.id)));
    dispatch(closeModal());
  };

  return (
    <TZModal className="announcementAddEditModal">
      <TZModal.Head>{lg.ansage}</TZModal.Head>
      <TZModal.Body style={{ minWidth: 400, background: "#f4f4f796" }}>
        <BusyWrapper isBusy={isLoading()}>
          <div className="formField">
            <Controller
              name="title"
              defaultValue=""
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input onChange={onChange} value={value} placeholder={lg.titel} autoFocus />
              )}
            />
          </div>
          <div
            className="formField"
            style={{
              marginTop: 12,
              marginBottom: 12,
            }}
          >
            <Controller
              name="message"
              defaultValue=""
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input.TextArea
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  style={{ minHeight: 100 }}
                  onChange={onChange}
                  value={value}
                  placeholder={lg.nachricht}
                />
              )}
            />
          </div>
          <div className="selectUsersSection">
            <div className="dropDownsColumn">
              <div className="label">{lg.standorte}</div>
              <Select
                mode="multiple"
                placeholder={lg.standorte}
                filterOption={antDesignSelectFilterSearch}
                value={selectedBranchIds}
                onChange={(ids) => setSelectedBranchIds(ids)}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    <div className="announcementDropDownSelectAllButton" onMouseDown={toggleAllBranchIds}>
                      <span>{lg.alle_standorte}</span>
                      {selectedBranchIds.length === visibleBranches.length && <Icon type="check" />}
                    </div>
                  </div>
                )}
              >
                {visibleBranches.map((b) => (
                  <Select.Option value={b.id} key={b.id} label={b.name} title={b.name}>
                    <div className="selectIndicator" style={{ backgroundColor: b.color }} />
                    {b.name}
                  </Select.Option>
                ))}
              </Select>

              <div className="label" style={{ marginTop: 24 }}>
                {lg.rollen}
              </div>
              <Select
                mode="multiple"
                value={selectedJobPositionIds}
                filterOption={antDesignSelectFilterSearch}
                placeholder={lg.rollen}
                onChange={setSelectedJobPositionIds}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    <div className="announcementDropDownSelectAllButton" onMouseDown={toggleAllJobPositions}>
                      <span>{lg.alle_rollen}</span>
                      {selectedJobPositionIds.length === jobPositions.length && <Icon type="check" />}
                    </div>
                  </div>
                )}
              >
                {jobPositions.map((jp) => (
                  <Select.Option value={jp.id} key={jp.id} label={jp.name} title={jp.name}>
                    <div className="selectIndicator" style={{ backgroundColor: jp.color }} />
                    {jp.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="fb col usersCol">
              <div className="labelWrapper fb row">
                <div className="label">{lg.mitarbeiter_plural}</div>
                {!!selectedUserIds.length && (
                  <span style={{ marginLeft: "auto", color: "#1a90ff" }}>
                    {selectedUserIds.length} {lg.ausgewählt}
                  </span>
                )}
              </div>
              <div className="colList">
                {_.orderBy(visibleUsers, "name").map((u) => {
                  const isSelected = selectedUserIds.includes(u.id);
                  return (
                    <div
                      key={u.id}
                      className="colListItem"
                      onClick={() =>
                        setUserIds(
                          !isSelected ? [...selectedUserIds, u.id] : selectedUserIds.filter((uId) => uId !== u.id)
                        )
                      }
                    >
                      <div className="userName">{getUserName(u)}</div>
                      <Checkbox checked={isSelected} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {!!uplaodsToDisplay.length && (
            <div className="announcementUploadsSectionMain">
              <div className="uploadSectionTitle">{lg.anhänge}</div>
              {uplaodsToDisplay.map((upload) => {
                return (
                  <div className="uploadEntry" key={upload.id}>
                    <div className="attachment">
                      <Icon type="file-text" />
                    </div>
                    <div className="nameWrapper">{renderFileName(upload)}</div>
                    {!!(upload as any).url && (
                      <div
                        className="download actionBtn"
                        data-rh={lg.download}
                        onClick={() => downloadFile(upload as IUpload)}
                      >
                        <Icon type="download" />
                      </div>
                    )}
                    <div className="delete actionBtn" data-rh={lg.löschen} onClick={() => deleteClicked(upload)}>
                      <Icon type="delete" />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </BusyWrapper>
      </TZModal.Body>
      <TZModal.Footer>
        <AvUploader
          buttonType="default"
          label={lg.anhang}
          onChange={(files) => selectedFiles(files)}
          style={{ marginRight: "auto" }}
          doAllowUploade={true}
        />
        {existingAnnouncement && (
          <Button
            type="dashed"
            data-rh={lg.löschen}
            style={{ alignSelf: "flex-start" }}
            icon="delete"
            onClick={() => {
              Modal.confirm({
                title: lg.ansage_löschen,
                okText: lg.ja,
                cancelText: lg.nein,
                onOk: deleteAnnouncementClicked,
              });
            }}
          />
        )}
        <Button
          disabled={!isValid || !selectedUserIds.length}
          type="primary"
          children={lg.Speichern}
          onClick={handleSubmit(submit)}
        />
      </TZModal.Footer>
    </TZModal>
  );
};
