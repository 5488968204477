import React, { MouseEvent } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { Checkbox, Button, message, InputNumber, Icon, Radio, notification, TimePicker } from "antd";
import "./styles.scss";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { timeClockSettingsRepository } from "../../../repositories/timeClockSettingsRepository";
import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import { selectActiveBranches } from "../../../selectors/ActiveBranchesSelector";
import { ITimeClockSettings } from "../../../shared/entities/ITimeClockSettings";
import { RuleSpecificationBtn } from "../../../components/RuleSpecificationBtn/RuleSpecificationBtn";
import cn from "classnames";
import { decimalSeparatorLocal } from "../../../helpers/dateFormatHelper";
import moment from "moment";
import { isNumber, times } from "lodash";
import { openModal } from "../../../actions/modal";
import { BreakRulesModal } from "../rosterSettings/BreakRulesModal/BreakRulesModal";
import { rosterSettingsRepository } from "../../../repositories/rosterSettingsRepository";

const mapStateToProps = (state: AppState) => ({
  timeClockSettings: state.data.timeClockSettings[0], // setting-entities are always tables with only one row -> so we grab the index 0
  rosterSettings: state.data.rosterSettings[0],
  branches: state.data.branches,
  activebranches: selectActiveBranches(state),
  isV2: state.data.tenantInfo.isV2,
  users: state.data.users,
});

type State = {
  settings: ITimeClockSettings;
};

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class TimeClockSettings extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      settings: this.props.timeClockSettings,
    };
  }

  renderCheckbox = (
    fieldName: keyof ITimeClockSettings,
    label: React.ReactNode,
    options: {
      invert?: boolean;
      hidden?: boolean;
      styles?: React.CSSProperties;
      specBtnStyles?: React.CSSProperties;
      infoText?: string;
      classes?: string;
      withoutSpecification?: boolean;
      clearSpecificationOnChange?: boolean;
    } = {}
  ) => {
    if (options.hidden) {
      return null;
    }

    const isChecked = options?.invert ? !this.state.settings[fieldName] : (this.state.settings[fieldName] as boolean);
    return (
      <div
        className={"checkboxWrapper borderTop ruleBlock" + " " + options?.classes}
        key={fieldName}
        style={options.styles || {}}
      >
        <Checkbox
          checked={isChecked}
          onChange={async (e) => {
            const value = options?.invert ? !e.target.checked : e.target.checked;
            this.updateSettings(fieldName, value, options.clearSpecificationOnChange);
          }}
        />
        <div className="label">{label}</div>
        {options.infoText && (
          <div className="infoIconWrapper" data-rh={options.infoText}>
            <Icon type="info-circle" />
          </div>
        )}
        {!options.withoutSpecification && isChecked && (
          <RuleSpecificationBtn
            type="TimeClockSettings"
            ruleKey={fieldName}
            style={{
              position: "absolute",
              right: 0,
              bottom: 12,
              ...(options?.specBtnStyles || {}),
            }}
          />
        )}
      </div>
    );
  };

  openBreakRules = (e: MouseEvent<any, any>) => {
    e.stopPropagation();
    e.preventDefault();
    const { rosterSettings, dispatch } = this.props;

    dispatch(
      openModal(BreakRulesModal, {
        rules: rosterSettings.breakRules,
        onComplete: (newBreakRules) =>
          dispatch(
            rosterSettingsRepository.update({
              ...rosterSettings,
              breakRules: newBreakRules,
            })
          ),
      })
    );
  };

  updateSettings = async (fieldName: string, value: any, doClearSpecification?: boolean) => {
    const branchWithClockingExists = this.props.branches.some((b) => !b.isInactive && b.isClockingEnabled);
    if (!branchWithClockingExists) {
      notification.warning({
        message: lg.die_zeiterfassung_muss_zunächst_unter_einstellungen_standorte_aktivert_werden,
      });
    }

    const next = { ...this.state.settings, [fieldName]: value };
    this.setState({ settings: next });

    const newTimeClockSettings = {
      ...next,
      specifications: this.props.timeClockSettings.specifications || {}, // this state can get edited by modals, so need to update here aswell
    };

    doClearSpecification && (newTimeClockSettings.specifications[fieldName] = null);
    return this.props.dispatch(timeClockSettingsRepository.update(newTimeClockSettings));
  };

  render() {
    const { breaksNeedToBeClocked, autoCapOverTimeMinutes, capEarlyClockinsV2, capEarlyClockinsAtTimeV2 } =
      this.state.settings;
    const { isV2 } = this.props;

    return (
      <div className="timeClockSettingsMain">
        {/* <h2 className="avTitle">Zeiterfassung</h2> */}
        <h2 className="">{lg.zeiterfassung}</h2>
        {this.props.isLoading("general") ? (
          <Icon type="loading" style={{ fontSize: "70px", color: "#1990ff" }} spin />
        ) : (
          <div className="content">
            <div className="basicCheckBoxesList">
              {!isV2 && (
                <div className="infoClockingEnabledBox">
                  <div>{lg.folgende_einstellungen_gelten_für_standorte_mit_aktivierter_stempeluhr_zeiterfassung}</div>
                </div>
              )}
              {this.renderCheckbox("shiftsNeedToBeClocked", lg.arbeitszeiten_müssen_gestempelt_werden, {
                infoText: lg.schichten_mit_fehlender_zeiterfassung_werden_als_nicht_angetreten_markiert,
                styles: { borderTop: "none" },
                hidden: isV2,
              })}
              <div className="ruleBlock" style={{ paddingBottom: 16, flexDirection: "column", alignItems: "start" }}>
                <div
                  className="radioRow"
                  style={{ marginBottom: 6 }}
                  onClick={async () => {
                    await this.updateSettings("breaksNeedToBeClocked", true);
                    this.updateSettings("applyAtLeastPlannedBreakTime", null);
                  }}
                >
                  <Radio value={true} checked={breaksNeedToBeClocked}>
                    {lg.pausen_müssen_über_die_stempeluhr_erfasst_werden}
                  </Radio>
                </div>
                <div
                  className="radioRow"
                  onClick={async () => {
                    await this.updateSettings("breaksNeedToBeClocked", false);
                    this.updateSettings("applyAtLeastPlannedBreakTime", null);
                  }}
                >
                  <Radio value={false} checked={!breaksNeedToBeClocked}>
                    <span>
                      <span>{lg.pausen_werden_automatisch_von_der_gestempelten_zeit_abgezogen}</span>
                      {isV2 && (
                        <span className="breakRulesBtn" onClick={(e) => this.openBreakRules(e)}>
                          Pausenregelung
                        </span>
                      )}
                    </span>
                  </Radio>
                </div>
                {breaksNeedToBeClocked && (
                  <RuleSpecificationBtn
                    ruleKey={"breaksNeedToBeClocked"}
                    type="TimeClockSettings"
                    style={{
                      position: "absolute",
                      right: 0,
                      bottom: 12,
                    }}
                  />
                )}
              </div>
              {this.renderCheckbox(
                "applyAtLeastPlannedBreakTime",
                isV2
                  ? lg.wenn_weniger_als_die_hinterlegte_pause_gestempelt_wird_pausenregelung_anwenden
                  : lg.wenn_weniger_als_die_geplante_pause_gestempelt_wird_geplante_pausendauer_übernehmen
              )}
              {this.renderCheckbox(
                "noEarlyClockin",
                lg.beim_einstempeln_vor_schichtbeginn_die_startzeit_der_schicht_übernehmen,
                { hidden: isV2 }
              )}
              {isV2 && (
                <div className="borderTop ruleBlock capEarlyClockinRule">
                  <Checkbox
                    checked={!!capEarlyClockinsV2}
                    onClick={async (e: any) => {
                      await this.updateSettings("capEarlyClockinsV2", e.target.checked);
                      this.updateSettings("capEarlyClockinsAtTimeV2", "08:00");
                    }}
                  >
                    <span style={{ marginLeft: 0 }}>
                      <span>Beim Einstemplen vor</span>
                      <TimePicker
                        disabled={!capEarlyClockinsV2}
                        size="small"
                        style={{ margin: "0 10px", width: 80 }}
                        defaultValue={moment("08:00", "HH:mm")}
                        format={"HH:mm"}
                        value={moment(capEarlyClockinsAtTimeV2 || "08:00", "HH:mm")}
                        onChange={(mom, timeString) => this.updateSettings("capEarlyClockinsAtTimeV2", timeString)}
                      />
                      <span>Uhr, diese Uhrzeit als Startzeit übernehmen.</span>
                      {/* <span>als Startzeit übernehmen</span> */}
                    </span>
                  </Checkbox>
                  {!!capEarlyClockinsV2 && (
                    <RuleSpecificationBtn
                      ruleKey={"capEarlyClockinsV2"}
                      type="TimeClockSettings"
                      style={{
                        position: "absolute",
                        right: 0,
                      }}
                    />
                  )}
                </div>
              )}
              {!isV2 && (
                <div className="borderTop ruleBlock">
                  <Checkbox
                    checked={this.state.settings.autoCapOverTimeMinutes !== undefined}
                    onClick={() => {
                      this.updateSettings(
                        "autoCapOverTimeMinutes",
                        this.state.settings.autoCapOverTimeMinutes === undefined ? 0 : undefined
                      );
                    }}
                  >
                    <span style={{ marginLeft: 0 }}>
                      {lg.beim_ausstempeln_schichtzeit_übernehmen_wenn_überschreitung_weniger_als_comp_minuten(
                        <InputNumber
                          size="small"
                          className="noInputStepper"
                          style={{ margin: "0 10px", width: 45 }}
                          decimalSeparator={decimalSeparatorLocal}
                          placeholder="0"
                          disabled={this.state.settings.autoCapOverTimeMinutes === undefined}
                          onChange={(v) => this.updateSettings("autoCapOverTimeMinutes", v)}
                          value={autoCapOverTimeMinutes}
                        />
                      )}
                    </span>
                  </Checkbox>
                  {!!this.state.settings.autoCapOverTimeMinutes && (
                    <RuleSpecificationBtn
                      ruleKey={"autoCapOverTimeMinutes"}
                      type="TimeClockSettings"
                      style={{
                        position: "absolute",
                        right: 0,
                      }}
                    />
                  )}
                </div>
              )}
              {this.renderCheckbox(
                "clockingsAreApprovedOnDefault",
                lg.über_die_stempeluhr_erfasste_zeiten_automatisch_bestätigen,
                { hidden: isV2 }
              )}
              {!isV2 && (
                <span style={{ marginLeft: 40, marginBottom: 10, marginTop: -6 }}>
                  {lg.bei_einer_differenz_der_geplanten_zur_gestempleten_zeit_von_unter_comp_minuten(
                    <InputNumber
                      size="small"
                      className="noInputStepper"
                      style={{ margin: "0 10px", width: 45 }}
                      decimalSeparator={decimalSeparatorLocal}
                      placeholder="0"
                      disabled={!this.state.settings.clockingsAreApprovedOnDefault}
                      onChange={(v) => this.updateSettings("autoApproveClockingMaxTimeDiff", v)}
                      value={
                        this.state.settings.clockingsAreApprovedOnDefault
                          ? this.state.settings.autoApproveClockingMaxTimeDiff
                          : undefined
                      }
                    />
                  )}
                </span>
              )}
              {!isV2 && (
                <div className={cn({ freeClockingBlock: true, isActive: this.state.settings.isFreeClockingAllowed })}>
                  {this.renderCheckbox(
                    "isFreeClockingAllowed",
                    lg.arbeitszeiten_können_auch_ohne_geplante_schichten_gestempelt_werden,
                    { specBtnStyles: { bottom: 10 } }
                  )}
                  {this.state.settings.isFreeClockingAllowed &&
                    this.renderCheckbox(
                      "freeClockingApplyBreakeRule",
                      lg.pausen_entsprechend_der_pausenregelung_automatisch_abziehen,
                      { classes: "isSubRule", specBtnStyles: { bottom: 4 } }
                    )}
                  {this.state.settings.isFreeClockingAllowed &&
                    this.renderCheckbox("freeClockingIsAutoApproved", lg.diese_zeiterfassungen_automatisch_bestätigen, {
                      classes: "isSubRule",
                      specBtnStyles: { bottom: 4 },
                    })}
                </div>
              )}
              {this.renderCheckbox(
                "splitClockingForNextShift",
                lg.aufeinander_folgende_schichten_können_gemeinsam_gestempelt_werden,
                {
                  infoText: lg.so_kann_man_sich_zur_frühschicht_einstempeln_und_zum_ende_der_spätschicht_aussptempeln,
                  withoutSpecification: true,
                  hidden: isV2,
                }
              )}
              {this.renderCheckbox(
                "allowUserTimClockInBrowser",
                lg.mitarbeiter_können_die_stempeluhr_über_den_eigenen_zugang_im_browser_verwenden
              )}
              {this.renderCheckbox(
                "manualTrackingIsDisabled",
                lg.mitarbeiter_koennen_zeiterfassungen_manuell_eintragen,
                {
                  hidden: isV2,
                  invert: true,
                  infoText:
                    lg.manuell_eintragen_zeiterfassungen_muessen_anschliessend_von_einem_manager_oder_admin_bestaetigt_werden,
                  clearSpecificationOnChange: true,
                }
              )}
              {this.renderCheckbox(
                "allowManualClockingsV2",
                lg.mitarbeiter_koennen_zeiterfassungen_manuell_eintragen_und_editieren,
                {
                  hidden: !isV2,
                  infoText:
                    "Manuelles Eintragen bedeutet, dass die Arbeitszeit nicht gestempelt wird, sondern der Mitarbeiter selbst einträgt, zu welcher Uhrzeit er gekommen ist und wann er gegangen ist.",
                }
              )}
              {isV2 && this.state.settings.allowManualClockingsV2 && (
                <span style={{ marginLeft: 40, marginBottom: 10, marginTop: -6 }}>
                  {lg.erlaubt_fuer_bis_zu_tagen_in_der_vergangenheit}
                  <InputNumber
                    size="small"
                    className="noInputStepper"
                    style={{ margin: "0 10px", width: 45, textAlign: "center" }}
                    decimalSeparator={decimalSeparatorLocal}
                    placeholder="0"
                    disabled={!this.state.settings.allowManualClockingsV2}
                    onChange={(v) => this.updateSettings("allowManualClockingsForDaysV2", v)}
                    value={this.state.settings.allowManualClockingsForDaysV2}
                  />
                  {lg.in_der_vergangenheit}
                  <span className="infoIconWrapperFree" style={{ marginLeft: 8 }} data-rh={lg.nur_am_selben_tag_ist_0}>
                    <Icon type="info-circle" />
                  </span>
                </span>
              )}
              {this.renderCheckbox(
                "manualClockingsAreAutopprovedV2",
                lg.manuell_eingetragene_arbeitszeiten_automatisch_bestaetigen,
                {
                  hidden: !isV2,
                  infoText:
                    "Einträge müssen von einem Admin/Manager bestätigt werden. Falls das nicht notwendig ist, aktiviren Sie diese Regel.",
                }
              )}
              {this.renderCheckbox("clockingsAreAutoApprovedV2", lg.gestempelte_arbeitszeiten_automatisch_bestaetigen, {
                hidden: !isV2,
                infoText:
                  "Zeiterfassungen müssen von einem Admin/Manager bestätigt werden. Falls das nicht notwendig ist, aktiviren Sie diese Regel.",
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withErrorBoundary(
  connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(busyInjector(TimeClockSettings))
);
