import React from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import TZModal from "../../../components/TZModal/TZModal";
import { DatePicker, Button, Icon, notification, Input, message } from "antd";
import { closeModal } from "../../../actions/modal";
import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import { userRepository } from "../../../repositories/userRepository";
import { IUser } from "../../../shared/entities/IUser";

const mapStateToProps = (state: AppState, props: OwnProps) => {
  return {};
};

type OwnProps = {
  user: IUser;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

type State = {
  password: string;
};

class _SetEmployeePasswordModal extends React.PureComponent<Props, State> {
  state = {
    password: "",
  };

  saveClicked = async () => {
    const { dispatch, load, user } = this.props;
    const { password } = this.state;

    if (!password) {
      notification.error({ message: lg.bitte_geben_sie_ein_passwort_ein });
      return;
    }

    await load(dispatch(userRepository.createAccountRaw(user, password)));
    notification.success({ message: lg.mitarbeiter_account_wurde_aktiviert });

    dispatch(closeModal());
    dispatch(closeModal()); // to close the underlying userModal aswell.
  };

  render() {
    return (
      <>
        <TZModal style={{ width: "500px" }}>
          <TZModal.Head title={lg.mitarbeiter_passwort_vergeben} headStyle={{ border: "none" }}></TZModal.Head>
          <div className="setEmployeePasswordModalBody">
            <div className="infoText">
              <div className="expleneation">
                {
                  lg.als_alternative_zur_email_aktivierung_können_sie_hier_selber_ein_passwort_vergeben_mit_dem_sich_der_mitarbeiter_einloggen_kann
                }
                <br />
                <br />
                {lg.das_passwort_kann_vom_mitarbeiter_nach_dem_login_in_der_app_geändert_werden}
              </div>
            </div>
            <div className="row">
              <div className="inputWrapper">
                <Input
                  placeholder={lg.passwort}
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </div>
              <div className="buttonWrapper">
                <Button
                  style={{ width: 100 }}
                  id="manually-set-user-password-save-button"
                  type="primary"
                  children={lg.Speichern}
                  onClick={this.saveClicked}
                  loading={this.props.isLoading()}
                />
              </div>
            </div>
            <div className="row"></div>
          </div>
        </TZModal>
      </>
    );
  }
}

export const SetEmployeePasswordModal = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  busyInjector(_SetEmployeePasswordModal)
);
