import { IBranch } from "../../shared/entities/IBranch";
import { IUpload } from "../../shared/entities/IUpload";
import { IShiftAddress } from "../../shared/entities/IShiftAddress";
import { pastelColors, strongColors } from "./colors";
import _ from "lodash";
import { getLocale } from "../../helpers/dateFormatHelper";
import { DispFn } from "../types/thunkTypes";
import { AppState } from "../../types/AppState";
import { environment } from "../../env";
import * as Sentry from "@sentry/browser";
import { IUser } from "../../shared/entities/IUser";
import { IUserTimeClockSettings } from "../../shared/entities/ITimeClockSettings";
import { addSimpleDays } from "../../shared/helpers/dateHelpers";
import { ReportColumns } from "../../actions/reporting";
import { DeviceType } from "../../shared/entities/ITimeClocking";

export const getUrlParam = (name: string) => {
  const results = new RegExp("[?&]" + name + "=([^&#]*)").exec(window.location.href);
  return results == null ? null : decodeURI(results[1]) || null;
};

const getBranchIndex = (branch: IBranch) => {
  return parseInt(branch.id.substring(1)); // turning 'b3' to 3
};

// this method returned the branch-color if its defined
// if its not yet defined its assigning one by using the branch-index for that
// all this function is just here to be backwards compatible > because branches initially didnt have a color-prop
export const getBranchColor = (branch: IBranch): string => {
  return branch.color || [...strongColors, ...pastelColors][getBranchIndex(branch)];
};

export const antDesignSelectFilterSearch = (input: string, option: React.ReactElement<any>): boolean => {
  return option.props.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export const downloadFile = async (upload: IUpload) => {
  const res = await fetch(upload.url);
  const a = document.createElement("a");
  const url = window.URL.createObjectURL(await res.blob());
  a.style.display = "none";
  a.href = url;
  a.download = upload.name;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const orderAddresses = (addresses: IShiftAddress[]): IShiftAddress[] => {
  return _.orderBy(addresses, (a) => (a.name || a.address || "").toLowerCase());
};

export const orderByName = <T extends { name: string }>(obj: T[]): T[] => {
  return _.orderBy(obj, (a) => a.name.toLowerCase());
};

export const getLanguage = (): "en" | "de" => {
  return getLocale().includes("de") ? "de" : "en";
};

export const fetchCurrency = async (): Promise<"EUR" | "USD" | "GBP"> => {
  if (environment.isDev()) {
    return "EUR"; // to not use up all Free geo API calls while developing
  }

  return await new Promise(function (resolve, reject) {
    try {
      // Copied from https://docs.ipdata.co/
      var xhr = new XMLHttpRequest();
      xhr.open("GET", "https://api.ipdata.co/?api-key=d910cc09abed2d37b1344bebdec0e7fae521d9793247fba7e202c19d");
      xhr.setRequestHeader("Accept", "application/json");
      xhr.onreadystatechange = function () {
        if (this.readyState === 4) {
          const resJson = JSON.parse(this.responseText);
          if (resJson) {
            if (resJson?.currency?.code === "GBP") {
              resolve("GBP");
            } else {
              if (resJson.is_eu) {
                resolve("EUR");
              } else {
                resolve("USD");
              }
            }
          } else {
            reject();
          }
        } else {
          reject();
        }
      };
      xhr.onerror = function () {
        reject();
      };
      xhr.send();
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      resolve("EUR");
    }
  });
};

export const haveCommonJobPos = (user1: IUser, user2: IUser) =>
  user1.jobPositionIds.some((el) => user2.jobPositionIds.includes(el));

export const clearLocalStorageExcept = (keys: string[]) => {
  const keyValues: { key: string; val: string }[] = [];
  keys.forEach((key) => {
    const val = localStorage.getItem(key);
    val && keyValues.push({ key, val });
  });

  localStorage.clear();

  keyValues.forEach((keyValue) => {
    localStorage.setItem(keyValue.key, keyValue.val);
  });
};

export const getPunchingLog = (type: DeviceType, userId: string, timestamp: string) => {
  return `${type}_${userId}_${timestamp}`;
};

export const canEmployeAddEditPunchingOnDate = (
  timeClockSettings: IUserTimeClockSettings,
  date: string,
  today: string
) => {
  const { allowManualClockingsV2, allowManualClockingsForDaysV2 } = timeClockSettings;
  return !!allowManualClockingsV2 && date <= today && addSimpleDays(date, allowManualClockingsForDaysV2 || 0) >= today;
};

export const numberToEuroPrice = (num?: number) => (num ? num.toFixed(2).replace(".", ",") : "0") + " €";

export const reportColsToV2 = (reportCols: ReportColumns, isV2: boolean | undefined): ReportColumns => {
  return {
    ...reportCols,
    shiftTime: isV2 ? false : reportCols.shiftTime,
  };
};

export const isV2Domain = (): boolean => {
  return window.location.hostname.includes("zeitguru");
};

export const getSimpleDateToday = () => {
  const date = new Date();
  const year = date.toLocaleString("default", { year: "numeric" });
  const month = date.toLocaleString("default", {
    month: "2-digit",
  });
  const day = date.toLocaleString("default", { day: "2-digit" });
  return [year, month, day].join("-");
};

export const isUserActiveOnDate = (user: IUser, date: string) => {
  return !user.isDeleted && (!user.lastWorkDay || user.lastWorkDay > date);
};

export const getCalenderNeedsSyncUpdate = (tenantId: string, userIds) => {
  const updates = {};
  userIds.forEach((userId) => (updates[`iCals/${tenantId}_${userId}/needsSync`] = true));
  return updates;
};
