import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import "./styles.scss";
import { Checkbox, Button, Alert } from "antd";
import TZModal from "../../TZModal/TZModal";
import { AppState } from "../../../types/AppState";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { BusyInjectorProps, busyInjector } from "../../BusyInjector/BusyInjector";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { closeModal } from "../../../actions/modal";
import { publishWeekPlans } from "../../../actions/weekPlan";

import _ from "lodash";
import { Pusher } from "../../../actions/pushNote";
import { simpleDateToMoment, toSimpleDate } from "../../../shared/helpers/timeHelpers";
import * as Sentry from "@sentry/browser";
import { setNotifyOnPublish } from "../../../reducers/ui/shifts/roster/isNotifyOnPublishChecked";
import { useSelector } from "../../../helpers/redux";
import { DispFn } from "../../../frontend-core/types/thunkTypes";
import { selectVisibleBranches } from "../../../selectors/VisibleBranchesSelector";
import { BasicSelect } from "../../BasicSelect/BasicSelect";
import cn from "classnames";
import moment from "moment";
import { SDateFormat } from "../../../shared/helpers/SimpleTime";

type Props = BusyInjectorProps & {
  week?: string;
};

export const PublishWeekModal = busyInjector(({ week, ...props }: Props) => {
  const users = useSelector((state) => state.data.users);
  const dispatch = useDispatch<DispFn>();

  // const sessionInfo = useSelector((state) => selectSessionInfo(state));
  const publishedWeeks = useSelector((state) => state.data.publishedWeeks);
  const selectedBranch = useSelector((state) => state.ui.selectedBranch);
  // const [displayMultiSelect, setDisplayMultiSelect] = useState(!selectedBranch);
  const visibleBranches = useSelector(selectVisibleBranches);
  const selectedWeek = useSelector((state) => state.ui.shifts.roster.selectedWeek);
  const getFirstDayOfWeek = () => {
    return week || selectedWeek;
  };

  const unpublishedBranches = visibleBranches.filter(
    (branch) =>
      !publishedWeeks.find((wp) => wp.branchId === branch.id && wp.startDate === getFirstDayOfWeek() && wp.published)
  );

  const [selectedBranchIds, setSelectedBranchIds] = useState(
    selectedBranch ? [selectedBranch] : unpublishedBranches.map((b) => b.id)
  );

  const [selectedIsoWeeks, setSelectedIsoWeeks] = useState<number[]>([]);
  const [showMultiWeeks, setShowMultiWeeks] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const shifts = useSelector((state) => state.data.shifts);
  const isNotifyOnPublishChecked = useSelector((state) => state.ui.shifts.roster.isNotifyOnPublishChecked);

  const mom = simpleDateToMoment(getFirstDayOfWeek());
  const isoWeeks: number[] = [];
  const totalIsoWeeks = mom.isoWeeksInISOWeekYear();
  const startDayByIsoWeek = {};
  const disabledIsoWeeksMap = {};

  for (let i = mom.isoWeek(); i <= totalIsoWeeks; i++) {
    isoWeeks.push(i);
    const _startDate = mom.clone().isoWeek(i).startOf("isoWeek").format(SDateFormat);
    const hasPublishedWeek = publishedWeeks.find(
      (pw) => pw.published && pw.startDate === _startDate && selectedBranchIds.includes(pw.branchId)
    );
    disabledIsoWeeksMap[i] = hasPublishedWeek;
    startDayByIsoWeek[i] = _startDate;
  }

  const publishWeek = async () => {
    const firstDayOfWeek = getFirstDayOfWeek();
    await dispatch(publishWeekPlans(selectedBranchIds, firstDayOfWeek));

    if (isNotifyOnPublishChecked) {
      for (let branchId of selectedBranchIds) {
        const branchName = `(${visibleBranches.find((b) => b.id === branchId)?.name})`;
        const isInitialPublishing = !publishedWeeks.find(
          (wp) => wp.branchId === branchId && wp.startDate === firstDayOfWeek
        );
        const lastDayOfWeekMom = simpleDateToMoment(firstDayOfWeek).add(7, "days");
        const lastDayOfWeek = toSimpleDate(lastDayOfWeekMom);

        const allWeekPlanShifts = shifts
          .filter((s) => s.branchId === branchId)
          .filter((s) => s.date >= firstDayOfWeek && s.date <= lastDayOfWeek);
        const jobPosIdsOfOpenShifts = _.uniq(allWeekPlanShifts.filter((s) => !s.userId).map((s) => s.jobPositionId));
        const potentialOpenShiftApplicantIds = users
          .filter((u) => !!_.intersection(jobPosIdsOfOpenShifts, u.jobPositionIds).length)
          .map((u) => u.id);
        const alluserIdsInWeekPlan = allWeekPlanShifts.map((s) => s.userId).filter((id) => id !== undefined);
        const userIdsToInform = [...alluserIdsInWeekPlan, ...potentialOpenShiftApplicantIds];
        const usersUnique = _.uniq(userIdsToInform).filter((id) => !!id) as string[];

        Sentry.addBreadcrumb({
          message: "published Weekplan",
          data: { branchName, firstDayOfWeek },
        });

        await dispatch(Pusher.weekPlanPublished(usersUnique, firstDayOfWeek, isInitialPublishing, branchName));
      }
    }

    dispatch(closeModal());
  };

  const multiPublish = async () => {
    setLoading(true);
    for (let isoWeek of selectedIsoWeeks) {
      const weekStartDate = startDayByIsoWeek[isoWeek];
      await dispatch(publishWeekPlans(selectedBranchIds, weekStartDate));
    }
    setLoading(false);
    dispatch(closeModal());
  };

  return (
    <TZModal style={{ width: 500 }}>
      <TZModal.Head>
        {!showMultiWeeks ? lg.wochenplan_kw_week_veröffentlichen(mom.isoWeek()) : lg.wochenpläne_veröffentlichen}
      </TZModal.Head>
      <TZModal.Body style={{ minHeight: "50px" }}>
        <div className="pubhlishWeekModalMain">
          <div className="weekPlanUpdateMessageWrapper">
            <Alert
              message={
                lg.durch_das_veröffentlichen_des_wochenplans_werden_die_schichten_dieser_kalenderwoche_sichtbar_für_die_mitarbeiter
              }
              type="info"
              showIcon
            />
          </div>

          {(unpublishedBranches.length > 1 || !selectedBranch) && (
            <div className="weekPlancheckBoxWrapperMain fb column" style={{ paddingTop: 10 }}>
              {visibleBranches.length > 1 && (
                <BasicSelect
                  style={{ marginTop: 12, marginBottom: 12 }}
                  mode="multiple"
                  onChange={(ids: string[]) => {
                    setSelectedBranchIds(ids);
                    setSelectedIsoWeeks([]); // just in case, if it has been set, clear out.
                  }}
                  value={selectedBranchIds}
                  options={unpublishedBranches.map((b) => ({ label: b.name, value: b.id }))}
                />
              )}
            </div>
          )}
          <div className="checkboxWrapper">
            <Checkbox
              children={lg.mitarbeiter_benachrichtigen}
              checked={!!isNotifyOnPublishChecked}
              onClick={(e) => dispatch(setNotifyOnPublish(!isNotifyOnPublishChecked))}
              disabled={showMultiWeeks}
            />
          </div>
          <div className="checkboxWrapper paddingBottom">
            <Checkbox
              children={lg.mehrere_wochenpläne_veröffentlichen}
              checked={!!showMultiWeeks}
              onClick={(e) => {
                setShowMultiWeeks(!showMultiWeeks);
                setSelectedIsoWeeks([]);
                dispatch(setNotifyOnPublish(false));
              }}
            />
          </div>
          {showMultiWeeks && (
            <div className="multiWeekPublishPicker">
              <div className="head">Kalenderwochen wählen</div>
              <div className="content">
                {isoWeeks.map((isoWeek) => (
                  <div
                    key={isoWeek}
                    className={cn({
                      dayBox: true,
                      selected: selectedIsoWeeks.includes(isoWeek),
                      disabled: disabledIsoWeeksMap[isoWeek],
                    })}
                    onClick={() => {
                      const next = selectedIsoWeeks.includes(isoWeek)
                        ? selectedIsoWeeks.filter((w) => w !== isoWeek)
                        : [...selectedIsoWeeks, isoWeek];
                      console.log(next);
                      setSelectedIsoWeeks(next);
                    }}
                  >
                    {isoWeek}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </TZModal.Body>
      <TZModal.Footer>
        <Button
          disabled={selectedBranchIds.length < 1}
          id="publish-week"
          children={lg.veröffentlichen}
          type="primary"
          onClick={() => {
            showMultiWeeks ? multiPublish() : publishWeek();
          }}
          icon={isLoading ? "loading" : undefined}
        />
      </TZModal.Footer>
    </TZModal>
  );
});
