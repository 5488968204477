import sortBy from "lodash/sortBy";
import { WeekDay, WeekDays, IContract } from "../../../shared/entities/IContract";
import { toMomentUnsafe, simpleDateToMoment } from "../../../shared/helpers/timeHelpers";
import { IAbsence, AbsenceStatus } from "../../../shared/entities/IAbsence";
import moment from "moment";
import { IAbsenceEntitlement } from "../../../shared/entities/IAbsenceEntitlement";
import { IContingentCorrection } from "../../../shared/entities/IContingentCorrection";
import { IUpload, IUploadBase } from "../../../shared/entities/IUpload";
import { rangesOverlap } from "../../../shared/helpers/dateHelpers";
import { HttpError } from "../../../frontend-core/actions/send";
import * as Sentry from "@sentry/browser";
import { IVacationBan } from "../../../shared/entities/IRosterSettings";
import { IUser } from "../../../shared/entities/IUser";
import { isRuleApplyingToUser } from "../../../shared/helpers/settingsHelpers";
import { GetState } from "../../../types/AppState";
import { selectAbsenceMap } from "../../../selectors/mapSelectors";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import _ from "lodash";
import { canManageAbsencesSelector } from "../../../selectors/canManageAbsencesSelector";

export const getWeekDayOfSimpleDate = (date: string): WeekDay => {
  const weekdayNum = toMomentUnsafe(date as string)!.isoWeekday() - 1;
  return WeekDays[weekdayNum];
};

export const getTotalDaysCount = (absence: IAbsence) => {
  const startMoment = simpleDateToMoment(absence.startDate);
  const endMoment = simpleDateToMoment(absence.endDate);
  let totalDays = moment(endMoment).diff(startMoment, "days") + 1;
  if (absence.startsHalfDay) {
    totalDays -= 0.5;
  }
  if (absence.startDate !== absence.endDate && absence.endsHalfDay) {
    totalDays -= 0.5;
  }
  return totalDays;
};

export const absenceOverlaps = (absence: Partial<IAbsence>, absences: IAbsence[]) => {
  const { startDate, endDate, id } = absence;
  return !!absences.find(
    (a) =>
      a.id !== id && rangesOverlap(a.startDate as string, a.endDate as string, startDate as string, endDate as string) // to not warn when it overlaps with itselfe
  );
};

export const getLatestEntitlementEntry = (
  absenceEntitlements: IAbsenceEntitlement[],
  selectedYear: number
): IAbsenceEntitlement | undefined => {
  const contingentsOfPast = absenceEntitlements.filter((c) => c.year <= selectedYear);
  return sortBy(contingentsOfPast, ["year"]).reverse()[0];
};

export const getRemainingEntitlementDays = (
  contingentCorrection: IContingentCorrection | undefined,
  contingentOverrideCorrection: IContingentCorrection | undefined,
  takenAbsenceDays: number | undefined,
  absenceEntitlements: IAbsenceEntitlement[],
  selectedYear: number
): number | undefined => {
  const latestEntry = getLatestEntitlementEntry(absenceEntitlements, selectedYear);
  if (!latestEntry) {
    return undefined;
  }

  const overrideCorrection = contingentOverrideCorrection ? contingentOverrideCorrection.days : 0;
  const correction = contingentCorrection ? contingentCorrection.days : 0;
  const latestContingent = latestEntry ? latestEntry.days : 0;

  return (contingentOverrideCorrection ? overrideCorrection : latestContingent) - correction - (takenAbsenceDays || 0);
};

export const isUploaded = (upload: IUpload | IUploadBase): boolean => {
  return !!(upload as any).url;
};

export const getViolatingVacationBan = (
  user: IUser,
  absence: Partial<IAbsence>,
  vacationBans: IVacationBan[] = []
): IVacationBan | undefined => {
  const userVacationBans = (vacationBans || []).filter((b) => isRuleApplyingToUser(b.appliesTo, user));
  return userVacationBans.find(
    (ban) =>
      absence.startDate &&
      absence.endDate &&
      absence.typeId &&
      rangesOverlap(ban.from, ban.until, absence.startDate!, absence.endDate!) &&
      (!ban.typeId || ban.typeId === absence.typeId)
  );
};

export const getAbsenceCreateLog =
  (absence: IAbsence) =>
  (disp, getState: GetState): string | undefined => {
    const absencesMap = selectAbsenceMap(getState());
    const sessionInfo = selectSessionInfo(getState());
    const canManageAbsences = canManageAbsencesSelector(getState());
    const prevAbsence = absencesMap[absence.id];
    const userId = sessionInfo.user.id;
    const unix = Math.round(Date.now() / 1000);
    return !prevAbsence && canManageAbsences ? `${userId}_${unix}` : prevAbsence?.createdLog;
  };

export const getAbsenceEditLog =
  (absence: IAbsence) =>
  (disp, getState: GetState): string | undefined => {
    const absencesMap = selectAbsenceMap(getState());
    const sessionInfo = selectSessionInfo(getState());
    const canManageAbsences = canManageAbsencesSelector(getState());
    const prevAbsence = absencesMap[absence.id];
    const userId = sessionInfo.user.id;
    const unix = Math.round(Date.now() / 1000);
    const relevantProps = ["startDate", "endDate", "typeId", "earning", "effectiveDaysOverride"];
    const hasChanged = !_.isEqual(_.pick(absence, relevantProps), _.pick(prevAbsence || {}, relevantProps));
    return prevAbsence && hasChanged && canManageAbsences ? `${userId}_${unix}` : prevAbsence?.createdLog;
  };
