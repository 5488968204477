import { ReportType } from "../../../actions/reporting";
import { DispFn } from "../../../frontend-core/types/thunkTypes";
import { WeekDay } from "../../../shared/constants/WeekDays";
import { AppState } from "../../../types/AppState";

export const UPDATE_REPORT_EXCLUDED_WEEK_DAYS = "UPDATE_REPORT_EXCLUDED_WEEK_DAYS";
//
export const updateReportExcludedWeekDays = (payload: WeekDay[]) => ({
  type: UPDATE_REPORT_EXCLUDED_WEEK_DAYS,
  payload,
});

const initialState: WeekDay[] = [];

export const reportExcludedWeekDaysReducer = (
  state = initialState,
  a: { payload: WeekDay[]; type: string }
): WeekDay[] => (a.type === UPDATE_REPORT_EXCLUDED_WEEK_DAYS ? a.payload : state || []);
