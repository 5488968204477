import moment from "moment";

const weekDayLabelsEN = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
const weekDayLabelsDE = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];

export const getWeekDayLabels = (lang: "en" | "de") => ({
  mo: lang === "en" ? "Mo" : "Mo",
  tu: lang === "en" ? "Tu" : "Di",
  we: lang === "en" ? "We" : "Mi",
  th: lang === "en" ? "Th" : "Do",
  fr: lang === "en" ? "Fr" : "Fr",
  sa: lang === "en" ? "Sa" : "Sa",
  su: lang === "en" ? "Su" : "So",
});

export const getWeekDayIndex = (wd: WeekDay) => {
  return WeekDays.indexOf(wd);
};

export const WeekDays = ["mo", "tu", "we", "th", "fr", "sa", "su"] as Array<WeekDay>;
export type WeekDay = "mo" | "tu" | "we" | "th" | "fr" | "sa" | "su";
