import { getMonthIntervalStartDate, getMonthIntervalEndDate } from "./../../shared/helpers/dateHelpers";
import { selectRosterDateRange } from "../../selectors/rosterDateRangeSelector";
import { getRosterModeSelection } from "./../../helpers/rosterHelpers";
import { BalanceAction } from "../../repositories/creditBalanceRepository";
import { getBalances } from "./getBalances";
import { getUsersWithMinMaxHours, getUsersWithMonthlyContract } from "./creditHelpers";
import { getPrevSimpleDate } from "../../shared/helpers/dateHelpers";
import { getCreditIntervals } from "./getCreditIntervals";
import { getActiveUsersAtDate } from "../../selectors/ActiveUserSelectors";
import { AppState } from "../../types/AppState";
import _ from "lodash";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import { CreditIntervalAction } from "../../repositories/creditIntervalRepository";

let userIdsToProcess: string[] = [];
let debounceIsRunning = false;

export const updateCreditsOfCurrentRoster = () => (dispatch: DispFn, getState: () => AppState) => {
  const state = getState();
  const users = state.data.users;
  const selectedBranchId = state.ui.selectedBranch;
  return dispatch(
    updateCreditsOfRoster(
      users.filter((u) => !selectedBranchId || u.branchIds.includes(selectedBranchId)).map((u) => u.id)
    )
  );
};

export const updateCreditsOfRoster = (
  _userIds: string[],
  selfCalled = false // 'selfCalled' is used to debounce the action
) => (disp: DispFn, getState: () => AppState) => {
  //// debounce logic START
  if (!selfCalled) {
    if (!debounceIsRunning) {
      debounceIsRunning = true;
      userIdsToProcess = [..._userIds];
      setTimeout(() => disp(updateCreditsOfRoster([], true)), 100);
    } else {
      userIdsToProcess = [...userIdsToProcess, ..._userIds];
    }
    return;
  }

  const uniqUserIds = _.uniq([...userIdsToProcess]);
  debounceIsRunning = false;
  userIdsToProcess = [];
  //// debounce logic END

  const { users, contracts } = getState().data;
  const { rangeStart, rangeEnd } = selectRosterDateRange(getState());
  const customMonthStartDay = getState().data.rosterSettings[0].customMonthIntervalStart;
  const preRangeStart = getPrevSimpleDate(rangeStart);

  const allActiveUserIds = getActiveUsersAtDate(users, rangeStart).map((u) => u.id);
  let userIds = _.intersection(allActiveUserIds, uniqUserIds);

  const _monthlyUsers = getUsersWithMonthlyContract(rangeStart, contracts);
  const monthlyUsers = _.intersection(userIds, _monthlyUsers) as string[];
  const weeklyUsers = _.difference(userIds, _monthlyUsers) as string[];
  const usersWithMinMaxHours = getUsersWithMinMaxHours(rangeStart, contracts);
  const monthlyAndMinMaxUsers = _.uniq([...monthlyUsers, ...usersWithMinMaxHours]);

  const startOfMonth = getMonthIntervalStartDate(rangeStart, customMonthStartDay);
  const endOfMonth = getMonthIntervalEndDate(rangeStart, customMonthStartDay);
  const preStartOfMonth = getPrevSimpleDate(startOfMonth);

  userIds = weeklyUsers; // to exclude monthlyUsers, because they get handled in this block
  const balancesM = disp(getBalances(monthlyAndMinMaxUsers, preStartOfMonth));
  const intervalsM = disp(getCreditIntervals(monthlyAndMinMaxUsers, startOfMonth, endOfMonth));
  disp({ type: BalanceAction.SET, payload: balancesM });
  disp({ type: CreditIntervalAction.SET, payload: intervalsM });

  const balances = disp(getBalances(userIds, preRangeStart));
  const intervals = disp(getCreditIntervals(userIds, rangeStart, rangeEnd));
  disp({ type: BalanceAction.SET, payload: balances });
  disp({ type: CreditIntervalAction.SET, payload: intervals });
};
