import { wrongTimeZoneActionType } from "../../actions/checkServerTime";
import { envi } from "../../env";

export const wrongTimeZoneReducer = (state = false, action: any): boolean | string => {
  switch (action.type) {
    case wrongTimeZoneActionType.SET:
      return envi.isDev() ? false : action.payload;
    default:
      return state;
  }
};
