import { IResource } from "./IResource";

export enum PushNoteTopic {
  roster = "roster",
  absence = "absence",
  handover = "handover",
  shift = "shift",
  chat = "chat",
  announcement = "announcement",
}

export enum PushNoteType {
  openShiftAvailable = "openShiftAvailable",
  handOverAvailable = "handOverAvailable",
  handOverProposed = "handOverProposed",
  shiftApplicationResolved = "shiftApplicationResolved",
  handOverResolved = "handOverResolved",
  weekPlanPublished = "weekPlanPublished",
  // lateToShiftRemind = "lateToShiftRemind", // disabled this functionality sofar
  lateToShiftInform = "lateToShiftInform", // this goes to the managers
  changeRequested = "changeRequested",
  pendingHandOverRequest = "pendingHandOverRequest",
  shiftTimeChanged = "shiftTimeChanged",
  shiftDeleted = "shiftDeleted",
  shiftAssigned = "shiftAssigned",
  shiftDateChanged = "shiftDateChanged",
  absenceRequestResolved = "absenceRequestResolved",
  absenceRequestCreated = "absenceRequestCreated",
  changeRequestResolved = "changeRequestResolved",
  chatMessage = "chatMessage",
  announcementCreated = "announcementCreated",
}

export type IPushNoteBase = {
  title: string;
  message: string;
  topic: PushNoteTopic;
  params: { date?: string; branchId?: string; shiftId?: string; threadId?: string; announcementId?: string };
  type: PushNoteType;
  needsToBeSent?: boolean;
};

export interface IPushNote extends IResource, IPushNoteBase {
  timestamp: number; // unix-timestamp
  creator?: string; // userId
  hasSeen: boolean;
  userId: string;
}

export interface IPushNoteDB extends IPushNote {
  userId_hasSeen: string;
}
