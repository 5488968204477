import React, { useMemo, useEffect, useState } from "react";
import { IShift } from "../../../../shared/entities/IShift";
import { Icon, Alert, Modal, DatePicker, Select, Button } from "antd";
import { toMoment, toSimpleDate } from "../../../../shared/helpers/timeHelpers";
import WeekDayPicker from "../../../modals/AvailabilityModal/weekDayPicker/WeekDayPicker";
import "./styles.scss";
import { getWeekDayString } from "../../../../shared/helpers/weekDaysList";
import { getWeekDayIndex, getWeekDayLabels } from "../../../../shared/constants/WeekDays";
import { useDispatch } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { repeatEveryOptions } from "../../../../constants/repeatOptions";
import { IShiftRepeat } from "../../../../shared/entities/IShiftRepeat";
import { v4 as uuid } from "uuid";
import { getLocaleLang } from "../../../../helpers/dateFormatHelper";

type Props = {
  shift: Partial<IShift>;
  isCreationMode: boolean;
  updateShiftRepeat: (rS: IShiftRepeat) => void;
  shiftRepeat?: IShiftRepeat;
  collapseField: () => any;
  uneditable?: boolean;
};

export const RepeatingShiftOptions = (props: Props) => {
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  useEffect(() => {
    if (props.isCreationMode) {
      props.updateShiftRepeat({
        id: uuid(),
        startDate: props.shift.date!,
        repeatEvery: 1,
        weekDays: [getWeekDayString(props.shift.date!)],
        branchId: props.shift.branchId!,
        userId: props.shift.userId,
      });
    }
  }, []);

  if (!props.shiftRepeat) {
    return null; // this is for the initiyl render. OnMount the shiftRepeat get set
  }

  const setOptionValue = (key: keyof IShiftRepeat, value: any) => {
    props.updateShiftRepeat({
      ...props.shiftRepeat!,
      [key]: value,
    });
  };

  const isEditable = !props.shift.repeatId;
  const { endDate, startDate, repeatEvery, weekDays } = props.shiftRepeat;
  const today = toSimpleDate(moment());

  const endDateMinDate = toMoment(_.max([startDate, today, props.shift.date])!);
  const lang = getLocaleLang();

  if (!props.isCreationMode) {
    const repeateTxt = repeatEveryOptions.find((o) => o.value === repeatEvery)?.label;
    const weekDaysTxt = _.orderBy(weekDays, (w) => getWeekDayIndex(w)).map((wd) => getWeekDayLabels(lang)[wd]);
    const endDateDisplay = endDate && toMoment(endDate).format("L");
    const endDateLabel = endDate ? `${lg.bis_zum} ${endDateDisplay}` : lg.ohne_enddatum;

    return (
      <div className="avInputFieldMain repeatingShiftDisplayMain">
        <span className="labelWrapper">
          <label>{lg.wiederholend}</label>
        </span>
        <div className="content">
          <div className="repeateEveryLabel">{repeateTxt}</div>
          <div className="weekDaysDisplayWrapper">{weekDaysTxt.join(", ")}</div>
          <div className="endDate" data-rh={endDate ? lg.ändern : lg.enddatum_hinzufügen}>
            <span onClick={() => setDatePickerOpen(true)}>{endDateLabel}</span>
            <DatePicker
              open={isDatePickerOpen}
              onChange={(m) => m && setOptionValue("endDate", toSimpleDate(m))}
              value={endDate ? toMoment(endDate) : undefined}
              disabledDate={(date) => !!date?.isBefore(endDateMinDate)}
              // allowClear={false}
              format={"DD. MMM YYYY"}
              onOpenChange={(v) => setDatePickerOpen(v)}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="avInputFieldMain">
      <div className="labelWrapper" style={{ paddingTop: 6 }}>
        {lg.wiederholend}
      </div>
      <div className="repeatOptionsWrapper">
        <div className="fb row aCenter">
          {isEditable && (
            <div className="closeExpandedFieldButton" onClick={props.collapseField}>
              <Icon type="close" />
            </div>
          )}
        </div>
        <div className="fb row" style={{ marginTop: 0 }}>
          {/* <DatePicker
              disabled
              disabledDate={(date) =>
                !!date?.isBefore(toMoment(props.shift.date!))
              }
              value={toMoment(repeatOptions.startDate)}
              onChange={(v) =>
                setOptionValue("startDate", v ? toSimpleDate(v) : undefined)
              }
              format="DD. MMM YYYY"
              allowClear={false}
            /> */}

          <Select
            style={{ width: 119 }}
            disabled={!isEditable}
            value={repeatEvery}
            onChange={(v) => {
              setOptionValue("repeatEvery", v);
            }}
          >
            {repeatEveryOptions.map((o) => (
              <Select.Option value={o.value} key={o.value} title={o.label}>
                {o.label}
              </Select.Option>
            ))}
          </Select>

          {isEditable && (
            <DatePicker
              disabledDate={(date) => !!date?.isBefore(endDateMinDate)}
              style={{ marginLeft: 10, width: 130 }}
              value={endDate ? toMoment(endDate) : undefined}
              defaultPickerValue={toMoment(startDate).add(1, "month")}
              onChange={(v) => setOptionValue("endDate", v ? toSimpleDate(v) : undefined)}
              placeholder={lg.enddatum}
              allowClear={true}
            />
          )}

          <WeekDayPicker
            mainStyle={{ borderRadius: 4, overflow: "hidden" }}
            dayStyle={{ marginRight: 0, borderRadius: 0, height: 28, width: 44 }}
            disabled={!isEditable}
            alwaysActiveWeekDays={[getWeekDayString(props.shift.date!)]}
            value={props.shiftRepeat.weekDays || []}
            onChange={(value) => setOptionValue("weekDays", value)}
          />

          {!isEditable && (
            <div className="endDateDisplay">
              {endDate ? (
                <div>
                  {lg.bis} {toMoment(endDate).format("L")}
                </div>
              ) : (
                <Icon type="calendar" style={{ fontSize: 16 }} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
