import React, { useCallback, useMemo } from "react";
import { DayGridShift } from "./DayGridShift/DayGridShift";
import { IUser } from "../../../../shared/entities/IUser";
import { AbsenceStatus, IAbsence } from "../../../../shared/entities/IAbsence";
import { IShift } from "../../../../shared/entities/IShift";
import { useSelector } from "../../../../helpers/redux";
import GridUserCell from "../RosterWeekGrid/GridUserCell/GridUserCell";
import { RosterMode } from "../../../../reducers/ui/shifts/roster/rosterMode";
import { AvIcon } from "../../../../components/AvIcon/AvIcon";
import { AbsenceTypeCode } from "../../../../shared/entities/IAbsenceType";
import { toSimpleDate } from "../../../../shared/helpers/timeHelpers";
import moment from "moment";
import { doShiftsOverlap } from "../../../../selectors/shiftOverlappingsSelector";
import { selectCreditSpanForUser } from "../../../../selectors/creditSpansSelector";
import { selectContractsByUser } from "../../../../selectors/contractsByUserSelector";
import { featuresSelector } from "../../../../selectors/FeaturesSelector";
import { selectRosterDateRange } from "../../../../selectors/rosterDateRangeSelector";
import { selectInitialCreditByUser } from "../../../../selectors/initialCreditsByUserSelector";
import { selectJobPositionMap, selectUserMap } from "../../../../selectors/mapSelectors";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../actions/modal";
import { ShiftPopup } from "../../../../components/ShiftPopup/ShiftPopup/ShiftPopup";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import cn from "classnames";
import { selectVisibleAbsencesByUser } from "../../../../selectors/absencesByUserSelector";
import { Icon } from "antd";
import { RosterRowCollapseButton } from "../components/RosterRowCollapseButton/RosterRowCollapseButton";
import { getValidContract } from "../../../../shared/helpers/credit";
import { monthlyMaxHoursWarningSelector } from "../../../../selectors/monthlyMaxHoursWarningSelector";
import { setOpenShiftsCollapsed } from "../../../../reducers/ui/shifts/roster/isOpenShiftsCollapsed";
import { setRequiredShiftsCollapsed } from "../../../../reducers/ui/shifts/roster/isRequiredShiftsCollapsed";
import { assignedShiftsSelector } from "../../../../selectors/assignedShiftsSelector";
import { OpenShiftsRowInfoIcon } from "../RosterWeekGrid/OpenShiftsRowInfoIcon/AddUserRow";
import { canUserEditOwnShiftsOfDate } from "../helpers";
import { selectRosterSettingsByUser } from "../../../../selectors/rosterSettingsByUserSelector";

type Props = {
  userId?: string;
  isRequirement?: boolean;
  hoursInDay: number[];
  date: string;
  getTime: (time: string, format: string) => number;
  shiftsOfDay: IShift[];
  jobPositionId?: string;
  groupByJobPosition: boolean;
  style: Object;
  nowSimpleTime: string;
};

const getSlotKey = (date: string, userId?: string, jobPositionId?: string) => {
  return [date, userId, jobPositionId].filter((s) => !!s).join("_");
};

const getTimeFromNumber = (t: number) => `${t > 9 ? t : "0" + t}:00`;

export const DayGridRow = React.memo((props: Props) => {
  const dispatch = useDispatch();
  const usersMap = useSelector(selectUserMap);
  const user = props.userId ? usersMap[props.userId] : undefined;
  const sessionInfo = useSelector(selectSessionInfo);
  const jobPosMap = useSelector(selectJobPositionMap);
  const contractsMap = useSelector(selectContractsByUser);
  const features = useSelector(featuresSelector);
  const initialUserCredits = useSelector(selectInitialCreditByUser);
  const rosterSettings = useSelector((s) => s.data.rosterSettings[0]);
  const rosterSettingsByUser = useSelector(selectRosterSettingsByUser);
  const rosterDateRange = useSelector(selectRosterDateRange);
  const absencesOfUser = useSelector((s) => (user?.id ? selectVisibleAbsencesByUser(s)[user?.id] : undefined));
  const maxHoursExceedingMap = useSelector(monthlyMaxHoursWarningSelector);

  const isTemplateMode = useSelector((s) => s.ui.shifts.roster.rosterTemplateMode.active);
  const isOpenShiftsCollapsed = useSelector((s) => s.ui.shifts.roster.isOpenShiftsCollapsed);
  const isRequiredShiftsCollapsed = useSelector((s) => s.ui.shifts.roster.isRequiredShiftsCollapsed);
  const isV2 = useSelector((s) => s.data.tenantInfo.isV2);

  const assignedShiftsMap = useSelector(assignedShiftsSelector);

  const canManage = sessionInfo.hasManagerPermissions();
  const showCredits = (user && canManage) || undefined;
  const creditSpan = useSelector((s) => showCredits && selectCreditSpanForUser(s, user!.id), _.isEqual);

  const onEmptyRowClick = useCallback(
    (startTime?: string) => {
      const newShift: Partial<IShift> = {
        date: props.date,
        jobPositionId: props.jobPositionId,
        userId: user?.id,
        startTime,
        isRequirement: props.isRequirement || undefined,
      };
      dispatch(openModal(ShiftPopup, { shift: newShift }));
    },
    [props.date]
  );

  const { hoursInDay, date, getTime, shiftsOfDay, jobPositionId, groupByJobPosition } = props;
  const isOpenShift = !user;

  const _shifts =
    !groupByJobPosition || isOpenShift ? shiftsOfDay : shiftsOfDay.filter((s) => s.jobPositionId === jobPositionId);
  const shifts = _.orderBy(_shifts, ["startTime", "branchId", "jobPositionId", "addressId"]);

  const absence = absencesOfUser?.find(
    (a) => a.endDate >= date && a.startDate <= date && a.status === AbsenceStatus.active
  ) as IAbsence;

  const dayGridRowRef = React.createRef<HTMLDivElement>();

  const onRowClick = (e) => {
    const rowWidth = dayGridRowRef.current?.clientWidth || 0;
    const bounds = dayGridRowRef.current?.getBoundingClientRect() as DOMRect;
    const mouseOffset = e.clientX - bounds?.left;
    const hour = Math.floor((mouseOffset / rowWidth) * hoursInDay.length);
    const targetTime = getTimeFromNumber(hoursInDay[hour]);

    return onEmptyRowClick(targetTime);
  };

  const someShiftsOverlap = useMemo(
    () => shifts.some((shift) => shifts.some((_shift) => _shift.id !== shift.id && doShiftsOverlap(shift, _shift))),
    [shifts]
  );

  const showOpenShiftsCount = !user && !props.isRequirement && isOpenShiftsCollapsed;
  const showRequirementCount = props.isRequirement && isRequiredShiftsCollapsed;

  const openShiftsCount = showOpenShiftsCount && shifts.length && _.sumBy(shifts, (s) => s.requiredUsersAmount || 0);

  const requiredShiftsCount =
    showRequirementCount && shifts.length && _.sumBy(shifts, (s) => s.requiredUsersAmount || 0);

  const assignedShiftsCount =
    showRequirementCount && shifts.length && _.sumBy(shifts, (s) => assignedShiftsMap?.[s.id] || 0);

  const canEditOwnShifts =
    !canManage && user?.id === sessionInfo.user.id && canUserEditOwnShiftsOfDate(rosterSettingsByUser[user.id], date);

  return (
    <div style={props.style}>
      <div
        className={cn({
          gridRow: true,
          unassignedShiftsRow: !user && !props.isRequirement,
        })}
      >
        <div className="user cell firstColumn">
          {isOpenShift && (
            <div className="userListItemMain openShiftsCell">
              <div className="openShiftsDayView">
                {props.isRequirement ? lg.personalbedarf : lg.offene_schichten}
                {props.isRequirement ? null : <OpenShiftsRowInfoIcon />}
                <RosterRowCollapseButton isRequirement={!!props.isRequirement} />
              </div>
            </div>
          )}
          {user && (
            <GridUserCell
              user={user}
              startDate={date}
              rosterMode={RosterMode.Day}
              jobPosMap={jobPosMap}
              creditSpan={creditSpan}
              contract={getValidContract(contractsMap[user.id], date)!}
              features={features}
              initialUserCredit={initialUserCredits[user.id]}
              rosterDateRange={rosterDateRange}
              isTemplateMode={isTemplateMode}
              customMonthStartDay={rosterSettings.customMonthIntervalStart || 1}
              canManage={sessionInfo.hasManagerPermissions()}
              maxHoursExceeding={maxHoursExceedingMap[user.id]}
              isV2={isV2}
            />
          )}
        </div>
        <div
          className={cn({ dayGridRow: true, canClick: canManage || canEditOwnShifts })}
          style={{ backgroundSize: `${100 / hoursInDay.length}%` }}
          data-slot-id={getSlotKey(date, user?.id, jobPositionId)}
          data-type="grid-slot"
          onClick={(e) => (canManage || canEditOwnShifts) && onRowClick(e)}
          ref={dayGridRowRef}
        >
          {absence && (
            <div className="absence">
              <AvIcon
                style={{
                  opacity: absence.typeCode === AbsenceTypeCode.illness ? "1" : "0.6",
                }}
                type={
                  absence.typeCode === AbsenceTypeCode.illness
                    ? "icon-flat_band_aid-patch-plaster"
                    : "icon-beach_access"
                }
              />
            </div>
          )}
          {!showOpenShiftsCount &&
            !showRequirementCount &&
            shifts.map((shift, i) => {
              const prevDate = toSimpleDate(moment(date).subtract(1, "days"));
              const fromPreviousDay = shift.date === prevDate;
              const _shift = { ...shift, endTime: shift.isActiveClocking ? props.nowSimpleTime : shift.endTime };

              return (
                <DayGridShift
                  key={shift.id + "-" + i}
                  customKey={shift.id + "-" + i}
                  shift={_shift}
                  hoursInDay={hoursInDay}
                  getTime={getTime}
                  absence={absence}
                  jobPosMap={jobPosMap}
                  fromPreviousDay={fromPreviousDay}
                  displayInSeparateRows={someShiftsOverlap}
                  canClick={canManage || shift.userId === sessionInfo.user.id || !shift.userId}
                  selectedDate={props.date}
                  nowSimpleTime={props.nowSimpleTime}
                />
              );
            })}
          {showOpenShiftsCount && !!openShiftsCount && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setOpenShiftsCollapsed(false));
              }}
              data-rh={openShiftsCount + " " + lg.offene + " " + (openShiftsCount === 1 ? lg.schicht : lg.schichten)}
              className="openShiftsCountDaily"
            >
              {openShiftsCount}
            </div>
          )}
          {showRequirementCount && !!requiredShiftsCount && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setRequiredShiftsCollapsed(false));
              }}
              className={cn({
                openShiftsCountDaily: true,
                isRequirement: true,
                isRequirementComplete: (assignedShiftsCount || 0) >= (requiredShiftsCount || 0),
              })}
            >
              {assignedShiftsCount + " / " + requiredShiftsCount}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
