import React, { useState } from "react";
import { BusyInjectorProps, busyInjector } from "../BusyInjector/BusyInjector";
import { useSelector } from "../../helpers/redux";
import { useDispatch, batch } from "react-redux";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import { closeModal } from "../../actions/modal";
import { sendPost } from "../../frontend-core/actions/send";
import { authRepository } from "../../repositories/authRepository";
import { IUserFull, RoleType } from "../../shared/entities/IUser";
import { notification, Button, Input, Select } from "antd";
import TZModal from "../TZModal/TZModal";
import BusyWrapper from "../BusyWrapper/BusyWrapper";
import { decryptUser } from "../../shared/helpers/userHelpers";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { UserInfo } from "../../shared/helpers/UserInfo";
import moment from "moment";
import { toSimpleDate } from "../../shared/helpers/timeHelpers";
import { isUserActive } from "../../selectors/ActiveUserSelectors";

type Props = {};

const _CloseTimeClockStationModal = (props: Props & BusyInjectorProps) => {
  const dispatch = useDispatch<DispFn>();
  const auth = useSelector((s) => s.data.auth);
  const sessionInfo = useSelector(selectSessionInfo);
  const today = toSimpleDate(moment());
  const users = useSelector((s) => s.data.users);
  const _managingUsers = users.filter((u) => u.role !== RoleType.employee && isUserActive(u, today));
  const managingUsers = _managingUsers.map(decryptUser).filter((u) => u.email); // managing fullUsers with email

  const [selectedUserId, selectUserId] = useState<string>(sessionInfo.user.id);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const submit = async () => {
    try {
      props.setLoading("all", true);
      const url = `/api/login`;
      const user = users.find((u) => u.id === selectedUserId)!;
      const email = decryptUser(user).email!;
      const loginToken = (await dispatch(sendPost(url, { email, password }))) as any;
      if (loginToken) {
        if (loginToken.role === RoleType.admin || loginToken.role === RoleType.manager) {
          batch(() => {
            dispatch(authRepository.logout());
            notification.success({ message: lg.stations_modus_beendet });
            dispatch(closeModal());
          });
        } else {
          setError(lg.du_hast_nicht_die_notwendigen_rechte);
        }
      }
    } catch (e) {
      setError(lg.falsches_passwort);
    } finally {
      props.setLoading("all", false);
    }
  };

  return (
    <TZModal>
      <TZModal.Head>{lg.stations_modus_beenden}</TZModal.Head>
      <TZModal.Body>
        <BusyWrapper isBusy={props.isLoading("all")} style={{ padding: "24px 24px 8px 24px" }}>
          <div className="formField">
            <Select
              value={selectedUserId}
              onChange={(userId: string) => selectUserId(userId)}
              style={{ width: "100%" }}
            >
              {managingUsers.map((u) => (
                <Select.Option value={u.id}>{u.name}</Select.Option>
              ))}
            </Select>
          </div>
          <div className="formField">
            <Input.Password
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  submit();
                }
              }}
              type="text"
              onChange={(e) => setPassword(e.target.value)}
              placeholder={lg.passwort}
              name="password"
              autoComplete="off"
              size="large"
            />
          </div>
          {!!error && <span style={{ color: "red", marginTop: -12, marginBottom: 18 }}>{error}</span>}
        </BusyWrapper>
      </TZModal.Body>
      <TZModal.Footer>
        <Button
          id="leave-timeclock-kiosk"
          disabled={!selectedUserId || !password}
          onClick={submit}
          children={lg.stations_modus_beenden}
        />
      </TZModal.Footer>
    </TZModal>
  );
};

export const CloseTimeClockStationModal = React.memo(busyInjector(_CloseTimeClockStationModal));
