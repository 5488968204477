import React from "react";
import { connect } from "react-redux";
import { openModal } from "../../../../../actions/modal";
import { ITracking } from "../../../../../shared/entities/ITracking";
import { AppState } from "../../../../../types/AppState";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { CreditEntryBar } from "../CreditEntryBar/CreditEntryBar";
import "./styles.scss";
import { IShift } from "../../../../../shared/entities/IShift";
import { getShiftCredit } from "../../../../../shared/helpers/credit";
import { ShiftPopup } from "../../../../../components/ShiftPopup/ShiftPopup/ShiftPopup";
import { Icon } from "antd";
import { updateCreditsUISelection } from "../../../../../reducers/ui/shifts/credits";
import { updateHinting } from "../../../../../actions/hinting";
import { featuresSelector } from "../../../../../selectors/FeaturesSelector";

const mapStateToProps = (state: AppState) => ({
  rosterSetting: state.data.rosterSettings[0],
  hasHourAccountFeature: featuresSelector(state).credits,
});
type OwnProps = {};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

export class CreditsHourAccountInfoComp extends React.PureComponent<Props> {
  onClose = () => {
    // this.props.dispatch({});
    this.props.dispatch(updateCreditsUISelection({ showHourAccountInfo: false }));
    !this.props.rosterSetting.hinting?.hasSeen_hourAccountFunction &&
      this.props.dispatch(
        updateHinting({
          hasSeen_hourAccountFunction: true,
        })
      );
  };

  render() {
    // const { shift, branches, tracking } = this.props;

    return (
      <div className="creditsHourAcountInfoMain">
        <div className="leftWrapper">
          <div className="leftText">
            {this.props.hasHourAccountFeature
              ? lg.trage_in_der_Spalte_Stundenkonto_einen_Startwert_ein()
              : lg.fuer_die_funktion_stundenkonto_ist_ein_upgrade_erforderlich()}
          </div>
          <div className="closeIconWrapper" onClick={this.onClose}>
            <Icon type="close" />
          </div>
        </div>
        <div className="rightWrapper">
          <Icon type="arrow-down" />
        </div>
      </div>
    );
  }
}

export const CreditsHourAccountInfo = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  CreditsHourAccountInfoComp
);
