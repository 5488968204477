import { IUserTimeClockSettings } from "../shared/entities/ITimeClockSettings";
import { generateUserTimeClockSettings } from "../shared/helpers/settingsHelpers";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import { selectSessionInfo } from "./SessionInfoSelector";

export const selectTimeClockSettingsOfSesionUser = createSelector(
  [selectSessionInfo, (state: AppState) => state.data.timeClockSettings[0]],
  (sessionInfo, timeClockSettings): IUserTimeClockSettings => {
    return generateUserTimeClockSettings(timeClockSettings, sessionInfo.user);
  }
);
