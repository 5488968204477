import React from "react";
import { DatePicker, Icon, InputNumber, Switch, Checkbox, Popover } from "antd";

import cn from "classnames";
import "./styles.scss";
import { Moment } from "moment";
import _ from "lodash";
import WorkIntervalField from "./WorkIntervalField/WorkIntervalField";
import DurationInput from "./DurationInput/DurationInput";
import {
  IContractCore,
  DailyQuota,
  WeekDays,
  WeekDay,
  WorkInterval,
  WeekdayKey,
  getWeekDayLabels,
} from "../../../../shared/entities/IContract";
import { toSimpleDate, minutesToHours } from "../../../../shared/helpers/timeHelpers";
import { isWorkDay } from "../../../../shared/helpers/credit";
import { ContractEntryHead } from "../ContractEntryHead/ContractEntryHead";
import { useDispatch, connect } from "react-redux";
import { openModal, closeModal } from "../../../../actions/modal";
import { IntervalStartDayModal } from "../UserContractsTab/IntervalStartDayModal/IntervalStartDayModal";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { AppState } from "../../../../types/AppState";
import { selectCustomMonthStartDay } from "../../../../reducers/ui/general/rosterMonthStartDay";
import { IRosterSettings } from "../../../../shared/entities/IRosterSettings";
import AvFormikSelect from "../../../../components/AvFormikSelect/AvFormikSelect";
import { selectActiveBranches } from "../../../../selectors/ActiveBranchesSelector";
import { BasicSelect } from "../../../../components/BasicSelect/BasicSelect";
import { MainBranchPicker } from "./MainBranchPicker/MainBranchPicker";
import { decimalSeparatorLocal, getLocaleLang } from "../../../../helpers/dateFormatHelper";
import { MinMaxHoursEditor } from "./MinMaxHoursEditor/MinMaxHoursEditor";
import { selectIsFreshTenant } from "../../../../selectors/selectIsFreshTenant";
import { UserTypeEditor } from "./UserTypeEditor/UserTypeEditor";
import { HourlyWageEditor } from "./HourlyWageEditor/HourlyWageEditor";

type State = {
  isMainBranchPickerOpen: boolean;
};

type OwnProps = {
  contract: IContractCore;
  previousContract?: IContractCore;
  nextContract?: IContractCore;
  displayValidFromDate: boolean;
  isEditable: boolean;
  onContractChange: (wp: IContractCore) => void;
  onDelete: () => void;
  isCollapsed: boolean;
  contractIndex?: number;
  handleEntryToggle?: () => void;
  isDeletable: boolean;
  extraClassName?: string;
  rosterSettings: IRosterSettings;
};

const mapStateToProps = (state: AppState) => {
  return {
    branches: selectActiveBranches(state),
    isFreshTenant: selectIsFreshTenant(state),
  };
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

const getDailyQuotaArray = (dailyQuota: DailyQuota): Array<{ day: string; minutes: number | null }> => {
  return WeekDays.map((day) => ({ day, minutes: dailyQuota[day] }));
};

const getAvgMinsOfDay = (day: string, dailyQuota: DailyQuota, totalHours: number | undefined) => {
  const totalMins = (totalHours || 0) * 60;
  const workingDaysCount = Object.values(dailyQuota).filter(isWorkDay).length;
  const lastWorkDay = getDailyQuotaArray(dailyQuota)
    .filter(({ day, minutes }) => isWorkDay(minutes))
    .reverse()[0]?.day;

  const avgMins = Math.floor(totalMins / workingDaysCount);
  // for the rare case that the daily minuts cant get evenly distributed over the totalMins
  // we add the extra mins to the last workDay ( e.g. 24,5 h to work on 4 Days )
  const extraModuloMins = totalMins % avgMins || 0;
  return !!extraModuloMins && day === lastWorkDay ? avgMins + extraModuloMins : avgMins;
};

const getWeekDayLabel = (weekDay: WeekDay) => {
  return getWeekDayLabels(getLocaleLang())[weekDay];
};

// const parsesMinutesToHoursString = (minutes: number) => {
//   let parsedHours: number | string = Math.floor(minutes / 60);
//   let paresedMinutes: number | string = minutes % 60;
//   if (parsedHours < 10) {
//     parsedHours = "0" + parsedHours;
//   }
//   if (paresedMinutes < 10) {
//     paresedMinutes = "0" + paresedMinutes;
//   }
//   return `${parsedHours}:${paresedMinutes}`;
// };

class ContractEntry extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isMainBranchPickerOpen: false,
    };
  }

  toggleWeekDay = (_weekday: keyof DailyQuota) => {
    const { dailyQuota, totalHours, interval } = this.props.contract;
    const isAdding = !isWorkDay(dailyQuota[_weekday]);
    const onlyOneActive = Object.values(dailyQuota).filter(isWorkDay).length === 1;
    const initialQutoa = interval === WorkInterval.weekly ? 0 : 1;

    if (onlyOneActive && !isAdding) {
      return; // dont allow to deselect all days. Minimum is 1 day.
    }

    const dailyQuotaCloned = { ...dailyQuota };

    WeekDays.forEach((day) => {
      if (day === _weekday) {
        dailyQuotaCloned[day] = isAdding ? initialQutoa : null;
      }
    });

    if (interval === WorkInterval.weekly) {
      Object.keys(dailyQuotaCloned).forEach((day) => {
        if (Number.isInteger(dailyQuotaCloned[day])) {
          dailyQuotaCloned[day] = getAvgMinsOfDay(day, dailyQuotaCloned, totalHours);
        }
      });
    }

    // const totalMinutes = _.sum(Object.values(dailyQuotaCloned));
    // const totalHours = minutesToHours(totalMinutes);
    this.updateData({ dailyQuota: dailyQuotaCloned });
  };

  updateData = (data: Partial<IContractCore>) => {
    this.props.onContractChange({ ...this.props.contract, ...data });
  };

  onDateChange = (mom: Moment | null) => {
    if (!mom) {
      return;
    }
    const validFrom = toSimpleDate(mom);
    this.updateData({ validFrom });
  };

  dailyQuotaChanged = (weekDay: WeekDay, newMinutesVal?: number) => {
    const prevDailyQuota = this.props.contract.dailyQuota;
    const dailyQuota = { ...prevDailyQuota };
    dailyQuota[weekDay] = newMinutesVal || 0;

    const totalMinutes = _.sum(Object.values(dailyQuota));
    const totalHours = minutesToHours(totalMinutes);

    this.updateData({ dailyQuota, totalHours });
  };

  updateTotalHours = (_totalHours?: number) => {
    const totalHours = _totalHours || 0;
    const prevDailyQuota = this.props.contract.dailyQuota;
    const interval = this.props.contract.interval;
    const dailyQuota = { ...prevDailyQuota };

    if (interval === WorkInterval.weekly) {
      Object.entries(dailyQuota)
        .filter(([day, minutes]) => isWorkDay(minutes))
        .forEach(([day, minutes]) => {
          const minsPerDays = getAvgMinsOfDay(day, prevDailyQuota, totalHours);
          dailyQuota[day as WeekDay] = minsPerDays;
        });
    }

    this.updateData({ totalHours, dailyQuota });
  };

  updateInterval = (interval: WorkInterval) => {
    const { dailyQuota, totalHours } = this.props.contract;
    const { rosterSettings } = this.props;
    const newDailyQuota = {} as DailyQuota;
    let intervalStartDay: number | undefined;

    if (interval === WorkInterval.weekly) {
      getDailyQuotaArray(dailyQuota).forEach(({ day, minutes }) => {
        const meanMinutes = getAvgMinsOfDay(day, dailyQuota, totalHours);
        newDailyQuota[day] = isWorkDay(minutes) ? meanMinutes : null;
      });
      intervalStartDay = undefined;
    } else {
      // Monthly Interval
      getDailyQuotaArray(dailyQuota).forEach(({ day, minutes }) => {
        newDailyQuota[day] = isWorkDay(minutes) ? 1 : null;
      });
      intervalStartDay = rosterSettings.customMonthIntervalStart;
    }

    this.updateData({
      interval,
      dailyQuota: newDailyQuota,
      intervalStartDay,
    });
  };

  renderDayHour = (weekday: keyof DailyQuota, label: string) => {
    const { dailyQuota, interval } = this.props.contract;
    const isActive = isWorkDay(dailyQuota[weekday]);
    const workingMinutes = dailyQuota[weekday] || 0;

    return (
      <div className="weekdayButton">
        <div className="topPart">
          <div className="switchWrapper">
            <Switch
              checkedChildren={label}
              unCheckedChildren={label}
              checked={isActive}
              onChange={() => this.toggleWeekDay(weekday)}
            />
          </div>
        </div>
        {interval === WorkInterval.weekly && (
          <div className="hoursInputWrapper">
            <DurationInput
              value={workingMinutes}
              onChange={(min) => {
                this.dailyQuotaChanged(weekday, min);
              }}
              disabled={!isActive}
              showTooltip={this.props.isFreshTenant}
            />
          </div>
        )}
      </div>
    );
  };

  toggleMainBranchPicker = () => {
    this.setState({ isMainBranchPickerOpen: !this.state.isMainBranchPickerOpen });
  };

  monthlyIntervalStartClicked = () => {
    // Intentionally commeted out. For now we go for the solution to batch update this prop from the rosterSettings-PAGE.
    // If we need to change this prop individually!
    //
    // const { dispatch } = this.props;
    // const selectedDay = this.props.contract.intervalStartDay;
    // const onDaySelect = (day: number | undefined) => {
    //   this.updateData({ intervalStartDay: day });
    //   this.props.dispatch(selectCustomMonthStartDay(day || 1)); // so the selectCustomMonthStartDay is autotamically set accordingly, so the roster shows correct data
    //   dispatch(closeModal());
    // };
    // dispatch(openModal(IntervalStartDayModal, { selectedDay, onDaySelect }));
  };

  render() {
    const { contract, displayValidFromDate, isEditable, isCollapsed, extraClassName, rosterSettings } = this.props;
    const { vacationDayCredit, illnessDayCredit } = contract;
    const extraClasObj = extraClassName ? { [extraClassName]: true } : {};

    return (
      <div
        className={cn({
          disabled: !isEditable,
          contractEntryMain: true,
          ...extraClasObj,
        })}
      >
        <div className="mainContent">
          {displayValidFromDate && (
            <ContractEntryHead
              isInitial={this.props.contractIndex === 0}
              contract={contract}
              prevContract={this.props.previousContract}
              nextContract={this.props.nextContract}
              isCollapsed={isCollapsed}
              isDeletable={this.props.isDeletable}
              onDelete={this.props.onDelete}
              onToggle={this.props.handleEntryToggle!}
              onDateChange={this.onDateChange}
            />
          )}
          {!isCollapsed && (
            <>
              <div className="contractRow mainRow ">
                <div className="fb workIntervalWrapper">
                  <WorkIntervalField state={contract.interval} onChange={this.updateInterval} />
                  <div className="totalHoursInputWrapper">
                    <InputNumber
                      disabled={!isEditable}
                      value={contract.totalHours}
                      onChange={this.updateTotalHours}
                      decimalSeparator={decimalSeparatorLocal}
                      placeholder={"0"}
                      style={{ width: 55 }}
                    />
                  </div>
                  <div className="unitText">{lg.stunden}</div>
                  {contract.interval === WorkInterval.monthly && rosterSettings.allowCustomMonthInterval && (
                    <div
                      className="monthlyIntervalStart"
                      // data-rh="Monatsintervall startet am"
                      onClick={this.monthlyIntervalStartClicked}
                    >
                      {contract.intervalStartDay
                        ? lg.intervall_startet_am + " " + contract.intervalStartDay + "."
                        : lg.kalendermonat}
                      <Icon type="calendar" style={{ marginLeft: 6 }} />
                    </div>
                  )}

                  <div className="extraOptions">
                    {rosterSettings.wagesCanBeEntered && (
                      <HourlyWageEditor
                        hourlyWage={contract.hourlyWage}
                        updateHourlyWage={(h) => this.updateData({ hourlyWage: h })}
                      />
                    )}
                    <MinMaxHoursEditor
                      monthlyMaxHours={contract.monthlyMaxHours}
                      updateMonthlyMaxHours={(h) => this.updateData({ monthlyMaxHours: h })}
                    />
                    <UserTypeEditor
                      typeId={contract.userTypeId}
                      onTypeSelected={(userTypeId: string | undefined) => this.updateData({ userTypeId })}
                    />
                  </div>
                </div>
              </div>

              <div className="contractRow daySelectRow">
                {/* <div className='label' >Arbeitstage</div> */}
                <div className="workDaysWrapper">
                  {this.renderDayHour("mo", getWeekDayLabel("mo"))}
                  {this.renderDayHour("tu", getWeekDayLabel("tu"))}
                  {this.renderDayHour("we", getWeekDayLabel("we"))}
                  {this.renderDayHour("th", getWeekDayLabel("th"))}
                  {this.renderDayHour("fr", getWeekDayLabel("fr"))}
                  {this.renderDayHour("sa", getWeekDayLabel("sa"))}
                  {this.renderDayHour("su", getWeekDayLabel("su"))}
                </div>
              </div>

              <div className="extraOptionsSection">
                <div className="contractRow" style={{ padding: "6px 34px" }}>
                  <div className="checkboxRow">
                    <Checkbox
                      checked={contract.applyQuotaOnHolidays}
                      onChange={(e) => {
                        this.updateData({ applyQuotaOnHolidays: e.target.checked });
                      }}
                    >
                      {lg.an_feiertagen_sollstunden_gutschreiben}
                    </Checkbox>
                  </div>
                  {contract.interval === WorkInterval.monthly && (
                    <div className="checkboxRow vacationInput">
                      <div className="fb col">
                        <div className="fb row inputRow">
                          <div className="text">{lg.gutschrift_pro_urlaubstag}</div>
                          <DurationInput
                            value={vacationDayCredit}
                            onChange={(mins) => {
                              this.updateData({ vacationDayCredit: mins });
                            }}
                            hoursPlaceholder={lg.std}
                            minutesPlaceholder={lg.min}
                            inputWidth={40}
                          />
                        </div>
                        {rosterSettings.canDefineIllnessDayCredit && (
                          <div className="fb row inputRow">
                            <div className="text">{lg.gutschrift_pro_krankheitstag}</div>
                            <DurationInput
                              value={illnessDayCredit}
                              onChange={(mins) => {
                                this.updateData({ illnessDayCredit: mins });
                              }}
                              hoursPlaceholder={lg.std}
                              minutesPlaceholder={lg.min}
                              inputWidth={40}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="contractRow mainBranchRow" style={{ padding: "0px 0px" }}>
                  {!!this.props.branches.length && this.props.rosterSettings.canSetBranchSpecificHolidays && (
                    <div className="bottomCell mainBranch" style={{ padding: "8px 34px" }}>
                      <div>{lg.hauptstandort}:</div>

                      <Popover
                        trigger={"click"}
                        placement="bottomRight"
                        visible={this.state.isMainBranchPickerOpen}
                        onVisibleChange={(v) => this.setState({ isMainBranchPickerOpen: v })}
                        content={
                          <MainBranchPicker
                            selectedBranchId={contract.mainBranchId}
                            onChange={(mainBranchId) => {
                              this.updateData({ mainBranchId });
                              this.toggleMainBranchPicker();
                            }}
                          />
                        }
                      >
                        <div className="mainBranchName" onClick={this.toggleMainBranchPicker}>
                          {this.props.branches.find((b) => b.id === contract.mainBranchId)?.name || lg.kein}
                        </div>
                      </Popover>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(ContractEntry);
