// ddasd
import { useDispatch } from "react-redux";
import TZModal from "../../../components/TZModal/TZModal";
import _ from "lodash";
import "./styles.scss";
import { DispFn } from "../../../frontend-core/types/thunkTypes";
import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import { useSelector } from "../../../helpers/redux";
import { IUserType } from "../../../shared/entities/IRosterSettings";
import { dummyUserTypes } from "../UserDetailsModal/ContractEntry/UserTypeEditor/UserTypeEditor";
import { Button, Checkbox, Input, notification } from "antd";
import { toTripleDigit } from "../../../shared/helpers/timeHelpers";
import { IUser } from "../../../shared/entities/IUser";
import { rosterSettingsRepository } from "../../../repositories/rosterSettingsRepository";
import { closeModal } from "../../../actions/modal";

export const getNextTypeId = (userTypes: IUserType[]): string => {
  const userNrs = userTypes.map((u) => parseInt(u.id.split("ut")[1])); //  does this: 'ut32' -> 32
  return `ut${toTripleDigit(_.max(userNrs)! + 1)}`; // ids are incremental
};

type Props = {
  userTypes: IUserType[]; // its important to not access from store > becaues of dummyUserTypes logic
} & BusyInjectorProps;

export const UserTypesModal = busyInjector((props: Props) => {
  const dispatch = useDispatch<DispFn>();

  const rosterSetting = useSelector((s) => s.data.rosterSettings[0]);

  const [userTypes, setUserTypes] = useState<IUserType[]>([]);
  const [editingId, setEditingId] = useState<string | undefined>();
  const [backup, setBackup] = useState<IUserType | undefined>(); // used as a store of the initial value, when editing > so it can be restored on Cancel
  const [confirmDelteId, setConfirmDeleteId] = useState<string | undefined>();

  const saveAll = () => {
    const userTypesObj = userTypes.reduce((acc, ut) => ({ ...acc, [ut.id]: ut }), {}); // array to object with id-keys
    dispatch(
      rosterSettingsRepository.update({
        ...rosterSetting,
        userTypes: userTypesObj,
      })
    );

    dispatch(closeModal());
  };

  const updateType = (id: string, key: string, val: any) => {
    const newTypes = userTypes.map((ut) => (ut.id === id ? { ...ut, [key]: val } : ut));
    setUserTypes(newTypes);
  };

  useEffect(() => {
    setUserTypes(props.userTypes);
  }, []);

  const cancelClicked = () => {
    const isNewType = !props.userTypes.find((ut) => ut.id === editingId);
    isNewType
      ? setUserTypes(userTypes.filter((ut) => ut.id !== editingId))
      : setUserTypes(userTypes.map((ut) => (ut.id === editingId ? backup! : ut)));

    setEditingId(undefined);
    confirmDelteId && setConfirmDeleteId(undefined);
  };

  const saveClicked = () => {
    const editingType = userTypes.find((ut) => ut.id === editingId)!;
    !editingType.name && notification.warning({ message: "Name fehlt" });
    !editingType.slug && notification.warning({ message: "Kürzel fehlt" });
    if (editingType.name && editingType.slug) {
      setEditingId(undefined);
    }
  };

  const deleteClicked = (id: string) => {
    if (confirmDelteId === id) {
      setUserTypes(userTypes.filter((ut) => ut.id !== id));
      setConfirmDeleteId(undefined);
    } else {
      setConfirmDeleteId(id);
    }
  };

  return (
    <TZModal>
      <TZModal.Head>{lg.mitarbeiter_typen}</TZModal.Head>
      <TZModal.Body>
        {/* <div className="fb row"></div> */}
        <div className="userTypesModalMain">
          <div className="fb col content ">
            {_.orderBy(userTypes, "id").map((ut) => (
              <div className="fb row userTypeRow" key={ut.id}>
                <div className="nameInput">
                  {editingId === ut.id ? (
                    <Input
                      value={ut.name}
                      onChange={(e) => updateType(ut.id, "name", e.target.value)}
                      placeholder={lg.name}
                      autoFocus
                    />
                  ) : (
                    <div className="name display">{ut.name}</div>
                  )}
                </div>
                <div className="slugInput" data-rh={lg.kuerzel}>
                  {editingId === ut.id ? (
                    <Input
                      value={ut.slug}
                      onChange={(e) => updateType(ut.id, "slug", e.target.value)}
                      placeholder={lg.kuerzel}
                      maxLength={4}
                    />
                  ) : (
                    <div className="slug display">{ut.slug}</div>
                  )}
                </div>
                {(editingId === ut.id || confirmDelteId === ut.id) && (
                  <div className="cancelBtn btn" data-rh={lg.abbrechen}>
                    <Button onClick={cancelClicked} icon="close" type="dashed" />
                  </div>
                )}
                {editingId === ut.id && (
                  <div className="saveBtn btn" data-rh={lg.Speichern}>
                    <Button onClick={saveClicked} icon="check" type="primary" />
                  </div>
                )}
                {editingId !== ut.id && confirmDelteId !== ut.id && (
                  <div className="editBtn btn" data-rh={lg.editieren}>
                    <Button
                      onClick={() => {
                        setBackup({ ...ut });
                        setEditingId(ut.id);
                      }}
                      icon="form"
                      disabled={(!!editingId && editingId !== ut.id) || (!!confirmDelteId && confirmDelteId !== ut.id)}
                    />
                  </div>
                )}
                {editingId !== ut.id && (
                  <div className="deleteBtn btn" data-rh={confirmDelteId === ut.id ? lg.wirklich_löschen : lg.löschen}>
                    <Button
                      onClick={() => deleteClicked(ut.id)}
                      icon={confirmDelteId === ut.id ? "question" : "delete"}
                      disabled={(!!editingId && editingId !== ut.id) || (!!confirmDelteId && confirmDelteId !== ut.id)}
                      type={confirmDelteId === ut.id ? "danger" : "default"}
                    />
                  </div>
                )}
              </div>
            ))}
            <div className="addNewRowBtn">
              <Button
                disabled={!!editingId}
                onClick={() => {
                  const newType: IUserType = { id: getNextTypeId(userTypes), name: "", slug: "" };
                  setUserTypes([...userTypes, newType]);
                  setEditingId(newType.id);
                }}
                icon="plus"
                children={"hinzufügen"}
              />
            </div>
          </div>
        </div>
      </TZModal.Body>
      <TZModal.Footer>
        <Button onClick={saveAll} children={lg.fertig} disabled={!!editingId} type="primary" />
      </TZModal.Footer>
    </TZModal>
  );
});
