import { publishedShiftsSelector } from "./publishedShiftsSelector";
import { SDateFormat } from "../shared/helpers/SimpleTime";
import { createSelector } from "reselect";
import { selectSessionInfo } from "./SessionInfoSelector";
import moment from "moment";

export const openUpcomingShiftsSelector = createSelector(
  [selectSessionInfo, publishedShiftsSelector],
  (sessionInfo, publishedShifts) => {
    const today = moment().format(SDateFormat);
    const user = sessionInfo.user;
    const canManage = sessionInfo.hasManagerPermissions();
    const isAdmin = sessionInfo.isAdmin();

    return publishedShifts.filter((s) => {
      return (
        !s.userId &&
        !s.isRequirement &&
        s.date >= today &&
        (isAdmin ||
          (user.branchIds.includes(s.branchId) && (canManage || user.jobPositionIds.includes(s.jobPositionId))))
      );
    });
  }
);
