import { IDailyNote } from "./../shared/entities/IDailyNote";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";

export const selectDailyNotesByDate: (state: AppState) => { [id: string]: IDailyNote[] } = createSelector(
  [(state: AppState) => state.data.dailyNotes, (state: AppState) => state.ui.selectedBranch],
  (dailyNotes, selectedBranchId) => {
    const map: { [id: string]: IDailyNote[] } = {};
    dailyNotes
      .filter((u) => !selectedBranchId || u.branchId === selectedBranchId)
      .forEach((u) => (map[u.date] = map[u.date] ? [...map[u.date], u] : [u]));
    return map;
  }
);
