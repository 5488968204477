import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { toSimpleDate } from "../../shared/helpers/timeHelpers";
import moment from "moment";
import { shiftRepository } from "../../repositories/shiftRepository";
import { timeClockingRepository } from "../../repositories/timeClockingRepository";
import { trackingRepository } from "../../repositories/TrackingRepository";
import _ from "lodash";
import { busyInjector, BusyInjectorProps } from "../../components/BusyInjector/BusyInjector";
import { useSelector } from "../../helpers/redux";
import {
  getActiveTimeClocking,
  getRelevantShiftsWithoutTrackingInBranch,
} from "../../shared/helpers/timeClockingHelpers";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import { TimeClockUserContent } from "./TimeClockUserContent";
import { generateUserTimeClockSettings } from "../../shared/helpers/settingsHelpers";
import { visibleShiftsSelector } from "../../selectors/publishedShiftsSelector";
import { selectTimeClockingMap, selectTrackingMap, selectUserMap } from "../../selectors/mapSelectors";
import { Button, Icon } from "antd";
import { getUserName } from "../../shared/helpers/userHelpers";
import { alwaysClockableBranchIdsSelector } from "../../selectors/AlwaysClockableBranchIdsSelector";
import { selectShiftMap } from "../../selectors/shiftMapSelector";

type Props = BusyInjectorProps & {
  userId: string;
  deselectUserId?: () => void;
  setSelectedShiftOrClockingId: (id: string | undefined) => void;
  selectedShiftOrClockingId?: string;
  setSelectedUserId?: (id: string | undefined) => void;
  isWebMode?: boolean;
};

export const TimeClockUser = busyInjector((props: Props) => {
  const { setSelectedShiftOrClockingId, selectedShiftOrClockingId } = props;
  const isMounted = useRef(false);
  const timeClockStationMode = useSelector((state) => state.ui.timeClockMode);
  const branches = useSelector((s) => s.data.branches);
  const alwaysClockableBranchIds = useSelector(alwaysClockableBranchIdsSelector);
  const clockableBranchIds: string[] = props.isWebMode
    ? branches.filter((b) => b.isClockingEnabled).map((b) => b.id)
    : timeClockStationMode.branchIds
    ? timeClockStationMode.branchIds
    : alwaysClockableBranchIds;

  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const lastFetchingDateRef = useRef<undefined | string>();
  const dispatch = useDispatch<DispFn>();
  const [, updateState] = useState<any>();
  const trackingMap = useSelector(selectTrackingMap);
  const shifts = useSelector(visibleShiftsSelector);
  const usersMap = useSelector(selectUserMap);
  const isV2 = useSelector((s) => s.data.tenantInfo.isV2);
  const currentUser = usersMap[props.userId];
  const shiftMap = useSelector(selectShiftMap);
  const timeClockings = useSelector((state) => state.data.timeClockings);
  const timeClockingMap = useSelector(selectTimeClockingMap);
  const activeTimeClocking = getActiveTimeClocking(timeClockings, currentUser.id, trackingMap);
  const _timeClockSettings = useSelector((state) => state.data.timeClockSettings[0]);
  const timeClockSettings = generateUserTimeClockSettings(_timeClockSettings, currentUser);
  const { isFreeClockingAllowed } = timeClockSettings;
  const forceUpdate = useCallback(() => updateState({}), []);

  const clockableShifts = getRelevantShiftsWithoutTrackingInBranch(
    currentUser.id,
    shifts,
    trackingMap,
    clockableBranchIds
  ).filter((s) => !timeClockingMap[s.id]?.endTime && !timeClockingMap[s.id]?.isAutoClockOut); // For the case that the tracking has been manually removed but the clocking still exists

  if (!isV2 && activeTimeClocking && !activeTimeClocking.isDynamicClocking) {
    clockableShifts.push(shiftMap[activeTimeClocking.id]);
  }

  const fetchData = async (withoutBusyLoader = false) => {
    if (!withoutBusyLoader) {
      props.setLoading("full", true);
    }
    if (!lastFetchingDateRef.current || lastFetchingDateRef.current < toSimpleDate(moment())) {
      lastFetchingDateRef.current = toSimpleDate(moment());
      // fetch relevant shifts to select
      const userDateFilter = {
        filter: [
          "userId_date",
          "between",
          [
            `${currentUser.id}_${toSimpleDate(moment().add(-1, "days"))}`,
            `${currentUser.id}_${toSimpleDate(moment().add(1, "days"))}`,
          ],
        ],
      } as any;

      await dispatch(shiftRepository.addListener({ ...userDateFilter, key: "shiftsAroundOndeDay" }));
      await dispatch(trackingRepository.addListener({ ...userDateFilter, key: "trackingsAroundOndeDay" }));
      await dispatch(
        timeClockingRepository.addListener({
          filter: ["date", "between", [toSimpleDate(moment().add(-1, "days")), toSimpleDate(moment().add(1, "days"))]],
        })
      );
    }

    if (!withoutBusyLoader) {
      props.setLoading("full", false);
    }
  };

  const updateTimerLoop = () => {
    setTimeout(() => {
      if (isMounted.current) {
        fetchData(true);
        forceUpdate();
        updateTimerLoop();
      }
    }, 5000);
  };

  useEffect(() => {
    isMounted.current = true;

    updateTimerLoop();
    fetchData();

    setInitiallyLoaded(true);

    return () => {
      isMounted.current = false;
    };
  }, []);

  /**
   * This is the active time clocking which also can be stopped atm.
   * Some clockings can't be stopped, becuase the employee is for example
   * not in the geofancing radius
   */
  const activeClockableClocking = useMemo(() => {
    if (!activeTimeClocking) {
      return;
    }
    if (activeTimeClocking.isDynamicClocking && !trackingMap[activeTimeClocking.id]) {
      return activeTimeClocking;
    }
    if (clockableShifts.find((s) => s.id === activeTimeClocking.id)) {
      return activeTimeClocking;
    }
  }, [activeTimeClocking, clockableShifts, trackingMap]);

  useEffect(() => {
    if (activeClockableClocking) {
      if (selectedShiftOrClockingId !== activeClockableClocking.id) {
        setSelectedShiftOrClockingId(activeClockableClocking.id);
      }
    } else {
      if (clockableShifts.length === 1) {
        setSelectedShiftOrClockingId(clockableShifts[0].id);
      }
    }
  }, [
    activeClockableClocking,
    selectedShiftOrClockingId,
    currentUser,
    shifts,
    clockableShifts,
    setSelectedShiftOrClockingId,
  ]);

  console.log("TimeClockUser.txs");

  return (
    <>
      {!initiallyLoaded || props.isLoading("full") ? (
        <Icon type="loading" style={{ fontSize: "70px", color: "white" }} spin />
      ) : (
        <>
          {props.setSelectedUserId && (
            <div className="fb row aCenter jCenter userBox">
              <Button
                id="deselect-user-of-timeclock"
                icon={"arrow-left"}
                size="default"
                onClick={() => {
                  setSelectedShiftOrClockingId(undefined);
                  props.setSelectedUserId && props.setSelectedUserId(undefined);
                }}
              />
              {getUserName(currentUser)}
            </div>
          )}
          {!selectedShiftOrClockingId &&
            !clockableShifts.length &&
            (!isFreeClockingAllowed || !clockableBranchIds.length) && (
              <div style={{ color: "white", textAlign: "center", marginTop: 12, marginBottom: 12 }}>
                {lg.derzeit_gibt_es_keine_verfügbaren_schichten_für_dich_zum_stempeln}
              </div>
            )}
          <TimeClockUserContent
            isFreeClockingAllowed={isFreeClockingAllowed}
            selectedShiftOrClockingId={selectedShiftOrClockingId}
            setSelectedShiftOrClockingId={setSelectedShiftOrClockingId}
            clockableBranchIds={clockableBranchIds}
            load={props.load}
            currentUser={currentUser}
            deselectShift={() => setSelectedShiftOrClockingId(undefined)}
            deselectUserId={props.deselectUserId}
          />
        </>
      )}
    </>
  );
});
