import React from "react";
import { v4 as uuid } from "uuid";

export type BusyLoad = <T>(p: Promise<T>, s?: string) => Promise<T> | Promise<void> | any;

export type BusyInjectorProps = {
  isLoading: (s?: string) => boolean;
  load: <T>(p: Promise<T>, s?: string) => Promise<T>;
  setLoading: (s: string, loadingState: boolean) => void;
};

type State = {
  loadingEntities: { [s: string]: boolean };
};

type Optionalize<T extends K, K> = Omit<T, keyof K>;

export const busyInjector = <P extends BusyInjectorProps = BusyInjectorProps>(Component: React.ComponentType<P>) => {
  class InjectedComponent extends React.PureComponent<Optionalize<P, BusyInjectorProps>, State> {
    state: State = {
      loadingEntities: {},
    };

    load = async <T extends Record<string, any>>(p: Promise<T>, _s?: string) => {
      const s = _s || uuid();
      let result: T;

      this.setState({
        loadingEntities: {
          ...this.state.loadingEntities,
          [s]: true,
        },
      });

      const stopLoading = () =>
        this.setState({
          loadingEntities: {
            ...this.state.loadingEntities,
            [s]: false,
          },
        });

      try {
        result = await p;
      } catch (e) {
        stopLoading();
        throw e;
      }

      stopLoading();

      return result;
    };

    isLoading = (s?: string) => {
      return s ? !!this.state.loadingEntities[s] : !!Object.values(this.state.loadingEntities).find((x) => !!x);
    };

    // use this method, if you want to manually set sthing to loading / loaded
    setLoading = (s: string, loadingState: boolean) => {
      this.setState({
        loadingEntities: {
          ...this.state.loadingEntities,
          [s]: loadingState,
        },
      });
    };

    render() {
      return (
        <Component
          // @ts-ignore
          isLoading={this.isLoading}
          // @ts-ignore
          load={this.load}
          // @ts-ignore
          setLoading={this.setLoading}
          {...(this.props as P)}
          s={this.state} // we add the state as a prop, to trigger a re-rendering on a loading change
        />
      );
    }
  }

  return InjectedComponent;
};
