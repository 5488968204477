import React from "react";
import ReactTable, { TableProps } from "react-table-v6";
import "./styles.scss";

type State = {};
type Props = Partial<TableProps>;

class Table extends React.PureComponent<Props, State> {
  render() {
    return <ReactTable minRows={0} {...this.props} />;
  }
}

export default Table;
