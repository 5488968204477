import { IAbsenceType } from "../shared/entities/IAbsenceType";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import { AbsenceStatus, IAbsence } from "../shared/entities/IAbsence";
import { IUser, RoleType } from "../shared/entities/IUser";
import { selectAbsenceTypeMap } from "./absenceTypeMapSelector";
import { selectSessionInfo } from "./SessionInfoSelector";
import { selectRosterDateRange } from "./rosterDateRangeSelector";

// Dont include this mapSelector in mapSelecotrs.ts > becaue this way users with no absences appear in the map with an empty Array.
// So we dont have null pointers when referencing a user with no absence in the map
export const getAbsencesByUser = (absences: IAbsence[], users: IUser[]): { [userId: string]: IAbsence[] } => {
  const map: { [userId: string]: IAbsence[] } = {};
  users.forEach((user) => (map[user.id] = []));
  absences.forEach((absence) => (map[absence.userId] = [...map[absence.userId], absence]));
  return map;
};

export const isAbsenceVisible = (absence: IAbsence, absenceType: IAbsenceType, user: IUser) => {
  return (
    user.role === RoleType.admin ||
    user.role === RoleType.manager ||
    absence.userId === user.id ||
    absenceType.isVisibleToColleagues
  );
};

export const selectAbsencesByUser: (state: AppState) => { [userId: string]: IAbsence[] } = createSelector(
  [(state: AppState) => state.data.absences, (state: AppState) => state.data.users],
  (absences, users) => getAbsencesByUser(absences, users)
);

export const selectVisibleAbsencesByUser: (state: AppState) => { [userId: string]: IAbsence[] } = createSelector(
  [
    (state: AppState) => state.data.absences,
    (state: AppState) => state.data.users,
    selectAbsenceTypeMap,
    selectSessionInfo,
  ],
  (absences, users, absenceTypMap, sessionInfo) => {
    const visibleAbsences = absences.filter((a) => isAbsenceVisible(a, absenceTypMap[a.typeId], sessionInfo.user));
    return getAbsencesByUser(visibleAbsences, users);
  }
);

export const selectAbsencesOfRosterByUser: (state: AppState) => { [userId: string]: IAbsence[] } = createSelector(
  [(state: AppState) => state.data.absences, (state: AppState) => state.data.users, selectRosterDateRange],
  (absences, users, rosterDateRange) => {
    let { rangeStart, rangeEnd } = rosterDateRange;
    const activeAbsencesOfRoster = absences.filter(
      (a) => a.status === AbsenceStatus.active && a.startDate <= rangeEnd && a.endDate >= rangeStart
    );
    return getAbsencesByUser(activeAbsencesOfRoster, users);
  }
);
