import { closestWithAttribute } from "../../helpers/general";
import { AbsenceStatus, IAbsence } from "../../shared/entities/IAbsence";

export const getClickedUserID = (e: any) => {
  const userNode = closestWithAttribute(e.target, "data-type", "absence-user");
  return userNode?.getAttribute("data-user");
};

export const getClickedAbsenceID = (e: any) => {
  const absenceBarNode = closestWithAttribute(e.target, "data-type", "absence-bar");
  return absenceBarNode?.getAttribute("data-absence-id");
};

export const getAbsenceStatusLabel = (status: AbsenceStatus) => {
  switch (status) {
    case AbsenceStatus.active:
      return lg.aktiv;
    case AbsenceStatus.requested:
      return lg.beantragt;
  }
};

export type AbsenceRow = IAbsence & {
  userName: string;
  displayDateRange: string;
  displayEffectiveDays: number;
  effectiveDaysInRangeVal?: number;
  isEffectiveDaysOverriden?: boolean;
  displayStatus: string;
  displayType: string;
  totalDays: number;
};

export type AbsenceCalendarPageState = {
  branchFilter?: string;
  jobPositionFilter?: string;
  selectedTypeId?: string;
  userFilter?: string;
  statusFilter?: AbsenceStatus;
  startDate: string;
  endDate: string;
};
