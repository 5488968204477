import { Input, Checkbox, Icon, InputNumber, Button } from "antd";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import AvColorPicker from "../../../../components/AvColorPicker/AvColorPicker";
import AvFormField from "../../../../components/AvFormField/AvFormField";
import { busyInjector, BusyInjectorProps } from "../../../../components/BusyInjector/BusyInjector";
import TZModal from "../../../../components/TZModal/TZModal";
import { strongColors, pastelColors } from "../../../../frontend-core/helpers/colors";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import { useSelector } from "../../../../helpers/redux";
import { BreakRule } from "../../../../shared/entities/IRosterSettings";
import { IShiftAddress } from "../../../../shared/entities/IShiftAddress";
import { LocationSearchInput } from "../../branches/branchPopup/LocationSearchInput";
import cn from "classnames";
import "./styles.scss";
import { rosterSettingsRepository } from "../../../../repositories/rosterSettingsRepository";
import { closeModal } from "../../../../actions/modal";
import { decimalSeparatorLocal } from "../../../../helpers/dateFormatHelper";
import DurationInput from "../../../Users/UserDetailsModal/ContractEntry/DurationInput/DurationInput";

export const defaultBreakRules = [
  {
    isActive: true,
    workingHours: 6,
    workingMinutes: 0,
    breakMinutes: 30,
  },
  {
    isActive: true,
    workingHours: 9,
    workingMinutes: 0,
    breakMinutes: 45,
  },
];

const emptyRule = {
  isActive: false,
  workingHours: 0,
  workingMinutes: 0,
  breakMinutes: 0,
};

type Props = {
  rules?: BreakRule[];
  onComplete: (breakeRules: BreakRule[]) => void;
};

export const BreakRulesModal = busyInjector((props: Props & BusyInjectorProps) => {
  const dispatch = useDispatch<DispFn>();
  const [_breakRules, _setBreakRules] = useState<BreakRule[]>([]);

  useEffect(() => {
    const breakRules = (props.rules || defaultBreakRules).filter((rule) => rule.isActive) as BreakRule[];
    const initialBreakRules = [breakRules[0] || emptyRule, breakRules[1] || emptyRule, breakRules[2] || emptyRule];
    _setBreakRules(initialBreakRules);
  }, []);

  const saveClicked = () => {
    const _breakRuls = getActiveRules().filter((rule) => !!rule.isActive);
    props.onComplete(_breakRuls);
    dispatch(closeModal());
  };

  const getActiveRules = () =>
    (_breakRules || []).filter(
      (rule) => rule.isActive && !(rule.breakMinutes === undefined && rule.workingHours === undefined)
    );

  const isSaveDisabled = () => {
    const activeRules = getActiveRules();

    if (activeRules.every((rule) => !rule.isActive)) {
      return false; // allow to uncheck all breakRulles and save
    }

    return (
      activeRules.some((rule) => rule.breakMinutes === undefined || rule.workingHours === undefined) ||
      activeRules.some((rule) => typeof rule.breakMinutes === "string" || typeof rule.workingHours === "string") ||
      !activeRules.length
    );
  };

  const updateBreakRule = (index: number, key: keyof BreakRule, value: number | undefined) => {
    const _value = !value && value !== 0 ? undefined : value; // The ant-design component seems to emit '' aswell.
    const newRules = _breakRules.map((rule, i) => (i === index ? { ...rule, [key]: _value } : rule));
    _setBreakRules(newRules);
  };

  const toggleBreakRule = (index: number) => {
    const newRules = _breakRules.map((rule, i) =>
      i === index
        ? (({ workingHours: undefined, breakMinutes: undefined, isActive: !rule.isActive } as any) as BreakRule)
        : rule
    );
    _setBreakRules(newRules);
  };

  const getTotalWorkingMins = (wr: BreakRule): number => {
    return (wr.workingHours || 0) * 60 + (wr.workingMinutes || 0);
  };

  return (
    <div>
      <TZModal>
        <TZModal.Head>{lg.pausenregelung}</TZModal.Head>
        <TZModal.Body>
          <div className="breakRulesModalMain">
            <div className="rulesList">
              <div className="headRow">
                <div className="headCells">
                  <div className="headStd cell">{lg.Std}</div>
                  <div className="headMin cell">{lg.Min}</div>
                </div>
              </div>
              {_breakRules.map((breakRule, index) => (
                <div className={cn({ ruleRow: true, isDisabled: !breakRule.isActive })}>
                  <div className="checkBoxWrapper">
                    <Checkbox onChange={(e) => toggleBreakRule(index)} checked={!!breakRule.isActive} />
                  </div>
                  Ab
                  <DurationInput
                    value={breakRule?.isActive ? getTotalWorkingMins(breakRule) : undefined}
                    onChange={(totalMins) => {
                      const hours = Math.floor(totalMins / 60);
                      const mins = totalMins % 60;
                      updateBreakRule(index, "workingHours", hours);
                      updateBreakRule(index, "workingMinutes", mins);
                      hours !== breakRule?.workingHours && updateBreakRule(index, "workingHours", hours);
                      mins !== breakRule?.workingMinutes && updateBreakRule(index, "workingMinutes", mins);
                    }}
                    // hoursPlaceholder={lg.Std}
                    // minutesPlaceholder={lg.Min}
                    inputWidth={40}
                    disabled={!breakRule.isActive}
                    style={{ marginLeft: "8px", marginRight: "8px" }}
                    showTooltip={true}
                    showTooltipAt="top"
                  />
                  {lg.Arbeit}
                  <Icon type="right" style={{ marginLeft: "8px" }} />
                  <InputNumber
                    size="small"
                    style={{ marginLeft: "8px", marginRight: "8px", width: 65 }}
                    decimalSeparator={decimalSeparatorLocal}
                    onChange={(m) => updateBreakRule(index, "breakMinutes", m)}
                    value={breakRule.isActive ? breakRule?.breakMinutes : undefined}
                    min={0}
                    max={99000}
                    disabled={!breakRule.isActive}
                  />
                  {lg.minuten_pause}
                </div>
              ))}
            </div>
          </div>
        </TZModal.Body>
        <TZModal.Footer>
          <Button
            id="save-branch"
            type="primary"
            disabled={isSaveDisabled()}
            onClick={saveClicked}
            children={lg.Speichern}
          />
        </TZModal.Footer>
      </TZModal>
    </div>
  );
});
