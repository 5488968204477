import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { Button, Icon } from "antd";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";
import { openModal } from "../../../actions/modal";
import JobPositionsPopup from "./JobPositionsModal/JobPositionsModal";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";

import { jobPositionRepository } from "../../../repositories/jobPositionRepository";
import _ from "lodash";
import { selectActiveJobPositions } from "../../../selectors/ActiveJobPositionsSelector";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { IJobPosition } from "../../../shared/entities/IJobPosition";
import { reorder } from "../../../helpers/general";

const mapStateToProps = (state: AppState) => {
  return {
    jobPositions: selectActiveJobPositions(state),
    isV2: state.data.tenantInfo.isV2,
  };
};

type OwnProps = {};
type State = {
  jobPositions: IJobPosition[];
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class JobPositions extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      jobPositions: _.orderBy(props.jobPositions, ["sortIndex"]),
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!_.isEqual(this.props.jobPositions, prevProps.jobPositions)) {
      this.setState({
        jobPositions: _.orderBy(this.props.jobPositions, ["sortIndex"]),
      });
    }
  }

  onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reOrderedJobPositions = reorder(this.state.jobPositions, result.source.index, result.destination.index).map(
      (j, i) => {
        return {
          ...j,
          sortIndex: i,
        };
      }
    );

    this.setState({
      jobPositions: reOrderedJobPositions,
    });

    this.props.dispatch(jobPositionRepository.updateList(reOrderedJobPositions));
  };

  render() {
    const { isV2 } = this.props;

    return (
      <>
        <div className="jobPositionsMain ">
          <h2 className="">{lg.rollen}</h2>
          <div className="jobPositionList">
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    // style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {this.state.jobPositions.map((item: IJobPosition, index: number) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided, snapshot) => (
                          <div>
                            <div
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                              style={{
                                ...provided.draggableProps.style,
                                marginBottom: 12,
                              }}
                            >
                              <div
                                className="jobPositionItem"
                                onClick={() => this.props.dispatch(openModal(JobPositionsPopup, { data: item }))}
                              >
                                <Icon type="more" style={{ marginRight: 12, cursor: "move" }} />
                                <div className="color" style={{ backgroundColor: item.color }}></div>
                                <div className="name">{item.name}</div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          {!isV2 && (
            <span className="infoText">
              {lg.die_reihenfolge_der_rollen_kann_per_drag_drop_geändert_werden_der_dienstplan_folgt_dieser_sortierung}
            </span>
          )}
          <div className="buttonWrapper">
            <Button
              id="add-job-positions"
              type="primary"
              onClick={() => this.props.dispatch(openModal(JobPositionsPopup, {}))}
            >
              {" "}
              {lg.rolle_hinzufügen}{" "}
            </Button>
          </div>
        </div>
        {/* <JobPositionsPopup/> */}
      </>
    );
  }
}

export default withErrorBoundary(connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(JobPositions));
