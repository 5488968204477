import { Button, Icon, Popover } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { closeModal, openModal } from "../../../../../actions/modal";
import { pastelColors } from "../../../../../frontend-core/helpers/colors";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";
import WorkSpaceEditor from "../../../../settings/branches/branchPopup/workspaceEditor/WorkSpaceEditor";
import WorkSpaceForm from "../../../../settings/branches/branchPopup/workspaceEditor/workspaceForm/WorkSpaceForm";
import { workSpaceRepository } from "../../../../../repositories/workSpaceRepository";
import { IWorkSpace } from "../../../../../shared/entities/IWorkSpace";
import { busyInjector, BusyInjectorProps } from "../../../../../components/BusyInjector/BusyInjector";
import TZModal from "../../../../../components/TZModal/TZModal";
import "./styles.scss";
import { RosterFilterPopover } from "../../components/RosterFilterPopover/RosterFilterPopover";
import { useSelector } from "../../../../../helpers/redux";
import cn from "classnames";

export const RosterFilterButton = (props: {}) => {
  const [isVisible, setVisible] = useState(false);
  const rosterFilter = useSelector((s) => s.ui.shifts.roster.rosterFilter);
  const { jobPositions, workSpaces, timeSpan, addresses, hashtags } = rosterFilter;
  const isActive =
    !!jobPositions.length || !!workSpaces.length || !!addresses.length || !!hashtags.length || !!timeSpan.length;

  return (
    <Popover
      content={isVisible ? <RosterFilterPopover onComplete={() => setVisible(false)} /> : null} // hacky way to mount/unmount the content
      title={null}
      trigger="click"
      visible={isVisible}
      onVisibleChange={setVisible}
      placement="bottomRight"
    >
      <div
        className={cn({ rosterFilterButtonMain: true, isActive, isVisible })}
        data-rh={isActive || isVisible ? undefined : "Filter"}
      >
        <Icon type="filter" theme="filled" />
        {isActive && <div className="isActiveLabel">{lg.filter_aktiv}</div>}
      </div>
    </Popover>
  );
};
