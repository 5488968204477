import React from 'react';
import { FormikProps } from 'formik';
import { Form } from 'antd';
import './styles.scss';
import AvFormField from '../AvFormField/AvFormField';

import AvTimeInput from '../AvTimeInput/AvTimeInput';

type Props = {
  formikProps: FormikProps<any>;
  fieldName: string;
  noErrorMessage?: boolean; // to hide the error-message-div
  noError?: boolean; // to hide all error displaying ( msg / red-border / icon )
  label?: string;
  disabled?: boolean;
  onChange?: (time: string | undefined) => void;
};

export default class AvFormikTimePicker extends React.PureComponent<Props, {}> {
  update = (time: string | undefined) => {
    this.props.formikProps.setFieldValue(this.props.fieldName, time);
    this.props.onChange && this.props.onChange(time);
  };

  render() {
    const {
      fieldName,
      formikProps,
      label,
      noErrorMessage,
      disabled,
      noError,
    } = this.props;

    const isTouched = formikProps.touched[fieldName];
    const error: string | undefined | false =
      isTouched && (formikProps.errors[fieldName] as any);

    return (
      <AvFormField label={label} errorMessage={!noError && !noErrorMessage && error}>
        <Form.Item
          style={{ marginBottom: 0 }}
          validateStatus={!noError && error ? 'error' : undefined}
          hasFeedback={true}
        >
          <div className="avTimeInputWrapper">
            <AvTimeInput
              value={formikProps.values[fieldName]}
              onValueChange={this.update}
              disabled={disabled}
              style={{ textAlign: 'center', fontSize: 16 }}
            />
          </div>
        </Form.Item>
      </AvFormField>
    );
  }
}
