import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";
import { IShift } from "../../../../shared/entities/IShift";
import { Input } from "antd";
import { assignedShiftsSelector } from "../../../../selectors/assignedShiftsSelector";
import { selectWorkSpaces } from "../../../../selectors/_workSpacesSelector";

const mapStateToProps = (state: AppState) => ({
  workSpaces: selectWorkSpaces(state),
  jobPositions: state.data.jobPositions,
  assignedUsersMap: assignedShiftsSelector(state),
});

type OwnProps = {
  shift: IShift;
  setUserFilter?: any;
};

type State = {
  isLoading: boolean;
  editMode: boolean;
  initialComment?: string;
};

type StoreProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StoreProps & DispatchBaseProps;

class _OpenShiftInfoRow extends React.PureComponent<Props, State> {
  render() {
    const { shift, assignedUsersMap } = this.props;
    const jobPosition = this.props.jobPositions.find((j) => j.id === this.props.shift.jobPositionId);
    const workSpace = this.props.workSpaces.find((j) => j.id === this.props.shift.workSpaceId);
    const assignedUser = (shift.isRequirement && assignedUsersMap[shift.id]) || 0;

    return (
      <div className="openShiftInfoRowMain">
        <div className="requiredUsers">
          <div className="label ">{shift.isRequirement ? lg.bedarf : lg.benötigtes_personal} </div>
          <div className="count">
            {shift.isRequirement ? `${assignedUser} / ${shift.requiredUsersAmount} ` : shift.requiredUsersAmount}
          </div>
        </div>
        <div className="searchInputWrapper" key="searchRow">
          <Input.Search
            placeholder={lg.filtern}
            onChange={this.props.setUserFilter || ((e) => {})}
            style={{ width: 110, height: 27 }}
            size="small"
          />
        </div>
        <div className="shiftTags">
          {jobPosition && (
            <div className="tag jobPosition" style={{ backgroundColor: jobPosition!.color }}>
              {jobPosition.name}
            </div>
          )}
          {workSpace && (
            <div className="tag workSpace" style={{ backgroundColor: workSpace!.color }}>
              {workSpace.name}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export const OpenShiftInfoRow = connect(mapStateToProps)(_OpenShiftInfoRow);
