import { IRosterTemplate } from "./../shared/entities/IRosterTemplate";
import { Dispatch } from "redux";

import { shiftRepository } from "../repositories/shiftRepository";

import { rosterTemplateRepository } from "../repositories/rosterTemplateRepository";

import { DispFn } from "../frontend-core/types/thunkTypes";
import { addSimpleDays } from "../shared/helpers/dateHelpers";

export const rosterTemplateActionTypes = {
  SET_EDIT_MODE: "@@AV/ROSTER_TEMPLATE_SET_EDIT_MODE",
};

export const setRosterTemplateMode = (
  active: boolean,
  rosterTemplate?: IRosterTemplate
) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: rosterTemplateActionTypes.SET_EDIT_MODE,
      payload: { active, rosterTemplate: active ? rosterTemplate : undefined },
    });
  };
};

export const onDeleteTemplateConfirm = (
  shiftsStoredAtDate: string,
  rosterTemplate: IRosterTemplate
) => async (dispatch: DispFn) => {
  const branchId = rosterTemplate.branchId;
  const from = shiftsStoredAtDate;
  const to = addSimpleDays(shiftsStoredAtDate, 6);
  const fromComp = `${branchId}_${from}`;
  const toComp = `${branchId}_${to}`;

  const shiftIdsToRemove = (
    await dispatch(
      shiftRepository.fetchMany({
        filter: ["branchId_date", "between", [fromComp, toComp]],
      })
    )
  ).map((s) => s.id);

  await dispatch(shiftRepository.removeList(shiftIdsToRemove));
  await dispatch(rosterTemplateRepository.remove(rosterTemplate.id));
  dispatch(setRosterTemplateMode(false));
};
