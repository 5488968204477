import { Raw, IResource } from "./IResource";

export enum RoleType {
  admin = "admin",
  manager = "manager",
  employee = "employee",
}

export type IUser = IResource & {
  jobPositionIds: string[];
  branchIds: string[];
  timeClockId?: string;
  createdAt: string;
  role: RoleType;
  accountId?: string;
  lastWorkDay?: string;
  isDeleted?: boolean;
  isInvited?: boolean;
  isHiddenInRoster?: boolean;
  canManageEmployees?: boolean;
  hasICalSyncing?: boolean;
  sortIndexByBranch?: {
    [branchId: string]: number;
  };
};

export type IUserFull = IUser & {
  email?: string;
  name: string;
  photoUrl?: string;
};

export type IUserSetup = {
  name: string;
  jobPositionIds: string[];
};

export type IUserRaw = Raw<IUser>;
