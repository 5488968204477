import React, { useState } from "react";
import { useSelector } from "../../../../helpers/redux";
import _ from "lodash";
import GridUserCell from "../RosterWeekGrid/GridUserCell/GridUserCell";
import { UserFilter } from "../../../../components/UserFilter/UserFilter";
import { selectActiveUsers, selectActiveUsersFull } from "../../../../selectors/ActiveUserSelectors";
import { isUserFilteredOut } from "../../../../selectors/visibleUsersOfRosterSelector";
import { orderUsers } from "../../../../helpers/general";
import { selectCreditSpans } from "../../../../selectors/creditSpansSelector";
import { selectContractsByUser } from "../../../../selectors/contractsByUserSelector";
import { featuresSelector } from "../../../../selectors/FeaturesSelector";
import { selectInitialCreditByUser } from "../../../../selectors/initialCreditsByUserSelector";
import { selectRosterDateRange } from "../../../../selectors/rosterDateRangeSelector";
import { selectJobPositionMap, selectUserDetailMap } from "../../../../selectors/mapSelectors";
import { getUserName } from "../../../../shared/helpers/userHelpers";
import { IUserFull } from "../../../../shared/entities/IUser";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { getValidContract } from "../../../../shared/helpers/credit";
import { monthlyMaxHoursWarningSelector } from "../../../../selectors/monthlyMaxHoursWarningSelector";

type Props = {
  startDate: string;
};

export const KanbanUserList = (props: Props) => {
  const rosterSettings = useSelector((s) => s.data.rosterSettings[0]);
  const usersFull = useSelector((s) => selectActiveUsersFull(s, props.startDate));
  const selectedBranchId = useSelector((s) => s.ui.selectedBranch);
  const isV2 = useSelector((s) => s.data.tenantInfo.isV2);
  const usersOrderedFull = orderUsers(usersFull, selectedBranchId, rosterSettings.sortRosterEmployeesByName);
  const rosterMode = useSelector((s) => s.ui.shifts.roster.rosterMode);
  const [userNameFilter, setUserNameFilter] = useState<string | undefined>("");
  const [posFilter, setPosFilter] = useState<string | undefined>("");
  const sessionInfo = useSelector(selectSessionInfo);
  const jobPosMap = useSelector(selectJobPositionMap);
  const contractsMap = useSelector(selectContractsByUser);
  const userDetailMap = useSelector(selectUserDetailMap);
  const features = useSelector(featuresSelector);
  const initialUserCredits = useSelector(selectInitialCreditByUser);
  const rosterDateRange = useSelector(selectRosterDateRange);
  const isTemplateMode = useSelector((s) => s.ui.shifts.roster.rosterTemplateMode.active);
  const maxHoursExceedingMap = useSelector(monthlyMaxHoursWarningSelector);

  const canManage = sessionInfo.hasManagerPermissions();
  const showCredits = canManage || undefined;
  const creditSpan = useSelector((s) => showCredits && selectCreditSpans(s));

  return (
    <div className="kanbanUserListMain">
      <div className="userFilterWrapper">
        <UserFilter
          noBranchFilter={true}
          onSearchChange={setUserNameFilter}
          onBranchChange={() => {}}
          onPositionChange={setPosFilter}
          searchInput={userNameFilter}
          positionFilter={posFilter}
        />
      </div>
      <div className="usersListContent">
        {usersOrderedFull
          .filter((u) => !selectedBranchId || u.branchIds.includes(selectedBranchId))
          .filter((u) => !posFilter || u.jobPositionIds.includes(posFilter))
          .filter((u) => !isUserFilteredOut(u, userNameFilter, userDetailMap[u.id]))
          .map((u) => {
            const validContract = getValidContract(contractsMap[u.id], props.startDate)!;
            const userTypes = rosterSettings.userTypes || {};
            const userType = validContract?.userTypeId ? userTypes[validContract.userTypeId] : undefined;
            return (
              <GridUserCell
                user={u}
                startDate={props.startDate}
                key={u.id}
                rosterMode={rosterMode}
                jobPosMap={jobPosMap}
                creditSpan={creditSpan?.[u.id]}
                contract={validContract}
                features={features}
                initialUserCredit={initialUserCredits[u.id]}
                rosterDateRange={rosterDateRange}
                isTemplateMode={isTemplateMode}
                customMonthStartDay={rosterSettings.customMonthIntervalStart || 1}
                canManage={sessionInfo.hasManagerPermissions()}
                showOvertime={!rosterSettings.hideOvertimeInRoster}
                staffNumber={rosterSettings.showStaffNumberInRoster ? userDetailMap[u.id]?.employNum : undefined}
                userType={rosterSettings.showUserTypeInRoster ? userType : undefined}
                isKanban={true}
                maxHoursExceeding={maxHoursExceedingMap[u.id]}
                isV2={isV2}
              />
            );
          })}
      </div>
    </div>
  );
};
