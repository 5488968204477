import { Button, Icon, Input, Popover } from "antd";
import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import {
  selectJobPositionMap,
  selectWorkSpaceMap,
  selectBranchMap,
  selectUserMap,
  selectShiftAddressMap,
} from "../../../selectors/mapSelectors";
import { ITimeClocking } from "../../../shared/entities/ITimeClocking";
import cn from "classnames";
import { useSelector } from "../../../helpers/redux";
import { selectActiveBranches } from "../../../selectors/ActiveBranchesSelector";
import { selectActiveJobPositions } from "../../../selectors/ActiveJobPositionsSelector";
import { selectActiveWorkSpaces } from "../../../selectors/ActiveWorkSpacesSelector";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { timeClockingRepository } from "../../../repositories/timeClockingRepository";

type Props = {
  timeClocking?: ITimeClocking;
  preClocking: Partial<ITimeClocking>;
  updateClocking: (c: Partial<ITimeClocking>) => void;
};

export const PunchClockOptionsBar = ({ timeClocking, preClocking, updateClocking }: Props) => {
  const dispatch = useDispatch();
  const [openPopoverKey, setOpenPopoverKey] = useState<string | undefined>(undefined);
  const [commentStr, setComment] = useState<string>("");

  useEffect(() => {
    setComment((timeClocking || preClocking).comment || "");
  }, [timeClocking]);

  const addresses = useSelector((s) => s.data.shiftAddresses.filter((a) => !a.isDisabled));
  const labels = useSelector(selectActiveWorkSpaces);
  const branches = useSelector(selectActiveBranches);

  const addressMap = useSelector(selectShiftAddressMap);
  const labelMap = useSelector(selectWorkSpaceMap);
  const userMap = useSelector(selectUserMap);
  const user = userMap[(timeClocking || preClocking).userId!];

  const renderOptionsMenu = (timeClockKey: keyof ITimeClocking, options: { name: string; id: string }[]) => (
    <div className="punchClockOptionsPopoverMenu">
      {options.map((o) => (
        <div
          className="menuOption"
          key={o.id}
          onClick={() => {
            const _new = { [timeClockKey]: o.id };
            updateClocking(_new);
            setOpenPopoverKey(undefined);
          }}
        >
          {o.name || (o as any).address}
        </div>
      ))}
    </div>
  );

  const renderCommentInput = () => (
    <div className="punchClockCommentInputBox">
      <div className="inputWrapper">
        <Input.TextArea
          placeholder={lg.kommentar}
          rows={3}
          value={commentStr}
          onChange={(e) => setComment(e.target.value)}
          autoFocus
        />
      </div>
      <Button
        className="commentSaveBtn"
        type="primary"
        children={lg.Speichern}
        onClick={() => {
          updateClocking({ comment: commentStr });
          setOpenPopoverKey(undefined);
        }}
      />
    </div>
  );

  const getBoxButton = (
    key: keyof ITimeClocking,
    placeholder: string,
    iconType: string,
    label?: string,
    popoverContent?: any
  ) => (
    <Popover
      trigger="click"
      placement={"top"}
      title={null}
      visible={openPopoverKey === key}
      onVisibleChange={(isOpen) => setOpenPopoverKey(isOpen ? key : undefined)}
      content={popoverContent}
      key={key}
    >
      <div className={cn({ box: true, isEmpty: !label })} key={key}>
        <div className="logoWrapepr">
          <Icon type={iconType} />
        </div>
        <div className="label">{label || placeholder}</div>
      </div>
    </Popover>
  );

  const selectableBranches = branches.filter((jp) => user.branchIds.includes(jp.id) && !jp.isDisabled);
  const selectableAddresses = addresses;

  const _timeClocking = timeClocking || preClocking;

  const labelVal = _timeClocking.workSpaceId && labelMap[_timeClocking.workSpaceId].name;
  const addressVal =
    _timeClocking.addressId &&
    (addressMap[_timeClocking.addressId].name || addressMap[_timeClocking.addressId].address);

  const commentBox = getBoxButton("comment", lg.kommentar, "file-text", _timeClocking.comment, renderCommentInput());
  const labelBox = getBoxButton("workSpaceId", lg.label, "tag", labelVal, renderOptionsMenu("workSpaceId", labels));

  const addressBox = getBoxButton(
    "addressId",
    lg.adresse,
    "environment",
    addressVal,
    renderOptionsMenu("addressId", selectableAddresses)
  );

  const boxesArray: any = [];
  // branches.length > 1 && user.branchIds.length > 1 && boxesArray.push(branchBox);
  labels.length > 1 && boxesArray.push(labelBox);
  addresses.length > 1 && boxesArray.push(addressBox);
  boxesArray.push(commentBox);

  return <div className="punchClockOptionsBarMain">{boxesArray}</div>;
};
