import { IResource } from "./IResource";

export enum AbsenceTypeCode {
  vacation = "vacation",
  illness = "illness",
  unpaidVacation = "unpaidVacation",
  overtimeReduction = "overtimeReduction",
  custom = "custom",
}

export enum AbsenceEarning {
  none = "none",
  dailyQuota = "dailyQuota",
  meanQuota = "meanQuota",
  asPlaned = "asPlaned",
}

export type IAbsenceType = IResource & {
  name: string;
  color: string;
  code: AbsenceTypeCode;
  earning: AbsenceEarning;
  allowHalfDay?: boolean;
  canBeRequested?: boolean;
  isVisibleToColleagues?: boolean;
  hasEntitlement?: boolean;
  // employeesCanAttachFiles?: boolean;
};
