import { Dispatch } from "redux";
import { ReportMasterDataColumns } from "../reducers/ui/reportSettings/reportMasterDataColumns";
import { IUserDetail } from "../shared/entities/IUserDetail";

export const reportFilterActionTypes = {
  SET: "@@AV/REPORT_FILTER",
};

export enum ReportType {
  user,
  shift,
}

export type ReportFilterData = {
  filterStartDate: string;
  filterEndDate: string;
  filterJobPositionId?: string;
  filterUserId?: string;
  filterAddressId?: string;
  filterBranchId?: string;
  filterLabelId?: string;
  filterHashtagId?: string;
};

export const setReportFilterData = (payload: ReportFilterData) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: reportFilterActionTypes.SET,
      payload,
    });
  };
};

//////

export const reportColumnActionTypes = {
  SET: "@@AV/REPORT_COLUMNS",
};

export const reportMasterDataColumnActionTypes = {
  UPDATE: "@@AV/REPORT_MASTER_DATA_COLUMNS",
};

export type ReportColumns = {
  shiftId: boolean;
  date: boolean;
  userName: boolean;
  staffNumber: boolean;
  jobPositionName: boolean;
  branchName: boolean;
  addressName: boolean;
  shiftTime: boolean;
  trackedTime: boolean;
  duration: boolean;
  comment: boolean;
  workSpaceName: boolean;
  breakIntervalls: boolean;
  hashtags: boolean;
  wage: boolean;
  nightSurcharge: boolean;
  holidaySurcharge: boolean;
  sundaySurcharge: boolean;
  customSurcharge: boolean;
};

export const setReportColumns = (payload: ReportColumns) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: reportColumnActionTypes.SET,
      payload,
    });
  };
};

export const updateReportMasterDataColumns = (payload: Partial<ReportMasterDataColumns>) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: reportMasterDataColumnActionTypes.UPDATE,
      payload,
    });
  };
};
