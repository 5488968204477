import { selectAvailabilites } from "./availabilitiesSelector";
import { IAbsence } from "../shared/entities/IAbsence";
import { IAvailability } from "../shared/entities/IAvailability";
import { IBranch } from "../shared/entities/IBranch";
import { IChangeRequest } from "../shared/entities/IChangeRequest";
import { IContract } from "../shared/entities/IContract";
import { IJobPosition } from "../shared/entities/IJobPosition";
import { IShiftHandOverRequest } from "../shared/entities/IShiftHandOverRequest";
import { ITimeClocking } from "../shared/entities/ITimeClocking";
import { ITracking } from "../shared/entities/ITracking";
import { IUser } from "../shared/entities/IUser";
import { IHashtag, IWorkSpace } from "../shared/entities/IWorkSpace";
import { createMapSelector } from "../shared/helpers/createMapSelector";
import { AppState } from "../types/AppState";
import { IShift } from "../shared/entities/IShift";
import { IShiftAddress } from "../shared/entities/IShiftAddress";
import { IUserDetail } from "../shared/entities/IUserDetail";
import { IAnnouncement } from "../shared/entities/IAnnouncement";
import { selectWorkSpaces } from "./_workSpacesSelector";
import { selectHashtags } from "./hashtagSelector";
import { IPublishedWeek } from "../shared/entities/IPublishedWeek";

export const selectJobPositionMap = createMapSelector<IJobPosition, AppState>((s) => s.data.jobPositions);
export const selectTrackingMap = createMapSelector<ITracking, AppState>((s) => s.data.trackings);
export const selectHandOverRequestMap = createMapSelector<IShiftHandOverRequest, AppState>(
  (s) => s.data.shiftHandOverRequests
);
export const selectUserMap = createMapSelector<IUser, AppState>((s) => s.data.users);
export const selectChangeRequestMap = createMapSelector<IChangeRequest, AppState>((s) => s.data.changeRequests);
export const selectTimeClockingMap = createMapSelector<ITimeClocking, AppState>((s) => s.data.timeClockings);
export const selectBranchMap = createMapSelector<IBranch, AppState>((s) => s.data.branches);
export const selectAbsenceMap = createMapSelector<IAbsence, AppState>((s) => s.data.absences);
export const selectPublishedWeeksMap = createMapSelector<IPublishedWeek, AppState>((s) => s.data.publishedWeeks);
export const selectWorkSpaceMap = createMapSelector<IWorkSpace, AppState>(selectWorkSpaces);
export const selectHashtagMap = createMapSelector<IHashtag, AppState>(selectHashtags);
export const selectShiftAddressMap = createMapSelector<IShiftAddress, AppState>((s) => s.data.shiftAddresses);
export const selectUserDetailMap = createMapSelector<IUserDetail, AppState>((s) => s.data.userDetails);
export const selectAnnouncementMap = createMapSelector<IAnnouncement, AppState>((s) => s.data.announcements);
export const selectContractsByUserMap = createMapSelector<IContract, AppState>((s) => s.data.contracts, "userId");
export const selectShiftsByUserMap = createMapSelector<IShift, AppState>((s) => s.data.shifts, "userId");
export const selectAvailabilitiesByUserMap = createMapSelector<IAvailability, AppState>(selectAvailabilites, "userId");
