import { sitemap } from "./../helpers/sitemap";
import { notification } from "antd";
import { push } from "connected-react-router";
import { RoleType } from "./../shared/entities/IUser";
import { baseDataLoading } from "./../reducers/dataStatus";
import { ISetupWizardTemp } from "../shared/entities/ISetupWizardTemp";
import { firebaseListenerRepo } from "./firebaseListenerRepo";
import { ILoginToken } from "./../shared/types/ILoginToken";
import { envi, environment } from "./../env";
import { sendPost } from "./../frontend-core/actions/send";
import { IRegisterTenant } from "../shared/types/IRegisterTenant";
import { Reducer } from "redux";
import { PersistConfig, persistReducer } from "redux-persist";
import { PersistPartial } from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import { ISession } from "../shared/types/ISession";
import { DispFn } from "../frontend-core/types/thunkTypes";
import firebase from "firebase/compat/app";
import { getLocaleLang } from "../helpers/dateFormatHelper";
import { clearLocalStorageExcept } from "../frontend-core/helpers/frontendHelpers";

export interface IAuthReducerState {
  session?: ISession;
}
export type state = IAuthReducerState;
export const initialState: IAuthReducerState = {};

const persistConfig: PersistConfig<any> = {
  key: "session",
  storage: storage,
  whitelist: ["session"] as (keyof IAuthReducerState)[],
};

export class AuthRepository {
  login(credentials: { email: string; password: string }) {
    return async (dispatch: DispFn): Promise<boolean> => {
      const url = `/api/login`;
      const loginToken = (await dispatch(sendPost(url, credentials))) as any as ILoginToken;
      if (!!loginToken.isV2 !== !!envi.isV2()) {
        throw new Error("Falsche App: Aplano / Zeitguru");
      }
      dispatch(baseDataLoading());
      firebase.auth().signInWithCustomToken(loginToken.firebaseToken);
      return true;
    };
  }

  sendPasswordReset(email: string, redirectUrl: string, isV2: boolean) {
    return (dispatch: DispFn) => {
      const url = `/api/send-password-reset`;
      // need to pass in language here > because rosterSettings is not loaded
      return dispatch(sendPost(url, { email, redirectUrl, language: getLocaleLang(), isV2 }));
    };
  }

  isTokenActive(token: string) {
    return (dispatch: DispFn) => {
      const url = `/api/is-token-active`;
      return dispatch(sendPost(url, { token }));
    };
  }

  resetPassword(password: string, token: string) {
    return (dispatch: DispFn) => {
      const url = `/api/reset-password`;
      return dispatch(sendPost(url, { password, token }));
    };
  }

  completeInvitation(password: string, token: string) {
    return (dispatch: DispFn) => {
      const url = `/api/complete-invitation`;
      return dispatch(sendPost(url, { password, token }));
    };
  }

  seedTenant(wizardData: ISetupWizardTemp) {
    return (dispatch: DispFn) => {
      return dispatch(sendPost(`/api/seed-tenant`, wizardData));
    };
  }

  logout = () => async (dispatch: DispFn) => {
    dispatch(firebaseListenerRepo.removeAll());
    await firebase.auth().signOut();
    dispatch({ type: "@@AV/LOGOUT" });
    clearLocalStorageExcept(["language"]);
    window.location.reload();
  };

  setSession = (session: ISession) => async (dispatch: DispFn) => {
    dispatch({
      type: "@@AV/LOGIN",
      payload: session,
    });
  };

  //This is now being triggered by the website
  registerTenant(registration: IRegisterTenant) {
    // return (dispatch: DispFn) => {
    //   const url = `/api/register-tenant`;
    //   // need to pass in language here > because rosterSettings is not loaded
    //   return dispatch(sendPost(url, { registration, language: getLocaleLang() }));
    // };
  }

  addDevSeeding() {
    // return (dispatch: DispFn) => {
    //   const url = `/api/add-dev-seeding`;
    //   return dispatch(sendPost(url, { origin: environment.getCloudFunctionsHost() }));
    // };
  }

  getReducer(): Reducer<IAuthReducerState & PersistPartial, any> {
    const reducer = (state: IAuthReducerState = initialState, action: any): IAuthReducerState => {
      switch (action.type) {
        case "@@AV/LOGOUT":
          return {};
        case "@@AV/LOGIN":
          return { ...state, session: action.payload as ISession };
        default:
          return state;
      }
    };

    return persistReducer(persistConfig, reducer);
  }
}

export const authRepository = new AuthRepository();
