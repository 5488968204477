import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../types/AppState";
import { Popover, Badge, Icon, Button } from "antd";
import "./styles.scss";

import { selectMonth, setSelectedYear } from "../../../../../actions/absence";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";

import moment from "moment";
import _ from "lodash";
import cn from "classnames";
import { openModal } from "../../../../../actions/modal";
import AbsenceModal from "../../../../../components/modals/AbsenceModal/AbsenceModal";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { selectUsersWithSharedBranch } from "../../../../../selectors/UsersWithSharedBranchSelector";
import { AbsenceStatus } from "../../../../../shared/entities/IAbsence";
import { SDateFormat } from "../../../../../shared/helpers/SimpleTime";
import { selectAbsencesExtended } from "../../../../../selectors/absencesExtendedSelector";
import { AbsenceTypeCode } from "../../../../../shared/entities/IAbsenceType";
import { getUserName } from "../../../../../shared/helpers/userHelpers";

const mapStateToProps = (state: AppState) => ({
  absences: selectAbsencesExtended(state),
  sessionInfo: selectSessionInfo(state),
  users: state.data.users,
  selectedYear: state.ui.absences.selectedYear,
  selectedMonth: state.ui.absences.selectedMonth,
  absenceTypes: state.data.absenceTypes,
  usersWithSharedBranch: selectUsersWithSharedBranch(state),
  rosterSettings: state.data.rosterSettings[0],
});

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class AbsenceRequestTracker extends PureComponent<Props, {}> {
  state = {
    visible: false,
  };

  jumpToAbsence = (month: number, year: number) => {
    this.props.dispatch(selectMonth(month));
    this.props.dispatch(setSelectedYear(year));
  };

  onVisibleChange = (visible: boolean) => {
    this.setState({ visible });
  };

  render() {
    const { absences, usersWithSharedBranch, sessionInfo, rosterSettings, absenceTypes } = this.props;
    const { managersCanManageAbsences } = rosterSettings;
    const canManageAbsences = sessionInfo.isAdmin() || (sessionInfo.isManager() && managersCanManageAbsences);
    const requestableAbsenceTypes = absenceTypes.filter((t) => t.canBeRequested);
    const onlyVacationsCanBeRequested =
      requestableAbsenceTypes.length === 1 && requestableAbsenceTypes[0].code === AbsenceTypeCode.vacation;

    const absenceRequsts = absences.filter(
      (absence) =>
        (canManageAbsences || absence.userId === sessionInfo.user.id) &&
        usersWithSharedBranch.includes(absence.userId) &&
        absence.status === AbsenceStatus.requested
    );

    const content = (
      <div
        className="requestTrackerPopover"
        onClick={() => {
          this.onVisibleChange(false);
        }}
      >
        <div className="popoverContent">
          {canManageAbsences && !absenceRequsts.length && (
            <div className="noRequests">
              <div className="label">{lg.keine_anträge_vorhanden}</div>
              <div className="iconWrapper">
                <Icon type="smile" />
              </div>
            </div>
          )}
          {!canManageAbsences && !!requestableAbsenceTypes.length && (
            <div
              className="requestAbsenceButton"
              style={{ marginBottom: absenceRequsts.length ? 4 : 0 }}
              onClick={() => {
                this.props.dispatch(openModal(AbsenceModal, { userId: sessionInfo.user.id }));
              }}
            >
              {onlyVacationsCanBeRequested ? lg.urlaub_beantragen : lg.abwesenheit_beantragen}
            </div>
          )}
          {absenceRequsts.map((absence) => {
            const user = this.props.users.find((u) => u.id === absence.userId)!;
            const startDate = moment(absence.startDate as string, SDateFormat).format("L");
            const endDate = moment(absence.endDate as string, SDateFormat).format("L");
            const effectiveDays = _.sum(Object.values(absence.effectiveDays));
            const startDateMom = moment(absence.startDate as string, SDateFormat);
            const month = startDateMom.month();
            const year = startDateMom.year();
            const isCurMonth = month === this.props.selectedMonth && year === this.props.selectedYear;

            return (
              <div key={absence.id} className="row">
                <div
                  className="mainContent"
                  onClick={() => {
                    this.props.dispatch(openModal(AbsenceModal, { absence, userId: absence.userId }));
                  }}
                >
                  <div className="mainRow">
                    <div className="userName">{getUserName(user)}</div>
                    <div className="absenceType">
                      {this.props.absenceTypes.find((aT) => aT.id === absence.typeId)?.name}
                    </div>
                  </div>
                  <div className="dates">
                    {/* <Icon type="caret-right" /> */}
                    {startDate + " - " + endDate}
                    <div className="daysCount">
                      {effectiveDays} {lg.Tage}
                    </div>
                  </div>
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    this.jumpToAbsence(month, year);
                  }}
                  className={cn({ arrow: true, clickable: !isCurMonth })}
                >
                  <Icon type="double-right" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );

    if (!requestableAbsenceTypes.length) {
      return null;
    }

    return (
      <div className="absenceRequestTrackerMain">
        <Popover
          content={content}
          placement="bottom"
          trigger="click"
          visible={this.state.visible}
          onVisibleChange={this.onVisibleChange}
        >
          <Badge count={absenceRequsts.length}>
            <Button id="vacation-requests" className="absenceRequestTrackerMain">
              {onlyVacationsCanBeRequested ? lg.urlaubsanträge : lg.abwesenheitsanträge}
            </Button>
          </Badge>
        </Popover>
      </div>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(AbsenceRequestTracker);
