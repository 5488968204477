import { AppState } from "./../types/AppState";
import { createSelector } from "reselect";
import { selectVisibleBranches } from "./VisibleBranchesSelector";

export const selectPublishedWeekplans = (state: AppState) => state.data.publishedWeeks;
export const selectCurrentWeek = (state: AppState) => state.ui.shifts.roster.selectedWeek;
export const selectCurrentBranchId = (state: AppState) => state.ui.selectedBranch;

export const selectIsWeekPlanPublished = createSelector(
  selectPublishedWeekplans,
  selectCurrentWeek,
  selectCurrentBranchId,
  (state: AppState) => state.ui.shifts.roster.rosterTemplateMode.active,
  (state: AppState) => state.data.tenantInfo.isV2,
  (publishedWeekPlans, currentWeek, currentBranchId, templateEditModeIsActive, isV2) => {
    return (
      isV2 ||
      (!templateEditModeIsActive &&
        !!publishedWeekPlans.find(
          (wp) => wp.branchId === currentBranchId && wp.startDate === currentWeek && wp.published
        ))
    );
  }
);

export const selectCurrentPublishedBranches = createSelector(
  selectPublishedWeekplans,
  selectCurrentWeek,
  selectCurrentBranchId,
  (state: AppState) => state.ui.shifts.roster.rosterTemplateMode.active,
  selectVisibleBranches,
  (state: AppState) => state.data.tenantInfo.isV2,
  (publishedWeekPlans, currentWeek, currentBranchId, templateEditModeIsActive, visibleBranches, isV2) => {
    if (templateEditModeIsActive) {
      return [];
    }
    return visibleBranches.filter((b) => {
      return (
        isV2 || !!publishedWeekPlans.find((wp) => wp.branchId === b.id && wp.startDate === currentWeek && wp.published)
      );
    });
  }
);
