import React from "react";
// import { RouteComponentProps } from "react-router";
import "./styles.scss";

import moment from "moment";

import ContractEntry from "../ContractEntry/ContractEntry";
import { Button, message } from "antd";
import "./styles.scss";

import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";

import { contractRepository } from "../../../../repositories/contractRepository";
import sortBy from "lodash/sortBy";
import { updateValidToDates } from "./helpers";

import TZModal from "../../../../components/TZModal/TZModal";
import { closeModal } from "../../../../actions/modal";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import { busyInjector, BusyInjectorProps } from "../../../../components/BusyInjector/BusyInjector";
import { Raw } from "../../../../shared/entities/IResource";
import { IContract, IContractCore } from "../../../../shared/entities/IContract";
import { IUser } from "../../../../shared/entities/IUser";
import { toSimpleDate, simpleDateToMoment } from "../../../../shared/helpers/timeHelpers";
import { updateCreditsOfRoster } from "../../../../actions/creditActions/updateCreditsOfRoster";
import { selectContractsByUser } from "../../../../selectors/contractsByUserSelector";

const getLaterDate = (a: string, b: string): string => (a > b ? a : b);

const mapStateToProps = (state: AppState, props: OwnProps) => {
  return {
    userContracts: selectContractsByUser(state)[props.user.id],
    rosterSettings: state.data.rosterSettings[0],
  };
};

type State = { contracts: Raw<IContract>[]; expandedContracts: number[] };

type OwnProps = {
  user: IUser;
  isCreationMode: boolean;
  gotDirty: (isDirty?: boolean) => void;
  closeOnSave: boolean;
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class UserContractsTabComponent extends React.PureComponent<Props, State> {
  initialContractIds: string[] = []; // needed for the BatchRequest -> to delete them

  constructor(props: Props) {
    super(props);
    this.state = {
      contracts: [],
      expandedContracts: [], // holding the indeces of the expanded contracts
    };
  }

  async componentDidMount() {
    const { userContracts } = this.props;
    const contractsSorted = sortBy(userContracts, "validFrom");
    const contracts = contractsSorted.map((w) => _.omit(w, "id")) as Raw<IContract>[];

    this.setState({
      contracts,
      expandedContracts: [contracts.length - 1],
    });

    this.initialContractIds = userContracts.map((w) => w.id);
  }

  saveContracts = async () => {
    const { dispatch, load, closeOnSave } = this.props;
    const newContracts = updateValidToDates(this.state.contracts);
    const currentIds = this.initialContractIds;

    if (currentIds.length === 1 && newContracts.length === 1) {
      console.log("Simple Contract Update");
      // no need to delete and recreate contracts, do a simple update
      const updatedContract = { ...newContracts[0], id: currentIds[0] };
      await load(dispatch(contractRepository.update(updatedContract)));
    } else {
      console.log("Batch Contract Update");
      // remove all previous contracts, and create new ones
      await load(dispatch(contractRepository.updateContracts(newContracts, currentIds)));
    }

    // need to update credits because users can be edited from roster-page
    dispatch(updateCreditsOfRoster([this.props.user.id]));
    // dispatch(closeModal());
    this.props.gotDirty(false);
    message.success(lg.daten_gespeichert);
    closeOnSave && dispatch(closeModal());
  };

  updateSingleContract = (C: IContractCore, index: number) => {
    const newContract = { ...C, userId: this.props.user.id };
    const prevContracts = this.state.contracts;
    const contracts = prevContracts.map((wp, i) => (i === index ? newContract : wp));
    this.setState({ contracts });
    this.props.gotDirty();
  };

  addContractClicked = () => {
    const { contracts } = this.state;
    const lastContract = contracts[contracts.length - 1];

    const minValidFrom = getLaterDate(lastContract.validFrom, toSimpleDate(moment()));

    const validFromMoment = simpleDateToMoment(minValidFrom).add(1, "week").startOf("isoWeek");

    const newContract = {
      ...lastContract,
      validFrom: toSimpleDate(validFromMoment),
    };

    const newContracts = [...contracts, newContract].map((wp) => ({
      ...wp,
      id: uuid(),
    }));

    this.setState({
      contracts: newContracts,
      expandedContracts: [newContracts.length - 1],
    });
    this.props.gotDirty();
  };

  removeContract = (index: number) => {
    const pevContracts = this.state.contracts;
    const contracts = pevContracts.filter((wp, i) => i !== index);
    const expandedContracts = [contracts.length - 1];
    this.setState({ contracts, expandedContracts });
    this.props.gotDirty();
  };

  toggleContractEntry = (index: number) => {
    const isExpanded = !!this.state.expandedContracts.includes(index);
    const expandedContracts = isExpanded
      ? this.state.expandedContracts.filter((i) => i !== index)
      : [...this.state.expandedContracts, index];

    this.setState({ expandedContracts });
    this.props.gotDirty();
  };

  render() {
    const { contracts } = this.state;
    const { rosterSettings } = this.props;

    return (
      <div className="userDailyQuotaTab">
        <div className="userCondictionsTabMainContent">
          {contracts.map((wp, index) => (
            <ContractEntry
              key={index}
              contract={wp}
              previousContract={contracts[index - 1]}
              nextContract={contracts[index + 1]}
              displayValidFromDate={contracts.length > 1} // show validity dates -> if user has a changed contract
              isEditable={true}
              onContractChange={(wp: IContractCore) => this.updateSingleContract(wp, index)}
              onDelete={() => {
                this.removeContract(index);
              }}
              contractIndex={index}
              isCollapsed={!this.state.expandedContracts.includes(index)}
              handleEntryToggle={() => this.toggleContractEntry(index)}
              isDeletable={index !== 0 && index === contracts.length - 1}
              extraClassName={contracts.length === 1 ? "singleContract" : undefined}
              rosterSettings={rosterSettings}
            />
          ))}
          {!this.props.isCreationMode && (
            <div className="addContractButtonWrapper">
              <Button id="add-user-contract" size="small" type="ghost" onClick={this.addContractClicked}>
                {lg.neuer_eintrag}
              </Button>
            </div>
          )}
        </div>
        <TZModal.Footer style={{ border: "none", background: "#f5f5f5" }}>
          <Button
            id="save-user-contract"
            type="primary"
            onClick={this.saveContracts}
            loading={this.props.isLoading()}
            children={lg.Speichern}
          />
        </TZModal.Footer>
      </div>
    );
  }
}

export const UserContractsTab = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  busyInjector(UserContractsTabComponent)
);
