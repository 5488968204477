import { IAbsenceEntitlement } from "../shared/entities/IAbsenceEntitlement";
import { BaseRepository } from "../frontend-core/BaseRepository";

class AbsenceEntitlementRepository extends BaseRepository<IAbsenceEntitlement> {
  constructor() {
    super("vacationContingents");
  }
}

export const absenceEntitlementRepository = new AbsenceEntitlementRepository();
