import { toSimpleDate } from "./../../../../shared/helpers/timeHelpers";
import { ReportFilterData, reportFilterActionTypes, ReportType } from "../../../../actions/reporting";
import moment from "moment";

const initialState: ReportFilterData = {
  filterStartDate: toSimpleDate(moment()),
  filterEndDate: toSimpleDate(moment()),
  filterJobPositionId: undefined,
  filterUserId: undefined,
  filterAddressId: undefined,
  filterBranchId: undefined,
  filterLabelId: undefined,
  filterHashtagId: undefined,
};

export const reportFiltersReducer = (state = initialState, a: any): ReportFilterData =>
  a.type === reportFilterActionTypes.SET ? a.payload : state;
