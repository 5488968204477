import React from "react";
import Page from "../../components/Page/Page";
import { Switch, Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import { RosterSettings } from "./rosterSettings/RosterSettings";
import JobPositions from "./job-positions/JobPositions";
import { ISitemapEntry, sitemap } from "../../helpers/sitemap";
import BranchList from "./branches/branchList/BranchList";
import { Holidays } from "./holidays/holidays";
import AbsenceSettings from "./absenceSettings/AbsenceSettings";
import { withErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";
import TimeClockSettings from "./timeClockSettings/TimeClockSettings";
import { useSelector } from "../../helpers/redux";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { tierInfoSelector } from "../../selectors/TierInfoSelector";
import { Tiers } from "../../shared/constants/Tiers";
import { BillingPage } from "./billingPage/BillingPage";
import { ChatSettings } from "./chatSettings/ChatSettings";
import "./styles.scss";
import { AnnouncementSettings } from "./announcementSettings/AnnouncementSettings";
import { SideBar } from "../../components/SideBar/SideBar";

export const Settings = withErrorBoundary(() => {
  const tierInfo = useSelector(tierInfoSelector);
  const isAdmin = useSelector(selectSessionInfo).isAdmin();
  const rosterSetting = useSelector((s) => s.data.rosterSettings[0]);
  const isV2 = useSelector((s) => s.data.tenantInfo.isV2);
  const showBillingSettings = isAdmin && tierInfo.tier !== Tiers.Core && !tierInfo.isAlwaysFreePremiumTenant;

  const subBarLinks = [
    sitemap.rosterSettings,
    isV2 && sitemap.timeClockSettings, // in V2 we have it as second place
    sitemap.branchList,
    sitemap.jobPositionSettings,
    sitemap.holidaySettings,
    sitemap.absenceSettings,
    !isV2 && sitemap.timeClockSettings,
    !isV2 && sitemap.announcementSettings,
    !!rosterSetting.chatModuleEnabled && sitemap.chatSettings,
    !!showBillingSettings && sitemap.billingSettings,
  ]
    .filter((s: any) => !!s)
    .map((l: any) => ({ ...l, title: (isV2 && l.titleV2) || l.title })) as ISitemapEntry[];

  return (
    <div className="settingsPageMain">
      <Page style={{ paddingTop: 0 }}>
        <div className="settingsPageMainWrapper">
          <SideBar links={subBarLinks} />
          <div className="settingsPageMainContent">
            <Switch>
              <Route path={sitemap.rosterSettings.url} component={RosterSettings} />
              <Route path={sitemap.branchList.url} component={BranchList} />
              <Route path={sitemap.jobPositionSettings.url} component={JobPositions} />
              <Route path={sitemap.holidaySettings.url} component={Holidays} />
              <Route path={sitemap.absenceSettings.url} component={AbsenceSettings} />
              <Route path={sitemap.timeClockSettings.url} component={TimeClockSettings} />
              <Route path={sitemap.announcementSettings.url} component={AnnouncementSettings} />
              <Route path={sitemap.chatSettings.url} component={ChatSettings} />
              {/* DONT GROUP THEM IN AN ARRAY! Otherwise it will break the Switch Redirect */}
              {isAdmin && <Route path={sitemap.billingSettings.url} component={BillingPage} />}
              <Redirect
                exact
                from={sitemap.settings.url}
                to={isV2 ? sitemap.timeClockSettings.url : sitemap.rosterSettings.url}
              />
            </Switch>
          </div>
        </div>
      </Page>
    </div>
  );
});
