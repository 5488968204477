import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../../types/AppState";
import { getPosLeftYearly, getPosWidthYearly } from "./localHelpers";
import { Icon } from "antd";
import cn from "classnames";
import "./styles.scss";
import { DispatchBaseProps } from "../../../../../../frontend-core/types/DispatchBaseProps";
import { IAbsence } from "../../../../../../shared/entities/IAbsence";
import { simpleDateToMoment } from "../../../../../../shared/helpers/timeHelpers";
import { AbsenceIcon } from "../../../../../../components/AbsenceIcon/AbsenceIcon";
import moment from "moment";

const mapStateToProps = (state: AppState) => ({
  absenceTypes: state.data.absenceTypes,
});

type OwnProps = {
  absence: IAbsence;
  yearStartDate: string;
  yearEndDate: string;
  isClickable: boolean;
};
type StoreProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StoreProps & DispatchBaseProps;

class AbsenceBarYearView extends React.PureComponent<Props, {}> {
  render() {
    const { absence, yearStartDate, yearEndDate, absenceTypes } = this.props;
    const absenceType = absenceTypes.find((a) => a.id === absence.typeId)!;

    const dayStart = getPosLeftYearly(absence, yearStartDate);
    const daysLength = getPosWidthYearly(absence, yearStartDate, yearEndDate);

    // const getStartEndShort = () => {
    //   const start = simpleDateToMoment(absence.startDate).format("DD MMM");
    //   const end = simpleDateToMoment(absence.endDate).format("DD MMM");
    //   return start + " - " + end;
    // };

    return (
      <div
        className={cn({ absenceYearBarMain: true })}
        style={{
          left: dayStart * (100 / 365) + "%",
          width: (daysLength + 1) * (100 / 365) + "%",
          minWidth: 4,
        }}
        data-type={this.props.isClickable ? "absence-bar" : ""}
        data-absence-id={absence.id}
        data-user={absence.userId}
      >
        <div
          className={cn({
            innerBox: true,
            clickable: this.props.isClickable,
            requested: absence.status === "requested",
          })}
          style={{ backgroundColor: absenceType.color }}
        >
          <div className="content">{/* {width > 90 && getStartEndShort()} */}</div>
        </div>
      </div>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(AbsenceBarYearView);
