import React from "react";
import { Moment } from "moment";
import cn from "classnames";
import "./styles.scss";

type Props = {
  width: number;
  monthHead: string;
};

export const AbsenceMonthCell = (props: Props) => {
  const width = (props.width / 365) * 100 + "%";

  return (
    <div
      className={cn({
        fb: true,
        absenceMonthCellMain: true,
      })}
      style={{ width, flexBasis: width }}
      id={"absence-month-cell-" + props.monthHead}
    >
      {props.monthHead === "JAN" ? null : <div className="monthLine"></div>}
      {props.monthHead}
    </div>
  );
};
