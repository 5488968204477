import { Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { busyInjector, BusyInjectorProps } from "../../components/BusyInjector/BusyInjector";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import { useSelector } from "../../helpers/redux";
import { rosterSettingsRepository } from "../../repositories/rosterSettingsRepository";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";

export const ChatNotEnabledMessage = busyInjector((props: BusyInjectorProps) => {
  const dispatch = useDispatch<DispFn>();
  const sessionInfo = useSelector(selectSessionInfo);
  const rosterSettings = useSelector((s) => s.data.rosterSettings[0]);
  return (
    <div className="fb aCenter jCenter" style={{ width: "100%" }}>
      {sessionInfo.isAdmin() && (
        <div className="fb aCenter jCenter">
          <Button
            children={lg.chat_modul_aktivieren_kostenlos}
            size={"large"}
            type="primary"
            onClick={() => {
              props.load(
                dispatch(
                  rosterSettingsRepository.update({
                    ...rosterSettings,
                    chatModuleEnabled: true,
                    canChatWithUsersFromOtherBranch: true,
                    canChat: true,
                    canGroupChat: true,
                  })
                )
              );
            }}
            loading={props.isLoading()}
          />
        </div>
      )}
      {!sessionInfo.isAdmin() && (
        <div className="fb aCenter jCenter">
          <p style={{ color: "white" }}>{lg.das_chat_modul_wurde_noch_nicht_von_ihrem_administrator_aktiviert}</p>
        </div>
      )}
    </div>
  );
});
