import React from "react";
import { Badge, Button, Checkbox, Switch } from "antd";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { AvStepper } from "../../../AvStepper/AvStepper";
import { closeModal, openModal } from "../../../../actions/modal";
import { ShiftApplicationsPopup } from "../../ShiftApplicationsPopup/ShiftApplicationsPopup";
import "./styles.scss";
import { IShift } from "../../../../shared/entities/IShift";
import {
  assignedShiftsExactMapper,
  assignedShiftsSelector,
  doesShiftMatch,
  getShiftWithDayExtendingTime,
} from "../../../../selectors/assignedShiftsSelector";
import { relevantShiftsSelector } from "../../../../selectors/RelevantShiftsSelector";
import { doShiftsOverlap } from "../../../../selectors/shiftOverlappingsSelector";
import { selectUserFullMap } from "../../../../selectors/userFullMapSelector";
import cn from "classnames";
import { selectAbsencesByUser } from "../../../../selectors/absencesByUserSelector";

const mapStateToProps = (state: AppState) => ({
  assignedUsersMap: assignedShiftsSelector(state),
  relevantShifts: relevantShiftsSelector(state),
  absencesByUser: selectAbsencesByUser(state),
  usersMap: selectUserFullMap(state),
  rosterSettings: state.data.rosterSettings[0],
});

type OwnProps = {
  shift?: IShift;
  requiredUsersAmount: number;
  setRequiredUsersAmount: (number) => void;
  appliedUserIds?: string[];
  assignOpenShiftClicked: () => void;
  canDirectAssign: boolean;
  isDirectAssignDisabled: boolean;
  isLoading: boolean;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _OpenShiftActions extends React.PureComponent<Props> {
  openApplicantsPopup = () => {
    this.props.dispatch(closeModal());
    this.props.dispatch(
      openModal(ShiftApplicationsPopup, {
        shiftId: this.props.shift!.id,
      })
    );
  };

  getOverlappingUserShifs = () => {
    const relevantShiftsExtended = this.props.relevantShifts.map(getShiftWithDayExtendingTime);
    const requirement = getShiftWithDayExtendingTime(this.props.shift!);
    return relevantShiftsExtended.filter(
      (s) => s.userId && doesShiftMatch(requirement, s) && doShiftsOverlap(requirement, s)
    );
  };

  getAssignedUsersTooltip = () => {
    const { relevantShifts, absencesByUser, shift, rosterSettings } = this.props;
    const { requiremetsUseExactMatching } = rosterSettings;
    const shifts = requiremetsUseExactMatching
      ? assignedShiftsExactMapper(relevantShifts, absencesByUser)[shift?.id || ""]
      : this.getOverlappingUserShifs();

    const assignedCount = this.props.assignedUsersMap[this.props.shift!.id] || 0;
    let text = "Durchgehend eingeteilt :   " + assignedCount + " Mitarbeiter";
    text += "\n";
    text += "\n";
    shifts.forEach((shift) => {
      text += this.props.usersMap[shift.userId!].name;
      text += requiremetsUseExactMatching ? "" : " > " + shift.startTime + " - " + shift.endTime;
      text += "\n";
    });
    return text;
  };

  render() {
    const {
      appliedUserIds,
      requiredUsersAmount,
      canDirectAssign,
      isLoading,
      isDirectAssignDisabled,
      setRequiredUsersAmount,
      shift,
    } = this.props;

    const assignedUsersCount = shift ? this.props.assignedUsersMap[shift.id] || 0 : 0;
    const isRequiredUsersComplete = shift?.isRequirement && (shift.requiredUsersAmount || 0) <= assignedUsersCount;

    return (
      <div className="fb row openShiftActionsMain" style={{ marginTop: "20px" }}>
        <div className="requiredAmountLabel">{lg.anzahl}</div>
        <div style={{ marginRight: 12 }}>
          <AvStepper
            value={requiredUsersAmount || 0}
            onChange={(val) => setRequiredUsersAmount(val)}
            min={Math.max(appliedUserIds?.length || 0, 1)}
          />
        </div>
        {shift && !!appliedUserIds?.length && (
          <div style={{ marginRight: 24 }}>
            <Badge count={appliedUserIds?.length}>
              <Button
                id="applied-users"
                disabled={!appliedUserIds?.length}
                style={{ marginBottom: 1 }}
                children={lg.bewerber}
                onClick={this.openApplicantsPopup}
              />
            </Badge>
          </div>
        )}

        {canDirectAssign && (
          <Button
            //type="primary"
            id="assign-users"
            children={lg.zuweisen}
            disabled={isDirectAssignDisabled}
            icon="arrow-right"
            loading={isLoading}
            onClick={this.props.assignOpenShiftClicked}
          />
        )}

        {shift?.isRequirement && (
          <div className={cn({ assignedUsersInfoBox: true, isRequiredUsersComplete })}>
            <div className="count">{assignedUsersCount}</div>
            <div className="text" data-rh={assignedUsersCount ? this.getAssignedUsersTooltip() : null}>
              eingeteilt
            </div>
          </div>
        )}
      </div>
    );
  }
}

export const OpenShiftActions = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(_OpenShiftActions);
