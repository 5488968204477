import React, { PureComponent } from "react";
import "./styles.scss";
import { AppState } from "../../types/AppState";
import { IAbsenceType, AbsenceTypeCode } from "../../shared/entities/IAbsenceType";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import { BusyInjectorProps, busyInjector } from "../BusyInjector/BusyInjector";
import TZModal from "../TZModal/TZModal";
import { connect } from "react-redux";
import { ITimeClockSettings, IUserTimeClockSettings } from "../../shared/entities/ITimeClockSettings";
import { Checkbox, Select, Button } from "antd";
import { BasicSelect } from "../BasicSelect/BasicSelect";
import moment from "moment";
import { toSimpleDate } from "../../shared/helpers/timeHelpers";
import { selectActiveUsers } from "../../selectors/ActiveUserSelectors";
import _ from "lodash";
import { isRuleApplyingToUser } from "../../shared/helpers/settingsHelpers";
import { IRuleSpecification } from "../../shared/entities/IRuleSpecification";
import { decryptUser } from "../../shared/helpers/userHelpers";
import { IUser, RoleType } from "../../shared/entities/IUser";
import { roleOptions } from "../../pages/Users/UserDetailsModal/UserGeneralTab/UserGeneralTab";
// import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
// import { selectUsersWithSharedBranch } from "../../selectors/UsersWithSharedBranchSelector";
// import { selectVisibleBranches } from "../../selectors/VisibleBranchesSelector";

// const mapStateToProps = (state: AppState) => {
//   const sessionInfo = selectSessionInfo(state);
//   const usersWithShardBranch = selectUsersWithSharedBranch(state);
//   return {
//     timeClockSettings: state.data.timeClockSettings[0],
//     activeUsers: selectActiveUsers(state, toSimpleDate(moment())).filter(
//       (user) => sessionInfo.isAdmin() || usersWithShardBranch.includes(user.id)
//     ),
//     jobPositions: state.data.jobPositions,
//     branches: selectVisibleBranches(state),
//   };
// };

const mapStateToProps = (state: AppState) => ({
  timeClockSettings: state.data.timeClockSettings[0],
  activeUsers: selectActiveUsers(state, toSimpleDate(moment())),
  jobPositions: state.data.jobPositions,
  branches: state.data.branches,
});

type OwnProps = {
  ruleSpecification: IRuleSpecification;
  onChange: (rule: IRuleSpecification, includedUsers: IUser[]) => void;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _RuleSpecificationForm extends PureComponent<Props> {
  isNoneSelected = () => {
    const { jobPositionIds, userIds, exceptUserIds, branchIds, roleTypes } = this.props.ruleSpecification;
    return !userIds && !exceptUserIds && !jobPositionIds && !branchIds && !roleTypes;
  };

  update = (rule: Partial<IRuleSpecification>) => {
    const { activeUsers, ruleSpecification } = this.props;
    const newSpecification = {
      ...this.props.ruleSpecification,
      ...rule,
    };

    const includingUsers = activeUsers.filter((u) => isRuleApplyingToUser(newSpecification, u));
    this.props.onChange(newSpecification, includingUsers);
  };

  render() {
    const activeUserIds = this.props.activeUsers.map((u) => u.id);
    const activeBranches = this.props.branches.filter((b) => !b.isInactive);
    const activeBrnachIds = activeBranches.map((b) => b.id);
    const activeJobPositions = this.props.jobPositions.filter((b) => !b.isInactive);
    const activeJobPositionIds = activeJobPositions.map((b) => b.id);

    const { userIds, branchIds, jobPositionIds, exceptUserIds, roleTypes } = this.props.ruleSpecification;

    const selectedUserIds = _.intersection(activeUserIds, userIds || []);
    const selectedExceptUserIds = _.intersection(activeUserIds, exceptUserIds || []);
    const selectedBranchIds = _.intersection(activeBrnachIds, branchIds || []);
    const selectedJobPositionIds = _.intersection(activeJobPositionIds, jobPositionIds || []);
    const selectedRoleTypes = roleTypes;

    const userOptions = this.props.activeUsers.map(decryptUser).map((u) => ({
      value: u.id,
      label: u.name,
    }));

    const branchOptions = activeBranches.map((u) => ({
      value: u.id,
      label: u.name,
    }));

    const jobPositionOptions = activeJobPositions.map((u) => ({
      value: u.id,
      label: u.name,
    }));

    const includingUsers = this.props.activeUsers.filter((u) => isRuleApplyingToUser(this.props.ruleSpecification, u));

    return (
      <div className="ruleSpecificationFormMain">
        <div className="ruleSpecificationModalMain">
          <div className="checkboxRow">
            <div className="checkboxWrapper">
              <Checkbox
                children={`${lg.alle_mitarbeiter}:`}
                checked={this.isNoneSelected()}
                onClick={() => {
                  if (!this.isNoneSelected()) {
                    this.update({
                      userIds: undefined,
                      exceptUserIds: undefined,
                      jobPositionIds: undefined,
                      branchIds: undefined,
                      roleTypes: undefined,
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className="checkboxRow">
            <div className="checkboxWrapper">
              <Checkbox
                children={`${lg.nur_selektierte_mitarbeiter}:`}
                checked={!!userIds}
                onClick={() => {
                  const isChecked = !!userIds;
                  this.update({
                    userIds: isChecked ? undefined : [],
                    exceptUserIds: undefined,
                    jobPositionIds: undefined,
                    branchIds: undefined,
                    roleTypes: undefined,
                  });
                }}
              />
            </div>
            {!!userIds && (
              <div className="selectWrapper">
                <BasicSelect
                  placeholder={lg.mitarbeiter_wählen}
                  options={userOptions}
                  value={selectedUserIds}
                  mode="multiple"
                  onChange={(userIds: string[]) => this.update({ userIds })}
                />
              </div>
            )}
          </div>
          <div className="checkboxRow">
            <div className="checkboxWrapper">
              <Checkbox
                children={`${lg.ausgenommen_selektierter_mitarbeiter}:`}
                checked={!!exceptUserIds}
                onClick={() => {
                  const isChecked = !!exceptUserIds;
                  this.update({
                    userIds: undefined,
                    exceptUserIds: isChecked ? undefined : [],
                  });
                }}
              />
            </div>
            {!!exceptUserIds && (
              <div className="selectWrapper">
                <BasicSelect
                  placeholder={lg.mitarbeiter_wählen}
                  options={userOptions}
                  value={selectedExceptUserIds}
                  mode="multiple"
                  onChange={(exceptUserIds: string[]) => this.update({ exceptUserIds })}
                />
              </div>
            )}
          </div>
          <div className="checkboxRow">
            <div className="checkboxWrapper">
              <Checkbox
                children={`${lg.nur_mitarbeiter_der_rolle}:`}
                checked={!!jobPositionIds}
                onClick={() => {
                  const isChecked = !!jobPositionIds;
                  this.update({
                    jobPositionIds: isChecked ? undefined : [],
                    userIds: undefined,
                  });
                }}
              />
            </div>
            {!!jobPositionIds && (
              <div className="selectWrapper">
                <BasicSelect
                  placeholder={lg.rolle_wähen}
                  options={jobPositionOptions}
                  value={selectedJobPositionIds}
                  mode="multiple"
                  onChange={(jobPositionIds) => this.update({ jobPositionIds })}
                />
              </div>
            )}
          </div>
          {/*  */}
          <div className="checkboxRow">
            <div className="checkboxWrapper">
              <Checkbox
                children={`${lg.nur_mitarbeiter_der_standorte}:`}
                checked={!!branchIds}
                onClick={() => {
                  const isChecked = !!branchIds;
                  this.update({
                    branchIds: isChecked ? undefined : [],
                    userIds: undefined,
                  });
                }}
              />
            </div>
            {!!branchIds && (
              <div className="selectWrapper">
                <BasicSelect
                  placeholder={lg.standort_wählen}
                  options={branchOptions}
                  value={selectedBranchIds}
                  mode="multiple"
                  onChange={(branchIds) => this.update({ branchIds })}
                />
              </div>
            )}
          </div>
          <div className="checkboxRow">
            <div className="checkboxWrapper">
              <Checkbox
                children={`${lg.nur_mitarbeiter_der_berechtigung}:`}
                checked={!!roleTypes}
                onClick={() => {
                  const isChecked = !!roleTypes;
                  this.update({
                    roleTypes: isChecked ? undefined : [],
                    userIds: undefined,
                  });
                }}
              />
            </div>
            {!!roleTypes && (
              <div className="selectWrapper">
                <BasicSelect
                  placeholder={lg.berechtigung_wählen}
                  options={roleOptions}
                  value={selectedRoleTypes}
                  mode="multiple"
                  onChange={(roleTypes: RoleType[]) => this.update({ roleTypes })}
                />
              </div>
            )}
          </div>
          <div className="usersInfo">
            <div className="userCount">{`${includingUsers.length} / ${activeUserIds.length}`}</div>
            <div className="label">{lg.mitarbeiter_plural}</div>
          </div>
        </div>
      </div>
    );
  }
}

export const RuleSpecificationForm = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  _RuleSpecificationForm
);
