import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../../types/AppState";
import { notification } from "antd";

const mapStateToProps = (state: AppState) => ({});

type OwnProps = {};

type State = {};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & { dispatch: ThunkDispatch<any, any, any> };

class _ExceptionsVisualizer extends React.PureComponent<Props, State> {
  visualize = (exception: any) => {
    notification.error({
      message: (
        <>
          <span>{lg.ein_fehler_ist_aufgetreten}</span>
          <span
            style={{
              whiteSpace: "pre-wrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: 276,
              display: "block",
              maxHeight: 120,
            }}
          >
            {exception.message}
          </span>
        </>
      ),
    });
  };

  render() {
    return <></>;
  }
}

export const ExceptionsVisualizer = connect(mapStateToProps)(_ExceptionsVisualizer);
