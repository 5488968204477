import React, { useEffect, useState } from "react";
import TZModal from "../../../../components/TZModal/TZModal";
import { useSelector } from "../../../../helpers/redux";
import { selectActiveBranches } from "../../../../selectors/ActiveBranchesSelector";
import {
  selectAnnouncementMap,
  selectBranchMap,
  selectJobPositionMap,
  selectUserMap,
} from "../../../../selectors/mapSelectors";
import { selectActiveJobPositions } from "../../../../selectors/ActiveJobPositionsSelector";
import _ from "lodash";

import { useLoading } from "../../../../helpers/useLoading";
import { useDispatch } from "react-redux";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import BusyWrapper from "../../../../components/BusyWrapper/BusyWrapper";
import moment from "moment";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { getUserName } from "../../../../shared/helpers/userHelpers";
import { IUpload, IUploadBase } from "../../../../shared/entities/IUpload";
import { uploadRepository } from "../../../../repositories/uploadRepository";
import "./styles.scss";
import { Button, Icon, Modal } from "antd";
import { announcementRepository } from "../../../../repositories/announcementRepository";
import { closeModal, openModal } from "../../../../actions/modal";
import { AnnouncementAddEditModal } from "../AnnouncementAddEditModal/AnnouncementAddEditModal";
import { downloadFile } from "../../../../frontend-core/helpers/frontendHelpers";
import { isUploaded } from "../../../../components/modals/AbsenceModal/localHelpers";
import cn from "classnames";
import { IAnnouncement } from "../../../../shared/entities/IAnnouncement";

type Props = {
  id: string;
};

export const AnnouncementViewerModal = (props: Props) => {
  const [isLoading, load] = useLoading();
  const visibleBranches = useSelector(selectActiveBranches);
  const activeJobPositions = useSelector(selectActiveJobPositions);
  const sessionInfo = useSelector(selectSessionInfo);
  const branchMap = useSelector(selectBranchMap);
  const jobPositionMap = useSelector(selectJobPositionMap);
  const dispatch = useDispatch<DispFn>();
  // const announcementMap = useSelector(selectAnnouncementMap);
  const userMap = useSelector(selectUserMap);
  const allUploads = useSelector((s) => s.data.uploads);

  const [announcement, setAnnouncement] = useState<IAnnouncement>();

  useEffect(() => {
    (async () => {
      const ann = (await load(dispatch(announcementRepository.fetchOne(props.id))))!;
      console.log("ann", ann); // dont remove these logs > useful to debugg in production
      setAnnouncement(ann);
      dispatch(uploadRepository.fetchMany({ filter: ["relatedEntityId", "=", ann.id] }));
    })();
  }, []);

  if (!announcement) {
    return null;
  }

  const userIds = _.orderBy(_.keys(announcement.readByUserId), [
    (id) => announcement.readByUserId?.[id],
    (id) => getUserName(userMap[id]),
  ]);

  const readByValues = _.values(announcement.readByUserId);
  const hasRead = !!announcement.readByUserId?.[sessionInfo.user.id];
  const isCreator = sessionInfo.user.id === announcement.by;
  const canEdit = isCreator || sessionInfo.isAdmin();
  const isReadByAll = !readByValues.some((r) => !r);
  const isHighlighted = isCreator ? !isReadByAll : !hasRead;

  const allBranchesSelected = announcement.branchIds?.length === visibleBranches.length;
  const allJobPositionsSelected = announcement.jobPositionIds?.length === activeJobPositions.length;
  const isAssigned = announcement.readByUserId?.hasOwnProperty(sessionInfo.user.id);

  const uploads = allUploads.filter((u) => u.relatedEntityId === announcement.id);

  const renderFileName = (upload: IUpload | IUploadBase) =>
    isUploaded(upload) ? (
      <a
        href={(upload as IUpload).url}
        target="_blank"
        rel="noreferrer"
        onClick={(event) => event.stopPropagation()}
        children={upload.displayName}
      />
    ) : (
      <div className="fileName">{upload.displayName}</div>
    );

  return (
    <TZModal className="announcementViewerModal">
      {/* <TZModal.Head>{`Ansage`}</TZModal.Head> */}
      <TZModal.Body style={{ minWidth: 560 }}>
        <BusyWrapper isBusy={isLoading()} style={{ padding: 20, paddingTop: 14 }}>
          {!!announcement.title?.length && <div className="titleText">{announcement.title}</div>}
          {!!announcement.message && <div className="messageText">{announcement.message}</div>}
          <div className="fb row infoRow">
            <div className="createdAt">{moment(announcement.at).format("DD. MMM hh:mm")}</div>
            <div className="by">{lg.von_start}</div>
            <div className="createdBy">{getUserName(userMap[announcement.by])}</div>
          </div>

          <div className="confirmButtonRow">
            {isAssigned && (
              <Button
                style={{
                  marginTop: 14,
                  backgroundColor: isHighlighted ? "auto" : "#d3ffbdcf",
                  color: isHighlighted ? "auto" : "#3bbd00",
                  border: isHighlighted ? "auto" : "none",
                }}
                type={isHighlighted ? "primary" : "default"}
                icon={hasRead ? "check" : undefined}
                // iconProps={{ name: "check", color: !isActive ? "#18c756" : "#ffffff", size: 20 }}
                children={!!hasRead ? lg.gelesen : lg.als_gelesen_markieren}
                onClick={async () => {
                  if (!hasRead) {
                    await load(dispatch(announcementRepository.toggleHasRead(announcement)));
                    dispatch(closeModal());
                  } else {
                    Modal.confirm({
                      title: lg.ansage_als_ungelesen_markieren,
                      onOk: async () => {
                        await load(dispatch(announcementRepository.toggleHasRead(announcement)));
                        dispatch(closeModal());
                      },
                      okText: lg.ja,
                      cancelText: lg.nein,
                    });
                  }
                }}
              />
            )}
          </div>

          {canEdit && (
            <>
              <div className="selectionWrapper">
                {allBranchesSelected && (
                  <div className="selectionBox" style={{ backgroundColor: "gray" }}>
                    <Icon type="environment" style={{ paddingRight: "6px", color: "#ffffff" }} />
                    {lg.alle_standorte}
                  </div>
                )}
                {!allBranchesSelected &&
                  announcement.branchIds?.map((bId) => (
                    <div
                      className="selectionBox"
                      data-rh={branchMap[bId].name}
                      data-rh-at="bottom"
                      style={{ backgroundColor: branchMap[bId].color }}
                    >
                      <Icon type="environment" style={{ paddingRight: "6px", color: "#ffffff" }} />
                      {branchMap[bId].name}
                    </div>
                  ))}
                {allJobPositionsSelected && (
                  <div className="selectionBox" style={{ backgroundColor: "gray" }}>
                    {lg.alle_rollen}
                  </div>
                )}
                <div className="wrapBreak"></div>
                {!allJobPositionsSelected &&
                  announcement.jobPositionIds?.map((jpId) => (
                    <div
                      className="selectionBox"
                      data-rh={jobPositionMap[jpId].name}
                      data-rh-at="bottom"
                      style={{ backgroundColor: jobPositionMap[jpId].color }}
                    >
                      {jobPositionMap[jpId].name}
                    </div>
                  ))}
              </div>
              <div className="readByUsersList">
                {userIds.map((uId) => {
                  const user = userMap[uId];
                  const userRead = announcement.readByUserId?.[uId];
                  return (
                    <div key={uId} className={cn({ userTag: true, hasRead: userRead })}>
                      <div className="name">{getUserName(user)}</div>
                      <Icon
                        data-rh={userRead ? lg.gelesen : lg.noch_ungelesen}
                        type={userRead ? "check" : "hourglass"}
                        style={{ color: userRead ? "#18c756" : "#b1b1b1" }}
                      />
                      {/* {userRead && <span className="userReadDateBox">gelesen</span>} */}
                    </div>
                  );
                })}
              </div>
            </>
          )}
          <div className="fb row">
            {!!uploads.length && (
              <div className="announcementUploadsSectionMain">
                <div className="uploadSectionTitle">{lg.anhänge}</div>
                {uploads.map((upload) => {
                  return (
                    <div className="uploadEntry" key={upload.id}>
                      <div className="attachment">
                        <Icon type="file-text" />
                      </div>
                      <div className="nameWrapper">{renderFileName(upload)}</div>
                      {!!(upload as any).url && (
                        <div
                          className="download actionBtn"
                          data-rh={lg.download}
                          onClick={() => downloadFile(upload as IUpload)}
                        >
                          <Icon type="download" />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </BusyWrapper>
      </TZModal.Body>
      {canEdit && (
        <TZModal.Footer>
          <div className={cn({ count: true, isReadByAll })}>
            {readByValues.filter((r) => !!r).length} / {readByValues.length} {lg.gelesen}{" "}
          </div>
          {canEdit && (
            <Button
              type="default"
              icon={"edit"}
              // iconProps={{ name: "check", color: !isActive ? "#18c756" : "#ffffff", size: 20 }}
              children={lg.bearbeiten}
              onClick={async () => {
                dispatch(closeModal());
                setTimeout(() => {
                  dispatch(openModal(AnnouncementAddEditModal, { id: announcement.id }));
                }, 200);
              }}
            />
          )}
        </TZModal.Footer>
      )}
    </TZModal>
  );
};
