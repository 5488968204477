import { IUserFull } from "./../shared/entities/IUser";
import { Dictionary } from "lodash";
import { decryptUser } from "./../shared/helpers/userHelpers";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import { RoleType } from "../shared/entities/IUser";
import * as Sentry from "@sentry/browser";

export const selectUserFullMap = createSelector(
  [(state: AppState) => state.data.users, (state: AppState) => state.data.auth.session],
  (users, session): Dictionary<IUserFull> => {
    if (!session) {
      return {};
    }

    // if (session.role === RoleType.employee) {
    //   return {};
    // }

    const map = {};
    users.forEach((u) => (map[u.id] = decryptUser(u)));
    return map;
  }
);
