import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { InputNumber, Button, Icon, Input, Modal, Checkbox, Select, notification } from "antd";
import TZModal from "../../TZModal/TZModal";

import { closeModal } from "../../../actions/modal";
import "./styles.scss";
import { busyInjector, BusyInjectorProps } from "../../BusyInjector/BusyInjector";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { IShift } from "../../../shared/entities/IShift";
import { IShiftHandOverRequest, HandoverType } from "../../../shared/entities/IShiftHandOverRequest";
import { selectActiveUsers } from "../../../selectors/ActiveUserSelectors";
import { SDateFormat } from "../../../shared/helpers/SimpleTime";
import moment from "moment";
import { getUserName } from "../../../shared/helpers/userHelpers";
import { toMoment } from "../../../shared/helpers/timeHelpers";
import { string } from "yup";
import { DispFn } from "../../../frontend-core/types/thunkTypes";
import { shiftHandOverRequestRepository } from "../../../repositories/shiftHandOverRequestRepository";
import { BasicSelect } from "../../BasicSelect/BasicSelect";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";

const mapStateToProps = (state: AppState) => ({
  users: selectActiveUsers(state, moment().format(SDateFormat)),
  jobPositions: state.data.jobPositions,
  sessionInfo: selectSessionInfo(state),
});

type State = {
  toUserId?: string;
  handOverType?: HandoverType;
};

type OwnProps = {
  shift: IShift;
  // onComplete: (IShiftHandOverRequest) => void;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & BusyInjectorProps & DispatchBaseProps;

class _HandOverRequestModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      toUserId: undefined,
      handOverType: undefined,
    };
  }

  save = async () => {
    const { shift } = this.props;
    const { toUserId, handOverType } = this.state;
    await this.props.load(
      this.props.dispatch(
        shiftHandOverRequestRepository.create({
          id: shift.id,
          toUserId: toUserId,
          branchId: shift.branchId,
          fromUserId: shift.userId!,
          date: shift.date,
          jobPositionId: shift.jobPositionId,
          createdAt: moment().toISOString(),
          type: handOverType!,
        })
      )
    );
    notification.success({ message: lg.anfrage_wurde_erstellt });
    this.props.dispatch(closeModal());
  };

  userSelected = (toUserId: string) => {
    this.setState({ toUserId });
  };

  typeSelected = (handOverType: HandoverType) => {
    this.setState({ handOverType });
  };

  render() {
    const { users, shift, jobPositions, isLoading, sessionInfo } = this.props;
    const { handOverType, toUserId } = this.state;
    const jobPositionName = jobPositions.find((jp) => jp.id === shift.jobPositionId)!.name;

    const selectableUsers = users.filter(
      (u) =>
        u.branchIds.includes(shift.branchId) &&
        u.jobPositionIds.includes(shift.jobPositionId) &&
        u.id !== sessionInfo.user.id
    );

    const userOptions = selectableUsers.map((u) => ({ label: getUserName(u), value: u.id }));

    return (
      <TZModal>
        {/* <TZModal.Head ></TZModal.Head> */}
        <TZModal.Body>
          <div className="handOverRequestModalMain">
            <div className="col">
              <div className="mainText">{lg.wem_soll_deine_schicht_angeboten_werden}</div>
              <div className="shiftDate">
                {lg.schicht_am} {toMoment(shift.date).format("DD. MMMM")}
              </div>
              <div className="checkboxRow">
                <Checkbox
                  checked={handOverType === HandoverType.group}
                  onChange={(e) => this.typeSelected(HandoverType.group)}
                >
                  <span>
                    {lg.allen_mitarbeitern_der_rolle} <b>{jobPositionName}</b>
                  </span>
                </Checkbox>
              </div>
              <div className="checkboxRow">
                <Checkbox
                  checked={handOverType === HandoverType.direct}
                  onChange={(e) => this.typeSelected(HandoverType.direct)}
                  children={lg.einem_bestimmten_mitarbeiter}
                />
              </div>
              {handOverType === HandoverType.direct && (
                <div className="userSelectWrapper">
                  <BasicSelect
                    id="user-to-handover-select"
                    placeholder={lg.mitarbeiter_wählen}
                    value={toUserId}
                    onChange={(toUserId: string) => this.setState({ toUserId })}
                    style={{ width: "100%" }}
                    showSearch
                    options={userOptions.map((option) => ({ value: option.value, label: option.label }))}
                  />
                </div>
              )}
            </div>
          </div>
        </TZModal.Body>
        <TZModal.Footer>
          <Button
            id="cancel-button-hand-over-request-popup"
            style={{ width: 202 }}
            type="primary"
            onClick={this.save}
            icon={isLoading() ? "loading" : undefined}
            children={isLoading() ? null : lg.schichtabgabe_beantragen}
            disabled={!handOverType || (handOverType === "direct" && !toUserId)}
          />
        </TZModal.Footer>
      </TZModal>
    );
  }
}

export const HandOverRequestModal = connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(
  busyInjector(_HandOverRequestModal)
);
