import { selectSessionInfo } from "./SessionInfoSelector";
import { decryptUser } from "./../shared/helpers/userHelpers";
import { selectUsersFull } from "./usersFullSelector";
import { UserInfo } from "./../shared/helpers/UserInfo";
import { selectRosterStartDate } from "./rosterStartDateSelector";
import { IUser, IUserFull } from "./../shared/entities/IUser";
import { relevantShiftsOfBranchSelector } from "./RelevantShiftsSelector";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import { selectContractsByUser } from "./contractsByUserSelector";
import { orderUsers } from "../helpers/general";
import { IUserDetail } from "../shared/entities/IUserDetail";
import { selectUserDetailMap } from "./mapSelectors";
import { haveCommonJobPos } from "../frontend-core/helpers/frontendHelpers";
import { usersHaveIntersectingBranch } from "./UsersWithSharedBranchSelector";

export const selectVisibleUsersOfRoster = createSelector(
  [
    (state: AppState) => state.data.users,
    selectRosterStartDate,
    (state: AppState) => state.ui.shifts.roster.userNameFilter,
    (state: AppState) => state.ui.shifts.roster.emptyShiftRowsHidden,
    relevantShiftsOfBranchSelector,
    (state: AppState) => state.ui.selectedBranch,
    selectSessionInfo,
    (state: AppState) => state.data.rosterSettings[0],
    selectUserDetailMap,
    (state: AppState) => state.data.tenantInfo.isV2,
  ],
  (
    users,
    startDate,
    userNameFilter,
    emptyShiftRowsHidden,
    shifts,
    selectedBranchId,
    sessionInfo,
    rosterSettings,
    userDetailMap,
    isV2
  ): IUser[] => {
    const canManage = sessionInfo.hasManagerPermissions();
    const isAdmin = sessionInfo.isAdmin();
    const canSeeOtherUsers = canManage || (!rosterSettings.employeesCanNotSeeShiftsOfCoWorkers && !isV2);
    const canSeeOtherRoles = canManage || !rosterSettings.usersOfOtherRolesAreHidden;
    const visibleUsers = users
      .map((user) => decryptUser(user))
      .filter(
        (user) =>
          //contractsByUser[user.id]  little hack for edge-cases when contract gets updated
          !user.isHiddenInRoster &&
          (canSeeOtherUsers || user.id === sessionInfo.user.id) &&
          (canSeeOtherRoles || haveCommonJobPos(user, sessionInfo.user)) &&
          (isAdmin || usersHaveIntersectingBranch(sessionInfo.user, user)) && // this is relevant for the all-branches view for managers
          !new UserInfo(user).isDeactivated(startDate) &&
          !isUserFilteredOut(user, userNameFilter, userDetailMap[user.id]) &&
          (!emptyShiftRowsHidden || hasUserShiftsInBranch(user, shifts)) &&
          (!selectedBranchId || user.branchIds.includes(selectedBranchId) || hasUserShiftsInBranch(user, shifts))
      );
    return orderUsers(visibleUsers, selectedBranchId, rosterSettings.sortRosterEmployeesByName);
  }
);

export const selectVisibleUsersOfRosterByJobPosition = createSelector(
  [
    (state: AppState) => state.data.jobPositions,
    selectVisibleUsersOfRoster,
    relevantShiftsOfBranchSelector,
    (state: AppState) => state.ui.shifts.roster.emptyShiftRowsHidden,
  ],
  (jobPositions, visibleUsers, visibleShifts, emptyShiftRowsHidden): { [jobPosId: string]: IUser[] } => {
    const usersByJobPos = {} as { [jobPosId: string]: IUser[] };

    jobPositions.forEach((jp) => {
      usersByJobPos[jp.id] = [];
      visibleUsers.filter((u) => {
        const hasTheJobPosition = u.jobPositionIds.includes(jp.id);
        const hasShiftWithJobPosition = visibleShifts.find((s) => s.userId === u.id && s.jobPositionId === jp.id);

        if ((hasTheJobPosition && !jp.isInactive && !emptyShiftRowsHidden) || hasShiftWithJobPosition) {
          usersByJobPos[jp.id] = [...usersByJobPos[jp.id], u];
        }
      });
    });
    return usersByJobPos;
  }
);

const hasUserShiftsInBranch = (user: IUser, shifts) => {
  return !!shifts.find((s) => s.userId === user.id);
};

export const isUserFilteredOut = (user: IUserFull, searchString = "", userDetail?: IUserDetail) => {
  const userNameExtended = user.name.toLowerCase() + "_" + (userDetail?.employNum || "").toLowerCase();
  return searchString.trim() && !userNameExtended.includes(searchString.trim().toLowerCase());
};
