import { reportDataTypesReducer } from "./reportDataTypes";
import { reportColumnsReducer } from "./reportColumns";
import { combineReducers } from "redux";
import { reportUserSpecification } from "./reportUserSpecification";
import { reportExcludedWeekDaysReducer } from "./reportExcludedWeekDays";
import { getSimpleReducer } from "../../helpers/reducerHelpers";
import { reportTimeFormatReducer } from "./reportTimeFormat";
import { reportMasterDataColumnsReducer } from "./reportMasterDataColumns";

export const reportSettingsReducer = combineReducers({
  reportDataTypes: reportDataTypesReducer,
  reportColumns: reportColumnsReducer,
  reportMasterDataColumns: reportMasterDataColumnsReducer,
  reportExcludedWeekDays: reportExcludedWeekDaysReducer,
  reportUserSpecification: reportUserSpecification,
  reportTimeFormat: reportTimeFormatReducer,
});
