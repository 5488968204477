import firebase from "firebase/compat";
import "@firebase/storage-compat";
import { IUploadBase, IUpload } from "../shared/entities/IUpload";
import { DispFn } from "../frontend-core/types/thunkTypes";
import { AppState } from "../types/AppState";
import moment from "moment";
import _ from "lodash";
import * as Sentry from "@sentry/browser";
import { notification } from "antd";
import { uploadRepository } from "../repositories/uploadRepository";

export type NewUpload = IUploadBase & { file: File };

const handleError = (error: any, uploadBase: IUploadBase) => {
  console.log(error);
  notification.error({ message: error.message });
  Sentry.setTag("Function", "doUpload");
  Sentry.addBreadcrumb({ data: uploadBase });
  Sentry.captureException(error);
};

const uploadToStorage =
  (file: File, uploadBase: IUploadBase) =>
  async (disp: DispFn, getState: () => AppState): Promise<IUpload> => {
    const session = getState().data.auth.session!;
    const tenantId = session.tenantId;
    const creatorId = session.userId!;
    const { userId, id, name, relatedRepoKey } = uploadBase;
    const customMetadata = { creatorId, userId: userId || "", repoKey: relatedRepoKey };
    const fileRef = firebase.storage().ref().child(tenantId).child("uploads").child(id).child(name);
    const fileSnap = await fileRef.put(file, { customMetadata });
    return {
      ...uploadBase,
      creatorId,
      timestamp: moment().toISOString(),
      contentType: fileSnap.metadata.contentType || undefined,
      url: await fileRef.getDownloadURL(),
      size: fileSnap.metadata.size,
    };
  };

const removeFromStorage =
  (upload: IUploadBase) =>
  async (disp: DispFn, getState: () => AppState): Promise<any> => {
    const tenantId = getState().data.auth.session!.tenantId;
    const fileRef = firebase.storage().ref().child(tenantId).child("uploads").child(upload.id).child(upload.name);
    return fileRef.delete();
  };

export const uploadFile =
  (file: File, uploadBase: IUploadBase) =>
  async (dispatch: DispFn): Promise<IUpload> => {
    try {
      const upload = await dispatch(uploadToStorage(file, uploadBase));
      await dispatch(uploadRepository.create(upload));
      return upload;
    } catch (e) {
      handleError(e, uploadBase);
      throw e;
    }
  };

export const uploadFileList =
  (uploadJobs: { file: File; uploadBase: IUploadBase }[]) =>
  async (dispatch: DispFn): Promise<IUpload[]> => {
    const uploads: IUpload[] = [];
    for (let job of uploadJobs) {
      const upload = await dispatch(uploadFile(job.file, job.uploadBase));
      uploads.push(upload);
    }
    return uploads;
  };

export const removeUpload =
  (upload: IUpload) =>
  async (dispatch: DispFn, getState: () => AppState): Promise<any> => {
    try {
      await dispatch(uploadRepository.remove(upload.id));
      await dispatch(removeFromStorage(upload));
    } catch (e) {
      handleError(e, upload);
      throw e;
    }
  };

export const removeUploadList =
  (uploads: IUpload[]) =>
  async (dispatch: DispFn, getState: () => AppState): Promise<any> => {
    for (let upload of uploads || []) {
      await dispatch(removeUpload(upload));
    }
  };

export const editUpload =
  (upload: IUpload) =>
  async (dispatch: DispFn, getState: () => AppState): Promise<IUpload> => {
    return dispatch(uploadRepository.update(upload));
  };
