import React from "react";
import { connect } from "react-redux";
import { DispatchBaseProps } from "../frontend-core/types/DispatchBaseProps";
import { push } from "connected-react-router";
import { AppState } from "../types/AppState";
import { IUser, RoleType } from "../shared/entities/IUser";
import { selectSessionInfo } from "../selectors/SessionInfoSelector";

const mapStateToProps = (state: AppState) => ({
  auth: state.data.auth,
  sessionUser: selectSessionInfo(state).user,
});

type State = {};
type OwnProps = {
  /**
   * the required role or asterisk (*) for simply requiring a user
   * being logged in
   */
  hasRequiredRights?: (user: IUser) => boolean;
  /**
   * url to redirect to in case user is not authorized;
   * Note: Always use a place that can be accessed by any user without
   * extra permissions, e.g. dashboard
   *
   * If a function is provided instead of an url, it will be executed.
   */
  onUnauthorized?: string | Function;
  /**
   * url to redirect to in case user is not signed in at all.
   * Typically you would want to redirect to the login page.
   *
   * If a function is provided, it will be executed.
   */
  onUnauthenticated?: string | Function;
  children: React.ReactNode;
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

/**
 * Render-props based component that guards against unauthorized access.
 */
class AccessControl extends React.PureComponent<Props, State> {
  isAuthenticated() {
    return this.props.auth?.session;
  }

  isAuthorized() {
    const { auth, hasRequiredRights, sessionUser } = this.props;
    if (!this.isAuthenticated()) {
      return false;
    }
    if (!auth.session?.userId || !sessionUser) {
      return false;
    }

    return !hasRequiredRights || hasRequiredRights(sessionUser);
  }

  redirectOnAccessDenied() {
    const { onUnauthorized, onUnauthenticated, dispatch } = this.props;
    if (!this.isAuthenticated() && onUnauthenticated) {
      typeof onUnauthenticated === "string" ? dispatch(push(onUnauthenticated)) : onUnauthenticated();
    }
    if (!this.isAuthorized() && onUnauthorized) {
      typeof onUnauthorized === "string" ? dispatch(push(onUnauthorized)) : onUnauthorized();
    }
  }

  componentDidUpdate() {
    this.redirectOnAccessDenied();
  }

  componentDidMount() {
    this.redirectOnAccessDenied();
  }

  render() {
    return this.isAuthorized() ? <>{this.props.children}</> : null;
  }
}

export default connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(AccessControl);
