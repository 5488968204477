import React from "react";
import "./styles.scss";
import { CreditEntryBar } from "../CreditEntryBar/CreditEntryBar";
import { IHoliday } from "../../../../../shared/entities/IHoliday";

type Props = {
  quota: number;
  holiday: IHoliday;
};

export class CreditHolidayEntry extends React.PureComponent<Props> {
  render() {
    return <CreditEntryBar minutes={this.props.quota} tagTooltip={this.props.holiday.name} tag={lg.feiertag} />;
  }
}
