import { timeClockModeActionTypes } from "../../../actions/timeClockMode";

type ITimeClockModeState = {
  branchIds: string[] | null;
  activeSince: string | null; // IsoString
};

export const timeClockModeReducer = (
  state: ITimeClockModeState = { branchIds: null, activeSince: null },
  action: any
): ITimeClockModeState => {
  switch (action.type) {
    case timeClockModeActionTypes.START:
      return action.payload;
    default:
      return state;
  }
};
