import { getDuration } from "./timeHelpers";
import { ITracking } from "./../entities/ITracking";
import { IShift } from "./../entities/IShift";
import { CrudOperation, Operation } from "./firebaseHelpers";
import { ITimeClocking } from "../entities/ITimeClocking";

export const getShiftMiniId = (shiftId: string) => {
  const miniId = shiftId.substr(0, 3);
  // firebase workaround: a key with 001 gets turned into an array
  // when fetching obj from realtime-database (just in android)
  return miniId === "001" ? "00z" : miniId;
};

const getBasePath = (userId: string, date: string, shiftId: string) => {
  return `credits/${userId}_${date}/${getShiftMiniId(shiftId)}`;
};

const getShiftPath = (s: IShift) => {
  return `${getBasePath(s.userId!, s.date, s.id)}/S`;
};

const getSurchargePath = (s: IShift, isPercent: boolean) => {
  return `${getBasePath(s.userId!, s.date, s.id)}/${isPercent ? "AP" : "AA"}`;
};

const getTrackingPath = (t: ITracking) => {
  return `${getBasePath(t.userId, t.date, t.id)}/T`;
};

const getClockingPathV2 = (t: ITimeClocking) => {
  return `${getBasePath(t.userId, t.date, t.id)}/C`;
};

const getClockingDuration = (clocking: ITimeClocking) => {
  return getDuration({ ...clocking, endTime: clocking.endTime || clocking.startTime });
};

export const getShiftMiniUpdates = (
  crudOperations: CrudOperation<IShift>[],
  shiftsMap: { [id: string]: IShift }
): {} => {
  const updates = {} as any;
  crudOperations.forEach((op) => {
    const shift = op.entity;
    if (op.operation === Operation.create && shift.userId) {
      if (!shift.isDynamicClocked && !shift.isNoShow) {
        // freeClocked shifts dont count > we count their tracking-duration once its accepted
        updates[getShiftPath(shift)] = getDuration(shift);
        shift.surcharge && (updates[getSurchargePath(shift, !!shift.surchargeInPercentage)] = shift.surcharge);
      }
    }
    if (op.operation === Operation.remove) {
      const prev = shiftsMap[shift.id]; // previous shift
      prev.userId && (updates[getBasePath(prev.userId!, prev.date, prev.id)] = null);
    }
    if (op.operation === Operation.update) {
      const prev = shiftsMap[shift.id];
      prev.userId && (updates[getShiftPath(prev)] = null);
      shift.userId && !shift.isDynamicClocked && !shift.isNoShow && (updates[getShiftPath(shift)] = getDuration(shift));
      // remove and add surcharge if it has changed
      if (prev.surcharge || shift.surcharge) {
        prev.surcharge && prev.userId && (updates[getSurchargePath(prev, false)] = null);
        prev.surcharge && prev.userId && (updates[getSurchargePath(prev, true)] = null);
        shift.surcharge &&
          shift.userId &&
          (updates[getSurchargePath(shift, !!shift.surchargeInPercentage)] = shift.surcharge);
      }
    }
  });
  return updates;
};

export const getTrackingMiniUpdates = (crudOperations: CrudOperation<ITracking>[], trackingsMap: any): {} => {
  const updates = {} as any;
  crudOperations.forEach((op) => {
    const tracking = op.entity;
    if (op.operation === Operation.create) {
      tracking.isAccepted && (updates[getTrackingPath(tracking)] = getDuration(tracking));
    }
    if (op.operation === Operation.remove) {
      const prevTracking = trackingsMap[tracking.id] as ITracking;
      prevTracking && prevTracking.isAccepted && (updates[getTrackingPath(prevTracking)] = null);
    }
    if (op.operation === Operation.update) {
      updates[getTrackingPath(tracking)] = tracking.isAccepted ? getDuration(tracking) : null;
    }
  });
  return updates;
};

export const getClockingMiniUpdates = (crudOperations: CrudOperation<ITimeClocking>[], clockingsMap: any): {} => {
  const updates = {} as any;
  crudOperations.forEach((op) => {
    const clocking = op.entity;
    if (op.operation === Operation.create) {
      clocking.isAccepted && (updates[getClockingPathV2(clocking)] = getClockingDuration(clocking)); // In V2 we save Duration+Surcharge
    }
    if (op.operation === Operation.remove) {
      const prevClocking = clockingsMap[clocking.id] as ITimeClocking;
      prevClocking && prevClocking.isAccepted && (updates[getClockingPathV2(prevClocking)] = null);
    }
    if (op.operation === Operation.update) {
      updates[getClockingPathV2(clocking)] = clocking.isAccepted ? getClockingDuration(clocking) : null; // In V2 we save Duration+Surcharge
    }
  });
  return updates;
};
