import React from "react";

import { useDispatch } from "react-redux";
import { useState } from "react";
import TZModal from "../TZModal/TZModal";

import { Input, Button } from "antd";
import { closeModal } from "../../actions/modal";
import { useSelector } from "../../helpers/redux";
import { deleteAllShiftsOfWeek } from "../../actions/shift";
import { simpleDateToMoment } from "../../shared/helpers/timeHelpers";

type Props = {};

export const DeleteWeekPopup = (props: Props) => {
  const dispatch = useDispatch();
  const [checkValue, setCheckValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const cw = useSelector((state) => state.ui.shifts.roster.selectedWeek);
  const isoWeek = simpleDateToMoment(cw).isoWeek();

  return (
    <TZModal>
      <TZModal.Head>{lg.bestätigung_erforderlich}</TZModal.Head>
      <TZModal.Body>
        <TZModal.Row className="noPadding">
          <div>
            <p style={{ margin: 0 }}>
              {lg.bitte_bestätigen_sie_das_löschen_des_wochenplans_der_kalenderwoche_week_indem_sie_week_eingeben(
                isoWeek
              )}
            </p>
            <Input style={{ margin: "16px 0" }} value={checkValue} onChange={(e) => setCheckValue(e.target.value)} />
          </div>
        </TZModal.Row>
      </TZModal.Body>
      <TZModal.Footer>
        <Button
          id="cancel-delete-week"
          onClick={async () => {
            dispatch(closeModal());
          }}
          children={lg.abbrechen}
        />
        <Button
          id="delete-week"
          type="danger"
          onClick={async () => {
            setIsLoading(true);
            await dispatch(deleteAllShiftsOfWeek());
            dispatch(closeModal());
          }}
          loading={isLoading}
          style={{ marginLeft: "auto" }}
          disabled={checkValue !== `${isoWeek}`}
          children={lg.wochenplan_löschen}
        />
      </TZModal.Footer>
    </TZModal>
  );
};
