import React, { PureComponent } from "react";
import TZModal from "../../TZModal/TZModal";
import { InputNumber, Button } from "antd";
import { closeModal } from "../../../actions/modal";
import { connect } from "react-redux";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";
import { EffectiveAbsenceDays } from "../../../shared/entities/IAbsence";
import { decimalSeparatorLocal } from "../../../helpers/dateFormatHelper";

const mapStateToProps = (state: AppState) => ({});

type State = { effectiveDays: { [year: string]: number | undefined } };

type OwnProps = {
  effectiveDays: EffectiveAbsenceDays;
  onChange: (effectiveDays: EffectiveAbsenceDays) => void;
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & DispatchBaseProps & StoreProps;

class EffectiveAbsenceDaysModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      effectiveDays: this.props.effectiveDays,
    };
  }

  onChange = (year: string, days: number | undefined) => {
    this.setState({ effectiveDays: { ...this.state.effectiveDays, [year]: days } });
  };

  save() {
    this.props.onChange(this.state.effectiveDays as EffectiveAbsenceDays);
    this.props.dispatch(closeModal());
  }

  render() {
    const isMultiYear = Object.keys(this.state.effectiveDays).length > 1;

    return (
      <TZModal>
        <TZModal.Head title={lg.effektive_tage}></TZModal.Head>
        <TZModal.Body>
          <div className="effectiveAbsenceDaysCorrectionModal">
            <div className="col">
              <div className="label">{lg.effektive_tage_überschreiben_mit}</div>
              {Object.entries(this.state.effectiveDays).map(([year, days]) => (
                <div className="row" key={year}>
                  {isMultiYear && <div className="label year">{lg.jahr + " " + year}</div>}
                  <div className="inputWrapper">
                    <InputNumber
                      decimalSeparator={decimalSeparatorLocal}
                      autoFocus={true}
                      value={days}
                      onChange={(days) => this.onChange(year, days)}
                    />
                  </div>
                  <div className="daysunit">{lg.Tage}</div>
                </div>
              ))}
            </div>
          </div>
        </TZModal.Body>
        <TZModal.Footer>
          <Button
            id="save-effective-days"
            type="primary"
            onClick={(e: any) => this.save()}
            disabled={!!Object.values(this.state.effectiveDays).some((days) => typeof days !== "number")}
          >
            {lg.Speichern}
          </Button>
        </TZModal.Footer>
      </TZModal>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(EffectiveAbsenceDaysModal);
