import { Button, Input, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../actions/modal";
import { editUpload, uploadFile } from "../../../../../actions/uploads";
import { busyInjector, BusyInjectorProps } from "../../../../../components/BusyInjector/BusyInjector";
import { isUploaded } from "../../../../../components/modals/AbsenceModal/localHelpers";
import TZModal from "../../../../../components/TZModal/TZModal";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";
import { IUpload, IUploadBase } from "../../../../../shared/entities/IUpload";
import "./styles.scss";

type Props = {
  upload: IUpload | IUploadBase;
  onComplete?: (upload: IUpload | IUploadBase) => any;
  doSave?: boolean;
  withComment?: boolean;
};

export const EditUploadModal = busyInjector((props: Props & BusyInjectorProps) => {
  const [displayName, setDisplayName] = useState("");
  const [comment, setComment] = useState("");
  const dispatch = useDispatch<DispFn>();

  useEffect(() => {
    setDisplayName(props.upload.displayName);
    setComment(props.upload.comment || "");
  }, []);

  const saveClicked = async () => {
    if (props.doSave && !isUploaded(props.upload)) {
      return console.error('@AV: Can only use "doSave" if you pass in an IUpload insted of IUplaodBase ');
    }
    const upload = { ...props.upload, displayName, comment } as IUpload;
    props.doSave && (await props.load(dispatch(editUpload(upload))));
    dispatch(closeModal());
    props.onComplete && props.onComplete(upload);
  };

  return (
    <TZModal>
      <TZModal.Head>{lg.dateiname_ändern}</TZModal.Head>
      <TZModal.Body style={{ padding: 20, width: 480 }}>
        <div className="editUplaodModalMain">
          <div className="row nameRow">
            <Input
              type="text"
              className="nameInput"
              onChange={(e) => setDisplayName(e.target.value)}
              value={displayName}
            />
          </div>
          <div className="rwo fileNameRow">{props.upload.name}</div>
          {props.withComment && (
            <div className="row commentRow">
              <Input
                type="text"
                className="comment"
                placeholder={lg.kommentar_hinzufügen}
                onChange={(e) => setComment(e.target.value)}
                value={comment}
              />
            </div>
          )}
        </div>
      </TZModal.Body>
      <TZModal.Footer>
        <Button
          id="delete-account-modal-final"
          style={{ width: 120 }}
          disabled={!displayName}
          type="primary"
          onClick={saveClicked}
          loading={props.isLoading()}
          children={props.isLoading() ? null : lg.Speichern}
        ></Button>
      </TZModal.Footer>
    </TZModal>
  );
});
