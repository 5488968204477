import React, { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import loaderGifUrl from "./../loader.gif";
import clockLoaderWhiteUrl from "./../clockLoaderWhite.gif";
import { Icon, Popover } from "antd";
import _ from "lodash";
import moment from "moment";
import {
  toggleTimeClockingBreak,
  stopTimeClocking,
  startPunching,
  stopPunching,
} from "../../../../actions/timeClocking";
import { BusyLoad } from "../../../../components/BusyInjector/BusyInjector";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import { selectJobPositionMap, selectWorkSpaceMap, selectBranchMap } from "../../../../selectors/mapSelectors";
import { ITimeClocking } from "../../../../shared/entities/ITimeClocking";
import { IUser } from "../../../../shared/entities/IUser";
import { generateUserTimeClockSettings } from "../../../../shared/helpers/settingsHelpers";
import { calcBreakMinutes } from "../../../../shared/helpers/timeClockingHelpers";
import { minutesToDuration, getDuration, toSimpleTime } from "../../../../shared/helpers/timeHelpers";
import { TimeClockLogBox } from "../../TimeClockLogBox/TimeClockLogBox";
import { useSelector } from "../../../../helpers/redux";
import "./styles.scss";
import { PunchClockUser } from "../../PunchClockUser/PunchClockUser";
import { PunchClockOptionsBar } from "../../PunchClockOptionsBar/PunchClockOptionsBar";
import { decryptUser } from "../../../../shared/helpers/userHelpers";
import cn from "classnames";
import { timeClockingRepository } from "../../../../repositories/timeClockingRepository";
import { IBranch } from "../../../../shared/entities/IBranch";

type Props = {
  changeBranchId: (bid: string) => void;
  selectableBranches: IBranch[];
  branchId: string;
};

export const PunchClockBranchBar = React.memo(({ changeBranchId, selectableBranches, branchId }: Props) => {
  const [isBranchPopoverOpen, toggleBranchPopover] = useState(false);
  return (
    <Popover
      trigger={selectableBranches.length > 1 ? "click" : "contextMenu"}
      placement={"top"}
      title={null}
      visible={isBranchPopoverOpen}
      onVisibleChange={(isOpen) => toggleBranchPopover(isOpen)}
      content={
        <div className="punchClockOptionsPopoverMenu">
          {selectableBranches.map((b) => (
            <div
              className="menuOption"
              key={b.id}
              onClick={() => {
                toggleBranchPopover(!isBranchPopoverOpen);
                changeBranchId(b.id);
                // activeClocking
                //   ? dispatch(timeClockingRepository.update({ ...activeClocking, branchId: b.id }))
                //   : setPreClocking({ ...preClocking, branchId: b.id });
              }}
            >
              {b.name}
            </div>
          ))}
        </div>
      }
    >
      <div className={cn({ punchClockBranchBar: true, canChange: selectableBranches.length > 1 })}>
        <div className="icon">
          <Icon type="environment" />
        </div>
        <div className="name">{selectableBranches.find((b) => b.id === branchId)!.name}</div>
      </div>
    </Popover>
  );
});
