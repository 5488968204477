import React from "react";
import { Select } from "antd";
import "./styles.scss";
import { SelectProps } from "antd/lib/select";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import { connect } from "react-redux";
import { selectActiveJobPositions } from "../../../../selectors/ActiveJobPositionsSelector";
import { selectUserMap } from "../../../../selectors/mapSelectors";
import { getBranchColor, orderByName } from "../../../../frontend-core/helpers/frontendHelpers";
import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { RoleType } from "../../../../shared/entities/IUser";
import { includes } from "lodash";
import { IBranch } from "../../../../shared/entities/IBranch";

const mapStateToProps = (state: AppState) => {
  return {
    branches: state.data.branches,
    userMap: selectUserMap(state),
    sessionUser: selectSessionInfo(state).user,
  };
};

type State = {};
type OwnProps = {
  onChange: (value: string) => void;
  value: string | undefined;
  userId?: string;
  selectedUserIds: string[]; // in case of userPickerMode
  isDisplayOnly?: boolean;
} & SelectProps<any>;

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;

class _ShiftBranchSelect extends React.PureComponent<Props, {}> {
  render() {
    const { value, onChange, branches, userMap, userId, selectedUserIds, sessionUser, ...otherProps } = this.props;
    const users = [...selectedUserIds, userId].filter((uid) => !!uid).map((uid) => userMap[uid as string]);

    const canSeeBranch = (branch: IBranch): boolean =>
      sessionUser.role === RoleType.admin || sessionUser.branchIds.includes(branch.id);

    // allow only branches that belong to the userId-prop
    // if the userId-prop is undefined, allow all branches
    const selectableBranches = orderByName(branches).filter((branch) => {
      if (branch.id === value) {
        return true; // the selected one should always be included in the options
      }

      if (branch.isInactive || branch.isDisabled) {
        return false;
      }

      if (users.length) {
        return (
          // if one ore more users are selected > only allow jobPositions that the users have
          users.every((u) => u.branchIds.includes(branch.id)) && canSeeBranch(branch)
        );
      }

      if (!users.length) {
        return canSeeBranch(branch);
      }
    });

    const selectedBranch = value ? branches.find((jp) => jp.id === value) : undefined;
    const selectedBranchColor = selectedBranch && getBranchColor(selectedBranch);

    return (
      <div className="avInputFieldMain jobPosSelectMainField">
        <span className="labelWrapper">
          <label>{lg.standort}</label>
        </span>
        {this.props.isDisplayOnly ? (
          <div className="row fb aCenter jobPosRect ">
            <div className="colorRectJobPosSelect" style={{ background: selectedBranchColor }}></div>
            <div>{selectedBranch?.name}</div>
          </div>
        ) : (
          <Select
            id="shift-branch-select"
            value={value}
            onChange={(value: string) => {
              this.props.onChange(value);
            }}
            style={{ width: "100%", overflow: "hidden" }}
            allowClear={false}
            {...otherProps}
          >
            {selectableBranches.map((branch) => (
              <Select.Option value={branch.id} key={branch.id}>
                <div className="row fb aCenter">
                  <div className="colorRectJobPosSelect" style={{ background: getBranchColor(branch) }} />
                  {branch.name}
                </div>
              </Select.Option>
            ))}
          </Select>
        )}
      </div>
    );
  }
}

export const ShiftBranchSelect = connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(_ShiftBranchSelect);
