import { Dispatch } from "redux";

export const branchSelectionActionTypes = {
  SELECT: "@@AV/BRANCH_SELECT",
};

export const selectBranch = (branchId: string | null) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: branchSelectionActionTypes.SELECT,
      payload: branchId,
    });
  };
};
