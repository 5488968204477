import React from "react";
import "./styles.scss";
import { Icon } from "antd";
import cN from "classnames";
import { AvIcon } from "../../../AvIcon/AvIcon";
import AvIntegerInput from "../../../AvIntegerInput/AvIntegerInput";
import { getDuration, minutesToDuration, minutesToTTime } from "../../../../shared/helpers/timeHelpers";
import cn from "classnames";
import { IRosterSettings } from "../../../../shared/entities/IRosterSettings";
import AvTimeInput from "../../../AvTimeInput/AvTimeInput";
import { ITracking } from "../../../../shared/entities/ITracking";
import { DeviceType, ITimeClocking } from "../../../../shared/entities/ITimeClocking";
import { IUser, IUserFull } from "../../../../shared/entities/IUser";
import { Map } from "../../../../shared/types/general";
import moment from "moment";
import { getPunchingLogDevice } from "../helper";

type Props = {
  startTime: string;
  endTime: string;
  isInvalid?: boolean;
  onChange: (v: string | number, name: "startTime" | "endTime" | "breakMinutes" | "breakStartTime") => void;
  onEnterKeyDown?: () => void;
  disabled?: boolean;
  breakMinutes?: number;
  breakStartTime?: string;
  noLabel?: boolean;
  withBorderBottom?: boolean;
  displayQuestionMarkForEmptyValuesWhenDisabled?: boolean;
  disableStartTime?: boolean;
  additionalTag?: boolean;
  showDuration?: boolean;
  isDynamicClocked?: boolean;
  withBreakStartTime?: boolean;
  extraDuration?: number;
  onClick?: () => void;
  hideBreakInput?: boolean;
  isV2?: boolean;
  clockingV2?: ITimeClocking;
  userFullMap?: Map<IUserFull>; // used in V2
};

export default class StartEndTimeInput extends React.Component<Props> {
  startTimeInputRef: React.RefObject<HTMLInputElement>;
  endTimeInputRef: React.RefObject<HTMLInputElement>;
  breakTimeInputRef: any;
  //breakMinutesInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.startTimeInputRef = React.createRef();
    this.endTimeInputRef = React.createRef();
    //this.breakMinutesInputRef = React.createRef();
  }

  componentDidMount() {
    !this.props.startTime && this.focusFirstInputField();
  }

  componentWillUpdate(nextProps: Props) {
    const justEnabled = this.props.disabled && !nextProps.disabled;
    justEnabled && this.focusFirstInputField();
  }

  focusFirstInputField() {
    setTimeout(() => {
      (this.startTimeInputRef.current as HTMLInputElement)?.focus();
      (this.startTimeInputRef.current as HTMLInputElement)?.setSelectionRange(0, 0);
    }, 10);
  }

  handleInput = (value) => {
    if (!value.length) {
      return true;
    }

    return /^[0-9:]+$/.test(value);
  };

  startTimeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.handleInput(e.target.value)) {
      this.changeHandler("startTime", e.target.value);
    }
  };

  endTimeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.handleInput(e.target.value)) {
      this.changeHandler("endTime", e.target.value);
    }
  };

  breakMinutesChanged = (val: number | undefined) => {
    if (val === undefined || (val >= 0 && val <= 1439)) {
      this.props.onChange(val || 0, "breakMinutes");
    }
  };

  /**
   * Sets the start- or endTime state when entering a new value,
   * and simoultanously does some magic transformations to the value,
   * so the user has some shortcuts for entering a valid time.
   */
  changeHandler = (name: string, value: string) => {
    const previousValue = this.props[name as "startTime" | "endTime"];
    let newValue = value;

    if (previousValue.length === 2 && newValue.length === 3 && !isNaN(Number(newValue.charAt(3)))) {
      // When the cursor of the user is at "02|" and he enters
      // another number, we automatically add the ":" between "2" and the new digit
      newValue = `${previousValue}:${newValue.charAt(2)}`;
    }

    if (newValue.length === 2 && !isNaN(Number(newValue.charAt(0))) && newValue.charAt(1) === ":") {
      // When the user enters "1:", we transform it to "01:"
      newValue = `0${newValue.charAt(0)}:`;
    }

    if (isNaN(Number(newValue.substr(0, 2)))) {
      // HOURS digits are not numeric
      return;
    }

    if (newValue.length >= 2 && Number(newValue.charAt(0)) > 2) {
      newValue = newValue.charAt(0);
    }

    if (newValue.length >= 3 && (newValue.charAt(2) !== ":" || isNaN(Number(newValue.substr(3, 5))))) {
      // TIME digits are not numeric
      return;
    } else if (Number(newValue.charAt(0)) === 2 && Number(newValue.charAt(1)) > 4) {
      // Second HOURS digit does not represent a valid time in combination
      // with the first HOURS digit
      return;
    } else if (newValue.length > 3 && Number(newValue.charAt(3)) > 5) {
      // First MINUTES digit does not represent a valid time
      return;
    }

    if (previousValue.length === 2 && newValue.length === 3 && !isNaN(Number(newValue.charAt(3)))) {
      // When the cursor of the user is at "02|" and he enters
      // another number, we automatically add the ":" between "2" and the new digit
      newValue = `${previousValue}:${newValue.charAt(3)}`;
    }
    if (newValue.length === 1 && Number(newValue) > 2) {
      // When the user enters a number from 3 to 9 as the first digit,
      // we automatically assume he means for example 08: (when typing 8)
      newValue = `0${newValue}:`;
    } else if (previousValue.length === 4 && newValue.length === 3) {
      // When the user removes the last char of "00:0"
      // we automatically remove the ":" as well, so the focus goes to "00|"
      newValue = newValue.substr(0, 2);
    } else if (previousValue.length === 3 && newValue.length === 2) {
      // When the user removes the last char of "12:"
      // we automatically remove the "2:", so the focus goes to "1|"
      // Keep in mind, that this can only happen, when the user starts to enter
      // 2 digits, and then removes one.
      newValue = newValue.substr(0, 1);
    } else if (newValue.length === 2) {
      newValue = newValue + ":";
    }

    if (previousValue.length === 4 && newValue.length === 5 && name === "startTime") {
      // When the user enters the last digit of the startTime
      // we put the focus on the other field
      (this.endTimeInputRef.current as HTMLInputElement).focus();
      (this.endTimeInputRef.current as HTMLInputElement).setSelectionRange(0, 0);
    }

    // if (name === 'endTime' && newValue.length === 5) {
    //   // When the endtime gets a valid full new value, we focus the breakminutes field
    //   // (this.breakMinutesInputRef.current as HTMLInputElement).focus();
    //   this.applyBreakRuleIfAvailable(newValue);
    // }

    this.props.onChange(newValue, name as "startTime" | "endTime");
  };

  /**
   * When bluring out, we fill up the rest of the empty slots with 0 digits
   */
  startEndTimeBlurHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    let newValue = value;
    if (value.length === 1) {
      newValue = `0${newValue}:00`;
    }
    if (value.length === 2) {
      newValue = `${newValue}:00`;
    }
    if (value.length === 3) {
      newValue = `${newValue}00`;
    }
    if (value.length === 4) {
      newValue = `${newValue}0`;
    }
    if (newValue !== value) {
      this.props.onChange(newValue, name as "startTime" | "endTime");
    }
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, name: "startTime" | "endTime" | "breakMinutes") => {
    if (event.key === "Enter" && this.props.onEnterKeyDown && !this.props.isInvalid) {
      this.props.onEnterKeyDown();
      return;
    }

    if (name === "breakMinutes") {
      return;
    }

    const current = name === "startTime" ? this.startTimeInputRef.current : this.endTimeInputRef.current;

    if (!current) {
      return;
    }

    if (name === "startTime" && event.key === "ArrowRight" && current.selectionStart === 5) {
      // When the user is with his cursor at the last index
      // of the startTime input field, and presses the arrow right
      // we put the cursor to the first index of the endTime input field
      (this.endTimeInputRef.current as HTMLInputElement).focus();
      (this.endTimeInputRef.current as HTMLInputElement).setSelectionRange(0, 1);
    }

    if (current.selectionStart !== 0 || current.selectionEnd !== 0) {
      return;
    }

    if (name === "endTime" && event.key === "ArrowLeft") {
      // When the user is with his cursor at the first index
      // of the endTime input field, and presses the arrow left
      // we put the cursor to the one before the last index of the
      // startTime input
      (this.startTimeInputRef.current as HTMLInputElement).setSelectionRange(
        this.props.startTime.length,
        this.props.startTime.length + 1
      );
      (this.startTimeInputRef.current as HTMLInputElement).focus();
    }

    if (
      name === "endTime" &&
      this.props[name].length === 0 &&
      event.key === "Backspace" &&
      this.props.startTime.length === 5
    ) {
      // When the user presses the backspace, while being at the first
      // index of the endTime input, we directly remove the last digit
      // of the startTime input field
      const newStartTimeValue = this.props.startTime.substr(0, 5);
      this.props.onChange(newStartTimeValue, "startTime");
      (this.startTimeInputRef.current as HTMLInputElement).focus();
    }

    if (this.props[name].length > 0) {
      // When the user puts his cursor at the first index
      // of the input field, while there are already some digits
      // then we override the rest the current value by the newly entered
      // digit
      if (!isNaN(Number(event.key))) {
        const key = event.key;
        // We need to call this, after the original
        // onChange handler of the input field gets triggered.
        setTimeout(() => {
          this.props.onChange(key, name);
        });
      }
    }
  };

  renderDuration = () => {
    const { startTime, endTime, breakMinutes, extraDuration } = this.props;
    const duration =
      startTime?.length === 5 && endTime?.length === 5 && getDuration({ startTime, endTime, breakMinutes });
    const durationTime = duration ? minutesToDuration(duration) : "0:00";
    const extraDurationTime = extraDuration && minutesToDuration(extraDuration);

    return (
      <div className="startendDuration">
        <div className="durationValues" data-rh="Schichtdauer">{`${durationTime} ${lg.std}`}</div>
        {!!extraDuration && (
          <div className="extraDurationValues" data-rh={lg.zeiterfassung_dauer}>
            {`${extraDurationTime} ${lg.std}`}
          </div>
        )}
      </div>
    );
  };

  getLogIcon = (logKey: "logS" | "logE" | "logB"): JSX.Element | null => {
    const clocking = this.props.clockingV2;
    const log = clocking?.[logKey] as string;

    if (!log) {
      return null;
    }

    const [deviceType, userId, timestamp] = log.split("_");
    const userName = userId ? this.props.userFullMap?.[userId]?.name : "";

    const dateStr = timestamp && moment.unix(parseInt(timestamp)).format("lll");
    const tooltip =
      deviceType === DeviceType.Manual
        ? `Manuell von ${userName} am ${dateStr}`
        : `Gestempelt per ${getPunchingLogDevice(deviceType as any)}`;

    return (
      <div className="logIconWrapper" data-rh={tooltip}>
        <Icon type={deviceType === DeviceType.Manual ? "edit" : "history"} />
      </div>
    );
  };

  render() {
    const { withBreakStartTime, onClick, breakMinutes, endTime, isV2 } = this.props;
    const hasValidEndTime = endTime && endTime.length === 5;

    const _withBreakStartTime = !isV2 && withBreakStartTime;

    if (this.props.isDynamicClocked) {
      return null;
    }

    const startTime = this.props.startTime;
    const timePlaceHolder = this.props.displayQuestionMarkForEmptyValuesWhenDisabled ? "??:??" : "00:00";

    const allCharsAreFilled = startTime?.length === 5 && endTime?.length === 5;
    return (
      <div
        className={cN({
          startEndTimeInput: true,
          disabled: this.props.disabled,
          isInvalid: allCharsAreFilled && this.props.isInvalid,
          additionalTag: this.props.additionalTag,
          isV2: isV2,
        })}
        style={{
          borderBottom: this.props.withBorderBottom ? "1px solid #d8d8d8" : undefined,
        }}
        onClick={() => {
          onClick && onClick();
        }}
      >
        {!this.props.noLabel && (
          <label className={cn({ shiftPopupLabelMain: 1, isV2 })}>{lg.arbeitszeit__schicht}</label>
        )}
        <div className="inputValueWrapper">
          <div className={"inputContent orangable" + (isV2 ? " isV2" : "")}>
            {isV2 && <div className="inputLabel">{lg.start}</div>}
            {isV2 && this.getLogIcon("logS")}
            <input
              type="text"
              onChange={this.startTimeChanged}
              onBlur={this.startEndTimeBlurHandler}
              value={startTime as string}
              placeholder={timePlaceHolder}
              name="startTime"
              maxLength={5}
              autoFocus={!startTime}
              ref={this.startTimeInputRef}
              onKeyDown={(e) => this.onKeyDown(e, "startTime")}
              disabled={this.props.disabled || this.props.disableStartTime}
              className="timeInput startTimeInput"
            />
          </div>
          {!isV2 && <span className="minus-icon orangable"></span>}
          <div className={"inputContent orangable endTime" + (isV2 ? " isV2" : "")}>
            {isV2 && <div className="inputLabel">{lg.ende}</div>}
            {isV2 && this.getLogIcon("logE")}
            {allCharsAreFilled && startTime > endTime && (
              <div className={cn({ iconWrapper: 1, moonIconWrapper: 1, isV2 })}>
                <AvIcon type="icon-moon-blue" style={{ fontSize: "12px" }} data-rh={lg.schicht_endet_am_folgetag} />
              </div>
            )}
            <input
              type="text"
              onChange={this.endTimeChanged}
              onBlur={this.startEndTimeBlurHandler}
              value={endTime as string}
              placeholder={timePlaceHolder}
              name="endTime"
              maxLength={5}
              ref={this.endTimeInputRef}
              onKeyDown={(e) => this.onKeyDown(e, "endTime")}
              disabled={this.props.disabled}
              className="timeInput endTimeInput"
            />
          </div>
          {!this.props.hideBreakInput && (
            <div className="breakMinutesInputWrapper fb col" data-rh={lg.pause_in_minuten}>
              <div
                className={cn({
                  minutesRow: true,
                  withBreakStartTime: _withBreakStartTime,
                  isBreakMinutesEmpty: !breakMinutes,
                  isV2,
                })}
                onClick={() => this.breakTimeInputRef?.focus()}
              >
                {!isV2 && (
                  <Icon
                    type="coffee"
                    className="cupIcon"
                    style={{
                      marginRight: 6,
                      // color: this.props.additionalTag ? "#ffa940" : undefined,
                    }}
                  ></Icon>
                )}
                {isV2 && <div className="inputLabel">{lg.pause}</div>}
                {isV2 && this.getLogIcon("logB")}
                <AvIntegerInput
                  getInputRef={(ref) => (this.breakTimeInputRef = ref)}
                  onValueChange={this.breakMinutesChanged}
                  value={this.props.breakMinutes}
                  placeholder="00"
                  name="breakMinutes"
                  min={0}
                  max={1439}
                  //ref={this.breakMinutesInputRef}
                  disabled={this.props.disabled}
                />
              </div>
              {_withBreakStartTime && (
                <div className="startTimeRow" data-rh-at="right" data-rh={lg.startzeit_der_pause}>
                  <AvTimeInput
                    value={this.props.breakStartTime}
                    disabled={this.props.disabled}
                    style={{ fontSize: 18, paddingLeft: 4, color: "#888888" }}
                    onValueChange={(val: string | undefined) => {
                      this.props.onChange(val || "", "breakStartTime");
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {this.props.showDuration && hasValidEndTime && this.renderDuration()}
      </div>
    );
  }
}
