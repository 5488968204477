import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import "./styles.scss";
import TZModal from "../../../TZModal/TZModal";

import { selectSessionInfo } from "../../../../selectors/SessionInfoSelector";
import { openModal, closeModal } from "../../../../actions/modal";
import { Icon, Tooltip, Select, DatePicker } from "antd";
import cn from "classnames";
import { ReassignShiftPopup } from "../../ReassignShiftPopup/ReassignShiftPopup";
import { IShift } from "../../../../shared/entities/IShift";
import { simpleDateToMoment, toSimpleDate } from "../../../../shared/helpers/timeHelpers";
import { Moment } from "moment";
import { ShiftPopupDatePicker } from "./ShiftPopupDatePicker/ShiftPopupDatePicker";
import { getUserName } from "../../../../shared/helpers/userHelpers";
import { ITracking } from "../../../../shared/entities/ITracking";
import { ITimeClocking } from "../../../../shared/entities/ITimeClocking";
import clockedInGifSrc from "./../../../../frontend-core/assets/clockedinsignal.gif";
import { BasicSelect } from "../../../BasicSelect/BasicSelect";
import { selectUsersWithSharedBranch } from "../../../../selectors/UsersWithSharedBranchSelector";
import moment from "moment";
import { selectActiveUsers } from "../../../../selectors/ActiveUserSelectors";
import { selectTimeClockSettingsOfSesionUser } from "../../../../selectors/timeClockSettingsOfSessionUser";
import { canEmployeAddEditPunchingOnDate } from "../../../../frontend-core/helpers/frontendHelpers";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  return {
    users: state.data.users,
    canManage: selectSessionInfo(state).hasManagerPermissions(),
    isV2: state.data.tenantInfo.isV2,
    usersWithShardBranch: selectUsersWithSharedBranch(state),
    activeUsers: selectActiveUsers(state, toSimpleDate(moment())),
    sessionInfo: selectSessionInfo(state),
    selectedBranchId: state.ui.selectedBranch,
    userTimeclockSettings: selectTimeClockSettingsOfSesionUser(state),
  };
};

type OwnProps = {
  shift: Partial<IShift>;
  tracking?: ITracking;
  clocking?: ITimeClocking;
  isUserEditable?: boolean;
  onUserRemove?: () => any;
  changeShiftDate?: (date: string) => void;
  changeShiftUserIdV2?: (userId: string) => void;
  title?: string | boolean;
  isRosterTemplateMode?: boolean;
  showUserSelectV2?: boolean;
  isCreation?: boolean;
};

type State = {
  isDatePopoverOpen: boolean;
};

type StoreProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StoreProps & DispatchBaseProps;

class _ShiftPopupHead extends React.PureComponent<Props, State> {
  today: string;

  constructor(props: Props) {
    super(props);
    this.today = moment().format(SDateFormat);
  }

  openReassignmentPopup = () => {
    this.props.dispatch(closeModal());
    this.props.dispatch(openModal(ReassignShiftPopup, { shift: this.props.shift as IShift }));
  };

  activeUsersOfBranch = this.props.activeUsers.filter(
    (u) => !this.props.selectedBranchId || u.branchIds.includes(this.props.selectedBranchId)
  );

  selectableUsers = this.activeUsersOfBranch.filter(
    (user) => this.props.sessionInfo.isAdmin() || this.props.usersWithShardBranch.includes(user.id)
  );

  render() {
    const {
      shift,
      canManage,
      isUserEditable,
      title,
      isV2,
      clocking,
      tracking,
      showUserSelectV2,
      changeShiftUserIdV2,
      userTimeclockSettings,
      isCreation,
    } = this.props;
    const assignedUser = this.props.users.find((u) => u.id === shift.userId);
    const canBeReassigned = isUserEditable && canManage && !!shift.userId && !!shift.id && !isV2;
    const canEmployeeEditClocking = canEmployeAddEditPunchingOnDate(userTimeclockSettings, shift.date!, this.today);

    const canChangeDate = isV2
      ? isCreation && (canManage || canEmployeeEditClocking)
      : !shift.repeatId && !tracking && !clocking && canManage;

    return (
      <TZModal.Head headStyle={{ padding: "0px" }}>
        <div className="shiftPopupHeadMain">
          {/* <label>{simpleDateToMoment(shift.date!).format("dd DD. MMM")}</label> */}
          <ShiftPopupDatePicker
            disabled={!canChangeDate}
            changeShiftDate={this.props.changeShiftDate}
            shift={shift}
            justWeekDay={this.props.isRosterTemplateMode}
            isEmpty={!!shift.isTemplate}
            today={this.today}
            disabledDate={
              isV2
                ? canManage
                  ? (m) => !!m && toSimpleDate(m) > this.today
                  : (m) => !!m && !canEmployeAddEditPunchingOnDate(userTimeclockSettings, toSimpleDate(m), this.today)
                : undefined
            }
          />

          {!showUserSelectV2 && (
            <div className={"userName " + cn({ editable: canBeReassigned, isRequirement: shift.isRequirement })}>
              <span>{(assignedUser ? getUserName(assignedUser) : title) || ""}</span>
              <Tooltip title={lg.schicht_einem_anderen_nutzer_zuweisen}>
                <Icon type="edit" onClick={() => canBeReassigned && this.openReassignmentPopup()} />
              </Tooltip>
              {this.props.onUserRemove && (
                <Tooltip title={lg.nutzer_entfernen_und_schicht_wieder_freigeben_als_offene_schicht}>
                  <Icon type="minus-circle" onClick={() => this.props.onUserRemove!()} />
                </Tooltip>
              )}
            </div>
          )}

          {showUserSelectV2 && (
            <div className="fieldWrapper" style={{ width: 200, marginLeft: 5 }}>
              <BasicSelect
                options={this.selectableUsers.map((u) => ({ value: u.id, label: getUserName(u) }))}
                onChange={(userId: string) => changeShiftUserIdV2 && changeShiftUserIdV2(userId)}
                value={this.props.shift.userId}
                placeholder={lg.mitarbeiter}
                showSearch
              />
            </div>
          )}

          {/*
          {shift.surcharge && (
            <span className="badge surcharge" style={{ marginLeft: 8 }}>
              {`${shift.surcharge} ${shift.surchargeInPercentage ? "%" : "h"} Aufschlag`}
            </span>
          )} */}

          {clocking && !clocking.isAccepted && clocking.endTime && !clocking.isAutoClockOut && isV2 && (
            <div className="unapprovedBadge">
              <Icon type="hourglass" className="unapprovedIcon" />
              {lg.unbestätigt}
            </div>
          )}

          {clocking && !clocking.endTime && !clocking.isAutoClockOut && isV2 && (
            <div className="isClockedInWrapper">
              <img src={clockedInGifSrc} style={{ width: 24, height: 24 }} data-rh={lg.ist_eingestempelt} />
              <div className="label">eingestempelt</div>
            </div>
          )}

          {clocking && clocking.isAutoClockOut && isV2 && (
            <div className="autoClockoutBadge" data-rh={lg.automatisch_ausgestempelt_12_stunden_nach_dem_einstempeln}>
              {lg.automatisch_ausgestempelt}
            </div>
          )}

          {shift.isDynamicClocked && !isV2 && (
            <span
              className="badge freeClockedBadge"
              style={{ marginLeft: 8 }}
              data-rh={lg.ohne_geplante_schichten_gestempelt}
            >
              {lg.ohne_schicht_gestempelt}
            </span>
          )}
        </div>
      </TZModal.Head>
    );
  }
}

export const ShiftPopupHead = connect(mapStateToProps)(_ShiftPopupHead);
