import { IAvailability } from "./../shared/entities/IAvailability";
import { IShift } from "./../shared/entities/IShift";
import { STimeFormat } from "./../shared/helpers/SimpleTime";

import moment from "moment";
import { getWeekDayOfSimpleDate } from "../components/modals/AbsenceModal/localHelpers";
import { IAbsence } from "../shared/entities/IAbsence";

export const isWorkingTimeInvalid = (startTime?: string, endTime?: string, breakMinutes = 0) => {
  if (!startTime || !endTime) {
    return true;
  }

  if (startTime.length !== 5 || endTime.length !== 5) {
    return true;
  }

  const startTimeMoment = moment(startTime as string, STimeFormat);
  const endTimeMoment = moment(endTime as string, STimeFormat);

  if (!startTimeMoment.isValid() || !endTimeMoment.isValid()) {
    // Make sure,
    // that the entered numbers represent a valid time,
    return true;
  }

  return false;
};

export const getUnavailableDuringShift = (
  shift: IShift,
  availabilities: IAvailability[]
): IAvailability | undefined => {
  const weekday = getWeekDayOfSimpleDate(shift.date);
  const unavailability = availabilities.find((availability) => {
    if (
      availability.isAvailable ||
      shift.userId !== availability.userId ||
      availability.startDate > shift.date ||
      (availability.endDate && availability.endDate < shift.date)
    ) {
      return false;
    }

    return (
      (availability.isSingleDay || !!availability.weekDays?.find((wd) => wd === weekday)) &&
      (availability.isWholeDay || (availability.startTime! < shift.endTime && availability.endTime! > shift.startTime))
    );
  });
  return unavailability;
};

export const filterAvailabilitiesForUser = (userId: string, availabilities: IAvailability[], date: string) => {
  const weekday = getWeekDayOfSimpleDate(date);
  return availabilities.filter(
    (availability) =>
      userId === availability.userId &&
      availability.startDate <= date &&
      (!availability.endDate || availability.endDate >= date) &&
      (availability.startDate === availability.endDate || !!availability.weekDays?.find((wd) => wd === weekday))
  );
};

export const filterAvailabilitiesForDate = (userAvailabilities: IAvailability[], date: string) => {
  const weekday = getWeekDayOfSimpleDate(date);
  return userAvailabilities.filter(
    (availability) =>
      availability.startDate <= date &&
      (!availability.endDate || availability.endDate >= date) &&
      (availability.startDate === availability.endDate || !!availability.weekDays?.find((wd) => wd === weekday))
  );
};

const minify = (time: string = "", doMinify?: boolean) => {
  return doMinify ? time.replace(":00", "") : time;
};

const getAvailabilitySting = (a: IAvailability, opt?: { mini?: boolean; unit?: string }) =>
  (a.isWholeDay
    ? opt?.mini
      ? " 0 - 24 h"
      : ` ${lg.ganztägig}`
    : ` ${minify(a.startTime, opt?.mini)} - ${minify(a.endTime, opt?.mini)} ${opt?.unit || ""}`) +
  (a.note && !opt?.mini ? ` | ${a.note}` : "");

export const generateAvailabilityText = (availabilities: IAvailability[], opt?: { minimal: boolean }): string => {
  if (!availabilities?.length) {
    return "";
  }

  if (availabilities[0]?.isGeneratedByContract) {
    return opt?.minimal ? "X" : lg.kein_arbeitstag;
  }

  // Pass ONLY availabilties or unavailabilities to render the tooltips
  const availableOrUnavailableText = opt?.minimal
    ? ""
    : (availabilities[0].isAvailable ? lg.verfügbar : lg.unverfügbar) + ":";

  return (
    availableOrUnavailableText +
    (availabilities[1] ? getAvailabilitySting(availabilities[1], { mini: opt?.minimal }) + " |" : "") +
    getAvailabilitySting(availabilities[0], { mini: opt?.minimal, unit: " h" })
  );
};
