import moment from "moment";
import React from "react";
import { dummyColors } from "../../../constants/styling";
import { IMessage } from "../../../shared/entities/IMessage";
import { IUser } from "../../../shared/entities/IUser";
import { IUserThreadInfo } from "../../../shared/entities/IUserThreadInfo";
import { SDateTimeFormat, STimeFormat } from "../../../shared/helpers/SimpleTime";
import { getUserName } from "../../../shared/helpers/userHelpers";
import { UserInfo } from "../../../shared/helpers/UserInfo";
import { Map } from "../../../shared/types/general";
import { timestampToSimpleTime } from "../chatHelpers";
import { ThreadStyles } from "./styles";
import classNames from "classnames";
import { AvIcon } from "../../../components/AvIcon/AvIcon";
import { Button, Icon, Modal } from "antd";

type Props = {
  userMap: Map<IUser>;
  message: IMessage;
  userThreadInfo: IUserThreadInfo;
  sessionInfo: UserInfo;
  onDeleteClick?: () => void;
  canDelete?: boolean;
};

export const Message = React.memo(({ message, userMap, userThreadInfo, sessionInfo, ...props }: Props) => {
  const isOwnMessage = sessionInfo.user.id === message.userId;
  const userIdNumber = parseInt(message.userId.slice(1, message.userId.length));
  const dateTimeText = timestampToSimpleTime(message.id.split("_")[0]);
  const isGroupChat = !!userThreadInfo?.title?.length;
  return (
    <div
      className={classNames({
        fb: true,
        row: true,
        chatMessageRow: true,
        isOwnMessage,
      })}
      style={{
        ...ThreadStyles.messageRow,
        justifyContent: isOwnMessage ? "flex-end" : "flex-Start",
      }}
    >
      <div
        style={{ ...ThreadStyles.messageBox, background: isOwnMessage ? "#d7edff" : "white" }}
        className="chatMessageBox"
      >
        {isGroupChat && !isOwnMessage && (
          <div style={{ ...ThreadStyles.messageUserNameText, color: dummyColors[userIdNumber % dummyColors.length] }}>
            {getUserName(userMap[message.userId]) || lg.unbekannt}
          </div>
        )}
        <div style={{ ...ThreadStyles.messageText, justifyContent: isOwnMessage ? "flex-end" : "flex-start" }}>
          {message.text}
        </div>
        <div style={ThreadStyles.messageDateText}>{dateTimeText}</div>
        {/* <div style={ThreadStyles.messageDateText}>{message.timestamp}</div> */}
        {props.canDelete && (
          <div className="messageSettingsBox">
            <Button
              type="danger"
              icon="delete"
              shape="circle"
              onClick={() => {
                Modal.error({
                  okCancel: true,
                  title: lg.nachricht_wirklich_löschen,
                  okText: lg.löschen,
                  content: `"${message.text}"`,
                  onOk: props.onDeleteClick,
                  cancelText: lg.abbrechen,
                  okType: "danger",
                });
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
});
