import { surchargeRepository } from "../../repositories/surchargeRepository";
import { IContract } from "../../shared/entities/IContract";
import { IHoliday } from "../../shared/entities/IHoliday";
import { IShift } from "../../shared/entities/IShift";
import { ISurcharge } from "../../shared/entities/ISurcharge";
import { ITracking } from "../../shared/entities/ITracking";
import { IUser } from "../../shared/entities/IUser";
import { getValidContract } from "../../shared/helpers/credit";
import { addSimpleDays } from "../../shared/helpers/dateHelpers";
import { tTimetoMinutes, minutesToTTime, getDuration, minutesToDuration } from "../../shared/helpers/timeHelpers";
import { getWeekDay, getWeekDayString } from "../../shared/helpers/weekDaysList";

const toMM = (time: string, plusDay: number = 0): number => {
  return Number(time.substr(0, 2)) * 60 + Number(time.substr(3, 5)) + plusDay * 1440;
};

const getOvelap = (xStart: number, xEnd: number, yStart: number, yEnd: number) => {
  return yStart < xEnd && yEnd > xStart ? Math.min(xEnd, yEnd) - Math.max(xStart, yStart) : 0;
};

export const passesShiftFilters = (surcharge: ISurcharge, s: IShift) => {
  return !(
    (surcharge.jobPositions && !surcharge.jobPositions[s.jobPositionId]) ||
    (surcharge.branches && !surcharge.branches[s.branchId]) ||
    (surcharge.labels && !surcharge.labels[s.workSpaceId || "x"]) ||
    (surcharge.addresses && !surcharge.addresses[s.addressId || "x"])
  );
};

export const isSurchargeStillValid = (surcharge: ISurcharge, date: string) => {
  return !((surcharge.validFrom && date < surcharge.validFrom) || (surcharge.validTo && date > surcharge.validTo));
};

export const isDayIncluded = (date: string, s: ISurcharge, isHoliday: boolean) => {
  return isHoliday
    ? s.onlyOnHolidays || (!s.exceptOnHolidays && s.weekdays?.[getWeekDayString(date)])
    : s.weekdays?.[getWeekDayString(date)] && !s.onlyOnHolidays;
};

export const passesUserFilter = (surcharge: ISurcharge, s: IShift, userTypeId?: string) => {
  return (
    !surcharge.excludingUsers?.[s.userId!] &&
    (surcharge.appliesTo === "all-users" ||
      (surcharge.appliesTo === "selected-users" && surcharge.includingUsers?.[s.userId!]) ||
      (surcharge.appliesTo === "users-of-type" && surcharge.userTypes?.[userTypeId || "x"]))
  );
};

export const getShiftSurchargeOverlaps = (
  _surchargeSpans: { startMM: number; endMM: number }[],
  _shiftSpans: { startMM: number; endMM: number }[]
) => {
  let overlap = 0;
  const surchargeSpans = _surchargeSpans.filter((span) => span.startMM < span.endMM);
  const shiftSpans = _shiftSpans.filter((span) => span.startMM < span.endMM);

  surchargeSpans.forEach((surchargeSp) => {
    shiftSpans.forEach((shiftSp) => {
      overlap += getOvelap(shiftSp.startMM, shiftSp.endMM, surchargeSp.startMM, surchargeSp.endMM);
    });
  });

  return overlap;
};

export const getSurchargeTimes = (
  surcharge: ISurcharge,
  s: IShift,
  t: ITracking | undefined,
  contractsByUser: { [userId: string]: IContract[] },
  holidayFinder: (date: string, branchId: string | undefined) => IHoliday | undefined
) => {
  // const shift = t || s;
  const shift = t ? { ...s, ...t } : s;
  const contract = getValidContract(contractsByUser[s.userId!], shift.date)!;

  if (
    (s.isNoShow && !t) ||
    !passesShiftFilters(surcharge, shift) ||
    !isSurchargeStillValid(surcharge, shift.date) ||
    !passesUserFilter(surcharge, shift, contract.userTypeId)
  ) {
    return 0;
  }

  // For this calculations we use the time Format MM: going from 0 minutes to 2880 Miutes,
  // Which starts with 0 at the beginning of dayOne and goes until the end of dayTwo.
  // Thats how we narrow down the calculation to the relevant timespan to calculate a final
  // duration of the effective surcharge

  const dayOne = s.date;
  const dayTwo = addSimpleDays(s.date, 1);
  const isDayOneHoliday = !!holidayFinder(dayOne, contract.mainBranchId);
  const isDayTwoHoliday = !!holidayFinder(dayTwo, contract.mainBranchId);
  const isSingleDayShift = shift.startTime <= shift.endTime;
  const shiftStartMM = toMM(shift.startTime);
  const shiftEndMM = shiftStartMM + getDuration({ ...shift, breakMinutes: 0 });
  const breakStartTime = shift.breakStartTime && shift.breakMinutes ? shift.breakStartTime : shiftStartMM;

  const isBreakStartOutOfRange = isSingleDayShift
    ? breakStartTime < shift.startTime || breakStartTime > shift.endTime
    : breakStartTime < shift.startTime && breakStartTime > shift.endTime;

  // Breaks logic
  let breakStartMM = shift.breakStartTime && shift.breakMinutes ? toMM(shift.breakStartTime) : shiftStartMM;
  isBreakStartOutOfRange && (breakStartMM = shiftStartMM);
  !isSingleDayShift && breakStartMM < shiftStartMM && (breakStartMM += 1440); // during a night-shift in this case the breakStart is in dayTwo
  let breakEndMM = breakStartMM + shift.breakMinutes;
  breakEndMM = breakEndMM > shiftEndMM ? shiftEndMM : breakEndMM; // safety check > if break exceeds the shiftEnd

  let shiftTimes: { startMM: number; endMM: number }[] = []; // worked timespan with the break in the middle
  let intervals: { startMM: number; endMM: number }[] = []; // surcharge timespans during dayOne and dayTwo

  breakStartMM !== shiftStartMM && shiftTimes.push({ startMM: shiftStartMM, endMM: breakStartMM });
  shiftTimes.push({ startMM: breakEndMM, endMM: shiftEndMM });

  if (isDayIncluded(dayOne, surcharge, isDayOneHoliday)) {
    let { startTime, endTime } = surcharge;
    startTime < endTime && intervals.push({ startMM: toMM(startTime), endMM: toMM(endTime) });
    startTime > endTime && intervals.push({ startMM: 0, endMM: toMM(endTime) });
    startTime > endTime && intervals.push({ startMM: toMM(startTime), endMM: toMM("24:00") });
  }

  if (isDayIncluded(dayTwo, surcharge, isDayTwoHoliday) && !isSingleDayShift) {
    let { startTime, endTime } = surcharge;
    startTime < endTime && intervals.push({ startMM: toMM(startTime, 1), endMM: toMM(endTime, 1) });
    startTime > endTime && intervals.push({ startMM: toMM("00:00", 1), endMM: toMM(endTime, 1) });
    startTime > endTime && intervals.push({ startMM: toMM(startTime, 1), endMM: toMM("24:00", 1) });
  }

  // if (shift.startTime === "22:00") {
  //   console.log(shift);
  //   console.log("isBreakStartOutOfRange", isBreakStartOutOfRange);
  //   console.log(intervals.map((i) => [minutesToDuration(i.startMM), minutesToDuration(i.endMM)]));
  //   console.log(shiftTimes.map((i) => [minutesToDuration(i.startMM), minutesToDuration(i.endMM)]));
  // }

  const duration = getShiftSurchargeOverlaps(intervals, shiftTimes);
  const fallsBelowMinDuration = duration < (surcharge.minDuration || 0);

  return fallsBelowMinDuration ? 0 : duration;
};
