import { Icon, Popover } from "antd";
import React from "react";
import "./styles.scss";

type Props = {};

export class RolesExplenationField extends React.PureComponent<Props, {}> {
  render() {
    return (
      <Popover
        trigger="hover"
        children={
          <div className="rolesExplenationBoxMain">
            <Icon type="info-circle" theme="filled" />
          </div>
        }
        content={
          <div className="rolesExplenationContentWrapper">
            <div className="rowItem">
              <div className="title">{lg.admin}</div>
              <div className="text">{lg.ein_admin_hat_volle_lese_und_schreiberechte}</div>
            </div>
            <div className="rowItem">
              <div className="title">{lg.manager}</div>
              <div className="text">
                {lg.ein_manager_kann_alles_bis_auf_system_einstellungen_ändern_und_mitarbeiter_anlegen_bearbeiten}
              </div>
            </div>
            <div className="rowItem">
              <div className="title">{lg.mitarbeiter}</div>
              <div className="text">{lg.mitarbeiter_könenn_ihre_eigenen_schichten_und_die_der_kollegen_einsehen}</div>
            </div>
          </div>
        }
      />
    );
  }
}
