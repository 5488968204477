import React from "react";
import { Icon } from "antd";
import "./styles.scss";
import { IShiftOverlap } from "../../../shared/entities/IShiftOverlap";
import { IUser } from "../../../shared/entities/IUser";

type Props = {};

export class ShiftHandOverIcon extends React.PureComponent<Props> {
  render() {
    return (
      <div className="handOverIconWrapper shiftIcon" data-rh-at="top" data-rh={lg.schichtabgabe_beantragt}>
        <Icon type="sync" />
      </div>
    );
  }
}
