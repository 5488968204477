import React from "react";
import "./styles.scss";

import "./styles.scss";

import { Icon } from "antd";
import { IAvailability } from "../../../../shared/entities/IAvailability";
import { WeekDay, WeekDays, getWeekDayLabels } from "../../../../shared/entities/IContract";
import { simpleDateToMoment } from "../../../../shared/helpers/timeHelpers";
import { getLocaleLang } from "../../../../helpers/dateFormatHelper";
import moment from "moment";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";

type Props = {
  availability: IAvailability;
  onClick: (av: IAvailability) => void;
};

export class AvailabilityEntry extends React.PureComponent<Props> {
  renderWeekDays = (selectedWeekDays: Array<WeekDay>) => {
    const lang = getLocaleLang();
    return (
      <div className="weekDaysInfo">
        <div className="label">{lg.jeden}</div>
        <div className="weekDaysWrapper">
          {WeekDays.filter((wd) => selectedWeekDays.includes(wd)).map((wd) => (
            <div key={wd} className="day">
              {getWeekDayLabels(lang)[wd]}
            </div>
          ))}
        </div>
      </div>
    );
  };

  renderStrandEndDAtes = (av: IAvailability) => {
    const statDateMom = simpleDateToMoment(av.startDate!);
    const endDateMom = simpleDateToMoment(av.endDate!);
    const currentYear = moment().year();
    const today = moment().format(SDateFormat);
    const startDateFormat = statDateMom.year() === currentYear ? "D. MMM" : "D. MMM YYYY";
    const endDateFormat = endDateMom.year() === currentYear ? "D. MMM" : "D. MMM YYYY";
    const farFuture = moment().add(3, "months").format(SDateFormat);
    const showStartDate = av.isSingleDay || av.startDate >= today;
    const showEndDAte = av.endDate && av.startDate !== av.endDate && av.endDate < farFuture;

    return (
      (showStartDate || showEndDAte) && (
        <div className="fb datesWrapper row" style={{ marginRight: 10 }}>
          {showStartDate && (
            <div className="fb row">
              {!av.isSingleDay && <div className="prefix">{lg.ab} </div>}
              <div className="date">{statDateMom.format(startDateFormat)}</div>
            </div>
          )}
          {showEndDAte && (
            <>
              <div className="fb col separator">bis</div>
              <div className="fb row">
                <div className="date">{endDateMom.format(endDateFormat)}</div>
              </div>
            </>
          )}
        </div>
      )
    );
  };

  renderTimes = (av: IAvailability) => (
    <div className="fb row timesWrapper">
      <div className="fb col iconWrapper">
        <Icon type="clock-circle" />
      </div>
      <div className="fb col">{av.startTime}</div>
      <div className="fb col separator">-</div>
      <div className="fb col">{av.endTime}</div>
    </div>
  );

  render() {
    const av = this.props.availability;
    return (
      <div className="availabilityEntryMain" onClick={() => this.props.onClick(this.props.availability)}>
        <div className="leftPart">
          <div className={`availableTag ${av.isAvailable ? "" : "isUnavailable"}`}>
            {!av.isAvailable ? lg.unverfügbar : lg.verfügbar}
          </div>
        </div>
        <div className="mitPart">
          {this.renderStrandEndDAtes(av)}
          {av.startDate !== av.endDate && av.weekDays.length < 7 ? (
            this.renderWeekDays(av.weekDays!)
          ) : av.isSingleDay ? null : (
            <div className="label">{lg.jeden_tag}</div>
          )}
        </div>
        <div className="rightPart">
          {av.isWholeDay ? <div className="wholeDayLabel"> {lg.ganztägig} </div> : this.renderTimes(av)}
        </div>
      </div>
    );
  }
}
