import React from "react";

import { TimeField } from "../TimeField/TimeField";
import "./styles.scss";
import { Input } from "antd";
import { InputProps } from "antd/lib/input";

type Props = {
  value?: string;
  onValueChange: (time: string | undefined) => void;
  disabled?: boolean;
  isDuration?: boolean;
  placeholder?: string;
} & InputProps;

export default class AvTimeInput extends React.PureComponent<Props, {}> {
  static defaultProps = { maxLength: 6 };

  render() {
    const { value, onValueChange, disabled, isDuration, placeholder, ...otherProps } = this.props;

    return (
      <TimeField
        value={(value as string) || ""}
        onChange={onValueChange}
        isDuration={isDuration}
        render={(sProps: any) => (
          <Input
            className="TimeFieldInputFieldMain"
            placeholder={placeholder || "00:00"}
            {...sProps}
            {...(otherProps as InputProps)}
            disabled={disabled}
          />
        )}
      />
    );
  }
}
