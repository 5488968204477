import React, { useState } from "react";
import _ from "lodash";
import { Checkbox, Button, Radio, Icon } from "antd";
import "./styles.scss";
import TZModal from "../../../../components/TZModal/TZModal";
import { useDispatch } from "react-redux";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import { AppState } from "../../../../types/AppState";
import {
  ReportColumns,
  setReportColumns,
  ReportFilterData,
  ReportType,
  updateReportMasterDataColumns,
} from "../../../../actions/reporting";
import { useSelector } from "../../../../helpers/redux";
import { closeModal } from "../../../../actions/modal";
import { updateReportDataTypes, ReportDataTypes } from "../../../../reducers/ui/reportSettings/reportDataTypes";
import { AbsenceEarning } from "../../../../shared/entities/IAbsenceType";
import { WeekDay, getWeekDayLabels, WeekDays } from "../../../../shared/constants/WeekDays";
import cn from "classnames";
import { updateReportExcludedWeekDays } from "../../../../reducers/ui/reportSettings/reportExcludedWeekDays";
import { TimeFormat, updateReportTimeFormat } from "../../../../reducers/ui/reportSettings/reportTimeFormat";
import { getLocaleLang } from "../../../../helpers/dateFormatHelper";
import { arrayToMap } from "../../../settings/rosterSettings/SurchargesSection/SurchargeModal/SurchargeModal";
import { masterDataFields } from "../../../Users/UserDetailsModal/MasterDataTab/MasterDataTab";
import { MasterDataFormField } from "../../../../shared/entities/IRosterSettings";
import { selectHashtags } from "../../../../selectors/hashtagSelector";

type Props = {
  reloadData: () => void;
};
//
export const ReportColumnsModal = (props: Props) => {
  const dispatch = useDispatch<DispFn>();
  const reportType = useSelector((s) => s.ui.shifts.reporting.reportType);
  const surcharges = useSelector((s) => s.data.surcharges);
  const isV2 = useSelector((s) => s.data.tenantInfo.isV2);
  const hashtags = useSelector(selectHashtags);
  const rosterSetting = useSelector((s) => s.data.rosterSettings[0]);
  const timeClockSettings = useSelector((s) => s.data.timeClockSettings[0]);
  const reportDataTypes = useSelector((s) => s.ui.reportSettings.reportDataTypes);
  const reportTimeFormat = useSelector((s) => s.ui.reportSettings.reportTimeFormat);
  const reportMasterDataColumns = useSelector((s) => s.ui.reportSettings.reportMasterDataColumns);
  const excludedWeekDays = useSelector((s) => s.ui.reportSettings.reportExcludedWeekDays);
  const absenceTypes = useSelector((s) => s.data.absenceTypes);
  const columns = useSelector((s: AppState) => s.ui.reportSettings.reportColumns) as ReportColumns;
  const hasUsersWithStaffNumber = useSelector((s) => s.data.userDetails).some((u) => u.employNum);
  const customMasterDataFields = rosterSetting.customMasterDataFields || {};
  const canEnterBreakStartTime = rosterSetting.canEnterBreakStartTime;
  const shiftsNeedToBeClocked = timeClockSettings.shiftsNeedToBeClocked;
  const showBreakIntervallsOption = canEnterBreakStartTime || shiftsNeedToBeClocked;
  const showWagesOption = useSelector((s) => s.data.rosterSettings[0].wagesCanBeEntered);

  const [masterDataExpanded, setMasterDataExpanded] = useState(false);
  const [dataTypesExpanded, setDataTypesExpanded] = useState(reportType === ReportType.user);

  const toggleColumn = (key: keyof ReportColumns, doReloadData?: boolean) => {
    dispatch(setReportColumns({ ...columns, [key]: !columns[key] }));
    doReloadData && props.reloadData();
  };

  const renderCheckbox = (key: keyof ReportColumns, label: string, doReloadData?: boolean) => (
    <Checkbox
      checked={columns[key]}
      onChange={(e) => toggleColumn(key, doReloadData)}
      style={{ marginLeft: 0 }}
      key={key}
    >
      {label}
    </Checkbox>
  );

  const renderDataTypeCheckbox = (key: keyof ReportDataTypes, label: string) => (
    <Checkbox
      checked={!!reportDataTypes[key]}
      onChange={() => toggleDataType(key)}
      children={label}
      style={{ marginLeft: 0 }}
    />
  );

  const toggleAbsenceType = (absenceTypeId: string) => {
    const _reportDataTypes = { ...reportDataTypes } as ReportDataTypes;
    const isRemoving = reportDataTypes.absenceTypeIds.includes(absenceTypeId);
    _reportDataTypes.absenceTypeIds = isRemoving
      ? _reportDataTypes.absenceTypeIds.filter((id) => id !== absenceTypeId)
      : [..._reportDataTypes.absenceTypeIds, absenceTypeId];
    dispatch(updateReportDataTypes(_reportDataTypes));
    props.reloadData();
  };

  const toggleDataType = async (dataType: keyof ReportDataTypes) => {
    dispatch(
      updateReportDataTypes({
        ...reportDataTypes,
        [dataType]: !reportDataTypes[dataType],
      })
    );
    props.reloadData();
  };

  const isShiftReport = reportType === ReportType.shift;
  const isUserReport = reportType === ReportType.user;

  const toggleWeekDay = (wd: WeekDay) => {
    const isSelected = excludedWeekDays.includes(wd);
    const next = isSelected ? excludedWeekDays.filter((_wd) => wd !== _wd) : [...excludedWeekDays, wd];
    dispatch(updateReportExcludedWeekDays(next));
  };

  const existingSurchargeTypes = arrayToMap(surcharges.map((s) => s.type));

  const renderSurchargeCheckboxes = () => [
    existingSurchargeTypes["night"] && renderCheckbox("nightSurcharge", lg.Nachtzuschlag, true),
    existingSurchargeTypes["holiday"] && renderCheckbox("holidaySurcharge", lg.Feiertagszuschalg, true),
    existingSurchargeTypes["sunday"] && renderCheckbox("sundaySurcharge", lg.Sonntagszuschlag, true),
    existingSurchargeTypes["custom"] && renderCheckbox("customSurcharge", lg.Extrazuschlag, true),
  ];

  const allMasterDataFields = Object.values({ ...masterDataFields, ...customMasterDataFields });

  const renderMasterDataCheckbox = (field: MasterDataFormField) => (
    <Checkbox
      onChange={() => dispatch(updateReportMasterDataColumns({ [field.id]: !reportMasterDataColumns[field.id] }))}
      children={field.label}
      checked={!!reportMasterDataColumns[field.id]}
      style={{ marginLeft: 0 }}
    />
  );

  return (
    <TZModal className="exportReportModalMain">
      <TZModal.Body>
        <div className="title">{lg.sichtbare_spalten}:</div>
        {isShiftReport && (
          <div className="content fb row">
            <div className="col1 fb col">
              {renderCheckbox("date", lg.datum)}
              {renderCheckbox("userName", lg.mitarbeiter)}
              {renderCheckbox("jobPositionName", lg.rolle)}
              {renderCheckbox("branchName", lg.standort)}
              {renderCheckbox("workSpaceName", lg.label)}
              {!isV2 && renderCheckbox("shiftTime", lg.planzeit)}
              {renderCheckbox("trackedTime", lg.zeiterfassung)}
              {renderCheckbox("duration", lg.dauer)}
            </div>
            <div className="col1 fb col">
              {renderCheckbox("shiftId", "Id")}
              {hasUsersWithStaffNumber && renderCheckbox("staffNumber", lg.personalnummer)}
              {renderCheckbox("addressName", lg.adresse)}
              {renderCheckbox("hashtags", lg.hashtags)}
              {renderCheckbox("comment", lg.kommentar)}
              {showBreakIntervallsOption && renderCheckbox("breakIntervalls", lg.pausenintervall)}
              {renderSurchargeCheckboxes()}
              {showWagesOption && renderCheckbox("wage", lg.lohn)}
            </div>
          </div>
        )}

        <div className="extraDataContent">
          {isShiftReport && (
            <div
              className={"head fb row expandable " + cn({ expanded: dataTypesExpanded })}
              onClick={() => setDataTypesExpanded(!dataTypesExpanded)}
            >
              {lg.datentypen}
              <div className="iconWrapper">
                <Icon type={dataTypesExpanded ? "caret-up" : "caret-down"} />
              </div>
            </div>
          )}
          {dataTypesExpanded && (
            <div className="extraBody fb row">
              <div className="col1 fb col">
                {renderDataTypeCheckbox("shifts", isV2 ? lg.zeiterfassung : lg.schichten)}
                {renderDataTypeCheckbox("creditCorrections", lg.korrekturen)}
                {renderDataTypeCheckbox("holidays", lg.feiertage)}
                {isUserReport && renderDataTypeCheckbox("quota", lg.sollstunden)}
                {isUserReport && renderDataTypeCheckbox("overtime", lg.überstunden)}
                {isUserReport && renderDataTypeCheckbox("hourAccount", lg.stundenkonto)}
              </div>
              <div className="col1 fb col">
                {isUserReport && renderCheckbox("wage", lg.lohn, true)}
                {isUserReport && renderSurchargeCheckboxes()}
                {absenceTypes
                  .filter((a) => reportDataTypes.absenceTypeIds.includes(a.id) || a.earning !== AbsenceEarning.none) // the isChecked condition is just for backward compatibility
                  .map((a) => (
                    <Checkbox
                      checked={reportDataTypes.absenceTypeIds.includes(a.id)}
                      onChange={() => toggleAbsenceType(a.id)}
                      children={a.name}
                      style={{ marginLeft: 0 }}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>

        <div className="extraDataContent">
          <div
            className={"head fb row expandable " + cn({ expanded: masterDataExpanded })}
            onClick={() => setMasterDataExpanded(!masterDataExpanded)}
          >
            {lg.stammdaten}
            <div className="iconWrapper">
              <Icon type={masterDataExpanded ? "caret-up" : "caret-down"} />
            </div>
          </div>

          {masterDataExpanded && (
            <div className="extraBody fb col">
              <div className="fb row exportInfo ">Diese Daten erscheinen im CSV / EXCEL export:</div>
              <div className="fb row">
                <div className="col1 fb col">
                  {allMasterDataFields
                    .filter((a, i) => i < allMasterDataFields.length / 2)
                    .map((field) => renderMasterDataCheckbox(field))}
                </div>
                <div className="col1 fb col">
                  {allMasterDataFields
                    .filter((a, i) => i >= allMasterDataFields.length / 2)
                    .map((field) => renderMasterDataCheckbox(field))}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="timeFormatRow">
          <div className="label">{lg.zeitformat}</div>
          <Radio.Group onChange={(e) => dispatch(updateReportTimeFormat(e.target.value))} value={reportTimeFormat}>
            <Radio value={TimeFormat.DecimalHours}>{lg.stunden_dezimal}</Radio>
            <Radio value={TimeFormat.HoursAndMinutes}>
              {lg.std}:{lg.min}
            </Radio>
          </Radio.Group>
        </div>
        <div className="weekDaysSection">
          <div className="label">{lg.wochentage}:</div>
          <div className="weekDaysContent fb row">
            {WeekDays.map((wd) => (
              <div
                className={cn({ weekDay: true, isExcluded: excludedWeekDays.includes(wd) })}
                onClick={() => toggleWeekDay(wd)}
              >
                {getWeekDayLabels(getLocaleLang())[wd]}
              </div>
            ))}
          </div>
        </div>
        {/* <div className="buttonsWrapper">
          <Button
            children={lg.Speichern}
            onClick={() => {
              props.onFinish();
              dispatch(closeModal());
            }}
          />
        </div> */}
      </TZModal.Body>
    </TZModal>
  );
};
