import { Dispatch } from "redux";
import { AppState } from "../types/AppState";
import { selectSessionInfo } from "../selectors/SessionInfoSelector";
import moment from "moment";
import firebase from "firebase/compat/app";
import { LoopProtector } from "../frontend-core/LoopProtector";

export const setLastLogin = () => {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const accountId = state.data.auth.session?.accountId;
    if (!accountId) {
      return;
    }

    LoopProtector.check(dispatch);
    const db = firebase.database().ref();
    await db.child("accounts").child(accountId).child("lastLogin").set(moment().toISOString());
  };
};
