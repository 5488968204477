import { createSelector } from 'reselect';
import { AppState } from '../types/AppState';

export const selectRosterTemplates = createSelector(
  [
    (state: AppState) => state.ui.selectedBranch,
    (state: AppState) => state.data.rosterTemplates,
  ],
  (selectedBranch, rosterTemplates) =>
    rosterTemplates.filter((r) => r.branchId === selectedBranch)
);
