import moment, { Moment } from "moment";
import { createSelector } from "reselect";
import { environment } from "../env";
import { Tiers } from "../shared/constants/Tiers";
import { simpleDateToMoment } from "../shared/helpers/timeHelpers";
import { AppState } from "../types/AppState";

const NFTenants: string[] = [];

export type TierInfo = {
  isTrialMode: boolean;
  tier: Tiers;
  isAlwaysFreePremiumTenant: boolean;
  isCore: boolean;
  isBasic: boolean;
  isPro: boolean;
  daysUntilTrialEnd: number;
  unpaidSince?: string;
  unpaidSinceTwoWeeks?: boolean;
  unpaidSinceOneWeek?: boolean;
  isCoreWithoutProTrial: boolean;
};

const getTierByActiveStripeProductIds = (productIds: string[] = [], isV2?: boolean) => {
  if (productIds.includes(environment.getPremiumTierProductId(isV2))) {
    return Tiers.Pro;
  } else if (productIds.includes(environment.getLightTierProductId())) {
    return Tiers.Basic;
  } else {
    return Tiers.Core;
  }
};

export const selectCurrentTier = createSelector([(s: AppState) => s.data.tenantInfo], (tenantInfo) => {
  return getTierByActiveStripeProductIds(tenantInfo.activeStripeProductIds, tenantInfo.isV2);
});

/**
 * CHANGES NEED TO BE COPIED TO THE MOBILE REPO AS WELL!
 */

export const tierInfoSelector = createSelector([(s: AppState) => s.data.tenantInfo], (tenantInfo): TierInfo => {
  const tier = getTierByActiveStripeProductIds(tenantInfo.activeStripeProductIds, tenantInfo.isV2);
  let trialPeriodEnd: Moment;
  if (moment(tenantInfo.createdAt).isAfter(simpleDateToMoment("2021-02-02"))) {
    trialPeriodEnd = moment(tenantInfo.createdAt).add(15, "days");
  } else {
    trialPeriodEnd = moment(tenantInfo.createdAt).add(1, "month");
  }

  const isTrialMode = trialPeriodEnd.isAfter(moment()) && tier === Tiers.Core;
  const daysUntilTrialEnd = isTrialMode ? trialPeriodEnd.diff(moment(), "days") : 0;

  const isAlwaysFreePremiumTenant =
    moment(tenantInfo.createdAt).isBefore(simpleDateToMoment("2020-09-14")) && !NFTenants.includes(tenantInfo.id);

  const generalUnapidSinceValue = tenantInfo.unpaidSince || tenantInfo.unpaidDisputePaymentSince;

  return {
    isTrialMode,
    tier,
    isAlwaysFreePremiumTenant,
    isCore: tier === Tiers.Core,
    isBasic: tier === Tiers.Basic,
    isPro: tier === Tiers.Pro,
    daysUntilTrialEnd,
    unpaidSince: generalUnapidSinceValue,
    unpaidSinceTwoWeeks: generalUnapidSinceValue
      ? moment(generalUnapidSinceValue).isBefore(moment().add(-2, "weeks"))
      : false,
    unpaidSinceOneWeek: generalUnapidSinceValue
      ? moment(generalUnapidSinceValue).isBefore(moment().add(-1, "weeks"))
      : false,
    isCoreWithoutProTrial:
      !isTrialMode &&
      tier === Tiers.Core &&
      moment(tenantInfo.createdAt).isAfter(simpleDateToMoment("2020-09-14"), "day"),
  };
});
