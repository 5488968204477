import React from "react";
import { connect, batch } from "react-redux";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import { selectBranch } from "../../../actions/branchSelection";
import { setRosterTemplateMode } from "../../../actions/rosterTemplate";
import cn from "classnames";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import BusyWrapper from "../../../components/BusyWrapper/BusyWrapper";
import "./styles.scss";
import { busyInjector, BusyInjectorProps } from "../../../components/BusyInjector/BusyInjector";
import Page from "../../../components/Page/Page";
import {
  addShiftsOfRosterListener,
  addTrackingsOfRosterListener,
  addClockingsOfRosterListener,
} from "../../../actions/roster";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { selectVisibleBranches } from "../../../selectors/VisibleBranchesSelector";
import { RosterActionBarTemplateMode } from "./RosterActionBarTemplateMode/RosterActionBarTemplateMode";
import { RosterActionBar } from "./RosterActionBar/RosterActionBar";
import { RosterWeekGrid } from "./RosterWeekGrid/RosterWeekGrid";
import { updateCreditsOfRoster } from "../../../actions/creditActions/updateCreditsOfRoster";
import { RouteComponentProps } from "react-router";
import { RosterKanban } from "./RosterKanban/RosterKanban";
import RosterDayView from "./RosterDayGrid/RosterDayGrid";
import { RosterMonthGrid } from "./RosterMonthGrid/RosterMonthGrid";
import { closestWithAttribute } from "../../../helpers/general";
import { openModal } from "../../../actions/modal";
import UserDetailsModal from "../../Users/UserDetailsModal/UserDetailsModal";
import { RosterMode, setRosterMode } from "../../../reducers/ui/shifts/roster/rosterMode";
import { RosterType, setRosterType } from "../../../reducers/ui/shifts/roster/rosterType";
import { selectIsFreshTenant } from "../../../selectors/selectIsFreshTenant";
import { hideEmptyShiftRows } from "../../../actions/hiddenShiftRows";
import { toggleShiftTemplatesDrawer } from "../../../reducers/ui/shifts/roster/isShiftTemplatesOpen";
import { selectGroupByJobPosition } from "../../../selectors/groupByJobPositionSelector";
import { RosterListV2 } from "./RosterListV2/RosterListV2";

const mapStateToProps = (state: AppState) => {
  const rosterTemplateMode = state.ui.shifts.roster.rosterTemplateMode;
  return {
    selectedBranchId: state.ui.selectedBranch,
    sessionInfo: selectSessionInfo(state),
    rosterTemplateMode,
    selectedWeek: !rosterTemplateMode.active
      ? state.ui.shifts.roster.selectedWeek
      : rosterTemplateMode?.rosterTemplate?.shiftsStoredAtDate,
    selectedDay: state.ui.shifts.roster.selectedDay,
    selectedMonth: state.ui.shifts.roster.selectedMonth,
    customMonthStartDay: state.ui.general.rosterCustomMonthStartDay, // this is rarely used if the user wants the month-roster to go from e.g: 16.SEP > 15.NOV
    visibleBranches: selectVisibleBranches(state),
    rosterMode: state.ui.shifts.roster.rosterMode,
    rosterType: state.ui.shifts.roster.rosterType,
    rosterWillRerender: state.ui.shifts.roster.rosterWillRerender,
    isMouseOverUserCells: state.ui.shifts.roster.isMouseOverUserCells,
    isGroupedByJobPos: selectGroupByJobPosition(state),
    isFreshTenant: selectIsFreshTenant(state),
    emptyShiftRowsHidden: state.ui.shifts.roster.emptyShiftRowsHidden,
    users: state.data.users,
    isTemplatesSideBarOpen: state.ui.shifts.roster.isShiftTemplatesOpen,
    isV2: state.data.tenantInfo.isV2,
  };
};

type OwnProps = {} & RouteComponentProps;
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class RosterPage extends React.PureComponent<Props> {
  changeBranch = (branchId: string) => {
    this.props.setLoading("main", true); // TO jump over initial lag
    setTimeout(() => {
      this.props.dispatch(selectBranch(branchId === "av-all-branches" ? null : branchId));
    }, 20);
  };

  async componentDidMount() {
    document.addEventListener("click", this.clickDetected);
    const { visibleBranches, selectedBranchId, dispatch, isFreshTenant, isV2, sessionInfo, rosterMode, rosterType } =
      this.props;

    if (selectedBranchId !== null && !visibleBranches.find((b) => b.id === selectedBranchId)) {
      // to make sure that the selectedBranch is always defined and a visibleBranch
      dispatch(selectBranch(visibleBranches[0].id));
    }

    if (isV2 && !sessionInfo.hasManagerPermissions()) {
      (selectedBranchId || rosterMode !== RosterMode.Month || rosterType !== RosterType.List) &&
        batch(() => {
          dispatch(selectBranch(null)); // in V2 employees always see all branches
          dispatch(setRosterMode(RosterMode.Month)); // in V2 employees just need the monthMode
          dispatch(setRosterType(RosterType.List)); // in V2 employees just need the listType
        });
    }

    if (isFreshTenant && this.props.emptyShiftRowsHidden) {
      // it happens that a fresh user does not notice that he activated hideEmptyRows
      dispatch(hideEmptyShiftRows(false));
    }

    try {
      await this.props.load(this.fetchData(), "main");
    } catch (e) {
      // workaround, because react swallows async errors from compoennt-did-mount
      console.error(e);
    }
  }

  getUserIdsOfBranch = (nextProps: Props) => {
    return nextProps.users
      .filter((u) => !nextProps.selectedBranchId || u.branchIds.includes(nextProps.selectedBranchId))
      .map((u) => u.id);
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.clickDetected);
    // The template edit mode needs to be turned off, when the user routes
    // away from the roster page
    if (this.props.rosterTemplateMode.active) {
      this.props.dispatch(setRosterTemplateMode(false));
    }
    if (this.props.isTemplatesSideBarOpen) {
      this.props.dispatch(toggleShiftTemplatesDrawer());
    }
  }

  clickDetected = (e: any) => {
    if (this.props.sessionInfo.isAdmin()) {
      const userCellNode = closestWithAttribute(e.target, "data-type", "user-cell");
      const userId = userCellNode?.getAttribute("data-user-id");

      if (userId) {
        this.props.dispatch(openModal(UserDetailsModal, { userId }));
      }
    }
  };

  fetchData = async (props = this.props) => {
    const { dispatch, sessionInfo, isV2 } = this.props;
    const canManage = sessionInfo.hasManagerPermissions();
    const creditUserIds = canManage ? this.getUserIdsOfBranch(props) : [sessionInfo.user.id];
    const isTemplateMode = props.rosterTemplateMode.active;

    !isTemplateMode && dispatch(updateCreditsOfRoster(creditUserIds));
    // !isTemplateMode && !canManage && dispatch(fetchAbsencesOfRoster()); // for managers/admins all absences already have listener set in the preloader

    isV2 ? await dispatch(addClockingsOfRosterListener()) : await dispatch(addShiftsOfRosterListener());

    if (!isV2 && !isTemplateMode && props.rosterMode !== RosterMode.Month) {
      dispatch(addTrackingsOfRosterListener());
      dispatch(addClockingsOfRosterListener());
    }
  };

  async UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.rosterWillRerender !== nextProps.rosterWillRerender) {
      this.props.setLoading("fake", true);

      setTimeout(() => {
        this.props.setLoading("fake", false);
      }, 50);
    }

    if (
      this.props.selectedDay !== nextProps.selectedDay ||
      this.props.selectedWeek !== nextProps.selectedWeek ||
      this.props.selectedMonth !== nextProps.selectedMonth ||
      this.props.customMonthStartDay !== nextProps.customMonthStartDay ||
      this.props.selectedBranchId !== nextProps.selectedBranchId ||
      this.props.rosterMode !== nextProps.rosterMode
    ) {
      this.props.setLoading("main", true);
      await this.fetchData(nextProps);
      setTimeout(() => this.props.setLoading("main", false), 100);
    }
  }

  render() {
    return (
      <>
        <Page>
          <div
            className={cn({
              rosterMain: true,
              rosterTemplateMode: this.props.rosterTemplateMode.active,
              isMouseOverUserCells: this.props.isMouseOverUserCells,
              isGroupedByJobPos: this.props.isGroupedByJobPos,
            })}
          >
            <div className="aCenter jBetween row fb">
              {this.props.rosterTemplateMode.active ? (
                <RosterActionBarTemplateMode />
              ) : (
                <RosterActionBar changeBranch={this.changeBranch} />
              )}
            </div>
            {(!this.props.rosterTemplateMode.active || this.props.rosterTemplateMode.rosterTemplate) && (
              // <DndProvider backend={HTML5Backend}>
              <BusyWrapper isBusy={this.props.isLoading()} style={{ height: "100%" }}>
                {this.props.rosterMode === RosterMode.Week && this.props.rosterType === RosterType.Grid && (
                  <RosterWeekGrid startDate={this.props.selectedWeek!} isRosterLoading={this.props.isLoading("main")} />
                )}

                {this.props.rosterMode === RosterMode.Week && this.props.rosterType === RosterType.Kanban && (
                  <RosterKanban startDate={this.props.selectedWeek!} />
                )}
                {this.props.rosterMode === RosterMode.Day && this.props.rosterType === RosterType.Grid && (
                  <RosterDayView selectedDay={this.props.selectedDay!} />
                )}
                {this.props.rosterMode === RosterMode.Month && this.props.rosterType === RosterType.Grid && (
                  <RosterMonthGrid isRosterLoading={this.props.isLoading("main")} />
                )}
                {this.props.rosterType === RosterType.List && <RosterListV2 selectedDay={this.props.selectedDay!} />}
              </BusyWrapper>
              // </DndProvider>
            )}
            {this.props.rosterTemplateMode.active && !this.props.rosterTemplateMode.rosterTemplate && (
              <div className="noTemplatesBox">{lg.für_diesen_standort_gibt_es_derzeitig_keine_vorlagen}</div>
            )}
          </div>
        </Page>
      </>
    );
  }
}

export default withErrorBoundary(
  connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(busyInjector(RosterPage))
);
