import React from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../actions/modal";
import { IntroVideoModal } from "../../components/modals/IntroVideoModal/IntroVideoModal";
import "./styles.scss";
import { Icon } from "antd";
import { openCrisp } from "../../helpers/crisp";
import { getLocaleLang } from "../../helpers/dateFormatHelper";
import { useSelector } from "../../helpers/redux";
import { tierInfoSelector } from "../../selectors/TierInfoSelector";

export const HelpPage = () => {
  const dispatch = useDispatch();
  const tierInfo = useSelector(tierInfoSelector);
  const canUseSupportChat = !tierInfo.isCoreWithoutProTrial;
  const isV2 = useSelector((s) => s.data.tenantInfo.isV2);
  const domain = isV2 ? "https://www.zeitguru.de" : "https://www.aplano.de";

  return (
    <div className="fb column grow helpPage">
      <div className="fb title">{lg.hilfe}</div>
      <div className="fb row">
        {getLocaleLang() === "de" && (
          <a className="helpItem" href={`${domain}/faq`} target="_blank">
            <Icon type="question" />
            <span>{lg.häufige_fragen}</span>
          </a>
        )}
        {getLocaleLang() === "de" && (
          <div className="helpItem" onClick={() => dispatch(openModal(IntroVideoModal, {}))}>
            <Icon type="youtube" />
            <span>{lg.erklärvideos}</span>
          </div>
        )}
        {canUseSupportChat && (
          <div
            className="helpItem"
            id="crispHelpItem"
            onClick={() => {
              openCrisp();
            }}
          >
            <Icon type="mail" />
            <span>{lg.support_chat}</span>
          </div>
        )}
      </div>
    </div>
  );
};
