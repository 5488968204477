import { Input, Checkbox, Icon, InputNumber, Button } from "antd";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import TZModal from "../../../../components/TZModal/TZModal";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import cn from "classnames";
import "./styles.scss";
import AvOption from "../../../../components/AvOption/AvOption";
import { useSelector } from "../../../../helpers/redux";
import { rosterSettingsRepository } from "../../../../repositories/rosterSettingsRepository";
import { closeModal } from "../../../../actions/modal";

export const EditShiftsIntervalModal = (props) => {
  const dispatch = useDispatch<DispFn>();
  const rosterSetting = useSelector((s) => s.data.rosterSettings[0]);
  const [minDays, setMinDays] = useState<number>(0);
  const [minPositive, setMinPositive] = useState<boolean>(true);
  const [maxDays, setMaxDays] = useState<number>(0);
  const [maxPositive, setMaxPositive] = useState<boolean>(true);

  useEffect(() => {
    const usersCanAddEditOwnShifts = rosterSetting.usersCanAddEditOwnShifts;
    const min = usersCanAddEditOwnShifts ? usersCanAddEditOwnShifts.min : -7;
    const max = usersCanAddEditOwnShifts ? usersCanAddEditOwnShifts.max : 60;

    setMinDays(Math.abs(min));
    setMinPositive(min >= 0);

    setMaxDays(Math.abs(max));
    setMaxPositive(max >= 0);
  }, []);

  const saveClicked = () => {
    const min = minDays * (minPositive ? 1 : -1);
    const max = maxDays * (maxPositive ? 1 : -1);

    dispatch(rosterSettingsRepository.update({ ...rosterSetting, usersCanAddEditOwnShifts: { min, max } }));
    dispatch(closeModal());
  };

  return (
    <div>
      <TZModal>
        <TZModal.Head>Zulässiger Zeitraum</TZModal.Head>
        <TZModal.Body>
          <div className="editShiftIntervalModalMain">
            <div className="fb row description">
              Mitarbeiter können nur in folgendem Zeitraum Schichten erstellen und editieren.
            </div>
            <div className="fb row ruleRow">
              <div className="text left init">Zeitraum Start: </div>
              <div className="text left">Heute </div>
              <AvOption
                value={minPositive}
                options={[
                  { value: true, label: "+" },
                  { value: false, label: "-" },
                ]}
                buttonStyle="solid"
                onChange={(isPositive) => setMinPositive(isPositive)}
                style={{ marginRight: 8 }}
              />
              <InputNumber value={minDays} onChange={(days) => setMinDays(days || 0)} style={{ width: 58 }} />
              <div className="text unit">Tage</div>
            </div>
            <div className="fb row">
              <div className="text left init">Zeitraum Ende: </div>
              <div className="text left">Heute </div>
              <AvOption
                value={maxPositive}
                options={[
                  { value: true, label: "+" },
                  { value: false, label: "-" },
                ]}
                buttonStyle="solid"
                onChange={(isPositive) => setMaxPositive(isPositive)}
                style={{ marginRight: 8 }}
              />
              <InputNumber value={maxDays} onChange={(days) => setMaxDays(days || 0)} style={{ width: 58 }} />
              <div className="text unit">{lg.Tage}</div>
            </div>
          </div>
        </TZModal.Body>
        <TZModal.Footer>
          <Button id="save-branch" type="primary" onClick={saveClicked} children={lg.Speichern} />
        </TZModal.Footer>
      </TZModal>
    </div>
  );
};
