import { ReportType, setReportFilterData } from "../../../../actions/reporting";
import { DispFn } from "../../../../frontend-core/types/thunkTypes";
import { AppState } from "../../../../types/AppState";

export const reportTypeActionTypes = {
  SET: "@@AV/SET_REPORT_TYPE",
};

export const setReportType = (payload: ReportType) => (dispatch: DispFn, getState: () => AppState) => {
  const filters = getState().ui.shifts.reporting.filters;
  // need to remove all other filters like branchFilter,labelFilter,userFilter > otherwise they prefilter data in ReportType.user
  dispatch(setReportFilterData({ filterStartDate: filters.filterStartDate, filterEndDate: filters.filterEndDate }));
  dispatch({ type: reportTypeActionTypes.SET, payload });
};

const initialState: ReportType = ReportType.shift;

export const reportTypeReducer = (state = initialState, a: any): ReportType =>
  a.type === reportTypeActionTypes.SET ? a.payload : state;
