import { Button, Drawer, Icon, notification, Popover } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { closeModal, openModal } from "../../../../../actions/modal";
import { pastelColors } from "../../../../../frontend-core/helpers/colors";
import { DispFn } from "../../../../../frontend-core/types/thunkTypes";
import WorkSpaceEditor from "../../../../settings/branches/branchPopup/workspaceEditor/WorkSpaceEditor";
import WorkSpaceForm from "../../../../settings/branches/branchPopup/workspaceEditor/workspaceForm/WorkSpaceForm";
import { workSpaceRepository } from "../../../../../repositories/workSpaceRepository";
import { IWorkSpace } from "../../../../../shared/entities/IWorkSpace";
import { busyInjector, BusyInjectorProps } from "../../../../../components/BusyInjector/BusyInjector";
import TZModal from "../../../../../components/TZModal/TZModal";
import "./styles.scss";
import { RosterFilterPopover } from "../../components/RosterFilterPopover/RosterFilterPopover";
import { useSelector } from "../../../../../helpers/redux";
import cn from "classnames";
import { IShift } from "../../../../../shared/entities/IShift";
import { toggleShiftTemplatesDrawer } from "../../../../../reducers/ui/shifts/roster/isShiftTemplatesOpen";

export const ShiftTemplatesButton = React.memo((props: {}) => {
  const dispatch = useDispatch();
  const selectedBranch = useSelector((s) => s.ui.selectedBranch);
  const isActive = useSelector((s) => s.ui.shifts.roster.isShiftTemplatesOpen);

  const toggleActiveShiftTemp = () => {
    if (!selectedBranch) {
      notification.info({ message: lg.bitte_standort_wählen });
      return;
    }

    dispatch(toggleShiftTemplatesDrawer());
  };

  return (
    <div className="shiftTemplateButtonWrapper">
      <div
        className={cn({ shiftTemplateButtonMain: true, isActive })}
        data-rh={lg.schicht_vorlagen}
        onClick={() => toggleActiveShiftTemp()}
      >
        <Icon type="diff" style={{ fontSize: 20 }} />
      </div>
    </div>
  );
});
