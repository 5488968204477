import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../types/AppState";
import { DispatchBaseProps } from "../../../../frontend-core/types/DispatchBaseProps";
import AvFormField from "../../../../components/AvFormField/AvFormField";
import { Input, Checkbox, Button, notification, InputNumber, Icon } from "antd";
import { branchRepository } from "../../../../repositories/branchRepository";
import { userRepository } from "../../../../repositories/userRepository";
import TZModal from "../../../../components/TZModal/TZModal";
import * as Sentry from "@sentry/browser";
import { closeModal, openModal } from "../../../../actions/modal";
import { busyInjector, BusyInjectorProps } from "../../../../components/BusyInjector/BusyInjector";

import { selectActiveUsers, selectActiveUsersFull } from "../../../../selectors/ActiveUserSelectors";
import { SDateFormat } from "../../../../shared/helpers/SimpleTime";
import { IWorkSpace } from "../../../../shared/entities/IWorkSpace";
import { IBranch } from "../../../../shared/entities/IBranch";
import { IUser } from "../../../../shared/entities/IUser";
import moment from "moment";
import "./styles.scss";
import _, { filter } from "lodash";
import { IRuleSpecification } from "../../../../shared/entities/IRuleSpecification";
import { isRuleApplyingToUser } from "../../../../shared/helpers/settingsHelpers";
import { shiftRepository } from "../../../../repositories/shiftRepository";
import { FarFuture } from "../../../Users/UserDetailsModal/UserGeneralTab/UserGeneralTab";
import { selectUsersFull } from "../../../../selectors/usersFullSelector";
import { selectUserFullMap } from "../../../../selectors/userFullMapSelector";
import { BasicSelect } from "../../../../components/BasicSelect/BasicSelect";
import { selectVisibleBranches } from "../../../../selectors/VisibleBranchesSelector";

const low = (str: string) => (str ? str.toLowerCase().trim() : "");

const mapStateToProps = (state: AppState, props: OwnProps) => {
  return {
    users: selectUsersFull(state),
    userMapFull: selectUserFullMap,
    activeUsers: selectActiveUsersFull(state, moment().format(SDateFormat)),
    activeBranches: selectVisibleBranches(state),
  };
};

type OwnProps = {
  selectedUserIds: string[];
  toggleUser: (userId: string) => void;
  selectList: (userIds: string[]) => void;
  showBranchFilter?: boolean;
  showCount?: boolean;
};

type State = { userNameFilter: string; branchFilter?: string };
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps & BusyInjectorProps;

class BranchPopup extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      userNameFilter: "",
    };
  }

  render() {
    const { selectedUserIds, toggleUser, activeUsers, activeBranches, showBranchFilter } = this.props;
    const { branchFilter } = this.state;

    const filteredUsers = activeUsers
      .filter((u) => low(u.name).includes(low(this.state.userNameFilter)))
      .filter((u) => !branchFilter || u.branchIds.includes(branchFilter));

    const sortedUsers = _.sortBy(filteredUsers, (u) => u.name);

    const isAllSelected = selectedUserIds.length === filteredUsers.length;

    return (
      <div className="userPickerListMain">
        <div className="fb searchInputWrapper">
          <input
            type="text"
            placeholder={lg.filtern}
            className="lightInput"
            value={this.state.userNameFilter}
            onChange={(e) => this.setState({ userNameFilter: e.target.value })}
          />
        </div>
        {activeBranches.length > 1 && showBranchFilter && (
          <div className="branchFilterWrapper">
            <BasicSelect
              id="user-filter-branch-select"
              value={branchFilter}
              onChange={(id?: string) => this.setState({ branchFilter: id })}
              options={activeBranches.map((o) => ({ label: o.name, value: o.id }))}
              placeholder={lg.aus_allen_standorten}
              allowClear
              size="small"
            />
          </div>
        )}
        <div className="userListWrapper">
          {sortedUsers.map((user) => {
            return (
              <div key={user.id} className="fb userRow" onClick={() => toggleUser(user.id)}>
                <div className="checkBoxWrapper">
                  <Checkbox checked={selectedUserIds.includes(user.id)} />
                </div>
                <div className="userName">{user.name}</div>
              </div>
            );
          })}
        </div>
        {activeUsers.length > 15 && (
          <div
            className="selectAllBtnWrapper"
            onClick={() => this.props.selectList(isAllSelected ? [] : filteredUsers.map((u) => u.id))}
          >
            {this.props.showCount && (
              <div className="count" data-rh={lg.selektiert}>
                {selectedUserIds.length}
              </div>
            )}
            {isAllSelected ? lg.alle_deselektieren : lg.alle_selektieren}
          </div>
        )}
      </div>
    );
  }
}

export default connect<StoreProps, DispatchBaseProps, OwnProps, AppState>(mapStateToProps)(busyInjector(BranchPopup));
