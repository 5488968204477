import React from "react";
import "./styles.scss";
import { getDuration } from "../../../shared/helpers/timeHelpers";

type Props = {
  startTime: string;
  endTime?: string;
  breakMinutes?: number;
  showBreakes?: boolean;
  showDuration?: boolean;
  hideEndTime?: boolean;
  isV2?: boolean;
};

export class ShiftTimeFrame extends React.PureComponent<Props> {
  calculatedShiftDuration = () => {
    const { startTime, endTime, breakMinutes } = this.props;
    const durationInMinutes = getDuration({ startTime, endTime: endTime!, breakMinutes });
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    const editedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const remainingMinutes = minutes !== 0 ? editedMinutes : "00";
    const result = `${hours}:${remainingMinutes} ${lg.std}`;
    return result;
  };

  render() {
    const { startTime, endTime, breakMinutes, hideEndTime, isV2 } = this.props;
    if (!this.props.startTime) {
      return null;
    }

    const tooltip = !endTime ? null : this.calculatedShiftDuration();

    const startTimeDisplay = startTime;
    const endTimeDisplay = hideEndTime ? "" : endTime;

    return (
      <>
        <div data-rh={tooltip} className="shiftTimeFrameMain startAndEndTime">
          {`${startTimeDisplay} - ${endTimeDisplay || ""}`}
          {hideEndTime && <div className="autoclockoutBadge">A</div>}
        </div>
        {!!this.props.breakMinutes && this.props.showBreakes && (
          <div className="shiftTimeFrameBreakMinutes">{`${this.props.breakMinutes}`}</div>
        )}
        {(!!this.props.showDuration || isV2) && !!endTime && endTime !== startTime && (
          <div className="shiftTimeFrameMainDurationBox">{`${
            Math.round(getDuration({ startTime, endTime, breakMinutes }) / 6) / 10
          }h`}</div>
        )}
      </>
    );
  }
}
