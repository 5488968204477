import moment from "moment";
import { getLocale, getLocaleLang } from "../../../../helpers/dateFormatHelper";

export const getYearsArray: () => Array<number> = () => {
  const base: number[] = [];
  const minYear = 2019;
  const maxYear = moment().year() + 8;

  for (let year = minYear; year <= maxYear; year++) {
    base.push(year);
  }
  return base;
};

type GetNextYM = (year: number, month: number) => { year: number; month: number };
export const getNextYM: GetNextYM = (year, month) => ({
  month: month === 11 ? 0 : month + 1,
  year: month === 11 ? year + 1 : year,
});

type GetPrevYM = (year: number, month: number) => { year: number; month: number };
export const getPrevYM: GetPrevYM = (year, month) => ({
  month: month === 0 ? 11 : month - 1,
  year: month === 0 ? year - 1 : year,
});

export const monthsDE = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];

export const monthsEN = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getMonthsArray = () => (getLocaleLang() === "en" ? monthsEN : monthsDE);
