import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../../types/AppState";
import { DispatchBaseProps } from "../../../../../frontend-core/types/DispatchBaseProps";
import { Moment } from "moment";
import moment from "moment";
import { WeekdayHeader } from "./WeekdayHeader/WeekdayHeader";
import "./styles.scss";
import { generateWeekdays, toMomentUnsafe, toSimpleDate } from "../../../../../shared/helpers/timeHelpers";
import _ from "lodash";
import { Input, Icon } from "antd";
import { setRosterUserNameFilter } from "../../../../../reducers/ui/shifts/roster/userNameFilter";
import { UserFilterInput } from "../../components/UserFilterInput/UserFilterInput";
import { SortOrderButton } from "../../RosterActionBar/SortOrderButton/SortOrderButton";
import { selectSessionInfo } from "../../../../../selectors/SessionInfoSelector";
import { GroupByRoleButton } from "../../RosterActionBar/GroupByRoleButton/GroupByRoleButton";
import { SDateFormat } from "../../../../../shared/helpers/SimpleTime";

const mapStateToProps = (state: AppState) => {
  return {
    isTemplate: state.ui.shifts.roster.rosterTemplateMode.active,
    rosterSettings: state.data.rosterSettings[0],
  };
};

type OwnProps = { startDate: string };
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & DispatchBaseProps;
type State = { searchTerm: string };

class RosterWeekGridHead extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchTerm: "",
    };
  }

  updateUserSearchValue = (searchTerm: string) => {
    this.setState({ searchTerm });
    _.debounce(() => {
      this.props.dispatch(setRosterUserNameFilter(searchTerm));
    }, 300)();
  };

  render() {
    const weekdays = generateWeekdays(this.props.startDate, {
      noSaturday: this.props.rosterSettings.hideSaturdaysInWeekPlan,
      noSunday: this.props.rosterSettings.hideSundaysInWeekPlan,
    });

    const today = moment().format(SDateFormat);

    return (
      <div className="fixed-header userGridHeadMain">
        <div className="gridRow header">
          {/* Render the first cell which is at the top left corner */}
          <div className="header cell firstColumn userSearchField">
            <UserFilterInput />
            <SortOrderButton />

            {/* <SettingsPopover
              title="Sortieren nach"
              options={this.props.sortByOptions.map((s) => ({
                id: s.value,
                name: s.label,
              }))}
              value={this.props.sortBy}
              sortDirection={this.props.sortDirection}
              changeSortDirection={this.props.changeSortDirection}
              onChange={this.props.updateSortBy}
              buttonIconName="sort-ascending"
            /> */}
          </div>
          {/* Render weekdays header cells */}
          {weekdays.map((weekday: Moment, i: number) => {
            const date = toSimpleDate(weekday);
            const isInFuture = date > today;
            return <WeekdayHeader date={date} key={i} isTemplate={this.props.isTemplate} isInFuture={isInFuture} />;
          })}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(RosterWeekGridHead);
