import { CLEAR_DATA_ACTION_TYPE } from "./maintenanceRepo";
import { CreditIntervalsMap } from "./../actions/creditActions/creditTypes";
import { Reducer } from "redux";

export const CreditIntervalAction = {
  SET: "@@AV/SET_CREDIT_INTERVALS",
};

class CreditIntervalRepository {
  getReducer(): Reducer<CreditIntervalsMap> {
    return (state: CreditIntervalsMap = {}, action: any): CreditIntervalsMap => {
      switch (action.type) {
        case CreditIntervalAction.SET:
          return { ...state, ...action.payload };
        case CLEAR_DATA_ACTION_TYPE:
          return {};
        default:
          return state;
      }
    };
  }
}

export const creditIntervalRepository = new CreditIntervalRepository();
