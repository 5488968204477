import { Button, Input, notification } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { busyInjector, BusyInjectorProps } from "../../BusyInjector/BusyInjector";
import { selectSessionInfo } from "../../../selectors/SessionInfoSelector";
import { HintModal } from "../HintModal/HintModal";
import { useSelector } from "../../../helpers/redux";
import "./styles.scss";
import { updateHinting } from "../../../actions/hinting";
import { closeModal } from "../../../actions/modal";
import absencePullingGifUrl from "./absencePulling.gif";

export const AbsenceCreateHintModal = busyInjector((props: {} & BusyInjectorProps) => {
  const dispatch = useDispatch();
  const submit = async () => {
    dispatch(updateHinting({ hasDragAndDropped: true }));
    dispatch(closeModal());
  };

  return (
    <HintModal title="Drag & Drop" onConfirm={submit}>
      <div className="dragAndDropHintMain">
        <div className="text">
          {lg.tipp_zum_anlegen_einer_abwesenheit_kann_der_zeitraum_mit_der_maus_markiert_werden}
        </div>
        <div className="gifWrapper">
          <img className="gif" style={{ width: 460 }} src={absencePullingGifUrl} alt="loading..." />
        </div>
      </div>
    </HintModal>
  );
});
