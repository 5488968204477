import { ICreditCorrection } from "./../shared/entities/ICreditCorrection";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";

export const selectCreditCorrectionMap: (
  state: AppState
) => { [correctionIn: string]: ICreditCorrection } = createSelector(
  [(state: AppState) => state.data.creditCorrections],
  (creditCorrections) => {
    const map: { [k: string]: ICreditCorrection } = {};
    creditCorrections.forEach((s) => (map[s.id] = s));
    return map;
  }
);
