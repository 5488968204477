import { ICreditSpan } from "../selectors/creditSpansSelector";
import { minutesToDuration } from "../shared/helpers/timeHelpers";

export const getUserCreditsDisplayData = (userCredits: ICreditSpan | undefined) => {
  const { credit, quota, balance } = userCredits || {};

  const hasQuota = quota !== undefined;
  const hasBalance = balance !== undefined;
  const hasEarned = credit !== undefined;

  let quotaTime = hasQuota && minutesToDuration(quota!);
  let quotaTimeOnlyHours = hasQuota && minutesToDuration(quota!, { onlyHours: true, showOneDecimal: true });
  let creditTime = hasEarned && minutesToDuration(credit!, { onlyHours: !credit });
  const _overtime = hasEarned && hasQuota && credit! - quota!;
  const hasOvertime = _overtime !== undefined && _overtime !== false;

  let overTime = hasOvertime && minutesToDuration(_overtime as number, { withSign: true });

  let balanceTime = hasBalance && minutesToDuration(balance!, { withSign: true });

  let balanceTimeOnlyHours =
    hasBalance &&
    minutesToDuration(balance!, {
      withSign: true,
      onlyHours: true,
    });

  let overTimeOnlyHours =
    hasOvertime &&
    minutesToDuration(_overtime as number, {
      withSign: true,
      onlyHours: true,
    });

  if (userCredits?.credit === undefined) {
    quotaTime = "-";
    quotaTimeOnlyHours = "-";
    creditTime = "-";
    overTime = "-";
    balanceTime = "-";
    balanceTimeOnlyHours = "-";
    overTimeOnlyHours = "-";
  }

  const isBalanceNegative = hasBalance && balance! < 0;
  const isBalancePositive = hasBalance && balance! > 0;
  const isOvertimeNegative = _overtime < 0;
  const isOvertimePositive = _overtime > 0;

  return {
    isBalanceNegative,
    isBalancePositive,
    isOvertimePositive,
    isOvertimeNegative,
    balanceTime,
    overTimeOnlyHours,
    creditTime,
    overTime,
    quotaTime,
    quotaTimeOnlyHours,
    balanceTimeOnlyHours,
  };
};
