import React, { PureComponent, Suspense } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../types/AppState";
import "./styles.scss";
import { closeModal } from "../../actions/modal";
import { Modal } from "antd";
import { withErrorBoundary } from "../ErrorBoundary/ErrorBoundary";

const mapStateToProps = (state: AppState) => ({
  modals: state.ui.modals,
});

type State = {};
type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StoreProps & { dispatch: Dispatch };

class ModalManager extends PureComponent<Props, State> {
  render() {
    const modal1 = this.props.modals[0];
    const modal2 = this.props.modals[1];

    //DAVID: Dont turn this into an array of Modals: Doing so breaks the Closing animation.

    return (
      <>
        <Suspense fallback={<div></div>}>
          <Modal
            title={null}
            width="auto"
            bodyStyle={{ padding: 0 }}
            footer={null}
            visible={!!modal1}
            destroyOnClose={true}
            onCancel={() => this.props.dispatch(closeModal())}
            afterClose={() => {}}
            {...modal1?.antModalProps}
          >
            {modal1 && <modal1.component {...modal1.props} modalId={modal1.id} />}
          </Modal>

          <Modal
            title={null}
            width="auto"
            bodyStyle={{ padding: 0 }}
            footer={null}
            visible={!!modal2}
            destroyOnClose={true}
            onCancel={() => this.props.dispatch(closeModal())}
            afterClose={() => {}}
            style={{ top: 130 }}
            {...modal2?.antModalProps}
          >
            {modal2 && <modal2.component {...modal2.props} modalId={modal2.id} />}
          </Modal>
        </Suspense>
      </>
    );
  }
}

export default withErrorBoundary(
  connect<StoreProps, {}, OwnProps, AppState>(mapStateToProps)(ModalManager)
);
