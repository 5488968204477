import React from "react";
import Page from "../../components/Page/Page";
import { Redirect, Route, Switch } from "react-router";
import AbsenceCalendar from "./AbsenceCalendarPage/AbsenceCalendarPage";
import { AbsenceListDetailed } from "./AbsenceListPage/AbsenceListDetailed/AbsenceListDetailed";
import AbsenceEntitlementPage from "./AbsenceEntitlementPage/AbsenceEntitlementPage";
import { sitemap } from "../../helpers/sitemap";
import { SubBar } from "../../components/SubBar/SubBar";
import { withErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";
import { useSelector } from "../../helpers/redux";
import { AppState } from "../../types/AppState";
import { DispatchBaseProps } from "../../frontend-core/types/DispatchBaseProps";
import { connect } from "react-redux";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { selectCan } from "../../selectors/canSelector";
import { AbsenceListSummed } from "./AbsenceListPage/AbsenceListSummed/AbsenceListSummed";

type StoreProps = ReturnType<typeof mapStateToProps>;
type Props = {} & StoreProps & DispatchBaseProps;

const mapStateToProps = (state: AppState) => ({
  sessionInfo: selectSessionInfo(state),
  can: selectCan(state),
});

class _Absences extends React.PureComponent<Props> {
  render() {
    const { can } = this.props;
    const links = [sitemap.absenceCalendar, sitemap.absenceList];
    can.manageAbsences && links.push(sitemap.absenceEntitlement);

    return (
      <>
        <SubBar links={links} />
        <Page>
          <Switch>
            <Route path={sitemap.absenceCalendar.url} component={AbsenceCalendar} />
            <Route path={sitemap.absenceList.url} component={AbsenceListDetailed} />
            <Route path={sitemap.absenceTable.url} component={AbsenceListSummed} />
            <Route path={sitemap.absenceEntitlement.url} component={AbsenceEntitlementPage} />
            <Redirect exact from={sitemap.absences.url} to={sitemap.absenceCalendar.url} />
          </Switch>
        </Page>
      </>
    );
  }
}

export default withErrorBoundary(connect<StoreProps, {}, {}, AppState>(mapStateToProps)(_Absences));
