import React from "react";
import cn from "classnames";
import { Map } from "../../../../../shared/types/general";
import { IJobPosition } from "../../../../../shared/entities/IJobPosition";
import { getUserName } from "../../../../../shared/helpers/userHelpers";
import { UserCreditDetails, UserCreditDetailsProps } from "../../components/UserCreditDetails/UserCreditDetails";
import "./styles.scss";
import { minutesToDuration, minutesToHours } from "../../../../../shared/helpers/timeHelpers";
import moment from "moment";
import { SDateFormat } from "../../../../../shared/helpers/SimpleTime";
import { IUserType } from "../../../../../shared/entities/IRosterSettings";
import { selectIsMultiJobPos } from "../../../../../selectors/isMultiJobPos";
import { useSelector } from "react-redux";

type Props = UserCreditDetailsProps & {
  staffNumber?: string;
  userType?: IUserType;
  className?: string;
  jobPosMap: Map<IJobPosition>;
  showOvertime?: boolean;
  isKanban?: boolean;
  canManage: boolean;
  maxHoursExceeding?: number;
};

const GridUserCell = React.memo((props: Props) => {
  const { staffNumber, jobPosMap, canManage, contract, maxHoursExceeding, rosterDateRange, userType } = props;
  const rangeStart = rosterDateRange.rangeStart;
  const jobPositionsOfUser = props.user.jobPositionIds.map((id) => jobPosMap[id]);
  const jobPositionNames = jobPositionsOfUser.map((jp) => jp.name).join(" / ");
  const isMultiJobPos = useSelector(selectIsMultiJobPos);

  return (
    <div
      className={`userListItemMain ${props.className ? props.className : ""}`}
      data-type="user-cell"
      data-user-id={props.user.id}
    >
      <div className="fb column content">
        <div className="fb row aCenter">
          <div className="name">{getUserName(props.user)}</div>
          {staffNumber && <div className="staffNumber">{staffNumber}</div>}
          {userType && (
            <div className="userType" data-rh={userType.name}>
              {userType.slug}
            </div>
          )}
          {contract.monthlyMaxHours && (
            <div
              className={cn({ minMaxHours: true, warning: !!maxHoursExceeding })}
              data-rh-at="right"
              data-rh={
                !maxHoursExceeding
                  ? lg.monatlich_maximale_arbeitsstunden
                  : lg.monatlich_maximale_arbeitsstunden_überschritten +
                    ` [ ${minutesToDuration(maxHoursExceeding, { onlyHours: true })} ${lg.std} ${lg.im} ${moment(
                      rangeStart,
                      SDateFormat
                    ).format("MMM")} ]`
              }
            >
              {`max ${contract.monthlyMaxHours}`}
            </div>
          )}
          {isMultiJobPos && (
            <div className={cn({ jobPositionsWrapper: true })} data-rh={jobPositionNames}>
              {jobPositionsOfUser.map((jp) => (
                <div className="jobPositionBox" key={jp.id} style={{ background: jp.color }}></div>
              ))}
            </div>
          )}
        </div>
        {canManage && <UserCreditDetails {...props} />}
      </div>
    </div>
  );
});

export default GridUserCell;
