import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useSelector } from "../../../../helpers/redux";
import "./styles.scss";
import { Button, Checkbox, Icon, Input, InputNumber } from "antd";
import TZModal from "../../../../components/TZModal/TZModal";
import { LocationSearchInput } from "../../branches/branchPopup/LocationSearchInput";
import { IShiftAddress } from "../../../../shared/entities/IShiftAddress";
import { v4 as uuid } from "uuid";
import AvColorPicker from "../../../../components/AvColorPicker/AvColorPicker";
import { pastelColors, strongColors } from "../../../../frontend-core/helpers/colors";
import _ from "lodash";
import AvFormField from "../../../../components/AvFormField/AvFormField";
import { shiftAddressRepository } from "../../../../repositories/shiftAddressRepository";
import { closeModal, openModal } from "../../../../actions/modal";
import { busyInjector, BusyInjectorProps } from "../../../../components/BusyInjector/BusyInjector";
import { orderAddresses } from "../../../../frontend-core/helpers/frontendHelpers";
import { SettingsListCollapser } from "../SettingsListCollapser/SettingsListCollapser";
import { ISurcharge } from "../../../../shared/entities/ISurcharge";
import { SurchargeModal } from "./SurchargeModal/SurchargeModal";

type Props = {};

export const SurchargesSection = busyInjector((props: Props & BusyInjectorProps) => {
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState<boolean>();
  const surcharges = useSelector((s) => s.data.surcharges);
  const count = surcharges.length;

  return (
    <div className="surchargesSectionMain">
      <div className="fb title">{lg.Zuschlaege}</div>
      <div className="titleDescription">{lg.aufschläge_werden_in_den_auswertungen_angezeigt}</div>

      <SettingsListCollapser
        isCollapsed={isCollapsed}
        onCollapsedChange={(isCollapsed) => setIsCollapsed(isCollapsed)}
        count={count}
        label={lg.Zuschlaege}
      />

      {!isCollapsed && (
        <div className="content">
          <div className="listContainer">
            {surcharges.map((surcharge) => (
              <div
                className="surchargeListElement"
                onClick={() => dispatch(openModal(SurchargeModal, { surcharge }))}
                key={surcharge.id}
              >
                <div className="surchargeIcon" style={{}}>
                  %
                </div>
                {/* <div className="colorRect"></div> */}
                <div className="text">{surcharge.name}</div>
              </div>
            ))}
          </div>
          <div className="addButtonWrapper">
            <Button
              id="add-surcharge"
              icon="plus"
              children={lg.zuschlag_hinzufügen}
              onClick={() => dispatch(openModal(SurchargeModal, {}))}
            />
          </div>
        </div>
      )}
    </div>
  );
});
