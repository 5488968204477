import { Button, Icon, Upload } from "antd";
import { ButtonType } from "antd/lib/button";
import { IconProps } from "antd/lib/icon";
import React, { DOMElement, useRef } from "react";
import { useDispatch } from "react-redux";
import { paidFeatureWarning } from "../../actions/paidFeatureWarning";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import { useSelector } from "../../helpers/redux";
import { featuresSelector } from "../../selectors/FeaturesSelector";

type Props = {
  onChange: (fiels: File[]) => any;
  //multiple?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  label?: string;
  buttonType?: ButtonType;
  doAllowUploade?: boolean;
};

export const AvUploader = (props: Props) => {
  const inputEl = useRef<HTMLInputElement | null>(null);

  const dispatch = useDispatch<DispFn>();
  const features = useSelector(featuresSelector);
  const isFeatureEnabled = features.fileUploads;

  return (
    // <Upload
    //   name="file"
    //   // fileList={[]}
    //   multiple //={props.multiple}
    //   customRequest={() => {}}
    //   onChange={async (event) => {
    //     const files = Array.from(event.fileList || []).map((file) => file.originFileObj);
    //     props.onChange(files as File[]);
    //   }}
    //   style={{ width: "100%" }}
    // >
    <>
      <Button
        id="file-uploader-button"
        style={props.style}
        icon={props.isLoading ? "loading" : "upload"}
        type={props.buttonType || "primary"}
        children={props.isLoading ? "" : props.label || lg.datei_hochladen}
        disabled={!!props.disabled}
        onClick={() => {
          isFeatureEnabled || props.doAllowUploade ? inputEl.current?.click() : dispatch(paidFeatureWarning());
        }}
      />
      <input
        ref={inputEl}
        style={{ display: "none" }}
        multiple
        type="file"
        onChange={(event) => {
          const files = event.target.files;
          props.onChange(Array.from(files || []));
          event.target.value = "";
        }}
      />
    </>
    // </Upload>
  );
};
