export const setOpenShiftsCollapsed = (isCollapsed: Boolean) => ({
  type: ROSTER_IS_OPEN_SHIFTS_COLLAPSED,
  payload: isCollapsed,
});

const ROSTER_IS_OPEN_SHIFTS_COLLAPSED = "ROSTER_IS_OPEN_SHIFTS_COLLAPSED";

const initialState = false;

export const isOpenShiftsCollapsedReducer = (state: boolean = initialState, action: any): boolean => {
  return action.type === ROSTER_IS_OPEN_SHIFTS_COLLAPSED ? action.payload : state;
};
