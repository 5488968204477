import { Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { openUpdatePaymentDetailsModal } from "../../actions/billingActions";
import { DispFn } from "../../frontend-core/types/thunkTypes";
import { useSelector } from "../../helpers/redux";
import { selectSessionInfo } from "../../selectors/SessionInfoSelector";
import { tierInfoSelector } from "../../selectors/TierInfoSelector";
import moment from "moment";

type Props = {};
export const AccountWarnings = (props: Props) => {
  const tenantInfo = useSelector((s) => s.data.tenantInfo);
  const sessionInfo = useSelector(selectSessionInfo);
  const dispatch = useDispatch<DispFn>();
  const tierInfo = useSelector(tierInfoSelector);
  const displayTempSuspendedModal = () => {
    Modal.error({
      okCancel: false,
      title: lg.zugang_vorübergehend_gesperrt,
      content: (
        <div>
          {sessionInfo.isManager() || sessionInfo.isAdmin()
            ? lg.auf_grund_von_überfälliger_rechnungen_oder_fehlgeschlagenen_lastschriftzahlungen_wurde_der_account_gesperrt_der_kontoinhaber_wurde_per_e_mail_kontaktiert_bei_fragen_wenden_sie_sich_bitte_an_unseren()
            : lg.bitte_wenden_sie_sich_an_ihrem_manager}
        </div>
      ),
      maskClosable: false,
      maskStyle: { background: "#1a90ff" },
      onOk: () => displayTempSuspendedModal(),
      onCancel: () => displayTempSuspendedModal(),
    });
  };
  const displayEmployeesNoAccessModal = () => {
    Modal.error({
      style: { minWidth: "100%" },
      okCancel: false,
      title: lg.kein_web_zugang_für_mitarbeiter,
      content: <div>{lg.mitarbeiim_free_paket_können_nur_manager_admins_die_web_version_nutzenter_haben}</div>,
      maskClosable: false,
      maskStyle: { background: "#1a90ff" },
      onOk: () => displayEmployeesNoAccessModal(),
      onCancel: () => displayEmployeesNoAccessModal(),
    });
  };
  useEffect(() => {
    // this warning is only being displayed for automatically charged accounts
    if (!tenantInfo.tempSuspended && !tenantInfo.byInvoice && sessionInfo.isAdmin() && tierInfo.unpaidSinceOneWeek) {
      Modal.warning({
        style: { minWidth: 500 },
        okCancel: true,
        title: lg.letzte_abo_zahlung_fehlgeschlagen,
        content:
          lg.die_letzte_zahlung_für_dein_tier_abo_ist_fehlgeschlagen_wenn_du_bald_keine_validen_zahlungsinformationen_angibst_wird_dein_tier_abo_automatisch_gekündigt(
            tierInfo.tier
          ),
        okText: lg.zahlungsinformationen_aktualisieren,
        onOk: () => dispatch(openUpdatePaymentDetailsModal()),
        cancelText: lg.später,
      });
    }
    if (tenantInfo.tempSuspended) {
      displayTempSuspendedModal();
    }
    // if (tierInfo.isCoreWithoutProTrial && sessionInfo.isEmployee()) {
    //   displayEmployeesNoAccessModal();
    // }
  }, []);
  return <div style={{ display: "none" }}></div>;
};
