import { IRosterTemplate } from "../../../../shared/entities/IRosterTemplate";
import { rosterTemplateActionTypes } from "../../../../actions/rosterTemplate";

type RosterTEmplateEditModeReducerState = {
  rosterTemplate?: IRosterTemplate;
  active: boolean;
};

export const rosterTemplateModeReducer = (
  state = { active: false },
  action: any
): RosterTEmplateEditModeReducerState => {
  switch (action.type) {
    case rosterTemplateActionTypes.SET_EDIT_MODE:
      return {
        active: action.payload!.active,
        rosterTemplate: action.payload!.active
          ? action.payload!.rosterTemplate
          : undefined,
      };
    default:
      return state;
  }
};
