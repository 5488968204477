import React, { useEffect, useState } from "react";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import _ from "lodash";
import * as Sentry from "@sentry/browser";
import moment from "moment";
import { useSelector } from "../../../helpers/redux";
import { shiftLogRepository } from "../../../repositories/ShiftLogRepository";
import Page from "../../../components/Page/Page";
import { ShiftLogActionBar, operationWordings } from "./ShiftLogActionBar/ShiftLogActionBar";
import Table from "../../../components/Table/Table";
import { Column, CellInfo } from "react-table-v6";
import { IShiftLog } from "../../../shared/entities/IShiftLog";
import { Operation } from "../../../shared/helpers/firebaseHelpers";
import { IShift } from "../../../shared/entities/IShift";
import { hasChanged } from "../../../shared/helpers/misc";
import { SDateFormat, DisplayDateFormat } from "../../../shared/helpers/SimpleTime";
import "./styles.scss";
import { oneLine } from "common-tags";
import { selectUsersFull } from "../../../selectors/usersFullSelector";
import { selectWorkSpaces } from "../../../selectors/_workSpacesSelector";

export type LogFilters = {
  branchId: string;
  startTimestamp: number;
  endTimestamp: number;
  operation?: Operation;
  involvedUserIds?: string[];
  jobPositionIds?: string[];
};

interface IRowData extends IShiftLog {
  userName: string;
  branchName: string;
  displayDateTime: string;
  changeText?: string;
}

const renderDefaultCell = (props: CellInfo) => {
  return (
    <div className="fb jStart">
      <div className="cell-text-wrapper">{props.value}</div>
    </div>
  );
};

const getFormattedShiftTime = (s: IShift) => `${s.startTime} - ${s.endTime}`;
const getShiftLabel = (s: IShift) => oneLine`
  ${moment(s.date, SDateFormat).format("dd DD. MMM")} | ${s.startTime} - ${s.endTime}
`;
const getFormattedShiftSurcharge = (s: IShift) => `${s.surcharge || 0}${s.surchargeInPercentage ? "%" : "h"}`;

interface VerbalizedChange {
  title: string;
  prev: string;
  next: string;
}

export const ShiftLogPage = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();

  const shiftLogs = useSelector((state) => state.data.shiftLogs);
  const shiftAddresses = useSelector((state) => state.data.shiftAddresses);
  const branches = useSelector((state) => state.data.branches);
  const users = useSelector(selectUsersFull);
  const selectedBranch = useSelector((state) => state.ui.selectedBranch);
  const jobPositions = useSelector((state) => state.data.jobPositions);
  const workSpaces = useSelector(selectWorkSpaces);

  const [filters, updateFilters] = useState<LogFilters>({
    startTimestamp: moment().subtract(7, "days").startOf("day").unix(),
    endTimestamp: moment().endOf("day").unix(),
    branchId: selectedBranch,
    operation: undefined,
    jobPositionIds: [],
    involvedUserIds: [],
  });

  useEffect(() => {
    fetchLogs();
  }, []);

  const fetchLogs = () => {
    setTimeout(() => {
      // setTimeout: to wait for filter-state changes, before fetching
      const { branchId, startTimestamp, endTimestamp } = filters;
      const startIndex = `${branchId}_${startTimestamp}`;
      const endIndex = `${branchId}_${endTimestamp}`;

      dispatch(
        shiftLogRepository.fetchMany({
          filter: ["branchId_timestamp", "between", [startIndex, endIndex]],
        })
      );
    });
  };

  const getVerbalizedShiftChanges = (prev: IShift, next: IShift): VerbalizedChange[] => {
    const changes: VerbalizedChange[] = [];
    if (hasChanged(["startTime", "endTime"], prev, next)) {
      changes.push({
        title: lg.schichtzeit,
        prev: getFormattedShiftTime(prev),
        next: getFormattedShiftTime(next),
      });
    }
    if (hasChanged(["surcharge", "surchargeInPercentage"], prev, next)) {
      changes.push({
        title: lg.aufschlag,
        prev: getFormattedShiftSurcharge(prev),
        next: getFormattedShiftSurcharge(next),
      });
    }
    if (hasChanged(["date"], prev, next)) {
      changes.push({
        title: lg.datum,
        prev: moment(prev.date, SDateFormat).format(DisplayDateFormat),
        next: moment(next.date, SDateFormat).format(DisplayDateFormat),
      });
    }
    if (hasChanged(["breakMinutes"], prev, next)) {
      changes.push({
        title: lg.pause,
        prev: `${prev.breakMinutes} Minuten`,
        next: `${next.breakMinutes} Minuten`,
      });
    }
    if (hasChanged(["jobPositionId"], prev, next)) {
      changes.push({
        title: lg.rolle,
        prev: jobPositions.find((jp) => jp.id === prev.jobPositionId)!.name,
        next: jobPositions.find((jp) => jp.id === next.jobPositionId)!.name,
      });
    }
    if (hasChanged(["userId"], prev, next)) {
      changes.push({
        title: lg.mitarbeiter,
        prev: users.find((u) => u.id === prev.userId)?.name || "Offen",
        next: users.find((u) => u.id === next.userId)?.name || "Offen",
      });
    }
    if (hasChanged(["workSpaceId"], prev, next)) {
      changes.push({
        title: lg.label,
        prev: workSpaces.find((w) => w.id === prev.workSpaceId)?.name || "",
        next: workSpaces.find((w) => w.id === next.workSpaceId)?.name || "",
      });
    }
    if (hasChanged(["requiredUsersAmount"], prev, next)) {
      changes.push({
        title: lg.benötigtes_personal,
        prev: `${prev.requiredUsersAmount || 0} ${lg.mitarbeiter_plural}`,
        next: `${next.requiredUsersAmount || 0} ${lg.mitarbeiter_plural}`,
      });
    }
    if (hasChanged(["comment"], prev, next)) {
      changes.push({
        title: lg.kommentar,
        prev: (prev.comment || "").substr(0, 20) + "...",
        next: (next.comment || "").substr(0, 20) + "...",
      });
    }

    if (hasChanged(["addressId"], prev, next)) {
      changes.push({
        title: lg.adresse,
        prev: shiftAddresses.find((w) => w.id === prev.addressId)?.name || "",
        next: shiftAddresses.find((w) => w.id === next.addressId)?.name || "",
      });
    }

    if (hasChanged(["breakStartTime"], prev, next)) {
      changes.push({
        title: lg.pausenstart,
        prev: `${prev.breakStartTime} Uhr`,
        next: `${next.breakStartTime} Uhr`,
      });
    }

    if (hasChanged(["branchId"], prev, next)) {
      changes.push({
        title: lg.standort,
        prev: branches.find((w) => w.id === prev.branchId)?.name || "",
        next: branches.find((w) => w.id === next.branchId)?.name || "",
      });
    }

    if (hasChanged(["isNoShow"], prev, next)) {
      changes.push({
        title: lg.nicht_angetreten,
        prev: !!prev.isNoShow + "",
        next: !!next.isNoShow + "",
      });
    }

    return changes;
  };

  const shiftLogRowData = React.useMemo(() => {
    const log = shiftLogs
      .map((sl) => {
        const actingUser = users.find((u) => u.id === sl.userId)?.name;
        const displayDateTime = moment.unix(sl.timestamp).format("DD. MMM - HH:mm");
        const baseData = displayDateTime + " | " + actingUser;
        const assignedUser = users.find((u) => u.id === (sl.previous?.userId || sl.next!.userId));
        return {
          ...sl,
          branchName: branches.find((b) => b.id === sl.branchId)?.name,
          userName: assignedUser ? assignedUser.name : lg.offene_schicht,
          baseData,
          operationDisplayName: operationWordings[sl.operation],
          shiftName: getShiftLabel(sl.previous || sl.next!),
        };
      })
      .filter(
        (sl) =>
          sl.branchId === filters.branchId &&
          sl.timestamp >= filters.startTimestamp! &&
          sl.timestamp <= filters.endTimestamp! &&
          (!filters.operation || sl.operation === filters.operation) &&
          (!filters.involvedUserIds?.length ||
            filters.involvedUserIds.some(
              (uid) => sl.userId === uid || sl.previous?.userId === uid || sl.next?.userId === uid
            )) &&
          (!filters.jobPositionIds?.length ||
            filters.jobPositionIds.some(
              (jpId) => sl.previous?.jobPositionId === jpId || sl.next?.jobPositionId === jpId
            ))
      );

    return log;
  }, [shiftLogs, jobPositions, workSpaces, branches, users, filters]);

  const tableColumns: Column<IRowData>[] = React.useMemo(
    () => [
      {
        Header: lg.geändert_am_von,
        accessor: "baseData",
        Cell: renderDefaultCell,
      },
      {
        Header: lg.schichtdatum,
        accessor: "shiftName",
        Cell: renderDefaultCell,
      },
      {
        Header: lg.mitarbeiter,
        accessor: "userName",
        Cell: renderDefaultCell,
      },
      {
        Header: lg.änderungen,
        minWidth: 200,
        Cell: (props: CellInfo) => {
          const isUpdate = props.original.operation === Operation.update;
          const isCreate = props.original.operation === Operation.create;
          return (
            <div className="fb jStart large-cell">
              <div className="cell-text-wrapper">
                {!isUpdate
                  ? isCreate
                    ? lg.erstellt
                    : lg.gelöscht
                  : getVerbalizedShiftChanges(props.original.previous, props.original.next).map((change, i) => (
                      <div key={i} className="fb row">
                        <div className="changeTitle">{change.title}:</div>
                        <div className="changeData">
                          {change.prev} <span style={{ opacity: 0.3 }}> {">"} </span> {change.next}
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <Page>
      <div className="shiftLogPageMain">
        <h2 className="avTitle">Schichtenlog</h2>
        <ShiftLogActionBar filters={filters} updateFilters={updateFilters} fetchLogs={fetchLogs} />
        <Table
          data={shiftLogRowData}
          columns={tableColumns}
          showPagination={false}
          pageSize={shiftLogs.length}
          NoDataComponent={() => <div className="noData">{lg.keine_daten_im_gewählten_zeitraum_vorhanden}</div>}
        />
      </div>
    </Page>
  );
};
