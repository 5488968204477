import { getPrevSimpleDate, getMonthIntervalStartDate, getMonthIntervalEndDate } from "./../shared/helpers/dateHelpers";
import { createCachedSelector } from "re-reselect";
import { selectRosterDateRange } from "./rosterDateRangeSelector";
import { getShiftCredit } from "../shared/helpers/credit";
import { selectContractsByUser } from "./contractsByUserSelector";
import { createSelector } from "reselect";
import { AppState } from "../types/AppState";
import moment from "moment";
import { relevantShiftsSelector } from "./RelevantShiftsSelector";
import _ from "lodash";
import { toSimpleDate } from "../shared/helpers/timeHelpers";
import { getValidContract } from "../shared/helpers/credit";
import { IContract, WorkInterval } from "../shared/entities/IContract";
import { RosterMode } from "../reducers/ui/shifts/roster/rosterMode";
import { IShift } from "../shared/entities/IShift";
import { BalancesMap, CreditIntervalsMap } from "../actions/creditActions/creditTypes";

export type ICreditSpan = {
  credit: number;
  quota: number;
  balance?: number;
};

export type CreditSpansMap = { [userId: string]: ICreditSpan };

const getCreditSpanForUser = (
  userId: string,
  contractsByUser: {
    [userId: string]: IContract[];
  },
  creditBalances: BalancesMap,
  creditIntervals: CreditIntervalsMap,
  templateModeIsActive: boolean,
  shifts: IShift[],
  customMonthStartDate: number,
  rosterDateRange: {
    rangeStart: string;
    rangeEnd: string;
  },
  rosterMode: RosterMode
) => {
  let { rangeStart, rangeEnd } = rosterDateRange;
  let preRangeStart = getPrevSimpleDate(rangeStart);
  const contracts = contractsByUser[userId];
  const validContract = getValidContract(contracts, rangeStart)!;
  const hasMonthlyContract = validContract.interval === WorkInterval.monthly;
  const isDayMode = rosterMode === RosterMode.Day;

  if (hasMonthlyContract && !isDayMode) {
    // also in weeklyRoster for users with monthlyContracts we use the month range
    rangeStart = getMonthIntervalStartDate(rangeStart, customMonthStartDate);
    rangeEnd = getMonthIntervalEndDate(rangeStart, customMonthStartDate);
    preRangeStart = getPrevSimpleDate(rangeStart);
  }

  if (templateModeIsActive) {
    // in template mode we manually sum up the shift-durations
    const currentShiftsForUser = shifts.filter((s) => s.userId === userId);
    return {
      quota: validContract.totalHours * 60,
      credit: _.sum(currentShiftsForUser.map((s) => getShiftCredit(s))),
    };
  }

  const interval = creditIntervals[`${userId}_${rangeStart}_${rangeEnd}`];
  const balance = creditBalances[`${userId}_${preRangeStart}`]?.balance || 0;
  return {
    credit: interval?.credit,
    quota: interval?.quota,
    balance: balance,
  };
};

export const selectCreditSpans = createSelector(
  [
    (state: AppState) => state.data.users,
    selectContractsByUser,
    (state: AppState) => state.data.creditBalances,
    (state: AppState) => state.data.creditIntervals,
    (state: AppState) => state.ui.shifts.roster.rosterTemplateMode.active,
    relevantShiftsSelector,
    (state: AppState) => state.data.rosterSettings[0].customMonthIntervalStart || 1,
    selectRosterDateRange,
    (state: AppState) => state.ui.shifts.roster.rosterMode,
  ],
  (
    users,
    contractsByUser,
    creditBalances,
    creditIntervals,
    templateModeIsActive,
    shifts,
    customMonthStartDate,
    rosterDateRange,
    rosterMode
  ): CreditSpansMap => {
    const creditSpans: CreditSpansMap = {};

    users.map((user) => {
      creditSpans[user.id] = getCreditSpanForUser(
        user.id,
        contractsByUser,
        creditBalances,
        creditIntervals,
        templateModeIsActive,
        shifts,
        customMonthStartDate,
        rosterDateRange,
        rosterMode
      );
    });

    return creditSpans;
  }
);

export const selectCreditSpanForUser = createCachedSelector(
  selectContractsByUser,
  (state: AppState) => state.data.creditBalances,
  (state: AppState) => state.data.creditIntervals,
  (state: AppState) => state.ui.shifts.roster.rosterTemplateMode.active,
  relevantShiftsSelector,
  (state: AppState) => state.data.rosterSettings[0].customMonthIntervalStart || 1,
  selectRosterDateRange,
  (state: AppState) => state.ui.shifts.roster.rosterMode,
  (_s, userId: string) => userId,
  (
    contractsByUser,
    creditBalances,
    creditIntervals,
    templateModeIsActive,
    shifts,
    customMonthStartDate,
    rosterDateRange,
    rosterMode,
    userId
  ) => {
    return getCreditSpanForUser(
      userId,
      contractsByUser,
      creditBalances,
      creditIntervals,
      templateModeIsActive,
      shifts,
      customMonthStartDate,
      rosterDateRange,
      rosterMode
    );
  }
)((s, userId) => userId);
