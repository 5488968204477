import { RosterSettings } from "../pages/settings/rosterSettings/RosterSettings";
import { BaseRepository } from "../frontend-core/BaseRepository";
import { IUpload } from "../shared/entities/IUpload";

class UploadRepository extends BaseRepository<IUpload, IUpload> {
  constructor() {
    super("uploads");
  }
}

export const uploadRepository = new UploadRepository();
