import { unparse, parse } from "papaparse";
import { saveDataAs } from "../helpers/export";
import { SDateFormat } from "../shared/helpers/SimpleTime";
import * as XLSX from "xlsx";
import moment from "moment";

const getFileName = (_startDate: string, _endDate: string) => {
  const startDate = moment(_startDate, SDateFormat).format("L");
  const endDate = moment(_endDate, SDateFormat).format("L");
  return `${lg.abwesenheiten} (${startDate} - ${endDate}`;
};

const getAbsenceTableCsv = (absenceListData: Array<any>, columns: Array<{ Header: string; accessor: string }>) => {
  const data = absenceListData.map((rowData) => {
    const row = {};
    columns.forEach((col) => (row[col.Header] = rowData[col.accessor]));
    return row;
  });

  return unparse(data);
};

export const exportAbsenceTableCsv = (
  absenceListData: Array<any>,
  columns: Array<{ Header: string; accessor: string }>,
  startDate: string,
  endDate: string
) => {
  const csv = getAbsenceTableCsv(absenceListData, columns);
  saveDataAs(csv, `${getFileName(startDate, endDate)}.csv`);
};

export const exportAbsenceTableExcel = (
  absenceListData: Array<any>,
  cols: Array<{ Header: string; accessor: string }>,
  startDate: string,
  endDate: string
) => {
  const csv = getAbsenceTableCsv(absenceListData, cols);
  const filename = getFileName(startDate, endDate);

  const worksheet = XLSX.utils.aoa_to_sheet(parse(csv, { dynamicTyping: true }).data as string[][]);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "worksheet");
  XLSX.writeFileXLSX(workbook, filename + ".xlsx", {});
};
