import React from "react";
import { useDrag } from "react-dnd";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { Shift, ShiftProps } from "../../../../../components/Shift/Shift";
import { shfitDragActionTypes } from "../../../../../reducers/ui/shifts/roster/draggingShift";
import { IShift } from "../../../../../shared/entities/IShift";

type Props = ShiftProps & {
  disableDnD?: boolean;
};

const DraggableShift = (props: Props) => {
  const { data } = props;

  // Init Drag Hook
  const [{ isDragging }, drag] = useDrag<IShift, any, { isDragging: boolean }>({
    type: "SHIFT",
    canDrag: () => props.canManage && !props.disableDnD && !props.handOverRequest && !props.tracking,
    collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
    item: () => {
      // using setTimeout here to make it possible to pick up a shift, because
      // as soon as a shift is picked up we add `pointer-events:none` to all shifts.
      setTimeout(() => {
        props.dispatch({ type: shfitDragActionTypes.BEGIN, payload: data });
      });
      return data;
    },
    end: () => {
      props.dispatch({ type: shfitDragActionTypes.END });
    },
  });

  return (
    <Shift
      {...props}
      dragRef={drag}
      additionalClassNames={
        cn({
          isDragging,
          isNotDragging: !isDragging,
        }) +
          " " +
          props.additionalClassNames || ""
      }
    />
  );
};

export default React.memo(DraggableShift);
