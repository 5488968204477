import React, { useState } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { Icon, Alert } from "antd";
import { AppState } from "../../../types/AppState";
import { DispatchBaseProps } from "../../../frontend-core/types/DispatchBaseProps";
import BlueAplanoLogo from "../../../components/Logos/BlueAplanoLogo";
import googleBadge from "../noMobile/google-badge.svg";
import appleBadge from "../noMobile/apple-badge.svg";
import aplanoLogoSrc from "../../../logos/aplano-logo-blue.svg";
import zeitguruLogoSrc from "../../../logos/zeitguru-logo-black.svg";
import tadaGifSrc from "./tada.gif";
import YouTube from "react-youtube";
import { isV2Domain } from "../../../frontend-core/helpers/frontendHelpers";
import { envi, environment } from "../../../env";

type Props = {};

export const NoMobileAdmin = (props: Props) => {
  const logoSrc = envi.isV2() ? zeitguruLogoSrc : aplanoLogoSrc;

  return (
    <div className="noMobileAdmin">
      <div className="alertMsg">
        <div className="iconWrapper">
          <Icon type="like" style={{ color: envi.isV2() ? "#020b12" : undefined }} />
        </div>
        <div className="title setupAbgeschlossenText">{lg.einrichtung_abgeschlossen}</div>
        {/* <div className="text">{lg.erstellen_sie_nun_dienstpläne_im_browser_am_pc()}</div> */}
      </div>
      {!envi.isV2() && (
        <div className="adminVideoWrapper noShrink" style={{ width: "100%", height: 180, maxWidth: "100%" }}>
          <div style={{ width: "100%", zIndex: 10, maxWidth: "100%" }}>
            <YouTube videoId={"ZawRaO-fDyo"} opts={{ width: "100%", height: "180", playerVars: { autoplay: 1 } }} />
          </div>
        </div>
      )}
      {envi.isV2() && (
        <div className="fb row jCenter" style={{ alignSelf: "stretch" }}>
          <div className="fb row aCenter jCenter" style={{ marginBottom: 20, marginTop: "5vh" }}>
            <img src={tadaGifSrc} width="150" />
          </div>
        </div>
      )}
      <div className="descriptionPrimary">
        <div className="secondary">
          {lg.um_mitarbeiter_anzulegen_und_dienstpläne_zu_erstellen_verwenden_sie_unsere_desktop_version_im_browser()}
        </div>
        <div className="primary">{lg.loggen_sie_sich_dafür_mit_ihren_zugangsdaten_unter_aplano_am_rechner_ein()}</div>
      </div>

      <div className="buttonsWrapper fb aCenter jEnd" style={{ flex: "1 1 auto" }}>
        <div className="logoWrapper fb row aCenter jCenter ">
          <img src={logoSrc} width="130" />
        </div>
        <div className="text mitDerAppText">
          {envi.isV2()
            ? lg.unser_mobile_app_gibt_es_im_app_store_und_im_play_store
            : lg.mit_der_aplano_app_haben_auch_ihre_mitarbeiter_den_dienstplan_immer_dabei}
        </div>
        <div className="fb row">
          <a
            href={
              environment.isV2()
                ? "https://play.google.com/store/apps/details?id=com.zeitguru"
                : "https://play.google.com/store/apps/details?id=com.aplano"
            }
          >
            <img src={googleBadge} alt="googleBadge" className="googleBadge" />
          </a>
          <a
            href={
              environment.isV2()
                ? "https://apps.apple.com/de/app/zeitguru/id6467637471"
                : "https://apps.apple.com/de/app/aplano/id1497688145"
            }
          >
            <img src={appleBadge} alt="appleBadge" className="appleBadge" />
          </a>
        </div>
      </div>
    </div>
  );
};
